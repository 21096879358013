import React from 'react'
import { Field, reduxForm } from 'redux-form/immutable'
import { InjectedFormProps } from 'redux-form'
import { Map } from 'immutable'
import { WithStyles, withStyles } from '@material-ui/core/styles'
import { withNamespaces, WithNamespaces } from 'react-i18next'
import { jumpToFirstError, FormTextField } from '@syncfab/machine'
import { Grid, Button } from '@material-ui/core'

export const PromoCodeFormName: string = 'PromoCodeFormName'

const styles = theme => ({
    paper: {
        width: '90%',
        minWidth: 300,
        padding: theme.spacing(4),
        marginTop: theme.spacing(4),
        marginBottom: theme.spacing(4),
    },
    divider: {
        marginTop: theme.spacing(3),
        marginBottom: theme.spacing(3),
    },
    link: {
        textDecoration: 'none',
        color: '#40B4BF',
    },
    button: {
        margin: theme.spacing(1),
    },
    viewpart: {
        margin: theme.spacing(4),
    },
    mfgIcon: {
        width: 75,
        height: 75,
    },
    PrtIcon: {
        width: 35,
        height: 35,
    },
    card: {
        width: '100%',
        margin: `${theme.spacing(3)}px 12px`,
        backgroundColor: theme.palette.common.white,
    },
    cardheader: {
        backgroundColor: '#DBDBDB',
    },
    balanceContainer: {
        border: `1px solid ${theme.palette.secondary.main}`,
        borderRadius: 15,
        margin: `${theme.spacing(2)}px ${theme.spacing(1)}px`,
        padding: theme.spacing(2),
    },
    balance: {
        color: theme.palette.secondary.main,
        fontSize: 34,
        marginLeft: theme.spacing(1),
    },
    avatar: {
        backgroundColor: '#fffff',
    },
    fitContent: {
        height: 'fit-content',
    },
    // divider: {
    //     marginBottom: theme.spacing.unit * 2,
    // },

    // arrow: {
    //     color: theme.palette.common.white,
    //     marginRight: theme.spacing.unit,
    // },
    detailcard: {
        height: 'fit-content',
        margin: `${theme.spacing(3)}px 12px`,
        backgroundColor: theme.palette.common.white,
        //color: theme.palette.common.black,
    },
    customcardheader: {
        'background-color': '#eeeeee',
    },
    customtextfield: {
        'background-color': '#DBDBDB',
        margin: theme.spacing(1),
    },
    marginTextField: {
        marginRight: theme.spacing(2),
    },
})

const validate = (data: Map<string, string>): {} => {
    const errors: Fields = {}
    data.forEach((val, key) => {
        console.log(key, val)

        if (!data.get('promocode')) {
            errors.promocode = 'Required'
        }
        // if (key.endsWith('_title_1') && val.length > 10) {
        //     errors[key] = 'Max character limit 10'
        // }
        // if (key.endsWith('_title_2') && val.length > 10) {
        //     errors[key] = 'Max character limit 10'
        // }
        // if (key.endsWith('_desc_1') && val.length > 20) {
        //     errors[key] = 'Max character limit 20'
        // }
        // if (key.endsWith('_desc_2') && val.length > 20) {
        //     errors[key] = 'Max character limit 20'
        // }
    })

    return errors
}
type Fields = {
    promocode?: string
}

interface FormState {
    //sendToNextPage: boolean
}

interface FormProperties {
    // selectedParts?: any[]
    // balance?: any
    // transactionFeeForPartTokenization?: any
    // deletePart: (id) => void
}

interface FormProps extends InjectedFormProps<{}, {}>, WithStyles, WithNamespaces, FormProperties {}

class PromoCodeFormView extends React.Component<FormProps, FormState> {
    constructor(props) {
        super(props)
        //  this.props.reset()
        this.state = {
            //    sendToNextPage: false,
        }
    }

    render() {
        const { handleSubmit, classes } = this.props
        const {} = this.state
        return (
            <form onSubmit={handleSubmit}>
                <Grid container>
                    <Grid container item xs={8} style={{ paddingRight: '16px' }}>
                        <Field
                            name="promocode"
                            type="text"
                            id="standard-basic"
                            style={{ border: '1px solid #ccc', borderRadius: '3px' }}
                            //  className={`${classes.textField} ${classes.customtextfield}`}
                            label="Add Promo Code"
                            //  margin="normal"
                            //   variant=""
                            fullWidth
                            component={FormTextField}
                        />
                    </Grid>
                    <Grid container item xs={4} alignItems="center">
                        <Button type="submit" size="small" color="secondary" variant="outlined" className={`${classes.fitContent}`}>
                            ADD CODE
                        </Button>
                    </Grid>
                </Grid>
            </form>
        )
    }
}

const translatedForm = withStyles(styles)(withNamespaces()(PromoCodeFormView))
export const PromoCodeForm = reduxForm<{}, FormProperties>({
    form: PromoCodeFormName,
    validate,
    onSubmitFail: jumpToFirstError,
})(translatedForm as any) // ReduxForm types are not good
