import React, { FC } from "react";
import {
    Box,
    Chip,
    Table,
    TableBody,
    TableCell,
    TableRow,
    Theme,
    Tooltip,
    Typography,
    useTheme
} from "@material-ui/core";
import {
    CurrencyFormatter,
    DateFormatter, downloadDocument, EntityType,
    getSupplierPOLineItemStatusBackgroundColor, IntegerFormatter,
    PurchaseOrderLineItem,
    PurchaseOrderLineItemStatus,
    useStandardStyles
} from "@syncfab/machine";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import FiberManualRecordIcon from "@material-ui/icons/FiberManualRecord";
import InfoIcon from '@material-ui/icons/Info';

const useStyles = makeStyles((_theme: Theme) =>
    createStyles({
        innerTableDataRow: {
            height: 'auto !important',
            border: '1px solid #FFFFFF40',
            '&:nth-of-type(odd)': {
                backgroundColor: '#FFFFFF15',
            },
            // hide last border
            '&:last-child td, &:last-child th': {
                border: 0,
            },
            '& td': {
                padding: '8px',
                borderBottom: 'none',
                fontSize: 14
            }
        },
        tableLeftCell: {
            padding: '0px',
            width: 120,
            border: 'none'
        },
        tableRightCell: {
            padding: '0px 0px 0px 8px',
            border: 'none'
        },
        tableChipCell: {
            padding: '0px',
            border: 'none'
        },
        value: {
            fontSize: '14px'
        },
        lineItemTableCell: {
            verticalAlign: 'top',
            paddingTop: '16px',
            paddingBottom: '16px',
            borderBottom: '1px solid #FFFFFF40'
        }
    })
)
interface SupplierPurchaseOrderRowProps {
    poLineItem: PurchaseOrderLineItem,
    handleViewDocument: (docId: string, entityId: string, entityType: EntityType) => void,
    token: string
}

export const SupplierPurchaseOrderLineItemRow: FC<SupplierPurchaseOrderRowProps> = ({
    poLineItem,
    handleViewDocument,
    token
}) => {

    const standardClasses = useStandardStyles()
    const classes = useStyles()
    const theme = useTheme()

    const getRemainingQuantity = (poLineItem: PurchaseOrderLineItem): string => {
        if (!!poLineItem.pricing.quantity && !!poLineItem.receivedQuantity) {
            return IntegerFormatter.format(poLineItem.pricing.quantity - poLineItem.receivedQuantity)
        }
        if (!!poLineItem.pricing.quantity) {
            return IntegerFormatter.format(poLineItem.pricing.quantity)
        }
        return '--'
    }

    return (
        <TableRow hover tabIndex={-1}>
            <TableCell className={classes.lineItemTableCell}>
                {poLineItem._id}
            </TableCell>
            <TableCell className={classes.lineItemTableCell} style={{ minWidth: '300px' }}>
                <Table style={{ backgroundColor: '#364F68' }}>
                    <TableBody>
                        <TableRow className={classes.innerTableDataRow}>
                            <TableCell className={classes.tableLeftCell}>
                                <Typography variant="body2"
                                            className={standardClasses.label}>
                                    Line Status
                                </Typography>
                            </TableCell>
                            <TableCell className={classes.tableRightCell}>
                                <Box display='flex' alignItems='middle' justifyContent='flex-start'>
                                    <FiberManualRecordIcon
                                        fontSize='small'
                                        style={{
                                            color: getSupplierPOLineItemStatusBackgroundColor(poLineItem.status, theme),
                                            marginRight: '4px'
                                        }}
                                    />
                                    <Typography style={{ fontSize: 14, fontWeight: 'bold' }}>
                                        {poLineItem.status.toUpperCase()}
                                    </Typography>
                                </Box>
                            </TableCell>
                        </TableRow>
                        <TableRow className={classes.innerTableDataRow}>
                            <TableCell className={classes.tableLeftCell}>
                                <Typography className={standardClasses.label}>
                                    Ship Date
                                </Typography>
                            </TableCell>
                            <TableCell className={classes.tableRightCell}>
                                <Typography className={classes.value}>
                                    {!!poLineItem.shipDate ? DateFormatter.format(new Date(poLineItem.shipDate)) : '--'}
                                </Typography>
                            </TableCell>
                        </TableRow>
                        <TableRow className={classes.innerTableDataRow}>
                            <TableCell className={classes.tableLeftCell}>
                                <Typography variant="body2"
                                            className={standardClasses.label}>
                                    Part Number
                                </Typography>
                            </TableCell>
                            <TableCell className={classes.tableRightCell}>
                                <Typography className={classes.value}>
                                    {poLineItem.part?.number ?? poLineItem.partId}
                                </Typography>
                            </TableCell>
                        </TableRow>
                        <TableRow className={classes.innerTableDataRow}>
                            <TableCell className={classes.tableLeftCell}>
                                <Typography className={standardClasses.label}>
                                    Revision
                                </Typography>
                            </TableCell>
                            <TableCell className={classes.tableRightCell}>
                                <Typography className={classes.value}>
                                    {poLineItem.part?.revision}
                                </Typography>
                            </TableCell>
                        </TableRow>
                        <TableRow className={classes.innerTableDataRow}>
                            <TableCell className={classes.tableLeftCell}>
                                <Typography className={standardClasses.label}>
                                    Description
                                </Typography>
                            </TableCell>
                            <TableCell className={classes.tableRightCell}>
                                <Typography className={classes.value}>
                                    {poLineItem.part?.name}
                                </Typography>
                            </TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
            </TableCell>
            <TableCell className={classes.lineItemTableCell} style={{ minWidth: '350px' }}>
                <Table style={{ backgroundColor: '#364F68' }}>
                    <TableBody>
                        <TableRow className={classes.innerTableDataRow}>
                            <TableCell className={classes.tableLeftCell}>
                                <Typography variant="body2"
                                            className={standardClasses.label}>
                                    Material
                                </Typography>
                            </TableCell>
                            <TableCell className={classes.tableRightCell}>
                                    { poLineItem.part.materials?.map((item, index) =>
                                        <Typography className={standardClasses.value} key={index}>
                                            {`${item}${poLineItem.part.materialDetails?.[index] ? ' - ' + poLineItem.part.materialDetails[index] : ''}`}
                                        </Typography>
                                    )}
                            </TableCell>
                        </TableRow>
                        <TableRow className={classes.innerTableDataRow}>
                            <TableCell className={classes.tableLeftCell}>
                                <Box display='flex'>
                                    <Typography variant="body2"
                                                className={standardClasses.label}>
                                        Finish
                                    </Typography>
                                    <Tooltip title='Required/Approved Outside Services' placement='top'>
                                        <InfoIcon fontSize='small' style={{ color: '#FFFFFF', marginLeft: '8px' }} />
                                    </Tooltip>
                                </Box>
                            </TableCell>
                            <TableCell className={classes.tableRightCell}>
                                { poLineItem.part.finishes?.map((item, index) =>
                                    <Typography className={standardClasses.value} key={index}>
                                        {`${item}${poLineItem.part.finishDetails?.[index] ? ' - ' + poLineItem.part.finishDetails[index] : ''}`}
                                    </Typography>
                                )}
                            </TableCell>
                        </TableRow>
                        <TableRow className={classes.innerTableDataRow}>
                            <TableCell className={classes.tableLeftCell}>
                                <Box display='flex'>
                                    <Typography variant="body2"
                                            className={standardClasses.label}>
                                        Part ID
                                    </Typography>
                                    <Tooltip title='Required Part Identification for Completed Parts' placement='top'>
                                        <InfoIcon fontSize='small' style={{ color: '#FFFFFF', marginLeft: '8px' }} />
                                    </Tooltip>
                                </Box>
                            </TableCell>
                            <TableCell className={classes.tableRightCell}>
                                <Typography className={classes.value}>
                                    {poLineItem.partIdentification}
                                </Typography>
                            </TableCell>
                        </TableRow>
                        <TableRow className={classes.innerTableDataRow}>
                            <TableCell className={classes.tableLeftCell}>
                                <Box display='flex'>
                                    <Typography variant="body2"
                                                className={standardClasses.label}>
                                        Exceptions
                                    </Typography>
                                    <Tooltip title='Approved Exceptions' placement='top'>
                                        <InfoIcon fontSize='small' style={{ color: '#FFFFFF', marginLeft: '8px' }} />
                                    </Tooltip>
                                </Box>
                            </TableCell>
                            <TableCell className={classes.tableRightCell}>
                                <Typography className={classes.value}>
                                    {poLineItem.exceptions}
                                </Typography>
                            </TableCell>
                        </TableRow>
                        <TableRow className={classes.innerTableDataRow}>
                            <TableCell className={classes.tableLeftCell}>
                                <Typography variant="body2"
                                            className={standardClasses.label}>
                                    Notes
                                </Typography>
                            </TableCell>
                            <TableCell className={classes.tableRightCell}>
                                <Typography className={classes.value}>
                                    {poLineItem.notes}
                                </Typography>
                            </TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
            </TableCell>
            <TableCell className={classes.lineItemTableCell} style={{ minWidth: '200px' }}>
                <Table style={{ backgroundColor: '#364F68' }}>
                    <TableBody>
                        <TableRow className={classes.innerTableDataRow}>
                            <TableCell className={classes.tableLeftCell}>
                                <Typography variant="body2"
                                            className={standardClasses.label}>
                                    Total
                                </Typography>
                            </TableCell>
                            <TableCell className={classes.tableRightCell}>
                                <Typography className={classes.value}>
                                    {poLineItem.pricing.quantity ? IntegerFormatter.format(poLineItem.pricing.quantity) : '--'}
                                </Typography>
                            </TableCell>
                        </TableRow>
                        <TableRow className={classes.innerTableDataRow}>
                            <TableCell className={classes.tableLeftCell}>
                                <Typography variant="body2"
                                            className={standardClasses.label}>
                                    Delivered
                                </Typography>
                            </TableCell>
                            <TableCell className={classes.tableRightCell}>
                                <Typography className={classes.value}>
                                    {poLineItem.receivedQuantity  ? IntegerFormatter.format(poLineItem.receivedQuantity) : '0'}
                                </Typography>
                            </TableCell>
                        </TableRow>
                        <TableRow className={classes.innerTableDataRow}>
                            <TableCell className={classes.tableLeftCell}>
                                <Typography variant="body2"
                                            className={standardClasses.label}>
                                    Remaining
                                </Typography>
                            </TableCell>
                            <TableCell className={classes.tableRightCell}>
                                <Typography className={classes.value}>
                                    {getRemainingQuantity(poLineItem)}
                                </Typography>
                            </TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
            </TableCell>
            <TableCell className={classes.lineItemTableCell} style={{ width: '405px' }}>
                <Table style={{ backgroundColor: '#364F68', width: '405px' }}>
                    <TableBody>
                    {poLineItem.part.documents?.map((doc) =>
                        <TableRow className={classes.innerTableDataRow} key={doc._id}>
                            <TableCell>
                                <div style={{
                                    width: '220px',
                                    whiteSpace: 'nowrap',
                                    overflow: 'hidden',
                                    textOverflow: 'ellipsis' }}
                                >
                                    {doc.name}
                                </div>
                            </TableCell>
                            <TableCell className={classes.tableChipCell} style={{ width: '165px' }}>
                                <Chip
                                    clickable
                                    size='small'
                                    variant='outlined'
                                    label='VIEW'
                                    onClick={() => {
                                        handleViewDocument(doc._id, poLineItem._id, EntityType.PURCHASEORDERLINEITEM)
                                    }}
                                    disabled={!doc.name.toLowerCase().endsWith('.pdf')}
                                    style={{
                                        color: '#40B4BF',
                                        borderColor: '#40B4BF',
                                        fontWeight: 'bold',
                                    }}
                                />
                                <Chip
                                    clickable
                                    label='DOWNLOAD'
                                    size='small'
                                    variant='outlined'
                                    style={{
                                        marginLeft: '8px',
                                        borderColor: '#40B4BF',
                                        color: '#40B4BF',
                                        fontWeight: 'bold'
                                    }}
                                    onClick={async () => {
                                        await downloadDocument(doc._id, token, EntityType.PURCHASEORDERLINEITEM, poLineItem._id)
                                    }}
                                />
                            </TableCell>
                        </TableRow>
                    )}
                    </TableBody>
                </Table>
            </TableCell>
            <TableCell className={classes.lineItemTableCell} style={{ minWidth: '250px' }}>
                <Table style={{ backgroundColor: '#364F68' }}>
                    <TableBody>
                        <TableRow className={classes.innerTableDataRow}>
                            <TableCell className={classes.tableLeftCell}>
                                <Typography variant="body2"
                                            className={standardClasses.label}>
                                    Unit Price
                                </Typography>
                            </TableCell>
                            <TableCell 
                                style={{
                                    textDecoration: (
                                            poLineItem.status === PurchaseOrderLineItemStatus.CANCELED && 
                                            !!poLineItem.pricing.supplierUnitCost 
                                                ? 'line-through' 
                                                : 'inherit'
                                        )
                                }} 
                                className={classes.tableRightCell}
                            >
                                <Typography className={classes.value}>
                                    {!!poLineItem.pricing.supplierUnitCost ? CurrencyFormatter.format(poLineItem.pricing.supplierUnitCost) : '--'}
                                </Typography>
                            </TableCell>
                        </TableRow>
                        {poLineItem.additionalCharges.filter(charge => charge.supplierChargeAmount > 0).map(charge =>
                        <TableRow className={classes.innerTableDataRow} key={charge._id}>
                            <TableCell className={classes.tableLeftCell}>
                                <Typography variant="body2"
                                            className={standardClasses.label}>
                                    {charge.chargeType}
                                </Typography>
                            </TableCell>
                            <TableCell 
                                style={{
                                    textDecoration: (
                                            poLineItem.status === PurchaseOrderLineItemStatus.CANCELED && 
                                            !!poLineItem.pricing.supplierUnitCost 
                                                ? 'line-through' 
                                                : 'inherit'
                                        )
                                }} 
                                className={classes.tableRightCell}
                            >
                                <Typography className={classes.value}>
                                    {!!charge.supplierChargeAmount ? CurrencyFormatter.format(charge.supplierChargeAmount) : '--'}
                                </Typography>
                            </TableCell>
                        </TableRow>
                        )}
                    </TableBody>
                </Table>
            </TableCell>
        </TableRow>
    )
}
