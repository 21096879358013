import 'reflect-metadata'
import React from 'react'
import { Redirect, Route, RouteComponentProps, Switch, withRouter } from 'react-router-dom'
import { Helmet } from 'react-helmet'
import {
    FeaturePackage,
    ForcedPublicRoute,
    LoginRole,
    ProtectedRoute,
    PublicRoute,
    SimpleFeedback
} from '@syncfab/machine'
import { withStyles, WithStyles } from '@material-ui/core'
import { Dispatch } from 'redux'
import { connect } from 'react-redux'
import Navigation from '../layout/Navigation'
import { SideBar } from '../layout/SideBar'
import cn from 'classnames'
import TBDComponent from '../layout/TBDComponent'
import { SAVE_PREVIOUSURL } from '../components/user/Actions'
import UserEmailValidation from '../view/settings/UserEmailValidation'
import SupplierSignupValidation from '../view/supplier/signup/SupplierSignupValidation'
import SupplierReverificationView from '../view/supplier/signup/SupplierReverificationView'
import SignupConfirmation from '../view/signup/SignupConfirmation'
import ViewSupplier from '../view/supplier/viewSupplier'
import SignupActivation from '../view/signup/SignupActivation'
import LoginSelection from '../view/loginSelection/LoginSelection'
import Wallet from '../view/wallet'
import SupplierSignupConfirmation from '../view/supplier/signup/SupplierSignupConfirmation'
import UpdatePassword from '../view/password/UpdatePassword'
import Settings from '../view/settings'
import { SuppliersUpdatePassword } from '../view/supplier/password/UpdatePassword'
import UserActivation from '../view/users/activation/index'
import { QuoteSuppliers } from '../view/quotes/supplier'
import { Part } from '../view/parts'
import { UploadPart } from '../view/parts/uploadpart'
import { PartTokenizeDisplay } from '../view/parts/parttoknize'
import { PurchaseMFGView } from '../view/wallet/PurchaseMFG'
import { TrackDetailsView } from '../view/track/viewTrack'
import { UserProfileEditor } from '../view/users/editor'
import { Signup } from '../view/signup'
import { ReverificationView } from '../view/signup/Reverification'
import { Login } from '../view/login'
import { ForgotPasswordConfirmation } from '../view/password/ForgotPasswordConfirmation'
import { ForgotPassword } from '../view/password'
import { SupplierSignup } from '../view/supplier/signup'
import { SupplierLogin } from '../view/supplier/login'
import { SupplierForgotPasswordConfirmation } from '../view/supplier/password/ForgotPasswordConfirmation'
import { SupplierForgotPassword } from '../view/supplier/password'
import { Dashboard } from '../view/dashboard'
import { Suppliers } from '../view/supplier/supplierList'
import QuoteList from '../view/quotes/list'
import ViewPart from '../view/parts/viewPart'
import RFQQuoteEditor from '../view/quotes/editor/components/RFQQuoteEditor'
import { useAppSelector } from "./hooks";
import Track from "../view/track"
import NoFeatureAccess from "../layout/NoFeatureAccess";
import { ViewRFQ } from "../view/quotes/details/ViewRFQ";
import { ViewQuoteDocument } from "../view/quotes/details/ViewQuoteDocument";
import { SupplierPurchaseOrderList } from "../view/purchaseOrders/ListSupplierPurchaseOrders";
import { ViewSupplierPurchaseOrder } from "../view/purchaseOrders/ViewSupplierPurchaseOrder";
import { ConfirmSupplierPurchaseOrder } from "../view/purchaseOrders/ConfirmSupplierPurchaseOrder";
import ContactListIndex from '../view/supplier/verifySupplier/index/ContactListIndex'
import { BuyerPurchaseOrderList } from "../view/purchaseOrders/ListBuyerPurchaseOrders";
import { ViewBuyerPurchaseOrder } from "../view/purchaseOrders/ViewBuyerPurchaseOrder";
import DetailsIndex from '../view/supplier/verifySupplier/index/DetailsIndex'
import QAIndex from '../view/supplier/verifySupplier/index/QAIndex'
import CertificationsIndex from '../view/supplier/verifySupplier/index/CertificationsIndex'
import { Publications } from '../view/publications'

const styles = theme => ({
    root: {
        padding: 0,
        margin: 0,
        minHeight: '100vh',
        position: 'relative' as any,
    },
    mainContent: {
        paddingLeft: theme.spacing(30),
    },
    mainContentCollapsed: {
        paddingLeft: theme.spacing(12),
    },
    messageClass: {
        color: theme.palette.common.white,
        backgroundColor: theme.palette.common.black,
    },
})

interface ApplicationProps extends WithStyles, RouteComponentProps {
    sideBarCollapsed?: boolean
    authToken?: string
    showSupplierTab?: boolean
    savePreviousUrl?: (url: string) => any
    loggedUser?: any
}

class Application extends React.Component<ApplicationProps> {
    public render() {
        if (!this.props.authToken && window.location.href.includes('/track/')) {
            //this.props.
            let url = window.location.href.split('/track/')
            this.props.savePreviousUrl('/track/' + url[url.length - 1])
        }
        const { classes, sideBarCollapsed, authToken } = this.props

        const styles = cn({
            [classes.mainContentCollapsed]: !!authToken && sideBarCollapsed,
            [classes.mainContent]: !!authToken && !sideBarCollapsed,
        })
        return (
            <div className={classes.root}>
                <Navigation />
                <SideBar />
                <div className={styles}>
                    <React.Fragment>
                        <Helmet>
                            <title>SyncFab</title>
                        </Helmet>

                        <Switch>
                            <Route path="/featureAccess" component={NoFeatureAccess} />
                            <Route path="/updateEmail" component={UserEmailValidation} />
                            <Route path="/updatePassword" component={UpdatePassword} />
                            <Route path="/suppliers/updatePassword" component={SuppliersUpdatePassword} />
                            <ForcedPublicRoute path="/users/activate" component={UserActivation} />
                            <ForcedPublicRoute path="/quotes/suppliers" component={QuoteSuppliers} />
                            <ProtectedRoute path="/settings/:selectedTab" component={Settings} />
                            <Redirect from="/settings" to="/settings/user" />
                            <ProtectedRoute exact path="/quotes/request" component={RFQQuoteEditor} />
                            <ProtectedRoute path="/quotes/request/:id" component={RFQQuoteEditor} />
                            <ProtectedRoute path="/quotes/:quoteId/preview" component={ViewQuoteDocument} />
                            <ProtectedRoute path="/quotes/:rfqId" component={ViewRFQ} />
                            <ProtectedRoute exact path="/quotes" component={QuoteList} />
                            <ProtectedRoute path="/supplier/verify/contacts" component={ContactListIndex} />
                            <ProtectedRoute path="/supplier/verify/details" component={DetailsIndex} />
                            <ProtectedRoute path="/supplier/verify/qa" component={QAIndex} />
                            <ProtectedRoute path="/supplier/verify/certifications" component={CertificationsIndex} />
                            <ProtectedRoute exact path="/purchaseOrders" component={BuyerPurchaseOrderList} />
                            <ProtectedRoute path="/purchaseOrders/:purchaseOrderId" component={ViewBuyerPurchaseOrder} />
                            <ProtectedRoute exact path="/supplierPurchaseOrders" component={SupplierPurchaseOrderList} />
                            <ProtectedRoute path="/supplierPurchaseOrders/:supplierPurchaseOrderId/confirm" component={ConfirmSupplierPurchaseOrder} />
                            <ProtectedRoute path="/supplierPurchaseOrders/:supplierPurchaseOrderId/" component={ViewSupplierPurchaseOrder} />
                            <ProtectedRoute exact path="/parts" component={Part} />
                            <ProtectedRoute path="/parts/uploadpart" component={UploadPart} />
                            <ProtectedRoute path="/parts/tokenizeparts" component={PartTokenizeDisplay} />
                            <ProtectedRoute path="/parts/editpart/:companyId/:partId" component={UploadPart} />
                            <ProtectedRoute path="/parts/:id" component={ViewPart} />
                            <ProtectedRoute path="/invoices" component={TBDComponent} />
                            <ProtectedRoute path="/users/:id?" component={UserProfileEditor} />
                            <ProtectedRoute path="/documentation" component={Publications} />
                            <PublicRoute path="/signup" component={Signup} />
                            <PublicRoute path="/confirmation" component={SignupConfirmation} />
                            <PublicRoute path="/verify" component={SignupActivation} />
                            <PublicRoute path="/reverification" component={ReverificationView} />
                            <PublicRoute path="/login" component={LoginSelection} />
                            <PublicRoute path="/user/login" component={Login} />
                            <PublicRoute path="/forgot/confirmation" component={ForgotPasswordConfirmation} />
                            <PublicRoute path="/forgot" component={ForgotPassword} />
                            <PublicRoute path="/suppliers/signup" component={SupplierSignup} />
                            <PublicRoute path="/suppliers/login" component={SupplierLogin} />
                            <PublicRoute path="/suppliers/forgot/confirmation" component={SupplierForgotPasswordConfirmation} />
                            <PublicRoute path="/suppliers/forgot" component={SupplierForgotPassword} />
                            <PublicRoute path="/suppliers/confirmation" component={SupplierSignupConfirmation} />
                            <PublicRoute path="/suppliers/reverification" component={SupplierReverificationView} />
                            <PublicRoute path="/suppliers/validation" component={SupplierSignupValidation} />

                            <Route exact path="/wallet" render={() => (
                                <RequireFeaturePackage requiredFeaturePackage={FeaturePackage.SUPPLIER_ANALYTICS} >
                                    {/*
                                    // @ts-ignore */}
                                    <Wallet />
                                </RequireFeaturePackage>
                                )}
                            />
                            <Route exact path="/wallet/purchase" render={() => (
                                <RequireFeaturePackage requiredFeaturePackage={FeaturePackage.SUPPLIER_ANALYTICS} >
                                    <PurchaseMFGView />
                                </RequireFeaturePackage>
                                )}
                            />
                            <Route exact path="/track" render={() => (
                                    <RequireFeaturePackage requiredFeaturePackage={FeaturePackage.SUPPLIER_ANALYTICS} >
                                        <Track />
                                    </RequireFeaturePackage>
                                )}
                            />
                            <Route path="/track/:id" render={() => (
                                <RequireFeaturePackage requiredFeaturePackage={FeaturePackage.SUPPLIER_ANALYTICS} >
                                    {/*
                                    // @ts-ignore */}
                                    <TrackDetailsView />
                                </RequireFeaturePackage>
                                )}
                            />
                            <Route path="/dashboard" render={() => (
                                <RequireFeaturePackage requiredFeaturePackage={FeaturePackage.SUPPLIER_ANALYTICS} >
                                    {/*
                                    // @ts-ignore */}
                                    <Dashboard />
                                </RequireFeaturePackage>
                                )}
                            />
                            <Route exact path="/suppliers" render={() => (
                                <RequireFeaturePackage requiredFeaturePackage={FeaturePackage.SUPPLIER_ANALYTICS} >
                                    {/*
                                    // @ts-ignore */}
                                    <Suppliers />
                                </RequireFeaturePackage>
                                )}
                            />
                            <Route path="/suppliers/:id" render={() => (
                                <RequireFeaturePackage requiredFeaturePackage={FeaturePackage.SUPPLIER_ANALYTICS} >
                                    {/*
                                    // @ts-ignore */}
                                    <ViewSupplier />
                                </RequireFeaturePackage>
                                )}
                            />

                            {!!authToken && <Redirect from="*" to="/quotes" />}
                            {!authToken && <Redirect from="*" to="/login" />}
                        </Switch>
                    </React.Fragment>
                </div>
                <SimpleFeedback messageClass={classes.messageClass} />
            </div>
        )
    }
}

const AppWithRouter = withRouter(withStyles(styles)(Application))

const mapStateToProps = ({ auth: { authToken }, layout: { sideBarCollapsed }, supplier: { showSupplierTab }, user: { loggedUser } }) => {
    return {
        sideBarCollapsed,
        authToken,
        showSupplierTab,
        loggedUser,
    }
}

const mapDispatchToProps = (dispatch: Dispatch) => {
    return {
        savePreviousUrl: (url: string) => dispatch(SAVE_PREVIOUSURL.actions.TRIGGER(url)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps, null)(AppWithRouter)

const RequireFeaturePackage = (
    { children, requiredFeaturePackage, redirectTo = '/featureAccess' }:
        { children, requiredFeaturePackage: FeaturePackage, redirectTo?: string }
) => {
    if (!useAppSelector((state) => state.auth?.authToken)) {
        return <Redirect to='/login' />
    }
    const user = useAppSelector((state) => state.user.loggedUser)
    if (user?.loginRole === LoginRole.SUPPLIER) {
        return children
    }
    const userFeaturePackages = user?.company?.featurePackage ?? []
    return userFeaturePackages.includes(requiredFeaturePackage) ? children : <Redirect to={redirectTo} />
}
