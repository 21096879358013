import { PaymentTerms } from '../quote'
import { Document } from '../document/Document'

export enum OrderStatusFilter {
    ALL = 'ALL',
    ORDER_RECEIVED = 'ORDER_RECEIVED',
    PRODUCTION_START = 'PRODUCTION_START',
    PRODUCTION_END = 'PRODUCTION_END',
    DEPARTURE = 'DEPARTURE',
    DELIVERY = 'DELIVERY',
}

export enum OrderStatus {
    ORDER_RECEIVED = 'ORDER_RECEIVED',
    PRODUCTION_START = 'PRODUCTION_START',
    PRODUCTION_END = 'PRODUCTION_END',
    DEPARTURE = 'DEPARTURE',
    DELIVERY = 'DELIVERY',
}

export enum PaymentStatus {
    PAID = 'PAID',
    OVERDUE = 'OVERDUE',
    PENDING = 'PENDING',
}

export interface OrderMilestone {
    estimated?: Date
    updated?: Date
    actual?: Date
}

export interface Order {
    _id: string
    companyId: string
    userId: string
    operatorId?: string
    quoteId: string
    status: OrderStatus
    // Milestones
    milestones: { [key in OrderStatus]: OrderMilestone }
    orderItemCount: number
    name: string
    paymentTerms: PaymentTerms
    documents: Document[]
    paymentStatus: PaymentStatus
    createdAt: Date
    updateAt?: Date
}
