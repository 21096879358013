import React from 'react'
import { WithNamespaces, withNamespaces } from 'react-i18next'
import { connect } from 'react-redux'
import Helmet from 'react-helmet'
import { Loader, User, PUSH, SimpleDialog } from '@syncfab/machine'
import { WithStyles, withStyles, Paper } from '@material-ui/core'
import { State } from '../../../application/Store'
import { Dispatch } from 'redux'
import { LIST_USERS, DELETE_USER, RESEND_INVITATION_USER } from '../../../components/user/Actions'
import { UserList } from './components/UserList'
import { UserProfileEditor } from '../editor'
import { ListMeta } from '../../../layout/list/Meta'

const styles = theme => ({
    paper: {
        width: '100%',
        minWidth: 300,
        padding: theme.spacing(4),
        marginTop: theme.spacing(4),
        marginBottom: theme.spacing(4),
    },
    header: {
        marginBottom: theme.spacing(1),
    },
})

export interface UserListBaseProps {
    loggedUser: User
    deleteUser: (id: string) => void
    editUser: (id: string) => void
    resendInvite: (id: string) => void
}

interface UserListProps extends UserListBaseProps, WithNamespaces, WithStyles {
    list: (meta: ListMeta) => void
    edit: (id: string) => void
    create: () => void
    users: User[]
    meta: ListMeta
}

interface UserListState {
    dialogOpen: boolean
    idToDelete?: string
}

class List extends React.Component<UserListProps, UserListState> {
    constructor(props: UserListProps) {
        super(props)
        this.toggleDeletedDialog = this.toggleDeletedDialog.bind(this)
        this.onDelete = this.onDelete.bind(this)
        this.deleteUser = this.deleteUser.bind(this)
        this.state = {
            dialogOpen: false,
        }
        this.props.list(this.props.meta)
    }

    toggleDeletedDialog() {
        this.setState(state => ({
            dialogOpen: !state.dialogOpen,
        }))
    }

    onDelete(idToDelete: string) {
        this.setState({
            idToDelete,
        })
        this.toggleDeletedDialog()
    }

    deleteUser() {
        const id = this.state.idToDelete
        if (!id) {
            return
        }
        this.props.deleteUser(id)
        this.toggleDeletedDialog()
        this.setState({
            idToDelete: undefined,
        })
    }

    render() {
        const { t, users, classes, edit, loggedUser, resendInvite, meta,list } = this.props

        if (!users) {
            return <Loader />
        }
        const queryParams = new URLSearchParams(window.location.search)
        const queryStringType = queryParams.get('type')
        return (
            <React.Fragment>
                <Helmet>
                    <title>{`${t('user-list-title')}`}</title>
                </Helmet>
                {queryStringType ? (
                    <Paper className={classes.paper} elevation={1}>
                         <UserProfileEditor/>
                    </Paper>
                ) : (
                    <UserList meta={meta} users={users} deleteUser={this.onDelete} editUser={edit} loggedUser={loggedUser} resendInvite={resendInvite} getUserList={list}/>
                )}
                <SimpleDialog
                    title={t('user-list-delete-dialog-title')}
                    content={t('user-list-delete-dialog-content')}
                    primaryActionText={t('form-action-delete')}
                    onPrimaryAction={this.deleteUser}
                    secondaryActionText={t('form-action-cancel')}
                    onSecondaryAction={this.toggleDeletedDialog}
                    open={this.state.dialogOpen}
                />
            </React.Fragment>
        )
    }
}

const UserListViewWithTranslations = withStyles(styles)(withNamespaces()(List))

const mapStateToProps = ({ user: { users, loggedUser, meta } }: State) => {
    return {
        users,
        loggedUser,
        meta,
    }
}

const mapDispatchToProps = (dispatch: Dispatch) => {
    return {
        list: (meta: ListMeta) => dispatch(LIST_USERS.actions.TRIGGER({ meta })),
        deleteUser: (id: string) => dispatch(DELETE_USER.actions.TRIGGER({ id })),
        resendInvite: (id: string) => dispatch(RESEND_INVITATION_USER.actions.TRIGGER({ id })),
        edit: (id: string) => dispatch(PUSH(`/settings/team?type=edit&userId=${id}`)),
        create: () => dispatch(PUSH('/users')),
    }
}

export const UserListView = connect(mapStateToProps, mapDispatchToProps)(UserListViewWithTranslations)
