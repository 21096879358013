import * as React from 'react'
import {
    TableHead,
    TableRow,
    TableCell,
    withStyles,
    Grid,
    Typography,
    Card,
    CardHeader,
    CardContent,
    Table,
    TableBody,
    Divider,
    Tooltip,
    IconButton,
    CircularProgress,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    Menu,
} from '@material-ui/core'
import { PRTIconWhite, PartIcon, colors } from '@syncfab/machine'
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline'
import Chip from '@material-ui/core/Chip'
import OpenInNewIcon from '@material-ui/icons/OpenInNew'
import { Link } from 'react-router-dom'
import { InsertDriveFile } from '@material-ui/icons'
import MoreVertIcon from '@material-ui/icons/MoreVert'
import PDFViewer from '../../../components/pdfViewer/PDFViewer'

const styles = theme => ({
    link: {
        color: '#40B4BF',
        'text-decoration': 'none',
    },
    card: {
        width: '100%',
        margin: `${theme.spacing(3)}px 12px`,
        backgroundColor: theme.palette.common.white,
    },
    cardWhite: {
        width: '100%',
        margin: 0,
        marginTop: 10,
        backgroundColor: theme.palette.common.white,
        padding: theme.spacing(2),
    },
    detailcard: {
        width: '-webkit-fill-available',
        height: 'fit-content',
        margin: `${theme.spacing(3)}px 12px`,
        backgroundColor: theme.palette.common.white,
    },
    cardheader: {
        backgroundColor: theme.palette.grey['300'],
    },
    iconImg: {
        // height:'20%',
        // width:'100%',
        backgroundColor: theme.palette.grey['300'],
    },
    PartIcon: {
        height: 'fit-content',
        width: '100%',
        padding: theme.spacing(3),
    },
    tableCell: {
        '&.MuiTableCell-root': {
            color: '#000 !important', // <-- arbitrary value
        },
    },
    label: {
        fontFamily: "'Titillium Web', 'sans-serif'",
        fontWeight: theme.typography.fontWeightMedium,
        fontSize: 14,
    },
    text: {
        fontFamily: "'Titillium Web', 'sans-serif'",
        fontSize: 14,
        margin: 0,
        color: '#666',
    },
    fileSize: {
        color: theme.palette.grey.A200,
    },
    fileName: {
        color: theme.palette.text.primary,
        fontWeight: theme.typography.fontWeightMedium,
    },
    formControl: {
        '& .MuiOutlinedInput-root.Mui-disabled .MuiOutlinedInput-notchedOutline': {
            borderColor: theme.palette.common.black,
            opacity: 0.1,
            color: colors.disabled,
        },
        '& .MuiInputLabel-root': {
            color: colors.disabled,
        },
        '& MuiInputBase-root.Mui-disabled': {
            color: '#fff !important',
        },
    },
})
interface PartViewProps {
    classes?: any
    part?: any
    company?: any
    download: (id: string) => void
    ethernetLink?: any
    reset?: () => void
    previewDocument?: (id: string) => void
    supplierTags: {
        finishes: { label: string; value: string }[]
        manufacturingProcesses: { label: string; value: string }[]
        materials: { label: string; value: string }[]
    }
}
interface ViewPartState {
    anchorEl: null
    pdfViewer: boolean
    openDocumentMenuDocId: string
}
class PartView extends React.Component<PartViewProps, ViewPartState> {
    constructor(props: PartViewProps) {
        super(props)
        this.state = {
            anchorEl: null,
            pdfViewer: false,
            openDocumentMenuDocId: null,
        }
    }
    bytesForHuman(bytes, decimals = 2) {
        let units = ['B', 'KB', 'MB', 'GB', 'TB', 'PB']

        let i = 0

        for (i; bytes > 1024; i++) {
            bytes /= 1024
        }

        return parseFloat(bytes.toFixed(decimals)) + ' ' + units[i]
    }
    handleClickDocumentOptions = (event, id) => {
        this.setState({
            openDocumentMenuDocId: id,
            anchorEl: event.currentTarget,
        })
    }

    handleCloseDocumentOptions = () => {
        this.setState({
            openDocumentMenuDocId: null,
            anchorEl: null,
        })
    }

    handleDocumentOptionMenu = (option, id) => {
        if (option.toLowerCase() === 'preview') {
            this.viewPDF(id)
        } else if (option.toLowerCase() === 'download') {
            this.props.download(id)
        }
    }
    viewPDF = docId => {
        this.props.previewDocument(docId)
        this.setState({ pdfViewer: true })
    }
    closePDFViewer = () => {
        this.setState({ pdfViewer: false })
        this.props.reset()
    }
    public render(): JSX.Element {
        const { classes, part, company, ethernetLink, supplierTags } = this.props
        const { anchorEl, pdfViewer } = this.state
        const ITEM_HEIGHT = 48
        const options = ['Preview', 'Download']
        const open = Boolean(anchorEl)

        const materialsTags =
            part && part?.materials?.length && supplierTags?.materials?.length
                ? part?.materials?.map(st => {
                      const supplierTag = supplierTags?.materials?.filter(mp => mp.value === st)

                      return supplierTag && supplierTag?.length === 1 ? supplierTag[0] : null
                  })
                : []

        const manufacturingProcessesTags =
            part && part?.manufacturingProcesses && supplierTags?.manufacturingProcesses?.length
                ? part?.manufacturingProcesses?.map(st => {
                      const supplierTag = supplierTags?.manufacturingProcesses?.filter(mp => mp.value === st)

                      return supplierTag && supplierTag?.length === 1 ? supplierTag[0] : null
                  })
                : []

        const finishesTags =
            part && part?.finishes?.length && supplierTags?.finishes?.length
                ? part.finishes.map(st => {
                      const supplierTag = supplierTags?.finishes?.filter(mp => mp.value === st)

                      return supplierTag && supplierTag?.length === 1 ? supplierTag[0] : null
                  })
                : []

        return (
            <Grid container>
                <Grid container item xs={4}>
                    <Card className={classes.detailcard} style={{ marginLeft: '0px', marginRight: '24px' }}>
                        <div className={classes.iconImg}>
                            <PartIcon className={classes.PartIcon} />
                        </div>
                        {/* <img src={'https://thebulletin.org/wp-content/uploads/2017/10/blockchain-CS-BLK_0.jpg'} alt={'diagram'} width="100%" height="40%" /> */}
                        <Grid className={classes.card}>
                            <Typography color="textPrimary" variant="h6">
                                MFGPRT Token ID
                            </Typography>
                            {/* <Typography color="textPrimary" variant="body1"> */}
                            <Grid container direction="row" alignItems="center">
                                {part.status == 'IN_PROGRESS' && (
                                    <Grid container direction="row" alignItems="center">
                                        <Grid item alignItems="center" justify="center" direction="column">
                                            {/* <Typography> */}
                                            <CircularProgress color="primary" size={16} thickness={4} />
                                            {/* </Typography> */}
                                        </Grid>
                                        <Grid item style={{ marginLeft: '12px' }}>
                                            <a className={classes.link}>Processing</a>
                                        </Grid>
                                    </Grid>
                                )}
                                {part.tokenId && part.status == 'TOKENIZED' && (
                                    <React.Fragment>
                                        <Grid item>
                                            {' '}
                                            <Chip icon={<PRTIconWhite fill="#FFFFFF" />} color="secondary" label={part.tokenId} />
                                        </Grid>
                                        <Grid>
                                            <a target="_blank" href={`${ethernetLink}${part?.tokenId}`}>
                                                <OpenInNewIcon fontSize="small" color="disabled" style={{ marginLeft: '16px' }} />
                                            </a>
                                            {/* <a target="_blank" href={`https://ropsten.etherscan.io/token/${company.walletAddress}?a=${part.tokenId}`}>
                                            <OpenInNewIcon fontSize="small" color="disabled" style={{ marginLeft: '16px' }} />
                                        </a> */}
                                        </Grid>
                                    </React.Fragment>
                                )}
                                {(part?.status == 'NOT_TOKENIZE' || !part?.status) && (
                                    <Grid item>
                                        {' '}
                                        <Typography color="textPrimary" variant="body1">
                                            {' '}
                                            No TokenID{' '}
                                        </Typography>
                                    </Grid>
                                )}

                                {(part.status == 'NOT_TOKENIZE' || !part.status) && (
                                    <Grid item style={{ marginLeft: '16px' }}>
                                        <Tooltip
                                            title={
                                                'Adding a MFGPRT TokenID to your part creates an immutable blockchain record of your part that secures its IP data and allows more robust Track & Trace features.'
                                            }
                                            enterDelay={300}
                                        >
                                            <ErrorOutlineIcon color="primary" fontSize="small" />
                                        </Tooltip>
                                    </Grid>
                                )}
                                {part.status == 'FAILED' && <Grid item>{'Failed'}</Grid>}
                            </Grid>
                            {/* </Typography> */}
                        </Grid>
                        <Divider />
                        <Grid item className={classes.card}>
                            <Typography color="textPrimary" variant="h6">
                                Owner
                            </Typography>
                            <Typography color="textPrimary" variant="body1">
                                {company.name}
                            </Typography>
                        </Grid>
                        <Divider />
                        <Grid item className={classes.card}>
                            <Typography color="textPrimary" variant="h6">
                                Part Uploaded{' '}
                            </Typography>
                            <Typography color="textPrimary" variant="body1">
                                {new Date(part.createdAt).toLocaleDateString()}
                            </Typography>
                        </Grid>
                    </Card>
                </Grid>
                <Grid container item xs={8} direction="row">
                    <Grid item xs={12}>
                        <Card className={classes.card} style={{ marginLeft: '0px', marginBottom: '0px' }}>
                            <CardHeader
                                className={classes.cardheader}
                                title={
                                    <Typography color="textPrimary" variant="h3">
                                        Part Details
                                    </Typography>
                                }
                            />
                            <CardContent>
                                <Grid container item xs={12} style={{ margin: '20px 0' }}>
                                    <Grid item xs={4}>
                                        <div className={classes.label}>Part Number</div>
                                    </Grid>
                                    <Grid item xs={8}>
                                        <p className={classes.text}>{part?.number}</p>
                                    </Grid>
                                </Grid>
                                <Grid container item xs={12} style={{ margin: '20px 0' }}>
                                    <Grid item xs={4}>
                                        <div className={classes.label}>Description</div>
                                    </Grid>
                                    <Grid item xs={8}>
                                        <p className={classes.text}>{part?.name}</p>
                                    </Grid>
                                </Grid>
                                <Grid container item xs={12} style={{ margin: '20px 0' }}>
                                    <Grid item xs={4}>
                                        <div className={classes.label}>Revision</div>
                                    </Grid>
                                    <Grid item xs={8}>
                                        <p className={classes.text}>{part?.revision}</p>
                                    </Grid>
                                </Grid>
                                <Grid container item xs={12} style={{ margin: '20px 0' }}>
                                    <Grid item xs={4}>
                                        <div className={classes.label}>National Stock Number</div>
                                    </Grid>
                                    <Grid item xs={8}>
                                        <p className={classes.text}>{part?.nationalStockNumber ? part?.nationalStockNumber : '-'}</p>
                                    </Grid>
                                </Grid>
                                <Grid container item xs={12} style={{ margin: '20px 0' }}>
                                    <Grid container item xs={4} alignItems={'center'}>
                                        <div className={classes.label}>Manufacturing Process</div>
                                    </Grid>
                                    <Grid item xs={8}>
                                        {manufacturingProcessesTags && manufacturingProcessesTags?.length > 0
                                            ? manufacturingProcessesTags?.map((mt, index) => {
                                                  return <Chip key={index} tabIndex={-1} label={mt?.label} style={{ marginBottom: 5, marginRight: 5 }} />
                                              })
                                            : null}
                                    </Grid>
                                </Grid>
                                <Grid container item xs={12} style={{ margin: '10px 0' }}>
                                    <Grid container item xs={4} alignItems={'center'}>
                                        <div className={classes.label}>Material</div>
                                    </Grid>
                                    <Grid item xs={8}>
                                        {materialsTags && materialsTags.length && materialsTags.length > 0
                                            ? materialsTags.map((mt, index) => {
                                                const detail = part.materialDetails?.[index]
                                                return (
                                                    <>
                                                        <Chip key={index} tabIndex={-1} label={mt?.label} style={{ marginBottom: 5, marginRight: 5 }} />
                                                        {!!detail ? ` - ${detail}` : ''}
                                                        <br />
                                                    </>
                                                )
                                            })
                                            : null}
                                    </Grid>
                                </Grid>
                                <Grid container item xs={12} style={{ margin: '10px 0' }}>
                                    <Grid container item xs={4} alignItems={'center'}>
                                        <div className={classes.label}>Finish</div>
                                    </Grid>
                                    <Grid item xs={8}>
                                        {finishesTags && finishesTags.length && finishesTags.length > 0
                                            ? finishesTags.map((mt, index) => {
                                                const detail = part.finishDetails?.[index]
                                                return (
                                                    <>
                                                        <Chip key={index} tabIndex={-1} label={mt?.label} style={{ marginBottom: 5, marginRight: 5 }} />
                                                        {!!detail ? ` - ${detail}` : ''}
                                                        <br />
                                                    </>
                                                )
                                            })
                                            : null}
                                    </Grid>
                                </Grid>
                                <Grid container item xs={12} style={{ margin: '10px 0' }}>
                                    <Grid item xs={4}>
                                        <div className={classes.label}>Notes</div>
                                    </Grid>
                                    <Grid item xs={8}>
                                        <p className={classes.text}>{part?.notes ?? '-'}</p>
                                    </Grid>
                                </Grid>
                                {part.priorRevisionPartId && (
                                    <Grid container item xs={12} style={{ margin: '10px 0' }}>
                                        <Grid item xs={4}>
                                            <div className={classes.label}>Prior Revision ID</div>
                                        </Grid>
                                        <Grid item xs={8}>
                                            <Link to={`/parts/${part?.priorRevisionPartId}/${part?.companyId}`} className={classes.link}>
                                                {part?.priorRevisionPartId}
                                            </Link>
                                        </Grid>
                                    </Grid>
                                )}
                            </CardContent>
                        </Card>
                    </Grid>

                    <Grid item xs={12} style={{ marginTop: '10px' }}>
                        <Card className={classes.card} style={{ marginLeft: '0px', marginBottom: '0px' }}>
                            <CardHeader
                                className={classes.cardheader}
                                title={
                                    <Typography color="textPrimary" variant="h3">
                                        Part Documents
                                    </Typography>
                                }
                            />
                            {/* <Divider /> */}
                            <CardContent>
                                <React.Fragment>
                                    {part?.documents?.length > 0 &&
                                        part?.documents?.map((document: any, index: number) => {
                                            if (document) {
                                                return (
                                                    <Grid container key={index}>
                                                        <Grid item xs={12} key={document?._id}>
                                                            <Card className={classes.cardWhite}>
                                                                <Grid container spacing={0} justifyContent="space-between" direction="row" alignItems="center">
                                                                    <Grid item container md={4} sm={6} xs={6}>
                                                                        <Grid item xs={2}>
                                                                            <IconButton
                                                                                style={{
                                                                                    borderRadius: '50%',
                                                                                    padding: '8px',
                                                                                    backgroundColor: 'gray',
                                                                                    color: 'white',
                                                                                    marginRight: '8px',
                                                                                }}
                                                                            >
                                                                                <InsertDriveFile />
                                                                            </IconButton>
                                                                        </Grid>
                                                                        <Grid item container xs={10} style={{ paddingLeft: 10 }}>
                                                                            <Grid item xs={12}>
                                                                                <Typography color="textPrimary" className={classes.fileName}>
                                                                                    {document?.name ?? ''}
                                                                                </Typography>
                                                                            </Grid>
                                                                            <Grid item xs={12}>
                                                                                <Typography variant="caption" className={classes.fileSize}>
                                                                                    {!!document ? this.bytesForHuman(document?.size) : ''}
                                                                                </Typography>
                                                                            </Grid>
                                                                        </Grid>
                                                                    </Grid>
                                                                    <Grid item md={2} sm={2} xs={2}>
                                                                        <FormControl fullWidth className={classes.formControl} variant="outlined">
                                                                            <InputLabel id={`label-for-doc-type-${index}`}>Type</InputLabel>
                                                                            <Select
                                                                                variant="outlined"
                                                                                label="Type"
                                                                                labelId={`label-for-doc-type-${index}`}
                                                                                name={`document[${index}].docType`}
                                                                                id="documentType"
                                                                                placeholder="Type"
                                                                                fullWidth
                                                                                disabled
                                                                                value={document['type']}
                                                                                inputProps={{
                                                                                    className: classes.documentSelect,
                                                                                }}
                                                                            >
                                                                                <MenuItem key="MODEL_2D" value="MODEL_2D">
                                                                                    2D Model
                                                                                </MenuItem>
                                                                                <MenuItem key="MODEL_3D" value="MODEL_3D">
                                                                                    3D Model
                                                                                </MenuItem>
                                                                                <MenuItem key="SUPPORT_FILE" value="SUPPORT_FILE">
                                                                                    Support File
                                                                                </MenuItem>
                                                                            </Select>
                                                                        </FormControl>
                                                                    </Grid>
                                                                    <Grid item md={2} sm={2} xs={2}>
                                                                        <FormControl fullWidth className={classes.formControl} variant="outlined">
                                                                            <InputLabel id={`label-for-doc-perms-${index}`}>Permissions</InputLabel>
                                                                            <Select
                                                                                name={`document[${index}].visibility`}
                                                                                id="visibility"
                                                                                label="Permissions"
                                                                                labelId={`label-for-doc-type-${index}`}
                                                                                placeholder="Permissions"
                                                                                fullWidth
                                                                                disabled
                                                                                value={document['visibility']}
                                                                                inputProps={{
                                                                                    className: classes.documentSelect,
                                                                                }}
                                                                            >
                                                                                <MenuItem key="Buyer" value="Buyer">
                                                                                    Buyer Only
                                                                                </MenuItem>
                                                                                <MenuItem key="Supplier" value="Supplier">
                                                                                    Supplier Only
                                                                                </MenuItem>
                                                                                <MenuItem key="SyncFab" value="SyncFab">
                                                                                    Syncfab
                                                                                </MenuItem>
                                                                                <MenuItem key="All" value="All">
                                                                                    All Users
                                                                                </MenuItem>
                                                                            </Select>
                                                                        </FormControl>
                                                                    </Grid>
                                                                    <Grid container item md={2} xs={2} justifyContent={'flex-end'}>
                                                                        <IconButton
                                                                            aria-label="More"
                                                                            aria-owns={open ? 'long-menu' : undefined}
                                                                            aria-haspopup="true"
                                                                            onClick={e => this.handleClickDocumentOptions(e, document?._id)}
                                                                        >
                                                                            <MoreVertIcon />
                                                                        </IconButton>
                                                                        <Menu
                                                                            id="long-menu"
                                                                            anchorEl={anchorEl}
                                                                            open={this.state?.openDocumentMenuDocId === document?._id}
                                                                            onClose={this.handleCloseDocumentOptions}
                                                                            PaperProps={{
                                                                                style: {
                                                                                    maxHeight: ITEM_HEIGHT * 4.5,
                                                                                    width: 200,
                                                                                },
                                                                            }}
                                                                        >
                                                                            {options.map((option, index) => (
                                                                                <MenuItem
                                                                                    key={index}
                                                                                    onClick={() => {
                                                                                        this.handleCloseDocumentOptions()
                                                                                        this.handleDocumentOptionMenu(option, document?._id)
                                                                                    }}
                                                                                >
                                                                                    {option}
                                                                                </MenuItem>
                                                                            ))}
                                                                        </Menu>
                                                                    </Grid>
                                                                </Grid>
                                                            </Card>
                                                        </Grid>
                                                    </Grid>
                                                )
                                            }
                                            return null
                                        })}
                                    {part.documents && part.documents.length === 0 && (
                                        <Typography color="textPrimary" variant="h6">
                                            No documents found
                                        </Typography>
                                    )}
                                </React.Fragment>
                            </CardContent>
                        </Card>
                    </Grid>
                    <Grid item xs={12}>
                        <Card className={classes.card} style={{ marginLeft: '0px', marginBottom: '0px' }}>
                            <CardHeader
                                title={
                                    <Typography color="textPrimary" variant="h3">
                                        Part History
                                    </Typography>
                                }
                                className={classes.cardheader}
                            />
                            <CardContent>
                                {part?.history && part?.history?.length > 0 && (
                                    <Table className={classes.table}>
                                        <TableHead>
                                            <TableRow>
                                                <TableCell className={classes.tableCell}>Description</TableCell>
                                                <TableCell className={classes.tableCell}>Modified By</TableCell>
                                                <TableCell className={classes.tableCell}>Date</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {part?.history?.map((row, index) => {
                                                return (
                                                    <TableRow key={index} style={{ borderTop: '1px solid #b8b8b8' }}>
                                                        <TableCell className={classes.tableCell}>{row?.activityType ?? '-'}</TableCell>
                                                        <TableCell className={classes.tableCell}>{row?.activityBy?.userName ?? '-'}</TableCell>
                                                        <TableCell className={classes.tableCell}>{new Date(row?.activityAt)?.toLocaleDateString()}</TableCell>
                                                    </TableRow>
                                                )
                                            })}
                                        </TableBody>
                                    </Table>
                                )}

                                {part?.history && part?.history?.length == 0 && (
                                    <Typography color="textPrimary" variant="h6">
                                        No part history found
                                    </Typography>
                                )}
                            </CardContent>
                        </Card>
                    </Grid>
                </Grid>
                {pdfViewer && <PDFViewer open={pdfViewer} onClose={this.closePDFViewer} />}
            </Grid>
        )
    }
}

//export default EnhancedTableHead as any

export default withStyles(styles)(PartView as any)
