import { object, SchemaOf, string } from 'yup';

export type ValueLabelOption = {
  value: string;
  label: string;
};

export const valueLabelSchema: SchemaOf<ValueLabelOption> = object({
  value: string().required(),
  label: string().required(),
});

export const generateYesNoValueLabel = () => {
  return [
    {
      value: 'No',
      label: 'No',
    },
    {
      value: 'Yes',
      label: 'Yes',
    },
  ];
};

export const transformEntryToValueLabel = (
  entry,
  entryToValueFunc = e => e,
  entryToLabelFunc = e => e
) => {
  if (entry === undefined || entry === null) {
    return null;
  }
  return {
    value: entryToValueFunc(entry),
    label: entryToLabelFunc(entry),
  };
};

export const transformArrayEntryToValueLabel = (
  arrEntry,
  transformFunc = transformEntryToValueLabel
) => {
  if (arrEntry === null || arrEntry === undefined) return [];
  return arrEntry.map(entry => transformFunc(entry)).filter(e => !!e); // filter out null values
};

export const valueLabelSchemaConstrained: (
  constrain: SchemaOf<string>
) => SchemaOf<ValueLabelOption> = constrain =>
  object().shape({
    value: constrain.required(),
    label: string().required(),
  });

export const sortOptions = (
  options: ValueLabelOption[]
): ValueLabelOption[] => {
  return options.sort(valueLabelOptionSortFunction);
};

const valueLabelOptionSortFunction = (
  optionA: ValueLabelOption,
  optionB: ValueLabelOption
): 0 | 1 | -1 => {
  const labelA = optionA.label.toUpperCase();
  const labelB = optionB.label.toUpperCase();
  if (labelA < labelB) {
    return -1;
  }
  if (labelA > labelB) {
    return 1;
  }
  return 0;
};

export const generateOptionsFromEnum = (members: string[]) =>
  members.map(member => {
    return { value: member, label: member };
  });

export const toLowerSplit = entry => {
  return entry
    .split('_')
    .map(d => d.charAt(0) + d.slice(1).toLowerCase())
    .join(' ');
};

export const convertEnumToValueLabel = exampleEnum => {
  return Object.keys(exampleEnum).map(key => {
    return {
      label: key
        .split('_')
        .map(k => k.charAt(0) + k.toLowerCase().slice(1))
        .join(' '),
      value: exampleEnum[key],
    };
  });
};
