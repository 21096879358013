import React from 'react'
import SvgIcon from '@material-ui/core/SvgIcon'

export const BuyerIcon = props => {
    return (
        <SvgIcon {...props} viewBox="0 0 18 16.2" x="0px" y="0px" baseProfile="tiny" xmlSpace="preserve">
            <path
                id="domain_FILL1_wght400_GRAD0_opsz48"
                d="M5.35,22.2A1.344,1.344,0,0,1,4,20.85V7.35A1.344,1.344,0,0,1,5.35,6h6.075a1.344,1.344,0,0,1,1.35,1.35V9.713H20.65A1.344,1.344,0,0,1,22,11.063V20.85a1.344,1.344,0,0,1-1.35,1.35Zm0-1.35H7.713V18.487H5.35Zm0-3.712H7.713V14.775H5.35Zm0-3.712H7.713V11.063H5.35Zm0-3.713H7.713V7.35H5.35ZM9.063,20.85h2.362V18.487H9.063Zm0-3.712h2.362V14.775H9.063Zm0-3.712h2.362V11.063H9.063Zm0-3.713h2.362V7.35H9.063ZM12.775,20.85H20.65V11.063H12.775v2.363h1.8v1.35h-1.8v2.363h1.8v1.35h-1.8Zm4.163-6.075v-1.35h1.35v1.35Zm0,3.712v-1.35h1.35v1.35Z"
                transform="translate(-4 -6)"
                fill="#fff"
            />
        </SvgIcon>
    )
}
