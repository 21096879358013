import { Part, PartDAPP, FetchActions, createFetchActions, RFQ } from '@syncfab/machine'
import { ListMeta } from '../../layout/list/Meta'

export type SavePartPayload = {
    part: Partial<PartDAPP>
    rfq: RFQ
    documentId: string
    partId?: string
}

export type SavePartDAPPPayload = {
    part: Partial<PartDAPP>
    documentId?: string
    partId?: string
    rfqId?: string
}

export const SAVE_PART: FetchActions<SavePartDAPPPayload, PartDAPP> = createFetchActions('SAVE_PART')
export const UPDATE_PART: FetchActions<SavePartDAPPPayload, PartDAPP> = createFetchActions('UPDATE_PART')
export const GET_PARTS_BY_IDS: FetchActions<{ ids: string[] }, Part> = createFetchActions('GET_PARTS_BY_IDS')
export const FIND_PARTS_BY_IDS: FetchActions<{ ids: string[] }, Part> = createFetchActions('FIND_PARTS_BY_IDS') // HMD 20122019
export const GET_PARTS_FOR_DAPP: FetchActions<{ companyId: string; ids: string[] }, Part> = createFetchActions('GET_PARTS_FOR_DAPP')
export const RESET_DATA: FetchActions<void, number> = createFetchActions('RESET_DATA')
export const GET_PART_BY_ID: FetchActions<{ id: string }, Part> = createFetchActions('GET_PART_BY_ID')
export const SET_PARTS_FOR_TOKENIZATION: FetchActions<Part[], any> = createFetchActions('SET_PARTS_FOR_TOKENIZATION')
export const TOKENIZE_PARTS: FetchActions<any, any> = createFetchActions('TOKENIZE_PARTS')
export const TOKENIZE_FAILED_PARTS: FetchActions<any, any> = createFetchActions('TOKENIZE_FAILED_PARTS')
export const DELETE_PARTS_BY_IDS: FetchActions<{ ids: string[] }, boolean> = createFetchActions('DELETE_PARTS_BY_IDS')
export const SET_PART_FOR_EDIT: FetchActions<Part, any> = createFetchActions('SET_PART_FOR_EDIT')
export const EDIT_PART: FetchActions<{ id: string; request?: string }, {}> = createFetchActions('EDIT_PART')
export const GET_PART_HISTORY: FetchActions<{ id: string }, Part> = createFetchActions('GET_PART_HISTORY')
export const GET_ETHERNET_LINK: FetchActions<{}, any> = createFetchActions('GET_ETHERNET_LINK')
export const GET_PARTS: FetchActions<{ meta: ListMeta }, { data: Part[]; total: number }> = createFetchActions('GET_PARTS')
