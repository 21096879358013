import Table from '@material-ui/core/Table';
import { ListHeader } from './ListHeader';
import TableBody from '@material-ui/core/TableBody';
import TablePagination from '@material-ui/core/TablePagination';
import React, { FC, useState } from 'react';
import { ListMeta, ListResult, SortDirection } from '../BaseTypes';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import { IconButton, TextField, Theme, Tooltip } from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import CloseIcon from '@material-ui/icons/Close';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    tableWrapper: {
      'overflow-x': 'auto',
    },
    listBody: {
      backgroundColor: theme.palette.customComponent.listBackgroundColor,
    },
  })
);

interface ListSearchTermInputProps {
  initialValue: string;
  handleSearch: (string) => void;
  resetSearch: () => void;
  trimOnBlur?: boolean;
}

export const ListSearchTermInput: FC<ListSearchTermInputProps> = ({
  initialValue,
  handleSearch,
  resetSearch,
  trimOnBlur = true,
}: ListSearchTermInputProps) => {
  const [searchText, setSearchText] = useState<string>(initialValue ?? '');

  return (
    <TextField
      id="search"
      label="Search"
      name="search"
      type="text"
      variant="outlined"
      value={searchText}
      fullWidth
      onBlur={
        trimOnBlur
          ? event => {
              setSearchText(event.target.value?.trim());
            }
          : () => {}
      }
      onChange={event => {
        setSearchText(event.target.value);
      }}
      onKeyDown={event => {
        if (event.key === 'Enter') {
          handleSearch(searchText.trim());
        }
      }}
      InputProps={{
        endAdornment: (
          <>
            <Tooltip title="Search">
              <IconButton
                aria-label="Search"
                type="button"
                style={{ padding: 5 }}
                onClick={() => handleSearch(searchText.trim())}
              >
                <SearchIcon />
              </IconButton>
            </Tooltip>
            {!!searchText && (
              <IconButton
                type="button"
                style={{ padding: 5 }}
                onClick={() => {
                  setSearchText('');
                  resetSearch();
                }}
              >
                <CloseIcon />
              </IconButton>
            )}
          </>
        ),
      }}
    />
  );
};

export interface ListProps<T> {
  renderRow: (row: T, freezeFirstColumn: boolean) => JSX.Element;
  columns: { id: string; label: string; fixed?: boolean }[];
  data: ListResult<T>;
  meta: ListMeta;
  onMetaChange: (meta: ListMeta) => void;
  freezeFirstColumn?: boolean;
  rowsPerPage?: Array<number>;
}

export function List<T>(props: ListProps<T>) {
  const {
    rowsPerPage = [5, 10, 20],
    renderRow,
    columns,
    meta,
    onMetaChange,
    data,
    freezeFirstColumn = false,
  } = props;
  const classes = useStyles();

  const handleSort = (columnId: string) => {
    onMetaChange({
      ...meta,
      sortField: columnId,
      sortDirection:
        meta.sortField === columnId && meta.sortDirection === SortDirection.ASC
          ? SortDirection.DESC
          : SortDirection.ASC,
    });
  };

  const handlePageNumberChange = (page: number) => {
    onMetaChange({
      ...meta,
      page: page,
    });
  };

  const handlePageSizeChange = (size: number) => {
    onMetaChange({
      ...meta,
      size: size,
      page: 0,
    });
  };

  return (
    <>
      <div className={classes.tableWrapper}>
        <Table aria-labelledby="buyerRFQs" className={classes.listBody}>
          <ListHeader
            sortDirection={meta.sortDirection}
            sortField={meta.sortField}
            handleSort={handleSort}
            columns={columns}
            freezeFirstColumn={freezeFirstColumn}
          />
          <TableBody>
            {data.data.map((row: T) => {
              return renderRow(row, freezeFirstColumn);
            })}
          </TableBody>
        </Table>
      </div>
      <TablePagination
        rowsPerPageOptions={rowsPerPage}
        component="div"
        count={data.total}
        rowsPerPage={meta.size}
        page={meta.page}
        backIconButtonProps={{ 'aria-label': 'Previous Page' }}
        nextIconButtonProps={{ 'aria-label': 'Next Page' }}
        onPageChange={(_event, pageNumber) =>
          handlePageNumberChange(pageNumber)
        }
        onRowsPerPageChange={event =>
          handlePageSizeChange(Number(event.target.value))
        }
      />
    </>
  );
}
