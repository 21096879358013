import { Activity, UserIdAndName } from "../BaseTypes";
import { PurchaseOrderDetails, PurchaseOrderStatus } from "./PurchaseOrder";
import { PurchaseOrderLineItem } from "./PurchaseOrderLineItem";
import { Theme } from "@material-ui/core";
import { Document } from "../document";

export interface SupplierPurchaseOrderInput {
    revision: string
    issueDate?: Date
    selectedSupplier: {
        supplierId: string
        contactId: string
    }
    paymentTerms: string
    notes: string
    documentIds?: string[]
}

export interface SupplierPurchaseOrder extends SupplierPurchaseOrderInput {
    _id: string
    number: string
    selectedSupplier: {
        supplierId: string
        supplierName: string
        contactId: string
        contactName: string
    }
    poId: string
    poRevision: number
    documentIds?: string[]
    documents?: Document[]
    activity: Activity[]
    createdAt: Date
    createdBy: UserIdAndName
    updatedAt?: Date
    updatedBy?: UserIdAndName
    deletedAt?: Date
    deletedBy?: UserIdAndName
}

export interface SupplierQuote {
    _id?: string
    rfqId: string
    rfqLineItemIds: string[]
    rfqLineItemGroupIds: string[]
    rfqLineItemGroups?: { _id: string; name: string }[]
    supplierId: string
    supplierName: string
    rfqSentDate: Date
    subject: string
    quoteUploaded: boolean
    noQuote: boolean
    createdAt?: Date
    createdBy?: any
    document?: Document
    // verification?: VerificationList
    description?: string
    quoteUploadedDate?: Date
    quoteUploadedBy?: string
    // rfqMatchCriteria?: SupplierSearchCriteria
  }

export interface SupplierPurchaseOrderDetail extends SupplierPurchaseOrder {
    po: PurchaseOrderDetails
    poLineItem?: PurchaseOrderLineItem[]
    lineItem?: PurchaseOrderLineItem
    numberLines?: number
    completedLines?: number
    nextDeliveryDate?: Date
    poStatus: string
    supplierPOTotal?: number
    buyerPOTotal?: number
}

export const getSupplierPOStatusBackgroundColor = (status: PurchaseOrderStatus, theme: Theme): string => {
    switch (status) {
        case PurchaseOrderStatus.DRAFT:
            return theme.palette.error.main //yellow
        case PurchaseOrderStatus.ONHOLD:
        case PurchaseOrderStatus.CANCELED:
            return theme.palette.error.main //red
        case PurchaseOrderStatus.COMPLETED:
            return theme.palette.success.main // green
        case PurchaseOrderStatus.INPROGRESS:
            return theme.palette.warning.main
        default:
            return theme.palette.grey[600]
        }
    }