import axios, { AxiosPromise } from 'axios'
import { DocumentType, Environment, EnvironmentVariable, AuthorizationService } from '@syncfab/machine'

export class DocumentAPI {
    constructor(private environment: Environment, private authorizationService: AuthorizationService) {}

    public getCompanyDocuments = (): (() => AxiosPromise) => {
        const url = this.environment.get(EnvironmentVariable.API_LAYER_URL)
        const authorization = `Bearer ${this.authorizationService.getAuthToken()}`
        return () => {
            return axios.get(`${url}/documents`, {
                headers: {
                    authorization,
                },
            })
        }
    }

    public download = (id: string): (() => AxiosPromise) => {
        const url = this.environment.get(EnvironmentVariable.API_LAYER_URL)
        const authorization = `Bearer ${this.authorizationService.getAuthToken()}`
        return () => {
            return axios.get(`${url}/documents/downloadForBuyer/${id}`, {
                headers: {
                    authorization,
                },
                responseType: 'blob',
            })
        }
    }

    public downloadByType = (type: DocumentType): (() => AxiosPromise) => {
        const url = this.environment.get(EnvironmentVariable.API_LAYER_URL)
        const authorization = `Bearer ${this.authorizationService.getAuthToken()}`
        return () => {
            return axios.get(`${url}/documents/download/types/${type}`, {
                headers: {
                    authorization,
                },
                responseType: 'blob',
            })
        }
    }

    public deleteByType = (type: DocumentType): (() => AxiosPromise) => {
        const url = this.environment.get(EnvironmentVariable.API_LAYER_URL)
        const authorization = `Bearer ${this.authorizationService.getAuthToken()}`
        return () => {
            return axios.delete(`${url}/documents/types/${type}`, {
                headers: {
                    authorization,
                },
            })
        }
    }

    public update = (id: string, type: string, visibility: string): (() => AxiosPromise) => {
        const url = this.environment.get(EnvironmentVariable.API_LAYER_URL)
        const authorization = `Bearer ${this.authorizationService.getAuthToken()}`
        return () =>
            axios.patch(
                `${url}/documents/${id}`,
                { type, visibility },
                {
                    headers: {
                        authorization,
                    },
                }
            )
    }
}
