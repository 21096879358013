import { ListParameters } from "../BaseTypes"
export const ItemTypeIndicatorMap = new Map<string, string>([

    ['1', 'NSN'],
    ['2', 'Part Number'],
])

export const ItemTypeIndicatorOptions = [...ItemTypeIndicatorMap.keys()].map(d => {
    return{
        value: d,
        label: ItemTypeIndicatorMap.get(d),
    }
});

export const SmallBusinessSetAsideIndicatorMap = new Map<string, string>([
    ['Y', 'Small Business'],
    ['H', 'HubZone'],
    ['R', 'Disabled Veteran Business'],
    ['L', 'Woman Owned Business'],
    ['E', 'Econ. Disadvantaged Women Owned Sm Business'],
    ['C', 'Combined'],
    ['N', 'No Set Aside'],
])

export enum SmallBusinessSetAsideEnum {
    'Y' = 'Y',
    'H' = 'H',
    'R' = 'R',
    'L' = 'L',
    'E' = 'E',
    'C' = 'C',
    'N' = 'N',
}

export const SmallBusinessSetAsideOptions = [...SmallBusinessSetAsideIndicatorMap.keys()].map(d => {
    return{
        value: d,
        label: `${d} - ${SmallBusinessSetAsideIndicatorMap.get(d)}`,
    }
});

export const InspectionPointIndicatorMap = new Map<string, string>([
    ['D', 'Destination'],
    ['O', 'Origin'],
])

export interface PriceHistory {
    cageCode: string
    contractNumber: string
    quantity: number
    unitCost: number
    awardDate: Date
}

export enum DLATimeFrameEnum {
    'TODAY' = 'TODAY',
    '3_DAYS' = '3_DAYS',
    '7_DAYS' = '7_DAYS',
    '15_DAYS' = '15_DAYS',
    '30_DAYS' = '30_DAYS',
};
export const DLATimeFrameOptions = [
    { value: 'TODAY', label: 'Today' },
    { value: '3_DAYS', label: 'Last 3 Days'},
    { value: '7_DAYS', label: 'Last 7 Days'},
    { value: '15_DAYS', label: 'Last 15 Days'},
    { value: '30_DAYS', label: 'Last 30 Days'},
];

export interface DLAListParameters extends ListParameters {
    fromDate?: DLATimeFrameEnum 
    setAside?: keyof typeof SmallBusinessSetAsideOptions
}

export interface DLASolicitation {
    _id?: string
    solicitationNumber: string
    solicitationDate: Date
    filename: string
    nsn: string
    createdAt: Date
    purchaseRequestNumber: string[]
    returnByDate: Date
    quantity: number
    unitIssue: string
    description: string
    buyerCode: string
    acquisitionMethodSuffixCode: string
    itemTypeIndicator: string
    smallBusinessSetAsideIndicator: string
    smallBusinessSetAsidePercent: number
    itemDescriptionIndicator?: string
    inspectionPoint?: string
    fobPoint?: string
    deliveryDays?: number
    jcpRequired?: boolean
    qaCertificationRequired?: boolean
    naicsCode?: string
    priceHistory?: PriceHistory[]
    solicitationDocumentId?: string
    state: DLAState
}

export const enum SolicitationActionMenuOption {
    CREATE_RFQ = 'Create RFQ',
    VIEW_TECHDOCS = 'View Tech Drawings',
    NO_QUOTE_RFQ = 'No Quote',
}

export enum DLAState {
    'NO_QUOTE' = 'NO_QUOTE',
    'RFQ_CREATED' = 'RFQ_CREATED',
    'NEW' = 'NEW',
  }
export const DLAStateOptions = [
    { value: DLAState.NO_QUOTE, label: 'No Quote' },
    { value: DLAState.NEW, label: 'New' },
    { value: DLAState.RFQ_CREATED, label: 'RFQ Created' },

] 

export const DLAStateMap = {
    [DLAState.NEW]: 'New',
    [DLAState.NO_QUOTE]: 'No Quote',
    [DLAState.RFQ_CREATED]: 'RFQ Created',
}
