"use strict";

exports.__esModule = true;
exports["default"] = exports.UPDATE_SYNC_WARNINGS = exports.UPDATE_SYNC_ERRORS = exports.UNTOUCH = exports.UNREGISTER_FIELD = exports.TOUCH = exports.SUBMIT = exports.STOP_SUBMIT = exports.STOP_ASYNC_VALIDATION = exports.START_SUBMIT = exports.START_ASYNC_VALIDATION = exports.SET_SUBMIT_SUCCEEDED = exports.SET_SUBMIT_FAILED = exports.RESET_SECTION = exports.RESET = exports.REGISTER_FIELD = exports.INITIALIZE = exports.FOCUS = exports.DESTROY = exports.CLEAR_ASYNC_ERROR = exports.CLEAR_SUBMIT_ERRORS = exports.CLEAR_SUBMIT = exports.CLEAR_FIELDS = exports.CHANGE = exports.BLUR = exports.AUTOFILL = exports.ARRAY_SWAP = exports.ARRAY_UNSHIFT = exports.ARRAY_SPLICE = exports.ARRAY_SHIFT = exports.ARRAY_REMOVE_ALL = exports.ARRAY_REMOVE = exports.ARRAY_PUSH = exports.ARRAY_POP = exports.ARRAY_MOVE = exports.ARRAY_INSERT = exports.prefix = void 0;
var prefix = '@@redux-form/';
exports.prefix = prefix;
var ARRAY_INSERT = prefix + "ARRAY_INSERT";
exports.ARRAY_INSERT = ARRAY_INSERT;
var ARRAY_MOVE = prefix + "ARRAY_MOVE";
exports.ARRAY_MOVE = ARRAY_MOVE;
var ARRAY_POP = prefix + "ARRAY_POP";
exports.ARRAY_POP = ARRAY_POP;
var ARRAY_PUSH = prefix + "ARRAY_PUSH";
exports.ARRAY_PUSH = ARRAY_PUSH;
var ARRAY_REMOVE = prefix + "ARRAY_REMOVE";
exports.ARRAY_REMOVE = ARRAY_REMOVE;
var ARRAY_REMOVE_ALL = prefix + "ARRAY_REMOVE_ALL";
exports.ARRAY_REMOVE_ALL = ARRAY_REMOVE_ALL;
var ARRAY_SHIFT = prefix + "ARRAY_SHIFT";
exports.ARRAY_SHIFT = ARRAY_SHIFT;
var ARRAY_SPLICE = prefix + "ARRAY_SPLICE";
exports.ARRAY_SPLICE = ARRAY_SPLICE;
var ARRAY_UNSHIFT = prefix + "ARRAY_UNSHIFT";
exports.ARRAY_UNSHIFT = ARRAY_UNSHIFT;
var ARRAY_SWAP = prefix + "ARRAY_SWAP";
exports.ARRAY_SWAP = ARRAY_SWAP;
var AUTOFILL = prefix + "AUTOFILL";
exports.AUTOFILL = AUTOFILL;
var BLUR = prefix + "BLUR";
exports.BLUR = BLUR;
var CHANGE = prefix + "CHANGE";
exports.CHANGE = CHANGE;
var CLEAR_FIELDS = prefix + "CLEAR_FIELDS";
exports.CLEAR_FIELDS = CLEAR_FIELDS;
var CLEAR_SUBMIT = prefix + "CLEAR_SUBMIT";
exports.CLEAR_SUBMIT = CLEAR_SUBMIT;
var CLEAR_SUBMIT_ERRORS = prefix + "CLEAR_SUBMIT_ERRORS";
exports.CLEAR_SUBMIT_ERRORS = CLEAR_SUBMIT_ERRORS;
var CLEAR_ASYNC_ERROR = prefix + "CLEAR_ASYNC_ERROR";
exports.CLEAR_ASYNC_ERROR = CLEAR_ASYNC_ERROR;
var DESTROY = prefix + "DESTROY";
exports.DESTROY = DESTROY;
var FOCUS = prefix + "FOCUS";
exports.FOCUS = FOCUS;
var INITIALIZE = prefix + "INITIALIZE";
exports.INITIALIZE = INITIALIZE;
var REGISTER_FIELD = prefix + "REGISTER_FIELD";
exports.REGISTER_FIELD = REGISTER_FIELD;
var RESET = prefix + "RESET";
exports.RESET = RESET;
var RESET_SECTION = prefix + "RESET_SECTION";
exports.RESET_SECTION = RESET_SECTION;
var SET_SUBMIT_FAILED = prefix + "SET_SUBMIT_FAILED";
exports.SET_SUBMIT_FAILED = SET_SUBMIT_FAILED;
var SET_SUBMIT_SUCCEEDED = prefix + "SET_SUBMIT_SUCCEEDED";
exports.SET_SUBMIT_SUCCEEDED = SET_SUBMIT_SUCCEEDED;
var START_ASYNC_VALIDATION = prefix + "START_ASYNC_VALIDATION";
exports.START_ASYNC_VALIDATION = START_ASYNC_VALIDATION;
var START_SUBMIT = prefix + "START_SUBMIT";
exports.START_SUBMIT = START_SUBMIT;
var STOP_ASYNC_VALIDATION = prefix + "STOP_ASYNC_VALIDATION";
exports.STOP_ASYNC_VALIDATION = STOP_ASYNC_VALIDATION;
var STOP_SUBMIT = prefix + "STOP_SUBMIT";
exports.STOP_SUBMIT = STOP_SUBMIT;
var SUBMIT = prefix + "SUBMIT";
exports.SUBMIT = SUBMIT;
var TOUCH = prefix + "TOUCH";
exports.TOUCH = TOUCH;
var UNREGISTER_FIELD = prefix + "UNREGISTER_FIELD";
exports.UNREGISTER_FIELD = UNREGISTER_FIELD;
var UNTOUCH = prefix + "UNTOUCH";
exports.UNTOUCH = UNTOUCH;
var UPDATE_SYNC_ERRORS = prefix + "UPDATE_SYNC_ERRORS";
exports.UPDATE_SYNC_ERRORS = UPDATE_SYNC_ERRORS;
var UPDATE_SYNC_WARNINGS = prefix + "UPDATE_SYNC_WARNINGS";
exports.UPDATE_SYNC_WARNINGS = UPDATE_SYNC_WARNINGS;
var _default = {
  ARRAY_INSERT: ARRAY_INSERT,
  ARRAY_MOVE: ARRAY_MOVE,
  ARRAY_POP: ARRAY_POP,
  ARRAY_PUSH: ARRAY_PUSH,
  ARRAY_REMOVE: ARRAY_REMOVE,
  ARRAY_REMOVE_ALL: ARRAY_REMOVE_ALL,
  ARRAY_SHIFT: ARRAY_SHIFT,
  ARRAY_SPLICE: ARRAY_SPLICE,
  ARRAY_UNSHIFT: ARRAY_UNSHIFT,
  ARRAY_SWAP: ARRAY_SWAP,
  AUTOFILL: AUTOFILL,
  BLUR: BLUR,
  CHANGE: CHANGE,
  CLEAR_FIELDS: CLEAR_FIELDS,
  CLEAR_SUBMIT: CLEAR_SUBMIT,
  CLEAR_SUBMIT_ERRORS: CLEAR_SUBMIT_ERRORS,
  CLEAR_ASYNC_ERROR: CLEAR_ASYNC_ERROR,
  DESTROY: DESTROY,
  FOCUS: FOCUS,
  INITIALIZE: INITIALIZE,
  REGISTER_FIELD: REGISTER_FIELD,
  RESET: RESET,
  RESET_SECTION: RESET_SECTION,
  SET_SUBMIT_FAILED: SET_SUBMIT_FAILED,
  SET_SUBMIT_SUCCEEDED: SET_SUBMIT_SUCCEEDED,
  START_ASYNC_VALIDATION: START_ASYNC_VALIDATION,
  START_SUBMIT: START_SUBMIT,
  STOP_ASYNC_VALIDATION: STOP_ASYNC_VALIDATION,
  STOP_SUBMIT: STOP_SUBMIT,
  SUBMIT: SUBMIT,
  TOUCH: TOUCH,
  UNREGISTER_FIELD: UNREGISTER_FIELD,
  UNTOUCH: UNTOUCH,
  UPDATE_SYNC_ERRORS: UPDATE_SYNC_ERRORS,
  UPDATE_SYNC_WARNINGS: UPDATE_SYNC_WARNINGS
};
exports["default"] = _default;