"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

exports.__esModule = true;
exports["default"] = createIsValid;

var _hasError = _interopRequireDefault(require("../hasError"));

function createIsValid(structure) {
  var getIn = structure.getIn,
      keys = structure.keys;
  var hasError = (0, _hasError["default"])(structure);
  return function (form, getFormState, ignoreSubmitErrors) {
    if (ignoreSubmitErrors === void 0) {
      ignoreSubmitErrors = false;
    }

    return function (state) {
      var nonNullGetFormState = getFormState || function (state) {
        return getIn(state, 'form');
      };

      var formState = nonNullGetFormState(state);
      var syncError = getIn(formState, form + ".syncError");

      if (syncError) {
        return false;
      }

      if (!ignoreSubmitErrors) {
        var error = getIn(formState, form + ".error");

        if (error) {
          return false;
        }
      }

      var syncErrors = getIn(formState, form + ".syncErrors");
      var asyncErrors = getIn(formState, form + ".asyncErrors");
      var submitErrors = ignoreSubmitErrors ? undefined : getIn(formState, form + ".submitErrors");

      if (!syncErrors && !asyncErrors && !submitErrors) {
        return true;
      }

      var registeredFields = getIn(formState, form + ".registeredFields");

      if (!registeredFields) {
        return true;
      }

      return !keys(registeredFields).filter(function (name) {
        return getIn(registeredFields, "['" + name + "'].count") > 0;
      }).some(function (name) {
        return hasError(getIn(registeredFields, "['" + name + "']"), syncErrors, asyncErrors, submitErrors);
      });
    };
  };
}