import React from 'react'
import { Field, reduxForm } from 'redux-form/immutable'
import { InjectedFormProps } from 'redux-form'
import { Map } from 'immutable'
import { withStyles, WithStyles } from '@material-ui/core/styles'
import { withNamespaces, WithNamespaces } from 'react-i18next'
import { FormTextField, Button, jumpToFirstError } from '@syncfab/machine'
import { requiredEmailField } from '../../../utils/Validators'

export const ForgotPasswordFormName: string = 'ForgotPasswordForm'

const styles = theme => ({
    form: {
        display: 'flex',
        'flex-direction': 'column',
        maxWidth: 600,
        minWidth: 300,
        margin: 'auto',
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(1),
    },
    action: {
        marginBottom: theme.spacing(1),
    },
    formInput: {
        '& .MuiFormControl-marginNormal': {
            margin: 0,
        },
    },
})

type ForgotPasswordFields = {
    email?: string
}

const validate = (data: Map<string, string>): {} => {
    const errors: ForgotPasswordFields = {}
    errors.email = requiredEmailField(data.get('email'))
    return errors
}

const form = (props: InjectedFormProps<{}, {}> & WithStyles & WithNamespaces) => {
    const { handleSubmit, submitting, t, classes } = props
    return (
        <form onSubmit={handleSubmit} className={classes.form}>
            <Field name="email" component={FormTextField} type="text" placeholder={t('forget-password-email')} className={classes.formInput} fullWidth />
            <Button type="submit" color="primary" disabled={submitting} className={classes.action} fullWidth>
                {t('forget-password-continue')}
            </Button>
        </form>
    )
}

const translatedForm = withNamespaces()(withStyles(styles)(form))
export const ForgotPasswordForm = reduxForm<{}, {}>({
    form: ForgotPasswordFormName,
    validate,
    onSubmitFail: jumpToFirstError,
})(translatedForm)
