import 'date-fns'
import React from 'react'
import { FormControl, InputAdornment } from '@material-ui/core'
import { Lock } from '@material-ui/icons'
import { FormFieldFooter } from '../Error'
import DateFnsUtils from '@date-io/date-fns'
import { MuiPickersUtilsProvider, DatePicker } from '@material-ui/pickers'

export const DatePickerField = ({ input, label, meta: { touched, error }, fullWidth, className, disabled, description, InputProps, minDate }: any) => {
    let inputProps = InputProps
    if (disabled && !InputProps) {
        inputProps = {
            endAdornment: (
                <InputAdornment position="end">
                    <Lock />
                </InputAdornment>
            ),
        }
    }
    return (
        <FormControl fullWidth={fullWidth} className={className}>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <DatePicker
                    margin="normal"
                    inputVariant="filled"
                    label={label}
                    value={input.value || null}
                    onChange={date => input.onChange(date)}
                    error={!!touched && !!error}
                    InputProps={inputProps}
                    minDate={minDate}
                    format="MM/dd/yyyy"
                    disabled={disabled}
                    onBlur={input.onBlur}
                    clearable
                />
            </MuiPickersUtilsProvider>
            <FormFieldFooter touched={touched} error={error} description={description} />
        </FormControl>
    )
}
