import React from 'react'
import { Helmet } from 'react-helmet'
import { withNamespaces, WithNamespaces } from 'react-i18next'
import { WithStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import { User, Button, EmailSent } from '@syncfab/machine'
import { Paper, withStyles } from '@material-ui/core'
import { CenteredContainer } from '../../layout/Container'
import { connect } from 'react-redux'
import { Dispatch } from 'redux'
import { State } from '../../application/Store'
import { RESEND_VALIDATION } from './Actions'

const styles = theme => ({
    paper: {
        padding: theme.spacing(6),
        display: 'flex',
        'flex-direction': 'column',
        'justify-content': 'center',
        alignItems: 'center',
        width: 400,
        'text-align': 'center',
    },
    text: {
        padding: theme.spacing(2),
        color: theme.palette.common.white,
        marginBottom: theme.spacing(2),
    },
    link: {
        width: '100%',
    },
    sentIcon: {
        marginLeft: -16,
    },
})

interface SignupConfirmationProps extends WithNamespaces, WithStyles {
    loggedUser?: User
    resend: (userId: string) => void
}

// TODO(JR): Unify this view
class SignupConfirmationView extends React.Component<SignupConfirmationProps> {
    constructor(props: SignupConfirmationProps) {
        super(props)
        this.onResend = this.onResend.bind(this)
    }

    onResend() {
        const { userId } = this.props.loggedUser as any
        this.props.resend(userId)
    }

    public render() {
        const { classes, t } = this.props
        return (
            <CenteredContainer>
                <Helmet>
                    <title>{`${t('signup-confirmation-title')}`}</title>
                </Helmet>
                <Paper className={classes.paper} elevation={1}>
                    <EmailSent className={classes.sentIcon} />
                    <Typography className={classes.text} variant="h5">
                        {`${t('signup-confirmation-content')}`}
                    </Typography>
                    <Button color="primary" variant="outlined" fullWidth onClick={this.onResend}>
                        {t('signup-confirmation-resend')}
                    </Button>
                </Paper>
            </CenteredContainer>
        )
    }
}

const SignupConfirmationViewWithTranslations = withNamespaces()(withStyles(styles)(SignupConfirmationView))

const mapStateToProps = ({ user: { loggedUser } }: State) => {
    return {
        loggedUser,
    }
}

const mapDispatchToProps = (dispatch: Dispatch) => {
    return {
        resend: (userId: string) => dispatch(RESEND_VALIDATION(userId)),
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(SignupConfirmationViewWithTranslations)
