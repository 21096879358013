import React from 'react'
import SvgIcon from '@material-ui/core/SvgIcon'

export const Logo = props => {
    return (
        <SvgIcon {...props} viewBox="0 0 180 24" x="0px" y="0px" baseProfile="tiny" xmlSpace="preserve" style={{ width: '180px' }}>
            <path
                id="Hex"
                fill="#fff"
                d="M9.7.2,1.2,5A3.5,3.5,0,0,0,0,7.3v9.4a2.6,2.6,0,0,0,1.4,2.2l5,2.9,10.9-5.9a3.9,3.9,0,0,0,1.2-5.5,3.7,3.7,0,0,0-3.1-1.8,4.6,4.6,0,0,0-2,.5L7.9,12l.8,1.5-3.8-1L6,8.7l.9,1.5,5.5-2.9a3.6,3.6,0,0,1,1.7-.7h1.3a6.4,6.4,0,0,1,5.4,2.9,6.4,6.4,0,0,1,.5,4.5,5.7,5.7,0,0,1-3,3.6L8.5,23l1.3.7a2.8,2.8,0,0,0,2.5,0l8.5-4.8a2.5,2.5,0,0,0,1.3-2.2V7.3A2.6,2.6,0,0,0,20.8,5L15.5,2.1,4.8,8a3.9,3.9,0,0,0-1.6,5.3,4.2,4.2,0,0,0,3.6,1.9,2.4,2.4,0,0,0,1.8-.5l5.7-3-.9-1.5,3.9,1L16.1,15l-.9-1.5L9.6,16.6a5.8,5.8,0,0,1-2.8.7,6.3,6.3,0,0,1-5.4-3A5.7,5.7,0,0,1,.9,9.7,5.9,5.9,0,0,1,3.7,6.1L13.4.9A5,5,0,0,0,11.1,0L9.7.2"
            />
            <path
                id="letterY"
                fill="#fff"
                d="M29.1,20.7c1.8-.2,1.6-2,1.6-3.5a3.8,3.8,0,0,1-2.4.7c-6.2.3-3.9-8-4.3-11.7h2.1v7c0,2,.8,2.6,2.2,2.6s2.4-.5,2.4-2.6-.9-6.7,2.5-7.3L34,7.6a1.5,1.5,0,0,0-1.2,1.6V19c0,1.8-.7,3-2.9,3.4Z"
            />
            <path id="letterN" fill="#fff" d="M41.4,17.6V10.2c0-2.3-2.4-2.7-4.4-1.7v9.1H34.9V6.2h2v.3c2.3-1.2,6.8-.5,6.6,3.2v7.9Z" />
            <path id="letterC" fill="#fff" d="M52.4,17.5c-3.4.8-7.8.5-7.6-4s1.5-9.2,7.5-6.8l-.6,1.7c-5.3-1.8-4.9,1.9-4.8,5.3s3.3,2.3,4.9,1.8Z" />
            <polygon id="letterF" fill="#fff" points="53.7 17.6 53.7 1.6 61.6 1.6 61.6 3.7 55.9 3.7 55.9 8.2 59.4 8.2 58.7 10.2 55.9 10.2 55.9 17.6 53.7 17.6" />
            <path
                id="letterA"
                fill="#fff"
                d="M69.6,17.6c-3.7.3-9.1,1-9.1-3.9V11.8c-.2-4.1,4.9-3.8,6.9-2.5,0-1.3-1-1.9-2.9-1.9L64.7,6c4.9,0,4.9,2.6,4.9,4.8Zm-2.2-6.2c-1.2-.7-4.7-1.8-4.7.7s.5,4.3,4.7,3.7Z"
            />
            <path id="letterB" fill="#fff" d="M79.8,14c-.4,4.7-4.8,3.9-8.7,3.6V1.6h2.2V6.4c2.2-1,6.7-.4,6.5,3.3Zm-2.1-3.8c0-2.8-3.4-2.4-4.4-1.7v7.3C78.6,16.6,77.6,14,77.7,10.2Z" />
        </SvgIcon>
    )
}
