import axios, { AxiosPromise } from 'axios'
import { AuthorizationService, Environment, EnvironmentVariable } from '@syncfab/machine'

export class BlockchainAPI {
    constructor(private environment: Environment, private authorizationService: AuthorizationService) {}

    public get = (id: string): (() => AxiosPromise) => {
        const url = this.environment.get(EnvironmentVariable.API_LAYER_URL)
        const authorization = `Bearer ${this.authorizationService.getAuthToken()}`
        return () => {
            return axios.get(`${url}/blockchain/${id}`, {
                headers: {
                    authorization,
                },
            })
        }
    }
}
