import { IconButton, Menu, MenuItem } from '@material-ui/core';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import React, { useState } from 'react';

interface MenuOptionsObject {
  option: string;
  disabled: boolean;
}
interface ActionMenuProps {
  menuOptions: Array<MenuOptionsObject | string>;
  itemKey: string;
  handleMenuOptionClick: (option: string, key: string) => void;
  style?: Object;
}

export const ActionMenu = ({
  menuOptions,
  itemKey,
  handleMenuOptionClick,
  style = {},
}: ActionMenuProps) => {
  const [anchorEl, setAnchorEl] = useState<Element>(null);

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <IconButton
        aria-label="More"
        aria-owns={open ? 'long-menu' : undefined}
        aria-haspopup="true"
        onClick={event => {
          setAnchorEl(event.currentTarget);
        }}
        style={style}
      >
        <MoreVertIcon />
      </IconButton>
      <Menu
        id="long-menu"
        anchorEl={anchorEl}
        open={!!anchorEl}
        onClose={() => handleMenuClose()}
        PaperProps={{
          style: {
            maxHeight: 48 * 4.5,
            width: 200,
          },
        }}
      >
        {menuOptions.map((option, index) => {
          let displayOption = '';
          let disabled = false;
          if (typeof option === 'string') {
            displayOption = option;
            disabled = false;
          } else {
            displayOption = option.option;
            disabled = option.disabled;
          }
          return (
            <MenuItem
              disabled={disabled}
              key={index}
              onClick={() => {
                handleMenuClose();
                handleMenuOptionClick(displayOption, itemKey);
              }}
            >
              {displayOption}
            </MenuItem>
          );
        })}
      </Menu>
    </>
  );
};
