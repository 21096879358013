import React from 'react'
import { Field, reduxForm } from 'redux-form/immutable'
import { InjectedFormProps } from 'redux-form'
import { Map } from 'immutable'
import {withStyles, WithStyles} from '@material-ui/core/styles'
import { withNamespaces, WithNamespaces } from 'react-i18next'
import { FormTextField, Button, jumpToFirstError, FormPasswordField } from '@syncfab/machine'
import { requiredEmailField, requiredPasswordField } from '../../../utils/Validators'

export const UpdatePasswordFormName: string = 'UpdatePasswordForm'

const styles = theme => ({
    form: {
        display: 'flex',
        'flex-direction': 'column',
        maxWidth: 600,
        minWidth: 300,
        margin: 'auto',
    },
    action: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
    },
})

type UpdatePasswordFields = {
    email?: string
    password?: string
    confirmPassword?: string
}

const validate = (data: Map<string, string>): {} => {
    const errors: UpdatePasswordFields = {}
    const password = data.get('password')
    const confirmPassword = data.get('confirmPassword')
    errors.email = requiredEmailField(data.get('email'))
    errors.password = requiredPasswordField(password)
    errors.confirmPassword = requiredPasswordField(confirmPassword)

    if (!!password && !!confirmPassword && password !== confirmPassword) {
        errors.confirmPassword = 'Password should match'
    }

    return errors
}

const form = (props: InjectedFormProps<{}, {}> & WithStyles & WithNamespaces) => {
    const { handleSubmit, submitting, t, classes } = props
    return (
        <form onSubmit={handleSubmit} className={classes.form}>
            <Field name="email" component={FormTextField} type="text" label={t('user-email')} />
            <Field name="password" component={FormPasswordField as any} label={t('update-password-new-password')} />
            <Field name="confirmPassword" component={FormPasswordField as any} label={t('update-password-confirm-password')} />
            <Button type="submit" color="primary" disabled={submitting} className={classes.action} fullWidth>
                {t('form-action-update')}
            </Button>
        </form>
    )
}

const translatedForm = withNamespaces()(withStyles(styles)(form))
export const UpdatePasswordForm = reduxForm<{}, {}>({
    form: UpdatePasswordFormName,
    validate,
    onSubmitFail: jumpToFirstError,
})(translatedForm)
