import React from 'react'
import _ from 'lodash-es'
import { makeStyles } from '@material-ui/core'
import { Button } from '@syncfab/machine'
import ListItem from '@material-ui/core/ListItem'
import List from '@material-ui/core/List'
import { Typography, IconButton } from '@material-ui/core'
import { AutoCompleteValueLabel } from '@syncfab/machine'
import CloseIcon from '@material-ui/icons/Close'
import { useAppSelector } from '../../../../application/hooks'
import { WithNamespaces, withNamespaces } from 'react-i18next'
const styles = theme => ({
    detailcard: {
        width: '-webkit-fill-available',
        height: 'fit-content',
        margin: `${theme.spacing(3)}px 12px`,
        backgroundColor: theme.palette.common.white,
        marginTop: '100px',
        marginLeft: '24px',
        marginRight: '24px',
    },
    noTokenID: {
        marginLeft: theme.spacing(3),
        'font-weight': 'bold',
    },
    marginTextRight: {
        marginRight: theme.spacing(2),
    },
    refresh: {
        color: '#40B4BF',
    },
    refreshIcon: {
        marginLeft: theme.spacing(1),
    },
    clearIcon: {
        marginLeft: theme.spacing(1),
    },
    error: {
        backgroundColor: theme.palette.error.light,
    },
    root: {
        color: theme.palette.text.secondary,
        background: '#ffffff',
        padding: theme.spacing(4),
        marginTop: theme.spacing(4),
        marginBottom: theme.spacing(4),
        minWidth: 300,
    },
    table: {
        minWidth: 650,
        backgroundColor: theme.palette.background.paper,
    },
    tableBody: {
        fontSize: 14,
    },
    spacer: {
        flex: '1 1 100%',
    },
    actions: {
        color: theme.palette.text.secondary,
        marginTop: '7px',
        marginRight: '10px',
    },
    title: {
        flex: '0 0 auto',
    },
    checkbox: {
        color: '#363636',
    },
    button: {
        margin: theme.spacing(1),
    },
    paper: {
        width: '100%',
        margin: `${theme.spacing(3)}px 12px`,
        backgroundColor: theme.palette.background.paper,
    },
    header: {
        padding: `0px ${theme.spacing(1)}px`,
    },
    testLink: {
        cursor: 'pointer',
    },
    textField: {
        marginLeft: theme.spacing(3),
        marginRight: theme.spacing(1),
        width: 400,
    },
    row: {
        padding: '20px',
        alignItems: 'center',
    },
    goBackButton: {
        margin: 0,
        padding: 0,
        'text-transform': 'none',
        color: theme.palette.primary.light,
        fontWeight: theme.typography.fontWeightRegular,
        'font-size': '12',
    },
    arrow: {
        color: theme.palette.common.white,
        marginRight: theme.spacing(1),
    },
    icon: {
        fontSize: 20,
        color: theme.palette.common.white,
        marginRight: theme.spacing(2),
    },
    iconVariant: {
        opacity: 0.9,
        marginRight: theme.spacing * 1,
    },
    message: {
        display: 'flex',
        alignItems: 'center',
    },
    btnclass: {
        color: theme.palette.common.white,
        marginLeft: theme.spacing(3),
        fontSize: 10,
        borderColor: theme.palette.common.white,
    },
    listItem: {
        display: 'flex',
        'flex-direction': 'column',
        alignItems: 'flex-start',
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
    },
    btnWrapper: {
        display: 'flex',
        alignItems: 'baseline',
        justifyContent: 'flex-end',
        [theme.breakpoints.down('md')]: {
            justifyContent: 'flex-start',
        },
    },
    formControl: {
        'border-radius': '5px',
    },
    rootSelect: {
        color: '#000',
    },
    tableWrapper: {
        'overflow-x': 'auto',
    },
    clearBtn: {
        fontSize: 14,
        color: '#40B4BF',
        background: 'transparent',
        'box-shadow': 'none',
        minWidth: '100px',
        marginRight: 10,
        '&:hover': {
            background: 'transparent',
            'box-shadow': 'none',
        },
        '&:focus': {
            background: 'transparent',
            'box-shadow': 'none',
        },
    },
    downloadWrapper: {
        width: '140px',
        display: 'flex',
        'justify-content': 'flex-start',
    },
    csvBtn: {
        background: 'transparent',
        width: '100%',
        height: '100%',
        left: 0,
        top: 0,
        padding: '10px 0 0 35px',
        color: '#40B4BF',
        'text-decoration': 'none',
    },
    new: {
        backgroundColor: theme.palette.secondary.main,
    },
    active: {
        backgroundColor: theme.palette.success.main,
    },
    deactived: {
        backgroundColor: theme.palette.grey[600],
    },
    closeIcon: {
        padding: 0,
    },
    searchInput: {
        '& :-webkit-autofill': {
            '-webkit-text-fill-color': '#000',
        },
    },
    compactRow: {
        paddingBottom: '10px',
        paddingTop: '10px',
        borderBottom: '1px solid #DBDBDB',
    },
    captionStyle: {
        marginTop: theme.spacing(3),
    },
    link: {
        color: '#40B4BF',
        'text-decoration': 'none',
        cursor: 'pointer',
        fontWeight: 'bold' as any,
    },
});

const useStyles = makeStyles(styles);
interface SupplierFilterState {
    manufacturingProcesses: any[], 
    finishes: any[]
    productPreference: any[]
    materials: any[]
    certifications: any[]
    leadTime: any[]
    lotSize: any[]
}

interface FilterListDrawerProps extends WithNamespaces {
    toggleDrawer: () => void,
    updateFilterStateFunction: (field: any) => (value: any) => void,
    filterState: SupplierFilterState,
    addFilter: () => void,
}

const FilterListDrawer: React.FC<FilterListDrawerProps> = ({t, toggleDrawer, updateFilterStateFunction, filterState, addFilter }) => {
    const classes = useStyles();
    const { manufacturingProcesses, finishes, productPreference, materials, certifications, leadTime, lotSize } = filterState
        // Extract supplier tags for inputs from state
    const supplierTagsUnfiltered = useAppSelector(state => state.supplier.supplierTags);
    const labelToSupplierMapping = {
        MANUFACTURING: 'manufacturing',
        FINISHING: 'finishing',
        CERTIFICATIONS: 'certifications',
        PRODUCT_PREFERENCES: 'productPreferences',
        MATERIAL_PREFERENCES: 'materialPreferences',
        AFFILIATE_ORGANIZATIONS: 'organizations',
        LOT_SIZE: 'lotsizes',
        LEAD_TIME: 'leadtimes',
    };

    // This method assures only one pass through of all supplierTags, faster than selectors before.
    // Create an object where key: value of labelToSupplierMapping and value is []
    const supplierTags = Object.fromEntries(Object.values(labelToSupplierMapping).map(label => [label, []])) 

    supplierTagsUnfiltered.forEach(sT => {
        if(sT.type in labelToSupplierMapping) {
            supplierTags[labelToSupplierMapping[sT.type]].push({label: sT.name, value: sT.value})
        }
    })
    
    for(const i in supplierTags) {
        supplierTags[i].sort((a, b) => b.label.toUpperCase() < a.label.toUpperCase() ? 1 : 1)
    }
    return (

    <div style={{ width: '300px' }}>
            <List>
                <ListItem style={{ borderBottom: '1px solid #45678a', display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }} className={classes.listItem}>
                    <Typography style={{ color: '#FFF', fontWeight: 'bolder' }} variant="subtitle1">
                        FILTERS
                    </Typography>
                    <IconButton
                        edge="start"
                        color="primary"
                        aria-label="close"
                        className={classes.closeIcon}
                        disableFocusRipple
                        disableRipple
                        style={{ backgroundColor: 'transparent' }}
                        onClick={toggleDrawer}
                    >
                        <CloseIcon />
                    </IconButton>
                </ListItem>
                <ListItem className={classes.listItem} style={{ marginTop: 16 }}>
                    <AutoCompleteValueLabel
                        name="supplierCapabilities"
                        multiple
                        renderCheckboxes
                        onChangeHandler={updateFilterStateFunction('manufacturingProcesses')}
                        value={manufacturingProcesses}
                        options={supplierTags?.manufacturing}
                        placeholder={t('supplier-capabilities-manufacturing-process')}
                        label={t('supplier-capabilities-manufacturing-process')}
                        disableClearable={false}
                    />
                </ListItem>
                <ListItem className={classes.listItem}>
                    <AutoCompleteValueLabel
                        name="finishingCapabilities"
                        multiple
                        renderCheckboxes
                        onChangeHandler={updateFilterStateFunction('finishes')}
                        value={finishes}
                        options={supplierTags.finishing}
                        placeholder={t('supplier-capabilities-finishes')}
                        label={t('supplier-capabilities-finishes')}
                        disableClearable={false}
                    />
                </ListItem>
                <ListItem className={classes.listItem}>
                    <AutoCompleteValueLabel
                        name="productPreferences"
                        multiple
                        renderCheckboxes
                        onChangeHandler={updateFilterStateFunction('productPreference')}
                        value={productPreference}
                        options={supplierTags.productPreferences}
                        placeholder={t('Product Preferences')}
                        label={t('Product Preferences')}
                        disableClearable={false}
                    />
                </ListItem>
                <ListItem className={classes.listItem}>
                    <AutoCompleteValueLabel
                        name="materialPreferences"
                        multiple
                        renderCheckboxes
                        onChangeHandler={updateFilterStateFunction('materials')}
                        value={materials}
                        options={supplierTags.materialPreferences}
                        placeholder={t('Material Preferences')}
                        label={t('Material Preferences')}
                        disableClearable={false}
                    />
                </ListItem>

                <ListItem className={classes.listItem}>
                    <AutoCompleteValueLabel
                        name="certifications"
                        multiple
                        renderCheckboxes
                        onChangeHandler={updateFilterStateFunction('certifications')}
                        value={certifications}
                        options={supplierTags.certifications}
                        placeholder={t('quotes-details-certifications')}
                        label={t('quotes-details-certifications')}
                        disableClearable={false}
                    />
                </ListItem>
                <ListItem className={classes.listItem}>
                    <AutoCompleteValueLabel
                        name="suppliers"
                        multiple
                        renderCheckboxes
                        onChangeHandler={updateFilterStateFunction('leadTime')}
                        value={leadTime}
                        options={supplierTags?.leadtimes}
                        placeholder={t('quotes-details-leadtime')}
                        label={t('quotes-details-leadtime')}
                        disableClearable={false}
                    />
                </ListItem>
                <ListItem className={classes.listItem}>
                    <AutoCompleteValueLabel
                        name="lotSize"
                        multiple
                        renderCheckboxes
                        onChangeHandler={updateFilterStateFunction('lotSize')}
                        value={lotSize}
                        options={supplierTags?.lotsizes}
                        placeholder={t('quotes-details-lot-size')}
                        label={t('quotes-details-lot-size')}
                        disableClearable={false}
                    />
                </ListItem>

                <ListItem style={{ justifyContent: 'flex-end', paddingLeft: 16, paddingRight: 16 }}>
                    <Button onClick={addFilter} color="primary">
                        Apply Filter
                    </Button>
                </ListItem>
            </List>
        </div>
    )
}

export default (withNamespaces()(FilterListDrawer)) 
