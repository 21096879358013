import { Grid, Typography } from '@material-ui/core'
import { Button, FormAutocompleteV2, jumpToFirstError } from '@syncfab/machine'
import { Map } from 'immutable'
import React from 'react'
import { withNamespaces, WithNamespaces } from 'react-i18next'
import { InjectedFormProps } from 'redux-form'
import { Field, reduxForm } from 'redux-form/immutable'
import { withStyles, WithStyles } from '@material-ui/core/styles'

export const LineItemEditorFormName: string = 'LineItemEditorFormName'

const styles = _ => ({
    action: {
        minWidth: 70,
        fontSize: '14px',
    },
    detailsHeader: {
        fontSize: '14px',
    },
})

// TODO: Review the data type of 'data' below
const validate = (data: Map<string, any>): {} => {
    const errors = {}
    ;['partId'].forEach(field => {
        const value = data.get(field)
        if (!value) {
            errors[field] = 'Required'
        }
    })

    return errors
}

interface Properties {
    cancel: () => void
    partOptions: any[]
}

interface FormProps extends Properties, WithNamespaces, WithStyles, InjectedFormProps<{}, {}> {}

class Form extends React.Component<FormProps, {}> {
    constructor(props) {
        super(props)
    }

    render() {
        const { handleSubmit, submitting, t, classes, cancel, partOptions } = this.props
        return (
            <form onSubmit={handleSubmit}>
                <Grid container item xs={12}>
                    <Typography variant="h6" className={classes.detailsHeader} style={{ marginBottom: 10 }}>
                        {`${t('Select Part')}`}
                    </Typography>
                    <Field
                        name="partId"
                        component={FormAutocompleteV2}
                        label={`${t('Parts')}*`}
                        fullWidth
                        options={partOptions}
                        format={value => {
                            if (!value) {
                                return null
                            }
                            if (!!value.toJS) {
                                return value.toJS()
                            }
                            return value
                        }}
                    />
                </Grid>
                <Grid container item xs={12} justifyContent="flex-end" style={{ marginBottom: 15 }}>
                    <Button type="button" color="primary" variant="text" disabled={!!submitting} className={classes.action} onClick={cancel}>
                        {t('form-action-cancel')}
                    </Button>
                    <Button type="submit" color="primary" className={classes.action} style={{ marginLeft: 5 }}>
                        {t('form-action-save')}
                    </Button>
                </Grid>
            </form>
        )
    }
}

const translatedForm = withStyles(styles)(withNamespaces()(Form))
export const LineItemForm = reduxForm<{}, Properties>({
    form: LineItemEditorFormName,
    validate,
    onSubmitFail: jumpToFirstError,
})(translatedForm as any)
