import React from 'react'
import SvgIcon from '@material-ui/core/SvgIcon'

export const TransactionUpIcon = props => {
    return (
        <SvgIcon {...props} viewBox="0 0 24 24" x="0px" y="0px" baseProfile="tiny" xmlSpace="preserve">
            <path
                fill="#FFFFFF"
                d="M12,24c6.6,0,12-5.4,12-12S18.6,0,12,0S0,5.4,0,12C0,18.6,5.3,24,12,24
				C12,24,12,24,12,24z M7.7,11.1l3.8-3.8c0.1-0.1,0.1-0.1,0.2-0.1c0.1,0,0.1-0.1,0.1-0.1c0.1,0,0.2,0,0.3-0.1c0.1,0,0.2,0,0.3,0.1
				l0.1,0.1c0.1,0.1,0.2,0.1,0.2,0.1l3.7,3.8c0.3,0.3,0.4,0.8,0.1,1.1c-0.3,0.3-0.8,0.4-1.1,0.1c0,0-0.1-0.1-0.1-0.1l-3.2-3.2l-3.2,3.2
				c-0.3,0.3-0.8,0.3-1.1,0c0,0,0,0,0,0C7.4,12,7.4,11.6,7.7,11.1C7.7,11.2,7.7,11.2,7.7,11.1L7.7,11.1z M7.7,15.4l3.8-3.8
				c0.1-0.1,0.1-0.1,0.1-0.1c0.1-0.1,0.1-0.1,0.2-0.1c0.1,0,0.2,0,0.3-0.1c0.1,0,0.2,0,0.3,0.1c0.1,0,0.1,0.1,0.1,0.1
				c0.1,0.1,0.1,0.1,0.2,0.1l3.7,3.8c0.3,0.3,0.4,0.8,0.1,1.1c-0.3,0.3-0.8,0.4-1.1,0.1c0,0-0.1-0.1-0.1-0.1l-3.2-3.2l-3.2,3.2
				c-0.3,0.3-0.8,0.3-1.1,0c0,0,0,0,0,0C7.4,16.3,7.4,15.8,7.7,15.4C7.7,15.5,7.7,15.5,7.7,15.4L7.7,15.4z"
            />
        </SvgIcon>
    )
}
