import * as React from 'react';
import { Fade } from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import { Snackbar, Typography } from '@material-ui/core';
// import WarningIcon from '@material-ui/icons/Warning';
import { connect } from 'react-redux';
import {
  HIDE_COMPLETE_FEEDBACK,
  HIDE_DIRTY_FEEDBACK,
  HIDE_ERROR_FEEDBACK,
  HIDE_SAVING_FEEDBACK,
} from '../../feedback';

export const Feedback = props => (
  <Snackbar
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'left',
    }}
    open={props.open}
    autoHideDuration={props.duration || 3000}
    onClose={props.handleClose}
    ContentProps={{
      'aria-describedby': 'message-id',
    }}
    message={
      <Typography
        id="message-id"
        className={props.messageClass}
        style={{ color: 'white' }}
      >
        {props.message}
      </Typography>
    }
  />
);

const SavingStandard = ({ isSaving, handleClose }) => {
  return (
    <Snackbar
      anchorOrigin={{ horizontal: 'center', vertical: 'top' }}
      open={isSaving}
      onClose={handleClose}
    >
      <Alert severity="error">
        Save in progress, please do not leave page.
      </Alert>
    </Snackbar>
  );
};

export const SavingStandardFeedback = connect(
  ({ feedback: { isSaving } }) => {
    return {
      isSaving,
    };
  },
  dispatch => {
    return {
      handleClose: () => dispatch(HIDE_SAVING_FEEDBACK()),
    };
  }
)(SavingStandard);

const ErrorStandard = ({ isError, handleClose }) => {
  return (
    <Snackbar
      anchorOrigin={{ horizontal: 'center', vertical: 'top' }}
      open={isError}
      onClose={handleClose}
      autoHideDuration={3000}
    >
      <Alert severity="error">
        An error occured while saving. Please try again.
      </Alert>
    </Snackbar>
  );
};

export const ErrorStandardFeedback = connect(
  ({ feedback: { isError } }) => {
    return {
      isError,
    };
  },
  dispatch => {
    return {
      handleClose: () => dispatch(HIDE_ERROR_FEEDBACK()),
    };
  }
)(ErrorStandard);

const UnchangedStandard = ({ isDirty }) => {
  return (
    <Snackbar
      open={isDirty}
      TransitionComponent={Fade}
      anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
    >
      <Alert severity="warning">
        Unsaved changes. Please save your work to avoid losing data.
      </Alert>
    </Snackbar>
  );
};

export const UnchangedStandardFeedback = connect(
  ({ feedback: { isDirty } }) => {
    return {
      isDirty,
    };
  },
  dispatch => {
    return {
      handleClose: () => dispatch(HIDE_DIRTY_FEEDBACK()),
    };
  }
)(UnchangedStandard);

const CompletedStandard = ({ handleClose, isComplete }) => {
  return (
    <Snackbar
      autoHideDuration={3000}
      onClose={handleClose}
      open={isComplete}
      anchorOrigin={{ horizontal: 'center', vertical: 'top' }}
      TransitionComponent={Fade}
    >
      <Alert severity="success">
        Save completed! All changes are up to date.
      </Alert>
    </Snackbar>
  );
};

export const CompletedStandardFeedback = connect(
  ({ feedback: { isComplete } }) => {
    return {
      isComplete,
    };
  },
  dispatch => {
    return {
      handleClose: () => dispatch(HIDE_COMPLETE_FEEDBACK()),
    };
  }
)(CompletedStandard);
