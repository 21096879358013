import React from 'react'
import { InjectedFormProps } from 'redux-form'
import { Map } from 'immutable'
import { WithStyles, withStyles } from '@material-ui/core/styles'
import { withNamespaces, WithNamespaces } from 'react-i18next'
import {
    Grid,
    Typography,
    Divider,
    Card,
    CardHeader,
    CardContent,
    ExpansionPanel,
    ExpansionPanelSummary,
    ExpansionPanelDetails,
    FormControlLabel,
    Radio,
    Dialog,
    DialogContent,
    SnackbarContent,
    IconButton,
    Button,
} from '@material-ui/core'
import { MFGIcon } from '@syncfab/machine'
import classNames from 'classnames'
import { connect } from 'react-redux'
import { PromoCodeForm } from './PromoCodeForm'
import { PaymentForm } from './PaymentForm'
import InfoIcon from '@material-ui/icons/Info'
import { ReactStripeElements, injectStripe } from 'react-stripe-elements'
import { State } from '../../../../application/Store'
import { Dispatch } from 'redux'
import { MAKE_PAYMENT, GET_WALLET_ADDRESS, GET_TRANSACTION_FEE, ACH_PAYMENT } from '../../../../components/transaction/Actions'
import CachedIcon from '@material-ui/icons/Cached'
import PlaidLink from 'react-plaid-link'
import { EnvironmentVariable, Environment } from '@syncfab/machine'
import { Snackbar, Tooltip } from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close'
import ErrorIcon from '@material-ui/icons/Error'
import DeleteIcon from '@material-ui/icons/Delete'
import { User } from '@syncfab/machine'
import { amber } from '@material-ui/core/colors'
import { GET_LOGGED_USER } from '../../../../components/user/Actions'
interface FormState {
    selectedPlan?: any
    expanded?: string
    setExpanded?: boolean
    name: string
    plaidKey: string
    openSnack?: boolean
    openSnackError?: boolean
    bankDetails: any
    bankAccountToken: any
    mfg_unit: number
    discountedCost: number
}

interface FormProps extends InjectedFormProps<{}, {}>, WithStyles, WithNamespaces, ReactStripeElements.InjectedStripeProps {
    getLoggedUser: () => void
    loggedUser?: User
    stripe: any
    elements: any
    makePayment: (data: any) => void
    achPayment: (data: any) => void
    walletAddress?: string
    getWalletAddress: () => void
    isLoading: boolean
    getTransactionFee: () => void
    transactionFee: any
}

class PurchaseMFGEdiorForm extends React.Component<FormProps, FormState> {
    constructor(props) {
        super(props)
        this.onSubmit = this.onSubmit.bind(this)
        this.onPaymentSubmit = this.onPaymentSubmit.bind(this)
        this.saveBankDetails = this.saveBankDetails.bind(this)
        this.onDeleteBankDetails = this.onDeleteBankDetails.bind(this)
        this.makeACHPayment = this.makeACHPayment.bind(this)
        this.props.getWalletAddress()
        this.props.getTransactionFee()
        const environment = new Environment()
        environment.load()
        this.state = {
            selectedPlan: {
                id: '1',
                name: 'Purchase 100 MFG',
                cost: '12.00',
                tokens: 100,
                gasFee: 0.3,
            },
            expanded: null,
            setExpanded: false,
            name: 'syncfab',
            plaidKey: environment.get(EnvironmentVariable.PLAID_PUBLIC_KEY),
            openSnack: false,
            bankDetails: undefined,
            bankAccountToken: null,
            openSnackError: false,
            mfg_unit: 0.15,
            discountedCost: 15,
        }
        this.handleCloseSnack = this.handleCloseSnack.bind(this)
        this.props.getLoggedUser()
    }
    //handleChange = (panel: string) => (e, newExpanded: boolean) => {
    handleChange = (panel: string) => () => {
        this.setState({ expanded: panel })
    }

    onSubmit = () => {}

    async onPaymentSubmit(values: Map<string, string>) {
        const vals = values.toJS()
        if (!this.state.selectedPlan) {
            this.setState({ openSnack: true })
            return
        }

        let processingFees = 0
        let total = this.state.discountedCost + this.state.selectedPlan.gasFee
        if (this.state.expanded && this.state.expanded == 'cardPanel') {
            processingFees = this.state.discountedCost * (2.9 / 100) + 0.3
        }
        if (this.state.expanded && this.state.expanded == 'bankPanel') {
            processingFees = total * (0.8 / 100) > 5 ? 5 : total * (0.8 / 100)
        }
        let totalAmt = Math.round((this.state.selectedPlan.gasFee + processingFees) * 100) / 100

        let totalCost = totalAmt + this.state.discountedCost

        try {
            var createPaymement = await this.props.stripe.createPaymentMethod({ type: 'card', card: this.props.elements.getElement('card') })
            this.props.makePayment({
                amount: totalCost.toString(),
                tokens: this.state.selectedPlan.tokens.toString(),
                paymentMethodId: createPaymement.paymentMethod.id,
                paymentIntentId: createPaymement.paymentMethod.id,
                walletAddress: this.props.walletAddress,
                email: vals['email'],
            })
        } catch (e) {
            console.log(e)
            this.setState({ openSnackError: true })
        }
    }

    saveBankDetails(token, metadata) {
        this.setState({ bankDetails: metadata, bankAccountToken: token })
    }

    makeACHPayment() {
        if (!this.state.selectedPlan) {
            this.setState({ openSnack: true })
            return
        }
        const environment = new Environment()
        environment.load()

        let processingFees = 0
        let total = this.state.discountedCost + this.state.selectedPlan.gasFee
        if (this.state.expanded && this.state.expanded == 'cardPanel') {
            processingFees = this.state.discountedCost * (2.9 / 100) + 0.3
        }
        if (this.state.expanded && this.state.expanded == 'bankPanel') {
            processingFees = total * (0.8 / 100) > 5 ? 5 : total * (0.8 / 100)
        }
        let totalAmt = Math.round((this.state.selectedPlan.gasFee + processingFees) * 100) / 100

        let totalCost = totalAmt + this.state.discountedCost

        try {
            this.props.achPayment({
                publicToken: this.state.bankAccountToken,
                accountId: this.state.bankDetails.account_id,
                amount: Number(totalCost),
                walletAddress: this.props.walletAddress,
                tokens: this.state.selectedPlan.tokens.toString(),
                email: this.props.loggedUser.email,
            })
        } catch (e) {
            console.log(e)
            this.setState({ openSnackError: true })
        }
    }

    handleOnExit() {}
    handleOnEvent() {}
    handleOnLoad() {}
    handleCloseSnack() {
        this.setState({ openSnack: false, openSnackError: false })
    }
    onDeleteBankDetails() {
        this.setState({ bankDetails: undefined, bankAccountToken: null })
    }

    render() {
        const { classes, isLoading, transactionFee } = this.props

        const { selectedPlan, expanded, plaidKey, openSnack, bankDetails, openSnackError, mfg_unit, discountedCost } = this.state
        const plans = [
            {
                id: '1',
                name: 'Purchase 100 MFG',
                cost: '12.00',
                tokens: 100,
                gasFee: 0.3,
            },
            {
                id: '2',
                name: 'Purchase 1000 MFG',
                cost: '51.00',
                discount: 5,
                tokens: 1000,
                gasFee: 0.3,
            },
            {
                id: '3',
                name: 'Purchase 5000 MFG',
                cost: '96.00',
                discount: 10,
                tokens: 5000,
                gasFee: 0.3,
            },
            {
                id: '4',
                name: 'Purchase 10,000 MFG',
                cost: '840.00',
                discount: 15,
                tokens: 10000,
                gasFee: 0.3,
            },
        ]
        const handleClose = () => {
            this.handleCloseSnack()
        }
        const getTransactionFee = () => {
            //  console.log(transactionFee.mfgTransferFee);

            let processingFees = 0
            let total = discountedCost + selectedPlan.gasFee
            if (expanded && expanded == 'cardPanel') {
                processingFees = discountedCost * (2.9 / 100) + 0.3
            }
            if (expanded && expanded == 'bankPanel') {
                processingFees = total * (0.8 / 100) > 5 ? 5 : total * (0.8 / 100)
            }
            return Math.round((selectedPlan.gasFee + processingFees) * 100) / 100
        }

        return (
            // <form onSubmit={this.addPromoCode}>
            <Grid container>
                <Snackbar
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'left',
                    }}
                    open={openSnack}
                    autoHideDuration={5000}
                    onClose={handleClose}
                >
                    <SnackbarContent
                        className={classes.error}
                        aria-describedby="client-snackbar"
                        message={
                            <span id="client-snackbar" className={`${classes.message} ${classes.messageClass}`}>
                                <ErrorIcon className={`${classes.icon}  ${classes.iconVariant}${classes.messageClass}`} />
                                <Typography className={`${classes.message} ${classes.snackText}`}>{'Please Select Plan'}</Typography>
                            </span>
                        }
                        action={[
                            <IconButton key="close" aria-label="close" color="inherit" onClick={handleClose}>
                                <CloseIcon className={classes.icon} />
                            </IconButton>,
                        ]}
                    />
                </Snackbar>

                <Snackbar
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'left',
                    }}
                    open={openSnackError}
                    autoHideDuration={5000}
                    onClose={handleClose}
                >
                    <SnackbarContent
                        className={classes.error}
                        aria-describedby="client-snackbar"
                        message={
                            <span id="client-snackbar" className={`${classes.message} ${classes.messageClass}`}>
                                <ErrorIcon className={`${classes.icon}  ${classes.iconVariant}${classes.messageClass}`} />
                                <Typography className={`${classes.message} ${classes.snackText}`}>{'Something went wrong. Please try again.'}</Typography>
                            </span>
                        }
                        action={[
                            <IconButton key="close" aria-label="close" color="inherit" onClick={handleClose}>
                                <CloseIcon className={classes.icon} />
                            </IconButton>,
                        ]}
                    />
                </Snackbar>
                <Dialog aria-labelledby="simple-dialog-title" open={isLoading}>
                    <DialogContent>
                        <Grid container justify="center">
                            <Grid item container justify="center" xs={12}>
                                {/* <Fab className={classes.processingButton}> */}
                                <CachedIcon className={classes.processingButton} />
                                {/* </Fab> */}
                            </Grid>

                            <Typography gutterBottom variant="h3">
                                Please Wait...
                            </Typography>
                        </Grid>
                    </DialogContent>
                </Dialog>
                <Grid container item xs={8}>
                    <Typography variant="h3">MFG Purchase Amount</Typography>
                    <Grid item container xs={12} style={{ marginBottom: '24px' }}>
                        {plans.map(plan => (
                            <Grid
                                item
                                container
                                xs={6}
                                key={plan.id}
                                onClick={() => {
                                    let selectedPlan = (this.state.selectedPlan && this.state.selectedPlan.id) == plan.id ? undefined : plan
                                    let discountedCost = selectedPlan.discount
                                        ? selectedPlan.tokens * mfg_unit - selectedPlan.tokens * mfg_unit * (selectedPlan.discount / 100)
                                        : selectedPlan.tokens * mfg_unit
                                    //  ${selectedPlan ? parseFloat(selectedPlan.cost) + 1.5 : 1.5}
                                    this.setState({ selectedPlan: selectedPlan, discountedCost: Math.round(discountedCost * 100) / 100 })
                                }}
                            >
                                <Grid
                                    style={{ margin: '16px 24px 0px 0px' }}
                                    item
                                    container
                                    xs={12}
                                    alignItems="center"
                                    className={(selectedPlan && selectedPlan.id) == plan.id ? classes.selectedbalanceContainer : classes.balanceContainer}
                                >
                                    <Grid item xs={3}>
                                        {(selectedPlan && selectedPlan.id) == plan.id && <MFGIcon className={classes.mfgIcon} />}
                                        {(selectedPlan && selectedPlan.id) != plan.id && <MFGIcon className={classes.mfgIcon} fill="FFBA00" />}
                                    </Grid>
                                    <Grid item xs={9}>
                                        <Typography variant="h6" color={((selectedPlan && selectedPlan.id) == plan.id ? 'primary' : 'textPrimary')}>
                                            {plan.name}
                                        </Typography>
                                        <Typography color={(selectedPlan && selectedPlan.id) == plan.id ? 'primary' : 'textPrimary'}>
                                            ${plan.tokens * mfg_unit} {plan.discount && ' (' + plan.discount + '% Off)'}{' '}
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                        ))}
                    </Grid>
                    {transactionFee && (
                        <React.Fragment>
                            <Typography variant="h3">Payment Method</Typography>
                            <Grid item container xs={12} style={{ marginTop: '8px', marginRight: '24px' }}>
                                <div className={classNames(classes.panelcss, classes.marginTop)}>
                                    <ExpansionPanel className={classes.panelcss} square expanded={expanded === 'cardPanel'} onChange={this.handleChange('cardPanel')}>
                                        <ExpansionPanelSummary aria-controls="panel1d-content" id="panel1d-header">
                                            <FormControlLabel
                                                color="textPrimary"
                                                checked={expanded == 'cardPanel'}
                                                aria-label="Acknowledge"
                                                //  onClick={event => event.stopPropagation()}
                                                // onFocus={event => event.stopPropagation()}
                                                control={<Radio color="secondary" />}
                                                label={
                                                    <Typography variant="h6" color="textPrimary">
                                                        Credit or Debit Card
                                                    </Typography>
                                                }
                                            />
                                        </ExpansionPanelSummary>
                                        <ExpansionPanelDetails>
                                            {/* <Elements> */}
                                            <Grid item xs={12}>
                                                <PaymentForm onSubmit={this.onPaymentSubmit as any} amount={selectedPlan && transactionFee ? discountedCost + getTransactionFee() + '' : '0'} />
                                            </Grid>

                                            {/* </Elements> */}
                                        </ExpansionPanelDetails>
                                    </ExpansionPanel>
                                    <ExpansionPanel
                                        className={classes.panelcss}
                                        square
                                        expanded={expanded === 'bankPanel'} // onClick={event => event.stopPropagation()}
                                        // onFocus={event => event.stopPropagation()}
                                        onChange={this.handleChange('bankPanel')}
                                    >
                                        <ExpansionPanelSummary aria-controls="panel2d-content" id="panel2d-header">
                                            <FormControlLabel
                                                color="textPrimary"
                                                checked={expanded == 'bankPanel' ? true : false}
                                                aria-label="Acknowledge"
                                                //  onClick={event => event.stopPropagation()}
                                                // onFocus={event => event.stopPropagation()}
                                                control={<Radio color="secondary" />}
                                                label={
                                                    <Typography variant="h6" color="textPrimary">
                                                        ACH or Bank Transfer
                                                    </Typography>
                                                }
                                            />
                                        </ExpansionPanelSummary>
                                        <ExpansionPanelDetails>
                                            {!bankDetails && (
                                                <Grid item xs={12}>
                                                    <PlaidLink
                                                        clientName="Plaid Client"
                                                        env="sandbox"
                                                        product={['transactions']}
                                                        publicKey={plaidKey}
                                                        apiVersion="v2"
                                                        onSuccess={this.saveBankDetails}
                                                        onExit={this.handleOnExit}
                                                        onEvent={this.handleOnEvent}
                                                        onLoad={this.handleOnLoad}
                                                        className={classes.addBankBtn}
                                                        style={{}}
                                                        selectAccount={true}
                                                    >
                                                        LINK BANK ACCOUNT
                                                    </PlaidLink>
                                                </Grid>
                                            )}
                                            {bankDetails && (
                                                <Grid item container xs={12}>
                                                    <Grid item container justify="space-between" xs={12} alignItems="center" className={classes.bankDetails}>
                                                        <Grid>
                                                            <Typography variant="h5" color={'textPrimary'}>
                                                                Business Checking
                                                            </Typography>
                                                            <Typography color={'textSecondary'}>••••••••{bankDetails.account.mask}</Typography>
                                                        </Grid>
                                                        <Grid>
                                                            <IconButton aria-label="Delete" className={classes.actions}>
                                                                <DeleteIcon onClick={this.onDeleteBankDetails} />
                                                            </IconButton>
                                                        </Grid>
                                                    </Grid>
                                                    <Button variant="contained" fullWidth color="secondary" className={classes.action} onClick={this.makeACHPayment}>
                                                        pay $ {selectedPlan && transactionFee ? discountedCost + getTransactionFee() + '' : '0'}
                                                    </Button>
                                                </Grid>
                                            )}
                                        </ExpansionPanelDetails>
                                    </ExpansionPanel>
                                </div>
                            </Grid>
                        </React.Fragment>
                    )}
                    {}
                </Grid>
                {transactionFee != null && (
                    <Grid container item xs={4}>
                        <Card className={`${classes.card} ${classes.detailcard}`} style={{ margin: '0px' }}>
                            <CardHeader
                                className={classes.cardheader}
                                title={
                                    <Typography color="textPrimary" variant="h3">
                                        Order Summary
                                    </Typography>
                                }
                            />
                            <CardContent>
                                {selectedPlan && transactionFee != null && (
                                    <Grid container className={classes.container} spacing={2}>
                                        <Grid item xs={8}>
                                            <Typography color="textSecondary" variant="h6">
                                                {selectedPlan.name}
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={4}>
                                            <Typography color="textSecondary" align="right">
                                                {discountedCost}
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                )}
                                <Grid container className={classes.container} spacing={2}>
                                    <Grid container item xs={8} direction="row">
                                        <Grid>
                                            <Typography color="textSecondary" variant="h6">
                                                Transaction/Gas Fee{' '}
                                            </Typography>
                                        </Grid>
                                        <Grid>
                                            <Tooltip
                                                className={classes.snackText}
                                                title={'Used for Gas Fee to run transaction over the Ethereum Backchain and Credit Card or ACH processing fee'}
                                                enterDelay={300}
                                            >
                                                <InfoIcon fontSize="small" color="disabled" />
                                            </Tooltip>
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={4}>
                                        <Typography color="textSecondary" align="right">
                                            ${getTransactionFee()}
                                        </Typography>
                                    </Grid>
                                </Grid>

                                <Divider className={classes.divider} />
                                <PromoCodeForm onSubmit={this.onSubmit as any} />
                                {/* <Grid container className={classes.container} spacing={16}>
                                <Grid item xs={8}>
                                    <Typography color="textSecondary" variant="h6">
                                        Promo Code Added{' '}
                                    </Typography>
                                </Grid>
                                <Grid item xs={4}>
                                    <Typography color="textSecondary" align="right">
                                        -$1.50
                                    </Typography>
                                </Grid>
                            </Grid> */}
                                <Divider className={classes.divider} />

                                <Grid container className={classes.container}>
                                    <Grid item xs={8}>
                                        <Typography color="textPrimary" variant="h3">
                                            Total
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={4}>
                                        <Typography color="textPrimary" align="right" variant="h3">
                                            ${selectedPlan && transactionFee ? discountedCost + getTransactionFee() : 0}
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </CardContent>
                        </Card>
                    </Grid>
                )}
            </Grid>
        )
    }
}
const styles = theme => ({
    paper: {
        width: '90%',
        minWidth: 300,
        padding: theme.spacing(4),
        marginTop: theme.spacing(4),
        marginBottom: theme.spacing(4),
    },
    divider: {
        marginTop: theme.spacing(3),
        marginBottom: theme.spacing(3),
        backgroundColor: '#DBDBDB',
    },
    mfgIcon: {
        width: 45,
        height: 45,
    },
    panelcss: {
        backgroundColor: theme.palette.common.white,
        width: 'inherit',
    },
    marginTop: {
        marginTop: theme.spacing(1),
    },
    customtextfield: {
        'background-color': '#DBDBDB',
        margin: theme.spacing(0),
    },

    link: {
        textDecoration: 'none',
        color: '#40B4BF',
    },
    button: {
        margin: theme.spacing(1),
    },
    viewpart: {
        margin: theme.spacing(4),
    },

    PrtIcon: {
        width: 55,
        height: 55,
    },
    card: {
        width: '100%',
        margin: `${theme.spacing(3)}px 12px`,
        backgroundColor: theme.palette.common.white,
    },
    cardheader: {
        backgroundColor: '#DBDBDB',
    },
    selectedbalanceContainer: {
        backgroundColor: theme.palette.secondary.main,
        borderRadius: 45,
        margin: `${theme.spacing(1)}px ${theme.spacing(1)}px`,
        padding: theme.spacing(2),
    },
    balanceContainer: {
        backgroundColor: theme.palette.grey[100],
        color: theme.palette.secondary.main,
        borderRadius: 45,
        margin: `${theme.spacing(1)}px ${theme.spacing(1)}px`,
        padding: theme.spacing(2),
    },
    balance: {
        color: theme.palette.secondary.main,
        fontSize: 34,
        marginLeft: theme.spacing(1),
    },
    avatar: {
        backgroundColor: '#fffff',
    },
    detailcard: {
        height: 'fit-content',
        margin: `0px 12px`,
        backgroundColor: theme.palette.common.white,
    },
    customcardheader: {
        'background-color': '#eeeeee',
    },
    marginTextField: {
        marginRight: theme.spacing(0),
    },
    processingButton: {
        color: '#40BF8A',
        margin: theme.spacing(2),
        fontSize: 48,
    },
    plaidLink: {
        backgroundColor: theme.palette.common.secondary,
    },
    addBankBtn: {
        color: '#fff',
        backgroundColor: theme.palette.secondary.main + '!important',
        //   textTransform:'Uppercase',
        padding: `${theme.spacing(1)}px ${theme.spacing(2)}px`,
        width: '-webkit-fill-available !important',
        minWidth: theme.spacing(15),
        minHeight: theme.spacing(4.5),
        borderRadius: theme.spacing(3),
        border: 0,
        fontWeight: 600,
        fontFamily: `'Titillium Web', 'sans-serif'`,
        fontSize: 14,
        // textTransform: `uppercase`,
        //  fontFamily:'Titillium Web',
        //boxShadow: `1px 3px 1px`,
    },
    error: {
        backgroundColor: theme.palette.error.light,
    },
    info: {
        backgroundColor: theme.palette.primary.main,
    },
    warning: {
        backgroundColor: amber[700],
    },
    icon: {
        fontSize: 20,
        color: theme.palette.common.white,
    },
    iconVariant: {
        opacity: 0.9,
        marginRight: theme.spacing * 1,
    },
    message: {
        display: 'flex',
        alignItems: 'center',
    },
    messageClass: {
        color: theme.palette.common.white,
        backgroundColor: theme.palette.common.green,
    },
    snackText: {
        marginLeft: theme.spacing(1),
        color: '#adacac',
    },
    bankDetails: {
        backgroundColor: theme.palette.grey[100],
        color: theme.palette.secondary.main,
        borderRadius: 15,
        margin: `${theme.spacing(2)}px ${theme.spacing(1)}px`,
        padding: theme.spacing(1),
    },
})

const PurchaseMFGWithTranslations = withStyles(styles)(withNamespaces()(injectStripe(PurchaseMFGEdiorForm)))

const mapStateToProps = (state: State) => {
    return {
        loggedUser: state.user.loggedUser,
        isLoading: state.transaction.isLoading,
        walletAddress: state.transaction.walletAddress,
        transactionFee: state.transaction.transactionFee,
    }
}

const mapDispatchToProps = (dispatch: Dispatch) => {
    return {
        getWalletAddress: () => dispatch(GET_WALLET_ADDRESS.actions.TRIGGER()),
        makePayment: (data: any) => dispatch(MAKE_PAYMENT.actions.TRIGGER({ data })),
        getTransactionFee: () => dispatch(GET_TRANSACTION_FEE.actions.TRIGGER()),
        achPayment: (data: any) => dispatch(ACH_PAYMENT.actions.TRIGGER({ data })),
        getLoggedUser: () => dispatch(GET_LOGGED_USER.actions.TRIGGER()),
    }
}
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(PurchaseMFGWithTranslations)
