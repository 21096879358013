import axios, { AxiosPromise } from 'axios'
import { AuthorizationService, Environment, EnvironmentVariable, RFQ } from '@syncfab/machine'
import { ListMeta } from '../../layout/list/Meta'

export class RFQV2API {
    constructor(private environment: Environment, private authorizationService: AuthorizationService) {}

    public get = (id: string): (() => AxiosPromise) => {
        const url = this.environment.get(EnvironmentVariable.API_LAYER_URL)
        const authorization = `Bearer ${this.authorizationService.getAuthToken()}`
        return () => {
            return axios.get(`${url}/rfqs/v2/${id}`, {
                headers: {
                    authorization,
                },
            })
        }
    }

    public getQuoteFromRFQ = (rfqId: string): (() => AxiosPromise) => {
        const url = this.environment.get(EnvironmentVariable.API_LAYER_URL)
        const authorization = `Bearer ${this.authorizationService.getAuthToken()}`
        return () => {
            return axios.get(`${url}/rfqs/v2/${rfqId}/quote`, {
                headers: {
                    authorization,
                },
            })
        }
    }

    public saveRFQ = (rfq: RFQ): (() => AxiosPromise) => {
        const url = this.environment.get(EnvironmentVariable.API_LAYER_URL)
        const authorization = `Bearer ${this.authorizationService.getAuthToken()}`
        return () => {
            return axios.post(`${url}/rfqs/v2`, rfq, {
                headers: {
                    authorization,
                },
            })
        }
    }

    public createRFQ = (_id, rfq: Partial<RFQ>): (() => AxiosPromise) => {
        const url = this.environment.get(EnvironmentVariable.API_LAYER_URL)
        const authorization = `Bearer ${this.authorizationService.getAuthToken()}`
        return () => {
            return axios.put(`${url}/rfqs/v2/${_id}/submit`, rfq, {
                headers: {
                    authorization,
                },
            })
        }
    }

    public draft = (id: string, rfq: Partial<RFQ>): (() => AxiosPromise) => {
        const url = this.environment.get(EnvironmentVariable.API_LAYER_URL)
        const authorization = `Bearer ${this.authorizationService.getAuthToken()}`
        return () => {
            return axios.patch(`${url}/rfqs/v2/${id}`, rfq, {
                headers: {
                    authorization,
                },
            })
        }
    }

    public list = (meta: ListMeta): (() => AxiosPromise) => {
        const url = this.environment.get(EnvironmentVariable.API_LAYER_URL)
        const authorization = `Bearer ${this.authorizationService.getAuthToken()}`
        return () => {
            return axios.get(`${url}/rfqs/v2/getAll`, {
                params: meta,
                headers: {
                    authorization,
                },
            })
        }
    }
}
