import React from 'react'
import SvgIcon from '@material-ui/core/SvgIcon'

export const OrderReceivedIcon = props => {
    return (
        <SvgIcon {...props} viewBox="0 0 40 40" x="0px" y="0px" baseProfile="tiny" xmlSpace="preserve" spacing="preserve">
            <path
                fill="#FFFFFF"
                d="M12.1,3.1C10,3.1,8.2,4.6,8,6.7c-0.3,2.1,1.1,4,3.2,4.5V23c0,0.5,0.4,1,1,1s1-0.4,1-1V11.2
				c2.1-0.5,3.4-2.4,3.2-4.5C16,4.6,14.2,3.1,12.1,3.1L12.1,3.1z"
            />
            <path
                fill="#FFFFFF"
                d="M7.5,2.6c-0.4-0.4-1-0.4-1.4,0C4.9,3.8,4.2,5.4,4.2,7c0,1.7,0.7,3.3,1.8,4.4c0.4,0.4,1,0.4,1.4,0
				c0.4-0.4,0.4-1,0-1.4h0C5.8,8.4,5.8,5.7,7.5,4C7.8,3.6,7.8,3,7.5,2.6L7.5,2.6z"
            />
            <path
                fill="#FFFFFF"
                d="M4.4,0.3C4-0.1,3.4-0.1,3,0.3C1.2,2.1,0.1,4.6,0.1,7.2s1,5.1,2.9,6.9c0.2,0.2,0.4,0.4,0.7,0.4
				c0.3,0,0.6-0.1,0.8-0.3c0.2-0.2,0.3-0.5,0.3-0.8c0-0.3-0.2-0.5-0.4-0.7c-1.5-1.5-2.3-3.5-2.3-5.5s0.8-4.1,2.3-5.5
				C4.6,1.5,4.7,1.3,4.7,1C4.7,0.7,4.6,0.5,4.4,0.3L4.4,0.3z"
            />
            <path
                fill="#FFFFFF"
                d="M18.2,3c-0.4-0.4-1-0.4-1.4-0.1c-0.4,0.4-0.4,1-0.1,1.4h0c1.7,1.7,1.7,4.4,0,6.1c-0.3,0.4-0.3,0.9,0.1,1.3
				c0.3,0.3,0.9,0.4,1.3,0.1C19.3,10.6,20,9,20,7.3C20,5.7,19.3,4.1,18.2,3L18.2,3z"
            />
            <path
                fill="#FFFFFF"
                d="M19.8,0.3c-0.4,0.4-0.4,1,0,1.4c1.5,1.5,2.3,3.5,2.3,5.5s-0.8,4.1-2.3,5.5c-0.4,0.4-0.4,1,0,1.4s1,0.4,1.4,0
				c1.8-1.8,2.9-4.3,2.9-6.9s-1-5.1-2.9-6.9C20.8-0.1,20.2-0.1,19.8,0.3L19.8,0.3z"
            />
        </SvgIcon>
    )
}
