import React from 'react'
import SvgIcon from '@material-ui/core/SvgIcon'

export const ResetIcon = props => {
    return (
        <SvgIcon {...props} viewBox="0 0 20.448 18" x="0px" y="0px" baseProfile="tiny" xmlSpace="preserve">
            <path
                id="lock_reset_FILL1_wght400_GRAD0_opsz48"
                d="M14.3,23.8a8.892,8.892,0,0,1-2.918-.495,9.124,9.124,0,0,1-2.571-1.36.692.692,0,0,1-.3-.532.754.754,0,0,1,.247-.606.715.715,0,0,1,.495-.21.8.8,0,0,1,.544.185,7.573,7.573,0,0,0,2.1,1.125,7.4,7.4,0,0,0,7.7-1.8,7.235,7.235,0,0,0,2.213-5.3A7.235,7.235,0,0,0,19.6,9.5a7.235,7.235,0,0,0-5.3-2.213A7.235,7.235,0,0,0,8.994,9.5a7.235,7.235,0,0,0-2.213,5.3v.445l1.212-1.212a.717.717,0,0,1,1.038,0,.81.81,0,0,1,.21.519.669.669,0,0,1-.21.519L6.559,17.545a.6.6,0,0,1-.247.161,1.016,1.016,0,0,1-.544,0,.6.6,0,0,1-.247-.161L3.048,15.072a.686.686,0,0,1-.2-.507.773.773,0,0,1,.2-.532.717.717,0,0,1,1.038,0L5.3,15.245V14.8A8.761,8.761,0,0,1,6,11.289,9.056,9.056,0,0,1,10.787,6.5a9.1,9.1,0,0,1,7.022,0,9.056,9.056,0,0,1,4.784,4.784,8.761,8.761,0,0,1,.7,3.511,8.682,8.682,0,0,1-2.621,6.379A8.682,8.682,0,0,1,14.3,23.8ZM12.32,18.632a.887.887,0,0,1-.865-.865V14.8a.8.8,0,0,1,.284-.606,1.006,1.006,0,0,1,.7-.26V12.822a1.854,1.854,0,0,1,3.709,0v1.113a1.006,1.006,0,0,1,.7.26.8.8,0,0,1,.284.606v2.967a.887.887,0,0,1-.865.865Zm.865-4.7H15.41V12.822a1.113,1.113,0,1,0-2.225,0Z"
                transform="translate(-2.85 -5.8)"
                fill="#fff"
            />
        </SvgIcon>
    )
}
