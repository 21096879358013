import React from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from '@material-ui/core';
import { Button } from '../Button';

export interface SimpleDialogProps {
  title: string;
  content: string | React.Component | JSX.Element;
  primaryActionText: string;
  onPrimaryAction: () => void;
  secondaryActionText: string;
  onSecondaryAction: () => void;
  open: boolean;
}

export class SimpleDialog extends React.Component<SimpleDialogProps> {
  render() {
    const {
      title,
      content,
      primaryActionText,
      onPrimaryAction,
      secondaryActionText,
      onSecondaryAction,
      open,
    } = this.props;

    return (
      <Dialog
        onClose={onSecondaryAction}
        open={open}
        aria-labelledby="simple-dialog-title"
        aria-describedby="simple-dialog-description"
        disableEscapeKeyDown={true}
        fullWidth
        style={{ padding: 16 }}
      >
        <DialogTitle id="simple-dialog-title">{title}</DialogTitle>
        <DialogContent id="simple-dialog-description">
          <>{content}</>
        </DialogContent>
        <DialogActions style={{ padding: '16px 24px' }}>
          <Button onClick={onSecondaryAction} color="primary" variant="text">
            {secondaryActionText}
          </Button>
          <Button onClick={onPrimaryAction} color="primary" autoFocus>
            {primaryActionText}
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}
