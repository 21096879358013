import React, { FC } from 'react'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import TableCell from '@material-ui/core/TableCell'
import Tooltip from '@material-ui/core/Tooltip'
import TableSortLabel from '@material-ui/core/TableSortLabel'
import Checkbox from '@material-ui/core/Checkbox'
import Typography from '@material-ui/core/Typography'
import { Box, Theme, useTheme } from '@material-ui/core'
import { createStyles, makeStyles } from "@material-ui/core/styles";
import { SortDirection } from "../BaseTypes";

const useStyles = makeStyles((theme: Theme) => createStyles({
    root: {},
    checkbox: {
        color: theme.palette.common.white,
    },
    indeterminate: {
        color: theme.palette.primary.main,
    },
    columnHeaderText: {
        fontWeight: 'bold',
        fontSize: '14px',
        color: theme.palette.customComponent.textColor,
        "&:hover": {
            color: theme.palette.customComponent.listHeaderHoverColor
        }
    }
}))

export interface ListHeaderProps {
    columns: { id: string; label: string; fixed?: boolean; icon?: JSX.Element, action?: () => void }[]
    sortDirection: SortDirection
    sortField: string
    handleSort: (columnId: string) => void
    numRows?: number
    numRowsSelected?: number
    showSelectAll?: boolean
    onSelectAllClick?: any
    freezeFirstColumn: boolean
}

export const ListHeader: FC<ListHeaderProps> =
    ({ sortDirection, sortField, handleSort, columns, numRowsSelected, onSelectAllClick, numRows, showSelectAll = false, freezeFirstColumn}: ListHeaderProps) => {

    const classes = useStyles()
    const theme = useTheme()

    const order = sortDirection === SortDirection.ASC ? 'asc' : 'desc'

    return (
        <TableHead>
            <TableRow>
                {showSelectAll && (
                    <TableCell align="left" padding="normal" style={{ borderBottom: '1px solid #65788B' }}>
                        <Checkbox
                            color="primary"
                            classes={{ root: classes.checkbox, indeterminate: classes.indeterminate }}
                            indeterminate={numRowsSelected > 0 && numRowsSelected < numRows}
                            checked={numRowsSelected === numRows}
                            onChange={onSelectAllClick}
                        />
                    </TableCell>
                )}
                { columns.map((column, index) => {
                    return (
                        <TableCell
                            align="left"
                            padding="normal"
                            style={{
                                borderBottom: '1px solid #65788B',
                                whiteSpace: 'nowrap',
                                ...((freezeFirstColumn && index === 0) && {
                                    position: 'sticky',
                                    left: 0,
                                    backgroundColor: theme.palette.customComponent.listBackgroundColor,
                                    zIndex: theme.zIndex.appBar + 2,
                                })
                            }}
                            key={index}
                            sortDirection={!column.fixed && sortField === column.id ? order : false}
                        >
                            <Tooltip title="Sort" enterDelay={300}>
                                <>
                                    {!column.fixed && (
                                        <TableSortLabel
                                            classes={{
                                                root: classes.columnHeaderText
                                            }}
                                            //style={{ fontWeight: 'bold', color: theme.palette.customComponent.textColor, fontSize: '14px' }}
                                            active={sortField === column.id}
                                            direction={order}
                                            onClick={() => handleSort(column.id)}
                                        >
                                            {column.label}
                                        </TableSortLabel>
                                    )}
                                    {!!column.fixed && (
                                        <Box
                                            display='flex'
                                            justifyContent='flex-start'
                                            alignItems="center"
                                            onClick={() => { if (!!column.action) {column.action()} }}
                                        >
                                            <Typography variant="caption" className={classes.columnHeaderText}>
                                                {column.label}
                                            </Typography>
                                            {!!column.icon && column.icon}
                                        </Box>
                                    )}
                                </>
                            </Tooltip>
                        </TableCell>
                    )
                })}
            </TableRow>
        </TableHead>
    )
}