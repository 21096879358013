import {
  createApi,
  fetchBaseQuery,
  FetchBaseQueryError,
  BaseQueryFn,
  FetchArgs,
} from '@reduxjs/toolkit/dist/query/react';
import {
  EnvironmentVariable,
  Company,
  CompanyInput,
  Document,
  User,
  PurchaseOrder,
  ListParameters,
  ListResult,
  RFQ,
  PurchaseOrderInput,
  PurchaseOrderLineItem,
  PurchaseOrderLineItemInput,
  RFQListParameters,
  Address,
  UserListParameters,
  RFQWithOptions,
  SortDirection,
  PurchaseOrderWithOptions,
  Supplier,
  NCRLog,
  NCRLogInput,
  Notification,
  PurchaseOrderDetails,
  SupplierPurchaseOrder,
  SupplierPurchaseOrderInput,
  PartDAPP,
  ShipmentInput,
  ShipmentDetails,
  Shipment,
  Tag,
  SupplierQuoteDetail,
  SupplierQuoteQuery,
  SupplierQuoteUpload,
  SupplierPurchaseOrderQuery,
  SupplierPurchaseOrderDetail,
  SupplierRFQMetrics,
  SupplierPurchaseOrderMetrics,
  BuyerRFQMetrics,
  BuyerPurchaseOrderMetrics,
  Exception,
  NCRQuery,
  QuoteDetail,
  CreateRFQInput,
  UpdateRFQInput,
  ActionItem,
  ActionItemInput,
  EntityType,
  RFQLineItem,
  BulkRFQLineItemInput,
  DLASolicitation,
  DLAListParameters,
  CompanyMetricTimeFrameData,
  SupplierQuote,
  PurchaseOrderAnalyticsResponse,
  SupplierPurchaseOrderAnalyticsResponse,
  PurchaseOrderAnalyticsQuery,
  SupplierCostAnalyticsQuery,
  ListMeta,
  RFQLineItemGroupDetail,
  SHOW_FEEDBACK,
  SHOW_SAVING_FEEDBACK,
  HIDE_SAVING_FEEDBACK,
  SHOW_COMPLETE_FEEDBACK,
  SHOW_ERROR_FEEDBACK,
} from '../components';
import { environment } from './environment';

const formatParams = listParams => {
  const keysToRemove = ['viewBy'];
  const paramsFormatted = [];
  for (const key in listParams) {
    if (!keysToRemove.includes(key)) {
      if (Array.isArray(listParams[key])) {
        paramsFormatted.push(...listParams[key].map(l => [key, l]));
      } else if (listParams[key] !== '' && listParams[key] !== null) {
        paramsFormatted.push([key, listParams[key].toString()]);
      }
    }
  }
  return paramsFormatted;
};
const baseQuery = fetchBaseQuery({
  baseUrl: environment.get(EnvironmentVariable.API_LAYER_URL),
  prepareHeaders: (headers, { getState }) => {
    // By default, if we have a token in the store, let's use that for authenticated requests
    const token = (getState() as any).auth.authToken;
    if (token) {
      headers.set('authorization', `Bearer ${token}`);
    }
    return headers;
  },
});

const baseQueryWithFeedback: BaseQueryFn<
  string | FetchArgs,
  unknown,
  FetchBaseQueryError
> = async (args, api, extraOptions) => {
  // Since args can be an object or a string
  if (typeof args !== 'string') {
    const isMutation =
      args.method === 'POST' ||
      args.method === 'UPDATE' ||
      args.method === 'PUT' ||
      args.method === 'DELETE';
    if (isMutation) api.dispatch(SHOW_SAVING_FEEDBACK());
    let result = await baseQuery(args, api, extraOptions);
    if (isMutation) {
      api.dispatch(HIDE_SAVING_FEEDBACK());
      if (result.meta.response.status >= 400) {
        api.dispatch(SHOW_ERROR_FEEDBACK());
      } else {
        api.dispatch(SHOW_COMPLETE_FEEDBACK());
      }
    }
    return result;
  } else {
    let result = await baseQuery(args, api, extraOptions);
    return result;
  }
};

export const api = createApi({
  reducerPath: 'syncfabEngine',
  baseQuery: baseQueryWithFeedback,
  tagTypes: [
    'purchaseOrders',
    'purchaseOrderLineItems',
    'supplierPurchaseOrders',
    'ncrLogs',
    'shipments',
    'quotes',
    'rfqs',
    'parts',
    'companies',
    'suppliers',
    'actionItems',
    'notifications',
    'rfqLineItems',
    'solicitations',
    'supplierComments',
    'loggedUser',
    'analytics',
    'publications',
  ],
  endpoints: builder => ({
    // ***** /suppliers endpoints *****

    // POST
    createSupplierComment: builder.mutation<
      null,
      { id: string; comment: string }
    >({
      query: ({ id, comment }) => {
        return {
          url: `/suppliers/${id}/comments`,
          method: 'POST',
          body: { comment: comment },
        };
      },
      invalidatesTags: ['supplierComments'],
    }),

    // PATCH
    acknowledgeVerificationComplete: builder.mutation<
      Partial<Supplier>,
      Partial<Supplier>
    >({
      query: supplierId => {
        return {
          url: `/suppliers/${supplierId}/acknowledgeVerify`,
          method: 'PATCH',
        };
      },
    }),
    supplierVerify: builder.mutation<Supplier, Partial<Supplier>>({
      query: supplier => {
        return {
          url: `/suppliers/${supplier._id}/verify`,
          method: 'PATCH',
          body: supplier,
        };
      },
      invalidatesTags: ['suppliers', 'loggedUser'],
    }),
    savePartialSupplierVerify: builder.mutation<Supplier, Partial<Supplier>>({
      query: supplier => {
        return {
          url: `/suppliers/${supplier._id}/verify/save`,
          method: 'PATCH',
          body: supplier,
        };
      },
      invalidatesTags: ['suppliers', 'loggedUser'],
    }),

    // PUT
    updateSupplier: builder.mutation<Supplier, { supplier: Supplier }>({
      query: ({ supplier }) => ({
        url: `/suppliers/${supplier._id}`,
        method: 'PUT',
        body: supplier,
      }),
      invalidatesTags: ['suppliers', 'supplierComments'],
    }),
    updateSupplierComment: builder.mutation<
      null,
      { id: string; commentId: string; comment: string }
    >({
      query: ({ id, commentId, comment }) => ({
        url: `/suppliers/${id}/comments/${commentId}`,
        method: 'PUT',
        body: { comment: comment },
      }),
      invalidatesTags: ['supplierComments'],
    }),

    // DELETE
    deleteSupplierComment: builder.mutation<
      null,
      { id: string; commentId: string }
    >({
      query: ({ id, commentId }) => ({
        url: `/suppliers/${id}/comments/${commentId}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['supplierComments'],
    }),

    // GET
    getSupplier: builder.query<Supplier, string>({
      query: supplierId => `/suppliers/${supplierId}`,
      providesTags: ['suppliers', 'supplierComments'],
    }),
    getSupplierSearchList: builder.query<
      { data: Supplier[]; total: number },
      {
        // These are strings because they will be stringified
        searchCriteria: string;
        exclusionCriteria: string;
        directSupplierIds: string[];
      } & ListMeta
    >({
      query: params => {
        return {
          url: '/suppliers/search/list',
          method: 'GET',
          params: formatParams(params),
        };
      },
      providesTags: ['suppliers'],
    }),
    getSupplierAddresses: builder.query<Address[], string>({
      query: supplierId => {
        return {
          url: `/suppliers/${supplierId}/addresses`,
        };
      },
    }),
    listSuppliers: builder.query<ListResult<Supplier>, ListParameters>({
      // Extend this ListParams type and extend it.

      query: listParams => {
        // Format arrays as query params by passing params down to URLSearchParams format
        // This is all Redux tool kit does with params is use the URLSearchParams browser API.
        const paramsFormatted = [];
        for (const key in listParams) {
          if (Array.isArray(listParams[key])) {
            paramsFormatted.push(...listParams[key].map(l => [key, l]));
          } else if (listParams[key] !== '' && listParams[key] !== null) {
            paramsFormatted.push([key, listParams[key].toString()]);
          }
        }

        return {
          url: '/suppliers',
          params: paramsFormatted,
        };
      },
    }),

    // END /suppliers endpoints

    // ***** /purchaseOrders Endpoints *****
    // POST
    createPurchaseOrder: builder.mutation<PurchaseOrder, PurchaseOrderInput>({
      query: poInput => ({
        url: '/purchaseOrders',
        method: 'POST',
        body: poInput,
      }),
      invalidatesTags: ['purchaseOrders'],
    }),
    createPOComment: builder.mutation<
      null,
      { id: string; comment: string; poLineItemId?: string }
    >({
      query: ({ id, comment, poLineItemId }) => ({
        url: `/purchaseOrders/${id}/comments`,
        method: 'POST',
        body: { comment: comment, ...(!!poLineItemId && { poLineItemId }) },
      }),
      invalidatesTags: ['purchaseOrders'],
      onQueryStarted: (_arg, { dispatch }) => {
        dispatch(SHOW_FEEDBACK('Saving Comment.'));
      },
      onCacheEntryAdded: (_arg, { dispatch }) => {
        dispatch(SHOW_FEEDBACK('Comment Saved.'));
      },
    }),
    createSupplierPurchaseOrders: builder.mutation<{ message: string }, string>(
      {
        query: poId => ({
          url: `/purchaseOrders/${poId}/supplier`,
          method: 'POST',
        }),
        invalidatesTags: ['supplierPurchaseOrders'],
      }
    ),
    // PATCH
    patchPurchaseOrder: builder.mutation<
      PurchaseOrder,
      { poId: string; poInput: Partial<PurchaseOrderInput> }
    >({
      query: ({ poId, poInput }) => ({
        url: `/purchaseOrders/${poId}`,
        method: 'PATCH',
        body: poInput,
      }),
      invalidatesTags: ['purchaseOrders'],
    }),
    updateSupplierPurchaseOrder: builder.mutation<
      SupplierPurchaseOrder,
      {
        supplierPOId: string;
        supplierPOInput: Partial<SupplierPurchaseOrderInput>;
      }
    >({
      query: ({ supplierPOId, supplierPOInput }) => ({
        url: `/purchaseOrders/supplier/${supplierPOId}`,
        method: 'PATCH',
        body: supplierPOInput,
      }),
      invalidatesTags: ['supplierPurchaseOrders'],
    }),
    // PUT
    sendSupplierPurchaseOrder: builder.mutation<string, string>({
      query: supplierPOId => ({
        url: `/purchaseOrders/supplier/${supplierPOId}/send`,
        method: 'PUT',
      }),
      invalidatesTags: ['purchaseOrders', 'supplierPurchaseOrders'],
    }),
    confirmSupplierPurchaseOrder: builder.mutation<string, string>({
      query: supplierPOId => ({
        url: `/purchaseOrders/supplier/${supplierPOId}/confirm`,
        method: 'PUT',
      }),
      invalidatesTags: ['supplierPurchaseOrders'],
    }),
    updatePOComment: builder.mutation<
      null,
      { id: string; commentId: string; comment: string }
    >({
      query: ({ id, commentId, comment }) => ({
        url: `/purchaseOrders/${id}/comments/${commentId}`,
        method: 'PUT',
        body: { comment: comment },
      }),
      invalidatesTags: ['purchaseOrders'],
    }),
    updatePurchaseOrder: builder.mutation<
      PurchaseOrder,
      { poId: string; poInput: PurchaseOrderInput }
    >({
      query: ({ poId, poInput }) => ({
        url: `/purchaseOrders/${poId}`,
        method: 'PUT',
        body: poInput,
      }),
      invalidatesTags: ['purchaseOrders'],
    }),
    // DELETE
    deletePOComment: builder.mutation<null, { id: string; commentId: string }>({
      query: ({ id, commentId }) => ({
        url: `/purchaseOrders/${id}/comments/${commentId}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['purchaseOrders'],
      onQueryStarted: (_arg, { dispatch }) => {
        dispatch(SHOW_FEEDBACK('Deleting Comment.'));
      },
      onCacheEntryAdded: (_arg, { dispatch }) => {
        dispatch(SHOW_FEEDBACK('Comment Deleted.'));
      },
    }),
    // GET
    listSupplierPurchaseOrders: builder.query<
      ListResult<SupplierPurchaseOrderDetail>,
      SupplierPurchaseOrderQuery
    >({
      query: listParams => {
        return {
          url: '/purchaseOrders/supplier',
          params: formatParams(listParams),
        };
      },
      providesTags: ['supplierPurchaseOrders'],
    }),
    listSupplierPurchaseOrderLineItems: builder.query<
      ListResult<SupplierPurchaseOrderDetail>,
      SupplierPurchaseOrderQuery
    >({
      query: listParams => {
        return {
          url: '/purchaseOrders/supplier/groupByPart',
          params: formatParams(listParams),
        };
      },
    }),
    getSupplierPurchaseOrderMetrics: builder.query<
      SupplierPurchaseOrderMetrics,
      { supplierId: string; timeFrame: CompanyMetricTimeFrameData }
    >({
      query: queryParams => {
        return {
          url: '/purchaseOrders/supplier/metrics',
          params: queryParams,
        };
      },
    }),
    getBuyerPurchaseOrderMetrics: builder.query<
      BuyerPurchaseOrderMetrics,
      {
        companyId: string;
        timeFrame: CompanyMetricTimeFrameData;
      }
    >({
      query: queryParams => {
        return {
          url: '/purchaseOrders/metrics',
          params: queryParams,
        };
      },
    }),
    getPurchaseOrder: builder.query<PurchaseOrderDetails, string>({
      query: poId => `/purchaseOrders/${poId}`,
      providesTags: ['purchaseOrders'],
    }),
    getPurchaseOrderWithOptions: builder.query<
      PurchaseOrderWithOptions,
      string
    >({
      async queryFn(arg, _queryApi, _extraOptions, fetchWithBQ) {
        // get PO
        const poResult = await fetchWithBQ(`/purchaseOrders/${arg}`);
        if (poResult.error) {
          return { error: poResult.error as FetchBaseQueryError };
        }
        const po = poResult.data as PurchaseOrder;
        // TODO need to reuse this, duplicate code
        const buyerContactsResult = await fetchWithBQ(
          `/users?size=0&sortField=firstName&sortDirection=${SortDirection.ASC}&companyId=${po.buyerCompany?.companyId}`
        );
        if (buyerContactsResult.error) {
          return { error: buyerContactsResult.error as FetchBaseQueryError };
        }
        const buyerContacts = (buyerContactsResult.data as ListResult<User>)
          .data;
        const buyerAddressesResult = await fetchWithBQ(
          `/companies/mms/addresses/${po.buyerCompany?.companyId}`
        );
        if (buyerAddressesResult.error) {
          return { error: buyerAddressesResult.error as FetchBaseQueryError };
        }
        const buyerAddresses = (buyerAddressesResult.data as ListResult<
          Address
        >).data;
        return {
          data: {
            po,
            buyerContacts,
            buyerAddresses,
          } as PurchaseOrderWithOptions,
        };
      },
      providesTags: ['purchaseOrders'],
    }),
    listPurchaseOrders: builder.query<
      ListResult<PurchaseOrderDetails>,
      ListParameters
    >({
      query: listParams => {
        return {
          url: '/purchaseOrders',
          params: formatParams(listParams),
        };
      },
      providesTags: ['purchaseOrders'],
    }),
    getPurchaseOrderLineItems: builder.query<PurchaseOrderLineItem[], string>({
      query: poId => {
        if (poId === undefined || poId === null) return { url: null };
        return {
          url: `/purchaseOrders/${poId}/lineItems`,
        };
      },
      providesTags: ['purchaseOrderLineItems'],
    }),
    getSupplierPurchaseOrder: builder.query<
      SupplierPurchaseOrderDetail,
      string
    >({
      query: supplierPOId => {
        return {
          url: `/purchaseOrders/supplier/${supplierPOId}`,
        };
      },
      providesTags: ['supplierPurchaseOrders'],
    }),
    getSupplierPurchaseOrders: builder.query<SupplierPurchaseOrder[], string>({
      query: poId => {
        return {
          url: `/purchaseOrders/${poId}/supplier`,
        };
      },
      providesTags: ['supplierPurchaseOrders'],
    }),
    getSupplierPurchaseOrderDocument: builder.query<any, string>({
      query: supplierPOId => {
        return {
          url: `/purchaseOrders/supplier/${supplierPOId}/generateSupplierPurchaseOrderDocument`,
        };
      },
      providesTags: ['supplierPurchaseOrders'],
    }),
    // END /purchaseOrders

    // ***** /purchaseOrderLineItems Endpoints *****

    // POST
    duplicatePurchaseOrderLineItem: builder.mutation<
      PurchaseOrderLineItem,
      string
    >({
      query: poLineItemId => ({
        url: `/purchaseOrderLineItems/${poLineItemId}/duplicate`,
        method: 'POST',
      }),
      invalidatesTags: ['purchaseOrderLineItems'],
    }),
    createPurchaseOrderLineItem: builder.mutation<
      PurchaseOrderLineItem,
      { poId: string; supplierId: string; partId: string }
    >({
      query: poLineItemInput => ({
        url: `/purchaseOrderLineItems`,
        method: 'POST',
        body: poLineItemInput,
      }),
      invalidatesTags: ['purchaseOrderLineItems'],
    }),

    // PATCH
    patchPurchaseOrderLineItem: builder.mutation<
      PurchaseOrderLineItem,
      {
        poLineItemId: string;
        poLineItemInput: Partial<PurchaseOrderLineItemInput>;
      }
    >({
      query: ({ poLineItemId, poLineItemInput }) => ({
        url: `/purchaseOrderLineItems/${poLineItemId}`,
        method: 'PATCH',
        body: poLineItemInput,
      }),
      invalidatesTags: ['purchaseOrderLineItems'],
    }),

    // PUT
    updatePurchaseOrderLineItem: builder.mutation<
      PurchaseOrderLineItem,
      {
        poLineItemId: string;
        poLineItemInput: Partial<PurchaseOrderLineItemInput>;
      }
    >({
      query: ({ poLineItemId, poLineItemInput }) => ({
        url: `/purchaseOrderLineItems/${poLineItemId}`,
        method: 'PUT',
        body: poLineItemInput,
      }),
      invalidatesTags: ['purchaseOrderLineItems'],
    }),
    cancelPurchaseOrderLineItem: builder.mutation<{ id: string }, string>({
      query: poLineItemId => ({
        url: `/purchaseOrderLineItems/${poLineItemId}/cancel`,
        method: 'PUT',
      }),
      invalidatesTags: ['purchaseOrderLineItems'],
    }),

    // DELETE
    deletePurchaseOrderLineItem: builder.mutation<{ id: string }, string>({
      query: poLineItemId => ({
        url: `/purchaseOrderLineItems/${poLineItemId}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['purchaseOrderLineItems'],
    }),

    // GET
    listPurchaseOrderLineItems: builder.query<
      ListResult<PurchaseOrderLineItem>,
      ListParameters
    >({
      query: listParams => {
        return {
          url: '/purchaseOrderLineItems',
          params: formatParams(listParams),
        };
      },
      providesTags: ['purchaseOrderLineItems'],
    }),
    // END /purchaseOrderLineItems

    // ***** /rfqs endpoints *****

    //POST
    addSupplierQuoteDocument: builder.mutation<
      void,
      { rfqId: string; supplierQuoteUpload: SupplierQuoteUpload }
    >({
      query: ({ rfqId, supplierQuoteUpload }) => ({
        url: `/rfqs/addSupplierQuote/${rfqId}`,
        method: 'POST',
        body: { ...supplierQuoteUpload },
      }),
    }),

    // PUT
    removeSupplierQuoteDocument: builder.mutation<
      void,
      { docId: string; rfqId: string }
    >({
      query: ({ docId, rfqId }) => ({
        url: `/rfqs/supplier/document/${docId}`,
        method: 'PUT',
        body: { rfqId },
      }),
    }),
    addSupplierNoQuote: builder.mutation<
      void,
      { rfqId: string; noQuoteReason: string }
    >({
      query: ({ rfqId, noQuoteReason }) => ({
        url: `/rfqs/logSupplierNoQuote/${rfqId}`,
        method: 'PUT',
        body: { description: noQuoteReason },
      }),
    }),

    // GET
    getRFQDetails: builder.query<any, string>({
      query: rfqId => `/rfqs/rfqDetails/${rfqId}`,
      providesTags: ['rfqs'],
    }),
    // END /rfqs

    // ***** /rfqs/v2 endpoints *****

    // POST
    createRFQ: builder.mutation<RFQ, CreateRFQInput>({
      query: rfqInput => ({
        url: '/rfqs/v2',
        method: 'POST',
        body: rfqInput,
      }),
      invalidatesTags: ['rfqs'],
    }),
    createRFQLineItem: builder.mutation<
      RFQLineItem,
      { rfqId: string; rfqLineItemInput: BulkRFQLineItemInput }
    >({
      query: ({ rfqId, rfqLineItemInput }) => ({
        url: `/rfqs/v2/${rfqId}/lineItems`,
        method: 'POST',
        body: rfqLineItemInput,
      }),
      invalidatesTags: ['rfqs'],
    }),
    createBulkRFQLineItems: builder.mutation<
      RFQLineItem[],
      { rfqId: string; lineItems: BulkRFQLineItemInput[] }
    >({
      query: ({ rfqId, lineItems }) => ({
        url: `/rfqs/v2/${rfqId}/bulkLineItems`,
        method: 'POST',
        body: lineItems,
      }),
      invalidatesTags: ['rfqLineItems'],
    }),
    addRFQLineItemGroup: builder.mutation<null, any>({
      query: ({ rfqId, data }) => ({
        url: `/rfqs/v2/${rfqId}/sendLineItemGroup`,
        method: 'POST',
        body: data,
      }),
      invalidatesTags: ['rfqs', 'rfqLineItems'],
    }),
    duplicateRFQ: builder.mutation<RFQ, { rfqId: string }>({
      query: ({ rfqId }) => ({
        url: `/rfqs/v2/${rfqId}/duplicate`,
        method: 'POST',
      }),
      invalidatesTags: ['rfqLineItems', 'rfqs'],
    }),
    // DELETE
    deleteRFQLineItem: builder.mutation<
      string,
      { rfqId: string; rfqLineItemId: string }
    >({
      query: ({ rfqId, rfqLineItemId }) => ({
        url: `rfqs/v2/${rfqId}/lineItems/${rfqLineItemId}`,
        method: 'DELETE',
      }),
    }),
    // PATCH
    patchRFQ: builder.mutation<
      RFQ,
      { rfqId: string; rfqInput: Partial<UpdateRFQInput> }
    >({
      query: ({ rfqId, rfqInput }) => ({
        url: `/rfqs/v2/${rfqId}`,
        method: 'PATCH',
        body: rfqInput,
      }),
      invalidatesTags: ['rfqs'],
    }),
    updateRFQLineItemGroups: builder.mutation<null, any>({
      query: ({ rfqId, lineItemGroupId, data }) => {
        console.log('params', rfqId, lineItemGroupId, data);
        return {
          url: `/rfqs/v2/${rfqId}/lineItemGroups/${lineItemGroupId}`,
          method: 'PATCH',
          body: data,
        };
      },
      invalidatesTags: ['rfqs', 'rfqLineItems'],
    }),
    // PUT
    updateRFQ: builder.mutation<
      RFQ,
      { rfqId: string; rfqInput: UpdateRFQInput }
    >({
      query: ({ rfqId, rfqInput }) => ({
        url: `/rfqs/v2/${rfqId}`,
        method: 'PUT',
        body: rfqInput,
      }),
      invalidatesTags: ['rfqs'],
    }),
    archiveRFQLineItem: builder.mutation<
      string,
      { rfqId: string; rfqLineItemId: string }
    >({
      query: ({ rfqId, rfqLineItemId }) => ({
        url: `rfqs/v2/${rfqId}/lineItems/${rfqLineItemId}/archive`,
        method: 'PUT',
      }),
    }),
    restoreRFQLineItem: builder.mutation<
      string,
      { rfqId: string; rfqLineItemId: string }
    >({
      query: ({ rfqId, rfqLineItemId }) => ({
        url: `rfqs/v2/${rfqId}/lineItems/${rfqLineItemId}/restore`,
        method: 'PUT',
      }),
    }),

    // GET
    getSupplierQuotesByRFQLineItem: builder.query<
      Array<SupplierQuote>,
      { rfqId: string; lineItemId: string }
    >({
      query: ({ rfqId, lineItemId }) => {
        return {
          url: `/rfqs/v2/${rfqId}/lineItems/${lineItemId}/supplierQuotes`,
        };
      },
      providesTags: ['quotes', 'rfqs', 'rfqLineItems'],
    }),
    getBuyerRFQMetrics: builder.query<
      BuyerRFQMetrics,
      {
        buyerCompanyId: string;
        timeFrame: CompanyMetricTimeFrameData;
      }
    >({
      query: queryParams => {
        return {
          url: '/rfqs/v2/metrics',
          params: queryParams,
        };
      },
    }),
    getRFQ: builder.query<RFQ, string>({
      query: rfqId => `/rfqs/v2/${rfqId}`,
      providesTags: ['rfqs'],
    }),
    getRFQLineItemDetails: builder.query<
      any,
      { rfqId: string; rfqLineItemId: string }
    >({
      query: ({ rfqId, rfqLineItemId }) =>
        `/rfqs/v2/${rfqId}/lineItems/${rfqLineItemId}/details`,
      providesTags: ['rfqs', 'rfqLineItems'],
    }),
    listRFQs: builder.query<ListResult<RFQ>, RFQListParameters>({
      query: listParams => {
        const params = formatParams(listParams);
        return {
          url: '/rfqs/v2/getAll',
          params,
        };
      },
      providesTags: ['rfqs', 'rfqLineItems'],
    }),
    listRFQsSimplified: builder.query<ListResult<RFQ>, RFQListParameters>({
      query: listParams => {
        const params = formatParams(listParams);
        return {
          url: '/rfqs/v2/getSimplified',
          params,
        };
      },
      providesTags: ['rfqs', 'rfqLineItems'],
    }),
    listRFQsByPart: builder.query<ListResult<RFQ>, RFQListParameters>({
      query: listParams => {
        return {
          url: '/rfqs/v2/getAll/groupByPart',
          params: listParams,
        };
      },
    }),
    getRFQLineItemGroups: builder.query<
      Array<RFQLineItemGroupDetail>,
      { rfqId: string }
    >({
      query: ({ rfqId }) => {
        return {
          url: `/rfqs/v2/${rfqId}/lineItemGroups`,
        };
      },
      providesTags: ['quotes', 'rfqs', 'rfqLineItems'],
    }),
    // END /rfqs/v2 endpoints

    // ***** /shipments endpoints *****

    //POST
    createShipment: builder.mutation<Shipment, ShipmentInput>({
      query: shipmentInput => ({
        url: '/shipments',
        method: 'POST',
        body: shipmentInput,
      }),
      invalidatesTags: ['shipments', 'purchaseOrderLineItems'],
    }),

    // PUT
    updateShipment: builder.mutation<
      Shipment,
      { shipmentId: string; shipmentInput: ShipmentInput }
    >({
      query: ({ shipmentId, shipmentInput }) => ({
        url: `/shipments/${shipmentId}`,
        method: 'PUT',
        body: shipmentInput,
      }),
      invalidatesTags: ['shipments', 'purchaseOrderLineItems'],
    }),
    // END /shipments

    // ***** /companies *****
    // POST
    createAddress: builder.mutation<Address, Partial<Address>>({
      query: (addressInput: Partial<Address>) => ({
        url: `/companies/addresses`,
        method: 'POST',
        body: addressInput,
      }),
    }),

    // PATCH
    patchCompany: builder.mutation<
      Company,
      { companyId: string; companyInput: Partial<CompanyInput> }
    >({
      query: ({ companyId, companyInput }) => ({
        url: `/companies/${companyId}`,
        method: 'PATCH',
        body: companyInput,
      }),
      invalidatesTags: ['companies'],
    }),

    // PUT
    updateAddress: builder.mutation<
      Address,
      { addressId: string; addressInput: Partial<Address> }
    >({
      query: ({ addressId, addressInput }) => ({
        url: `/companies/addresses/${addressId}`,
        method: 'PUT',
        body: addressInput,
      }),
    }),
    updateCompany: builder.mutation<
      Company,
      { companyId: string; companyInput: Partial<CompanyInput> }
    >({
      query: ({ companyId, companyInput }) => ({
        url: `/companies/${companyId}`,
        method: 'PUT',
        body: companyInput,
      }),
      invalidatesTags: ['companies'],
    }),

    // DELETE
    deleteAddress: builder.mutation<{ id: string }, string>({
      query: id => ({
        url: `/companies/addresses/${id}`,
        method: 'DELETE',
      }),
    }),

    // GET
    getRFQForPO: builder.query<RFQWithOptions, string>({
      async queryFn(arg, _queryApi, _extraOptions, fetchWithBQ) {
        if (!arg) {
          return {
            data: {
              rfq: null,
              rfqExists: false,
              buyerContacts: [],
              buyerAddresses: [],
            },
          };
        }

        const rfqResult = await fetchWithBQ(`/rfqs/v2/${arg}`);
        if (rfqResult.error) {
          return { error: rfqResult.error as FetchBaseQueryError };
        }
        const rfq = rfqResult.data as RFQ;
        const buyerContactsResult = await fetchWithBQ(
          `/users?size=0&sortField=firstName&sortDirection=${SortDirection.ASC}&companyId=${rfq.buyerCompany?.companyId}`
        );
        if (buyerContactsResult.error) {
          return { error: buyerContactsResult.error as FetchBaseQueryError };
        }
        const buyerContacts = (buyerContactsResult.data as ListResult<User>)
          .data;
        const buyerAddressesResult = await fetchWithBQ(
          `/companies/mms/addresses/${rfq.buyerCompany?.companyId}`
        );
        if (buyerAddressesResult.error) {
          return { error: buyerAddressesResult.error as FetchBaseQueryError };
        }
        const buyerAddresses = (buyerAddressesResult.data as ListResult<
          Address
        >).data;
        return {
          data: {
            rfq,
            rfqExists: true,
            buyerContacts,
            buyerAddresses,
          } as RFQWithOptions,
        };
      },
    }),

    listAddresses: builder.query<
      ListResult<Address>,
      { companyId: string; listParams?: ListParameters }
    >({
      query: ({ companyId, listParams }) => {
        return {
          url: `/companies/mms/addresses/${companyId}`,
          params: listParams,
        };
      },
    }),
    getCompany: builder.query<Company, string>({
      query: (companyId: string) => {
        return `/companies/${companyId}`;
      },
      providesTags: ['companies'],
    }),
    listCompanies: builder.query<ListResult<Company>, ListParameters>({
      query: listParams => {
        return {
          url: '/companies',
          params: listParams,
        };
      },
      providesTags: ['companies'],
    }),
    // END /comapanies

    // ***** /ncrLogs *****

    // POST
    createNCRLog: builder.mutation<NCRLog, NCRLogInput>({
      query: ncrLogInput => ({
        url: '/ncrLogs',
        method: 'POST',
        body: ncrLogInput,
      }),
      invalidatesTags: ['ncrLogs'],
    }),

    // PATCH
    patchNCRLog: builder.mutation<
      NCRLog,
      { ncrLogId: string; ncrLogInput: Partial<NCRLogInput> }
    >({
      query: ({ ncrLogId, ncrLogInput }) => ({
        url: `/ncrLogs/${ncrLogId}`,
        method: 'PATCH',
        body: ncrLogInput,
      }),
      invalidatesTags: ['ncrLogs'],
    }),

    // PUT
    updateNCRLog: builder.mutation<
      Exception,
      { ncrLogId: string; ncrLogInput: NCRLogInput }
    >({
      query: ({ ncrLogId, ncrLogInput }) => ({
        url: `/ncrLogs/${ncrLogId}`,
        method: 'PUT',
        body: ncrLogInput,
      }),
      invalidatesTags: ['ncrLogs'],
    }),

    // DELETE
    deleteNCRLog: builder.mutation<{ id: string }, string>({
      query: id => ({
        url: `/ncrLogs/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['ncrLogs'],
    }),

    // GET
    listNCRLogs: builder.query<ListResult<Exception>, NCRQuery>({
      query: listParams => {
        return {
          url: '/ncrLogs',
          params: formatParams(listParams),
        };
      },
      providesTags: ['ncrLogs'],
    }),
    getNCRLog: builder.query<Exception, string>({
      query: (logId: string) => `/ncrLogs/${logId}`,
      providesTags: ['ncrLogs'],
    }),
    getDownloadNCRLog: builder.query<Exception, string>({
      query: (logId: string) => `/ncrLogs/${logId}/generateExceptionPDF`,
      providesTags: ['ncrLogs'],
    }),
    // END /ncrLogs

    // ***** /parts/v2 *****

    // POST
    createPart: builder.mutation<void, { part: any }>({
      query: ({ part }) => ({
        url: '/parts/v2',
        method: 'POST',
        body: part,
      }),
      invalidatesTags: ['parts'],
    }),

    // PATCH
    updatePart: builder.mutation<void, { partId: string; part: any }>({
      query: ({ partId, part }) => ({
        url: `/parts/v2/${partId}`,
        method: 'PATCH',
        body: part,
      }),
      invalidatesTags: ['parts'],
    }),

    // GET
    listParts: builder.query<ListResult<PartDAPP>, ListParameters>({
      query: listParams => {
        return {
          url: '/parts/v2',
          params: listParams,
        };
      },
    }),
    listPartsSimplified: builder.query<ListResult<PartDAPP>, ListParameters>({
      query: listParams => {
        return {
          url: '/parts/v2/simplified',
          params: listParams,
        };
      },
    }),
    getPart: builder.query<PartDAPP, string>({
      query: partId => {
        return {
          url: `/parts/v2/${partId}`,
        };
      },
      providesTags: ['parts'],
    }),
    searchPart: builder.query<
      PartDAPP,
      { number: string; revision: string; docName: string }
    >({
      query: params => {
        return { url: `parts/v2/search`, params: formatParams(params) };
      },
      providesTags: ['parts'],
    }),
    // END /parts

    // ***** /changeRequests *****

    // POST
    createChangeRequest: builder.mutation<
      void,
      { entityId: string; entityType: EntityType; changeRequest: string }
    >({
      query: ({ entityId, entityType, changeRequest }) => ({
        url: '/changeRequests/',
        method: 'POST',
        body: {
          entityId,
          entityType,
          text: changeRequest,
        },
      }),
    }),

    // END /changeRequests

    // ***** /dla *****

    // PUT
    convertDLASolicitationToRFQ: builder.mutation<{ rfqId: string }, string>({
      query: solicitationId => ({
        url: `/dla/${solicitationId}`,
        method: 'PUT',
      }),
      invalidatesTags: ['rfqs'],
    }),
    noQuoteDLASolicitation: builder.mutation<
      { solicitation: DLASolicitation },
      string
    >({
      query: solicitationId => ({
        url: `/dla/noQuote/${solicitationId}`,
        method: 'PUT',
      }),
      invalidatesTags: ['solicitations'],
    }),

    // GET
    listDLASolicitations: builder.query<
      ListResult<DLASolicitation>,
      DLAListParameters
    >({
      query: listParams => {
        return {
          url: '/dla',
          params: formatParams(listParams),
        };
      },
      providesTags: ['solicitations'],
    }),
    getDLASolicitation: builder.query<DLASolicitation, string>({
      query: solicitationId => {
        return {
          url: `/dla/${solicitationId}`,
        };
      },
      providesTags: ['solicitations'],
    }),

    // END /dla

    // ***** /actionItems *****

    // POST
    createActionItem: builder.mutation<string, ActionItemInput>({
      query: actionItemInput => ({
        url: `/actionItems`,
        method: 'POST',
        body: actionItemInput,
      }),
      invalidatesTags: ['actionItems'],
    }),

    // PUT
    updateActionItem: builder.mutation<
      string,
      { actionItemId: string; actionItemInput: Partial<ActionItemInput> }
    >({
      query: ({ actionItemId, actionItemInput }) => ({
        url: `/actionItems/${actionItemId}`,
        method: 'PUT',
        body: actionItemInput,
      }),
      invalidatesTags: ['actionItems'],
    }),
    completeActionItem: builder.mutation<
      string,
      { actionItemId: string; outcome: string }
    >({
      query: ({ actionItemId, outcome }) => ({
        url: `/actionItems/${actionItemId}/complete`,
        method: 'PUT',
        body: {
          outcome,
        },
      }),
      invalidatesTags: ['actionItems'],
    }),

    // GET
    listActionItems: builder.query<ListResult<ActionItem>, ListParameters>({
      query: listParams => {
        return {
          url: '/actionItems',
          params: listParams,
        };
      },
      providesTags: ['actionItems'],
    }),
    // END /actionItems

    // ***** /users *****

    // POST
    createSupplierUser: builder.mutation<User, Partial<User>>({
      query: body => ({
        url: '/users',
        method: 'POST',
        body: {
          ...body,
          loginRole: 'SUPPLIER',
          roles: ['ADMIN'],
        },
      }),
      invalidatesTags: ['suppliers'],
    }),
    createContact: builder.mutation<User, Partial<User>>({
      query: body => ({
        url: '/users/contact',
        method: 'POST',
        body,
      }),
    }),

    // PATCH
    updateContact: builder.mutation<
      User,
      { id: string; userInput: Partial<User> }
    >({
      query: ({ id, userInput }) => ({
        url: `/users/${id}`,
        method: 'PATCH',
        body: userInput,
      }),
      invalidatesTags: ['suppliers'],
    }),

    // PUT
    activateUser: builder.mutation<User, string>({
      query: id => ({
        url: `/users/${id}/activate`,
        method: 'PUT',
        body: {},
      }),
      invalidatesTags: ['suppliers'],
    }),
    deactivateUser: builder.mutation<User, string>({
      query: id => ({
        url: `/users/${id}/deactivate`,
        method: 'PUT',
        body: {},
      }),
      invalidatesTags: ['suppliers'],
    }),
    userResendInvite: builder.mutation<User, string>({
      query: id => ({
        url: `/users/${id}/resendInvite`,
        method: 'PUT',
        body: {},
      }),
      invalidatesTags: ['suppliers'],
    }),

    // DELETE
    deleteContact: builder.mutation<{ id: string }, string>({
      query: id => ({
        url: `/users/${id}`,
        method: 'DELETE',
      }),
    }),

    // GET
    listUsers: builder.query<ListResult<User>, UserListParameters>({
      query: listParams => {
        return {
          url: '/users',
          params: listParams,
        };
      },
    }),
    getLoggedUser: builder.query<User, void>({
      query: () => {
        return {
          url: '/users/logged',
        };
      },
      providesTags: ['loggedUser'],
    }),
    getSupplierUsers: builder.query<ListResult<User>, string>({
      query: supplierId => {
        return {
          url: `/users?size=0&sortField=firstName&sortDirection=${SortDirection.ASC}&supplierId=${supplierId}`,
        };
      },
    }),
    // END /users

    // ***** /quotes *****

    // POST
    createQuoteLineItem: builder.mutation<any, any>({
      query: ({ quoteId, ...quoteLineItem }) => ({
        url: `/quotes/${quoteId}/quoteLineItems`,
        method: 'POST',
        body: quoteLineItem,
      }),
      invalidatesTags: [
        'purchaseOrderLineItems',
        'quotes',
        'rfqs',
        'rfqLineItems',
      ],
    }),
    createQuote: builder.mutation<any, any>({
      query: quote => ({
        url: `/quotes`,
        method: 'POST',
        body: quote,
      }),
      invalidatesTags: ['quotes', 'rfqs'],
    }),

    // PATCH
    updateQuote: builder.mutation<
      any,
      { quoteId: string; rfqId: string; expiresAt: Date; notes?: string }
    >({
      query: ({ quoteId, ...quote }) => ({
        url: `/quotes/${quoteId}`,
        method: 'PATCH',
        body: quote,
      }),
      invalidatesTags: ['quotes', 'rfqs'],
    }),
    patchQuoteLineItems: builder.mutation<null, any>({
      query: ({
        quoteId,
        quoteLineItemId,
        additionalCharges,
        pricing,
        exceptions,
        notes,
        rfqLineItemId,
        supplierId,
      }) => ({
        url: `/quotes/${quoteId}/quoteLineItems/${quoteLineItemId}`,
        method: 'PATCH',
        body: {
          additionalCharges,
          pricing,
          exceptions,
          notes,
          rfqLineItemId,
          selectedSupplier: {
            supplierId: supplierId,
          },
        },
      }),
      invalidatesTags: [
        'purchaseOrderLineItems',
        'rfqs',
        'quotes',
        'rfqLineItems',
      ],
    }),

    // PUT
    sendQuote: builder.mutation<void, { quoteId: string }>({
      query: ({ quoteId }) => ({
        url: `/quotes/${quoteId}/send`,
        method: 'PUT',
      }),
      invalidatesTags: ['quotes', 'rfqs'],
    }),
    declineQuote: builder.mutation<
      void,
      { quoteId: string; declineReason: string }
    >({
      query: ({ quoteId, declineReason }) => ({
        url: `/quotes/${quoteId}/decline`,
        method: 'PUT',
        body: { declineReason },
      }),
      invalidatesTags: ['quotes', 'rfqs'],
    }),
    acceptQuote: builder.mutation<void, { quoteId: string; poId: string }>({
      query: ({ quoteId, poId }) => ({
        url: `/quotes/${quoteId}/accept`,
        method: 'PUT',
        body: { poId },
      }),
      invalidatesTags: ['quotes', 'rfqs'],
    }),

    // GET
    getQuote: builder.query<QuoteDetail, string>({
      query: quoteId => `/quotes/${quoteId}`,
      providesTags: ['quotes'],
    }),
    // END /quotes

    // ***** /documents *****

    // PATCH
    updateDocument: builder.mutation<
      null,
      { documentId: string; document: Partial<Document> }
    >({
      query: ({ documentId, document }) => ({
        url: `/documents/${documentId}`,
        method: 'PATCH',
        body: document,
      }),
    }),

    // END /documents

    // ***** /notifications *****

    // PUT
    acknowledgeNotification: builder.mutation<string, string>({
      query: notificationId => ({
        url: `/notifications/${notificationId}/acknowledge`,
        method: 'PUT',
      }),
      invalidatesTags: ['notifications'],
    }),

    // GET
    getNotifications: builder.query<Notification[], void>({
      query: () => {
        return {
          url: '/notifications',
        };
      },
      providesTags: ['notifications'],
    }),
    // END /notifications

    // ***** /shipments *****

    // DELETE
    deleteShipment: builder.mutation<null, { shipmentId: string }>({
      query: ({ shipmentId }) => ({
        url: `/shipments/${shipmentId}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['shipments', 'purchaseOrderLineItems'],
    }),

    // GET
    listShipments: builder.query<ListResult<ShipmentDetails>, ListParameters>({
      query: listParams => {
        return {
          url: '/shipments',
          params: listParams,
        };
      },
      providesTags: ['shipments'],
    }),
    getShipment: builder.query<ShipmentDetails, string>({
      query: shipmentId => {
        return {
          url: `/shipments/${shipmentId}`,
        };
      },
      providesTags: ['shipments'],
    }),
    // END /shipments

    // ***** /publications *****

    // PUT
    updatePublicationsDocuments: builder.mutation<
      { _id: string; documents: Array<Document> },
      { _id: string; documentIds: Array<string> }
    >({
      query: ({ _id, documentIds }) => {
        return {
          url: '/publications',
          method: 'PUT',
          body: {
            _id,
            documentIds,
          },
        };
      },
      invalidatesTags: ['publications'],
    }),

    // GET
    getPublicationsDocuments: builder.query<
      { data: { _id: string; documents: Array<Document> } },
      ListMeta
    >({
      query: params => {
        return {
          url: '/publications',
          method: 'GET',
          params,
        };
      },
      providesTags: ['publications'],
    }),
    // END /publications

    // ***** /analytics *****

    // GET
    getSupplierCostAnalytics: builder.query<
      SupplierPurchaseOrderAnalyticsResponse,
      SupplierCostAnalyticsQuery
    >({
      query: params => ({
        url: '/analytics/supplierCosts',
        params,
        method: 'GET',
        providesTags: ['analytics'],
      }),
    }),
    getAnalyticsPurchaseOrder: builder.query<
      PurchaseOrderAnalyticsResponse,
      PurchaseOrderAnalyticsQuery
    >({
      query: params => ({
        url: '/analytics/purchaseOrders',
        params,
        method: 'GET',
      }),
      providesTags: ['analytics'],
    }),
    // END /analytics

    // ***** /supplierQuotes *****

    // GET
    getSupplierQuotes: builder.query<
      ListResult<SupplierQuoteDetail>,
      SupplierQuoteQuery
    >({
      query: listParams => {
        return {
          url: '/supplierQuotes',
          params: formatParams(listParams),
        };
      },
    }),
    getSupplierQuotesLineItems: builder.query<
      ListResult<SupplierQuoteDetail>,
      SupplierQuoteQuery
    >({
      query: listParams => {
        return {
          url: '/supplierQuotes/groupByPart',
          params: formatParams(listParams),
        };
      },
    }),
    getSupplierRFQMetrics: builder.query<
      SupplierRFQMetrics,
      { supplierId: string; timeFrame: CompanyMetricTimeFrameData }
    >({
      query: queryParams => {
        return {
          url: '/supplierQuotes/metrics',
          params: queryParams,
        };
      },
    }),
    // END /supplierQuotes

    // ***** /tags *****

    // GET
    getSupplierTags: builder.query<ListResult<Tag>, void>({
      query: () => {
        return {
          url: '/tags/list',
        };
      },
    }),
    // END /tags
  }),
});

export const {
  useCreateQuoteLineItemMutation,
  useCreateSupplierUserMutation,
  useCreateContactMutation,
  useDeleteContactMutation,
  useUpdateContactMutation,
  useUserResendInviteMutation,
  useActivateUserMutation,
  useDeactivateUserMutation,
  useGetSupplierQuery,
  useUpdateSupplierMutation,
  useGetSupplierQuotesQuery,
  useGetSupplierQuotesLineItemsQuery,
  useGetSupplierQuotesByRFQLineItemQuery,
  useListSupplierPurchaseOrdersQuery,
  useListSupplierPurchaseOrderLineItemsQuery,
  useGetSupplierRFQMetricsQuery,
  useGetSupplierPurchaseOrderMetricsQuery,
  useGetBuyerPurchaseOrderMetricsQuery,
  useGetBuyerRFQMetricsQuery,
  useGetRFQQuery,
  useGetRFQDetailsQuery,
  useGetQuoteQuery,
  useGetRFQForPOQuery,
  useCreateRFQMutation,
  useUpdateRFQMutation,
  usePatchRFQMutation,
  useDuplicateRFQMutation,
  useCreateRFQLineItemMutation,
  useCreateBulkRFQLineItemsMutation,
  useDeleteRFQLineItemMutation,
  useArchiveRFQLineItemMutation,
  useRestoreRFQLineItemMutation,
  useGetPurchaseOrderQuery,
  useGetPurchaseOrderWithOptionsQuery,
  useGetPurchaseOrderLineItemsQuery,
  useGetSupplierPurchaseOrderQuery,
  useGetSupplierPurchaseOrdersQuery,
  useCreateSupplierPurchaseOrdersMutation,
  useUpdateSupplierPurchaseOrderMutation,
  useSendSupplierPurchaseOrderMutation,
  useConfirmSupplierPurchaseOrderMutation,
  useListShipmentsQuery,
  useGetShipmentQuery,
  useCreateShipmentMutation,
  useUpdateShipmentMutation,
  useDeleteShipmentMutation,
  useListPurchaseOrdersQuery,
  useCreatePurchaseOrderMutation,
  useUpdatePurchaseOrderMutation,
  usePatchPurchaseOrderMutation,
  useListPurchaseOrderLineItemsQuery,
  useCreatePurchaseOrderLineItemMutation,
  usePatchPurchaseOrderLineItemMutation,
  useUpdatePurchaseOrderLineItemMutation,
  useDeletePurchaseOrderLineItemMutation,
  useDuplicatePurchaseOrderLineItemMutation,
  useCancelPurchaseOrderLineItemMutation,
  useListRFQsQuery,
  useListRFQsSimplifiedQuery,
  useListRFQsByPartQuery,
  useListUsersQuery,
  useListAddressesQuery,
  useCreateAddressMutation,
  useUpdateAddressMutation,
  useDeleteAddressMutation,
  useGetCompanyQuery,
  useUpdateCompanyMutation,
  usePatchCompanyMutation,
  useUpdateDocumentMutation,
  useListCompaniesQuery,
  useGetLoggedUserQuery,
  useCreatePOCommentMutation,
  useUpdatePOCommentMutation,
  useDeletePOCommentMutation,
  useListSuppliersQuery,
  useGetSupplierAddressesQuery,
  useGetSupplierUsersQuery,
  useGetSupplierTagsQuery,
  useListNCRLogsQuery,
  useGetNCRLogQuery,
  useGetDownloadNCRLogQuery,
  useCreateNCRLogMutation,
  useUpdateNCRLogMutation,
  usePatchNCRLogMutation,
  useDeleteNCRLogMutation,
  useListPartsQuery,
  useGetPartQuery,
  useUpdatePartMutation,
  useCreatePartMutation,
  useCreateChangeRequestMutation,
  useDeclineQuoteMutation,
  useAcceptQuoteMutation,
  useAddSupplierQuoteDocumentMutation,
  useRemoveSupplierQuoteDocumentMutation,
  useAddSupplierNoQuoteMutation,
  useListActionItemsQuery,
  useCreateActionItemMutation,
  useUpdateActionItemMutation,
  useCompleteActionItemMutation,
  useGetNotificationsQuery,
  useSendQuoteMutation,
  useAcknowledgeNotificationMutation,
  useSupplierVerifyMutation,
  useSavePartialSupplierVerifyMutation,
  useAcknowledgeVerificationCompleteMutation,
  useCreateSupplierCommentMutation,
  useUpdateSupplierCommentMutation,
  useDeleteSupplierCommentMutation,
  useListDLASolicitationsQuery,
  useGetDLASolicitationQuery,
  useConvertDLASolicitationToRFQMutation,
  useNoQuoteDLASolicitationMutation,
  usePatchQuoteLineItemsMutation,
  useGetSupplierCostAnalyticsQuery,
  useGetAnalyticsPurchaseOrderQuery,
  useCreateQuoteMutation,
  useUpdateQuoteMutation,
  useGetRFQLineItemDetailsQuery,
  useUpdatePublicationsDocumentsMutation,
  useGetPublicationsDocumentsQuery,
  useAddRFQLineItemGroupMutation,
  useUpdateRFQLineItemGroupsMutation,
  useGetRFQLineItemGroupsQuery,
  useGetSupplierSearchListQuery,
  useSearchPartQuery,
  useLazyGetPartQuery,
  useLazySearchPartQuery,
  useListPartsSimplifiedQuery,
} = api;
