import React from 'react'
import { Helmet } from 'react-helmet'
import { Map } from 'immutable'
import { withNamespaces, WithNamespaces } from 'react-i18next'
import { Dispatch } from 'redux'
import { WithStyles } from '@material-ui/core/styles'
import { connect } from 'react-redux'
import Typography from '@material-ui/core/Typography'
import { ForgotPasswordForm } from './components/ForgotPasswordForm'
import { Link } from 'react-router-dom'
import { Avatar, Box, Card, Grid, withStyles } from '@material-ui/core'
import { CenteredContainer } from '../../../layout/Container'
import { SUPPLIER_FORGOT_PASSWORD } from '../../../components/supplier/Actions'
import { ResetIcon } from '@syncfab/machine'

const styles = theme => ({
    container: {
        padding: theme.spacing(2),
    },
    card: {
        width: '100%',
        margin: 'auto',
        padding: theme.spacing(2),
    },
    title: {
        color: theme.palette.common.white,
        fontSize: theme.spacing(3),
        marginLeft: 12,
    },
    loginText: {
        color: theme.palette.common.white,
        'text-align': 'center',
        marginTop: theme.spacing(2),
    },
    loginLink: {
        margin: 0,
        padding: 0,
        'text-decoration': 'none',
        color: theme.palette.primary.light,
    },
    header: {
        display: 'flex',
    },
    small: {
        width: theme.spacing(4),
        height: theme.spacing(4),
        backgroundColor: theme.palette.background.default,
    },
    icon: {
        fontSize: theme.spacing(2),
        color: theme.palette.common.white,
    },
})

interface ForgotPasswordProps extends WithNamespaces, WithStyles {
    forgotPassword: (email: string) => void
}

class ForgotPasswordView extends React.Component<ForgotPasswordProps> {
    constructor(props: ForgotPasswordProps) {
        super(props)
        this.onSubmit = this.onSubmit.bind(this)
    }

    public render() {
        const { classes, t } = this.props
        return (
            <CenteredContainer>
                <Helmet>
                    <title>{`${t('forget-password-header')}`}</title>
                </Helmet>
                <Grid container justifyContent="center" className={classes.container}>
                    <Grid item xs={12} sm={6} md={4}>
                        <Card className={classes.card}>
                            <Box className={classes.header}>
                                <Avatar className={classes.small}>
                                    <ResetIcon className={classes.icon} />
                                </Avatar>
                                <Typography variant="h2" gutterBottom className={classes.title}>
                                    {`${t('forget-password-title')}`}
                                </Typography>
                            </Box>

                            <ForgotPasswordForm onSubmit={this.onSubmit as any} />
                            <Typography variant="caption" className={classes.loginText}>
                                <Link to="/suppliers/login" className={classes.loginLink}>
                                    {`${t('forget-password-login')}`}
                                </Link>
                            </Typography>
                        </Card>
                    </Grid>
                </Grid>
            </CenteredContainer>
        )
    }

    private onSubmit(values: Map<string, string>) {
        const { email } = values.toJS() as any
        this.props.forgotPassword(email)
    }
}

const mapDispatchToProps = (dispatch: Dispatch) => {
    return {
        forgotPassword: (email: string) => dispatch(SUPPLIER_FORGOT_PASSWORD.actions.TRIGGER({ email })),
    }
}

const forgotPasswordWithTranslations = withNamespaces()(withStyles(styles)(ForgotPasswordView))

export const SupplierForgotPassword = connect(null, mapDispatchToProps)(forgotPasswordWithTranslations)
