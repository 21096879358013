import FileSaver from 'file-saver';
import { environment, EnvironmentVariable } from '../environment/Environment';

export enum DownloadType {
  PDF = 'application/pdf',
}

const getDocumentAsBlob = async (
  url: string,
  token: string
): Promise<{ data: Blob; headers: Headers }> => {
  const response = await fetch(
    `${environment.get(EnvironmentVariable.API_LAYER_URL)}${url}`,
    {
      headers: {
        authorization: `Bearer ${token}`,
      },
    }
  );
  const blob = await response.blob();
  return { data: blob, headers: response.headers };
};
export const retrievePublication = async (
  documentId: string,
  token: string
): Promise<string> => {
  return retrieveDocumentFromURL(
    `/documents/download/publications/${documentId}`,
    token
  );
};

export const retrieveDocument = async (
  documentId: string,
  token: string,
  entity?: string,
  entityId?: string
): Promise<string> => {
  return !!entity && !!entityId
    ? retrieveDocumentFromURL(
        `/documents/download/${documentId}?entity=${entity}&entityId=${entityId}`,
        token
      )
    : retrieveDocumentFromURL(`/documents/download/${documentId}`, token);
};

export const retrieveDocumentFromURL = async (
  url: string,
  token: string
): Promise<string> => {
  const documentBlob = (await getDocumentAsBlob(url, token)).data;
  return URL.createObjectURL(
    new Blob([documentBlob], { type: DownloadType.PDF })
  );
};

export const downloadDocumentFromURL = async (
  url: string,
  token: string,
  filename: string
) => {
  const blobHref = await retrieveDocumentFromURL(url, token);
  let downloadLink = document.createElement('a');
  downloadLink.href = blobHref;
  downloadLink.download = filename;
  document.body.appendChild(downloadLink);
  downloadLink.click();
  document.body.removeChild(downloadLink);
};

export const downloadDocument = async (
  documentId: string,
  token: string,
  entity?: string,
  entityId?: string
) => {
  const url =
    !!entity && !!entityId
      ? `/documents/download/${documentId}?entity=${entity}&entityId=${entityId}`
      : `/documents/download/${documentId}`;
  const documentResponse = await getDocumentAsBlob(url, token);
  const name = /^attachment; filename="(.*)"$/.exec(
    documentResponse.headers.get('Content-Disposition')
  )![1];
  FileSaver.saveAs(new Blob([documentResponse.data]), name);
};
export const downloadPublication = async (
  documentId: string,
  token: string
) => {
  const url = `/documents/download/publications/${documentId}`;
  const documentResponse = await getDocumentAsBlob(url, token);
  const name = /^attachment; filename="(.*)"$/.exec(
    documentResponse.headers.get('Content-Disposition')
  )![1];
  FileSaver.saveAs(new Blob([documentResponse.data]), name);
};
