import { date, object, array, string } from 'yup';
import { Location } from '../company';
import { CompanySize, Industry, MarketingChannel } from '../company';
import { ManufacturingProcesses, Certification } from '../quote';
import { PartFinishes, PartMaterial } from '../part';
import { Document } from '../document/Document';
import { ValueLabelOption } from '../ui';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import _ from 'lodash-es';
// import { LanguageValueLabelOptions } from '../data';

import {
  // transformEntryToValueLabel,
  // transformArrayEntryToValueLabel,
  toLowerSplit,
} from '../ui';
import { UserIdAndName } from '../BaseTypes';
export interface SupplierSearchCriteria {
  states?: string[];
  manufacturingProcesses?: string[];
  certifications?: string[];
  materials?: string[];
  materialPreferences?: string[];
  finishCapabilities?: string[];
  affiliateOrganizations?: string[];
  productPreferences?: string[];
  country?: string[];
  lotSize: string[];
  leadTime: string[];
  supplierIds?: any[] | [];
}

// William Defined here for suppliers/GET route
export interface SupplierListSearchCriteria {
  states?: string;
  manufacturingProcesses?: ManufacturingProcesses[];
  certifications?: Certification[];
  material?: PartMaterial[];
  finishCapabilities?: PartFinishes[];
  affiliateOrganizations?: string[];
  productPreferences?: string[];
  country?: string;
  leadTime?: string[];
  lotSize?: string[];
  companyLanguages?: string[];
  stateLanguages?: string[];
  documentation?: string[];
  filter: string;
  page: string;
  size: string;
  sortDirection: string;
  sortField: string;
  total: string;
}

export enum SupplierState {
  NEW = 'NEW',
  ACTIVE_NEED_INFO = 'ACTIVE_NEED_INFO',
  INFO_SUBMITTED = 'INFO_SUBMITTED',
  ACTIVE = 'ACTIVE',
  DEACTIVATED = 'DEACTIVATED',
}

export enum SupplierFeaturePackages {
  BASIC = 'BASIC',
  PREMIUM = 'PREMIUM',
}

export const generateSupplierFeaturePackageOptions = () => [
  { value: SupplierFeaturePackages.BASIC, label: 'Basic' },
  { value: SupplierFeaturePackages.PREMIUM, label: 'Premium' },
];

export const SupplierStateOptions = [
  {
    value: SupplierState.NEW,
    label: 'New',
  },
  {
    value: SupplierState.ACTIVE_NEED_INFO,
    label: 'Active Need Info',
  },
  {
    value: SupplierState.INFO_SUBMITTED,
    label: 'Info Submitted',
  },
  {
    value: SupplierState.ACTIVE,
    label: 'Active',
  },
  {
    value: SupplierState.DEACTIVATED,
    label: 'Deactivated',
  },
];

export const SupplierReceiveRFQArray = [SupplierState.ACTIVE];

export const SupplierStateActiveArray = [
  SupplierState.ACTIVE,
  SupplierState.INFO_SUBMITTED,
  SupplierState.ACTIVE_NEED_INFO,
];

export enum SupplierRiskLevel {
  TIER_1 = 'GOLD',
  TIER_2 = 'SILVER',
  TIER_3 = 'BRONZE',
}

export enum SupplierCalibrationInstrument {
  CALIPERS = 'CALIPERS',
  MICROMETERS = 'MICROMETERS',
  GAUGE_BLOCKS = 'GAUGE_BLOCKS',
  COORDINATE_MEASURING_MACHINES = 'COORDINATE_MEASURING_MACHINES (CMM)',
  OPTICAL_COMPARATORS = 'OPTICAL_COMPARATORS',
  SURFACE_ROUGHNESS_TESTERS = 'SURFACE_ROUGHNESS_TESTERS',
  HARDNESS_TESTERS = 'HARDNESS_TESTERS',
  SPECTROMETERS = 'SPECTROMETERS',
  VISION_SYSTEMS_AND_IMAGE_DIMENSION_MEASUREMENT_SYSTEMS = 'VISION_SYSTEMS_AND_IMAGE_DIMENSION_MEASUREMENT_SYSTEMS',
  X_RAY_AND_CT_SCANNING_EQUIPMENT = 'X_RAY_AND_CT_SCANNING_EQUIPMENT',
  LASER_SCANNERS = 'LASER_SCANNERS',
}

export const CalibrationInstrumentOptions = [
  {
    value: SupplierCalibrationInstrument.CALIPERS,
    label: 'Calipers',
  },
  {
    value: SupplierCalibrationInstrument.MICROMETERS,
    label: 'Micrometers',
  },
  {
    value: SupplierCalibrationInstrument.GAUGE_BLOCKS,
    label: 'Gauge Blocks',
  },
  {
    value: SupplierCalibrationInstrument.COORDINATE_MEASURING_MACHINES,
    label: 'Coordinate Measuring Machines (CMC)',
  },
  {
    value: SupplierCalibrationInstrument.OPTICAL_COMPARATORS,
    label: 'Optical Comparators',
  },
  {
    value: SupplierCalibrationInstrument.SURFACE_ROUGHNESS_TESTERS,
    label: 'Surface Roughness Testers',
  },
  {
    value: SupplierCalibrationInstrument.HARDNESS_TESTERS,
    label: 'Hardness Testers',
  },
  {
    value: SupplierCalibrationInstrument.SPECTROMETERS,
    label: 'Spectrometers',
  },
  {
    value:
      SupplierCalibrationInstrument.VISION_SYSTEMS_AND_IMAGE_DIMENSION_MEASUREMENT_SYSTEMS,
    label: 'Vision Systems And Image Dimension Measurement Systems',
  },
  {
    value: SupplierCalibrationInstrument.X_RAY_AND_CT_SCANNING_EQUIPMENT,
    label: 'X-Ray And CT Scanning Equipment',
  },
  {
    value: SupplierCalibrationInstrument.LASER_SCANNERS,
    label: 'LASER Scanners',
  },
];

export enum SupplierCompanyType {
  MANUFACTURER = 'MANUFACTURER',
  FINISHING_FACILITY_ONLY = 'FINISHING_FACILITY_ONLY',
  RAW_MATERIAL_VENDOR = 'RAW_MATERIAL_VENDOR',
  DISTRIBUTOR = 'DISTRIBUTOR',
}

export const SupplierCompanyTypeOptions = [
  {
    value: SupplierCompanyType.MANUFACTURER,
    label: 'Manufacturer',
  },
  {
    value: SupplierCompanyType.FINISHING_FACILITY_ONLY,
    label: 'Finishing Facility Only',
  },
  {
    value: SupplierCompanyType.RAW_MATERIAL_VENDOR,
    label: 'Raw Material Vendor',
  },
  {
    value: SupplierCompanyType.DISTRIBUTOR,
    label: 'Distributor',
  },
];

export enum BusinessClassification {
  SMALL_BUSINESS = 'SMALL_BUSINESS',
  WOMAN_OWNED_SMALL_BUSINESS = 'WOMAN_OWNED_SMALL_BUSINESS',
  ECONOMICALLY_DISADVANTAGED_WOMAN_OWNED_SMALL_BUSINESS = 'ECONOMICALLY_DISADVANTAGED_WOMAN_OWNED_SMALL_BUSINESS',
  VETERAN_OWNED_SMALL_BUSINESS = 'VETERAN_OWNED_SMALL_BUSINESS',
  SERVICE_DISABLED_VETERAN_OWNED_SMALL_BUSINESS = 'SERVICE_DISABLED_VETERAN_OWNED_SMALL_BUSINESS',
  SMALL_DISADVANTAGED_BUSINESS = 'SMALL_DISADVANTAGED_BUSINESS',
  EIGHT_A_BUSINESS_DEVELOPMENT_PROGRAM = '8_A_BUSINESS_DEVELOPMENT_PROGRAM',
  HUBZONE_SMALL_BUSINESS = 'HUBZONE_SMALL_BUSINESS',
  ABILITYONE_PROGRAM = 'ABILITYONE_Program',
}
export const BusinessClassificationOptions = [
  {
    value: BusinessClassification.SMALL_BUSINESS,
    label: 'Small Business (SB)',
  },
  {
    value: BusinessClassification.WOMAN_OWNED_SMALL_BUSINESS,
    label: 'Woman-Owned Small Business (WOSB)',
  },
  {
    value:
      BusinessClassification.ECONOMICALLY_DISADVANTAGED_WOMAN_OWNED_SMALL_BUSINESS,
    label: 'Economically Disadvantaged Woman Owned Small Business (EDWOSB)',
  },
  {
    value: BusinessClassification.VETERAN_OWNED_SMALL_BUSINESS,
    label: 'Veteran-Owned Small Business (VOSB)',
  },
  {
    value: BusinessClassification.SERVICE_DISABLED_VETERAN_OWNED_SMALL_BUSINESS,
    label: 'Service-Disabled Veteran Owned Small Business (SDVOSB)',
  },
  {
    value: BusinessClassification.SMALL_DISADVANTAGED_BUSINESS,
    label: 'Small Disadvantaged Business (SDB)',
  },
  {
    value: BusinessClassification.EIGHT_A_BUSINESS_DEVELOPMENT_PROGRAM,
    label: '8(a) Business Development Program',
  },
  {
    value: BusinessClassification.HUBZONE_SMALL_BUSINESS,
    label: 'HUBZone (Historically Underutilized Business Zones) Small Business',
  },
  {
    value: BusinessClassification.ABILITYONE_PROGRAM,
    label: 'AbilityOne Program',
  },
];

interface Note {
  commentId: string;
  _id: string;
  note?: string;
  createdBy: UserIdAndName;
  createdAt: string;
}

type YesOrNo = 'Yes' | 'No' | null | string;

export interface Supplier {
  _id: string;
  cageCode?: string;
  businessType?: string;
  businessClassification?: string;
  companyType?: string;
  createdAt: Date;
  updatedAt?: Date;
  deletedAt?: Date;
  state: SupplierState;
  firstName: string;
  lastName: string;
  primaryContact?: string;
  phoneNumber: string;
  email: string;
  title: string;
  companyName: string;
  companyNameAlt: string;
  location: Location;
  industry?: Industry;
  size?: CompanySize;
  manufacturingProcesses?: ManufacturingProcesses[];
  manufacturing?: ManufacturingProcesses[];
  finishes?: PartFinishes[];
  finishing?: PartFinishes[];
  certifications?: Certification[];
  materials?: PartMaterial[];
  capacityDocument?: Document[];
  qualityQuestionnaire?: Document[];
  heardAboutUs: MarketingChannel;
  productPreference: any[];
  affiliateOrganizations?: any[];
  organizations?: any[];
  companyId?: string;
  geoLocation?: any;
  preferredLanguage?: any[];
  materialPreferences?: any[];
  language?: any[];
  rfqSentDate?: any;
  JCPCertified?: any;
  multipleSite?: any;
  lotSize?: any;
  leadTime?: any;
  teams?: any[];
  supplierId?: string;
  notes?: Note[];
  riskLevel?: string;
  supplierInformedOfSupplierStateUpdate?: boolean;
  rfqReview?: YesOrNo;
  rfqDedicated?: YesOrNo;
  rfqDedicatedUser?: string;
  procurementFlow?: YesOrNo;
  procurementSub?: YesOrNo;
  procurementQuantityReview?: YesOrNo;
  procurementDedicated?: YesOrNo;
  procurementDedicatedUser?: string;
  manufacturingInstructions?: YesOrNo;
  manufacturingFOD?: YesOrNo;
  manufacturingCapabilitiesCut?: YesOrNo;
  nonConformingProcess?: YesOrNo;
  nonConformingCorrection?: YesOrNo;
  nonConformingFlowDown?: YesOrNo;
  inspectionCalibrationUtilized?: YesOrNo;
  inspectionCalibratedPeriodically?: YesOrNo;
  inspectionCalibratedUser?: string;
  inspectionCalibratedInHouse?: YesOrNo;
  inspectionRecords?: YesOrNo;
  inspectionPerson?: YesOrNo;
  inspectionPersonUser?: string;
  materialCustomer?: YesOrNo;
  materialTraceable?: YesOrNo;
  materialReceipt?: YesOrNo;
  materialStored?: YesOrNo;
  materialRequirements?: YesOrNo;
  documentIds?: string[];
  documents?: Document[];
}

export const generateSupplierOption = (
  supplierId: string,
  supplierName: string
): ValueLabelOption => {
  return {
    value: supplierId,
    label: `${supplierId} - ${supplierName}`,
  };
};

export const generateSupplierOptions = (
  suppliers: Supplier[]
): ValueLabelOption[] => {
  return suppliers.map(supplier =>
    generateSupplierOption(supplier._id, supplier.companyName)
  );
};

export const passThroughAsIsKeys = [
  '_id',
  'cageCode',
  'companyId',
  'companyName',
  'createdAt',
  'documents',
  'documentIds',
  'geoLocation',
  'location',
  'rfqReview',
  'rfqDedicated',
  'rfqDedicatedUser',
  'procurementFlow',
  'procurementSub',
  'procurementQuantityReview',
  'procurementDedicated',
  'procurementDedicatedUser',
  'manufacturingInstructions',
  'manufacturingFOD',
  'manufacturingCapabilitiesCut',
  'nonConformingProcess',
  'nonConformingCorrection',
  'nonConformingFlowDown',
  'inspectionCalibrationUtilized',
  'inspectionCalibratedPeriodically',
  'inspectionCalibratedUser',
  'inspectionCalibratedInHouse',
  'inspectionRecords',
  'inspectionPerson',
  'inspectionPersonUser',
  'materialCustomer',
  'materialTraceable',
  'materialReceipt',
  'materialStored',
  'materialRequirements',
  'notes',
  'website',
];

//If a default value is needed, make it an empty array for these keys
export const emptyArrayDefaultValues = [
  'affiliateOrganizations',
  'businessClassification',
  'certificateExpirations',
  'certifications',
  'finishes',
  'industry',
  'leadTime',
  'lotSize',
  'manufacturingProcesses',
  'materials',
  'productPreference',
  'inspectionCalibratedUser',
];

// If a default is needed, use an empty array for these keys (typically multi select AutoComplete)
export const nullDefaultValues = [
  // 'teams',
  'companyType',
  'rfqReview',
  'rfqDedicated',
  'rfqDedicatedUser',
  'procurementFlow',
  'procurementSub',
  'procurementQuantityReview',
  'procurementDedicated',
  'procurementDedicatedUser',
  'manufacturingInstructions',
  'manufacturingFOD',
  'manufacturingCapabilitiesCut',
  'nonConformingProcess',
  'nonConformingCorrection',
  'nonConformingFlowDown',
  'inspectionCalibrationUtilized',
  'inspectionCalibratedPeriodically',
  'inspectionCalibratedInHouse',
  'inspectionRecords',
  'inspectionPerson',
  'inspectionPersonUser',
  'materialCustomer',
  'materialTraceable',
  'materialReceipt',
  'materialStored',
  'materialRequirements',
  'cageCode',
  'documentIds',
];
export const finishesRequired = [SupplierCompanyType.FINISHING_FACILITY_ONLY];
export const manufacturingProcessesRequired = [
  SupplierCompanyType.MANUFACTURER,
];
export const leadTimeOptionsRequired = [
  SupplierCompanyType.MANUFACTURER,
  SupplierCompanyType.DISTRIBUTOR,
];
export const materialsRequired = [
  SupplierCompanyType.MANUFACTURER,
  SupplierCompanyType.DISTRIBUTOR,
  SupplierCompanyType.RAW_MATERIAL_VENDOR,
];

const YesOrNoSchema = string().oneOf(['Yes', 'No']);

export const translateEntryToLowerValueLabel = entry => {
  if (entry === null || entry === undefined) return null;
  return {
    value: entry,
    label: toLowerSplit(entry),
  };
};

export const transformFormDataToSubmitFormat = (
  formData,
  keySubset = undefined
) => {
  const dataToSubmit = !!keySubset
    ? _.pick(formData, keySubset)
    : { ...formData };
  return dataToSubmit;
};

export type valueOf<T> = T[keyof T];

// Syntactic sugar for whether the field should be optional or multiple allowed
// adding opt onto a oneOf schema, DOES NOT ADD null or undefined to that allowable oneOf's and causes errors
const NullableYesOrNoSchema = string()
  .nullable()
  .oneOf([null, 'Yes', 'No']);
const opt = sch => sch.nullable();
const multiple = sch => array(sch);
const minOne = (sch, reason = undefined) => sch.min(1, reason);
const minN = (sch, n, reason = undefined) => sch.min(n, reason);
const multipleMinOne = (sch, reason = undefined) => array(sch).min(1, reason);
// Supplier needs to be able to save user state as deactivated without filling in rest of fields.
const allowNullWhenStateIsDeactivated = (
  sch,
  deactiveState = s => s.nullable(),
  notDeactiveState = s => s
) =>
  sch.when('state', {
    is: SupplierState.DEACTIVATED,
    then: deactiveState,
    otherwise: notDeactiveState,
  });

export const SupplierVerificationObject = {
  // Other SupplierDetails on MMS only (filtered out by step keys)
  companyName: string().required(),
  state: string().required(),
  riskLevel: opt(string()),
  multipleSite: opt(string()),
  size: opt(string()),
  featurePackage: string().required('Select a feature level.'),
  heardAboutUs: string().required(),
  website: opt(string()),
  language: multiple(string()),
  preferredLanguage: multiple(string()),
  location: object(),
  certificateExpirations: opt(
    multiple(
      object({
        certification: string(),
        expirationDate: opt(date()),
      })
    )
  ),
  // Non-step keys
  _id: string().required(),
  // Step 1
  teams: minN(array(object()), 2, 'Must have at least 2 contacts'),
  // Step 2
  affiliateOrganizations: opt(multiple(string())),
  companyType: string()
    .required()
    .typeError('Field is required'),
  finishes: multiple(string()).when('companyType', (companyType, schema) => {
    return finishesRequired.findIndex(e => e === companyType) >= 0
      ? minOne(schema, 'Select at least one finish')
      : opt(schema);
  }),
  industry: multipleMinOne(string(), 'Select at least one industry'),
  leadTime: array(string()).when('companyType', (companyType, schema) => {
    return leadTimeOptionsRequired.findIndex(e => e === companyType) >= 0
      ? minOne(schema, 'Select at least one lead time.')
      : opt(schema);
  }),
  lotSize: multipleMinOne(string(), 'Select at least one lot size'),
  manufacturingProcesses: multiple(string()).when(
    'companyType',
    (companyType, schema) => {
      return manufacturingProcessesRequired.findIndex(e => e === companyType) >=
        0
        ? minOne(schema, 'Select at least one manufacturing process')
        : opt(schema);
    }
  ),
  materials: multiple(string()).when('companyType', (companyType, schema) => {
    return materialsRequired.findIndex(e => e === companyType) >= 0
      ? minOne(schema, 'Select at least one material')
      : opt(schema);
  }),
  productPreference: opt(multiple(string())),
  businessClassification: opt(multiple(string())),
  // Step 3
  rfqReview: YesOrNoSchema,
  rfqDedicated: YesOrNoSchema,
  rfqDedicatedUser: string().when('rfqDedicated', (rfqDedicated, schema) => {
    return rfqDedicated === 'Yes' ? schema.required() : schema.nullable();
  }),
  procurementFlow: YesOrNoSchema,
  procurementSub: YesOrNoSchema,
  procurementQuantityReview: YesOrNoSchema,
  procurementDedicated: YesOrNoSchema,
  procurementDedicatedUser: string().when(
    'procurementDedicated',
    (procurementDedicated, schema) => {
      return procurementDedicated === 'Yes'
        ? schema.required()
        : schema.nullable();
    }
  ),
  manufacturingInstructions: YesOrNoSchema,
  manufacturingFOD: YesOrNoSchema,
  manufacturingCapabilitiesCut: YesOrNoSchema,
  nonConformingProcess: YesOrNoSchema,
  nonConformingCorrection: YesOrNoSchema,
  nonConformingFlowDown: YesOrNoSchema,
  inspectionCalibrationUtilized: YesOrNoSchema,
  inspectionCalibratedPeriodically: YesOrNoSchema,
  inspectionCalibratedUser: string().when(
    'inspectionCalibratedPeriodically',
    (iPU, schema) => {
      return iPU === 'Yes'
        ? multipleMinOne(schema, 'Select at least one Inspection Equipment')
        : opt(multiple(schema));
    }
  ),
  inspectionCalibratedInHouse: YesOrNoSchema,
  inspectionRecords: YesOrNoSchema,
  inspectionPerson: YesOrNoSchema,
  inspectionPersonUser: string().when(
    'inspectionPerson',
    (inspectionPerson, schema) => {
      return inspectionPerson === 'Yes' ? schema.required() : schema.nullable();
    }
  ),
  materialCustomer: YesOrNoSchema,
  materialTraceable: YesOrNoSchema,
  materialReceipt: YesOrNoSchema,
  materialStored: YesOrNoSchema,
  materialRequirements: YesOrNoSchema,
  // Step 4
  certifications: array(string().required()),
  cageCode: string().when('certifications', (certifications, schema) => {
    return certifications?.findIndex(e => e === 'JCP') >= 0
      ? schema.required()
      : schema.nullable();
  }),
  documentIds: opt(multiple(string())),
  documents: array(object()),
};

export const SupplierMMSPartialObject = {
  ...SupplierVerificationObject,
  companyName: allowNullWhenStateIsDeactivated(string().required()),
  riskLevel: allowNullWhenStateIsDeactivated(opt(string())),
  multipleSite: opt(string()),
  size: opt(string()),
  heardAboutUs: allowNullWhenStateIsDeactivated(
    string(),
    s => s.nullable(),
    s => s.required()
  ),
  companyType: allowNullWhenStateIsDeactivated(
    string(),
    s => s.nullable(),
    s => s.required().typeError('Field is required')
  ),
  industry: allowNullWhenStateIsDeactivated(
    multiple(string()),
    s => s.nullable(),
    s => minOne(s, 'Select at least one industry')
  ),
  lotSize: allowNullWhenStateIsDeactivated(
    multiple(string()),
    s => s.nullable(),
    s => minOne(s, 'Select at least one lot size')
  ),
  website: allowNullWhenStateIsDeactivated(opt(string())),
  language: allowNullWhenStateIsDeactivated(multiple(string())),
  preferredLanguage: allowNullWhenStateIsDeactivated(multiple(string())),
  teams: allowNullWhenStateIsDeactivated(opt(object())),
  location: object(),
  rfqReview: NullableYesOrNoSchema,
  rfqDedicated: NullableYesOrNoSchema,
  rfqDedicatedUser: opt(string()),
  procurementFlow: NullableYesOrNoSchema,
  procurementSub: NullableYesOrNoSchema,
  procurementQuantityReview: NullableYesOrNoSchema,
  procurementDedicated: NullableYesOrNoSchema,
  procurementDedicatedUser: opt(string()),
  manufacturingInstructions: NullableYesOrNoSchema,
  manufacturingFOD: NullableYesOrNoSchema,
  manufacturingCapabilitiesCut: NullableYesOrNoSchema,
  nonConformingProcess: NullableYesOrNoSchema,
  nonConformingCorrection: NullableYesOrNoSchema,
  nonConformingFlowDown: NullableYesOrNoSchema,
  inspectionCalibrationUtilized: NullableYesOrNoSchema,
  inspectionCalibratedPeriodically: NullableYesOrNoSchema,
  inspectionCalibratedUser: opt(multiple(string())),
  inspectionCalibratedInHouse: NullableYesOrNoSchema,
  inspectionRecords: NullableYesOrNoSchema,
  inspectionPerson: NullableYesOrNoSchema,
  inspectionPersonUser: opt(string()),
  materialCustomer: NullableYesOrNoSchema,
  materialTraceable: NullableYesOrNoSchema,
  materialReceipt: NullableYesOrNoSchema,
  materialStored: NullableYesOrNoSchema,
  materialRequirements: NullableYesOrNoSchema,
  certifications: array(
    string().required().typeError(
      'Please choose a value for this certification or remove it'
    )
  ),
};

export const SupplierDappSaveAndExitSchema = {
  ...SupplierMMSPartialObject,
  teams: opt(multiple(object())),
  affiliateOrganizations: opt(multiple(string())),
  companyType: opt(string()),
  finishes: opt(multiple(string())),
  industry: opt(multiple(string())),
  leadTime: opt(multiple(string())),
  lotSize: opt(multiple(string())),
  manufacturingProcesses: opt(multiple(string())),
  materials: opt(multiple(string())),
  productPreference: opt(multiple(string())),
  businessClassification: opt(multiple(string())),
};

export type StepType = Partial<keyof typeof SupplierVerificationObject>[];

export const VerifySupplierSchema = object(SupplierVerificationObject);

export const createMethods = (preLoadedValues: Supplier, schema) => {
  const nullDefaultFields = Object.fromEntries(
    nullDefaultValues.map(n => [n, null])
  );
  const emptyArrayDefaultFields = Object.fromEntries(
    emptyArrayDefaultValues.map(n => [n, []])
  );
  const defaultValues = {
    ...nullDefaultFields,
    ...emptyArrayDefaultFields,
    ...preLoadedValues,
  };

  return useForm({
    mode: 'onChange',
    // resolver: async (data, context, options) => {
    //   // you can debug your validation schema here
    //   console.log('formData', data);
    //   console.log(
    //     'validation result',
    //     await yupResolver(schema)(data, context, options)
    //   );
    //   return yupResolver(schema)(data, context, options);
    // },
    resolver: yupResolver(schema),
    defaultValues,
  });
};

export const createSupplierVerifyForm = (existingDefaultValues, schema) => {
  const methods = createMethods(existingDefaultValues, object(schema));
  return {
    methods,
  };
};

export const getValuesForSupplierForm = existingValues => {
  const formInitializedvalues = existingValues;
  const nullDefaultFields = Object.fromEntries(
    nullDefaultValues.map(n => [n, null])
  );
  const emptyArrayDefaultFields = Object.fromEntries(
    emptyArrayDefaultValues.map(n => [n, []])
  );
  const defaultValues = {
    ...nullDefaultFields,
    ...emptyArrayDefaultFields,
    ...formInitializedvalues,
  };
  return defaultValues;
};

export const formatSupplierTagByValueLabel = sT => {
  return {
    label: sT.name,
    value: sT.value,
  };
};
