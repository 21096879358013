import { Button, Grid, Typography } from "@material-ui/core";
import React, { FC, useState } from "react";
import { object, SchemaOf, string } from "yup";
import { FormProvider, SubmitHandler, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import {
    Document, DocumentCardList,
    DocumentMenuOption,
    DocumentType,
    MIMEFileType,
    PurchaseOrderInput, PurchaseOrderStatus,
    QuoteDetail,
    RFQ,
    RHFTextField,
    SHOW_FEEDBACK, useAcceptQuoteMutation, useCreatePurchaseOrderMutation,
} from "@syncfab/machine";
import { useAppDispatch } from "../../../../application/hooks";
import FilePondComponent from "../../../../components/document/DocumentComponent";
import { useHistory } from "react-router";

interface AcceptQuoteProps {
    quote: QuoteDetail
    rfq: RFQ
    cancel: () => void
    complete: () => void
}
interface AcceptQuoteFormProps {
    buyerPOReference: string
}

const acceptQuoteFormSchema: SchemaOf<AcceptQuoteFormProps> = object({
    buyerPOReference: string().required('A purchase order reference number is required.')
})

export const AcceptQuote: FC<AcceptQuoteProps> = ({ cancel, complete, quote, rfq }) => {

    const dispatch = useAppDispatch()
    const history = useHistory();

    const [documentList, setDocumentList] = useState<Document[]>([])

    const [createPO] = useCreatePurchaseOrderMutation()
    const [acceptQuote] = useAcceptQuoteMutation()

    const methods = useForm<AcceptQuoteFormProps>({
        resolver: yupResolver(acceptQuoteFormSchema, { stripUnknown: true }),
        defaultValues:  {
            buyerPOReference: ''
        },
    });

    const handleDocumentUpload = (newDocument: Document) => {
        setDocumentList((documentList) => [...documentList, newDocument])
    }

    const onSubmit: SubmitHandler<AcceptQuoteFormProps> = async (acceptQuoteData: AcceptQuoteFormProps) => {
        const po: PurchaseOrderInput = createPOInput(acceptQuoteData)

        if (documentList.length === 0) {
            dispatch(SHOW_FEEDBACK("Please upload your purchase order document."))
            return
        }

        po.buyerPODocumentIds = documentList.map(doc => doc._id)

        try {
            const newPO = await createPO(po).unwrap()
            await acceptQuote({ quoteId: quote._id, poId: newPO._id })
            complete()
            dispatch(SHOW_FEEDBACK('Quote accepted and purchase order created.'))
            history.push('/purchaseOrders');
        } catch(error) {
            dispatch(SHOW_FEEDBACK(error.data))
        }
    }

    const createPOInput = (acceptQuoteData: AcceptQuoteFormProps): PurchaseOrderInput => {
        return {
            rfqId: rfq._id,
            quoteId: quote._id,
            revision: '1',
            status: PurchaseOrderStatus.DRAFT,
            buyerPOReference: acceptQuoteData.buyerPOReference,
            buyerCompany: {
                companyId: rfq.buyerCompany.companyId,
                companyName: rfq.buyerCompany.companyName,
                billingAddressId: rfq.buyerCompany.billingAddressId,
                shippingAddressId: rfq.buyerCompany.shippingAddressId
            },
            buyerContact: rfq.buyerContact,
            assignedTo: rfq.assignedTo,
            salesperson: rfq.salesperson,
            buyerPODocumentIds: [],
            additionalDocumentIds: []
        }
    }

    return (
        <FormProvider {...methods} >
            <Typography style={{ fontSize: 22, fontWeight: 600, paddingTop: '8px', paddingBottom: '16px' }}>
                Accept Quote
            </Typography>
            <Typography style={{ fontSize: 14, paddingBottom: '24px' }}>
                To accept this quote you are required to upload a purchase order. The purchase order must reflect pricing and terms specified on SyncFab’s quote.
            </Typography>
            <RHFTextField
                name='buyerPOReference'
                label='PO Number'
                required
            />
            <Grid item xs={12} sm={12} md={12} style={{ marginTop: '16px' }}>
                <Typography style={{ fontSize: 14, fontWeight: 'bold', paddingBottom: '16px' }}>
                    Upload Purchase Order
                </Typography>
            </Grid>
            <Grid item xs={12} sm={12} md={12}>
                <FilePondComponent
                    type={DocumentType.PO}
                    acceptedFileTypes={[MIMEFileType.PDF, MIMEFileType.EXTENSION, MIMEFileType.DOCX, MIMEFileType.XLSX]}
                    allowMultiple={false}
                    maxFiles={1}
                    maxFileSize="50MB"
                    maxTotalFileSize="50MB"
                    referenceId={rfq.buyerCompany.companyId}
                    onLoad={(doc: Document) => {
                        handleDocumentUpload(doc)
                    }}
                    clearAfterAllFilesProcessed={true}
                />
                <Typography gutterBottom variant="caption" paragraph align="center"
                            style={{ marginTop: '5px' }}>
                    Accepted file types: PDF | XLSX | DOCX files - Max Single Document Size: 50MB
                </Typography>
            </Grid>
            <DocumentCardList
                documents={documentList}
                setDocuments={setDocumentList}
                setAreDocumentsDirty={() => {}}
                availableMenuOptions={[DocumentMenuOption.VIEW, DocumentMenuOption.DOWNLOAD, DocumentMenuOption.REMOVE]}
                showType={false}
                showVisibility={false}
                showRevision={false}
            />
            <Grid container justifyContent='flex-end' style={{ paddingTop: '16px', paddingBottom:'16px' }}>
                <Button variant="text" style={{ color: "#40B4BF" }} onClick={() => cancel()}>
                    CANCEL
                </Button>
                <Button
                    type='submit'
                    variant="contained"
                    style={{ backgroundColor: '#40B4BF', color: '#FFFFFF', marginLeft: '16px' }}
                    onClick={methods.handleSubmit(onSubmit)}
                >
                    ACCEPT QUOTE
                </Button>
            </Grid>
        </FormProvider>
    )
}