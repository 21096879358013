import { Document, DocumentType, FetchActions, createFetchActions } from '@syncfab/machine'

export interface DocumentTypePayload {
    type: DocumentType
}

export const GET_COMPANY_DOCUMENTS: FetchActions<{}, Document[]> = createFetchActions('GET_COMPANY_DOCUMENTS')
export const DOCUMENT_UPLOAD: FetchActions<{ document: Document }, {}> = createFetchActions('DOCUMENT_UPLOAD')
export const DOCUMENT_DOWNLOAD: FetchActions<{ id: string }, string> = createFetchActions('DOCUMENT_DOWNLOAD')
export const DOCUMENT_DOWNLOAD_URL: FetchActions<{ id: string }, string> = createFetchActions('DOCUMENT_DOWNLOAD_URL')
export const RESET_DOWNLOAD_URL: FetchActions<{}, string> = createFetchActions('RESET_DOWNLOAD_URL')
export const DOCUMENT_DELETE_BY_TYPE: FetchActions<DocumentTypePayload, DocumentTypePayload> = createFetchActions('DOCUMENT_DELETE_BY_TYPE')
export const DOCUMENT_DOWNLOAD_TYPE: FetchActions<DocumentTypePayload, string> = createFetchActions('DOCUMENT_DOWNLOAD_TYPE')
export const DOCUMENT_UPDATE: FetchActions<{ id: string; type: string; visibility: string }, {}> = createFetchActions('DOCUMENT_UPDATE')
