import { analyticsSnippet } from './AnalyticsSnippet'
import { User } from '@syncfab/machine'

export class AnalyticsService {
    private client: any

    constructor(private appId: string) {
        !!appId && this.insertScript(appId)
    }

    private insertScript(appId: string) {
        if (typeof window === undefined) {
            return
        }

        const script = document.createElement('script')
        const innerText = analyticsSnippet(appId)
        script.innerText = innerText
        script.async = true
        document.body.appendChild(script)
        this.client = window['drift']
    }

    private shouldSend() {
        return !!this.appId && !!this.client
    }

    public identify(user: User) {
        if (!this.shouldSend()) {
            return () => {}
        }
        return () =>
            this.client.identify(user._id, {
                companyId: user.companyId,
                firstName: user.firstName,
                lastName: user.lastName,
                email: user.email,
                roles: user.roles.join(','),
                activatedAt: user.activatedAt,
                createdAt: user.createdAt,
            })
    }

    // Used on Sagas
    public trackEvent(event: string, payload: any) {
        if (!this.shouldSend()) {
            return () => {}
        }
        return () => this.client.track(event, payload)
    }

    public pageChange({ pathname }: { pathname: string }) {
        if (!this.shouldSend()) {
            return
        }
        this.client.page(pathname)
    }
}
