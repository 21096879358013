import React from 'react'
import { Field, reduxForm } from 'redux-form/immutable'
import { InjectedFormProps } from 'redux-form'
import { Map } from 'immutable'
import { WithStyles, withStyles } from '@material-ui/core/styles'
import { withNamespaces, WithNamespaces } from 'react-i18next'
import { FormTextField, Button, jumpToFirstError, FormPasswordField } from '@syncfab/machine'
import { EMAIL_REGEX, isExistSpecialCharacter, isNumber } from '../../../../utils/Regex'
import { requiredTextFields } from '../../../../utils/Validators'
import { SUPPLIER_EMAIL_EXIST } from '../../../../components/supplier/Actions'
import { Grid } from '@material-ui/core'

export const SupplierSignupFormName: string = 'SupplierSignupForm'
const MAX_LENGTH: number = 40

const styles = theme => ({
    action: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
    },
    loginText: {
        color: theme.palette.common.white,
        'text-align': 'center',
        marginTop: theme.spacing(2),
    },
    signupLink: {
        margin: 0,
        padding: 0,
        'text-decoration': 'none',
        color: theme.palette.primary.light,
    },
    gridItem: {
        paddingTop: '0px !important',
        paddingBottom: '0px !important',
    },
    formInput: {
        '& .MuiFormControl-marginNormal': {
            margin: 0,
        },
    },
})

type SignupPersonalInformationFields = {
    email?: string
    password?: string
    firstName?: string
    lastName?: string
    phone?: string
}

const validate = (data: Map<string, string>): {} => {
    let errors: SignupPersonalInformationFields = {}
    errors = requiredTextFields(data, ['firstName', 'lastName', 'phone', 'title'], {
        firstName: MAX_LENGTH,
        lastName: MAX_LENGTH,
        title: MAX_LENGTH,
    })

    if (!data.get('email')) {
        errors.email = 'Required'
    } else if (!EMAIL_REGEX.test(data.get('email') || '')) {
        errors.email = 'Invalid email'
    }
    let phone = data.get('phone')
    if (phone && phone.length > 0 && !isNumber(data.get('phone'))) {
        errors['phone'] = 'Enter valid phone number.'
    }
    if (!data.get('password')) {
        errors.password = 'Required'
    } else if (!/^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/.test(data.get('password') || '')) {
        errors.password = 'A password requires at least 8 characters (at least 1 number, 1 symbol and 1 Uppercase)'
    }
    if (isExistSpecialCharacter(data.get('firstName'))) errors.firstName = 'Not allow certain special characters'
    if (isExistSpecialCharacter(data.get('lastName'))) errors.lastName = 'Not allow certain special characters'

    return errors
}

const asyncValidate = (values, dispatch) => {
    dispatch(SUPPLIER_EMAIL_EXIST.actions.TRIGGER({ email: values.get('email') }))
    return new Promise<void>(resolve => resolve())
}

const form = (props: InjectedFormProps<{}, {}> & WithStyles & WithNamespaces) => {
    const { handleSubmit, submitting, t, classes } = props
    return (
        <form onSubmit={handleSubmit}>
            <Grid container direction="row" spacing={2}>
                <Grid item xs={12} md={6} className={classes.gridItem}>
                    <Field name="firstName" fullWidth component={FormTextField} type="text" label={t('user-first-name')} className={classes.formInput} />
                </Grid>
                <Grid item xs={12} md={6} className={classes.gridItem}>
                    <Field name="lastName" fullWidth component={FormTextField} type="text" label={t('user-last-name')} className={classes.formInput} />
                </Grid>
                <Grid item xs={12} md={12} className={classes.gridItem}>
                    <Field name="phone" component={FormTextField} type="text" label={t('user-phone')} fullWidth className={classes.formInput} />
                </Grid>
                <Grid item xs={12} md={12} className={classes.gridItem}>
                    <Field name="email" component={FormTextField} type="email" label={t('user-email')} fullWidth className={classes.formInput} />
                </Grid>
                <Grid item xs={12} md={12} className={classes.gridItem}>
                    <Field name="password" component={FormPasswordField as any} label={t('user-password')} fullWidth className={classes.formInput} />
                </Grid>
                <Grid item xs={12} md={12} className={classes.gridItem}>
                    <Field name="title" component={FormTextField} type="text" label={t('user-job-title')} fullWidth className={classes.formInput} />
                </Grid>
                <Grid item xs={12} md={12} className={classes.gridItem}>
                    <Button type="submit" color="primary" disabled={submitting} className={classes.action} fullWidth>
                        {t('signup-form-continue')}
                    </Button>
                </Grid>
            </Grid>
        </form>
    )
}

const translatedForm = withNamespaces()(withStyles(styles)(form))
export const SupplierSignupPersonalInformationForm = reduxForm<{}, {}>({
    form: SupplierSignupFormName,
    validate,
    destroyOnUnmount: false,
    forceUnregisterOnUnmount: true,
    onSubmitFail: jumpToFirstError,
    asyncValidate,
    asyncChangeFields: ['email'],
    asyncBlurFields: ['email'],
})(translatedForm)
