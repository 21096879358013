import 'reflect-metadata'
import React from 'react'
import ReactDOM from 'react-dom'
import { Router } from 'react-router-dom'
import Application from './Application'
import { Provider } from 'react-redux'
import { store } from './Store'
import { create } from 'jss'
import { jssPreset } from '@material-ui/core/styles'
import { dappTheme } from '@syncfab/machine'
import CssBaseline from '@material-ui/core/CssBaseline'
import { GET_LOGGED_USER } from '../components/user/Actions'
import { GET_SUPPLIER_TAGS } from '../components/supplier/Actions'
import { StylesProvider, ThemeProvider } from "@material-ui/core";
import { routerService } from './services'

const jss = create(jssPreset())

// If there is a valid token, we load the current user
if (!!store.getState().auth.authToken) {
    store.dispatch(GET_LOGGED_USER.actions.TRIGGER())
    store.dispatch(GET_SUPPLIER_TAGS.actions.TRIGGER())
}

ReactDOM.render(
    <StylesProvider jss={jss}>
        <ThemeProvider theme={dappTheme}>
            <Provider store={store}>
                <Router history={routerService.getHistory()}>
                    <React.Fragment>
                        <CssBaseline />
                        <Application />
                    </React.Fragment>
                </Router>
            </Provider>
        </ThemeProvider>
    </StylesProvider>,
    document.getElementById('main-container')
)