import {
  CurrencyFormatter,
  downloadDocument,
  downloadDocumentFromURL,
  getPartFinishes,
  getPartMaterials,
  PDFViewer,
  RFQ,
  RFQLineItem,
  useStandardStyles,
} from '@syncfab/machine';
import React, { FC, useState } from 'react';
import {
  Button,
  Chip,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Theme,
  Typography,
} from '@material-ui/core';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import { useSelector } from 'react-redux';
import ReactQuill from 'react-quill-new';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    cell: {
      verticalAlign: 'top',
      padding: theme.spacing(1),
      borderBottom: 'none',
    },
    tableRow: {
      height: 'auto !important',
    },
    innerTableHeaderRow: {
      height: 'auto !important',
      '& th': {
        paddingTop: 0,
        paddingBottom: 0,
        borderBottom: 'none',
        fontSize: 14,
      },
    },
    // innerTableDataRow: {
    //     height: 'auto !important',
    //     border: '1px solid #FFFFFF40',
    //     '&:nth-of-type(odd)': {
    //         backgroundColor: '#FFFFFF15',
    //     },
    //     // hide last border
    //     '&:last-child td, &:last-child th': {
    //         border: 0,
    //     },
    //     '& td': {
    //         padding: '8px',
    //         borderBottom: 'none',
    //         fontSize: 14
    //     }
    // },
    tableLeftCell: {
      padding: '0px',
      width: 120,
      border: 'none',
    },
    truncatedText: {
      width: '170px',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      fontSize: 14,
    },
    tableRightCell: {
      padding: '0px 0px 0px 8px',
      border: 'none',
    },
    tableChipCell: {
      padding: '0px',
      border: 'none',
    },
    tableCellPadding: {
      paddingTop: 0,
      paddingBottom: 0,
    },
    value: {
      fontSize: '14px',
    },
    discountChip: {
      marginLeft: '16px',
      backgroundColor: '#73AA27',
      fontSize: 12,
      fontStyle: 'italic',
      color: '#FFFFFF',
    },
    chipIcon: {
      color: '#FFFFFF',
    },
    quillEditor: {
        '& .ql-container.ql-snow': { border: 'none' },
        '& .ql-editor': { padding: 0 },
      },
  })
);

interface SupplierRFQLineItemProps {
  rfq: RFQ;
}

export const SupplierRFQLineItems: FC<SupplierRFQLineItemProps> = ({ rfq }) => {
  const standardClasses = useStandardStyles();
  const classes = useStyles();

  const [documentIdToView, setDocumentIdToView] = useState<string>('');
  const [isPDFViewerOpen, setIsPDFViewerOpen] = useState<boolean>(false);

  const token = useSelector((state: any) => state.auth.authToken);

  const handleDownloadAllDocuments = async () => {
    await downloadDocumentFromURL(
      `/rfqs/v2/${rfq._id}/downloadAll`,
      token,
      `${rfq._id}Documents.zip`
    );
  };

  return (
    <Paper className={standardClasses.paper}>
      <Grid container>
        <Grid container item xs={12} md={6}>
          <Typography
            style={{ padding: '16px', fontSize: '20px', fontWeight: 'bold' }}
          >
            Line Items
          </Typography>
        </Grid>
        <Grid
          container
          item
          xs={12}
          md={6}
          justifyContent="flex-end"
          style={{ padding: '16px 16px 16px 0px' }}
        >
          <Button
            style={{
              color: '#40B4BF',
              borderColor: '#40B4BF',
              fontWeight: 'bold',
            }}
            variant="outlined"
            onClick={() => handleDownloadAllDocuments()}
          >
            DOWNLOAD ALL
          </Button>
        </Grid>
      </Grid>
      <TableContainer style={{ overflowX: 'auto' }}>
        <Table style={{ backgroundColor: '#364F68' }}>
          <TableBody>
            {rfq.lineItems?.map((lineItem: RFQLineItem, index: number) => {
                console.log(lineItem);
              return (
                <TableRow
                  style={{
                    border: '1px solid #FFFFFF40',
                    borderRight: 'none',
                    borderLeft: 'none',
                  }}
                  key={lineItem._id}
                >
                  <TableCell
                    style={{
                      verticalAlign: 'middle',
                      textAlign: 'center',
                      borderRight: '1px solid #FFFFFF40',
                      borderBottom: 'none',
                    }}
                  >
                    {index + 1}
                  </TableCell>
                  <TableCell
                    className={classes.cell}
                    style={{ minWidth: '250px' }}
                  >
                    <Typography
                      style={{
                        fontSize: 14,
                        fontWeight: 'bold',
                        paddingBottom: '8px',
                      }}
                    >
                      Part Details
                    </Typography>
                    <Table style={{ backgroundColor: '#364F68' }}>
                      <TableBody>
                        <TableRow className={standardClasses.innerTableDataRow}>
                          <TableCell className={classes.tableLeftCell}>
                            <Typography
                              variant="body2"
                              className={standardClasses.label}
                            >
                              Part Number
                            </Typography>
                          </TableCell>
                          <TableCell className={classes.tableRightCell}>
                            <Typography className={classes.value}>
                              {lineItem.part?.number ?? lineItem.partId}
                            </Typography>
                          </TableCell>
                        </TableRow>
                        <TableRow className={standardClasses.innerTableDataRow}>
                          <TableCell className={classes.tableLeftCell}>
                            <Typography className={standardClasses.label}>
                              Revision
                            </Typography>
                          </TableCell>
                          <TableCell className={classes.tableRightCell}>
                            <Typography className={classes.value}>
                              {lineItem.part?.revision}
                            </Typography>
                          </TableCell>
                        </TableRow>
                        <TableRow className={standardClasses.innerTableDataRow}>
                          <TableCell className={classes.tableLeftCell}>
                            <Typography className={standardClasses.label}>
                              Description
                            </Typography>
                          </TableCell>
                          <TableCell className={classes.tableRightCell}>
                            <Typography className={classes.value}>
                              {lineItem.part?.name}
                            </Typography>
                          </TableCell>
                        </TableRow>
                        <TableRow className={standardClasses.innerTableDataRow}>
                          <TableCell className={classes.tableLeftCell}>
                            <Typography className={standardClasses.label}>
                              NSN
                            </Typography>
                          </TableCell>
                          <TableCell className={classes.tableRightCell}>
                            <Typography className={classes.value}>
                              {lineItem.part?.nationalStockNumber}
                            </Typography>
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </TableCell>
                  <TableCell
                    className={classes.cell}
                    style={{ minWidth: '300px' }}
                  >
                    <Typography
                      style={{
                        fontSize: 14,
                        fontWeight: 'bold',
                        paddingBottom: '8px',
                      }}
                    >
                      Part Requirements
                    </Typography>
                    <Table>
                      <TableBody>
                        <TableRow
                          className={standardClasses.innerTableDataRow}
                          style={{ whiteSpace: 'normal' }}
                        >
                          <TableCell>
                            <Typography className={standardClasses.label}>
                              Manufacturing Process
                            </Typography>
                          </TableCell>
                          <TableCell className={standardClasses.tableRightCell}>
                            <Typography className={standardClasses.value}>
                              {lineItem.part.manufacturingProcesses
                                ? lineItem.part.manufacturingProcesses.join(
                                    ', '
                                  )
                                : ''}
                            </Typography>
                          </TableCell>
                        </TableRow>
                        <TableRow
                          className={standardClasses.innerTableDataRow}
                          style={{ whiteSpace: 'normal' }}
                        >
                          <TableCell style={{ verticalAlign: 'top' }}>
                            <Typography className={standardClasses.label}>
                              Material
                            </Typography>
                          </TableCell>
                          <TableCell className={standardClasses.tableRightCell}>
                            {getPartMaterials(lineItem.part).map(
                              (item, index) =>
                                item.material ? (
                                  <Typography
                                    className={standardClasses.value}
                                    key={index}
                                  >
                                    {`${item.material}${
                                      item?.detail ? ' - ' + item.detail : ''
                                    }`}
                                  </Typography>
                                ) : (
                                  <p style={{ margin: '0px' }}>&nbsp;</p>
                                )
                            )}
                          </TableCell>
                        </TableRow>
                        <TableRow
                          className={standardClasses.innerTableDataRow}
                          style={{ whiteSpace: 'normal' }}
                        >
                          <TableCell style={{ verticalAlign: 'top' }}>
                            <Typography className={standardClasses.label}>
                              Finish
                            </Typography>
                          </TableCell>
                          <TableCell className={standardClasses.tableRightCell}>
                            {getPartFinishes(lineItem.part).map((item, index) =>
                              item.finish ? (
                                <Typography
                                  className={standardClasses.value}
                                  key={index}
                                >
                                  {`${item.finish}${
                                    item?.detail ? ' - ' + item.detail : ''
                                  }`}
                                </Typography>
                              ) : (
                                <p style={{ margin: '0px' }}>&nbsp;</p>
                              )
                            )}
                          </TableCell>
                        </TableRow>
                        <TableRow
                          className={standardClasses.innerTableDataRow}
                          style={{ whiteSpace: 'normal' }}
                        >
                          <TableCell>
                            <Typography className={standardClasses.label}>
                              Part Identification
                            </Typography>
                          </TableCell>
                          <TableCell className={standardClasses.tableRightCell}>
                            <Typography className={standardClasses.value}>
                              {lineItem.partIdentification}
                            </Typography>
                          </TableCell>
                        </TableRow>
                        <TableRow
                          className={standardClasses.innerTableDataRow}
                          style={{ whiteSpace: 'normal' }}
                        >
                          <TableCell>
                            <Typography className={standardClasses.label}>
                              Exceptions
                            </Typography>
                          </TableCell>
                          <TableCell className={standardClasses.tableRightCell}>
                            <ReactQuill
                              readOnly
                              value={lineItem?.exceptions ?? '-'}
                              className={classes.quillEditor}
                              modules={{ toolbar: false}}
                            />
                          </TableCell>
                        </TableRow>
                        <TableRow
                          className={standardClasses.innerTableDataRow}
                          style={{ whiteSpace: 'normal' }}
                        >
                          <TableCell>
                            <Typography className={standardClasses.label}>
                              Notes
                            </Typography>
                          </TableCell>
                          <TableCell className={standardClasses.tableRightCell}>
                            <ReactQuill
                              readOnly
                              value={lineItem?.notes ?? '-'}
                              className={classes.quillEditor}
                              modules={{ toolbar: false }}
                            />
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </TableCell>
                  <TableCell
                    className={classes.cell}
                    style={{ minWidth: '200px' }}
                  >
                    <Typography
                      style={{
                        fontSize: 14,
                        fontWeight: 'bold',
                        paddingBottom: '8px',
                      }}
                    >
                      Qtys & Target Unit Price
                    </Typography>
                    <Table style={{ backgroundColor: '#364F68' }}>
                      {lineItem.quantity.map((qty, index) => (
                        <TableRow
                          className={standardClasses.innerTableDataRow}
                          key={index}
                        >
                          <TableCell
                            className={classes.tableLeftCell}
                            style={{ width: '35%' }}
                          >
                            <Typography
                              variant="body2"
                              className={standardClasses.label}
                            >
                              {`Qty ${index + 1}`}
                            </Typography>
                          </TableCell>
                          <TableCell
                            className={classes.tableRightCell}
                            style={{ width: '25%' }}
                          >
                            <Typography className={classes.value}>
                              {qty}
                            </Typography>
                          </TableCell>
                          <TableCell
                            className={classes.tableRightCell}
                            style={{ width: '40%' }}
                          >
                            <Typography className={classes.value}>
                              {CurrencyFormatter.format(
                                lineItem?.targetUnitPrice?.[index]
                                  ? lineItem.targetUnitPrice[index]
                                  : 0
                              )}
                            </Typography>
                          </TableCell>
                        </TableRow>
                      ))}
                    </Table>
                  </TableCell>
                  {/* Table cell and table widths necessary to maintain spacing of documents table
                                        and ellipsis text overflow of document names */}
                  <TableCell
                    className={classes.cell}
                    style={{ width: '405px' }}
                  >
                    <Table
                      style={{ backgroundColor: '#364F68', width: '405px' }}
                    >
                      <Typography
                        style={{
                          fontSize: 14,
                          fontWeight: 'bold',
                          paddingBottom: '8px',
                        }}
                      >
                        Documents
                      </Typography>
                      {lineItem.part.documents.map(doc => (
                        <TableRow
                          className={standardClasses.innerTableDataRow}
                          key={doc._id}
                        >
                          <TableCell>
                            <div
                              style={{
                                width: '220px',
                                whiteSpace: 'nowrap',
                                overflow: 'hidden',
                                textOverflow: 'ellipsis',
                              }}
                            >
                              {doc.name}
                            </div>
                          </TableCell>
                          <TableCell
                            className={classes.tableChipCell}
                            style={{ width: '165px' }}
                          >
                            <Chip
                              clickable
                              size="small"
                              variant="outlined"
                              label="VIEW"
                              onClick={() => {
                                setDocumentIdToView(doc._id);
                                setIsPDFViewerOpen(true);
                              }}
                              disabled={
                                !doc.name.toLowerCase().endsWith('.pdf')
                              }
                              style={{
                                color: '#40B4BF',
                                borderColor: '#40B4BF',
                                fontWeight: 'bold',
                              }}
                            />
                            <Chip
                              clickable
                              label="DOWNLOAD"
                              size="small"
                              variant="outlined"
                              style={{
                                marginLeft: '8px',
                                borderColor: '#40B4BF',
                                color: '#40B4BF',
                                fontWeight: 'bold',
                              }}
                              onClick={() => {
                                downloadDocument(
                                  doc._id,
                                  token,
                                  'RFQPART',
                                  rfq._id
                                );
                              }}
                            />
                          </TableCell>
                        </TableRow>
                      ))}
                    </Table>
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
      <PDFViewer
        documentId={documentIdToView}
        open={isPDFViewerOpen}
        entity="RFQPART"
        entityId={rfq._id}
        onClose={() => {
          setIsPDFViewerOpen(false);
          setDocumentIdToView('');
        }}
      />
    </Paper>
  );
};
