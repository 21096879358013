import React from 'react'
import SvgIcon from '@material-ui/core/SvgIcon'

export const VerifiedUserIcon = props => {
    return (
        <SvgIcon {...props} viewBox="0 0 24 24" x="0px" y="0px" baseProfile="tiny" xmlSpace="preserve">
            <path
                id="verified_user_FILL1_wght400_GRAD0_opsz20"
                d="M9.125,10.542,7.979,9.4a.88.88,0,0,0-1.229,0,.855.855,0,0,0,0,1.208l1.771,1.771a.88.88,0,0,0,1.229,0l3.521-3.521a.869.869,0,0,0-1.229-1.229ZM10,18.271a2.188,2.188,0,0,1-.229-.011.756.756,0,0,1-.209-.052,8.426,8.426,0,0,1-4.479-3.375,9.541,9.541,0,0,1-1.75-5.562V5.354A1.73,1.73,0,0,1,4.479,3.729L9.4,1.9a1.806,1.806,0,0,1,1.208,0l4.917,1.833a1.73,1.73,0,0,1,1.146,1.625V9.271a9.541,9.541,0,0,1-1.75,5.562,8.426,8.426,0,0,1-4.479,3.375A3.632,3.632,0,0,1,10,18.271Z"
                transform="translate(-3.333 -1.792)"
                fill="#dbdbdb"
            />
        </SvgIcon>
    )
}
