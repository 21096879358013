import React from 'react'
import { WithNamespaces, withNamespaces } from 'react-i18next'
import { connect } from 'react-redux'
import { Loader, User, UserRole, findSelectedSingleOption } from '@syncfab/machine'
import { RouteComponentProps, withRouter } from 'react-router'
import { WithStyles, withStyles } from '@material-ui/core'
import { State } from '../../../application/Store'
import { Dispatch } from 'redux'
import { GET_USER, USER_UPDATE, SAVE_USER, RESET_USER } from '../../../components/user/Actions'
import { UserProdileEditorForm } from './components/UserProfileEditorForm'
import { Map } from 'immutable'

const styles = theme => ({
    paper: {
        width: '100%',
        minWidth: 300,
        padding: theme.spacing(4),
        marginTop: theme.spacing(4),
        marginBottom: theme.spacing(4),
    },
})

interface UserProfileEditorProps extends RouteComponentProps<{ id: string }>, WithNamespaces, WithStyles {
    get: (id: string) => void
    save: (user: Partial<User>,history:any) => void
    update: (id: string, delta: Partial<User>,history) => void
    // cancel: () => void
    user?: User
    type?: string
    id?: string
    resetUser: () => void
}

interface UserProfileEditorState {
    id?: string
}

class UserProfileEditorView extends React.Component<UserProfileEditorProps, UserProfileEditorState> {
    constructor(props: UserProfileEditorProps) {
        super(props)
        this.onSubmit = this.onSubmit.bind(this)
        const queryParams = new URLSearchParams(window.location.search)
        const id = queryParams.get('userId')
        this.state = {
            id
        }
        if (id) {
            this.props.get(id)
        } 
    }

    onSubmit(values: Map<{}, {}>) {
        const formData = values.toJS()
        const { id } = this.state
        const userInput = {
            ...formData,
            roles: [(formData.roles as any)?.value] ?? [],
        }
        if (!!id) {
            this.props.update(id, userInput,this.props.history)
        } else {
            this.props.save(userInput,this.props.history)
        }
    }
    cancel = () => {
        this.props.resetUser()
        this.props.history.push('/settings/team')
    }
    render() {
        const { t, user } = this.props
        if (!user && this.state.id) {
            return <Loader />
        }
        const rolesArr = [
            {
                label: t('personal-form-admin'),
                value: UserRole.ADMIN,
            },

            {
                label: t('personal-form-member'),
                value: UserRole.MEMBER,
            },
        ]

        return (
            // <CenteredAlignedContainerV2>
            // <Helmet>
            //     <title>{`${t('user-profile-editor-title')}`}</title>
            // </Helmet>
            // <Paper className={classes.paper} elevation={1}>
            <UserProdileEditorForm
                onSubmit={this.onSubmit as any}
                user={user}
                initialValues={
                    user
                        ? { ...user, roles: findSelectedSingleOption(rolesArr, (user?.roles[0] as any) || rolesArr[0]?.value) }
                        : {
                              notificationSettings: [
                                  {
                                      type: 'RFQ',
                                      subscribed: true,
                                  },
                                  {
                                      type: 'QUOTES',
                                      subscribed: true,
                                  },
                                  {
                                      type: 'PURCHASE_ORDERS',
                                      subscribed: true,
                                  },
                                  {
                                      type: 'NEWS_LETTER',
                                      subscribed: false,
                                  },
                              ],
                              roles: findSelectedSingleOption(rolesArr, (user?.roles[0] as any) || rolesArr[0]?.value),
                          }
                }
                cancel={this.cancel}
            />
            // </Paper>
            // </CenteredAlignedContainerV2>
        )
    }
}

const UserProfileEditorWithTranslations = withStyles(styles)(withRouter(withNamespaces()(UserProfileEditorView)))

const mapStateToProps = ({ user: { user } }: State) => {
    return {
        user,
    }
}

const mapDispatchToProps = (dispatch: Dispatch) => {
    return {
        get: (id: string) => dispatch(GET_USER.actions.TRIGGER({ id })),
        save: (user: Partial<User>,history:any) => dispatch(SAVE_USER.actions.TRIGGER({ user,history })),
        update: (id: string, delta: Partial<User>,history:any) => dispatch(USER_UPDATE.actions.TRIGGER({ id, delta,history })),
        // cancel: () => dispatch(PUSH('/settings/team')),
        resetUser: () => dispatch(RESET_USER.actions.TRIGGER()),
    }
}

export const UserProfileEditor = connect(mapStateToProps, mapDispatchToProps)(UserProfileEditorWithTranslations)
