import React from 'react'
import { CenteredAlignedContainer } from './Container'
import { Typography } from '@material-ui/core'
import { Logo } from '@syncfab/machine'

export default () => {
    return (
        <CenteredAlignedContainer>
            <Logo />
            <Typography variant="h6" component="h6" style={{ padding: 16, color: '#A7B1BB' }}>
                This space intentionally left blank.
            </Typography>
        </CenteredAlignedContainer>
    )
}
