import React from 'react'
import SvgIcon from '@material-ui/core/SvgIcon'

export const ProductionEndIcon = props => {
    return (
        <SvgIcon {...props} viewBox="0 0 40 40" x="0px" y="0px" baseProfile="tiny" xmlSpace="preserve">
            <path
                fill="#FFFFFF"
                d="M6,21.6c0,1-0.8,1.8-1.8,1.8c-1,0-1.8-0.8-1.8-1.8c0-1,0.8-1.8,1.8-1.8C5.2,19.8,6,20.6,6,21.6z M22.2,19.8
			c-1,0-1.8,0.8-1.8,1.8s0.8,1.8,1.8,1.8c1,0,1.8-0.8,1.8-1.8S23.2,19.8,22.2,19.8z M24,16.8V18c0,0.3-0.3,0.6-0.6,0.6H6.6
			c-2.3,0-4.2-1.9-4.2-4.2V4.8c0-1-0.8-1.8-1.8-1.8h0C0.3,3,0,2.7,0,2.4V1.2c0-0.3,0.3-0.6,0.6-0.6h0c2.3,0,4.2,1.9,4.2,4.2v9.6
			c0,1,0.8,1.8,1.8,1.8h0c0.3,0,0.6-0.3,0.6-0.6l0-10.8c0-0.3,0.3-0.6,0.6-0.6h4.8c0.3,0,0.6,0.3,0.6,0.6V6c0,0.3,0.3,0.6,0.6,0.6H15
			c0.3,0,0.6-0.3,0.6-0.6l0-1.2c0-0.3,0.3-0.6,0.6-0.6H21c0.3,0,0.6,0.3,0.6,0.6v10.8c0,0.3,0.3,0.6,0.6,0.6h1.2
			C23.7,16.2,24,16.5,24,16.8z"
            />
        </SvgIcon>
    )
}
