import { GET_CAPABILITIES, GET_LAT_LONG, GET_SUPPLIER_ANALYTICS } from './Actions'

export type DashboardReducerType = {
    capabilities?: any
    isLoading?: boolean
    supplierAnalytics?: any
    latLong?: any[]
}

const initialState: DashboardReducerType = {
    capabilities: null,
    isLoading: false,
    latLong: [],
}

export const DashboardReducer = (state = initialState, action: any) => {
    switch (action.type) {
        case GET_CAPABILITIES.types.TRIGGER:
            return {
                ...state,
                isLoading: true,
            }
        case GET_CAPABILITIES.types.SUCCESSFULL:
            return {
                ...state,
                capabilities: action.data,
                isLoading: false,
            }
        case GET_CAPABILITIES.types.FAILED:
            return {
                ...state,
                isLoading: false,
            }

        case GET_SUPPLIER_ANALYTICS.types.TRIGGER:
            return {
                ...state,
                isLoading: true,
            }
        case GET_SUPPLIER_ANALYTICS.types.SUCCESSFULL:
            return {
                ...state,
                supplierAnalytics: action.data,
                isLoading: false,
            }
        case GET_SUPPLIER_ANALYTICS.types.FAILED:
            return {
                ...state,
                isLoading: false,
            }

        case GET_LAT_LONG.types.TRIGGER:
            return {
                ...state,
                isLoading: true,
            }
        case GET_LAT_LONG.types.SUCCESSFULL:
            //let newLatLong = state.latLong.push(action.data)

            let latLong = action.data.map(data => (data && data.length === 1 ? data[0] : data))
            return {
                ...state,
                latLong: latLong,
                isLoading: false,
            }
        case GET_LAT_LONG.types.FAILED:
            return {
                ...state,
                isLoading: false,
            }
        default:
            return state
    }
}
