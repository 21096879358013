
export enum EnvironmentVariable {
    DAPP_URL = 'dappUrl',
    API_LAYER_URL = 'apiUrl',
    PORT = 'port',
    ANALYTICS_ID = 'analyticsId',
    STRIPE_PUBLISHABLE_KEY = 'stripePublishableKey',
    PLAID_PUBLIC_KEY = 'plaidPublicKey',
    GOOGLE_MAP_KEY = 'googleMapKey',
}

type EnvironmentVariableType = { [key in EnvironmentVariable]: string | undefined }

export class Environment {
    private variables: EnvironmentVariableType

    public load(): void {
        this.variables = document.getElementsByTagName('body')[0].dataset as EnvironmentVariableType
        console.debug(process.env.API_LAYER_URL)
        if (!this.variables.apiUrl) {
            this.variables.apiUrl = process.env.API_LAYER_URL
        }
    }

    public get(key: EnvironmentVariable): string | undefined {
        return this.variables[key]
    }
}

export const StaticEnvironmentFinder = (variable: EnvironmentVariable) => document.getElementsByTagName('body')[0].dataset[variable]

const environment: any = new Environment()
environment.load()

export { environment }