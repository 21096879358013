import React from 'react'
import SvgIcon from '@material-ui/core/SvgIcon'

export const AnalyticsIcon = props => {
    return (
        <SvgIcon {...props} viewBox="0 0 24 24" x="0px" y="0px" baseProfile="tiny" xmlSpace="preserve">
            <path
                id="Path_3309"
                data-name="Path 3309"
                d="M19,3H5A2.006,2.006,0,0,0,3,5V19a2.006,2.006,0,0,0,2,2H19a2.006,2.006,0,0,0,2-2V5A2.006,2.006,0,0,0,19,3ZM8,17H8a1,1,0,0,1-1-1V13a1,1,0,0,1,2,0v3A1,1,0,0,1,8,17Zm4,0h0a1,1,0,0,1-1-1V15a1,1,0,0,1,2,0v1A1,1,0,0,1,12,17Zm0-5a1,1,0,1,1,1-1A1,1,0,0,1,12,12Zm4,5h0a1,1,0,0,1-1-1V8a1,1,0,0,1,2,0v8A1,1,0,0,1,16,17Z"
                fill="#fff"
            />
        </SvgIcon>
    )
}
