import { UserIdAndName } from "../BaseTypes";

export enum ActionItemStatus {
    OPEN = 'OPEN',
    CLOSED = 'CLOSED'
}

export enum EntityType {
    RFQPART = 'RFQPART',
    RFQ = 'RFQ',
    PURCHASEORDER = 'PO',
    PURCHASEORDERLINEITEM = 'POLINEITEM',
    ACTIONITEM = 'ACTIONITEM',
    SUPPLIER_PURCHASE_ORDER = 'SUPPLIER_PURCHASE_ORDER',
}

export enum ActionItemEntityType {
    RFQPART = 'RFQPART',
    RFQ = 'RFQ',
    PURCHASEORDER = 'PO',
    PURCHASEORDERLINEITEM = 'POLINEITEM',
    SUPPLIER_PURCHASE_ORDER = 'SUPPLIER_PURCHASE_ORDER',
    NEW_SUPPLIER = 'NEW_SUPPLIER',
    NEW_BUYER = 'NEW_BUYER',
}
export enum ActionItemType {
    NEW_SUPPLIER = 'NEW_SUPPLIER',
    NEW_BUYER = 'NEW_BUYER',
    ACTION_ITEM_DUE = 'ACTION_ITEM_DUE',
    ACTION_ITEM_NEW = 'ACTION_ITEM_NEW',
    PURCHASE_ORDER_CHANGE_REQUEST = 'PURCHASE_ORDER_CHANGE_REQUEST',
    RFQ_CHANGE_REQUEST = 'RFQ_CHANGE_REQUEST',
    RFQ_CREATED = 'RFQ_CREATED',
    SHIP_DATE_PAST_DUE = 'SHIP_DATE_PAST_DUE',
    NEW_DOCK_DATE_PAST_DUE = 'NEW_DOCK_DATE_PAST_DUE',
    SUPPLIER_QUOTE_SUBMITTED = 'SUPPLIER_QUOTE_SUBMITTED',
    SUPPLIER_PURCHASE_ORDER_ISSUED = 'SUPPLIER_PURCHASE_ORDER_ISSUED',
    SUPPLIER_PURCHASE_ORDER_CONFIRMED = 'SUPPLIER_PURCHASE_ORDER_CONFIRMED',
    INVOICE_SENT = 'INVOICE_SENT',

}
export const getEntityTypeDescription = (entityType: EntityType): string => {
    switch (entityType) {
        case EntityType.PURCHASEORDER:
            return 'Purchase Order'
        case EntityType.SUPPLIER_PURCHASE_ORDER:
            return 'Purchase Order'
        case EntityType.RFQ:
            return 'RFQ'
        default:
            return entityType
    }
}

export enum ActionItemMenuOption {
    EDIT = 'Edit',
    COMPLETE = 'Complete'
}

export interface EntityDefinition {
    entityId: string
    entityType: ActionItemEntityType
}

export interface ActionItemInput {
    entityType: ActionItemEntityType
    entityId: string
    description: string
    dueBy: Date
    outcome?: string
    assignedTo: {
        userId: string
    }
    completedAt?: Date
    completedBy?: {
        userId?: string
    }
}

export interface ActionItem extends ActionItemInput {
    _id: string
    actionItemType?: ActionItemType
    assignedTo: UserIdAndName
    completedBy?: {
        userId?: string
        userName?: string
    }
    createdAt: Date
    createdBy: UserIdAndName
    updatedAt?: Date
    updatedBy?: UserIdAndName
}