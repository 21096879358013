import React from 'react'
import { Field, reduxForm } from 'redux-form/immutable'
import { InjectedFormProps } from 'redux-form'
import { Map } from 'immutable'
import { WithStyles, withStyles } from '@material-ui/core/styles'
import { withNamespaces, WithNamespaces } from 'react-i18next'
import { Button, jumpToFirstError, FormTextField } from '@syncfab/machine'
import { Typography, Grid } from '@material-ui/core'
import { EMAIL_REGEX } from '../../../utils/Regex'

export const SettingsEmailFormName: string = 'settingsEmailForm'

const styles = theme => ({
    form: {
        // display: 'flex',
        // 'flex-direction': 'column',
        marginTop: theme.spacing(1),
        margin: 'auto',
    },
    action: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
        marginLeft: 'auto',
    },
    gridItem: {
        paddingTop: '0px !important',
        paddingBottom: '0px !important',
    },
    formInput: {
        '& .MuiFormControl-marginNormal': {
            margin: 0,
        },
    },
})

type EmailSettingsFields = {
    email?: string
    newEmail?: string
    reNewemail?: string
}

const validate = (data: Map<string, string>): {} => {
    const errors: EmailSettingsFields = {}
    ;['newEmail', 'reNewemail'].forEach(field => {
        if (!data.get(field)) {
            errors[field] = 'Required'
        } else if (!EMAIL_REGEX.test(data.get(field) || '')) {
            errors[field] = 'Invalid user'
        }
    })

    const newEmail = data.get('newEmail')
    const reNewemail = data.get('reNewemail')
    if (!!newEmail && !!reNewemail && newEmail !== reNewemail) {
        errors.reNewemail = 'Do not match'
    }

    return errors
}

const form = (props: InjectedFormProps<{}, {}> & WithStyles & WithNamespaces) => {
    const { handleSubmit, submitting, t, classes } = props
    return (
        <form onSubmit={handleSubmit} className={props.classes.form}>
            <Grid container spacing={2}>
                <Grid item md={12} xs={12} className={classes.gridItem}>
                    <Field name="email" component={FormTextField} type="email" label={t('user-email')} fullWidth disabled className={classes.formInput} />
                </Grid>
                <Grid item md={12} xs={12} className={classes.gridItem} style={{ marginBottom: 8 }}>
                    <Typography gutterBottom variant="caption">
                        {`${t('email-warning')}`}
                    </Typography>
                </Grid>
                <Grid item md={6} xs={12} className={classes.gridItem}>
                    <Field name="newEmail" component={FormTextField} type="email" label={t('user-newemail')} fullWidth className={classes.formInput} />
                </Grid>
                <Grid item md={6} xs={12} className={classes.gridItem}>
                    <Field name="reNewemail" component={FormTextField} type="email" label={t('user-reNewemail')} fullWidth className={classes.formInput} />
                </Grid>

                <Grid item md={12} xs={12} style={{ textAlign: 'end' }}>
                    <Button type="submit" color="primary" disabled={submitting} className={classes.action}>
                        {t('form-action-update-email')}
                    </Button>
                </Grid>
            </Grid>
        </form>
    )
}

const translatedForm = withNamespaces()(withStyles(styles)(form))
export const SettingsEmailForm = reduxForm<{}, {}>({
    form: SettingsEmailFormName,
    validate,
    onSubmitFail: jumpToFirstError,
})(translatedForm)
