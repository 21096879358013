import {
    AddressDisplay,
    BuyerRFQStatusMap,
    DateFormatter,
    DocumentCardList,
    DocumentMenuOption,
    EntityType,
    HeaderNavLink,
    Loader,
    QuoteSummary,
    useGetQuoteQuery,
    useGetRFQQuery
} from "@syncfab/machine";
import Helmet from "react-helmet";
import { Box, Button, Dialog, DialogContent, Divider, Grid, Paper, Typography } from "@material-ui/core";
import React, { FC, useState } from "react";
import { useParams } from "react-router";
import { useStandardStyles } from "../../styles/standardStyles";
import InfoIcon from "@material-ui/icons/Info";
import TodayIcon from "@material-ui/icons/Today";
import { RFQPORequirements } from "./components/RFQPORequirements";
import { skipToken } from "@reduxjs/toolkit/query";
import WatchLaterIcon from "@material-ui/icons/WatchLater";
import { ChangeRequest } from "./components/ChangeRequest";
import { DeclineQuote } from "./components/DeclineQuote";
import { AcceptQuote } from "./components/AcceptQuote";
import { Link } from "react-router-dom";
import { BuyerRFQLineItems } from "./components/BuyerRFQLineItems";

export const ViewBuyerRFQ: FC = (() => {
    const standardClasses = useStandardStyles()
    const { rfqId } = useParams<{ rfqId?: string }>()

    const [isChangeRequestDialogOpen, setIsChangeRequestDialogOpen] = useState(false)
    const [isDeclineQuoteDialogOpen, setIsDeclineQuoteDialogOpen] = useState(false)
    const [isAcceptQuoteDialogOpen, setIsAcceptQuoteDialogOpen] = useState(false)

    const {
        data: rfqData,
        isLoading: isRFQDataLoading,
        isError: isRFQDataError,
        error: rfqDataError
    } = useGetRFQQuery(rfqId)

    const shouldRetrieveQuoteData = (status): boolean => ['REVIEW QUOTE', 'POISSUED', 'CLOSED'].includes(status)

    const { data: quoteData, isLoading: isQuoteDataLoading } =
        useGetQuoteQuery((!!rfqData?.quoteId && shouldRetrieveQuoteData(rfqData.status)) ? rfqData.quoteId : skipToken)

    const cancelChangeRequestDialog = () => {
        setIsChangeRequestDialogOpen(false)
    }

    const completeChangeRequest = () => {
        setIsChangeRequestDialogOpen(false)
    }

    const cancelDeclineQuoteDialog = () => {
        setIsDeclineQuoteDialogOpen(false)
    }

    const completeDeclineQuote = () => {
        setIsDeclineQuoteDialogOpen(false)
    }

    const cancelAcceptQuoteDialog = () => {
        setIsAcceptQuoteDialogOpen(false)
    }

    const completeAcceptQuote = () => {
        setIsAcceptQuoteDialogOpen(false)
    }

    if (isRFQDataLoading || isQuoteDataLoading) {
        return <Loader/>
    }

    if (isRFQDataError) {
        // @ts-ignore
        const errorStatusCode = rfqDataError.status
        return (
            <div className={standardClasses.centeredAlignedContainer}>
                {errorStatusCode === 401 ?
                    (
                        <Typography style={{ fontSize: 32, fontWeight: 'bold' }}>
                            You are not authorized to view this RFQ.
                        </Typography>
                    ) : (
                        <Typography style={{ fontSize: 32, fontWeight: 'bold' }}>
                            An error has occurred.
                        </Typography>
                    )
                }
            </div>
        )
    }

    if (!!rfqData) {
        return (
            <div className={standardClasses.centeredAlignedContainer}>
                <Helmet>
                    <title>RFQs</title>
                </Helmet>
                <HeaderNavLink linkTo="/quotes" linkText="RFQ INBOX"/>
                <Grid container className={standardClasses.header} spacing={2}>
                    <Grid item xs={12} md={8}>
                        <Typography
                            style={{ marginBottom: '8px', fontSize: 32, fontWeight: 'bold' }}>{rfqData._id}</Typography>
                    </Grid>
                    <Grid container item xs={12} md={4} justifyContent='flex-end'>
                        { rfqData.status as string === 'REVIEW QUOTE' && !!quoteData &&
                            <Button
                                variant='outlined'
                                component={Link}
                                to={`/quotes/${quoteData._id}/preview`}
                                size='medium'
                                style={{ color: '#40B4BF', borderColor: '#40B4BF', marginRight: '16px' }}
                            >
                                VIEW PDF QUOTE
                            </Button>
                        }
                        <Button
                            variant='outlined'
                            size='medium'
                            style={{ color: '#40B4BF', borderColor: '#40B4BF' }}
                            onClick={() => setIsChangeRequestDialogOpen(true)}
                        >
                            REQUEST CHANGE
                        </Button>
                    </Grid>
                </Grid>
                <Grid container spacing={2}>
                    <Grid item xs={8}>
                        <BuyerRFQLineItems rfq={rfqData} />
                        <RFQPORequirements rfqOrPO={rfqData} entityType='RFQ'/>
                        {rfqData.documents?.length > 0 && (
                            <Paper className={standardClasses.paper}>
                                <Grid item xs={12} style={{ padding: '16px' }}>
                                    <Typography gutterBottom style={{ fontSize: 20, fontWeight: 'bold' }}>
                                        Supporting Files
                                    </Typography>
                                </Grid>
                                <Divider style={{ width: '100%', color: "#FFFFFF40" }}/>
                                <Grid item xs={12} style={{ padding: '24px' }}>
                                    <DocumentCardList
                                        documents={rfqData.documents}
                                        setAreDocumentsDirty={() => {
                                        }}
                                        setDocuments={() => {
                                        }}
                                        availableMenuOptions={[DocumentMenuOption.VIEW, DocumentMenuOption.DOWNLOAD]}
                                        showType={false}
                                        showVisibility={false}
                                    />
                                </Grid>
                            </Paper>
                        )}
                        <Paper className={standardClasses.paper}>
                            <Grid item xs={12} style={{ padding: '16px' }}>
                                <Typography gutterBottom style={{ fontSize: 20, fontWeight: 'bold' }}>
                                    Shipping & Billing
                                </Typography>
                            </Grid>
                            <Divider style={{ width: '100%', color: "#FFFFFF40" }}/>
                            <Grid container spacing={2} style={{ padding: '16px' }}>
                                <Grid item xs={12} sm={12} md={6}>
                                    <AddressDisplay address={rfqData.buyerCompany.billingAddress}
                                                    title='Billing Address'/>
                                </Grid>
                                <Grid item xs={12} sm={12} md={6}>
                                    <AddressDisplay address={rfqData.buyerCompany.shippingAddress}
                                                    title='Shipping Address'/>
                                </Grid>
                            </Grid>
                        </Paper>
                    </Grid>
                    <Grid item xs={4}>
                        <Paper className={standardClasses.paper}>
                            <Grid container direction='column' style={{ padding: '16px' }}>
                                <Typography style={{ fontSize: '20px', fontWeight: 'bold' }}>RFQ Reference</Typography>
                                <Typography style={{ color: '#F3F3F3' }}>{rfqData.buyerRFQReference}</Typography>
                                <Box style={{ paddingTop: '16px', display: 'flex' }}>
                                    <InfoIcon fontSize='medium' style={{ color: '#A0AEC0' }}/>
                                    <Typography style={{ color: '#A0AEC0', fontWeight: 'bold', marginLeft: '8px' }}>
                                        Status: <span style={{ color: '#FFBA00' }}>{BuyerRFQStatusMap.get(
                                        rfqData.status)}</span>
                                    </Typography>
                                </Box>
                                <Box style={{ paddingTop: '16px', display: 'flex' }}>
                                    <TodayIcon fontSize='medium' style={{ color: '#A0AEC0' }}/>
                                    <Typography style={{ color: '#A0AEC0', fontWeight: 'bold', marginLeft: '8px' }}>
                                        RFQ Created:&nbsp;
                                        <span style={{ color: '#FFFFFF' }}>
                                            {rfqData.createdAt ? DateFormatter.format(
                                                new Date(rfqData.createdAt)) : '--'}
                                        </span>
                                    </Typography>
                                </Box>
                            </Grid>
                            {!!quoteData && (
                                <>
                                    <Divider style={{ width: '100%', color: "#FFFFFF40" }}/>
                                    <Grid container style={{ padding: '16px' }}>
                                        <Box style={{ display: 'flex' }}>
                                            <WatchLaterIcon fontSize='medium' style={{ color: '#A0AEC0' }}/>
                                            <Typography
                                                style={{ color: '#A0AEC0', fontWeight: 'bold', marginLeft: '8px' }}>
                                                Quote Expiration:&nbsp;
                                                <span style={{ color: '#FFFFFF' }}>
                                                    {quoteData.expiresAt ? DateFormatter.format(
                                                        new Date(quoteData.expiresAt)) : '--'}
                                                </span>
                                            </Typography>
                                        </Box>
                                    </Grid>
                                    <QuoteSummary quote={quoteData}/>
                                    { rfqData.status as string === 'REVIEW QUOTE' && (
                                    <>
                                        <Divider style={{ width: '100%', color: "#FFFFFF40" }}/>
                                        <Grid container alignItems='center' direction='column' style={{ padding: '16px' }}>
                                            <Button
                                                style={{ backgroundColor: '#FFBA00', color: '#FFFFFF' }}
                                                onClick={() => {
                                                    setIsAcceptQuoteDialogOpen(true)
                                                }}
                                            >
                                                ACCEPT QUOTE & UPLOAD PO
                                            </Button>
                                            <Typography
                                                style={{ fontSize: 16, color: '#40B4BF', marginTop: '16px' }}
                                                onClick={() => {
                                                    setIsDeclineQuoteDialogOpen(true)
                                                }}
                                            >
                                                Decline Quote
                                            </Typography>
                                        </Grid>
                                    </>
                                    )}
                                </>
                            )}
                        </Paper>
                    </Grid>
                </Grid>
                <Dialog open={isChangeRequestDialogOpen} onClose={cancelChangeRequestDialog} fullWidth>
                    <DialogContent>
                        <ChangeRequest
                            cancel={cancelChangeRequestDialog}
                            complete={completeChangeRequest}
                            entityType={EntityType.RFQ}
                            entityId={rfqData._id}
                        />
                    </DialogContent>
                </Dialog>
                { !!quoteData &&
                <>
                    <Dialog open={isDeclineQuoteDialogOpen} onClose={cancelDeclineQuoteDialog} fullWidth>
                        <DialogContent>
                            <DeclineQuote cancel={cancelDeclineQuoteDialog} complete={completeDeclineQuote} quoteId={quoteData._id} />
                        </DialogContent>
                    </Dialog>
                    <Dialog open={isAcceptQuoteDialogOpen} onClose={cancelAcceptQuoteDialog} fullWidth>
                        <DialogContent>
                            <AcceptQuote
                                cancel={cancelAcceptQuoteDialog}
                                complete={completeAcceptQuote}
                                quote={quoteData}
                                rfq={rfqData}
                            />
                        </DialogContent>
                    </Dialog>
                </>
                }
            </div>
        )
    }
    return null
})