import React from 'react'
import { Typography, Grid, TableRow, TableCell, Tooltip } from '@material-ui/core'
import { withNamespaces, WithNamespaces } from 'react-i18next'
import { TransactionItem } from './TransactionItem'
import SwapHorizIcon from '@material-ui/icons/SwapHoriz'
import { BoldTypography } from '../../../../utils/Typography/BoldTypography'
import { RightTypography } from '../../../../utils/Typography/RightTypography'
import AttachmentIcon from '@material-ui/icons/Attachment'

interface PurchasedTransactionListProps extends TransactionItem, WithNamespaces {}

export const PurchasedTransactionListItem = withNamespaces()(({ transaction }: PurchasedTransactionListProps) => {
    return (
        <TableRow key={transaction._id}>
            <TableCell style={{ padding: '4px 20px' }}>
                <Grid container direction="row" alignContent="flex-start">
                    <SwapHorizIcon style={{ color: 'white' }} />
                    <RightTypography gutterBottom>Purchase</RightTypography>
                </Grid>
            </TableCell>
            <TableCell style={{ padding: '4px 24px' }}>
                <Grid container item>
                    <Grid container direction="row" alignItems="flex-start">
                        <Grid item>
                            <Typography gutterBottom variant="h3">
                                {transaction.context}
                            </Typography>
                        </Grid>
                        <Grid item style={{ marginLeft: '12px' }}>
                            <Tooltip style={{ backgroundColor: 'black' }} title="View on Etherscan">
                                <AttachmentIcon
                                    style={{ color: 'white' }}
                                    onClick={() => {
                                        window.open('https://etherscan.io/tx/' + transaction.txnHash, '_blank')
                                    }}
                                />
                            </Tooltip>
                        </Grid>
                    </Grid>
                    <Typography gutterBottom>
                        From: {/* <Button variant="text" color="primary"> */}
                        {transaction.particular}
                        {/* </Button> */}
                    </Typography>
                </Grid>
            </TableCell>
            <TableCell>
                <Grid item>
                    <Typography gutterBottom>{new Date(transaction.createdAt).toLocaleDateString()}</Typography>
                </Grid>
            </TableCell>
            <TableCell>
                <Grid item>
                    <BoldTypography gutterBottom>+{transaction.value} MFG</BoldTypography>
                </Grid>
            </TableCell>
        </TableRow>
        // <Grid item container spacing={8} xs={12} direction="row" justify="space-between" alignContent="center">
        //     <Grid container item xs={2} direction="row" alignContent="center">
        //         <TransactionUpIcon />
        //         <RightTypography gutterBottom>{t('wallet-transactions-purchased')}</RightTypography>
        //     </Grid>
        //     <Grid item xs={6}>
        //         <Typography gutterBottom>
        //             {t('wallet-transactions-purchased-description')} {!!transaction.context && transaction.context.referenceId}
        //         </Typography>
        //     </Grid>
        //     <Grid item xs={2}>
        //         <Typography gutterBottom>{new Date(transaction.createdAt).toLocaleDateString()}</Typography>
        //     </Grid>
        //     <Grid item xs={2}>
        //         <BoldTypography gutterBottom>{transaction.value}</BoldTypography>
        //     </Grid>
        // </Grid>
    )
})
