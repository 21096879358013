import React from 'react'
import { Helmet } from 'react-helmet'
import { WithStyles } from '@material-ui/core/styles'
import { TextField, SHOW_FEEDBACK } from '@syncfab/machine'
import { Paper, withStyles, Typography, Button } from '@material-ui/core'
import { CenteredContainer } from '../../layout/Container'
import { connect } from 'react-redux'
import { Dispatch } from 'redux'
import { State } from '../../application/Store'
import { RESEND_VERIFICATION_EMAIL_USER } from './Actions'
import { withNamespaces, WithNamespaces } from 'react-i18next'
import { EMAIL_REGEX } from '../../utils/Regex'

const styles = theme => ({
    paper: {
        padding: theme.spacing(6),
        display: 'flex',
        'flex-direction': 'column',
        'justify-content': 'center',
        alignItems: 'center',
        width: 400,
        'text-align': 'center',
    },
    text: {
        padding: theme.spacing(2),
        color: theme.palette.common.white,
        marginBottom: theme.spacing(2),
    },
    link: {
        width: '100%',
    },
    sentIcon: {
        marginLeft: -16,
    },
})

interface ReverificationProps extends WithNamespaces, WithStyles {
    resendVerification: (email: string) => void
    showError: (error: string) => void
}

interface ReverificationState {
    userEmail: string
}

// TODO(JR): Unify this view
class Reverification extends React.Component<ReverificationProps, ReverificationState> {
    constructor(props: ReverificationProps) {
        super(props)
        const state: ReverificationState = {
            userEmail: '',
        }
        this.state = state
    }

    resendLink = () => {
        if (!EMAIL_REGEX.test(this.state.userEmail || '')) {
            this.props.showError('Email Address is not valid.')
        }
        if (this.state.userEmail) {
            this.props.resendVerification(this.state.userEmail || '')
        }
    }

    public render() {
        const { classes, t } = this.props
        return (
            <CenteredContainer>
                <Helmet>
                <title>{`${t('signup-verification-title')}`}</title>
                </Helmet>
                <Paper className={classes.paper} elevation={1}>
                    <Typography variant="h2" gutterBottom className={classes.title}>
                        {`${t('signup-confirmation-resend')}`}
                    </Typography>
                    <TextField
                        value={this.state.userEmail}
                        onChange={(e: any) => {
                            this.setState({ userEmail: e.target.value })
                        }}
                        name="email"
                        fullWidth
                        type="email"
                        label={t('user-email')}
                    />
                    <Button variant="contained" style={{ marginTop: 30 }} type="submit" color="secondary" onClick={this.resendLink}>
                        Resend
                    </Button>
                </Paper>
            </CenteredContainer>
        )
    }
}

const mapStateToProps = ({ user: {} }: State) => {
    return {}
}

const mapDispatchToProps = (dispatch: Dispatch) => {
    return {
        showError: error => dispatch(SHOW_FEEDBACK(error)),
        resendVerification: (email: string) => dispatch(RESEND_VERIFICATION_EMAIL_USER(email)),
    }
}
const ReverificationViewWithTranslations = withNamespaces()(withStyles(styles)(Reverification))

export const ReverificationView = connect(
    mapStateToProps,
    mapDispatchToProps
)(withStyles(styles)(ReverificationViewWithTranslations))
