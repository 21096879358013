import React from 'react'
import { Menu, MenuItem, withStyles, WithStyles, Typography } from '@material-ui/core'
import { WithNamespaces, withNamespaces } from 'react-i18next'
import Sort from '@material-ui/icons/Sort'
import { Button } from '@syncfab/machine'

interface SortButtonProps extends WithNamespaces, WithStyles {
    sort: (field: string, direction: number, filter: string) => void
    field: string
    direction: number
    filter: string
}

type SortButtonState = {
    anchorEl: any
}

const styles = theme => ({
    sort: {
        color: theme.palette.common.white,
    },
    marginText: {
        marginLeft: theme.spacing(1),
    },
})

class SortButtonElement extends React.Component<SortButtonProps, SortButtonState> {
    constructor(props) {
        super(props)
        this.state = {
            anchorEl: null,
        }
    }

    handleMenu = event => {
        this.setState({ anchorEl: event.currentTarget })
    }

    handleSelection = (field: string, direction: number, filter: string) => {
        this.props.sort(field, direction, filter)
        this.handleClose()
    }

    handleClose = () => {
        this.setState({ anchorEl: null })
    }

    render() {
        const { t, classes } = this.props
        const { anchorEl } = this.state
        return (
            <React.Fragment>
                <Button variant="text" onClick={this.handleMenu} className={classes.sort}>
                    <Sort className={classes.sort} />
                    {/* {!!field ? `${t(`syncfab-direction-${direction}-${field}`)} ${t(`field-${field}`)}` : t('syncfab-sort')} */}
                    <Typography className={classes.marginText}>{`${t('syncfab-sort')}`}</Typography>
                </Button>
                <Menu id="menu-transaction-sorting" anchorEl={anchorEl} open={!!anchorEl} onClose={this.handleClose}>
                    <MenuItem onClick={() => this.handleSelection('createdAt', -1, null)}>{t('Newest Created')}</MenuItem>
                    <MenuItem onClick={() => this.handleSelection('createdAt', 1, null)}>{t('Oldest Created')}</MenuItem>
                    <MenuItem onClick={() => this.handleSelection('type', 1, null)}>{t('Alphabetic Category')}</MenuItem>
                    <MenuItem onClick={() => this.handleSelection('createdAt', -1, 'SENT')}>{t('Sent Only')}</MenuItem>
                    <MenuItem onClick={() => this.handleSelection('createdAt', 1, 'DEPOSIT')}>{t('Received Only')}</MenuItem>
                    <MenuItem onClick={() => this.handleSelection('createdAt', 1, 'PURCHASED')}>{t('Purchase Only')}</MenuItem>
                </Menu>
            </React.Fragment>
        )
    }
}

export const SortButton = withStyles(styles)(withNamespaces()(SortButtonElement))
