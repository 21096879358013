import React from 'react'
import SvgIcon from '@material-ui/core/SvgIcon'

export const EmailSent = props => {
    return (
        <SvgIcon {...props} viewBox="0 0 72 34" x="0px" y="0px" baseProfile="tiny" xmlSpace="preserve" style={{ width: '80px' }}>
            <path
                fill="#FFFFFF"
                d="M14.36,14.51c1.42,0,2.57,1.11,2.57,2.48c0,1.37-1.15,2.48-2.57,2.48H7.18c-1.42,0-2.57-1.11-2.57-2.48
				c0-1.37,1.15-2.48,2.57-2.48H14.36z M22.08,6.14l24.99,14.45L72,6.14V2.81c0-0.78-0.34-1.44-0.81-1.96C70.64,0.33,69.9,0,69.09,0
				H24.99c-0.81,0-1.49,0.33-2.03,0.85c-0.54,0.52-0.88,1.18-0.88,1.96L22.08,6.14z M58.72,19.61L72,31.05V11.9L58.72,19.61z
				M22.08,31.38l13.34-11.77L22.08,11.9v19.29L22.08,31.38L22.08,31.38z M45.72,25.63l-5.69-3.33L26.76,34h40.91L54.05,22.3
				l-5.62,3.27C47.62,26.09,46.6,26.09,45.72,25.63L45.72,25.63z M14.36,24.65c1.42,0,2.57,1.18,2.57,2.55c0,1.37-1.15,2.48-2.57,2.48
				h-2.57c-1.42,0-2.57-1.11-2.57-2.48c0-1.37,1.15-2.55,2.57-2.55L14.36,24.65z M14.36,4.38c1.42,0,2.57,1.11,2.57,2.48
				c0,1.37-1.15,2.48-2.57,2.48H2.58C1.15,9.35,0,8.24,0,6.86c0-1.37,1.15-2.48,2.57-2.48L14.36,4.38z"
            />
        </SvgIcon>
    )
}
