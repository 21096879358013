import { PaletteOptions } from '@material-ui/core/styles/createPalette'

export const palette: PaletteOptions = {
    common: {
        black: '#000',
        white: '#fff',
    },
    type: 'light',
    primary: {
        light: '#44C5D2',
        main: '#40B4BF',
        dark: '#3B9FA6',
        contrastText: '#fff',
    },
    secondary: {
        light: '#FFC525',
        main: '#FFBA00',
        dark: '#FFAD00',
        contrastText: '#fff',
    },
    error: {
        light: '#FF6859',
        main: '#BF4B40',
        dark: '#A03F32',
        contrastText: '#fff',
    },
    grey: {
        50: '#fafafa',
        100: '#f5f5f5',
        200: '#eeeeee',
        300: '#e0e0e0',
        400: '#bdbdbd',
        500: '#9e9e9e',
        600: '#757575',
        700: '#616161',
        800: '#424242',
        900: '#212121',
        A100: '#d5d5d5',
        A200: '#aaaaaa',
        A400: '#303030',
        A700: '#616161',
    },
    contrastThreshold: 3,
    tonalOffset: 0.2,
    text: {
        primary: 'rgba(0, 0, 0, 0.87)',
        secondary: 'rgba(0, 0, 0, 0.54)',
        disabled: 'rgba(0, 0, 0, 0.38)',
        hint: 'rgba(0, 0, 0, 0.38)',
    },
    divider: '#566E83', //'rgba(0, 0, 0, 0.12)',
    background: {
        paper: '#364F68',
        default: '#1C2D3E',
    },
    action: {
        active: 'rgba(0, 0, 0, 0.54)',
        hover: 'rgba(0, 0, 0, 0.08)',
        hoverOpacity: 0.08,
        selected: 'rgba(0, 0, 0, 0.14)',
        disabled: 'rgba(0, 0, 0, 0.26)',
        disabledBackground: 'rgba(0, 0, 0, 0.12)',
    },
    customComponent: {
        backgroundColor: '#364F68',
        iconBackgroundColor: '#E0E0E0',
        iconColor: '#6B7280',
        textColor: 'white',
        captionTextColor: '#C0CBD9',
        cardBorderColor: '#FFFFFF40',
        listBackgroundColor: '#364F68',
        listHeaderBorderColor: '#FFFFFF40',
        listHeaderHoverColor: '#FFFFFF',
        innerTableBackgroundColor: '#FFFFFF15',
        innerTableBorderColor: '#FFFFFF40',
        fieldBackgroundColor: '#364F68',
        fieldBorderColor: '#FFFFFF40'
    }
}

export const colors: { [key: string]: any } = {
    surfaceDark: '#233A4F',
    tertiary: {
        light: '#715FCE',
        main: '#4B40BF',
        dark: '#2A32AE',
        contrastText: '#fff',
    },
    success: {
        primary: '#40BF8A',
        dark: '#00A46A',
        main: '#40BF71',
    },
    warning: {
        light: '#FDECEA',
    },
    disabled:'#6B7E94',
}
