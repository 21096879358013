import { UserIdAndName } from '../BaseTypes';
import { PartDAPP } from '../part';
import { QuoteLineItem, RFQ } from '../quote';
import { ValueLabelOption } from '../ui';
import { Theme } from '@material-ui/core';
import { PurchaseOrder } from './PurchaseOrder';

export enum PurchaseOrderLineItemStatus {
  DRAFT = 'Draft',
  NOTISSUED = 'Not Issued',
  ISSUED = 'Issued',
  MATERIALORDERED = 'Material Ordered',
  INPRODUCTION = 'In Production',
  OSP = 'OSP (Outside Processing)',
  INSPECTION = 'In Inspection',
  IN_FAI = 'In FAI',
  QA_REVIEW = 'QA Review',
  SHIPPED = 'Shipped',
  RECEIVED = 'Received',
  STOCK = 'Stock',
  ONHOLD = 'On Hold',
  RECEIPTISSUE = 'Customer Receipt Issue',
  READYTOINVOICE = 'Ready to Invoice',
  INVOICESENT = 'Invoice Sent',
  PAYMENTDUE = 'Payment Due',
  PAYMENTRECEIVED = 'Payment Received',
  PAID = 'Paid',
  CANCELED = 'Canceled',
  REVISED = 'Revised',
}

export const StatusesForPastDockDateSearches = [
  PurchaseOrderLineItemStatus.DRAFT,
  PurchaseOrderLineItemStatus.NOTISSUED,
  PurchaseOrderLineItemStatus.ISSUED,
];
export enum PurchaseOrderDockDateData {
  ALL = 'ALL',
  NEXT_7_DAYS = 'NEXT_7_DAYS',
  NEXT_14_DAYS = 'NEXT_14_DAYS',
  NEXT_30_DAYS = 'NEXT_30_DAYS',
  NEXT_3_MONTHS = 'NEXT_3_MONTHS',
  NEXT_6_MONTHS = 'NEXT_6_MONTHS',
  NEXT_9_MONTHS = 'NEXT_9_MONTHS',
  NEXT_12_MONTHS = 'NEXT_12_MONTHS',
  LAST_30_DAYS = 'LAST_30_DAYS',
  LAST_6_MONTHS = 'LAST_6_MONTHS',
  LAST_12_MONTHS = 'LAST_12_MONTHS',
}
export const PurchaseOrderDockDateDataToDisplayMap = {
  [PurchaseOrderDockDateData.ALL]: 'All',
  [PurchaseOrderDockDateData.NEXT_7_DAYS]: 'Next 7 Days',
  [PurchaseOrderDockDateData.NEXT_14_DAYS]: 'Next 14 Days',
  [PurchaseOrderDockDateData.NEXT_30_DAYS]: 'Next 30 Days',
  [PurchaseOrderDockDateData.NEXT_3_MONTHS]: 'Next 3 Months',
  [PurchaseOrderDockDateData.NEXT_6_MONTHS]: 'Next 6 Months',
  [PurchaseOrderDockDateData.NEXT_9_MONTHS]: 'Next 9 Months',
  [PurchaseOrderDockDateData.NEXT_12_MONTHS]: 'Next 12 Months',
  [PurchaseOrderDockDateData.LAST_30_DAYS]: 'Last 30 Days',
  [PurchaseOrderDockDateData.LAST_6_MONTHS]: 'Last 6 Months',
  [PurchaseOrderDockDateData.LAST_12_MONTHS]: 'Last 12 Months',
};

export const getPOLineItemStatusBackgroundColor = (
  status: PurchaseOrderLineItemStatus,
  theme: Theme
): string => {
  if (status === PurchaseOrderLineItemStatus.NOTISSUED) {
    return theme.palette.secondary.main; //yellow
  } else if (
    [
      PurchaseOrderLineItemStatus.STOCK,
      PurchaseOrderLineItemStatus.ONHOLD,
      PurchaseOrderLineItemStatus.RECEIPTISSUE,
      PurchaseOrderLineItemStatus.CANCELED,
      PurchaseOrderLineItemStatus.REVISED,
    ].includes(status)
  ) {
    return theme.palette.error.main; //red
  } else if ([PurchaseOrderLineItemStatus.PAID].includes(status)) {
    return theme.palette.success.main; // green
  } else if ([PurchaseOrderLineItemStatus.ISSUED].includes(status)) {
    return theme.palette.grey[500];
  } else {
    return theme.palette.grey[600];
  }
};

export const getSupplierPOLineItemStatusBackgroundColor = (
  status: PurchaseOrderLineItemStatus,
  theme: Theme
): string => {
  switch (status) {
    case PurchaseOrderLineItemStatus.ONHOLD:
    case PurchaseOrderLineItemStatus.RECEIPTISSUE:
    case PurchaseOrderLineItemStatus.STOCK:
    case PurchaseOrderLineItemStatus.CANCELED:
    case PurchaseOrderLineItemStatus.REVISED:
      return theme.palette.error.main; //red
    case PurchaseOrderLineItemStatus.PAID:
      return theme.palette.success.main; // green
    default:
      return theme.palette.warning.main; // yellow
  }
};

export interface PurchaseOrderLineItemInput {
  status?: PurchaseOrderLineItemStatus;
  selectedSupplier: {
    supplierId: string;
  };
  initialDockDate?: Date;
  shipDate?: Date;
  currentDockDate?: Date;
  completionDate?: Date;
  pricing: {
    quantity: number;
    leadTimeDays: number;
    supplierUnitCost: number;
    markup: number;
    unitCost: number;
    subtotal: number;
  };
  additionalCharges: PurchaseOrderLineItemCharge[];
  exceptions?: string;
  notes?: string;
  material?: string;
  partIdentification?: string;
  serialNumber?: string;
  outsideServices?: string;
  materialLotNumber?: string;
  updateComment?: string;
  updateCommentDate?: Date;
}

export interface PurchaseOrderLineItem extends PurchaseOrderLineItemInput {
  _id: string;
  poId: string;
  poRevision: number;
  rfqId: string;
  quoteId: string;
  quoteLineItemId: string;
  partId: string;
  selectedSupplier: {
    supplierId: string;
    supplierName: string;
  };
  purchaseOrder: PurchaseOrder;
  part?: PartDAPP;
  shippedQuantity?: number;
  receivedQuantity?: number;
  quoteLineItem?: QuoteLineItem;
  createdAt: Date;
  createdBy: UserIdAndName;
  updatedAt?: Date;
  updatedBy?: UserIdAndName;
  deletedAt?: Date;
  deletedBy?: UserIdAndName;
  rfq: RFQ;
  grossMargin?: number;
}

export interface PurchaseOrderLineItemCharge {
  _id: string;
  chargeType: string;
  supplierChargeAmount: number;
  chargeMarkup: number;
  totalChargeAmount: number;
  grossMargin?: number;
  chargeTypeValue?: ValueLabelOption;
}

export const generatePOLineItemOption = (
  lineItem: PurchaseOrderLineItem
): ValueLabelOption => {
  return {
    value: lineItem._id,
    label: `${lineItem._id} - ${lineItem.selectedSupplier.supplierName} - ${lineItem.part.number} - ${lineItem.part.revision} - ${lineItem.part.name}`,
  };
};

export const generatePOLineItemOptions = (
  lineItems: PurchaseOrderLineItem[]
): ValueLabelOption[] => {
  return lineItems.map(lineItem => generatePOLineItemOption(lineItem));
};
