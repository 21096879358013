import React from 'react';
import { Loader } from '@syncfab/machine';
import { withStyles, WithStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TablePagination from '@material-ui/core/TablePagination';
import Paper from '@material-ui/core/Paper';
import { Typography } from '@material-ui/core';
import { withNamespaces, WithNamespaces } from 'react-i18next';
import { ListToolbar } from './ListToolbar';
// import { ListHead } from './ListHead'
import { SortDirection, ListMeta } from './Meta';
import ListHeader from './ListHeader';

const styles = theme => ({
  root: {
    width: '100%',
    marginTop: theme.spacing(3),
    backgroundColor: theme.palette.background.paper,
    borderBottom: '1px solid #65788B',
  },
  tableWrapper: {
    'overflow-x': 'auto',
  },
  filterLabel: {
    padding: `${theme.spacing(1)}px ${theme.spacing(3)}px`,
  },
  customTable: {
    '&.MuiTableCell': {
      color: '#fff', // <-- arbitrary value
    },
  },
  selectIcon: {
    color: '#fff',
  },
});
export interface ListProps<T> extends WithStyles, WithNamespaces {
  elements: T[];
  meta: ListMeta;
  onChange: (meta: ListMeta) => void;
  renderBody: (elements: any[]) => JSX.Element | JSX.Element[]; // Typescript does not support T[] here
  renderMenu?: (
    handleSelection: (filter: string) => void
  ) => JSX.Element | JSX.Element[];
  rows: { id: string; label: string }[];
  hideSearch?: boolean;
  hideSortFilter?: boolean;
  showStatusFilter?: boolean;
  statusArray?: any[];
  showUserFilter?: boolean;
  userStatusList?: any[];
  tableTitle?: string;
  selected?: number;
  onSelectAllClick?: any;
  showSelectAll?: boolean;
  showRefreshButton?: boolean;
}

class ListComponent<T> extends React.Component<ListProps<T>> {
  onFilter = (filter: string) => {
    const { meta } = this.props;
    if (filter !== meta.filter) {
      this.props.onChange({
        ...meta,
        filter,
        page: 0,
      });
    }
  };

  onClickUserStatusFilter = (userState: string) => {
    const { meta } = this.props;
    console.log('userState', userState);
    if (userState === '') {
      delete meta.userState;
      this.props.onChange({
        ...meta,
      });
    } else if (userState !== meta.userState) {
      this.props.onChange({
        ...meta,
        userState,
      });
    }
  };

  onSearch = data => {
    const { meta } = this.props;
    const query = data.get('query');

    if (query !== meta.query && !!query) {
      this.props.onChange({
        ...meta,
        page: 0,
        query,
      });
    } else if (!query) {
      this.props.onChange({
        ...meta,
        page: 0,
        query: undefined
      });
    }
  };

  onSort = (_, property) => {
    const { meta } = this.props;
    this.props.onChange({
      ...meta,
      sortField: property,
      sortDirection:
        meta.sortField === property && meta.sortDirection === SortDirection.DESC
          ? SortDirection.ASC
          : SortDirection.DESC,
    });
  };

  onChangePage = (_, page) => {
    const { meta } = this.props;
    this.props.onChange({
      ...meta,
      page,
    });
  };

  onChangeSize = event => {
    const { meta } = this.props;
    this.props.onChange({
      ...meta,
      size: event.target.value,
    });
  };

  onResetFilter = () => {
    const { meta } = this.props;
    if (meta.filter) {
      meta.filter = 'ALL';
    }
    if (meta?.userState) {
      delete meta.userState;
    }
    this.props.onChange({
      ...meta,
    });
  };
  onRefresh = () => {
    const { meta } = this.props;
    this.props.onChange({
      ...meta,
    });
  };
  render() {
    const {
      elements,
      meta,
      classes,
      t,
      rows,
      renderMenu,
      hideSearch,
      hideSortFilter,
      statusArray,
      showStatusFilter,
      showUserFilter,
      userStatusList,
      tableTitle,
      onSelectAllClick,
      selected,
      showSelectAll,
      showRefreshButton,
    } = this.props;
    // const emptyRows = meta.size - Math.min(meta.size, meta.total - meta.page * meta.size)
    if (!elements) {
      return <Loader />;
    }
    return (
      <Paper className={classes.root}>
        {renderMenu && (
          <ListToolbar
            onSearch={this.onSearch}
            onFilter={this.onFilter}
            renderMenu={renderMenu}
            showStatusFilter={showStatusFilter}
            hideSearch={hideSearch}
            onResetFilter={this.onResetFilter}
            meta={meta}
            statusArray={statusArray}
            showUserFilter={showUserFilter}
            userStatusList={userStatusList}
            tableTitle={tableTitle}
            showRefreshButton={showRefreshButton}
            onRefresh={this.onRefresh}
          />
        )}
        {!renderMenu && (
          <ListToolbar
            hideSearch={hideSearch}
            onSearch={this.onSearch}
            hideFilter={true}
            hideRenderMenu={true}
            meta={meta}
            onSortFilter={val => {
              const sortDirection = val === 'NEWEST' ? -1 : 1;
              console.log('sortDirection', sortDirection);
              this.props.onChange({
                ...meta,
                sortField: 'createdAt',
                sortDirection: sortDirection,
              });
            }}
            showSortFilter={!hideSortFilter}
            showStatusFilter={showStatusFilter}
            statusArray={statusArray}
            onResetFilter={this.onResetFilter}
            showUserFilter={showUserFilter}
            userStatusList={userStatusList}
            onClickUserStatusFilter={this.onClickUserStatusFilter}
            tableTitle={tableTitle}
            showRefreshButton={showRefreshButton}
            onRefresh={this.onRefresh}
          />
        )}
        {!statusArray && renderMenu && (
          <Typography variant="subtitle2" className={classes.filterLabel}>
            <>
              {t('filter-label')}: {meta.filter}{' '}
              {!!meta.query && `, ${t('filter-search-label')}: ${meta.query}`}
            </>
          </Typography>
        )}
        <div className={classes.tableWrapper}>
          <Table aria-labelledby="RFQS" className={classes.customTable}>
            {elements?.length > 0 && (
              <ListHeader
                sortDirection={meta.sortDirection}
                sortField={meta.sortField}
                onRequestSort={this.onSort}
                rows={rows}
                onSelectAllClick={onSelectAllClick}
                selected={selected}
                total={meta.total}
                showSelectAll={showSelectAll}
              />
            )}
            <TableBody>
              {elements?.length > 0 && this.props.renderBody(elements)}
              {/* {elements?.length > 0 && emptyRows > 0 && (
                                <TableRow style={{ height: 49 * emptyRows }}>
                                    <TableCell colSpan={6} />
                                </TableRow>
                            )} */}
            </TableBody>
          </Table>
        </div>
        {elements?.length > 0 && (
          <TablePagination
            rowsPerPageOptions={[5, 10, 15, 20]}
            component="div"
            count={meta.total}
            rowsPerPage={meta.size}
            page={meta.page}
            backIconButtonProps={{
              'aria-label': 'Previous Page',
            }}
            nextIconButtonProps={{
              'aria-label': 'Next Page',
            }}
            onPageChange={this.onChangePage}
            onRowsPerPageChange={this.onChangeSize}
            style={{ color: '#fff' }}
            classes={{
              selectIcon: classes.selectIcon,
            }}
          />
        )}
      </Paper>
    );
  }
}

export const List = withNamespaces()(withStyles(styles)(ListComponent));
