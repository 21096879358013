import React from 'react'
import { WithNamespaces, withNamespaces } from 'react-i18next'
import { connect } from 'react-redux'
import { WithStyles, withStyles, Paper, Typography, Grid, Card, Dialog, Button } from '@material-ui/core'
import { Dispatch } from 'redux'
import { CenteredAlignedContainer } from '../../../layout/Container'
import { State } from '../../../application/Store'
import { RouteComponentProps } from 'react-router'
import { GET_SUPPLIER } from '../../../components/supplier/Actions'
import { Loader } from '@syncfab/machine'
import PDFViewer from './PDFViewer'
import { DOCUMENT_DOWNLOAD_URL, RESET_DOWNLOAD_URL } from '../../../components/document/Actions'
import { Link } from 'react-router-dom'
import ArrowBack from '@material-ui/icons/ArrowBack'
import OpenInNewIcon from '@material-ui/icons/OpenInNew'
import { Languages } from '../../../data/languages'

const styles = theme => ({
    dialog: { marginTop: theme.spacing(2) },
    paper: {
        width: '90%',
        minWidth: 300,
        padding: theme.spacing(1),
        marginTop: theme.spacing(4),
        marginBottom: theme.spacing(4),
        background: 'transparent',
        boxShadow: 'none',
    },
    detailcard: {
        padding: '20px',
    },
    capabalities: {
        padding: 0,
        listStyle: 'none',
        color: theme.palette.text.secondary,
        fontFamily: 'Titillium Web',
    },
    listItem: {
        display: 'inline-block',
        margin: '0 10px 10px 0',
        borderRadius: '20px',
        padding: '5px 10px',
        fontSize: '13px',
        color: theme.palette.common.white,
        border: '1px solid #969696',
    },
    docWrapper: {
        border: '1px solid #969696',
        padding: '20px',
        borderRadius: '5px',
        marginBottom: '20px',
        color: theme.palette.common.white,
    },
    viewIcon: {
        fontSize: '30px',
        cursor: 'pointer',
    },
    map: {
        width: '100%',
        height: '300px',
        maxWidth: '100%',
        borderRadius: '8px',
        marginBottom: '20px',
    },
    arrow: {
        color: theme.palette.common.white,
        marginRight: theme.spacing(1),
    },
    header: {
        marginBottom: theme.spacing(1),
    },
    contactBox: {
        border: '1px solid #969696',
        borderRadius: '5px',
        padding: '10px 15px',
    },
    subTitle: {
        color: theme.palette.common.white,
        fontSize: '18px',
    },
    viewPdf: {
        marginRight: '15px',
        cursor: 'pointer',
    },
    grayText: {
        color: '#b5b5b5',
    },
    '@media all and (max-width:959px)': {
        gridItem: {
            width: '100%',
        },
        detailcard: {
            marginLeft: '0 !important',
        },
    },
})

interface SupplierProps extends WithNamespaces, WithStyles, RouteComponentProps<{ id: string }> {
    isLoading: boolean
    history: any
    getSupplier: (supplierId: string) => void
    download: (id: string) => void
    reset: () => void
    suppliers: any
    blobPDF: any
    supplierDetails: any
}

interface SupplierState {
    WarningDialog: boolean
}

class SupplierView extends React.Component<SupplierProps, SupplierState> {
    constructor(props: SupplierProps) {
        super(props)
        this.state = {
            WarningDialog: false,
        }

        this.props.getSupplier(props.match.params.id)
    }

    closeWarningDialog = () => {
        this.setState({ WarningDialog: false })
        this.props.reset()
    }

    viewPDF = docId => {
        this.props.download(docId)
        this.setState({ WarningDialog: true })
    }

    render() {
        const { supplierDetails, classes, isLoading, blobPDF, t } = this.props
        const { WarningDialog } = this.state

        if (!supplierDetails) {
            return <Loader />
        }
        if (isLoading) {
            return <Loader />
        }

        return (
            <CenteredAlignedContainer>
                <Paper className={classes.paper} elevation={1}>
                    <Grid container spacing={3} alignItems="center" className={classes.header}>
                        <Link to={'/suppliers'}>
                            <ArrowBack className={classes.arrow} />
                        </Link>
                        <Typography color="primary" gutterBottom variant="h2" style={{ marginBottom: '3px' }}>
                            {supplierDetails && supplierDetails.companyName}
                        </Typography>
                    </Grid>
                    {supplierDetails && (
                        <Grid container>
                            <Grid item md={7} className={classes.gridItem}>
                                <Card className={classes.detailcard}>
                                    <Typography gutterBottom variant="h3" style={{ marginBottom: '20px' }}>
                                        {'Details'}
                                    </Typography>
                                    <Typography gutterBottom variant="body1">
                                        <b>Company Name:</b> {supplierDetails.companyName}
                                    </Typography>
                                    <Typography gutterBottom variant="body1">
                                        <b>Company Size:</b> {supplierDetails.size ? supplierDetails.size : '-'}
                                    </Typography>
                                    <Typography gutterBottom variant="body1">
                                        <>
                                            <b>Industry:</b>
                                        {supplierDetails.industry && supplierDetails.industry.length > 0
                                            ? supplierDetails.industry.map((industry, index) => {
                                              return <span key={index}>{`${t(`company-industry-${industry ? industry.toLowerCase() : ''}`)}`}</span>
                                            })
                                            : '-'}
                                        </>
                                    </Typography>
                                    <Typography gutterBottom variant="body1">
                                        <b>Company Languages:</b>{' '}
                                        {supplierDetails.language
                                            ? supplierDetails.language
                                                  .map(lng => {
                                                      return Languages.filter(lg => lg.code === lng)[0].name
                                                  })
                                                  .join(',')
                                            : '-'}
                                    </Typography>
                                    <Typography gutterBottom variant="body1">
                                        <b>State/Canton Languages:</b>{' '}
                                        {supplierDetails.preferredLanguage
                                            ? supplierDetails.preferredLanguage
                                                  .map(lng => {
                                                      return Languages.filter(lg => lg.code === lng)[0].name
                                                  })
                                                  .join(',')
                                            : '-'}
                                    </Typography>
                                    <Typography gutterBottom variant="h3" style={{ margin: '30px 0 20px' }}>
                                        {'Contact'}
                                    </Typography>
                                    <Grid container spacing={2}>
                                        <Grid item md={6}>
                                            <div className={classes.contactBox}>
                                                <Typography gutterBottom className={classes.subTitle}>
                                                    {supplierDetails.firstName} {supplierDetails.lastName}
                                                </Typography>
                                                <Typography gutterBottom variant="body1">
                                                    <b>Title:</b> {supplierDetails.title ? supplierDetails.title : '-'}
                                                </Typography>
                                                <Typography gutterBottom variant="body1">
                                                    <b>Email:</b> {supplierDetails.email}
                                                </Typography>
                                                <Typography gutterBottom variant="body1">
                                                    <b>Phone:</b> {supplierDetails.phoneNumber}
                                                </Typography>
                                            </div>
                                        </Grid>

                                        {supplierDetails.teams && supplierDetails.teams.length > 0
                                            ? supplierDetails.teams.map((teamMember: any, index) => {
                                                  return (
                                                      <Grid item md={6} key={index}>
                                                          <div className={classes.contactBox}>
                                                              <Typography gutterBottom className={classes.subTitle}>
                                                                  {teamMember.firstName} {teamMember.lastName}
                                                              </Typography>
                                                              <Typography gutterBottom variant="body1">
                                                                  <b>Title:</b> {teamMember.title ? teamMember.title : '-'}
                                                              </Typography>
                                                              <Typography gutterBottom variant="body1">
                                                                  <b>Email:</b> {teamMember.email}
                                                              </Typography>
                                                              <Typography gutterBottom variant="body1">
                                                                  <b>Phone:</b> {teamMember.phoneNumber}
                                                              </Typography>
                                                          </div>
                                                      </Grid>
                                                  )
                                              })
                                            : null}
                                    </Grid>
                                </Card>
                                <Card className={classes.detailcard} style={{ margin: '24px 0 0' }}>
                                    <Typography gutterBottom variant="h3" style={{ marginBottom: '20px' }}>
                                        {'Supplier Capabilities'}
                                    </Typography>
                                    <Typography gutterBottom variant="body1">
                                        <b>Manufacturing Capabilities</b>
                                    </Typography>
                                    <ul className={classes.capabalities}>
                                        {supplierDetails.manufacturingProcesses && supplierDetails.manufacturingProcesses.length > 0 ? (
                                            supplierDetails.manufacturingProcesses.map((item, index) => {
                                                return (
                                                    <li key={index} className={classes.listItem}>
                                                        {item
                                                            ? `${item.charAt(0)}${item
                                                                  .slice(1)
                                                                  .toLowerCase()
                                                                  .replace(/_/g, ' ')}`
                                                            : ''}
                                                    </li>
                                                );
                                            })
                                        ) : (
                                            <Typography gutterBottom variant="body1">
                                                -
                                            </Typography>
                                        )}
                                    </ul>
                                    <Typography gutterBottom variant="body1">
                                        <b>Finishing Capabilities</b>
                                    </Typography>
                                    <ul className={classes.capabalities}>
                                        {supplierDetails.finishes && supplierDetails.finishes.length > 0 ? (
                                            supplierDetails.finishes.map((item, index) => {
                                                return (
                                                    <li key={index} className={classes.listItem}>
                                                        {item
                                                            ? `${item.charAt(0)}${item
                                                                  .slice(1)
                                                                  .toLowerCase()
                                                                  .replace(/_/g, ' ')}`
                                                            : ''}
                                                    </li>
                                                );
                                            })
                                        ) : (
                                            <Typography gutterBottom variant="body1">
                                                -
                                            </Typography>
                                        )}
                                    </ul>
                                    <Typography gutterBottom variant="body1">
                                        <b>Certifications</b>
                                    </Typography>
                                    <ul className={classes.capabalities}>
                                        {supplierDetails.certifications && supplierDetails.certifications.length > 0 ? (
                                            supplierDetails.certifications.map((item, index) => {
                                                return (
                                                    <li key={index} className={classes.listItem}>
                                                        {item
                                                            ? `${item.charAt(0)}${item
                                                                  .slice(1)
                                                                  .toLowerCase()
                                                                  .replace(/_/g, ' ')}`
                                                            : ''}
                                                    </li>
                                                );
                                            })
                                        ) : (
                                            <Typography gutterBottom variant="body1">
                                                -
                                            </Typography>
                                        )}
                                    </ul>
                                    <Typography gutterBottom variant="body1">
                                        <b>Material Preferences</b>
                                    </Typography>
                                    <ul className={classes.capabalities}>
                                        {supplierDetails.materials && supplierDetails.materials.length > 0 ? (
                                            supplierDetails.materials.map((item, index) => {
                                                return (
                                                    <li key={index} className={classes.listItem}>
                                                        {item
                                                            ? `${item.charAt(0)}${item
                                                                  .slice(1)
                                                                  .toLowerCase()
                                                                  .replace(/_/g, ' ')}`
                                                            : ''}
                                                    </li>
                                                );
                                            })
                                        ) : (
                                            <Typography gutterBottom variant="body1">
                                                -
                                            </Typography>
                                        )}
                                    </ul>
                                    <Typography gutterBottom variant="body1">
                                        <b>Affiliate Organizations</b>
                                    </Typography>
                                    <ul className={classes.capabalities}>
                                        {supplierDetails.affiliateOrganizations &&
                                            supplierDetails.affiliateOrganizations.map(element => (
                                                <li className={classes.listItem}>
                                                    {element
                                                        ? `${element.charAt(0)}${element
                                                              .slice(1)
                                                              .toLowerCase()
                                                              .replace(/_/g, ' ')}`
                                                        : ''}
                                                </li>
                                            ))}
                                    </ul>
                                    <Typography gutterBottom variant="body1">
                                        <b>Product Preferences</b>
                                    </Typography>
                                    <ul className={classes.capabalities}>
                                        {supplierDetails.productPreference &&
                                            supplierDetails.productPreference.map(element => (
                                                <li className={classes.listItem}>
                                                    {element
                                                        ? `${element.charAt(0)}${element
                                                              .slice(1)
                                                              .toLowerCase()
                                                              .replace(/_/g, ' ')}`
                                                        : ''}
                                                </li>
                                            ))}
                                    </ul>
                                </Card>
                                {supplierDetails.capacityDocument && (
                                    <Card className={classes.detailcard} style={{ margin: '24px 0 0' }}>
                                        <Typography gutterBottom variant="h3" style={{ marginBottom: '20px' }}>
                                            {'Industry Certifications'}
                                        </Typography>
                                        {supplierDetails && supplierDetails.capacityDocument && supplierDetails.capacityDocument.length > 0 ? (
                                            supplierDetails.capacityDocument.map((capDoc, index) => (
                                                <Grid container justify="space-between" alignItems="center" className={classes.docWrapper} key={index}>
                                                    <Grid item md={8}>
                                                        <Typography style={{ color: '#fff' }} gutterBottom variant="h4">
                                                            {capDoc.name && capDoc.name.includes('.pdf') ? capDoc.name.split('.pdf')[0] : capDoc.name ? capDoc.name : null}
                                                        </Typography>

                                                        <Typography className={classes.grayText} variant="body1">
                                                            {capDoc && capDoc.key}
                                                        </Typography>
                                                    </Grid>
                                                    <Grid item md={4} justify="flex-end" alignItems="center" style={{ display: 'flex' }}>
                                                        <Typography
                                                            className={classes.viewPdf}
                                                            color="primary"
                                                            variant="body1"
                                                            onClick={() => {
                                                                this.viewPDF(capDoc._id)
                                                            }}
                                                        >
                                                            View PDF
                                                        </Typography>
                                                        <OpenInNewIcon color="primary" style={{ cursor: 'pointer' }} />
                                                    </Grid>
                                                </Grid>
                                            ))
                                        ) : (
                                            <Typography color="primary" variant="body1">
                                                No Document Found
                                            </Typography>
                                        )}
                                    </Card>
                                )}
                                {supplierDetails.qualityQuestionnaire && (
                                    <Card className={classes.detailcard} style={{ margin: '24px 0 0' }}>
                                        <Typography gutterBottom variant="h3" style={{ marginBottom: '20px' }}>
                                            {`${t('QA Documentation')}`}
                                        </Typography>
                                        {supplierDetails && supplierDetails.qualityQuestionnaire && supplierDetails.qualityQuestionnaire.length > 0 ? (
                                            supplierDetails.qualityQuestionnaire.map((capDoc, index) => (
                                                <Grid container justify="space-between" alignItems="center" className={classes.docWrapper} key={index}>
                                                    <Grid item md={8}>
                                                        <Typography style={{ color: '#fff' }} gutterBottom variant="h4">
                                                            {capDoc.name && capDoc.name.includes('.pdf') ? capDoc.name.split('.pdf')[0] : capDoc.name ? capDoc.name : null}
                                                        </Typography>

                                                        <Typography className={classes.grayText} variant="body1">
                                                            {capDoc && capDoc.key}
                                                        </Typography>
                                                    </Grid>
                                                    <Grid item md={4} justify="flex-end" alignItems="center" style={{ display: 'flex' }}>
                                                        <Typography
                                                            className={classes.viewPdf}
                                                            color="primary"
                                                            variant="body1"
                                                            onClick={() => {
                                                                this.viewPDF(capDoc._id)
                                                            }}
                                                        >
                                                            View PDF
                                                        </Typography>
                                                        <OpenInNewIcon color="primary" style={{ cursor: 'pointer' }} />
                                                    </Grid>
                                                </Grid>
                                            ))
                                        ) : (
                                            <Typography color="primary" variant="body1">
                                                No Document Found
                                            </Typography>
                                        )}
                                    </Card>
                                )}
                            </Grid>
                            <Grid item md={5} className={classes.gridItem}>
                                <Card className={classes.detailcard} style={{ margin: '0 0 20px 24px' }}>
                                    <Typography gutterBottom variant="h3" style={{ marginBottom: '20px' }}>
                                        {'Supplier Location'}
                                    </Typography>
                                    {supplierDetails.location && (
                                        <iframe
                                            className={classes.map}
                                            src={`https://maps.google.com/maps?q=${supplierDetails.location.line},${supplierDetails.location.city},${supplierDetails.location.state},${
                                                supplierDetails.location.zipCode
                                            },${supplierDetails.location.country}&z=15&output=embed&key=AIzaSyAoVzb0i5s76I6mwleSxavtFUNro4xYLBM&zoom=18`}
                                            width="600"
                                            height="450"
                                            frameBorder="0"
                                        />
                                    )}
                                    <Typography gutterBottom className={classes.subTitle}>
                                        {supplierDetails && supplierDetails.companyName}
                                    </Typography>
                                    <Typography gutterBottom variant="body1">
                                        {supplierDetails.location.line}
                                    </Typography>
                                    <Typography gutterBottom variant="body1">
                                        {supplierDetails.location.city}, {supplierDetails.location.state}
                                    </Typography>
                                    <Typography gutterBottom variant="body1">
                                        {supplierDetails.location.country}, {supplierDetails.location.zipCode}
                                    </Typography>
                                </Card>
                            </Grid>
                        </Grid>
                    )}
                </Paper>
                <Dialog className={classes.dialog} aria-labelledby="simple-dialog-title" open={WarningDialog} onClose={this.closeWarningDialog} maxWidth="xl">
                    <PDFViewer />
                    {blobPDF && (
                        <div style={{ position: 'fixed', width: '100%', display: 'flex', alignItems: 'center', alignContent: 'center', top: '3%' }}>
                            <Button color="primary" style={{ position: 'fixed', marginRight: 'auto' }} variant="contained" onClick={this.closeWarningDialog}>
                                {`Close`}
                            </Button>
                        </div>
                    )}
                </Dialog>
            </CenteredAlignedContainer>
        );
    }
}

const SupplierViewWithTranslations = withStyles(styles)(withNamespaces()(SupplierView))

const mapStateToProps = (state: State) => {
    return {
        isLoading: state.supplier.isLoading,
        supplierDetails: state.supplier.supplierDetail,
        blobPDF: state.document.blobPDF,
    }
}

const mapDispatchToProps = (dispatch: Dispatch) => {
    return {
        getSupplier: (supplierId: string) => dispatch(GET_SUPPLIER.actions.TRIGGER(supplierId)),
        download: (id: string) => dispatch(DOCUMENT_DOWNLOAD_URL.actions.TRIGGER({ id })),
        reset: () => dispatch(RESET_DOWNLOAD_URL.actions.TRIGGER()),
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(SupplierViewWithTranslations)
