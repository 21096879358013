import { RFQ, RFQStatusFilter, RFQPart, LOGOUT_ACTION_TYPE, RFQStatus, QuoteDetail, RFQMMSStatusFilter } from '@syncfab/machine'
import { SortDirection, ListMeta } from '../../../layout/list/Meta'
import {
    REMOVE_DOCUMENT_RFQ,
    GET_RFQS,
    RFQ_DELETE,
    RFQ_LIST_UPDATE_FILTER,
    PART_ADD,
    PART_REMOVE,
    PART_UPDATE_QUANTITY,
    PART_UPDATE_PRICE,
    GET_RFQ,
    RFQ_CREATE,
    DECLINE_RFQ,
    GET_SUPPLIER_DATA_RFQ,
    SUPPLIER_NO_QUOTE,
    GET_QUOTE_FROM_RFQ,
    RESET_DATA,
    UPDATE_RFQ_FORM,
    GET_SUPPLIER_RFQS,
    GET_SUPPLIER_RFQ,
} from './Actions'

export type RFQReducerType = {
    rfqs: RFQ[] | null
    rfqSelectedFilter: RFQStatusFilter
    parts: RFQPart[]
    rfq?: RFQ
    noQuote: any
    quote: QuoteDetail
    draftParts?: any[]
    partDocuments?: any[]
    isLoading?: boolean
    meta?: ListMeta
}

const initialState: RFQReducerType = {
    rfqs: null,
    rfqSelectedFilter: RFQStatusFilter.ALL,
    parts: [],
    noQuote: null,
    quote: null,
    meta: {
        page: 0,
        size: 10,
        filter: RFQMMSStatusFilter.ALL,
        sortField: '_id',
        sortDirection: SortDirection.DESC,
        total: 0,
    },
    draftParts: [],
    partDocuments: [],
    isLoading: false,
}

export const RFQReducer = (state = initialState, action: any) => {

    switch (action.type) {
        case PART_ADD.types.TRIGGER:
            return {
                ...state,
                parts: [...state.parts, { document: action.data.part, quantity: 0 }],
            }
        case PART_REMOVE.types.TRIGGER:
            const parts = state.parts?.filter(part => part.document._id !== action.data.partId)
            return {
                ...state,
                parts,
            }
        case PART_UPDATE_QUANTITY.types.TRIGGER:
            return {
                ...state,
                parts: state.parts.map(part => {
                    if (part.document._id === action.data.partId) {
                        return {
                            ...part,
                            quantity: action.data.value,
                        }
                    }
                    return part
                }),
            }
        case PART_UPDATE_PRICE.types.TRIGGER:
            return {
                ...state,
                parts: state.parts.map(part => {
                    if (part.document._id === action.data.partId) {
                        return {
                            ...part,
                            price: action.data.value,
                        }
                    }
                    return part
                }),
            }
        case RFQ_LIST_UPDATE_FILTER.types.TRIGGER:
            return {
                ...state,
                rfqSelectedFilter: action.data.filter,
            }
        case GET_RFQ.types.TRIGGER:
            return {
                ...state,
                rfq: null,
                draftParts: [],
                rfqs: null,
                isLoading: true,
            }
        case GET_RFQ.types.SUCCESSFULL:
            const partDocs = []
            action.data?.draftParts?.forEach(part => {
                if (part?.documents) {
                    partDocs.push(...part.documents)
                }
            })
            return {
                ...state,
                rfq: action.data,
                draftParts: action.data.draftParts || [],
                partDocuments: partDocs || [],
                rfqs: null,
                isLoading: false,
            }
        case GET_RFQ.types.FAILED:
            return {
                ...state,
                isLoading: false,
            }
        case GET_RFQS.types.TRIGGER:
            return {
                ...state,
                rfq: null,
                draftParts: [],
                rfqs: null,
                isLoading: true,
                meta: action.data.meta,
            }
        case GET_SUPPLIER_RFQ.types.SUCCESSFULL:
            return {
                ...state,
                rfq: action.data,
                rfqs: null,
            }
        case GET_RFQS.types.SUCCESSFULL:
            return {
                ...state,
                rfqs: action.data.data,
                meta: { ...state.meta, total: action.data.total },
                rfq: null,
                isLoading: false,
            }
        case RFQ_DELETE.types.SUCCESSFULL:
            const rfqs = state.rfqs.filter(rfq => rfq._id !== action.data.id)
            return {
                ...state,
                rfqs,
            }
        case REMOVE_DOCUMENT_RFQ.types.SUCCESSFULL:
            state.rfq.supplierQuotes = state.rfq.supplierQuotes?.filter(quote => quote.document._id !== action.data.id)
            return {
                ...state,
            }
        case RFQ_CREATE.types.SUCCESSFULL:
            return {
                ...state,
                parts: [],
                rfq: null,
            }
        case DECLINE_RFQ.types.TRIGGER:
            return {
                ...state,
                rfqs: null,
            }
        case DECLINE_RFQ.types.SUCCESSFULL:
            return {
                ...state,
                rfq: {
                    ...state.rfq,
                    status: RFQStatus.MISSED,
                },
            }
        case GET_SUPPLIER_DATA_RFQ.types.SUCCESSFULL:
            return {
                ...state,
                rfq: action.data.rfq,
            }

        case SUPPLIER_NO_QUOTE.types.SUCCESSFULL:
            return {
                ...state,
                noQuote: action.data,
            }
        case LOGOUT_ACTION_TYPE:
            return {
                ...state,
                ...initialState,
                rfq: null,
            }
        case GET_QUOTE_FROM_RFQ.types.SUCCESSFULL:
            return {
                ...state,
                quote: action.data,
            }
        case RESET_DATA.types.TRIGGER:
            return {
                ...state,
                rfq: null,
                draftParts: [],
                partDocuments: [],
                parts: [],
            }
        case UPDATE_RFQ_FORM.types.TRIGGER:
            return {
                ...state,
                rfq: {
                    ...state.rfq,
                    ...action.data.field,
                },
            }
        case GET_SUPPLIER_RFQS.types.TRIGGER:
            return {
                ...state,
                rfqs: null,
            }
        case GET_SUPPLIER_RFQS.types.SUCCESSFULL:
            return {
                ...state,
                rfqs: action.data,
                rfq: null,
            }
        default:
            return state
    }
}
