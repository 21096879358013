import { ValueLabelOption } from "../ui";

export enum DocumentType {
    PERMIT = 'PERMIT',
    NDA = 'NDA',
    W9 = 'W9',
    PO = 'PO',
    PART = 'PART',
    RFQ = 'RFQ',
    MODEL_2D = 'MODEL_2D',
    MODEL_2D_REVISED = 'MODEL_2D_REVISED',
    MODEL_3D = 'MODEL_3D',
    DOCUMENT = 'DOCUMENT',
    SUPPLIER_CAPACITY = 'SUPPLIER_CAPACITY',
    QUALITY_QUESTIONNAIRE = 'QUALITY_QUESTIONNAIRE',
    SUPPLIER_QUOTE = 'SUPPLIER_QUOTE',
    SUPPORT_FILE = 'SUPPORT_FILE',
    BUYER = 'BUYER'
}

export enum DocumentVisibility {
    ALL = 'All',
    SYNCFAB = 'SyncFab',
    BUYER = 'Buyer',
    SUPPLIER = 'Supplier',
}

export const SupportingDocumentTypeOptions: ValueLabelOption[] = [
    { value: DocumentType.MODEL_2D, label: '2D Model' },
    { value: DocumentType.MODEL_3D, label: '3D Model' },
    { value: DocumentType.SUPPORT_FILE, label: 'Support File'}
]

export const getFileNameWithoutExtension = name => name.replace(/\.[^/.]+$/, '')

export const PO_ACCEPTED_FILE_TYPES = ['application/pdf', 'application/msword', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet']


export interface Document {
    _id: string
    name: string
    key: string
    type: string
    size: number
    referenceId: string
    userId?: string
    operatorId?: string
    createdAt: Date
    updatedAt: Date
    deletedAt: Date
    partId: string
    visibility: string
    companyId: string
    revision?: string
}

export const bytesForHuman = ((bytes: number, decimals: number = 2) => {
    const units = ['B', 'KB', 'MB', 'GB', 'TB', 'PB']

    let i = 0
    for (i; bytes > 1024; i++) {
        bytes /= 1024
    }

    return `${parseFloat(bytes.toFixed(decimals))} ${units[i]}`
})

export enum DocumentMenuOption {
    VIEW = 'View',
    DOWNLOAD = 'Download',
    REMOVE = 'Remove'
}

export const enum MIMEFileType {
    PDF = 'application/pdf',
    EXTENSION = 'allowed/extension',
    XLSX = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    XLS = 'application/vnd.ms-excel',
    DOCX = 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
    DOC = 'application/msword',
    PNG = 'image/png',
    JPG = 'image/jpeg',
    MOV = 'video/quicktime',
    MP4 = 'video/mp4',
    WVM = 'video/x-ms-asf',
    MP3 = 'audio/mpeg',
}

export const enum FileExtensions {
    JT = '.jt',
    PDF = '.pdf',
    XLSX = '.xlsx',
    XLS = '.xls',
    DOCX = '.docx',
    DOC = '.doc',
    PNG = '.png',
    JPG = '.jpg',
    JPEG = '.jpeg',
    MOV = '.mov',
    MP4 = '.mp4',
    WVM = '.wvm',
    MP3 = '.mp3',
    STEP = '.step',
    STP = '.stp',
    SLDPRT = '.sldprt',
    STL = '.stl',
    SAT = '.sat',
    IPT = '.ipt',
    X_T = '.x_t',
    X_B = '.x_b',
    C4 = '.c4',
    CAT_PART = '.catpart',
    CAT_PRODUCT = '.cat_product',
    DXF = '.dxf',
    DWG = '.dwg',
    ZIP = '.zip',
    PRT = '.prt',
    THREE_D_XML = '.3dxml',
    NONE = '',
}

export const SUPPLIER_FILE_TYPE_CAPTION: string = `Accepted File Types - pdf | xlsx | xls | docx | doc | png | jpg | jpeg | mov | mp4 | wvm | mp3 | step | stp | sldprt | stl | sat ipt | x_t | x_b | c4 | catpart | cat_product | dxf | dwg | zip | prt`
export const PARTS_ACCEPTED_FILE_EXTENSIONS = [
    FileExtensions.STEP,
    FileExtensions.STP,
    FileExtensions.SLDPRT,
    FileExtensions.STL,
    FileExtensions.X_T,
    FileExtensions.X_B,
    FileExtensions.IPT,
    FileExtensions.THREE_D_XML,
    FileExtensions.C4,
    FileExtensions.CAT_PART,
    FileExtensions.CAT_PRODUCT,
    FileExtensions.DXF,
    FileExtensions.DWG,
    FileExtensions.ZIP,
    FileExtensions.PRT,
    FileExtensions.SAT,
    FileExtensions.NONE,
    FileExtensions.JT,
]

export const PARTS_ACCEPTED_FILE_TYPES = ['application/pdf', 'image/x-c4', 'allowed/extension', 'model/*'].concat(PARTS_ACCEPTED_FILE_EXTENSIONS)

export const PARTS_FILE_TYPE_CAPTION: string = 'PDF | STEP | STP | SLDPRT | STL |  SAT | IPT | 3DXML | C4 | CATPART | PRT | DXF | DWG | X_T | X_B | JT Files Only - 150MB max'