import React from 'react'
import { withNamespaces, WithNamespaces } from 'react-i18next'
import { Dialog, DialogTitle, DialogContent, DialogActions, Typography, Grid, WithStyles } from '@material-ui/core'
import { Button, MFGIcon } from '@syncfab/machine'
import WarningIcon from '@material-ui/icons/Warning'

import { SendMFGTokensForm } from './SendMFGTokensForm'

interface SendTokensProps extends WithNamespaces, WithStyles {
    open: boolean
    close: () => void
    onSubmit: () => void
    balance?: any
    onSendToken: (data: any) => void
}

const dialog = ({ onSubmit, onSendToken, open, close, classes, balance, t }: SendTokensProps) => {
    return (
        <Dialog open={open} aria-labelledby="address-dialog-title" onEscapeKeyDown={close} fullWidth className={classes.dialogClass}>
            <DialogTitle id="simple-dialog-title" className={classes.title}>
                <Typography style={{ fontSize: '24px', fontWeight: 600 }}> Send MFG Tokens</Typography>
            </DialogTitle>
            <DialogContent>
                <Typography>
                    A MFG transaction fee is charged for any send request. This transaction fee is used to pay for blockchain miners GAS fee.
                    <a target="_blank" href="https://kb.myetherwallet.com/en/transactions/what-is-gas/" className={classes.links}>
                        {'Learn More'}
                    </a>
                    {/* A transaction fee for blockchain miners will be added for any send request to another MFG wallet. These transaction fees do not go to SyncFab. If recipent's wallet is not MFG
                    Compatible it will result in permanent loss of MFGs. */}
                </Typography>
                <Typography variant="h3" className={classes.marginTop}>
                    Available MFG to send
                </Typography>
                <Grid item container alignItems="center" className={classes.balanceContainer}>
                    <Grid item xs={2}>
                        <MFGIcon className={classes.mfgIcon} fill="#FFBA00" />
                    </Grid>
                    <Grid item xs={10}>
                        <Typography gutterBottom variant="h3">
                            MFG Balance (Erc721)
                        </Typography>
                        <Typography gutterBottom className={classes.balance}>
                            {balance ? (balance.MFG_Balance !== null || undefined ? `${balance.MFG_Balance}` : '0') : '0'}
                        </Typography>
                    </Grid>
                </Grid>
                <Typography gutterBottom variant="h3" className={classes.marginTop}>
                    Send Details
                </Typography>
                <Grid container direction="row" alignItems="center" className={classes.errormsg}>
                    <Grid item>
                        <WarningIcon className={classes.warningIcon} style={{ fontSize: 18 }} />
                    </Grid>
                    <Grid item>
                        <Typography variant="body1">Recipient's wallet must support MFG ERC20 or may result in loss of MFGs.</Typography>{' '}
                    </Grid>
                </Grid>
                {/* <p className={classes.paragraph}>If Recipient's wallet is not MFG ERC20 compatible it will result in permanent loss of MFGs.</p> */}

                <SendMFGTokensForm onSubmit={onSubmit} />
            </DialogContent>
            <DialogContent>
                <DialogActions>
                    <Button onClick={close} color="primary" variant="text">
                        {t('address-dialog-cancel')}
                    </Button>
                    <Button onClick={onSendToken} color="primary">
                        {/* {!!address ? t('form-action-update') : t('form-action-save')} */}
                        CONTINUE
                    </Button>
                </DialogActions>
            </DialogContent>
        </Dialog>
    )
}

export const SendTokensDialog = withNamespaces()(dialog)
