import { Button, Grid, Typography } from "@material-ui/core";
import React, { FC } from "react";
import { object, SchemaOf, string } from "yup";
import { FormProvider, SubmitHandler, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { RHFTextField, SHOW_FEEDBACK, useAddSupplierNoQuoteMutation } from "@syncfab/machine";
import { useAppDispatch } from "../../../../application/hooks";

interface NoQuoteProps {
    rfqId: string
    cancel: () => void
    complete: () => void
}
interface NoQuoteFormProps {
    noQuoteReason: string
}

const noQuoteFormSchema: SchemaOf<NoQuoteFormProps> = object({
    noQuoteReason: string()
})

export const NoQuote: FC<NoQuoteProps> = ({ cancel, complete, rfqId }) => {

    const dispatch = useAppDispatch()

    const [noQuote] = useAddSupplierNoQuoteMutation()

    const methods = useForm<NoQuoteFormProps>({
        resolver: yupResolver(noQuoteFormSchema, { stripUnknown: true }),
        defaultValues:  {
            noQuoteReason: ''
        },
    });

    const onSubmit: SubmitHandler<NoQuoteFormProps> = async (noQuoteData: NoQuoteFormProps) => {
        try {
            await noQuote({ rfqId, noQuoteReason: noQuoteData.noQuoteReason }).unwrap()
            complete()
            dispatch(SHOW_FEEDBACK('Your no quote was recorded.'))
        } catch(error) {
            dispatch(SHOW_FEEDBACK(error.data))
        }
    }

    return (
        <FormProvider {...methods} >
            <Typography style={{ fontSize: 22, fontWeight: 600, paddingTop: '8px', paddingBottom: '16px' }}>
                No Quote
            </Typography>
            <Typography style={{ fontSize: 14, paddingBottom: '24px' }}>
                Kindly share your feedback regarding the reasons for choosing not to quote this RFQ, as it will assist us in determining which RFQs to send you in the future.
            </Typography>
            <RHFTextField
                name='noQuoteReason'
                label='Enter feedback...'
                multiline
                minRows={4}
            />
            <Grid container justifyContent='flex-end' style={{ paddingTop: '16px', paddingBottom:'16px' }}>
                <Button variant="text" style={{ color: "#40B4BF" }} onClick={() => cancel()}>
                    CANCEL
                </Button>
                <Button
                    type='submit'
                    variant="contained"
                    style={{ backgroundColor: '#40B4BF', color: '#FFFFFF', marginLeft: '16px' }}
                    onClick={methods.handleSubmit(onSubmit)}
                >
                    NO QUOTE
                </Button>
            </Grid>
        </FormProvider>
    )
}