import { Document } from '../document'

export interface DraftPart {
    partNumber: string
    revision: string
    isNewPart: boolean
    isNewRev: boolean
    partDocuments: {
        document: Document,
        isNewDocument: boolean
    }[]
    quantity?: number[]
}

export interface ParsedPartDocument {
    partNumber: string
    revision: string
    document: Document
    isNewPart?: boolean
    isNewRev?: boolean
    isNewDocument?: boolean
}

// export const containsPart = (parts: { number: string, revision: string }[], { partNumber, revision }: { partNumber: string, revision: string }): number => {
//     return parts.findIndex(part => part.number === partNumber && part.revision === revision)
// }

// export const replaceAt = <T> (array: T[], index: number, value: T) => {
//     const ret = array.slice(0);
//     ret[index] = value;
//     return ret;
// }

export const parsePartDocumentName = (document: Document): ParsedPartDocument => {
    const revRegEx = /(?<partNumber>[A-Za-z0-9-_]+[A-Za-z0-9])[\s\S]*(REV|REVISION){1}[\s_-]*(?<revision>[A-Za-z0-9]+)/i
    const lastDashRegEx = /(?<partNumber>[A-Za-z0-9_-]+)[-](?<revision>[A-Za-z0-9]+)/
    const firstUnderscoreRegEx = /(?<partNumber>[A-Za-z0-9-]+)_/

    const docNameWithExtension: string = document.name
    // get filename without extension
    const docName = docNameWithExtension?.slice(0, docNameWithExtension?.indexOf('.'))

    // try to match against regex that expects filenames with 'rev' or 'revision' in them
    let partNumber: string = ''
    let revision: string = ''

    if (!!docName?.match(revRegEx)) {
        const revMatch = docName?.match(revRegEx)
        // console.log(`REV MATCH Part number: ${revMatch.groups.partNumber} and revision: ${revMatch.groups.revision}`)
        partNumber = revMatch.groups.partNumber
        revision = revMatch.groups.revision
    } else if (!!docName?.match(lastDashRegEx)) {
        const lastDashMatch = docName?.match(lastDashRegEx)
        // console.log(`LAST DASH MATCH Part number: ${lastDashMatch.groups.partNumber} and revision ${lastDashMatch.groups.revision}`)
        partNumber = lastDashMatch.groups.partNumber
        revision = lastDashMatch.groups.revision
    } else if (!!docName?.match(firstUnderscoreRegEx)) {
        const firstUnderscoreMatch = docName?.match(firstUnderscoreRegEx)
        // console.log(`FIRST UNDERSCORE MATCH Part number: ${firstUnderscoreMatch.groups.partNumber}`)
        partNumber = firstUnderscoreMatch.groups.partNumber
    } else {
        //console.log(`FULL FILENAME ${docName}`)
        partNumber = docName
    }
    return { partNumber, revision, document }
}

// export const partLookup = (parts: PartDAPP[], partDocument: ParsedPartDocument): ParsedPartDocument => {
//
//     // check for part number and revision match
//     if (parts.some(part => part.number === partDocument.partNumber &&
//         part.revision === partDocument.revision)) {
//         partDocument = {
//             ...partDocument,
//             isNewPart: false,
//             isNewRev: false,
//             isNewDocument: true
//         }
//         // next check if part number already exists
//     } else if (parts.some(part => part.number === partDocument.partNumber)) {
//         partDocument = {
//             ...partDocument,
//             isNewPart: false,
//             isNewRev: true,
//             isNewDocument: true
//         }
//         // no matches, new part
//     } else {
//         partDocument = {
//             ...partDocument,
//             isNewPart: true,
//             isNewRev: true,
//             isNewDocument: true
//         }
//     }
//     return partDocument
// }