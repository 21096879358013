import axios, { AxiosPromise } from 'axios'
import { AuthorizationService, Environment, EnvironmentVariable, Document } from '@syncfab/machine'

export class OrderAPI {
    constructor(private environment: Environment, private authorizationService: AuthorizationService) {}

    public getAll = (): (() => AxiosPromise) => {
        const url = this.environment.get(EnvironmentVariable.API_LAYER_URL)
        const authorization = `Bearer ${this.authorizationService.getAuthToken()}`
        return () => {
            return axios.get(`${url}/orders`, {
                headers: {
                    authorization,
                },
            })
        }
    }

    public get = (id: string): (() => AxiosPromise) => {
        const url = this.environment.get(EnvironmentVariable.API_LAYER_URL)
        const authorization = `Bearer ${this.authorizationService.getAuthToken()}`
        return () => {
            return axios.get(`${url}/orders/${id}`, {
                headers: {
                    authorization,
                },
            })
        }
    }

    public getPartDetails = (id: string): (() => AxiosPromise) => {
        const url = this.environment.get(EnvironmentVariable.API_LAYER_URL)
        const authorization = `Bearer ${this.authorizationService.getAuthToken()}`
        return () => {
            return axios.get(`${url}/orders/trackOrder/${id}`, {
                headers: {
                    authorization,
                },
            })
        }
    }

    public accept = (id: string): (() => AxiosPromise) => {
        const url = this.environment.get(EnvironmentVariable.API_LAYER_URL)
        const authorization = `Bearer ${this.authorizationService.getAuthToken()}`
        return () => {
            return axios.post(
                `${url}/orders/${id}`,
                {},
                {
                    headers: {
                        authorization,
                    },
                }
            )
        }
    }

    public addDocument = (id: string, document: Document): (() => AxiosPromise) => {
        const url = this.environment.get(EnvironmentVariable.API_LAYER_URL)
        const authorization = `Bearer ${this.authorizationService.getAuthToken()}`
        return () => {
            return axios.put(
                `${url}/orders/addDocument/${id}`,
                { document },
                {
                    headers: {
                        authorization,
                    },
                }
            )
        }
    }

    public removeDocument = (id: string, documentId: string): (() => AxiosPromise) => {
        const url = this.environment.get(EnvironmentVariable.API_LAYER_URL)
        const authorization = `Bearer ${this.authorizationService.getAuthToken()}`
        return () => {
            return axios.put(
                `${url}/orders/removeDocument/${id}`,
                { documentId },
                {
                    headers: {
                        authorization,
                    },
                }
            )
        }
    }
}
