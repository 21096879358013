import React from 'react'
import './Marker.css'

const Marker = (props: any) => {
    const { color, name, location } = props
    return (
        <div
            onClick={() => {
                console.log('====================================')
                console.log(location, name)
                console.log('====================================')
            }}
        >
            <div className="pin bounce" style={{ backgroundColor: color, cursor: 'pointer' }} title={`${location.supplierId}`} />
            <div className="pulse" />
            <div> {location.supplieId}</div>
        </div>
    )
}

export default Marker
