import {
    SupplierVerificationObject,
} from "@syncfab/machine";
import {
    object,
} from "yup";
import { useForm } from 'react-hook-form';
import { yupResolver } from "@hookform/resolvers/yup";
import { createStyles, makeStyles } from '@material-ui/core/styles'
import { Theme } from '@material-ui/core/styles';
import _ from 'lodash-es';
import {
    emptyArrayDefaultValues,
    nullDefaultValues
} from './constants';

export const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        stepper: {
            border: '1px solid #368A8E',
            backgroundColor: '#1C2D3E',
        },
        teamAlert: {
            // border: '1px solid #368A8E',
            backgroundColor: "#364F68",
            margin: 8,
            color: '#BF4B40',
        },
        stepperLabel: {
            color: 'white !important',

        },
        action: {
            minWidth: 120,
        },
        "@global": {
            ".MuiStepLabel-label.MuiStepLabel-active": {
                color: 'white',
            },
            ".MuiStepLabel-label.MuiStepLabel-completed": {
                color: 'white',
            },
            ".MuiStepLabel-label": {
                color: 'white',
            },
            ".MuiStepIcon-root": {
                color: '#FFFFFF40',
            },
            ".MuiRadio-root": {
                color: 'white',
            },
        },
        container: {
            paddingLeft: theme.spacing(5),
            paddingTop: theme.spacing(13),
            paddingRight: theme.spacing(5),
            paddingBottom: theme.spacing(6),
            display: 'flex',
            'flex-direction': 'column',
            alignItems: 'center',
            backgroundColor: '#fff',
        },
        formBackground: {
            width: '100%',
            marginTop: theme.spacing(3),
            padding: '24px',
            paddingTop: theme.spacing(2),
            paddingBottom: theme.spacing(2),
            backgroundColor: '#364F68',
        },
        root: {
            width: '100%',
            marginTop: theme.spacing(3),
            backgroundColor: '#F3F3F3',
            marginBottom: theme.spacing(4),
        },
        listControlContainer: {
            margin: theme.spacing(1),
            paddingTop: '18px',
            paddingBottom: '10px',
            paddingLeft: '16px',
            paddingRight: '36px',

        },
        noData: {
            margin: theme.spacing(2),
            textAlign: 'center',
        },
        header: {
            fontWeight: 'bolder',
            marginTop: theme.spacing(1)
        },
        borderCellWithInput: {
            border: '1px solid #dbdbdb',
            backgroundColor: '#F3F3F3',
            padding: '0px'
        },
        borderCellWithCheck: {
            border: '1px solid #dbdbdb',
            backgroundColor: '#F3F3F3',
            padding: '0px 0px 0px 27px',
        },
        headerCellWithInput: {
            border: '1px solid #dbdbdb',
            backgroundColor: "#F3F3F3",
            paddingLeft: '14px',
            whiteSpace: 'nowrap'
        },
        noColSpacing: {
            paddingTop: theme.spacing(4),
            paddingBottom: theme.spacing(0),
            paddingLeft: theme.spacing(0),
            paddingRight: theme.spacing(0),
        },
        tableWrapper: {
            'overflow-x': 'auto',
        },
        listBody: {
            backgroundColor: '#f3f3f3',
        },
        link: {
            color: '#40B4BF',
            'text-decoration': 'none',
            cursor: 'pointer',
            fontWeight: 'bold' as any,
        },
        addCard: {
            display: 'flex',
            'justify-content': 'center',
            'align-items': 'center',
            'flex-direction': 'column',
            width: '100%',
            backgroundColor: '#364F68',
            color: 'white',
            padding: '20px',
            height: 212,
        },
        altTableRow: {
            '&:nth-of-type(odd)': {
                backgroundColor: '#FFFFFF1A'
            },
            '&:nth-of-type(even)': {
            }
        },
        tableBody: {
            border: '1px solid #FFFFFF40'
        },
        tableCell: {
            border: 'none',
        },
        tableCellHeader: {
            border: 'none',
            fontSize: 16,
        },
        tableCellHeaderCentered: {
            border: 'none',
            fontSize: 16,
            textAlign: 'center',
        },
        tableCellAdded: {
            border: 'none',
            paddingTop: 7,
            paddingBottom: 7,
        },
        text: {
            'font-weight': 'normal',
        },
        paper: {},
        contactCard: {
            backgroundColor: "#364F68",
            padding: '20px',
            marginBottom: '20px',
            display: 'block',
            color: 'white',
        },
    })
);

export const VerifySupplierSchema = object(SupplierVerificationObject)

export const createForm = existingDefaultValues => {
    const objNullFields = Object.fromEntries(nullDefaultValues.map(n => ([n, null])))
    const empArrayFields = Object.fromEntries(emptyArrayDefaultValues.map(e => ([e, []])))
    const methods = useForm({
        resolver:
        yupResolver(VerifySupplierSchema, { stripUnknown: true }),
        defaultValues: {
            ...objNullFields,
            ...empArrayFields,
            ...existingDefaultValues,
        }
    })
    return {
        methods,
    }
}

export const formatSupplierTagByValueLabel = sT => {
    return {
        label: sT.name,
        value: sT.value,
    }
}
