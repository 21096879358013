export enum TransactionType {
    SPENT = 'SPENT',
    PURCHASED = 'PURCHASED',
    RECEIVED = 'RECEIVED',
    EARNED = 'EARNED',
    SENT = 'SENT',
}

export interface Transaction {
    _id: string
    createdAt: Date
    companyId: string
    userId: string
    value: number
    type: TransactionType
    context?: any
    deletedAt?: Date
    txnHash?: string
    particular?: string
}
