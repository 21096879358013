import React, { FC, useEffect, useState } from 'react'
import {
    ActivityType,
    CurrencyFormatter,
    DateFormatter, getSupplierPOLineItemStatusBackgroundColor,
    IntegerFormatter,
    PurchaseOrderStatus,
    SupplierPurchaseOrderDetail
} from '@syncfab/machine'
import { createStyles, makeStyles } from '@material-ui/core/styles'
import TableCell from '@material-ui/core/TableCell'
import TableRow from '@material-ui/core/TableRow'
import {
    Box,
    Button,
    Collapse,
    IconButton,
    Table,
    TableBody,
    TableHead,
    Theme,
    Typography,
    useTheme
} from '@material-ui/core'
import { Link } from 'react-router-dom'
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import { SupplierPOStatusChip } from "./SupplierPOStatusChip";
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        compactRow: {
            paddingBottom: '10px',
            paddingTop: '10px',
            borderBottom: '1px solid #FFFFFF40',
        },
        captionStyle: {
            marginTop: theme.spacing(3),
        },
        link: {
            color: '#40B4BF',
            'text-decoration': 'none',
            cursor: 'pointer',
            fontWeight: 'bold' as any,
        },
        icon: {
            '& path': {
                fill: theme.palette.common.black,
            },
        },
        actionButton: {
            color: theme.palette.primary.main,
            borderColor: theme.palette.primary.main,
        }
    })
)

interface SupplierPurchaseOrderRowProps {
    supplierPO: SupplierPurchaseOrderDetail
    allOpen: boolean
}

export const SupplierPurchaseOrderRow: FC<SupplierPurchaseOrderRowProps> = ({ supplierPO, allOpen }) => {

    const classes = useStyles()
    const theme = useTheme()
    const [isRowOpen, setIsRowOpen] = useState<boolean>(false)

    useEffect(() => {
        setIsRowOpen(allOpen)
    }, [allOpen])

    return (
        <>
            <TableRow hover tabIndex={-1}>
                <TableCell
                    style={{
                        minWidth: '110px',
                    }}
                    className={classes.compactRow}
                    component="th"
                    scope="row"
                >
                    <IconButton
                        size="small"
                        onClick={() => setIsRowOpen(!isRowOpen)}
                        style={{ marginRight: '16px' }}
                    >
                        {isRowOpen ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                    </IconButton>
                </TableCell>
                <TableCell className={classes.compactRow}>
                    <Link to={`/supplierPurchaseOrders/${supplierPO._id}`} className={`${classes.link}`}>
                        {supplierPO.number}
                    </Link>
                </TableCell>
                <TableCell className={classes.compactRow}>
                    <SupplierPOStatusChip status={supplierPO.po.status as PurchaseOrderStatus} />
                </TableCell>
                <TableCell className={classes.compactRow}>
                    {!!supplierPO.nextDeliveryDate ? DateFormatter.format(new Date(supplierPO.nextDeliveryDate)) : '--'}
                </TableCell>
                <TableCell className={classes.compactRow}>
                    {!!supplierPO.po.initialPromiseDate ? DateFormatter.format(new Date(supplierPO.po.initialPromiseDate)) : '--'}
                </TableCell>
                <TableCell className={classes.compactRow}>{`${supplierPO.completedLines}/${supplierPO.numberLines} Lines Closed`}</TableCell>
                <TableCell className={classes.compactRow}>
                    {CurrencyFormatter.format(supplierPO.supplierPOTotal)}
                </TableCell>
                <TableCell className={classes.compactRow}>
                    {supplierPO.activity.some(activity => activity.activityType === ActivityType.CONFIRM_SUPPLIER_PURCHASE_ORDER) ?
                        <Button
                            className={classes.actionButton}
                            variant='outlined'
                            component={Link}
                            to={`/supplierPurchaseOrders/${supplierPO._id}`}
                        >
                            UPDATE PO
                        </Button>
                        :
                        <Button
                            className={classes.actionButton}
                            variant='outlined'
                            component={Link}
                            to={`/supplierPurchaseOrders/${supplierPO._id}/confirm`}
                        >
                            CONFIRM PO
                        </Button>
                    }
                </TableCell>
            </TableRow>
            { isRowOpen &&
                <TableRow>
                    <TableCell style={{ paddingTop: 0, borderBottom: '1px solid #FFFFFF40' }} colSpan={8}>
                        <Collapse in={isRowOpen} timeout="auto" unmountOnExit>
                            <Box margin={1}>
                                <Typography style={{ fontSize: 18, fontWeight: 'bold', marginTop: '16px' }}>
                                    PO Line Items
                                </Typography>
                                <Table style={{ backgroundColor: '#364F68', marginTop: '8px' }}>
                                    <TableHead>
                                        <TableRow style={{ color: '#fff' }}>
                                            <TableCell align="left" className={classes.compactRow}>Line Item ID</TableCell>
                                            <TableCell align="left" className={classes.compactRow}>Line Status</TableCell>
                                            <TableCell align="left" className={classes.compactRow}>Part Number</TableCell>
                                            <TableCell align="left" className={classes.compactRow}>Rev</TableCell>
                                            <TableCell align="left" className={classes.compactRow}>Description</TableCell>
                                            <TableCell align="left" className={classes.compactRow}>Ship Date</TableCell>
                                            <TableCell align="left" className={classes.compactRow}>Quantity</TableCell>
                                            <TableCell align="left" className={classes.compactRow}>Qty Delivered</TableCell>
                                            <TableCell align="left" className={classes.compactRow}>Qty Remaining</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        { supplierPO.poLineItem?.map(lineItem =>
                                            <TableRow key={lineItem._id}>
                                                <TableCell className={classes.compactRow}>{lineItem._id}</TableCell>
                                                <TableCell className={classes.compactRow}>
                                                    <Box display='flex' alignItems='middle' justifyContent='flex-start'>
                                                        <FiberManualRecordIcon
                                                            fontSize='small'
                                                            style={{
                                                                color: getSupplierPOLineItemStatusBackgroundColor(lineItem.status, theme),
                                                                marginRight: '4px'
                                                            }}
                                                        />
                                                        <Typography style={{ fontSize: 14, fontWeight: 'bold' }}>
                                                            {lineItem.status.toUpperCase()}
                                                        </Typography>
                                                    </Box>
                                                </TableCell>
                                                <TableCell className={classes.compactRow}>{lineItem.part?.number}</TableCell>
                                                <TableCell className={classes.compactRow}>{lineItem.part?.revision}</TableCell>
                                                <TableCell className={classes.compactRow}>{lineItem.part?.name}</TableCell>
                                                <TableCell className={classes.compactRow}>
                                                    {!!lineItem.shipDate ? DateFormatter.format(new Date(lineItem.shipDate)) : '--'}
                                                </TableCell>
                                                <TableCell className={classes.compactRow}>{!!lineItem.pricing.quantity ? IntegerFormatter.format(lineItem.pricing.quantity) : '--'}</TableCell>
                                                <TableCell className={classes.compactRow}>{!!lineItem.shippedQuantity ? IntegerFormatter.format(lineItem.shippedQuantity) : '--'}</TableCell>
                                                <TableCell className={classes.compactRow}>
                                                    {!!lineItem.shippedQuantity ?
                                                        IntegerFormatter.format(lineItem.pricing.quantity - lineItem.shippedQuantity) :
                                                        '--'
                                                    }
                                                </TableCell>
                                            </TableRow>
                                        )}
                                    </TableBody>
                                </Table>
                            </Box>
                        </Collapse>
                    </TableCell>
                </TableRow>
            }
        </>
    )
}
