import React from 'react'
import { WithNamespaces, withNamespaces } from 'react-i18next'
import { connect } from 'react-redux'
import Helmet from 'react-helmet'
import { CenteredAlignedContainer } from '../../../layout/Container'
import { withRouter, RouteComponentProps } from 'react-router-dom'
import PartTokenize from './components/PartTokenize'

interface PartTokenizeProps extends WithNamespaces, RouteComponentProps<{}> {}

interface PartTokenizeState {}

class PartTokenizeView extends React.Component<PartTokenizeProps, PartTokenizeState> {
    constructor(props) {
        super(props)
    }

    render() {
        return (
            <CenteredAlignedContainer>
                <Helmet>
                    <title>{'Tokenize Part'}</title>
                </Helmet>
                <PartTokenize />
            </CenteredAlignedContainer>
        )
    }
}

const PartTokenizeWithTranslations = withRouter(withNamespaces()(PartTokenizeView))

export const PartTokenizeDisplay = connect()(PartTokenizeWithTranslations)
