import React from 'react';
import {
  Typography,
  Grid,
  Paper,
  Divider,
  Chip,
  TableCell,
  TableRow,
  TableContainer,
  Table,
  TableBody,
  Theme,
} from '@material-ui/core';
import { PaymentTermsOptions, PurchaseOrder, RFQ } from '@syncfab/machine';
import { useStandardStyles } from '../../../styles/standardStyles';
import ReactQuill from 'react-quill-new';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import { useAppSelector } from '../../../../application/hooks';
const useStyles = makeStyles((_theme: Theme) =>
  createStyles({
    innerTableDataRow: {
      height: 'auto !important',
      border: '1px solid #FFFFFF40',
      '&:nth-of-type(odd)': {
        backgroundColor: '#FFFFFF15',
      },
      // hide last border
      '&:last-child td, &:last-child th': {
        border: 0,
      },
      '& td': {
        padding: '8px',
        borderBottom: 'none',
        fontSize: 14,
      },
    },
    tableLeftCell: {
      padding: '0px',
      width: '240px',
      border: 'none',
    },
    tableRightCell: {
      padding: '0px 0px 0px 8px',
      border: 'none',
    },
    value: {
      fontSize: '14px',
    },
  })
);

// TODO Fix entity type definitions across beam and engine

interface RFQPORequirementsProps {
  rfqOrPO: RFQ | PurchaseOrder;
  entityType?: 'RFQ' | 'PO';
}

export const RFQPORequirements = ({
  rfqOrPO,
  entityType = 'RFQ',
}: RFQPORequirementsProps) => {
  const standardClasses = useStandardStyles();
  const classes = useStyles();
  const user = useAppSelector(state => state.user.loggedUser);
  console.log(user);

  return (
    <Paper className={standardClasses.paper}>
      <Grid item xs={12} style={{ padding: '16px' }}>
        <Typography gutterBottom style={{ fontSize: 20, fontWeight: 'bold' }}>
          {entityType} Requirements
        </Typography>
      </Grid>
      <Divider style={{ width: '100%', color: '#FFFFFF40' }} />
      <TableContainer style={{ padding: '16px' }}>
        <Table style={{ backgroundColor: '#364F68' }}>
          <TableBody>
            <TableRow className={classes.innerTableDataRow}>
              <TableCell className={classes.tableLeftCell}>
                <Typography variant="body2" className={standardClasses.label}>
                  Payment Terms
                </Typography>
              </TableCell>
              <TableCell className={classes.tableRightCell}>
                <Typography className={classes.value}>
                  {// Use supplierPaymentTerms if it is defined for an RFQ and role is supplier
                  user.loginRole === 'SUPPLIER' &&
                  entityType === 'RFQ' &&
                  'supplierPaymentTerms' in rfqOrPO
                    ? PaymentTermsOptions.find(
                        opt => opt.value === rfqOrPO.supplierPaymentTerms
                      )?.label ?? '--'
                    : PaymentTermsOptions.find(
                        option => option.value === rfqOrPO.paymentTerms
                      )?.label ?? '--'}
                </Typography>
              </TableCell>
            </TableRow>
            <TableRow className={classes.innerTableDataRow}>
              <TableCell className={classes.tableLeftCell}>
                <Typography variant="body2" className={standardClasses.label}>
                  Domestic Production Required
                </Typography>
              </TableCell>
              <TableCell className={classes.tableRightCell}>
                <Typography className={classes.value}>
                  {rfqOrPO.domesticProductionRequired ? 'YES' : 'NO'}
                </Typography>
              </TableCell>
            </TableRow>
            <TableRow className={classes.innerTableDataRow}>
              <TableCell className={classes.tableLeftCell}>
                <Typography variant="body2" className={standardClasses.label}>
                  Customer Supplied Material
                </Typography>
              </TableCell>
              <TableCell className={classes.tableRightCell}>
                <Typography className={classes.value}>
                  {rfqOrPO.customerSuppliedMaterial ? 'YES' : 'NO'}
                </Typography>
              </TableCell>
            </TableRow>
            <TableRow className={classes.innerTableDataRow}>
              <TableCell className={classes.tableLeftCell}>
                <Typography variant="body2" className={standardClasses.label}>
                  Certification Requirements
                </Typography>
              </TableCell>
              <TableCell className={classes.tableRightCell}>
                {rfqOrPO.certifications?.length > 0 ? (
                  <section>
                    {rfqOrPO.certifications.map(certification => (
                      <Chip
                        key={certification}
                        label={certification.toUpperCase()}
                        style={{ marginRight: '8px', marginTop: '8px' }}
                      />
                    ))}
                  </section>
                ) : (
                  <Typography gutterBottom className={standardClasses.value}>
                    None
                  </Typography>
                )}
              </TableCell>
            </TableRow>
            <TableRow className={classes.innerTableDataRow}>
              <TableCell className={classes.tableLeftCell}>
                <Typography variant="body2" className={standardClasses.label}>
                  Inspection Requirements
                </Typography>
              </TableCell>
              <TableCell className={classes.tableRightCell}>
                <Typography className={classes.value}>
                  {rfqOrPO.inspection ? rfqOrPO.inspection : '--'}
                </Typography>
              </TableCell>
            </TableRow>
            <TableRow className={classes.innerTableDataRow}>
              <TableCell className={classes.tableLeftCell}>
                <Typography variant="body2" className={standardClasses.label}>
                  Packaging Requirements
                </Typography>
              </TableCell>
              <TableCell className={classes.tableRightCell}>
                <Typography className={classes.value}>
                  {rfqOrPO.packaging ? rfqOrPO.packaging : '--'}
                </Typography>
              </TableCell>
            </TableRow>
            <TableRow className={classes.innerTableDataRow}>
              <TableCell className={classes.tableLeftCell}>
                <Typography variant="body2" className={standardClasses.label}>
                  Additional Requirements
                </Typography>
              </TableCell>
              <TableCell className={classes.tableRightCell}>
                <Typography className={classes.value}>
                  {rfqOrPO.additionalRequirements
                    ? rfqOrPO.additionalRequirements
                    : '--'}
                </Typography>
              </TableCell>
            </TableRow>
            <TableRow className={classes.innerTableDataRow}>
              <TableCell className={classes.tableLeftCell}>
                <Typography variant="body2" className={standardClasses.label}>
                  {entityType} Notes
                </Typography>
              </TableCell>
              <TableCell className={classes.tableRightCell}>
                <ReactQuill
                  defaultValue={rfqOrPO.notes ?? '--'}
                  readOnly={true}
                  modules={{ toolbar: false }}
                  style={{ color: '#FFFFFF' }}
                />
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </Paper>
  );
};
