import { TOGGLE_SIDE_BAR_ACTION_TYPE } from './Actions'

export type LayoutReducerType = {
    sideBarCollapsed: boolean
}

const initialState: LayoutReducerType = {
    sideBarCollapsed: false,
}

export const LayoutReducer = (state = initialState, action: any) => {
    switch (action.type) {
        case TOGGLE_SIDE_BAR_ACTION_TYPE:
            return {
                ...state,
                sideBarCollapsed: !state.sideBarCollapsed,
            }

        default:
            return state
    }
}
