import React from 'react';
import { CenteredAlignedContainerV2 } from '../../../../layout/Container';
import { useGetLoggedUserQuery, useGetSupplierQuery } from '@syncfab/machine';
import SupplierFormShell from '../SupplierFormShell';
import QuestionnaireForm from '../QuestionnaireForm';
const QAIndex = () => {
  // const loggedUser = useAppSelector(state => state.user.loggedUser);
  const { data: loggedUser } = useGetLoggedUserQuery();
  const { data: supplier } = useGetSupplierQuery(loggedUser?.supplierId ?? '', {
    skip: !loggedUser,
  });

  return (
    <CenteredAlignedContainerV2>
      {!!supplier && (
        <SupplierFormShell activeStep={3} supplier={supplier}>
          <QuestionnaireForm teams={loggedUser?.teams ?? []} />
        </SupplierFormShell>
      )}
    </CenteredAlignedContainerV2>
  );
};

export default QAIndex;
