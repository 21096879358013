import React from 'react'
import SvgIcon from '@material-ui/core/SvgIcon'
export const PRTIcon = props => {
    return (
        <SvgIcon {...props} viewBox="0 0 35.6 35.6" x="0px" y="0px" baseProfile="tiny" xmlSpace="preserve">
            <path
                fill={'none'}
                stroke={'#fdbe14'}
                strokeLinejoin={'round'}
                d="M54.3,24.1l2.2-1c-.4-1.4-.7-2.8-1.1-4.2l-2.5.2-1.3-2.3c.4-.6.9-1.2,1.3-1.9l-3-3.2-2,1.4-2.3-1.3c.1-.9.1-1.6.2-2.5L41.6,8.2l-1,2.2H37.9L36.8,8.2,32.6,9.3c.1,1,.2,1.7.3,2.5l-2.4,1.3-2-1.4c-1,1.1-2.1,2-3.1,3l1.5,2.1-1.4,2.3L23,18.9c-.3,1.5-.7,2.8-1,4.2l2.1,1v2.8l-2.2,1c.4,1.4.8,2.8,1.1,4.2l2.5-.2c.5.7.9,1.5,1.4,2.3l-1.4,1.9,2.9,3.2,2.2-1.4,2.3,1.3-.3,2.5,4.3,1.1,1-2.3h2.7c.3.7.6,1.5,1,2.3l4.2-1.2c-.1-.9-.1-1.7-.2-2.4l2.3-1.3L50,39.3l3.1-3-1.5-2.1c.5-.8.9-1.6,1.4-2.3l2.4.2c.4-1.5.8-2.9,1.1-4.2a19.5,19.5,0,0,0-2.2-1Z"
                transform="translate(-21.4 -7.7)"
            />
            <path
                fill={'#fdbe14'}
                d="M30.8,27.3H29.2V30H27.3V20.5h3.5q3.3,0,3.3,3.3a3.5,3.5,0,0,1-.8,2.6A3.3,3.3,0,0,1,30.8,27.3Zm-1.6-1.7h1.5q1.5,0,1.5-1.8c0-.6-.1-1-.4-1.2a1.2,1.2,0,0,0-1.1-.4H29.2Z"
                transform="translate(-21.4 -7.7)"
            />
            <path
                fill={'#fdbe14'}
                d="M38.1,26.8V30H36.2V20.5h3.7c2.2,0,3.4,1.1,3.4,3.2a3.1,3.1,0,0,1-1.4,2.7L43.2,30H41.1L40,26.8Zm2.9-2a2,2,0,0,0,.3-1.1,1.2,1.2,0,0,0-.4-1.1,1.2,1.2,0,0,0-1-.4H38.1v3h1.8A1.4,1.4,0,0,0,41,24.8Z"
                transform="translate(-21.4 -7.7)"
            />
            <path fill={'#fdbe14'} d="M44.7,22.2V20.5h7v1.7H49.2V30H47.3V22.2Z" transform="translate(-21.4 -7.7)" />
            <path fill={'#fdbe14'} d="M47.4,17.6a.5.5,0,0,1-.5.5H40.8a.5.5,0,0,1-.5-.5.5.5,0,0,1,.5-.5h6.1A.5.5,0,0,1,47.4,17.6Z" transform="translate(-21.4 -7.7)" />
            <circle fill={'#fdbe14'} cx="10.7" cy="8.2" r="0.8" />
            <path fill={'#fdbe14'} d="M31.3,33a.5.5,0,0,1,.4-.5h6.1a.5.5,0,0,1,.5.5.5.5,0,0,1-.5.5H31.7C31.5,33.5,31.3,33.2,31.3,33Z" transform="translate(-21.4 -7.7)" />
            <circle fill={'#fdbe14'} cx="25.1" cy="27" r="0.8" />
        </SvgIcon>
    )
}
