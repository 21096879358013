import React from 'react'
import { makeStyles } from '@material-ui/core'
import { Drawer as MaterialUIDrawer } from '@material-ui/core'

const useStyles = makeStyles({
    paper: {
        minWidth: 800,
    },
    '@media (max-width: 1024px)': {
        paper: {
            minWidth: 'initial',
            width: ' 100%',
        },
    },
})

interface DrawerProps {
    open: boolean,
    children: JSX.Element | JSX.Element[],
    width?: string
}

export const Drawer = (({ open, children, width = '900px' }: DrawerProps ) => {

    const classes = useStyles()

    return (
        <MaterialUIDrawer anchor={'right'} open={open} classes={{ paper: classes.paper }} PaperProps={{ style: { width } }}>
            {children}
        </MaterialUIDrawer>
    )
})
