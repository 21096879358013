import FileSaver from 'file-saver'
import { call, takeLatest, getContext, put } from 'redux-saga/effects'
import { Gateway } from '../gateway'
import {
    GET_COMPANY_DOCUMENTS,
    DOCUMENT_DOWNLOAD,
    DOCUMENT_DOWNLOAD_TYPE,
    DOCUMENT_DELETE_BY_TYPE,
    DOCUMENT_UPLOAD,
    DocumentTypePayload,
    DOCUMENT_DOWNLOAD_URL,
    RESET_DOWNLOAD_URL,
    DOCUMENT_UPDATE,
} from './Actions'
import { Document, SHOW_FEEDBACK } from '@syncfab/machine'

function* getCompanyDocuments() {
    try {
        const gateway: Gateway = yield getContext('gateway')
        const { data } = yield call(gateway.getDocumentAPI().getCompanyDocuments())
        yield put(GET_COMPANY_DOCUMENTS.actions.SUCCESSFULL(data))
    } catch ({ response: { status, data } }) {
        const handleError = yield getContext('handleError')
        yield call(handleError(status))
        yield put(GET_COMPANY_DOCUMENTS.actions.FAILED(data))
    }
}

function* download({ data: { id } }: { data: { id: string } }) {
    try {
        const gateway: Gateway = yield getContext('gateway')
        const { data, headers } = yield call(gateway.getDocumentAPI().download(id))
        const name = /^attachment; filename="(.*)"$/.exec(headers['content-disposition'])![1]
        FileSaver.saveAs(new Blob([data]), name)
        yield put(DOCUMENT_DOWNLOAD.actions.SUCCESSFULL(id))
    } catch ({ response: { status, data } }) {
        const handleError = yield getContext('handleError')
        yield call(handleError(status))
        yield put(DOCUMENT_DOWNLOAD.actions.FAILED(data))
    }
}

function* downloadURL({ data: { id } }: { data: { id: string } }) {
    try {
        const gateway: Gateway = yield getContext('gateway')
        const { data } = yield call(gateway.getDocumentAPI().download(id))
        let url = URL.createObjectURL(new Blob([data]))
        yield put(DOCUMENT_DOWNLOAD_URL.actions.SUCCESSFULL(url))
    } catch ({ response: { status, data } }) {
        const handleError = yield getContext('handleError')
        yield call(handleError(status))
        yield put(DOCUMENT_DOWNLOAD_URL.actions.FAILED(data))
    }
}

function* downloadByType({ data: { type } }: { data: DocumentTypePayload }) {
    try {
        const gateway: Gateway = yield getContext('gateway')
        const { data, headers } = yield call(gateway.getDocumentAPI().downloadByType(type))
        const name = /^attachment; filename="(.*)"$/.exec(headers['content-disposition'])![1]
        FileSaver.saveAs(new Blob([data]), name)
        yield put(DOCUMENT_DOWNLOAD.actions.SUCCESSFULL(type))
    } catch ({ response: { status, data } }) {
        const handleError = yield getContext('handleError')
        yield call(handleError(status))
        yield put(DOCUMENT_DOWNLOAD.actions.FAILED(data))
    }
}

function* deleteByType({ data: { type } }: { data: DocumentTypePayload }) {
    try {
        const gateway: Gateway = yield getContext('gateway')
        yield call(gateway.getDocumentAPI().deleteByType(type))
        yield put(DOCUMENT_DELETE_BY_TYPE.actions.SUCCESSFULL({ type }))
        yield put(SHOW_FEEDBACK('document-deleted'))
    } catch ({ response: { status, data } }) {
        const handleError = yield getContext('handleError')
        yield call(handleError(status))
        yield put(DOCUMENT_DELETE_BY_TYPE.actions.FAILED(data))
    }
}

function* documentUploaded({ data: { document } }: { data: { document: Document } }) {
    yield put(DOCUMENT_UPLOAD.actions.SUCCESSFULL({ document }))
    yield put(SHOW_FEEDBACK('document-uploaded'))
}

function* documentUpdate({ data: { id, type, visibility } }: { data: { id: string; type: string; visibility: string } }) {
    const gateway: Gateway = yield getContext('gateway')
    const { data } = yield call(gateway.getDocumentAPI().update(id, type, visibility))
    yield put(DOCUMENT_UPDATE.actions.SUCCESSFULL({ data }))
    yield put(SHOW_FEEDBACK('document-updated'))
}

function* reset() {}

function* documentSaga() {
    // Typings are not good enough. String should be valid but the types
    // do not show it
    yield takeLatest(GET_COMPANY_DOCUMENTS.types.TRIGGER as any, getCompanyDocuments)
    yield takeLatest(DOCUMENT_DOWNLOAD.types.TRIGGER as any, download)
    yield takeLatest(DOCUMENT_DOWNLOAD_TYPE.types.TRIGGER as any, downloadByType)
    yield takeLatest(DOCUMENT_DELETE_BY_TYPE.types.TRIGGER as any, deleteByType)
    yield takeLatest(DOCUMENT_UPLOAD.types.TRIGGER as any, documentUploaded)
    yield takeLatest(DOCUMENT_DOWNLOAD_URL.types.TRIGGER as any, downloadURL)
    yield takeLatest(RESET_DOWNLOAD_URL.types.TRIGGER as any, reset)
    yield takeLatest(DOCUMENT_UPDATE.types.TRIGGER as any, documentUpdate)
}

export default documentSaga
