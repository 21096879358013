import { LOGIN_SUCESSFUL_ACTION_TYPE, LOGOUT_ACTION_TYPE, LOGIN_FAILED_ACTION_TYPE, CLEAN_AUTH_TOKEN_ACTION_TYPE } from './Actions'

export type AuthReducerType = {
    authToken: string | null
    authError?: string | null
}

const initialState: AuthReducerType = { authToken: null }

export const AuthReducer = (state = initialState, action: { type: string; token: string | null; error?: string }) => {
    switch (action.type) {
        case CLEAN_AUTH_TOKEN_ACTION_TYPE:
            return {
                ...state,
                authToken: null,
            }
        case LOGIN_SUCESSFUL_ACTION_TYPE:
            return {
                ...state,
                authToken: action.token,
                authError: null,
            }
        case LOGOUT_ACTION_TYPE:
            return {
                ...state,
                authToken: null,
            }
        case LOGIN_FAILED_ACTION_TYPE:
            return {
                ...state,
                authError: action.error,
            }
        default:
            return state
    }
}
