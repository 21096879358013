import {
    SAVE_PART,
    GET_PARTS_BY_IDS,
    RESET_DATA,
    GET_PART_BY_ID,
    GET_PARTS_FOR_DAPP,
    SET_PARTS_FOR_TOKENIZATION,
    TOKENIZE_PARTS,
    TOKENIZE_FAILED_PARTS,
    DELETE_PARTS_BY_IDS,
    SET_PART_FOR_EDIT,
    UPDATE_PART,
    GET_PART_HISTORY,
    FIND_PARTS_BY_IDS,
    GET_ETHERNET_LINK,
    GET_PARTS,
} from './Actions'
import { Part, LOGOUT_ACTION_TYPE } from '@syncfab/machine'
import { GET_RFQS, GET_SUPPLIER_DATA_RFQ } from '../quote/rfq/Actions'
import { ListMeta, SortDirection } from '../../layout/list/Meta'

export type PartReducerType = {
    parts?: Part[]
    isLoading?: boolean
    successfullySaved?: boolean
    successTokenizePart?: boolean
    failedToSave?: boolean
    part?: Part
    selectedPartsForTokenization?: any[]
    selectedPartForEdit?: any
    partHistory?: any
    ethernetLink?: any
    meta: ListMeta
}

const initialState: PartReducerType = {
    parts: [],
    isLoading: false,
    successfullySaved: false,
    successTokenizePart: false,
    failedToSave: false,
    part: null,
    selectedPartsForTokenization: [],
    selectedPartForEdit: null,
    partHistory: null,
    ethernetLink: null,
    meta: {
        page: 0,
        size: 10,
        filter: 'ALL',
        sortField: 'createdAt',
        sortDirection: SortDirection.DESC,
        total: 0,
    },
}

export const PartReducer = (state = initialState, action: any) => {
    switch (action.type) {
        case SAVE_PART.types.TRIGGER:
            return {
                ...state,
                isLoading: true,
            }
        case SAVE_PART.types.SUCCESSFULL:
            return {
                ...state,
                isLoading: false,
                successfullySaved: true,
                parts: [...state.parts, action.data],
            }
        case FIND_PARTS_BY_IDS.types.SUCCESSFULL:
            return {
                ...state,
                isLoading: false,
                successfullySaved: true,
                parts: action.data,
            }
        case SAVE_PART.types.FAILED:
            return {
                ...state,
                isLoading: false,
                failedToSave: true,
            }

        case UPDATE_PART.types.TRIGGER:
            return {
                ...state,
                isLoading: true,
            }
        case UPDATE_PART.types.SUCCESSFULL:
            return {
                ...state,
                isLoading: false,
                successfullySaved: true,
                parts: [...state.parts, action.data],
            }
        case UPDATE_PART.types.FAILED:
            return {
                ...state,
                isLoading: false,
                failedToSave: true,
            }

        case TOKENIZE_PARTS.types.TRIGGER:
            return {
                ...state,
                isLoading: true,
            }
        case TOKENIZE_PARTS.types.SUCCESSFULL:
            return {
                ...state,
                isLoading: false,
                successTokenizePart: true,
            }
        case TOKENIZE_PARTS.types.FAILED:
            return {
                ...state,
                isLoading: false,
                failedToSave: true,
            }

        case TOKENIZE_FAILED_PARTS.types.TRIGGER:
            return {
                ...state,
                isLoading: true,
            }
        case TOKENIZE_FAILED_PARTS.types.SUCCESSFULL:
            return {
                ...state,
                isLoading: false,
                successTokenizePart: true,
            }
        case TOKENIZE_FAILED_PARTS.types.FAILED:
            return {
                ...state,
                isLoading: false,
                failedToSave: true,
            }

        case GET_PARTS_FOR_DAPP.types.TRIGGER:
            return {
                ...state,
                isLoading: true,
            }
        case GET_PARTS_FOR_DAPP.types.SUCCESSFULL:
            return {
                ...state,
                parts: action?.data?.data,
                isLoading: false,
            }
        case GET_PARTS_FOR_DAPP.types.FAILED:
            return {
                ...state,
                isLoading: false,
            }

        case GET_PARTS_BY_IDS.types.TRIGGER:
            return {
                ...state,
                isLoading: true,
            }
        case GET_PARTS_BY_IDS.types.SUCCESSFULL:
            return {
                ...state,
                parts: action?.data?.data,
                isLoading: false,
            }
        case GET_PARTS_BY_IDS.types.FAILED:
            return {
                ...state,
                isLoading: false,
            }

        case DELETE_PARTS_BY_IDS.types.TRIGGER:
            return {
                ...state,
                isLoading: true,
            }
        case DELETE_PARTS_BY_IDS.types.SUCCESSFULL:
            return {
                ...state,
                isLoading: false,
            }
        case DELETE_PARTS_BY_IDS.types.FAILED:
            return {
                ...state,
                isLoading: false,
            }

        case GET_PART_BY_ID.types.TRIGGER:
            return {
                ...state,
                isLoading: true,
            }
        case GET_PART_BY_ID.types.SUCCESSFULL:
            return {
                ...state,
                part: action.data,
                isLoading: false,
            }
        case GET_PART_BY_ID.types.FAILED:
            return {
                ...state,
                isLoading: false,
            }

        case GET_SUPPLIER_DATA_RFQ.types.SUCCESSFULL:
            return {
                ...state,
                parts: action.data.parts,
            }
        case GET_RFQS.types.SUCCESSFULL:
        case LOGOUT_ACTION_TYPE:
            return {
                ...state,
                parts: [],
            }
        case RESET_DATA.types.TRIGGER:
            return {
                ...state,
                successfullySaved: false,
                failedToSave: false,
                isLoading: false,
                successTokenizePart: false,
            }

        case SET_PARTS_FOR_TOKENIZATION.types.TRIGGER:
            return {
                ...state,
                selectedPartsForTokenization: action.data.data,
            }

        case SET_PARTS_FOR_TOKENIZATION.types.SUCCESSFULL:
            return {
                ...state,
                selectedPartsForTokenization: action.data.data,
            }

        case SET_PART_FOR_EDIT.types.SUCCESSFULL:
            return {
                ...state,
                selectedPartForEdit: action.data.data,
            }

        case SET_PART_FOR_EDIT.types.TRIGGER:
            return {
                ...state,
                selectedPartForEdit: action.data.data,
            }

        case GET_PART_HISTORY.types.TRIGGER:
            return {
                ...state,
                isLoading: true,
            }
        case GET_PART_HISTORY.types.SUCCESSFULL:
            return {
                ...state,
                partHistory: action.data,
                isLoading: false,
            }
        case GET_PART_HISTORY.types.FAILED:
            return {
                ...state,
                isLoading: false,
            }
        case GET_ETHERNET_LINK.types.TRIGGER:
            return {
                ...state,
                isLoading: true,
            }
        case GET_ETHERNET_LINK.types.SUCCESSFULL:
            return {
                ...state,
                ethernetLink: action.data.url,
                isLoading: false,
            }
        case GET_ETHERNET_LINK.types.FAILED:
            return {
                ...state,
                isLoading: false,
            }

        case GET_PARTS.types.TRIGGER:
            return {
                ...state,
                meta: action.data.meta,
                parts: null,
                part: null,
                isLoading: true,
            }
        case GET_PARTS.types.SUCCESSFULL:
            return {
                ...state,
                parts: action.data.data,
                meta: { ...state.meta, total: action.data.total },
                part: null,
                isLoading: false,
            }
        case GET_PARTS.types.FAILED:
            return {
                ...state,
                parts: null,
                part: null,
                isLoading: false,
            }
        default:
            return state
    }
}
