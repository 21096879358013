import React from 'react'
import SvgIcon from '@material-ui/core/SvgIcon'

export const NavigateNext = props => {
    return (
        <SvgIcon {...props} viewBox="0 0 24 24" x="0px" y="0px" baseProfile="tiny" xmlSpace="preserve" fill="#808080">
            <path d="M0 0h24v24H0z" fill="none" />
            <path d="M10 6L8.59 7.41 13.17 12l-4.58 4.59L10 18l6-6z" fill="#808080" />
        </SvgIcon>
    )
}
