import React from 'react'
import { Typography, Grid } from '@material-ui/core'
import { withNamespaces } from 'react-i18next'

export const EmptyTransactionList = withNamespaces()(({ t }) => {
    return (
        <Grid container spacing={0} justify="center">
            <Typography>{`${t('wallet-no-transactions')}`}</Typography>
        </Grid>
    )
})
