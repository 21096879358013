import { call, takeLatest, getContext, put } from 'redux-saga/effects'
import { Gateway } from '../gateway'
import { PUSH, SHOW_FEEDBACK, Document } from '@syncfab/machine'
import { ACCEPT_RFQ, GET_ORDERS, GET_ORDER, ADD_DOCUMENT_ORDER, REMOVE_DOCUMENT_ORDER, GET_PART_BY_TRACKID } from './Actions'
import { GET_QUOTE_FROM_RFQ, GET_RFQ } from '../quote/rfq/Actions'

function* accept({ data: { id } }: { data: { id: string } }) {
    try {
        const gateway: Gateway = yield getContext('gateway')
        const data = yield call(gateway.getOrderAPI().accept(id))
        yield put(PUSH(`/orders/${data.data}`))
        yield put(ACCEPT_RFQ.actions.SUCCESSFULL())
        yield put(SHOW_FEEDBACK('feedback-rfq-accepted'))
    } catch ({ response: { status, data } }) {
        const handleError = yield getContext('handleError')
        yield call(handleError(status))
        yield put(ACCEPT_RFQ.actions.FAILED(data))
        yield put(SHOW_FEEDBACK('feedback-rfq-acceptd-failed'))
    }
}

function* getOrders() {
    try {
        const gateway: Gateway = yield getContext('gateway')
        const {
            data: { data },
        } = yield call(gateway.getOrderAPI().getAll())
        yield put(GET_ORDERS.actions.SUCCESSFULL(data))
    } catch ({ response: { status, data } }) {
        const handleError = yield getContext('handleError')
        yield call(handleError(status))
        yield put(GET_ORDERS.actions.FAILED(data))
    }
}

function* get({ data: { id } }: { data: { id: string } }) {
    try {
        const gateway: Gateway = yield getContext('gateway')
        const { data } = yield call(gateway.getOrderAPI().get(id))
        yield put(GET_RFQ.actions.TRIGGER({ id: data.quoteId }))
        yield put(GET_QUOTE_FROM_RFQ.actions.TRIGGER({ rfqId: data.quoteId }))
        yield put(GET_ORDER.actions.SUCCESSFULL(data))
    } catch ({ response: { status, data } }) {
        const handleError = yield getContext('handleError')
        yield call(handleError(status))
        yield put(GET_ORDER.actions.FAILED(data))
        if (status === 404) {
            yield put(PUSH('/orders'))
        }
    }
}

function* getPartDetails({ data: { id } }: { data: { id: string } }) {
    try {
        const gateway: Gateway = yield getContext('gateway')
        const { data } = yield call(gateway.getOrderAPI().getPartDetails(id))

        //yield put(GET_RFQ.actions.TRIGGER({ id: data.quoteId }))
        yield put(GET_PART_BY_TRACKID.actions.SUCCESSFULL(data))
    } catch ({ response: { status, data } }) {
        const handleError = yield getContext('handleError')
        yield call(handleError(status))
        yield put(GET_PART_BY_TRACKID.actions.FAILED(data))
        if (status === 404) {
            yield put(PUSH('/track'))
        }
    }
}

function* addDocument({ data: { id, document } }: { data: { id: string; document: Document } }) {
    try {
        const gateway: Gateway = yield getContext('gateway')
        yield call(gateway.getOrderAPI().addDocument(id, document))
        yield put(ADD_DOCUMENT_ORDER.actions.SUCCESSFULL({ document }))
        yield put(SHOW_FEEDBACK('feedback-order-added-document'))
    } catch ({ response: { status, data } }) {
        const handleError = yield getContext('handleError')
        yield call(handleError(status))
        yield put(ADD_DOCUMENT_ORDER.actions.FAILED(data))
        yield put(SHOW_FEEDBACK('feedback-support'))
    }
}

function* removeDocument({ data: { id, documentId } }: { data: { id: string; documentId: string } }) {
    try {
        const gateway: Gateway = yield getContext('gateway')
        yield call(gateway.getOrderAPI().removeDocument(id, documentId))
        yield put(REMOVE_DOCUMENT_ORDER.actions.SUCCESSFULL({ documentId }))
        yield put(SHOW_FEEDBACK('feedback-order-removed-document'))
    } catch ({ response: { status, data } }) {
        const handleError = yield getContext('handleError')
        yield call(handleError(status))
        yield put(REMOVE_DOCUMENT_ORDER.actions.FAILED(data))
        yield put(SHOW_FEEDBACK('feedback-support'))
    }
}

function* orderSaga() {
    // Typings are not good enough. String should be valid but the types
    // do not show it
    yield takeLatest(ACCEPT_RFQ.types.TRIGGER as any, accept)
    yield takeLatest(GET_ORDERS.types.TRIGGER as any, getOrders)
    yield takeLatest(GET_ORDER.types.TRIGGER as any, get)
    yield takeLatest(ADD_DOCUMENT_ORDER.types.TRIGGER as any, addDocument)
    yield takeLatest(REMOVE_DOCUMENT_ORDER.types.TRIGGER as any, removeDocument)
    yield takeLatest(GET_PART_BY_TRACKID.types.TRIGGER as any, getPartDetails)
}

export default orderSaga
