import React from 'react'
import { connect } from 'react-redux'
import { Paper, withStyles, WithStyles, Typography, Grid } from '@material-ui/core'
import { Dispatch } from 'redux'
import { State } from '../../../application/Store'
import { SettingsPersonalForm } from './UserSettingsForm'
import { Map } from 'immutable'
import { SettingsPasswordForm } from './UpdatePassordForm'
import { findSelectedSingleOption, User } from '@syncfab/machine'
import { USER_UPDATE, USER_PASSWORD, GET_LOGGED_USER, USER_EMAIL_UPDATE } from '../../../components/user/Actions'
import { Error, SimpleDialog } from '@syncfab/machine'
import { withNamespaces, WithNamespaces } from 'react-i18next'
import { UserNotificationSettingsForm } from './UserNotificationSettingsForm'
import { SettingsEmailForm } from './UpdateEmailForm'

const styles = theme => ({
    paper: {
        width: '100%',
        minWidth: 300,
        padding: theme.spacing(3),
        marginTop: theme.spacing(3),
    },
    title: {
        fontSize: '20px' as any,
    },
})

interface UserSettingsProps extends WithStyles, WithNamespaces {
    getLoggedUser: () => void
    updateUser: (id: string, delta: Partial<User>) => void
    updatePassword: (password: string, newPassword: string) => void
    updateEmail: (newEmail: string) => void
    loggedUser?: User
    updateUserError?: string
    updatePasswordError?: string
    updateEmailError?: string
}

interface UserSettingsState {
    updatePaswordDialogOpen: boolean
    password?: string
    newPassword?: string
}

class UserSettings extends React.Component<UserSettingsProps, UserSettingsState> {
    constructor(props) {
        super(props)
        this.onSubmitPersonalDetails = this.onSubmitPersonalDetails.bind(this)
        this.onSubmitPassword = this.onSubmitPassword.bind(this)
        this.onSubmitNotificationSettings = this.onSubmitNotificationSettings.bind(this)
        this.onSubmitEmail = this.onSubmitEmail.bind(this)
        this.toggleUpdatePassowrdDialog = this.toggleUpdatePassowrdDialog.bind(this)
        this.updatePassword = this.updatePassword.bind(this)
        this.state = {
            updatePaswordDialogOpen: false,
        }
        props.getLoggedUser()
    }

    toggleUpdatePassowrdDialog() {
        this.setState(state => ({
            updatePaswordDialogOpen: !state.updatePaswordDialogOpen,
        }))
    }

    onSubmitPersonalDetails(values: Map<string, string>) {
        const delta = values.toJS()
        delta['roles'] = [delta['roles']['value']]
        const userId = this.props.loggedUser!._id
        this.props.updateUser(userId, delta)
    }

    updatePassword() {
        this.props.updatePassword(this.state.password!, this.state.newPassword!)
        this.setState({
            password: undefined,
            newPassword: undefined,
        })
        this.toggleUpdatePassowrdDialog()
    }

    onSubmitPassword(values: Map<string, string>) {
        const data: { password: string; newPassword: string } = values.toJS() as any
        this.setState({
            password: data.password,
            newPassword: data.newPassword,
        })
        this.toggleUpdatePassowrdDialog()
    }

    onSubmitEmail(values: Map<string, string>) {
        const data: { newEmail: string } = values.toJS() as any
        this.props.updateEmail(data.newEmail)
    }

    onSubmitNotificationSettings(values: Map<string, any>) {
        const { notificationSettings }: Partial<User> = values.toJS()
        const userId = this.props.loggedUser!._id
        this.props.updateUser(userId, {
            companyId: this.props.loggedUser!.companyId,
            notificationSettings,
        })
    }

    render() {
        const { classes, t, loggedUser, updateUserError, updatePasswordError, updateEmailError } = this.props
        const { updatePaswordDialogOpen } = this.state
        if (!loggedUser) {
            return null
        }
        const rolesArr = [
            {
                label: t('personal-form-admin'),
                value: 'ADMIN',
            },

            {
                label: t('personal-form-member'),
                value: 'MEMBER',
            },
        ]
        return (
            <React.Fragment>
                <Paper className={classes.paper} elevation={1}>
                    <Grid container direction="row" spacing={2}>
                        <Grid item xs={12} sm={12} md={12}>
                            <Typography gutterBottom variant="h6" className={classes.title}>
                                {`${t('user-settings-personal-title')}`}
                            </Typography>
                        </Grid>
                        <Grid item xs={12} sm={12} md={12}>
                            <SettingsPersonalForm
                                onSubmit={this.onSubmitPersonalDetails as any}
                                initialValues={{ ...loggedUser, roles: findSelectedSingleOption(rolesArr, (loggedUser?.roles[0] as any) || rolesArr[0]?.value) }}
                            />
                            <Error trigger={!!updateUserError} error={t(updateUserError || '')} />
                        </Grid>
                    </Grid>
                </Paper>
                <Paper className={classes.paper} elevation={1}>
                    <Grid container direction="row" spacing={2}>
                        <Grid item xs={12} sm={12} md={12}>
                            <Typography gutterBottom variant="h6" className={classes.title}>
                                {`${t('user-settings-email-title')}`}
                            </Typography>
                        </Grid>
                        <Grid item xs={12} sm={12} md={12}>
                            <SettingsEmailForm onSubmit={this.onSubmitEmail as any} initialValues={{ email: loggedUser!.email }} />
                            <Error trigger={!!updateEmailError} error={t(updateEmailError || '')} />
                        </Grid>
                    </Grid>
                </Paper>
                <Paper className={classes.paper} elevation={1}>
                    <Grid container direction="row" spacing={2}>
                        <Grid item xs={12} sm={12} md={12}>
                            <Typography gutterBottom variant="h6" className={classes.title}>
                                {`${t('user-settings-password-title')}`}
                            </Typography>
                        </Grid>
                        <Grid item xs={12} sm={12} md={12}>
                            <SettingsPasswordForm onSubmit={this.onSubmitPassword as any} />
                            <Error trigger={!!updatePasswordError} error={t(updatePasswordError || '')} />
                        </Grid>
                    </Grid>
                </Paper>
                <Paper className={classes.paper} elevation={1}>
                    <Grid container direction="row" spacing={2}>
                        <Grid item xs={12} sm={12} md={12}>
                            <Typography gutterBottom variant="h6" className={classes.title}>
                                {`${t('user-settings-notifications-title')}`}
                            </Typography>
                        </Grid>
                        <Grid item xs={12} sm={12} md={12}>
                            <UserNotificationSettingsForm onSubmit={this.onSubmitNotificationSettings as any} initialValues={loggedUser} />
                        </Grid>
                    </Grid>
                </Paper>
                <SimpleDialog
                    title={t('company-settings-update-password-dialog-title')}
                    content={t('company-settings-update-password-dialog-content')}
                    primaryActionText={t('company-settings-update-password-dialog-update')}
                    onPrimaryAction={this.updatePassword}
                    secondaryActionText={t('company-settings-update-password-dialog-cancel')}
                    onSecondaryAction={this.toggleUpdatePassowrdDialog}
                    open={updatePaswordDialogOpen}
                />
            </React.Fragment>
        )
    }
}

const mapStateToProps = ({ user: { loggedUser, updateUserError, updatePasswordError, updateEmailError } }: State) => {
    return {
        loggedUser,
        updateUserError,
        updatePasswordError,
        updateEmailError,
    }
}

const mapDispatchToProps = (dispatch: Dispatch) => {
    return {
        updateUser: (id: string, delta: Partial<User>) => dispatch(USER_UPDATE.actions.TRIGGER({ id, delta })),
        updatePassword: (password: string, newPassword: string) => dispatch(USER_PASSWORD.actions.TRIGGER({ password, newPassword })),
        getLoggedUser: () => dispatch(GET_LOGGED_USER.actions.TRIGGER()),
        updateEmail: (newEmail: string) => {
            dispatch(USER_EMAIL_UPDATE.actions.TRIGGER({ newEmail }))
        },
    }
}

const UserSettingsWithTranslations = withStyles(styles)(withNamespaces()(UserSettings))

export default connect(mapStateToProps, mapDispatchToProps)(UserSettingsWithTranslations)
