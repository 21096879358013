import React from 'react'
import { Helmet } from 'react-helmet'
import { Map } from 'immutable'
import { withNamespaces, WithNamespaces } from 'react-i18next'
import { Dispatch } from 'redux'
import { WithStyles } from '@material-ui/core/styles'
import { connect } from 'react-redux'
import Typography from '@material-ui/core/Typography'
import { UserActivationForm } from './components/UserActivationForm'
import { Card, withStyles } from '@material-ui/core'
import { ACTIVATE_INVITED_USER, UserActivationPayload } from '../../../components/user/Actions'
import { CenteredContainer } from '../../../layout/Container'
import { getURLParam } from '../../../utils/URLParser'
import { SHOW_FEEDBACK } from '@syncfab/machine'

const styles = theme => ({
    card: {
        width: '40%',
        margin: 'auto',
        padding: theme.spacing(2),
    },
    '@media (max-width:800px)': {
        card: {
            width: '85%',
        },
    },
})

interface UserActivationProps extends WithNamespaces, WithStyles {
    activate: (payload: UserActivationPayload) => void,
    notify: () => void,
}

class UserActivationView extends React.Component<UserActivationProps> {
    constructor(props: UserActivationProps) {
        super(props)
        this.onSubmit = this.onSubmit.bind(this)
    }

    public render() {
        const { classes, t } = this.props
        return (
            <CenteredContainer>
                <Helmet>
                    <title>{`${t('user-activation-title')}`}</title>
                </Helmet>
                <Card className={classes.card}>
                    <Typography variant="h2" gutterBottom>
                        {`${t('user-activation-header')}`}
                    </Typography>
                    <Typography variant="caption" gutterBottom>
                        {`${t('user-activation-content')}`}
                    </Typography>
                    <UserActivationForm onSubmit={this.onSubmit as any} />
                </Card>
            </CenteredContainer>
        )
    }

    private onSubmit(values: Map<string, string>) {
        const token = getURLParam('token')
        const { email, password } = values.toJS() as any
        this.props.activate({ email, token, password });
        this.props.notify();
    }
}

const mapDispatchToProps = (dispatch: Dispatch) => {
    return {
        activate: (payload: UserActivationPayload) => dispatch(ACTIVATE_INVITED_USER.actions.TRIGGER(payload)),
        notify: () => dispatch(SHOW_FEEDBACK('user-activated')),
    }
}

const UserActivationWithTranslations = withNamespaces()(withStyles(styles)(UserActivationView))

export default connect(
    null,
    mapDispatchToProps
)(UserActivationWithTranslations)
