import React from 'react'
import { withNamespaces, WithNamespaces } from 'react-i18next'
import { Button, RHFTextField, useUpdateContactMutation, useCreateSupplierUserMutation, Supplier } from '@syncfab/machine'
import { Grid } from '@material-ui/core'
import { makeStyles, createStyles, Theme } from '@material-ui/core';
import { FormProvider, useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup';
import { object, SchemaOf, string } from 'yup';

export const TeamEditorFormName: string = 'TeamEditorFormName'

const useStyles = makeStyles((theme: Theme) => {
    return createStyles({
        action: {
            minWidth: 120,
        },
        header: {
            paddingTop: theme.spacing(2),
        },
        footerText: {
            'text-align': 'center',
        },
        companyIdMissingText: {
            'text-align': 'center',
        },
        form: {
            display: 'flex',
            'flex-direction': 'column',
            maxWidth: 1000,
            minWidth: 300,
            margin: 'auto',
            [theme.breakpoints.down('md')]: {
                maxWidth: 600,
            },
        },
        row: {
            display: 'flex',
            'flex-direction': 'row',
            [theme.breakpoints.down('md')]: {
                'flex-direction': 'column',
            },
        },
        leftInput: {
            marginRight: theme.spacing(2),
            [theme.breakpoints.down('md')]: {
                marginRight: 0,
            },
        },
        rightInput: {
            marginLeft: theme.spacing(2),
            [theme.breakpoints.down('md')]: {
                marginLeft: 0,
            },
        },
    })
})

interface FormState {
    firstName: string | null
    lastName: string | null
    title: string | null
    email: string | null
    phone: string | null
    phoneExtension: string | null
    mobile: string | null
}

const formDefaults = {
    firstName: null,
    lastName: null,
    title: '',
    email: null,
    phone: null,
    phoneExtension: '',
    mobile: '',
    _id: null,
}

const FormSchema: SchemaOf<FormState> = object({
    firstName: string().required(),
    lastName: string().required(),
    title: string(),
    email: string().email().required(),
    phone: string().required(),
    phoneExtension: string(),
    mobile: string(),
    _id: string().nullable(),
})

interface FormProps extends WithNamespaces {
    initialValues: FormState
    cancel: () => void
    supplier: Supplier
}
const Form = ({ initialValues, t, cancel, supplier }: FormProps) => {
    const classes = useStyles();
    const methods = useForm({
        resolver: yupResolver(FormSchema, { stripUnknown: true}),
        defaultValues: {
            ...formDefaults,
            ...initialValues,
        }
    })

    const [ updateContact ] = useUpdateContactMutation();
    const [ createContact ] = useCreateSupplierUserMutation();
    const submitFunction = async formData => {
        if(!!initialValues) {
            updateContact({id: formData._id, userInput: formData })
        } else {
            createContact({
                ...formData,
                supplierId: supplier._id,
                companyId: supplier.companyId,
            });
        }
        cancel();
    }
    return (
        <FormProvider {...methods}>
            <Grid container justifyContent="flex-start" spacing={2}>
                <Grid item xs={12}>
                    <RHFTextField required name="firstName" label="First Name"></RHFTextField>
                </Grid>
                <Grid item xs={12}>
                    <RHFTextField name="lastName" label="Last Name"></RHFTextField>
                </Grid>
                <Grid item xs={12}>
                    <RHFTextField name="title" label="Title"></RHFTextField>
                </Grid>
                <Grid item xs={12}>
                    <RHFTextField name="email" label="Email"></RHFTextField>
                </Grid>
                <Grid item xs={8}>
                    <RHFTextField name="phone" label="Phone"></RHFTextField>
                </Grid>
                <Grid item xs={4}>
                    <RHFTextField name="phoneExtension" label="Extension"></RHFTextField>
                </Grid>
                <Grid item xs={12}>
                    <RHFTextField name="mobile" label="Mobile"></RHFTextField>
                </Grid>
                <Grid container item xs={12} justifyContent="flex-end">
                    <Button type="button" color="primary" variant="text" className={classes.action} onClick={() => cancel()}>
                        {t('form-action-cancel')}
                    </Button>
                    <Button type="submit" color="primary" className={classes.action} onClick={methods.handleSubmit(submitFunction)}>
                        {t('form-action-save')}
                    </Button>
                </Grid>
            </Grid>
        </FormProvider>
    )
}

export const RHFTeamEditorForm = withNamespaces()(Form);