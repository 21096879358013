import React from 'react'
import { Table, TableHead, TableRow, TableCell, TableBody, withStyles, WithStyles, TableFooter } from '@material-ui/core'
import { withNamespaces, WithNamespaces } from 'react-i18next'
import { Part, RFQ, Button } from '@syncfab/machine'

const styles = theme => ({
    table: {
        background: theme.palette.common.white,
        width: '100%',
        borderRadius: 4,
    },
    description: {
        color: theme.palette.grey['700'],
    },
})

interface SupplierTableProps extends WithNamespaces, WithStyles {
    rfq: RFQ
    parts: { [key: string]: Part }
    getDocuments: () => void
}

const columns: string[] = ['part-number', 'description', 'quantity']

const SupplierTable = ({ t, rfq, parts, classes, getDocuments }: SupplierTableProps) => {
    return (
        <Table className={classes.table}>
            <TableHead>
                <TableRow>
                    {columns.map(column => (
                        <TableCell key={column}>{`${t(column)}`}</TableCell>
                    ))}
                </TableRow>
            </TableHead>
            <TableBody>
                {rfq.orderItems.map(item => {
                    const part = parts[item.partId]
                    return (
                        <TableRow key={item.partId}>
                            <TableCell component="th" scope="row">
                                {!!part ? part.number : '...'}
                            </TableCell>
                            <TableCell className={classes.description}>{!!part ? part.name : '...'}</TableCell>
                            <TableCell>{item.quantity}</TableCell>
                        </TableRow>
                    )
                })}
            </TableBody>
            <TableFooter>
                <TableRow>
                    <TableCell align="right" colSpan={3}>
                        <Button color="primary" onClick={getDocuments}>
                            {t('download-files')}
                        </Button>
                    </TableCell>
                </TableRow>
            </TableFooter>
        </Table>
    )
}

export const QuoteSupplierTable = withNamespaces()(withStyles(styles)(SupplierTable))
