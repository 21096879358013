import { Path, UseFormReturn } from "react-hook-form";

export function focusFirstError<T>(methods: UseFormReturn<T, any>) {
    const keys = Object.keys(methods.formState.errors) as Array<Path<T>>
    const firstErrorField = keys.reduce((field, a) => {
        return !!methods.formState.errors[field as string] ? field : a
    }, null)

    if (firstErrorField) {
        methods.setFocus(firstErrorField)
    }
}