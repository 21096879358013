import React from 'react'
import { Helmet } from 'react-helmet'
import { withNamespaces, WithNamespaces } from 'react-i18next'
import { WithStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import { Supplier, Button, EmailSent } from '@syncfab/machine'
import { Paper, withStyles } from '@material-ui/core'
import { CenteredContainer } from '../../../layout/Container'
import { connect } from 'react-redux'
import { Dispatch } from 'redux'
import { State } from '../../../application/Store'
import { SUPPLIER_RESEND_EMAIL } from '../../../components/supplier/Actions'

const styles = theme => ({
    paper: {
        padding: theme.spacing(6),
        display: 'flex',
        'flex-direction': 'column',
        'justify-content': 'center',
        alignItems: 'center',
        width: 400,
        'text-align': 'center',
    },
    text: {
        padding: theme.spacing(2),
        color: theme.palette.common.white,
        marginBottom: theme.spacing(2),
    },
    link: {
        width: '100%',
    },
    sentIcon: {
        marginLeft: -16,
    },
})

interface SupplierSignupConfirmationProps extends WithNamespaces, WithStyles {
    supplier?: Partial<Supplier>
    resend: (id: string) => void
}

// TODO(JR): Unify this view
class SignupConfirmationView extends React.Component<SupplierSignupConfirmationProps> {
    constructor(props: SupplierSignupConfirmationProps) {
        super(props)
        this.onResend = this.onResend.bind(this)
    }

    onResend() {
        const { _id } = this.props.supplier
        this.props.resend(_id)
    }

    public render() {
        const { classes, t, supplier } = this.props
        if (!supplier) {
            return null
        }

        return (
            <CenteredContainer>
                <Helmet>
                    <title>{`${t('signup-confirmation-title')}`}</title>
                </Helmet>
                <Paper className={classes.paper} elevation={1}>
                    <EmailSent className={classes.sentIcon} />
                    <Typography className={classes.text} variant="h5">
                        {`${t('signup-confirmation-content')}`}
                    </Typography>
                    <Button color="primary" variant="outlined" fullWidth onClick={this.onResend}>
                        {t('signup-confirmation-resend')}
                    </Button>
                </Paper>
            </CenteredContainer>
        )
    }
}

const SignupConfirmationViewWithTranslations = withNamespaces()(withStyles(styles)(SignupConfirmationView))

const mapStateToProps = ({ supplier: { supplier } }: State) => {
    return {
        supplier,
    }
}

const mapDispatchToProps = (dispatch: Dispatch) => {
    return {
        resend: (id: string) => dispatch(SUPPLIER_RESEND_EMAIL.actions.TRIGGER({ id })),
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(SignupConfirmationViewWithTranslations)
