import React from 'react';
import {
  Grid,
  Typography,
  Card,
  withStyles,
  WithStyles,
} from '@material-ui/core';
import { RFQ, RFQStatus, Button } from '@syncfab/machine';
import cn from 'classnames';
import { withNamespaces, WithNamespaces } from 'react-i18next';
import { Link } from 'react-router-dom';
import DashboardIcon from '@material-ui/icons/Dashboard';
import ListAltIcon from '@material-ui/icons/ListAlt';
import TodayIcon from '@material-ui/icons/Today';
import WatchLaterIcon from '@material-ui/icons/WatchLater';
const styles = theme => ({
  card: {
    width: '100%',
    marginTop: 0,
    margin: `${theme.spacing(3)}px 12px`,
    backgroundColor: theme.palette.common.white,
  },
  cardHideAciton: {
    width: '100%',
    margin: `${theme.spacing(3)}px 0`,
    backgroundColor: theme.palette.common.white,
  },
  idLabel: {
    color: theme.palette.common.black,
    'font-weight': theme.typography.fontWeightMedium,
    fontSize: '18px' as any,
  },
  label: {
    color: theme.palette.common.black,
  },
  value: {
    color: theme.palette.common.black,
    fontSize: '14px' as any,
    'text-overflow': 'ellipsis',
    'white-space': 'nowrap',
    overflow: 'hidden',
  },
  accepted: {
    color: theme.palette.primary.light,
  },
  expired: {
    color: theme.palette.error.dark,
  },
  decline: {
    color: theme.palette.error.dark,
  },
  quoted: {
    color: '#40BF71',
    fontWeight: theme.typography.fontWeightMedium,
  },
  open: {
    color: theme.palette.secondary.main,
  },
  button: {
    minWidth: 120,
  },
  link: {
    'text-decoration': 'none',
    color: theme.palette.common.black,
  },
  title: {
    width: '100%',
    backgroundColor: theme.palette.grey['200'],
    padding: theme.spacing(3),
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    color: theme.palette.grey['700'],
    marginBottom: theme.spacing(2),
  },
  container: {
    width: '100%',
    padding: theme.spacing(3),
    paddingTop: 0,
    paddingBottom: theme.spacing(2),
  },
  iconStyle: {
    color: '#A0AEC0',
  },
  itemLabel: {
    marginLeft: 10,
    color: '#A0AEC0',
    fontSize: '14px',
  },
  itemValue: {
    fontSize: '14px',
    color: '#000',
    marginLeft: 5,
  },
  item: {
    alignItems: 'center',
    display: 'flex',
    marginRight: 24,
    marginBottom: 0,
    '@media all and (max-width:959px)': {
      marginBottom: theme.spacing(1),
    },
  },
});

interface QuoteListItemProps extends WithStyles, WithNamespaces {
  rfq: RFQ;
  hideAction?: boolean;
  hideId?: boolean;
}

class ListItem extends React.Component<
  QuoteListItemProps,
  { anchorEl: HTMLElement | null }
> {
  constructor(props) {
    super(props);
    this.state = {
      anchorEl: null,
    };
  }

  render() {
    const { rfq, classes, t, hideAction, hideId } = this.props;
    const isQuoted = [RFQStatus.QUOTED, RFQStatus.INAPPROVAL].includes(
      rfq.status
    );
    const statusClassNames = cn({
      [classes.value]: true,
      [classes.open]: rfq.status === RFQStatus.OPEN,
      [classes.expired]: rfq.status === RFQStatus.EXPIRED,
      [classes.quoted]: rfq.status === RFQStatus.QUOTED,
    });

    return (
      <Card className={!!hideAction ? classes.cardHideAciton : classes.card}>
        <Grid container className={classes.title}>
          <Grid item xs={8} md={10}>
            {!hideId && (
              <Typography gutterBottom className={classes.idLabel} variant="h5">
                {rfq._id}
              </Typography>
            )}
            {!!hideId && (
              <Typography gutterBottom className={classes.label} variant="h5">
                {`${t('quote-list-item-rfq-po-name')}`}
              </Typography>
            )}
            <Typography className={classes.value} variant="caption">
              {rfq.name}
            </Typography>
          </Grid>
          <Grid item xs={4} md={2} style={{ textAlign: 'end' }}>
            {RFQStatus.OPEN === rfq.status ? (
              <Link to={`/quotes/${rfq._id}`} className={classes.link}>
                <Button
                  color={isQuoted ? 'secondary' : 'primary'}
                  className={classes.button}
                  variant={'contained'}
                >
                  {t(`quote-item-${rfq.status.toLowerCase()}`)}
                </Button>
              </Link>
            ) : [RFQStatus.QUOTED, RFQStatus.INAPPROVAL].includes(
                rfq.status
              ) ? (
              <Link to={`/quotes/${rfq._id}`} className={classes.link}>
                <Button
                  color={'primary'}
                  className={classes.button}
                  variant={'outlined'}
                >
                  {t(`quote-item-${rfq.status.toLowerCase()}`)}
                </Button>
              </Link>
            ) : (
              <Link to={`/quotes/${rfq._id}`} className={classes.link}>
                <Button
                  color={isQuoted ? 'secondary' : 'primary'}
                  className={classes.button}
                  variant={isQuoted ? 'contained' : 'outlined'}
                >
                  {t(`quote-list-item-${rfq.status.toLowerCase()}`)}
                </Button>
              </Link>
            )}
          </Grid>
        </Grid>

        <Grid
          container
          direction="row"
          alignItems="center"
          className={classes.container}
        >
          <Grid item className={classes.item}>
            <DashboardIcon fontSize="small" className={classes.iconStyle} />
            <Typography variant="h6" className={classes.itemLabel}>
              {`${t('quote-list-item-rfq-status')}:`}
            </Typography>
            <Typography
              className={statusClassNames}
              variant="h3"
              style={{
                fontSize: '14px',
                marginLeft: 5,
                textTransform: 'uppercase',
              }}
            >
              {`${t(`quote-list-item-status-${rfq.status.toLowerCase()}`)}`}
            </Typography>
          </Grid>
          <Grid item className={classes.item}>
            <ListAltIcon fontSize="small" className={classes.iconStyle} />
            <Typography variant="h3" className={classes.itemLabel}>
              {`${t('Total Lines')}:`}
            </Typography>
            <Typography variant="caption" className={classes.itemValue}>
              {rfq && rfq.numLineItems ? rfq.numLineItems : '--'}
            </Typography>
          </Grid>
          <Grid item className={classes.item}>
            <TodayIcon fontSize="small" className={classes.iconStyle} />
            <Typography variant="h3" className={classes.itemLabel}>
              {`${t('RFQ Created')}:`}
            </Typography>
            <Typography  variant="caption" className={classes.itemValue}>
              {rfq && rfq.createdAt
                ? `${new Date(rfq.createdAt).toLocaleDateString()} ${new Date(
                    rfq.createdAt
                  ).toLocaleTimeString()}`
                : '--'}
            </Typography>
          </Grid>
          <Grid item className={classes.item}>
            <WatchLaterIcon fontSize="small" className={classes.iconStyle} />
            <Typography variant="h3" className={classes.itemLabel}>{`${t(
              'Quote Due'
            )}:`}</Typography>
            <Typography variant="caption" className={classes.itemValue}>
              {rfq.quoteDeadline
                ? `${new Date(
                    rfq.quoteDeadline
                  ).toLocaleDateString()} ${new Date(
                    rfq.quoteDeadline
                  ).toLocaleTimeString()}`
                : '--'}
            </Typography>
          </Grid>
        </Grid>
      </Card>
    );
  }
}

export const RFQQuoteListItem = withStyles(styles)(withNamespaces()(ListItem));
