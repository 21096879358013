import axios, { AxiosPromise } from 'axios'
import { Address, Company, Environment, EnvironmentVariable, AuthorizationService } from '@syncfab/machine'
import { SignupData } from '../../view/signup/Actions'

export class CompanyAPI {
    constructor(private environment: Environment, private authorizationService: AuthorizationService) {}

    public signup(data: SignupData): () => AxiosPromise {
        const url = this.environment.get(EnvironmentVariable.API_LAYER_URL)
        return () => axios.post(`${url}/signup`, { ...data, loginRole: 'BUYER' })
    }

    // TODO:  Need to verify after addressing PROD-48
    public resendValidationEmail(userId: string): () => AxiosPromise {
        const url = this.environment.get(EnvironmentVariable.API_LAYER_URL)
        return () => axios.post(`${url}/signup/${userId}/resendVerification`)
    }

    public resendVerificationEmail(email: string): () => AxiosPromise {
        const url = this.environment.get(EnvironmentVariable.API_LAYER_URL)
        return () => axios.post(`${url}/signup/${encodeURIComponent(email)}/resendVerificationEmail`)
    }

    public getLoggedCompany = (): (() => AxiosPromise) => {
        const url = this.environment.get(EnvironmentVariable.API_LAYER_URL)
        const authorization = `Bearer ${this.authorizationService.getAuthToken()}`
        return () => {
            return axios.get(`${url}/companies/logged`, {
                headers: {
                    authorization,
                },
            })
        }
    }

    public update(delta: Partial<Company>): () => AxiosPromise {
        const url = this.environment.get(EnvironmentVariable.API_LAYER_URL)
        const authorization = `Bearer ${this.authorizationService.getAuthToken()}`
        return () =>
            axios.put(`${url}/companies`, delta, {
                headers: {
                    authorization,
                },
            })
    }

    public getAddresses = (): (() => AxiosPromise) => {
        const url = this.environment.get(EnvironmentVariable.API_LAYER_URL)
        const authorization = `Bearer ${this.authorizationService.getAuthToken()}`
        return () => {
            return axios.get(`${url}/companies/addresses`, {
                headers: {
                    authorization,
                },
            })
        }
    }

    public createAddress(address: Partial<Address>): () => AxiosPromise {
        const url = this.environment.get(EnvironmentVariable.API_LAYER_URL)
        const authorization = `Bearer ${this.authorizationService.getAuthToken()}`
        return () =>
            axios.post(`${url}/companies/addresses`, address, {
                headers: {
                    authorization,
                },
            })
    }

    public updateAddress({ _id, ...address }: Address): () => AxiosPromise {
        const url = this.environment.get(EnvironmentVariable.API_LAYER_URL)
        const authorization = `Bearer ${this.authorizationService.getAuthToken()}`
        return () =>
            axios.put(`${url}/companies/addresses/${_id}`, address, {
                headers: {
                    authorization,
                },
            })
    }

    public deleteAddress(_id: string): () => AxiosPromise {
        const url = this.environment.get(EnvironmentVariable.API_LAYER_URL)
        const authorization = `Bearer ${this.authorizationService.getAuthToken()}`
        return () =>
            axios.delete(`${url}/companies/addresses/${_id}`, {
                headers: {
                    authorization,
                },
            })
    }
    public updateCompany = (companyId: string, companyInput: Partial<Company>): (() => AxiosPromise) => {
        const url = this.environment.get(EnvironmentVariable.API_LAYER_URL)
        const authorization = `Bearer ${this.authorizationService.getAuthToken()}`
        return () => {
            return axios.patch(`${url}/companies/${companyId}`, companyInput, {
                headers: {
                    authorization,
                },
            })
        }
    }
}
