import React from 'react'
import { CenteredAlignedContainerV2 } from '../../../layout/Container'
import PartEditorForm from './components/PartEditorForm'
import { useParams } from 'react-router'
import { Loader, useGetPartQuery } from '@syncfab/machine'

export const UploadPart = () => {
    const { partId } = useParams<{companyId: string, partId: string}>();
    const { data: partData, isLoading: partLoading } = useGetPartQuery(partId, { skip: !partId })
        return (
            partLoading ? <Loader /> : 
            <CenteredAlignedContainerV2>
                <PartEditorForm
                    existingDefaultValues={partData}
                />
            </CenteredAlignedContainerV2>
        )
}
