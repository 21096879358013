import { FetchActions, createFetchActions, Transaction } from '@syncfab/machine'

export const TRANSACTION_REQUEST_SIZE = 25

export const GET_TRANSACTIONS: FetchActions<{ field: string; direction: number; filter: string; size: number }, Transaction[]> = createFetchActions('GET_TRANSACTIONS')
export const FETCH_TRANSACTIONS: FetchActions<{ page: number; field: string; direction: number; size: number }, Transaction[]> = createFetchActions('FETCH_TRANSACTIONS')
export const GET_BALANCE: FetchActions<void, number> = createFetchActions('GET_BALANCE')
export const GET_NFT_BALANCE: FetchActions<void, number> = createFetchActions('GET_NFT_BALANCE')
export const SEND_MFG_TOKENS: FetchActions<void, any> = createFetchActions('SEND_MFG_TOKENS')
export const GET_TRANSACTION_FEE: FetchActions<void, number> = createFetchActions('GET_TRANSACTION_FEE')
export const RESET_DATA: FetchActions<void, number> = createFetchActions('RESET_DATA')
export const CREATE_WALLET: FetchActions<void, any> = createFetchActions('CREATE_WALLET')
export const GET_WALLET_STATUS: FetchActions<void, number> = createFetchActions('GET_WALLET_STATUS')
export const GET_WALLET_ADDRESS: FetchActions<void, number> = createFetchActions('GET_WALLET_ADDRESS')
export const GET_COST: FetchActions<void, number> = createFetchActions('GET_COST')
export const MAKE_PAYMENT: FetchActions<any, any> = createFetchActions('MAKE_PAYMENT')
export const ACH_PAYMENT: FetchActions<any, any> = createFetchActions('ACH_PAYMENT')
export const CONTACT_SALES: FetchActions<{ companyId: string; subject: string; description: string }, {}> = createFetchActions('CONTACT_SALES')
