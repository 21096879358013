import {
    AuthorizationService,
    EnvironmentVariable,
    I18NService,
    LocalStoragePersistanceService,
    PersistanceService,
    RouterService
} from "@syncfab/machine";
import { environment } from './environment'
import { AnalyticsService } from "../utils/Analytics/AnalyticsService";

// Create services
export const persistanceService: PersistanceService = new LocalStoragePersistanceService()
export const routerService: RouterService = new RouterService()
export const authorizationService: AuthorizationService = new AuthorizationService(persistanceService)
export const i18nService: I18NService = new I18NService(environment)
export const analyticsService: AnalyticsService = new AnalyticsService(environment.get(EnvironmentVariable.ANALYTICS_ID))