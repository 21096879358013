import React from 'react'
import { WithNamespaces, withNamespaces } from 'react-i18next'
import { connect } from 'react-redux'
import { CenteredAlignedContainerV2 } from '../../layout/Container'
import Helmet from 'react-helmet'
import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'
import UserSettings from './components/UserSettings'
import CompanySettings from './components/CompanySettings'
import CompanyDocuments from './components/CompanyDocuments'
import { withRouter, RouteComponentProps } from 'react-router-dom'
import { Dispatch } from 'redux'
import { Button, PUSH } from '@syncfab/machine'
import { UserListView } from '../users/list'
import { Grid, Typography, withStyles, WithStyles } from '@material-ui/core'

interface SettingsProps extends WithStyles, WithNamespaces, RouteComponentProps<{ selectedTab: string }> {
    changeTab: (tab: string) => void
    addUser: (tab: string) => void
}
const styles = theme => ({
    header: {
        fontSize: theme.spacing(4),
        textTransform: 'uppercase' as any,
        marginTop: theme.spacing(3),
        marginBottom: theme.spacing(3),
    },
})
class SettingsView extends React.Component<SettingsProps> {
    private tabs: string[] = ['user', 'company', 'documents', 'team']
    // TODO (JR): remove this any
    private tabMapping: { [key: string]: any } = {
        user: UserSettings,
        company: CompanySettings,
        documents: CompanyDocuments,
        team: UserListView,
    }

    constructor(props) {
        super(props)
    }

    private handleChange = (_, newTab) => {
        this.props.changeTab(newTab)
    }

    render() {
        const {
            t,
            match: {
                params: { selectedTab },
            },
            classes,
        } = this.props

        const TabContentComponent = this.tabMapping[selectedTab]
        const queryParams = new URLSearchParams(window.location.search)
        const queryStringType = queryParams.get('type')
        return (
            <CenteredAlignedContainerV2>
                <Helmet>
                    <title>{`${t('settings-title')}`}</title>
                </Helmet>
                <Grid container spacing={0} alignItems="center" direction="row">
                    <Grid item xs={6}>
                        <Typography variant="h4" className={classes.header}>{`${t('user-settings-title')}`}</Typography>
                    </Grid>
                    {selectedTab === 'team' && !queryStringType && (
                        <Grid item xs={6}>
                            <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                                <Button className={classes.saveBtn} type="button" color="primary" size="small" onFocus={() => {}} onClick={() => {
                                    this.props.addUser(selectedTab)
                                }}>
                                    {`${t('user-add-title')}`}
                                </Button>
                            </div>
                        </Grid>
                    )}
                </Grid>
                <Grid container spacing={0}>
                    <Tabs value={selectedTab} onChange={this.handleChange} indicatorColor="primary" textColor="primary" centered>
                        {this.tabs.map(tab => (
                            <Tab key={tab} value={tab} label={`${t(`settings-tab-${tab}`)}`} style={{ minWidth: '120px' }} />
                        ))}
                    </Tabs>
                </Grid>
                <TabContentComponent />
            </CenteredAlignedContainerV2>
        )
    }
}

const SettingsWithTranslations = withRouter(withStyles(styles)(withNamespaces()(SettingsView)))

const mapDispatchToProps = (dispatch: Dispatch) => {
    return {
        changeTab: (newTab: string) => dispatch(PUSH(`/settings/${newTab}`)),
        addUser: (newTab: string) => dispatch(PUSH(`/settings/${newTab}?type=add`)),
    }
}

export default connect(null, mapDispatchToProps)(SettingsWithTranslations)
