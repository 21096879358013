import { Document, Page } from "react-pdf";
import React, { FC, useEffect, useState } from "react";
import { retrieveDocumentFromURL } from "./DocumentServices";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import { Theme } from "@material-ui/core";
import { ProgressBar } from "../ui";

const useStyles = makeStyles((_theme: Theme) => createStyles({
    pdfCanvas: {
        '& .react-pdf__Page__canvas': {
            margin: '0 auto',
            width: '100% !important',
            height: '100% !important',
        },
    },
}))

interface PDFViewerInPageProps {
    docURL: string
    authToken: string
}

export const PDFViewerInPage: FC<PDFViewerInPageProps> = ({ docURL, authToken}) => {

    const classes = useStyles()

    const [docContents, setDocContents] = useState(null)
    const [isDocLoading, setIsDocLoading] = useState(false)
    const [numPages, setNumPages] = useState<number>(0)

    useEffect(() => {
        setIsDocLoading(true)

        const retrieveDocument = async () => {
            const docHref = await retrieveDocumentFromURL(docURL, authToken)
            setDocContents(docHref)
            setIsDocLoading(false)
        }

        retrieveDocument()
            .catch(error => {
                console.error(error)
                setIsDocLoading(false)
            })
    },[])

    const onDocumentLoadSuccess = ({ numPages }) => {
        setNumPages(numPages)
    }

    return (
        <>
            { isDocLoading && <ProgressBar />}
            { !isDocLoading && !!docContents &&
                <div style={{ display: 'flex', justifyContent: 'center', marginTop: '16px' }}>
                    <Document
                        file={docContents}
                        onLoadSuccess={onDocumentLoadSuccess}
                        onLoadError={console.error}
                        loading=''
                    >
                        {Array.from(new Array(numPages), (_, index) => {
                            return (
                                <React.Fragment key={index}>
                                    { index > 0 && <div style={{ height: '4px' }} /> }
                                    <Page className={classes.pdfCanvas}
                                          key={`page_${index + 1}`}
                                          pageNumber={index + 1}
                                          scale={2}
                                          renderAnnotationLayer={false}
                                          renderTextLayer={false}
                                    />
                                </React.Fragment>
                            )}
                        )}
                    </Document>
                </div>
            }
        </>
    )
}