import { PaletteOptions } from '@material-ui/core/styles/createPalette'

export const palette: PaletteOptions = {
    common: {
        black: '#000',
        white: '#fff',
    },
    type: 'light',
    primary: {
        light: '#44C5D2',
        main: '#40B4BF',
        dark: '#368ABE',
        contrastText: '#fff',
    },
    secondary: {
        light: '#FFC525',
        main: '#FFBA00',
        dark: '#FFAD00',
        contrastText: '#fff',
    },
    error: {
        light: '#E05B49',
        main: '#BF4B40',
        dark: '#A03F32',
        contrastText: '#fff',
    },
    grey: {
        50: '#f9f9f9',
        100: '#f3f3f3',
        200: '#ebebeb',
        300: '#dbdbdb',
        400: '#b8b8b8',
        500: '#989898',
        600: '#707070',
        700: '#5c5c5c',
        800: '#3d3d3d',
        900: '#1d1d1d',
        A100: '#d5d5d5',
        A200: '#aaaaaa',
        A400: '#303030',
        A700: '#616161',
    },
    contrastThreshold: 3,
    tonalOffset: 0.2,
    text: {
        primary: 'rgba(0, 0, 0, 0.87)',
        secondary: 'rgba(0, 0, 0, 0.54)',
        disabled: 'rgba(0, 0, 0, 0.38)',
        hint: 'rgba(0, 0, 0, 0.38)',
    },
    divider: '#DBDBDB',
    background: {
        paper: '#f3f3f3',
        default: '#ffffff',
    },
    action: {
        active: 'rgba(0, 0, 0, 0.54)',
        hover: 'rgba(0, 0, 0, 0.08)',
        hoverOpacity: 0.08,
        selected: 'rgba(0, 0, 0, 0.14)',
        disabled: 'rgba(0, 0, 0, 0.26)',
        disabledBackground: 'rgba(0, 0, 0, 0.12)',
    },
    customComponent: {
        backgroundColor: '#fff',
        iconBackgroundColor: 'gray',
        iconColor: 'white',
        textColor: '#000000',
        captionTextColor: '#989898',
        cardBorderColor: '#fff',
        listBackgroundColor: '#f3f3f3',
        listHeaderBorderColor: '#65788B',
        listHeaderHoverColor: 'gray',
        innerTableBackgroundColor: '#E6E6E6',
        innerTableBorderColor: '#0000001A',
        fieldBackgroundColor: '#F3F3F3',
        fieldBorderColor: '#00000023'
    }
}

export const colors: { [key: string]: any } = {
    surfaceDark: '#233A4F',
    tertiary: {
        light: '#715FCE',
        main: '#4B40BF',
        dark: '#2A32AE',
        contrastText: '#fff',
    },
}
