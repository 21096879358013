import React, { FC } from "react";
import { FormControlLabel } from "@material-ui/core";
import { Controller, useFormContext } from "react-hook-form";
import { Checkbox } from "../Checkbox";


export interface RHFCheckboxProps {
    name: string,
    label: string,
    labelPlacement?: 'end' | 'bottom' | 'start' | 'top' | 'none'
    style?: any
}

export const RHFCheckbox: FC<RHFCheckboxProps> = ({ name, label, labelPlacement = 'end', style = {} }: RHFCheckboxProps) => {

    const methods = useFormContext()

    if (labelPlacement === 'none') {
        return (
            <Controller
                name={name}
                control={methods.control}
                render={({ field: props }) => (
                    <Checkbox
                        {...props}
                        color='primary'
                        checked={!!props.value}
                        onChange={(e) => props.onChange(e.target.checked)}
                        style={style}
                    />
                )}
            />
        )
    }

    return (
        <FormControlLabel
            control={
                <Controller
                    name={name}
                    control={methods.control}
                    render={({ field: props }) => (
                        <Checkbox
                            {...props}
                            color='primary'
                            checked={!!props.value}
                            onChange={(e) => props.onChange(e.target.checked)}
                            style={style}
                        />
                    )}
                />
            }
            label={label}
            labelPlacement={labelPlacement}
        />
    )
}