import React from 'react';
import { Grid, Paper, Typography } from '@material-ui/core';
import {
  DocumentCardList,
  DocumentMenuOption,
  useGetPublicationsDocumentsQuery,
} from '@syncfab/machine';
import { useStandardStyles } from '../styles/standardStyles';
import Helmet from 'react-helmet';

export const Publications = () => {
  const standardClasses = useStandardStyles();

  const { data: startingDocs } = useGetPublicationsDocumentsQuery({
    page: 0,
    size: -1,
    sortDirection: 1,
    sortField: '_id',
  });

  return (
    <div className={standardClasses.centeredAlignedContainer}>
      <Helmet>
        <title>Documentation</title>
      </Helmet>
      <Grid container justifyContent="flex-start">
        <Typography
          gutterBottom
          style={{ marginTop: '32px', fontSize: 32, fontWeight: 'bold' }}
        >
          Documentation
        </Typography>
      </Grid>
      <Paper className={standardClasses.paper}>
        <Grid container justifyContent="flex-start"></Grid>
        <Grid item xs={12} md={12} style={{ padding: 16}}>
          <DocumentCardList
            documents={startingDocs?.data[0].documents ?? []}
            setDocuments={() => {}}
            availableMenuOptions={[DocumentMenuOption.DOWNLOAD, DocumentMenuOption.VIEW]}
            showType={false}
            showVisibility={false}
            isPublication
          />
        </Grid>
      </Paper>
    </div>
  );
};
