import React from 'react'
import SvgIcon from '@material-ui/core/SvgIcon'

export const TransactionDownIcon = props => {
    return (
        <SvgIcon {...props} viewBox="0 0 24 24" x="0px" y="0px" baseProfile="tiny" xmlSpace="preserve">
            <path
                fill="#FFFFFF"
                d="M12,0C5.4,0,0,5.4,0,12s5.4,12,12,12s12-5.4,12-12C24,5.4,18.7,0,12,0
				C12,0,12,0,12,0z M16.3,12.9l-3.8,3.8c-0.1,0.1-0.1,0.1-0.2,0.1c-0.1,0-0.1,0.1-0.1,0.1c-0.1,0-0.2,0-0.3,0.1c-0.1,0-0.2,0-0.3-0.1
				l-0.1-0.1c-0.1-0.1-0.2-0.1-0.2-0.1l-3.8-3.8c-0.3-0.3-0.4-0.8-0.1-1.1c0.3-0.3,0.8-0.4,1.1-0.1c0,0,0.1,0.1,0.1,0.1l3.2,3.2
				l3.2-3.2c0.3-0.3,0.8-0.3,1.1,0c0,0,0,0,0,0C16.6,12,16.6,12.4,16.3,12.9C16.3,12.8,16.3,12.8,16.3,12.9L16.3,12.9z M16.3,8.6
				l-3.8,3.8c-0.1,0.1-0.1,0.1-0.1,0.1c-0.1,0.1-0.1,0.1-0.2,0.1c-0.1,0-0.2,0-0.3,0.1c-0.1,0-0.2,0-0.3-0.1c-0.1,0-0.1-0.1-0.1-0.1
				c-0.1-0.1-0.1-0.1-0.2-0.1L7.7,8.6C7.3,8.3,7.3,7.8,7.5,7.4C7.8,7.1,8.3,7,8.7,7.3c0,0,0.1,0.1,0.1,0.1l3.2,3.2l3.2-3.2
				c0.3-0.3,0.8-0.3,1.1,0c0,0,0,0,0,0C16.6,7.7,16.6,8.2,16.3,8.6C16.3,8.5,16.3,8.5,16.3,8.6L16.3,8.6z"
            />
        </SvgIcon>
    )
}
