import { User, createFetchActions, FetchActions } from '@syncfab/machine'

export const SIGNUP_EMAIL_VALIDATION: FetchActions<{ email }, string> = createFetchActions('SIGNUP_EMAIL_VALIDATION')

export const SIGNUP_SUCESSFUL_ACTION_TYPE: string = 'SIGNUP_SUCESSFUL'
export const SIGNUP_FAILED_ACTION_TYPE: string = 'SIGNUP_FAILED'
export const SIGNUP_ACTION_TYPE: string = 'SIGNUP'
export const RESEND_VALIDATION_SUCESSFUL_ACTION_TYPE: string = 'RESEND_VALIDATION_SUCESSFUL'
export const RESEND_VALIDATION_FAILED_ACTION_TYPE: string = 'RESEND_VALIDATION_FAILED'
export const RESEND_VALIDATION_ACTION_TYPE: string = 'RESEND_VALIDATION'
export const ACTIVATE_USER_ACTION_TYPE: string = 'ACTIVATE_USER'
export const ACTIVATE_USER_SUCESSFUL_ACTION_TYPE: string = 'ACTIVATE_USER_SUCESSFUL'
export const ACTIVATE_USER_FAILED_ACTION_TYPE: string = ' ACTIVATE_USER_FAILED'
export const RESEND_VERIFICATION_EMAIL_ACTION_TYPE: string = 'RESEND_VERIFICATION_EMAIL_ACTION_TYPE'
export const RESEND_VERIFICATION_EMAIL_SUPPLIER_ACTION_TYPE: string = 'RESEND_VERIFICATION_EMAIL_SUPPLIER_ACTION_TYPE'

export type SignupData = {
    email: string
    password: string
    name: string
    industry: string
    firstName: string
    lastName: string
    title: string
    phone: string
    size: string
    heardAboutUs: string
}

export type SignupAction = {
    type: string
    data: SignupData
}

export type ActivationAction = {
    type: string
    token: string
}

export const SIGNUP = (SignupData: SignupData): SignupAction => {
    return {
        type: SIGNUP_ACTION_TYPE,
        data: {
            ...SignupData,
        },
    }
}

export const SIGNUP_SUCESSFUL = (user: User) => {
    return {
        user,
        type: SIGNUP_SUCESSFUL_ACTION_TYPE,
    }
}

export const SIGNUP_FAILED = error => {
    return {
        type: SIGNUP_FAILED_ACTION_TYPE,
        error,
    }
}

export const RESEND_VALIDATION = (userId: string) => {
    return {
        type: RESEND_VALIDATION_ACTION_TYPE,
        data: {
            userId,
        },
    }
}
export const RESEND_VERIFICATION_EMAIL_USER = (email: string) => {
    return {
        type: RESEND_VERIFICATION_EMAIL_ACTION_TYPE,
        data: {
            email,
        },
    }
}
export const RESEND_VERIFICATION_EMAIL_SUPPLIER = (email: string) => {
    return {
        type: RESEND_VERIFICATION_EMAIL_SUPPLIER_ACTION_TYPE,
        data: {
            email,
        },
    }
}

export const RESEND_VALIDATION_SUCESSFUL = () => {
    return {
        type: RESEND_VALIDATION_SUCESSFUL_ACTION_TYPE,
    }
}

export const RESEND_VALIDATION_FAILED = error => {
    return {
        type: RESEND_VALIDATION_FAILED_ACTION_TYPE,
        error,
    }
}

export const ACTIVATE_USER = (token): ActivationAction => {
    return {
        type: ACTIVATE_USER_ACTION_TYPE,
        token,
    }
}

export const ACTIVATE_USER_SUCESSFUL = () => {
    return {
        type: ACTIVATE_USER_SUCESSFUL_ACTION_TYPE,
    }
}

export const ACTIVATE_USER_FAILED = error => {
    return {
        type: ACTIVATE_USER_FAILED_ACTION_TYPE,
        error,
    }
}
