import React, { useEffect, useState } from 'react';
import { Document, Page, pdfjs } from 'react-pdf';
import {
  Typography,
  Dialog,
  Theme,
  AppBar,
  Toolbar,
  IconButton,
  Grid,
  Box,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import ArrowBackIos from '@material-ui/icons/ArrowBackIos';
import ArrowForwardIos from '@material-ui/icons/ArrowForwardIos';
import { ProgressBar } from '../ui';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import { retrieveDocument, retrievePublication } from './DocumentServices';
import { useSelector } from 'react-redux';
import { PageCallback } from 'react-pdf/dist/cjs/shared/types';

pdfjs.GlobalWorkerOptions.workerSrc = require('pdfjs-dist/build/pdf.worker.entry');

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    paper: {
      width: '90%',
      minWidth: 300,
      padding: theme.spacing(1),
      marginTop: theme.spacing(6),
      marginBottom: theme.spacing(4),
      background: 'transparent',
      boxShadow: 'none',
      justifyContent: 'center',
    },
    container: {
      marginTop: theme.spacing(6),
    },
    pdfCanvas: {
      '& .react-pdf__Page__canvas': {
        margin: 'auto',
        width: '95% !important',
        height: '95% !important',
      },
    },
    pdfDocument: {
      marginTop: theme.spacing(3),
    },
    pdfContainer: {
      minHeight: 400,
    },
  })
);

interface PDFViewerProps {
  documentId: string;
  entity?: string;
  entityId?: string;
  open: boolean;
  onClose: () => void;
  isPublication?: boolean;
}

export const PDFViewer = ({
  open,
  onClose,
  documentId,
  entity = '',
  entityId = '',
  isPublication = false,
}: PDFViewerProps) => {
  const classes = useStyles();

  const token = useSelector((state: any) => state.auth.authToken);

  const [numPages, setNumPages] = useState<number>(0);
  const [pageNumber, setPageNumber] = useState<number>(1);
  const [scale, setScale] = useState<number>(1.0);
  const [isDocLoading, setIsDocLoading] = useState<boolean>(false);
  const [docContents, setDocContents] = useState(null);

  useEffect(() => {
    if (documentId) {
      setIsDocLoading(true);

      const retrieveDocumentToView = async () => {
        const docHref = isPublication
          ? await retrievePublication(documentId, token)
          : await retrieveDocument(documentId, token, entity, entityId);
        setDocContents(docHref);
        setIsDocLoading(false);
      };

      retrieveDocumentToView().catch(error => {
        console.error(error);
        setIsDocLoading(false);
      });
    }
  }, [documentId]);

  const onDocumentLoadSuccess = ({ numPages }) => {
    setNumPages(numPages);
  };

  const onPageLoad = (page: PageCallback) => {
    const parentDiv = document.querySelector('#pdfDocument');
    let pageScale = parentDiv?.clientWidth / page?.originalWidth;
    if (scale !== pageScale) {
      setScale(pageScale);
    }
  };

  function changePage(offset: number) {
    setPageNumber(prevPageNumber => prevPageNumber + offset);
  }

  function previousPage() {
    if (pageNumber > 1) {
      changePage(-1);
    }
  }

  function nextPage() {
    if (pageNumber < numPages) {
      changePage(1);
    }
  }

  const onViewerClose = () => {
    setDocContents(null);
    setNumPages(0);
    setPageNumber(1);
    setScale(1.0);
    onClose();
  };

  return (
    <Dialog fullScreen open={open} onClose={onClose}>
      <AppBar style={{ position: 'fixed', marginBottom: '100px' }}>
        <Toolbar>
          <Grid container justifyContent="flex-start">
            <Grid item xs={1} md={1}>
              <IconButton
                edge="start"
                color="inherit"
                onClick={() => {
                  onViewerClose();
                }}
                aria-label="close"
              >
                <CloseIcon />
              </IconButton>
            </Grid>
            <Grid container justifyContent="center" item xs={10} md={10}>
              {numPages > 0 && (
                <Box display="flex">
                  <IconButton
                    color="inherit"
                    onClick={previousPage}
                    aria-label="previous"
                  >
                    <ArrowBackIos />
                  </IconButton>
                  <Typography
                    variant="body1"
                    style={{
                      color: '#FFFFFF',
                      fontWeight: 'bold',
                      marginTop: '12px',
                    }}
                  >
                    Page {pageNumber} of {numPages}
                  </Typography>
                  <IconButton
                    color="inherit"
                    onClick={nextPage}
                    aria-label="next"
                    style={{ marginLeft: '4px' }}
                  >
                    <ArrowForwardIos />
                  </IconButton>
                </Box>
              )}
            </Grid>
            <Grid item xs={1} md={1}>
              &nbsp;
            </Grid>
          </Grid>
        </Toolbar>
      </AppBar>
      <div className={classes.container}>
        {docContents ? (
          <div id="pdfDocument" className={classes.pdfDocument}>
            <Document
              file={docContents}
              onLoadSuccess={onDocumentLoadSuccess}
              onLoadError={console.error}
            >
              <Page
                key={`page_${pageNumber}`}
                pageNumber={pageNumber}
                renderAnnotationLayer={false}
                renderTextLayer={false}
                onLoadSuccess={page => {
                  onPageLoad(page);
                }}
                scale={scale}
              />
            </Document>
          </div>
        ) : isDocLoading ? (
          <ProgressBar className={classes.pdfContainer} />
        ) : (
          <Typography
            gutterBottom
            variant="h5"
            style={{
              margin: 0,
              position: 'absolute',
              top: ' 50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
            }}
          >
            Something went wrong. Please try again
          </Typography>
        )}
      </div>
    </Dialog>
  );
};
