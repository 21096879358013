import React, { FC } from "react";
import { Chip, useTheme } from "@material-ui/core";
import { getPOStatusBackgroundColor, PurchaseOrderStatus } from "../PurchaseOrder";


interface PurchaseOrderStatusChipProps {
    status: PurchaseOrderStatus
}
export const PurchaseOrderStatusChip: FC<PurchaseOrderStatusChipProps> = ((props) => {

    const { status } = props
    const theme = useTheme()

    return (
        <Chip
            style={{ width: '100%', color: '#fff', backgroundColor: getPOStatusBackgroundColor(status, theme)}}
            size="small"
            label={status}
        />
    )
})