import { Button, Grid, Typography } from "@material-ui/core";
import React, { FC } from "react";
import { object, SchemaOf, string } from "yup";
import { FormProvider, SubmitHandler, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { RHFTextField, SHOW_FEEDBACK, useDeclineQuoteMutation } from "@syncfab/machine";
import { useAppDispatch } from "../../../../application/hooks";

interface DeclineQuoteProps {
    quoteId: string
    cancel: () => void
    complete: () => void
}
interface DeclineQuoteFormProps {
    declineReason: string
}

const declineQuoteFormSchema: SchemaOf<DeclineQuoteFormProps> = object({
    declineReason: string()
})

export const DeclineQuote: FC<DeclineQuoteProps> = ({ cancel, complete, quoteId }) => {

    const dispatch = useAppDispatch()

    const [declineQuote] = useDeclineQuoteMutation()

    const methods = useForm<DeclineQuoteFormProps>({
        resolver: yupResolver(declineQuoteFormSchema, { stripUnknown: true }),
        defaultValues:  {
            declineReason: ''
        },
    });

    const onSubmit: SubmitHandler<DeclineQuoteFormProps> = async (declineQuoteData: DeclineQuoteFormProps) => {
        try {
            await declineQuote({ quoteId, declineReason: declineQuoteData.declineReason }).unwrap()
            complete()
            dispatch(SHOW_FEEDBACK('Your quote was declined successfully.'))
        } catch(error) {
            dispatch(SHOW_FEEDBACK(error.data))
        }
    }

    return (
        <FormProvider {...methods} >
            <Typography style={{ fontSize: 22, fontWeight: 600, paddingTop: '8px', paddingBottom: '16px' }}>
                Decline Quote
            </Typography>
            <Typography style={{ fontSize: 14, paddingBottom: '24px' }}>
                Kindly share your feedback regarding the reasons for declining this quote, as it will assist us in improving our future quotes to you.
            </Typography>
            <RHFTextField
                name='declineReason'
                label='Enter feedback...'
                multiline
                minRows={4}
            />
            <Grid container justifyContent='flex-end' style={{ paddingTop: '16px', paddingBottom:'16px' }}>
                <Button variant="text" style={{ color: "#40B4BF" }} onClick={() => cancel()}>
                    CANCEL
                </Button>
                <Button
                    type='submit'
                    variant="contained"
                    style={{ backgroundColor: '#40B4BF', color: '#FFFFFF', marginLeft: '16px' }}
                    onClick={methods.handleSubmit(onSubmit)}
                >
                    DECLINE QUOTE
                </Button>
            </Grid>
        </FormProvider>
    )
}