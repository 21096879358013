import { Map } from 'immutable'
import { EMAIL_REGEX, PASSWORD_REGEX } from './Regex'

export const requiredTextField = (value: string, maxLength?: number): string => {
    if (!value) {
        return 'Required'
    } else if (!!maxLength && value.length > maxLength) {
        return `Maximum length is ${maxLength}`
    }
    return null
}

export const requiredTextFields = (values: Map<string, string>, fields: string[], maxLengths?: { [key: string]: number }): { [key: string]: string } => {
    const errors = {}
    fields.forEach(field => {
        const value = values.get(field)
        const result = requiredTextField(value, maxLengths[field])
        result && (errors[field] = result)
    })
    return errors
}

export const requiredEmailField = (value: string): string => {
    if (!value) {
        return 'Required'
    } else if (!EMAIL_REGEX.test(value || '')) {
        return 'Invalid email'
    }
    return null
}

export const requiredPasswordField = (value: string): string => {
    if (!value) {
        return 'Required'
    } else if (!PASSWORD_REGEX.test(value || '')) {
        return 'Passwords must contain at least eight characters.  They must also include at least 1 lower case character, 1 upper case character, 1 number and 1 special character.'
    }
    return null
}
