import { put, cancel } from 'redux-saga/effects'
import { LOGOUT } from './Actions'
import { PUSH } from '../router'

const handleError = (status: number) => {
    return function* handle() {
        if (status === 401) {
            yield put(LOGOUT())
            yield put(PUSH('/login'))
            yield cancel()
        }
        if (status === 404) {
            yield put(PUSH('/'))
            yield cancel()
        }
    }
}

export { handleError }
