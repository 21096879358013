import { PartDAPP } from "../../part";
import { Activity, UserIdAndName } from "../../BaseTypes";
import { ValueLabelOption } from "../../ui";
import { Document } from "../../document/Document"

const shippingMethods = [
    'FedEx Overnight',
    'FedEx Priority Overnight',
    'FedEx Standard Overnight',
    'FedEx Two Day AM',
    'FedEx Two Day',
    'FedEx Express Saver',
    'FedEx Ground',
    'FedEx First Freight',
    'FedEx Freight Economy',
    'FedEx Freight Priority',
    'UPS Express Critical',
    'UPS Next Day Air Early',
    'UPS Next Day Air',
    'UPS Next Day Air Saver',
    'UPS Second Day Air AM',
    'UPS Second Day Air',
    'UPS Three Day Select',
    'UPS Ground',
    'UPS Ground with Freight Pricing',
    'UPS Hundredweight Service',
    'UPS Mail Innovations',
    'Delivered by Supplier',
    'Local Courier',
    'Vendor Shipment Module'
] as const

export type ShippingMethod = typeof shippingMethods[number]

export const ShippingMethodOptions: ValueLabelOption[] = shippingMethods.map(sm => { return { value: sm, label: sm }})

export interface ShipmentLineItemInput {
    poLineItemId: string,
    shippedQuantity: number,
    receivedQuantity: number
}

export interface ShipmentLineItem extends ShipmentLineItemInput{
    partId: string
}

export interface ShipmentLineItemDetail extends ShipmentLineItem {
    poLineItem: {
        poLIneItemId: string
        orderQuantity: number
        shippedQuantity: number
    }
    part: PartDAPP
}

export interface ShipmentInput {
    poId: string
    trackingNumber: string
    shippingMethod: ShippingMethod
    shipmentLineItems: ShipmentLineItemInput[]
    internalNotes: string
    externalNotes: string
    documentIds: string[]
}

export interface Shipment extends ShipmentInput {
    _id: string
    supplierPOId: string
    shipmentDate: Date
    selectedSupplier: {
        supplierId: string
        supplierName: string
    }
    activity: Activity[]
    createdAt: Date
    createdBy: UserIdAndName
    updatedAt?: Date
    updatedBy?: UserIdAndName
    deletedAt?: Date
    deletedBy?: UserIdAndName
}

export interface ShipmentDetails extends Shipment {
    documents: Document[]
    shipmentLineItems: ShipmentLineItemDetail[]
    totalQuantityShipped: number,
    lineItemCount: number
}