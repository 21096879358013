import React from 'react'
import { Field, reduxForm } from 'redux-form/immutable'
import { InjectedFormProps } from 'redux-form'
import { Map } from 'immutable'
import { WithStyles, withStyles } from '@material-ui/core/styles'
import { withNamespaces, WithNamespaces } from 'react-i18next'
import { FormTextField, Button, jumpToFirstError, FormPasswordField } from '@syncfab/machine'
import { EMAIL_REGEX } from '../../../utils/Regex'

export const LoginFormName: string = 'LoginForm'

const styles = theme => ({
    form: {
        display: 'flex',
        'flex-direction': 'column',
        maxWidth: 600,
        minWidth: 300,
        margin: 'auto',
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(1),
    },
    action: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
    },
    formInput: {
        '& .MuiFormControl-marginNormal': {
            margin: 0,
        },
    },
})

type LoginFields = {
    email?: string
    password?: string
}

const validate = (data: Map<string, string>): {} => {
    const errors: LoginFields = {}
    // TODO (JR): Unify
    if (!data.get('email')) {
        errors.email = 'Required'
    } else if (!EMAIL_REGEX.test(data.get('email') || '')) {
        errors.email = 'Improperly formatted email'
    }

    if (!data.get('password')) {
        errors.password = 'Required'
    } else if (!/^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*\_\\\-+\/,`~.?<>():;=\|])(?=.{8,})/.test(data.get('password') || '')) {
        errors.password = 'Invalid Password'
    }

    return errors
}

const form = (props: InjectedFormProps<{}, {}> & WithStyles & WithNamespaces) => {
    const { handleSubmit, submitting, t, classes } = props
    return (
        <form onSubmit={handleSubmit} className={classes.form}>
            <Field name="email" component={FormTextField} type="email" placeholder={t('login-form-email')} className={classes.formInput} fullWidth />
            {/* Redux-form typings are not very good */}
            <Field name="password" component={FormPasswordField as any} placeholder={t('login-form-password')} className={classes.formInput} fullWidth />
            <Button type="submit" color="primary" disabled={submitting} className={classes.action} fullWidth>
                {t('login-form-login')}
            </Button>
        </form>
    )
}

const translatedForm = withNamespaces()(withStyles(styles)(form))
export const LoginForm = reduxForm<{}, {}>({
    form: LoginFormName,
    validate,
    onSubmitFail: jumpToFirstError,
})(translatedForm)
