export const Languages = [
    {
        name: 'Abkhazian',
        code: 'AB',
    },
    {
        name: 'Afar',
        code: 'AA',
    },
    {
        name: 'Afrikaans',
        code: 'AF',
    },
    {
        name: 'Albanian',
        code: 'SQ',
    },
    {
        name: 'Amharic',
        code: 'AM',
    },
    {
        name: 'Arabic',
        code: 'AR',
    },
    {
        name: 'Armenian',
        code: 'HY',
    },
    {
        name: 'Assamese',
        code: 'AS',
    },
    {
        name: 'Aymara',
        code: 'AY',
    },
    {
        name: 'Azerbaijani',
        code: 'AZ',
    },
    {
        name: 'Bashkir',
        code: 'BA',
    },
    {
        name: 'Basque',
        code: 'EU',
    },
    {
        name: 'Bengali (Bangla)',
        code: 'BN',
    },

    {
        name: 'Bhutani',
        code: 'DZ',
    },
    {
        name: 'Bihari',
        code: 'BH',
    },

    {
        name: 'Bislama',
        code: 'BI',
    },

    {
        name: 'Breton',
        code: 'BR',
    },

    {
        name: 'Bulgarian',
        code: 'BG',
    },

    {
        name: 'Burmese',
        code: 'MY',
    },

    {
        name: 'Byelorussian (Belarusian)',
        code: 'BE',
    },

    {
        name: 'Cambodian',
        code: 'KM',
    },
    {
        name: 'Catalan',
        code: 'CA',
    },
    {
        name: 'Chinese(Simplified)',
        code: 'ZH',
    },
    {
        name: 'Chinese(Traditional)',
        code: 'ZH',
    },
    {
        name: 'Corsican',
        code: 'CO',
    },
    {
        name: 'Croatian',
        code: 'HR',
    },
    {
        name: 'Czech',
        code: 'CS',
    },
    {
        name: 'Danish',
        code: 'DA',
    },
    {
        name: 'Dutch',
        code: 'NL',
    },
    {
        name: 'English',
        code: 'EN',
    },
    {
        name: 'Esperanto',
        code: 'EO',
    },
    {
        name: 'Estonian',
        code: 'ET',
    },
    {
        name: 'Faeroese',
        code: 'FO',
    },
    {
        name: 'Farsi',
        code: 'FA',
    },
    {
        name: 'Fiji',
        code: 'FJ',
    },
    {
        name: 'Finnish',
        code: 'FI',
    },
    {
        name: 'French',
        code: 'FR',
    },
    {
        name: 'Frisian',
        code: 'FY',
    },
    {
        name: 'Galician',
        code: 'GL',
    },
    {
        name: 'Gaelic(Scottish)',
        code: 'GD',
    },
    {
        name: 'Gaelic(Manx)',
        code: 'GV',
    },
    {
        name: 'Georgian',
        code: 'KA',
    },
    {
        name: 'German',
        code: 'DE',
    },
    {
        name: 'Greek',
        code: 'EL',
    },
    {
        name: 'Greenlandic',
        code: 'KL',
    },
    {
        name: 'Guarani',
        code: 'GN',
    },
    {
        name: 'Gujarati',
        code: 'GU',
    },
    {
        name: 'Hausa',
        code: 'HA',
    },
    {
        name: 'Hebrew',
        code: 'HE',
    },
    {
        name: 'Hindi',
        code: 'HI',
    },
    {
        name: 'Hungarian',
        code: 'HU',
    },
    {
        name: 'Icelandic',
        code: 'IS',
    },
    {
        name: 'Indonesian',
        code: 'ID',
    },
    {
        name: 'Interlingua',
        code: 'IA',
    },
    {
        name: 'Interlingue',
        code: 'IE',
    },
    {
        name: 'Inuktitut',
        code: 'IU',
    },

    {
        name: 'Inupiak',
        code: 'IK',
    },

    {
        name: 'Irish',
        code: 'GA',
    },
    {
        name: 'Italian',
        code: 'IT',
    },

    {
        name: 'Japanese',
        code: 'JA',
    },
    {
        name: 'Javanese',
        code: 'JA',
    },
    {
        name: 'Kannada',
        code: 'KN',
    },
    {
        name: 'Kashmiri',
        code: 'KS',
    },
    {
        name: 'Kazakh',
        code: 'KK',
    },
    {
        name: 'Kinyarwanda(Ruanda)',
        code: 'RW',
    },
    {
        name: 'Kirghiz',
        code: 'KY',
    },
    {
        name: 'Kirundi(Rundi)',
        code: 'RN',
    },
    {
        name: 'Korean',
        code: 'KO',
    },
    {
        name: 'Kurdish',
        code: 'KU',
    },
    {
        name: 'Laothian',
        code: 'LO',
    },
    {
        name: 'Latin',
        code: 'LA',
    },
    {
        name: 'Latvian(Lettish)',
        code: 'LV',
    },
    {
        name: 'Limburgish(Limburger)',
        code: 'LI',
    },
    {
        name: 'Lingala',
        code: 'LN',
    },
    {
        name: 'Lithuanian',
        code: 'LT',
    },
    {
        name: 'Macedonian',
        code: 'MK',
    },
    {
        name: 'Malagasy',
        code: 'MG',
    },
    {
        name: 'Malay',
        code: 'MS',
    },
    {
        name: 'Malayalam',
        code: 'ML',
    },
    {
        name: 'Maltese',
        code: 'MT',
    },
    {
        name: 'Maori',
        code: 'MI',
    },
    {
        name: 'Marathi',
        code: 'MR',
    },
    {
        name: 'Moldavian',
        code: 'MO',
    },
    {
        name: 'Mongolian',
        code: 'MN',
    },
    {
        name: 'Nauru',
        code: 'NA',
    },
    {
        name: 'Nepali',
        code: 'NE',
    },
    {
        name: 'Norwegian',
        code: 'NO',
    },
    {
        name: 'Occitan',
        code: 'OC',
    },
    {
        name: 'Oriya',
        code: 'OR',
    },
    {
        name: 'Oromo(Afan, Galla)',
        code: 'OM',
    },
    {
        name: 'Pashto(Pushto)',
        code: 'PS',
    },
    {
        name: 'Polish',
        code: 'PL',
    },
    {
        name: 'Portuguese',
        code: 'PT',
    },
    {
        name: 'Punjabi',
        code: 'PA',
    },
    {
        name: 'Quechua',
        code: 'QU',
    },
    {
        name: 'Rhaeto - Romance',
        code: 'RM',
    },
    {
        name: 'Romanian',
        code: 'RO',
    },
    {
        name: 'Russian',
        code: 'RU',
    },
    {
        name: 'Samoan',
        code: 'SM',
    },
    {
        name: 'Sangro',
        code: 'SG',
    },
    {
        name: 'Sanskrit',
        code: 'SA',
    },
    {
        name: 'Serbian',
        code: 'SR',
    },
    {
        name: 'Serbo - Croatian',
        code: 'SH',
    },
    {
        name: 'Sesotho',
        code: 'ST',
    },

    {
        name: 'Setswana',
        code: 'TN',
    },
    {
        name: 'Shona',
        code: 'SN',
    },
    {
        name: 'Sindhi',
        code: 'SD',
    },
    {
        name: 'Sinhalese',
        code: 'SI',
    },
    {
        name: 'Siswati',
        code: 'SS',
    },
    {
        name: 'Slovak',
        code: 'SK',
    },
    {
        name: 'Slovenian',
        code: 'SL',
    },
    {
        name: 'Somali',
        code: 'SO',
    },
    {
        name: 'Spanish',
        code: 'ES',
    },
    {
        name: 'Sundanese',
        code: 'SU',
    },

    {
        name: 'Swahili(Kiswahili)',
        code: 'SW',
    },
    {
        name: 'Swedish',
        code: 'SV',
    },
    {
        name: 'Tagalog',
        code: 'TL',
    },
    {
        name: 'Tajik',
        code: 'TG',
    },
    {
        name: 'Tamil',
        code: 'TA',
    },
    {
        name: 'Tatar',
        code: 'TT',
    },
    {
        name: 'Telugu',
        code: 'TE',
    },
    {
        name: 'Thai',
        code: 'TH',
    },
    {
        name: 'Tibetan',
        code: 'BO',
    },
    {
        name: 'Tigrinya',
        code: 'TI',
    },
    {
        name: 'Tonga',
        code: 'TO',
    },
    {
        name: 'Tsonga',
        code: 'TS',
    },
    {
        name: 'Turkish',
        code: 'TR',
    },
    {
        name: 'Turkmen',
        code: 'TK',
    },
    {
        name: 'Twi',
        code: 'TW',
    },
    {
        name: 'Uighur',
        code: 'UG',
    },
    {
        name: 'Ukrainian',
        code: 'UK',
    },
    {
        name: 'Urdu',
        code: 'UR',
    },
    {
        name: 'Uzbek',
        code: 'UZ',
    },
    {
        name: 'Vietnamese',
        code: 'VI',
    },
    {
        name: 'Volapük',
        code: 'VO',
    },
    {
        name: 'Welsh',
        code: 'CY',
    },
    {
        name: 'Wolof',
        code: 'WO',
    },
    {
        name: 'Xhosa',
        code: 'XH',
    },
    {
        name: 'Yiddish',
        code: 'YI',
    },
    {
        name: 'Yoruba',
        code: 'YO',
    },
    {
        name: 'Zulu',
        code: 'ZU',
    },
]
