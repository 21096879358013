import { Avatar, Box, Button, Card, Grid, withStyles } from '@material-ui/core'
import { WithStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import { BuyerIcon, SupplierIcon } from '@syncfab/machine'
import React from 'react'
import { withNamespaces, WithNamespaces } from 'react-i18next'
import { NavLink, RouteComponentProps, withRouter } from 'react-router-dom'
import { CenteredContainer } from '../../layout/Container'

const styles = theme => ({
    card: {
        width: '80%',
        margin: 'auto',
        padding: theme.spacing(3),
        height: '100%',
    },
    title: {
        color: theme.palette.common.white,
        marginLeft: 12,
        fontSize: theme.spacing(3),
    },
    error: {
        color: theme.palette.error.main,
    },
    signupLink: {
        margin: '0px',
        padding: '0px',
        textDecoration: 'none',
        color: theme.palette.primary.light,
        'font-weight': 'bold',
    },
    itemCenter: {
        'text-align': 'center',
    },
    navLink: {
        textDecoration: 'none',
    },
    loginIcon: {
        fontSize: theme.spacing(2),
    },
    supplierloginIcon: {
        fontSize: theme.spacing(2),
        color: theme.palette.common.white,
    },
    small: {
        width: theme.spacing(4),
        height: theme.spacing(4),
        backgroundColor: theme.palette.background.default,
    },
    '@media (max-width:600px)': {
        item: {
            marginBottom: theme.spacing(2),
        },
    },
})

interface LoginSectionProps extends WithNamespaces, WithStyles, RouteComponentProps {}

class LoginSecionView extends React.Component<LoginSectionProps> {
    public render() {
        const { classes, t } = this.props
        return (
            <CenteredContainer>
                <Grid container direction="row">
                    <Grid item xs md={2} />
                    <Grid item xs={12} sm={6} md={4} className={classes.item}>
                        <Card className={classes.card}>
                            <Grid container direction="column" spacing={2}>
                                <Grid item>
                                    <Box style={{ display: 'flex' }}>
                                        <Avatar className={classes.small}>
                                            <BuyerIcon className={classes.loginIcon} />
                                        </Avatar>
                                        <Typography variant="h2" gutterBottom className={classes.title}>
                                            {`${t('buyer-login-title')}`}
                                        </Typography>
                                    </Box>
                                </Grid>
                                <Grid item>
                                    <NavLink to="/user/login" className={classes.navLink}>
                                        <Button color="primary" variant="contained" fullWidth>
                                            {`${t('login-form-login')}`}
                                        </Button>
                                    </NavLink>
                                </Grid>
                                <Grid item>
                                    <Typography variant="caption" className={classes.itemCenter}>
                                        {`${t('buyer-login-sing-up-text')}`}
                                        <a href="https://syncfab.com/book-a-demo/" target="_blank" className={classes.signupLink}>
                                            {' ' + 'Contact Us'}
                                        </a>
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Card>
                    </Grid>
                    <Grid item xs={12} sm={6} md={4}>
                        <Card className={classes.card}>
                            <Grid container direction="column" spacing={2}>
                                <Grid item>
                                    <Box style={{ display: 'flex' }}>
                                        <Avatar className={classes.small}>
                                            <SupplierIcon className={classes.supplierloginIcon} />
                                        </Avatar>
                                        <Typography variant="h2" gutterBottom className={classes.title}>
                                            {`${t('supplier-login-title')}`}
                                        </Typography>
                                    </Box>
                                </Grid>
                                <Grid item>
                                    <NavLink to="/suppliers/login" className={classes.navLink}>
                                        <Button color="primary" variant="contained" fullWidth>
                                            {`${t('login-form-login')}`}
                                        </Button>
                                    </NavLink>
                                </Grid>
                                <Grid item>
                                    <Typography variant="caption" className={classes.itemCenter}>
                                        {`${t('supplier-login-sing-up-text')}`}
                                        <a href="https://syncfab.com/book-a-demo/" target="_blank" className={classes.signupLink}>
                                            {' ' + 'Contact Us'}
                                        </a>
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Card>
                    </Grid>
                    <Grid item xs md={2} />
                </Grid>
            </CenteredContainer>
        )
    }
}

const LoginSelection = withNamespaces()(withStyles(styles)(withRouter(LoginSecionView)))
export default LoginSelection
