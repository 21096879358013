import React, { Dispatch, FC, SetStateAction, useState } from 'react';
import {
  Card,
  Grid,
  GridSize,
  IconButton,
  Menu,
  MenuItem,
  TextField,
  Theme,
  Typography,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import InsertDriveFile from '@material-ui/icons/InsertDriveFile';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import { downloadDocument, downloadPublication } from './DocumentServices';
import { PDFViewer } from './PDFViewer';
import {
  bytesForHuman,
  Document,
  DocumentMenuOption,
  DocumentVisibility,
} from './Document';
import {
  AutoCompleteValueLabel,
  findSingleSelectedValueLabelOption,
  generateOptionsFromEnum,
  ValueLabelOption,
} from '../ui';
import { useDispatch, useSelector } from 'react-redux';
import { useUpdateDocumentMutation } from '../../application';
import { SHOW_FEEDBACK } from '../feedback';
import { LoginRole } from '../user';

const useStyles = makeStyles<Theme>(theme => ({
  documentCard: {
    width: '100%',
    marginBottom: '10px',
    backgroundColor: theme.palette.customComponent.backgroundColor,
    padding: theme.spacing(2),
    position: 'relative',
    border: `1px solid ${theme.palette.customComponent.cardBorderColor}`,
  },
  iconButton: {
    backgroundColor: theme.palette.customComponent.iconBackgroundColor,
    color: theme.palette.customComponent.iconColor,
    borderRadius: '50%',
    padding: '8px',
  },
  fileNameText: {
    color: theme.palette.customComponent.textColor,
    fontWeight: 'bold',
  },
  fileSizeText: {
    color: theme.palette.customComponent.captionTextColor,
  },
  cardAction: {
    position: 'absolute' as any,
    right: 0,
  },
}));

export interface DocumentCardListProps {
  documents: Document[];
  setDocuments: (fct: (documents: Document[]) => Document[]) => void;
  setAreDocumentsDirty?: Dispatch<SetStateAction<boolean>>;
  availableMenuOptions: DocumentMenuOption[];
  showType?: boolean;
  typeOptions?: ValueLabelOption[];
  showVisibility?: boolean;
  showRevision?: boolean;
  handleRemoveDocument?: (docIds: string[], docId: string) => void;
  entity?: string;
  entityId?: string;
  isPublication?: boolean;
}

export const DocumentCardList: FC<DocumentCardListProps> = ({
  documents,
  setDocuments,
  setAreDocumentsDirty = null,
  availableMenuOptions,
  showType = true,
  typeOptions = [],
  showVisibility = true,
  showRevision = false,
  handleRemoveDocument = () => {},
  entity = '',
  entityId = '',
  isPublication = false,
}) => {
  const classes = useStyles();
  const token = useSelector((state: any) => state.auth.authToken);
  const user = useSelector((state: any) => state.user.loggedUser);
  const dispatch = useDispatch();

  const [documentMenuMeta, setDocumentMenuMeta] = useState<{
    docId: string;
    anchorEl: Element;
  }>(null);
  const [documentIdToView, setDocumentIdToView] = useState<string>('');
  const [isPDFViewerOpen, setIsPDFViewerOpen] = useState<boolean>(false);

  const [updateDocument] = useUpdateDocumentMutation();

  const handleDocumentTypeChange = (docId: string, value: ValueLabelOption) => {
    if (!value?.value) {
      return;
    }
    handleDocumentUpdate(docId, { type: value.value });
  };

  const handleDocumentVisibilityChange = (
    docId: string,
    value: ValueLabelOption
  ) => {
    if (!value?.value) {
      return;
    }
    handleDocumentUpdate(docId, { visibility: value.value });
  };

  const handleDocumentRevisionChange = (docId: string, revision: string) => {
    handleDocumentUpdate(docId, { revision });
  };

  const handleDocumentUpdate = async (
    docId: string,
    documentUpdate: Partial<Document>
  ) => {
    setDocuments((docs: Document[]) =>
      docs.map(doc => {
        return doc._id === docId ? { ...doc, ...documentUpdate } : doc;
      })
    );
    //if (!!setAreDocumentsDirty) { setAreDocumentsDirty(true) }
    try {
      const fieldsUpdated = Object.keys(documentUpdate).join(', ');
      updateDocument({ documentId: docId, document: documentUpdate });
      dispatch(SHOW_FEEDBACK(`Document ${fieldsUpdated} updated`));
    } catch (error) {
      dispatch(SHOW_FEEDBACK(error.data));
    }
  };

  const handleDocumentMenuClose = () => {
    setDocumentMenuMeta(null);
  };

  const calculateFileNameGridWidth = (): number => {
    let width = 3;
    if (!showVisibility) {
      width += 2;
    }
    if (!showType) {
      width += 2;
    }
    if (!showRevision) {
      width += 2;
    }
    return width;
  };

  const handleDocumentMenuOption = (
    option: DocumentMenuOption,
    docId: string
  ) => {
    if (option === DocumentMenuOption.VIEW) {
      setDocumentIdToView(docId);
      setIsPDFViewerOpen(true);
    } else if (option === DocumentMenuOption.REMOVE) {
      setDocuments((docs: Document[]) => docs.filter(doc => doc._id !== docId));
      if (!!setAreDocumentsDirty) {
        setAreDocumentsDirty(true);
      }
      // filter on documents to avoid any async issues with state update
      handleRemoveDocument(
        documents.filter(doc => doc._id !== docId).map(doc => doc._id),
        docId
      );
    } else if (option === DocumentMenuOption.DOWNLOAD) {
      isPublication
        ? downloadPublication(docId, token).then()
        : downloadDocument(docId, token, entity, entityId).then();
    }
  };

  return (
    <>
      {documents?.map(doc => (
        <Card className={classes.documentCard} key={doc._id}>
          <Grid container spacing={2} direction="row" alignItems="center">
            <Grid container item xs={1} justifyContent="center">
              <IconButton className={classes.iconButton}>
                <InsertDriveFile />
              </IconButton>
            </Grid>
            <Grid
              container
              item
              xs={calculateFileNameGridWidth() as GridSize}
              direction="column"
              alignItems="flex-start"
            >
              <Typography className={classes.fileNameText}>
                {doc.name ?? ''}
              </Typography>
              <Typography variant="caption" className={classes.fileSizeText}>
                {bytesForHuman(doc.size) ?? ''}
              </Typography>
            </Grid>
            {showRevision && (
              <Grid container item xs={2} justifyContent="center">
                <TextField
                  value={doc.revision}
                  name="revision"
                  label="Revision"
                  required={true}
                  variant="outlined"
                  type="text"
                  onChange={event =>
                    handleDocumentRevisionChange(doc._id, event.target.value)
                  }
                />
              </Grid>
            )}
            {showType && (
              <Grid container item xs={2} justifyContent="center">
                <AutoCompleteValueLabel
                  value={
                    doc.type
                      ? findSingleSelectedValueLabelOption(
                          typeOptions,
                          doc.type
                        )
                      : null
                  }
                  options={typeOptions}
                  name="docType"
                  label="Type"
                  required={true}
                  onChangeHandler={(value: ValueLabelOption) =>
                    handleDocumentTypeChange(doc._id, value)
                  }
                  disableClearable={true}
                />
              </Grid>
            )}
            {showVisibility && (
              <Grid container item xs={2} justifyContent="center">
                <AutoCompleteValueLabel
                  value={
                    doc.visibility
                      ? { value: doc.visibility, label: doc.visibility }
                      : null
                  }
                  options={generateOptionsFromEnum(
                    Object.values(DocumentVisibility)
                  )}
                  name="docVisibility"
                  label="Permissions"
                  required={true}
                  onChangeHandler={(value: ValueLabelOption) =>
                    handleDocumentVisibilityChange(doc._id, value)
                  }
                  disableClearable={true}
                />
              </Grid>
            )}
            <Grid item xs={2} className={classes.cardAction}>
              <IconButton
                aria-label="More"
                aria-owns={open ? 'long-menu' : undefined}
                aria-haspopup="true"
                onClick={event =>
                  setDocumentMenuMeta({
                    docId: doc._id,
                    anchorEl: event.currentTarget,
                  })
                }
              >
                <MoreVertIcon />
              </IconButton>
              <Menu
                id="long-menu"
                anchorEl={documentMenuMeta?.anchorEl}
                open={documentMenuMeta?.docId === doc._id}
                onClose={() => handleDocumentMenuClose()}
                PaperProps={{
                  style: {
                    maxHeight: 48 * 4.5,
                    width: 200,
                  },
                }}
              >
                {availableMenuOptions.map((option, index) => {
                  let disabled = false;
                  if (
                    option === DocumentMenuOption.REMOVE &&
                    !!user &&
                    (user.loginRole === LoginRole.BUYER ||
                      user.loginRole === LoginRole.SUPPLIER) &&
                    doc.visibility === DocumentVisibility.ALL
                  ) {
                    disabled = true;
                  }
                  return (
                    <MenuItem
                      key={index}
                      onClick={() => {
                        handleDocumentMenuClose();
                        handleDocumentMenuOption(option, doc._id);
                      }}
                      disabled={disabled}
                    >
                      {option}
                    </MenuItem>
                  );
                })}
              </Menu>
            </Grid>
          </Grid>
        </Card>
      ))}
      <PDFViewer
        documentId={documentIdToView}
        open={isPDFViewerOpen}
        onClose={() => {
          setIsPDFViewerOpen(false);
          setDocumentIdToView('');
        }}
        entity={entity}
        entityId={entityId}
        isPublication={isPublication}
      />
    </>
  );
};
