import { Address } from './address/Address';
import { User } from '../user';
import { ValueLabelOption } from '../ui';

export enum Industry {
  AEROSPACE_AND_DEFENSE = 'AEROSPACE_AND_DEFENSE',
  AUTOMOTIVE = 'AUTOMOTIVE',
  COMMERCIAL_SPACE = 'COMMERCIAL_SPACE',
  ELECTRONIC_COMPONENTS = 'ELECTRONIC_COMPONENTS',
  LABORATORY_EQUIPMENT = 'LABORATORY_EQUIPMENT',
  MARINE_HARDWARE = 'MARINE_HARDWARE',
  MECHANICAL_COMPONENTS = 'MECHANICAL_COMPONENTS',
  MEDICAL_DEVICES = 'MEDICAL_DEVICES',
  OIL_AND_GAS = 'OIL_AND_GAS',
  RENEWABLE_ENERGY = 'RENEWABLE_ENERGY',
  ROBOTICS = 'ROBOTICS',
  SEMICONDUCTOR = 'SEMICONDUCTOR',
  SPACE_TOURISM = 'SPACE_TOURISM',
  OTHER = 'OTHER',
}

export enum MarketingChannel {
  SEARCH_ENGINE = 'SEARCH_ENGINE',
  SOCIAL_MEDIA = 'SOCIAL_MEDIA ',
  ONLINE_AD = 'ONLINE_AD',
  CONFERENCE_TRADESHOW = 'CONFERENCE_TRADESHOW',
  PRESS_NEWS_ARTICLE = 'PRESS_NEWS_ARTICLE',
  EMAIL_OUTREACH = 'EMAIL_OUTREACH',
  PHONE_CALL = 'PHONE_CALL',
  REFERRED_BY_A_FRIEND = 'REFERRED_BY_A_FRIEND',
  CESMII_GOVERNMENT_REFERRAL = 'CESMII_GOVERNMENT_REFERRAL',
  // I_AM_A_NTMA_MEMBER = 'I_AM_A_NTMA_MEMBER',
  OTHER = 'OTHER',
}

export enum CompanySize {
  SMALL = 'SMALL',
  MEDIUM = 'MEDIUM',
  LARGE = 'LARGE',
  ENTERPRISE = 'ENTERPRISE',
}

export enum CompanyState {
  ACTIVE = 'ACTIVE',
  DEACTIVATED = 'DEACTIVATED',
}

export enum FeaturePackage {
  BASIC = 'Basic',
  SUPPLIER_ANALYTICS = 'Supplier Analytics',
}

export interface CompanyInput {
  name: string;
  email?: string;
  industry: string;
  size: string;
  heardAboutUs: string;
  state: string;
  featurePackage: string[];
  documentIds?: string[];
  notes?: string;
  website?: string;
  riskLevel: string;
  salesperson: {
    userId: string;
  };
  paymentTerms: string;
  inspection: string;
  packaging: string;
  shippingMethod: string;
  shippingAccount: string;
  certifications: string[];
}

export interface Company {
  _id: string;
  name: string;
  email: string;
  industry: Industry;
  userIds: string[];
  size: CompanySize;
  heardAboutUs: MarketingChannel;
  state: CompanyState;
  featurePackage: FeaturePackage[];
  taxId?: string;
  createdAt: Date;
  updatedAt?: Date;
  deletedAt?: Date;
  documentIds?: string[];
  documents?: any[];
  notes?: string;
  website?: string;
  addresses?: Address[];
  users?: User[];
  riskLevel: string;
  salesperson: {
    userId: string;
    userName: string;
  };
  paymentTerms: string;
  inspection: string;
  packaging: string;
  shippingMethod: string;
  shippingAccount: string;
  certifications: string[];
}

export const createFeaturePackageOption = (
  feature: FeaturePackage
): ValueLabelOption => {
  return { value: feature, label: feature };
};

export const createSingleCompanyOption = (
  companyId: string,
  companyName: string
): ValueLabelOption => {
  return { value: companyId, label: `${companyId} - ${companyName}` };
};

export const CompanyStatusMap = new Map<string, string>([
  ['ACTIVE', 'Active'],
  ['DEACTIVATED', 'Deactivated'],
  ['PENDING', 'Pending'],
]);

export const createCompanyOption = (company: Company): ValueLabelOption => {
  return { value: company._id, label: `${company._id} - ${company.name}` };
};

export const RiskLevelOptions: ValueLabelOption[] = [
  {
    value: '1',
    label:
      'Level 1 (Low Risk) - Level 1 (Low Risk) - Commercial Standards Quality/Delivery, First PO Supplier',
  },
  {
    value: '2',
    label:
      'Level 2 (Med Risk) - Flight Ready Standards, Suppliers with PO History only',
  },
  {
    value: '3',
    label:
      'Level 3 (High Risk) - High and Specific Quality Standards, Top suppliers only',
  },
];

export const CompanySizeOptions = [
  {
    value: CompanySize.SMALL,
    label: 'Small (1-20 employees) ',
  },
  {
    value: CompanySize.MEDIUM,
    label: 'Medium (20-99 employees) ',
  },
  {
    value: CompanySize.LARGE,
    label: 'Large (100-499 employees) ',
  },
  {
    value: CompanySize.ENTERPRISE,
    label: 'Enterprise (500+ employees) ',
  },
];

export enum CompanyMetricTimeFrameData {
  ALL = 'ALL',
  LAST_7_DAYS = 'LAST_7_DAYS',
  LAST_30_DAYS = 'LAST_30_DAYS',
  LAST_3_MONTHS = 'LAST_3_MONTHS',
  LAST_6_MONTHS = 'LAST_6_MONTHS',
  LAST_9_MONTHS = 'LAST_9_MONTHS',
  LAST_12_MONTHS = 'LAST_12_MONTHS',
}

export const CompanyMetricTimeFrameOptionDisplayMap = {
  [CompanyMetricTimeFrameData.ALL]: 'All',
  [CompanyMetricTimeFrameData.LAST_7_DAYS]: 'Last 7 Days',
  [CompanyMetricTimeFrameData.LAST_30_DAYS]: 'Last 30 Days',
  [CompanyMetricTimeFrameData.LAST_3_MONTHS]: 'Last 3 Months',
  [CompanyMetricTimeFrameData.LAST_6_MONTHS]: 'Last 6 Months',
  [CompanyMetricTimeFrameData.LAST_9_MONTHS]: 'Last 9 Months',
  [CompanyMetricTimeFrameData.LAST_12_MONTHS]: 'Last 12 Months',
} as const;

export const CompanyMetricTimeFrameDataOptions = Object.keys(
  CompanyMetricTimeFrameOptionDisplayMap
).map(c => {
  return {
    value: c,
    label: CompanyMetricTimeFrameOptionDisplayMap[c],
  };
});
