import React from 'react'
import { Field, reduxForm } from 'redux-form/immutable'
import { InjectedFormProps } from 'redux-form'
import { Map } from 'immutable'
import { WithStyles, withStyles } from '@material-ui/core/styles'
import { withNamespaces, WithNamespaces } from 'react-i18next'
import {
    Address,
    FormTextField,
    Button,
    jumpToFirstError,
    renderCheckbox,
    DateTimePickerField,
    FormAutocompleteV2,
    DocumentType,
    PARTS_ACCEPTED_FILE_TYPES,
    findSelectedSingleOption,
} from '@syncfab/machine'
import { Typography, Grid, Card, IconButton, Box, Paper } from '@material-ui/core'
import { AddressDialog } from '../../../../components/company/address/AddressDialog'
import { InsertDriveFile } from '@material-ui/icons'
import CloseIcon from '@material-ui/icons/Close'
import PartDetailsV2 from './PartDetailsV2'
import FilePondComponent from '../../../../components/document/DocumentComponent'

export const RFQQuoteEditorFormName: string = 'RFQQuoteEditorFormName'

const styles = theme => ({
    paper: {
        width: '100%',
        marginTop: theme.spacing(3),
        padding: '24px',
        paddingTop: theme.spacing(3),
        paddingBottom: theme.spacing(3),
        backgroundColor: theme.palette.background.paper,
    },
    gridItem: {
        paddingTop: '0px !important',
        paddingBottom: '0px !important',
    },
    formInput: {
        '& .MuiFormControl-marginNormal': {
            margin: 0,
        },
    },
    dateGridItem: {
        paddingTop: '0px !important',
        paddingBottom: '0px !important',
    },
    card: {
        width: '100%',
        margin: 0,
        padding: theme.spacing(2),
        position: 'relative' as any,
        border: `1px solid rgba(255, 255, 255, 0.23)`,
        borderWidth: 2,
    },
    fileName: {
        color: theme.palette.common.white,
    },
    icon: {
        width: 25,
        height: 25,
        color: theme.palette.common.white,
        marginTop: theme.spacing(1),
        cursor: 'pointer',
    },
    documentContainer: {
        display: 'flex',
    },
    documentDetails: {
        flexGrow: 1,
        marginLeft: theme.spacing(2),
    },
    fileSize: {
        color: '#D4D5D6',
    },
    detailsHeader: {
        fontSize: '20px',
        letterSpacing: '1.25px',
    },
    // table: {
    //     '&:last-child td, &:last-child th': {
    //         border: 0,
    //     },
    // },
    table: {
        border: `1px solid rgba(255, 255, 255, 0.23)`,
        borderWidth: 2,
    },
    tableRow: {
        '& .MuiTableCell-root': {
            borderBottom: `1px solid rgba(224, 224, 224, 0.23)`,
        },
        '&:last-child th, &:last-child td': {
            borderBottom: 0,
        },
    },
    tableHead: {
        '& .MuiTableCell-root': {
            '&:nth-child(1)': {
                borderLeft: 0,
            },
            borderWidth: 1,
            borderLeft: '1px solid rgba(224, 224, 224, 0.23)',
            borderBottom: `1px solid rgba(224, 224, 224, 0.23) !important`,
        },
    },
    editIcon: {
        minWidth: '30px',
    },
    saveBtn: {
        width: 100,
        minHeight: '36px',
        marginLeft: 16,
        borderWidth: 2,
    },
    button: {
        width: 150,
        minHeight: '36px',
        marginLeft: 16,
        borderWidth: 2,
    },
    tableCellStyle: {
        margin: 0,
        padding: 0,
        flexDirection: 'row' as any,
        '& .MuiOutlinedInput-input': {
            padding: 5,
            paddingLeft: 0,
            margin: 0,
        },
        '& .MuiFormControl-marginNormal': {
            margin: 0,
        },
        '& .MuiTableCell-root': {
            paddingTop: '5px',
            paddingBottom: '5px',
        },
        '& .MuiOutlinedInput-notchedOutline': {
            border: 'none',
        },
    },
    toolbar: {
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.common.white,
        marginBottom: '-5px',
        borderRadius: '5px 5px 0 0',
        width: '100%',
    },
    actions: {
        borderColor: theme.palette.common.white,
        color: theme.palette.common.white,
        margin: theme.spacing(1),
    },
    cardDocument: {
        width: '100%',
        margin: 0,
        backgroundColor: theme.palette.common.white,
        padding: theme.spacing(2),
        textAlign: 'center' as any,
    },
    documentTitle: {
        fontSize: '16px',
        color: theme.palette.common.black,
    },
    documentSubTitle: {
        fontSize: '14px',
        color: theme.palette.common.black,
    },
    checkbox: {
        '& .MuiCheckbox-root': {
            color: theme.palette.common.white,
        },
        '& .Mui-checked': {
            color: theme.palette.primary.main,
        },
    },
    checkBoxContainer: {
        paddingTop: '0px !important',
        paddingBottom: '0px !important',
        marginTop: -15,
        marginBottom: -15,
    },
})

type RFQQuoteEditorFields = {
    name?: string
    agreed?: string
    leadTime?: string
    certifications?: string[]
    manufacturingProcesses?: string[]
    billingAddressId?: string
    shippingAddressId?: string
    quoteDeadline?: string
}

const validate = (data: Map<string, string>): {} => {
    const errors: RFQQuoteEditorFields = {}
    if (!data.get('isDraft')) {
        ;['leadTime', 'quoteDeadline', 'shippingAddressId', 'billingAddressId'].forEach(field => {
            if (!data.get(field)) {
                errors[field] = 'Required'
            }
        })

        if (!data.get('sameAsBilling') && !data.get('shippingAddressId')) {
            errors.shippingAddressId = 'Required'
        }

        if (data.get('leadTime')) {
            const leadTime = new Date(data.get('leadTime')).getTime()
            if (leadTime < new Date().getTime()) {
                errors.leadTime = 'Lead time must be a date in the future.'
            }
        }

        if (data.get('quoteDeadline')) {
            const quoteDeadline = new Date(data.get('quoteDeadline')).getTime()
            if (quoteDeadline < new Date().getTime()) {
                errors.quoteDeadline = 'Quote deadline must be a date in the future'
            }
        }
    }
    return errors
}

interface FormState {
    sameAsBilling: boolean
    addressDialogOpen: boolean
    addressTriggerField?: string | null
}

interface FormProperties {
    parts: any[]
    addresses?: { value: string; label: string }[]
    submitAddressForm: () => void
    createAddress: (address, field) => void
    supplierTags: {
        manufacturing: { label: string; value: string }[]
        certifications: { label: string; value: string }[]
    }
    updateField: (field: string, data: boolean) => void
    draftParts: any[]
    onDraftPartsChange: (draftParts: any[]) => void
    supportingDocuments: any[]
    onSupportingDocumentsChange: (documents: any[]) => void
    rfq?: any
    updateRFQFormData: (field: any) => void
}

interface FormProps extends InjectedFormProps<{}, {}>, WithStyles, WithNamespaces, FormProperties {}

class Form extends React.Component<FormProps, FormState> {
    constructor(props) {
        super(props)
        this.props.reset()
        this.state = {
            sameAsBilling: true,
            addressDialogOpen: false,
        }
        this.toggleSameAsBilling = this.toggleSameAsBilling.bind(this)
        this.toggleAddressDialog = this.toggleAddressDialog.bind(this)
        this.onSaveAddress = this.onSaveAddress.bind(this)
    }

    onSaveAddress(data: Map<string, any>) {
        this.props.change('sameAsBilling', false);
        this.setState({ sameAsBilling: false});
        const address: Address = (data.toJS() as unknown) as Address
        let addressData = address as any
        addressData = {
            ...addressData,
            location: {
                ...addressData.location,
                country: addressData?.location?.country ? addressData?.location?.country?.value : '',
                state: addressData?.location?.state ? addressData?.location?.state?.value : '',
            },
        }
        this.props.createAddress(addressData, this.state.addressTriggerField)
        this.props.change('shippingAddressId', '')
        this.toggleAddressDialog(null)
    }

    toggleAddressDialog(addressTriggerField?: string | null) {
        this.setState(state => ({
            addressDialogOpen: !state.addressDialogOpen,
            addressTriggerField,
        }))
    }

    toggleSameAsBilling() {
        this.setState(state => ({
            sameAsBilling: !state.sameAsBilling,
        }))
    }

    addSupportingDocument = doc => {
        this.props.onSupportingDocumentsChange(this.props.supportingDocuments.concat(doc))
    }

    removeSupportingDocument = document => {
        this.props.onSupportingDocumentsChange(this.props.supportingDocuments.filter(d => d._id !== document._id))
    }

    bytesForHuman(bytes, decimals = 2) {
        let units = ['B', 'KB', 'MB', 'GB', 'TB', 'PB']

        let i = 0

        for (i; bytes > 1024; i++) {
            bytes /= 1024
        }

        return parseFloat(bytes.toFixed(decimals)) + ' ' + units[i]
    }

    render() {
        const { t, classes, addresses = [], handleSubmit, draftParts, onDraftPartsChange, supplierTags, updateField, submitAddressForm, rfq, parts, updateRFQFormData, submitting } = this.props

        const { sameAsBilling, addressDialogOpen } = this.state
        return (
            <>
                <form style={{ width: '100%' }}>
                    <PartDetailsV2 draftParts={draftParts} onDraftPartsChange={onDraftPartsChange} parts={parts} />
                    <Paper className={classes.paper}>
                        <Grid container direction="row" spacing={2}>
                            <Grid item xs={12} sm={12} md={12}>
                                <Typography gutterBottom variant="h6" className={classes.detailsHeader}>
                                    {`${t('quote-editor-documents')}`}
                                </Typography>
                            </Grid>
                            <Grid item xs={12} sm={12} md={12}>
                                <FilePondComponent
                                    className={classes.file}
                                    type={DocumentType.RFQ}
                                    onLoad={response => {
                                        const docObj = { ...response }
                                        this.addSupportingDocument(docObj)
                                    }}
                                    acceptedFileTypes={PARTS_ACCEPTED_FILE_TYPES}
                                    allowMultiple={true}
                                    maxFiles={20}
                                    maxFileSize="50MB"
                                    maxTotalFileSize="1000MB"
                                    allFilesProcessed={() => {}}
                                    clearAfterAllFilesProcessed={true}
                                />
                                <Typography gutterBottom variant="caption" paragraph align="center" style={{ color: '#DBDBDB', marginBottom: 8 }}>
                                    {`${t('rfq-model-footer-title')}`}
                                </Typography>
                            </Grid>
                            {this.props.supportingDocuments?.map((document: any, index: number) => (
                                <Grid item xs={12} md={12} style={{ paddingTop: 0 }} key={index}>
                                    <Card className={classes.card}>
                                        <Box className={classes.documentContainer} alignItems="center">
                                            <Box>
                                                <IconButton style={{ borderRadius: '50%', padding: '8px', backgroundColor: '#1C2D3E', color: 'white', marginLeft: '8px' }}>
                                                    <InsertDriveFile />
                                                </IconButton>
                                            </Box>
                                            <Box className={classes.documentDetails}>
                                                <Typography color="textPrimary" variant="body2" className={classes.fileName}>
                                                    {!!document?.name ? document?.name : ''}
                                                </Typography>
                                                <Typography variant="caption" className={classes.fileSize}>
                                                    {!!document?.size ? this.bytesForHuman(document?.size) : ''}
                                                </Typography>
                                            </Box>
                                            <Box>
                                                <CloseIcon className={classes.icon} onClick={() => this.removeSupportingDocument(document)} />
                                            </Box>
                                        </Box>
                                    </Card>
                                </Grid>
                            ))}
                        </Grid>
                    </Paper>
                    <Paper className={classes.paper}>
                        <Grid container direction="row" spacing={2}>
                            <Grid item xs={12} sm={12} md={12}>
                                <Typography variant="h6" className={classes.detailsHeader}>{`${t('quotes-requirements')}`}</Typography>
                            </Grid>
                            <Grid item xs={12} sm={12} md={6} className={classes.dateGridItem}>
                                <Field
                                    name="leadTime"
                                    inputVariant="outlined"
                                    component={DateTimePickerField}
                                    type="date"
                                    label={`${t('quotes-editor-lead-time')}*`}
                                    fullWidth
                                    onChange={(e: any) => {
                                        updateRFQFormData({ ['leadTime']: e })
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12} sm={12} md={6} className={classes.dateGridItem}>
                                <Field
                                    name="quoteDeadline"
                                    inputVariant="outlined"
                                    component={DateTimePickerField}
                                    type="date"
                                    label={`${t('quotes-editor-due-date')}*`}
                                    fullWidth
                                    onChange={(e: any) => {
                                        updateRFQFormData({ ['quoteDeadline']: e })
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12} sm={12} md={6} className={classes.gridItem}>
                                <Field
                                    name="buyerRFQReference"
                                    variant="outlined"
                                    component={FormTextField}
                                    type="text"
                                    label={`${t('quote-editor-rfq-reference')}`}
                                    fullWidth
                                    className={classes.formInput}
                                    onChange={(e: any) => {
                                        const { name } = e.target
                                        updateRFQFormData({ [name]: e.target.value })
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12} sm={12} md={6} className={classes.gridItem}>
                                <Field
                                    name="certifications"
                                    component={FormAutocompleteV2}
                                    options={supplierTags.certifications}
                                    label={`${t('supplier-capabilities-certification')}`}
                                    fullWidth
                                    isMulti
                                    format={value => {
                                        if (!value) {
                                            return []
                                        }
                                        if (!!value.toJS) {
                                            return value.toJS()
                                        }
                                        return value
                                    }}
                                    onChange={(e: any) => {
                                        updateRFQFormData({ ['certifications']: e })
                                    }}
                                ></Field>
                            </Grid>
                            <Grid item xs={12} sm={12} md={6} className={classes.gridItem}>
                                <Field
                                    name="inspection"
                                    variant="outlined"
                                    component={FormTextField}
                                    type="text"
                                    label={`${t('part-inspection')}`}
                                    fullWidth
                                    className={classes.formInput}
                                    onChange={(e: any) => {
                                        const { name } = e.target
                                        updateRFQFormData({ [name]: e.target.value })
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12} sm={12} md={6} className={classes.gridItem}>
                                <Field
                                    name="packaging"
                                    variant="outlined"
                                    component={FormTextField}
                                    type="text"
                                    label={`${t('part-packaging')}`}
                                    fullWidth
                                    className={classes.formInput}
                                    onChange={(e: any) => {
                                        const { name } = e.target
                                        updateRFQFormData({ [name]: e.target.value })
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12} sm={12} md={12} className={classes.gridItem}>
                                <Field
                                    name="additionalRequirements"
                                    variant="outlined"
                                    component={FormTextField}
                                    type="text"
                                    label={`${t('quotes-additional-requirements')}`}
                                    fullWidth
                                    className={classes.formInput}
                                    onChange={(e: any) => {
                                        const { name } = e.target
                                        updateRFQFormData({ [name]: e.target.value })
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12} sm={12} md={6} className={classes.gridItem}>
                                <Field
                                    name="customerSuppliedMaterial"
                                    component={renderCheckbox}
                                    label={t('quote-editor-supplied-material')}
                                    onChange={(e: any) => {
                                        const { name } = e.target
                                        updateRFQFormData({ [name]: e.target.checked })
                                    }}
                                    className={classes.checkbox}
                                />
                            </Grid>
                            <Grid item xs={12} sm={12} md={6} className={classes.gridItem}>
                                <Field
                                    name="domesticProductionRequired"
                                    component={renderCheckbox}
                                    label={t('quotes-domestic-production-required')}
                                    onChange={(e: any) => {
                                        const { name } = e.target
                                        updateRFQFormData({ [name]: e.target.checked })
                                    }}
                                    className={classes.checkbox}
                                />
                            </Grid>
                        </Grid>
                    </Paper>

                    {!!addresses && (
                        <Paper className={classes.paper}>
                            <Grid container direction="row" spacing={2}>
                                <Grid item xs={12} sm={12} md={12}>
                                    <Typography variant="h6" className={classes.detailsHeader}>{`${t('quotes-shipping-billing')}`}</Typography>
                                </Grid>
                                <Grid item xs={12} sm={12} md={12} className={classes.gridItem}>
                                    <Field
                                        name="billingAddressId"
                                        component={FormAutocompleteV2}
                                        options={addresses}
                                        format={value => {
                                            if (!value) {
                                                return null
                                            }
                                            if (!!value.toJS) {
                                                return value.toJS()
                                            }
                                            return value
                                        }}
                                        label={`${t('quotes-billing-address')}*`}
                                        fullWidth
                                        className={classes.formControl}
                                        onChange={(newValue: any) => {
                                            updateRFQFormData({ buyerCompany: { ...rfq?.buyerCompany, billingAddressId: newValue?.value } })
                                        }}
                                    ></Field>
                                </Grid>
                                <Grid item xs={12} sm={12} md={12} className={classes.checkBoxContainer}>
                                    <Field
                                        component={renderCheckbox}
                                        value={sameAsBilling}
                                        name="sameAsBilling"
                                        label={t('quotes-editor-same-as-billing')}
                                        onChange={(e: any) => {
                                            this.toggleSameAsBilling()
                                            const { name } = e.target
                                            updateRFQFormData({ [name]: e.target.checked })
                                            if (e.target.checked && rfq?.buyerCompany?.billingAddressId) {
                                                const billingAddress = findSelectedSingleOption(addresses, rfq?.buyerCompany?.billingAddressId || '')
                                                this.props.change('shippingAddressId', billingAddress)
                                            } else {
                                                this.props.change('shippingAddressId', '')
                                            }
                                        }}
                                        className={classes.checkbox}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={12} md={12} className={classes.gridItem}>
                                    <Field
                                        name="shippingAddressId"
                                        component={FormAutocompleteV2}
                                        options={addresses}
                                        format={value => {
                                            if (!value) {
                                                return null
                                            }
                                            if (!!value.toJS) {
                                                return value.toJS()
                                            }
                                            return value
                                        }}
                                        label={`${t('quotes-shipping-address')}*`}
                                        fullWidth
                                        className={classes.formControl}
                                        onChange={(newValue: any) => {
                                            updateRFQFormData({ buyerCompany: { ...rfq?.buyerCompany, shippingAddressId: newValue?.value } })
                                        }}
                                    ></Field>
                                </Grid>
                                <Grid item xs={12} sm={12} md={12} className={classes.gridItem}>
                                    <Button variant="outlined" color="primary" onClick={() => this.toggleAddressDialog('shippingAddressId')} style={{ marginRight: 16 }}>
                                        {t('Add new address')}
                                    </Button>
                                </Grid>
                            </Grid>
                        </Paper>
                    )}
                    <Grid container direction="row" spacing={2} style={{ marginTop: 20, textAlign: 'end' }}>
                        <Grid item xs={12} sm={12} md={12}>
                            <Button
                                className={classes.saveBtn}
                                type="button"
                                color="primary"
                                size="small"
                                variant="outlined"
                                onFocus={() => {
                                    updateField('isDraft', true)
                                }}
                                onClick={handleSubmit}
                                disabled={!!submitting}
                            >
                                {`${t('rfq-draft-save')}`}
                            </Button>
                            <Button
                                className={classes.saveBtn}
                                type="button"
                                color="primary"
                                size="small"
                                onFocus={() => {
                                    updateField('isDraft', false)
                                }}
                                onClick={handleSubmit}
                                disabled={draftParts?.length === 0 || !!submitting}
                            >
                                {`${t('side-menu-request')}`}
                            </Button>
                        </Grid>
                    </Grid>
                </form>
                <AddressDialog onSubmit={this.onSaveAddress as any} onSaveUpdate={submitAddressForm} close={this.toggleAddressDialog} open={addressDialogOpen} />
            </>
        )
    }
}

const translatedForm = withStyles(styles)(withNamespaces()(Form))
export const RFQQuoteEditorForm = reduxForm<{}, FormProperties>({
    form: RFQQuoteEditorFormName,
    validate,
    onSubmitFail: jumpToFirstError,
    enableReinitialize: true,
})(translatedForm as any) // ReduxForm types are not good
