import React from 'react'
import { WithNamespaces, withNamespaces } from 'react-i18next'
import { connect } from 'react-redux'
import { Loader, MFGIcon, PRTIcon, PUSH } from '@syncfab/machine'
import {
    WithStyles,
    withStyles,
    Paper,
    Typography,
    Grid,
    Button,
    Fab,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    TableBody,
    TableRow,
    TableCell,
    Tooltip,
    ClickAwayListener,
} from '@material-ui/core'
import { Dispatch } from 'redux'
import { CenteredAlignedContainer } from '../../layout/Container'
import { State } from '../../application/Store'
import {
    GET_BALANCE,
    GET_TRANSACTIONS,
    FETCH_TRANSACTIONS,
    SEND_MFG_TOKENS,
    GET_TRANSACTION_FEE,
    RESET_DATA,
    CREATE_WALLET,
    GET_WALLET_STATUS,
    GET_WALLET_ADDRESS,
    GET_NFT_BALANCE,
    CONTACT_SALES,
} from '../../components/transaction/Actions'
import { TransactionList } from './components/TransactionList'
import { getHasNextSelector } from '../../components/transaction/Selectors'
import CallReceivedIcon from '@material-ui/icons/CallReceived'
import { SortButton } from './components/SortButton'
import CallMadeIcon from '@material-ui/icons/CallMade'
import SwapHorizIcon from '@material-ui/icons/SwapHoriz'
import { EmptyTransactionList } from './components/EmptyTransactionList'
import WarningIcon from '@material-ui/icons/Warning'
import CachedIcon from '@material-ui/icons/Cached'
import Table from '@material-ui/core/Table'
import { RouteComponentProps } from 'react-router'
import { SendMFGTokensFormName } from './components/SendMFGTokensForm'
import { Map } from 'immutable'
import { SendTokensDialog } from './components/SendTokensDialog'
import { submit } from 'redux-form'
import FileCopyIcon from '@material-ui/icons/FileCopy'
import ErrorIcon from '@material-ui/icons/Error'
import RefreshIcon from '@material-ui/icons/Refresh'
import { ContactSalesForm } from './components/ContactSalesForm'
import { GET_LOGGED_USER } from '../../components/user/Actions'
import AccountBalanceWalletRoundedIcon from '@material-ui/icons/AccountBalanceWalletRounded'

const styles = theme => ({
    paper: {
        width: '90%',
        minWidth: 300,
        padding: theme.spacing(4),
        marginTop: theme.spacing(4),
        marginBottom: theme.spacing(4),
    },
    paper2: {
        width: '80%',
        minWidth: 300,
        padding: theme.spacing(4),
        marginTop: theme.spacing(4),
        marginBottom: theme.spacing(4),
    },
    gridPadding: {
        padding: `${theme.spacing(2)}px ${theme.spacing(20)}px`,
    },
    balanceContainer: {
        border: `1px solid ${theme.palette.secondary.main}`,
        borderRadius: 15,
        margin: `${theme.spacing(2)}px ${theme.spacing(1)}px`,
        padding: theme.spacing(2),
    },
    mfgIcon: {
        width: 75,
        height: 75,
    },
    balance: {
        color: theme.palette.secondary.main,
        fontSize: 34,
        marginLeft: theme.spacing(1),
    },
    margin: {
        margin: theme.spacing(1),
    },
    paragraph: {
        color: '#f55c47',
    },
    title: {
        marginBottom: theme.spacing(0),
        paddingBottom: theme.spacing(0),
    },

    warningButton: {
        color: '#ef5350',
        margin: theme.spacing(1),
        fontSize: 64,
    },
    errorButton: {
        color: '#ef5350',
        margin: theme.spacing(2),
        fontSize: 64,
    },
    walletIcon: {
        color: theme.palette.common.white,
        margin: theme.spacing(2),
        fontSize: 64,
        backgroundColor: '#FFBA00',
    },
    processingButton: {
        color: '#40BF8A',
        margin: theme.spacing(2),
        fontSize: 64,
    },
    root: {
        backgroundColor: theme.palette.common.white,
        marginTop: theme.spacing(2),
        padding: theme.spacing(2),
        color: theme.palette.grey['700'],
    },

    horizontalheader: {
        backgroundColor: theme.palette.grey['300'],
        width: '35%',
        fontWeight: 900,
        // padding:`${theme.spacing.unit * 2}px 0`
    },
    link: {
        color: '#40B4BF',
        textDecoration: 'none',
    },

    tablePagination: {},
    tablePaginationCaption: {
        color: 'white',
    },
    tablePaginationSelectIcon: {
        color: 'white',
    },

    tablePaginationSelect: {
        color: 'black',
    },
    tablePaginationActions: {
        color: 'white',
    },

    tablePaginationNextIcon: {
        color: 'white !important',
    },
    tablePaginationBackIcon: {
        color: 'white !important',
    },

    refresh: {
        color: theme.palette.common.white,
    },
    refreshIcon: {
        marginLeft: theme.spacing(1),
    },
    marginTextRight: {
        marginRight: theme.spacing(2),
    },
    marginTextBottom: {
        marginBottom: theme.spacing(0),
        paddingBottom: theme.spacing(0),
    },
    links: {
        marginLeft: theme.spacing(0.5),
        'text-decoration': 'none',
        'text-transform': 'none',
        color: theme.palette.primary.light,
    },
    marginTop: {
        marginTop: theme.spacing(3),
    },
    errormsg: {
        backgroundColor: '#8E7931',
        'border-left-color': '#FFC525',
        'border-left-style': 'solid',
        'border-left-width': 'thick',
    },
    colorWhite: {
        color: 'white !important',
        fontSize: theme.spacing(1.8),
    },
    warningIcon: {
        color: theme.palette.common.white,
        margin: theme.spacing(0.5),
    },
    wrapIcon: {
        verticalAlign: 'middle',
        display: 'inline-flex',
    },
    dialogClass: {
        padding: theme.spacing(3),
    },
    dialogTitleClass: {
        paddingBottom: theme.spacing(1.7),
    },
    marginTopCopyText: {
        marginTop: theme.spacing(1.7),
    },
    heading: {
        fontSize: '24px',
        fontWeight: 600,
    },
    subtitle: {
        fontSize: '36px',
        color: 'white !important',
        letterSpacing: '0.5px !important',
        fontWeight: 400,
    },
    contactButton: {
        letterSpacing: '1px',
    },
})

interface WalletProps extends WithNamespaces, WithStyles, RouteComponentProps {
    getTransaction: (field: string, direction: number, filter: string, size: number) => void
    getBalance: () => void
    getNFTBalance: () => void
    getWalletAddress: () => void
    getWalletStatus: () => void
    createWallet: () => void
    getTransactionFee: () => void
    fetchTransaction: (page: number, field: string, direction: number, size: number) => void
    sendMFGTokens: (data: any) => void
    contactSales: (companyId: string, subject: string, description: string) => void
    submitTokenForm: () => void
    getLoggedUser: () => void
    resetErrorMsg: () => void
    push: (url: string) => void

    // sendToken: () => void
    transaction?: any
    balance?: any
    nftBalance?: any
    walletCreationStatus?: string
    status?: any
    hasNext: boolean
    isLoading: boolean
    createWalletSuccess: boolean
    failToCreateWallet: boolean
    sendingTokens: boolean
    // authError?: string | null
    transactionFee: any
    ErrorDialog?: boolean
    sendTokenData: any
    sendTokenSuccess?: boolean
    // transactionTotal?: any
    // ChangePage: (event, page) => void
    // refresh: () => void
    walletAddress?: string
    //history: any
    loggedUser: any
}

interface WalletState {
    field: string
    direction: number
    filter: string
    openSendDialog: boolean
    openReceiveDialog: boolean
    ConfirmDialog: boolean
    WarningDialog: boolean
    SelfSendError: boolean
    tokenToSend?: any
    tokenData?: any
    page: number
    size: number
    open: boolean
    contactSalesDialog: boolean
}

class WalletView extends React.Component<WalletProps, WalletState> {
    constructor(props: WalletProps) {
        super(props)
        this.fetch = this.fetch.bind(this)
        this.sort = this.sort.bind(this)
        this.state = {
            field: 'createdAt',
            direction: -1,
            filter: null,
            page: 0,
            size: 25,
            openSendDialog: false,
            openReceiveDialog: false,
            ConfirmDialog: false,
            WarningDialog: false,
            SelfSendError: false,
            open: false,
            contactSalesDialog: false,
        }
        this.props.getLoggedUser()
        this.props.getWalletAddress()
        this.props.getWalletStatus()
        this.props.getBalance()
        this.props.getNFTBalance()
        this.props.getTransactionFee()
        this.refresh = this.refresh.bind(this)
        this.props.getTransaction(this.state.field, this.state.direction, this.state.filter, this.state.size)
        this.handleClose = this.handleClose.bind(this)
        this.handleCloseWalletCreation = this.handleCloseWalletCreation.bind(this)
        this.openSendMFGTokenDialog = this.openSendMFGTokenDialog.bind(this)
        this.openReceiveMFGTokenDialog = this.openReceiveMFGTokenDialog.bind(this)
        this.showConfirmDialog = this.showConfirmDialog.bind(this)
        this.showProcessingDialog = this.showProcessingDialog.bind(this)
        this.showWarningDialog = this.showWarningDialog.bind(this)
        this.ChangePage = this.ChangePage.bind(this)
        this.ChangeRowsPerPage = this.ChangeRowsPerPage.bind(this)
        this.openContactSalesDialog = this.openContactSalesDialog.bind(this)
        this.onSendToken = this.onSendToken.bind(this)
        this.copyToClipboard = this.copyToClipboard.bind(this)
        this.createwallet = this.createwallet.bind(this)
        this.contactSubmit = this.contactSubmit.bind(this)
        this.onWalletActivate = this.onWalletActivate.bind(this)
    }

    fetch() {
        const { field, direction, page, size } = this.state
        // const page = Math.round(this.props.transaction.transactions.length / TRANSACTION_REQUEST_SIZE)
        this.props.fetchTransaction(page, field, direction, size)
    }

    sort(field: string, direction: number, filter: string) {
        this.setState({
            direction,
            field,
            filter,
        })

        this.props.getTransaction(field, direction, filter, 25)
    }

    openSendMFGTokenDialog() {
        this.setState({ openSendDialog: true, openReceiveDialog: false })
    }
    openReceiveMFGTokenDialog() {
        this.setState({ openSendDialog: false, openReceiveDialog: true, WarningDialog: false })
    }
    showConfirmDialog() {
        this.setState({ openSendDialog: false, openReceiveDialog: false, ConfirmDialog: true })
    }
    showProcessingDialog() {
        const dataToSend = {
            senderAddress: this.props.walletAddress,
            receiverAddress: this.state.tokenData.recipientWalletAddress.trim(),
            tokens: this.state.tokenData.MFGAmount.toString(),
            transactionFee: this.props.transactionFee.mfgTransferFee.toString(),
        }
        this.setState({ openSendDialog: false, openReceiveDialog: false, ConfirmDialog: false })
        this.props.sendMFGTokens(dataToSend)
    }
    showWarningDialog() {
        this.setState({ openSendDialog: false, openReceiveDialog: false, ConfirmDialog: false, WarningDialog: true })
    }
    handleClose = () => {
        //  const { field, direction } = this.state
        this.props.resetErrorMsg()
        this.setState({ openSendDialog: false, openReceiveDialog: false, ConfirmDialog: false, WarningDialog: false, SelfSendError: false, contactSalesDialog: false })
    }

    resetReload() {
        this.props.resetErrorMsg()
        this.fetch()
        this.setState({ openSendDialog: false, openReceiveDialog: false, ConfirmDialog: false, WarningDialog: false })
    }

    handleCloseWalletCreation() {
        this.props.resetErrorMsg()
        this.props.getBalance()
        this.props.getNFTBalance()
    }

    onSendToken(data: Map<string, string>) {
        let tokenData: any = data.toJS()
        tokenData.MFGAmount = Number(tokenData.MFGAmount)
        if (!this.props.balance) {
            return
        }

        if (this.props.walletAddress.trim() === tokenData.recipientWalletAddress.trim()) {
            this.setState({ openSendDialog: false, SelfSendError: true })
            return
        }

        if (tokenData.MFGAmount + Number(this.props.transactionFee.mfgTransferFee) > Number(this.props.balance.MFG_Balance)) {
            this.setState({ openSendDialog: false, WarningDialog: true })
            return
        } else {
            this.setState({ tokenData: tokenData })
            this.setState({ openSendDialog: false, ConfirmDialog: true })
        }
    }
    ChangePage(event, page) {
        console.log(event)

        this.setState({ page: page }, this.fetch)
    }
    ChangeRowsPerPage(event) {
        this.setState({ page: 0, size: event.target.value }, this.fetch)
        this.fetch()
    }
    createwallet() {
        this.props.createWallet()
        // this.props.getBalance()
    }

    refresh() {
        this.props.getWalletStatus()
        this.props.getBalance()
        this.props.getNFTBalance()
        this.props.getTransactionFee()

        this.props.getTransaction(this.state.field, this.state.direction, this.state.filter, this.state.size)
    }

    copyToClipboard() {}

    openContactSalesDialog() {
        this.setState({ contactSalesDialog: true })
    }
    contactSubmit(data: Map<string, string>) {
        let formdata: any = data.toJS()
        this.props.contactSales(this.props.loggedUser.companyId, formdata.subject, formdata.description)
        this.setState({ contactSalesDialog: false })
    }
    onWalletActivate() {
        this.setState({ contactSalesDialog: false })
    }
    render() {
        const {
            t,
            submitTokenForm,
            classes,
            balance,
            nftBalance,
            transaction,
            hasNext,
            isLoading,
            transactionFee,
            sendingTokens,
            ErrorDialog,
            sendTokenSuccess,
            walletCreationStatus,
            walletAddress,
        } = this.props
        const { open, field, direction, filter, openSendDialog, openReceiveDialog, ConfirmDialog, WarningDialog, tokenData, SelfSendError, contactSalesDialog } = this.state
        console.log('props', this.props)
        return (
            <CenteredAlignedContainer>
                {sendingTokens && <Loader />}
                <SendTokensDialog onSubmit={this.onSendToken as any} onSendToken={submitTokenForm} close={this.handleClose} open={openSendDialog} classes={classes} balance={balance} />

                <Dialog aria-labelledby="simple-dialog-title" open={ConfirmDialog} onClose={this.handleClose}>
                    <DialogTitle id="simple-dialog-title" className={classes.marginTextBottom}>
                        Confirm MFG Send
                    </DialogTitle>
                    <DialogContent>
                        <Grid container justifyContent="center">
                            <p>Verify the information below,once you send MFG this action cannot be undone.</p>
                            <Table className={classes.table}>
                                <TableBody className={classes.root}>
                                    <TableRow>
                                        <TableCell className={classes.horizontalheader}>To</TableCell>
                                        <TableCell align="left">{tokenData ? tokenData.recipientWalletAddress : ''}</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell className={classes.horizontalheader}>Amount</TableCell>
                                        <TableCell align="left">{tokenData ? tokenData.MFGAmount : ''} MFG</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell className={classes.horizontalheader}>Transaction Fee</TableCell>
                                        <TableCell align="left">{transactionFee ? transactionFee.mfgTransferFee : 0} MFG</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell className={classes.horizontalheader}>Total</TableCell>
                                        <TableCell align="left">{tokenData && transactionFee ? tokenData.MFGAmount + transactionFee.mfgTransferFee : 0} MFG</TableCell>
                                    </TableRow>
                                </TableBody>
                            </Table>
                        </Grid>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={this.handleClose} color="primary">
                            CANCEL
                        </Button>
                        <Button onClick={this.showProcessingDialog} color="primary" autoFocus>
                            CONFIRM
                        </Button>
                    </DialogActions>
                </Dialog>

                <Dialog aria-labelledby="simple-dialog-title" open={sendTokenSuccess} onClose={this.resetReload}>
                    <DialogContent>
                        <Grid container justifyContent="center">
                            <Grid item container justifyContent="center" xs={12}>
                                {/* <Fab className={classes.processingButton}> */}
                                <CachedIcon className={classes.processingButton} />
                                {/* </Fab> */}
                            </Grid>

                            <Typography gutterBottom variant="h3">
                                Your Request is Processing
                            </Typography>
                            <Typography align="center">
                                The transaction is recording over blockchain and can take up to 5 minutes to complete. You can refresh the page to update the status. We wil send you an email when your
                                transaction is completed.
                            </Typography>
                        </Grid>
                    </DialogContent>
                    <DialogActions>
                        <Grid container justifyContent="center">
                            <Button onClick={this.handleClose} color="primary" autoFocus>
                                OKAY
                            </Button>
                        </Grid>
                    </DialogActions>
                </Dialog>
                <Dialog aria-labelledby="simple-dialog-title" open={WarningDialog} onClose={this.handleClose}>
                    <DialogContent>
                        <Grid container justifyContent="center">
                            <Grid item container justifyContent="center" xs={12}>
                                {/* <Fab className={classes.errorButton}> */}
                                <ErrorIcon className={classes.errorButton} />
                                {/* </Fab> */}
                            </Grid>

                            <Typography gutterBottom variant="h3">
                                Insufficient MFG Funds
                            </Typography>
                            <Typography align="center">
                                Your MFG Wallet balance does not have enough MFG to complete your request. You can purchase MFG from SyncFab or send MFG to your wallet in the wallet page
                            </Typography>
                        </Grid>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={this.handleClose} color="primary" autoFocus>
                            CLOSE
                        </Button>
                        <Button onClick={this.openReceiveMFGTokenDialog} color="primary" autoFocus>
                            DEPOSIT MFG
                        </Button>
                    </DialogActions>
                </Dialog>

                <Dialog aria-labelledby="simple-dialog-title" open={SelfSendError} onClose={this.handleClose}>
                    <DialogContent>
                        <Grid container justifyContent="center">
                            <Grid item container justifyContent="center" xs={12}>
                                {/* <Fab className={classes.warningButton}> */}
                                <WarningIcon className={classes.warningButton} />
                                {/* </Fab> */}
                            </Grid>

                            <Typography gutterBottom variant="h3">
                                <p>Sending MFG Tokens to self company wallet is not allowed</p>
                            </Typography>
                        </Grid>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={this.handleClose} color="primary" autoFocus>
                            CLOSE
                        </Button>
                    </DialogActions>
                </Dialog>

                <Dialog aria-labelledby="simple-dialog-title" open={ErrorDialog} onClose={this.handleClose}>
                    <DialogContent>
                        <Grid container justifyContent="center">
                            <Grid item container justifyContent="center" xs={12}>
                                {/* <Fab className={classes.warningButton}> */}
                                <WarningIcon className={classes.warningButton} />
                                {/* </Fab> */}
                            </Grid>

                            <Typography gutterBottom variant="h3">
                                MFG transaction failed
                            </Typography>
                            <p>
                                You initiated a send request of {tokenData && tokenData.MFGAmount} MFG from your SyncFab MFG wallet. Unfortunately, the transaction failed. Your funds will remain in
                                your MFG Wallet. We recommend trying the transaction again.
                            </p>
                        </Grid>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={this.handleClose} color="primary" autoFocus>
                            CLOSE
                        </Button>
                    </DialogActions>
                </Dialog>

                <Dialog aria-labelledby="simple-dialog-title" open={isLoading}>
                    <DialogContent>
                        <Grid container justifyContent="center">
                            <Grid item container justifyContent="center" xs={12}>
                                {/* <Fab className={classes.processingButton}> */}
                                <CachedIcon className={classes.processingButton} />
                                {/* </Fab> */}
                            </Grid>

                            <Typography gutterBottom variant="h3">
                                Please Wait...
                            </Typography>
                        </Grid>
                    </DialogContent>
                </Dialog>

                <Dialog aria-labelledby="simple-dialog-title" open={openReceiveDialog} onClose={this.handleClose} className={classes.dialogClass}>
                    <DialogTitle id="simple-dialog-title" className={classes.dialogTitleClass}>
                        <Typography className={classes.heading}>Deposit MFG Tokens</Typography>
                    </DialogTitle>
                    <DialogContent>
                        <Grid container direction="row" alignItems="center" className={classes.errormsg}>
                            <Grid item>
                                <WarningIcon className={classes.warningIcon} style={{ fontSize: 18 }} />
                            </Grid>
                            <Grid item>Only send MFG ERC20 Tokens to this wallet address. </Grid>
                        </Grid>

                        <Typography gutterBottom variant="h3" className={classes.marginTop}>
                            MFG (Erc20) Wallet Address
                        </Typography>
                        <Paper className={`${classes.root} ${classes.marginTopCopyText}`}>
                            <Grid container direction="row" alignItems="center" justifyContent="space-between">
                                <Grid item>{walletAddress ? (walletAddress !== null || undefined ? `${walletAddress} ` : 'Wallet Address not Found') : 'Wallet Address not Found'}</Grid>
                                <Grid item style={{ marginLeft: '12px' }}>
                                    <ClickAwayListener
                                        onClickAway={() => {
                                            this.setState({ open: false })
                                        }}
                                    >
                                        <div>
                                            <Tooltip
                                                PopperProps={{
                                                    disablePortal: true,
                                                }}
                                                onClose={() => {
                                                    this.setState({ open: false })
                                                }}
                                                open={open}
                                                disableFocusListener
                                                disableHoverListener
                                                disableTouchListener
                                                title=" Copied"
                                                leaveDelay={200}
                                            >
                                                <Button>
                                                    <FileCopyIcon
                                                        color="primary"
                                                        onClick={() => {
                                                            ;(navigator as any).clipboard.writeText(walletAddress.trim()).then(() => {
                                                                this.setState({ open: true })
                                                            })

                                                            //     var textarea = document.createElement('input')
                                                            //     document.body.appendChild(textarea)

                                                            //     textarea.value = walletAddress.trim()
                                                            //    console.log(textarea.value);

                                                            //     var selection = document.getSelection()
                                                            //     var range = document.createRange()
                                                            //     range.selectNode(textarea)
                                                            //     selection.removeAllRanges()
                                                            //     selection.addRange(range)
                                                            //     document.execCommand('copy')

                                                            //     this.setState({ open: true })
                                                            //     //   alert("copied")
                                                            //     // console.log('copy success', document.execCommand('copy'));
                                                            //     selection.removeAllRanges()

                                                            //     document.body.removeChild(textarea)
                                                        }}
                                                    />
                                                </Button>
                                            </Tooltip>
                                        </div>
                                    </ClickAwayListener>
                                </Grid>
                            </Grid>
                            {/* {balance ? (walletAddress !== null || undefined ? `${walletAddress} ` : 'Wallet Address not Found') : 'Wallet Address not Found'}  */}
                        </Paper>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={this.handleClose} color="primary" autoFocus>
                            CLOSE
                        </Button>
                    </DialogActions>
                </Dialog>

                <Dialog fullWidth aria-labelledby="simple-dialog-title" open={contactSalesDialog}>
                    <DialogContent>
                        <Typography gutterBottom variant="h3">
                            Contact Sales
                        </Typography>
                        <ContactSalesForm onSubmit={this.contactSubmit as any} close={this.handleClose} />
                    </DialogContent>
                </Dialog>

                {walletCreationStatus && (walletCreationStatus == 'CREATED' || walletCreationStatus == 'DEACTIVATED') && (
                    <Paper className={classes.paper} elevation={1}>
                        <Grid container>
                            <Typography gutterBottom variant="h2">
                                {'MFG Wallet'}
                            </Typography>
                        </Grid>
                        <Grid container justifyContent="space-between" direction="row" alignItems="center">
                            <Grid container item xs={12} md={6} alignItems="center" direction="row">
                                <Grid item container alignItems="center" className={classes.balanceContainer}>
                                    <Grid item xs={12} md={3}>
                                        <MFGIcon className={classes.mfgIcon} fill="#FFBA00" />
                                    </Grid>
                                    <Grid item xs={12} md={9}>
                                        <Typography gutterBottom variant="h3">
                                            {`${t('wallet-balance')}`}`
                                        </Typography>
                                        <Grid container item direction="row">
                                            <Grid item xs={12} md={4}>
                                                <Typography variant="body1" gutterBottom className={classes.balance}>
                                                    {balance
                                                        ? balance.MFG_Balance !== null && balance.MFG_Balance !== undefined
                                                            ? balance.MFG_Balance % 1 === 0
                                                                ? `${balance.MFG_Balance}`
                                                                : `${Number(balance.MFG_Balance).toFixed(4)} `
                                                            : '0'
                                                        : '0'}
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={3} md={2} />
                                            <Grid item xs={3} md={2}>
                                                <Tooltip title="Deposit MFG Tokens">
                                                    <Fab color="secondary" size="small" className={classes.margin} onClick={this.openReceiveMFGTokenDialog}>
                                                        <CallReceivedIcon />
                                                    </Fab>
                                                </Tooltip>
                                            </Grid>
                                            <Grid item xs={3} md={2}>
                                                {walletCreationStatus == 'CREATED' && (
                                                    <Tooltip title="Send MFG Tokens">
                                                        <Fab color="secondary" size="small" className={classes.margin} onClick={this.openSendMFGTokenDialog}>
                                                            <CallMadeIcon />
                                                        </Fab>
                                                    </Tooltip>
                                                )}
                                                {walletCreationStatus == 'DEACTIVATED' && (
                                                    <Tooltip title="Contact Sales">
                                                        <Fab size="small" className={classes.margin} onClick={this.openContactSalesDialog}>
                                                            <CallMadeIcon />
                                                        </Fab>
                                                    </Tooltip>
                                                )}
                                            </Grid>
                                            <Grid item xs={3} md={2}>
                                                <Tooltip title="Purchase MFG Tokens">
                                                    <Fab
                                                        color="secondary"
                                                        size="small"
                                                        className={classes.margin}
                                                        onClick={() => {
                                                            this.props.history.push('/wallet/purchase')
                                                        }}
                                                    >
                                                        <SwapHorizIcon />
                                                    </Fab>
                                                </Tooltip>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid container item xs={12} md={6} alignItems="center" direction="row">
                                <Grid item container alignItems="center" className={classes.balanceContainer}>
                                    <Grid item xs={12} md={3}>
                                        <PRTIcon className={classes.mfgIcon} fill="#FFFFFF" />
                                    </Grid>
                                    <Grid item xs={12} md={9}>
                                        <Typography gutterBottom variant="h3">
                                            MFGPRT Balance (Erc721)
                                        </Typography>
                                        <Typography gutterBottom className={classes.balance}>
                                            {nftBalance ? (nftBalance.NFT_Balance !== null && nftBalance.NFT_Balance !== undefined ? `${nftBalance.NFT_Balance} ` : '0') : '0'}
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid container alignItems="center" direction="row" justifyContent="space-between">
                            <Typography gutterBottom variant="h3">
                                {`${t('wallet-history')}`}`
                            </Typography>
                            <Grid>
                                <Button variant="text" onClick={this.refresh} className={`${classes.refresh} ${classes.marginTextRight}`}>
                                    <RefreshIcon className={`${classes.refresh} ${classes.refreshIcon}`} />
                                    <Typography className={`${classes.refresh} ${classes.refreshIcon}`}>{'Refresh'}</Typography>
                                </Button>

                                <SortButton sort={this.sort} field={field} direction={direction} filter={filter} />
                            </Grid>
                        </Grid>
                        {!transaction.transactions && <Loader />}
                        {!!transaction && transaction.transactions && transaction.transactions.length > 0 && (
                            <TransactionList
                                transaction={transaction}
                                hasNext={hasNext}
                                handleChangePage={this.ChangePage}
                                handleChangeRowsPerPage={this.ChangeRowsPerPage}
                                isLoading={isLoading}
                                fetch={this.fetch}
                                classes={classes}
                            />
                        )}
                        {transaction.transactions && transaction.transactions.length === 0 && <EmptyTransactionList />}
                    </Paper>
                )}
                {walletCreationStatus && walletCreationStatus == 'FAILED' && (
                    <Paper className={classes.paper2} elevation={1}>
                        <Grid container>
                            <Typography gutterBottom variant="h2">
                                {'MFG Wallet'}
                            </Typography>
                        </Grid>
                        <Grid container justifyContent="center" className={classes.gridPadding} spacing={4}>
                            <Grid item container justifyContent="center" xs={12}>
                                <Fab color="secondary" size="large" className={classes.margin}>
                                    <AccountBalanceWalletRoundedIcon fontSize="large" />
                                </Fab>
                            </Grid>

                            <Typography align="center" className="classes.subtitle" variant="caption" style={{ letterSpacing: '0.5px', fontWeight: 'normal', fontSize: '20px' }}>
                                MFG Crypto Wallet allows you to send and receive MFG Tokens. <br />
                                This is premium, contact sales to learn more.
                            </Typography>
                            <Grid item container justifyContent="center" xs={12}>
                                <Button variant="contained" onClick={this.openContactSalesDialog} color="secondary">
                                    <Typography gutterBottom className="classes.contactButton" style={{ letterSpacing: '0.5px', marginTop: '2px' }}>
                                        Contact Sales
                                    </Typography>
                                </Button>
                            </Grid>
                        </Grid>
                    </Paper>
                )}
                {walletCreationStatus && walletCreationStatus == 'PENDING' && (
                    <Paper className={classes.paper2} elevation={1}>
                        <Grid container>
                            <Typography gutterBottom variant="h2">
                                {'MFG Wallet'}
                            </Typography>
                        </Grid>
                        <Grid container justifyContent="center" className={classes.gridPadding} spacing={4}>
                            <Grid item container justifyContent="center" xs={12}>
                                <Loader />
                            </Grid>

                            <Typography gutterBottom align="center" style={{ margin: -70 }}>
                                Your MFG Wallet creation is processing over the Ethereum blockchain. This can take a few minutes to complete. You can refresh the page to update the status. We will
                                send you an email notification when completed.
                            </Typography>

                            <Grid item container justifyContent="center" xs={12}>
                                <Button variant="contained" onClick={this.refresh} color="secondary">
                                    Refresh
                                </Button>
                            </Grid>
                        </Grid>
                    </Paper>
                )}
            </CenteredAlignedContainer>
        )
    }
}

const WalletViewWithTranslations = withStyles(styles)(withNamespaces()(WalletView))

const mapStateToProps = (state: State) => {
    const getHasNext = getHasNextSelector()
    console.log('state', state)
    return {
        balance: state.transaction.balance,
        nftBalance: state.transaction.nftBalance,
        transaction: state.transaction,
        hasNext: getHasNext(state),
        isLoading: state.transaction.isLoading,
        createWalletSuccess: state.transaction.createWalletSuccess,
        failToCreateWallet: state.transaction.failToCreateWallet,
        transactionFee: state.transaction.transactionFee,
        sendingTokens: state.transaction.sendingToken,
        ErrorDialog: state.transaction.ErrorDialog,
        sendTokenData: state.transaction.sendTokenData,
        sendTokenSuccess: state.transaction.sendTokenSuccess,
        walletCreationStatus: state.transaction.walletCreationStatus,
        status: state.transaction.status,
        walletAddress: state.transaction.walletAddress,
        loggedUser: state.user.loggedUser,
        //contactSales: state.contactSales
    }
}

const mapDispatchToProps = (dispatch: Dispatch) => {
    return {
        getTransaction: (field: string, direction: number, filter: string, size: number) => dispatch(GET_TRANSACTIONS.actions.TRIGGER({ field, direction, filter, size })),
        fetchTransaction: (page: number, field: string, direction: number, size: number) => dispatch(FETCH_TRANSACTIONS.actions.TRIGGER({ page, field, direction, size })),
        getBalance: () => dispatch(GET_BALANCE.actions.TRIGGER()),
        getNFTBalance: () => dispatch(GET_NFT_BALANCE.actions.TRIGGER()),
        getWalletStatus: () => dispatch(GET_WALLET_STATUS.actions.TRIGGER()),
        getTransactionFee: () => dispatch(GET_TRANSACTION_FEE.actions.TRIGGER()),
        submitTokenForm: () => dispatch(submit(SendMFGTokensFormName)),
        sendMFGTokens: (tokenData: any) => dispatch(SEND_MFG_TOKENS.actions.TRIGGER(tokenData)),
        resetErrorMsg: () => dispatch(RESET_DATA.actions.TRIGGER()),
        createWallet: () => dispatch(CREATE_WALLET.actions.TRIGGER()),
        getWalletAddress: () => dispatch(GET_WALLET_ADDRESS.actions.TRIGGER()),
        push: (url: string) => dispatch(PUSH(url)),
        getLoggedUser: () => dispatch(GET_LOGGED_USER.actions.TRIGGER()),
        contactSales: (companyId: string, subject: string, description: string) => dispatch(CONTACT_SALES.actions.TRIGGER({ companyId, subject, description })),
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(WalletViewWithTranslations)
