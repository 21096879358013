import React from 'react'
import { WithStyles, withStyles } from '@material-ui/core/styles'
import { Typography, Grid } from '@material-ui/core'
import { withNamespaces, WithNamespaces } from 'react-i18next'
import { NOTIFICATION_TYPES, NotificationSetting as Settings, renderCheckbox } from '@syncfab/machine'
import { Field } from 'redux-form/immutable'

const styles = theme => ({
    description: {
        'text-align': 'left',
        fontSize: 14,
    },
    checkBox: {
        'text-align': 'end',
        [theme.breakpoints.down('md')]: {
            'text-align': 'left',
        },
    },
    title: {
        fontSize: '14px' as any,
        fontWeight: 'bold' as any,
    },
    checkbox: {
        '& .MuiCheckbox-root': {
            color: theme.palette.common.white,
        },
        '& .Mui-checked': {
            color: theme.palette.primary.main,
        },
    },
})

interface Props extends WithStyles, WithNamespaces {
    notificationSettings: Settings[]
}

const settings = ({ classes, t, notificationSettings }: Props) => {
    return (
        <Grid container spacing={2}>
            {NOTIFICATION_TYPES.map((type, index) => {
                const value = notificationSettings.find(setting => setting.type === type)
                const subscribed = value ? value.subscribed : false
                return (
                    <Grid item key={type} xs={12}>
                        <Grid container spacing={3} alignItems="center">
                            <Grid item md={10} xs={12}>
                                <Typography variant="body2" className={classes.title}>{`${t(`user-notifications-${type.toLocaleLowerCase()}`)}`}</Typography>
                                <Typography className={classes.description}>{`${t(`user-notifications-${type.toLocaleLowerCase()}-description`)}`}</Typography>
                            </Grid>
                            <Grid item key={type} md={2} xs={12} className={classes.checkBox}>
                                <Field name={`notificationSettings[${index}].subscribed`} component={renderCheckbox} label={t('syncfab-subscribe')} value={subscribed} className={classes.checkbox} />
                            </Grid>
                        </Grid>
                    </Grid>
                )
            })}
        </Grid>
    )
}

export const NotificationSetting = withNamespaces()(withStyles(styles)(settings))
