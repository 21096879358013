import React from 'react'
import SvgIcon from '@material-ui/core/SvgIcon'

export const ArrowDown = props => {
    return (
        <SvgIcon {...props} viewBox="0 0 24 24" x="0px" y="0px" baseProfile="tiny" xmlSpace="preserve" fill="#808080">
            <path d="M0 0h24v24H0V0z" fill="none" />
            <path d="M7.41 8.59L12 13.17l4.59-4.58L18 10l-6 6-6-6 1.41-1.41z" fill="#808080" />
        </SvgIcon>
    )
}
