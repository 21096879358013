import { call, takeLatest, getContext, put } from 'redux-saga/effects'
import { Gateway } from '../gateway'
import { GET_CAPABILITIES, GET_LAT_LONG, GET_SUPPLIER_ANALYTICS } from './Actions'

function* getCapabilities() {
    try {
        const gateway: Gateway = yield getContext('gateway')
        const data = yield call(gateway.getDashboardAPI().getCapabilities())
        yield put(GET_CAPABILITIES.actions.SUCCESSFULL(data.data))
    } catch ({ response: { status, data } }) {
        const handleError = yield getContext('handleError')
        yield call(handleError(status))
        yield put(GET_CAPABILITIES.actions.FAILED(data))
    }
}

function* getSupplierAnalytics() {
    try {
        const gateway: Gateway = yield getContext('gateway')
        const response = yield call(gateway.getDashboardAPI().getSupplierAnalytics())
        yield put(GET_SUPPLIER_ANALYTICS.actions.SUCCESSFULL(response.data))
    } catch ({ response: { status, data } }) {
        const handleError = yield getContext('handleError')
        yield call(handleError(status))
        yield put(GET_SUPPLIER_ANALYTICS.actions.FAILED(data))
    }
}

function* getLatLong(request: any) {
    try {
        const gateway: Gateway = yield getContext('gateway')

        let responses = yield request.data.map(p =>
            p.coordinates && p.coordinates.length === 2
                ? { data: [{ latitude: p.coordinates[0], longitude: p.coordinates[1] }] }
                : p.address
                ? call(gateway.getDashboardAPI().getLatLongFromAddress(p.address))
                : { data: [{ latitude: 0, longitude: 0 }] }
        )

        // responses = responses.map(response => (response.data && response.data.results && response.data.results !== 0 ? response.data.results[0].length === 0?[{ latitude: 0, longitude: 0}]:response.data.results[0] : response.data))
        responses.map(response => console.log('---', response))

        let resp = []
        for (let i = 0; i < responses.length; i++) {
            let currentRecord = responses[i]

            if (currentRecord.data.latitude && currentRecord.data.latitude) {
                resp.push({ latitude: currentRecord.data.latitude, longitude: currentRecord.data.longitude })
                continue
            }

            if (currentRecord.data.results && currentRecord.data.results.length === 1) {
                let temp =
                    currentRecord.data.results[0].geometry && currentRecord.data.results[0].geometry.location
                        ? { latitude: currentRecord.data.results[0].geometry.location.lat, longitude: currentRecord.data.results[0].geometry.location.lng }
                        : { latitude: 0, longitude: 0 }
                resp.push(temp)
                continue
            }

            if (currentRecord.length == 1 && currentRecord[0].latitude && currentRecord[0].longitude) {
                resp.push({ latitude: currentRecord[0].latitude, longitude: currentRecord[0].longitude })
                continue
            }

            if (currentRecord && currentRecord.data && currentRecord.data.length === 1 && currentRecord.data[0].latitude && currentRecord.data[0].latitude) {
                resp.push({ latitude: currentRecord.data[0].latitude, longitude: currentRecord.data[0].longitude })
                continue
            }

            resp.push({ latitude: 0, longitude: 0 })
        }

        yield put(GET_LAT_LONG.actions.SUCCESSFULL(resp))
    } catch ({ response: { status, data } }) {
        const handleError = yield getContext('handleError')
        yield call(handleError(status))
        yield put(GET_LAT_LONG.actions.FAILED(data))
    }
}
function* dashboardSaga() {
    yield takeLatest(GET_CAPABILITIES.types.TRIGGER as any, getCapabilities)
    yield takeLatest(GET_SUPPLIER_ANALYTICS.types.TRIGGER as any, getSupplierAnalytics)
    yield takeLatest(GET_LAT_LONG.types.TRIGGER as any, getLatLong)
}

export default dashboardSaga
