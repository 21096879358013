import {
  DocumentManager,
  DocumentType,
  FileExtensions,
  RHFAutoCompletePrimitive,
  RHFTextField,
} from '@syncfab/machine';
import React, { useState } from 'react';
import { formatSupplierTagByValueLabel, useStyles } from './common';
import { Grid, Paper, Button } from '@material-ui/core';
import { useAppSelector } from '../../../application/hooks';
import { useFieldArray, useFormContext } from 'react-hook-form';

const formatDocumentsByCert = documents => {
  const docsByCert = {};
  documents?.forEach(d => {
    if ('certificationType' in d) {
      docsByCert[d.certificationType] = [
        ...(docsByCert[d.certificationType] ?? []),
        d,
      ];
    }
  });
  return docsByCert;
};

const CertificationsForm = () => {
  const supplierTags = useAppSelector(state => state.supplier.supplierTags);
  const methods = useFormContext();
  const docs = methods.watch('documents');
  const { fields, append, remove } = useFieldArray({
    control: methods.control,
    name: 'certifications',
  });
  const fieldWatcher = methods.watch('certifications');
  // I've expanded this to optimize the algorithm, i.e. a single pass through the supplierTag array.
  const certificationOptions = [];

  supplierTags?.forEach(sT => {
    switch (sT.type) {
      case 'CERTIFICATIONS':
        certificationOptions.push(formatSupplierTagByValueLabel(sT));
        break;
    }
  });
  const classes = useStyles();
  const [documentsByCert, setDocumentsByCert] = useState(
    formatDocumentsByCert(docs)
  );

  React.useEffect(() => {
    let allDocs = [];
    let allDocsFull = [];
    for (const key in documentsByCert) {
      allDocs = [...allDocs, ...documentsByCert[key].map(d => d._id)];
      allDocsFull = [
        ...allDocsFull,
        ...documentsByCert[key].map(d => ({
          ...d,
          certificationType: key,
        })),
      ];
    }
    methods.setValue('documentIds', allDocs);
    methods.setValue('documents', allDocsFull);
  }, [documentsByCert]);

  return (
    <Paper className={classes.formBackground}>
      <Grid container spacing={2}>
        {fields.map((field, index) => {
          const fWatch = methods.watch(`certifications[${index}]`);
          return (
            <>
              <Grid item xs={12}>
                <RHFAutoCompletePrimitive
                  key={field.id}
                  options={certificationOptions}
                  label="Certification"
                  name={`certifications[${index}]`}
                  RHFAutoCompleteOnChangeHandler={(_val, reason) => {
                    // Remove the certification from the list if the x is clicked.
                    if (reason === 'clear') remove(index);
                  }}
                />
              </Grid>
              {fWatch !== '' &&
                fWatch !== 'JCP' &&
                fWatch !== 'ITAR Compliant' && (
                  <Grid item xs={12}>
                    <DocumentManager
                      documents={documentsByCert[fWatch] ?? []}
                      setDocuments={() => {}}
                      handleRemoveDocument={(docIds: string[]) => {
                        setDocumentsByCert(state => {
                          return {
                            ...state,
                            [fWatch]: state[fWatch].filter(d =>
                              docIds.find(e => e === d._id)
                            ),
                          };
                        });
                      }}
                      handleAddDocument={doc =>
                        setDocumentsByCert(state => {
                          return {
                            ...state,
                            [fWatch]: [...(state[fWatch] ?? []), doc],
                          };
                        })
                      }
                      acceptedFileExtensions={[
                        FileExtensions.DOCX,
                        FileExtensions.PDF,
                        FileExtensions.XLSX,
                      ]}
                      label="Upload Documentation"
                      docType={DocumentType.DOCUMENT}
                      referenceId=""
                      showVisibility={false}
                      showType={false}
                      multiple={false}
                      maxFiles={1}
                      documentLimit={1}
                    />
                  </Grid>
                )}
              {fWatch === 'JCP' && (
                <Grid item xs={12}>
                  <RHFTextField name="cageCode" label="Cage Code" />
                </Grid>
              )}
            </>
          );
        })}
      </Grid>
      <Grid container spacing={2}>
        <Grid
          item
          md={12}
          style={{ display: 'flex', justifyContent: 'flex-end' }}
        >
          {!(fieldWatcher?.some(f => f === '' || f === null) ?? false) && (
            <Button
              variant="outlined"
              color="primary"
              onClick={() => {
                append('');
              }}
            >
              Add Certification
            </Button>
          )}
        </Grid>
      </Grid>
    </Paper>
  );
};

export default CertificationsForm;
