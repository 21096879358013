import React from 'react'
import { reduxForm } from 'redux-form/immutable'
import { InjectedFormProps } from 'redux-form'
import { Map } from 'immutable'
import { WithStyles, withStyles } from '@material-ui/core/styles'
import { withNamespaces, WithNamespaces } from 'react-i18next'
import { Button, jumpToFirstError, User } from '@syncfab/machine'
import { ProfileSettings } from '../../../../components/user/components/ProfileSettings'
import { NotificationSetting } from '../../../../components/user/components/NotificationSettings'
import { Typography } from '@material-ui/core'
import { EMAIL_REGEX, isNumber } from '../../../../utils/Regex'
import { USER_EMAIL_VALIDATION } from '../../../../components/user/Actions'
import { Grid } from '@material-ui/core'

export const UserProdileEditorFormName: string = 'UserProdileEditorForm'

const styles = theme => ({
    action: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
        marginLeft: 'auto',
        width: 80,
    },
    actionButton: {
        marginRight: theme.spacing(2),
    },
})

type PersonalSettingsFields = {
    firstName?: string
    lastName?: string
    phone?: string
    title?: string
    email?: string
}

const validate = (data: Map<string, string>): {} => {
    const errors: PersonalSettingsFields = {}
    const email = data.get('email')
    ;['firstName', 'lastName', 'phone', 'title', 'mobile'].forEach(field => {
        if (!data.get(field)) {
            errors[field] = 'Required'
        }
    })

    if (!email) {
        errors.email = 'Required'
    } else if (!EMAIL_REGEX.test(email || '')) {
        errors.email = 'Invalid email'
    }
    let mobileNumber = data.get('mobile')

    if (mobileNumber && mobileNumber.length > 0 && !isNumber(data.get('mobile'))) {
        errors['mobile'] = 'Enter Valid Mobile number.'
    }
    return errors
}

const asyncValidate = (values, dispatch) => {
    if (!values.get('_id')) {
        dispatch(USER_EMAIL_VALIDATION.actions.TRIGGER({ email: values.get('email') }))
    }
    return new Promise<void>(resolve => resolve())
}

const form = (props: InjectedFormProps<{}, {}> & WithStyles & WithNamespaces & { user?: User; cancel?: () => void }) => {
    const { handleSubmit, submitting, t, classes, user, cancel } = props
    const notificationSettings = user ? user.notificationSettings || [] : []
    const isEditing = user && !!user._id
    return (
        <form onSubmit={handleSubmit}>
            <Grid container direction="row" spacing={2}>
                <Grid item xs={12} sm={12} md={12} style={{ marginBottom: 8 }}>
                    <Typography gutterBottom variant="h3">
                        {isEditing ? `${t('user-editor-title')}` : `${t('user-add-title')}`}
                    </Typography>
                </Grid>
                <Grid item xs={12} sm={12} md={12}>
                    <ProfileSettings isEditing={isEditing} isLoggedUserProfile={false} />
                </Grid>

                {/* <Typography gutterBottom variant="h3">
                {`${t('user-settings-personal-credentials')}`}
            </Typography>
            <Field name="email" component={FormTextField} type="email" label={t('user-email')} fullWidth disabled={isEditing} />
            <Typography gutterBottom variant="h3">
                {`${t('user-settings-notifications-title')}`}
            </Typography> */}
                <Grid item xs={12} sm={12} md={12}>
                    <Typography gutterBottom variant="h3" className={classes.title}>
                        {`${t('user-settings-notifications-title')}`}
                    </Typography>
                </Grid>
                <Grid item xs={12} sm={12} md={12}>
                    <NotificationSetting notificationSettings={notificationSettings} />
                </Grid>
                <Grid item xs={12} sm={12} md={12} style={{ textAlign: 'end' }}>
                    <Button type="button" color="primary" variant="text" disabled={submitting} className={classes.actionButton} onClick={cancel}>
                        {t('form-action-cancel')}
                    </Button>
                    <Button type="submit" color="primary" disabled={submitting} className={classes.action}>
                        {isEditing ? t('form-action-save') : t('form-action-add')}
                    </Button>
                </Grid>
            </Grid>
        </form>
    )
}

const translatedForm = withNamespaces()(withStyles(styles)(form))
export const UserProdileEditorForm = reduxForm<{}, { user?: User; cancel?: () => void }>({
    form: UserProdileEditorFormName,
    validate,
    onSubmitFail: jumpToFirstError,
    asyncValidate,
    asyncChangeFields: ['email'],
    asyncBlurFields: ['email'],
    // initialValues: Map().set('roles', [UserRole.ADMIN]),
})(translatedForm)
