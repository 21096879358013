import React, { FC } from 'react';
import {
  Controller,
  UseControllerProps,
  useFormContext,
} from 'react-hook-form';
import {
  AutoCompleteValueLabelPrimitive,
  AutoCompleteValueLabelPrimitiveProps,
} from './AutoCompleteValueLabelPrimitive';

export interface RHFAutoCompletePrimitiveProps
  extends AutoCompleteValueLabelPrimitiveProps {
  rules?: UseControllerProps['rules'];
  RHFAutoCompleteOnChangeHandler?: Function;
}

export const RHFAutoCompletePrimitive: FC<RHFAutoCompletePrimitiveProps> = props => {
  const methods = useFormContext();
  return (
    <Controller
      name={props.name}
      control={methods.control}
      rules={props.rules}
      render={({
        field: { onChange, value, ref: fieldRef, onBlur },
        fieldState: { error },
      }) => {
        let targetValue = value;
        if (props.multiple && !value) targetValue = [];
        return (
          <AutoCompleteValueLabelPrimitive
            value={targetValue}
            InputRef={fieldRef}
            onBlurHandler={() => {
              onBlur();
            }}
            onChangeHandler={(value, reason) => {
              onChange(value);
              if (props.RHFAutoCompleteOnChangeHandler) {
                props.RHFAutoCompleteOnChangeHandler(value, reason);
              }
            }}
            error={error}
            {...props}
          />
        );
      }}
    />
  );
};
