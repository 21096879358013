import React from 'react'
import cn from 'classnames'

export const Step = ({ classes, active, completed }) => {
    const classNames = cn({
        [classes.completed]: completed,
        [classes.active]: active,
        [classes.default]: !active && !completed,
        [classes.step]: true,
    })

    return <div className={classNames} />
}
