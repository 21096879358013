import React from 'react'
import { Card, Grid, Typography, withStyles, WithStyles } from '@material-ui/core'
import InfoIcon from '@material-ui/icons/Info'
import { colors } from '../../../themes/dapp'

const styles = theme => ({
    root: {
        width: '100%',
        marginTop: theme.spacing.unit,
        backgroundColor: colors.tertiary.light,
        color: theme.palette.common.white,
        padding: theme.spacing.unit * 2,
    },
    message: {
        color: theme.palette.common.white,
    },
})

export interface InformationProperties extends WithStyles {
    message: string
}

export const Information = withStyles(styles)(({ classes, message }: InformationProperties) => {
    return (
        <Card className={classes.root}>
            <Grid container spacing={3} direction="row" alignItems="center">
                <Grid item xs={1}>
                    <InfoIcon />
                </Grid>
                <Grid item xs={11}>
                    <Typography gutterBottom variant="caption" className={classes.message}>
                        {message}
                    </Typography>
                </Grid>
            </Grid>
        </Card>
    )
})
