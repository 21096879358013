import { PurchaseOrder, SupplierPurchaseOrder } from "../purchaseOrder";
import { ListMeta } from "../BaseTypes";
export enum TimeInterval {
    DAY = 'DAY',
    WEEK = 'WEEK',
    MONTH = 'MONTH',
    YEAR = 'YEAR',
  }

  export const TimeIntervalOptions = [
    {
        value: TimeInterval.DAY,
        label: 'Day',
    },
    {
        value: TimeInterval.WEEK,
        label: 'Week',
    },
    {
        value: TimeInterval.MONTH,
        label: 'Month',
    },
    {
        value: TimeInterval.YEAR,
        label: 'Year',
    },
  ];
  export interface Interval {
    startOfIntervalInclusive: Date
    endOfIntervalExclusive: Date
  }


export interface PurchaseOrderForAnalytics extends PurchaseOrder {
  totalSales: number
  totalNet: number
}
  
  export interface PurchaseOrderInterval {
    interval: Interval
    purchaseOrders: Array<PurchaseOrderForAnalytics>
  }

  export interface PurchaseOrderAnalyticsResponse {
    data: Array<PurchaseOrderInterval>
    total: number
  }

  export interface PurchaseOrderAnalyticsQuery extends ListMeta{
    startDate: number
    endDate: number
    interval: string
  }

  export interface SupplierCostAnalyticsQuery extends ListMeta{
    startDate: number
    endDate: number
    interval: string
  }

  export interface SupplierPurchaseOrderAnalyticsResponse {
    data: Array<SupplierPurchaseOrder> 
    total: number
  }
