import React from 'react'
import { Step } from './Step'
import { WithStyles, withStyles } from '@material-ui/core/styles'

const styles = theme => ({
    layout: {
        display: 'flex',
        'flex-direction': 'row',
        paddingTop: theme.spacing(1),
        paddingBottom: theme.spacing(3),
    },
    step: {
        margin: 2,
        width: 'calc(100% /3)',
    },
    default: {
        borderBottom: `4px solid ${theme.palette.common.white}`,
    },
    active: {
        borderBottom: `4px solid ${theme.palette.primary.main}`,
    },
    completed: {
        borderBottom: `4px solid ${theme.palette.primary.main}`,
    },
})

interface StepperProperties extends WithStyles {
    steps: string[]
    activeStep: number
}

const BaseStepper = (props: StepperProperties) => {
    return (
        <div className={props.classes.layout}>
            {props.steps.map((step, index) => (
                <Step key={step} classes={props.classes} active={props.activeStep === index} completed={props.activeStep > index} />
            ))}
        </div>
    )
}

const Stepper = withStyles(styles)(BaseStepper)

export { Stepper }
