import React from 'react'
import { SimpleDialog, TextField } from '@syncfab/machine'

export interface EditDialogProps {
    title: string
    primaryActionText: string
    onPrimaryAction: () => void
    secondaryActionText: string
    onSecondaryAction: () => void
    open: boolean
    onChange: (value: string) => void
    value?: string
    label: string
}

export const EditDialog = ({ title, label, primaryActionText, onPrimaryAction, secondaryActionText, onSecondaryAction, open, onChange, value }: EditDialogProps) => {
    return (
        <SimpleDialog
            title={title}
            content={<TextField label={label} onChange={event => onChange(event.target.value)} value={value} type="text" fullWidth multiline rowsMax="4" />}
            primaryActionText={primaryActionText}
            onPrimaryAction={onPrimaryAction}
            secondaryActionText={secondaryActionText}
            onSecondaryAction={onSecondaryAction}
            open={open}
        />
    )
}
