export const CurrencyFormatter = new Intl.NumberFormat('default', { style: 'currency', currency: 'USD' })
export const CurrencyFormatterDecimal = new Intl.NumberFormat('default', { maximumFractionDigits: 2, style: 'currency', currency: 'USD' })

export const PercentFormatter = new Intl.NumberFormat('default', { style: 'percent', maximumFractionDigits: 2 })
export const PercentIntegerFormatter = new Intl.NumberFormat('default', { style: 'percent', maximumFractionDigits: 0 })
export const IntegerFormatter = new Intl.NumberFormat('default', { maximumFractionDigits: 0 })
export const DecimalFormatter = new Intl.NumberFormat('default', { maximumFractionDigits: 2 })
export const DateTimeFormatter = new Intl.DateTimeFormat('default', { dateStyle: 'short', timeStyle: 'medium' })
export const DateFormatter = new Intl.DateTimeFormat('default', { dateStyle: 'short' })

export const convertMillisToDaysHours = (durationMillis: number): string => {
    const hourMillis = 1000 * 60 * 60
    const dayMillis = hourMillis * 24

    const numDays = Math.floor(durationMillis / dayMillis)
    const numHours = Math.floor( (durationMillis - dayMillis * numDays) / hourMillis)

    return `${numDays} days, ${numHours} hours`
}