import { call, takeLatest, getContext, put } from 'redux-saga/effects'
import { Gateway } from '../gateway'
import {
    GET_TRANSACTIONS,
    GET_BALANCE,
    FETCH_TRANSACTIONS,
    SEND_MFG_TOKENS,
    GET_TRANSACTION_FEE,
    RESET_DATA,
    CREATE_WALLET,
    GET_WALLET_STATUS,
    GET_COST,
    GET_WALLET_ADDRESS,
    MAKE_PAYMENT,
    ACH_PAYMENT,
    GET_NFT_BALANCE,
    CONTACT_SALES,
} from './Actions'
import { SHOW_FEEDBACK } from '@syncfab/machine'

function* getTransaction({ data: { field, direction, filter, size } }: { data: { field: string; direction: number; filter: string; size: number } }) {
    try {
        const gateway: Gateway = yield getContext('gateway')
        const { data } = yield call(gateway.getTransactionAPI().getTransaction(0, field, direction, filter, size))

        yield put(GET_TRANSACTIONS.actions.SUCCESSFULL(data))
    } catch ({ response: { status, data } }) {
        const handleError = yield getContext('handleError')
        yield call(handleError(status))
        yield put(GET_TRANSACTIONS.actions.FAILED(data))
    }
}

function* fetchTransaction({ data: { page, field, direction, filter, size } }: { data: { page: number; field: string; direction: number; filter: string; size: number } }) {
    try {
        const gateway: Gateway = yield getContext('gateway')
        const { data } = yield call(gateway.getTransactionAPI().getTransaction(page, field, direction, filter, size))
        yield put(FETCH_TRANSACTIONS.actions.SUCCESSFULL(data))
    } catch ({ response: { status, data } }) {
        const handleError = yield getContext('handleError')
        yield call(handleError(status))
        yield put(FETCH_TRANSACTIONS.actions.FAILED(data))
    }
}

function* getBalance() {
    try {
        const gateway: Gateway = yield getContext('gateway')

        const response = yield call(gateway.getTransactionAPI().getWalletAddress())
        if (response.data.walletAddress) {
            const { data } = yield call(gateway.getTransactionAPI().getBalance(response.data.walletAddress))
            yield put(GET_BALANCE.actions.SUCCESSFULL(data))
        }
    } catch ({ response: { status, data } }) {
        const handleError = yield getContext('handleError')
        yield call(handleError(status))
        yield put(GET_BALANCE.actions.FAILED(data))
    }
}

function* getNFTBalance() {
    try {
        const gateway: Gateway = yield getContext('gateway')

        const response = yield call(gateway.getTransactionAPI().getWalletAddress())
        if (response.data.walletAddress) {
            const { data } = yield call(gateway.getTransactionAPI().getNFTBalance(response.data.walletAddress))
            yield put(GET_NFT_BALANCE.actions.SUCCESSFULL(data))
        }
    } catch ({ response: { status, data } }) {
        const handleError = yield getContext('handleError')
        yield call(handleError(status))
        yield put(GET_NFT_BALANCE.actions.FAILED(data))
    }
}

function* getTransactionFee() {
    try {
        const gateway: Gateway = yield getContext('gateway')
        const { data } = yield call(gateway.getTransactionAPI().getTransactionFee())
        yield put(GET_TRANSACTION_FEE.actions.SUCCESSFULL(data))
    } catch ({ response: { status, data } }) {
        const handleError = yield getContext('handleError')
        yield call(handleError(status))
        yield put(GET_TRANSACTION_FEE.actions.FAILED(data))
    }
}
function* createWallet() {
    try {
        try {
            const gateway: Gateway = yield getContext('gateway')
            const { data } = yield call(gateway.getTransactionAPI().createWallet())
            yield put(CREATE_WALLET.actions.SUCCESSFULL(data))
            yield call(getWalletStatus)
        } catch ({ response: { status, data } }) {
            const handleError = yield getContext('handleError')
            yield call(handleError(status))
            yield put(CREATE_WALLET.actions.FAILED(data))
        }
    } catch (error) {
        yield put(CREATE_WALLET.actions.FAILED(error))
    }
}

function* getWalletStatus() {
    try {
        try {
            const gateway: Gateway = yield getContext('gateway')
            const { data } = yield call(gateway.getTransactionAPI().getWalletStatus())
            yield put(GET_WALLET_STATUS.actions.SUCCESSFULL(data))
        } catch ({ response: { status, data } }) {
            const handleError = yield getContext('handleError')
            yield call(handleError(status))
            yield put(GET_WALLET_STATUS.actions.FAILED(data))
        }
    } catch (error) {
        yield put(GET_WALLET_STATUS.actions.FAILED(error))
    }
}

function* sendMFGTokens(transactionData: any) {
    try {
        const gateway: Gateway = yield getContext('gateway')
        const { data } = yield call(gateway.getTransactionAPI().sendMfgTokens(transactionData.data))
        yield put(SEND_MFG_TOKENS.actions.SUCCESSFULL(data))
    } catch ({ response: { status, data } }) {
        const handleError = yield getContext('handleError')
        yield call(handleError(status))
        yield put(SEND_MFG_TOKENS.actions.FAILED(data))
    }
}

function* getCost() {
    try {
        const gateway: Gateway = yield getContext('gateway')
        const { data } = yield call(gateway.getTransactionAPI().getCost())
        yield put(GET_COST.actions.SUCCESSFULL(data))
    } catch ({ response: { status, data } }) {
        const handleError = yield getContext('handleError')
        yield call(handleError(status))
        yield put(GET_COST.actions.FAILED(data))
    }
}

function* getWalletAddress() {
    try {
        const gateway: Gateway = yield getContext('gateway')
        const { data } = yield call(gateway.getTransactionAPI().getWalletAddress())

        yield put(GET_WALLET_ADDRESS.actions.SUCCESSFULL(data))
    } catch ({ response: { status, data } }) {
        const handleError = yield getContext('handleError')
        yield call(handleError(status))
        yield put(GET_WALLET_ADDRESS.actions.FAILED(data))
    }
}

function* makePayment(paymentdata: any) {
    try {
        const gateway: Gateway = yield getContext('gateway')
        const { data } = yield call(gateway.getTransactionAPI().makePayment(paymentdata.data.data))
        yield put(MAKE_PAYMENT.actions.SUCCESSFULL(data))
    } catch ({ response: { status, data } }) {
        const handleError = yield getContext('handleError')
        yield call(handleError(status))
        yield put(MAKE_PAYMENT.actions.FAILED(data))
    }
}

function* achPayment(paymentdata: any) {
    try {
        const gateway: Gateway = yield getContext('gateway')
        const { data } = yield call(gateway.getTransactionAPI().achPayment(paymentdata.data.data))
        yield put(ACH_PAYMENT.actions.SUCCESSFULL(data))
    } catch ({ response: { status, data } }) {
        const handleError = yield getContext('handleError')
        yield call(handleError(status))
        yield put(ACH_PAYMENT.actions.FAILED(data))
    }
}

function* reset() {}

function* contactSales(contactSalesData) {
    try {
        try {
            const gateway: Gateway = yield getContext('gateway')
            const { data } = yield call(gateway.getTransactionAPI().contactSales(contactSalesData.data))
            yield put(CONTACT_SALES.actions.SUCCESSFULL(data))
            yield put(SHOW_FEEDBACK('Email sent to sales team'))
            yield call(getWalletStatus)
        } catch ({ response: { status, data } }) {
            const handleError = yield getContext('handleError')
            yield call(handleError(status))
            yield put(CONTACT_SALES.actions.FAILED(data))
            yield put(SHOW_FEEDBACK('Failed to send email. Please contact administrator.'))
        }
    } catch (error) {
        yield put(CONTACT_SALES.actions.FAILED(error))
    }
}

function* transactionSaga() {
    // Typings are not good enough. String should be valid but the types
    // do not show it
    yield takeLatest(GET_TRANSACTIONS.types.TRIGGER as any, getTransaction)
    yield takeLatest(FETCH_TRANSACTIONS.types.TRIGGER as any, fetchTransaction)
    yield takeLatest(GET_BALANCE.types.TRIGGER as any, getBalance)
    yield takeLatest(GET_NFT_BALANCE.types.TRIGGER as any, getNFTBalance)
    yield takeLatest(SEND_MFG_TOKENS.types.TRIGGER as any, sendMFGTokens)
    yield takeLatest(GET_TRANSACTION_FEE.types.TRIGGER as any, getTransactionFee)
    yield takeLatest(CREATE_WALLET.types.TRIGGER as any, createWallet)
    yield takeLatest(RESET_DATA.types.TRIGGER as any, reset)
    yield takeLatest(GET_WALLET_STATUS.types.TRIGGER as any, getWalletStatus)
    yield takeLatest(GET_COST.types.TRIGGER as any, getCost)
    yield takeLatest(GET_WALLET_ADDRESS.types.TRIGGER as any, getWalletAddress)
    yield takeLatest(MAKE_PAYMENT.types.TRIGGER as any, makePayment)
    yield takeLatest(ACH_PAYMENT.types.TRIGGER as any, achPayment)
    yield takeLatest(CONTACT_SALES.types.TRIGGER as any, contactSales)
}

export default transactionSaga
