import { ListQuery } from "./ListQuery";

export const enum POListViewOption {
    PURCHASE_ORDER = 'Purchase Orders',
    LINE_ITEM = 'Line Items'
}
export interface PurchaseOrderQuery extends ListQuery {
    companyId?: string
    poStatus?: string[]
    poLineItemStatus?: string[]
    newDockDateRange?: string | null
    viewBy: POListViewOption
}