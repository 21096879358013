import * as React from 'react'
import { TableHead, TableRow, TableCell, Checkbox, Tooltip, TableSortLabel, withStyles, Grid, Theme } from '@material-ui/core'
import InfoIcon from '@material-ui/icons/Info'

export interface IColumnData {
    id?: string
    numeric?: boolean
    disablePadding?: boolean
    label?: string
}

interface IEnhancedTableHeadProps {
    onRequestSort?: (event: any, property: any) => any
    onSelectAllClick?: any
    order?: any
    orderBy?: any
    selected?: number
    count?: number
    columns?: IColumnData[]
    classes?: any
    page?: string
}

class EnhancedTableHead extends React.Component<IEnhancedTableHeadProps, {}> {
    private createSortHandler = (property: any) => (event: any) => {
        this.props.onRequestSort(event, property)
    }

    public render(): JSX.Element {
        const { columns, onSelectAllClick, order, orderBy, count, page } = this.props
        const { selected, classes } = this.props

        return (
            <TableHead>
                <TableRow>
                    {page != 'suppliers' && (
                        <TableCell padding="checkbox">
                            <Checkbox color="primary" indeterminate={selected > 0 && selected < count} checked={selected === count} onChange={onSelectAllClick} classes={{ root: classes.checkbox }} />
                        </TableCell>
                    )}

                    {columns.map((column, index) => {
                        return (
                            <TableCell align="left" key={column.id} padding={column.disablePadding ? 'none' : 'default'} sortDirection={orderBy === column.id ? order : false}>
                                <TableSortLabel className={classes.headerStyle} active={orderBy === column.id} direction={order} onClick={this.createSortHandler(column.id)}>
                                    {index == 0 ? (
                                        <Grid container item xs={12} direction="row">
                                            <Grid>
                                                <Tooltip title="Sort" placement={column.numeric ? 'bottom-end' : 'bottom-start'} enterDelay={300}>
                                                    <Grid>{column.label}</Grid>
                                                </Tooltip>
                                                {/* <Typography color="textSecondary" variant="h6">
                                                Transaction/Gas Fee{' '}
                                            </Typography> */}
                                            </Grid>
                                            {page != 'suppliers' && (
                                                <Grid>
                                                    <Tooltip
                                                        title={
                                                            'Adding a MFGPRT TokenID to your part creates an immutable blockchain record of your part that secures its IP data and allows more robust Track & Trace features.'
                                                        }
                                                        placement={column.numeric ? 'bottom-end' : 'bottom-start'}
                                                        enterDelay={300}
                                                    >
                                                        <InfoIcon color="action" fontSize="small" className={classes.snackText} />
                                                    </Tooltip>
                                                </Grid>
                                            )}
                                        </Grid>
                                    ) : (
                                        <Tooltip title="Sort" placement={column.numeric ? 'bottom-end' : 'bottom-start'} enterDelay={300}>
                                            <Grid>{column.label}</Grid>
                                        </Tooltip>
                                    )}
                                </TableSortLabel>
                            </TableCell>
                        )
                    }, this)}
                </TableRow>
            </TableHead>
        )
    }
}

const tableheadStyle = (theme: Theme) => ({
    root: {},

    checkbox: {
        color: '#363636',
    },
    headerStyle: {
        color: '#000',
        fontWeight: 600,
        fontSize: '14px',
    },
    snackText: {
        marginLeft: theme.spacing(1),
        color: theme.palette.grey['400'],
    },
})
//export default EnhancedTableHead as any

export default withStyles(tableheadStyle)(EnhancedTableHead as any)
