import React, { FC } from "react";
import { Grid, Theme, Typography } from "@material-ui/core";
import ArrowBack from "@material-ui/icons/ArrowBack";
import { Link } from "react-router-dom";
import { createStyles, makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme: Theme) => createStyles({
    header: {
        marginTop: theme.spacing(1),
        fontSize: '14px',
        display: 'flex',
        alignItems: 'center',
    },
    link: {
        display: 'flex',
        textDecoration: 'none',
    },
    arrow: {
        color: theme.palette.primary.main,
        marginRight: theme.spacing(1),
        marginTop: theme.spacing(0.5),
        fontSize: '14px',
    },
    detailsHeader: {
        fontSize: '14px',
        letterSpacing: '1.25px',
        color: '#B3B3B3'
    },
    linkText: {
        color: theme.palette.primary.main,
    }
}))

export interface HeaderNavLinkProps {
    linkText: string
    linkTo: string
    preLinkText?: string
}

export const HeaderNavLink: FC<HeaderNavLinkProps> = ({ linkText, linkTo, preLinkText = '' }: HeaderNavLinkProps) => {
    const classes = useStyles()

    return (
        <Grid container className={classes.header}>
            {!!preLinkText && (
                <Typography gutterBottom variant="h6" className={classes.detailsHeader}>
                    {preLinkText}&nbsp;
                </Typography>
            )}
            <Link
                to={linkTo}
                className={classes.link}
            >
                { !preLinkText && <ArrowBack className={classes.arrow}/>}
                <Typography gutterBottom variant="h6" className={`${classes.detailsHeader} ${classes.linkText}`}>
                    {`${linkText}`}
                </Typography>
            </Link>
        </Grid>
    )
}