import React from 'react'
import { Helmet } from 'react-helmet'
import { withNamespaces, WithNamespaces } from 'react-i18next'
import { Dispatch } from 'redux'
import { WithStyles } from '@material-ui/core/styles'
import { connect } from 'react-redux'
import Typography from '@material-ui/core/Typography'
import { Card, withStyles } from '@material-ui/core'
import { CenteredContainer } from '../../layout/Container'
import { Button } from '@syncfab/machine'
import { FORGOT_PASSWORD } from '../../components/user/Actions'
import { State } from '../../application/Store'

const styles = theme => ({
    card: {
        width: '30%',
        margin: 'auto',
        padding: theme.spacing(2),
        display: 'flex',
        'justify-content': 'center',
        'flex-direction': 'column',
    },
    '@media (max-width:800px)': {
        card: {
            width: '85%',
            margin: 'auto',
            padding: theme.spacing(2),
            display: 'flex',
            'justify-content': 'center',
            'flex-direction': 'column',
        },
    },
    text: {
        color: theme.palette.common.white,
        marginBottom: theme.spacing(3),
    },
})

interface ForgotPasswordConfirmationProps extends WithNamespaces, WithStyles {
    forgotPassword: (email: string) => void
    email: string
}

class ForgotPasswordConfirmationView extends React.Component<ForgotPasswordConfirmationProps> {
    constructor(props: ForgotPasswordConfirmationProps) {
        super(props)
        this.resend = this.resend.bind(this)
    }

    public render() {
        const { classes, t } = this.props
        return (
            <CenteredContainer>
                <Helmet>
                    <title>{`${t('forget-password-confirmation-header')}`}</title>
                </Helmet>
                <Card className={classes.card}>
                    <Typography variant="h2" gutterBottom className={classes.text}>
                        {`${t('forget-password-confirmation-title')}`}
                    </Typography>
                    <Typography variant="body1" gutterBottom className={classes.text}>
                        {`${t('forget-password-confirmation-body')}`}
                    </Typography>
                    <Button color="primary" variant="outlined" onClick={this.resend}>
                        {t('forget-password-resend')}
                    </Button>
                </Card>
            </CenteredContainer>
        )
    }

    private resend() {
        this.props.forgotPassword(this.props.email)
    }
}

const mapStateToProps = (state: State) => {
    return {
        email: state.user.forgotPasswordEmail,
    }
}

const mapDispatchToProps = (dispatch: Dispatch) => {
    return {
        forgotPassword: (email: string) => dispatch(FORGOT_PASSWORD.actions.TRIGGER({ email })),
    }
}

const forgotPasswordConfirmationWithTranslations = withNamespaces()(withStyles(styles)(ForgotPasswordConfirmationView))

export const ForgotPasswordConfirmation = connect(
    mapStateToProps,
    mapDispatchToProps
)(forgotPasswordConfirmationWithTranslations)
