import React from 'react'
import { Typography } from '@material-ui/core'

type WithInfiniteScrollProps = {
    fetch: () => void
    isLoading: boolean
    hasNext: boolean
}

export const withInfiniteScroll = (id, Component) =>
    (class WithInfiniteScroll<T> extends React.Component<WithInfiniteScrollProps & T> {
        componentDidMount() {
            window.addEventListener('scroll', this.onScroll, false)
        }

        componentWillUnmount() {
            window.removeEventListener('scroll', this.onScroll, false)
        }

        onScroll = () => {
            const shouldFetch = window.scrollY >= document.getElementById(id).offsetHeight - 400 && !this.props.isLoading && this.props.hasNext
            if (shouldFetch) {
                this.props.fetch()
            }
        }

        render() {
            if (this.props.isLoading) {
                return (
                    <React.Fragment>
                        <Component {...this.props} />
                        <Typography gutterBottom variant="h3">
                            Loading...
                        </Typography>
                    </React.Fragment>
                )
            }

            return <Component {...this.props} />
        }
    })
