import { ListQuery } from "./ListQuery";

export const enum SupplierRFQActivityViewOption {
    RFQ = 'rfqs',
    LINE_ITEM = 'lineItems'
}

export interface SupplierQuoteQuery extends ListQuery {
    supplierId: string
    supplierQuoteStatus: string
    rfqStatus: string
    viewBy: SupplierRFQActivityViewOption
}
