import { ListParameters, UserIdAndName } from '../BaseTypes'
import { Document } from '../document/Document'
import { PurchaseOrder, PurchaseOrderLineItem } from '../purchaseOrder'
import { RFQ } from '../quote'
import { ValueLabelOption } from '../ui'

export enum NCRLogStatus {
    OPEN = 'Open',
    CLOSED = 'Closed',
}
export enum IssuerType {
    INTERNAL = 'Internal',
    CUSTOMER = 'Customer',
    SUPPLIER = 'Supplier',
}

export interface NCRLogInput {
    poId: string
    referenceId?: string
    status: string
    buyerCompany: {
        companyId: string
    }
    selectedSupplier: {
        supplierId: string
    }
    problemStatement?: string
    correctionAction?: string
    issuer: string
    documentIds: string[]
}

export interface NCRLog extends NCRLogInput {
    _id: string
    buyerCompany: {
        companyId: string
        companyName: string
    }
    selectedSupplier: {
        supplierId: string
        supplierName: string
    }
    documents: Document[]
    createdAt: Date
    createdBy: UserIdAndName
    updatedAt?: Date
    updatedBy?: UserIdAndName
    deletedAt?: Date
    deletedBy?: UserIdAndName
}

export enum ExceptionsLogCategoryType {
    MATERIAL = 'Material',
    PROCESS = 'Process',
    HUMAN_ERROR = 'Human Error',
}

export enum ExceptionsLogOriginType {
    INTERNAL = 'Internal',
    SUPPLIER = 'Supplier',
    CUSTOMER = 'Customer',
}
export const ExceptionsLogCategoryOptions: ValueLabelOption[] = [
    { value: ExceptionsLogCategoryType.MATERIAL, label: ExceptionsLogCategoryType.MATERIAL},
    { value: ExceptionsLogCategoryType.PROCESS, label: ExceptionsLogCategoryType.PROCESS},
    { value: ExceptionsLogCategoryType.HUMAN_ERROR, label: ExceptionsLogCategoryType.HUMAN_ERROR}
];

export const ExceptionsLogOriginOptions: ValueLabelOption[] = [
    { value: ExceptionsLogOriginType.INTERNAL, label: ExceptionsLogOriginType.INTERNAL},
    { value: ExceptionsLogOriginType.SUPPLIER, label: ExceptionsLogOriginType.SUPPLIER},
    { value: ExceptionsLogOriginType.CUSTOMER, label: ExceptionsLogOriginType.CUSTOMER},
];

export enum ExceptionsLogStatusType {
    OPEN = 'Open',
    CLOSED = 'Closed',
}

export const ExceptionsLogStatusOptions: ValueLabelOption[] = [
    { value: ExceptionsLogStatusType.OPEN, label: ExceptionsLogStatusType.OPEN},
    { value: ExceptionsLogStatusType.CLOSED, label: ExceptionsLogStatusType.CLOSED},
];

export enum ExceptionsLogRootType {
    RFQ_ERROR = 'RFQ Error',
    PO_ACCEPTANCE_ERROR = 'PO Acceptance Error',
    PRODUCTION_PRINT_FLOWDOWN_ERROR = 'Production Print Flowdown Error',
    MATERIAL_REQUIREMENT_FLOWDOWN_ERROR = 'Material Requirement Flowdown Error',
    MANUFACTURING_STANDARD_FLOWDOWN_ERROR = 'Material Standard Flowdown Error',
    INSPECTION_REQUIREMENT_FLOWDOWN_ERROR = 'Inspection Requirement Flowdown Error',
    INSPECTION_ERROR = 'Inspection Error',
    INSPECTION_LATENESS = 'Inspection Lateness',
    PACKAGING_REQUIREMENT_FLOWDOWN_ERROR = 'Packaging Requirement Flowdown Error',
    SHIPPING_REQUIREMENT_FLOWDOWN_ERROR = 'Shipping Requirement Flowdown Error',
    SHIPPING_ADDRESS_ERROR = 'Shipping Address Error',
    SHIPPING_ACCOUNT_ERROR = 'Shipping Account Error',
    SHIPPING_METHOD_ERROR = 'Shipping Method Error',
    SHIPPING_LATENESS = 'Shipping Lateness',
    PO_ISSUED_TO_INCORRECT_DRAWING = 'PO Issued to Incorrect RFQ/Drawing',
    PO_REORDER_TO_EXPIRED_PRICED = 'PO Reorder to Expired Price - Revise PO to Updated Price',
    PO_DESIGN_REVISION = 'PO Design Revision',
    PO_MANUFACTURING_STANDARD_REVISION = 'PO Manufacturing Standard Revision',
    PO_MATERIAL_REVISION = 'PO Material Revision',
    PO_INSPECTION_REQUIREMENT_REVISION = 'PO Inspection Requirement Revision',
    PO_PLACED_ON_HOLD = 'PO Placed on Hold',
    PO_CANCELED = 'PO Canceled',
    MADE_TO_WRONG_PRINT = 'Made to Wrong Production Print',
    PRINT_SPECIFICATION_NON_CONFORMANCE = 'Print Specification GD&T Non-Conformance',
    MANUFACTURING_STANDARD_NON_CONFORMANCE = 'Manufacturing Standard Non-Conformance',
    MATERIAL_NON_CONFORMANCE = 'Material Non-Conformance',
    FINISH_NON_CONFORMANCE = 'Finish Non-Conformance',
    INSPECTION_NON_CONFORMANCE = 'Inspection Non-Conformance',
    DOCUMENTATION_NON_CONFORMANCE = 'Documentation Non-Conformance',
    PACKAGING_NON_CONFORMANCE = 'Packaging Non-Conformance',
    PRODUCITON_COMPLETION_LATENESS = 'Production Completion Lateness',
    SHIPPING_PICKUP_DROPOFF_ERROR = 'Shipping Pickup/Dropoff Error',
    REMAKE_SHIPPING_CHARGES = 'Remake Shipping Charges',
}

export const ExceptionsLogRootOptions: ValueLabelOption[] = Object.values(ExceptionsLogRootType).sort().map(val => {
    return {
        value: val,
        label: val,
    }
})

export enum ExceptionType {
    NCR = 'NCR',
    CAR = 'CAR',
    RMA = 'RMA',
}

export const ExceptionTypeOptions: ValueLabelOption[] = [
    { value: ExceptionType.NCR, label: ExceptionType.NCR },
    { value: ExceptionType.CAR, label: ExceptionType.CAR },
    { value: ExceptionType.RMA, label: ExceptionType.RMA }
]

export enum ExceptionStatus {
    OPEN = 'Open',
    CLOSED = 'Closed',
}

export const ExceptionStatusOptions: ValueLabelOption[] = [
    { value: ExceptionStatus.OPEN, label: ExceptionStatus.OPEN },
    { value: ExceptionStatus.CLOSED, label: ExceptionStatus.CLOSED },
];

export interface OrderDetails {
    selected: boolean,
    affected: number | null,
    completed: number | null,
    remaining: number | null,
    note: string | null,
}
export interface ImpactCosts {
    selected: boolean,
    costType: string, 
    originalCost: number | null
    newCost: number | null
}

export interface ProblemDescription {
    selected: boolean,
    problemDescription: string | null,
}

export interface CorrectiveAction {
    selected: boolean,
    actionItem: string | null,
    assignee: string | null,
    dueDate: string | null,
}

export interface PreventativeAction {
    selected: boolean,
    preventativeAction: string | null,
}

export interface PurchaseOrderNCR {
    selected: boolean,
    lineItem: string | null,
    affected: number | null,
    completed: number | null,
    remaining: number | null,
    note: string | null,
}

export interface RFQNCR {
    selected: boolean,
    lineItem: string | null,
    affected: number | null,
    completed: number | null,
    remaining: number | null,
    note: string | null,
}
export interface Exception {
    _id: string,
    type: ExceptionType,
    origin: ExceptionsLogOriginType,
    category: ExceptionsLogCategoryType,
    status: ExceptionsLogStatusType,
    rootCause: ExceptionsLogRootType,
    assignee: string | null,
    relatedExceptions: string[] | null,
    purchaseOrder?: string | null, 
    rfq?: string | null, 
    rfqDetails?: RFQ,
    purchaseOrders?: PurchaseOrderNCR[] | null, 
    purchaseOrderLineItemDetails?: PurchaseOrderLineItem[],
    purchaseOrderDetails?: PurchaseOrder,
    rfqs?: RFQNCR[] | null,
    orderDetails: OrderDetails[],
    createdBy: UserIdAndName,
    createdAt: string,

    // Order Details section in all components
    // root cause section in NCR
    problemDescription?: string | null,
    rootCauseDescription?: string | null,
    correctiveAction?: string | null
    // containment section
    initialReportDate?: string | null,
    completionDate?: string | null,
    partsContained?: string | null,
    suspectContained?: string | null,
    otherFailure?: string | null,
    // Impact costs section
    additionalNotes?: string | null,
    impactCosts?: ImpactCosts[] | null
    // Car Section
    problemDescriptions?: ProblemDescription
    correctiveActions?: CorrectiveAction[]
    preventativeActions?:  PreventativeAction[]
}

export interface NCRQuery extends ListParameters {
    type?: string
}