import {
  BusinessClassificationOptions,
  Industry,
  SupplierCompanyTypeOptions,
  convertEnumToValueLabel,
  TagType,
  RHFAutoCompletePrimitive,
  leadTimeOptionsRequired,
  materialsRequired,
  finishesRequired,
  manufacturingProcessesRequired
} from '@syncfab/machine';
import React from 'react';
import { useStyles } from './common';
import { Grid, Paper } from '@material-ui/core';
import { useFormContext } from 'react-hook-form';
import { useAppSelector } from '../../../application/hooks';
import { getSupplierTagByArray } from '../../../components/supplier/Selector';
const CapabilitiesForm = () => {
  const classes = useStyles();
  const methods = useFormContext();
  const companyTypeWatch = methods.watch('companyType');
  const supplierTagSelectorMemo = () =>
    getSupplierTagByArray([
      TagType.MANUFACTURING,
      TagType.MATERIAL_PREFERENCES,
      TagType.LOT_SIZE,
      TagType.LEAD_TIME,
      TagType.FINISHING,
      TagType.PRODUCT_PREFERENCES,
      TagType.AFFILIATE_ORGANIZATIONS,
    ]);
  const supplierTagsMemoSelector = React.useMemo(supplierTagSelectorMemo, []);
  const supplierTags = useAppSelector(supplierTagsMemoSelector);
  const {
    [TagType.MANUFACTURING]: manufacturingProcessOptions,
    [TagType.MATERIAL_PREFERENCES]: materialOptions,
    [TagType.LOT_SIZE]: lotSizeOptions,
    [TagType.LEAD_TIME]: leadTimeOptions,
    [TagType.FINISHING]: finishOptions,
    [TagType.PRODUCT_PREFERENCES]: productPreferenceOptions,
    [TagType.AFFILIATE_ORGANIZATIONS]: affiliateOrganizationsOptions,
  } = supplierTags;

  return (
    <Paper className={classes.formBackground}>
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <RHFAutoCompletePrimitive
            options={SupplierCompanyTypeOptions}
            label="Type of Company"
            name="companyType"
            required
          />
        </Grid>
        <Grid item xs={6}>
          <RHFAutoCompletePrimitive
            options={manufacturingProcessOptions}
            renderCheckboxes
            multiple
            required={
              !!(manufacturingProcessesRequired.find(m => m === companyTypeWatch))
            }
            label="Manufacturing Capabilities"
            name="manufacturingProcesses"
          />
        </Grid>
        <Grid item xs={6}>
          <RHFAutoCompletePrimitive
            options={finishOptions}
            renderCheckboxes
            multiple
            required={
              !!(finishesRequired.find(m => m === companyTypeWatch))
            }
            label="Finish Capabilities"
            name="finishes"
          />
        </Grid>
        <Grid item xs={6}>
          <RHFAutoCompletePrimitive
            options={lotSizeOptions}
            renderCheckboxes
            multiple
            required
            label="Lot Size Preference"
            name="lotSize"
          />
        </Grid>
        <Grid item xs={6}>
          <RHFAutoCompletePrimitive
            options={leadTimeOptions}
            renderCheckboxes
            multiple
            required={
              !!(leadTimeOptionsRequired.find(m => m === companyTypeWatch))
            }
            label="Lead Time Preference"
            name="leadTime"
          />
        </Grid>
        <Grid item xs={6}>
          <RHFAutoCompletePrimitive
            options={materialOptions}
            renderCheckboxes
            multiple
            required={
              !!(materialsRequired.find(m => m === companyTypeWatch))
            }
            label="Material Preference"
            name="materials"
          />
        </Grid>
        <Grid item xs={6}>
          <RHFAutoCompletePrimitive
            options={productPreferenceOptions}
            renderCheckboxes
            multiple
            required={false}
            label="Product Preferences"
            name="productPreference"
          />
        </Grid>
        <Grid item xs={6}>
          <RHFAutoCompletePrimitive
            options={affiliateOrganizationsOptions}
            renderCheckboxes
            multiple
            label="Affiliate Organizations"
            name="affiliateOrganizations"
          />
        </Grid>
        <Grid item xs={6}>
          <RHFAutoCompletePrimitive
            options={convertEnumToValueLabel(Industry)}
            renderCheckboxes
            multiple
            required
            label="Industries"
            name="industry"
          />
        </Grid>
        <Grid item xs={6}>
          <RHFAutoCompletePrimitive
            options={BusinessClassificationOptions}
            renderCheckboxes
            multiple
            label="Business Classification"
            name="businessClassification"
          />
        </Grid>
      </Grid>
    </Paper>
  );
};

export default CapabilitiesForm;
