import { Card, Grid, IconButton, Theme, Typography } from "@material-ui/core";
import RoomIcon from "@material-ui/icons/Room";
import React, { FC } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Address } from "../Address";

const useStyles = makeStyles<Theme>(theme => ({
    iconButton: {
        width: theme.spacing(4),
        height: theme.spacing(4),
        backgroundColor: theme.palette.customComponent.iconBackgroundColor,
        color: theme.palette.customComponent.iconColor,
        borderRadius: "50%",
        padding: "8px",
        marginLeft: "8px"
    },
    card: {
        width: '100%',
        marginBottom: '10px',
        backgroundColor: theme.palette.customComponent.backgroundColor,
        padding: theme.spacing(2),
        border: `1px solid ${theme.palette.customComponent.cardBorderColor}`
    },
    titleText: {
        color: theme.palette.common.white,
        fontSize: 14,
        fontWeight: 'bold',
    },
    contentText: {
         color: theme.palette.common.white,
         fontSize: 14,
    },
}))

export interface AddressDisplayProps {
    address: Address
    title: string
}

export const AddressDisplay: FC<AddressDisplayProps> = ({ address, title }) => {

    const classes = useStyles()

    return (
        <Card className={classes.card}>
            <Grid container spacing={2} direction="row">
                <Grid container item xs={2} justifyContent='center'>
                    <IconButton className={classes.iconButton}>
                        <RoomIcon fontSize='medium' />
                    </IconButton>
                </Grid>
                <Grid item xs={10}>
                    {!!title && (
                        <Typography className={classes.titleText} gutterBottom>
                            {title}
                        </Typography>
                    )}
                    <Typography className={classes.content} component="p">
                        {address?.location.line}
                    </Typography>
                    {!!address?.location.line2 && (
                        <Typography className={classes.content} component="p">
                            {address.location.line2}
                        </Typography>
                    )}
                    <Typography className={classes.content} component="p">
                        {address?.location.city}, {address?.location.state} {address?.location.zipCode}
                    </Typography>
                    <Typography className={classes.content} component="p">
                        {address?.location.country}
                    </Typography>
                </Grid>
            </Grid>
        </Card>
    )
}