import { State } from '../../../application/Store'
import { createSelector } from 'reselect'
import { RFQStatusFilter, RFQStatus } from '@syncfab/machine'

const getAllRFQs = (state: State) => {
    return state.rfq.rfqs
}

const getFilter = (state: State) => {
    return state.rfq.rfqSelectedFilter
}

const getRFQ = (state: State) => {
    return state.rfq.rfq
}

const getAddresses = (state: State) => {
    return state.company.addresses
}

export const getRFQSelector = () => {
    return createSelector(
        [getAllRFQs, getFilter],
        (rfqs, filter) => {
            if (!rfqs) {
                return null
            }
            switch (filter) {
                case RFQStatusFilter.ALL:
                    return rfqs
                // case RFQStatusFilter.PENDING:
                //     return rfqs.filter(rfq => rfq.status === RFQStatus.PENDING)
                case RFQStatusFilter.OPEN:
                    return rfqs.filter(rfq => rfq.status === RFQStatus.OPEN)
                // case RFQStatusFilter.ARCHIVED:
                //     return rfqs.filter(rfq => rfq.status === RFQStatus.REJECTED || rfq.status === RFQStatus.EXPIRED || rfq.status === RFQStatus.DECLINED || rfq.status === RFQStatus.ACCEPTED)
                case RFQStatusFilter.ACCEPTED:
                    return rfqs.filter(rfq => rfq.status === RFQStatus.POISSUED)
                case RFQStatusFilter.QUOTED:
                    return rfqs.filter(rfq => rfq.status === RFQStatus.QUOTED)
                default:
                    return rfqs
            }
        }
    )
}

export const getRFQFilterCountSelector = () => {
    return createSelector(
        [getAllRFQs],
        rfqs => {
            return {
                [RFQStatusFilter.ALL]: (rfqs || []).length,
                // [RFQStatusFilter.PENDING]: (rfqs || []).filter(rfq => rfq.status === RFQStatus.PENDING).length,
                [RFQStatusFilter.OPEN]: (rfqs || []).filter(rfq => rfq.status === RFQStatus.OPEN).length,
                // [RFQStatusFilter.ARCHIVED]: (rfqs || []).filter(
                //     rfq => rfq.status === RFQStatus.REJECTED || rfq.status === RFQStatus.EXPIRED || rfq.status === RFQStatus.DECLINED || rfq.status === RFQStatus.ACCEPTED
                // ).length,
                [RFQStatusFilter.ACCEPTED]: (rfqs || []).filter(rfq => rfq.status === RFQStatus.POISSUED).length,
                [RFQStatusFilter.QUOTED]: (rfqs || []).filter(rfq => rfq.status === RFQStatus.QUOTED).length,
            }
        }
    )
}

export const getBillingAddressSelector = () => {
    return createSelector(
        [getRFQ, getAddresses],
        (rfq, addresses) => {
            if (!rfq || !addresses) {
                return null
            }
            return addresses.find(address => address._id === rfq.billingAddressId)
        }
    )
}

export const getShippingAddressSelector = () => {
    return createSelector(
        [getRFQ, getAddresses],
        (rfq, addresses) => {
            if (!rfq || !addresses) {
                return null
            }
            return addresses.find(address => address._id === rfq.shippingAddressId)
        }
    )
}
