import {
  Box,
  Card,
  CardContent,
  Grid,
  Typography,
  useTheme,
} from '@material-ui/core';
import { AutoCompleteValueLabel } from '../ui';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import React, { FC } from 'react';
import {
  CompanyMetricTimeFrameData,
  CompanyMetricTimeFrameOptionDisplayMap,
} from '../company';
import { valueOf } from '../supplier';

export interface Metric {
  name: string;
  level: number;
  formattedValue: string;
}
interface TimeFrame {
  value: CompanyMetricTimeFrameData;
  label: valueOf<typeof CompanyMetricTimeFrameOptionDisplayMap>;
}

interface MetricsCardProps {
  name: string;
  title: string;
  metrics: Metric[];
  timeFrame?: TimeFrame;
  setTimeFrame?: React.Dispatch<React.SetStateAction<TimeFrame>>;
  colors?: string[],
}

export const MetricsCard: FC<MetricsCardProps> = ({
  name,
  title,
  metrics,
  timeFrame,
  setTimeFrame,
  colors = [
    '#40B4BF',
    '#6366F1',
    '#40BF71',
    '#FFBA00',
    '#BF4B40'
  ]
}: MetricsCardProps) => {
  const theme = useTheme();
  return (
    <Card>
      <CardContent>
        <Grid container>
          <Grid item xs={6}>
            <Typography variant="h6">{title}</Typography>
          </Grid>
          <Grid item xs={6}>
            <AutoCompleteValueLabel
              name={`${name}MetricsTimeFrame`}
              label="Time Frame"
              options={Object.keys(CompanyMetricTimeFrameOptionDisplayMap).map(
                c => {
                  return {
                    value: c,
                    label: CompanyMetricTimeFrameOptionDisplayMap[c],
                  };
                }
              )}
              onChangeHandler={!!setTimeFrame ? setTimeFrame : () => {}}
              value={
                timeFrame
              }
              disabled={!setTimeFrame}
            />
          </Grid>
        </Grid>
        <Grid container style={{ marginTop: '24px' }} alignItems="flex-start">
          <Grid
            container
            item
            xs={6}
            justifyContent="flex-start"
            style={{ marginBottom: '8px' }}
          >
            <Typography variant="body1" style={{ fontWeight: 'bold' }}>
              DATA POINT
            </Typography>
          </Grid>
          <Grid
            container
            item
            xs={6}
            justifyContent="flex-end"
            style={{ marginBottom: '8px' }}
          >
            <Typography variant="body1" style={{ fontWeight: 'bold' }}>
              VALUE
            </Typography>
          </Grid>
          {metrics.map((metric, index) => {
            return (
              <>
                <Grid
                  container
                  item
                  xs={6}
                  key={index}
                  style={{
                    marginTop: '16px',
                    paddingLeft: theme.spacing(4 * metric.level),
                  }}
                >
                  <Box
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      alignItems: 'center',
                    }}
                  >
                    <FiberManualRecordIcon
                      fontSize="small"
                      style={{ color: colors[index % colors.length] }}
                    />
                    <Typography variant="body1" style={{ paddingLeft: '8px' }}>
                      {metric.name}
                    </Typography>
                  </Box>
                </Grid>
                <Grid
                  container
                  item
                  xs={6}
                  justifyContent="flex-end"
                  style={{ marginTop: '16px' }}
                >
                  <Typography variant="body1">
                    {metric.formattedValue}
                  </Typography>
                </Grid>
              </>
            );
          })}
        </Grid>
      </CardContent>
    </Card>
  );
};
