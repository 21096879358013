import React from 'react'
import { RFQ, Loader } from '@syncfab/machine'
import { withStyles, WithStyles } from '@material-ui/core/styles'
import TableCell from '@material-ui/core/TableCell'
import TableRow from '@material-ui/core/TableRow'
import { withNamespaces, WithNamespaces } from 'react-i18next'
import { List as BaseList } from '../../../../layout/list/List'
import { ListMeta } from '../../../../layout/list/Meta'
import { RFQQuoteRow } from './RFQQuoteRow'

const styles = _ => ({})

export interface QuoteListProps extends WithStyles, WithNamespaces {
    rfqs: RFQ[]
    meta: ListMeta
    getRFQs: (meta: ListMeta) => void
    edit: (id: string, status: string) => void
    showStatusFilter?: boolean
}
interface QuoteListState {
    selectedFilterStatus: string
}
class List extends React.Component<QuoteListProps, QuoteListState> {
    constructor(props: QuoteListProps) {
        super(props)
        this.state = {
            selectedFilterStatus: '',
        }
    }
    private ROWS = [
        { id: '', label: '', fixed: true },
        { id: '_id', label: 'RFQ ID' },
        { id: 'status', label: 'Status' },
        { id: 'buyerRFQReference', label: 'RFQ Reference' },
        { id: 'buyerContact.userName', label: 'Buyer Contact' },
        { id: 'createdAt', label: 'Created On' },
        { id: 'quoteDeadline', label: 'Quote Due' },
        { id: 'numLineItems', label: 'Line Items', fixed: true },

        // { id: 'salesperson', label: 'Salesperson' },
        // { id: 'numSupplierQuotes', label: 'Quotes In', fixed: true },
        // { id: 'buyerCompany.companyName', label: 'Buyer' },
        { id: '', label: 'Quote Total', fixed: true },
    ]

    private statusArr = [
        { value: 'DRAFT', label: 'Draft' },
        { value: 'AWAITING QUOTE', label: 'Awaiting Quote' },
        { value: 'REVIEW QUOTE', label: 'Review Quote' },
        { value: 'CLOSED', label: 'Closed' },
        { value: 'POISSUED', label: 'PO Issued' },
    ]

    render() {
        const { rfqs, meta, edit } = this.props
        // const emptyRows = meta.size - Math.min(meta.size, meta.total - meta.page * meta.size)
        if (!rfqs) {
            return <Loader />
        }

        return (
            <BaseList
                elements={rfqs}
                rows={this.ROWS}
                onChange={this.props.getRFQs}
                meta={meta}
                statusArray={this.statusArr}
                showStatusFilter={true}
                renderMenu={_ => {
                    return null
                }}
                renderBody={(elements: RFQ[]) => {
                    return (
                        <React.Fragment>
                            {elements?.length > 0 &&
                                elements.map(rfq => {
                                    return <RFQQuoteRow rfq={rfq} edit={edit} statusArr={this.statusArr} />
                                })}
                            {elements?.length  === 0 && (
                                <TableRow>
                                    <TableCell colSpan={12} style={{textAlign:'center',border:'none'}}>No RFQ found</TableCell>
                                </TableRow>
                            )}
                        </React.Fragment>
                    )
                }}
            />
        )
    }
}
export const RFQQuoteList = withNamespaces()(withStyles(styles)(List))
