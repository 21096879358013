import React from 'react'
import { Order, SimpleDialog } from '@syncfab/machine'
import { WithNamespaces, withNamespaces } from 'react-i18next'
import TableCell from '@material-ui/core/TableCell'
import TableRow from '@material-ui/core/TableRow'
import { connect } from 'react-redux'
import { WithStyles, withStyles, Checkbox, Grid, Typography, Button, SnackbarContent, IconButton, Box } from '@material-ui/core'
import Helmet from 'react-helmet'
import { CenteredAlignedContainerV2 } from '../../layout/Container'
import EnhancedTableToolbar from './components/EnhancedTableToolbar'
import { Link } from 'react-router-dom'
import { Dispatch } from 'redux'
import { State } from '../../application/Store'
import { SET_PARTS_FOR_TOKENIZATION, DELETE_PARTS_BY_IDS, GET_PARTS, SET_PART_FOR_EDIT } from '../../components/part/Actions'
import { GET_LOGGED_USER } from '../../components/user/Actions'
import { User, PUSH, Document } from '@syncfab/machine'
import { Snackbar } from '@material-ui/core'
import ErrorIcon from '@material-ui/icons/Error'
import CloseIcon from '@material-ui/icons/Close'
// import CircularProgress from '@material-ui/core/CircularProgress'
// import Chip from '@material-ui/core/Chip'
import { PART_ADD } from '../../components/quote/rfq/Actions'
import { ListMeta } from '../../layout/list/Meta'
import { List as BaseList } from '../../layout/list/List'

const styles = theme => ({
    error: {
        backgroundColor: theme.palette.error.light,
    },
    checkbox: {
        color: theme.palette.common.white,
        // color: '#363636',
    },
    button: {
        margin: theme.spacing(1),
    },
    link: {
        color: '#40B4BF !important',
        'text-decoration': 'none',
        cursor: 'pointer',
        fontWeight: 'bold' as any,
    },
    testLink: {
        cursor: 'pointer',
    },
    icon: {
        fontSize: 20,
        color: theme.palette.common.white,
        marginRight: theme.spacing(2),
    },
    iconVariant: {
        opacity: 0.9,
        marginRight: theme.spacing * 1,
    },
    message: {
        display: 'flex',
        alignItems: 'center',
    },
    btnclass: {
        color: theme.palette.common.white,
        marginLeft: theme.spacing(3),
        fontSize: 10,
        borderColor: theme.palette.common.white,
    },
    listContainer: {
        display: 'flex',
        'flex-direction': 'column',
        'align-self': 'flex-start',
        width: '100%',
    },
    toolbar: {
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.common.white,
        marginBottom: '-25px',
        borderRadius: '5px 5px 0 0',
    },
    compactRow: {
        paddingBottom: '15px',
        paddingTop: '15px',
        borderBottom: '1px solid #65788B',
        color: '#fff',
    },
})

interface PartsProps extends WithStyles, WithNamespaces {
    orders: Order[]
    parts: any[]
    getParts: (meta: ListMeta) => void
    order: string
    getLoggedUser: () => void
    loggedUser?: User
    viewPart: (id: string) => void
    isLoading: boolean
    setPartsForTokenization: (parts: any[]) => void
    handleCloseSnack: () => void
    deleteParts: (partids: string[]) => void
    addPart: (part: Document) => void
    partForRFQ: any[]
    history: any
    meta: ListMeta
    setPartForEdit: (part: any) => void
}

interface PartsViewState {
    order: string
    orderBy: string
    selected: any[]
    data: any[]
    filteredData: any[]
    columns: any[]
    page: number
    rowsPerPage: number
    isTokenizedSelected: boolean
    openSnack?: boolean
    deleteDialogOpen: boolean
}
export class PartsView extends React.Component<PartsProps, PartsViewState> {
    constructor(props: PartsProps) {
        super(props)

        this.state = {
            order: 'asc',
            orderBy: 'PartNumber',
            selected: [],
            data: [],
            filteredData: this.props.parts ? this.props.parts : [],
            columns: [
                { id: 1, label: 'MFGPRT TokenID' },
                { id: 2, label: 'Part Number' },
                { id: 3, label: 'Part Description' },
                { id: 4, label: 'Part Uploaded' },
            ],
            page: 0,
            rowsPerPage: 5,
            isTokenizedSelected: false,
            openSnack: true,
            deleteDialogOpen: false,
        }
        props.getLoggedUser()
        this.handleCloseSnack = this.handleCloseSnack.bind(this)
        this.toggleDeletedDialog = this.toggleDeletedDialog.bind(this)
        const meta = { ...this.props.meta }
        this.props.getParts({ ...meta, page: 0, sortField: 'createdAt', sortDirection: -1, total: 0 })
    }
    private ROWS = [
        { id: 'Select', label: '' },
        { id: 'number', label: 'Part Number' },
        { id: 'revision', label: 'Revision' },
        { id: 'name', label: 'Description' },
        { id: 'documents', label: 'Documents', fixed: true },
        { id: 'nationalStockNumber', label: 'NSN' },
        { id: 'tokenId', label: 'Blockchain ID' },
        { id: 'createdAt', label: 'Created Date' },
    ]
    handleCloseSnack() {
        this.setState({ openSnack: false })
    }
    componentDidUpdate(oldProps) {
        if (this.props.parts !== oldProps.parts) {
            this.setState({ filteredData: this.props.parts })
        }
    }

    handleSelectAllClick = event => {
        if (event.target.checked) {
            let temp = this.state.filteredData.filter(row => {
                return row.status == 'TOKENIZED' || row.status == 'IN_PROGRESS'
            })
            this.setState(state => ({ selected: state.filteredData, isTokenizedSelected: temp.length > 0 }))
            return
        }
        this.setState({ selected: [] })
    }

    handleClick = (event, id) => {
        console.log(event)

        const { selected } = this.state
        const selectedIndex = selected.indexOf(id)
        let newSelected = []

        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, id)
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1))
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1))
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1))
        }
        let temp = newSelected.filter(row => {
            return row.status == 'TOKENIZED' || row.status == 'IN_PROGRESS'
        })
        this.setState({ selected: newSelected, isTokenizedSelected: temp.length > 0 })
    }

    isSelected(part) {
        let x = this.state?.selected?.find(item => part?._id == item?._id)
        return x ? true : false
    }

    sendForTokenization = () => {
        console.log(this.state.selected.length)

        this.props.setPartsForTokenization(this.state.selected)
        this.props.history.push('/parts/tokenizeparts')
    }

    editPart = () => {
        console.log(this.state.selected)

        // this.props.setPartsForTokenization(this.state.selected)
        this.props.setPartForEdit(this.state.selected[0])
        this.props.history.push(`/parts/editpart/${this.state.selected[0]?.company?.companyId}/${this.state?.selected[0]?._id}`)
        // this.props.history.push('/parts/tokenizeparts')
    }
    onRequestQuote = () => {
        // console.log(this.state.selected.length)
        // console.log(this.props.partForRFQ)

        this.state.selected?.forEach((part: any) => {
            const filtered = this.props.partForRFQ?.filter(prt => prt.document._id == part._id)
            if (filtered.length === 0) {
                if (part.TwoDModel) {
                    part.TwoDModel.partId = part._id
                    part.TwoDModel.status = part.status
                    part.status == 'TOKENIZED' ? (part.TwoDModel.tokenId = part.tokenId) : ''
                    this.props.addPart(part.TwoDModel)
                } else if (part.models) {
                    part.models[0].partId = part._id
                    part.models[0].status = part.status
                    part.status == 'TOKENIZED' ? (part.models[0].tokenId = part.tokenId) : ''
                    this.props.addPart(part.models[0])
                }
            }

            //parts.push({ document: part })
        })

        this.props.history.push('/quotes/request')
    }

    onDeleteParts = () => {
        var deletePartIDS = []
        this.state.selected.forEach(part => {
            if (!part.submittedToRFQ || (part.submittedToRFQ && part.submittedToRFQ !== true)) {
                deletePartIDS.push(part._id)
            }
        })

        if (deletePartIDS.length !== 0) {
            this.props.deleteParts(deletePartIDS)
            this.setState(state => ({
                deleteDialogOpen: !state.deleteDialogOpen,
                selected: [],
            }))
        } else {
            console.log('Parts submitted for RFQ cannot be deleted')
            this.setState(state => ({
                deleteDialogOpen: !state.deleteDialogOpen,
                selected: [],
            }))
        }
    }
    toggleDeletedDialog() {
        this.setState(state => ({
            deleteDialogOpen: !state.deleteDialogOpen,
        }))
    }

    retryTokenization = () => {
        var failedForTokenization = this.props.parts?.filter(prt => prt.status === 'FAILED')
        if (failedForTokenization.length > 0) {
            console.log(failedForTokenization.length)
            this.props.setPartsForTokenization(failedForTokenization)
            this.props.history.push('/parts/tokenizeparts')
        }
    }
    refresh() {
        this.props.getParts(this.props.meta)
    }
    render() {
        const { t, classes, parts, viewPart, meta, getParts } = this.props
        const { openSnack } = this.state

        const { selected, isTokenizedSelected } = this.state
        const handleClose = () => {
            this.handleCloseSnack()
        }

        return (
            <CenteredAlignedContainerV2>
                <Helmet>
                    <title>{'Part Library'}</title>
                </Helmet>
                {/* <Dialog aria-labelledby="simple-dialog-title" open={isLoading}>
                    <DialogContent>
                        <Grid container justify="center">
                            <Grid item container justify="center" xs={12}>
                                <Fab className={classes.processingButton}>
                                    <CachedIcon />
                                </Fab>
                            </Grid>

                            <Typography gutterBottom variant="h3">
                                Please Wait...
                            </Typography>
                        </Grid>
                    </DialogContent>
                   
                </Dialog> */}

                {parts && parts?.filter(prt => prt.status === 'FAILED')?.length > 0 && (
                    <Snackbar
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'left',
                        }}
                        open={openSnack}
                        onClose={handleClose}
                    >
                        <SnackbarContent
                            className={classes.error}
                            aria-describedby="client-snackbar"
                            message={
                                <span id="client-snackbar" className={`${classes.message} ${classes.messageClass}`}>
                                    <ErrorIcon className={`${classes.icon}  ${classes.iconVariant}${classes.messageClass}`} />
                                    <Typography className={classes.message}>{'Token creation failed'}</Typography>
                                    <Button color="default" variant="outlined" className={classes.btnclass} onClick={this.retryTokenization}>
                                        {' '}
                                        RETRY
                                    </Button>
                                </span>
                            }
                            action={[
                                <IconButton key="close" aria-label="close" color="inherit" onClick={handleClose}>
                                    <CloseIcon className={classes.icon} />
                                </IconButton>,
                            ]}
                        />
                    </Snackbar>
                )}
                <Grid container justifyContent="space-between" style={{ marginTop: 24 }}>
                    <Grid item xs={6}>
                        <Typography gutterBottom variant="h2">
                            Part Library
                        </Typography>
                    </Grid>
                    <Grid item xs={6} style={{ textAlign: 'right' }}>
                        <Link to="/parts/uploadpart" className={classes.link}>
                            <Button type="button" variant="contained" color="primary" className={classes.button}>
                                {`${t('part-editor-header')}`}
                            </Button>
                        </Link>
                    </Grid>
                </Grid>
                <div className={classes.listContainer}>
                    {selected.length > 0 ? (
                        <EnhancedTableToolbar
                            selected={selected.length}
                            isTokenizedSelected={isTokenizedSelected}
                            onSubmit={this.sendForTokenization}
                            onDeleteParts={this.toggleDeletedDialog}
                            onRequestQuote={this.onRequestQuote}
                            onEditPart={this.editPart}
                        />
                    ) : (
                        ''
                    )}

                    <BaseList
                        elements={parts}
                        rows={this.ROWS}
                        onChange={meta => {
                            getParts(meta)
                        }}
                        meta={meta}
                        hideSortFilter={true}
                        selected={selected?.length}
                        // onSelectAllClick={this.handleSelectAllClick}
                        // showSelectAll={true}
                        // showRefreshButton={true}
                        renderBody={elements => {
                            return (
                                <React.Fragment>
                                    {elements?.map(part => {
                                        const isItemSelected = this.isSelected(part)

                                        return (
                                            <TableRow hover aria-checked={isItemSelected} tabIndex={-1} key={part._id} className={classes.row}>
                                                <TableCell
                                                    className={classes.compactRow}
                                                    onClick={e => {
                                                        e.stopPropagation()
                                                        this.handleClick(e, part)
                                                    }}
                                                    role="checkbox"
                                                >
                                                    <Checkbox
                                                        color="primary"
                                                        checked={isItemSelected}
                                                        inputProps={{
                                                            'aria-label': 'primary checkbox',
                                                        }}
                                                        classes={{
                                                            root: classes.checkbox,
                                                        }}
                                                    />
                                                </TableCell>
                                                {/* <TableCell className={classes.compactRow}>{part._id ?? '-'}</TableCell> */}
                                                <TableCell className={`${classes.compactRow} ${classes.link}`} onClick={() => viewPart(part._id)}>
                                                    {part?.number ?? '-'}
                                                </TableCell>
                                                <TableCell className={classes.compactRow}>{part?.revision ?? '-'}</TableCell>
                                                <TableCell className={classes.compactRow}>{part?.name ?? '-'}</TableCell>
                                                <TableCell className={classes.compactRow}>
                                                    {part?.documents?.length > 0
                                                        ? part?.documents?.map((document, innerIndex) => {
                                                              return (
                                                                  <Box style={{ display: 'flex', flexDirection: 'row' }} key={innerIndex}>
                                                                      <Typography>{document?.name ?? ''}</Typography>
                                                                  </Box>
                                                              )
                                                          })
                                                        : '-'}
                                                </TableCell>
                                                <TableCell className={classes.compactRow}>{part?.nationalStockNumber ?? '-'}</TableCell>
                                                <TableCell className={classes.compactRow}>{part?.status == 'TOKENIZED' && part?.tokenId ? part?.tokenId : '-'}</TableCell>
                                                <TableCell className={classes.compactRow}>
                                                    {part?.createdAt ? `${new Date(part?.createdAt)?.toLocaleDateString()} ${new Date(part?.createdAt)?.toLocaleTimeString()}` : '-'}
                                                </TableCell>
                                            </TableRow>
                                        )
                                    })}
                                    {/* {emptyRows > 0 && (
                                        <TableRow style={{ height: 49 * emptyRows }}>
                                            <TableCell colSpan={6} />
                                        </TableRow>
                                    )} */}
                                </React.Fragment>
                            )
                        }}
                    />
                </div>
                {/* </Paper> */}
                <SimpleDialog
                    title="Delete Parts?"
                    content="This will delete the parts permanently and cannot be undone. Parts submitted for RFQ cannot be deleted"
                    primaryActionText={t('document-delete-dialog-delete')}
                    onPrimaryAction={this.onDeleteParts}
                    secondaryActionText={t('document-delete-dialog-cancel')}
                    onSecondaryAction={this.toggleDeletedDialog}
                    open={this.state.deleteDialogOpen}
                />
            </CenteredAlignedContainerV2>
        )
    }
}

const mapStateToProps = (state: State) => {
    // const getHasNext = getPartById()

    return {
        loggedUser: state.user.loggedUser,
        parts: state.part.parts,
        isLoading: state.part.isLoading,
        partForRFQ: state.rfq.parts,
        meta: state.part.meta,
    }
}

const mapDispatchToProps = (dispatch: Dispatch) => {
    return {
        getParts: (meta: ListMeta) => dispatch(GET_PARTS.actions.TRIGGER({ meta })),
        getLoggedUser: () => dispatch(GET_LOGGED_USER.actions.TRIGGER()),
        viewPart: (id: string) => dispatch(PUSH(`/parts/${id}`)),
        setPartsForTokenization: (parts: any[]) => dispatch(SET_PARTS_FOR_TOKENIZATION.actions.TRIGGER(parts)),
        deleteParts: (ids: any) => dispatch(DELETE_PARTS_BY_IDS.actions.TRIGGER(ids)),
        push: (url: string) => dispatch(PUSH(url)),
        addPart: (part: Document) => dispatch(PART_ADD.actions.TRIGGER({ part })),
        setPartForEdit: (part: any) => dispatch(SET_PART_FOR_EDIT.actions.TRIGGER(part)),
    }
}
const OrdersWithTranslations = withStyles(styles)(withNamespaces()(PartsView))

export const Part = connect(mapStateToProps, mapDispatchToProps)(OrdersWithTranslations)
