import React from 'react'
import SvgIcon from '@material-ui/core/SvgIcon'

export const OrderDeliveredIcon = props => {
    return (
        <SvgIcon {...props} viewBox="0 0 40 40" x="0px" y="0px" baseProfile="tiny" xmlSpace="preserve">
            <path
                fill="#FFFFFF"
                d="M20.2,11.4a.9.9,0,0,0-1.3.3l-1.3,1.9v-.2a1.2,1.2,0,0,0-1.4,0,1,1,0,0,0,0,1.4l1,1,.7.2h.1a.9.9,0,0,0,.7-.4l1.9-2.9a.9.9,0,0,0-.3-1.3Z"
                transform="translate(0 -1.2)"
            />
            <path
                fill="#FFFFFF"
                d="M8.8,6a.9.9,0,0,1-.7-.3A1.7,1.7,0,0,1,7.8,5V1.2H1a.9.9,0,0,0-1,.9V21.3a.9.9,0,0,0,1,1H20.2a.9.9,0,0,0,.9-1V18.6a5.7,5.7,0,0,0,0-9.9h0V2.1a.9.9,0,0,0-.9-.9H13.3V5c0,.3.1,1-1,1Zm9.4,11.5a3.9,3.9,0,1,1,3.9-3.9A3.8,3.8,0,0,1,18.2,17.5Z"
                transform="translate(0 -1.2)"
            />
        </SvgIcon>
    )
}
