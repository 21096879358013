import { Grid, Typography } from "@material-ui/core";
import React, { FC } from "react";
import { QuoteDetail } from "../Quote";
import { CurrencyFormatter } from "../../util";

interface QuoteSummaryProps {
    quote: QuoteDetail
}

export const QuoteSummary: FC<QuoteSummaryProps> = ({ quote }) => {

    if (quote?.lineItems?.length > 0) {
        return (
            <Grid container style={{ padding: '16px' }}>
                <Typography gutterBottom style={{ fontSize: 20, fontWeight: 'bold' }}>
                    Total Summary
                </Typography>
                <Grid item xs={12} md={12}>
                    <Grid container direction="row">
                        <Grid item xs={6} md={6}>
                            <Typography gutterBottom style={{ fontSize: 16, fontWeight: 'bold' }}>
                                Quote Subtotal
                            </Typography>
                        </Grid>
                        <Grid item xs={6} md={6} style={{ textAlign: 'right' }}>
                            {quote.subtotalMin !== quote.subtotalMax ? (
                                <Typography style={{ fontSize: 16 }}>
                                    {`${CurrencyFormatter.format(quote.subtotalMin)} - ${CurrencyFormatter.format(quote.subtotalMax)}`}
                                </Typography>
                            ) : (
                                <Typography style={{ fontSize: 16 }}>
                                    {CurrencyFormatter.format(quote.subtotalMin)}
                                </Typography>
                            )}
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12} md={12} style={{ marginTop: 10 }}>
                    <Typography gutterBottom style={{ fontSize: 16, fontWeight: 'bold' }}>
                        Lot Charges
                    </Typography>
                </Grid>
                <Grid container item xs={12} md={12}>
                    { quote.lotCharges?.map((charge, index) => (
                        <React.Fragment key={index}>
                            <Grid item xs={4} md={4}>
                                <Typography style={{ fontSize: 16, textTransform: 'capitalize' }}>
                                    {charge.chargeType ?? ''}
                                </Typography>
                            </Grid>
                            <Grid item xs={8} md={8} style={{ textAlign: 'right' }}>
                                <Typography style={{ fontSize: 16}}>
                                    {CurrencyFormatter.format(charge.chargeAmount)}
                                </Typography>
                            </Grid>
                        </React.Fragment>
                    ))}
                    <Grid item xs={4} md={4} style={{ marginTop: '16px' }}>
                        <Typography gutterBottom style={{ fontSize: 24, fontWeight: 'bold' }}>
                            TOTAL
                        </Typography>
                    </Grid>
                    <Grid item xs={8} md={8} style={{ marginTop: '16px', textAlign: 'right' }}>
                        {quote.totalMin !== quote.totalMax ? (
                            <Typography style={{ fontSize: 24, fontWeight: 'bold' }}>
                                {`${CurrencyFormatter.format(quote.totalMin)} - ${CurrencyFormatter.format(quote.totalMax)}`}
                            </Typography>
                        ) : (
                            <Typography style={{ fontSize: 24, fontWeight: 'bold' }}>
                                {CurrencyFormatter.format(quote.totalMin)}
                            </Typography>
                        )}
                    </Grid>
                </Grid>
            </Grid>
        )
    }
    return null
}