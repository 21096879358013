import FileSaver from 'file-saver'
import { call, takeLatest, getContext, put } from 'redux-saga/effects'
import { Gateway } from '../../gateway'
import {
    REMOVE_DOCUMENT_RFQ,
    GET_RFQS,
    RFQ_DELETE,
    RFQ_CREATE,
    GET_RFQ,
    DECLINE_RFQ,
    RFQ_LIST_UPDATE_FILTER,
    EDIT_RFQ,
    GET_SUPPLIER_DATA_RFQ,
    GET_SUPPLIER_DOCUMENTS_RFQ,
    ADD_SUPPLIER_QUOTE,
    SUPPLIER_NO_QUOTE,
    GET_QUOTE_FROM_RFQ,
    RFQ_DRAFT,
    RFQ_SAVE,
    RESET_DATA,
    GET_SUPPLIER_RFQS,
    GET_SUPPLIER_RFQ,
} from './Actions'
import { PUSH, RFQStatusFilter, SHOW_FEEDBACK } from '@syncfab/machine'
import { FIND_PARTS_BY_IDS } from '../../part/Actions'
import isEmpty from 'lodash/isEmpty'
import { ListMeta } from '../../../layout/list/Meta'

function* getRFQS({ data: { meta } }: { data: { meta: ListMeta } }) {
    try {
        const gateway: Gateway = yield getContext('gateway')
        const { data } = yield call(gateway.getRFQV2API().list(meta))
        yield put(GET_RFQS.actions.SUCCESSFULL(data))
    } catch ({ response: { status, data } }) {
        const handleError = yield getContext('handleError')
        yield call(handleError(status))
        yield put(GET_RFQS.actions.FAILED(data))
    }
}

function* get({ data: { id } }: { data: { id: string } }) {
    try {
        const gateway: Gateway = yield getContext('gateway')
        const data = yield call(gateway.getRFQV2API().get(id))
        yield put(GET_RFQ.actions.SUCCESSFULL(data.data))
        if (data.data.orderItems) {
            const ids = data.data.orderItems.filter(item => item.partId).map(item => item.partId)
            if (!!ids.length) {
                yield put(FIND_PARTS_BY_IDS.actions.TRIGGER({ ids }))
            }

            if (!!data.data.totals && !!data.data.totals.items && !!data.data.totals.items.length) {
                yield put(FIND_PARTS_BY_IDS.actions.TRIGGER({ ids: data.data.totals.items.map(item => item.partId) }))
            }
        }
    } catch ({ response: { status, data } }) {
        const handleError = yield getContext('handleError')
        yield call(handleError(status))
        yield put(GET_RFQ.actions.FAILED(data))
    }
}

function* getSupplierData({ data: { token } }: { data: { token: string } }) {
    try {
        const gateway: Gateway = yield getContext('gateway')
        const data = yield call(gateway.getRFQAPI().getSupplierData(token))
        yield put(GET_SUPPLIER_DATA_RFQ.actions.SUCCESSFULL(data.data))
    } catch ({ response: { status, data } }) {
        const handleError = yield getContext('handleError')
        yield call(handleError(status))
        yield put(GET_SUPPLIER_DATA_RFQ.actions.FAILED(data))
    }
}

function* downloadSupplierDocuments({ data: { token } }: { data: { token: string } }) {
    try {
        const gateway: Gateway = yield getContext('gateway')
        const { data, headers } = yield call(gateway.getRFQAPI().download(token))
        const name = /^attachment; filename="(.*)"$/.exec(headers['content-disposition'])![1]
        FileSaver.saveAs(new Blob([data]), name)
        yield put(GET_SUPPLIER_DOCUMENTS_RFQ.actions.SUCCESSFULL())
    } catch ({ response: { status, data } }) {
        const handleError = yield getContext('handleError')
        yield call(handleError(status))
        yield put(GET_SUPPLIER_DOCUMENTS_RFQ.actions.FAILED(data))
    }
}

function* addSupplierQuote({ data: { document, rfqId, verification } }: { data: { document: any; rfqId: String; verification: any } }) {
    try {
        console.log(verification)

        const gateway: Gateway = yield getContext('gateway')
        yield call(gateway.getRFQAPI().addSupplierQuote(rfqId, document, verification))
        yield put(ADD_SUPPLIER_QUOTE.actions.SUCCESSFULL())
        yield put(GET_RFQ.actions.TRIGGER({ id: rfqId.toString() }))
    } catch ({ response: { status, data } }) {
        const handleError = yield getContext('handleError')
        yield call(handleError(status))
        yield put(ADD_SUPPLIER_QUOTE.actions.FAILED(data))
    }
}

function* deleteRFQ({ data: { id } }: { data: { id: string } }) {
    try {
        const gateway: Gateway = yield getContext('gateway')
        yield call(gateway.getRFQAPI().deleteRFQ(id))

        yield put(RFQ_DELETE.actions.SUCCESSFULL({ id }))
        yield put(SHOW_FEEDBACK('feedback-rfq-deleted'))
        yield put(PUSH('/quotes'))
    } catch ({ response: { status, data } }) {
        const handleError = yield getContext('handleError')
        yield call(handleError(status))
        yield put(RFQ_DELETE.actions.FAILED(data))
    }
}

function* createRFQ({ data: { rfqId, rfq } }: { data: { rfqId: string; rfq: any } }) {
    try {
        const gateway: Gateway = yield getContext('gateway')
        let result = null
        if (rfqId) {
            result = yield call(gateway.getRFQV2API().createRFQ(rfqId, rfq))
        } else {
            const { data } = yield call(gateway.getRFQV2API().saveRFQ(rfq))
            result = yield call(gateway.getRFQV2API().createRFQ(data._id, rfq))
        }
        yield put(RFQ_CREATE.actions.SUCCESSFULL(result))
        yield put(SHOW_FEEDBACK('feedback-rfq-submit'))
        yield put(PUSH(`/quotes`))
    } catch ({ response: { status, data } }) {
        const handleError = yield getContext('handleError')
        yield call(handleError(status))
        yield put(RFQ_CREATE.actions.FAILED(data))
        yield put(SHOW_FEEDBACK(data))
    }
}

function* declineRFQ({ data: { id, reason } }: { data: { id: string; reason: string } }) {
    try {
        const gateway: Gateway = yield getContext('gateway')
        yield call(gateway.getRFQAPI().declineRFQ(id, reason))

        yield put(DECLINE_RFQ.actions.SUCCESSFULL())
        yield put(SHOW_FEEDBACK('feedback-rfq-declined'))
        // yield put(RFQ_LIST_UPDATE_FILTER.actions.TRIGGER({ filter: RFQStatusFilter.ARCHIVED }))
        yield put(RFQ_LIST_UPDATE_FILTER.actions.TRIGGER({ filter: RFQStatusFilter.ACCEPTED }))
        yield put(PUSH('/quotes'))
    } catch ({ response: { status, data } }) {
        const handleError = yield getContext('handleError')
        yield call(handleError(status))
        yield put(DECLINE_RFQ.actions.FAILED(data))
        yield put(SHOW_FEEDBACK('feedback-support'))
    }
}

function* editRFQ({ data: { id, request } }: { data: { id: string; request: string } }) {
    try {
        const gateway: Gateway = yield getContext('gateway')
        yield call(gateway.getRFQAPI().editRFQ(id, request))

        yield put(EDIT_RFQ.actions.SUCCESSFULL())
        yield put(SHOW_FEEDBACK('feedback-rfq-edited'))
    } catch ({ response: { status, data } }) {
        const handleError = yield getContext('handleError')
        yield call(handleError(status))
        yield put(EDIT_RFQ.actions.FAILED(data))
        yield put(SHOW_FEEDBACK('feedback-support'))
    }
}
function* removeRFQDocument({ data: { rfqId, documentId } }: { data: { rfqId: string; documentId: string } }) {
    try {
        const gateway: Gateway = yield getContext('gateway')
        yield call(gateway.getRFQAPI().removeRFQDocument(rfqId, documentId))
        // yield put(REMOVE_DOCUMENT_RFQ.actions.SUCCESSFULL())
        yield put(REMOVE_DOCUMENT_RFQ.actions.SUCCESSFULL({ id: documentId }))
        yield put(GET_RFQ.actions.TRIGGER({ id: rfqId.toString() }))
    } catch ({ response: { status, data } }) {
        const handleError = yield getContext('handleError')
        yield call(handleError(status))
        yield put(REMOVE_DOCUMENT_RFQ.actions.FAILED(data))
    }
}

function* supplierNoQuote({ data: { rfqId, description } }: { data: { rfqId: String; description: String } }) {
    try {
        const gateway: Gateway = yield getContext('gateway')
        const data = yield call(gateway.getRFQAPI().supplierNoQuote(rfqId, description))
        yield put(SUPPLIER_NO_QUOTE.actions.SUCCESSFULL(data))
        yield put(GET_RFQ.actions.TRIGGER({ id: rfqId.toString() }))
    } catch ({ response: { status, data } }) {
        const handleError = yield getContext('handleError')
        yield call(handleError(status))
        yield put(SUPPLIER_NO_QUOTE.actions.FAILED(data))
    }
}

function* getQuoteFromRFQ({ data: { rfqId } }: { data: { rfqId: string } }) {
    try {
        const gateway: Gateway = yield getContext('gateway')
        const data = yield call(gateway.getRFQV2API().getQuoteFromRFQ(rfqId))
        yield put(GET_QUOTE_FROM_RFQ.actions.SUCCESSFULL(data.data))
    } catch ({ response: { status, data } }) {
        const handleError = yield getContext('handleError')
        yield call(handleError(status))
        yield put(GET_QUOTE_FROM_RFQ.actions.FAILED(data))
    }
}

function* draft({ data: { rfqId, rfq } }: { data: { rfqId: string; rfq?: any } }) {
    try {
        const gateway: Gateway = yield getContext('gateway')
        const { data } = yield call(gateway.getRFQV2API().draft(rfqId, rfq))
        yield put(GET_RFQ.actions.SUCCESSFULL(data))
        yield put(SHOW_FEEDBACK('feedback-rfq-draft-save'))
        // yield put(PUSH(`/quotes`))
    } catch ({ response: { status, data } }) {
        const handleError = yield getContext('handleError')
        yield call(handleError(status))
        yield put(GET_RFQ.actions.FAILED(data))
        yield put(SHOW_FEEDBACK(data))
    }
}

function* saveRFQ({ data: { rfq } }: { data: { rfq: any } }) {
    try {
        const gateway: Gateway = yield getContext('gateway')
        const { data } = yield call(gateway.getRFQV2API().saveRFQ(rfq))
        yield put(GET_RFQ.actions.SUCCESSFULL(data))
        yield put(SHOW_FEEDBACK('feedback-rfq-draft-save'))
        yield put(PUSH(`/quotes/request/${data._id}`))
    } catch ({ response: { status, data } }) {
        const handleError = yield getContext('handleError')
        yield call(handleError(status))
        yield put(RFQ_SAVE.actions.FAILED(data))
        yield put(SHOW_FEEDBACK(data))
    }
}

function* getSupplierRFQS() {
    try {
        const gateway: Gateway = yield getContext('gateway')
        const {
            data: { data },
        } = yield call(gateway.getRFQAPI().getAll())
        const rfqs = data && !isEmpty(data) ? data : []
        yield put(GET_SUPPLIER_RFQS.actions.SUCCESSFULL(rfqs))
    } catch ({ response: { status, data } }) {
        const handleError = yield getContext('handleError')
        yield call(handleError(status))
        yield put(GET_SUPPLIER_RFQS.actions.FAILED(data))
    }
}

function* getRFQ({ data: { id } }: { data: { id: string } }) {
    try {
        const gateway: Gateway = yield getContext('gateway')
        const data = yield call(gateway.getRFQAPI().get(id))
        yield put(GET_SUPPLIER_RFQ.actions.SUCCESSFULL(data.data))
        if (data.data.orderItems) {
            const ids = data.data.orderItems.filter(item => item.partId).map(item => item.partId)
            if (!!ids.length) {
                yield put(FIND_PARTS_BY_IDS.actions.TRIGGER({ ids }))
            }

            if (!!data.data.totals && !!data.data.totals.items && !!data.data.totals.items.length) {
                yield put(FIND_PARTS_BY_IDS.actions.TRIGGER({ ids: data.data.totals.items.map(item => item.partId) }))
            }
        }
    } catch ({ response: { status, data } }) {
        const handleError = yield getContext('handleError')
        yield call(handleError(status))
        yield put(GET_SUPPLIER_RFQ.actions.FAILED(data))
    }
}
function* reset() {}

function* quoteSaga() {
    // Typings are not good enough. String should be valid but the types
    // do not show it
    yield takeLatest(GET_RFQS.types.TRIGGER as any, getRFQS)
    yield takeLatest(GET_RFQ.types.TRIGGER as any, get)
    yield takeLatest(RFQ_DELETE.types.TRIGGER as any, deleteRFQ)
    yield takeLatest(RFQ_CREATE.types.TRIGGER as any, createRFQ)
    yield takeLatest(DECLINE_RFQ.types.TRIGGER as any, declineRFQ)
    yield takeLatest(EDIT_RFQ.types.TRIGGER as any, editRFQ)
    yield takeLatest(GET_SUPPLIER_DATA_RFQ.types.TRIGGER as any, getSupplierData)
    yield takeLatest(GET_SUPPLIER_DOCUMENTS_RFQ.types.TRIGGER as any, downloadSupplierDocuments)
    yield takeLatest(ADD_SUPPLIER_QUOTE.types.TRIGGER as any, addSupplierQuote)
    yield takeLatest(REMOVE_DOCUMENT_RFQ.types.TRIGGER as any, removeRFQDocument)
    yield takeLatest(SUPPLIER_NO_QUOTE.types.TRIGGER as any, supplierNoQuote)
    yield takeLatest(GET_QUOTE_FROM_RFQ.types.TRIGGER as any, getQuoteFromRFQ)
    yield takeLatest(RFQ_DRAFT.types.TRIGGER as any, draft)
    yield takeLatest(RFQ_SAVE.types.TRIGGER as any, saveRFQ)
    yield takeLatest(RESET_DATA.types.TRIGGER as any, reset)
    yield takeLatest(GET_SUPPLIER_RFQS.types.TRIGGER as any, getSupplierRFQS)
    yield takeLatest(GET_SUPPLIER_RFQ.types.TRIGGER as any, getRFQ)
}

export default quoteSaga
