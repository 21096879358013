import React from 'react'
import { Field, reduxForm } from 'redux-form/immutable'
import { InjectedFormProps } from 'redux-form'
import { Map } from 'immutable'
import { WithStyles, withStyles } from '@material-ui/core/styles'
import { withNamespaces, WithNamespaces } from 'react-i18next'
import { FormTextField, Button, jumpToFirstError, FormPasswordField } from '@syncfab/machine'
// import { Typography } from '@material-ui/core'
// import { Link } from 'react-router-dom'
import { SIGNUP_EMAIL_VALIDATION } from '../Actions'
import { EMAIL_REGEX, isExistSpecialCharacter, isNumber } from '../../../utils/Regex'
import { Grid } from '@material-ui/core'

export const SignupFormName: string = 'SignupForm'

const styles = theme => ({
    action: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
    },
    loginText: {
        color: theme.palette.common.white,
        'text-align': 'center',
        marginTop: theme.spacing(2),
    },
    signupLink: {
        margin: 0,
        padding: 0,
        'text-decoration': 'none',
        color: theme.palette.primary.light,
    },
    gridItem: {
        paddingTop: '0px !important',
        paddingBottom: '0px !important',
    },
    formInput: {
        '& .MuiFormControl-marginNormal': {
            margin: 0,
        },
    },
})

type SignupPersonalInformationFields = {
    email?: string
    password?: string
    firstName?: string
    lastName?: string
    title?: string
    phone?: string
}

const validate = (data: Map<string, string>): {} => {
    const errors: SignupPersonalInformationFields = {}
    if (!data.get('email')) {
        errors.email = 'Required'
    } else if (!EMAIL_REGEX.test(data.get('email') || '')) {
        errors.email = 'Invalid user'
    }

    if (!data.get('password')) {
        errors.password = 'Required'
    } else if (!/^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/.test(data.get('password') || '')) {
        errors.password = 'A password requires at least 8 characters (at least 1 number, 1 symbol and 1 Uppercase)'
    }

    ;['firstName', 'lastName', 'phone', 'title'].forEach(field => {
        if (!data.get(field)) {
            errors[field] = 'Required'
        }
    })

    let phone = data.get('phone')
    if (phone && phone.length > 0 && !isNumber(data.get('phone'))) {
        errors['phone'] = 'Enter valid phone number.'
    }

    if (isExistSpecialCharacter(data.get('firstName'))) errors.firstName = 'Not allow certain special characters'
    if (isExistSpecialCharacter(data.get('lastName'))) errors.lastName = 'Not allow certain special characters'

    return errors
}

const asyncValidate = (values, dispatch) => {
    dispatch(SIGNUP_EMAIL_VALIDATION.actions.TRIGGER({ email: values.get('email') }))
    return new Promise<void>(resolve => resolve())
}

const form = (props: InjectedFormProps<{}, {}> & WithStyles & WithNamespaces) => {
    const { handleSubmit, submitting, t, classes } = props
    return (
        <form onSubmit={handleSubmit}>
            <Grid container direction="row" spacing={2}>
                <Grid item xs={12} md={6} className={classes.gridItem}>
                    <Field name="firstName" className={classes.formInput} fullWidth component={FormTextField} type="text" label={t('user-first-name')} />
                </Grid>
                <Grid item xs={12} md={6} className={classes.gridItem}>
                    <Field name="lastName" className={classes.formInput} fullWidth component={FormTextField} type="text" label={t('user-last-name')} />
                </Grid>
                <Grid item xs={12} md={12} className={classes.gridItem}>
                    <Field name="phone" component={FormTextField} type="text" label={t('user-phone')} fullWidth className={classes.formInput} />
                </Grid>
                <Grid item xs={12} md={12} className={classes.gridItem}>
                    <Field name="email" component={FormTextField} type="email" label={t('user-email')} fullWidth className={classes.formInput} />
                </Grid>
                <Grid item xs={12} md={12} className={classes.gridItem}>
                    <Field name="title" component={FormTextField} type="text" label={t('company-title')} fullWidth className={classes.formInput} />
                </Grid>
                {/* Redux-form typings are not very good */}
                <Grid item xs={12} md={12} className={classes.gridItem}>
                    <Field name="password" component={FormPasswordField as any} label={t('user-password')} fullWidth className={classes.formInput} />
                </Grid>
                <Grid item xs={12} md={12} className={classes.gridItem}>
                    <Button type="submit" color="primary" disabled={submitting} className={classes.action} fullWidth>
                        {t('signup-form-continue')}
                    </Button>
                    {/* <Typography variant="caption" className={classes.loginText}>
                    {t('signup-already-has-account')}{' '}
                    <Link to="/login" className={classes.signupLink}>
                        {t('signup-login')}
                    </Link>
                </Typography> */}
                </Grid>
            </Grid>
        </form>
    )
}

const translatedForm = withNamespaces()(withStyles(styles)(form))
export const SignupPersonalInformationForm = reduxForm<{}, {}>({
    form: SignupFormName,
    validate,
    destroyOnUnmount: false,
    forceUnregisterOnUnmount: true,
    onSubmitFail: jumpToFirstError,
    asyncValidate,
    asyncChangeFields: ['email'],
    asyncBlurFields: ['email'],
})(translatedForm)
