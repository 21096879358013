import React from 'react'
import { withNamespaces, WithNamespaces } from 'react-i18next'
import { Dispatch } from 'redux'
import { WithStyles } from '@material-ui/core/styles'
import { connect } from 'react-redux'
import { Card, withStyles, Typography, Grid, Box, IconButton, Menu, MenuItem } from '@material-ui/core'
import { State } from '../../../application/Store'
// import { DocumentItem } from './CompanyDocumentItem'
import { GET_COMPANY_DOCUMENTS, DOCUMENT_DOWNLOAD_TYPE, DOCUMENT_UPLOAD, DOCUMENT_DOWNLOAD_URL, RESET_DOWNLOAD_URL } from '../../../components/document/Actions'
// import { getDocument } from '../../../components/document/Selectors'
import { Document, DocumentType, SimpleDialog, Loader, Company } from '@syncfab/machine'
import FilePondComponent from '../../../components/document/DocumentComponent'
import { InsertDriveFile } from '@material-ui/icons'
import MoreVertIcon from '@material-ui/icons/MoreVert'
import { DELETE_DOCUMENT, GET_LOGGED_COMPANY, UPDATE_COMPANY } from '../../../components/company/Actions'
import PDFViewer from '../../../components/pdfViewer/PDFViewer'

const styles = theme => ({
    card: {
        width: '100%',
        padding: theme.spacing(3),
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2),
    },
    documentFooter: {
        color: '#DBDBDB',
        marginBottom: 8,
        fontSize: 11,
    },
    documentContainer: {
        display: 'flex',
    },
    documentDetails: {
        flexGrow: 1,
        marginLeft: theme.spacing(2),
    },
    fileSize: {
        color: '#D4D5D6',
    },
    fileName: {
        color: theme.palette.common.white,
    },
    icon: {
        width: 25,
        height: 25,
        color: theme.palette.common.white,
        marginTop: theme.spacing(1),
        cursor: 'pointer',
    },
    documentCard: {
        width: '100%',
        margin: 0,
        padding: theme.spacing(2),
        marginBottom: theme.spacing(2),
        position: 'relative' as any,
        backGroundColor: '#1C2D3E !important',
        border: `2px solid rgba(255, 255, 255, 0.23)`,
    },
})

interface CompanyDocumentsState {
    deleteDialogOpen: boolean
    documentId?: string
    anchorEl: null
    openDocumentMenuDocId: string
    pdfViewer: boolean
}

interface CompanyDocumentsProps extends WithNamespaces, WithStyles {
    // documents: {
    //     permit?: Document
    //     nda?: Document
    // }
    documents: Document[]
    getCompanyDocuments: () => void
    // deleteDocument: (type: DocumentType) => void
    download: (type: DocumentType) => void
    onLoadDocument: (document: Document) => void
    updateCompany: (companyId: string, companyInput: Partial<Company>) => void
    getLoggedCompany: () => void
    company?: Company
    reset?: () => void
    previewDocument?: (id: string) => void
    deleteDocument: (id: string) => void
    isLoading: boolean
}

class CompanyDocumentsView extends React.Component<CompanyDocumentsProps, CompanyDocumentsState> {
    constructor(props: CompanyDocumentsProps) {
        super(props)
        this.toggleDeletedDialog = this.toggleDeletedDialog.bind(this)
        this.onDelete = this.onDelete.bind(this)
        this.deleteDocument = this.deleteDocument.bind(this)
        props.getLoggedCompany()
        // this.props.getCompanyDocuments()
        this.state = {
            deleteDialogOpen: false,
            anchorEl: null,
            openDocumentMenuDocId: null,
            pdfViewer: false,
        }
    }

    toggleDeletedDialog() {
        this.setState(state => ({
            deleteDialogOpen: !state.deleteDialogOpen,
        }))
    }

    onDelete(id: string) {
        this.setState({
            documentId: id,
        })
        this.toggleDeletedDialog()
    }

    deleteDocument() {
        const id = this.state.documentId
        if (!id) {
            return
        }
        this.props.deleteDocument(id)
        this.toggleDeletedDialog()
        this.setState({
            documentId: undefined,
        })
    }

    bytesForHuman(bytes, decimals = 2) {
        let units = ['B', 'KB', 'MB', 'GB', 'TB', 'PB']

        let i = 0

        for (i; bytes > 1024; i++) {
            bytes /= 1024
        }

        return parseFloat(bytes.toFixed(decimals)) + ' ' + units[i]
    }
    handleClickDocumentOptions = (event, id) => {
        this.setState({
            openDocumentMenuDocId: id,
            anchorEl: event.currentTarget,
        })
    }

    handleCloseDocumentOptions = () => {
        this.setState({
            openDocumentMenuDocId: null,
            anchorEl: null,
        })
    }

    handleDocumentOptionMenu = (option, id) => {
        if (option.toLowerCase() === 'preview') {
            this.viewPDF(id)
        } else if (option.toLowerCase() === 'delete') {
            this.onDelete(id)
        }
    }
    viewPDF = docId => {
        this.props.previewDocument(docId)
        this.setState({ pdfViewer: true })
    }
    closePDFViewer = () => {
        this.setState({ pdfViewer: false })
        this.props.reset()
    }

    public render() {
        const { classes, t, onLoadDocument, company, isLoading } = this.props

        if (!company || isLoading) {
            return <Loader />
        }
        const { anchorEl, pdfViewer } = this.state
        const ITEM_HEIGHT = 48
        const options = ['Preview', 'Delete']
        const open = Boolean(anchorEl)
        return (
            <React.Fragment>
                <Card className={classes.card} elevation={1}>
                    <Typography variant="h3" gutterBottom>
                        {`${t('company-documents-title')}`}
                    </Typography>
                    <Typography variant="body1">Securely exchange and store company documents between your team and SyncFab</Typography>
                    <Grid container style={{ marginTop: 16 }}>
                        <Grid item xs={12} md={12}>
                            <FilePondComponent
                                className={classes.file}
                                type={DocumentType.DOCUMENT}
                                acceptedFileTypes={[
                                    'application/pdf',
                                    'allowed/extension',
                                    '.doc',
                                    '.docx',
                                    '.xlsx',
                                    'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
                                    'application/msword',
                                    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
                                ]}
                                allowMultiple={true}
                                maxFiles={20}
                                maxFileSize="150MB"
                                maxTotalFileSize="3000MB"
                                onLoad={onLoadDocument}
                                allFilesProcessed={() => {
                                    let docIds = this.props.documents?.map(doc => doc._id)
                                    if (company?.documentIds?.length > 0) {
                                        docIds = docIds.concat(company?.documentIds)
                                    }
                                    this.props.updateCompany(this.props?.company?._id, { documentIds: docIds })
                                }}
                                clearAfterAllFilesProcessed={true}
                            />
                            <Typography gutterBottom variant="caption" paragraph align="center" style={{ marginTop: -8 }}>
                                {`${t('company-settings-document')}`}
                            </Typography>
                        </Grid>
                        {company?.documents?.map((document: any, index: number) => (
                            <Grid item xs={12} md={12} style={{ paddingTop: 0 }} key={index}>
                                <Card className={classes.documentCard}>
                                    <Box className={classes.documentContainer} alignItems="center">
                                        <Box>
                                            <IconButton style={{ borderRadius: '50%', padding: '8px', backgroundColor: '#1C2D3E', color: 'white', marginLeft: '8px' }}>
                                                <InsertDriveFile />
                                            </IconButton>
                                        </Box>
                                        <Box className={classes.documentDetails}>
                                            <Typography color="textPrimary" variant="body2" className={classes.fileName}>
                                                {!!document?.name ? document?.name : ''}
                                            </Typography>
                                            <Typography variant="caption" className={classes.fileSize}>
                                                {!!document?.size ? this.bytesForHuman(document?.size) : ''}
                                            </Typography>
                                        </Box>
                                        <Box>
                                            <IconButton
                                                aria-label="More"
                                                aria-owns={open ? 'long-menu' : undefined}
                                                aria-haspopup="true"
                                                onClick={e => this.handleClickDocumentOptions(e, document?._id)}
                                                style={{ color: '#fff' }}
                                            >
                                                <MoreVertIcon />
                                            </IconButton>
                                            <Menu
                                                id="long-menu"
                                                anchorEl={anchorEl}
                                                open={this.state?.openDocumentMenuDocId === document?._id}
                                                onClose={this.handleCloseDocumentOptions}
                                                PaperProps={{
                                                    style: {
                                                        maxHeight: ITEM_HEIGHT * 4.5,
                                                        width: 200,
                                                    },
                                                }}
                                            >
                                                {options.map((option, index) => (
                                                    <MenuItem
                                                        key={index}
                                                        onClick={() => {
                                                            this.handleCloseDocumentOptions()
                                                            this.handleDocumentOptionMenu(option, document?._id)
                                                        }}
                                                    >
                                                        {option}
                                                    </MenuItem>
                                                ))}
                                            </Menu>
                                        </Box>
                                    </Box>
                                </Card>
                            </Grid>
                        ))}
                        {pdfViewer && <PDFViewer open={pdfViewer} onClose={this.closePDFViewer} />}
                    </Grid>
                    {/* <Divider />
                    <DocumentItem
                        type={DocumentType.PERMIT}
                        title={t('company-documents-seller-permit-title')}
                        description={t('company-documents-seller-permit-description')}
                        action={!!documents.permit ? 'download' : 'upload'}
                        downloadDocument={() => download(DocumentType.PERMIT)}
                        deleteDocument={() => this.onDelete(DocumentType.PERMIT)}
                        fileDescription={t('document-description')}
                        buttonText={t('download')}
                        showDelete={true}
                        onLoad={onLoadDocument}
                    />
                    <Divider />
                    <DocumentItem
                        type={DocumentType.NDA}
                        title={t('company-documents-nda-title')}
                        description={t('company-documents-nda-description')}
                        action={!!documents.nda ? 'download' : 'upload'}
                        downloadDocument={() => download(DocumentType.NDA)}
                        deleteDocument={() => this.onDelete(DocumentType.NDA)}
                        fileDescription={t('document-description')}
                        buttonText={t('download')}
                        showDelete={true}
                        onLoad={onLoadDocument}
                    />
                    <Divider />
                    <DocumentItem
                        type={DocumentType.W9}
                        title={t('company-documents-w9-title')}
                        description={t('company-documents-w9-description')}
                        action="download"
                        buttonText={t('download')}
                        downloadDocument={() => download(DocumentType.W9)}
                    /> */}
                </Card>
                <SimpleDialog
                    title={t('document-delete-dialog-title')}
                    content={t('document-delete-dialog-content')}
                    primaryActionText={t('document-delete-dialog-delete')}
                    onPrimaryAction={this.deleteDocument}
                    secondaryActionText={t('document-delete-dialog-cancel')}
                    onSecondaryAction={this.toggleDeletedDialog}
                    open={this.state.deleteDialogOpen}
                />
            </React.Fragment>
        )
    }
}

const mapStateToProps = (state: State) => {
    // const getPermitDocument = getDocument(DocumentType.PERMIT)
    // const getNDADocument = getDocument(DocumentType.NDA)
    // if (!state.document.documents) {
    //     return {}
    // }
    return {
        // documents: {
        //     permit: getPermitDocument(state),
        //     nda: getNDADocument(state),
        // },
        documents: state.document.documents,
        company: state.company.company,
        isLoading: state.company.isLoading,
    }
}

const mapDispatchToProps = (dispatch: Dispatch) => {
    return {
        getCompanyDocuments: () => dispatch(GET_COMPANY_DOCUMENTS.actions.TRIGGER()),
        download: (type: DocumentType) => dispatch(DOCUMENT_DOWNLOAD_TYPE.actions.TRIGGER({ type })),
        // deleteDocument: (type: DocumentType) => dispatch(DOCUMENT_DELETE_BY_TYPE.actions.TRIGGER({ type })),
        onLoadDocument: (document: Document) => dispatch(DOCUMENT_UPLOAD.actions.TRIGGER({ document })),
        updateCompany: (companyId: string, companyInput: Partial<Company>) => dispatch(UPDATE_COMPANY.actions.TRIGGER({ companyId, companyInput })),
        getLoggedCompany: () => dispatch(GET_LOGGED_COMPANY.actions.TRIGGER()),
        deleteDocument: (documentId: string) => dispatch(DELETE_DOCUMENT.actions.TRIGGER({ documentId })),
        previewDocument: (id: string) => dispatch(DOCUMENT_DOWNLOAD_URL.actions.TRIGGER({ id })),
        reset: () => dispatch(RESET_DOWNLOAD_URL.actions.TRIGGER()),
    }
}

const CompanyDocumentsWithTranslations = withNamespaces()(withStyles(styles)(CompanyDocumentsView))

export default connect(mapStateToProps, mapDispatchToProps)(CompanyDocumentsWithTranslations)
