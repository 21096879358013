import React from 'react'
import SvgIcon from '@material-ui/core/SvgIcon'

export const VerifiedIcon = props => {
    return (
        <SvgIcon {...props} viewBox="0 0 72 76" x="0px" y="0px" baseProfile="tiny" xmlSpace="preserve" style={{ width: '60px', height: '60px' }}>
            <path
                fill="#FFFFFF"
                d="M19.23,27.95h19.9c1.11,0,2-0.88,2-1.97s-0.9-1.97-2-1.97h-19.9c-1.11,0-2,0.88-2,1.97
				S18.12,27.95,19.23,27.95z"
            />
            <path
                fill="#FFFFFF"
                d="M19.23,34.99H29.8c1.11,0,2.01-0.88,2.01-1.97c0-1.09-0.9-1.97-2.01-1.97H19.23c-1.11,0-2,0.88-2,1.97
				C17.23,34.11,18.12,34.99,19.23,34.99z"
            />
            <path
                fill="#FFFFFF"
                d="M58.37,45.25V30.21c0-1.24-0.48-2.43-1.35-3.33l-6.21-6.41V10.03c0-1.46-1.21-2.65-2.7-2.65h-9.98l-5.9-6.1
				C31.44,0.46,30.35,0,29.2,0c-1.15,0-2.24,0.46-3.04,1.28l-5.91,6.1h-9.97c-1.49,0-2.7,1.19-2.7,2.65v10.44l-6.21,6.42
				C0.5,27.78,0.01,28.97,0,30.21v27.67c0,2.64,2.18,4.78,4.87,4.78H40.7c0-4.6,1.86-9.03,5.18-12.29
				C49.19,47.1,53.68,45.26,58.37,45.25L58.37,45.25z M45.39,36.17L31.18,47.35c-1.14,0.9-2.77,0.9-3.91,0L12.98,36V12.68h32.41V36.17
				z"
            />
            <path
                fill="#FFFFFF"
                d="M19.23,20.89h19.9c1.11,0,2-0.88,2-1.97s-0.9-1.97-2-1.97h-19.9c-1.11,0-2,0.88-2,1.97
				C17.23,20.01,18.12,20.89,19.23,20.89z"
            />
            <path
                fill="#FFFFFF"
                d="M58.37,49.27c-3.61,0-7.07,1.41-9.62,3.92c-2.55,2.51-3.98,5.91-3.98,9.45c0,3.54,1.44,6.94,3.99,9.44
				C51.33,74.6,54.79,76,58.4,76c3.61,0,7.07-1.41,9.62-3.91c2.55-2.51,3.98-5.9,3.98-9.45c0-3.55-1.44-6.94-4-9.45
				C65.45,50.68,61.99,49.27,58.37,49.27L58.37,49.27z M64.64,60.8l-6.56,6.44c-0.31,0.31-0.73,0.48-1.17,0.48
				c-0.44,0-0.87-0.17-1.17-0.48l-3.55-3.48c-0.61-0.76-0.55-1.86,0.15-2.55c0.7-0.69,1.81-0.76,2.59-0.16l1.95,1.91l4.96-4.87
				c0.37-0.36,0.87-0.56,1.38-0.56c0.52,0,1.02,0.2,1.38,0.56c0.37,0.36,0.58,0.85,0.58,1.37c0,0.51-0.21,1-0.58,1.36L64.64,60.8z"
            />
        </SvgIcon>
    )
}
