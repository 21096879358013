import React from 'react'
import { TextField as MaterialUITextField, FormControl, InputAdornment } from '@material-ui/core'
import { Lock } from '@material-ui/icons'
import { FormFieldFooter } from '../Error'

export const MUITextField = MaterialUITextField

export const TextField = ({ value, label, type, fullWidth, className, disabled, required, onChange, multiline, rowsMax, inputVariant = 'outlined' }: any) => {
    return (
        <FormControl fullWidth={fullWidth} className={className}>
            <MUITextField
                margin="normal"
                variant={inputVariant}
                disabled={disabled}
                label={label}
                type={type}
                required={required}
                fullWidth
                onChange={onChange}
                value={value}
                multiline={multiline}
                rowsMax={rowsMax}
            />
        </FormControl>
    )
}

export const FormTextField = ({ input, label, type, meta: { touched, error }, fullWidth, className, disabled, InputProps, description, required, inputVariant = 'outlined', ...custom }: any) => {
    let inputProps = InputProps
    if (disabled && !InputProps) {
        inputProps = {
            endAdornment: (
                <InputAdornment position="end" style={{ backgroundColor: 'inherit' }}>
                    <Lock />
                </InputAdornment>
            ),
        }
    }
    return (
        <FormControl fullWidth={fullWidth} className={className}>
            <MUITextField
                margin="normal"
                variant={inputVariant}
                disabled={disabled}
                label={label}
                type={type}
                required={required}
                fullWidth
                {...input}
                error={!!touched && !!error}
                InputProps={inputProps}
                {...custom}
            />
            <FormFieldFooter touched={touched} error={error} description={description} />
        </FormControl>
    )
}

// Redux-form typings are not very good
export class FormPasswordField extends React.Component<any> {
    state = {
        showText: false,
    }

    constructor(props) {
        super(props)
        this.toggleShowText = this.toggleShowText.bind(this)
    }
    toggleShowText() {
        this.setState({
            showText: !this.state.showText,
        })
    }

    render() {
        const {
            input,
            label,
            meta: { touched, error },
            fullWidth,
            className,
            inputVariant = 'outlined',
            ...custom
        } = this.props
        const type = this.state.showText ? 'text' : 'password'
        return (
            <FormControl fullWidth={fullWidth} className={className}>
                <MUITextField
                    margin="normal"
                    variant={inputVariant}
                    label={label}
                    type={type}
                    fullWidth
                    {...input}
                    error={!!touched && !!error}
                    {...custom}
                    // InputProps={{
                    //     endAdornment: (
                    //         <InputAdornment position="end">
                    //             <IconButton aria-label="Toggle password visibility" onClick={this.toggleShowText}>
                    //                 {this.state.showText ? <Visibility /> : <VisibilityOff />}
                    //             </IconButton>
                    //         </InputAdornment>
                    //     ),
                    // }}
                />
                <FormFieldFooter touched={touched} error={error} />
            </FormControl>
        )
    }
}
