import React from 'react'
import { Checkbox as MUICheckbox, FormControlLabel, FormControl } from '@material-ui/core'
import { FormFieldFooter } from '../Error'

export const Checkbox = MUICheckbox

const renderCheckbox = ({ input, label, value, meta: { touched, error }, labelPlacement = 'end', className }: any) => {
    return (
        <React.Fragment>
            <FormControl error={!!touched && !!error} className={className}>
                <FormControlLabel
                    labelPlacement={labelPlacement}
                    control={<Checkbox checked={!!input.value ? true : false} onChange={input.onChange} value={value} color="primary" />}
                    label={label}
                    name={input.name}
                />
            </FormControl>
            <FormFieldFooter touched={touched} error={error} />
        </React.Fragment>
    )
}

export { renderCheckbox }
