import React from 'react'
import {
  RHFAutoCompletePrimitive,
  RHFRadioTable,
  CalibrationInstrumentOptions,
} from '@syncfab/machine'
import {
  TableRow,
  TableCell,
  Paper,
  Table,
  TableBody,
  TableHead,
  WithStyles,
} from '@material-ui/core'
import { useFormContext } from 'react-hook-form'

const generateYesNoValueLabel = () => {
  return [
    {
      value: 'Yes',
      label: 'Yes',
    },
    {
      value: 'No',
      label: 'No',
    },
  ]
}

const ColGroup = () => {
  return (
    <colgroup>
      <col style={{ width: '90%' }} />
      <col style={{ width: '5%' }} />
      <col style={{ width: '5%' }} />
    </colgroup>
  )
}

const rfqQuestions = [
  {
    question:
      'Are product features, special processes, inspections, workmanship criteria, regulatory requirements assessed and reviewed before quoting new RFQs?',
    field: 'rfqReview',
  },
  {
    question:
      'Do you have a dedicated team member whose sole purpose is to conduct said RFQ review?',
    field: 'rfqDedicated',
  },
]
const procurementQuestions = [
  {
    question:
      'Do you flow down requirements for quality assurance, inspection, testing, special processes, drawings and thier revision status when purchasing with outside vendors?',
    field: 'procurementFlow',
  },
  {
    question: 'Are all your sub-tier vendors located in the US?',
    field: 'procurementSub',
  },
  {
    question:
      'Do you have a purchase order quality review process that insures all PO requirements are met?',
    field: 'procurementQuantityReview',
  },
  {
    question:
      'Do you have a dedicated team member whose sole purpose is to ensure all specified requirements before final delivery?',
    field: 'procurementDedicated',
  },
]
const manufacturingProcessesQuestions = [
  {
    question:
      'Are work instructions, shop travelers, routers, etc. updated as the product is revised during production?',
    field: 'manufacturingInstructions',
  },
  {
    question:
      'Do you have a Foreign Object Debris (FOD) prevention plan/procedure?',
    field: 'manufacturingFOD',
  },
  {
    question:
      'Do you have a capabilities cut sheet available with your your facilities manufacturing equipment listed?',
    field: 'manufacturingCapabilitiesCut',
  },
]

const nonconformingProductsQuestions = [
  {
    question:
      'Do you have a process in place to ensure nonconforming products are identified and removed from production areas or destroyed to preclude from further usage?',
    field: 'nonConformingProcess',
  },
  {
    question:
      'When products are found to be nonconforming post-delivery do you have a corrective/preventive action procedure?',
    field: 'nonConformingCorrection',
  },
  {
    question:
      "When nonconforming product is your supplier's responsibility do you flow down a corrective action?",
    field: 'nonConformingFlowDown',
  },
]
const inspectionProcessQuestionsPartOne = [
  {
    question:
      'Is calibrated measuring equipment utilized in your company for product inspections?',
    field: 'inspectionCalibrationUtilized',
  },
]
const inspectionProcessQuestionsPartTwo = [
  {
    question: 'Is inspection equipment periodically calibrated for accuracy?',
    field: 'inspectionCalibratedPeriodically',
  },
  {
    question:
      'Is calibration completed in-house or performed by a certified 3rd party?',
    field: 'inspectionCalibratedInHouse',
  },
  {
    question:
      'Do your inspection records clearly display (per AS9102) identification callout, inspection tool used, inspection status of units, identification of inspector, quantity of units accepted/rejected?',
    field: 'inspectionRecords',
  },
  {
    question:
      'Is final inspection and/or test performed by an independent person different from team member manufacturing the part/component?',
    field: 'inspectionPerson',
  },
]
const materialControlQuestions = [
  {
    question:
      'Does your company accept and work with customer supplied material?',
    field: 'materialCustomer',
  },
  {
    question:
      'Is each piece, batch, lot or group of raw material identified by or traceable to: kind of material, type, condition, source of supply, heat number or lot number?',
    field: 'materialTraceable',
  },
  {
    question: 'Are materials inspected upon receipt?',
    field: 'materialReceipt',
  },
  {
    question: 'Are all materials stored and handled in a controlled area?',
    field: 'materialStored',
  },
  {
    question:
      'Are you able to handle and follow requirements of DFARs (Qualifying Country) material?',
    field: 'materialRequirements',
  },
]

export interface QuestionnaireFormProps extends WithStyles {
  teams: any[]
}

export const QuestionnaireForm = ({
  classes,
  teams,
}: QuestionnaireFormProps) => {
  const methods = useFormContext()
  const userOptions =
    teams.map(t => {
      return {
        value: t._id,
        label: `${t.firstName} ${t.lastName}`,
      }
    }) ?? []
  const rfqReviewWatcher = methods.watch('rfqDedicated')
  const procurementWatcher = methods.watch('procurementDedicated')
  const inspectionCalibrationWatcher = methods.watch(
    'inspectionCalibrationUtilized'
  )
  const inspectionPersonWatcher = methods.watch('inspectionPerson')

  // Update the label based on supplier tags.
  return (
    <Paper className={classes.paper}>
      <Table className={classes.table}>
        <ColGroup />
        <TableHead>
          <TableRow>
            <TableCell className={classes.tableCellHeader}>
              RFQ Process Review
            </TableCell>
            <TableCell className={classes.tableCellHeaderCentered}>
              Yes
            </TableCell>
            <TableCell className={classes.tableCellHeaderCentered}>
              No
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody className={classes.tableBody}>
          {rfqQuestions.map(q => {
            return (
              <TableRow
                key={`${q.field}Question`}
                className={classes.altTableRow}
              >
                <TableCell className={classes.tableCell}>
                  {q.question}
                </TableCell>
                <RHFRadioTable
                  name={q.field}
                  options={generateYesNoValueLabel()}
                  styledClass={classes.tableCell}
                />
              </TableRow>
            )
          })}
          {rfqReviewWatcher === 'Yes' && (
            <TableRow
              key={`rfqDedicatedUserQuestion`}
              className={classes.altTableRow}
            >
              <TableCell className={classes.tableCellAdded}>
                If Yes, please specify team member from dropdown or add them
                your company below:
                <RHFAutoCompletePrimitive
                  name="rfqDedicatedUser"
                  label=""
                  options={userOptions}
                  size="small"
                />
              </TableCell>
              <TableCell className={classes.tableCell} />
              <TableCell className={classes.tableCell} />
            </TableRow>
          )}
        </TableBody>
      </Table>
      <Table className={classes.table}>
        <ColGroup />
        <TableHead>
          <TableRow>
            <TableCell className={classes.tableCellHeader}>
              Procurement Control
            </TableCell>
            <TableCell className={classes.tableCellHeaderCentered}>
              Yes
            </TableCell>
            <TableCell className={classes.tableCellHeaderCentered}>
              No
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody className={classes.tableBody}>
          {procurementQuestions.map(q => {
            return (
              <TableRow
                key={`${q.field}Question`}
                className={classes.altTableRow}
              >
                <TableCell className={classes.tableCell}>
                  {q.question}
                </TableCell>
                <RHFRadioTable
                  name={q.field}
                  options={generateYesNoValueLabel()}
                  styledClass={classes.tableCell}
                />
              </TableRow>
            )
          })}
          {procurementWatcher === 'Yes' && (
            <TableRow
              key={`procurementDedicatedUserQuestion`}
              className={classes.altTableRow}
            >
              <TableCell className={classes.tableCellAdded}>
                If Yes, please specify team member from dropdown or add them
                your company below:
                <RHFAutoCompletePrimitive
                  name="procurementDedicatedUser"
                  label=""
                  options={userOptions}
                  size="small"
                />
              </TableCell>
              <TableCell className={classes.tableCell} />
              <TableCell className={classes.tableCell} />
            </TableRow>
          )}
        </TableBody>
      </Table>
      <Table className={classes.table}>
        <ColGroup />
        <TableHead>
          <TableRow>
            <TableCell className={classes.tableCellHeader}>
              Manufacturing Processes
            </TableCell>
            <TableCell className={classes.tableCellHeaderCentered}>
              Yes
            </TableCell>
            <TableCell className={classes.tableCellHeaderCentered}>
              No
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody className={classes.tableBody}>
          {manufacturingProcessesQuestions.map(q => {
            return (
              <TableRow
                key={`${q.field}Question`}
                className={classes.altTableRow}
              >
                <TableCell className={classes.tableCell}>
                  {q.question}
                </TableCell>
                <RHFRadioTable
                  name={q.field}
                  options={generateYesNoValueLabel()}
                  styledClass={classes.tableCell}
                />
              </TableRow>
            )
          })}
        </TableBody>
      </Table>
      <Table className={classes.table}>
        <ColGroup />
        <TableHead>
          <TableRow>
            <TableCell className={classes.tableCellHeader}>
              Nonconforming Products
            </TableCell>
            <TableCell className={classes.tableCellHeaderCentered}>
              Yes
            </TableCell>
            <TableCell className={classes.tableCellHeaderCentered}>
              No
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody className={classes.tableBody}>
          {nonconformingProductsQuestions.map(q => {
            return (
              <TableRow
                key={`${q.field}Question`}
                className={classes.altTableRow}
              >
                <TableCell className={classes.tableCell}>
                  {q.question}
                </TableCell>
                <RHFRadioTable
                  name={q.field}
                  options={generateYesNoValueLabel()}
                  styledClass={classes.tableCell}
                />
              </TableRow>
            )
          })}
        </TableBody>
      </Table>
      <Table className={classes.table}>
        <ColGroup />
        <TableHead>
          <TableRow>
            <TableCell className={classes.tableCellHeader}>
              Inspection Process
            </TableCell>
            <TableCell className={classes.tableCellHeaderCentered}>
              Yes
            </TableCell>
            <TableCell className={classes.tableCellHeaderCentered}>
              No
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody className={classes.tableBody}>
          {inspectionProcessQuestionsPartOne.map(q => {
            return (
              <TableRow
                key={`${q.field}Question`}
                className={classes.altTableRow}
              >
                <TableCell className={classes.tableCell}>
                  {q.question}
                </TableCell>
                <RHFRadioTable
                  name={q.field}
                  options={generateYesNoValueLabel()}
                  styledClass={classes.tableCell}
                />
              </TableRow>
            )
          })}
          {inspectionCalibrationWatcher === 'Yes' && (
            <TableRow
              key={`inspectionCalibrationUserQuestion`}
              className={classes.altTableRow}
            >
              <TableCell className={classes.tableCellAdded}>
                If Yes, what type of inspection equipment is available? [Select
                all that Apply]
                <RHFAutoCompletePrimitive
                  multiple
                  renderCheckboxes
                  required
                  name="inspectionCalibratedUser"
                  label=""
                  options={CalibrationInstrumentOptions}
                  size="small"
                />
              </TableCell>
              <TableCell className={classes.tableCell} />
              <TableCell className={classes.tableCell} />
            </TableRow>
          )}

          {inspectionProcessQuestionsPartTwo.map(q => {
            return (
              <TableRow
                key={`${q.field}Question`}
                className={classes.altTableRow}
              >
                <TableCell className={classes.tableCell}>
                  {q.question}
                </TableCell>
                <RHFRadioTable
                  name={q.field}
                  options={generateYesNoValueLabel()}
                  styledClass={classes.tableCell}
                />
              </TableRow>
            )
          })}
          {inspectionPersonWatcher === 'Yes' && (
            <TableRow
              key={`inspectionPersonUserQuestion`}
              className={classes.altTableRow}
            >
              <TableCell className={classes.tableCellAdded}>
                If Yes, please specify team member from dropdown or add them
                your company below:
                <RHFAutoCompletePrimitive
                  name="inspectionPersonUser"
                  label=""
                  options={userOptions}
                  size="small"
                />
              </TableCell>
              <TableCell className={classes.tableCell} />
              <TableCell className={classes.tableCell} />
            </TableRow>
          )}
        </TableBody>
      </Table>
      <Table className={classes.table}>
        <ColGroup />
        <TableHead>
          <TableRow>
            <TableCell className={classes.tableCellHeader}>
              Material Control
            </TableCell>
            <TableCell className={classes.tableCellHeaderCentered}>
              Yes
            </TableCell>
            <TableCell className={classes.tableCellHeaderCentered}>
              No
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody className={classes.tableBody}>
          {materialControlQuestions.map(q => {
            return (
              <TableRow
                key={`${q.field}Question`}
                className={classes.altTableRow}
              >
                <TableCell className={classes.tableCell}>
                  {q.question}
                </TableCell>
                <RHFRadioTable
                  name={q.field}
                  options={generateYesNoValueLabel()}
                  styledClass={classes.tableCell}
                />
              </TableRow>
            )
          })}
        </TableBody>
      </Table>
    </Paper>
  )
}

export default QuestionnaireForm
