import React, { FC } from "react";
import { Controller, UseControllerProps, useFormContext } from "react-hook-form";
import { ValueLabelOption } from "./ValueLabelOption";
import { AutoCompleteValueLabel, AutoCompleteValueLabelProps } from "./AutoCompleteValueLabel";

export const findSelectedValueLabelOptions = (options: ValueLabelOption[] = [], selectedValues: string[] = []): ValueLabelOption[] => {
    // options is an array of options for an autoselect element
    // need to return references to the autoselect options for the selected values
    const selectedOptions: ValueLabelOption[] = []
    selectedValues.forEach(value => {
        const foundOption = options.find(option => option.value === value)
        if (!!foundOption) {
            selectedOptions.push(foundOption)
        }
    })
    return selectedOptions
}

export const findSingleSelectedValueLabelOption = (options: ValueLabelOption[], selectedValue: string): ValueLabelOption => {
    // options is an array of options for an autoselect element
    // selectedValue is a string value representing the single option that should be selected
    // need to return references to the autoselect option corresponding to the selected values
    return options?.find(item => item.value === selectedValue)
}

export interface RHFAutoCompleteProps extends AutoCompleteValueLabelProps {
    rules?: UseControllerProps['rules']
    RHFAutoCompleteOnChangeHandler?: Function
}

export const RHFAutoComplete: FC<RHFAutoCompleteProps> = (props: RHFAutoCompleteProps) => {

    const methods = useFormContext()

    return (
        <Controller
            name={props.name}
            control={methods.control}
            rules={props.rules}
            render={({
                 field: {onChange, value, ref: fieldRef, onBlur},
                 fieldState: {error}
             }) => (
                <AutoCompleteValueLabel
                    value={value}
                    InputRef={fieldRef}
                    onBlurHandler={() => {
                        onBlur()
                    }}
                    onChangeHandler={(value) => {
                        onChange(value)
                        if (props.RHFAutoCompleteOnChangeHandler) {
                            props.RHFAutoCompleteOnChangeHandler(value)
                        }
                    }}
                    error={error}
                    { ...props }
                />
            )}
        />
    )
}
