import React from 'react'
import { Field, reduxForm } from 'redux-form/immutable'
import { InjectedFormProps } from 'redux-form'
import { Map } from 'immutable'
import { withNamespaces, WithNamespaces } from 'react-i18next'
import { FormTextField, jumpToFirstError, FieldSelect } from '@syncfab/machine'
import { Grid, MenuItem, Button } from '@material-ui/core'
import { WithStyles, withStyles } from '@material-ui/core/styles'

export const ContactSalesFormName: string = 'ContactSalesForm'

const styles = theme => ({
    form: {
        display: 'flex',
        'flex-direction': 'column',
        maxWidth: 600,
        minWidth: 300,
        margin: 'auto',
    },
    action: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
    },
    signupLink: {
        margin: 0,
        padding: 0,
        'text-decoration': 'none',
        color: theme.palette.primary.light,
        marginBottom: theme.spacing(1),
    },
})

type SendMFGTokensFields = {
    subject?: string
    description?: string
}
interface FormProperties {
    close: () => void
}
interface FormState {}

const validate = (data: Map<string, string>): {} => {
    const errors: SendMFGTokensFields = {}
    // TODO (JR): Unify
    if (!data.get('subject')) {
        errors.subject = 'Required'
    }

    if (!data.get('description')) {
        errors.description = 'Required'
    }

    return errors
}

interface FormProps extends InjectedFormProps<{}, {}>, WithStyles, WithNamespaces, FormProperties {}

class Form extends React.Component<FormProps, FormState> {
    constructor(props) {
        super(props)
        this.props.reset()
    }
    render() {
        const { handleSubmit, close } = this.props
        return (
            <form onSubmit={handleSubmit as any}>
                <Grid container spacing={2}>
                    <Grid container item xs={12} direction="row">
                        <Grid item xs={12}>
                            <Field name="subject" component={FieldSelect} label={'Subject'} fullWidth>
                                <MenuItem value={'activate'}>Activate MFG Wallet</MenuItem>
                                <MenuItem value={'other'}>Other</MenuItem>
                            </Field>
                        </Grid>
                        <Grid item xs={12}>
                            <Field
                                placeholder="Tell us more about the products you are interested in..."
                                name="description"
                                component={FormTextField}
                                type="text"
                                multiline
                                label="Description"
                                fullWidth
                            />
                        </Grid>
                        <Grid item xs={12} style={{ justifyContent: 'flex-end', display: 'flex' }}>
                            <Button color="primary" onClick={close}>
                                CLOSE
                            </Button>
                            <Button type="submit" color="primary" autoFocus>
                                SEND
                            </Button>
                        </Grid>
                    </Grid>
                </Grid>
            </form>
        )
    }
}

const translatedForm = withStyles(styles)(withNamespaces()(Form))
export const ContactSalesForm = reduxForm<{}, FormProperties>({
    form: ContactSalesFormName,
    validate,
    onSubmitFail: jumpToFirstError,
})(translatedForm as any)
