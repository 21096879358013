import React from 'react'
import { WithNamespaces, withNamespaces } from 'react-i18next'
import { State } from '../../../../application/Store'
import { Dispatch } from 'redux'
import { connect } from 'react-redux'
import { withStyles, WithStyles, Typography, Divider, Paper, Dialog, DialogContent, Grid, DialogActions, Button } from '@material-ui/core'
import { Map } from 'immutable'
import { PUSH, EnvironmentVariable, Environment } from '@syncfab/machine'
import CheckCircleIcon from '@material-ui/icons/CheckCircle'
import PurchaseMFGEdiorForm from './PurchaseMFGEdiorForm'
import { StripeProvider, Elements } from 'react-stripe-elements'
import { RESET_DATA } from '../../../../components/transaction/Actions'
import WarningIcon from '@material-ui/icons/Warning'
const styles = theme => ({
    paper: {
        width: '90%',
        minWidth: 300,
        padding: theme.spacing(4),
        marginTop: theme.spacing(4),
        marginBottom: theme.spacing(4),
    },
    divider: {
        marginBottom: theme.spacing(3),
        marginTop: theme.spacing(2),
    },

    paper2: {
        width: '80%',
        minWidth: 300,
        padding: theme.spacing(4),
        marginTop: theme.spacing(4),
        marginBottom: theme.spacing(4),
    },
    gridPadding: {
        padding: `${theme.spacing(2)}px ${theme.spacing(20)}px`,
    },
    balanceContainer: {
        border: `1px solid ${theme.palette.secondary.main}`,
        borderRadius: 15,
        margin: `${theme.spacing(2)}px ${theme.spacing(1)}px`,
        padding: theme.spacing(2),
    },
    mfgIcon: {
        width: 75,
        height: 75,
    },
    balance: {
        color: theme.palette.secondary.main,
        fontSize: 34,
        marginLeft: theme.spacing(1),
    },
    margin: {
        margin: theme.spacing(1),
    },
    paragraph: {
        color: '#D6665D',
    },
    warningButton: {
        color: '#ef5350',
        margin: theme.spacing(1),
        fontSize: 64,
    },
    errorButton: {
        color: '#ef5350',
        margin: theme.spacing(1),
        fontSize: 48,
    },
    processingButton: {
        color: '#40BF8A',
        margin: theme.spacing(2),
        fontSize: 48,
    },
    root: {
        backgroundColor: theme.palette.common.white,
        marginTop: theme.spacing(2),
        padding: theme.spacing(2),
        color: theme.palette.grey['700'],
    },

    horizontalheader: {
        backgroundColor: theme.palette.grey['300'],
        width: '35%',
        fontWeight: 900,
        // padding:`${theme.spacing.unit * 2}px 0`
    },
    link: {
        color: '#40B4BF',
        textDecoration: 'none',
    },

    tablePagination: {},
    tablePaginationCaption: {
        color: 'white',
    },
    tablePaginationSelectIcon: {
        color: 'white',
    },

    tablePaginationSelect: {
        color: 'black',
    },
    tablePaginationActions: {
        color: 'white',
    },
})

interface PurchaseMFGProps extends WithStyles, WithNamespaces {
    //push: (url: string) => void
    purchaseCompleteDialog: boolean
    failedPurchaseDialog: boolean
    resetErrorMsg: () => void
    successMessage: any
    history?: any
}

interface PurchaseMFGState {
    stripe: any
}
declare global {
    interface Window {
        // add you custom properties and methods
        Stripe: any
    }
}

class PurchaseMFG extends React.Component<PurchaseMFGProps, PurchaseMFGState> {
    constructor(props) {
        super(props)
        this.state = {
            stripe: null,
        }
        this.handleClose = this.handleClose.bind(this)

        const environment = new Environment()
        environment.load()

        const stripeJs = document.createElement('script')
        stripeJs.src = 'https://js.stripe.com/v3/'
        stripeJs.async = true
        stripeJs.onload = () => {
            this.setState({
                stripe: window.Stripe(environment.get(EnvironmentVariable.STRIPE_PUBLISHABLE_KEY)),
            })
        }

        document.body && document.body.appendChild(stripeJs)
    }
    handleClose() {
        this.props.resetErrorMsg()
        this.props.history.push('/wallet')
        // this.props.resetErrorMsg()
    }
    onSubmit(values: Map<string, string>) {
        console.log(values)
    }

    render() {
        const { classes, purchaseCompleteDialog, failedPurchaseDialog, successMessage } = this.props

        const { stripe } = this.state
        return (
            <Paper className={classes.paper} elevation={1} style={{ padding: '24px' }}>
                <Typography gutterBottom variant="h2">
                    {'Purchase MFG'}
                </Typography>
                <Divider className={classes.divider} />
                {stripe && (
                    <StripeProvider stripe={this.state.stripe}>
                        <Elements>
                            <PurchaseMFGEdiorForm />
                        </Elements>
                    </StripeProvider>
                )}

                <Dialog aria-labelledby="simple-dialog-title" open={purchaseCompleteDialog && successMessage.status && successMessage.status === 'succeeded'} onClose={this.handleClose}>
                    <DialogContent>
                        <Grid container justify="center">
                            <Grid item container justify="center" xs={12}>
                                <CheckCircleIcon className={classes.processingButton} />
                            </Grid>
                            <Typography gutterBottom variant="h3">
                                MFG Purchase Complete
                            </Typography>
                            <Grid item container justify="center" xs={12}>
                                <Typography align="center">Thank you for purchasing MFG tokens.Please allow 2-3 minutes for tokens to be deposited into your MFG Wallet</Typography>
                            </Grid>
                        </Grid>
                    </DialogContent>
                    <DialogActions>
                        <Grid container justify="center">
                            <Button onClick={this.handleClose} color="primary" autoFocus>
                                OKAY
                            </Button>
                        </Grid>
                    </DialogActions>
                </Dialog>
                <Dialog aria-labelledby="simple-dialog-title" open={failedPurchaseDialog || (purchaseCompleteDialog && !successMessage.status)} onClose={this.props.resetErrorMsg}>
                    <DialogContent>
                        <Grid container justify="center">
                            <Grid item container justify="center" xs={12}>
                                <WarningIcon className={classes.warningButton} />
                            </Grid>

                            <Typography gutterBottom variant="h3">
                                Purchase MFG failed
                            </Typography>
                            <Grid item container justify="center" xs={12}>
                                <Typography align="center">You initiated a purchase MFG request. Unfortunately, the transaction failed. </Typography>
                            </Grid>
                        </Grid>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={this.props.resetErrorMsg} color="primary" autoFocus>
                            CLOSE
                        </Button>
                    </DialogActions>
                </Dialog>

                {/* {sendToNextPage && <Redirect to="/parts" />}  */}
            </Paper>
        )
    }
}

const EditorWithTranslations = withStyles(styles)(withNamespaces()(PurchaseMFG))

const mapStateToProps = (state: State) => {
    return {
        isLoading: state.part.isLoading,
        purchaseCompleteDialog: state.transaction.purchaseCompleteDialog,
        failedPurchaseDialog: state.transaction.failedPurchaseDialog,
        successMessage: state.transaction.successMessage,
    }
}

const mapDispatchToProps = (dispatch: Dispatch) => {
    return {
        push: (url: string) => dispatch(PUSH(url)),
        resetErrorMsg: () => dispatch(RESET_DATA.actions.TRIGGER()),
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(EditorWithTranslations)
