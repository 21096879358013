import 'date-fns';
import React from 'react';
import { FormControl, InputAdornment } from '@material-ui/core';
import { Lock, DateRange } from '@material-ui/icons';
import { FormFieldFooter } from '../Error';
import DateFnsUtils from '@date-io/date-fns';
import { MuiPickersUtilsProvider, DatePicker } from '@material-ui/pickers';

export const DateTimePickerField = ({
  input,
  label,
  meta: { touched, error },
  fullWidth,
  className,
  disabled,
  description,
  InputProps,
  minDate,
  inputVariant,
}: any) => {
  let inputProps = InputProps;
  if (disabled && !InputProps) {
    inputProps = {
      endAdornment: (
        <InputAdornment position="end" style={{ backgroundColor: 'inherit' }}>
          <Lock />
        </InputAdornment>
      ),
    };
  } else {
    inputProps = {
      endAdornment: (
        <InputAdornment position="end">
          <DateRange />
        </InputAdornment>
      ),
    };
  }
  return (
    <FormControl fullWidth={fullWidth} className={className}>
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <DatePicker
          margin="normal"
          inputVariant={inputVariant || 'filled'}
          label={label}
          value={input.value || null}
          onChange={date => input.onChange(date)}
          error={!!touched && !!error}
          InputProps={inputProps}
          minDate={minDate}
          format="MM/dd/yyyy"
          disabled={disabled}
          onBlur={input.onBlur}
          clearable
          helperText={!!error ? error.message : null}
        />
      </MuiPickersUtilsProvider>
      <FormFieldFooter
        touched={touched}
        error={error}
        description={description}
      />
    </FormControl>
  );
};
