import React, { FC } from 'react'
import { useAppSelector } from "../../../application/hooks";
import { LoginRole } from "@syncfab/machine";
import { ViewBuyerRFQ } from "./ViewBuyerRFQ";
import { ViewSupplierRFQ } from "./ViewSupplierRFQ";

export const ViewRFQ: FC = () => {

    const loggedInUser = useAppSelector(state => state.user.loggedUser)

    if (!!loggedInUser) {
        return (
            <>
                {loggedInUser.loginRole === LoginRole.SUPPLIER && (
                    <ViewSupplierRFQ />
                )}
                {loggedInUser.loginRole === LoginRole.BUYER && (
                    <ViewBuyerRFQ />
                )}
            </>
        )
    }
    return null
}