import { UserIdAndName } from '../BaseTypes'
import { ValueLabelOption } from "../ui";

export interface QuoteInput {
    rfqId: string
    expiresAt: Date
    notes: string
}

export interface Quote extends QuoteInput {
    _id: string
    createdAt: Date
    createdBy: UserIdAndName
    updatedAt?: Date
    updatedBy?: UserIdAndName
    deletedAt?: Date
    deletedBy?: UserIdAndName
    sentAt?: Date
    sentBy?: UserIdAndName
}

export interface QuoteDetail extends Quote {
    lineItems: QuoteLineItem[]
    subtotalMin: number
    subtotalMax: number
    lotCharges: Charge[]
    totalMin: number
    totalMax: number
    totalSupplierCostsMax: number
    totalSupplierCostsMin: number
}
export interface QuoteLineItemInput {
    rfqLineItemId: string
    selectedSupplier: {
        supplierId: string
    }
    pricing: ItemPricing[]
    additionalCharges: Charge[]
    exceptions?: string
    notes?: string
}

export interface QuoteLineItem extends QuoteLineItemInput {
    _id: string
    quoteId: string
    rfqId: string
    selectedSupplier: {
        supplierId: string
        supplierName: string
    }
    createdAt: Date
    createdBy: UserIdAndName
    updatedAt?: Date
    updatedBy?: UserIdAndName
    deletedAt?: Date
    deletedBy?: UserIdAndName
}

export interface ItemPricing {
    quantity: number
    leadTimeDays: number
    supplierUnitCost: number
    margin: number
    unitCost: number
    subtotal: number
}

export interface Charge {
    chargeType: string
    chargeAmount: number
}

export const chargeTypeOptions: ValueLabelOption[] = [
    { value: 'Expedite', label: 'Expedite' },
    { value: 'Finish', label: 'Finish' },
    { value: 'FAI', label: 'FAI' },
    { value: 'Labor', label: 'Labor' },
    { value: 'Material', label: 'Material' },
    { value: 'Non-Recurring Engineering (NRE)', label: 'Non-Recurring Engineering (NRE)' },
    { value: 'Shipping', label: 'Shipping' },
    { value: 'Tooling', label: 'Tooling' },
]
