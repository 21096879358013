import { ValueLabelOption } from "../ui";

export enum TagType {
    MANUFACTURING = 'MANUFACTURING',
    FINISHING = 'FINISHING',
    CERTIFICATIONS = 'CERTIFICATIONS',
    PRODUCT_PREFERENCES = 'PRODUCT_PREFERENCES',
    MATERIAL_PREFERENCES = 'MATERIAL_PREFERENCES',
    AFFILIATE_ORGANIZATIONS = 'AFFILIATE_ORGANIZATIONS',
    LOT_SIZE = 'LOT_SIZE',
    LEAD_TIME = 'LEAD_TIME',
}

export interface Tag {
    name: string
    type: TagType
    value: string
}

export const filterTagsByType = (tags: Tag[], tagType: TagType) => {
    return tags?.length > 0 ? tags.filter(tag => tag.type === tagType) : []
}

export const createTagOption = (tag: Tag): ValueLabelOption => {
    return { value: tag.value, label: tag.name }
}

export const createOptionsForTagType = (tags: Tag[], tagType: TagType) => {
    return filterTagsByType(tags, tagType).map(tag => createTagOption(tag))
}

export const checkTagExistence = (tag: Tag): ValueLabelOption => {
    if (!!tag) {
        return createTagOption(tag)
    } else {
        return { value: 'REMOVED', label: 'REMOVED' }
    }
}