import React from 'react'
import SvgIcon from '@material-ui/core/SvgIcon'

export const LockIcon = props => {
    return (
        <SvgIcon {...props} viewBox="0 0 24 24" x="0px" y="0px" baseProfile="tiny">
            <path
                id="lock_FILL1_wght400_GRAD0_opsz20"
                d="M5.5,18A1.5,1.5,0,0,1,4,16.5v-8A1.5,1.5,0,0,1,5.5,7H6V5A3.855,3.855,0,0,1,7.167,2.167,3.855,3.855,0,0,1,10,1a3.855,3.855,0,0,1,2.833,1.167A3.855,3.855,0,0,1,14,5V7h.5A1.5,1.5,0,0,1,16,8.5v8A1.5,1.5,0,0,1,14.5,18ZM10,14a1.5,1.5,0,1,0-1.062-.438A1.444,1.444,0,0,0,10,14ZM7.5,7h5V5A2.489,2.489,0,0,0,10,2.5,2.489,2.489,0,0,0,7.5,5Z"
                transform="translate(-4 -1)"
                fill="#dbdbdb"
            />
        </SvgIcon>
    )
}
