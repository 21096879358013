import React, { FC } from "react";
import { Chip, useTheme } from "@material-ui/core";
import { getSupplierPOStatusBackgroundColor, PurchaseOrderStatus } from "@syncfab/machine";

interface SupplierPOStatusChipProps {
    status: PurchaseOrderStatus
}
export const SupplierPOStatusChip: FC<SupplierPOStatusChipProps> = (({ status }) => {

    const theme = useTheme()
    return (
        <Chip
            style={{ paddingLeft: '8px', paddingRight: '8px', fontWeight: 'bold', color: '#fff', backgroundColor: getSupplierPOStatusBackgroundColor(status, theme)}}
            size="small"
            label={status.toUpperCase()}
        />
    )
})