import {
  SHOW_FEEDBACK_ACTION_TYPE,
  HIDE_FEEDBACK_ACTION_TYPE,
  SET_SAVING_MESSAGE_TYPE,
  SHOW_COMPLETE_FEEDBACK_ACTION_TYPE,
  HIDE_COMPLETE_FEEDBACK_ACTION_TYPE,
  SHOW_ERROR_FEEDBACK_ACTION_TYPE,
  HIDE_ERROR_FEEDBACK_ACTION_TYPE,
  SHOW_SAVING_FEEDBACK_ACTION_TYPE,
  HIDE_SAVING_FEEDBACK_ACTION_TYPE,
  SHOW_DIRTY_FEEDBACK_ACTION_TYPE,
  HIDE_DIRTY_FEEDBACK_ACTION_TYPE,
} from './Actions';

export type FeedbackReducerType = {
  show: boolean;
  message?: string;
  savingMessage?: string;
  isSaving: boolean;
  isError: boolean;
  isComplete: boolean;
  isDirty: boolean;
};

const initialState: FeedbackReducerType = {
  show: false,
  message: null,
  savingMessage: null,
  isSaving: false,
  isError: false,
  isComplete: false,
  isDirty: false,
};

export const FeedbackReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case SHOW_FEEDBACK_ACTION_TYPE:
      return {
        ...state,
        message: action.message,
        show: true,
      };
    case HIDE_FEEDBACK_ACTION_TYPE:
      return {
        ...state,
        message: null,
        show: false,
      };
    case SET_SAVING_MESSAGE_TYPE:
      return {
        ...state,
        savingMessage: action.message,
      };
    case SHOW_COMPLETE_FEEDBACK_ACTION_TYPE:
      return {
        ...state,
        isComplete: true,
      };
    case HIDE_COMPLETE_FEEDBACK_ACTION_TYPE:
      return {
        ...state,
        isComplete: false,
      };
    case SHOW_ERROR_FEEDBACK_ACTION_TYPE:
      return {
        ...state,
        isError: true,
      };
    case HIDE_ERROR_FEEDBACK_ACTION_TYPE:
      return {
        ...state,
        isError: false,
      };
    case SHOW_SAVING_FEEDBACK_ACTION_TYPE:
      return {
        ...state,
        isSaving: true,
      };
    case HIDE_SAVING_FEEDBACK_ACTION_TYPE:
      return {
        ...state,
        isSaving: false,
      };
    case SHOW_DIRTY_FEEDBACK_ACTION_TYPE:
      return {
        ...state,
        isDirty: true,
      };
    case HIDE_DIRTY_FEEDBACK_ACTION_TYPE:
      return {
        ...state,
        isDirty: false,
      };
    default:
      return state;
  }
};
