import React, { FC } from "react";
import { useListPurchaseOrdersQuery } from "@syncfab/machine";
import { PurchaseOrderList } from "./PurchaseOrderList";

export const BuyerPurchaseOrderList: FC = () => {

    // use list parameters stored in redux state so that the search criteria carry over even if the user navigates

    const buyerPurchaseOrderColumns = [
        { id: 'buyerPOReference', label: 'Customer PO' },
        { id: '_id', label: 'PO ID' },
        { id: 'status', label: 'PO Status' },
        { id: 'nextDeliveryDate', label: 'Next Dock Date' },
        { id: 'initialPromiseDate', label: 'Final Delivery Date' },
        { id: 'lineProgress', label: 'Progress', fixed: true },
        { id: 'poTotal', label: 'PO Total' },
        { id: 'actionItem', label: 'Action Item', fixed: true }
    ]

    return (
        <PurchaseOrderList
            listColumns={buyerPurchaseOrderColumns}
            retrieveData={useListPurchaseOrdersQuery}
        />
    )
}