import React from 'react'
import SvgIcon from '@material-ui/core/SvgIcon'

export const ArrowDownWhite = props => {
    return (
        <SvgIcon {...props} viewBox="0 0 24 24" x="0px" y="0px" baseProfile="tiny" xmlSpace="preserve" fill="#fff">
            <path d="M0 0h24v24H0z" fill="none" />
            <path d="M7 10l5 5 5-5z" />
        </SvgIcon>
    )
}
