import React from 'react'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import TableCell from '@material-ui/core/TableCell'
import Tooltip from '@material-ui/core/Tooltip'
import TableSortLabel from '@material-ui/core/TableSortLabel'
import { SortDirection } from './Meta'
import { Checkbox, Theme, Typography, withStyles } from '@material-ui/core'
export interface ListHeadProps {
    sortDirection: SortDirection
    sortField: string
    onRequestSort: Function
    rows: { id: string; label: string; fixed?: boolean }[]
    selected?: number
    onSelectAllClick?: any
    total?: number
    classes?: any
    showSelectAll?: boolean
}

class ListHead extends React.Component<ListHeadProps> {
    createSortHandler = property => event => {
        this.props.onRequestSort(event, property)
    }

    render() {
        const { sortDirection, sortField, rows, selected, onSelectAllClick, total, classes, showSelectAll } = this.props
        const order = sortDirection === SortDirection.ASC ? 'asc' : 'desc'
        return (
            <TableHead>
                <TableRow>
                    {showSelectAll && (
                        <TableCell align="left" padding="normal" style={{ borderBottom: '1px solid #65788B' }}>
                            <Checkbox
                                color="primary"
                                classes={{ root: classes.checkbox, indeterminate: classes.indeterminate }}
                                indeterminate={selected > 0 && selected < total}
                                checked={selected === total}
                                onChange={onSelectAllClick}
                            />
                        </TableCell>
                    )}
                    {rows.map(row => {
                        return (
                            <TableCell align="left" padding="normal" style={{ borderBottom: '1px solid #65788B' }} key={row.id} sortDirection={!row.fixed && sortField === row.id ? order : false}>
                                <Tooltip title="Sort" enterDelay={300}>
                                    <React.Fragment>
                                        {!row.fixed && (
                                            <TableSortLabel
                                                style={{ fontWeight: 'bold', color: '#fff', fontSize: '14px' }}
                                                active={sortField === row.id}
                                                direction={order}
                                                onClick={this.createSortHandler(row.id)}
                                            >
                                                {row.label}
                                            </TableSortLabel>
                                        )}
                                        {!!row.fixed && (
                                            <Typography variant="caption" style={{ fontWeight: 'bold', color: '#fff', fontSize: '14px' }}>
                                                {row.label}
                                            </Typography>
                                        )}
                                    </React.Fragment>
                                </Tooltip>
                            </TableCell>
                        )
                    }, this)}
                </TableRow>
            </TableHead>
        )
    }
}

const tableheadStyle = (theme: Theme) => ({
    root: {},

    checkbox: {
        color: theme.palette.common.white,
    },
    indeterminate: {
        color: theme.palette.primary.main,
    },
})
//export default EnhancedTableHead as any

export default withStyles(tableheadStyle)(ListHead as any)
