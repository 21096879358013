import { AnalyticsEventTrackingPayload, TRACK_EVENT } from './Actions'
import { call, takeLatest, getContext } from 'redux-saga/effects'
import { Gateway } from '../../components/gateway'
import { GET_LOGGED_USER } from '../../components/user/Actions'
import { LOGIN_SUCESSFUL_ACTION_TYPE, User } from '@syncfab/machine'

function* trackEvent({ data }: { data: AnalyticsEventTrackingPayload }) {
    try {
        const gateway: Gateway = yield getContext('gateway')
        yield call(gateway.getAnalyticsService().trackEvent(data.event, data.payload))
    } catch (e) {
        // Nothing to do
    }
}

function* identity({ data }: { data: User }) {
    try {
        const gateway: Gateway = yield getContext('gateway')
        yield call(gateway.getAnalyticsService().identify(data))
    } catch (e) {
        // Nothing to do
    }
}

function* analyticsSaga() {
    // Typings are not good enough. String should be valid but the types
    // do not show it
    yield takeLatest(TRACK_EVENT.types.TRIGGER as any, trackEvent)
    yield takeLatest(LOGIN_SUCESSFUL_ACTION_TYPE as any, identity)
    yield takeLatest(GET_LOGGED_USER.types.SUCCESSFULL as any, identity)
}

export default analyticsSaga
