export const jumpToFirstError = errors => {
    // Element does not have the focus event
    if (!errors) {
        return
    }
    const errorEl: any = document.querySelector(
        Object.keys(errors)
            .map(name => `[name="${name}"]`)
            .join(',')
    )
    if (errorEl && errorEl.focus) {
        if (errorEl.scrollIntoView) {
            errorEl.scrollIntoView()
        }
        ;(errorEl as any).focus()
    }
}
