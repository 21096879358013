export type FetchActions<A, T> = {
    types: {
        TRIGGER: Symbol
        SUCCESSFULL: Symbol
        FAILED: Symbol
    }
    actions: {
        TRIGGER: (args?: A) => { type: Symbol; data: A }
        SUCCESSFULL: (args?: T) => { type: Symbol; data: T }
        FAILED: (error: string) => { type: Symbol; error: string }
    }
}

export const createFetchActions = <A, T>(type: string): FetchActions<A, T> => {
    const types = {
        TRIGGER: Symbol(type),
        SUCCESSFULL: Symbol(type),
        FAILED: Symbol(type),
    }
    return {
        types,
        actions: {
            TRIGGER: (args?: A) => {
                return {
                    data: args!,
                    type: types.TRIGGER,
                }
            },
            SUCCESSFULL: (args?: T) => {
                return {
                    data: args!,
                    type: types.SUCCESSFULL,
                }
            },
            FAILED: (error: string) => {
                return {
                    error,
                    type: types.FAILED,
                }
            },
        },
    }
}
