import React from 'react'
import { Field, reduxForm } from 'redux-form/immutable'
import { InjectedFormProps } from 'redux-form'
import { Map } from 'immutable'
import { WithStyles, withStyles } from '@material-ui/core/styles'
import { withNamespaces, WithNamespaces } from 'react-i18next'
import { Button, jumpToFirstError, FormPasswordField } from '@syncfab/machine'
import { Grid } from '@material-ui/core'

export const SettingsPasswordFormName: string = 'settingsPasswordForm'

const styles = theme => ({
    form: {
        display: 'flex',
        'flex-direction': 'column',
        margin: 'auto',
    },
    action: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
        marginLeft: 'auto',
    },
    gridItem: {
        paddingTop: '0px !important',
        paddingBottom: '0px !important',
    },
    formInput: {
        '& .MuiFormControl-marginNormal': {
            margin: 0,
        },
    },
})

type PasswordSettingsFields = {
    password?: string
    newPassword?: string
    reNewPassword?: string
}

const validate = (data: Map<string, string>): {} => {
    const errors: PasswordSettingsFields = {}
        // TODO (JR): Unify
    ;['password', 'newPassword', 'reNewPassword'].forEach(field => {
        if (!data.get(field)) {
            errors[field] = 'Required'
        } else if (!/^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/.test(data.get(field) || '')) {
            errors[field] = 'Passwords must contain at least eight characters.  They must also include at least 1 lower case character, 1 upper case character, 1 number and 1 special character.'
        }
    })

    const newPassword = data.get('newPassword')
    const reNewPassword = data.get('reNewPassword')
    if (!!newPassword && !!reNewPassword && newPassword !== reNewPassword) {
        errors.reNewPassword = 'Do not match'
    }

    return errors
}

const form = (props: InjectedFormProps<{}, {}> & WithStyles & WithNamespaces) => {
    const { handleSubmit, submitting, t, classes } = props
    return (
        <form onSubmit={handleSubmit} className={props.classes.form}>
            <Grid container spacing={3}>
                <Grid item md={12} xs={12} className={classes.gridItem}>
                    <Field name="password" component={FormPasswordField as any} label={t('user-password')} fullWidth className={classes.formInput} />
                </Grid>
                <Grid item md={6} xs={12} className={classes.gridItem}>
                    <Field name="newPassword" component={FormPasswordField as any} label={t('user-newPassword')} fullWidth className={classes.formInput} />
                </Grid>
                <Grid item md={6} xs={12} className={classes.gridItem}>
                    <Field name="reNewPassword" component={FormPasswordField as any} label={t('user-reNewPassword')} fullWidth className={classes.formInput} />
                </Grid>
                <Grid item md={12} xs={12} style={{ textAlign: 'end' }}>
                    <Button type="submit" color="primary" disabled={submitting} className={classes.action}>
                        {t('form-action-change')}
                    </Button>
                </Grid>
            </Grid>
        </form>
    )
}

const translatedForm = withNamespaces()(withStyles(styles)(form))
export const SettingsPasswordForm = reduxForm<{}, {}>({
    form: SettingsPasswordFormName,
    validate,
    onSubmitFail: jumpToFirstError,
})(translatedForm)
