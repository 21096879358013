import { call, takeLatest, getContext, put,select } from 'redux-saga/effects'
import { Gateway } from '../gateway'
import {
    GET_LOGGED_USER,
    USER_UPDATE,
    USER_PASSWORD,
    USER_EMAIL_UPDATE,
    USER_EMAIL_VALIDATE,
    UserEmailValidatePayload,
    FORGOT_PASSWORD,
    UpdateForgottenPasswordPayload,
    UPDATE_FORGOT_PASSWORD,
    GET_USER,
    SAVE_USER,
    LIST_USERS,
    UserActivationPayload,
    ACTIVATE_INVITED_USER,
    DELETE_USER,
    RESEND_INVITATION_USER,
    SAVE_PREVIOUSURL,
} from './Actions'
import { GET_SUPPLIER_TAGS, GET_SUPPLIERS } from '../supplier/Actions'
import { startSubmit, stopSubmit, reset } from 'redux-form'
import { LoginFormName } from '../../view/login/components/LoginForm'
import { SettingsPasswordFormName } from '../../view/settings/components/UpdatePassordForm'
import { SettingsPersonalFormName } from '../../view/settings/components/UserSettingsForm'
import { ForgotPasswordFormName } from '../../view/password/components/ForgotPasswordForm'
import {
    SHOW_FEEDBACK,
    LoginAction,
    LOGIN_SUCESSFUL,
    LOGIN_FAILED,
    LOGIN_ACTION_TYPE,
    LOGOUT_ACTION_TYPE,
    PUSH,
    api
} from '@syncfab/machine'
import { SettingsNotificationsFormName } from '../../view/settings/components/UserNotificationSettingsForm'
import { SettingsEmailFormName } from '../../view/settings/components/UpdateEmailForm'
import { UpdatePasswordFormName } from '../../view/password/components/UpdatePasswordForm'
import { UserProdileEditorFormName } from '../../view/users/editor/components/UserProfileEditorForm'
import { User } from '@syncfab/machine'
import { UserActivationFormName } from '../../view/users/activation/components/UserActivationForm'
import { ListMeta } from '../../layout/list/Meta'
import { listMeta } from './Selector'

function* getLoggedUser() {
    try {
        const gateway: Gateway = yield getContext('gateway')
        const { data } = yield call(gateway.getUserAPI().getLoggedUser())
        yield put(GET_LOGGED_USER.actions.SUCCESSFULL(data))
        const data2 = yield call(gateway.getSupplierAPI().getSuppliers())
        yield put(GET_SUPPLIERS.actions.SUCCESSFULL(data2.data))
    } catch ({ response: { status, data } }) {
        const handleError = yield getContext('handleError')
        yield call(handleError(status))
        yield put(GET_LOGGED_USER.actions.FAILED(data))
    }
}

function* list({ data: { meta } }: { data: { meta: ListMeta } }) {
    try {
        const gateway: Gateway = yield getContext('gateway')
        const { page, size, sortField, sortDirection, query, loginRole, userState } = meta
        let search = null
        if(query && query !==''){
           search = query
        }
        const { data } = yield call(gateway.getUserAPI().list({ page, size, sortField, sortDirection, search, loginRole, userState }))
        yield put(LIST_USERS.actions.SUCCESSFULL(data))
    } catch ({ response: { status, data } }) {
        const handleError = yield getContext('handleError')
        yield call(handleError(status))
        yield put(LIST_USERS.actions.FAILED(data))
    }
}

function* get({ data: { id } }) {
    try {
        const gateway: Gateway = yield getContext('gateway')
        const { data } = yield call(gateway.getUserAPI().get(id))
        yield put(GET_USER.actions.SUCCESSFULL(data))
    } catch ({ response: { status, data } }) {
        const handleError = yield getContext('handleError')
        yield call(handleError(status))
        yield put(GET_USER.actions.FAILED(data))
    }
}

function* deleteUser({ data: { id } }) {
    try {
        const gateway: Gateway = yield getContext('gateway')
        yield call(gateway.getUserAPI().deleteUser(id))
        yield put(DELETE_USER.actions.SUCCESSFULL())
        const meta = yield select(listMeta)
        if(meta?.loginRole){
            delete meta.loginRole
        }
        yield call(list, { data: { meta } })
        yield put(SHOW_FEEDBACK('feedback-user-deleted'))
    } catch ({ response: { status, data } }) {
        const handleError = yield getContext('handleError')
        yield call(handleError(status))
        yield put(DELETE_USER.actions.FAILED(data))
    }
}

function* resendInvitation({ data: { id } }) {
    try {
        const gateway: Gateway = yield getContext('gateway')
        yield call(gateway.getUserAPI().resendInvitation(id))
        yield put(RESEND_INVITATION_USER.actions.SUCCESSFULL())
        yield put(SHOW_FEEDBACK('feedback-user-resend-invite'))
    } catch ({ response: { status, data } }) {
        const handleError = yield getContext('handleError')
        yield call(handleError(status))
        yield put(RESEND_INVITATION_USER.actions.FAILED(data))
    }
}

function* login({ data: { email, password, previousUrl } }: LoginAction) {
    try {
        yield put(startSubmit(LoginFormName))
        const gateway: Gateway = yield getContext('gateway')
        const {
            data: { token, user },
        } = yield call(gateway.getUserAPI().loginUser(email, password))
        yield put(LOGIN_SUCESSFUL(token, user))
        yield put(GET_LOGGED_USER.actions.TRIGGER())
        const data2 = yield call(gateway.getSupplierAPI().getSuppliers())
        yield put(GET_SUPPLIERS.actions.SUCCESSFULL(data2.data))
        const response = yield call(gateway.getSupplierAPI().getSupplierTags())
        yield put(GET_SUPPLIER_TAGS.actions.SUCCESSFULL(response.data.data))
        // yield put(PUSH('/track/O101522211-part_060220201-1'))
        previousUrl != undefined ? yield put(PUSH(previousUrl)) : yield put(PUSH('/quotes'))
        // yield put(PUSH('/quotes'))

        yield put(reset(LoginFormName))
        yield put(stopSubmit(LoginFormName))
    } catch ({ response: { status, data } }) {
        const handleError = yield getContext('handleError')
        yield call(handleError(status))
        yield put(LOGIN_FAILED(data))
        yield put(stopSubmit(LoginFormName))
    }
}

function* activate({ data }: { data: UserActivationPayload }) {
    try {
        yield put(startSubmit(UserActivationFormName))
        const gateway: Gateway = yield getContext('gateway')
        yield call(gateway.getUserAPI().activateInvitedUser(data))

        yield put(ACTIVATE_INVITED_USER.actions.SUCCESSFULL())
        yield put(PUSH('/suppliers/login'))
        yield put(reset(UserActivationFormName))
        yield put(stopSubmit(UserActivationFormName))
    } catch ({ response: { status, data } }) {
        const handleError = yield getContext('handleError')
        yield call(handleError(status))
        yield put(ACTIVATE_INVITED_USER.actions.FAILED(data))
        yield put(SHOW_FEEDBACK('feedback-support'))
        yield put(stopSubmit(UserActivationFormName))
    }
}

function* forgotPassword({ data: { email } }: { data: { email: string } }) {
    try {
        yield put(startSubmit(ForgotPasswordFormName))
        const gateway: Gateway = yield getContext('gateway')
        const { data } = yield call(gateway.getUserAPI().forgotPassword(email))

        yield put(FORGOT_PASSWORD.actions.SUCCESSFULL({ email: data }))
        yield put(PUSH('/forgot/confirmation'))
        yield put(reset(ForgotPasswordFormName))
        yield put(stopSubmit(ForgotPasswordFormName))
    } catch ({ response: { status, data } }) {
        const handleError = yield getContext('handleError')
        yield call(handleError(status))
        yield put(FORGOT_PASSWORD.actions.FAILED(data))
        yield put(stopSubmit(ForgotPasswordFormName))
    }
}

function* update({ data: { id, delta,history } }: any) {
    try {        
        yield put(startSubmit(SettingsPersonalFormName))
        yield put(startSubmit(SettingsNotificationsFormName))
        yield put(startSubmit(UserProdileEditorFormName))
        const gateway: Gateway = yield getContext('gateway')
        yield call(gateway.getUserAPI().update(id, delta))

        yield put(USER_UPDATE.actions.SUCCESSFULL())
        yield put(GET_LOGGED_USER.actions.TRIGGER())
        yield put(SHOW_FEEDBACK('feedback-user-update'))
        history != undefined ? history.push('/settings/team') : ''
        yield put(stopSubmit(SettingsNotificationsFormName))
        yield put(stopSubmit(UserProdileEditorFormName))
        yield put(stopSubmit(SettingsPersonalFormName))
    } catch ({ response: { status, data } }) {
        const handleError = yield getContext('handleError')
        yield call(handleError(status))
        yield put(USER_UPDATE.actions.FAILED(data))
        yield put(stopSubmit(SettingsNotificationsFormName))
        yield put(stopSubmit(UserProdileEditorFormName))
        yield put(stopSubmit(SettingsPersonalFormName))
    }
}

function* save({ data: { user,history } }: { data: { user: User,history } }) {
    try {
        yield put(startSubmit(UserProdileEditorFormName))
        const gateway: Gateway = yield getContext('gateway')
        yield call(gateway.getUserAPI().save(user))
        yield put(USER_UPDATE.actions.SUCCESSFULL())
        yield put(SHOW_FEEDBACK('feedback-user-saved'))
        yield put(stopSubmit(UserProdileEditorFormName))
        history != undefined ? history.push('/settings/team') : yield put(PUSH('/settings/team'))
    } catch ({ response: { status, data } }) {
        const handleError = yield getContext('handleError')
        yield call(handleError(status))
        yield put(USER_UPDATE.actions.FAILED(data))
        yield put(stopSubmit(UserProdileEditorFormName))
    }
}

function* updatePassword({ data: { password, newPassword } }: any) {
    try {
        yield put(startSubmit(SettingsPasswordFormName))
        const gateway: Gateway = yield getContext('gateway')
        yield call(gateway.getUserAPI().updatePassword(password, newPassword))

        yield put(USER_PASSWORD.actions.SUCCESSFULL())
        yield put(SHOW_FEEDBACK('feedback-password-update'))
        yield put(stopSubmit(SettingsPasswordFormName))
        yield put(reset(SettingsPasswordFormName))
    } catch ({ response: { status, data } }) {
        const handleError = yield getContext('handleError')
        yield call(handleError(status))
        yield put(USER_PASSWORD.actions.FAILED(data))
        yield put(stopSubmit(SettingsPasswordFormName))
    }
}

function* updateEmail({ data: { newEmail } }: any) {
    try {
        yield put(startSubmit(SettingsEmailFormName))
        const gateway: Gateway = yield getContext('gateway')
        yield call(gateway.getUserAPI().updateEmail(newEmail))

        yield put(SHOW_FEEDBACK('feedback-email-update'))
        yield put(USER_EMAIL_UPDATE.actions.SUCCESSFULL())
        yield put(stopSubmit(SettingsEmailFormName))
        yield put(reset(SettingsEmailFormName))
    } catch ({ response: { status, data } }) {
        const handleError = yield getContext('handleError')
        yield call(handleError(status))
        yield put(USER_EMAIL_UPDATE.actions.FAILED(data))
        yield put(stopSubmit(SettingsEmailFormName))
    }
}

function* updateForgottenPassword({ data }: { data: UpdateForgottenPasswordPayload }) {
    try {
        yield put(startSubmit(UpdatePasswordFormName))
        const gateway: Gateway = yield getContext('gateway')
        yield call(gateway.getUserAPI().updateForgottenPassword(data))

        yield put(UPDATE_FORGOT_PASSWORD.actions.SUCCESSFULL())
        yield put(SHOW_FEEDBACK('feedback-password-update'))
        yield put(stopSubmit(UpdatePasswordFormName))
        yield put(reset(UpdatePasswordFormName))
        yield put(PUSH('/login'))
    } catch ({ response: { status, data } }) {
        const handleError = yield getContext('handleError')
        yield call(handleError(status))
        yield put(USER_PASSWORD.actions.FAILED(data))
        yield put(SHOW_FEEDBACK('feedback-support'))
        yield put(stopSubmit(UpdatePasswordFormName))
    }
}

function* validateEmail({ data: { token } }: { data: UserEmailValidatePayload }) {
    try {
        const gateway: Gateway = yield getContext('gateway')
        yield call(gateway.getUserAPI().validateEmail(token))
        yield put(USER_EMAIL_VALIDATE.actions.SUCCESSFULL())
    } catch ({ response: { status, data } }) {
        yield put(USER_EMAIL_VALIDATE.actions.FAILED(data))
    }
}

function* logout() {
    yield put(api.util.resetApiState())
    yield put(PUSH('/login'))
}

function* savePreviousUrl(url: string) {
    yield put(SAVE_PREVIOUSURL.actions.SUCCESSFULL(url))
}

function* userSaga() {
    // Typings are not good enough. String should be valid but the types
    // do not show it
    yield takeLatest(GET_LOGGED_USER.types.TRIGGER as any, getLoggedUser)
    yield takeLatest(GET_USER.types.TRIGGER as any, get)
    yield takeLatest(LOGIN_ACTION_TYPE as any, login)
    yield takeLatest(LOGOUT_ACTION_TYPE as any, logout)
    yield takeLatest(FORGOT_PASSWORD.types.TRIGGER as any, forgotPassword)
    yield takeLatest(USER_UPDATE.types.TRIGGER as any, update)
    yield takeLatest(USER_PASSWORD.types.TRIGGER as any, updatePassword)
    yield takeLatest(USER_EMAIL_UPDATE.types.TRIGGER as any, updateEmail)
    yield takeLatest(USER_EMAIL_VALIDATE.types.TRIGGER as any, validateEmail)
    yield takeLatest(UPDATE_FORGOT_PASSWORD.types.TRIGGER as any, updateForgottenPassword)
    yield takeLatest(SAVE_USER.types.TRIGGER as any, save)
    yield takeLatest(LIST_USERS.types.TRIGGER as any, list)
    yield takeLatest(ACTIVATE_INVITED_USER.types.TRIGGER as any, activate)
    yield takeLatest(DELETE_USER.types.TRIGGER as any, deleteUser)
    yield takeLatest(RESEND_INVITATION_USER.types.TRIGGER as any, resendInvitation)
    yield takeLatest(SAVE_PREVIOUSURL.types.TRIGGER as any, savePreviousUrl)
}

export default userSaga
