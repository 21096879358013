import React from 'react'
import { Menu, MenuItem, withStyles, WithStyles, Typography, Avatar } from '@material-ui/core'
import { LOGOUT, colors, Button, User, Notifications } from '@syncfab/machine'
import { WithNamespaces, withNamespaces } from 'react-i18next'
import { Dispatch } from 'redux'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { State } from '../application/Store'

const styles = theme => ({
    link: {
        textDecoration: 'none',
        color: theme.palette.common.black,
    },
    button: {
        borderRadius: 10,
    },
    loggedUserName: {
        color: theme.palette.common.black,
        'text-overflow': 'ellipsis',
        'white-space': 'nowrap',
        overflow: 'hidden',
        marginRight: theme.spacing(1),
        'text-transform': 'none',
        maxWidth: 150,
    },
    avatar: {
        'text-transform': 'uppercase',
        color: theme.palette.common.white,
        backgroundColor: colors.tertiary.main,
        width: theme.spacing(3),
        height: theme.spacing(3),
        fontSize: 14,
    },
})
interface UserMenuProps extends WithNamespaces, WithStyles {
    logout: () => void
    loggedUser: User
}

class UserMenu extends React.Component<UserMenuProps> {
    state = {
        anchorEl: null,
    }

    handleMenu = event => {
        this.setState({ anchorEl: event.currentTarget })
    }

    handleClose = () => {
        this.setState({ anchorEl: null })
    }

    render() {
        const { logout, t, classes, loggedUser } = this.props
        const { anchorEl } = this.state
        return (
            <React.Fragment>
                <Notifications />
                {!!loggedUser && (
                    <Button aria-owns={open ? 'menu-appbar' : undefined} aria-haspopup="true" onClick={this.handleMenu} className={classes.button}>
                        <Typography className={classes.loggedUserName}>
                            {loggedUser.firstName} {loggedUser.lastName}
                        </Typography>
                        <Avatar className={classes.avatar}>
                            {loggedUser.firstName[0]}
                            {loggedUser.lastName[0]}
                        </Avatar>
                    </Button>
                )}
                <Menu
                    id="menu-appbar"
                    anchorEl={anchorEl}
                    anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                    }}
                    transformOrigin={{
                        vertical: 'bottom',
                        horizontal: 'left',
                    }}
                    open={!!anchorEl}
                    onClose={this.handleClose}
                >
                    {loggedUser && !loggedUser.supplier && (
                        <MenuItem onClick={this.handleClose}>
                            <Link to="/settings" className={classes.link}>
                                {`${t('menu-settings')}`}
                            </Link>
                        </MenuItem>
                    )}
                    <MenuItem onClick={this.handleClose}>
                        <a href="https://syncfab.com/faq" className={classes.link} target="_blank">
                            {`${t('menu-faq')}`}
                        </a>
                    </MenuItem>
                    {/* <MenuItem onClick={this.handleClose}>
                        <a href="mailto:support@syncfab.com" className={classes.link}>
                            {t('menu-support')}
                        </a>
                    </MenuItem> */}
                    <MenuItem onClick={logout}>{t('menu-logout')}</MenuItem>
                </Menu>
            </React.Fragment>
        )
    }
}

const mapStateToProps = ({ user: { loggedUser } }: State) => {
    return {
        loggedUser,
    }
}

const mapDispatchToProps = (dispatch: Dispatch) => {
    return {
        logout: () => dispatch(LOGOUT()),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withNamespaces()(withStyles(styles)(UserMenu)))
