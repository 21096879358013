import React from 'react'
import CircularProgress, { CircularProgressProps } from '@material-ui/core/CircularProgress'
import Typography from '@material-ui/core/Typography'
import Box from '@material-ui/core/Box'
import { withStyles } from '@material-ui/core'

const styles = _ => ({
    container: {
        width: '100%',
        minHeight: 250,
        position: 'relative' as any,
    },
    progress: {
        width: '10vh',
        height: '10vh',
        position: 'absolute' as any,
        top: '50%',
        left: '50%',
        margin: -25,
    },
})
function CircularProgressWithLabel(props: CircularProgressProps & { value: number }) {
    return (
        <Box position="relative" display="inline-flex">
            <CircularProgress variant="determinate" {...props} />
            <Box top={0} left={0} bottom={0} right={0} position="absolute" display="flex" alignItems="center" justifyContent="center">
                <Typography variant="caption" component="div" color="textSecondary">{`${Math.round(props.value)}%`}</Typography>
            </Box>
        </Box>
    )
}

const ProgressBarInner = props => {
    const [progress, setProgress] = React.useState(10)
    const { classes, className } = props
    React.useEffect(() => {
        const timer = setInterval(() => {
            setProgress(prevProgress => (prevProgress >= 100 ? 0 : prevProgress + 10))
        }, 800)
        return () => {
            clearInterval(timer)
        }
    }, [])

    return (
        <Box className={`${classes.container} ${className}`}>
            <Box className={classes.progress}>
                <CircularProgressWithLabel value={progress} />
            </Box>
        </Box>
    )
}
export const ProgressBar = withStyles(styles)(ProgressBarInner)
