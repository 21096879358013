import React from 'react'
import { Typography, Grid } from '@material-ui/core'
import { withNamespaces, WithNamespaces } from 'react-i18next'
import { TransactionItem } from './TransactionItem'
import { TransactionDownIcon } from '@syncfab/machine'
import { BoldTypography } from '../../../../utils/Typography/BoldTypography'
import { RightTypography } from '../../../../utils/Typography/RightTypography'

interface SpentTransactionListProps extends TransactionItem, WithNamespaces {}

export const SpentTransactionListItem = withNamespaces()(({ t, transaction }: SpentTransactionListProps) => {
    return (
        <Grid item container spacing={8} xs={12} direction="row" justify="space-between" alignContent="center">
            <Grid container item xs={2} direction="row" alignContent="center">
                <TransactionDownIcon />
                <RightTypography gutterBottom>{`${t('wallet-transactions-spent')}`}</RightTypography>
            </Grid>
            <Grid item xs={6}>
                <Typography gutterBottom>
                    {t('wallet-transactions-spent-description')} {!!transaction.context && transaction.context.referenceId}
                </Typography>
            </Grid>
            <Grid item xs={2}>
                <Typography gutterBottom>{new Date(transaction.createdAt).toLocaleDateString()}</Typography>
            </Grid>
            <Grid item xs={2}>
                <BoldTypography gutterBottom>-{transaction.value}</BoldTypography>
            </Grid>
        </Grid>
    )
})
