import React from 'react'
import SvgIcon from '@material-ui/core/SvgIcon'

export const ComingSoonIcon = props => {
    return (
        <SvgIcon {...props} viewBox="0 0 200 200" x="0px" y="0px" baseProfile="tiny" xmlSpace="preserve" style={{ enableBackground: 'new 0 0 200 200', width: 200, height: 200, marginTop: 32 }}>
            <path
                fill="#233A4F"
                d="M195.5,162.1c1-0.2,1.9-0.4,3-0.6c1-0.2,1.6-1,1.6-2c0-2.5,0-5-0.1-7.5c0-1-0.7-1.8-1.7-2
				c-1.1-0.2-2.2-0.4-3.2-0.5c-0.8-0.1-1.4-0.7-1.6-1.4c-0.4-1.3-0.8-2.7-1.1-4c-0.2-0.8,0-1.6,0.6-2.1c0.7-0.6,1.4-1.2,2.2-1.8
				c0.7-0.6,0.9-1.7,0.5-2.5c-1.3-2.3-2.5-4.5-3.6-6.6c-0.5-0.9-1.5-1.3-2.5-0.9c-1,0.4-2,0.8-2.9,1.1c-0.7,0.3-1.6,0.1-2.1-0.5
				c-1-1-2-2-3-3c-0.5-0.5-0.7-1.4-0.5-2.1c0.4-1,0.7-2,1.1-3.1c0.3-0.9-0.1-1.9-0.9-2.4c-2.3-1.2-4.4-2.4-6.5-3.6
				c-0.8-0.5-1.9-0.3-2.5,0.5c-0.7,0.9-1.4,1.6-2,2.4c-0.5,0.6-1.3,0.8-2,0.6l-4.1-1.1c-0.8-0.2-1.3-0.8-1.5-1.6c-0.2-1-0.4-2-0.6-3.1
				c-0.2-1-1-1.7-2-1.6c-2.6,0-5,0.1-7.5,0.1c-1,0-1.8,0.7-1.9,1.7c-0.2,1.1-0.3,2.2-0.5,3.1c-0.1,0.8-0.7,1.4-1.4,1.6
				c-1.4,0.4-2.7,0.8-4,1.1c-0.8,0.2-1.6,0-2.1-0.6c-0.8-0.9-1.5-1.8-2.3-2.8c-0.5-0.5-1.3-0.7-1.9-0.3c-2.3,1.3-4.6,2.6-6.8,3.9
				c-0.9,0.5-1.2,1.5-0.9,2.4c0.4,1.1,0.8,2.2,1.2,3.2c0.3,0.7,0.1,1.6-0.5,2.1c-1,1-1.9,1.9-2.9,2.9c-0.5,0.5-1.3,0.7-2,0.5
				c-1-0.3-2.1-0.7-3.2-1.1c-0.9-0.3-1.9,0.1-2.4,0.9c-1.2,2.2-2.4,4.4-3.6,6.5c-0.5,0.8-0.3,1.9,0.5,2.5c0.9,0.7,1.6,1.4,2.4,2
				c0.6,0.5,0.8,1.3,0.6,2l-1.1,4.1c-0.2,0.7-0.8,1.3-1.6,1.5c-1,0.2-2,0.4-3,0.6c-0.9,0.2-1.6,1-1.6,2c0,2.5,0,5,0,7.4
				c0,1,0.7,1.8,1.7,2c1.2,0.2,2.2,0.4,3.2,0.6c0.8,0.1,1.4,0.7,1.6,1.4c0.4,1.4,0.8,2.7,1.1,4c0.2,0.8,0,1.6-0.6,2.1
				c-0.8,0.7-1.6,1.3-2.3,2c-0.7,0.6-0.9,1.7-0.5,2.5c1.2,2.2,2.4,4.4,3.6,6.6c0.5,0.9,1.5,1.3,2.4,0.9c1.2-0.4,2.2-0.8,3.2-1.2
				c0.7-0.3,1.5-0.1,2.1,0.5c1,1,2,2,3,2.9c0.6,0.6,0.7,1.4,0.5,2.1c-0.4,1-0.7,1.9-1.1,2.9c-0.3,0.9,0,2,0.9,2.5
				c2.2,1.2,4.4,2.5,6.6,3.7c0.8,0.5,1.9,0.3,2.5-0.5c0.7-0.9,1.4-1.7,2-2.5c0.5-0.6,1.3-0.9,2.1-0.7l4,1c0.8,0.2,1.3,0.8,1.5,1.6
				c0.2,1,0.4,2.1,0.6,3.2c0.2,1,1,1.7,2,1.7c2.6,0,5-0.1,7.5-0.1c1,0,1.8-0.7,2-1.7c0.2-1.1,0.3-2.1,0.5-3.1c0.1-0.8,0.7-1.4,1.5-1.6
				c1.4-0.4,2.7-0.7,4-1.1c0.7-0.2,1.5,0,2,0.6c0.7,0.8,1.4,1.6,2.2,2.5c0.6,0.7,1.7,0.9,2.5,0.4c2.2-1.2,4.3-2.4,6.4-3.6
				c0.9-0.5,1.2-1.5,0.9-2.5c-0.5-1.2-0.9-2.3-1.2-3.2c-0.3-0.7-0.1-1.6,0.5-2.1c1-1,2-2,3-3c0.5-0.5,1.3-0.7,2-0.5
				c1,0.4,2,0.7,3.1,1.1c0.9,0.3,2-0.1,2.4-0.9c1.2-2.2,2.4-4.4,3.6-6.5c0.5-0.8,0.3-1.9-0.5-2.5c-0.9-0.7-1.6-1.3-2.3-1.9
				c-0.6-0.5-0.9-1.3-0.7-2l1.1-4.2C194.1,162.9,194.7,162.3,195.5,162.1z M177.1,144c-0.1,0.5-0.6,0.7-1.1,0.6l-3.6-0.9
				c-0.5-0.1-0.7-0.6-0.6-1.1c0.1-0.5,0.6-0.7,1.1-0.6l3.6,0.9C177,143.1,177.2,143.5,177.1,144 M170.7,141.5c0.5,0.1,0.7,0.6,0.6,1.1
				c-0.1,0.5-0.6,0.7-1.1,0.6l-5.7-1.5c-0.5-0.1-0.7-0.6-0.6-1.1c0.1-0.5,0.6-0.7,1.1-0.6L170.7,141.5z M156.1,144.6l11.6,3l-1.1,4.1
				l-6.8-1.8l-1,4.1l6.3,1.6l-1.1,4.1l-6.3-1.6l-2.3,8.7l-4.8-1.2L156.1,144.6z M152.9,134.6c-0.2,0.8-1,1.3-1.8,1.1s-1.3-1-1.1-1.8
				c0.2-0.8,1-1.3,1.8-1.1C152.6,133,153.1,133.8,152.9,134.6 M134.8,182.7c-0.2,0.3-0.6,0.4-0.9,0.3c-0.1,0-0.2-0.1-0.4-0.2
				c-5.9-5.1-9.9-12-11.5-19.5c0,0-0.1-0.7,0.7-0.9c0.8-0.2,1,0.7,1,0.7c1.5,7,5.3,13.6,10.9,18.4C135.1,181.8,135.1,182.4,134.8,182.7
				M141.5,170.1l-5.7-1.5c-0.5-0.1-0.7-0.6-0.6-1.1c0.1-0.5,0.6-0.7,1.1-0.6l5.7,1.5c0.5,0.1,0.7,0.6,0.6,1.1
				C142.4,170,141.9,170.3,141.5,170.1 M143.8,150.5l-8.5,11.2l-1.7-0.4l-2-13.9l-4.9,12.1l-4.8-1.2l8.8-20.1l4,1l2,13.9l8.5-11.2l4,1
				l-2,21.8l-4.8-1.2L143.8,150.5z M143.5,169.8c0.1-0.5,0.6-0.7,1.1-0.6l1.6,0.4c0.5,0.1,0.7,0.6,0.6,1.1c-0.1,0.5-0.6,0.7-1.1,0.6
				l-1.6-0.4C143.7,170.7,143.4,170.2,143.5,169.8 M159.3,191.2c-3.9,0.3-7.7,0-11.5-0.9l-0.1,0c-2.7-0.7-5.2-1.7-7.6-3
				c-0.4-0.2-0.6-0.7-0.4-1.2c0.2-0.4,0.7-0.6,1.2-0.4c2.3,1.2,4.8,2.2,7.4,2.8c3.6,0.9,7.2,1.2,10.9,0.9c0.5,0,0.9,0.3,0.9,0.8
				C160.2,190.7,159.8,191.1,159.3,191.2 M168.6,167.6c-0.9-1.6-2.1-4.6-1-8.8c0.9-3.4,2.8-5.8,5.1-7.2c3.3-2,6.5-1.6,9-0.9
				c1.7,0.4,3.9,1.3,5.5,3.1c1.2,1.3,2,3.1,2.4,4.6l-5,1.2c-0.2-0.9-0.6-1.9-1.3-2.7c-0.8-0.9-1.8-1.5-2.9-1.8
				c-1.9-0.5-3.5-0.2-4.8,0.6c-1.5,0.9-2.6,2.6-3.1,4.5c-0.6,2.4-0.1,4.2,0.5,5.3c0.9,1.6,2.2,2.3,3.8,2.7c2.1,0.5,3.4,0.1,3.9-0.2
				c0.2-0.1,1.3-0.7,2.1-2.1l-5.1-1.3l1-3.9l10.4,2.7l-0.1,0.2c-0.7,2.5-2.6,6.2-5.5,8c-3,1.8-5.7,1.6-8.2,0.9
				C173,171.9,170.4,170.7,168.6,167.6 M167,189.7c-0.2,0.1-0.3,0.1-0.5,0c-0.3-0.1-0.5-0.3-0.6-0.6c-0.1-0.5,0.1-0.9,0.6-1.1
				c5.8-1.8,10.8-5.1,14.8-9.4c0,0,0.7-0.8,1.4-0.2c0.6,0.5,0.2,1,0.2,1C178.7,184.1,173.3,187.7,167,189.7 M178.4,129.6
				c0.2-0.3,0.6-0.4,0.9-0.3c0.1,0,0.2,0.1,0.3,0.2c5.9,5.1,9.9,12,11.5,19.5c0,0,0.1,0.7-0.7,0.9c-0.8,0.2-1-0.7-1-0.7
				c-1.5-7-5.3-13.6-10.9-18.4C178.1,130.5,178.1,129.9,178.4,129.6 M153.9,121.1c3.9-0.3,7.7,0,11.5,0.9l0.1,0c2.6,0.7,5.2,1.7,7.6,3
				c0.4,0.2,0.6,0.7,0.4,1.2c-0.2,0.4-0.7,0.6-1.2,0.4c-2.3-1.2-4.8-2.2-7.4-2.8c-3.6-0.9-7.2-1.2-10.9-0.9c-0.5,0-0.9-0.3-0.9-0.8
				C153.1,121.6,153.4,121.2,153.9,121.1 M146.3,122.6c0.2-0.1,0.3-0.1,0.5,0c0.3,0.1,0.5,0.3,0.6,0.6c0.1,0.5-0.1,0.9-0.6,1.1
				c-5.8,1.8-10.8,5.1-14.8,9.4c0,0-0.7,0.8-1.4,0.2c-0.6-0.5-0.2-1-0.2-1C134.6,128.2,140,124.6,146.3,122.6 M164.7,179
				c-0.2,0.8-1,1.3-1.8,1.1c-0.8-0.2-1.3-1-1.1-1.8c0.2-0.8,1-1.3,1.8-1.1C164.4,177.4,164.9,178.2,164.7,179"
            />
            <path
                fill="#233A4F"
                d="M131.9,63.6c2.2-1,4.3-2,6.7-3.1c0.7-0.3,1-1.1,0.8-1.8c-1.3-4.9-2.5-9.6-3.8-14.4c-0.2-0.7-0.9-1.2-1.6-1.1
				c-2.8,0.2-5.2,0.4-7.7,0.6c-0.6,0-1.2-0.2-1.5-0.8c-1.5-2.6-3-5.1-4.5-7.6c-0.3-0.5-0.3-1.2,0.1-1.7c1.4-1.9,2.7-3.8,4.1-5.7
				c0.4-0.6,0.4-1.4-0.1-2c-3.6-3.8-7-7.4-10.3-11c-0.5-0.6-1.4-0.6-2-0.2c-2.3,1.6-4.3,3-6.1,4.3c-0.5,0.4-1.2,0.4-1.7,0.1
				c-2.7-1.6-5.1-3.1-7.7-4.5c-0.5-0.3-0.8-0.9-0.8-1.5c0.2-2.5,0.4-5,0.7-7.7c0.1-0.8-0.4-1.5-1.2-1.6c-5-1.3-9.7-2.5-14.3-3.7
				c-0.7-0.2-1.5,0.2-1.8,0.8c-1.2,2.5-2.2,4.7-3.2,6.7c-0.3,0.5-0.8,0.9-1.4,0.9c-2.1,0-6.9,0-9,0c-0.6,0-1.1-0.3-1.4-0.9
				c-1-2.2-2-4.3-3.2-6.8c-0.3-0.7-1.1-1-1.8-0.8c-4.9,1.4-9.7,2.7-14.4,4C44,4.3,43.5,5,43.6,5.8c0.3,2.8,0.5,5.2,0.7,7.6
				c0.1,0.6-0.2,1.2-0.8,1.5c-2.7,1.5-5.2,3-7.7,4.5c-0.5,0.3-1.2,0.3-1.7-0.1c-2-1.4-4.1-2.9-6.2-4.4c-0.6-0.4-1.4-0.4-2,0.2
				c-3.7,3.5-7.1,6.9-10.6,10.3c-0.5,0.5-0.6,1.4-0.2,2c1.7,2.5,3.1,4.6,4.5,6.5c0.3,0.5,0.4,1.1,0.1,1.7c-1.5,2.6-2.9,5-4.4,7.6
				c-0.3,0.5-0.9,0.8-1.4,0.8c-2.5-0.2-5-0.4-7.8-0.6c-0.7-0.1-1.4,0.4-1.6,1.2C3.2,49.3,2,54,0.8,58.7c-0.2,0.7,0.2,1.5,0.8,1.8
				c2.6,1.2,4.8,2.3,6.7,3.2c0.5,0.3,0.9,0.8,0.9,1.4c0,2.1,0,6.9,0,9c0,0.6-0.3,1.1-0.9,1.4c-2.2,1-4.3,2-6.7,3.2
				c-0.7,0.3-1,1.1-0.8,1.8C2,85.2,3.2,90,4.5,94.8c0.2,0.7,0.9,1.2,1.6,1.2c2.9-0.2,5.3-0.4,7.7-0.6c0.6,0,1.2,0.3,1.5,0.8
				c1.5,2.6,3,5.1,4.5,7.7c0.3,0.5,0.3,1.2-0.1,1.7c-1.5,2-2.8,4-4.3,6c-0.4,0.6-0.4,1.4,0.1,2c3.5,3.7,6.8,7.3,10.1,10.9
				c0.5,0.6,1.4,0.7,2,0.2c2.6-1.8,4.6-3.2,6.6-4.5c0.5-0.3,1.1-0.4,1.7-0.1c2.7,1.5,5.2,3,7.7,4.5c0.5,0.3,0.8,0.9,0.8,1.5
				c-0.2,2.4-0.5,4.8-0.8,7.5c-0.1,0.8,0.4,1.5,1.1,1.7c4.9,1.3,9.6,2.5,14.5,3.8c0.7,0.2,1.5-0.2,1.8-0.9c1.2-2.6,2.2-4.8,3.2-7
				c0.3-0.5,0.8-0.9,1.4-0.9l8.9,0c0.6,0,1.1,0.3,1.4,0.9c1,2.2,2.1,4.5,3.2,7c0.3,0.7,1.1,1,1.8,0.8c5-1.4,9.7-2.7,14.4-4
				c0.7-0.2,1.2-0.9,1.1-1.6c-0.3-2.8-0.5-5.2-0.8-7.6c-0.1-0.6,0.2-1.2,0.8-1.5c2.7-1.5,5.2-2.9,7.7-4.4c0.5-0.3,1.1-0.3,1.6,0.1
				c2.1,1.4,4.1,2.8,6.5,4.4c0.6,0.4,1.4,0.3,1.9-0.2c3.5-3.4,6.9-6.6,10.3-9.9c0.7-0.7,0.8-1.8,0.2-2.6c-1.8-2.5-3.1-4.4-4.5-6.3
				c-0.4-0.5-0.4-1.2-0.1-1.7c1.6-2.7,3.1-5.2,4.6-7.7c0.3-0.5,0.9-0.8,1.4-0.7c2.6,0.2,5,0.4,7.7,0.6c0.8,0.1,1.4-0.4,1.6-1.2
				c1.2-5,2.4-9.7,3.6-14.4c0.2-0.7-0.2-1.5-0.8-1.8c-2.6-1.2-4.7-2.2-6.6-3.1c-0.5-0.3-0.9-0.8-0.9-1.4c0-2.1,0-7,0-9.1
				C131,64.4,131.3,63.9,131.9,63.6z M48.2,116c-0.2,0.5-0.7,0.7-1.2,0.7c-0.2,0-0.4,0-0.6-0.1c-10.4-5.2-18.8-13.7-23.9-23.9
				c0,0-0.4-1,0.6-1.6c1-0.5,1.8,0.6,1.8,0.6c4.8,9.6,12.7,17.6,22.6,22.5C48.3,114.6,48.5,115.3,48.2,116 M86.8,119.1
				c-5.5,1.9-11.2,2.9-17,2.9h-0.2c-4.1,0-8.2-0.5-12.1-1.4c-0.7-0.2-1.1-0.9-1-1.6c0.2-0.7,1-1.1,1.7-0.9c3.8,0.9,7.7,1.3,11.6,1.3
				c5.5,0,10.9-0.9,16.1-2.8c0.7-0.2,1.4,0.1,1.6,0.8C87.8,118.1,87.5,118.8,86.8,119.1 M97.3,114c-0.2,0.1-0.4,0.2-0.7,0.2
				c-0.4,0-0.9-0.2-1.1-0.6c-0.4-0.6-0.2-1.4,0.4-1.8c7.6-4.8,13.8-11.5,17.8-19.1c0,0,0.8-1.4,2-0.9c1.1,0.5,0.7,1.4,0.7,1.4
				C112.1,101.7,105.6,108.8,97.3,114 M91.5,22.9c0.2-0.5,0.7-0.7,1.2-0.7c0.2,0,0.4,0,0.6,0.1C103.6,27.5,112,36,117,46.2
				c0,0,0.3,1-0.6,1.6c-1,0.5-1.8-0.6-1.8-0.6c-4.8-9.6-12.7-17.6-22.6-22.5C91.4,24.3,91.1,23.5,91.5,22.9 M52.9,19.8
				c5.5-1.9,11.2-2.9,17-2.9H70c4.1,0,8.2,0.5,12.1,1.4c0.7,0.2,1.1,0.9,1,1.6c-0.2,0.7-0.9,1.1-1.6,1c-3.8-0.9-7.8-1.4-11.7-1.4
				c-5.5,0-10.9,1-16.1,2.8c-0.7,0.2-1.4-0.1-1.7-0.8C51.8,20.8,52.2,20,52.9,19.8 M42.4,24.8c0.2-0.1,0.4-0.2,0.7-0.2
				c0.4,0,0.9,0.2,1.1,0.6c0.4,0.6,0.2,1.4-0.4,1.8C36.1,31.8,30,38.5,25.9,46.2c0,0-0.8,1.4-2,0.9c-1.1-0.5-0.7-1.4-0.7-1.4
				C27.6,37.2,34,30,42.4,24.8 M94.6,68.5c0,14.1-11.5,25.6-25.6,25.6c-20,0-34.6-23-19-44c0.1-0.1,0.2-0.2,0.3-0.3
				C71.4,33.8,94.6,48.5,94.6,68.5"
            />
        </SvgIcon>
    )
}
