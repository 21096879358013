import { Transaction, LOGOUT_ACTION_TYPE } from '@syncfab/machine'
import {
    GET_TRANSACTIONS,
    GET_BALANCE,
    FETCH_TRANSACTIONS,
    SEND_MFG_TOKENS,
    GET_TRANSACTION_FEE,
    RESET_DATA,
    CREATE_WALLET,
    GET_WALLET_STATUS,
    GET_COST,
    GET_WALLET_ADDRESS,
    MAKE_PAYMENT,
    ACH_PAYMENT,
    GET_NFT_BALANCE,
    CONTACT_SALES,
} from './Actions'

export type TransactionReducerType = {
    walletCreationStatus?: string
    transactions?: Transaction[]
    transactionTotal?: number
    balance?: number
    nftBalance?: number
    isLoading?: boolean
    sendingToken?: boolean
    sendTokenData: any
    transactionFee?: any
    ErrorDialog?: boolean
    sendTokenSuccess?: boolean
    createWalletSuccess?: boolean
    failToCreateWallet?: boolean
    SelfSendError?: boolean
    status?: any
    getBalanceError?: boolean
    walletAddress?: string
    transactionFeeForPartTokenization?: any
    purchaseCompleteDialog: boolean
    failedPurchaseDialog: boolean
    successMessage: any
}

const initialState: TransactionReducerType = {
    transactions: null,
    transactionTotal: 0,
    balance: 0,
    nftBalance: 0,
    isLoading: false,
    sendTokenData: null,
    transactionFee: null,
    ErrorDialog: false,
    sendingToken: false,
    sendTokenSuccess: false,
    createWalletSuccess: false,
    failToCreateWallet: false,
    SelfSendError: false,
    walletCreationStatus: null,
    status: null,
    transactionFeeForPartTokenization: null,
    getBalanceError: false,
    walletAddress: null,
    purchaseCompleteDialog: false,
    failedPurchaseDialog: false,
    successMessage: null,
}

export const TransactionReducer = (state = initialState, action: any) => {
    switch (action.type) {
        case GET_TRANSACTIONS.types.TRIGGER:
            return {
                ...state,
                transactions: null,
                isLoading: true,
                ErrorDialog: false,
                sendTokenSuccess: false,
            }
        case GET_TRANSACTIONS.types.SUCCESSFULL:
            return {
                ...state,
                transactions: action.data.data,
                transactionTotal: action.data.total,
                page: action.data.page,
                size: action.data.size,
                isLoading: false,
            }
        case FETCH_TRANSACTIONS.types.TRIGGER:
            return {
                ...state,
                isLoading: true,
            }
        case FETCH_TRANSACTIONS.types.SUCCESSFULL:
            return {
                ...state,
                isLoading: false,
                transactions: action.data.data,
                page: action.data.page,
                size: action.data.size,
                // transactions: [...state.transactions, ...action.data.data],
                transactionTotal: action.data.total,
            }
        case GET_BALANCE.types.TRIGGER:
            return {
                ...state,
                isLoading: false,
            }
        case GET_BALANCE.types.SUCCESSFULL:
            return {
                ...state,
                balance: action.data,
            }

        case GET_NFT_BALANCE.types.TRIGGER:
            return {
                ...state,
                isLoading: false,
            }
        case GET_NFT_BALANCE.types.SUCCESSFULL:
            return {
                ...state,
                nftBalance: action.data,
            }

        case GET_WALLET_ADDRESS.types.TRIGGER:
            return {
                ...state,
                isLoading: false,
            }
        case GET_WALLET_ADDRESS.types.SUCCESSFULL:
            return {
                ...state,
                walletAddress: action.data.walletAddress,
            }

        case GET_WALLET_ADDRESS.types.FAILED:
            return {
                ...state,
                isLoading: false,
                walletAddress: null,
            }
        case SEND_MFG_TOKENS.types.SUCCESSFULL:
            return {
                ...state,
                sendTokenData: action.data,
                sendTokenSuccess: true,
                ErrorDialog: false,
                isLoading: false,
                sendingToken: false,
            }
        case SEND_MFG_TOKENS.types.TRIGGER:
            return {
                ...state,
                sendingToken: true,
            }
        case SEND_MFG_TOKENS.types.FAILED:
            return {
                ...state,
                ErrorDialog: true,
                sendingToken: false,
            }
        case CREATE_WALLET.types.SUCCESSFULL:
            return {
                ...state,
                isLoading: false,
                walletCreationStatus: action.data.walletCreationStatus,
                createWalletSuccess: true,
            }
        case CREATE_WALLET.types.TRIGGER:
            return {
                ...state,
                isLoading: true,
            }
        case CREATE_WALLET.types.FAILED:
            return {
                ...state,
                createWalletSuccess: false,
                failToCreateWallet: true,
                isLoading: false,
            }

        case GET_WALLET_STATUS.types.SUCCESSFULL:
            return {
                ...state,
                status: action.data,
                walletCreationStatus: action.data.walletCreationStatus,
                isLoading: false,
                createWalletSuccess: true,
            }
        case GET_WALLET_STATUS.types.TRIGGER:
            return {
                ...state,
                isLoading: true,
            }
        case GET_WALLET_STATUS.types.FAILED:
            return {
                ...state,
                createWalletSuccess: false,
                failToCreateWallet: true,
                isLoading: false,
            }
        case GET_TRANSACTION_FEE.types.TRIGGER:
            return {
                ...state,
                sendingToken: false,
            }
        case RESET_DATA.types.TRIGGER:
            return {
                ...state,
                ErrorDialog: false,
                sendTokenSuccess: false,
                createWalletSuccess: false,
                failToCreateWallet: false,
                SelfSendError: false,
                failedPurchaseDialog: false,
                purchaseCompleteDialog: false,
                successMessage: null,
            }

        case GET_TRANSACTION_FEE.types.SUCCESSFULL:
            return {
                ...state,
                transactionFee: action.data,
            }
        case LOGOUT_ACTION_TYPE:
            return {
                ...state,
                ...initialState,
            }

        case GET_COST.types.TRIGGER:
            return {
                ...state,
            }

        case GET_COST.types.SUCCESSFULL:
            return {
                ...state,
                transactionFeeForPartTokenization: action.data,
            }

        case GET_COST.types.FAILED:
            return {
                ...state,
            }

        case MAKE_PAYMENT.types.SUCCESSFULL:
            return {
                ...state,
                isLoading: false,
                purchaseCompleteDialog: true,
                successMessage: action.data,
            }
        case MAKE_PAYMENT.types.TRIGGER:
            return {
                ...state,
                isLoading: true,
            }
        case MAKE_PAYMENT.types.FAILED:
            return {
                ...state,
                isLoading: false,
                failedPurchaseDialog: true,
            }
        case ACH_PAYMENT.types.SUCCESSFULL:
            return {
                ...state,
                isLoading: false,
                purchaseCompleteDialog: true,
                successMessage: action.data,
            }
        case ACH_PAYMENT.types.TRIGGER:
            return {
                ...state,
                isLoading: true,
            }
        case ACH_PAYMENT.types.FAILED:
            return {
                ...state,
                isLoading: false,
                failedPurchaseDialog: true,
            }
        case CONTACT_SALES.types.TRIGGER:
            return {
                ...state,
                isLoading: true,
            }
        case CONTACT_SALES.types.SUCCESSFULL:
            return {
                ...state,
                isLoading: false,
            }
        default:
            return state
    }
}
