import React, { FC, FocusEventHandler } from 'react';
import {
  Controller,
  UseControllerProps,
  useFormContext,
} from 'react-hook-form';
import TextField from '@material-ui/core/TextField';
import { InputAdornment, Theme, OutlinedInputProps, InputBaseComponentProps } from '@material-ui/core';
import { Lock } from '@material-ui/icons';
import { createStyles, makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((_theme: Theme) =>
  createStyles({
    noBorder: {
      border: 'none',
    },
    noTopBottomPadding: {
      paddingTop: '0px',
      paddingBottom: '0px',
    },
  })
);

export interface RHFTextFieldProps {
  name: string;
  label: string;
  rules?: UseControllerProps['rules'];
  variant?: 'outlined' | 'filled' | 'standard';
  required?: boolean;
  disabled?: boolean;
  isTableCellInput?: boolean;
  multiline?: boolean;
  minRows?: number;
  onBlur?: FocusEventHandler;
  style?: object;
  size?: 'small' | 'medium';
  className?: string;
  inputProps?: InputBaseComponentProps;
}

export const RHFTextField: FC<RHFTextFieldProps> = ({
  className,
  name,
  label,
  rules,
  variant = 'outlined',
  required = false,
  disabled = false,
  isTableCellInput = false,
  multiline = false,
  minRows = 1,
  style,
  size = 'medium',
  inputProps,
}: RHFTextFieldProps) => {
  const methods = useFormContext();
  const classes = useStyles();

  const localInputProps: OutlinedInputProps = {};
  if (disabled) {
    localInputProps.endAdornment = (
      <InputAdornment position="end" style={{ backgroundColor: 'inherit' }}>
        <Lock />
      </InputAdornment>
    );
  }
  if (!!inputProps) {
    localInputProps.inputProps = inputProps;
  }
  if (isTableCellInput) {
    localInputProps.classes = {
      notchedOutline: classes.noBorder,
      input: classes.noTopBottomPadding,
    };
  }

  return (
    <Controller
      name={name}
      control={methods.control}
      rules={rules}
      render={({
        field: { onChange, onBlur, value, ref },
        fieldState: { error },
      }) => {
        return (
          <TextField
            value={value}
            className={className}
            name={name}
            id={name}
            type="text"
            variant={variant}
            label={label}
            fullWidth
            required={required}
            onChange={onChange}
            onBlur={onBlur}
            helperText={error ? error.message : null}
            error={!!error}
            disabled={disabled}
            inputRef={ref}
            InputProps={localInputProps}
            multiline={multiline}
            minRows={minRows}
            style={style}
            size={size}
          />
        );
      }}
    />
  );
};
