import { SIGNUP_FAILED_ACTION_TYPE, SIGNUP_SUCESSFUL_ACTION_TYPE } from '../../view/signup/Actions'
import { COMPANY_UPDATE, GET_LOGGED_COMPANY, ADDRESS_GET, ADDRESS_CREATE, ADDRESS_CREATE_INLINE, UPDATE_COMPANY, DELETE_DOCUMENT } from './Actions'
import { Address, Company, LOGOUT_ACTION_TYPE } from '@syncfab/machine'

export type CompanyReducerType = {
    signupError?: string
    company?: Company
    addresses?: Address[]
    updateCompanyError?: string
    isLoading?: boolean
}

const initialState: CompanyReducerType = {}

export const CompanyReducer = (state = initialState, action: any) => {
    switch (action.type) {
        case SIGNUP_FAILED_ACTION_TYPE:
            return {
                ...state,
                signupError: action.error,
            }
        case SIGNUP_SUCESSFUL_ACTION_TYPE:
            return {
                ...state,
                signupError: null,
            }
        case COMPANY_UPDATE.types.FAILED:
            return {
                ...state,
                updateCompanyError: action.error,
            }
        case COMPANY_UPDATE.types.SUCCESSFULL:
            return {
                ...state,
                updateCompanyError: null,
            }
        case GET_LOGGED_COMPANY.types.SUCCESSFULL:
            return {
                ...state,
                company: action.data,
            }
        case ADDRESS_GET.types.TRIGGER:
            return {
                ...state,
                addresses: null,
            }
        case ADDRESS_GET.types.SUCCESSFULL:
            return {
                ...state,
                addresses: [...action.data],
            }

        case ADDRESS_CREATE_INLINE.types.SUCCESSFULL:
            return {
                ...state,
                addresses: [action.data, ...(state.addresses || [])],
            }
        case ADDRESS_CREATE.types.SUCCESSFULL:
            return {
                ...state,
                addresses: [action.data, ...(state.addresses || [])],
            }
        case LOGOUT_ACTION_TYPE:
            return {
                ...state,
                signupError: null,
                company: null,
                addresses: [],
                updateCompanyError: null,
            }
        case UPDATE_COMPANY.types.SUCCESSFULL:
            return {
                ...state,
                company: action.data,
            }
        case DELETE_DOCUMENT.types.TRIGGER:
            return {
                ...state,
                isLoading: true,
            }
        case DELETE_DOCUMENT.types.SUCCESSFULL:
            return {
                ...state,
                isLoading: false,
            }
        case DELETE_DOCUMENT.types.FAILED:
            return {
                ...state,
                isLoading: false,
            }
        default:
            return state
    }
}
