export const EMAIL_REGEX = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/i
export const PASSWORD_REGEX = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/
export const isCompanyIdValid = companyId => {
    return /C[\d]{4}/.test(companyId);
}
export const isExistSpecialCharacter = str => {
    return /[^a-zA-Z0-9]/g.test(str);
}
export const isNumber = str => {
    return /^\d+$/g.test(str);
}
