import React from 'react'
import { TextField as MaterialUITextField, FormControl, InputAdornment } from '@material-ui/core'
import { Lock } from '@material-ui/icons'
import NumberFormat from 'react-number-format'
import { FormFieldFooter } from '../Error'

export const MUINumberTextField = MaterialUITextField

function NumberFormatCustom(props) {
    // suffix
    const { inputRef, onChange, prefix, suffix, ...other } = props

    return (
        <NumberFormat
            {...other}
            getInputRef={inputRef}
            onValueChange={values => {
                onChange(values.value)
            }}
            decimalScale={2}
            thousandSeparator
            // isNumericString
            prefix={prefix}
            suffix={suffix}
        />
    )
}

export const FormNumberFormatField = ({
    input,
    label,
    type,
    meta: { touched, error },
    fullWidth,
    onChange,
    className,
    disabled,
    InputProps,
    description,
    required,
    prefix = '$',
    suffix = '',
    allowNegative = false,
    ...custom
}: any) => {
    let inputProps = InputProps
    if (disabled && !InputProps) {
        inputProps = {
            endAdornment: (
                <InputAdornment position="end" style={{ backgroundColor: 'inherit' }}>
                    <Lock />
                </InputAdornment>
            ),
        }
    }

    return (
        <FormControl fullWidth={fullWidth} className={className}>
            <MUINumberTextField
                margin="normal"
                variant="outlined"
                disabled={disabled}
                label={label}
                type={type}
                required={required}
                fullWidth
                onChange={input.onChange}
                value={input.value}
                {...input}
                error={!!touched && !!error}
                InputProps={{
                    inputProps: {
                        ...inputProps,
                        prefix,
                        suffix,
                        allowNegative: allowNegative,
                    },
                    inputComponent: NumberFormatCustom as any,
                }}
                {...custom}
            />
            <FormFieldFooter touched={touched} error={error} description={description} />
        </FormControl>
    )
}
