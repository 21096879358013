import { ValueLabelOption } from "../ui";

export const PaymentTermsOptions: ValueLabelOption[] = [
    { value: 'NET30', label: 'Net 30 - Payment 30 days after product acceptance date' },
    { value: 'NET15', label: 'Net 15 - Payment 15 days after product acceptance date' },
    { value: 'NET45', label: 'Net 45 - Payment 45 days after product acceptance date' },
    { value: 'NET60', label: 'Net 60 - Payment 60 days after product acceptance date' },
    { value: 'NET90', label: 'Net 90 - Payment 90 days after product acceptance date' },
    { value: 'NET120', label: 'Net 120 - Payment 120 days after product acceptance date' },
    { value: 'COD', label: 'COD - Cash on delivery' },
    { value: 'CND', label: 'CND - Cash next delivery' },
    { value: 'CIA', label: 'CIA - Cash in advance' },
    { value: 'CWO', label: 'CWO - Cash with order' },
    { value: 'PIA', label: 'PIA - Payment in advance' },
    { value: 'PWP', label: 'PWP - Paid-When-Paid' },
]