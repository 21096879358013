import { User, FetchActions, createFetchActions } from '@syncfab/machine'
import { ListMeta } from '../../layout/list/Meta'

export type UserUpdatePayload = {
    id: string
    delta: Partial<User>
    history?:string
}

export type UserPasswordUpdatePayload = {
    password: string
    newPassword: string
}

export type UserEmailUpdatePayload = {
    newEmail: string
}

export type UserEmailValidatePayload = {
    token: string
}

export type UpdateForgottenPasswordPayload = {
    password: string
    confirmPassword: string
    email: string
    token: string
}

export type UserActivationPayload = {
    token: string
    email: string
    password: string
}

export const USER_UPDATE: FetchActions<UserUpdatePayload, string> = createFetchActions('USER_UPDATE')
export const USER_PASSWORD: FetchActions<UserPasswordUpdatePayload, string> = createFetchActions('USER_PASSWORD')
export const USER_EMAIL_UPDATE: FetchActions<UserEmailUpdatePayload, string> = createFetchActions('USER_EMAIL')
export const USER_EMAIL_VALIDATE: FetchActions<UserEmailValidatePayload, string> = createFetchActions('USER_EMAIL_VALIDATE')
export const GET_LOGGED_USER: FetchActions<{}, User> = createFetchActions('GET_LOGGED_USER')
export const FORGOT_PASSWORD: FetchActions<{ email: string }, { email: string }> = createFetchActions('FORGOT_PASSWORD')
export const UPDATE_FORGOT_PASSWORD: FetchActions<UpdateForgottenPasswordPayload, {}> = createFetchActions('UPDATE_FORGOT_PASSWORD')
export const GET_USER: FetchActions<{ id: string }, User> = createFetchActions('GET_USER')
export const DELETE_USER: FetchActions<{ id: string }, void> = createFetchActions('DELETE_USER')
export const RESEND_INVITATION_USER: FetchActions<{ id: string }, void> = createFetchActions('RESEND_INVITATION_USER')
export const SAVE_USER: FetchActions<{ user: Partial<User>,history?:any }, void> = createFetchActions('SAVE_USER')
export const LIST_USERS: FetchActions<{ meta: ListMeta }, User[]> = createFetchActions('LIST_USERS')
export const USER_EMAIL_VALIDATION: FetchActions<{ email }, string> = createFetchActions('USER_EMAIL_VALIDATION')
export const ACTIVATE_INVITED_USER: FetchActions<UserActivationPayload, void> = createFetchActions('ACTIVATE_INVITED_USER')
export const SAVE_PREVIOUSURL: FetchActions<string, string> = createFetchActions('SAVE_PREVIOUSURL')
export const RESET_USER: FetchActions<{}, {}> = createFetchActions('RESET_USER')
