import React from 'react'
import SvgIcon from '@material-ui/core/SvgIcon'

export const PartIcon = props => {
    return (
        <SvgIcon {...props} viewBox="0 0 24 24" x="0px" y="0px" baseProfile="tiny" xmlSpace="preserve">
            <path
                fill={props.fill || '#FFFFFF'}
                d="M10.9,2.5,4,6.4A2.5,2.5,0,0,0,3,8.2v7.7a2.2,2.2,0,0,0,1.1,1.8L8.2,20l8.9-4.8a3.2,3.2,0,0,0-1.6-5.9,3.2,3.2,0,0,0-1.6.4L9.4,12.1l.7,1.2L7,12.5l.9-3.1.7,1.2,4.5-2.4a3.7,3.7,0,0,1,1.4-.5h1A5.1,5.1,0,0,1,19.9,10a4.8,4.8,0,0,1,.4,3.7,4.6,4.6,0,0,1-2.4,2.9L9.9,21l1.1.6a2.3,2.3,0,0,0,2,0l6.9-3.9A2.3,2.3,0,0,0,21,15.9V8.2a2.2,2.2,0,0,0-1.1-1.8L15.6,4,6.9,8.8a3.2,3.2,0,0,0-1.3,4.3,3.4,3.4,0,0,0,2.9,1.6,2.6,2.6,0,0,0,1.5-.4l4.6-2.5-.7-1.2,3.2.8-1,3.1-.7-1.2-4.6,2.5a4.6,4.6,0,0,1-2.3.6,5.2,5.2,0,0,1-4.4-2.5,4.7,4.7,0,0,1-.4-3.7A5,5,0,0,1,6,7.3L13.9,3A4.6,4.6,0,0,0,12,2.3l-1.1.2"
            />
        </SvgIcon>
    )
}
