import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { SortDirection, SupplierPurchaseOrderQuery } from '@syncfab/machine'
import { ExternalPurchaseOrderQuery } from "@syncfab/machine/src/components/list/ExternalPurchaseOrderQuery";

const initialState: ExternalPurchaseOrderQuery = {
    page: 0,
    size: 10,
    sortField: '_id',
    sortDirection: SortDirection.DESC,
    searchTerm: '',
    poStatus: ''
}

const ExternalPurchaseOrderListParametersSlice = createSlice({
    name: 'externalPurchaseOrderListParameters',
    initialState,
    reducers: {
        updateExternalPurchaseOrderListParameters: (state, action: PayloadAction<SupplierPurchaseOrderQuery>) => {
            return { ...state, ...action.payload }
        },
    },
})

export const { updateExternalPurchaseOrderListParameters } = ExternalPurchaseOrderListParametersSlice.actions

export default ExternalPurchaseOrderListParametersSlice.reducer
