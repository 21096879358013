import axios, { AxiosPromise } from 'axios'
import { AuthorizationService, Environment, EnvironmentVariable } from '@syncfab/machine'

export class DashboardAPI {
    constructor(private environment: Environment, private authorizationService: AuthorizationService) {}

    public getCapabilities = (): (() => AxiosPromise) => {
        const url = this.environment.get(EnvironmentVariable.API_LAYER_URL)
        const authorization = `Bearer ${this.authorizationService.getAuthToken()}`

        return () => {
            return axios.get(`${url}/suppliers/getTopCapabilities`, {
                headers: {
                    authorization,
                },
            })
        }
    }

    public getSupplierAnalytics = (): (() => AxiosPromise) => {
        const url = this.environment.get(EnvironmentVariable.API_LAYER_URL)
        const authorization = `Bearer ${this.authorizationService.getAuthToken()}`

        return () => {
            return axios.get(`${url}/suppliersRatings/list/supplierAnalytics`, {
                headers: {
                    authorization,
                },
            })
        }
    }

    public getLatLongFromAddress = (addressString: string): (() => AxiosPromise) => {
        return () => {
            return axios.get(`https://maps.googleapis.com/maps/api/geocode/json?key=AIzaSyAoVzb0i5s76I6mwleSxavtFUNro4xYLBM&address=${addressString}`)
        }
    }
}
