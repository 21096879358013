import axios, { AxiosPromise } from 'axios'
import { RFQ, AuthorizationService, Environment, EnvironmentVariable, Document } from '@syncfab/machine'

export class RFQAPI {
    constructor(private environment: Environment, private authorizationService: AuthorizationService) {}

    public getAll = (): (() => AxiosPromise) => {
        const url = this.environment.get(EnvironmentVariable.API_LAYER_URL)
        const authorization = `Bearer ${this.authorizationService.getAuthToken()}`
        return () => {
            return axios.get(`${url}/rfqs`, {
                headers: {
                    authorization,
                },
            })
        }
    }

    public get = (id: string): (() => AxiosPromise) => {
        const url = this.environment.get(EnvironmentVariable.API_LAYER_URL)
        const authorization = `Bearer ${this.authorizationService.getAuthToken()}`
        return () => {
            return axios.get(`${url}/rfqs/${id}`, {
                headers: {
                    authorization,
                },
            })
        }
    }

    public getSupplierData = (token: string): (() => AxiosPromise) => {
        const url = this.environment.get(EnvironmentVariable.API_LAYER_URL)
        const authorization = `Bearer ${this.authorizationService.getAuthToken()}`
        return () => {
            return axios.get(`${url}/rfqs/suppliers?token=${token}`, {
                headers: {
                    authorization,
                },
            })
        }
    }

    public download = (token: string): (() => AxiosPromise) => {
        const url = this.environment.get(EnvironmentVariable.API_LAYER_URL)
        const authorization = `Bearer ${this.authorizationService.getAuthToken()}`
        return () => {
            return axios.get(`${url}/rfqs/suppliers/documents?token=${token}`, {
                headers: {
                    authorization,
                },
                responseType: 'blob',
            })
        }
    }

    public createRFQ = (rfq: RFQ): (() => AxiosPromise) => {
        const url = this.environment.get(EnvironmentVariable.API_LAYER_URL)
        const authorization = `Bearer ${this.authorizationService.getAuthToken()}`
        return () => {
            return axios.post(`${url}/rfqs`, rfq, {
                headers: {
                    authorization,
                },
            })
        }
    }

    public deleteRFQ = (id: string): (() => AxiosPromise) => {
        const url = this.environment.get(EnvironmentVariable.API_LAYER_URL)
        const authorization = `Bearer ${this.authorizationService.getAuthToken()}`
        return () => {
            return axios.delete(`${url}/rfqs/${id}`, {
                headers: {
                    authorization,
                },
            })
        }
    }

    public declineRFQ = (id: string, reason?: string): (() => AxiosPromise) => {
        const url = this.environment.get(EnvironmentVariable.API_LAYER_URL)
        const authorization = `Bearer ${this.authorizationService.getAuthToken()}`
        return () => {
            return axios.put(
                `${url}/rfqs/decline/${id}`,
                { reason },
                {
                    headers: {
                        authorization,
                    },
                }
            )
        }
    }

    public editRFQ = (id: string, request?: string): (() => AxiosPromise) => {
        const url = this.environment.get(EnvironmentVariable.API_LAYER_URL)
        const authorization = `Bearer ${this.authorizationService.getAuthToken()}`
        return () => {
            return axios.put(
                `${url}/rfqs/edit/${id}`,
                { request },
                {
                    headers: {
                        authorization,
                    },
                }
            )
        }
    }

    public removeRFQDocument = (rfqId: string, documentId: string): (() => AxiosPromise) => {
        const url = this.environment.get(EnvironmentVariable.API_LAYER_URL)
        const authorization = `Bearer ${this.authorizationService.getAuthToken()}`
        return () => {
            return axios.put(
                `${url}/rfqs/supplier/document/${documentId}`,
                { rfqId },
                {
                    headers: {
                        authorization,
                    },
                }
            )
        }
    }

    public addSupplierQuote = (rfqId: String, document: Document, verification: any): (() => AxiosPromise) => {
        const url = this.environment.get(EnvironmentVariable.API_LAYER_URL)
        const authorization = `Bearer ${this.authorizationService.getAuthToken()}`
        return () => {
            return axios.post(
                `${url}/rfqs/addSupplierQuote/${rfqId}`,
                { document, verification },
                {
                    headers: {
                        authorization,
                    },
                }
            )
        }
    }
    public supplierNoQuote = (rfqId: String, description: String): (() => AxiosPromise) => {
        const url = this.environment.get(EnvironmentVariable.API_LAYER_URL)
        const authorization = `Bearer ${this.authorizationService.getAuthToken()}`
        return () => {
            return axios.put(
                `${url}/rfqs/logSupplierNoQuote/${rfqId}`,
                { rfqId, description },
                {
                    headers: {
                        authorization,
                    },
                }
            )
        }
    }
}
