import { useAppSelector } from "../../../application/hooks";
import React from "react";
import { downloadDocumentFromURL, useGetQuoteQuery, PDFViewerInPage, HeaderNavLink } from "@syncfab/machine";
import Helmet from "react-helmet";
import { Button, Grid, Typography } from "@material-ui/core";
import { useParams } from "react-router";
import { useStandardStyles } from "../../styles/standardStyles";

export const ViewQuoteDocument = () => {

    const standardClasses = useStandardStyles()
    const { quoteId } = useParams<{ quoteId?: string }>()
    const authToken = useAppSelector(state => state.auth.authToken)

    const { data: quoteData, isLoading: isQuoteDataLoading } = useGetQuoteQuery(quoteId)

    const docURL = `/quotes/${quoteId}/generateFinalQuote`

    const handleDownloadQuote = async () => {
        await downloadDocumentFromURL(docURL, authToken, `Quote-${quoteData.rfqId}.pdf`)
    }

    if (!isQuoteDataLoading) {
    return (
        <div className={standardClasses.centeredAlignedContainer}>
            <Helmet>
                <title>RFQs</title>
            </Helmet>
            <HeaderNavLink
                linkTo={`/quotes/${quoteData.rfqId}`}
                linkText='BACK TO RFQ'/>
            <Grid container className={standardClasses.header}>
                <Grid item xs={12} md={6}>
                    <Typography style={{ fontSize: 34, fontWeight: 600 }}>View Quote</Typography>
                </Grid>
                <Grid item container justifyContent="flex-end" alignItems="center" xs={12} md={6}>
                    <Button
                        type="button"
                        color="primary"
                        variant="outlined"
                        onClick={handleDownloadQuote}
                        style={{ minWidth: '120px', marginRight: '8px' }}
                    >
                        Download Quote
                    </Button>
                </Grid>
            </Grid>
            <PDFViewerInPage docURL={docURL} authToken={authToken}/>
        </div>
    )}
    return null
}