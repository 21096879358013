import { Document, RFQ, RFQStatusFilter, FetchActions, createFetchActions, Part, QuoteDetail } from '@syncfab/machine'

export const GET_RFQS: FetchActions<{}, RFQ[]> = createFetchActions('GET_RFQS')
export const GET_RFQ: FetchActions<{ id: string }, RFQ> = createFetchActions('GET_RFQ')
export const RFQ_LIST_UPDATE_FILTER: FetchActions<{ filter: RFQStatusFilter }, {}> = createFetchActions('RFQ_LIST_UPDATE_FILTER')
export const RFQ_DELETE: FetchActions<{ id: string }, { id: string }> = createFetchActions('RFQ_DELETE')
export const RFQ_CREATE: FetchActions<{ rfqId: string; rfq: Partial<RFQ> }, {}> = createFetchActions('RFQ_CREATE')
export const PART_ADD: FetchActions<{ part: Document }, {}> = createFetchActions('PART_ADD')
export const PART_REMOVE: FetchActions<{ partId: string }, {}> = createFetchActions('PART_REMOVE')
export const PART_UPDATE_QUANTITY: FetchActions<{ partId: string; value: number }, {}> = createFetchActions('PART_UPDATE_QUANTITY')
export const PART_UPDATE_PRICE: FetchActions<{ partId: string; value: number }, {}> = createFetchActions('PART_UPDATE_PRICE')
export const DECLINE_RFQ: FetchActions<{ id: string; reason?: string }, {}> = createFetchActions('DECLINE_RFQ')
export const EDIT_RFQ: FetchActions<{ id: string; request?: string }, {}> = createFetchActions('EDIT_RFQ')
export const GET_SUPPLIER_DATA_RFQ: FetchActions<{ token: string }, { rfq: RFQ; parts: Part[] }> = createFetchActions('GET_SUPPLIER_DATA_RFQ')
export const GET_SUPPLIER_DOCUMENTS_RFQ: FetchActions<{ token: string }, void> = createFetchActions('GET_SUPPLIER_DOCUMENTS_RFQ')
export const ADD_SUPPLIER_QUOTE: FetchActions<{ rfqId: string; document: any; verification: any }, void> = createFetchActions('ADD_SUPPLIER_QUOTE')
export const REMOVE_DOCUMENT_RFQ: FetchActions<{ rfqId: string; documentId }, { id: string }> = createFetchActions('REMOVE_DOCUMENT_RFQ')
export const SUPPLIER_NO_QUOTE: FetchActions<{ rfqId: string; description: string }, void> = createFetchActions('ADD_SUPPLIER_QUOTE')
export const GET_QUOTE_FROM_RFQ: FetchActions<{ rfqId: string }, QuoteDetail> = createFetchActions('GET_QUOTE_FROM_RFQ')
export const RFQ_DRAFT: FetchActions<{ rfqId: string; rfq?: any }, {}> = createFetchActions('RFQ_DRAFT')
export const RFQ_SAVE: FetchActions<{ rfq: any }, {}> = createFetchActions('RFQ_SAVE')
export const RESET_DATA: FetchActions<void, number> = createFetchActions('RESET_DATA')
export const UPDATE_RFQ_FORM: FetchActions<{ field: any }, {}> = createFetchActions('UPDATE_RFQ_FORM')
export const GET_SUPPLIER_RFQS: FetchActions<{}, RFQ[]> = createFetchActions('GET_SUPPLIER_RFQS')
export const GET_SUPPLIER_RFQ: FetchActions<{ id: string }, RFQ> = createFetchActions('GET_SUPPLIER_RFQ')
