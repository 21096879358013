import { Document } from '../document/Document'
import { AutoselectOption, ValueLabelOption, valueLabelSchema } from "../ui";
import { object, string } from "yup";

export enum PartFinishes {
    'ANODIZING' = 'ANODIZING',
    'METALLIC_PLATING' = 'METALLIC_PLATING',
    'BLACK_OXIDE' = 'BLACK_OXIDE',
    'ELECTROLESS_PLATING' = 'ELECTROLESS _LATING',
    'PASSIVATING' = 'PASSIVATING',
    'BRUSHING' = 'BRUSHING',
    'BUFF_POLISHING' = 'BUFF_POLISHING',
    'SUPERFINISHING' = 'SUPERFINISHING',
    'A3_OPTICAL_SMOOTH_FINISH' = 'A3_OPTICAL_SMOOTH_FINISH',
    'CHEMICAL_FILM' = 'CHEMICAL_FILM',
    'PLATING' = 'PLATING',
    'POWDER_COATING' = 'POWDER_COATING',
    'POLISHING' = 'POLISHING',
    'VIBRATORY_FINISHING' = 'VIBRATORY_FINISHING',
    'LAPPING' = 'LAPPING',
    'A2_SMOOTH_FINISHING' = 'A2 SMOOTH_FINISHING',
    'MILL_FINISH' = 'MILL_FINISH',
    'BEAD_BLAST' = 'BEAD_BLAST',
    'BLACK_ANODIZE' = 'BLACK_ANODIZE',
    'NONE' = 'NONE',
}

export enum PartMaterial {
    'ALLOY_STEEL_ETD150' = 'ALLOY_STEEL_ETD150',
    'ALLOY_STEEL_41L40' = 'ALLOY_STEEL_41L40',
    'ALLOY_STEEL_86L20' = 'ALLOY_STEEL_86L20',
    'ALLOY_STEEL_4130' = 'ALLOY_STEEL_4130',
    'ALLOY_STEEL_4140' = 'ALLOY_STEEL_4140',
    'ALLOY_STEEL_4142' = 'ALLOY_STEEL_4142',
    'ALLOY_STEEL_4150' = 'ALLOY_STEEL_4150',
    'ALLOY_STEEL_4330' = 'ALLOY_STEEL_4330',
    'ALLOY_STEEL_4340' = 'ALLOY_STEEL_4340',
    'ALLOY_STEEL_6150' = 'ALLOY_STEEL_6150',
    'ALLOY_STEEL_8620' = 'ALLOY_STEEL_8620',
    'ALLOY_STEEL_9310' = 'ALLOY_STEEL_9310',
    'ALLOY_STEEL_52100' = 'ALLOY_STEEL_52100',
    'ALLOY_STEEL_NOT_SURE' = 'ALLOY_STEEL_NOT_SURE',
    'ALUMINUM_1100' = 'ALUMINUM_1100',
    'ALUMINUM_2011' = 'ALUMINUM_2011',
    'ALUMINUM_2024' = 'ALUMINUM_2024',
    'ALUMINUM_3003' = 'ALUMINUM_3003',
    'ALUMINUM_3004' = 'ALUMINUM_3004',
    'ALUMINUM_5052' = 'ALUMINUM_5052',
    'ALUMINUM_5083' = 'ALUMINUM_5083',
    'ALUMINUM_5086' = 'ALUMINUM_5086',
    'ALUMINUM_6060' = 'ALUMINUM_6060',
    'ALUMINUM_6061' = 'ALUMINUM_6061',
    'ALUMINUM_6063' = 'ALUMINUM_6063',
    'ALUMINUM_6101' = 'ALUMINUM_6101',
    'ALUMINUM_6262' = 'ALUMINUM_6262',
    'ALUMINUM_7075' = 'ALUMINUM_7075',
    'ALUMINUM_NOT_SURE' = 'ALUMINUM_NOT_SURE',
    'BRASS_230' = 'BRASS_230',
    'BRASS_260' = 'BRASS_260',
    'BRASS_270' = 'BRASS_270',
    'BRASS_272' = 'BRASS_272',
    'BRASS_314' = 'BRASS_314',
    'BRASS_330' = 'BRASS_330',
    'BRASS_353' = 'BRASS_353',
    'BRASS_360' = 'BRASS_360',
    'BRASS_385' = 'BRASS_385',
    'BRASS_464' = 'BRASS_464',
    'BRASS_485' = 'BRASS_485',
    'BRASS_770' = 'BRASS_770',
    'BRASS_792' = 'BRASS_792',
    'BRASS_NOT_SURE' = 'BRASS_NOT_SURE',
    'BRONZE_SAE_841' = 'BRONZE_SAE_841',
    'BRONZE_SAE_863' = 'BRONZE_SAE_863',
    'BRONZE_220' = 'BRONZE_220',
    'BRONZE_510' = 'BRONZE_510',
    'BRONZE_544' = 'BRONZE_544',
    'BRONZE_630' = 'BRONZE_630',
    'BRONZE_642' = 'BRONZE_642',
    'BRONZE_655' = 'BRONZE_655',
    'BRONZE_863' = 'BRONZE_863',
    'BRONZE_932' = 'BRONZE_932',
    'BRONZE_954' = 'BRONZE_954',
    'BRONZE_959' = 'BRONZE_959',
    'BRONZE_NOT_SURE' = 'BRONZE_NOT_SURE',
    'CARBON_STEEL_A36' = 'CARBON_STEEL_A36',
    'CARBON_STEEL_A529-50' = 'CARBON_STEEL_A529-50',
    'CARBON_STEEL_A53' = 'CARBON_STEEL_A53',
    'CARBON_STEEL_A106' = 'CARBON_STEEL_A106',
    'CARBON_STEEL_A311' = 'CARBON_STEEL_A311',
    'CARBON_STEEL_A366' = 'CARBON_STEEL_A366',
    'CARBON_STEEL_A500' = 'CARBON_STEEL_A500',
    'CARBON_STEEL_A513' = 'CARBON_STEEL_A513',
    'CARBON_STEEL_A514' = 'CARBON_STEEL_A514',
    'CARBON_STEEL_A516-70' = 'CARBON_STEEL_A516-70',
    'CARBON_STEEL_A519' = 'CARBON_STEEL_A519',
    'CARBON_STEEL_A569' = 'CARBON_STEEL_A569',
    'CARBON_STEEL_A572' = 'CARBON_STEEL_A572',
    'CARBON_STEEL_A572-50' = 'CARBON_STEEL_A572-50',
    'CARBON_STEEL_A588' = 'CARBON_STEEL_A588',
    'CARBON_STEEL_A606' = 'CARBON_STEEL_A606',
    'CARBON_STEEL_A653' = 'CARBON_STEEL_A653',
    'CARBON_STEEL_A786' = 'CARBON_STEEL_A786',
    'CARBON_STEEL_A787' = 'CARBON_STEEL_A787',
    'CARBON_STEEL_A992' = 'CARBON_STEEL_A992',
    'CARBON_STEEL_AR235' = 'CARBON_STEEL_AR235',
    'CARBON_STEEL_AR400F' = 'CARBON_STEEL_AR400F',
    'CARBON_STEEL_AR500F' = 'CARBON_STEEL_AR500F',
    'CARBON_STEEL_J524' = 'CARBON_STEEL_J524',
    'CARBON_STEEL_J525' = 'CARBON_STEEL_J525',
    'CARBON_STEEL_12L14' = 'CARBON_STEEL_12L14',
    'CARBON_STEEL_100' = 'CARBON_STEEL_100',
    'CARBON_STEEL_1008' = 'CARBON_STEEL_1008',
    'CARBON_STEEL_1011' = 'CARBON_STEEL_1011',
    'CARBON_STEEL_1018' = 'CARBON_STEEL_1018',
    'CARBON_STEEL_1020' = 'CARBON_STEEL_1020',
    'CARBON_STEEL_1025' = 'CARBON_STEEL_1025',
    'CARBON_STEEL_1044' = 'CARBON_STEEL_1044',
    'CARBON_STEEL_1045' = 'CARBON_STEEL_1045',
    'CARBON_STEEL_1060' = 'CARBON_STEEL_1060',
    'CARBON_STEEL_1117' = 'CARBON_STEEL_1117',
    'CARBON_STEEL_1141' = 'CARBON_STEEL_1141',
    'CARBON_STEEL_1144' = 'CARBON_STEEL_1144',
    'CARBON_STEEL_1147' = 'CARBON_STEEL_1147',
    'CARBON_STEEL_1215' = 'CARBON_STEEL_1215',
    'CARBON_STEEL_NOT_SURE' = 'CARBON_STEEL_NOT_SURE',
    'CAST_IRON_65-45-12' = 'CAST_IRON_65-45-12',
    'CAST_IRON_80-55-06' = 'CAST_IRON_80-55-06',
    'CAST_IRON_NOT_SURE' = 'CAST_IRON_NOT_SURE',
    'COBALT_CHROME_HAAS_BANKY' = 'COBALT_CHROME_HAAS_BANKY',
    'COPPER_101' = 'COPPER_101',
    'COPPER_110' = 'COPPER_110',
    'COPPER_122' = 'COPPER_122',
    'COPPER_145' = 'COPPER_145',
    'COPPER_147' = 'COPPER_147',
    'COPPER_172' = 'COPPER_172',
    'COPPER_182' = 'COPPER_182',
    'COPPER_NOT_SURE' = 'COPPER_NOT_SURE',
    'INCO_625_HAAS_BANKY' = 'INCO_625_HAAS_BANKY',
    'INCO_718_HAAS_BANKY' = 'INCO_718_HAAS_BANKY',
    'MARAGING_STEEL_MS1_HAAS_BANKY' = 'MARAGING_STEEL_MS1_HAAS_BANKY',
    'NICKEL_ALLOY_A286' = 'NICKEL_ALLOY_A286',
    'NICKEL_ALLOY_C276' = 'NICKEL_ALLOY_C276',
    'NICKEL_ALLOY_K500' = 'NICKEL_ALLOY_K500',
    'NICKEL_ALLOY_R405' = 'NICKEL_ALLOY_R405',
    'NICKEL_ALLOY_200' = 'NICKEL_ALLOY_200',
    'NICKEL_ALLOY_400' = 'NICKEL_ALLOY_400',
    'NICKEL_ALLOY_600' = 'NICKEL_ALLOY_600',
    'NICKEL_ALLOY_625' = 'NICKEL_ALLOY_625',
    'NICKEL_ALLOY_718' = 'NICKEL_ALLOY_718',
    'NICKEL_ALLOY_NOT_SURE' = 'NICKEL_ALLOY_NOT_SURE',
    'STAINLESS_STEEL_N50' = 'STAINLESS_STEEL_N50',
    'STAINLESS_STEEL_N60' = 'STAINLESS_STEEL_N60',
    'STAINLESS_STEEL_13-8' = 'STAINLESS_STEEL_13-8',
    'STAINLESS_STEEL_15-5' = 'STAINLESS_STEEL_15-5',
    'STAINLESS_STEEL_17-4' = 'STAINLESS_STEEL_17-4',
    'STAINLESS_STEEL_201' = 'STAINLESS_STEEL_201',
    'STAINLESS_STEEL_301' = 'STAINLESS_STEEL_301',
    'STAINLESS_STEEL_302' = 'STAINLESS_STEEL_302',
    'STAINLESS_STEEL_303' = 'STAINLESS_STEEL_303',
    'STAINLESS_STEEL_304' = 'STAINLESS_STEEL_304',
    'STAINLESS_STEEL_304L' = 'STAINLESS_STEEL_304L',
    'STAINLESS_STEEL_316' = 'STAINLESS_STEEL_316',
    'STAINLESS_STEEL_316L' = 'STAINLESS_STEEL_316L',
    'STAINLESS_STEEL_321' = 'STAINLESS_STEEL_321',
    'STAINLESS_STEEL_347' = 'STAINLESS_STEEL_347',
    'STAINLESS_STEEL_410' = 'STAINLESS_STEEL_410',
    'STAINLESS_STEEL_416' = 'STAINLESS_STEEL_416',
    'STAINLESS_STEEL_420' = 'STAINLESS_STEEL_420',
    'STAINLESS_STEEL_430' = 'STAINLESS_STEEL_430',
    'STAINLESS_STEEL_440' = 'STAINLESS_STEEL_440',
    'STAINLESS_STEEL_440C' = 'STAINLESS_STEEL_440C',
    'STAINLESS_STEEL_1505' = 'STAINLESS_STEEL_1505',
    'STAINLESS_STEEL_NOT_SURE' = 'STAINLESS_STEEL_NOT_SURE',
    'TITANIUM_CP_GRADE_2' = 'TITANIUM_CP_GRADE_2',
    'TITANIUM_6AL-4V' = 'TITANIUM_6AL-4V',
    'TITANIUM_NOT_SURE' = 'TITANIUM_NOT_SURE',
    'TOOL_STEEL_A-2' = 'TOOL_STEEL_A-2',
    'TOOL_STEEL_A-6' = 'TOOL_STEEL_A-6',
    'TOOL_STEEL_D-2' = 'TOOL_STEEL_D-2',
    'TOOL_STEEL_DC-53' = 'TOOL_STEEL_DC-53',
    'TOOL_STEEL_G2' = 'TOOL_STEEL_G2',
    'TOOL_STEEL_H-13' = 'TOOL_STEEL_H-13',
    'TOOL_STEEL_L-6' = 'TOOL_STEEL_L-6',
    'TOOL_STEEL_M-2' = 'TOOL_STEEL_M-2',
    'TOOL_STEEL_M-4' = 'TOOL_STEEL_M-4',
    'TOOL_STEEL_O-1' = 'TOOL_STEEL_O-1',
    'TOOL_STEEL_O-6' = 'TOOL_STEEL_O-6',
    'TOOL_STEEL_P-20' = 'TOOL_STEEL_P-20',
    'TOOL_STEEL_S-5' = 'TOOL_STEEL_S-5',
    'TOOL_STEEL_W-1' = 'TOOL_STEEL_W-1',
    'TOOL_STEEL_NOT_SURE' = 'TOOL_STEEL_NOT_SURE',
    'ZINC' = 'ZINC',
    'CERAMIC' = 'CERAMIC',
    'EDIBLE' = 'EDIBLE',
    'GLASS' = 'GLASS',
    'PAPER' = 'PAPER',
    'SAND' = 'SAND',
    'WOOD' = 'WOOD',
    'ABS_ABS' = 'ABS_ABS',
    'ABS_DELRIN' = 'ABS_DELRIN',
    'ABS_ERTALYTE' = 'ABS_ERTALYTE',
    'ABS_KETRON' = 'ABS_KETRON',
    'ABS_KYNAR' = 'ABS_KYNAR',
    'ABS_NYLATRON' = 'ABS_NYLATRON',
    'ABS_PEEK' = 'ABS_PEEK',
    'ABS_PVDF' = 'ABS_PVDF',
    'ABS_TECHTRON' = 'ABS_TECHTRON',
    'ABS_TEFLON' = 'ABS_TEFLON',
    'ABS_TIVAR' = 'ABS_TIVAR',
    'ABS_TORLON' = 'ABS_TORLON',
    'ABS_ACETAL' = 'ABS_ACETAL',
    'ABS_ACRYLIC' = 'ABS_ACRYLIC',
    'ABS_FLURAN' = 'ABS_FLURAN',
    'ABS_GPO-3' = 'ABS_GPO-3',
    'ABS_NYLON' = 'ABS_NYLON',
    'ABS_PLA' = 'ABS_PLA',
    'ABS_POLYCARBONATE' = 'ABS_POLYCARBONATE',
    'ABS_PTFE' = 'ABS_PTFE',
    'ABS_PVC' = 'ABS_PVC',
    'ABS_TYGON' = 'ABS_TYGON',
    'ABS_UHMW' = 'ABS_UHMW',
    'ABS_NOT_SURE' = 'ABS_NOT_SURE',
    'ALUMINUM_FILLED_NYLON' = 'ALUMINUM_FILLED_NYLON',
    'ASA' = 'ASA',
    'CARBON_FILLED_NYLON' = 'CARBON_FILLED_NYLON',
    'FLEX' = 'FLEX',
    'FR_106_(FR)' = 'FR_106_(FR)',
    'GLASS_FILLED_NYLON' = 'GLASS_FILLED_NYLON',
    'HDPE' = 'HDPE',
    'HIPS' = 'HIPS',
    'NYLON_11' = 'NYLON_11',
    'NYLON_12_PA' = 'NYLON_12_PA',
    'PA' = 'PA',
    'PA_2100_FR' = 'PA_2100_FR',
    'PA_2200' = 'PA_2200',
    'PA_606_FR' = 'PA_606_FR',
    'PC' = 'PC',
    'PE' = 'PE',
    'PEKK' = 'PEKK',
    'PET' = 'PET',
    'PHA' = 'PHA',
    'PLA_ABS' = 'PLA_ABS',
    'PLA_DELRIN' = 'PLA_DELRIN',
    'PLA_ERTALYTE' = 'PLA_ERTALYTE',
    'PLA_KETRON' = 'PLA_KETRON',
    'PLA_KYNAR' = 'PLA_KYNAR',
    'PLA_NYLATRON' = 'PLA_NYLATRON',
    'PLA_PEEK' = 'PLA_PEEK',
    'PLA_PVDF' = 'PLA_PVDF',
    'PLA_TECHTRON' = 'PLA_TECHTRON',
    'PLA_TEFLON' = 'PLA_TEFLON',
    'PLA_TIVAR' = 'PLA_TIVAR',
    'PLA_TORLON' = 'PLA_TORLON',
    'PLA_ACETAL' = 'PLA_ACETAL',
    'PLA_ACRYLIC' = 'PLA_ACRYLIC',
    'PLA_FLURAN' = 'PLA_FLURAN',
    'PLA_GPO-3' = 'PLA_GPO-3',
    'PLA_NYLON' = 'PLA_NYLON',
    'PLA_PLA' = 'PLA_PLA',
    'PLA_POLYCARBONATE' = 'PLA_POLYCARBONATE',
    'PLA_PTFE' = 'PLA_PTFE',
    'PLA_PVC' = 'PLA_PVC',
    'PLA_TYGON' = 'PLA_TYGON',
    'PLA_UHMW' = 'PLA_UHMW',
    'PLA_NOT_SURE' = 'PLA_NOT_SURE',
    'POLYCARBONATE' = 'POLYCARBONATE',
    'POLYESTER' = 'POLYESTER',
    'POLYSTYRENE' = 'POLYSTYRENE',
    'PP' = 'PP',
    'PRIME_CAST_101' = 'PRIME_CAST_101',
    'PS' = 'PS',
    'PVA' = 'PVA',
    'PVC' = 'PVC',
    'TPE' = 'TPE',
    'PLASTICS_ABS' = 'PLASTICS_ABS',
    'PLASTICS_DELRIN' = 'PLASTICS_DELRIN',
    'PLASTICS_ERTALYTE' = 'PLASTICS_ERTALYTE',
    'PLASTICS_KETRON' = 'PLASTICS_KETRON',
    'PLASTICS_KYNAR' = 'PLASTICS_KYNAR',
    'PLASTICS_NYLATRON' = 'PLASTICS_NYLATRON',
    'PLASTICS_PEEK' = 'PLASTICS_PEEK',
    'PLASTICS_PVDF' = 'PLASTICS_PVDF',
    'PLASTICS_TECHTRON' = 'PLASTICS_TECHTRON',
    'PLASTICS_TEFLON' = 'PLASTICS_TEFLON',
    'PLASTICS_TIVAR' = 'PLASTICS_TIVAR',
    'PLASTICS_TORLON' = 'PLASTICS_TORLON',
    'PLASTICS_ACETAL' = 'PLASTICS_ACETAL',
    'PLASTICS_ACRYLIC' = 'PLASTICS_ACRYLIC',
    'PLASTICS_FLURAN' = 'PLASTICS_FLURAN',
    'PLASTICS_GPO-3' = 'PLASTICS_GPO-3',
    'PLASTICS_NYLON' = 'PLASTICS_NYLON',
    'PLASTICS_PLA' = 'PLASTICS_PLA',
    'PLASTICS_POLYCARBONATE' = 'PLASTICS_POLYCARBONATE',
    'PLASTICS_PTFE' = 'PLASTICS_PTFE',
    'PLASTICS_PVC' = 'PLASTICS_PVC',
    'PLASTICS_TYGON' = 'PLASTICS_TYGON',
    'PLASTICS_UHMW' = 'PLASTICS_UHMW',
    'PLASTICS_NOT_SURE' = 'PLASTICS_NOT_SURE',
}

export type PartDocumentTypes = 'revised2DModel' | 'revised3DModel'

export interface Editor {
    userId?: string
    userName?: string
}
export interface Part {
    _id: string
    number: string
    name: string
    companyId: string
    userId?: string
    operatorId?: string
    material: PartMaterial
    finishes: PartFinishes
    models: Document[]
    revised2DModel: Document
    revised3DModel: Document
    createdAt: Date
    updatedAt?: Date
    deletedAt?: Date
    documents?: Document[]
    updatedBy?: Editor
}

export interface PartDAPPSimple {
    _id: string
    number: string
    revision: string
    name: string
    companyId: string
    userId?: string
    material: PartMaterial[] // legacy
    manufacturingProcesses: string[]
    materials: string[]
    materialDetails: string[]
    finishes: string[]
    finishDetails: string[]
    models: Document[]
    createdAt: Date
    updatedAt?: Date
    deletedAt?: Date
    documentIds: string[]
    company: {
        companyId: string
    }
    nationalStockNumber?: string
    notes?: string
    exceptions?: string
    isCurrentRevision?: boolean
}
export interface PartDAPP extends PartDAPPSimple{
    documents?: Document[]
}

export interface PartInput {
    _id: string
    number: string
    revision: string
    name: string
    nationalStockNumber: string
    company: {
        companyId: string
    }
    manufacturingProcesses: string[]
    materials: string[]
    finishes: string[]
    notes: string
    documentIds: string[]
}

export interface PartSubmission {
    _id: string
    number: string
    revision: string
    name: string
    companySelection: AutoselectOption
    manufacturingProcesses: AutoselectOption[]
    materials: AutoselectOption[]
    finishes: AutoselectOption[]
    nationalStockNumber: string
    notes: string
}

export const generatePartOptions = (parts: PartDAPP[]): ValueLabelOption[] => {
    return parts.map(part => generatePartOption(part))
}

export const generatePartOption = (part: PartDAPP): ValueLabelOption => {
    return {
        value: part._id,
        label: `${part._id} - ${part.number} - ${part.revision} - ${part.name}`
    }
}
export const materialSchema = object().shape({
    material: valueLabelSchema.required('Material is required.').typeError('Material is required.'),
    details: string().optional()
})

export const finishSchema = object().shape({
    finish: valueLabelSchema.required('Finish is required').typeError('Finish is required.'),
    details: string().optional()
})
