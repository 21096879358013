import { Supplier, FetchActions, createFetchActions } from '@syncfab/machine'

export type UpdateForgottenPasswordPayload = {
    password: string
    confirmPassword: string
    email: string
    token: string
}

export type UserPasswordUpdatePayload = {
    password: string
    newPassword: string
}

export const SUPPLIER_CREATE: FetchActions<Partial<Supplier>, Partial<Supplier>> = createFetchActions('SUPPLIER_CREATE')
export const SUPPLIER_EMAIL_EXIST: FetchActions<{ email: string }, void> = createFetchActions('SUPPLIER_EMAIL_EXIST')
export const SUPPLIER_FORGOT_PASSWORD: FetchActions<{ email: string }, { email: string }> = createFetchActions('SUPPLIER_FORGOT_PASSWORD')
export const SUPPLIER_RESEND_EMAIL: FetchActions<{ id: string }, void> = createFetchActions('SUPPLIER_RESEND_EMAIL')
export const SUPPLIER_RESEND_VERIFICATION_EMAIL: FetchActions<{ email: string }, void> = createFetchActions('SUPPLIER_RESEND_VERIFICATION_EMAIL')
export const SUPPLIER_VALIDATE: FetchActions<{ token: string }, { active: boolean; activationError?: string }> = createFetchActions('SUPPLIER_VALIDATE')
export const SUPPLIER_UPDATE_FORGOT_PASSWORD: FetchActions<UpdateForgottenPasswordPayload, {}> = createFetchActions('SUPPLIER_UPDATE_FORGOT_PASSWORD')
export const SUPPLIER_PASSWORD: FetchActions<UserPasswordUpdatePayload, string> = createFetchActions('SUPPLIER_PASSWORD')
export const GET_SUPPLIERS: FetchActions<{}, {}> = createFetchActions('GET_SUPPLIERS')
export const GET_SUPPLIER: FetchActions<{}, {}> = createFetchActions('GET_SUPPLIER')
export const UPDATE_LAST_ACTIVE: FetchActions<{}, {}> = createFetchActions('UPDATE_LAST_ACTIVE')
export const GET_SUPPLIER_TAGS: FetchActions<{}, { data: any; total: number }> = createFetchActions('GET_SUPPLIER_TAGS')
