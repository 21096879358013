import React from 'react'
import { Helmet } from 'react-helmet'
import { withNamespaces, WithNamespaces } from 'react-i18next'
import { WithStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import { State } from '../../../application/Store'
import { Dispatch } from 'redux'
import { connect } from 'react-redux'
import { useHistory } from 'react-router-dom';
import { VerifiedIcon } from '@syncfab/machine'
import { Paper, withStyles } from '@material-ui/core'
import { CenteredContainer } from '../../../layout/Container'
import { SUPPLIER_VALIDATE } from '../../../components/supplier/Actions'

const styles = theme => ({
    paper: {
        padding: theme.spacing(6),
        display: 'flex',
        'flex-direction': 'column',
        'justify-content': 'center',
        alignItems: 'center',
        width: 400,
        'text-align': 'center',
    },
    error: {
        color: theme.palette.error.main,
        marginBottom: theme.spacing(2),
    },
    text: {
        padding: theme.spacing(2),
        color: theme.palette.common.white,
        marginBottom: theme.spacing(2),
    },
    link: {
        textDecoration: 'none',
        width: '100%',
    },
    verifiedIcon: {
        marginLeft: -16,
    },
})

interface SignupValidationProps extends WithNamespaces, WithStyles {
    activate: (token: string) => {}
    validationError?: string
    valid: boolean
}

const SignupValidationView: React.FC<SignupValidationProps> = ({ activate, validationError, t, classes }) => {
    const history = useHistory();
    React.useEffect(() => {
        const token = new URLSearchParams(window.location.search.slice(1)).get('token') || ''
        console.log('ACTIVATING');
        activate(token);
    }, [])
    React.useEffect(() => {
        if(activate && !validationError) {
            history.push('/suppliers/login');
        }
    }, [activate, validationError])
    return (
        <CenteredContainer>
            <Helmet>
                <title>{`${t('supplier-signup-Validation-title')}`}</title>
            </Helmet>
            <Paper className={classes.paper} elevation={1}>
                {activate && !!validationError && (
                    <Typography className={classes.error} variant="caption">
                        {`${t('feedback-support')}`}
                    </Typography>
                )}
                {activate && !validationError && (
                    <React.Fragment>
                        <VerifiedIcon className={classes.verifiedIcon} />
                        <Typography className={classes.text} variant="h5">
                            {`${t('supplier-signup-Validation-activated')}`}
                        </Typography>
                    </React.Fragment>
                )}
            </Paper>
        </CenteredContainer>
    );
}
const mapStateToProps = ({ supplier: { validationError, valid } }: State) => {
    return {
        validationError,
        valid,
    }
}

const mapDispatchToProps = (dispatch: Dispatch) => {
    return {
        activate: (token: string) => dispatch(SUPPLIER_VALIDATE.actions.TRIGGER({ token })),
    }
}
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withNamespaces()(withStyles(styles)(SignupValidationView)))