import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import { FormControl } from '@material-ui/core'
import { FormFieldFooter } from '../Error'
import ReactQuill from 'react-quill-new'
import 'react-quill-new/dist/quill.snow.css'

const styles = theme => ({
    editor: {
        '& .ql-editor': {
            minHeight: 100,
            borderRadius: '0.5em',
            '&.ql-blank::before': {
                fontStyle: 'normal',
                color: theme.palette.text.disabled,
            },
        },
        '& .ql-container': {
            borderBottomLeftRadius: '0.5em',
            borderBottomRightRadius: '0.5em',
            background: '#fefcfc',
            backgroundColor: '#F3F3F3',
        },
        '& .ql-snow.ql-toolbar': {
            borderTopLeftRadius: '0.5em',
            borderTopRightRadius: '0.5em',
        },
        '& .ql-snow.ql-toolbar button:hover': {
            stroke: theme.palette.primary.main,
        },
        '& .ql-snow.ql-toolbar button:hover .ql-stroke': {
            stroke: theme.palette.primary.main,
        },
        '& .ql-snow.ql-toolbar button.ql-active': {
            color: theme.palette.primary.main,
        },
        '& .ql-snow.ql-toolbar button:focus': {
            color: theme.palette.primary.main,
        },
        '& .ql-snow.ql-toolbar button.ql-active .ql-stroke': {
            stroke: theme.palette.primary.main,
        },
        '& .ql-editor.ql-blank::before': {
            fontSize: '14px',
        },
    },
})

const modules = {
    toolbar: [['bold', 'italic', 'underline']],
}

const formats = ['bold', 'italic', 'underline']

const TextEditor = ({ input, meta: { touched, error }, placeholder, description, fullWidth, className, disabled, classes }: any) => {
    return (
        <FormControl error={!!touched && !!error} fullWidth={fullWidth} className={className} disabled={disabled}>
            <ReactQuill className={classes.editor} onChange={input.onChange} value={input.value} modules={modules} formats={formats} placeholder={placeholder} />
            <FormFieldFooter touched={touched} error={error} description={description} />
        </FormControl>
    )
}

export const FormTextEditor = withStyles(styles)(TextEditor)
