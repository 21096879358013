import React from 'react'
import { Field, reduxForm } from 'redux-form/immutable'
import { InjectedFormProps } from 'redux-form'
import { Map } from 'immutable'
import { WithStyles, withStyles } from '@material-ui/core/styles'
import { withNamespaces, WithNamespaces } from 'react-i18next'
import { jumpToFirstError, FormTextField } from '@syncfab/machine'
import { Grid, Button, Typography, Divider, Card, CardHeader, IconButton, CardContent, Tooltip } from '@material-ui/core'
import { MFGIcon, PRTIcon } from '@syncfab/machine'
import DeleteIcon from '@material-ui/icons/Delete'
import { Link, Redirect } from 'react-router-dom'
import InfoIcon from '@material-ui/icons/Info'

export const PartTokenizeEdiorFormName: string = 'PartTokenizeEdiorFormName'

const styles = theme => ({
    paper: {
        minWidth: 300,
        //padding: theme.spacing.unit * 3,
        marginTop: theme.spacing(3),
        //marginBottom: theme.spacing.unit * 4,
    },
    IconContainer: {
        padding: theme.spacing(3),
    },
    divider: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2),
        backgroundColor: '#dbdbdb',
    },
    link: {
        textDecoration: 'none',
        color: '#40B4BF',
    },
    button: {
        width: '95%',
    },
    viewpart: {
        margin: theme.spacing(4),
    },
    mfgIcon: {
        // width: '100%',
        // height: '100%',
        width: 75,
        height: 75,
    },
    PrtIcon: {
        width: 35,
        height: 35,
    },
    card: {
        width: '100%',
        margin: `${theme.spacing(3)}px 12px`,
        backgroundColor: theme.palette.common.white,
    },
    cardheader: {
        backgroundColor: '#DBDBDB',
    },
    nftBalanceContainer: {
        border: `1px solid ${theme.palette.secondary.main}`,
        borderRadius: 15,
        margin: `${theme.spacing(2)}px ${theme.spacing(0)}px`,
        //padding: theme.spacing.unit * 3,
    },
    nftBalance: {
        color: theme.palette.secondary.main,
        fontSize: 34,
        marginLeft: theme.spacing(1),
    },
    avatar: {
        backgroundColor: '#fffff',
    },
    // divider: {
    //     marginBottom: theme.spacing.unit * 2,
    // },

    // arrow: {
    //     color: theme.palette.common.white,
    //     marginRight: theme.spacing.unit,
    // },
    detailcard: {
        height: 'fit-content',
        margin: `${theme.spacing(3)}px 12px`,
        backgroundColor: theme.palette.common.white,
        //color: theme.palette.common.black,
    },
    customcardheader: {
        'background-color': '#eeeeee',
    },
    customtextfield: {
        'background-color': '#DBDBDB',
        margin: theme.spacing(0),
    },
    marginTextField: {
        marginRight: theme.spacing(2),
    },
    padRight: {
        paddingRight: theme.spacing(2),
    },
    container: {
        marginTop: theme.spacing(1),
    },
    paddingRight: {
        paddingRight: theme.spacing(3),
        paddingTop: theme.spacing(3),
    },
    mediumFont: {
        fontSize: 24,
    },
    inputBox: {
        border: '1px solid rgba(0, 0, 0, 0.23)',
        borderRadius: 3,
    },
    tooltipIcon: {
        display: 'inline-block',
        'vertical-align': 'middle',
        marginLeft: 10,
    },
})

const validate = (data: Map<string, string>): {} => {
    const errors = {}
    data.forEach((val, key) => {
        console.log(key, val)
        console.log(key.endsWith('_desc_2'))
        console.log(val.length)

        if (key.endsWith('_title_1') && val.length > 10) {
            errors[key] = 'Max character limit 10'
        }
        if (key.endsWith('_title_2') && val.length > 10) {
            errors[key] = 'Max character limit 10'
        }
        if (key.endsWith('_desc_1') && val.length > 20) {
            errors[key] = 'Max character limit 20'
        }
        if (key.endsWith('_desc_2') && val.length > 20) {
            errors[key] = 'Max character limit 20'
        }
    })

    return errors
}

interface FormState {
    sendToNextPage: boolean
}

interface FormProperties {
    selectedParts?: any[]
    nftBalance?: any
    transactionFeeForPartTokenization?: any
    deletePart: (id) => void
}

interface FormProps extends InjectedFormProps<{}, {}>, WithStyles, WithNamespaces, FormProperties {}

class Form extends React.Component<FormProps, FormState> {
    constructor(props) {
        super(props)
        this.props.reset()
        this.state = {
            sendToNextPage: false,
        }
        if (!this.props.selectedParts || this.props.selectedParts.length == 0) {
            this.state = {
                sendToNextPage: true,
            }
        }
    }

    getTotal() {
        var total = 0
        this.props.selectedParts.forEach(prt => {
            if (prt.status == 'NOT_TOKENIZE' || !prt.status) {
                total += this.props.transactionFeeForPartTokenization.nftCreationCost
                total += this.props.transactionFeeForPartTokenization.nftCreationFee
                total += this.props.transactionFeeForPartTokenization.mfgprtUnitCost
            }
        })
        return total
    }

    getFeeTotal() {
        var total = 0
        this.props.selectedParts.forEach(prt => {
            if (prt.status == 'NOT_TOKENIZE' || !prt.status) {
                //total += this.props.transactionFeeForPartTokenization.nftCreationCost
                total += this.props.transactionFeeForPartTokenization.nftCreationCost
            }
        })
        return total
    }

    getmfgPrtUnitCostTotal() {
        var total = 0
        this.props.selectedParts.forEach(prt => {
            if (prt.status == 'NOT_TOKENIZE' || !prt.status) {
                //total += this.props.transactionFeeForPartTokenization.nftCreationCost
                total += this.props.transactionFeeForPartTokenization.nftCreationFee
            }
        })
        return total
    }

    render() {
        const { handleSubmit, classes, selectedParts, nftBalance, transactionFeeForPartTokenization, deletePart } = this.props
        const { sendToNextPage } = this.state
        return (
            <form onSubmit={handleSubmit}>
                <Grid container>
                    <Grid container item xs={8} className={classes.paddingRight}>
                        <Grid>
                            <Typography variant="h3">Current MFG Balance</Typography>
                        </Grid>

                        <Grid item container alignItems="center" className={classes.nftBalanceContainer}>
                            <Grid item xs={12} md={3} className={classes.IconContainer}>
                                <MFGIcon className={classes.mfgIcon} fill="#FFBA00" />
                            </Grid>
                            <Grid item xs={12} md={9}>
                                <Typography gutterBottom variant="h3">
                                    MFG Token Balance
                                </Typography>
                                <Typography gutterBottom className={classes.nftBalance}>
                                    {nftBalance ? (nftBalance.MFG_Balance !== null || undefined ? `${nftBalance.MFG_Balance} ` : '0') : '0'}{' '}
                                </Typography>
                            </Grid>
                        </Grid>
                        <Grid container className={classes.container}>
                            <Typography variant="h3">The following part(s) will be Tokenized</Typography>
                            <Typography variant="body1">
                                Tokenizing your part(s) creates an immutable TokenID that tracks the ownership of the part on our Hybrid Blockchain. Once attributes are logged onto the blockchain they
                                cannot be changed.
                            </Typography>
                            <Grid container spacing={8} direction="row">
                                {selectedParts.map(singlePart => (
                                    <Grid item container xs={12} key={singlePart._id} direction="row">
                                        <Card className={classes.card} style={{ margin: '16px 0 0' }}>
                                            <CardHeader
                                                title={
                                                    <Typography variant="h3" color="textPrimary">
                                                        Part Number : {singlePart.number}
                                                    </Typography>
                                                }
                                                // className={classes.cardheader}
                                                className={`${classes.cardheader} ${classes.customcardheader}`}
                                                avatar={<PRTIcon className={classes.PrtIcon} fill="#FFBA00" />}
                                                action={
                                                    <IconButton aria-label="settings" className={classes.marginTextField}>
                                                        <DeleteIcon
                                                            onClick={() => {
                                                                console.log('call delete part')

                                                                deletePart(singlePart._id)
                                                            }}
                                                        />
                                                    </IconButton>
                                                }
                                            />
                                            <CardContent style={{ padding: '16px 16px 0' }}>
                                                <Grid container item xs={12} className={classes.container}>
                                                    <Grid item xs={12}>
                                                        <Typography color="textPrimary" variant="h6">
                                                            Add Custom Attribute 1 (Optional)
                                                        </Typography>
                                                        <Grid container>
                                                            <Grid item xs={4} className={classes.padRight}>
                                                                <Field
                                                                    name={`${singlePart._id}_title_1`}
                                                                    type="text"
                                                                    id="standard-basic"
                                                                    //className={`${classes.textField} ${classes.customtextfield}`}
                                                                    label="Title"
                                                                    margin="normal"
                                                                    variant="outlined"
                                                                    fullWidth
                                                                    component={FormTextField}
                                                                />
                                                            </Grid>
                                                            <Grid item xs={8}>
                                                                <Field
                                                                    name={`${singlePart._id}_desc_1`}
                                                                    type="text"
                                                                    id="standard-basic"
                                                                    //className={`${classes.textField} ${classes.customtextfield}`}
                                                                    label="Description"
                                                                    margin="normal"
                                                                    variant="outlined"
                                                                    fullWidth
                                                                    component={FormTextField}
                                                                />
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                    <Grid item xs={12}>
                                                        <Typography color="textPrimary" variant="h6">
                                                            Add Custom Attribute 2 (Optional)
                                                        </Typography>
                                                        <Grid container>
                                                            <Grid item xs={4} className={classes.padRight}>
                                                                <Field
                                                                    name={`${singlePart._id}_title_2`}
                                                                    type="text"
                                                                    id="standard-basic"
                                                                    //className={`${classes.textField} ${classes.customtextfield}`}
                                                                    label="Title"
                                                                    margin="normal"
                                                                    variant="outlined"
                                                                    fullWidth
                                                                    component={FormTextField}
                                                                />
                                                            </Grid>
                                                            <Grid item xs={8}>
                                                                <Field
                                                                    name={`${singlePart._id}_desc_2`}
                                                                    type="text"
                                                                    id="standard-basic"
                                                                    //className={`${classes.textField} ${classes.customtextfield}`}
                                                                    label="Description"
                                                                    margin="normal"
                                                                    variant="outlined"
                                                                    fullWidth
                                                                    component={FormTextField}
                                                                />
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            </CardContent>
                                        </Card>
                                    </Grid>
                                ))}
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid container item xs={4}>
                        <Card className={`${classes.card} ${classes.detailcard}`} style={{ marginLeft: '0', marginRight: '0' }}>
                            <CardHeader
                                className={classes.cardheader}
                                title={
                                    <Typography variant="h3" color="textPrimary" className={classes.mediumFont}>
                                        Order Summary
                                    </Typography>
                                }
                            />
                            <CardContent style={{ padding: '16px' }}>
                                {selectedParts.map(singlePart => (
                                    <Grid container className={classes.container} key={singlePart._id}>
                                        <Grid item xs={6}>
                                            <Typography color="textPrimary">MFGPRT Token</Typography>
                                        </Grid>
                                        <Grid item xs={6}>
                                            <Typography color="textPrimary" align="right">
                                                {transactionFeeForPartTokenization &&
                                                    (singlePart.status == 'NOT_TOKENIZE' || !singlePart.status) &&
                                                    transactionFeeForPartTokenization.mfgprtUnitCost + ' MFG'}
                                                {transactionFeeForPartTokenization &&
                                                    singlePart.status != 'NOT_TOKENIZE' &&
                                                    !singlePart.status &&
                                                    !transactionFeeForPartTokenization.mfgprtUnitCost &&
                                                    '0 MFG'}
                                                {!transactionFeeForPartTokenization && ''}
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                ))}

                                <Divider className={classes.divider} />

                                <Grid container className={classes.container}>
                                    <Grid item xs={8}>
                                        <Typography color="textPrimary">
                                            Transfer Fee
                                            <Tooltip className={classes.tooltipIcon} title={'Covers the transaction cost of transferring the NFT token to your wallet'} enterDelay={300}>
                                                <InfoIcon fontSize="small" color="disabled" />
                                            </Tooltip>
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={4}>
                                        <Typography color="textPrimary" align="right">
                                            {transactionFeeForPartTokenization && this.getmfgPrtUnitCostTotal() + ' MFG'}
                                            {!transactionFeeForPartTokenization && ''}
                                        </Typography>
                                    </Grid>
                                </Grid>
                                <Grid container className={classes.container}>
                                    <Grid item xs={8}>
                                        <Typography color="textPrimary">
                                            Token Creation Fee
                                            <Tooltip className={classes.tooltipIcon} title={'Covers the creation cost of NFT token on Ethereum blockchain'} enterDelay={300}>
                                                <InfoIcon fontSize="small" color="disabled" />
                                            </Tooltip>
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={4}>
                                        <Typography color="textPrimary" align="right">
                                            {transactionFeeForPartTokenization && this.getFeeTotal() + ' MFG'}
                                            {!transactionFeeForPartTokenization && ''}
                                        </Typography>
                                    </Grid>
                                </Grid>
                                <Divider className={classes.divider} />
                                <Grid container className={classes.container}>
                                    <Grid item xs={6}>
                                        <Typography color="textPrimary" variant="h3">
                                            Total
                                            {/* <Tooltip className={classes.tooltipIcon} title={'Equivalent MFG cost for NFT Transaction = 4000 MFG'} enterDelay={300}>
                                                <InfoIcon fontSize="small" color="disabled" />
                                            </Tooltip> */}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Typography color="textPrimary" align="right" variant="h3">
                                            {transactionFeeForPartTokenization && selectedParts && this.getTotal() + ' MFG'}
                                        </Typography>
                                    </Grid>
                                </Grid>
                                <Grid container className={classes.container} style={{ marginTop: '16px' }}>
                                    {/* <Grid item xs={12} style={{marginBottom:'24px'}}>
                                        <Typography color="textPrimary">
                                            By Confirming this transaction ,you're agreeing to SyncFab's{' '}
                                            <a href="https://syncfab.com/terms-of-use" target="_blank" className={classes.link}>
                                                {' '}
                                                Terms and Conditions
                                            </a>{' '}
                                        </Typography>
                                    </Grid> */}
                                    <Grid item xs={6}>
                                        <Link to="/parts" className={classes.link}>
                                            <Button type="button" color="primary" variant="outlined" className={classes.button}>
                                                Cancel
                                            </Button>
                                        </Link>
                                    </Grid>
                                    <Grid item xs={6} style={{ textAlign: 'right' }}>
                                        <Button type="submit" color="primary" variant="contained" className={classes.button}>
                                            Purchase
                                        </Button>
                                    </Grid>
                                </Grid>
                            </CardContent>
                        </Card>
                    </Grid>
                </Grid>
                {sendToNextPage && <Redirect to="/parts" />}
            </form>
        )
    }
}

const translatedForm = withStyles(styles)(withNamespaces()(Form))
export const PartTokenizeEdiorForm = reduxForm<{}, FormProperties>({
    form: PartTokenizeEdiorFormName,
    validate,
    onSubmitFail: jumpToFirstError,
})(translatedForm as any) // ReduxForm types are not good
