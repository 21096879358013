import React from 'react'
import { TransactionType } from '@syncfab/machine'
import { SpentTransactionListItem } from './Transactions/SpentTransactionListItem'
import { PurchasedTransactionListItem } from './Transactions/PurchasedTransactionListItem'
import { ReceivedTransactionListItem } from './Transactions/ReceivedTransactionListItem'
import { EarnedTransactionListItem } from './Transactions/EarnedTransactionListItem'
import { SentTransactionListItem } from './Transactions/SentTransactionListItem'
import { Table, TableHead, TableRow, TableCell, TableBody, TablePagination } from '@material-ui/core'
import { withNamespaces, WithNamespaces } from 'react-i18next'
import { withInfiniteScroll } from './WithInfiniteScroll'
import { BoldTypography } from '../../../utils/Typography/BoldTypography'

const mapper = {
    [TransactionType.SPENT]: SpentTransactionListItem,
    [TransactionType.PURCHASED]: PurchasedTransactionListItem,
    [TransactionType.RECEIVED]: ReceivedTransactionListItem,
    [TransactionType.EARNED]: EarnedTransactionListItem,
    [TransactionType.SENT]: SentTransactionListItem,
}

interface TransactionListProps extends WithNamespaces {
    handleChangePage: (event, page: number) => void
    handleChangeRowsPerPage?: (event) => void
    transaction: any
    classes: any
}

export const TransactionList = withInfiniteScroll(
    'transaction-list',
    withNamespaces()(({ transaction, t, handleChangePage, handleChangeRowsPerPage, classes }: TransactionListProps) => {
        // if (!transactions.length) {
        //     return <EmptyTransactionList />
        // }

        return (
            <div style={{ overflowX: 'auto' }}>
                <Table style={{ minWidth: 650, border: 'none' }}>
                    <TableHead>
                        <TableRow style={{ borderColor: '#566E83' }}>
                            <TableCell>
                                <BoldTypography align="left" gutterBottom>
                                    {`${t('wallet-transactions-category')}`}
                                </BoldTypography>
                            </TableCell>
                            <TableCell>
                                <BoldTypography align="left" gutterBottom>
                                    {`${t('syncfab-description')}`}
                                </BoldTypography>
                            </TableCell>
                            <TableCell>
                                <BoldTypography align="left" gutterBottom>
                                    {`${t('syncfab-date')}`}
                                </BoldTypography>
                            </TableCell>
                            <TableCell>
                                <BoldTypography align="left" gutterBottom>
                                    {`${t('wallet-transactions-tokens')}`}
                                </BoldTypography>
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {transaction.transactions.map(transaction => {
                            const Item = mapper[transaction.type] || mapper[TransactionType.EARNED]
                            return (
                                <React.Fragment key={transaction._id}>
                                    <Item transaction={transaction} />
                                    {/* <Grid item xs={12}>
                                <Divider />
                            </Grid> */}
                                </React.Fragment>
                            )
                        })}
                    </TableBody>
                </Table>
                <TablePagination
                    rowsPerPageOptions={[25, 50, 75]}
                    component="div"
                    count={parseInt(transaction.transactionTotal)}
                    rowsPerPage={parseInt(transaction.size)}
                    page={parseInt(transaction.page)}
                    backIconButtonProps={{
                        'aria-label': 'Previous Page',
                        className: classes.tablePaginationBackIcon,
                    }}
                    nextIconButtonProps={{
                        'aria-label': 'Next Page',
                        className: classes.tablePaginationNextIcon,
                    }}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                    classes={{
                        root: classes.tablePagination,
                        caption: classes.tablePaginationCaption,
                        selectIcon: classes.tablePaginationSelectIcon,
                        select: classes.tablePaginationSelect,
                        actions: classes.tablePaginationActions,
                    }}
                />
            </div>
            // <Grid container spacing={8} id="transaction-list" style={{ overflowX: 'auto'}}>
            //     <Grid item container spacing={8} xs={12} direction="row" justify="space-between">
            //         <Grid item xs={6} md={2}>
            //             <BoldTypography gutterBottom>{t('wallet-transactions-category')}</BoldTypography>
            //         </Grid>
            //         <Grid item xs={12} md={6}>
            //             <BoldTypography gutterBottom>{t('syncfab-description')}</BoldTypography>
            //         </Grid>
            //         <Grid item xs={6} md={2}>
            //             <BoldTypography gutterBottom>{t('syncfab-date')}</BoldTypography>
            //         </Grid>
            //         <Grid item xs={6} md={2}>
            //             <BoldTypography gutterBottom>{t('wallet-transactions-tokens')}</BoldTypography>
            //         </Grid>
            //     </Grid>
            //     <Grid item xs={12}>
            //         <Divider />
            //     </Grid>
            //     {transactions.map(transaction => {
            //         const Item = mapper[transaction.type] || mapper[TransactionType.EARNED]
            //         return (
            //             <React.Fragment key={transaction._id}>
            //                 <Item transaction={transaction} />
            //                 <Grid item xs={12}>
            //                     <Divider />
            //                 </Grid>
            //             </React.Fragment>
            //         )
            //     })}
            // </Grid>
        )
    })
)
