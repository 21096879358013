import React, { useEffect, useRef, useState } from 'react'
import { Field, reduxForm, FormSection } from 'redux-form/immutable'
import { InjectedFormProps } from 'redux-form'
import { Map } from 'immutable'
import { WithStyles, withStyles } from '@material-ui/core/styles'
import { withNamespaces, WithNamespaces } from 'react-i18next'
import { FormAutocompleteV2, FormTextField, jumpToFirstError } from '@syncfab/machine'
import { Countries } from '../../../data/countries'
import { Grid } from '@material-ui/core'

export const AddressFormName: string = 'addressForm'

const styles = theme => ({
    form: {
        marginTop: 5,
        // display: 'flex',
        // 'flex-direction': 'column',
        // maxWidth: 1000,
        // minWidth: 300,
        // margin: 'auto',
        // [theme.breakpoints.down('md')]: {
        //     maxWidth: 600,
        // },
    },
    // row: {
    //     display: 'flex',
    //     'flex-direction': 'row',
    //     [theme.breakpoints.down('md')]: {
    //         'flex-direction': 'column',
    //     },
    // },
    leftInput: {
        marginRight: theme.spacing(2),
        [theme.breakpoints.down('md')]: {
            marginRight: 0,
        },
        '& .MuiFormControl-marginNormal': {
            margin: 0,
        },
    },
    rightInput: {
        marginLeft: theme.spacing(2),
        [theme.breakpoints.down('md')]: {
            marginLeft: 0,
        },
        '& .MuiFormControl-marginNormal': {
            margin: 0,
        },
    },
    action: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
        marginLeft: 'auto',
    },
    gridItem: {
        paddingTop: '0px !important',
        paddingBottom: '0px !important',
    },
    formInput: {
        '& .MuiFormControl-marginNormal': {
            margin: 0,
        },
    },
})

// type CompanyAddressFields = Partial<Address>
const MAX_LENGTH_MAPPING = {
    state: 3,
    line: 100,
    city: 40,
    zipCode: 6,
    country: 40,
}

export const locationValidation = (data: Map<string, string>): {} => {
    const errors = {} as any
    ;['line', 'city', 'state', 'country', 'zipCode'].forEach(field => {
        if (!data.getIn(['location']) || !data.getIn(['location', field])) {
            errors[field] = 'Required'
        } else if (!data.getIn(['location']) || (data.getIn(['location', field]) as string).length > MAX_LENGTH_MAPPING[field]) {
            errors[field] = `Maximum ${MAX_LENGTH_MAPPING[field]} characters`
        }
    })

    return errors
}

const validate = (data: Map<string, string>): {} => {
    const errors: { [key: string]: any } = {}
    ;['name', 'contactName', 'companyName', 'phoneNumber'].forEach(field => {
        if (!data.get(field)) {
            errors[field] = 'Required'
        }
    })

    errors.location = locationValidation(data)
    return errors
}

interface Properties {
    address?: any
    stateOptions?: any[]
}

interface FormProps extends Properties, WithNamespaces, WithStyles, InjectedFormProps<{}, {}> {}

const form = (props: FormProps) => {
    const { handleSubmit, t, classes, stateOptions } = props as any
    const [states, setStates] = useState(stateOptions || [])
    const selectStateRef = useRef<any>(null)
    const renderFormFilterAutoComplete = props => {
        return <FormAutocompleteV2 className={classes.root} setRef={selectStateRef} {...props} />
    }

    useEffect(() => {
        setStates(stateOptions || [])
    }, [stateOptions])
    const onChangesCountry = (country: string) => {
        if (states && states?.length && states?.length > 0) {
            if (selectStateRef && selectStateRef?.current) {
                const button = selectStateRef?.current?.querySelector('button')
                button.click()
            }
        }
        if (country) {
            const selectedCountry = Countries?.find(x => x.name == country)
            if (selectedCountry && selectedCountry?.states) setStates(selectedCountry?.states as any)
        } else {
            setStates([])
        }
    }
    const createStatesOptions = options => {
        if (!options) return []
    
        return options.map(value => ({
            value: value.state_code ? value.state_code : '',
            label: value.name,
        }))
    }
    
    const createCountriesOptions = options => {
        if (!options) return []
    
        return options?.map(value => ({
            value: value?.name ? value?.name : '',
            label: value?.name,
        }))
    }
    return (
        <form onSubmit={handleSubmit} className={classes.form}>
            <Grid container direction="row" spacing={2}>
                <Grid item xs={12} md={12} className={classes.gridItem}>
                    <FormSection name="location">
                        <Grid item xs={12} md={12} className={classes.gridItem}>
                            <Field name="line" component={FormTextField} type="text" label={`${t('address-form-line')}*`} fullWidth className={classes.formInput} />
                        </Grid>
                        <Grid item xs={12} md={12} className={classes.gridItem}>
                            <Field name="line2" component={FormTextField} type="text" label={t('address-form-line2')} fullWidth className={classes.formInput} />
                        </Grid>
                        <Grid item xs={12} md={12} className={classes.gridItem}>
                            <Field
                                name="country"
                                label={`${t('address-form-country')}*`}
                                options={createCountriesOptions(Countries)}
                                component={FormAutocompleteV2}
                                t={t}
                                onChange={(e: any) => {
                                    onChangesCountry(e?.value)
                                }}
                                fullWidth
                                format={value => {
                                    if (!value) {
                                        return []
                                    }
                                    if (!!value.toJS) {
                                        return value.toJS()
                                    }
                                    return value
                                }}
                                className={classes.formInput}
                            />
                            {/* <Field name="country" component={FormTextField} type="text" label={`${t('address-form-country')}*`} fullWidth /> */}
                        </Grid>
                        <Grid item container direction="row" spacing={2} style={{ marginTop: 2 }}>
                            <Grid item xs={12} md={4} className={classes.gridItem}>
                                <Field name="city" component={FormTextField} type="text" label={`${t('address-form-city')}*`} fullWidth className={classes.formInput} />
                            </Grid>
                            <Grid item xs={12} md={4} className={classes.gridItem}>
                                <Field
                                    label={`${t('address-form-state')}*`}
                                    placeholder={t('address-form-state')}
                                    name="state"
                                    options={createStatesOptions(states)}
                                    component={renderFormFilterAutoComplete}
                                    fullWidth
                                    t={t}
                                    format={value => {
                                        if (!value) {
                                            return []
                                        }
                                        if (!!value.toJS) {
                                            return value.toJS()
                                        }
                                        return value
                                    }}
                                    className={classes.formInput}
                                />
                            </Grid>
                            <Grid item xs={12} md={4} className={classes.gridItem}>
                                <Field name="zipCode" component={FormTextField} type="text" label={`${t('address-form-zipCode')}*`} fullWidth className={classes.formInput} />
                            </Grid>
                        </Grid>
                    </FormSection>
                </Grid>
            </Grid>
        </form>
    )
}

const translatedForm = withNamespaces()(withStyles(styles)(form))
export const AddressFormV2 = reduxForm<{}, Properties>({
    form: AddressFormName,
    validate,
    onSubmitFail: jumpToFirstError,
    enableReinitialize: true,
})(translatedForm)
