import React from 'react'
import { WithNamespaces, withNamespaces } from 'react-i18next'
import { connect } from 'react-redux'
import { PRTIconWhite } from '@syncfab/machine'
import { WithStyles, withStyles, Paper, Typography, Grid, Divider, Card, CardHeader, CardContent, Button } from '@material-ui/core'
import { Dispatch } from 'redux'
import { CenteredAlignedContainer } from '../../../layout/Container'
import { State } from '../../../application/Store'
import { RouteComponentProps } from 'react-router'

import QRCode from 'qrcode.react'
import Chip from '@material-ui/core/Chip'
import { GET_PART_BY_TRACKID } from '../../../components/order/Actions'
import { Link } from 'react-router-dom'
import ArrowBack from '@material-ui/icons/ArrowBack'
import { SAVE_PREVIOUSURL } from '../../../components/user/Actions'
import { EnvironmentVariable, Environment } from '@syncfab/machine'

const styles = theme => ({
    paper: {
        width: '90%',
        minWidth: 300,
        padding: theme.spacing(4),
        marginTop: theme.spacing(4),
        marginBottom: theme.spacing(4),
    },
    paper2: {
        width: '80%',
        minWidth: 300,
        padding: theme.spacing(4),
        marginTop: theme.spacing(4),
        marginBottom: theme.spacing(4),
    },
    gridPadding: {
        padding: `${theme.spacing(2)}px ${theme.spacing(20)}px`,
    },
    balanceContainer: {
        border: `1px solid ${theme.palette.secondary.main}`,
        borderRadius: 15,
        margin: `${theme.spacing(2)}px ${theme.spacing(1)}px`,
        padding: theme.spacing(2),
    },
    mfgIcon: {
        width: 75,
        height: 75,
    },
    balance: {
        color: theme.palette.secondary.main,
        fontSize: 34,
        marginLeft: theme.spacing(1),
    },
    margin: {
        margin: theme.spacing(1),
    },
    paragraph: {
        color: '#f55c47',
    },
    title: {
        marginBottom: theme.spacing(0),
        paddingBottom: theme.spacing(0),
    },
    pageTitle: {
        marginBottom: '10px',
        paddingBottom: '0px',
        color: '#40B4BF',
    },
    root: {
        backgroundColor: theme.palette.common.white,
        marginTop: theme.spacing(2),
        padding: theme.spacing(2),
        color: theme.palette.grey['700'],
    },
    divider: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
    },
    detailcard: {
        width: '-webkit-fill-available',
        height: 'fit-content',
        margin: `${theme.spacing(3)}px 12px`,
        backgroundColor: theme.palette.common.white,
        //color: theme.palette.common.black,
        marginLeft: '0px',
        marginRight: '24px',
    },
    qrcode: {
        backgroundColor: theme.palette.grey['300'],
    },
    card: {
        width: '100%',
        margin: `${theme.spacing(3)}px 12px`,
        backgroundColor: theme.palette.common.white,
        //color: theme.palette.common.black,
    },
    cardheader: {
        backgroundColor: theme.palette.grey['300'],
    },
    PartIcon: {
        display: 'block',
        marginLeft: 'auto',
        marginRight: 'auto',
        height: 'fit-content',
        width: 'fit-content',
        padding: theme.spacing(3),
    },
    leftBlock: {
        marginLeft: '0px',
        marginRight: '24px',
    },
    '@media (max-width:960px)': {
        detailcard: {
            marginLeft: '0px',
            marginRight: '0px',
        },
        hiddenInMobile: {
            display: 'none',
        },
        pageTitle: {
            display: 'none',
        },
        headerSlash: {
            display: 'none',
        },
        trackID: {
            display: 'inline-block',
            width: '80%',
            overflow: 'hidden',
            'white-space': 'nowrap',
            'text-overflow': 'ellipsis',
        },
    },
    header: {
        marginBottom: theme.spacing(1),
    },

    headerSlash: {
        marginRight: theme.spacing(1),
        marginLeft: theme.spacing(1),
    },
    arrow: {
        color: theme.palette.common.white,
        marginRight: theme.spacing(1),
    },
})

interface TrackProps extends WithNamespaces, WithStyles, RouteComponentProps<{ id: string }> {
    isLoading: boolean
    history: any
    trackID: string
    part: any
    partDetails: any
    getPartByID: (trackID: string) => void
    previousUrl?: string
    savePreviousUrl?: (url: string) => any
}

interface TrackState {
    dappURL?: string
}

class TrackDetails extends React.Component<TrackProps, TrackState> {
    constructor(props: TrackProps) {
        super(props)
        console.log(this.props.previousUrl)
        if (this.props.previousUrl && this.props.previousUrl != '') {
            this.props.savePreviousUrl('')
        }

        const environment = new Environment()
        environment.load()
        this.state = {
            dappURL: environment.get(EnvironmentVariable.DAPP_URL),
        }
        this.props.getPartByID(this.props.match.params.id)
    }

    render() {
        const { classes } = this.props
        const { dappURL } = this.state
        let partDetails = null
        if (this.props.partDetails && this.props.partDetails.length != 0) {
            partDetails = this.props.partDetails[0]
        }
        return (
            <CenteredAlignedContainer>
                <Paper className={classes.paper} elevation={1}>
                    <Grid container item xs={12}>
                        <Grid item xs={10}>
                            {/* <DetailsHeader url="/track" id={this.props.match.params.id} title={'Locate Part Record'} /> */}
                            <Grid container spacing={3} alignItems="center" className={classes.header}>
                                <Link to="/track">
                                    <ArrowBack className={classes.arrow} />
                                </Link>
                                <Typography gutterBottom variant="h2" className={classes.pageTitle}>
                                    {'Locate Part Record'}
                                </Typography>
                                <Typography gutterBottom variant="h2" className={classes.headerSlash}>
                                    {'/'}
                                </Typography>
                                <Typography gutterBottom variant="h2" className={classes.trackID}>
                                    {this.props.match.params.id}
                                </Typography>
                            </Grid>
                        </Grid>
                        <Grid item xs={2} style={{ textAlign: 'right' }}>
                            <Link to="/track">
                                <Button style={{ marginTop: '-15px' }} type="button" color="primary" variant="outlined" className={classes.button}>
                                    Search
                                </Button>
                            </Link>
                        </Grid>
                    </Grid>
                    <Divider className={classes.divider} />
                    {partDetails && (
                        <Grid container>
                            <Grid container item sm={12} md={4}>
                                <Card className={classes.detailcard}>
                                    <div className={classes.qrcode}>
                                        <div className={classes.PartIcon}>
                                            <QRCode value={`${dappURL}/track/${this.props.match.params.id}`} />
                                        </div>
                                    </div>
                                    <Grid className={classes.card}>
                                        <Typography color="textPrimary" variant="h6">
                                            Trackable ID Number
                                        </Typography>
                                        <Typography color="textPrimary" variant="body1">
                                            {this.props.match.params.id}
                                        </Typography>
                                    </Grid>
                                </Card>
                            </Grid>
                            <Grid container item sm={12} md={8} direction="row">
                                {partDetails.Record.parts.map((item, i) => (
                                    <Grid key={i} container item sm={12} md={12} direction="row">
                                        <Grid item xs={12}>
                                            <Card className={classes.card} style={{ marginLeft: '0px', marginBottom: '0px' }}>
                                                {i == 0 && (
                                                    <CardHeader
                                                        className={classes.cardheader}
                                                        title={
                                                            <Typography color="textPrimary" variant="h3">
                                                                Blockchain Part Record
                                                            </Typography>
                                                        }
                                                    />
                                                )}
                                                <CardContent>
                                                    <Grid container item xs={12} className={classes.container}>
                                                        <Grid item xs={12} sm={5} className={classes.hiddenInMobile}>
                                                            <Typography color="textPrimary" variant="h6">
                                                                Trackable ID
                                                            </Typography>
                                                        </Grid>
                                                        <Grid item sm={12} md={7}>
                                                            <Typography color="textPrimary" variant="body1">
                                                                {partDetails.Record.trackableID}
                                                            </Typography>
                                                        </Grid>
                                                    </Grid>
                                                    <Grid container item xs={12} className={classes.container}>
                                                        <Grid item sm={12} md={5} className={classes.hiddenInMobile}>
                                                            <Typography color="textPrimary" variant="h6">
                                                                Block
                                                            </Typography>
                                                        </Grid>
                                                        <Grid item sm={12} md={7}>
                                                            <Typography color="textPrimary" variant="body1">
                                                                {partDetails.Record.blockNumber}
                                                            </Typography>
                                                        </Grid>
                                                    </Grid>
                                                    <Grid container item xs={12} className={classes.container}>
                                                        <Grid item sm={12} md={5} className={classes.hiddenInMobile}>
                                                            <Typography color="textPrimary" variant="h6">
                                                                Transaction Hash
                                                            </Typography>
                                                        </Grid>
                                                        <Grid item sm={12} md={7}>
                                                            <Typography color="textPrimary" variant="body1">
                                                                {/* <Chip icon={<PRTIconWhite fill="#FFFFFF" />} color="secondary" label={`${partDetails.Record.transactionHash.substr(0, 20)}...`} /> */}
                                                                {partDetails.Record.transactionHash}
                                                            </Typography>
                                                        </Grid>
                                                    </Grid>
                                                    <Grid container item xs={12} className={classes.container}>
                                                        <Grid item sm={12} md={5} className={classes.hiddenInMobile}>
                                                            <Typography color="textPrimary" variant="h6">
                                                                MFGPRT Token ID
                                                            </Typography>
                                                        </Grid>
                                                        <Grid item sm={12} md={7}>
                                                            <Typography color="textPrimary" variant="body1">
                                                                <Chip icon={<PRTIconWhite fill="#FFFFFF" />} color="secondary" label={`${item.tokenId}`} />
                                                            </Typography>
                                                        </Grid>
                                                    </Grid>
                                                    <Grid container item xs={12} className={classes.container}>
                                                        <Grid item sm={12} md={5} className={classes.hiddenInMobile}>
                                                            <Typography color="textPrimary" variant="h6">
                                                                Custom Attribute - Title
                                                            </Typography>
                                                        </Grid>
                                                        <Grid item sm={12} md={7}>
                                                            <Typography color="textPrimary" variant="body1">
                                                                {item.customAttribute1}
                                                            </Typography>
                                                        </Grid>
                                                    </Grid>
                                                    <Grid container item xs={12} className={classes.container}>
                                                        <Grid item sm={12} md={5} className={classes.hiddenInMobile}>
                                                            <Typography color="textPrimary" variant="h6">
                                                                Custom Attribute - Description
                                                            </Typography>
                                                        </Grid>
                                                        <Grid item sm={12} md={7}>
                                                            <Typography color="textPrimary" variant="body1">
                                                                {item.customAttribute2}
                                                            </Typography>
                                                        </Grid>
                                                    </Grid>
                                                    <Grid container item xs={12} className={classes.container}>
                                                        <Grid item sm={12} md={5} className={classes.hiddenInMobile}>
                                                            <Typography color="textPrimary" variant="h6">
                                                                Part Number
                                                            </Typography>
                                                        </Grid>
                                                        <Grid item sm={12} md={7}>
                                                            <Typography color="textPrimary" variant="body1">
                                                                {item.partNumber}
                                                            </Typography>
                                                        </Grid>
                                                    </Grid>
                                                    <Grid container item xs={12} className={classes.container}>
                                                        <Grid item sm={12} md={5} className={classes.hiddenInMobile}>
                                                            <Typography color="textPrimary" variant="h6">
                                                                Syncfab Part ID
                                                            </Typography>
                                                        </Grid>
                                                        <Grid item sm={12} md={7}>
                                                            <Typography color="textPrimary" variant="body1">
                                                                {item.syncfabPartId}
                                                            </Typography>
                                                        </Grid>
                                                    </Grid>
                                                    <Grid container item xs={12} className={classes.container}>
                                                        <Grid item sm={12} md={5} className={classes.hiddenInMobile}>
                                                            <Typography color="textPrimary" variant="h6">
                                                                Revision
                                                            </Typography>
                                                        </Grid>
                                                        <Grid item sm={12} md={7}>
                                                            <Typography color="textPrimary" variant="body1">
                                                                {item.revision}
                                                            </Typography>
                                                        </Grid>
                                                    </Grid>
                                                    <Grid container item xs={12} className={classes.container}>
                                                        <Grid item sm={12} md={5} className={classes.hiddenInMobile}>
                                                            <Typography color="textPrimary" variant="h6">
                                                                Revision Date
                                                            </Typography>
                                                        </Grid>
                                                        <Grid item sm={12} md={7}>
                                                            <Typography color="textPrimary" variant="body1">
                                                                {new Date(item.revisionDate).toLocaleDateString()}
                                                            </Typography>
                                                        </Grid>
                                                    </Grid>
                                                    <Grid container item xs={12} className={classes.container}>
                                                        <Grid item sm={12} md={5} className={classes.hiddenInMobile}>
                                                            <Typography color="textPrimary" variant="h6">
                                                                Material
                                                            </Typography>
                                                        </Grid>
                                                        <Grid item sm={12} md={7}>
                                                            <Typography color="textPrimary" variant="body1">
                                                                {item.material}
                                                            </Typography>
                                                        </Grid>
                                                    </Grid>
                                                    <Grid container item xs={12} className={classes.container}>
                                                        <Grid item sm={12} md={5} className={classes.hiddenInMobile}>
                                                            <Typography color="textPrimary" variant="h6">
                                                                Finish
                                                            </Typography>
                                                        </Grid>
                                                        <Grid item sm={12} md={7}>
                                                            <Typography color="textPrimary" variant="body1">
                                                                {item.finishes}
                                                            </Typography>
                                                        </Grid>
                                                    </Grid>
                                                    <Grid container item xs={12} className={classes.container}>
                                                        <Grid item sm={12} md={5} className={classes.hiddenInMobile}>
                                                            <Typography color="textPrimary" variant="h6">
                                                                2D File Name
                                                            </Typography>
                                                        </Grid>
                                                        <Grid item sm={12} md={7}>
                                                            <Typography color="textPrimary" variant="body1">
                                                                {item.twoDModel}
                                                            </Typography>
                                                        </Grid>
                                                    </Grid>
                                                    <Grid container item xs={12} className={classes.container}>
                                                        <Grid item sm={12} md={5} className={classes.hiddenInMobile}>
                                                            <Typography color="textPrimary" variant="h6">
                                                                3D File Name
                                                            </Typography>
                                                        </Grid>
                                                        <Grid item sm={12} md={7}>
                                                            <Typography color="textPrimary" variant="body1">
                                                                {item.threeDModel}
                                                            </Typography>
                                                        </Grid>
                                                    </Grid>
                                                    <Grid container item xs={12} className={classes.container}>
                                                        <Grid item sm={12} md={5} className={classes.hiddenInMobile}>
                                                            <Typography color="textPrimary" variant="h6">
                                                                PO Name
                                                            </Typography>
                                                        </Grid>
                                                        <Grid item sm={12} md={7}>
                                                            <Typography color="textPrimary" variant="body1">
                                                                {partDetails.Record.purchaseOrderName}
                                                            </Typography>
                                                        </Grid>
                                                    </Grid>
                                                    <Grid container item xs={12} className={classes.container}>
                                                        <Grid item sm={12} md={5} className={classes.hiddenInMobile}>
                                                            <Typography color="textPrimary" variant="h6">
                                                                PO Creation Date
                                                            </Typography>
                                                        </Grid>
                                                        <Grid item sm={12} md={7}>
                                                            <Typography color="textPrimary" variant="body1">
                                                                {new Date(partDetails.Record.purchaseorderCreationDate).toLocaleDateString()}
                                                            </Typography>
                                                        </Grid>
                                                    </Grid>
                                                    <Grid container item xs={12} className={classes.container}>
                                                        <Grid item sm={12} md={5} className={classes.hiddenInMobile}>
                                                            <Typography color="textPrimary" variant="h6">
                                                                Syncfab Order ID
                                                            </Typography>
                                                        </Grid>
                                                        <Grid item sm={12} md={7}>
                                                            <Typography color="textPrimary" variant="body1">
                                                                {partDetails.Record.syncfabOrderId}
                                                            </Typography>
                                                        </Grid>
                                                    </Grid>
                                                    <Grid container item xs={12} className={classes.container}>
                                                        <Grid item sm={12} md={5} className={classes.hiddenInMobile}>
                                                            <Typography color="textPrimary" variant="h6">
                                                                Supplier ID
                                                            </Typography>
                                                        </Grid>
                                                        <Grid item sm={12} md={7}>
                                                            <Typography color="textPrimary" variant="body1">
                                                                {partDetails.Record.supplierId}
                                                            </Typography>
                                                        </Grid>
                                                    </Grid>
                                                    <Grid container item xs={12} className={classes.container}>
                                                        <Grid item sm={12} md={5} className={classes.hiddenInMobile}>
                                                            <Typography color="textPrimary" variant="h6">
                                                                Quantity
                                                            </Typography>
                                                        </Grid>
                                                        <Grid item sm={12} md={7}>
                                                            <Typography color="textPrimary" variant="body1">
                                                                {item.quantity}
                                                            </Typography>
                                                        </Grid>
                                                    </Grid>
                                                    <Grid container item xs={12} className={classes.container}>
                                                        <Grid item sm={12} md={5} className={classes.hiddenInMobile}>
                                                            <Typography color="textPrimary" variant="h6">
                                                                Certification
                                                            </Typography>
                                                        </Grid>
                                                        <Grid item sm={12} md={7}>
                                                            <Typography color="textPrimary" variant="body1">
                                                                {partDetails.Record.certification}
                                                            </Typography>
                                                        </Grid>
                                                    </Grid>
                                                    <Grid container item xs={12} className={classes.container}>
                                                        <Grid item sm={12} md={5} className={classes.hiddenInMobile}>
                                                            <Typography color="textPrimary" variant="h6">
                                                                Production End
                                                            </Typography>
                                                        </Grid>
                                                        <Grid item sm={12} md={7}>
                                                            <Typography color="textPrimary" variant="body1">
                                                                {new Date(partDetails.Record.productionEndDate).toLocaleDateString()}
                                                            </Typography>
                                                        </Grid>
                                                    </Grid>

                                                    <Grid container item xs={12} className={classes.container}>
                                                        <Grid item sm={12} md={5} className={classes.hiddenInMobile}>
                                                            <Typography color="textPrimary" variant="h6">
                                                                QC Pass Date
                                                            </Typography>
                                                        </Grid>
                                                        <Grid item sm={12} md={7}>
                                                            <Typography color="textPrimary" variant="body1">
                                                                {new Date(partDetails.Record.qcPassDate).toLocaleDateString()}
                                                            </Typography>
                                                        </Grid>
                                                    </Grid>
                                                </CardContent>
                                            </Card>
                                        </Grid>
                                    </Grid>
                                ))}
                            </Grid>
                        </Grid>
                    )}

                    {!partDetails && (
                        <Grid container>
                            <Card className={classes.detailcard} style={{ marginLeft: '0px', marginRight: '24px', textAlign: 'center' }}>
                                <Grid className={classes.card}>
                                    <Typography color="textPrimary" variant="h6">
                                        No record found for this ID
                                    </Typography>
                                </Grid>
                            </Card>
                        </Grid>
                    )}
                    {/* <QRCode value="hj" />  */}
                </Paper>
            </CenteredAlignedContainer>
        )
    }
}

const TrackDetailsWithTranslations = withStyles(styles)(withNamespaces()(TrackDetails))

const mapStateToProps = (state: State) => {
    return {
        isLoading: state.transaction.isLoading,
        partDetails: state.order.partDetails,
        previousUrl: state.user.previousUrl,
    }
}

const mapDispatchToProps = (dispatch: Dispatch) => {
    return {
        //push: (url: string) => dispatch(PUSH(url)),
        savePreviousUrl: (url: string) => dispatch(SAVE_PREVIOUSURL.actions.TRIGGER(url)),
        getPartByID: (id: string) => dispatch(GET_PART_BY_TRACKID.actions.TRIGGER({ id })),
    }
}

export const TrackDetailsView = connect(
    mapStateToProps,
    mapDispatchToProps
)(TrackDetailsWithTranslations)
