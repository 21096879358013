import React from 'react'
import { Helmet } from 'react-helmet'
import { Map } from 'immutable'
import { withNamespaces, WithNamespaces } from 'react-i18next'
import { Dispatch } from 'redux'
import { WithStyles } from '@material-ui/core/styles'
import { connect } from 'react-redux'
import Typography from '@material-ui/core/Typography'
import { Card, withStyles } from '@material-ui/core'
import { CenteredContainer } from '../../layout/Container'
import { UpdatePasswordForm } from './components/UpdatePasswordForm'
import { UPDATE_FORGOT_PASSWORD } from '../../components/user/Actions'

const styles = theme => ({
    card: {
        width: '40%',
        margin: 'auto',
        padding: theme.spacing(2),
    },
    '@media (max-width:800px)': {
        card: {
            width: '85%',
        },
    },
    title: {
        color: theme.palette.common.white,
    },
})

interface UpdatePasswordProps extends WithNamespaces, WithStyles {
    updatePassword: (email: string, password: string, confirmPassword: string, token: string) => void
}

class UpdatePassowrdView extends React.Component<UpdatePasswordProps> {
    constructor(props: UpdatePasswordProps) {
        super(props)
        this.onSubmit = this.onSubmit.bind(this)
    }

    public render() {
        const { classes, t } = this.props
        return (
            <CenteredContainer>
                <Helmet>
                    <title>{`${t('update-password-header')}`}</title>
                </Helmet>
                <Card className={classes.card}>
                    <Typography variant="h2" gutterBottom className={classes.title}>
                        {`${t('update-password-title')}`}
                    </Typography>
                    <UpdatePasswordForm onSubmit={this.onSubmit as any} />
                </Card>
            </CenteredContainer>
        )
    }

    private onSubmit(values: Map<string, string>) {
        const { email, password, confirmPassword } = values.toJS() as any
        const token = new URLSearchParams(window.location.search.slice(1)).get('token') || ''
        this.props.updatePassword(email, password, confirmPassword, token)
    }
}

const mapDispatchToProps = (dispatch: Dispatch) => {
    return {
        updatePassword: (email: string, password: string, confirmPassword: string, token: string) => dispatch(UPDATE_FORGOT_PASSWORD.actions.TRIGGER({ email, password, confirmPassword, token })),
    }
}

const updatePasswordWithTranslations = withNamespaces()(withStyles(styles)(UpdatePassowrdView))

export default connect(
    null,
    mapDispatchToProps
)(updatePasswordWithTranslations)
