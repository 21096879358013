import _ from 'lodash-es';
import { SupplierVerificationObject, StepType } from '@syncfab/machine';
export const SupplierFormSteps = [
    'Invite Team Members',
    'Add Capabilities and Preferences',
    'Answer QA Questionairre',
    'Verify Certifications'
];
export const SupplierStepDescriptions = {
    1: 'Step 1: Every Syncfab Verified Supplier requires more than one point of contact for their facility. Please add one or more team members below.',
    2: 'Step 2: Add all relevent capabilities and preferences your facility has. The more you specify the better we will be able to match the RFQs that fit you.',
    3: 'Step 3: Please answer the yes or no questions below to allow us to better understand how your internal processes work.',
    4: 'Step 4: Add all certifications that apply and provide us with appropriate supporting documentation',
};
export const NonStepKeys: StepType = [
    '_id',
]
export const StepOneKeys: StepType = [
    ...NonStepKeys,
    'teams',
]
export const StepTwoKeys: StepType = [
    ...StepOneKeys,
    'affiliateOrganizations',
    'businessClassification',
    'companyType',
    'finishes',
    'industry',
    'leadTime',
    'lotSize',
    'manufacturingProcesses',
    'materials',
    'productPreference',
];
export const StepThreeKeys: StepType = [
    ...StepTwoKeys,
    'rfqReview',
    'rfqDedicated',
    'rfqDedicatedUser',
    'procurementFlow',
    'procurementSub',
    'procurementQuantityReview',
    'procurementDedicated',
    'procurementDedicatedUser',
    'manufacturingInstructions',
    'manufacturingFOD',
    'manufacturingCapabilitiesCut',
    'nonConformingProcess',
    'nonConformingCorrection',
    'nonConformingFlowDown',
    'inspectionCalibrationUtilized',
    'inspectionCalibratedPeriodically',
    'inspectionCalibratedUser',
    'inspectionCalibratedInHouse',
    'inspectionRecords',
    'inspectionPerson',
    'inspectionPersonUser',
    'materialCustomer',
    'materialTraceable',
    'materialReceipt',
    'materialStored',
    'materialRequirements'
]
export const StepFourKeys: StepType = [
    ...StepThreeKeys,
    'certifications',
    'cageCode',
    'documentIds',
    'documents',
]

//If a default value is needed, make it null for these keys
export const emptyArrayDefaultValues: StepType = [
    'affiliateOrganizations',
    'businessClassification',
    'finishes',
    'industry',
    'leadTime',
    'lotSize',
    'manufacturingProcesses',
    'materials',
    'productPreference',
    'inspectionCalibratedUser',
];

// If a default is needed, use an empty array for these keys (typically multi select AutoComplete)
export const nullDefaultValues: StepType = [
    // 'teams',
    'companyType',
    'rfqReview',
    'rfqDedicated',
    'rfqDedicatedUser',
    'procurementFlow',
    'procurementSub',
    'procurementQuantityReview',
    'procurementDedicated',
    'procurementDedicatedUser',
    'manufacturingInstructions',
    'manufacturingFOD',
    'manufacturingCapabilitiesCut',
    'nonConformingProcess',
    'nonConformingCorrection',
    'nonConformingFlowDown',
    'inspectionCalibrationUtilized',
    'inspectionCalibratedPeriodically',
    'inspectionCalibratedInHouse',
    'inspectionRecords',
    'inspectionPerson',
    'inspectionPersonUser',
    'materialCustomer',
    'materialTraceable',
    'materialReceipt',
    'materialStored',
    'materialRequirements',
    'cageCode',
    'documentIds',
]

export const getKeysBasedOnStep = step => {
    const mapping = {
        1: StepOneKeys,
        2: StepTwoKeys,
        3: StepThreeKeys,
        4: StepFourKeys,
    };
    return mapping[step];
}

export const getSchemaObjectFromStep = step => {
    return _.pick(SupplierVerificationObject, getKeysBasedOnStep(step));
}
export const getRoutesFromActiveStep = activeStep => {
    switch(activeStep) {
        case 1:
            return {
                next: '/supplier/verify/details',
                previous: '/quotes',
            }
        case 2:
            return {
                next: '/supplier/verify/qa',
                previous: '/supplier/verify/contacts',
            }
        case 3:
            return {
                next: '/supplier/verify/certifications',
                previous: '/supplier/verify/details',
            }
        case 4:
            return {
                next: '/quotes',
                previous: '/supplier/verify/qa',
            }
        default:
            return {
                next: null,
                previous: null,
            }
    }
}