import * as React from 'react'
import { Toolbar, Typography, Theme, withStyles, Grid, Button } from '@material-ui/core'
// import DeleteIcon from '@material-ui/icons/Delete'

interface IEnhancedTableToolbarProps {
    classes?: any
    selected?: number
    isTokenizedSelected?: boolean
    onSubmit: () => void
    onDeleteParts: () => void
    onRequestQuote: () => void
    onEditPart: () => void
}

class EnhancedTableToolbar extends React.Component<IEnhancedTableToolbarProps, {}> {
    public render(): JSX.Element {
        const { selected, classes, onDeleteParts, onEditPart } = this.props

        return (
            <Toolbar color="primary" className={classes.toolbar}>
                <Grid container justify="space-between" alignItems="center" className={classes.header}>
                    {selected > 0 ? (
                        <React.Fragment>
                            <Grid item>
                                <Typography color="inherit" variant="subtitle1">
                                    {selected} Selected
                                </Typography>
                            </Grid>

                            <Grid item>
                                <Button size="small" variant="text" className={classes.actions} onClick={onDeleteParts}>
                                    REMOVE
                                </Button>
                                {/* {!isTokenizedSelected && (
                                    <Tooltip title="Delete">
                                        <IconButton aria-label="Delete" className={classes.actions}>
                                            <DeleteIcon onClick={onDeleteParts} />
                                        </IconButton>
                                    </Tooltip>
                                )}
                                {!isTokenizedSelected && (
                                    <Button variant="outlined" size="small" className={classes.actions} onClick={onSubmit}>
                                        TOKENIZE PART(S)
                                    </Button>
                                )}
                                <Button variant="outlined" size="small" className={classes.actions} onClick={onRequestQuote}>
                                    REQUEST QUOTE
                                </Button> */}
                                {selected === 1 && (
                                    <Button variant="outlined" size="small" className={classes.actions} onClick={onEditPart}>
                                        EDIT
                                    </Button>
                                )}
                            </Grid>
                        </React.Fragment>
                    ) : (
                        ''
                    )}
                </Grid>
                {/* <div className={classes.spacer} />
                <div className={classes.actions}>
                    {selected > 0 ? (
                        <Tooltip title="Delete">
                            <IconButton aria-label="Delete" className={classes.actions}>
                                <DeleteIcon />
                            </IconButton>
                        </Tooltip>
                    ) : (
                        <Tooltip title="Filter list">
                            <IconButton aria-label="Filter list">
                                <FilterListIcon />
                            </IconButton>
                        </Tooltip>
                    )}
                </div> */}
            </Toolbar>
        )
    }
}

const toolbarStyles = (theme: Theme) => ({
    root: {
        paddingRight: theme.spacing(1),
    },
    highlight:
        theme.palette.type === 'light'
            ? {
                  color: theme.palette.secondary.main,
                  backgroundColor: `lighten(${theme.palette.secondary.light}, 0.85)`,
              }
            : {
                  color: theme.palette.text.primary,
                  backgroundColor: theme.palette.secondary.dark,
              },
    spacer: {
        flex: '1 1 100%',
    },
    actions: {
        //  color:theme.palette.grey[500]
        borderColor: theme.palette.common.white,
        color: theme.palette.common.white,
        margin: theme.spacing(1),
    },
    title: {
        flex: '0 0 auto',
    },
    toolbar: {
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.common.white,
        marginBottom: '-25px',
        borderRadius: '5px 5px 0 0',
    },
    link: {
        textDecoration: 'none',
    },
})

export default withStyles(toolbarStyles)(EnhancedTableToolbar as any)
