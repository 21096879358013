import React from 'react'
import { withStyles, WithStyles, Grid, Typography } from '@material-ui/core'
import { Link } from 'react-router-dom'
import ArrowBack from '@material-ui/icons/ArrowBack'

const styles = theme => ({
    header: {
        marginBottom: theme.spacing(1),
    },
    newHeaderDesign: {
        marginBottom: theme.spacing.unit,
        paddingLeft: theme.spacing.unit * 2,
    },
    title: {
        color: theme.palette.primary.main,
    },
    headerSlash: {
        marginRight: theme.spacing(1),
        marginLeft: theme.spacing(1),
    },
    arrow: {
        color: theme.palette.common.white,
        marginRight: theme.spacing(1),
    },
})

interface HeaderProps extends WithStyles {
    url: string
    title: string
    id: string
    newDesign?: boolean
}

const Header = ({ url, title, id, classes, newDesign }: HeaderProps) => {
    return (
        <Grid container spacing={3} alignItems="center" className={newDesign ? classes.newHeaderDesign : classes.header}>
            <Link to={url}>
                <ArrowBack className={classes.arrow} />
            </Link>
            <Typography gutterBottom variant="h2" className={classes.title}>
                {title}
            </Typography>
            <Typography gutterBottom variant="h2" className={classes.headerSlash}>
                {'/'}
            </Typography>
            <Typography gutterBottom variant="h2">
                {id}
            </Typography>
        </Grid>
    )
}

export const DetailsHeader = withStyles(styles)(Header)
