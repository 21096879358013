import {
  Box,
  Chip,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Theme,
  Typography,
} from '@material-ui/core';
import React from 'react';
import ReactQuill from 'react-quill-new';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import {
  CurrencyFormatter,
  PurchaseOrder,
  PurchaseOrderDetails,
  PurchaseOrderLineItem,
  PurchaseOrderLineItemStatus,
  useStandardStyles,
} from '@syncfab/machine';

const transformBackNToBreaks = (tx: string) => {
  const rep = tx.replace(new RegExp('\n', 'g'), '<br />');
  console.log(rep);
  return rep;
};
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    labelDataTable: {
      marginTop: '16px',
      marginBottom: '16px',
      backgroundColor: theme.palette.customComponent.innerTableBackgroundColor,
    },
    labelDataTableRow: {
      height: 'auto',
    },
    labelCell: {
      padding: '0px 0px 0px 0px',
      width: 120,
      // @ts-ignore
      fontWeight: theme.typography.fontWeightMedium,
      verticalAlign: 'top',
    },
    summaryHeaderCell: {
      // @ts-ignore
      fontWeight: theme.typography.fontWeightMedium,
    },
    summaryValueCell: {
      textAlign: 'right',
    },
    summarySpacerRow: {
      paddingTop: '16px',
    },
    summaryTableCell: {
      borderBottom: '0px',
      paddingLeft: '16px',
      paddingRight: '16px',
    },
    dataCell: {
      padding: '0px 0px 0px 8px',
    },
    headerRowWithBorder: {
      backgroundColor: theme.palette.customComponent.innerTableBackgroundColor,
      border: '1px solid #dbdbdb',
      whiteSpace: 'nowrap',
      // @ts-ignore
      fontWeight: theme.typography.fontWeightMedium,
      '& td': {
        border: '1px solid #dbdbdb',
      },
    },
    quillEditor: {
      '& .ql-container.ql-snow': { border: 'none' },
      '& .ql-editor': { padding: 0 },
    },
  })
);

const currencyFormatter = new Intl.NumberFormat('default', {
  style: 'currency',
  currency: 'USD',
});
const percentFormatter = new Intl.NumberFormat('default', {
  style: 'percent',
  maximumFractionDigits: 2,
});
const dateFormatter = new Intl.DateTimeFormat('default', {
  dateStyle: 'short',
  timeStyle: 'medium',
});

export const PurchaseOrderHeaderChips = ({ po }: { po: PurchaseOrder }) => {
  return (
    <>
      <Chip
        label={`${po.buyerCompany.companyId} - ${po.buyerCompany.companyName}`}
        style={{ marginRight: '4px' }}
      />
      {!!po.createdAt && (
        <Chip
          label={`Created on ${dateFormatter.format(new Date(po.createdAt))}`}
        />
      )}
    </>
  );
};

export const PurchaseOrderLinePartTable = ({
  lineItem,
}: {
  lineItem: PurchaseOrderLineItem;
}) => {
  const standardClasses = useStandardStyles();

  return (
    <Table style={{ backgroundColor: '#F3F3F3' }}>
      <TableBody>
        <TableRow className={standardClasses.innerTableDataRow}>
          <TableCell className={standardClasses.tableLeftCell}>
            <Typography className={standardClasses.label}>
              Part Number
            </Typography>
          </TableCell>
          <TableCell className={standardClasses.tableRightCell}>
            <Typography className={standardClasses.value}>
              {lineItem.part.number}
            </Typography>
          </TableCell>
        </TableRow>
        <TableRow className={standardClasses.innerTableDataRow}>
          <TableCell className={standardClasses.tableLeftCell}>
            <Typography variant="body2" className={standardClasses.label}>
              Revision
            </Typography>
          </TableCell>
          <TableCell className={standardClasses.tableRightCell}>
            <Typography className={standardClasses.value}>
              {lineItem.part.revision}
            </Typography>
          </TableCell>
        </TableRow>
        <TableRow className={standardClasses.innerTableDataRow}>
          <TableCell className={standardClasses.tableLeftCell}>
            <Typography className={standardClasses.label}>
              Description
            </Typography>
          </TableCell>
          <TableCell
            className={standardClasses.tableRightCell}
            style={{ whiteSpace: 'normal' }}
          >
            <Typography className={standardClasses.value}>
              {!!lineItem.part.name ? lineItem.part.name : '-'}
            </Typography>
          </TableCell>
        </TableRow>
        <TableRow className={standardClasses.innerTableDataRow}>
          <TableCell className={standardClasses.tableLeftCell}>
            <Typography className={standardClasses.label}>NSN</Typography>
          </TableCell>
          <TableCell className={standardClasses.tableRightCell}>
            <Typography className={standardClasses.value}>
              {!!lineItem.part.nationalStockNumber
                ? lineItem.part.nationalStockNumber
                : '-'}
            </Typography>
          </TableCell>
        </TableRow>
        {!!lineItem.serialNumber && lineItem.serialNumber !== '' && (
          <TableRow
            className={standardClasses.innerTableDataRow}
            style={{ whiteSpace: 'normal' }}
          >
            <TableCell>
              <Typography className={standardClasses.label}>
                Serial Number
              </Typography>
            </TableCell>
            <TableCell className={standardClasses.tableRightCell}>
              <Typography className={standardClasses.value}>
                {lineItem.serialNumber}
              </Typography>
            </TableCell>
          </TableRow>
        )}
      </TableBody>
    </Table>
  );
};

export const PurchaseOrderLineDetailsTable = ({
  lineItem,
}: {
  lineItem: PurchaseOrderLineItem;
}) => {
  const standardClasses = useStandardStyles();

  return (
    <Table style={{ backgroundColor: '#F3F3F3', width: '100%' }}>
      <TableBody>
        <TableRow
          className={standardClasses.innerTableDataRow}
          style={{ whiteSpace: 'normal' }}
        >
          <TableCell>
            <Typography className={standardClasses.label}>Supplier</Typography>
          </TableCell>
          <TableCell className={standardClasses.tableRightCell}>
            <Typography className={standardClasses.value}>
              {lineItem.selectedSupplier.supplierName}
            </Typography>
          </TableCell>
        </TableRow>
        <TableRow
          className={standardClasses.innerTableDataRow}
          style={{ whiteSpace: 'normal' }}
        >
          <TableCell>
            <Typography className={standardClasses.label}>
              Manufacturing Process
            </Typography>
          </TableCell>
          <TableCell className={standardClasses.tableRightCell}>
            <Typography className={standardClasses.value}>
              {lineItem.part.manufacturingProcesses.join(', ')}
            </Typography>
          </TableCell>
        </TableRow>
        <TableRow
          className={standardClasses.innerTableDataRow}
          style={{ whiteSpace: 'normal' }}
        >
          <TableCell style={{ verticalAlign: 'top' }}>
            <Typography className={standardClasses.label}>Material</Typography>
          </TableCell>
          <TableCell className={standardClasses.tableRightCell}>
            {lineItem.part.materials.map((item, index) => (
              <Typography className={standardClasses.value} key={index}>
                {`${item}${
                  lineItem.part.materialDetails?.[index]
                    ? ' - ' + lineItem.part.materialDetails[index]
                    : ''
                }`}
              </Typography>
            ))}
          </TableCell>
        </TableRow>
        <TableRow
          className={standardClasses.innerTableDataRow}
          style={{ whiteSpace: 'normal' }}
        >
          <TableCell style={{ verticalAlign: 'top' }}>
            <Typography className={standardClasses.label}>Finish</Typography>
          </TableCell>
          <TableCell className={standardClasses.tableRightCell}>
            {lineItem.part.finishes.map((item, index) => (
              <Typography className={standardClasses.value} key={index}>
                {`${item}${
                  lineItem.part.finishDetails?.[index]
                    ? ' - ' + lineItem.part.finishDetails[index]
                    : ''
                }`}
              </Typography>
            ))}
          </TableCell>
        </TableRow>
        <TableRow
          className={standardClasses.innerTableDataRow}
          style={{ whiteSpace: 'normal' }}
        >
          <TableCell>
            <Typography className={standardClasses.label}>
              Part Identification
            </Typography>
          </TableCell>
          <TableCell className={standardClasses.tableRightCell}>
            <Typography className={standardClasses.value}>
              {!!lineItem.partIdentification
                ? lineItem.partIdentification
                : '-'}
            </Typography>
          </TableCell>
        </TableRow>
        <TableRow
          className={standardClasses.innerTableDataRow}
          style={{ whiteSpace: 'normal' }}
        >
          <TableCell>
            <Typography className={standardClasses.label}>
              Exceptions
            </Typography>
          </TableCell>
          <TableCell className={standardClasses.tableRightCell}>
            <ReactQuill
              className={standardClasses.quillEditor}
              value={transformBackNToBreaks(
                !!lineItem.exceptions ? lineItem.exceptions : '-'
              )}
              readOnly
              modules={{ toolbar: false }}
            />
          </TableCell>
        </TableRow>
        <TableRow
          className={standardClasses.innerTableDataRow}
          style={{ whiteSpace: 'normal' }}
        >
          <TableCell>
            <Typography className={standardClasses.label}>Notes</Typography>
          </TableCell>
          <TableCell className={standardClasses.tableRightCell}>
            <ReactQuill
              className={standardClasses.quillEditor}
              value={transformBackNToBreaks(
                lineItem?.notes ? lineItem.notes : '-'
              )}
              readOnly
              modules={{ toolbar: false }}
            />
          </TableCell>
        </TableRow>
      </TableBody>
    </Table>
  );
};

export const PurchaseOrderLineSupplierTable = ({
  lineItem,
}: {
  lineItem: PurchaseOrderLineItem;
}) => {
  return (
    <>
      <Box style={{ whiteSpace: 'nowrap', marginTop: '16px' }}>
        {lineItem.selectedSupplier.supplierName}
      </Box>
      <Box style={{ marginBottom: '16px' }}>
        {lineItem.selectedSupplier.supplierId}
      </Box>
    </>
  );
};

export const PurchaseOrderLinePriceTable = ({
  struckWhenCanceled,
  lineItem,
  isSupplierView = false,
}: {
  struckWhenCanceled: boolean;
  lineItem: PurchaseOrderLineItem;
  isSupplierView?: boolean;
}) => {
  const classes = useStyles();

  const unitCost = isSupplierView
    ? lineItem.pricing?.supplierUnitCost
    : lineItem.pricing?.unitCost;
  const subtotal = (unitCost ?? 0) * (lineItem.pricing?.quantity ?? 0);

  return (
    <>
      <Table className={classes.labelDataTable}>
        <TableHead>
          <TableRow className={classes.headerRowWithBorder}>
            <TableCell>Qty</TableCell>
            <TableCell>Unit Price</TableCell>
            <TableCell>Total Price</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow className={classes.headerRowWithBorder}>
            <TableCell>{lineItem.pricing?.quantity ?? '--'}</TableCell>
            <TableCell>
              {unitCost ? currencyFormatter.format(unitCost) : '--'}
            </TableCell>
            <TableCell
              style={{
                textDecoration:
                  struckWhenCanceled && !!subtotal ? 'line-through' : 'inherit',
              }}
            >
              {subtotal ? currencyFormatter.format(subtotal) : '--'}
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
      {!!lineItem.additionalCharges && lineItem.additionalCharges.length > 0 && (
        <Table className={classes.labelDataTable}>
          <TableHead>
            <TableRow className={classes.headerRowWithBorder}>
              <TableCell>Additional Charges</TableCell>
              <TableCell>Total Price</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {lineItem.additionalCharges.map(charge => {
              const chargeAmount = isSupplierView
                ? charge.supplierChargeAmount
                : charge.totalChargeAmount;
              return (
                <TableRow
                  key={charge._id}
                  className={classes.headerRowWithBorder}
                >
                  <TableCell>{charge.chargeType}</TableCell>
                  <TableCell
                    style={{
                      textDecoration:
                        struckWhenCanceled && !!chargeAmount
                          ? 'line-through'
                          : 'inherit',
                    }}
                  >
                    {chargeAmount
                      ? currencyFormatter.format(chargeAmount)
                      : '--'}
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      )}
    </>
  );
};

export const PurchaseOrderBuyerSummary = ({
  po,
}: {
  po: PurchaseOrderDetails;
}) => {
  const classes = useStyles();

  const applicableLineItems = po.lineItems.filter(
    e => e.status !== PurchaseOrderLineItemStatus.CANCELED
  );

  const totalLineAmount = applicableLineItems.reduce(
    (accum, curr) => accum + (curr?.pricing?.subtotal ?? 0),
    0
  );
  const totalChargeAmount = applicableLineItems.reduce(
    (accum, curr) =>
      accum +
      (!!curr?.additionalCharges
        ? curr.additionalCharges.reduce(
            (accum, curr) => accum + curr.totalChargeAmount,
            0
          )
        : 0),
    0
  );
  const buyerTotal = totalLineAmount + totalChargeAmount;
  const totalSyncFabCost = applicableLineItems.reduce((accum, curr) => {
    return (
      accum +
      (curr?.pricing?.quantity ?? 0) * (curr?.pricing?.supplierUnitCost ?? 0) +
      (!!curr?.additionalCharges
        ? curr.additionalCharges.reduce(
            (accum, curr) => accum + curr.supplierChargeAmount,
            0
          )
        : 0)
    );
  }, 0);
  const grossMargin = buyerTotal - totalSyncFabCost;

  return (
    <Table className={classes.labelDataTable}>
      <TableBody>
        <TableRow>
          <TableCell colSpan={2} className={classes.summaryHeaderCell}>
            <Typography variant="h6">Total Summary</Typography>
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell
            className={`${classes.summaryHeaderCell} ${classes.summarySpacerRow}`}
          >
            Total Line Price
          </TableCell>
          <TableCell
            className={`${classes.summaryValueCell} ${classes.summarySpacerRow}`}
          >
            {currencyFormatter.format(totalLineAmount)}
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell
            colSpan={2}
            className={`${classes.summaryHeaderCell} ${classes.summarySpacerRow}`}
            style={{ paddingBottom: '8px' }}
          >
            Lot Charges
          </TableCell>
        </TableRow>
        {po.lineItems.flatMap(
          lineItem =>
            lineItem.status !== PurchaseOrderLineItemStatus.CANCELED &&
            lineItem?.additionalCharges?.map(charge => (
              <TableRow style={{ height: 'auto' }} key={charge._id}>
                <TableCell>{charge.chargeType}</TableCell>
                <TableCell className={classes.summaryValueCell}>
                  {currencyFormatter.format(charge.totalChargeAmount)}
                </TableCell>
              </TableRow>
            ))
        )}
        <TableRow>
          <TableCell
            className={`${classes.summaryHeaderCell} ${classes.summarySpacerRow}`}
          >
            Subtotal
          </TableCell>
          <TableCell className={classes.summaryValueCell}>
            {currencyFormatter.format(totalChargeAmount)}
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell
            className={`${classes.summaryHeaderCell} ${classes.summarySpacerRow}`}
            style={{ paddingBottom: '16px' }}
          >
            <Typography variant="h5">BUYER TOTAL</Typography>
          </TableCell>
          <TableCell
            className={`${classes.summaryValueCell} ${classes.summarySpacerRow}`}
            style={{ paddingBottom: '16px' }}
          >
            {currencyFormatter.format(buyerTotal)}
          </TableCell>
        </TableRow>
        <TableRow
          style={{ borderTop: '1px solid #dbdbdb', height: 'auto' }}
        ></TableRow>
        <TableRow style={{ height: 'auto' }}>
          <TableCell
            className={`${classes.summaryHeaderCell} ${classes.summarySpacerRow}`}
          >
            Gross Margin ($)
          </TableCell>
          <TableCell
            className={`${classes.summaryValueCell} ${classes.summarySpacerRow}`}
          >
            {currencyFormatter.format(grossMargin)}
          </TableCell>
        </TableRow>
        <TableRow style={{ height: 'auto' }}>
          <TableCell className={classes.summaryHeaderCell}>
            Gross Margin (%)
          </TableCell>
          <TableCell className={classes.summaryValueCell}>
            {percentFormatter.format(
              buyerTotal === 0 ? 0 : grossMargin / buyerTotal
            )}
          </TableCell>
        </TableRow>
        <TableRow style={{ height: 'auto' }}>
          <TableCell className={classes.summaryHeaderCell}>
            Markup (%)
          </TableCell>
          <TableCell className={classes.summaryValueCell}>
            {percentFormatter.format(
              totalSyncFabCost === 0 ? 0 : grossMargin / totalSyncFabCost
            )}
          </TableCell>
        </TableRow>
      </TableBody>
    </Table>
  );
};

export const getSupplierPurchaseOrderSummary = (
  lineItems: PurchaseOrderLineItem[]
) => {
  const totalLineAmount = lineItems.reduce(
    (accum, curr) =>
      accum + (curr?.pricing?.quantity * curr?.pricing?.supplierUnitCost ?? 0),
    0
  );
  const totalChargeAmount = lineItems.reduce(
    (accum, curr) =>
      accum +
      (!!curr?.additionalCharges
        ? curr.additionalCharges.reduce(
            (accum, curr) => accum + curr.supplierChargeAmount,
            0
          )
        : 0),
    0
  );
  const charges = lineItems.flatMap(lineItem =>
    lineItem?.additionalCharges?.map(charge => {
      return {
        chargeType: charge.chargeType,
        chargeAmount: charge.supplierChargeAmount,
      };
    })
  );

  return {
    totalLineAmount,
    totalChargeAmount,
    charges,
  };
};

export const getBuyerPurchaseOrderSummary = (
  lineItems: PurchaseOrderLineItem[]
) => {
  const applicableLineItems = lineItems.filter(
    e => e.status !== PurchaseOrderLineItemStatus.CANCELED
  );
  const totalLineAmount = applicableLineItems.reduce(
    (accum, curr) =>
      accum + (curr?.pricing?.quantity * curr?.pricing?.unitCost ?? 0),
    0
  );
  const totalChargeAmount = applicableLineItems.reduce(
    (accum, curr) =>
      accum +
      (!!curr?.additionalCharges
        ? curr.additionalCharges.reduce(
            (accum, curr) => accum + curr.totalChargeAmount,
            0
          )
        : 0),
    0
  );
  const charges = applicableLineItems.flatMap(lineItem =>
    lineItem?.additionalCharges?.map(charge => {
      return {
        chargeType: charge.chargeType,
        chargeAmount: charge.totalChargeAmount,
      };
    })
  );

  return {
    totalLineAmount,
    totalChargeAmount,
    charges,
  };
};

export const PurchaseOrderTotalSummary = (props: {
  poSummary: {
    charges: { chargeType: string; chargeAmount: number }[];
    totalLineAmount: number;
    totalChargeAmount: number;
  };
}) => {
  const classes = useStyles();

  return (
    <Table
      style={{
        paddingTop: '16px',
        paddingBottom: '16px',
        backgroundColor: '#364F68',
        fontSize: 14,
      }}
    >
      <TableBody>
        <TableRow>
          <TableCell colSpan={2} className={classes.summaryTableCell}>
            <Typography style={{ fontSize: 20, fontWeight: 'bold' }}>
              PO Total Summary
            </Typography>
          </TableCell>
        </TableRow>
        <TableRow style={{ paddingTop: '16px' }}>
          <TableCell className={classes.summaryTableCell}>
            <Typography style={{ fontWeight: 'bold' }}>
              Total Line Price
            </Typography>
          </TableCell>
          <TableCell
            className={classes.summaryTableCell}
            style={{ textAlign: 'right' }}
          >
            {CurrencyFormatter.format(props.poSummary.totalLineAmount)}
          </TableCell>
        </TableRow>
        <TableRow style={{ paddingTop: '16px' }}>
          <TableCell colSpan={2} className={classes.summaryTableCell}>
            <Typography style={{ fontWeight: 'bold' }}>Lot Charges</Typography>
          </TableCell>
        </TableRow>
        {props.poSummary.charges.map((charge, index) => (
          <TableRow key={index}>
            <TableCell className={classes.summaryTableCell}>
              {charge.chargeType}
            </TableCell>
            <TableCell
              className={classes.summaryTableCell}
              style={{ textAlign: 'right' }}
            >
              {CurrencyFormatter.format(charge.chargeAmount)}
            </TableCell>
          </TableRow>
        ))}
        <TableRow style={{ paddingTop: '16px' }}>
          <TableCell
            className={classes.summaryTableCell}
            style={{ fontSize: 18, fontWeight: 'bold' }}
          >
            TOTAL
          </TableCell>
          <TableCell
            className={classes.summaryTableCell}
            style={{ textAlign: 'right', fontSize: 18, fontWeight: 'bold' }}
          >
            {CurrencyFormatter.format(
              props.poSummary.totalLineAmount +
                props.poSummary.totalChargeAmount
            )}
          </TableCell>
        </TableRow>
      </TableBody>
    </Table>
  );
};
