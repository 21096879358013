import { call, takeLatest, getContext, put } from 'redux-saga/effects'
import { Gateway } from '../gateway'
import {
    GET_PARTS_BY_IDS,
    SAVE_PART,
    SavePartPayload,
    GET_PART_BY_ID,
    // GET_PARTS_FOR_DAPP,
    SET_PARTS_FOR_TOKENIZATION,
    TOKENIZE_PARTS,
    TOKENIZE_FAILED_PARTS,
    DELETE_PARTS_BY_IDS,
    SET_PART_FOR_EDIT,
    UPDATE_PART,
    EDIT_PART,
    FIND_PARTS_BY_IDS,
    GET_PART_HISTORY,
    GET_ETHERNET_LINK,
    GET_PARTS,
} from './Actions'
import { startSubmit, stopSubmit } from 'redux-form'
import { PartEditorFormName } from '../../view/parts/uploadpart/components/PartEditorForm'
import { RESET_DATA } from '../part/Actions'
import { SHOW_FEEDBACK, PUSH } from '@syncfab/machine'
import { ListMeta } from '../../layout/list/Meta'

//import { PartEditorFormName } from '../../view/part/editor/components/PartEditorForm'

function* save({ data: { part } }: { data: SavePartPayload }) {
    try {
        yield put(startSubmit(PartEditorFormName))
        const gateway: Gateway = yield getContext('gateway')
        const data = yield call(gateway.getPartAPI().save(part))
        yield put(SAVE_PART.actions.SUCCESSFULL(data.data))
        yield put(PUSH('/parts'))
        yield put(SHOW_FEEDBACK('feedback-part-created'))
        yield put(stopSubmit(PartEditorFormName))
    } catch ({ response: { status, data } }) {
        const handleError = yield getContext('handleError')
        yield call(handleError(status))
        yield put(SAVE_PART.actions.FAILED(data))
        // yield put(SHOW_FEEDBACK('feedback-part-created-failed'))
        yield put(stopSubmit(PartEditorFormName))
    }
}

function* update({ data: { part, partId } }: { data: SavePartPayload; partId: string }) {
    try {
        yield put(startSubmit(PartEditorFormName))
        const gateway: Gateway = yield getContext('gateway')
        const data = yield call(gateway.getPartAPI().update(partId, part))
        yield put(UPDATE_PART.actions.SUCCESSFULL(data.data))
        yield put(PUSH(`/parts/${partId}/${part?.company?.companyId}`))
        yield put(SHOW_FEEDBACK('feedback-part-updated'))
        yield put(stopSubmit(PartEditorFormName))
    } catch ({ response: { status, data } }) {
        const handleError = yield getContext('handleError')
        yield call(handleError(status))
        yield put(UPDATE_PART.actions.FAILED(data))
        // yield put(SHOW_FEEDBACK('feedback-part-created-failed'))
        yield put(stopSubmit(PartEditorFormName))
    }
}

function* tokenizeParts(inputdata: any) {
    try {
        const gateway: Gateway = yield getContext('gateway')
        const data = yield call(gateway.getPartAPI().tokenizepart(inputdata))
        yield put(TOKENIZE_PARTS.actions.SUCCESSFULL(data.data))
    } catch ({ response: { status, data } }) {
        const handleError = yield getContext('handleError')
        yield call(handleError(status))
        yield put(TOKENIZE_PARTS.actions.FAILED(data))
    }
}
function* tokenizeFailedParts(inputdata: any) {
    try {
        const gateway: Gateway = yield getContext('gateway')
        const data = yield call(gateway.getPartAPI().tokenizefailedpart(inputdata))
        yield put(TOKENIZE_PARTS.actions.SUCCESSFULL(data.data))
    } catch ({ response: { status, data } }) {
        const handleError = yield getContext('handleError')
        yield call(handleError(status))
        yield put(TOKENIZE_PARTS.actions.FAILED(data))
    }
}

function* findPartsDAPP() {
    try {
        const gateway: Gateway = yield getContext('gateway')
        const user = yield call(gateway.getUserAPI().getLoggedUser())

        const { data } = yield call(gateway.getPartAPI().findParts(user.data.companyId))

        yield put(GET_PARTS_BY_IDS.actions.SUCCESSFULL(data))
    } catch ({ response: { status, data } }) {
        const handleError = yield getContext('handleError')
        yield call(handleError(status))
        yield put(GET_PARTS_BY_IDS.actions.FAILED(data))
    }
}

function* getParts({ data: { meta } }: { data: { meta: ListMeta } }) {
    try {
        const gateway: Gateway = yield getContext('gateway')
        const { data } = yield call(gateway.getPartAPI().list(meta))
        yield put(GET_PARTS.actions.SUCCESSFULL(data))
    } catch ({ response: { status, data } }) {
        const handleError = yield getContext('handleError')
        yield call(handleError(status))
        yield put(GET_PARTS.actions.FAILED(data))
    }
}
function* findParts() {
    try {
        const gateway: Gateway = yield getContext('gateway')
        const user = yield call(gateway.getUserAPI().getLoggedUser())

        const data = yield call(gateway.getPartAPI().findParts(user.data.companyId))
        yield put(GET_PARTS_BY_IDS.actions.SUCCESSFULL(data))
    } catch ({ response: { status, data } }) {
        const handleError = yield getContext('handleError')
        yield call(handleError(status))
        yield put(GET_PARTS_BY_IDS.actions.FAILED(data))
    }
}

function* findPart(partid: any) {
    try {
        const gateway: Gateway = yield getContext('gateway')
        const user = yield call(gateway.getUserAPI().getLoggedUser())
        const { data } = yield call(gateway.getPartAPI().findPart(user.data.companyId, partid.data))
        yield put(GET_PART_BY_ID.actions.SUCCESSFULL(data))
    } catch ({ response: { status, data } }) {
        const handleError = yield getContext('handleError')
        yield call(handleError(status))
        yield put(GET_PART_BY_ID.actions.FAILED(data))
    }
}

function* findByIds({ data: { ids } }: { data: { ids: string[] } }) {
    try {
        const gateway: Gateway = yield getContext('gateway')
        const data = yield call(gateway.getPartAPI().findByIds(ids))
        yield put(FIND_PARTS_BY_IDS.actions.SUCCESSFULL(data.data.data))
    } catch ({ response: { status, data } }) {
        const handleError = yield getContext('handleError')
        yield call(handleError(status))
        yield put(FIND_PARTS_BY_IDS.actions.FAILED(data))
    }
}

function* deleteParts(ids: any) {
    try {
        const gateway: Gateway = yield getContext('gateway')
        const data = yield call(gateway.getPartAPI().deleteParts(ids.data))
        yield put(DELETE_PARTS_BY_IDS.actions.SUCCESSFULL(data))
        yield call(findPartsDAPP)
    } catch ({ response: { status, data } }) {
        const handleError = yield getContext('handleError')
        yield call(handleError(status))
        yield put(DELETE_PARTS_BY_IDS.actions.FAILED(data))
    }
}

function* editPart({ data: { id, request } }: { data: { id: string; request: string } }) {
    try {
        const gateway: Gateway = yield getContext('gateway')
        yield call(gateway.getPartAPI().editPart(id, request))

        yield put(EDIT_PART.actions.SUCCESSFULL())
        yield put(SHOW_FEEDBACK('feedback-part-edited'))
    } catch ({ response: { status, data } }) {
        const handleError = yield getContext('handleError')
        yield call(handleError(status))
        yield put(EDIT_PART.actions.FAILED(data))
        yield put(SHOW_FEEDBACK('feedback-support'))
    }
}

function* getPartHistory(partid: any) {
    try {
        const gateway: Gateway = yield getContext('gateway')

        const data = yield call(gateway.getPartAPI().getpartHistory(partid.data))
        yield put(GET_PART_HISTORY.actions.SUCCESSFULL(data.data))
    } catch ({ response: { status, data } }) {
        const handleError = yield getContext('handleError')
        yield call(handleError(status))
        yield put(GET_PART_HISTORY.actions.FAILED(data))
    }
}
function* getEthernetLink() {
    try {
        const gateway: Gateway = yield getContext('gateway')
        const data = yield call(gateway.getPartAPI().getEthernetLink())
        yield put(GET_ETHERNET_LINK.actions.SUCCESSFULL(data.data))
    } catch ({ response: { status, data } }) {
        const handleError = yield getContext('handleError')
        yield call(handleError(status))
        yield put(GET_ETHERNET_LINK.actions.FAILED(data))
    }
}

function* reset() {}

function* setPartsForTokenization(parts: any[]) {
    yield put(SET_PARTS_FOR_TOKENIZATION.actions.SUCCESSFULL(parts))
}

function* setPartForEdit(part: any) {
    yield put(SET_PART_FOR_EDIT.actions.SUCCESSFULL(part))
}
function* partSaga() {
    // Typings are not good enough. String should be valid but the types
    // do not show it
    yield takeLatest(SAVE_PART.types.TRIGGER as any, save)
    yield takeLatest(UPDATE_PART.types.TRIGGER as any, update)
    yield takeLatest(GET_PARTS_BY_IDS.types.TRIGGER as any, findParts)
    yield takeLatest(GET_PART_BY_ID.types.TRIGGER as any, findPart)
    yield takeLatest(GET_PARTS_BY_IDS.types.TRIGGER as any, findPartsDAPP)
    yield takeLatest(FIND_PARTS_BY_IDS.types.TRIGGER as any, findByIds)
    yield takeLatest(RESET_DATA.types.TRIGGER as any, reset)
    yield takeLatest(SET_PARTS_FOR_TOKENIZATION.types.TRIGGER as any, setPartsForTokenization)
    yield takeLatest(TOKENIZE_PARTS.types.TRIGGER as any, tokenizeParts)
    yield takeLatest(TOKENIZE_FAILED_PARTS.types.TRIGGER as any, tokenizeFailedParts)
    yield takeLatest(DELETE_PARTS_BY_IDS.types.TRIGGER as any, deleteParts)
    yield takeLatest(SET_PART_FOR_EDIT.types.TRIGGER as any, setPartForEdit)
    yield takeLatest(EDIT_PART.types.TRIGGER as any, editPart)
    yield takeLatest(GET_PART_HISTORY.types.TRIGGER as any, getPartHistory)
    yield takeLatest(GET_ETHERNET_LINK.types.TRIGGER as any, getEthernetLink)
    yield takeLatest(GET_PARTS.types.TRIGGER as any, getParts)
}

export default partSaga
