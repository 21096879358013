import React from 'react'
import SvgIcon from '@material-ui/core/SvgIcon'

export const OrderShippedIcon = props => {
    return (
        <SvgIcon {...props} viewBox="0 0 40 40" x="0px" y="0px" baseProfile="tiny" xmlSpace="preserve">
            <path
                fill="#FFFFFF"
                d="M22.9,14.8v-4.6c0-1.3-1-2.3-2.3-2.3l-3-0.3l-2.7-4c0,0-0.1-0.1-0.1-0.1C14.4,3,13.8,2.6,13,2.6H3.2
				C2,2.6,1,3.6,1,4.8v10c-0.5,0.1-1,0.6-1,1.3v1.6c0,0.7,0.6,1.3,1.3,1.3h1.8c0.4,1.4,1.7,2.5,3.3,2.5c1.6,0,2.9-1.1,3.3-2.5h4.8
				c0.4,1.4,1.7,2.5,3.3,2.5c1.6,0,2.9-1.1,3.3-2.5h1.6c0.7,0,1.3-0.6,1.3-1.3v-1.6C24,15.4,23.5,14.8,22.9,14.8L22.9,14.8z M6.4,19.7
				c-1,0-1.7-0.8-1.7-1.7s0.8-1.7,1.7-1.7c1,0,1.7,0.8,1.7,1.7C8.1,18.9,7.4,19.7,6.4,19.7z M17.8,19.7c-1,0-1.7-0.8-1.7-1.7
				s0.8-1.7,1.7-1.7c1,0,1.7,0.8,1.7,1.7C19.5,18.9,18.8,19.7,17.8,19.7z"
            />
        </SvgIcon>
    )
}
