import React from 'react'
import SvgIcon from '@material-ui/core/SvgIcon'

export const BuyerLoginIcon = props => {
    return (
        <SvgIcon {...props} viewBox="0 0 512 512" x="0px" y="0px" baseProfile="tiny" xmlSpace="preserve">
            <g fill={props.fill || '#46b4bf'}>
                <title>Distribution-Product-management-Business-export</title>
                <path d="M32,160H192a8,8,0,0,0,8-8V24a8,8,0,0,0-8-8H32a8,8,0,0,0-8,8V152A8,8,0,0,0,32,160ZM96,32h32V64H96ZM40,32H80V72a8,8,0,0,0,8,8h48a8,8,0,0,0,8-8V32h40V144H40Z" />
                <rect x="152" y="112" width="16" height="16" />
                <rect x="120" y="112" width="16" height="16" />
                <path d="M168,192V320a8,8,0,0,0,8,8H336a8,8,0,0,0,8-8V192a8,8,0,0,0-8-8H176A8,8,0,0,0,168,192Zm72,8h32v32H240Zm-56,0h40v40a8,8,0,0,0,8,8h48a8,8,0,0,0,8-8V200h40V312H184Z" />
                <rect x="296" y="280" width="16" height="16" />
                <rect x="264" y="280" width="16" height="16" />
                <path d="M488,16H328a8,8,0,0,0-8,8V152a8,8,0,0,0,8,8H488a8,8,0,0,0,8-8V24A8,8,0,0,0,488,16ZM392,32h32V64H392Zm88,112H336V32h40V72a8,8,0,0,0,8,8h48a8,8,0,0,0,8-8V32h40Z" />
                <rect x="448" y="112" width="16" height="16" />
                <rect x="416" y="112" width="16" height="16" />
                <path d="M192,352H32a8,8,0,0,0-8,8V488a8,8,0,0,0,8,8H192a8,8,0,0,0,8-8V360A8,8,0,0,0,192,352ZM96,368h32v32H96Zm88,112H40V368H80v40a8,8,0,0,0,8,8h48a8,8,0,0,0,8-8V368h40Z" />
                <rect x="152" y="448" width="16" height="16" />
                <rect x="120" y="448" width="16" height="16" />
                <path d="M488,352H328a8,8,0,0,0-8,8V488a8,8,0,0,0,8,8H488a8,8,0,0,0,8-8V360A8,8,0,0,0,488,352Zm-96,16h32v32H392Zm88,112H336V368h40v40a8,8,0,0,0,8,8h48a8,8,0,0,0,8-8V368h40Z" />
                <rect x="448" y="448" width="16" height="16" />
                <rect x="416" y="448" width="16" height="16" />
                <path d="M256,56a201.051,201.051,0,0,1,46.161,5.352l3.678-15.572a217.928,217.928,0,0,0-91.369-1.79l3.06,15.7A201.621,201.621,0,0,1,256,56Z" />
                <path d="M256,456a201.621,201.621,0,0,1-38.47-3.695l-3.06,15.7a217.928,217.928,0,0,0,91.369-1.79l-3.678-15.572A201.051,201.051,0,0,1,256,456Z" />
                <path d="M455.381,339.225a216.7,216.7,0,0,0,.061-166.3l-14.767,6.158a200.706,200.706,0,0,1-.056,153.976Z" />
                <path d="M256,88a168.345,168.345,0,0,1,45.823,6.323l4.354-15.4a185.341,185.341,0,0,0-91.988-2.154l3.622,15.585A168.921,168.921,0,0,1,256,88Z" />
                <path d="M256,424a168.921,168.921,0,0,1-38.189-4.358l-3.622,15.585a185.341,185.341,0,0,0,91.988-2.154l-4.354-15.4A168.345,168.345,0,0,1,256,424Z" />
                <path d="M419.933,339.641a184.345,184.345,0,0,0,0-167.282l-14.246,7.282a168.352,168.352,0,0,1,0,152.718Z" />
                <path d="M56.558,172.921a216.7,216.7,0,0,0,.061,166.3l14.762-6.17a200.706,200.706,0,0,1-.056-153.976Z" />
                <path d="M92.067,172.359a184.345,184.345,0,0,0,0,167.282l14.246-7.282a168.352,168.352,0,0,1,0-152.718Z" />
            </g>
        </SvgIcon>
    )
}
