import React, { FC } from "react";
import { Radio, TableCell } from "@material-ui/core";
import { Controller, useFormContext } from "react-hook-form";
import { ValueLabelOption } from "./ValueLabelOption";


export interface RHFRadioProps {
    options: ValueLabelOption[],
    name: string,
    styledClass: string,
    // label?: string, 
    // labelPlacement?: 'end' | 'bottom' | 'start' | 'top',
    // radioLabelPlacement?: 'end' | 'bottom' | 'start' | 'top',
}

export const RHFRadioTable: FC<RHFRadioProps> = ({ options, name, styledClass }: RHFRadioProps) => {

    const methods = useFormContext()

    return (
        <Controller
            name={name}
            control={methods.control}
            render={({ field }) => (
                <>
                {
                    options.map(op => {
                        return (
                            <TableCell key={`${name}${op.value}`} className={styledClass}>
                                <Radio
                                    checked={field.value === op.value}
                                    onChange={field.onChange}
                                    value={op.value}
                                    color='primary'
                                    size='small'
                                />
                            </TableCell>

                        )
                    })
                }
                </>
            )}
        />
    )
}