import {
    CurrencyFormatter,
    IntegerFormatter,
    PercentIntegerFormatter,
    RFQ,
    RFQLineItem
} from "@syncfab/machine"
import React, { FC } from "react";
import {
    Chip,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Theme,
    Typography
} from "@material-ui/core";
import { useStandardStyles } from "../../../styles/standardStyles";
import { createStyles, makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        cell: {
            verticalAlign: 'top',
            paddingTop: theme.spacing(2),
            paddingBottom: theme.spacing(2),
            paddingLeft: 5,
            paddingRight: 5,
            borderBottom: 'none'
        },
        tableRow: {
            height: 'auto !important',
        },
        quoteTableHeaderRow: {
            height: 'auto !important',
            '& th': {
                paddingTop: 0,
                paddingBottom: 0,
                borderBottom: 'none',
                fontSize: 14
            }
        },
        quoteTableDataRow: {
            height: 'auto !important',
            '&:nth-of-type(odd)': {
                backgroundColor: '#FFFFFF40',
            },
            // hide last border
            '&:last-child td, &:last-child th': {
                border: 0,
            },
            '& td': {
                paddingTop: 5,
                paddingBottom: 5,
                borderBottom: 'none',
                fontSize: 14
            }
        },
        tableLeftCell: {
            paddingTop: 0,
            paddingBottom: 0,
            paddingRight: 0,
            width: 120,
            verticalAlign: 'super',
            border: 'none'
        },
        tableRightCell: {
            padding: 0,
            border: 'none'
        },
        tableCellPadding: {
            paddingTop: 0,
            paddingBottom: 0,
        },
        value: {
            fontSize: '14px'
        },
        discountChip: {
            marginLeft: '16px',
            backgroundColor: '#73AA27',
            fontSize: 12,
            fontStyle: 'italic',
            color: '#FFFFFF'
        }
    })
)

interface BuyerRFQLineItemProps {
    rfq: RFQ
}

export const BuyerRFQLineItems: FC<BuyerRFQLineItemProps> = ({ rfq }) => {

    const standardClasses = useStandardStyles()
    const classes = useStyles()

    return (
        <Paper className={standardClasses.paper}>
            <Typography style={{ padding: '16px', fontSize: '20px', fontWeight: 'bold' }}>
                Line Items
            </Typography>
            <TableContainer>
                <Table style={{ backgroundColor: '#364F68' }}>
                    <TableBody>
                        {rfq.lineItems?.map((lineItem: RFQLineItem, index: number) => {
                            return (
                                <TableRow style={{
                                    border: '1px solid #FFFFFF40',
                                    borderRight: 'none',
                                    borderLeft: 'none'
                                }} key={lineItem._id}>
                                    <TableCell style={{
                                        verticalAlign: 'middle',
                                        textAlign: 'center',
                                        borderRight: '1px solid #FFFFFF40',
                                        borderBottom: 'none'
                                    }}>{index + 1}</TableCell>
                                    <TableCell className={classes.cell}>
                                        <Table style={{ backgroundColor: '#364F68' }}>
                                            <TableBody>
                                                <TableRow className={classes.tableRow}>
                                                    <TableCell className={classes.tableLeftCell}>
                                                        <Typography variant="body2"
                                                                    className={standardClasses.label}>
                                                            Part Number
                                                        </Typography>
                                                    </TableCell>
                                                    <TableCell className={classes.tableRightCell}>
                                                        <Typography className={classes.value}>
                                                            {lineItem.part?.number ?? lineItem.partId}
                                                        </Typography>
                                                    </TableCell>
                                                </TableRow>
                                                <TableRow className={classes.tableRow}>
                                                    <TableCell className={classes.tableLeftCell}>
                                                        <Typography className={standardClasses.label}>
                                                            Revision
                                                        </Typography>
                                                    </TableCell>
                                                    <TableCell className={classes.tableRightCell}>
                                                        <Typography className={classes.value}>
                                                            {lineItem.part?.revision}
                                                        </Typography>
                                                    </TableCell>
                                                </TableRow>
                                                <TableRow className={classes.tableRow}>
                                                    <TableCell className={classes.tableLeftCell}>
                                                        <Typography className={standardClasses.label}>
                                                            Description
                                                        </Typography>
                                                    </TableCell>
                                                    <TableCell className={classes.tableRightCell}>
                                                        <Typography className={classes.value}>
                                                            {lineItem.part?.name}
                                                        </Typography>
                                                    </TableCell>
                                                </TableRow>
                                                <TableRow className={classes.tableRow}>
                                                    <TableCell className={classes.tableLeftCell}>
                                                        <Typography className={standardClasses.label}>
                                                            Exceptions
                                                        </Typography>
                                                    </TableCell>
                                                    <TableCell className={classes.tableRightCell}>
                                                        <Typography className={classes.value}>
                                                            {lineItem.exceptions}
                                                        </Typography>
                                                    </TableCell>
                                                </TableRow>
                                                <TableRow className={classes.tableRow}>
                                                    <TableCell className={classes.tableLeftCell}>
                                                        <Typography className={standardClasses.label}>
                                                            Documents
                                                        </Typography>
                                                    </TableCell>
                                                    <TableCell className={classes.tableRightCell}>
                                                        {lineItem.part?.documents?.map(doc => {
                                                            return (
                                                                <Typography key={doc._id}
                                                                            className={classes.value}>
                                                                    {doc.name}
                                                                </Typography>
                                                            )
                                                        })}
                                                    </TableCell>
                                                </TableRow>
                                            </TableBody>
                                        </Table>
                                    </TableCell>
                                    <LineItemPricing lineItem={lineItem} />
                                </TableRow>
                            )
                        })}
                    </TableBody>
                </Table>
            </TableContainer>
        </Paper>
    )
}

const getLineItemPricing = (lineItem: RFQLineItem ): any[] => {

    const calculateDiscount = ((lineCost, highestCost): number => {
        if ( highestCost === 0) {
            return 0
        }
        return (highestCost - lineCost) / highestCost
    })

    if (lineItem.quoteLineItem?.pricing?.length > 0) {
        // find highest price
        const highestPrice = lineItem.quoteLineItem.pricing.reduce((accum, price) =>
                price.unitCost > accum ? price.unitCost : accum
            , 0)
        return lineItem.quoteLineItem.pricing.map(price => {

            return {
                quantity: price.quantity ? IntegerFormatter.format(price.quantity) : '--',
                leadTimeDays: price.leadTimeDays ? IntegerFormatter.format(price.leadTimeDays) : '--',
                unitCost: price.unitCost ? CurrencyFormatter.format(price.unitCost) : '--',
                subtotal: price.subtotal ? CurrencyFormatter.format(price.subtotal) : '--',
                discount: calculateDiscount(price.unitCost, highestPrice)
            }
        })
    } else if (lineItem.quantity?.length > 0) {
        return lineItem.quantity.map(qty => {
            return {
                quantity: IntegerFormatter.format(qty),
                leadTimeDays: '--',
                unitCost: '--',
                subtotal: '--',
                discount: 0
            }
        })
    } else {
        return [{
            quantity: '--',
            leadTimeDays: '--',
            unitCost: '--',
            subtotal: '--',
            discount: 0
        }]
    }
}

interface LineItemQuantitiesProps {
    lineItem: RFQLineItem
}

export const LineItemQuantities: FC<LineItemQuantitiesProps> = ({ lineItem }) => {

    const classes = useStyles()

    return (
        <TableCell className={classes.cell}
                   style={{ width: '10%', paddingRight: '16px' }}>
            <TableContainer component={Paper}
                            style={{ borderRadius: '3px' }}>
                <Table style={{
                    backgroundColor: '#364F68',
                    border: '1px solid #FFFFFF40'
                }}>
                    <TableHead>
                        <TableRow className={classes.quoteTableHeaderRow}>
                            <TableCell style={{ textAlign: 'center' }}>Qty</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        { lineItem.quantity.map((qty, index) =>
                            <TableRow className={classes.quoteTableDataRow} key={index}>
                                <TableCell style={{ textAlign: 'center' }}>{IntegerFormatter.format(qty)}</TableCell>
                            </TableRow>
                        )}
                    </TableBody>
                </Table>
            </TableContainer>
        </TableCell>
    )
}

interface LineItemPricingProps {
    lineItem: RFQLineItem
}

export const LineItemPricing: FC<LineItemPricingProps> = ({ lineItem }) => {

    const classes = useStyles()

    return (
        <TableCell className={classes.cell}
                   style={{ paddingRight: '16px', minWidth: '580px' }}>
            <TableContainer component={Paper}
                            style={{ borderRadius: '3px' }}>
                <Table style={{
                    backgroundColor: '#364F68',
                    border: '1px solid #FFFFFF40'
                }}>
                    <TableHead>
                        <TableRow className={classes.quoteTableHeaderRow}>
                            <TableCell>Qty</TableCell>
                            <TableCell>Lead Time (Business Days)</TableCell>
                            <TableCell>Unit Price</TableCell>
                            <TableCell>Total Price</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {getLineItemPricing(lineItem).map(
                            (price, index) => {
                                return (
                                    <TableRow
                                        className={classes.quoteTableDataRow}
                                        key={index}>
                                        <TableCell>{price.quantity}</TableCell>
                                        <TableCell>{price.leadTimeDays}</TableCell>
                                        <TableCell>
                                            {price.unitCost}
                                            {price.discount > 0 &&
                                                <Chip
                                                    className={classes.discountChip}
                                                    size='small'
                                                    label={`${PercentIntegerFormatter.format(
                                                        price.discount)} Lower`}
                                                />
                                            }
                                        </TableCell>
                                        <TableCell>{price.subtotal}</TableCell>
                                    </TableRow>
                                )
                            })}
                    </TableBody>
                </Table>
            </TableContainer>
        </TableCell>
    )
}