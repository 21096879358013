import { Grid, TextField, Typography } from '@material-ui/core'
import {
    Button,
    jumpToFirstError,
    DocumentType,
    SHOW_FEEDBACK,
    VerifiedUserIcon,
    TaskIcon,
    LockIcon,
    FilePondWrapper,
    PARTS_ACCEPTED_FILE_TYPES,
    PARTS_ACCEPTED_FILE_EXTENSIONS,
    PARTS_FILE_TYPE_CAPTION
} from '@syncfab/machine'
import { Map } from 'immutable'
import React from 'react'
import { withNamespaces, WithNamespaces } from 'react-i18next'
import { InjectedFormProps } from 'redux-form'
import { reduxForm } from 'redux-form/immutable'
import { withStyles, WithStyles } from '@material-ui/core/styles'
import { Box, Paper, TableHead, Table, TableRow, TableCell, TableBody, TableContainer, Checkbox, Toolbar } from '@material-ui/core'
import EditIcon from '@material-ui/icons/Edit'
import { Dispatch } from 'redux'
import { connect } from 'react-redux'
import { State } from '../../../../application/Store'
import { EditPartDocumentDialog } from './EditPartDocumentDialog'
import { LineItemDialog } from './LineItemDialog'

export const RFQQuoteEditorFormName: string = 'RFQQuoteEditorFormName'

const styles = theme => ({
    paper: {
        width: '100%',
        marginTop: theme.spacing(3),
        padding: '24px',
        paddingTop: theme.spacing(2),
        paddingBottom: theme.spacing(3),
        backgroundColor: theme.palette.background.paper,
    },
    detailsHeader: {
        fontSize: '20px',
        letterSpacing: '1.25px',
    },
    table: {
        border: `1px solid rgba(255, 255, 255, 0.23)`,
        borderWidth: 2,
    },
    tableRow: {
        '& .MuiTableCell-root': {
            borderBottom: `1px solid rgba(224, 224, 224, 0.23)`,
        },
        '&:last-child th, &:last-child td': {
            borderBottom: 0,
        },
    },
    tableHead: {
        '& .MuiTableCell-root': {
            '&:nth-child(1)': {
                borderLeft: 0,
            },
            borderWidth: 1,
            borderLeft: '1px solid rgba(224, 224, 224, 0.23)',
            borderBottom: `1px solid rgba(224, 224, 224, 0.23) !important`,
        },
    },
    editIcon: {
        minWidth: '30px',
    },
    button: {
        textAlign: 'center' as any,
    },
    tableCellStyle: {
        margin: 0,
        padding: 0,
        flexDirection: 'row' as any,
        color: '#fff',
        '& .MuiOutlinedInput-input': {
            padding: 5,
            paddingLeft: 0,
            margin: 0,
        },
        '& .MuiFormControl-marginNormal': {
            margin: 0,
        },
        '& .MuiTableCell-root': {
            paddingTop: '5px',
            paddingBottom: '5px',
        },
        '& .MuiOutlinedInput-notchedOutline': {
            border: 'none',
        },
        '& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button': {
            display: 'none',
        },
        '& input[type=number]': {
            MozAppearance: 'textfield',
        },
    },
    toolbar: {
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.common.white,
        marginBottom: '-5px',
        borderRadius: '5px 5px 0 0',
        width: '100%',
    },
    actions: {
        borderColor: theme.palette.common.white,
        color: theme.palette.common.white,
        margin: theme.spacing(1),
    },
    cardDocument: {
        width: '100%',
        margin: 0,
        backgroundColor: theme.palette.common.white,
        padding: theme.spacing(2),
        textAlign: 'center' as any,
    },
    documentTitle: {
        fontSize: '16px',
        color: theme.palette.common.black,
    },
    documentSubTitle: {
        fontSize: '14px',
        color: theme.palette.common.black,
    },
    numberInput: {
        '& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button': {
            display: 'none',
        },
        '& input[type=number]': {
            MozAppearance: 'textfield',
        },
    },
    note: {
        marginLeft: -4,
        [theme.breakpoints.down('md')]: {
            textAlign: 'start' as any,
        },
    },
    caption: {
        textAlign: 'end' as any,
        [theme.breakpoints.down('md')]: {
            textAlign: 'start' as any,
        },
    },
    captionContainer: {
        justifyContent: 'end' as any,
        [theme.breakpoints.down('md')]: {
            justifyContent: 'start' as any,
        },
    },
    disabled: {
        pointerEvents: 'none' as any,
        '& .Mui-disabled': {
            color: '#fff',
        },
    },
    disabledIcon: {
        color: '#6B7E94',
    },
    checkbox: {
        '& .MuiCheckbox-root': {
            color: theme.palette.common.white,
        },
        '& .Mui-checked': {
            color: theme.palette.primary.main,
        },
    },
})

interface FormState {
    selectedLineItems: number[]
    editDocumentDialogOpen: boolean
    lineItemDialogOpen: boolean
    lineItemKey: number
    partDocuments: any[]
    uploadedDocuments: any[]
}

interface Properties {
    parts: any[]
    draftParts: any[]
    onDraftPartsChange: (draftParts: any[]) => void
    rfq?: any
    onError: (error: string) => void
    displayWarningMessage: (message: string) => void
}

interface FormProps extends Properties, WithNamespaces, WithStyles, InjectedFormProps<{}, {}> {}

class Form extends React.Component<FormProps, FormState> {
    inputRef: any
    constructor(props) {
        super(props)
        this.inputRef = React.createRef()
        this.state = {
            selectedLineItems: [],
            lineItemDialogOpen: false,
            lineItemKey: null,
            editDocumentDialogOpen: false,
            partDocuments: null,
            uploadedDocuments: [],
        }
    }

    parsePartDocumentNames = documents => {
        const revRegEx = /(?<partNumber>[A-Za-z0-9-_]+[A-Za-z0-9])[\s\S]*(REV|REVISION){1}[\s_-]*(?<revision>[A-Za-z0-9]+)/i
        const lastDashRegEx = /(?<partNumber>[A-Za-z0-9_-]+)[-](?<revision>[A-Za-z0-9]+)/
        const firstUnderscoreRegEx = /(?<partNumber>[A-Za-z0-9-]+)_/

        const partMap: { _id: string; partNumber: string; revision?: string; documents: any[] }[] = []
        documents.forEach(doc => {
            const docNameWithExtension: string = doc.name
            // get filename without extension
            const docName = docNameWithExtension?.slice(0, docNameWithExtension?.indexOf('.'))
            // try to match against regex that expects filenames with 'rev' or 'revision' in them

            let partNumber: string = ''
            let revision: string = ''
            const _id: string = doc._id

            if (!!docName?.match(revRegEx)) {
                const revMatch = docName?.match(revRegEx)
                // console.log(`REV MATCH Part number: ${revMatch.groups.partNumber} and revision: ${revMatch.groups.revision}`)
                partNumber = revMatch.groups.partNumber
                revision = revMatch.groups.revision
            } else if (!!docName?.match(lastDashRegEx)) {
                const lastDashMatch = docName?.match(lastDashRegEx)
                // console.log(`LAST DASH MATCH Part number: ${lastDashMatch.groups.partNumber} and revision ${lastDashMatch.groups.revision}`)
                partNumber = lastDashMatch.groups.partNumber
                revision = lastDashMatch.groups.revision
            } else if (!!docName?.match(firstUnderscoreRegEx)) {
                const firstUnderscoreMatch = docName?.match(firstUnderscoreRegEx)
                // console.log(`FIRST UNDERSCORE MATCH Part number: ${firstUnderscoreMatch.groups.partNumber}`)
                partNumber = firstUnderscoreMatch.groups.partNumber
            } else {
                //console.log(`FULL FILENAME ${docName}`)
                partNumber = docName
            }

            const existingPart = partMap?.find(entry => {
                return entry.partNumber === partNumber && (entry?.revision === revision || (entry?.revision === '' && revision === ''))
            })
            !!existingPart ? existingPart.documents.push(doc) : partMap.push({ _id, partNumber, revision, documents: [doc] })
        })
        return partMap
    }

    isSelected(index: number) {
        return this.state.selectedLineItems.includes(index)
    }

    handleLineItemCheckboxClick = (index: number) => {
        let newSelectedLineItems
        if (this.isSelected(index)) {
            newSelectedLineItems = this.state.selectedLineItems.filter(i => i !== index)
        } else {
            newSelectedLineItems = [...this.state.selectedLineItems, index]
        }
        this.setState({ selectedLineItems: newSelectedLineItems })
    }

    handleRevisionChange(index: number, e: React.ChangeEvent<HTMLInputElement>) {
        const newDraftParts = this.props.draftParts.map((part, j) => {
            if (index === j) {
                return { ...part, revision: e.target.value }
            } else {
                return part
            }
        })
        this.props.onDraftPartsChange(newDraftParts)
    }

    handlePartNumberChange(index: number, e: React.ChangeEvent<HTMLInputElement>) {
        const newDraftParts = this.props.draftParts.map((part, j) => {
            if (index === j) {
                return { ...part, partNumber: e.target.value }
            } else {
                return part
            }
        })
        this.props.onDraftPartsChange(newDraftParts)
    }

    handleQuantityChange(index: number, e: React.ChangeEvent<HTMLInputElement>, qtyIndex: number) {
        const newDraftParts = this.props.draftParts.map((part, j) => {
            if (index === j) {
                const newQuantity = [...part.quantity]
                newQuantity[qtyIndex] = Number(e.target.value)
                return { ...part, quantity: newQuantity }
            } else {
                return part
            }
        })
        this.props.onDraftPartsChange(newDraftParts)
    }

    onClickEditDocuments = (partDocuments: any[], lineItemKey: number) => {
        this.setState({
            partDocuments: partDocuments,
            editDocumentDialogOpen: true,
            lineItemKey: lineItemKey,
        })
    }

    handlePartDocumentDialogSave = (documents: any[], lineItemKey: number) => {
        const newDraftParts = this.props.draftParts.map((part, j) => {
            if (lineItemKey === j) {
                return { ...part, documents: documents }
            } else {
                return part
            }
        })
        this.props.onDraftPartsChange(newDraftParts)
        this.togglePartDocumentDialog()
    }

    togglePartDocumentDialog = () => {
        this.setState({ editDocumentDialogOpen: false, lineItemKey: null })
    }

    onExistingPartSelect = (data: Map<string, any>) => {
        const selectedPartId = (data.toJS() as any).partId?.value

        if (!!this.props.draftParts?.find(part => part._id === selectedPartId)) {
            return this.props.onError('Selected part is already included in the RFQ.')
        }

        // get the part details for the selected part
        const selectedPart = this.props.parts?.find(part => part._id === selectedPartId)

        const partToAdd = {
            _id: selectedPart._id,
            partNumber: selectedPart.number,
            revision: selectedPart.revision,
            documents: selectedPart.documents,
            documentIds: selectedPart.documentIds,
            quantity: [],
        }

        this.props.onDraftPartsChange(this.props.draftParts.concat(partToAdd))
        this.toggleLineItemDialog()
    }

    toggleLineItemDialog = () => {
        this.setState({ lineItemDialogOpen: false })
    }

    onClickPreviousDocument = () => {
        this.setState({ lineItemDialogOpen: true })
    }

    deleteLineItems = () => {
        if (this.state.selectedLineItems?.length > 0) {
            const remainingParts = []
            this.props.draftParts.forEach((p, index) => {
                if (!this.state.selectedLineItems.includes(index)) {
                    remainingParts.push(p)
                }
            })
            this.props.onDraftPartsChange(remainingParts)
            this.setState({ selectedLineItems: [] })
        }
    }

    addUploadedDocument = doc => {
        this.setState({ uploadedDocuments: this.state.uploadedDocuments.concat(doc) })
    }

    mergeDocsIntoDraftParts = partMap => {
        const mergedDraftParts = [...this.props.draftParts]
        partMap.forEach(part => {
            const partMatch = mergedDraftParts.find(p => p.partNumber === part.partNumber && p.revision === part.revision)
            if (!!partMatch) {
                //console.log('Found a part match.')
                // if part is already in draftParts, then merge documents, unless a document with the same name already exists
                const existingDocNames = partMatch.documents.map(doc => doc.name)
                part.documents.forEach(document => {
                    if (!existingDocNames.includes(document.name)) {
                        partMatch.documents = partMatch.documents.concat(document)
                    }
                })
            } else {
                // else create new part
                mergedDraftParts.push({
                    partNumber: part.partNumber,
                    revision: part.revision,
                    documents: part.documents,
                    quantity: [],
                })
            }
        })
        this.props.onDraftPartsChange(mergedDraftParts)
        this.setState({ uploadedDocuments: [] })
    }

    render() {
        const { t, classes, parts, rfq, draftParts } = this.props
        const { lineItemDialogOpen, editDocumentDialogOpen } = this.state

        const partOptions = parts?.map(part => {
            return {
                value: part._id,
                label: `${part.number} - ${part.revision} - ${part.name}`,
            }
        })

        return (
            <>
                <Paper className={classes.paper}>
                    <Grid container direction="row" spacing={2}>
                        <Grid item xs={12} sm={12} md={12}>
                            <Typography variant="h6" className={classes.detailsHeader}>
                                {`${t('quote-editor-parts')}`}
                            </Typography>
                            <Typography style={{ color: '#DBDBDB' }}>{`${t('quote-editor-parts-note')}`}</Typography>
                        </Grid>
                        <Grid item xs={12} sm={12} md={12} style={{ paddingBottom: 0 }}>
                            <FilePondWrapper
                                className={classes.file}
                                type={DocumentType.PART}
                                acceptedFileTypes={PARTS_ACCEPTED_FILE_TYPES}
                                acceptedFileExtensions={PARTS_ACCEPTED_FILE_EXTENSIONS}
                                allowMultiple={true}
                                maxFiles={20}
                                maxFileSize="150MB"
                                maxTotalFileSize="3000MB"
                                referenceId={rfq && rfq?.buyerCompany?.companyId}
                                onLoad={response => {
                                    const docObj = { ...response }
                                    const partMap = this.parsePartDocumentNames([docObj])
                                    this.mergeDocsIntoDraftParts(partMap)
                                }}
                                fileProcessed={_docObj => {}}
                                allFilesProcessed={() => {}}
                                handleWarning={(message: string) => {
                                    this.props.displayWarningMessage(message)
                                }}
                                handleError={(error: string) => {
                                    this.props.onError(error)
                                }}
                                clearAfterAllFilesProcessed={true}
                            />
                        </Grid>
                        <Grid item container direction="row" xs={12} sm={12} md={12} style={{ paddingTop: 0, marginTop: -5 }}>
                            <Grid item xs={12} sm={12} md={3}>
                                <Box style={{ display: 'flex' }}>
                                    <LockIcon />
                                    <Typography gutterBottom variant="caption" paragraph align="center" style={{ color: '#DBDBDB' }} className={classes.note}>
                                        {`${t('part-editor-footer-note')}`}
                                    </Typography>
                                </Box>
                            </Grid>
                            <Grid item xs={12} sm={12} md={6}>
                                <Box style={{ display: 'flex' }}>
                                    <TaskIcon />
                                    <Typography gutterBottom variant="caption" paragraph align="center" style={{ color: '#DBDBDB', marginBottom: 8 }} className={classes.note}>
                                        {PARTS_FILE_TYPE_CAPTION}
                                    </Typography>
                                </Box>
                            </Grid>
                            <Grid item xs={12} sm={12} md={3}>
                                <Box style={{ display: 'flex' }} className={classes.captionContainer}>
                                    <VerifiedUserIcon />
                                    <Typography gutterBottom variant="caption" paragraph align="center" style={{ color: '#DBDBDB', marginBottom: 8 }} className={classes.caption}>
                                        {`${t('part-editor-footer-caption')}`}
                                    </Typography>
                                </Box>
                            </Grid>
                        </Grid>
                        <Grid item xs={12} sm={12} md={12} style={{ textAlign: 'center' }}>
                            <Button variant="outlined" color="primary" onClick={this.onClickPreviousDocument}>
                                {`${t('quote-editor-previous-documents')}`}
                            </Button>
                        </Grid>
                        {draftParts?.length > 0 && (
                            <>
                                <Grid item xs={12} md={12} style={{ paddingTop: 0 }}>
                                    <Typography gutterBottom variant="h6" className={classes.detailsHeader}>
                                        {`${t('quote-editor-lineitems-edit')}`}
                                    </Typography>
                                </Grid>
                                <Grid item xs={12} md={12} style={{ paddingTop: 0 }}>
                                    <>
                                        {this.state?.selectedLineItems?.length > 0 ? (
                                            <Toolbar color="primary" className={classes.toolbar}>
                                                <Grid container justifyContent="space-between" alignItems="center" className={classes.header}>
                                                    <Grid item>
                                                        <Typography color="inherit" variant="subtitle1">
                                                            {this.state.selectedLineItems?.length} Selected
                                                        </Typography>
                                                    </Grid>
                                                    <Grid item>
                                                        <Button variant="outlined" size="small" className={classes.actions} onClick={this.deleteLineItems}>
                                                            REMOVE
                                                        </Button>
                                                    </Grid>
                                                </Grid>
                                            </Toolbar>
                                        ) : (
                                            ''
                                        )}
                                        <TableContainer component={Paper} className={classes.table}>
                                            <Table aria-label="simple table">
                                                <TableHead>
                                                    {this.state.selectedLineItems?.length === 0 && (
                                                        <TableRow className={classes.tableHead}>
                                                            <TableCell align="right">Line Item</TableCell>
                                                            <TableCell align="left">Part Number*</TableCell>
                                                            <TableCell align="left">Part Revision*</TableCell>
                                                            <TableCell align="left">Documents*</TableCell>
                                                            <TableCell align="left">Qty 1*</TableCell>
                                                            <TableCell align="left">Qty 2</TableCell>
                                                            <TableCell align="left">Qty 3</TableCell>
                                                        </TableRow>
                                                    )}
                                                </TableHead>
                                                <TableBody>
                                                    {draftParts.map((part: any, index) => {
                                                        const isItemSelected = this.isSelected(index)
                                                        const labelId = `enhanced-table-checkbox-${index}`

                                                        return (
                                                            <TableRow className={classes.tableRow} hover role="checkbox" tabIndex={-1} aria-checked={isItemSelected} key={labelId}>
                                                                <TableCell component="th" scope="row" style={{ maxWidth: '100px', minWidth: '100px' }} className={classes.checkbox}>
                                                                    <Checkbox
                                                                        color="primary"
                                                                        checked={isItemSelected}
                                                                        inputProps={{ 'aria-label': 'primary checkbox' }}
                                                                        className={classes.checkbox}
                                                                        // classes={{ root: classes.checkbox }}
                                                                        onClick={() => this.handleLineItemCheckboxClick(index)}
                                                                    />
                                                                    {index + 1}
                                                                </TableCell>
                                                                <TableCell align="left">
                                                                    <TextField
                                                                        name="partNumber"
                                                                        type="text"
                                                                        placeholder="Enter part number"
                                                                        fullWidth
                                                                        variant="outlined"
                                                                        required
                                                                        style={{ width: 150 }}
                                                                        className={`${classes.tableCellStyle} ${part._id && classes.disabled}`}
                                                                        size="small"
                                                                        value={part.partNumber ?? ''}
                                                                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => this.handlePartNumberChange(index, e)}
                                                                        disabled={part._id}
                                                                    />
                                                                </TableCell>
                                                                <TableCell align="left">
                                                                    <TextField
                                                                        name="revision"
                                                                        type="text"
                                                                        placeholder="Enter Revision"
                                                                        fullWidth
                                                                        variant="outlined"
                                                                        required
                                                                        style={{ width: 100 }}
                                                                        className={`${classes.tableCellStyle} ${part._id && classes.disabled}`}
                                                                        size="small"
                                                                        value={part.revision ?? ''}
                                                                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => this.handleRevisionChange(index, e)}
                                                                        disabled={part._id}
                                                                    />
                                                                </TableCell>
                                                                <TableCell align="left">
                                                                    {part.documents?.length > 0 &&
                                                                        part?.documents?.map((document, innerIndex) => {
                                                                            return (
                                                                                <Box style={{ display: 'flex', flexDirection: 'row' }} key={innerIndex}>
                                                                                    <Box style={{ flexGrow: 1, maxWidth: '70%' }}>
                                                                                        <Typography>{document.name ?? ''}</Typography>
                                                                                    </Box>
                                                                                    {innerIndex === 0 && (
                                                                                        <Box
                                                                                            style={{ display: 'flex', flexDirection: 'row', cursor: 'pointer' }}
                                                                                            onClick={() => this.onClickEditDocuments(part.documents, index)}
                                                                                            className={`${part._id && classes.disabled}`}
                                                                                        >
                                                                                            <EditIcon
                                                                                                color={'primary'}
                                                                                                fontSize="small"
                                                                                                className={`${classes.editIcon} ${part._id && classes.disabledIcon}`}
                                                                                            />
                                                                                            <Typography
                                                                                                color={'primary'}
                                                                                                variant="body2"
                                                                                                className={`${part._id && classes.disabledIcon}`}
                                                                                                style={{ textTransform: 'uppercase', fontSize: 14, fontWeight: 'bold' }}
                                                                                            >
                                                                                                Edit
                                                                                            </Typography>
                                                                                        </Box>
                                                                                    )}
                                                                                </Box>
                                                                            )
                                                                        })}
                                                                </TableCell>
                                                                <TableCell align="left">
                                                                    <TextField
                                                                        inputProps={{ min: 1 }}
                                                                        type="number"
                                                                        placeholder="Enter Quantity"
                                                                        fullWidth
                                                                        variant="outlined"
                                                                        required
                                                                        style={{ width: 110 }}
                                                                        className={classes.tableCellStyle}
                                                                        size="small"
                                                                        value={part.quantity[0] ?? ''}
                                                                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => this.handleQuantityChange(index, e, 0)}
                                                                    />
                                                                </TableCell>
                                                                <TableCell align="left">
                                                                    <TextField
                                                                        inputProps={{ min: 1 }}
                                                                        type="number"
                                                                        placeholder="Enter Quantity"
                                                                        fullWidth
                                                                        variant="outlined"
                                                                        style={{ width: 130 }}
                                                                        className={classes.tableCellStyle}
                                                                        size="small"
                                                                        value={part.quantity[1] ?? ''}
                                                                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => this.handleQuantityChange(index, e, 1)}
                                                                    />
                                                                </TableCell>
                                                                <TableCell align="left">
                                                                    <TextField
                                                                        inputProps={{ min: 1 }}
                                                                        type="number"
                                                                        placeholder="Enter Quantity"
                                                                        fullWidth
                                                                        variant="outlined"
                                                                        style={{ width: 130 }}
                                                                        className={classes.tableCellStyle}
                                                                        size="small"
                                                                        value={part.quantity[2] ?? ''}
                                                                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => this.handleQuantityChange(index, e, 2)}
                                                                    />
                                                                </TableCell>
                                                            </TableRow>
                                                        )
                                                    })}
                                                </TableBody>
                                            </Table>
                                        </TableContainer>
                                    </>
                                </Grid>
                            </>
                        )}
                    </Grid>
                </Paper>

                {lineItemDialogOpen && <LineItemDialog onSubmit={this.onExistingPartSelect as any} partOptions={partOptions} open={lineItemDialogOpen} close={this.toggleLineItemDialog} />}
                {editDocumentDialogOpen && (
                    <EditPartDocumentDialog
                        onSubmit={this.handlePartDocumentDialogSave}
                        open={editDocumentDialogOpen}
                        close={this.togglePartDocumentDialog}
                        documents={this.state.partDocuments}
                        lineItemKey={this.state.lineItemKey}
                    />
                )}
            </>
        )
    }
}

const mapStateToProps = (state: State) => {
    return {
        rfq: state.rfq,
    }
}

const mapDispatchToProps = (dispatch: Dispatch) => {
    return {
        onError: (error: string) => dispatch(SHOW_FEEDBACK(error)),
        displayWarningMessage: (message: string) => dispatch(SHOW_FEEDBACK(message))
    }
}

const translatedForm = withStyles(styles)(withNamespaces()(Form))
const PartDetailsV2 = reduxForm<{}, Properties>({
    form: RFQQuoteEditorFormName,
    onSubmitFail: jumpToFirstError,
})(translatedForm as any)

export default connect(mapStateToProps, mapDispatchToProps)(PartDetailsV2)
