import React from 'react'
import { Typography, Grid } from '@material-ui/core'
import { withNamespaces, WithNamespaces } from 'react-i18next'
import { TransactionItem } from './TransactionItem'
import { TransactionUpIcon } from '@syncfab/machine'
import { BoldTypography } from '../../../../utils/Typography/BoldTypography'
import { RightTypography } from '../../../../utils/Typography/RightTypography'

interface ReceivedTransactionListProps extends TransactionItem, WithNamespaces {}

export const ReceivedTransactionListItem = withNamespaces()(({ t, transaction }: ReceivedTransactionListProps) => {
    return (
        <Grid item container spacing={8} xs={12} direction="row" justify="space-between" alignContent="center">
            <Grid container item xs={2} direction="row" alignContent="center">
                <TransactionUpIcon />
                <RightTypography gutterBottom>{`${t('wallet-transactions-received')}`}</RightTypography>
            </Grid>
            <Grid item xs={6}>
                <Typography gutterBottom>
                    {t('wallet-transactions-received-description')} {!!transaction.context && transaction.context.transactionHash}
                </Typography>
            </Grid>
            <Grid item xs={2}>
                <Typography gutterBottom>{new Date(transaction.createdAt).toLocaleDateString()}</Typography>
            </Grid>
            <Grid item xs={2}>
                <BoldTypography gutterBottom>{transaction.value}</BoldTypography>
            </Grid>
        </Grid>
    )
})
