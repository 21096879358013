import { ListQuery } from "./ListQuery";

export const enum SupplierPurchaseOrderActivityViewOption {
    PO = 'pos',
    LINE_ITEM = 'lineItems'
}

export interface SupplierPurchaseOrderQuery extends ListQuery {
    supplierId?: string
    poStatus?: string
    viewBy?: SupplierPurchaseOrderActivityViewOption
}
