import React from 'react'
import Helmet from 'react-helmet'
import { withStyles, WithStyles, Paper, Divider, Grid, Button } from '@material-ui/core'
import { withNamespaces, WithNamespaces } from 'react-i18next'
import { connect } from 'react-redux'
import { CenteredAlignedContainer } from '../../../layout/Container'
import { RouteComponentProps } from 'react-router-dom'
import PartView from './PartView'
import { GET_PART_BY_ID, SET_PART_FOR_EDIT, EDIT_PART, GET_ETHERNET_LINK, RESET_DATA } from '../../../components/part/Actions'
import { State } from '../../../application/Store'
import { Dispatch } from 'redux'
import { GET_LOGGED_COMPANY } from '../../../components/company/Actions'
import { DOCUMENT_DOWNLOAD, DOCUMENT_DOWNLOAD_URL } from '../../../components/document/Actions'
import { DetailsHeader } from '../../quotes/DetailsHeader'
import { EditDialog } from '../../quotes/EditDialog'
import { getSupplierTag } from '../../../components/supplier/Selector'

interface ViewPartProps extends WithStyles, RouteComponentProps<{ id: string }>, WithNamespaces {
    part: any
    company: any
    ethernetLink: any
    getPartByPartID: (partid: string) => void
    getLoggedCompany: () => void
    download: (id: string) => void
    setPartForEdit: (part: any) => void
    getEthernetLink: () => void
    editPart: (id: string, request?: string) => void
    history: any
    reset?: () => void
    previewDocument?: (id: string) => void
    supplierTags: {
        finishes: { label: string; value: string }[]
        manufacturingProcesses: { label: string; value: string }[]
        materials: { label: string; value: string }[]
    }
}

interface ViewPartState {
    editDialogOpen: boolean
    editRequest: string
}
export class ViewPart extends React.Component<ViewPartProps, ViewPartState> {
    constructor(props: ViewPartProps) {
        super(props)
        this.toggleEditDialog = this.toggleEditDialog.bind(this)
        this.onEditChange = this.onEditChange.bind(this)
        this.editPart = this.editPart.bind(this)
        this.props.getPartByPartID(this.props.match.params.id)
        this.props.getLoggedCompany()
        this.props.getEthernetLink()

        this.sendForEdit = this.sendForEdit.bind(this)
        this.state = {
            editDialogOpen: false,
            editRequest: '',
        }
    }

    sendForEdit() {
        if (this.props.part?.submittedToRFQ && this.props?.part?.submittedToRFQ === true) {
            this.setState({ editDialogOpen: true })
        } else {
            this.props.setPartForEdit(this.props.part)
            this.props.history.push(`/parts/editpart/${this.props?.part?.company?.companyId}/${this.props?.part?._id}`)
        }
    }

    onEditChange(value) {
        this.setState({
            editRequest: value,
        })
    }

    editPart() {

        this.props.editPart(this.props?.part?._id, this.state?.editRequest)
        this.setState({
            editRequest: '',
        })
        this.toggleEditDialog()
    }

    toggleEditDialog() {
        this.setState(state => ({
            editDialogOpen: !state.editDialogOpen,
        }))
    }
    render() {
        const { t, classes, part, company, download, ethernetLink, reset, previewDocument, supplierTags } = this.props
        const { editDialogOpen, editRequest } = this.state

        return (
            <CenteredAlignedContainer>
                <Helmet>
                    <title>{`${t('quote-editor-title')}`}</title>
                </Helmet>
                <EditDialog
                    title={t('Edit a part')}
                    label={t('rfq-edit-label')}
                    onChange={this.onEditChange}
                    value={editRequest}
                    primaryActionText={t('rfq-edit-dialog-edit')}
                    onPrimaryAction={this.editPart}
                    secondaryActionText={t('rfq-edit-dialog-cancel')}
                    onSecondaryAction={this.toggleEditDialog}
                    open={editDialogOpen}
                />
                <Paper style={{ padding: '24px' }} className={classes.paper} elevation={1}>
                    <Grid container justify="space-between" alignItems="center" className={classes.header}>
                        <Grid item>
                            <DetailsHeader url="/parts" title={'Parts Library'} id={this.props.match.params.id} />
                        </Grid>
                        <Grid item>
                            {/* <Link to="/" className={classes.link}> */}
                            <Button type="button" style={{ margin: '0 0 16px' }} color="primary" variant="outlined" className={classes.button} onClick={this.sendForEdit}>
                                EDIT
                            </Button>
                        </Grid>
                    </Grid>
                    <Divider />
                    {part && company && (
                        <PartView part={part} company={company} download={download} ethernetLink={ethernetLink} reset={reset} previewDocument={previewDocument} supplierTags={supplierTags} />
                    )}
                </Paper>
            </CenteredAlignedContainer>
        )
    }
}

const styles = theme => ({
    paper: {
        width: '90%',
        minWidth: 300,
        padding: theme.spacing(4),
        marginTop: theme.spacing(4),
        marginBottom: theme.spacing(4),
    },
    button: {
        margin: theme.spacing(1),
    },
})

const PartWithTranslations = withStyles(styles)(withNamespaces()(ViewPart))

const mapStateToProps = (state: State) => {
    const getFinishingTags = getSupplierTag('FINISHING')
    const getManufacturingTags = getSupplierTag('MANUFACTURING')
    const getMaterialPreferences = getSupplierTag('MATERIAL_PREFERENCES')
    return {
        part: state.part.part,
        company: state.company.company,
        ethernetLink: state.part.ethernetLink,
        supplierTags: {
            finishes: getFinishingTags(state),
            manufacturingProcesses: getManufacturingTags(state),
            materials: getMaterialPreferences(state),
        },
    }
}

const mapDispatchToProps = (dispatch: Dispatch) => {
    return {
        getPartByPartID: partid => dispatch(GET_PART_BY_ID.actions.TRIGGER(partid)),
        getLoggedCompany: () => dispatch(GET_LOGGED_COMPANY.actions.TRIGGER()),
        download: (id: string) => dispatch(DOCUMENT_DOWNLOAD.actions.TRIGGER({ id })),
        setPartForEdit: (part: any) => dispatch(SET_PART_FOR_EDIT.actions.TRIGGER(part)),
        editPart: (id: string, request?: string) => dispatch(EDIT_PART.actions.TRIGGER({ id, request })),
        getEthernetLink: () => dispatch(GET_ETHERNET_LINK.actions.TRIGGER()),
        reset: () => dispatch(RESET_DATA.actions.TRIGGER()),
        previewDocument: (id: string) => dispatch(DOCUMENT_DOWNLOAD_URL.actions.TRIGGER({ id })),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(PartWithTranslations)
