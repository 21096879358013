import axios, { AxiosPromise } from 'axios'
import { Environment, EnvironmentVariable, AuthorizationService } from '@syncfab/machine'
// import { TRANSACTION_REQUEST_SIZE } from '../transaction/Actions'

export class TransactionAPI {
    constructor(private environment: Environment, private authorizationService: AuthorizationService) {}

    public getTransaction(page: number = 0, field: string = 'createdAt', direction: number = -1, filter: string = null, size: number): () => AxiosPromise {
        const url = this.environment.get(EnvironmentVariable.API_LAYER_URL)
        const authorization = `Bearer ${this.authorizationService.getAuthToken()}`
        if (field == null && direction == null) {
            return () =>
                axios.get(`${url}/transactions/transactionHistory?page=${page}&size=${size}`, {
                    headers: {
                        authorization,
                    },
                })
        }
        if (field == null && direction != null) {
            return () =>
                axios.get(`${url}/transactions/transactionHistory?page=${page}&size=${size}&sortDirection=${direction}`, {
                    headers: {
                        authorization,
                    },
                })
        }
        if (field != null && direction == null) {
            return () =>
                axios.get(`${url}/transactions/transactionHistory?page=${page}&size=${size}&sortField=${field}`, {
                    headers: {
                        authorization,
                    },
                })
        }

        if (field != null && direction != null && filter != null) {
            return () =>
                axios.get(`${url}/transactions/transactionHistory?page=${page}&size=${size}&sortDirection=${direction}&sortField=${field}&filter=${filter}`, {
                    headers: {
                        authorization,
                    },
                })
        }

        if (field != null && direction != null) {
            return () =>
                axios.get(`${url}/transactions/transactionHistory?page=${page}&size=${size}&sortDirection=${direction}&sortField=${field}`, {
                    headers: {
                        authorization,
                    },
                })
        }
        return () =>
            axios.get(`${url}/transactions/transactionHistory?page=${page}&size=${size}`, {
                headers: {
                    authorization,
                },
            })
    }

    public getTransactionFee(): () => AxiosPromise {
        const url = this.environment.get(EnvironmentVariable.API_LAYER_URL)
        const authorization = `Bearer ${this.authorizationService.getAuthToken()}`
        return () =>
            axios.get(`${url}/companyWallet/getTransactionFee`, {
                headers: {
                    authorization,
                },
            })
    }

    public getCost(): () => AxiosPromise {
        const url = this.environment.get(EnvironmentVariable.API_LAYER_URL)
        const authorization = `Bearer ${this.authorizationService.getAuthToken()}`
        return () =>
            axios.get(`${url}/tokenization/getCost`, {
                headers: {
                    authorization,
                },
            })
    }

    public getBalance(walletAddress: string): () => AxiosPromise {
        const url = this.environment.get(EnvironmentVariable.API_LAYER_URL)
        const authorization = `Bearer ${this.authorizationService.getAuthToken()}`
        return () =>
            axios.get(`${url}/companywallet/tokenBalance/${walletAddress}`, {
                headers: {
                    authorization,
                },
            })
    }

    public getNFTBalance(walletAddress: string): () => AxiosPromise {
        const url = this.environment.get(EnvironmentVariable.API_LAYER_URL)
        const authorization = `Bearer ${this.authorizationService.getAuthToken()}`
        return () =>
            axios.get(`${url}/companywallet/NFTBalance/${walletAddress}`, {
                headers: {
                    authorization,
                },
            })
    }
    public getWalletStatus(): () => AxiosPromise {
        const url = this.environment.get(EnvironmentVariable.API_LAYER_URL)
        const authorization = `Bearer ${this.authorizationService.getAuthToken()}`
        return () =>
            axios.get(`${url}/companywallet/getWalletStatus`, {
                headers: {
                    authorization,
                },
            })
    }

    public sendMfgTokens = (transaction: any): (() => AxiosPromise) => {
        const url = this.environment.get(EnvironmentVariable.API_LAYER_URL)
        const authorization = `Bearer ${this.authorizationService.getAuthToken()}`

        return () =>
            axios.post(`${url}/companywallet/mfgTransfer`, transaction, {
                headers: {
                    authorization,
                },
            })
    }

    public createWallet(): () => AxiosPromise {
        const url = this.environment.get(EnvironmentVariable.API_LAYER_URL)
        const authorization = `Bearer ${this.authorizationService.getAuthToken()}`
        return () =>
            axios.put(`${url}/companywallet/createWallet`, null, {
                headers: {
                    authorization,
                },
            })
    }

    public getWalletAddress(): () => AxiosPromise {
        const url = this.environment.get(EnvironmentVariable.API_LAYER_URL)
        const authorization = `Bearer ${this.authorizationService.getAuthToken()}`
        return () =>
            axios.get(`${url}/companywallet/walletAddress`, {
                headers: {
                    authorization,
                },
            })
    }

    public makePayment = (paymentdata: any): (() => AxiosPromise) => {
        const url = this.environment.get(EnvironmentVariable.API_LAYER_URL)
        const authorization = `Bearer ${this.authorizationService.getAuthToken()}`

        return () =>
            axios.post(`${url}/payment/pay`, paymentdata, {
                headers: {
                    authorization,
                },
            })
    }

    public achPayment = (paymentdata: any): (() => AxiosPromise) => {
        const url = this.environment.get(EnvironmentVariable.API_LAYER_URL)
        const authorization = `Bearer ${this.authorizationService.getAuthToken()}`

        return () =>
            axios.post(`${url}/payment/achpay`, paymentdata, {
                headers: {
                    authorization,
                },
            })
    }
    public contactSales = (data: any): (() => AxiosPromise) => {
        const url = this.environment.get(EnvironmentVariable.API_LAYER_URL)
        const authorization = `Bearer ${this.authorizationService.getAuthToken()}`
        return () =>
            axios.post(`${url}/companywallet/contactSales`, data, {
                headers: {
                    authorization,
                },
            })
    }
}
