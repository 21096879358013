import React from 'react';
import { ActionItem, ActionItemType, EntityType } from '../actionItems';
import { UserIdAndName } from '../BaseTypes';
import { Department } from '../user';
import NotificationsIcon from '@material-ui/icons/Notifications';
import { AssignmentTurnedIn, EventBusyRounded, PersonAdd } from '@material-ui/icons';

export enum NotificationCategory {
  ACTION_ITEM_DUE = 'ACTION_ITEM_DUE',
  ACTION_ITEM_NEW = 'ACTION_ITEM_NEW',
  PURCHASE_ORDER_CHANGE_REQUEST = 'PURCHASE_ORDER_CHANGE_REQUEST',
  RFQ_CHANGE_REQUEST = 'RFQ_CHANGE_REQUEST',
  NEW_BUYER = 'NEW_BUYER',
  NEW_SUPPLIER = 'NEW_SUPPLIER',
  RFQ_CREATED = 'RFQ_CREATED',
  SHIP_DATE_PAST_DUE = 'SHIP_DATE_PAST_DUE',
  NEW_DOCK_DATE_PAST_DUE = 'NEW_DOCK_DATE_PAST_DUE',
  SUPPLIER_QUOTE_SUBMITTED = 'SUPPLIER_QUOTE_SUBMITTED',
  SUPPLIER_PURCHASE_ORDER_ISSUED = 'SUPPLIER_PURCHASE_ORDER_ISSUED',
  SUPPLIER_PURCHASE_ORDER_CONFIRMED = 'SUPPLIER_PURCHASE_ORDER_CONFIRMED',
  INVOICE_SENT = 'INVOICE_SENT',
}

export interface NotificationInput {
  notificationCategory: NotificationCategory;
  entityType: EntityType;
  entityId: string;
  displayAt?: Date;
  assignedTo?: {
    userId: string;
  };
  assignedToDepartments?: Department[];
  additionalDetails?: string[];
  acknowledgedAt?: Date;
  acknowledgedBy?: {
    userId?: string;
  };
}

export interface Notification extends NotificationInput {
  _id: string;
  actionItem?: ActionItem;
  assignedTo?: UserIdAndName;
  acknowledgedBy?: {
    userId?: string;
    userName?: string;
  };
  createdAt: Date;
  createdBy: UserIdAndName;
  updatedAt?: Date;
  updatedBy?: UserIdAndName;
}

export interface NotificationContent {
  icon: JSX.Element;
  description: string;
  linkText: string;
  linkDestination: string;
  date: Date;
  notificationId;
}
const getCustomerIcon = () => {
  return (
    <PersonAdd
      style={{
        backgroundColor: '#C7F0D9',
        borderRadius: 20,
        padding: 5,
        fontSize: 40,
        color: '#00C268',
      }}
    />
  );
};
const getActionItemIcon = () => {
  return (
    <AssignmentTurnedIn
      style={{
        backgroundColor: '#FFEDCB',
        borderRadius: 20,
        padding: 5,
        fontSize: 40,
        color: '#FFBA00',
      }}
    />
  );
};
const getPOStatusIcon = () => {
  return (
    <EventBusyRounded
      style={{
        backgroundColor: '#ffe1de',
        borderRadius: 20,
        padding: 5,
        fontSize: 40,
      }}
      color="error"
    />
  );
};

const getWarningIcon = () => {
  return (
    <NotificationsIcon
      style={{
        backgroundColor: '#ffe1de',
        borderRadius: 20,
        padding: 5,
        fontSize: 40,
      }}
      color="error"
    />
  );
};
const chooseIconForActionIcon = (notification: Notification) => {
  if (!notification.actionItem) {
    return (
      <AssignmentTurnedIn
        style={{
          backgroundColor: '#FFEDCB',
          borderRadius: 20,
          padding: 5,
          fontSize: 40,
          color: '#FFBA00',
        }}
      />
    );
  }
  switch (notification.actionItem.actionItemType) {
    case ActionItemType.NEW_BUYER:
      return getCustomerIcon();
    case ActionItemType.NEW_SUPPLIER:
      return getCustomerIcon();
    case ActionItemType.SHIP_DATE_PAST_DUE:
      return getPOStatusIcon();
    case ActionItemType.NEW_DOCK_DATE_PAST_DUE:
      return getPOStatusIcon();
    default:
      return getActionItemIcon();
  }
};

export const getNotificationContent = (
  notification: Notification
): NotificationContent => {
  switch (notification.notificationCategory) {
    case NotificationCategory.ACTION_ITEM_DUE:
      return {
        icon: getWarningIcon(), 
        date: new Date(notification.createdAt),
        description:
          notification?.actionItem?.description ??
          `Action item ${notification.entityId} assigned to you is due today.`,
        linkText: notification.entityId,
        linkDestination: 'actionItems',
        notificationId: notification._id,
      };
    case NotificationCategory.ACTION_ITEM_NEW:
      return {
        icon: chooseIconForActionIcon(notification),
        date: new Date(notification.createdAt),
        description:
          notification?.actionItem?.description ??
          `A new action item, ${notification.entityId} has been assigned to you.`,
        linkText: notification.entityId,
        linkDestination: 'actionItems',
        notificationId: notification._id,
      };
    case NotificationCategory.SUPPLIER_PURCHASE_ORDER_ISSUED: {
      const [supplierPONumber, rfqId] = notification.additionalDetails;
      return {
        icon: getActionItemIcon(),
        date: new Date(notification.createdAt),
        description: `Review and Acknowledge Purchase Order, ${supplierPONumber} for RFQ: ${rfqId}`,
        linkText: 'Confirm PO',
        linkDestination: `supplierPurchaseOrders/${notification.entityId}/confirm`,
        notificationId: notification._id,
      };
    }
    case NotificationCategory.NEW_DOCK_DATE_PAST_DUE: {
      const [
        purchaseOrder,
        _purchaseOrderLineItems,
      ] = notification.additionalDetails;
      return {
        icon: getWarningIcon(),
        date: new Date(notification.createdAt),
        description: `${notification.entityId} New Dock Date Past Due`,
        linkText: notification.entityId,
        linkDestination: `purchaseOrders/${purchaseOrder}/lineItemEditor`,
        notificationId: notification._id,
      };
    }
    case NotificationCategory.SHIP_DATE_PAST_DUE: {
      const [
        purchaseOrder,
        _purchaseOrderLineItems,
      ] = notification.additionalDetails;
      return {
        icon: getWarningIcon(),
        date: new Date(notification.createdAt),
        description: `${notification.entityId} Ship Date Past Due`,
        linkText: notification.entityId,
        linkDestination: `purchaseOrders/${purchaseOrder}/lineItemEditor`,
        notificationId: notification._id,
      };
    }
    case NotificationCategory.SUPPLIER_PURCHASE_ORDER_CONFIRMED: {
      const [
        _supplierPONumber,
        _supplierName,
        poId,
      ] = notification.additionalDetails;
      return {
        icon: getActionItemIcon(),
        date: new Date(notification.createdAt),
        description: `A supplier purchase order has been confirmed`,
        linkText: 'View PO',
        linkDestination: `purchaseOrders/${poId}/editor`,
        notificationId: notification._id,
      };
    }
    case NotificationCategory.RFQ_CHANGE_REQUEST: {
      return {
        icon: getActionItemIcon(),
        date: new Date(notification.createdAt),
        description: `The buyer has requested a change to RFQ ${notification.entityId}.`,
        linkText: 'View RFQ',
        linkDestination: `quotes/${notification.entityId}/details`,
        notificationId: notification._id,
      };
    }
    case NotificationCategory.RFQ_CREATED: {
      //   const [
      //     buyerCompanyName,
      //     buyerContactName,
      //   ] = notification.additionalDetails;
      return {
        icon: getActionItemIcon(),
        date: new Date(notification.createdAt),
        description: 'A new RFQ has been created by a buyer.',
        linkText: 'View RFQ',
        linkDestination: `quotes/${notification.entityId}/details`,
        notificationId: notification._id,
      };
    }
    case NotificationCategory.PURCHASE_ORDER_CHANGE_REQUEST: {
      const [supplierId] = notification.additionalDetails;
      return {
        icon: getActionItemIcon(),
        date: new Date(notification.createdAt),
        description: `Supplier ${supplierId} has requested a change to PO ${notification.entityId}.`,
        linkText: 'View PO',
        linkDestination: `purchaseOrders/${notification.entityId}/editor`,
        notificationId: notification._id,
      };
    }
    case NotificationCategory.SUPPLIER_QUOTE_SUBMITTED: {
      const [supplierName] = notification.additionalDetails;
      return {
        icon: getActionItemIcon(),
        date: new Date(notification.createdAt),
        description: `Supplier ${supplierName} has submitted a quote for RFQ ${notification.entityId}.`,
        linkText: 'View Quotes',
        linkDestination: `quotes/${notification.entityId}/supplier`,
        notificationId: notification._id,
      };
    }
    case NotificationCategory.INVOICE_SENT: {
      const [buyerPOReference, poLineItemId] = notification.additionalDetails;
      const link = (
        <a
          href="https://assets.staging.syncfab.com/SyncFab_Payment_Intructions_References.pdf"
          target="_blank"
          style={{ color: '#40B4BF' }}
        >
          payment instructions
        </a>
      );
      return {
        icon: getActionItemIcon(),
        date: new Date(notification.createdAt),
        description: `An invoice has been sent for purchase order line item ${poLineItemId} on PO ${buyerPOReference}. Please review our ${link} or contact ap@syncfab.com for assistance`,
        linkDestination: `purchaseOrders/${notification.entityId}/shipments`,
        linkText: 'Invoice Sent',
        notificationId: notification._id,
      };
    }
    default:
      return {
        icon: getActionItemIcon(),
        date: new Date(),
        description: `No handler for ${notification?.notificationCategory}.`,
        linkDestination: `quotes`,
        linkText: 'No handler',
        notificationId: 'Unknown',
      };
  }
};
