import { Button, Grid, Typography } from "@material-ui/core";
import React, { FC } from "react";
import { object, SchemaOf, string } from "yup";
import { FormProvider, SubmitHandler, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import {
    EntityType,
    getEntityTypeDescription,
    RHFTextField,
    SHOW_FEEDBACK,
    useCreateChangeRequestMutation
} from "@syncfab/machine";
import { useAppDispatch } from "../../../../application/hooks";

interface ChangeRequestProps {
    entityId: string
    entityType: EntityType
    cancel: () => void
    complete: () => void
}
interface ChangeRequestFormProps {
    changeRequest: string
}

const changeRequestFormSchema: SchemaOf<ChangeRequestFormProps> = object({
    changeRequest: string().required('Feedback regarding the desired change is required')
})

export const ChangeRequest: FC<ChangeRequestProps> = ({ cancel, complete, entityId, entityType }) => {

    const dispatch = useAppDispatch()

    const [createChangeRequest] = useCreateChangeRequestMutation()

    const methods = useForm<ChangeRequestFormProps>({
        resolver: yupResolver(changeRequestFormSchema, { stripUnknown: true }),
        defaultValues:  {
            changeRequest: ''
        },
    });

    const onSubmit: SubmitHandler<ChangeRequestFormProps> = async (changeRequestData: ChangeRequestFormProps) => {
        try {
            await createChangeRequest({ entityId: entityId, entityType: entityType, changeRequest: changeRequestData.changeRequest }).unwrap()
            complete()
            dispatch(SHOW_FEEDBACK('Change request submitted.'))
        } catch(error) {
            dispatch(SHOW_FEEDBACK(error.data))
        }
    }

    return (
        <FormProvider {...methods} >
            <Typography style={{ fontSize: 22, fontWeight: 600, paddingTop: '8px', paddingBottom: '16px' }}>
                Submit Change Request
            </Typography>
            <Typography style={{ fontSize: 14, paddingBottom: '24px' }}>
                We will update the {getEntityTypeDescription(entityType)} based on the feedback provided.
            </Typography>
            <RHFTextField
                name='changeRequest'
                label='Enter feedback...'
                multiline
                minRows={4}
                required
            />
            <Grid container justifyContent='flex-end' style={{ paddingTop: '16px', paddingBottom:'16px' }}>
                <Button variant="text" style={{ color: "#40B4BF" }} onClick={() => cancel()}>
                    CANCEL
                </Button>
                <Button
                    type='submit'
                    variant="contained"
                    style={{ backgroundColor: '#40B4BF', color: '#FFFFFF', marginLeft: '16px' }}
                    onClick={methods.handleSubmit(onSubmit)}
                >
                    SUBMIT REQUEST
                </Button>
            </Grid>
        </FormProvider>
    )
}