import React from 'react'
import { WithNamespaces, withNamespaces } from 'react-i18next'
import { connect } from 'react-redux'
import { WithStyles, withStyles, Paper, Typography, Grid, Divider } from '@material-ui/core'
import { CenteredAlignedContainer } from '../../layout/Container'
import { State } from '../../application/Store'
import { LocatePartForm } from './components/locatePartRecord'

const styles = theme => ({
    paper: {
        width: '90%',
        minWidth: 300,
        padding: theme.spacing(3),
        marginTop: theme.spacing(3),
        marginBottom: theme.spacing(3),
    },
    paper2: {
        width: '80%',
        minWidth: 300,
        padding: theme.spacing(4),
        marginTop: theme.spacing(4),
        marginBottom: theme.spacing(4),
    },
    gridPadding: {
        padding: `${theme.spacing(2)}px ${theme.spacing(20)}px`,
    },
    balanceContainer: {
        border: `1px solid ${theme.palette.secondary.main}`,
        borderRadius: 15,
        margin: `${theme.spacing(2)}px ${theme.spacing(1)}px`,
        padding: theme.spacing(2),
    },
    mfgIcon: {
        width: 75,
        height: 75,
    },
    balance: {
        color: theme.palette.secondary.main,
        fontSize: 34,
        marginLeft: theme.spacing(1),
    },
    margin: {
        margin: theme.spacing(1),
    },
    paragraph: {
        color: '#f55c47',
    },
    title: {
        marginBottom: theme.spacing(0),
        paddingBottom: theme.spacing(0),
    },
    root: {
        backgroundColor: theme.palette.common.white,
        marginTop: theme.spacing(2),
        padding: theme.spacing(2),
        color: theme.palette.grey['700'],
    },
    divider: {
        marginTop: theme.spacing(3),
        marginBottom: theme.spacing(3),
    },
})

interface TrackProps extends WithNamespaces, WithStyles {
    isLoading: boolean
}

interface TrackState {}

class TrackView extends React.Component<TrackProps, TrackState> {
    constructor(props: TrackProps) {
        super(props)
        this.state = {}
    }

    render() {
        const { classes } = this.props
        const {} = this.state

        return (
            <CenteredAlignedContainer>
                <Paper className={classes.paper} elevation={1}>
                    <Grid container>
                        <Typography gutterBottom variant="h2">
                            {'Locate Part Record'}
                        </Typography>
                    </Grid>
                    <Divider className={classes.divider} />
                    <LocatePartForm />
                </Paper>
            </CenteredAlignedContainer>
        )
    }
}

const TrackViewWithTranslations = withStyles(styles)(withNamespaces()(TrackView))

const mapStateToProps = (state: State) => {
    return {
        isLoading: state.transaction.isLoading,
    }
}

export default connect(
    mapStateToProps
)(TrackViewWithTranslations)
