import React from 'react'
import GoogleMapReact from 'google-map-react'
import Marker from './Marker'

interface MapProps {
    addressesWithLatLong: any[]
    locations: any[]
}

interface MapState {}

export class MapContainer extends React.Component<MapProps, MapState> {
    render() {
        const { addressesWithLatLong, locations } = this.props

        return (
            <div style={{ height: '40vh', width: '100%' }}>
                <GoogleMapReact
                    bootstrapURLKeys={{ key: 'AIzaSyAoVzb0i5s76I6mwleSxavtFUNro4xYLBM' }}
                    defaultCenter={{ lat: addressesWithLatLong[0].latitude, lng: addressesWithLatLong[0].longitude }}
                    defaultZoom={8}
                    hoverDistance={30}
                >
                    {addressesWithLatLong.map((addr, index) => {
                        if (addr.latitude === 0 && addr.longitude === 0) {
                            return null
                        }

                        return (
                            <Marker
                                key={index}
                                lat={addr && addr.latitude ? addr.latitude : 0}
                                lng={addr && addr.longitude ? addr.longitude : 0}
                                name={addr && addr.label ? addr.label : ''}
                                color="#4ebb8e"
                                location={locations[index]}
                            />
                        )
                    })}
                </GoogleMapReact>
            </div>
        )
    }
}
