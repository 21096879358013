import React, { useRef, useState } from 'react'
import { Field, reduxForm, FormSection } from 'redux-form/immutable'
import { InjectedFormProps } from 'redux-form'
import { Map } from 'immutable'
import { WithStyles, withStyles } from '@material-ui/core/styles'
import { withNamespaces, WithNamespaces } from 'react-i18next'
import { Address, FormAutocompleteV2, FormTextField, jumpToFirstError } from '@syncfab/machine'
import { Countries } from '../../../data/countries'
import { Grid } from '@material-ui/core'

export const AddressFormName: string = 'addressForm'

const styles = theme => ({
    form: {
        display: 'flex',
        'flex-direction': 'column',
        maxWidth: 1000,
        minWidth: 300,
        margin: 'auto',
        [theme.breakpoints.down('md')]: {
            maxWidth: 600,
        },
    },
    row: {
        display: 'flex',
        'flex-direction': 'row',
        [theme.breakpoints.down('md')]: {
            'flex-direction': 'column',
        },
    },
    leftInput: {
        marginRight: theme.spacing(2),
        [theme.breakpoints.down('md')]: {
            marginRight: 0,
        },
    },
    rightInput: {
        marginLeft: theme.spacing(2),
        [theme.breakpoints.down('md')]: {
            marginLeft: 0,
        },
    },
    action: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
        marginLeft: 'auto',
    },
    root: {
        width: '100%',
        backgroundColor: 'transparent',
        boxShadow: 'none',
    },
    gridItem: {
        paddingTop: '0px !important',
        paddingBottom: '0px !important',
    },
    formInput: {
        '& .MuiFormControl-marginNormal': {
            margin: 0,
        },
    },
})

type CompanyAddressFields = Partial<Address>
const MAX_LENGTH_MAPPING = {
    state: 3,
    line: 100,
    city: 40,
    zipCode: 6,
    country: 40,
}

export const locationValidation = (data: Map<string, string>): {} => {
    const errors = {} as any
    ;['line', 'city', 'state', 'country', 'zipCode'].forEach(field => {
        if (!data.getIn(['location']) || !data.getIn(['location', field])) {
            errors[field] = 'Required'
        } else if (!data.getIn(['location']) || (data.getIn(['location', field]) as string).length > MAX_LENGTH_MAPPING[field]) {
            errors[field] = `Maximum ${MAX_LENGTH_MAPPING[field]} characters`
        }
    })
    // ['city', 'state', 'country'].forEach(field => {
    //     if (data.getIn(['location', field]) &&
    //     isExistSpecialCharacter(data.getIn(['location', field]))) errors[field] = 'Not allow certain special characters'
    // })

    return errors
}

const validate = (data: Map<string, string>): {} => {
    const errors: { [key: string]: any } = {}
    ;['name', 'contactName', 'companyName', 'phoneNumber'].forEach(field => {
        if (!data.get(field)) {
            errors[field] = 'Required'
        }
    })

    errors.location = locationValidation(data)
    return errors
}

const LocationComponent = ({ classes, t }: WithStyles & WithNamespaces) => {
    const [states, setStates] = useState([])
    const selectStateRef = useRef<any>(null)
    const renderFormFilterAutoComplete = props => {
        return <FormAutocompleteV2 className={classes.root} setRef={selectStateRef} {...props} />
    }

    const onChangesCountry = (country: string) => {
        if (states && states?.length && states?.length > 0) {
            if (selectStateRef && selectStateRef?.current) {
                const button = selectStateRef?.current?.querySelector('button')
                button.click()
            }
        }
        if (country) {
            const selectedCountry = Countries?.find(x => x.name == country)
            if (selectedCountry && selectedCountry?.states) setStates(selectedCountry?.states as any)
        } else {
            setStates([])
        }
    }

    return (
        <React.Fragment>
            <Grid container direction="row" spacing={2}>
                <Grid item xs={12} md={12} className={classes.gridItem}>
                    <Field name="line" component={FormTextField} type="text" label={t('address-form-line')} fullWidth description={t('address-form-line-description')} className={classes.formInput} />
                </Grid>
                <Grid item xs={12} md={12} className={classes.gridItem}>
                    <Field
                        name="line2"
                        component={FormTextField}
                        type="text"
                        label={t('address-form-line2')}
                        fullWidth
                        description={t('address-form-line2-description')}
                        className={classes.formInput}
                    />
                </Grid>
                <Grid item xs={12} md={6} className={classes.gridItem}>
                    <Field
                        name="country"
                        label={t('address-form-country')}
                        options={Countries}
                        component={FormAutocompleteV2}
                        labelKey="name"
                        valueKey="name"
                        t={t}
                        onChange={(e: any) => {
                            onChangesCountry(e?.value)
                        }}
                        fullWidth
                        format={value => {
                            if (!value) {
                                return []
                            }
                            if (!!value.toJS) {
                                return value.toJS()
                            }
                            return value
                        }}
                        className={classes.formInput}
                    />
                </Grid>

                <Grid item xs={12} md={6} className={classes.gridItem}>
                    <Field
                        label={t('address-form-state')}
                        placeholder={t('address-form-state')}
                        name="state"
                        options={states}
                        component={renderFormFilterAutoComplete}
                        fullWidth
                        labelKey="name"
                        valueKey="state_code"
                        t={t}
                        format={value => {
                            if (!value) {
                                return []
                            }
                            if (!!value.toJS) {
                                return value.toJS()
                            }
                            return value
                        }}
                        className={classes.formInput}
                    />
                </Grid>
                <Grid item xs={12} md={6} className={classes.gridItem}>
                    <Field name="city" component={FormTextField} type="text" label={t('address-form-city')} fullWidth className={classes.formInput} />
                </Grid>
                <Grid item xs={12} md={6} className={classes.gridItem}>
                    <Field name="zipCode" component={FormTextField} type="text" label={t('address-form-zipCode')} fullWidth className={classes.formInput} />
                </Grid>
            </Grid>
        </React.Fragment>
    )
}

export const LocationForm = withNamespaces()(withStyles(styles)(LocationComponent))

const form = (props: InjectedFormProps<{}, {}> & WithStyles & WithNamespaces) => {
    const { handleSubmit, t, classes } = props
    return (
        <form onSubmit={handleSubmit} className={props.classes.form}>
            <div className={classes.row}>
                <Field name="name" component={FormTextField} type="text" label={t('address-form-name')} fullWidth />
            </div>
            <div className={classes.row}>
                <Field name="firstName" component={FormTextField} type="text" label={t('address-form-first-name')} fullWidth className={classes.leftInput} />
                <Field name="lastName" component={FormTextField} type="text" label={t('address-form-last-name')} fullWidth className={classes.rightInput} />
            </div>
            <FormSection name="location">
                <div className={classes.row}>
                    <Field name="line" component={FormTextField} type="text" label={t('address-form-line')} fullWidth description={t('address-form-line-description')} />
                </div>
                <div className={classes.row}>
                    <Field name="line2" component={FormTextField} type="text" label={t('address-form-line2')} fullWidth description={t('address-form-line2-description')} />
                </div>
                <div className={classes.row}>
                    <Field name="city" component={FormTextField} type="text" label={t('address-form-city')} fullWidth className={classes.leftInput} />
                    <Field name="state" component={FormTextField} type="text" label={t('address-form-state')} fullWidth className={classes.middle} />
                    <Field name="zipCode" component={FormTextField} type="text" label={t('address-form-zipCode')} fullWidth className={classes.rightInput} />
                </div>
                <div className={classes.row}>
                    <Field name="country" component={FormTextField} type="text" label={t('address-form-country')} fullWidth />
                </div>
            </FormSection>
            <div className={classes.row}>
                <Field name="phoneNumber" component={FormTextField} type="text" label={t('address-form-phone')} fullWidth description={t('address-form-phone-description')} />
            </div>
        </form>
    )
}

const translatedForm = withNamespaces()(withStyles(styles)(form))
export const AddressForm = reduxForm<{}, CompanyAddressFields>({
    form: AddressFormName,
    validate,
    onSubmitFail: jumpToFirstError,
})(translatedForm)
