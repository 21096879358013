import React from 'react';
import { Alert, AlertTitle } from "@material-ui/lab"
import { SvgIcon, withStyles } from "@material-ui/core"
import { useAppSelector } from '../../../../application/hooks';
import { useAcknowledgeVerificationCompleteMutation } from '@syncfab/machine';
const styles = () => ({
    '@global': {
        '.MuiAlert-standardSuccess': {
            backgroundColor: '#00A46A',
        },
        '.MuiAlertTitle-root': {
            fontSize: 26,
        },
        '.MuiAlert-message': {
            color: 'white',
            fontSize: 14,
        }
    },
});

const Al = () => {
    const loggedUser = useAppSelector(state => state.user.loggedUser);
    const supplierId = loggedUser?.supplierId;
    const [ ack ] = useAcknowledgeVerificationCompleteMutation();
    return (
        <Alert 
        icon={
            <SvgIcon
                style={{ fontSize: 120 }}
            >
               <svg version="1.1" id="Layer_1"
                xmlns="http://www.w3.org/2000/svg"
                xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                viewBox="0 0 246 247" xmlSpace="preserve"> <style type="text/css">
                {`.st0{fill:#FFFFFF;}`} </style> <g
                id="Layer_2_00000156561808516352378600000016286934230306890371_">
                <g id="Group_2132" transform="translate(-17.189 -14.268)"> <path
                id="Path_3400" className="st0"
                d="M160,141.8c0,1,0,1.9,0,2.9c0,2.3,0,4.5,0,6.8c-0.1,3.4-2.8,6-6.2,6.1c-1.3,0-2.7,0-4.2,0v2.9
                c0,3.8,0,7.6,0,11.3c0,1.1-0.4,1.5-1.5,1.5c-2.4,0-4.8,0.1-7.2,0c-1.2,0-1.6,0.4-1.6,1.6c0,6.8,0,13.5,0,20.3c0,1.1-0.1,2.1,0,3.1
                c0.2,1.3-0.3,2.5-1.3,3.4c-1.5,1.5-3,3.1-4.4,4.6c-0.4,0.6-1.1,0.7-1.7,0.4c-0.1-0.1-0.2-0.2-0.3-0.3c-1.7-1.8-3.4-3.6-5.1-5.3
                c-0.7-0.7-1-1.7-0.8-2.6c0.1-1,0.1-2.1,0.1-3.1c0-6.9,0-13.7,0-20.6c0-1-0.3-1.4-1.3-1.4c-2.4,0.1-4.8,0-7.2,0
                c-1.4,0-1.7-0.3-1.7-1.7c0-4.1,0-8.2,0-12.3c0-0.5-0.1-1-0.1-1.6c-1.3,0-2.5,0-3.7,0c-3.5,0.2-6.4-2.5-6.5-5.9c0-0.2,0-0.4,0-0.5
                c-0.1-4.8,0-9.7,0-14.5c0-1.7,0-3.4,0-4.9c-1.2-0.4-2.3-1-3.4-1.6c-2.3-1.6-3.2-3.9-3.2-6.6c0-8.1,0-16.1,0-24.2
                c0-8.5,0-17.1,0-25.6c0-3.6,0-7.2,0-10.8c0-0.8,0.2-1.4,1.1-1.4s1.2,0.6,1.2,1.4c0,0.3,0,0.5,0,0.8c0,5.8,0,11.6,0,17.4
                c0,14.2,0,28.3,0,42.5c0,3.6,2,5.6,5.6,5.6c4,0,8,0,12,0c3.3,0,6.6,0,9.9,0c0.4,0,0.7-0.1,1.2-0.1c-1-1.4-1.9-2.6-2.9-3.8
                c-0.8-0.7-0.8-2-0.1-2.7c1.1-1.5,2.3-3.1,3.5-4.5c0.5-0.5,0.6-1.3,0.3-1.9c-0.6-1.2-1.1-2.4-1.5-3.7c-0.3-0.9-0.9-1.1-1.7-1.2
                c-1.9-0.2-3.8-0.5-5.7-0.8c-0.9,0-1.6-0.7-1.6-1.6c-0.7-4.4-0.7-8.9,0-13.3c0-0.9,0.7-1.6,1.6-1.6c2-0.3,4.1-0.6,6.1-0.8
                c0.6,0,1.1-0.4,1.3-1c0.5-1.4,1.2-2.7,1.6-4.1c0.1-0.5,0.1-1-0.2-1.4c-1.2-1.7-2.4-3.4-3.7-5.1c-0.6-0.6-0.6-1.5,0-2.2
                c2.6-3.7,5.9-6.9,9.6-9.6c0.6-0.5,1.5-0.5,2,0c1.6,1.3,3.3,2.4,4.9,3.7c0.5,0.5,1.2,0.6,1.8,0.2c1-0.6,2-1,3.1-1.3
                c1.1-0.2,1.9-1.1,1.8-2.2c0.2-1.8,0.6-3.7,0.8-5.5c0-0.7,0.6-1.4,1.3-1.4c4.6-0.9,9.4-0.8,14,0.1c0.5,0.2,0.9,0.7,1.1,1.3
                c0.4,2.1,0.7,4.2,0.9,6.3c0,0.6,0.4,1.1,1,1.2c1.1,0.3,2.2,0.8,3.3,1.3c0.9,0.6,2.1,0.5,2.8-0.3c1.4-1.2,3-2.2,4.4-3.4
                c0.6-0.6,1.5-0.6,2.2,0c3.7,2.6,7,5.9,9.6,9.6c0.6,0.6,0.5,1.6,0,2.2c-1.3,1.7-2.6,3.4-3.8,5.2c-0.2,0.4-0.3,0.9-0.1,1.3
                c0.5,1.4,1.1,2.8,1.7,4.2c0.2,0.4,0.5,0.7,0.9,0.8c1.9,0.3,3.9,0.6,5.9,0.8c1.2,0,2.1,0.9,2.2,2l0,0c0.6,4.3,0.6,8.6-0.1,12.9
                c0,0.9-0.8,1.7-1.7,1.7c-1.9,0.2-3.7,0.5-5.6,0.7c-0.9,0-1.6,0.5-1.8,1.4c-0.4,1.2-0.9,2.4-1.5,3.6c-0.3,0.5-0.3,1.2,0.2,1.7
                c1.2,1.6,2.4,3.2,3.7,4.8c0.6,0.7,0.7,1.7,0,2.4c-2.6,3.7-5.8,6.9-9.5,9.4c-0.6,0.6-1.6,0.5-2.1,0c-1.6-1.3-3.3-2.6-5-3.8
                c-0.4-0.2-0.9-0.3-1.4-0.2c-1.3,0.5-2.5,1.1-3.8,1.6c-0.7,0.2-1.3,0.8-1.3,1.6c-0.2,1.9-0.6,3.8-0.7,5.7c0,1-0.8,1.7-1.7,1.7l0,0
                C163.6,141.4,161.9,141.6,160,141.8z
                M137.2,72.1c-3,2.1-5.6,4.6-7.8,7.5c-0.5,0.5-0.5,1.3,0,1.8c0,0,0,0,0.1,0.1
                c1.2,1.5,2.4,3.2,3.6,4.7c0.5,0.6,0.5,1.4,0.1,2.1c-0.9,1.8-1.6,3.7-2.3,5.6c-0.2,0.8-0.9,1.4-1.8,1.5c-2,0.2-4,0.5-6,0.8
                c-0.4,0.1-0.8,0.4-1,0.8c-0.6,3.5-0.6,7,0,10.5c0.1,0.4,0.4,0.6,0.8,0.7c2.1,0.4,4.2,0.7,6.4,0.9c0.8,0,1.4,0.5,1.6,1.3
                c0.6,1.8,1.4,3.6,2.2,5.3c0.6,0.9,0.5,2-0.2,2.8c-1.2,1.5-2.3,3.1-3.5,4.6c-0.5,0.6-0.6,1,0,1.6c2.3,2.3,4.7,4.7,7,7
                c0.6,0.6,1,0.5,1.6,0c1.6-1.2,3.2-2.4,4.8-3.6c0.6-0.5,1.5-0.6,2.2-0.2c1.8,0.8,3.6,1.6,5.4,2.3c0.9,0.2,1.6,1,1.6,2
                c0.2,1.8,0.5,3.7,0.7,5.5c0.1,0.8,0.3,1.4,1.3,1.4c3.2,0,6.4,0,9.7,0c0.9,0,1.2-0.5,1.3-1.3c0.2-2,0.6-3.9,0.8-5.9
                c0-0.8,0.6-1.4,1.3-1.6c1.9-0.7,3.8-1.4,5.6-2.3c0.8-0.5,1.7-0.4,2.4,0.2c1.8,1.4,3.7,2.8,5.8,4.3l8.3-8.3c-1.6-2.1-2.9-4-4.4-5.8
                c-0.6-0.6-0.7-1.6-0.2-2.3c0.9-1.9,1.6-3.8,2.4-5.7c0.2-0.7,0.8-1.2,1.5-1.3c2-0.2,4-0.6,6-0.8c0.9-0.1,1.3-0.5,1.3-1.3
                c0-3.3,0-6.5-0.1-9.8c0-0.8-0.4-1-1.2-1.1c-1.9-0.2-3.8-0.5-5.7-0.7c-0.9,0-1.7-0.7-1.9-1.6c-0.7-1.9-1.5-3.8-2.3-5.6
                c-0.4-0.6-0.3-1.5,0.2-2.1c1.4-1.9,2.8-3.8,4.3-5.7c-2.2-3.1-4.9-5.8-7.9-8c-0.2-0.2-0.9,0-1.2,0.2c-1.7,1.2-3.3,2.5-5,3.6
                c-0.5,0.3-1.2,0.4-1.8,0.2c-2.1-0.8-4.1-1.6-6.1-2.5c-0.5-0.3-0.9-0.8-1.1-1.4c-0.4-1.9-0.6-3.8-0.8-5.7c-0.1-1-0.6-1.5-1.6-1.5
                c-3.1,0-6.1,0-9.2,0c-1,0-1.5,0.5-1.6,1.5c-0.2,1.9-0.5,3.8-0.8,5.7c-0.1,0.7-0.6,1.3-1.3,1.5c-1.9,0.7-3.8,1.4-5.6,2.3
                c-0.8,0.5-1.8,0.4-2.5-0.2C140.8,74.8,139,73.5,137.2,72.1z
                M107.5,146.1h1.7c6.7,0,13.4,0,20.2,0c0.8,0,1.6,0.2,1.6,1.2
                s-0.8,1.2-1.6,1.2c-6,0-12,0-18,0h-3.9c0,0.9,0,1.6,0,2.3c0,3,1.6,4.6,4.6,4.6c5.9,0,11.8,0,17.6,0c7.9,0,15.9,0,23.8,0
                c2.2,0,4-1.3,4.1-3.4c0.2-3.3,0.1-6.6,0.1-9.9c-1.9-0.2-3.7-0.5-5.6-0.7c-0.8,0-1.5-0.7-1.5-1.6c-0.2-2-0.6-4-0.8-6
                c0-0.7-0.5-1.3-1.2-1.5c-1.3-0.4-2.5-1.1-3.8-1.6c-0.5-0.2-1-0.1-1.4,0.1c-1.8,1.2-3.5,2.5-5.2,3.8c-0.6,0.6-1.5,0.6-2,0
                c-1-0.8-2.1-1.7-3.2-2.5c-0.5-0.4-1.1-0.6-1.7-0.6c-7.5,0-14.9,0-22.4,0c-0.4,0-0.9,0-1.4,0.1L107.5,146.1z
                M117.9,171h1.5
                c8.9,0,17.9,0,26.8,0c0.9,0,1.1-0.4,1.1-1.2c0-3.7,0-7.3,0-11c0-0.4,0-0.7-0.1-1.2c-0.4,0-0.7-0.1-1-0.1c-5.8,0-11.6,0-17.4,0
                c-3.3,0-6.5,0-9.8,0c-0.5,0-1.1-0.1-1.1,0.7C117.9,162.5,117.9,166.6,117.9,171L117.9,171z
                M137,173.3c-2.3,0-4.4,0-6.6,0
                c-2.4,0-2.5,0-2.5,2.5c0,4.9,0,9.8,0,14.7c0,2.7,0.2,5.3,0.2,8c0,0.8,0.4,1.6,1,2.1c0.5,0.5,1,1.1,1.5,1.6c0.6,0.6,1.2,1.3,1.8,2
                c1.3-1.4,2.4-2.7,3.7-3.8c0.6-0.5,0.9-1.3,0.9-2.1c0-7.9,0-15.7,0-23.6C137.1,174.2,137.1,173.9,137,173.3L137,173.3z"/>
                <path id="Path_3401" className="st0"
                d="M142.6,256.8c8.9-0.2,17.8-1.4,26.4-3.7c7.4-1.9,14.6-4.5,21.5-7.8
                c13.2-6.3,25.1-14.9,35.2-25.5c16.2-17.1,27-38.6,30.9-61.8c3.4-20.2,1.5-41-5.4-60.3c-4.8-13.5-12.1-25.9-21.4-36.8
                c-0.2-0.3-0.4-0.5-0.7-0.7c-0.1,0-0.1-0.1-0.2-0.1c-5.1,2.2-9.4,1.3-11.7-2.3c-2.3-3.4-1.7-8,1.2-10.8c3.1-2.9,7.9-3,11.2-0.2
                c3.3,2.6,3.7,6.9,1.2,11.4c2.7,3.6,5.6,7.1,8.1,10.8c7.4,10.6,13.1,22.3,16.7,34.7c16.5,55.1-8.2,114.1-58.9,141.1
                c-9.2,4.9-19,8.7-29.2,11.1c-8,1.9-21.9,3.1-24.4,3.2c-4.9,0.2-9.8,0-14.6-0.4c-5-0.5-9.9-1.2-14.8-2.3
                c-6.2-1.5-12.3-3.4-18.2-5.6c-6.8-2.5-20-9.9-20.1-10.1c-0.4-0.3-0.5-0.9-0.2-1.3c0.2-0.5,0.8-0.7,1.3-0.5l0,0l0,0
                c1.6,0.8,3.1,1.8,4.7,2.7c8.4,4.8,17.4,8.5,26.8,11.1c6.7,1.9,13.6,3.2,20.5,3.8c3.3,0.3,6.7,0.4,10.1,0.5
                C139.2,256.9,142,256.8,142.6,256.8z
                M224.1,47.2c-3.3,0-6,2.6-6,5.8c0,3.3,2.6,6,5.8,6c3.3,0,5.9-2.7,5.9-5.9
                C230,49.9,227.4,47.2,224.1,47.2z"/> <path id="Path_3402"
                className="st0"
                d="M20.7,138.3c0.1,13.9,2.6,27.7,7.4,40.8c2.9,8.2,6.8,15.9,11.5,23.1c2.8,4.1,5.8,8,8.7,12
                c0.6,0.9,1.2,1,2.3,0.6c4.1-1.8,9,0,10.8,4.2c1.8,4.1,0,9-4.2,10.8c-0.7,0.3-1.5,0.5-2.3,0.6c-3.2,0.5-6.4-1-8-3.8
                c-1.8-2.7-1.7-6.2,0.1-8.8c0.5-0.5,0.4-1.3-0.1-1.8l0,0c-2.7-3.1-5.2-6.3-7.5-9.6c-2.7-4.2-5.1-8.6-7.5-13.1
                c-5-9.6-8.6-19.8-10.7-30.4c-2.4-11.2-3.1-22.7-2.2-34.2c3.2-41.9,27.9-79.1,65.3-98.2C93.4,25.8,103,22.3,113,20
                c23.9-5.4,48.8-3.4,71.5,5.7c6.3,2.6,12.5,5.6,18.3,9.2c0.3,0.2,0.6,0.4,1,0.6c0.7,0.5,1,1.1,0.5,1.8s-1.1,0.6-1.8,0.2
                c-2.7-1.5-5.3-3.1-8-4.6c-11.9-6.3-24.8-10.4-38.1-12.3c-9.3-1.4-18.7-1.6-28-0.7c-28.1,3-52.3,14.2-72.5,34.1
                c-19.2,19-31.3,44-34.3,70.9C21.1,129.4,21,133.8,20.7,138.3z
                M53.9,228.3c3.2-0.1,5.9-2.6,6.1-5.9c0.1-3.3-2.5-6.1-5.9-6.2
                c-3.3-0.1-6.1,2.5-6.2,5.9c-0.1,3.3,2.5,6.1,5.9,6.2C53.7,228.3,53.8,228.3,53.9,228.3z"/>
                <path id="Path_3403" className="st0"
                d="M78.6,65.9c2.3,4.5,1.5,8.9-2.1,11.4c-3.7,2.7-9,1.9-11.7-1.8c-2.2-3-2.2-7,0.1-10
                c2.7-3.7,7-4.2,11.8-1.7c4.3-2.9,8.4-6,12.7-8.6c8.3-5.2,17.4-8.9,27-11.1c4.2-1.1,8.5-1.9,12.8-2.4c6-0.3,12-0.4,18-0.1
                c6.6,0.4,13.2,1.5,19.5,3.5c8.1,2.3,15.8,5.7,23,10.1c20.9,12.9,35.1,31,42.4,54.5c3.8,12,5.1,24.6,4,37.1
                c-1.2,13.5-5.2,26.5-11.8,38.3c-0.4,0.8-1,1.4-1.9,0.9s-0.6-1.2-0.2-2c4-7.1,7-14.7,9-22.6c2.8-11.1,3.6-22.6,2.2-34
                c-1.8-15.8-7.6-30.9-16.8-43.9c-6.8-9.7-15.4-18-25.4-24.5c-9.6-6.3-20.3-10.8-31.5-13.2c-7.5-1.7-15.2-2.4-22.9-2.1
                c-3.9,0.1-7.8,0.5-11.6,1c-16.5,2.5-32.2,9.4-45.2,20c-0.3,0.3-0.7,0.5-1,0.8C78.9,65.6,78.8,65.8,78.6,65.9z
                M65.6,70.7
                c0.1,3.3,2.8,6,6.1,5.9c3.3-0.1,6-2.8,5.9-6.1c-0.1-3.3-2.8-6-6.1-5.9C68.2,64.7,65.6,67.4,65.6,70.7z"/>
                <path id="Path_3404" className="st0"
                d="M201.4,213c-3.5,2.4-6.9,5-10.4,7.2c-6.9,4.4-14.4,7.8-22.2,10.2c-11.5,3.6-23.5,5-35.6,4.3
                c-8.1-0.5-16-2-23.7-4.5C96.6,226,84.7,219.1,74.6,210c-13.4-12.2-23.1-27.9-28.1-45.3C43.4,154,42.2,143,42.9,132
                c0.2-5.9,1-11.8,2.5-17.5c2.3-8.1,5.4-16,9.4-23.4c0.4-0.7,0.9-1.3,1.8-0.8c0.8,0.5,0.7,1.2,0.3,2c-3.3,5.8-5.9,12-7.8,18.4
                c-0.9,3.1-1.5,6.2-2.2,9.4c-1.5,7-2.2,14.2-1.9,21.3c0.3,9.8,2.2,19.5,5.6,28.7c1.6,4.3,3.4,8.4,5.5,12.4
                c5.1,9.7,11.8,18.4,19.9,25.7c13.4,12.3,30.1,20.3,48,23.2c8.7,1.4,17.5,1.7,26.2,0.7c6.8-0.8,13.5-2.3,20-4.5
                c6-2,11.7-4.6,17.1-7.8c4-2.4,7.7-5.2,11.5-7.9c0.7-0.5,0.8-0.9,0.4-1.8c-1.5-3.2-0.9-7,1.6-9.4c2.4-2.4,5.9-3.1,9.1-1.9
                c3.3,1.3,5.4,4.5,5.2,8c0,2.9-1.6,5.6-4.2,7c-2.6,1.5-5.8,1.5-8.4-0.1C202.1,213.5,201.7,213.2,201.4,213z
                M212.8,206.7
                c0.1-3.3-2.6-6.1-5.9-6.2s-6.1,2.6-6.2,5.9c-0.1,3.3,2.5,6.1,5.8,6.2C209.9,212.7,212.7,210,212.8,206.7L212.8,206.7z"/>
                <path id="Path_3405" className="st0"
                d="M138.7,212.6h35.9c0.4,0,1.1,0,1.1,0s1.2,0,1.3,1c0,1.1-1.3,1.2-1.3,1.2c-0.4,0-0.8,0-1.1,0
                c-9.8,0-19.6,0.1-29.4,0.1c-11.6,0-23.1,0-34.6-0.1c-2.9,0-5.9,0-8.8,0c-0.8,0-1.8,0-1.7-1.1c0-1,1-1.1,1.8-1.1
                C114.2,212.7,126.4,212.7,138.7,212.6L138.7,212.6z"/> <path
                id="Path_3406" className="st0"
                d="M167.4,199.4c5.7,0,11.3,0,17,0c0.4,0,0.8,0,1.2,0.1c0,0,0.9,0.2,0.9,0.9
                c0.1,0.5-0.3,1.1-0.8,1.2c-0.4,0.1-0.8,0.1-1.2,0.1c-11.5,0-22.9,0-34.4,0c-0.4,0-0.7,0-1.1-0.1c-0.5-0.2-0.8-0.6-0.8-1.1
                c0-0.7,0.9-1,0.9-1c0.3-0.1,0.7-0.1,1,0L167.4,199.4L167.4,199.4z"/>
                <path id="Path_3407" className="st0"
                d="M104.6,199.5c4.1,0,8.1,0,12.2,0c0.7,0,1.8-0.2,1.8,0.9c0,1.1-0.9,1.2-1.8,1.2
                c-8.3,0-16.5,0-24.8,0c-0.8,0-1.5-0.2-1.6-1.1c0-0.9,0.8-1.1,1.5-1.1C96.2,199.5,100.4,199.5,104.6,199.5L104.6,199.5z"/>
                <path id="Path_3408" className="st0"
                d="M158.9,81.5c4.1-0.1,8.1,1.1,11.5,3.4c0.3,0.2,0.6,0.4,0.8,0.6c0.6,0.5,0.8,1,0.3,1.6
                c-0.4,0.6-1.1,0.7-1.7,0.3c0,0-0.1,0-0.1-0.1c-1.6-1-3.3-1.8-5-2.6c-12.1-4.4-25.2,5.6-24.3,18.5c0.6,10.1,9.2,17.9,19.4,17.3
                c6.9-0.4,13.1-4.6,15.8-11c1.3-2.8,1.8-6,1.5-9.1c0-0.2,0-0.5-0.1-0.8c-0.1-0.8,0.2-1.5,1.1-1.5c0.8-0.1,1.2,0.5,1.3,1.2
                c0.2,1.7,0.2,3.4,0.1,5.2c-1.3,11.4-11.7,19.6-23.1,18.3c-7.2-0.8-13.4-5.3-16.5-11.9c-2.8-5.9-2.7-12.7,0.5-18.4
                c3.2-6.2,8.5-9.8,15.4-11c0.2,0,0.5-0.1,0.8-0.1C157.4,81.5,158.1,81.5,158.9,81.5z"/>
                <path id="Path_3409" className="st0"
                d="M157.3,109.3c3.2-3.1,6.3-6.2,9.4-9.2c4.1-4.1,8.2-8.1,12.3-12.2c0.1-0.1,0.2-0.2,0.4-0.3
                c0.6-0.5,1.3-0.8,1.9-0.1s0.4,1.3-0.2,1.9c-1.9,1.9-3.9,3.8-5.8,5.8c-5.2,5.2-10.5,10.3-15.7,15.5c-2.2,2.2-2.2,2.2-4.4,0
                c-3.4-3.4-6.9-6.9-10.3-10.3c-0.2-0.2-0.4-0.3-0.5-0.5c-0.5-0.6-0.8-1.2-0.1-1.8c0.5-0.5,1.3-0.5,1.8,0c0.1,0,0.1,0.1,0.1,0.2
                c1.5,1.5,3,3,4.5,4.4C152.8,104.7,155,106.9,157.3,109.3z"/> <path
                id="Path_3410" className="st0"
                d="M143.4,148.4c-0.8,0-1.6,0-2.4,0c-0.7,0-1.4-0.3-1.4-1.1s0.6-1.2,1.3-1.2c1.7,0,3.4-0.1,5.2,0
                c0.6,0,1.1,0.5,1.1,1.1s-0.5,1.1-1.1,1.1C145.2,148.4,144.3,148.4,143.4,148.4L143.4,148.4z"/>
                </g> </g> <g
                id="Layer_1_00000114050713444373084820000004744444597391932859_">
                </g> </svg>
            </SvgIcon>
            }
            severity="success"
            onClose={async () => {
                await ack(supplierId);
            }}
        >
        <AlertTitle>Your facility is now verified. Thank you!</AlertTitle>
        Your information was reviewed and approved. You can now begin quoting
        RFQs and receiving POs from SyncFab.
    </Alert>
)
}

export default withStyles(styles)(Al);