import * as React from 'react'
import { WithStyles, withStyles, Drawer, List, ListItemIcon, ListItem, ListItemText, Typography } from '@material-ui/core'
import Add from '@material-ui/icons/Add'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import {
    OrderIcon,
    QuoteIcon,
    PartIcon,
    Button,
    User,
    AnalyticsIcon,
    SuppliersIcon,
    FeaturePackage, LoginRole
} from '@syncfab/machine'
import { withNamespaces, WithNamespaces } from 'react-i18next'
import { NavLink } from 'react-router-dom'
import { State } from '../application/Store'
import AccountTreeIcon from '@material-ui/icons/AccountTree'
import { Book } from '@material-ui/icons'
//import FindInPageIcon from '@material-ui/icons/FindInPage'

const transition = 'width' // 100ms
const enum MenuVisibility {
    ONLY_SUPPLIER = 'ONLY_SUPPLIER',
    ONLY_BUYER = 'ONLY_BUYER',
    BOTH = 'BOTH'
}

const enum MenuItem {
    RFQINBOX = 'RFQInbox',
    POINBOX = 'POInbox',
    PARTSLIBRARY = 'PartsLibrary',
    ANALYTICS = 'Analytics',
    SUPPLIERS = 'Suppliers',
    TRACKING = 'DLTTracking',
    MFG = 'MFGRewards',
    PUBLICATIONS = 'Publications',
}

const featurePackageMap = {
    [FeaturePackage.BASIC]: [MenuItem.RFQINBOX, MenuItem.POINBOX, MenuItem.PARTSLIBRARY, MenuItem.PUBLICATIONS],
    [FeaturePackage.SUPPLIER_ANALYTICS]: [MenuItem.SUPPLIERS, MenuItem.ANALYTICS, MenuItem.TRACKING, MenuItem.MFG]
}

interface SidebarMenuItem {
    menuItem: MenuItem
    text: string
    icon: JSX.Element
    url: string
    visibility: MenuVisibility
}

const styles = theme => ({
    drawer: {
        width: theme.spacing(30),
        flexShrink: 0,
    },
    drawerPaper: {
        width: theme.spacing(30),
        transition,
        display: 'flex',
    },
    collapsedDrawer: {
        width: theme.spacing(9),
        flexShrink: 0,
    },
    collapsedDrawerPaper: {
        width: theme.spacing(9),
        transition,
        display: 'flex',
        'align-items': 'center',
    },
    toolbar: theme.mixins.toolbar,
    item: {
        color: theme.palette.common.white,
        textDecoration: 'none',
        fontWeight: theme.typography.fontWeightMedium,
    },
    orderRequest: {
        'margin-left': theme.spacing(4),
        'margin-right': theme.spacing(4),
        width: '70%',
        minHeight: '36px',
    },
    orderRequestFAB: {
        minWidth: theme.spacing(3),
        width: 40,
    },
    activeStyle: {
        color: theme.palette.primary.main,
        '& p': {
            color: theme.palette.primary.main,
        },
        '& path': {
            fill: theme.palette.primary.main,
        },
    },
    Icon: {
        color: '#ffffff',
    },
})

interface SideBarProps extends WithStyles, WithNamespaces {
    authToken?: string
    sideBarCollapsed?: boolean
    loggedUser?: User
    showSupplierTab?: boolean
    balance?: any
}

const sidebarMenuItems: SidebarMenuItem[] = [
    {
        menuItem: MenuItem.RFQINBOX,
        text: 'side-menu-quote',
        icon: <QuoteIcon />,
        url: '/quotes',
        visibility: MenuVisibility.BOTH,
    },
    {
        menuItem: MenuItem.POINBOX,
        text: 'side-menu-order',
        icon: <OrderIcon />,
        url: '/purchaseOrders',
        visibility: MenuVisibility.ONLY_BUYER,
    },
    {
        menuItem: MenuItem.POINBOX,
        text: 'side-menu-order',
        icon: <OrderIcon />,
        url: '/supplierPurchaseOrders',
        visibility: MenuVisibility.ONLY_SUPPLIER,
    },
    {
        menuItem: MenuItem.PARTSLIBRARY,
        text: 'side-menu-parts-library',
        icon: <PartIcon />,
        url: '/parts',
        visibility: MenuVisibility.ONLY_BUYER,
    },
    {
        menuItem: MenuItem.PUBLICATIONS,
        text: 'Documentation',
        icon: <Book style={{color:'#ffffff'}}/>,
        url: '/documentation',
        visibility: MenuVisibility.BOTH,
    },
    {
        menuItem: MenuItem.ANALYTICS,
        text: 'side-menu-analytics',
        icon: <AnalyticsIcon />,
        url: '/dashboard',
        visibility: MenuVisibility.ONLY_BUYER,
    },
    {
        menuItem: MenuItem.SUPPLIERS,
        text: 'side-menu-suppliers',
        icon: <SuppliersIcon />,
        url: '/suppliers',
        visibility: MenuVisibility.ONLY_BUYER,
    },
    {
        menuItem: MenuItem.TRACKING,
        text: 'side-menu-dlt-tracking',
        icon: <AccountTreeIcon style={{ color: '#fff' }} />,
        url: '/track',
        visibility: MenuVisibility.ONLY_BUYER,
    },
    // {
    //     menuItem: MenuItem.MFG,
    //     text: 'side-menu-mfg-wallet',
    //     icon: <MFGIcon />,
    //     url: '/wallet',
    //     visibility: MenuVisibility.BOTH,
    // },
]

function userHasFeaturePackage(loggedUser: any, featurePackage: FeaturePackage): boolean {
    return loggedUser?.company?.featurePackage?.includes(featurePackage)
}

const RequestOrderButton = ({
    sideBarCollapsed,
    classes,
    label,
    loggedUser,
}: { sideBarCollapsed: boolean; label: string; loggedUser: any } & WithStyles) => {

    if (loggedUser?.loginRole === LoginRole.BUYER && userHasFeaturePackage(loggedUser, FeaturePackage.BASIC)) {
        return (
            <Link to="/quotes/request" className={classes.item}>
                {!!sideBarCollapsed && (
                    <Button vairant="fab" color="primary" aria-label="Add" className={classes.orderRequestFAB}>
                        <Add />
                    </Button>
                )}
                {!sideBarCollapsed && (
                    <Button color="primary" className={classes.orderRequest}>
                        <Add />
                        <ListItemText>
                            <Typography className={classes.item}>{label?.toUpperCase()}</Typography>
                        </ListItemText>
                    </Button>
                )}
            </Link>
        )
    } else {
        return null
    }
}

const sideBar = ({ classes, authToken, sideBarCollapsed, t, loggedUser }: SideBarProps) => {
    if (!authToken) {
        return null
    }

    const handleClick = (e, linkDisabled) => {
        if (linkDisabled) e.preventDefault()
    }

    const userFeaturePackage: FeaturePackage[] = loggedUser?.company?.featurePackage ?? []
    let userMenuItems = []
    userFeaturePackage.forEach(fp => {
        userMenuItems = userMenuItems.concat(featurePackageMap[fp])
    })
    // get distinct list of menu items. more than one feature may give access to the same menu items
    userMenuItems = [...(new Set(userMenuItems))]

    // filter sidebar menu items based on user's login role
    let availableSidebarMenuItems: SidebarMenuItem[] = []
    if (loggedUser?.loginRole === LoginRole.BUYER) {
        availableSidebarMenuItems = sidebarMenuItems.filter(item =>
             item.visibility === MenuVisibility.BOTH || item.visibility === MenuVisibility.ONLY_BUYER)
    } else if (loggedUser?.loginRole === LoginRole.SUPPLIER) {
        availableSidebarMenuItems = sidebarMenuItems.filter(item =>
            item.visibility === MenuVisibility.BOTH || item.visibility === MenuVisibility.ONLY_SUPPLIER)
    }

    return (
        <Drawer
            className={sideBarCollapsed ? classes.collapsedDrawer : classes.drawer}
            variant="permanent"
            classes={{
                paper: sideBarCollapsed ? classes.collapsedDrawerPaper : classes.drawerPaper,
            }}
        >
        <div className={classes.toolbar} />
        <RequestOrderButton sideBarCollapsed={sideBarCollapsed} classes={classes} label={t('side-menu-request')} loggedUser={loggedUser} />
        <List>
            {availableSidebarMenuItems.map(item => {
                // if a buyer user does not have access to a particular menu item based on their feature package,
                // show it as disabled.
                const isDisabled = loggedUser.loginRole === LoginRole.BUYER && !userMenuItems.includes(item.menuItem)

                return (
                    <NavLink
                        to={item.url}
                        className={classes.item}
                        key={item.text}
                        activeClassName={classes.activeStyle}
                        onClick={(e: any) => handleClick(e, isDisabled)}
                    >
                        <ListItem button style={{ color: '#fff' }} disabled={isDisabled}>
                            <ListItemIcon>{item.icon}</ListItemIcon>
                            {!sideBarCollapsed && (
                                <ListItemText>
                                    <Typography className={classes.item}>{t(item.text)?.toUpperCase()}</Typography>
                                </ListItemText>
                            )}
                        </ListItem>
                    </NavLink>
                )})
            }
            </List>
        </Drawer>
    )
}

const mapStateToProps = ({ auth: { authToken }, layout: { sideBarCollapsed }, user: { loggedUser }, supplier: { showSupplierTab }, transaction: { balance } }: State) => {
    return {
        authToken,
        sideBarCollapsed,
        loggedUser,
        showSupplierTab,
        balance,
    }
}

export const SideBar = connect(mapStateToProps, null, null)(withNamespaces()(withStyles(styles)(sideBar)))
