import { TypographyOptions } from '@material-ui/core/styles/createTypography'

export const typography: TypographyOptions = {
    fontFamily: `'Titillium Web', 'sans-serif'`,
    fontSize: 14,
    fontWeightLight: 300,
    fontWeightRegular: 400,
    fontWeightMedium: 600,
    h1: {
        fontSize: 96,
        letterSpacing: -1.5,
        color: 'black',
        fontWeight: 600,
    },
    h2: {
        fontSize: 60,
        letterSpacing: -0.5,
        color: 'black',
        fontWeight: 600,
    },
    h3: {
        fontSize: 40,
        letterSpacing: 0,
        color: 'black',
        fontWeight: 600,
    },
    h4: {
        fontSize: 34,
        letterSpacing: '2.85px',
        color: 'black',
        fontWeight: 600,
    },
    h5: {
        fontSize: 24,
        letterSpacing: 0,
        color: 'black',
        fontWeight: 600,
    },
    h6: {
        fontSize: 20,
        letterSpacing: 0.25,
        color: 'black',
    },
    body1: {
        fontSize: 16,
        letterSpacing: 0.25,
        color: 'black',
    },
    body2: {
        fontSize: 14,
        letterSpacing: 0.25,
        color: 'black',
    },
    caption: {
        fontSize: 12,
        letterSpacing: 0.25,
        color: 'black',
    },
    button: {
        fontSize: 16,
        textTransform: 'uppercase',
        fontWeight: 600,
    },
}
