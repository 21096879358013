import { call, takeLatest, getContext, put } from 'redux-saga/effects'
import { Gateway } from '../gateway'
import { GET_BLOCKCHAIN_ORDER } from './Actions'

function* get({ data: { id } }: { data: { id: string } }) {
    try {
        const gateway: Gateway = yield getContext('gateway')
        const { data } = yield call(gateway.getBlockchainAPI().get(id))
        yield put(GET_BLOCKCHAIN_ORDER.actions.SUCCESSFULL(data))
    } catch ({ response: { status, data } }) {
        const handleError = yield getContext('handleError')
        yield call(handleError(status))
        yield put(GET_BLOCKCHAIN_ORDER.actions.FAILED(data))
    }
}

function* blockchainSaga() {
    // Typings are not good enough. String should be valid but the types
    // do not show it
    yield takeLatest(GET_BLOCKCHAIN_ORDER.types.TRIGGER as any, get)
}

export default blockchainSaga
