import React from 'react'
import { Switch as MUISwitch, FormControlLabel, FormControl } from '@material-ui/core'
import { FormFieldFooter } from '../Error'

export const Switch = MUISwitch

export const SwitchComponent = ({ name, value, onChange, label }) => {
    return (
        <FormControl>
            <FormControlLabel control={<Switch checked={value ? true : false} onChange={onChange} value={value} color="primary" />} label={label} name={name} />
        </FormControl>
    )
}

const SwitchField = ({ input, label, value, meta: { touched, error }, labelPlacement = 'end', disabled }: any) => {
    return (
        <React.Fragment>
            <FormControl error={!!touched && !!error}>
                <FormControlLabel
                    labelPlacement={labelPlacement}
                    control={<Switch checked={input.value ? true : false} onChange={input.onChange} value={value} color="primary" disabled={!!disabled} />}
                    label={label}
                    name={input.name}
                />
            </FormControl>
            <FormFieldFooter touched={touched} error={error} />
        </React.Fragment>
    )
}

export { SwitchField }
