import React from 'react'
import { AppBar, Toolbar, withStyles, IconButton } from '@material-ui/core'
import { connect } from 'react-redux'
import { Dispatch } from 'redux'
import { WithStyles } from '@material-ui/core/styles'
import { LOGOUT, PUSH, colors, Button, Logo } from '@syncfab/machine'
import { withNamespaces, WithNamespaces } from 'react-i18next'
import { TOGGLE_SIDE_BAR } from './Actions'
import Menu from '@material-ui/icons/Menu'
import UserMenu from './UserMenu'
import { Link } from 'react-router-dom'
import { RouteComponentProps, withRouter } from 'react-router'
// import MFGMenu from './MFGMenu'

const styles = theme => ({
    grow: {
        flexGrow: 1,
        display: 'flex',
        flexDirection: 'row' as any,
        alignItems: 'center',
    },
    navigation: {
        backgroundColor: colors.surfaceDark,
        zIndex: theme.zIndex.drawer + 1,
    },
    button: {
        color: theme.palette.common.white,
    },
    menuButton: {
        marginLeft: -12,
        marginRight: 20,
    },
    logoLink: {
        height: theme.spacing(3),
    },
})

interface NavigationProps extends WithNamespaces, WithStyles, RouteComponentProps {
    signup: () => void
    signin: () => void
    supplierSignup: () => void
    supplierSignin: () => void
    toggleSideBar: () => void
    loginSection: () => void
    authToken?: string
}

class Navigation extends React.Component<NavigationProps> {
    render() {
        const {
            toggleSideBar,
            //signup,
            classes,
            authToken,
            t,
            location: { pathname },
            //signin,
            //supplierSignup,
            //supplierSignin,
            loginSection,
        } = this.props
        return (
            <AppBar position="fixed" className={classes.navigation}>
                <Toolbar disableGutters={false} variant="dense">
                    <section className={classes.grow}>
                        {!!authToken && (
                            <IconButton className={classes.menuButton} color="inherit" aria-label="Menu" onClick={toggleSideBar}>
                                <Menu />
                            </IconButton>
                        )}
                        <Link className={classes.logoLink} to={!!authToken ? '/quotes' : '/login'}>
                            <Logo />
                        </Link>
                    </section>
                    {/* !!authToken && <MFGMenu /> */}
                    {!!authToken && <UserMenu />}
                    {/* {!authToken && pathname === '/login' && (
                        <Button color="primary" variant="text" onClick={supplierSignup} className={classes.button}>
                            {t('navigation-signup-supplier')}
                        </Button>
                    )} */}
                    {/* {!authToken && (
                        <Button color="primary" variant="text" onClick={supplierSignin} className={classes.button}>
                            {t('navigation-supplier-signin')}
                        </Button>
                    )} */}
                    {/* {!authToken && pathname === '/login' && (
                        <Button color="primary" variant="text" onClick={signup} className={classes.button}>
                            {t('navigation-signup')}
                        </Button>
                    )} */}
                    {!authToken && pathname === '/signup' && (
                        <Button color="primary" variant="text" onClick={loginSection} className={classes.button}>
                            {t('navigation-signin')}
                        </Button>
                    )}
                    {/* {!authToken && pathname === '/user/login' && (
                        <Button color="primary" variant="text" onClick={loginSection} className={classes.button}>
                            {t('navigation-signup')}
                        </Button>
                    )} */}
                    {!authToken && pathname === '/suppliers/signup' && (
                        <Button color="primary" variant="text" onClick={loginSection} className={classes.button}>
                            {t('navigation-signin')}
                        </Button>
                    )}
                    {/* {!authToken && pathname === '/suppliers/login' && (
                        <Button color="primary" variant="text" onClick={loginSection} className={classes.button}>
                            {t('navigation-signup-supplier')}
                        </Button>
                    )} */}
                </Toolbar>
            </AppBar>
        )
    }
}

const mapStateToProps = ({ auth: { authToken } }) => {
    return {
        authToken,
    }
}

const mapDispatchToProps = (dispatch: Dispatch) => {
    return {
        logout: () => dispatch(LOGOUT()),
        toggleSideBar: () => dispatch(TOGGLE_SIDE_BAR()),
        signup: () => dispatch(PUSH('/signup')),
        signin: () => dispatch(PUSH('/user/login')),
        loginSection: () => dispatch(PUSH('/login')),
        supplierSignup: () => dispatch(PUSH('/suppliers/signup')),
        supplierSignin: () => dispatch(PUSH('/suppliers/login')),
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withNamespaces()(withStyles(styles)(withRouter(Navigation))))
