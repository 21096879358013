import React from 'react'
import { withNamespaces, WithNamespaces } from 'react-i18next'
import { Dialog, DialogTitle, DialogContent } from '@material-ui/core'
import { LineItemForm } from './LineItemForm'

interface DialogProps extends WithNamespaces {
    open: boolean
    close: () => void
    partOptions: any[]
    onSubmit: () => void
}

const dialog = ({ onSubmit, partOptions, t, open, close }: DialogProps) => {
    return (
        <Dialog open={open} aria-labelledby="address-dialog-title" onClose={close} fullWidth>
            <DialogTitle id="address-dialog-title">{`${t('part-lineitem-add')}`}</DialogTitle>
            <DialogContent>
                <LineItemForm onSubmit={onSubmit} partOptions={partOptions} cancel={close} />
            </DialogContent>
        </Dialog>
    )
}

export const LineItemDialog = withNamespaces()(dialog)
