import {
    DateFormatter,
    Document,
    DocumentCardList,
    DocumentManager,
    DocumentMenuOption,
    DocumentType,
    EntityType,
    FileExtensions,
    getBuyerPurchaseOrderSummary,
    HeaderNavLink,
    List,
    ListMeta,
    Loader,
    MIMEFileType,
    PDFViewer,
    PurchaseOrderLineItem,
    PurchaseOrderStatus,
    PurchaseOrderTotalSummary,
    SHOW_FEEDBACK,
    SortDirection,
    SupplierPurchaseOrderLineItemQuery,
    useGetPurchaseOrderQuery,
    useListPurchaseOrderLineItemsQuery,
    usePatchPurchaseOrderMutation,
    useStandardStyles,
} from "@syncfab/machine";
import Helmet from "react-helmet";
import { Box, Button, Dialog, DialogContent, Divider, Grid, Paper, Typography, useTheme } from "@material-ui/core";
import React, { FC, useEffect, useState } from "react";
import { useParams } from "react-router";
import { RFQPORequirements } from "../quotes/details/components/RFQPORequirements";
import InfoIcon from "@material-ui/icons/Info";
import TodayIcon from "@material-ui/icons/Today";
import AccessTimeIcon from "@material-ui/icons/AccessTime"
import { skipToken } from "@reduxjs/toolkit/query";
import { useDispatch, useSelector } from "react-redux";
import { SupplierPOStatusChip } from "./components/SupplierPOStatusChip";
import { ChangeRequest } from "../quotes/details/components/ChangeRequest";
import { BuyerPurchaseOrderLineItemRow } from "./components/BuyerPurchaseOrderLineItemRow";

export const ViewBuyerPurchaseOrder: FC = (() => {

    const standardClasses = useStandardStyles()
    const theme = useTheme()

    const token = useSelector((state: any) => state.auth.authToken)
    const dispatch = useDispatch()

    const { purchaseOrderId } = useParams<{ purchaseOrderId?: string }>()

    const [documentIdToView, setDocumentIdToView] = useState<string>('')
    const [isPDFViewerOpen, setIsPDFViewerOpen] = useState<boolean>(false)
    const [documentEntity, setDocumentEntity] =
        useState<{ entityId: string, entityType: EntityType}>(null)

    const [documents, setDocuments] = useState<Document[]>([])

    const [isChangeRequestDialogOpen, setIsChangeRequestDialogOpen] =
        useState<boolean>(false)

    const cancelChangeRequestDialog = () => {
        setIsChangeRequestDialogOpen(false)
    }

    const completeChangeRequest = () => {
        setIsChangeRequestDialogOpen(false)
    }

    const [listParams, setListParams] = useState<SupplierPurchaseOrderLineItemQuery>({
        page: 0,
        size: 10,
        sortField: '_id',
        sortDirection: SortDirection.DESC,
        searchTerm: '',
        poId: ''
    })

    const poLineItemColumns = [
        { id: 'col1', label: 'Line ID', fixed: true },
        { id: 'col2', label: 'Line Details', fixed: true },
        { id: 'col3', label: 'Line Requirements', fixed: true },
        { id: 'col4', label: 'Quantities', fixed: true },
        { id: 'col5', label: 'Documents', fixed: true },
        { id: 'col6', label: 'Charges', fixed: true }
    ]

    const {
        data: poData,
        isLoading: isPODataLoading,
        isError: isPODataError,
        error: poDataError
    } = useGetPurchaseOrderQuery(purchaseOrderId)

    useEffect(() => {
        if (!!poData) {
            setListParams({ ...listParams, poId: poData._id })
            setDocuments(poData.additionalDocuments)
        }
    }, [poData])

    const {
        data: poLineItemData,
        isLoading: isPOLineItemDataLoading,
        // refetch: refetchPOLineItems
    } = useListPurchaseOrderLineItemsQuery(!!listParams.poId ? listParams: skipToken)

    const handleListMetaChange = (meta: ListMeta) => {
        setListParams({ ...listParams, ...meta })
    }

    const handleViewDocument = ((docId: string, entityId: string, entityType: EntityType) => {
        setDocumentEntity({ entityId, entityType })
        setDocumentIdToView(docId)
        setIsPDFViewerOpen(true)
    })

    const [updatePO] = usePatchPurchaseOrderMutation()

    const handleAllFilesProcessed = async () => {
        try {
            await updatePO({
                poId: purchaseOrderId,
                poInput: { additionalDocumentIds: documents.map(doc => doc._id) }
            }).unwrap()
            dispatch(SHOW_FEEDBACK('Documents uploaded.'))
        } catch(error) {
            dispatch(SHOW_FEEDBACK(error.data))
        }
    }

    const handleRemoveDocument = async (docIds: string[], docId: string) => {
        try {
            const doc = documents.find(doc => doc._id === docId)
            if (doc.visibility )

            await updatePO({
                poId: purchaseOrderId,
                poInput: { additionalDocumentIds: docIds.filter(id => id !== docId) }
            })
            dispatch(SHOW_FEEDBACK('Document removed.'))
        } catch(error) {
            dispatch(SHOW_FEEDBACK(error.data))
        }
    }

    if (isPODataLoading || isPOLineItemDataLoading) {
        return <Loader/>
    }

    if (isPODataError) {
        // @ts-ignore
        const errorStatusCode = poDataError.status
        return (
            <div className={standardClasses.centeredAlignedContainer}>
                {errorStatusCode === 401 ?
                    (
                        <Typography style={{ fontSize: 32, fontWeight: 'bold' }}>
                            You are not authorized to view this Purchase Order.
                        </Typography>
                    ) : (
                        <Typography style={{ fontSize: 32, fontWeight: 'bold' }}>
                            An error has occurred.
                        </Typography>
                    )
                }
            </div>
        )
    }

    if (!!poData && !!poLineItemData) {
        const poSummary = getBuyerPurchaseOrderSummary(poData.lineItems)
        return (
            <div className={standardClasses.centeredAlignedContainer}>
                <Helmet>
                    <title>Purchase Orders</title>
                </Helmet>
                <HeaderNavLink linkTo="/purchaseOrders" linkText="BACK TO PO LIST"/>
                <Grid container className={standardClasses.header} spacing={2}>
                    <Grid item xs={12} md={8}>
                        <Typography
                            style={{
                                marginBottom: '8px',
                                fontSize: 32,
                                fontWeight: 'bold'
                            }}>{`PO ${poData.buyerPOReference}`}
                        </Typography>
                    </Grid>
                </Grid>
                <Grid container spacing={2}>
                    <Grid item xs={12} md={8}>
                        <Paper className={standardClasses.paper}>
                            <Typography style={{ padding: '16px', fontSize: '20px', fontWeight: 'bold' }}>
                                Line Items
                            </Typography>
                            {!isPOLineItemDataLoading && poLineItemData.data.length > 0 &&
                                <List<PurchaseOrderLineItem>
                                    columns={poLineItemColumns}
                                    data={poLineItemData}
                                    meta={listParams}
                                    onMetaChange={handleListMetaChange}
                                    renderRow={(row: PurchaseOrderLineItem) => {
                                        return <BuyerPurchaseOrderLineItemRow
                                            key={row._id}
                                            poLineItem={row}
                                            handleViewDocument={handleViewDocument}
                                            token={token}
                                        />
                                    }}
                                />
                            }
                        </Paper>
                        <RFQPORequirements rfqOrPO={poData} entityType='PO'/>
                        {poData.additionalDocuments?.length > 0 && (
                            <Paper className={standardClasses.paper}>
                                <Grid item xs={12} style={{ padding: '16px' }}>
                                    <Typography gutterBottom style={{ fontSize: 20, fontWeight: 'bold' }}>
                                        PO Documents
                                    </Typography>
                                </Grid>
                                <Divider style={{ width: '100%', color: "#FFFFFF40" }}/>
                                <Grid item xs={12} style={{ padding: '24px' }}>
                                    <DocumentManager
                                        label=''
                                        documents={documents}
                                        setDocuments={setDocuments}
                                        setAreDocumentsDirty={() => {
                                        }}
                                        docType={DocumentType.SUPPORT_FILE}
                                        referenceId={poData.buyerCompany.companyId}
                                        maxFileSizeMB={20}
                                        acceptedFileTypes={[MIMEFileType.PDF]}
                                        acceptedFileExtensions={[FileExtensions.PDF]}
                                        fileAreaCaption='Accepting .pdf - Max File Size: 20MB'
                                        showVisibility={false}
                                        showType={false}
                                        showRevision={false}
                                        handleRemoveDocument={handleRemoveDocument}
                                        handleAllFilesProcessed={handleAllFilesProcessed}
                                        entity={{ entityType: EntityType.PURCHASEORDER, entityId: poData._id }}
                                    />
                                </Grid>
                            </Paper>
                        )}
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <Paper className={standardClasses.paper}>
                            <Grid container direction='column' style={{ padding: '16px' }}>
                                <Typography style={{ fontSize: 20, fontWeight: 'bold' }}>PO Action Items</Typography>
                                <Box style={{ paddingTop: '16px', display: 'flex' }}>
                                    <InfoIcon fontSize='medium' style={{ color: '#A0AEC0' }}/>
                                    <Typography style={{
                                        color: '#A0AEC0',
                                        fontWeight: 'bold',
                                        marginLeft: '8px',
                                        marginRight: '8px'
                                    }}>
                                        PO Status:
                                    </Typography>
                                    <SupplierPOStatusChip status={poData.status as PurchaseOrderStatus}/>
                                </Box>
                                <Box style={{ paddingTop: '16px', display: 'flex' }}>
                                    <TodayIcon fontSize='medium' style={{ color: '#A0AEC0' }}/>
                                    <Typography style={{ color: '#A0AEC0', fontWeight: 'bold', marginLeft: '8px' }}>
                                        PO Issued:&nbsp;&nbsp;
                                        <span style={{ color: '#F3F3F3' }}>
                                        {poData.createdAt ? DateFormatter.format(
                                            new Date(poData.createdAt)) : '--'}
                                    </span>
                                    </Typography>
                                </Box>
                                <Box style={{ paddingTop: '16px', display: 'flex' }}>
                                    <AccessTimeIcon fontSize='medium' style={{ color: '#A0AEC0' }}/>
                                    <Typography style={{ color: '#A0AEC0', fontWeight: 'bold', marginLeft: '8px' }}>
                                        Final Due Date:&nbsp;&nbsp;
                                        <span style={{ color: '#F3F3F3' }}>
                                        {poData.initialPromiseDate ? DateFormatter.format(
                                                new Date(poData.initialPromiseDate)) : '--' }
                                    </span>
                                    </Typography>
                                </Box>
                                <Box style={{ display: 'flex', justifyContent: 'flex-end' }}>
                                    <Button
                                        variant='outlined'
                                        style={{
                                            borderColor: theme.palette.primary.main,
                                            color: theme.palette.primary.main,
                                            width: '160px',
                                            marginTop: '16px',
                                        }}
                                        onClick={() => setIsChangeRequestDialogOpen(true)}
                                    >
                                        REQUEST CHANGE
                                    </Button>
                                </Box>
                            </Grid>
                        </Paper>
                        <Paper
                            className={standardClasses.paper}
                            style={{ paddingTop: '16px', paddingBottom: '16px' }}
                        >
                            <PurchaseOrderTotalSummary poSummary={poSummary} />
                                <Grid container style={{ padding: '16px' }}>
                                    <Grid item xs={12}>
                                        <Divider />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Typography style={{ fontSize: 20, fontWeight: 'bold', marginTop: '16px' }}>
                                            Purchase Order
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12} style={{ marginTop: '16px' }}>
                                        <DocumentCardList
                                            documents={poData.buyerPODocuments}
                                            setDocuments={() => {}}
                                            setAreDocumentsDirty={() => {}}
                                            availableMenuOptions={[DocumentMenuOption.VIEW, DocumentMenuOption.DOWNLOAD]}
                                            showType={false}
                                            showVisibility={false}
                                            showRevision={false}
                                        />
                                    </Grid>
                                </Grid>
                                <Grid container item xs={12} justifyContent='flex-end'>
                                    <Button
                                        variant='outlined'
                                        href='https://assets.staging.syncfab.com/SyncFab_Payment_Intructions_References.pdf'
                                        target="_blank"
                                        style={{
                                            borderColor: theme.palette.primary.main,
                                            color: theme.palette.primary.main,
                                            width: '240px',
                                            marginTop: '16px',
                                            marginRight: '16px'
                                        }}
                                    >
                                        PAYMENT INSTRUCTIONS
                                    </Button>
                                </Grid>
                        </Paper>
                    </Grid>
                </Grid>
                <PDFViewer
                    documentId={documentIdToView}
                    open={isPDFViewerOpen}
                    entity={documentEntity?.entityType}
                    entityId={documentEntity?.entityId}
                    onClose={() => {
                        setIsPDFViewerOpen(false)
                        setDocumentIdToView('')
                        setDocumentEntity(null)
                    }}
                />
                <Dialog open={isChangeRequestDialogOpen} onClose={cancelChangeRequestDialog} fullWidth>
                    <DialogContent>
                        <ChangeRequest
                            cancel={cancelChangeRequestDialog}
                            complete={completeChangeRequest}
                            entityType={EntityType.PURCHASEORDER}
                            entityId={poData._id}
                        />
                    </DialogContent>
                </Dialog>
            </div>
        )
    }
    return null
})
