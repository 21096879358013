import { Document, LOGOUT_ACTION_TYPE } from '@syncfab/machine'
import { GET_COMPANY_DOCUMENTS, DOCUMENT_DELETE_BY_TYPE, DOCUMENT_UPLOAD, DOCUMENT_DOWNLOAD_URL, RESET_DOWNLOAD_URL, DOCUMENT_UPDATE } from './Actions'

export type DocumentReducerType = {
    documents?: Document[] | null
    blobPDF?: any
}

const initialState: DocumentReducerType = {
    documents: [],
    blobPDF: null,
}

export const DocumentReducer = (state = initialState, action: any) => {
    switch (action.type) {
        case GET_COMPANY_DOCUMENTS.types.TRIGGER:
            return {
                ...state,
                documents: null,
            }
        case GET_COMPANY_DOCUMENTS.types.SUCCESSFULL:
            return {
                ...state,
                documents: action.data.data,
            }
        case DOCUMENT_UPLOAD.types.SUCCESSFULL:
            return {
                ...state,
                documents: [...state.documents, action.data.document],
            }

        case DOCUMENT_DOWNLOAD_URL.types.SUCCESSFULL:
            return {
                ...state,
                blobPDF: action.data,
            }

        case RESET_DOWNLOAD_URL.types.TRIGGER:
            return {
                ...state,
                blobPDF: null,
            }

        case DOCUMENT_DELETE_BY_TYPE.types.SUCCESSFULL:
            const documents = state.documents.filter(document => document.type !== action.data.type)
            return {
                ...state,
                documents,
            }
        case LOGOUT_ACTION_TYPE:
            return {
                ...state,
                documents: null,
            }
        case DOCUMENT_UPDATE.types.TRIGGER:
            return {
                ...state,
            }
        case DOCUMENT_UPDATE.types.SUCCESSFULL:
            return {
                ...state,
            }
        default:
            return state
    }
}
