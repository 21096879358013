import React, { FC, RefObject } from 'react'
import { ValueLabelOption } from './ValueLabelOption'
import { Autocomplete } from '@material-ui/lab'
import { Checkbox, InputAdornment, OutlinedInputProps, Paper, Theme } from '@material-ui/core'
import { Lock } from '@material-ui/icons'
import TextField from '@material-ui/core/TextField'
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank'
import CheckBoxIcon from '@material-ui/icons/CheckBox'
import { createStyles, makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((_theme: Theme) =>
    createStyles({
        noBorder: {
            border: 'none',
        },
        autoWidth: {
            width: 'auto !important',
        },
        readOnlyInput: {
            backgroundColor: '#F3F3F3',
        },
        noMargin: {
            '& .MuiFormControl-marginDense': {
                margin: 0,
            },
        },
    })
)

export interface AutoCompleteValueLabelProps {
    name: string
    label: string
    variant?: 'outlined' | 'filled' | 'standard'
    required?: boolean
    disabled?: boolean
    size?: 'small' | 'medium'
    placeholder?: string
    onChangeHandler?: Function
    onBlurHandler?: Function
    options: ValueLabelOption[]
    renderCheckboxes?: boolean
    multiple?: boolean
    disabledOptions?: ValueLabelOption[]
    disableClearable?: boolean
    ref?: RefObject<HTMLInputElement>
    InputRef?: any
    value?: ValueLabelOption | ValueLabelOption[]
    error?: { message?: string }
    style?: object
    isTableCellInput?: boolean
}

export const AutoCompleteValueLabel: FC<AutoCompleteValueLabelProps> = ({
    name,
    label,
    variant = 'outlined',
    required = false,
    disabled = false,
    size = 'medium',
    placeholder = '',
    onChangeHandler,
    onBlurHandler = () => {},
    options,
    renderCheckboxes = false,
    multiple = false,
    disabledOptions = [],
    disableClearable = false,
    ref = null,
    InputRef = null,
    value,
    error,
    style,
    isTableCellInput = false,
}: AutoCompleteValueLabelProps) => {
    const classes = useStyles()

    return (
        <Autocomplete
            className={isTableCellInput ? classes.noMargin : null}
            style={style}
            id={name}
            value={value}
            ref={ref}
            placeholder={placeholder}
            multiple={multiple}
            disableCloseOnSelect={multiple}
            disableClearable={disableClearable}
            options={options}
            getOptionLabel={(option: ValueLabelOption) => {
                return option?.label ?? ''
            }}
            getOptionSelected={(option: ValueLabelOption, value: ValueLabelOption) => {
                return option.value === value?.value
            }}
            disabled={disabled}
            getOptionDisabled={option => {
                return disabledOptions.some(item => item.value === option.value)
            }}
            size={size}
            onBlur={() => {
                onBlurHandler()
            }}
            onChange={(_event, value) => {
                if (onChangeHandler) {
                    onChangeHandler(value)
                }
            }}
            fullWidth
            renderInput={params => {
                const inputProps: OutlinedInputProps = { ...params.InputProps }
                if (params.disabled) {
                    inputProps.endAdornment = (
                        <InputAdornment position="end" style={{ backgroundColor: 'inherit' }}>
                            <Lock />
                        </InputAdornment>
                    )
                }
                if (isTableCellInput) {
                    inputProps.classes = { ...inputProps.classes, notchedOutline: classes.noBorder, input: classes.autoWidth }
                }
                return (
                    <TextField
                        {...params}
                        inputRef={InputRef}
                        label={label}
                        value={value}
                        variant={variant}
                        required={required}
                        error={!!error}
                        helperText={error ? error.message : null}
                        InputProps={inputProps}
                        style={{ textOverflow: 'ellipsis' }}
                        margin={isTableCellInput ? 'dense' : 'none'}
                    />
                )
            }}
            renderOption={(option, { selected }) => (
                <>
                    {renderCheckboxes && (
                        <Checkbox
                            color="primary"
                            inputProps={{ 'aria-label': 'primary checkbox' }}
                            icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                            checkedIcon={<CheckBoxIcon fontSize="small" />}
                            style={{ marginRight: 8 }}
                            checked={selected}
                        />
                    )}
                    {option.label}
                </>
            )}
            PaperComponent={({ children }) => <Paper style={{ background: '#fff' }}>{children}</Paper>}
        />
    )
}
