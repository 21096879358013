import React from 'react';
import { CenteredAlignedContainerV2 } from '../../../../layout/Container';
import ContactListForm from '../ContactListForm';
import { useGetLoggedUserQuery, useGetSupplierQuery } from '@syncfab/machine';
import SupplierFormShell from '../SupplierFormShell';
const ContactListIndex = () => {
  // const loggedUser = useAppSelector(state => state.user.loggedUser);
  const { data: loggedUser } = useGetLoggedUserQuery();
  const { data: supplier } = useGetSupplierQuery(loggedUser?.supplierId ?? '', {
    skip: !loggedUser,
  });

  return (
    <CenteredAlignedContainerV2>
      {!!supplier && (
        <SupplierFormShell activeStep={1} supplier={supplier}>
          <ContactListForm supplier={supplier} />
        </SupplierFormShell>
      )}
    </CenteredAlignedContainerV2>
  );
};

export default ContactListIndex;
