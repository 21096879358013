import React from 'react'
import { WithNamespaces, withNamespaces } from 'react-i18next'
import { State } from '../../../application/Store'
import { getRFQSelector, getRFQFilterCountSelector } from '../../../components/quote/rfq/Selectors'
import { Dispatch } from 'redux'
import { connect } from 'react-redux'
import { GET_RFQS, RFQ_LIST_UPDATE_FILTER, RFQ_DELETE, EDIT_RFQ, GET_SUPPLIER_RFQS } from '../../../components/quote/rfq/Actions'
import { GET_WALLET_STATUS } from '../../../components/transaction/Actions'
import Helmet from 'react-helmet'
import {
    withStyles,
    WithStyles,
    Typography,
    Grid,
    //SnackbarContent, IconButton
} from '@material-ui/core'
import { RFQ, RFQStatusFilter, Button, SimpleDialog, User, PUSH } from '@syncfab/machine'
import { CenteredAlignedContainerV2 } from '../../../layout/Container'
import { EditDialog } from '../EditDialog'
// import { Snackbar } from '@material-ui/core'
// import CloseIcon from '@material-ui/icons/Close'
import { RFQQuoteList } from './components/RFQQuoteList'
import { ListMeta } from '../../../layout/list/Meta'
import QuoteList from './components/QuoteList'

// import ErrorIcon from '@material-ui/icons/Error'

const styles = theme => ({
    paper: {
        width: '100%',
        minWidth: 300,
        // padding: theme.spacing(4),
        marginTop: theme.spacing(4),
        marginBottom: theme.spacing(4),
        backgroundColor: '#364F68',
    },
    title: {
        fontSize: theme.spacing(4),
    },
    subTitle: {
        color: '#A0AEC0',
        fontWeight: theme.typography.fontWeightMedium,
    },
})

interface QuotesProps extends WithStyles, WithNamespaces {
    rfqs: RFQ[]
    meta: ListMeta
    getRFQs: (meta: ListMeta) => void
    handleCloseSnack: () => void
    updateFilter: (filter: RFQStatusFilter) => void
    deleteRFQ: (id: string) => void
    counts: { [key in RFQStatusFilter]: number }
    rfqSelectedFilter: RFQStatusFilter
    editRFQ: (id: string, request?: string) => void
    getWalletStatus: () => void
    walletCreationStatus: any
    loggedUser?: User
    history: any
    edit: (id: string, status: string) => void
    getAllRFQs: () => void
    createRFQ: () => void
}

interface QuotesViewState {
    dialogOpen: boolean
    idToDelete?: string
    editDialogOpen: boolean
    editRequest?: string
    idToEdit?: string
    openSnack?: boolean
}

class QuotesView extends React.Component<QuotesProps, QuotesViewState> {
    constructor(props) {
        super(props)
        this.toggleDeletedDialog = this.toggleDeletedDialog.bind(this)
        this.toggleEditDialog = this.toggleEditDialog.bind(this)
        this.onDelete = this.onDelete.bind(this)
        this.deleteRFQ = this.deleteRFQ.bind(this)
        this.onEditChange = this.onEditChange.bind(this)
        this.editRFQ = this.editRFQ.bind(this)
        this.onEditRFQ = this.onEditRFQ.bind(this)
        this.state = {
            dialogOpen: false,
            editDialogOpen: false,
            openSnack: true,
        }
        this.props.updateFilter(RFQStatusFilter.ALL)
        // this.props.getRFQs()
        if (this.props?.loggedUser?.loginRole === 'SUPPLIER') {
            this.props.getAllRFQs()
        } else {
            this.props.getRFQs({ ...props.meta, page: 0, query: null })
        }
        this.props.getWalletStatus()
        this.handleCloseSnack = this.handleCloseSnack.bind(this)
    }

    onEditRFQ(id: string) {
        this.props.history.push(`quotes/request/${id}`)
    }

    toggleDeletedDialog() {
        this.setState(state => ({
            dialogOpen: !state.dialogOpen,
        }))
    }

    toggleEditDialog() {
        this.setState(state => ({
            editDialogOpen: !state.editDialogOpen,
        }))
    }

    onEditChange(value) {
        this.setState({
            editRequest: value,
        })
    }

    editRFQ() {
        this.props.editRFQ(this.state.idToEdit, this.state.editRequest)
        this.setState({
            idToEdit: null,
            editRequest: '',
        })
        this.toggleEditDialog()
    }

    onDelete(idToDelete: string) {
        this.setState({
            idToDelete,
        })
        this.toggleDeletedDialog()
    }

    deleteRFQ() {
        const id = this.state.idToDelete
        if (!id) {
            return
        }
        this.props.deleteRFQ(id)
        this.toggleDeletedDialog()
        this.setState({
            idToDelete: undefined,
        })
    }
    handleCloseSnack() {
        this.setState({ openSnack: false })
    }
    render() {
        const { t, classes, rfqs, loggedUser, getRFQs, meta, edit, createRFQ } = this.props
        return (
            <CenteredAlignedContainerV2>
                <Helmet>
                    <title>{`${t('quotes-title')}`}</title>
                </Helmet>
                {loggedUser?.loginRole === 'SUPPLIER' ? (
                    <QuoteList deleteRFQ={this.onDelete} editRFQ={this.onEditRFQ} loggedUser={loggedUser} />
                ) : (
                    <>
                        <Grid container justifyContent="space-between" style={{ marginTop: 32 }}>
                            <Grid item xs={6}>
                                <Typography gutterBottom variant="h4" className={classes.title}>
                                    {`${t('quotes-list-title')}`}
                                </Typography>
                                <Typography gutterBottom variant="h4" className={classes.subTitle}>
                                    {`Hello, ${loggedUser?.firstName} ${loggedUser?.lastName}`}
                                </Typography>
                            </Grid>
                            <Grid item xs={6} style={{ textAlign: 'right' }}>
                                <Button color="primary" variant="contained" onClick={createRFQ}>
                                    {t('side-menu-request')}
                                </Button>
                            </Grid>
                        </Grid>
                        <RFQQuoteList rfqs={rfqs} meta={meta} getRFQs={getRFQs} edit={edit} />
                    </>
                )}
                <SimpleDialog
                    title={t('rfq-list-delete-dialog-title')}
                    content={t('rfq-list-delete-dialog-content')}
                    primaryActionText={t('rfq-list-delete-dialog-delete')}
                    onPrimaryAction={this.deleteRFQ}
                    secondaryActionText={t('rfq-list-delete-dialog-cancel')}
                    onSecondaryAction={this.toggleDeletedDialog}
                    open={this.state.dialogOpen}
                />
                <EditDialog
                    title={t('rfq-edit-dialog-title')}
                    label={t('rfq-edit-label')}
                    onChange={this.onEditChange}
                    value={this.state.editRequest}
                    primaryActionText={t('rfq-edit-dialog-edit')}
                    onPrimaryAction={this.editRFQ}
                    secondaryActionText={t('rfq-edit-dialog-cancel')}
                    onSecondaryAction={this.toggleEditDialog}
                    open={this.state.editDialogOpen}
                />
            </CenteredAlignedContainerV2>
        )
    }
}

const QuotesWithTranslations = withStyles(styles)(withNamespaces()(QuotesView))

const mapStateToProps = (state: State) => {
    const getRFQs = getRFQSelector()
    const getRFQFilterCount = getRFQFilterCountSelector()

    return {
        walletCreationStatus: state.transaction.walletCreationStatus,
        rfqs: getRFQs(state),
        counts: getRFQFilterCount(state),
        meta: state.rfq.meta,
        rfqSelectedFilter: state.rfq.rfqSelectedFilter,
        loggedUser: state.user.loggedUser,
    }
}

const mapDispatchToProps = (dispatch: Dispatch) => {
    return {
        getAllRFQs: () => dispatch(GET_SUPPLIER_RFQS.actions.TRIGGER()),
        getRFQs: (meta: ListMeta) => dispatch(GET_RFQS.actions.TRIGGER({ meta })),
        updateFilter: (filter: RFQStatusFilter) => dispatch(RFQ_LIST_UPDATE_FILTER.actions.TRIGGER({ filter })),
        deleteRFQ: (id: string) => dispatch(RFQ_DELETE.actions.TRIGGER({ id })),
        editRFQ: (id: string, request?: string) => dispatch(EDIT_RFQ.actions.TRIGGER({ id, request })),
        edit: (id: string, status: string) => dispatch(PUSH(status === 'DRAFT' ? `/quotes/request/${id}` : `/quotes/${id}`)),
        getWalletStatus: () => dispatch(GET_WALLET_STATUS.actions.TRIGGER()),
        createRFQ: () => dispatch(PUSH(`/quotes/request`)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(QuotesWithTranslations)
