import React from 'react'
import { Helmet } from 'react-helmet'
import { withNamespaces, WithNamespaces } from 'react-i18next'
import { WithStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import { Link } from 'react-router-dom'
import { State } from '../../application/Store'
import { Dispatch } from 'redux'
import { ACTIVATE_USER } from './Actions'
import { connect } from 'react-redux'
import { Button, VerifiedIcon } from '@syncfab/machine'
import { Paper, withStyles } from '@material-ui/core'
import { CenteredContainer } from '../../layout/Container'

const styles = theme => ({
    paper: {
        padding: theme.spacing(6),
        display: 'flex',
        'flex-direction': 'column',
        'justify-content': 'center',
        alignItems: 'center',
        width: 400,
        'text-align': 'center',
    },
    error: {
        color: theme.palette.error.main,
        marginBottom: theme.spacing(2),
    },
    text: {
        padding: theme.spacing(2),
        color: theme.palette.common.white,
        marginBottom: theme.spacing(2),
    },
    link: {
        textDecoration: 'none',
        width: '100%',
    },
    verifiedIcon: {
        marginLeft: -16,
    },
})

interface SignupActivationProps extends WithNamespaces, WithStyles {
    activate: (token: string) => {}
    activationError?: string
    active: boolean
}

class SignupActivationView extends React.Component<SignupActivationProps> {
    constructor(props: SignupActivationProps) {
        super(props)
        const token = new URLSearchParams(window.location.search.slice(1)).get('token') || ''
        this.props.activate(token)
    }

    public render() {
        const { classes, t, activate, activationError } = this.props
        if (!activate && !activationError) {
            return null
        }

        return (
            <CenteredContainer>
                <Helmet>
                    <title>{`${t('signup-activation-title')}`}</title>
                </Helmet>
                <Paper className={classes.paper} elevation={1}>
                    {activate && !!activationError && (
                        <Typography className={classes.error} variant="caption">
                            {`${t('signup-activation-error')}`}
                        </Typography>
                    )}
                    {activate && !activationError && (
                        <React.Fragment>
                            <VerifiedIcon className={classes.verifiedIcon} />
                            <Typography className={classes.text} variant="h5">
                                {/* {t('feedback-support')} */}
                                {`${t('signup-activation-show-text')}`}
                            </Typography>
                            <Link to="/login" className={classes.link}>
                                <Button color="primary" fullWidth>
                                    {t('signup-activation-login')}
                                </Button>
                            </Link>
                        </React.Fragment>
                    )}
                </Paper>
            </CenteredContainer>
        )
    }
}

const mapStateToProps = ({ user: { activationError, active } }: State) => {
    return {
        activationError,
        active,
    }
}

const mapDispatchToProps = (dispatch: Dispatch) => {
    return {
        activate: (token: string) => dispatch(ACTIVATE_USER(token)),
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withNamespaces()(withStyles(styles)(SignupActivationView)))
