import { AddressCreateInlinePayload, Address, Company, FetchActions, createFetchActions } from '@syncfab/machine'

export const GET_LOGGED_COMPANY: FetchActions<{}, Company> = createFetchActions('GET_LOGGED_COMPANY')
export const COMPANY_UPDATE: FetchActions<Partial<Company>, string> = createFetchActions('GET_LOGGED_COMPANY')
export const ADDRESS_GET: FetchActions<{}, Address[]> = createFetchActions('ADDRESS_GET')
export const ADDRESS_CREATE: FetchActions<Partial<Address>, Address> = createFetchActions('ADDRESS_CREATE')
export const ADDRESS_UPDATE: FetchActions<Partial<Address>, Address> = createFetchActions('ADDRESS_UPDATE')
export const ADDRESS_DELETE: FetchActions<{ id: string }, string> = createFetchActions('ADDRESS_DELETE')
export const ADDRESS_CREATE_INLINE: FetchActions<AddressCreateInlinePayload, Address> = createFetchActions('ADDRESS_CREATE_INLINE')
export const UPDATE_COMPANY: FetchActions<{ companyId: string; companyInput: Partial<Company> }, {}> = createFetchActions('UPDATE_COMPANY')
export const DELETE_DOCUMENT: FetchActions<{ documentId: string }, {}> = createFetchActions('DELETE_DOCUMENT')
