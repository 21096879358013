import React from 'react'
import SvgIcon from '@material-ui/core/SvgIcon'

export const CollapseUp = props => {
    return (
        <SvgIcon {...props} viewBox="0 0 24 24" x="0px" y="0px" baseProfile="tiny" xmlSpace="preserve">
            <path fill="#FFFFFF" d="M24 16l-12 12 2.83 2.83 9.17-9.17 9.17 9.17 2.83-2.83z" />
            <path d="M0 0h48v48h-48z" fill="none" />
        </SvgIcon>
    )
}
