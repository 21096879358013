import React from 'react'
import { withStyles, WithStyles } from '@material-ui/core'

const styles = theme => ({
    parent: {
        width: '100%',
        minHeight: 250,
        position: 'relative' as any,
    },
    container: {
        width: '10vh',
        height: '10vh',
        background: 'transparent',
        position: 'absolute' as any,
        top: '50%',
        left: '50%',
        margin: -25,
    },
    div: {
        position: 'absolute' as any,
        width: 50,
        height: 50,
        border: `6px solid ${theme.palette.primary.main}`,
        borderRadius: '50%',
        animation: '$contentAnimation 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite',
        borderColor: `${theme.palette.primary.main} transparent transparent transparent`,
    },
    div1: {
        'animation-delay': '-0.45s',
    },
    div2: {
        'animation-delay': '-0.3s',
    },
    div3: {
        'animation-delay': '-0.15s',
    },
    '@keyframes contentAnimation': {
        '0%': {
            transform: 'rotate(0deg)',
        },
        '100%': {
            transform: 'rotate(360deg)',
        },
    },
})

export const Loader = withStyles(styles)(({ classes }: WithStyles) => {
    return (
        <div className={classes.parent}>
            <div className={classes.container}>
                <div className={`${classes.div} ${classes.div1}`} />
                <div className={`${classes.div} ${classes.div2}`} />
                <div className={`${classes.div} ${classes.div3}`} />
                <div className={classes.div} />
            </div>
        </div>
    )
})
