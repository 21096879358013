import React, { FC } from "react";
import TextField from "@material-ui/core/TextField";
import { InputAdornment, Theme, OutlinedInputProps } from "@material-ui/core";
import { Lock } from "@material-ui/icons";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import { RHFTextFieldProps } from "./RHFTextField";
import NumberFormat from "react-number-format";

const useStyles = makeStyles((_theme: Theme) => createStyles({
    noBorder: {
        border: 'none'
    },
    noTopBottomPadding: {
        paddingTop: '0px',
        paddingBottom: '0px'
    },
    readOnlyInput: {
        backgroundColor: '#E6E6E6'
    }
}))

export interface NumberFormatFieldProps extends RHFTextFieldProps {
    thousandSeparator?: boolean
    decimalScale?: number
    prefix?: string
    suffix?: string
    value?: number
    isReadOnly?: boolean
    inputRef?: any
    onChangeHandler?: any
    error?: { message?: string }

}

export const NumberFormatField: FC<NumberFormatFieldProps> =
    ({ name, label, value, variant = "outlined", required = false, disabled = false, isTableCellInput = false, isReadOnly = false,
         thousandSeparator = true, decimalScale = 2, prefix = '', suffix = '', inputRef, onChangeHandler = () => {}, error,
         onBlur = () => {} }: NumberFormatFieldProps) => {

        const classes = useStyles()

        const localInputProps: OutlinedInputProps = {}
        if (disabled) {
            localInputProps.endAdornment = (
                <InputAdornment position="end" style={{ backgroundColor: 'inherit' }}>
                    <Lock/>
                </InputAdornment>
            )
        }
        if (isTableCellInput) {
            localInputProps.classes = { ...localInputProps.classes, notchedOutline: classes.noBorder, input: classes.noTopBottomPadding }
        }
        if (isReadOnly) {
            localInputProps.classes.input = `${localInputProps.classes.input} ${classes.readOnlyInput}`
        }

        return (
            <NumberFormat
                customInput={TextField}
                thousandSeparator={thousandSeparator}
                prefix={prefix}
                suffix={suffix}
                decimalScale={decimalScale}
                fixedDecimalScale={true}
                value={value}
                name={name}
                id={name}
                type="text"
                variant={variant}
                label={label}
                fullWidth
                required={required}
                disabled={disabled || isReadOnly}
                inputRef={inputRef}
                InputProps={localInputProps}
                error={!!error}
                helperText={error ? error.message : null}
                onValueChange={(values, _sourceInfo) => {
                    // handle undefined value for values.floatValue to prevent field from reverting to default value
                    values.floatValue ? onChangeHandler(values.floatValue) : onChangeHandler(null)
                }}
                onBlur={onBlur}
            />
        )
}