import axios, { AxiosPromise } from 'axios'
import { AuthorizationService, User, Environment, EnvironmentVariable } from '@syncfab/machine'
import { ActivationAction } from '../../view/signup/Actions'
import { UpdateForgottenPasswordPayload, UserActivationPayload } from '../user/Actions'
import { ListMeta } from '../../layout/list/Meta'

export class UserAPI {
    constructor(private environment: Environment, private authorizationService: AuthorizationService) {}

    public forgotPassword(email: string): () => AxiosPromise {
        const url = this.environment.get(EnvironmentVariable.API_LAYER_URL)
        return () => axios.post(`${url}/auth/forgotPassword`, { email })
    }

    public loginUser(email: string, password: string): () => AxiosPromise {
        const url = this.environment.get(EnvironmentVariable.API_LAYER_URL)
        return () => axios.post(`${url}/auth/login`, { email, password })
    }

    public save(data: Partial<User>): () => AxiosPromise {
        const url = this.environment.get(EnvironmentVariable.API_LAYER_URL)
        const authorization = `Bearer ${this.authorizationService.getAuthToken()}`
        return () =>
            axios.post(
                `${url}/users`,
                { ...data, loginRole: 'BUYER', roles: ['ADMIN'] },
                {
                    headers: {
                        authorization,
                    },
                }
            )
    }

    public update(id: string, delta: Partial<User>): () => AxiosPromise {
        const url = this.environment.get(EnvironmentVariable.API_LAYER_URL)
        const authorization = `Bearer ${this.authorizationService.getAuthToken()}`
        return () =>
            axios.patch(`${url}/users/${id}`, delta, {
                headers: {
                    authorization,
                },
            })
    }

    public updatePassword(password: string, newPassword: string): () => AxiosPromise {
        const url = this.environment.get(EnvironmentVariable.API_LAYER_URL)
        const authorization = `Bearer ${this.authorizationService.getAuthToken()}`
        return () =>
            axios.put(
                `${url}/users/password`,
                { password, newPassword },
                {
                    headers: {
                        authorization,
                    },
                }
            )
    }

    public updateForgottenPassword(payload: UpdateForgottenPasswordPayload): () => AxiosPromise {
        const url = this.environment.get(EnvironmentVariable.API_LAYER_URL)
        const authorization = `Bearer ${this.authorizationService.getAuthToken()}`
        return () =>
            axios.post(
                `${url}/auth/updateForgottenPassword`,
                { ...payload },
                {
                    headers: {
                        authorization,
                    },
                }
            )
    }

    public updateEmail(newEmail: string): () => AxiosPromise {
        const url = this.environment.get(EnvironmentVariable.API_LAYER_URL)
        const authorization = `Bearer ${this.authorizationService.getAuthToken()}`
        return () =>
            axios.post(
                `${url}/users/email`,
                { newEmail: newEmail },
                {
                    headers: {
                        authorization,
                    },
                }
            )
    }

    public emailExists(email: string): () => AxiosPromise {
        const url = this.environment.get(EnvironmentVariable.API_LAYER_URL)
        return () => axios.get(`${url}/signup/exists?email=${encodeURIComponent(email)}`)
    }

    public activate({ token }: ActivationAction): () => AxiosPromise {
        const url = this.environment.get(EnvironmentVariable.API_LAYER_URL)
        return () => axios.post(`${url}/signup/verify?token=${token}`)
    }

    public validateEmail(token: string): () => AxiosPromise {
        const url = this.environment.get(EnvironmentVariable.API_LAYER_URL)
        return () => axios.post(`${url}/users/updateEmail?token=${token}`)
    }

    public getLoggedUser = (): (() => AxiosPromise) => {
        const url = this.environment.get(EnvironmentVariable.API_LAYER_URL)
        const authorization = `Bearer ${this.authorizationService.getAuthToken()}`
        return () => {
            return axios.get(`${url}/users/logged`, {
                headers: {
                    authorization,
                },
            })
        }
    }

    public get = (id: string): (() => AxiosPromise) => {
        const url = this.environment.get(EnvironmentVariable.API_LAYER_URL)
        const authorization = `Bearer ${this.authorizationService.getAuthToken()}`
        return () => {
            return axios.get(`${url}/users/${id}`, {
                headers: {
                    authorization,
                },
            })
        }
    }

    public deleteUser = (id: string): (() => AxiosPromise) => {
        const url = this.environment.get(EnvironmentVariable.API_LAYER_URL)
        const authorization = `Bearer ${this.authorizationService.getAuthToken()}`
        return () => {
            return axios.delete(`${url}/users/${id}`, {
                headers: {
                    authorization,
                },
            })
        }
    }

    public resendInvitation = (id: string): (() => AxiosPromise) => {
        const url = this.environment.get(EnvironmentVariable.API_LAYER_URL)
        const authorization = `Bearer ${this.authorizationService.getAuthToken()}`
        return () => {
            return axios.put(
                `${url}/users/${id}/resendInvite`,
                {},
                {
                    headers: {
                        authorization,
                    },
                }
            )
        }
    }

    public list = (meta: ListMeta): (() => AxiosPromise) => {
        const url = this.environment.get(EnvironmentVariable.API_LAYER_URL)
        const authorization = `Bearer ${this.authorizationService.getAuthToken()}`
        return () => {
            return axios.get(`${url}/users`, {
                params: meta,
                headers: {
                    authorization,
                },
            })
        }
    }

    public activateInvitedUser({ token, email, password }: UserActivationPayload): () => AxiosPromise {
        const url = this.environment.get(EnvironmentVariable.API_LAYER_URL)
        return () => axios.post(`${url}/users/activate`, { token, email, password })
    }
}
