import { call, takeLatest, getContext, put } from 'redux-saga/effects'
import { PUSH, Supplier, SHOW_FEEDBACK, LOGIN_SUCESSFUL, SUPPLIER_LOGIN_ACTION_TYPE, LOGIN_FAILED } from '@syncfab/machine'
import { Gateway } from '../gateway'
import {
    SUPPLIER_CREATE,
    SUPPLIER_EMAIL_EXIST,
    SUPPLIER_RESEND_EMAIL,
    SUPPLIER_VALIDATE,
    SUPPLIER_FORGOT_PASSWORD,
    SUPPLIER_UPDATE_FORGOT_PASSWORD,
    SUPPLIER_PASSWORD,
    UpdateForgottenPasswordPayload,
    SUPPLIER_RESEND_VERIFICATION_EMAIL,
    GET_SUPPLIERS,
    GET_SUPPLIER,
    GET_SUPPLIER_TAGS,
} from './Actions'
import { startSubmit, stopSubmit, reset, startAsyncValidation, stopAsyncValidation } from 'redux-form'
import { SupplierSignupFormName } from '../../view/supplier/signup/components/SupplierSignupPersonalInformationForm'
import { LoginFormName } from '../../view/supplier/login/components/LoginForm'
import { ForgotPasswordFormName } from '../../view/supplier/password/components/ForgotPasswordForm'
import { UpdatePasswordFormName } from '../../view/supplier/password/components/UpdatePasswordForm'

function* save({ data }: { data: Partial<Supplier> }) {
    try {
        yield put(startSubmit(SupplierSignupFormName))
        const gateway: Gateway = yield getContext('gateway')
        const { data: response } = yield call(gateway.getSupplierAPI().save(data))

        yield put(SUPPLIER_CREATE.actions.SUCCESSFULL(response))
        yield put(PUSH('/suppliers/confirmation'))
        yield put(reset(SupplierSignupFormName))
        yield put(stopSubmit(SupplierSignupFormName))
    } catch ({ response: { status, data } }) {
        const handleError = yield getContext('handleError')
        yield call(handleError(status))
        yield put(SUPPLIER_CREATE.actions.FAILED(data))
        yield put(stopSubmit(SupplierSignupFormName))
    }
}

function* login({ data: { email, password } }: { data: { email: string; password: string } }) {
    try {
        yield put(startSubmit(LoginFormName))
        const gateway: Gateway = yield getContext('gateway')
        const {
            data: { token, user },
        } = yield call(gateway.getSupplierAPI().login(email, password))
        yield put(LOGIN_SUCESSFUL(token, user))
        const response = yield call(gateway.getSupplierAPI().getSupplierTags())
        yield put(GET_SUPPLIER_TAGS.actions.SUCCESSFULL(response.data.data))
        yield put(PUSH('/quotes'))
        yield put(reset(LoginFormName))
        yield put(stopSubmit(LoginFormName))
    } catch ({ response: { status, data } }) {
        const handleError = yield getContext('handleError')
        yield call(handleError(status))
        yield put(LOGIN_FAILED(data))
        yield put(stopSubmit(LoginFormName))
    }
}

function* emailExists({ data: { email } }: { data: { email: string } }) {
    try {
        const gateway: Gateway = yield getContext('gateway')
        yield put(startAsyncValidation(SupplierSignupFormName))
        yield call(gateway.getUserAPI().emailExists(email))
        yield put(stopAsyncValidation(SupplierSignupFormName, {}))
    } catch ({ response: { status, data } }) {
        yield put(stopAsyncValidation(SupplierSignupFormName, { email: 'Already in use' } as any))
    }
}

function* validate({ data: { token } }: { data: { token: string } }) {
    try {
        const gateway: Gateway = yield getContext('gateway')
        const { data } = yield call(gateway.getSupplierAPI().validate(token))
        yield put(SUPPLIER_VALIDATE.actions.SUCCESSFULL(data))
    } catch ({ response: { status, data } }) {
        const handleError = yield getContext('handleError')
        yield call(handleError(status))
        yield put(SUPPLIER_VALIDATE.actions.FAILED(data))
    }
}

function* resendValidation({ data: { id } }: { data: { id: string } }) {
    try {
        const gateway: Gateway = yield getContext('gateway')
        yield call(gateway.getSupplierAPI().resendValidationEmail(id))
        yield put(SHOW_FEEDBACK('validation-sent'))
        yield put(SUPPLIER_RESEND_EMAIL.actions.SUCCESSFULL())
    } catch ({ response: { status, data } }) {
        yield put(SUPPLIER_RESEND_EMAIL.actions.FAILED(data))
        yield put(SHOW_FEEDBACK(data))
    }
}

function* forgotPassword({ data: { email } }: { data: { email: string } }) {
    try {
        yield put(startSubmit(ForgotPasswordFormName))
        const gateway: Gateway = yield getContext('gateway')
        const { data } = yield call(gateway.getSupplierAPI().forgotPassword(email))

        yield put(SUPPLIER_FORGOT_PASSWORD.actions.SUCCESSFULL({ email: data }))
        yield put(PUSH('/suppliers/forgot/confirmation'))
        yield put(reset(ForgotPasswordFormName))
        yield put(stopSubmit(ForgotPasswordFormName))
    } catch ({ response: { status, data } }) {
        const handleError = yield getContext('handleError')
        yield call(handleError(status))
        yield put(SUPPLIER_FORGOT_PASSWORD.actions.FAILED(data))
        yield put(stopSubmit(ForgotPasswordFormName))
    }
}

function* updateForgottenPassword({ data }: { data: UpdateForgottenPasswordPayload }) {
    try {
        yield put(startSubmit(UpdatePasswordFormName))
        const gateway: Gateway = yield getContext('gateway')
        yield call(gateway.getSupplierAPI().updateForgottenPassword(data))

        yield put(SUPPLIER_UPDATE_FORGOT_PASSWORD.actions.SUCCESSFULL())
        yield put(SHOW_FEEDBACK('feedback-password-update'))
        yield put(stopSubmit(UpdatePasswordFormName))
        yield put(reset(UpdatePasswordFormName))
        yield put(PUSH('/suppliers/login'))
    } catch ({ response: { status, data } }) {
        const handleError = yield getContext('handleError')
        yield call(handleError(status))
        yield put(SUPPLIER_PASSWORD.actions.FAILED(data))
        yield put(SHOW_FEEDBACK('feedback-support'))
        yield put(stopSubmit(UpdatePasswordFormName))
    }
}

function* supplierResentVerificationEmail({ data: { email } }: { data: { email: string } }) {
    try {
        const gateway: Gateway = yield getContext('gateway')
        yield call(gateway.getSupplierAPI().resendVerificationEmail(email))
        yield put(SHOW_FEEDBACK('Verification Link Send Successfully.'))
        yield put(PUSH('/'))
    } catch ({ response: { status, data } }) {
        const handleError = yield getContext('handleError')
        yield call(handleError(status))
        yield put(SHOW_FEEDBACK(data))
    }
}
function* getSuppliers() {
    try {
        const gateway: Gateway = yield getContext('gateway')
        const data = yield call(gateway.getSupplierAPI().getSuppliers())
        if (data.data.showSupplierTab) {
            yield put(GET_SUPPLIERS.actions.SUCCESSFULL(data.data))
        } else {
            yield put(GET_SUPPLIERS.actions.FAILED(data))
        }
    } catch ({ response: { status, data } }) {
        const handleError = yield getContext('handleError')
        yield call(handleError(status))
        yield put(GET_SUPPLIERS.actions.FAILED(data))
    }
}

function* getSupplier(requestData: any) {
    try {
        const gateway: Gateway = yield getContext('gateway')
        const data = yield call(gateway.getSupplierAPI().getSupplier(requestData.data))
        yield put(GET_SUPPLIER.actions.SUCCESSFULL(data.data))
    } catch ({ response: { status, data } }) {
        const handleError = yield getContext('handleError')
        yield call(handleError(status))
        yield put(GET_SUPPLIER.actions.FAILED(data))
    }
}

function* getSupplierTags() {
    try {
        const gateway: Gateway = yield getContext('gateway')
        const response = yield call(gateway.getSupplierAPI().getSupplierTags())
        yield put(GET_SUPPLIER_TAGS.actions.SUCCESSFULL(response.data.data))
    } catch ({ response: { status, data } }) {
        const handleError = yield getContext('handleError')
        yield call(handleError(status))
        yield put(GET_SUPPLIER_TAGS.actions.FAILED(data))
    }
}

function* supplierSaga() {
    // Typings are not good enough. String should be valid but the types
    // do not show it

    yield takeLatest(SUPPLIER_CREATE.types.TRIGGER as any, save)
    yield takeLatest(SUPPLIER_LOGIN_ACTION_TYPE as any, login)
    yield takeLatest(SUPPLIER_FORGOT_PASSWORD.types.TRIGGER as any, forgotPassword)
    yield takeLatest(SUPPLIER_EMAIL_EXIST.types.TRIGGER as any, emailExists)
    yield takeLatest(SUPPLIER_RESEND_EMAIL.types.TRIGGER as any, resendValidation)
    yield takeLatest(SUPPLIER_VALIDATE.types.TRIGGER as any, validate)
    yield takeLatest(SUPPLIER_UPDATE_FORGOT_PASSWORD.types.TRIGGER as any, updateForgottenPassword)
    yield takeLatest(SUPPLIER_RESEND_VERIFICATION_EMAIL.types.TRIGGER as any, supplierResentVerificationEmail)
    yield takeLatest(GET_SUPPLIERS.types.TRIGGER as any, getSuppliers)
    yield takeLatest(GET_SUPPLIER.types.TRIGGER as any, getSupplier)
    yield takeLatest(GET_SUPPLIER_TAGS.types.TRIGGER as any, getSupplierTags)
}

export default supplierSaga
