import React from 'react'
import { Typography, Grid, Button, Tooltip, TableRow, TableCell } from '@material-ui/core'
import { withNamespaces, WithNamespaces } from 'react-i18next'
import { TransactionItem } from './TransactionItem'
import CallMadeIcon from '@material-ui/icons/CallMade'
import { BoldTypography } from '../../../../utils/Typography/BoldTypography'
import { RightTypography } from '../../../../utils/Typography/RightTypography'
import AttachmentIcon from '@material-ui/icons/Attachment'

interface SentTransactionListProps extends TransactionItem, WithNamespaces {}

export const SentTransactionListItem = withNamespaces()(({ transaction }: SentTransactionListProps) => {
    return (
        <TableRow key={transaction._id}>
            <TableCell style={{ padding: '4px 24px' }}>
                <Grid container direction="row" alignContent="flex-start">
                    <CallMadeIcon style={{ color: 'white' }} />
                    <RightTypography gutterBottom>
                        Sent
                        {/* {t('wallet-transactions-deposit')} */}
                    </RightTypography>
                </Grid>
            </TableCell>
            <TableCell style={{ padding: '4px 24px' }}>
                <Grid container item>
                    <Grid container direction="row" alignItems="flex-start">
                        <Grid item>
                            <Typography gutterBottom variant="h3">
                                {transaction.context}
                            </Typography>
                        </Grid>
                        <Grid item style={{ marginLeft: '12px' }}>
                            <Tooltip style={{ backgroundColor: 'black' }} title="View on Etherscan">
                                <AttachmentIcon
                                    style={{ color: 'white' }}
                                    onClick={() => {
                                        window.open('https://etherscan.io/tx/' + transaction.txnHash, '_blank')
                                    }}
                                />
                            </Tooltip>
                        </Grid>
                    </Grid>
                    <Typography gutterBottom>
                        To:{' '}
                        <Button
                            variant="text"
                            color="primary"
                            onClick={() => {
                                window.open('https://etherscan.io/address/' + transaction.particular, '_blank')
                            }}
                        >
                            {transaction.particular}
                        </Button>
                    </Typography>
                </Grid>
            </TableCell>
            <TableCell>
                <Grid item>
                    <Typography gutterBottom>{new Date(transaction.createdAt).toLocaleDateString()}</Typography>
                </Grid>
            </TableCell>
            <TableCell>
                <Grid item>
                    <BoldTypography gutterBottom>-{transaction.value} MFG</BoldTypography>
                </Grid>
            </TableCell>
            {/* <TableCell align="right">{row.calories}</TableCell>
        <TableCell align="right">{row.fat}</TableCell>
        <TableCell align="right">{row.carbs}</TableCell>
        <TableCell align="right">{row.protein}</TableCell> */}
        </TableRow>
        // <Grid item container spacing={8} xs={12} direction="row" justify="space-between" alignContent="center">
        //     <Grid container item xs={2} direction="row" alignContent="center">
        //         <CallMadeIcon style={{ color: 'white' }} />
        //         <RightTypography gutterBottom>{t('wallet-transactions-sent')}</RightTypography>
        //     </Grid>
        //     <Grid container item xs={6} spacing={0}>
        //         <Grid container direction="row" alignItems="center">
        //             <Grid item>
        //                 <Typography variant="h3">{transaction.context}</Typography>
        //             </Grid>
        //             <Grid item style={{ marginLeft: '12px' }}>
        //                 <Tooltip style={{ backgroundColor: 'black' }} title="View on Etherscan">
        //                     <AttachmentIcon
        //                         style={{ color: 'white' }}
        //                         onClick={() => {
        //                             window.open('https://etherscan.io/tx/' + transaction.txnHash, '_blank')
        //                         }}
        //                     />
        //                 </Tooltip>
        //             </Grid>
        //         </Grid>
        //         <Typography gutterBottom>
        //             To:{' '}
        //             <Button variant="text" color="primary">
        //                 {transaction.particular}
        //             </Button>
        //         </Typography>
        //         {/* <Typography gutterBottom>
        //             {t('wallet-transactions-sent-description')} {transaction.context} {!!transaction.context && transaction.context.transactionHash}
        //         </Typography> */}
        //     </Grid>
        //     <Grid item xs={2}>
        //         <Typography gutterBottom>{new Date(transaction.createdAt).toLocaleDateString()}</Typography>
        //     </Grid>
        //     <Grid item xs={2}>
        //         <BoldTypography gutterBottom>-{transaction.value} MFG</BoldTypography>
        //     </Grid>
        // </Grid>
    )
})
