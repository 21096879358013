import React from 'react'
import classNames from 'classnames'
import Select from 'react-select'
import AsyncSelect from 'react-select/lib/Async'
import { withStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import TextField from '@material-ui/core/TextField'
import Paper from '@material-ui/core/Paper'
import Chip from '@material-ui/core/Chip'
import MenuItem from '@material-ui/core/MenuItem'
import CancelIcon from '@material-ui/icons/Cancel'
import { emphasize } from '@material-ui/core/styles/colorManipulator'
import { FormControl } from '@material-ui/core'
import { FormFieldFooter } from '../Error'

const styles = theme => ({
    root: {
        'flex-grow': 1,
        height: 250,
    },
    input: {
        display: 'flex',
        padding: 0,
        height: 'auto !important',
    },
    valueContainer: {
        display: 'flex',
        'flex-wrap': 'wrap',
        flex: 1,
        alignItems: 'center',
        overflow: 'hidden',
        marginLeft: 12,
        height: 'auto !important',
    },
    chip: {
        margin: `${theme.spacing.unit / 2}px ${theme.spacing.unit / 4}px`,
    },
    chipFocused: {
        backgroundColor: emphasize(theme.palette.type === 'light' ? theme.palette.grey[300] : theme.palette.grey[700], 0.08),
    },
    noOptionsMessage: {
        padding: `${theme.spacing.unit}px ${theme.spacing.unit * 2}px`,
    },
    singleValue: {
        fontSize: 16,
    },
    placeholder: {
        position: 'absolute' as any,
        left: 12,
        fontSize: 16,
    },
    paper: {
        position: 'absolute' as any,
        zIndex: 1,
        marginTop: theme.spacing.unit,
        left: 0,
        right: 0,
    },
    divider: {
        height: theme.spacing.unit * 2,
    },
})

function NoOptionsMessage(props) {
    return (
        <Typography color="textSecondary" className={props.selectProps.classes.noOptionsMessage} {...props.innerProps}>
            {props.children}
        </Typography>
    )
}

function inputComponent({ inputRef, ...props }) {
    return <div ref={inputRef} {...props} />
}

function Control(props) {
    return (
        <TextField
            fullWidth
            InputProps={{
                inputComponent,
                inputProps: {
                    className: props.selectProps.classes.input,
                    inputRef: props.innerRef,
                    children: props.children,
                    ...props.innerProps,
                },
            }}
            {...props.selectProps.textFieldProps}
            error={!!props.selectProps.textFieldProps.error}
        />
    )
}

function Option(props) {
    return (
        <MenuItem
            buttonRef={props.innerRef}
            selected={props.isFocused}
            component="div"
            style={{
                fontWeight: props.isSelected ? 500 : 400,
            }}
            {...props.innerProps}
        >
            {props.children}
        </MenuItem>
    )
}

function Placeholder(props) {
    return (
        <Typography color="textSecondary" className={props.selectProps.classes.placeholder} {...props.innerProps}>
            {props.children}
        </Typography>
    )
}

function SingleValue(props) {
    return (
        <Typography className={props.selectProps.classes.singleValue} {...props.innerProps}>
            {props.children}
        </Typography>
    )
}

function ValueContainer(props) {
    return <div className={props.selectProps.classes.valueContainer}>{props.children}</div>
}

function MultiValue(props) {
    return (
        <Chip
            tabIndex={-1}
            label={props.children}
            className={classNames(props.selectProps.classes.chip, {
                [props.selectProps.classes.chipFocused]: props.isFocused,
            })}
            onDelete={props.removeProps.onClick}
            deleteIcon={<CancelIcon {...props.removeProps} />}
        />
    )
}

function Menu(props) {
    return (
        <Paper square className={props.selectProps.classes.paper} {...props.innerProps}>
            {props.children}
        </Paper>
    )
}

const components = {
    Control,
    Menu,
    MultiValue,
    NoOptionsMessage,
    Option,
    Placeholder,
    SingleValue,
    ValueContainer,
    IndicatorSeparator: null,
}

const Autocomplete = ({ input, label, meta: { touched, error }, placeholder, isMulti, fullWidth, className, disabled, classes, options, description, onInputChange, setRef = () => {} }: any) => {
    const ReactSelect: any = Select
    return (
        <FormControl fullWidth={fullWidth} className={className} disabled={disabled}>
            <ReactSelect
                ref={setRef}
                classes={classes}
                textFieldProps={{
                    label,
                    InputLabelProps: {
                        shrink: true,
                    },
                    error: !!touched && !!error,
                    disabled,
                }}
                components={components}
                placeholder={placeholder}
                name={input.name}
                closeMenuOnSelect={!isMulti}
                defaultValue={[]} // TODO: Add it when needed
                isMulti={isMulti}
                options={options}
                onChange={event => input.onChange(event)}
                value={input.value}
                isClearable
                isDisabled={disabled}
                onInputChange={onInputChange}
            />
            <FormFieldFooter touched={touched} error={error} description={description} />
        </FormControl>
    )
}

const AsyncAutocomplete = ({ input, label, meta: { touched, error }, loadOptions, placeholder, isMulti, fullWidth, className, disabled, classes, options, description }: any) => {
    const ReactAsyncSelect: any = AsyncSelect
    return (
        <FormControl fullWidth={fullWidth} className={className} disabled={disabled}>
            <ReactAsyncSelect
                classes={classes}
                textFieldProps={{
                    label,
                    InputLabelProps: {
                        shrink: true,
                    },
                    error: !!touched && !!error,
                    disabled,
                }}
                components={components}
                placeholder={placeholder}
                name={input.name}
                closeMenuOnSelect={!isMulti}
                defaultValue={[]} // TODO: Add it when needed
                isMulti={isMulti}
                options={options}
                loadOptions={loadOptions}
                onChange={event => input.onChange(event)}
                value={input.value}
                isClearable
                isDisabled={disabled}
            />
            <FormFieldFooter touched={touched} error={error} description={description} />
        </FormControl>
    )
}

export const FormAutocomplete = withStyles(styles)(Autocomplete)
export const FormAsyncAutocomplete = withStyles(styles)(AsyncAutocomplete)
