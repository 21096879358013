import * as React from 'react'
import { WithStyles, withStyles } from '@material-ui/core'

const styles = theme => ({
    container: {
        height: '100vh',
        display: 'flex',
        'flex-direction': 'column',
        paddingTop: theme.spacing(7),
    },
    centeredContainer: {
        height: '100vh',
        display: 'flex',
        'flex-direction': 'column',
        justifyContent: 'center',
        alignItems: 'center',
        paddingTop: theme.spacing(7),
    },

    centeredAlignedContainer: {
        height: '100vh',
        display: 'flex',
        'flex-direction': 'column',
        alignItems: 'center',
        paddingTop: theme.spacing(7),
    },
    centeredAlignedContainerV2: {
        //height: '100vh',
        display: 'flex',
        'flex-direction': 'column',
        alignItems: 'center',
        padding: theme.spacing(6),
        paddingTop: theme.spacing(7),
        backgroundColor: '#1C2D3E',
    },
})

interface ContainerProps extends WithStyles {
    children: any
}

const centeredContainer = ({ classes, children }: ContainerProps) => {
    return <div className={classes.centeredContainer}>{children}</div>
}

export const CenteredContainer = withStyles(styles, { withTheme: true })(centeredContainer)

const centeredAlignedContainer = ({ classes, children }: ContainerProps) => {
    return <div className={classes.centeredAlignedContainer}>{children}</div>
}

export const CenteredAlignedContainer = withStyles(styles, { withTheme: true })(centeredAlignedContainer)

const centeredAlignedContainerV2 = ({ classes, children }: ContainerProps) => {
    return <div className={classes.centeredAlignedContainerV2}>{children}</div>
}

export const CenteredAlignedContainerV2 = withStyles(styles, { withTheme: true })(centeredAlignedContainerV2)

const container = ({ classes, children }: ContainerProps) => {
    return <div className={classes.container}>{children}</div>
}

export const Container = withStyles(styles, { withTheme: true })(container)
