import { Supplier } from '@syncfab/machine'
import { SUPPLIER_CREATE, SUPPLIER_VALIDATE, SUPPLIER_FORGOT_PASSWORD, GET_SUPPLIERS, GET_SUPPLIER, UPDATE_LAST_ACTIVE, GET_SUPPLIER_TAGS } from './Actions'
import { GET_SUPPLIER_DATA_RFQ } from '../quote/rfq/Actions'

export type SupplierReducerType = {
    supplier?: Partial<Supplier>
    signupError?: string
    validationError?: string
    valid?: boolean
    forgotPasswordEmail?: string
    suppliers?: any
    showSupplierTab?: boolean
    isLoading?: boolean
    supplierDetail?: any
    supplierTags?: any
}

const initialState: SupplierReducerType = { showSupplierTab: false }

export const SupplierReducer = (state = initialState, action: any) => {
    switch (action.type) {
        case SUPPLIER_CREATE.types.SUCCESSFULL:
            return {
                ...state,
                supplier: action.data,
                signupError: null,
            }
        case SUPPLIER_CREATE.types.FAILED:
            return {
                ...state,
                signupError: action.error,
            }
        case SUPPLIER_VALIDATE.types.SUCCESSFULL:
            return {
                ...state,
                valid: true,
                validationError: action.data.validationError,
            }
        case SUPPLIER_VALIDATE.types.SUCCESSFULL:
            return {
                ...state,
                valid: true,
                validationError: null,
            }
        case SUPPLIER_VALIDATE.types.FAILED:
            return {
                ...state,
                valid: false,
                validationError: action.error,
            }
        case GET_SUPPLIER_DATA_RFQ.types.SUCCESSFULL:
            return {
                ...state,
                supplier: action.data.supplier,
            }
        case SUPPLIER_FORGOT_PASSWORD.types.SUCCESSFULL:
            return {
                ...state,
                forgotPasswordEmail: action.data.email,
            }
        case GET_SUPPLIERS.types.TRIGGER:
            return {
                ...state,
                isLoading: true,
            }
        case GET_SUPPLIERS.types.SUCCESSFULL:
            return {
                ...state,
                suppliers: action.data.suppliers && action.data.suppliers.data ? action.data.suppliers.data : [],
                showSupplierTab: action.data.showSupplierTab,
                isLoading: false,
            }
        case GET_SUPPLIERS.types.FAILED:
            return {
                ...state,
                isLoading: false,
                showSupplierTab: false,
                suppliers: [],
            }
        case GET_SUPPLIER.types.TRIGGER:
            return {
                ...state,
                isLoading: true,
            }
        case GET_SUPPLIER.types.SUCCESSFULL:
            return {
                ...state,
                supplierDetail: action.data,
                isLoading: false,
            }
        case GET_SUPPLIER.types.FAILED:
            return {
                ...state,
                supplierDetail: null,
                suppliers: [],
            }
        case UPDATE_LAST_ACTIVE.types.TRIGGER:
            return {
                ...state,
                isLoading: true,
            }
        case UPDATE_LAST_ACTIVE.types.SUCCESSFULL:
            return {
                ...state,
                isLoading: false,
            }
        case UPDATE_LAST_ACTIVE.types.FAILED:
            return {
                ...state,
                isLoading: false,
            }

        case GET_SUPPLIER_TAGS.types.TRIGGER:
            return {
                ...state,
                isLoading: true,
            }
        case GET_SUPPLIER_TAGS.types.SUCCESSFULL:
            return {
                ...state,
                supplierTags: action.data,
                isLoading: false,
            }
        case GET_SUPPLIER_TAGS.types.FAILED:
            return {
                ...state,
                isLoading: false,
                supplierTags: null,
            }
        default:
            return state
    }
}
