import React from 'react'
import { Select as MUISelect, FormControl, InputLabel, FilledInput, Input } from '@material-ui/core'
import { FormFieldFooter } from '../Error'

const margins = {
    marginTop: 8,
    marginBottom: 4,
}

export const Select = MUISelect

const FieldSelect = ({ input, value, label, meta: { touched, error }, fullWidth, multiple, className, children, disabled }: any) => {

    const optionsAvailable = !children || children.length === 0
    const fieldValue = optionsAvailable ? '' : (value || input.value)
    return (
        <React.Fragment>
            <FormControl variant="filled" error={!!touched && !!error} style={margins} fullWidth={fullWidth} className={className}>
                <InputLabel htmlFor={input.name}>{label}</InputLabel>
                <Select disabled={disabled} multiple={multiple} onChange={input.onChange} input={<FilledInput name={input.name} />} children={children} {...input} value={fieldValue} />
                <FormFieldFooter touched={touched} error={error} />
            </FormControl>
        </React.Fragment>
    )
}

const FieldOutlinedSelect = ({ input, value, label, meta: { touched, error }, fullWidth, multiple, className, children, disabled }: any) => {

    const optionsAvailable = children?.length > 0
    const emptyValue = multiple ? [] : ''
    let fieldValue
    if ( value ) {
        fieldValue = value
    } else if ( Array.isArray(input.value) && input.value.length > 0) {
        fieldValue = input.value
    } else if ( !Array.isArray(input.value) && input.value ) {
        fieldValue = input.value
    } else {
        fieldValue = emptyValue
    }
    const selectValue = optionsAvailable ? fieldValue : emptyValue

    return (
        <React.Fragment>
            <FormControl variant="outlined" error={!!touched && !!error} style={margins} fullWidth={fullWidth} className={className}>
                <InputLabel id={`select-${label}`}>{label}</InputLabel>
                <Select labelId={`select-${label}`} label={label} disabled={disabled} multiple={multiple} onChange={input.onChange} children={children} {...input} value={selectValue} />
                <FormFieldFooter touched={touched} error={error} />
            </FormControl>
        </React.Fragment>
    )
}

const FieldInputSelect = ({ input, value, label, meta: { touched, error }, fullWidth, multiple, className, children, disabled }: any) => {
    return (
        <React.Fragment>
            <FormControl error={!!touched && !!error} style={margins} fullWidth={fullWidth} className={className}>
                <InputLabel htmlFor={input.name}>{label}</InputLabel>
                <Select disabled={disabled} multiple={multiple} value={value || input.value} onChange={input.onChange} input={<Input name={input.name} />} children={children} {...input} />
                <FormFieldFooter touched={touched} error={error} />
            </FormControl>
        </React.Fragment>
    )
}

export { FieldSelect, FieldInputSelect, FieldOutlinedSelect }
