export const SHOW_FEEDBACK_ACTION_TYPE: string = 'SHOW_FEEDBACK';
export const HIDE_FEEDBACK_ACTION_TYPE: string = 'HIDE_FEEDBACK';
export const SET_SAVING_MESSAGE_TYPE: string = 'SHOW_SAVING_MESSAGE_TYPE';
export const SHOW_SAVING_FEEDBACK_ACTION_TYPE: string =
  'SHOW_SAVING_FEEDBACK_ACTION_TYPE';
export const HIDE_SAVING_FEEDBACK_ACTION_TYPE: string =
  'HIDE_SAVING_FEEDBACK_ACTION_TYPE';
export const SHOW_ERROR_FEEDBACK_ACTION_TYPE: string =
  'SHOW_ERROR_FEEDBACK_ACTION_TYPE';
export const HIDE_ERROR_FEEDBACK_ACTION_TYPE: string =
  'HIDE_ERROR_FEEDBACK_ACTION_TYPE';
export const SHOW_COMPLETE_FEEDBACK_ACTION_TYPE: string =
  'SHOW_COMPLETE_FEEDBACK_ACTION_TYPE';
export const HIDE_COMPLETE_FEEDBACK_ACTION_TYPE: string =
  'HIDE_COMPLETE_FEEDBACK_ACTION_TYPE';
export const SHOW_DIRTY_FEEDBACK_ACTION_TYPE: string =
  'SHOW_DIRTY_FEEDBACK_ACTION_TYPE';
export const HIDE_DIRTY_FEEDBACK_ACTION_TYPE: string =
  'HIDE_DIRTY_FEEDBACK_ACTION_TYPE';

export const SHOW_COMPLETE_FEEDBACK = () => {
  return {
    type: SHOW_COMPLETE_FEEDBACK_ACTION_TYPE,
  };
};

export const HIDE_COMPLETE_FEEDBACK = () => {
  return {
    type: HIDE_COMPLETE_FEEDBACK_ACTION_TYPE,
  };
};

export const SHOW_ERROR_FEEDBACK = () => {
  return {
    type: SHOW_ERROR_FEEDBACK_ACTION_TYPE,
  };
};

export const HIDE_ERROR_FEEDBACK = () => {
  return {
    type: HIDE_ERROR_FEEDBACK_ACTION_TYPE,
  };
};
export const SHOW_DIRTY_FEEDBACK = () => {
  return {
    type: SHOW_DIRTY_FEEDBACK_ACTION_TYPE,
  };
};

export const HIDE_DIRTY_FEEDBACK = () => {
  return {
    type: HIDE_DIRTY_FEEDBACK_ACTION_TYPE,
  };
};

export const SHOW_SAVING_FEEDBACK = () => {
  return {
    type: SHOW_SAVING_FEEDBACK_ACTION_TYPE,
  };
};

export const HIDE_SAVING_FEEDBACK = () => {
  return {
    type: HIDE_SAVING_FEEDBACK_ACTION_TYPE,
  };
};

export const SHOW_FEEDBACK = (message: string) => {
  return {
    message,
    type: SHOW_FEEDBACK_ACTION_TYPE,
  };
};

export const HIDE_FEEDBACK = () => {
  return {
    type: HIDE_FEEDBACK_ACTION_TYPE,
  };
};

export const SHOW_SAVING_MESSAGE = (message: string) => {
  return {
    type: SET_SAVING_MESSAGE_TYPE,
    message,
  };
};
