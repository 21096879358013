import React from 'react'
import { connect } from 'react-redux'
import { reduxForm } from 'redux-form/immutable'
import { InjectedFormProps } from 'redux-form'
import { WithStyles, withStyles } from '@material-ui/core/styles'
import { withNamespaces, WithNamespaces } from 'react-i18next'
import { User, Button, jumpToFirstError } from '@syncfab/machine'
import { State } from '../../../application/Store'
import { NotificationSetting } from '../../../components/user/components/NotificationSettings'
import { Grid } from '@material-ui/core'

export const SettingsNotificationsFormName: string = 'UserNotificationSettingsForm'

const styles = theme => ({
    form: {
        // display: 'flex',
        // 'flex-direction': 'column',
        marginTop: theme.spacing(1),
        margin: 'auto',
    },
    description: {
        'text-align': 'left',
        fontSize: 12,
    },
    action: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(1),
        marginLeft: 'auto',
    },
})

interface FormProps extends InjectedFormProps<{}, {}>, WithStyles, WithNamespaces {
    loggedUser?: User
}

const form = (props: FormProps) => {
    const { handleSubmit, submitting, t, classes, loggedUser } = props

    if (!loggedUser) {
        return <div />
    }

    const notificationSettings = loggedUser ? loggedUser.notificationSettings || [] : []
    return (
        <form onSubmit={handleSubmit} className={classes.form}>
            <Grid container>
                <Grid item xs={12} md={12}>
                    <NotificationSetting notificationSettings={notificationSettings} />
                </Grid>
                <Grid item xs={12} md={12} style={{ textAlign: 'end' }}>
                    <Button type="submit" color="primary" disabled={submitting} className={classes.action}>
                        {t('form-action-save')}
                    </Button>
                </Grid>
            </Grid>
        </form>
    )
}

const translatedForm = withNamespaces()(withStyles(styles)(form))

const mapStateToProps = ({ user: { loggedUser } }: State) => {
    return {
        loggedUser,
    }
}

const connectedForm = connect(mapStateToProps)(translatedForm)

export const UserNotificationSettingsForm = reduxForm<{}, {}>({
    form: SettingsNotificationsFormName,
    onSubmitFail: jumpToFirstError,
})(connectedForm)
