import {
    DateFormatter,
    Document,
    DocumentCardList,
    DocumentManager,
    DocumentMenuOption,
    DocumentType,
    EntityType,
    FileExtensions,
    getSupplierPurchaseOrderSummary,
    HeaderNavLink,
    List,
    ListMeta,
    Loader,
    MIMEFileType,
    PDFViewer,
    PurchaseOrderLineItem,
    PurchaseOrderLineItemStatus,
    PurchaseOrderStatus, PurchaseOrderTotalSummary,
    SHOW_FEEDBACK,
    SortDirection,
    SupplierPurchaseOrderLineItemQuery,
    useGetSupplierPurchaseOrderQuery,
    useListPurchaseOrderLineItemsQuery,
    useStandardStyles,
    useUpdateSupplierPurchaseOrderMutation
} from "@syncfab/machine";
import Helmet from "react-helmet";
import {
    Box,
    Button,
    Dialog,
    DialogContent,
    Divider,
    Grid,
    Paper,
    Typography,
    useTheme
} from "@material-ui/core";
import React, { FC, useEffect, useState } from "react";
import { useParams } from "react-router";
import { RFQPORequirements } from "../quotes/details/components/RFQPORequirements";
import InfoIcon from "@material-ui/icons/Info";
import TodayIcon from "@material-ui/icons/Today";
import { skipToken } from "@reduxjs/toolkit/query";
import { SupplierPurchaseOrderLineItemRow } from "./components/SupplierPurchaseOrderLineItemRow";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { SupplierPOStatusChip } from "./components/SupplierPOStatusChip";
import { ChangeRequest } from "../quotes/details/components/ChangeRequest";

export const ViewSupplierPurchaseOrder: FC = (() => {

    const standardClasses = useStandardStyles()
    const theme = useTheme()

    const token = useSelector((state: any) => state.auth.authToken)
    const dispatch = useDispatch()

    const { supplierPurchaseOrderId } = useParams<{ supplierPurchaseOrderId?: string }>()

    const [documentIdToView, setDocumentIdToView] = useState<string>('')
    const [isPDFViewerOpen, setIsPDFViewerOpen] = useState<boolean>(false)
    const [documentEntity, setDocumentEntity] =
        useState<{ entityId: string, entityType: EntityType}>(null)

    const [documents, setDocuments] = useState<Document[]>([])

    const [isChangeRequestDialogOpen, setIsChangeRequestDialogOpen] =
        useState<boolean>(false)

    const cancelChangeRequestDialog = () => {
        setIsChangeRequestDialogOpen(false)
    }

    const completeChangeRequest = () => {
        setIsChangeRequestDialogOpen(false)
    }

    const [listParams, setListParams] = useState<SupplierPurchaseOrderLineItemQuery>({
        page: 0,
        size: 10,
        sortField: '_id',
        sortDirection: SortDirection.DESC,
        searchTerm: '',
        poId: ''
    })

    const poLineItemColumns = [
        { id: 'col1', label: 'Line ID', fixed: true },
        { id: 'col2', label: 'Line Details', fixed: true },
        { id: 'col3', label: 'Line Requirements', fixed: true },
        { id: 'col4', label: 'Quantities', fixed: true },
        { id: 'col5', label: 'Documents', fixed: true },
        { id: 'col6', label: 'Charges', fixed: true }
    ]

    const {
        data: supplierPOData,
        isLoading: isSupplierPODataLoading,
        isError: isSupplierPODataError,
        error: supplierPODataError
    } = useGetSupplierPurchaseOrderQuery(supplierPurchaseOrderId)

    useEffect(() => {
        if (!!supplierPOData) {
            setListParams({ ...listParams, poId: supplierPOData.poId })
            setDocuments(supplierPOData.documents)
        }
    }, [supplierPOData])

    const {
        data: poLineItemData,
        isLoading: isPOLineItemDataLoading,
        // refetch: refetchPOLineItems
    } = useListPurchaseOrderLineItemsQuery(!!listParams.poId ? listParams: skipToken)

    const [updateSupplierPO] = useUpdateSupplierPurchaseOrderMutation()

    const handleListMetaChange = (meta: ListMeta) => {
        setListParams({ ...listParams, ...meta })
    }

    const handleViewDocument = ((docId: string, entityId: string, entityType: EntityType) => {
        setDocumentEntity({ entityId, entityType })
        setDocumentIdToView(docId)
        setIsPDFViewerOpen(true)
    })

    const handleAllFilesProcessed = async () => {
        try {
            await updateSupplierPO({
                supplierPOId: supplierPurchaseOrderId,
                supplierPOInput: { documentIds: documents.map(doc => doc._id) }
            }).unwrap()
            dispatch(SHOW_FEEDBACK('Documents uploaded.'))
        } catch(error) {
            dispatch(SHOW_FEEDBACK(error.data))
        }
    }

    const handleRemoveDocument = async (docIds: string[], docId: string) => {
        try {
            await updateSupplierPO({
                supplierPOId: supplierPurchaseOrderId,
                supplierPOInput: { documentIds: docIds.filter(id => id !== docId) }
            })
            dispatch(SHOW_FEEDBACK('Document removed.'))
        } catch(error) {
            dispatch(SHOW_FEEDBACK(error.data))
        }
    }

    if (isSupplierPODataLoading || isPOLineItemDataLoading) {
        return <Loader/>
    }

    if (isSupplierPODataError) {
        // @ts-ignore
        const errorStatusCode = supplierPODataError.status
        return (
            <div className={standardClasses.centeredAlignedContainer}>
                {errorStatusCode === 401 ?
                    (
                        <Typography style={{ fontSize: 32, fontWeight: 'bold' }}>
                            You are not authorized to view this Purchase Order.
                        </Typography>
                    ) : (
                        <Typography style={{ fontSize: 32, fontWeight: 'bold' }}>
                            An error has occurred.
                        </Typography>
                    )
                }
            </div>
        )
    }

    if (!!supplierPOData && !!poLineItemData) {
        // Only calculate totals for non-cancelled line items.
        const nonCanceledLineItems = supplierPOData.po.lineItems.filter(e => e.status !== PurchaseOrderLineItemStatus.CANCELED);
        const poSummary = getSupplierPurchaseOrderSummary(nonCanceledLineItems)
        return (
            <div className={standardClasses.centeredAlignedContainer}>
                <Helmet>
                    <title>Purchase Orders</title>
                </Helmet>
                <HeaderNavLink linkTo="/supplierPurchaseOrders" linkText="PO INBOX"/>
                <Grid container className={standardClasses.header} spacing={2}>
                    <Grid item xs={12} md={8}>
                        <Typography
                            style={{ marginBottom: '8px', fontSize: 32, fontWeight: 'bold' }}>{supplierPOData.number}
                        </Typography>
                    </Grid>
                </Grid>
                <Grid container spacing={2}>
                    <Grid item xs={12} md={8}>
                        <Paper className={standardClasses.paper}>
                            <Typography style={{ padding: '16px', fontSize: '20px', fontWeight: 'bold' }}>
                                Line Items
                            </Typography>
                            {!isPOLineItemDataLoading && poLineItemData.data.length > 0 &&
                                <List<PurchaseOrderLineItem>
                                    columns={poLineItemColumns}
                                    data={poLineItemData}
                                    meta={listParams}
                                    onMetaChange={handleListMetaChange}
                                    renderRow={(row: PurchaseOrderLineItem) => {
                                        return <SupplierPurchaseOrderLineItemRow
                                            key={row._id}
                                            poLineItem={row}
                                            handleViewDocument={handleViewDocument}
                                            token={token}
                                        />
                                    }}
                                />
                            }
                        </Paper>
                        <RFQPORequirements rfqOrPO={supplierPOData.po} entityType='PO' />
                        {supplierPOData.po.additionalDocuments?.length > 0 && (
                            <Paper className={standardClasses.paper}>
                                <Grid item xs={12} style={{ padding: '16px' }}>
                                    <Typography gutterBottom style={{ fontSize: 20, fontWeight: 'bold' }}>
                                        Supporting Documents
                                    </Typography>
                                </Grid>
                                <Divider style={{ width: '100%', color: "#FFFFFF40" }}/>
                                <Grid item xs={12} style={{ padding: '24px' }}>
                                    <DocumentCardList
                                        documents={supplierPOData.po.additionalDocuments}
                                        setAreDocumentsDirty={() => {}}
                                        setDocuments={() => {}}
                                        availableMenuOptions={[DocumentMenuOption.VIEW, DocumentMenuOption.DOWNLOAD]}
                                        showType={false}
                                        showVisibility={false}
                                        entity='PO'
                                        entityId={supplierPOData.poId}
                                    />
                                </Grid>
                            </Paper>
                        )}
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <Paper className={standardClasses.paper}>
                            <Grid container direction='column' style={{ padding: '16px' }}>
                                <Typography style={{ fontSize: 20, fontWeight: 'bold' }}>PO Action Items</Typography>
                                <Box style={{ paddingTop: '16px', display: 'flex' }}>
                                    <InfoIcon fontSize='medium' style={{ color: '#A0AEC0' }}/>
                                    <Typography style={{ color: '#A0AEC0', fontWeight: 'bold', marginLeft: '8px', marginRight: '8px' }}>
                                        PO Status:
                                    </Typography>
                                    <SupplierPOStatusChip status={supplierPOData.po.status as PurchaseOrderStatus} />
                                </Box>
                                <Box style={{ paddingTop: '16px', display: 'flex' }}>
                                    <TodayIcon fontSize='medium' style={{ color: '#A0AEC0' }}/>
                                    <Typography style={{ color: '#A0AEC0', fontWeight: 'bold', marginLeft: '8px' }}>
                                        PO Issued:&nbsp;
                                        <span style={{ color: '#F3F3F3' }}>
                                        {supplierPOData.issueDate ? DateFormatter.format(
                                            new Date(supplierPOData.issueDate)) : '--'}
                                    </span>
                                    </Typography>
                                </Box>
                                <Box style={{ display: 'flex', justifyContent: 'center' }}>
                                    <Button
                                        variant='outlined'
                                        style={{
                                            borderColor: theme.palette.primary.main,
                                            color: theme.palette.primary.main,
                                            width: '140px',
                                            marginTop: '24px',
                                            marginRight: '16px'
                                        }}
                                        onClick={() => setIsChangeRequestDialogOpen(true)}
                                    >
                                        SEND UPDATE
                                    </Button>
                                </Box>
                                <Divider style={{ marginTop: '24px', marginBottom: '16px' }}/>
                                <Typography style={{ fontSize: 18, fontWeight: 'bold', marginBottom: '16px' }}>
                                    PO Documents
                                </Typography>
                                <Typography style={{ fontSize: 14, marginBottom: '16px' }}>
                                    Upload documents by dragging and dropping them below or browse your computer for the files.
                                </Typography>
                                <DocumentManager
                                    label=''
                                    documents={documents}
                                    setDocuments={setDocuments}
                                    setAreDocumentsDirty={() => {}}
                                    docType={DocumentType.SUPPORT_FILE}
                                    referenceId={''}
                                    maxFileSizeMB={20}
                                    acceptedFileExtensions={[
                                        FileExtensions.PDF,
                                        FileExtensions.JPEG,
                                        FileExtensions.JPG,
                                        FileExtensions.PNG
                                    ]}
                                    acceptedFileTypes={[
                                        MIMEFileType.JPG,
                                        MIMEFileType.PNG,
                                        MIMEFileType.PDF,
                                        MIMEFileType.EXTENSION,
                                    ]}
                                    fileAreaCaption='Accepted file types: PDF | JPG | PNG files - Max Single Document Size: 20MB' 
                                    showVisibility={false}
                                    showType={false}
                                    showRevision={false}
                                    handleRemoveDocument={handleRemoveDocument}
                                    handleAllFilesProcessed={handleAllFilesProcessed}
                                    entity={{ entityType: EntityType.SUPPLIER_PURCHASE_ORDER, entityId: supplierPOData._id }}
                                />
                            </Grid>
                        </Paper>
                        <Paper
                            className={standardClasses.paper}
                            style={{ paddingTop: '16px', paddingBottom: '16px' }}
                        >
                            <PurchaseOrderTotalSummary poSummary={poSummary} />
                            <Grid container item xs={12} md={12} justifyContent='flex-end'>
                                <Button
                                    variant='outlined'
                                    style={{
                                        borderColor: theme.palette.primary.main,
                                        color: theme.palette.primary.main,
                                        width: '100px',
                                        marginTop: '16px',
                                        marginRight: '16px'
                                    }}
                                    component={Link}
                                    to={`/supplierPurchaseOrders/${supplierPurchaseOrderId}/confirm?returnToPO`}
                                >
                                    VIEW PO
                                </Button>
                            </Grid>
                        </Paper>
                    </Grid>
                </Grid>
                <PDFViewer
                    documentId={documentIdToView}
                    open={isPDFViewerOpen}
                    entity={documentEntity?.entityType}
                    entityId={documentEntity?.entityId}
                    onClose={() => {
                        setIsPDFViewerOpen(false)
                        setDocumentIdToView('')
                    }}
                />
                <Dialog open={isChangeRequestDialogOpen} onClose={cancelChangeRequestDialog} fullWidth>
                    <DialogContent>
                        <ChangeRequest
                            cancel={cancelChangeRequestDialog}
                            complete={completeChangeRequest}
                            entityType={EntityType.PURCHASEORDER}
                            entityId={supplierPOData.po._id}
                        />
                    </DialogContent>
                </Dialog>

            </div>
        )
    }
    return null
})
