import { createSelector } from 'reselect'
import { State } from '../../application/Store'

const getSupplierTags = (state: State) => {
    return state.supplier.supplierTags
}

export const getSupplierTag = (type: string) => {
    return createSelector(
        getSupplierTags,
        supplierTags => {
            return !!supplierTags ? supplierTags.reduce((array, supplierTag) => (supplierTag.type === type && array.push({ label: supplierTag.name, value: supplierTag.value }), array), []) : []
        }
    )
}
export const getSupplierTagByArray = (types: string[]) => {
    return createSelector(
        getSupplierTags,
        supplierTags => {
            if(!supplierTags) return Object.fromEntries(types.map(t => [t, []]));
            const resultObj = Object.fromEntries(types.map(t => [t, []]));
            supplierTags.forEach(sT => {
                if(sT.type in resultObj)
                    resultObj[sT.type].push({label: sT.name, value: sT.value});
            })
            return resultObj;
        }
    )
}

