import { Gateway } from './Gateway'
import { UserAPI } from './UserAPI'
import { Environment, AuthorizationService } from '@syncfab/machine'
import { CompanyAPI } from './CompanyAPI'
import { DocumentAPI } from './DocumentAPI'
import { RFQAPI } from './RFQAPI'
import { OrderAPI } from './OrderAPI'
import { PartAPI } from './PartAPI'
import { BlockchainAPI } from './BlockchainAPI'
import { AnalyticsService } from '../../utils/Analytics/AnalyticsService'
import { SupplierAPI } from './SupplierAPI'
import { TransactionAPI } from './TransactionAPI'
import { DashboardAPI } from './DashboardAPI'
import { RFQV2API } from './RFQV2API'
export { Gateway } from './Gateway'

export default (environment: Environment, authorizationService: AuthorizationService, analyticsService: AnalyticsService) => {
    return new Gateway(
        analyticsService,
        new UserAPI(environment, authorizationService),
        new CompanyAPI(environment, authorizationService),
        new DocumentAPI(environment, authorizationService),
        new RFQAPI(environment, authorizationService),
        new RFQV2API(environment, authorizationService),
        new OrderAPI(environment, authorizationService),
        new PartAPI(environment, authorizationService),
        new BlockchainAPI(environment, authorizationService),
        new SupplierAPI(environment, authorizationService),
        new TransactionAPI(environment, authorizationService),
        new DashboardAPI(environment, authorizationService)
    )
}
