import 'date-fns';
import React, { FC } from 'react';
import { InputAdornment, Theme } from '@material-ui/core';
import { Lock, DateRange } from '@material-ui/icons';
import { DatePicker } from '@material-ui/pickers';
import {
  Controller,
  UseControllerProps,
  useFormContext,
} from 'react-hook-form';
import { createStyles, makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((_theme: Theme) =>
  createStyles({
    noBorder: {
      border: 'none',
    },
    noTopBottomPadding: {
      paddingTop: '0px',
      paddingBottom: '0px',
    },
  })
);

export interface RHFDataPickerProps {
  name: string;
  label: string;
  variant?: 'dialog' | 'inline' | 'static';
  minDate?: Date;
  rules?: UseControllerProps['rules'];
  required?: boolean;
  disabled?: boolean;
  isTableCellInput?: boolean;
  onChangeHandler?: Function;
  RHFChangeHandler?: (e: Date) => any;
  getDisplayValue?: (e: any) => string;
  onBlurExternal?: () => void;
}

// RHFChangeHandler will take the newDate option and then map it to desired value
// getDisplayValue will take the value from the form and show how it was displayed.
export const RHFDatePicker: FC<RHFDataPickerProps> = ({
  name,
  label,
  variant = 'dialog',
  minDate = new Date('2022-01-01'),
  rules,
  required = false,
  disabled = false,
  isTableCellInput = false,
  onChangeHandler = null,
  RHFChangeHandler = e => e,
  getDisplayValue = e => e,
  onBlurExternal = () => {},
}: RHFDataPickerProps) => {
  const methods = useFormContext();
  const classes = useStyles();

  let localInputProps;
  if (disabled) {
    localInputProps = {
      endAdornment: (
        <InputAdornment position="end">
          <Lock />
        </InputAdornment>
      ),
    };
  } else {
    localInputProps = {
      endAdornment: (
        <InputAdornment position="end">
          <DateRange />
        </InputAdornment>
      ),
    };
  }

  if (isTableCellInput) {
    localInputProps.classes = {
      notchedOutline: classes.noBorder,
      input: classes.noTopBottomPadding,
    };
  }

  return (
    <Controller
      name={name}
      control={methods.control}
      rules={rules}
      render={({
        field: { onChange, onBlur, value, ref: fieldRef },
        fieldState: { error },
      }) => {
        return (
          <DatePicker
            variant={variant}
            inputVariant="outlined"
            label={label}
            value={getDisplayValue(value)}
            onBlur={() => {
              onBlur();
              onBlurExternal();
            }}
            onChange={date => {
              onChange(RHFChangeHandler(date));
              if (!!onChangeHandler) {
                onChangeHandler(date);
              }
            }}
            InputProps={localInputProps}
            inputRef={fieldRef}
            minDate={minDate}
            format="MM/dd/yyyy"
            disabled={disabled}
            clearable
            fullWidth
            required={required}
            error={!!error}
            // helperText={!!error ? error?.message : null}
          />
        );
      }}
    />
  );
};
