import { focusFirstError, ValueLabelOption } from "../ui";
import { Dispatch, SetStateAction, useEffect, useState } from "react";
import {
    useAcknowledgeNotificationMutation,
    useGetNotificationsQuery,
    useListCompaniesQuery,
    useListUsersQuery,
    useListAddressesQuery
} from "../../application";
import { SortDirection } from "../BaseTypes";
import { createUserOption, LoginRole } from "../user";
import { skipToken } from "@reduxjs/toolkit/query";
import { ActionItemEntityType, EntityDefinition } from "../actionItems";
import { Notification } from "../notifications"
import { createAddressOption, createCompanyOption } from "../company";
import { UseFormReturn } from "react-hook-form";

export const useFormHelpers = <T> (methods: UseFormReturn<T>) => {
    useEffect(() => {
        focusFirstError<T>(methods)
    }, [methods.formState.errors, methods.setFocus])

    useEffect(() => {
        if(methods.formState.isSubmitSuccessful) {
            methods.reset({} as T, { keepValues: true})
        }
    }, [methods.formState.isSubmitSuccessful, methods.reset])
}

export const useOperatorOptions = (): [ValueLabelOption[], boolean] => {

    const [operatorOptions, setOperatorOptions] = useState<ValueLabelOption[]>(null)

    const {
        data: operatorData,
        isLoading
    } = useListUsersQuery({
        size: 0,
        sortField: 'firstName',
        sortDirection: SortDirection.ASC,
        loginRole: LoginRole.OPERATOR,
        userState: 'ACTIVE'
    })

    useEffect(() => {
        if (!!operatorData) {
            setOperatorOptions(_prevValue => operatorData.data.map(operator =>
                createUserOption(operator)))
        }
    }, [operatorData])

    return [operatorOptions, isLoading]
}

export const useBuyerCompanyOptions = (): [ValueLabelOption[], boolean] => {

    const [buyerCompanyOptions, setBuyerCompanyOptions] =
        useState<ValueLabelOption[]>(null)

    const {
        data: buyerCompanyData,
        isLoading
    } = useListCompaniesQuery({
        size: 0,
        sortField: 'name',
        sortDirection: SortDirection.ASC
    })

    useEffect(() => {
        if (!!buyerCompanyData) {
            setBuyerCompanyOptions(_prevValue =>
                buyerCompanyData.data.map(company => createCompanyOption(company)))
        }
    }, [buyerCompanyData])

    return [buyerCompanyOptions, isLoading]
}

export const useBuyerContactOptions = (buyerCompanyId: string, methods): [ValueLabelOption[], boolean] => {

    const [buyerContactOptions, setBuyerContactOptions] =
        useState<ValueLabelOption[]>(null)
    // initial state indicator is used to reset buyer contact to null when the company is changed, unless it is the first
    // time that the options are created.  This sets the initial value of the field to the value retrieved from the database
    const [isInitialState, setIsInitialState] = useState<boolean>(true)

    const {
        data,
        isLoading
    } = useListUsersQuery(!!buyerCompanyId ?
        {
            size: 0,
            sortField: 'firstName',
            sortDirection: SortDirection.ASC,
            loginRole: LoginRole.BUYER,
            companyId: buyerCompanyId
        } : skipToken)

    useEffect(() => {
        if (!!data) {
            isInitialState ? setIsInitialState(false) : methods.setValue('buyerContact', null)

            setBuyerContactOptions(_prevState =>
                data.data.map(user => createUserOption(user))
            )
        }
    },[data])

    return [buyerContactOptions, isLoading]
}

export const useAddressOptions = (companyId: string): [ValueLabelOption[], boolean] => {

    const [addressOptions, setAddressOptions] = useState<ValueLabelOption[]>(null)

    const {
        data,
        isLoading
    } = useListAddressesQuery(!!companyId ? { companyId }: skipToken)

    useEffect(() => {
        if (!!data) {
            setAddressOptions(_prevState =>
                data.data.map(address => createAddressOption(address))
            )
        }
    }, [data])

    return [addressOptions, isLoading]
}

export const useActionItemFormDialog = (): [
    boolean,
    Dispatch<SetStateAction<boolean>>,
    () => void,
    boolean,
    Dispatch<SetStateAction<boolean>>,
    () => void,
    EntityDefinition,
    Dispatch<SetStateAction<EntityDefinition>>,
] => {
    const [isActionItemDialogOpen, setIsActionItemDialogOpen] =
        useState<boolean>(false)
    const [isCompleteActionItemDialogOpen, setIsCompleteActionItemDialogOpen] =
        useState<boolean>(false)

    const cancelActionItemDialog = () => {
        setIsActionItemDialogOpen(false)
        setActionItemEntity(null)
    }

    const cancelCompleteActionItemDialog = () => {
        setIsCompleteActionItemDialogOpen(false)
        setActionItemEntity(null)
    }

    const [actionItemEntity, setActionItemEntity] =
        useState<{ entityId: string, entityType: ActionItemEntityType}>(null)

    return [
        isActionItemDialogOpen, setIsActionItemDialogOpen, cancelActionItemDialog,
        isCompleteActionItemDialogOpen, setIsCompleteActionItemDialogOpen, cancelCompleteActionItemDialog,
        actionItemEntity, setActionItemEntity,
    ]
}

export const useNotifications = (): [
    Notification[],
    boolean,
    (string) => void
] => {
    const { data, isLoading, refetch } = useGetNotificationsQuery(null, { pollingInterval: 30 * 1000 })

    const [acknowledgeNotification] = useAcknowledgeNotificationMutation()

    const handleNotificationClick = async (notificationId) => {
        await acknowledgeNotification(notificationId).unwrap()
        refetch()
    }

    return [data, isLoading, handleNotificationClick]
}