import React from 'react'
import { Field, reduxForm, getFormValues } from 'redux-form/immutable'
import { change, InjectedFormProps } from 'redux-form'
import { Map } from 'immutable'
import { WithStyles, withStyles } from '@material-ui/core/styles'
import { withNamespaces, WithNamespaces } from 'react-i18next'
import { FormTextField } from '@syncfab/machine'
import SearchIcon from '@material-ui/icons/Search'
import { IconButton, Tooltip } from '@material-ui/core'
import { connect } from 'react-redux'
import { State } from '../../application/Store'
import CloseIcon from '@material-ui/icons/Close'
import { Dispatch } from 'redux'
import { submit } from 'redux-form'

export const ListSearchFormName: string = 'ListSearchForm'

const styles = theme => ({
    form: {
        padding: `${theme.spacing(1)}px 0`,
        witdh: 250,
        marginRight: '15px',
    },
    searchInput: {
        width: 300,
        margin: '0 0 -10px',
    },
    '@media (max-width: 1024px)': {
        searchInput: {
            width: 200,
        },
    },
})

type SearchFields = {
    query?: string
}
interface Properties {
    formValues: Map<string, string>
    submitForm?: () => void
    updateField?: (field: string, data: string) => void
}
interface FormProps extends Properties, WithNamespaces, WithStyles, InjectedFormProps<{}, {}> {}

const validate = (data: Map<string, string>): {} => {
    const errors: SearchFields = {}
    const query = data.get('query')

    if (!!query && query.length < 3 && query.length > 0) {
        errors.query = 'Minimum of 3 letters'
    }

    return errors
}

const form = (props: FormProps) => {
    const { handleSubmit, submitting, t, classes, valid, formValues } = props
    const searchValue = formValues ? formValues?.get('query') : null
    return (
        <form onSubmit={handleSubmit} className={classes.form}>
            <Field
                name="query"
                component={FormTextField}
                type="text"
                label={t('search-label')}
                className={classes.searchInput}
                InputProps={{
                    endAdornment: (
                        <>
                            <Tooltip title="Search">
                                <IconButton aria-label="Search" type="submit" style={{ padding: 5, color: '#fff' }} disabled={!valid || !!submitting}>
                                    <SearchIcon />
                                </IconButton>
                            </Tooltip>
                            {searchValue && (
                                <IconButton
                                    type="submit"
                                    style={{ padding: 5, visibility: searchValue ? 'visible' : 'hidden', color: '#fff' }}
                                    onClick={async () => {
                                        await props.updateField('query', '')
                                        await props.submitForm()
                                    }}
                                >
                                    <CloseIcon />
                                </IconButton>
                            )}
                        </>
                    ),
                }}
            />
        </form>
    )
}

const translatedForm = withStyles(styles)(withNamespaces()(form))

const ListSearchViewWithTranslations = reduxForm<{}, Properties>({
    form: ListSearchFormName,
    destroyOnUnmount: false,
    validate,
    enableReinitialize: true,
})(translatedForm as any)

const mapStateToProps = (state: State) => {
    return {
        formValues: getFormValues(ListSearchFormName)(state) as Map<string, any>,
    }
}
const mapDispatchToProps = (dispatch: Dispatch) => ({
    updateField: (field, data) => dispatch(change(ListSearchFormName, field, data)),
    submitForm: () => dispatch(submit(ListSearchFormName)),
})

export default connect(mapStateToProps, mapDispatchToProps)(ListSearchViewWithTranslations)
