import React from 'react'
import { Button } from '@material-ui/core'
import { Alert, AlertTitle } from '@material-ui/lab'
import NotificationsIcon from '@material-ui/icons/Notifications'
import { getNotificationContent, Notification } from "../Notification";
import { Link } from "react-router-dom";

interface AlertBoxProps {
    notification: Notification
    handleNotificationClick: (string) => void
}
export const AlertBox = ({ notification, handleNotificationClick }: AlertBoxProps) => {

    const notificationContent = getNotificationContent(notification)

    return (
        <Alert
            severity='error'
            icon={<NotificationsIcon style={{ color: '#DE3D31' }} />}
            style={{
                backgroundColor: '#83272180',
                color: '#FAB3AE',
                fontSize: 14
            }}
            action={
                <Button
                    color="primary"
                    variant="contained"
                    component={Link}
                    to={notificationContent.linkDestination}
                    onClick={() => handleNotificationClick(notification._id)}
                    style={{ marginRight: '16px' }}
                >
                    {notificationContent.linkText}
                </Button>

            }>
            <AlertTitle style={{ color: '#FAB3AE', fontSize: 16 }}>{notificationContent.notificationId}</AlertTitle>
            {notificationContent.description}
        </Alert>
    )
}