import { Chip, Grid, Paper, Table, TableBody, TableCell, TableHead, TableRow, Typography } from '@material-ui/core'
import React, { useState, useEffect } from 'react'
import { FormProvider, useFieldArray, useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { array, object, SchemaOf, string } from 'yup'
import { withNamespaces, WithNamespaces } from 'react-i18next'
import { Theme, createStyles, makeStyles } from '@material-ui/core/styles'
import { useParams } from 'react-router'
import { useAppSelector } from '../../../../application/hooks'
import {
    DocumentManager,
    Button,
    DocumentType,
    RHFAutoComplete,
    ValueLabelOption,
    valueLabelSchema,
    RHFTextField,
    RHFTextEditor,
    Document,
    useUpdatePartMutation,
    useCreatePartMutation,
    useUpdateDocumentMutation,
    transformEntryToValueLabel,
    sortOptions,
    colors,
    useGetLoggedUserQuery, createTagOption, TagType, materialSchema, finishSchema, RHFCheckbox
} from '@syncfab/machine'
import { Link } from 'react-router-dom'
import _ from 'lodash-es'
import { useHistory } from 'react-router'

export const PartEditorFormName: string = 'PartEditorFormName'
const useStyles = makeStyles((theme: Theme) => {
    return createStyles({
        container: {
            paddingTop: theme.spacing(3),
        },
        formContainer: {
            width: '100%',
            marginTop: theme.spacing(3),
            padding: theme.spacing(3),
        },
        action: {
            minWidth: 75,
            marginLeft: 16,
        },
        header: {
            paddingTop: theme.spacing(3),
        },
        footerText: {
            'text-align': 'center',
            display: 'flex',
            justifyContent: 'center',
            margin: theme.spacing(1),
            color: theme.palette.grey.A700,
        },
        companyIdMissingText: {
            'text-align': 'center',
        },
        card: {
            width: '100%',
            margin: 0,
            // backgroundColor: theme.palette.common.white,
            padding: theme.spacing(2),
            position: 'relative' as any,
            backGroundColor: '#1C2D3E !important',
            border: `2px solid rgba(255, 255, 255, 0.23)`,
        },
        downloadButton: {
            color: theme.palette.primary.light,
        },
        fileSize: {
            color: theme.palette.grey.A200,
        },
        fileName: {
            color: theme.palette.common.white,
            'font-weight': 'bold',
        },
        removeButton: {
            padding: '0 !important',
            [theme.breakpoints.down('md')]: {
                padding: `${theme.spacing(2)}px !important`,
            },
        },
        textField: {
            marginLeft: theme.spacing(1),
            marginRight: theme.spacing(1),
            '& .MuiOutlinedInput-notchedOutline': {
                borderColor: theme.palette.common.white,
            },
            '& .MuiSelect-icon': {
                color: theme.palette.common.white,
            },
            '& .MuiInputLabel-root': {
                color: theme.palette.common.white,
            },
        },
        dense: {
            marginTop: 16,
        },
        menu: {
            width: 200,
        },
        section: {
            backgroundColor: '#F3F3F3',
            borderRadius: 5,
            padding: 8,
            marginLeft: `0px !important`,
            marginRight: `0px !important`,
            marginBottom: theme.spacing(4),
        },
        sectionHeader: {
            fontWeight: 'bold' as any,
        },
        item: {
            marginTop: theme.spacing(0.5),
        },
        gridItem: {
            paddingTop: '0px !important',
            paddingBottom: '0px !important',
        },
        formInput: {
            '& .MuiFormControl-marginNormal': {
                margin: 0,
            },
        },
        cardAction: {
            position: 'absolute' as any,
            top: 0,
            right: 0,
        },
        disabled: {
            '& .MuiOutlinedInput-root.Mui-disabled .MuiOutlinedInput-notchedOutline': {
                borderColor: theme.palette.common.white,
                opacity: 0.1,
                color: colors.disabled,
            },
            '& .MuiSelect-icon': {
                color: colors.disabled, // for icon drop down icon color
            },
            '& .MuiInputLabel-root': {
                color: colors.disabled,
            },
            '& MuiInputBase-root.Mui-disabled': {
                color: '#fff !important',
            },
            '&:disabled': {
                color: colors.disabled,
            },
        },
        borderCellWithInput: {
            border: '1px solid #ffffff20',
            backgroundColor: '#364F68',
            padding: '0px'
        },
        headerCellWithInput: {
            border: '1px solid #ffffff20',
            backgroundColor: "#364F68",
            paddingLeft: '14px',
            whiteSpace: 'nowrap',
            fontWeight: 'bold'
        },
    })
})

const formatForRHF = (tags) => tags.map(t => {
    return {
        label: t.name,
        value: t.value,
    }
})
interface PartEditorFormProps {
    company: string | null
}

const translateEntryToSubmit = entry => {
    if(typeof entry === 'object' && entry !== null && 'value' in entry) 
        return entry.value;
    return entry;
}

const translateArrayEntryToSubmit = arrEntry => {
    if (typeof arrEntry === null) return null;
    if (arrEntry.hasOwnProperty('value')) return arrEntry.value;
    return Object.fromEntries(Object.keys(arrEntry).map(a => {
        return [a, translateEntryToSubmit(arrEntry[a])]
    }))
}

const translateArrayToSubmit = arr => {
    return arr.map(a => {
       return translateArrayEntryToSubmit(a); 
    })
}

const transformExistingValuesToDefaultValues = (existingValues, tags) => {
    if(!!existingValues) {
        const copiedWithoutAlteration = _.pick(existingValues, [
            'company',
            'number',
            'revision',
            'name',
            'nationalStockNumber',
            'notes',
        ])
        return {
            manufacturingProcesses: existingValues?.manufacturingProcesses?.map(mP => {
                return transformEntryToValueLabel(mP);
            }),
            materials: existingValues?.materials?.map((m, index) => {
                return {
                    material: createTagOption(tags.find(tag => tag.type === TagType.MATERIAL_PREFERENCES && tag.value === m)),
                    details: existingValues?.materialDetails?.[index],
                    checked: false
                }
            }),
            finishes: existingValues?.finishes?.map((f, index) => {
                return {
                    finish: createTagOption(tags.find(tag => tag.type === TagType.FINISHING && tag.value === f)),
                    details: existingValues?.finishDetails?.[index],
                    checked: false
                }
            }),
            ...copiedWithoutAlteration,
        }
    }
    return undefined;
}

const translateFormDataForSubmit = formData => {
    const parsedData = {
        ...formData
    };
    for (const key in formData) {
        if(Array.isArray(formData[key])) {
            parsedData[key] = translateArrayToSubmit(formData[key]);
        }
        else parsedData[key] = translateEntryToSubmit(formData[key])
    }
    parsedData.company = { companyId: parsedData.company }
    parsedData.materials = formData.materials.map(item => item.material.value)
    parsedData.materialDetails = formData.materials.map(item => item.details)
    parsedData.finishes = formData.finishes.map(item => item.finish.value)
    parsedData.finishDetails = formData.finishes.map(item => item.details)
    return parsedData
}
const PartEditorFormSchema: SchemaOf<PartEditorFormProps> = (
    object({
        company: valueLabelSchema.required(),
        number: string().required(),
        revision: string().required(),
        name: string().required(),
        manufacturingProcesses: array(valueLabelSchema).min(1).required(),
        materials: array(materialSchema)
            .min(1, "Materials is a required field.")
            .required(),
        finishes: array(finishSchema)
            .min(1, "Finishes is a required field.")
            .required(),
        nationalStockNumber: string().optional().nullable(),
        notes: string().optional().nullable(),
    })
)
interface FormProps extends WithNamespaces {
    existingDefaultValues: any
}
const createMethods = (defaultValues) => {
    const absentDefaultValues = {
        company: null,
        number: null,
        revision: null,
        name: null,
        manufacturingProcesses: [],
        materials: [],
        finishes: [],
        nationalStockNumber: '',
        notes: '',
    }
    return useForm({
        resolver: yupResolver(PartEditorFormSchema, { stripUnknown: true }),
        defaultValues: {
            ...absentDefaultValues,
            ...defaultValues,
        },
    })
}

const formatCompanyForValueLabel = co => {
    return {
        value: co._id,
        label: `${co._id} - ${co?.company.name ?? ''}`
    }
}

const PartEditorForm: React.FC<FormProps> = ({ existingDefaultValues, t }) => {
    const createdAt = existingDefaultValues?.createdAt ?? null; 
    const editing = !!existingDefaultValues;
    const { companyId, partId } = useParams<{companyId: string, partId: string}>();
    const classes = useStyles();
    const history = useHistory();

    const supplierTags = useAppSelector(state => state.supplier.supplierTags)
    const methods = createMethods(transformExistingValuesToDefaultValues(existingDefaultValues, supplierTags));
    const [additionalDocuments, setAdditionalDocuments] = useState<Document[]>(existingDefaultValues?.documents ?? [])
    const [areAdditionalDocumentsDirty, setAreAdditionalDocumentsDirty] = useState<boolean>(false)

    // Get tags for option select menus
    let materialPreferenceTags = [];
    let manufacturingTags = [];
    let finishingTags = [];
    // Faster than 3 filters as this is once through
    for(const sT of supplierTags) {
        if(sT.type === 'MATERIAL_PREFERENCES')
            materialPreferenceTags.push(sT)
        else if(sT.type === 'MANUFACTURING')
            manufacturingTags.push(sT)
        else if(sT.type === 'FINISHING') 
            finishingTags.push(sT)
    }
    finishingTags = formatForRHF(finishingTags)
    manufacturingTags = formatForRHF(manufacturingTags)
    materialPreferenceTags = formatForRHF(materialPreferenceTags)
    // sort is in place so return value not needed.
    sortOptions(finishingTags);
    sortOptions(manufacturingTags)
    sortOptions(materialPreferenceTags)
    // Construct submit function
    const [ updatePart ] = useUpdatePartMutation();
    const [ createPart ] = useCreatePartMutation();
    const [ updateDocuments ] = useUpdateDocumentMutation();
    const submittingFunction = async formData => {
        const translatedFormData = translateFormDataForSubmit(formData);
        translatedFormData.documentIds = additionalDocuments.map(a => a._id);
        if(!!existingDefaultValues)
            await updatePart({ partId, part: translatedFormData })
        else
            await createPart({ part: translatedFormData })
        if(areAdditionalDocumentsDirty) {
            await Promise.all(additionalDocuments.map(async document => {
                await updateDocuments({ 
                    documentId: document._id, 
                    document
                })
            }))
        } 
        history.push(`/parts/${partId}`)
    }

    const {
        fields: materialFields,
        append: appendMaterial,
        remove: removeMaterial
    } = useFieldArray({
        control: methods.control,
        name: `materials`,
        keyName: 'materialId'
    })

    const handleDeleteMaterial = () => {
        const materials = methods.getValues(`materials`)
        let toDelete: number[] = []
        materials.forEach((material, index) => {
            if (material.checked) {
                toDelete.push(index)
            }
        })
        removeMaterial(toDelete)
    }

    const handleAddMaterial = () => {
        appendMaterial({ material: null, details: '', checked: false })
    }

    const {
        fields: finishFields,
        append: appendFinish,
        remove: removeFinish
    } = useFieldArray({
        control: methods.control,
        name: `finishes`,
        keyName: 'finishId'
    })

    const handleDeleteFinishes = () => {
        const finishes = methods.getValues('finishes')
        let toDelete: number[] = []
        finishes.forEach((qty, index) => {
            if (qty.checked) {
                toDelete.push(index)
            }
        })
        removeFinish(toDelete)
    }

    const handleAddFinish = () => {
        appendFinish({ finish: null, details: '', checked: false })
    }

    // Fetch data to populate company part of form.
    const { data: loggedCompany } = useGetLoggedUserQuery();
    useEffect(() => {
        console.log(loggedCompany)
        if(!!loggedCompany) {
            const updatedCompanyDataResult = formatCompanyForValueLabel(loggedCompany)
            methods.setValue('company', updatedCompanyDataResult)
        }
    }, [loggedCompany])
    return (
            <div className={classes.container}>
                <FormProvider {...methods}>
                    <Grid container>
                        <Grid item xs={4}>
                            <Typography gutterBottom variant="h4">
                                {editing ? `${t('part-editor-header2')}` : `${t('part-editor-header')}`}
                            </Typography>
                        </Grid>
                        <Grid item xs={8} style={{ textAlign: 'right' }}>
                            <Link to={!!companyId && !!partId ? `/parts/${partId}` : `/parts`}>
                                <Button type="button" color="primary" size="small" variant="outlined" className={classes.action}>
                                    {t('part-back')}
                                </Button>
                            </Link>
                            <Button style={{ height: '40px' }} type="submit" color="primary" size="small" variant="contained" className={classes.action} onClick={methods.handleSubmit(submittingFunction)}>
                                {editing ? `${t('form-action-save')}` : `${t('part-create')}`}
                            </Button>
                        </Grid>
                    </Grid>

                    <div>
                        {createdAt ? (
                            <Chip label={`Created on ${new Date(createdAt).toLocaleDateString()} ${new Date(createdAt).toLocaleTimeString()}`} style={{ marginBottom: 5, marginRight: 5 }} />
                        ) : (
                            ''
                        )}
                        {companyId ? <Chip label={`${companyId}`} style={{ marginBottom: 5, marginRight: 5 }} /> : ''}
                        {partId ? <Chip label={`${partId}`} style={{ marginBottom: 5, marginRight: 5 }} /> : ''}
                    </div>
                        <>
                            <Paper className={classes.formContainer}>
                                <Grid container direction="row" spacing={2}>
                                    <Grid item xs={12} sm={12} md={12}>
                                        <Typography gutterBottom variant="h6">
                                            {`${t('part-basic-details')}`}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={12}>
                                        <RHFAutoComplete 
                                            options={
                                                !!loggedCompany ? [{
                                                        value: loggedCompany._id,
                                                        label: `${loggedCompany._id} - ${loggedCompany?.company.name}`
                                                    }] as ValueLabelOption[] : []
                                            }
                                            name='company'
                                            label='Company'
                                            disabled
                                        /> 
                                    </Grid>

                                    <Grid item xs={12} sm={12} md={6}>
                                        <RHFTextField name="number" label="Part number" required />
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={6}>
                                        <RHFTextField name="revision" label="Revision" required />
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={6}>
                                        <RHFTextField name="name" label="Part Name/Description" required />
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={6}>
                                        <RHFTextField
                                            name="nationalStockNumber"
                                            label={t('part-national-stock-number')}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={12}>
                                        <RHFTextEditor
                                            name='notes'
                                            placeholder='Enter notes'
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={12}>
                                        <RHFAutoComplete 
                                            options={
                                                manufacturingTags
                                            } 
                                            multiple 
                                            name="manufacturingProcesses" 
                                            label="Manufacturing Processes" 
                                            required 
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={12}>
                                        <Typography variant="h6">Materials</Typography>
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={12}>
                                        <Table>
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell className={classes.headerCellWithInput} style={{ padding: '0px' }}>&nbsp;</TableCell>
                                                    <TableCell className={classes.headerCellWithInput}>Material</TableCell>
                                                    <TableCell className={classes.headerCellWithInput}>Grade & Specifications</TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                { materialFields.map((field, materialIndex) =>
                                                    <TableRow key={field.materialId}>
                                                        <TableCell className={classes.borderCellWithInput} align='center'>
                                                            <RHFCheckbox
                                                                name={`materials[${materialIndex}].checked`}
                                                                label=''
                                                                labelPlacement='none'
                                                                style={{ color: '#FFFFFF' }}
                                                            />
                                                        </TableCell>
                                                        <TableCell className={classes.borderCellWithInput}>
                                                            <RHFAutoComplete
                                                                name={`materials[${materialIndex}].material`}
                                                                label=''
                                                                isTableCellInput={true}
                                                                options={materialPreferenceTags}
                                                                required
                                                            />
                                                        </TableCell>
                                                        <TableCell className={classes.borderCellWithInput}>
                                                            <RHFTextField
                                                                name={`materials[${materialIndex}].details`}
                                                                label=''
                                                                isTableCellInput={true}
                                                            />
                                                        </TableCell>
                                                    </TableRow>
                                                )}
                                            </TableBody>
                                        </Table>
                                    </Grid>
                                    <Grid container justifyContent='flex-end' item xs={12} md={12}>
                                        <Button
                                            type='button'
                                            color='primary'
                                            variant='text'
                                            onClick={handleDeleteMaterial}
                                            disabled={materialFields.length < 1}
                                        >
                                            DELETE ROW
                                        </Button>
                                        <Button
                                            type='button'
                                            color='primary'
                                            variant='outlined'
                                            onClick={handleAddMaterial}
                                            style={{ marginLeft: '8px' }}
                                            disabled={materialFields.length > 9}
                                        >
                                            ADD MATERIAL
                                        </Button>
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={12}>
                                        <Typography variant="h6">Finishes</Typography>
                                    </Grid>
                                    <Grid item xs={12} md={12}>
                                        <Table>
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell className={classes.headerCellWithInput} style={{ padding: '0px' }}>&nbsp;</TableCell>
                                                    <TableCell className={classes.headerCellWithInput}>Finish</TableCell>
                                                    <TableCell className={classes.headerCellWithInput}>Specifications</TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                { finishFields.map((field, finishIndex) =>
                                                    <TableRow key={field.finishId}>
                                                        <TableCell className={classes.borderCellWithInput} align='center'>
                                                            <RHFCheckbox
                                                                name={`finishes[${finishIndex}].checked`}
                                                                label=''
                                                                labelPlacement='none'
                                                                style={{ color: '#FFFFFF' }}
                                                            />
                                                        </TableCell>
                                                        <TableCell className={classes.borderCellWithInput}>
                                                            <RHFAutoComplete
                                                                name={`finishes[${finishIndex}].finish`}
                                                                label=''
                                                                isTableCellInput={true}
                                                                options={finishingTags}
                                                                required
                                                            />
                                                        </TableCell>
                                                        <TableCell className={classes.borderCellWithInput}>
                                                            <RHFTextField
                                                                name={`finishes[${finishIndex}].details`}
                                                                label=''
                                                                isTableCellInput={true}
                                                            />
                                                        </TableCell>
                                                    </TableRow>
                                                )}
                                            </TableBody>
                                        </Table>
                                    </Grid>
                                    <Grid container justifyContent='flex-end' item xs={12} md={12}>
                                        <Button
                                            type='button'
                                            color='primary'
                                            variant='text'
                                            onClick={handleDeleteFinishes}
                                            disabled={finishFields.length < 1}
                                        >
                                            DELETE ROW
                                        </Button>
                                        <Button
                                            type='button'
                                            color='primary'
                                            variant='outlined'
                                            onClick={handleAddFinish}
                                            style={{ marginLeft: '8px' }}
                                            disabled={finishFields.length > 9}
                                        >
                                            ADD FINISH
                                        </Button>
                                    </Grid>
                                </Grid>
                            </Paper>

                            <Paper className={classes.formContainer}>
                                <Grid container direction="row" spacing={2}>
                                    <Grid item xs={12} sm={12} md={12}>
                                        <Typography gutterBottom variant="h6">
                                            {`${t('part-documents')}`}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={12}>
                                        <DocumentManager
                                            label="Documentation"
                                            documents={additionalDocuments}
                                            setDocuments={setAdditionalDocuments}
                                            setAreDocumentsDirty={setAreAdditionalDocumentsDirty}
                                            docType={DocumentType.SUPPORT_FILE}
                                            referenceId={''}
                                        />
                                        </Grid>
                                    </Grid>
                            </Paper>
                    </>
                </FormProvider>
            </div>
    )
}

export default withNamespaces()(PartEditorForm)
