import { Document } from '../../document';
import { OrderItem } from './OrderItem';
import { SupplierCriteria } from '../../supplier/SupplierCriteria';
import { ListParameters, UserIdAndName, Comment } from '../../BaseTypes';
import { QuoteDetail, QuoteLineItem } from '../Quote';
import { Address } from '../../company';
import { User } from '../../user';
import { ValueLabelOption } from '../../ui';
import { PartDAPP } from '../../part';
import { SupplierQuote } from '../../purchaseOrder';
import { SupplierSearchCriteria } from '../../supplier';

export enum RFQStatusFilter {
  ALL = 'ALL',
  // PEND  ING = 'PENDING',
  OPEN = 'OPEN',
  QUOTED = 'QUOTED',
  ACCEPTED = 'ACCEPTED',
}

export enum RFQMMSStatusFilter {
  ALL = 'ALL',
  // PENDING = 'PENDING',
  OPEN = 'OPEN',
  QUOTED = 'QUOTED',
  ACCEPTED = 'ACCEPTED',
  EXPIRED = 'EXPIRED',
  DECLINED = 'DECLINED',
  REJECTED = 'REJECTED',
}

export enum RFQStatus {
  DRAFT = 'DRAFT',
  OPEN = 'OPEN',
  NEW = 'NEW',
  REQUOTED = 'REQUOTED',
  REOPENED = 'REOPENED',
  RFQOUT = 'RFQOUT',
  QUOTESIN = 'QUOTESIN',
  QUOTED = 'QUOTED',
  QUOTE_PARKED = 'QUOTE_PARKED',
  INAPPROVAL = 'INAPPROVAL',
  POISSUED = 'POISSUED',
  MISSED = 'MISSED',
  REJECTED = 'REJECTED',
  EXPIRED = 'EXPIRED',
}

export enum ManufacturingProcesses {
  Wire_EDM = 'Wire EDM',
  CNC_Lathe = 'CNC Lathe',
  CNC_Mill = 'CNC Mill',
  CNC_Router = 'CNC Router',
  CNC_Punch = 'CNC Punch',
  CNC_Plasma_Cutter = 'CNC Plasma Cutter',
  CNC_Waterjet = 'CNC Waterjet',
  CNC_Turning = 'CNC Turning',
  LBW_Laser_Beam_Welding = 'LBW Laser Beam Welding',
  EBW_Electron_Beam_Welding = 'EBW Electron Beam Welding',
  Printing_3D = '3D Printing',
  CNC_Laser_Cutter = 'CNC Laser Cutter',
  CNC_Swiss_Lathe = 'CNC Swiss Lathe',
  CNC_Mill_Turn = 'CNC Mill-Turn',
  Engine_Lathe = 'Engine Lathe',
  Mill = 'Mill',
  Lathe = 'Lathe',
  CNC_Robo_Drill = 'CNC Robo Drill',
  Not_Sure = 'Not Sure',
}
export enum Certification {
  ISO9001 = 'ISO 9001',
  AS9100 = 'AS9100',
  ISO13485 = 'ISO 13485',
  ISOTS16949 = 'ISO/TS16949',
  MILPRF31032 = 'MIL-PRF-31032',
  ITAR = 'ITAR',
  RoHS = 'RoHS',
  OTHER = 'OTHER',
  NONE = 'NONE',
}

export enum PaymentTerms {
  MATERIAL_DEPOSIT_REQUIRED_UPFRONT = 'MATERIAL_DEPOSIT_REQUIRED_UPFRONT',
  COD = 'COD',
  NET15 = 'NET15',
  NET30 = 'NET30',
  NET45 = 'NET45',
}

export enum RFQLineItemStatus {
  ACTIVE = 'ACTIVE',
  ARCHIVED = 'ARCHIVED',
}
export interface SupplierQuoteItem {
  document: Document;
  supplierId: String;
  supplierName: String;
}

export interface LegacyQuote {
  notes?: string;
  deliveryDate: number;
  expediteFeed: number;
  expirationDate: Date;
  paymentTerms: PaymentTerms;
  shipping: number;
  tax: number;
}

export interface RFQPart {
  document: Document;
  quantity: number;
  price?: number;
}

export interface QuoteTotals {
  items: {
    partId: string;
    unitPrice: number;
    amount: number;
  }[];
  total: number;
}

export interface SupplierData {
  subject: string;
  criteria: SupplierCriteria;
  emails: any[];
  suppliers: any[];
}

export interface BuyerCompany {
  companyId?: string;
  billingAddressId?: string;
  shippingAddressId?: string;
  companyName?: string;
  billingAddress?: Address;
  shippingAddress?: Address;
}

export interface RFQ {
  _id: string;
  name: string;
  status: RFQStatus;
  leadTime: string; // maybe enum?
  deadlineFilter: string[];
  manufacturingProcesses: ManufacturingProcesses[];
  certifications: Certification[];
  notes?: string;
  billingAddressId: string;
  shippingAddressId: string;
  companyId: string;
  userId: string;
  operatorId?: string;
  parts: RFQPart[];
  quote: QuoteDetail;
  totals?: QuoteTotals;
  purchaseOrder?: Document;
  orderItems: OrderItem[];
  accepted: boolean;
  quoted: boolean;
  editRequest?: string;
  declineReason?: string;
  supplierData?: SupplierData;
  supplierQuotes: SupplierQuoteItem[];
  awardedSupplierId?: string;
  createdAt: Date;
  updatedAt?: Date;
  deletedAt?: Date;
  downloadToken?: string;
  mfgReward?: number;
  rfqrewards?: any[];
  lineItems?: any[];
  lineItem?: RFQLineItem;
  rfqReward?: number;
  isRewardDistibuted: boolean;
  companyName?: string;
  supplierQuoteCount?: number;
  quoteDeadline?: Date;
  supplierQuote?: any;
  paymentTerms?: any;
  supplierPaymentTerms?: any;
  documents?: any;
  quoteId?: string;
  buyerCompany?: BuyerCompany;
  buyerContact?: any;
  assignedTo?: any;
  salesperson?: any;
  readyToSend?: boolean;
  domesticProductionRequired?: boolean;
  customerSuppliedMaterial?: boolean;
  inspection?: string;
  packaging?: string;
  additionalRequirements?: string;
  numLineItems?: number;
  draftParts?: any[];
  isDraft?: any;
  comments?: Comment[];
  buyerRFQReference?: string;
  numSupplierQuotes?: number;
  quoteTotalMin?: number;
  quoteTotalMax?: number;
  quoteLineItem?: QuoteLineItem;
  quoteSupplierCostsTotalMax?: number;
  quoteSupplierCostsTotalMin?: number;
}

export interface CreateRFQInput {
  status: RFQStatus;
  buyerCompany: {
    companyId: string;
  };
  buyerContact: {
    userId: string;
  };
  assignedTo: {
    userId: string;
  };
  salesperson: {
    userId: string;
  };
  paymentTerms?: string;
  inspection?: string;
  packaging?: string;
  certifications: string[];
  domesticProductionRequired: boolean;
  notes: string;
}

export interface UpdateRFQInput {}

export interface PatchRFQInput {
  status: RFQStatus;
}

export interface RFQLineItem {
  _id: string;
  rfqId: string;
  partId: string;
  exceptions: string;
  notes: string;
  quantity: number[];
  targetUnitPrice: number[];
  part: PartDAPP;
  partIdentification?: string;
  quoteLineItem?: QuoteLineItem;
  status: string;

  createdBy?: UserIdAndName;
  createdAt?: Date;
  updatedBy?: UserIdAndName;
  updatedAt?: Date;
  deletedBy?: UserIdAndName;
  deletedAt?: Date;
}

export interface RFQLineItemInput {
  _id?: string;
  part: {
    _id?: string;
    number: string;
    revision: string;
    name?: string;
    isNewPart?: boolean;
    isNewRevision?: boolean;
    documents: {
      document: Document;
      isNewDocument?: boolean;
    }[];
    manufacturingProcesses: ValueLabelOption[];
    materials: {
      material: ValueLabelOption;
      detail: string;
      checked: boolean;
    }[];
    finishes: {
      finish: ValueLabelOption;
      detail: string;
      checked: boolean;
    }[];
    nationalStockNumber?: string;
    notes?: string;
    exceptions?: string;
  };
  exceptions?: string;
  material?: string;
  partIdentification?: string;
  outsideServices?: string;
  quantities: {
    quantity: number;
    targetUnitPrice?: number;
    checked: boolean;
  }[];
  status: string;
}

export interface BulkRFQLineItemInput {
  _id?: string;
  rfqId: string;
  part: {
    _id?: string;
    number: string;
    revision: string;
    name: string;
    documentIds: string[];
    manufacturingProcesses: string[];
    materials: string[];
    materialDetails: string[];
    finishes: string[];
    finishDetails: string[];
    nationalStockNumber?: string;
    notes?: string;
    exceptions?: string;
  };
  exceptions?: string;
  material?: string;
  partIdentification?: string;
  outsideServices?: string;
  quantity: number[];
  targetUnitPrice: number[];
}

export type RFQWithOptions = {
  rfq: RFQ;
  rfqExists: boolean;
  buyerContacts: User[];
  buyerAddresses: Address[];
};

export interface RFQListParameters extends ListParameters {
  outcome?: string;
  companyId?: string;
  deadlineFilter?: string[];
}

export const RFQStatusMap = new Map<RFQStatus, string>([
  [RFQStatus.DRAFT, 'Draft'],
  [RFQStatus.NEW, 'New'],
  [RFQStatus.RFQOUT, 'RFQ Out'],
  [RFQStatus.QUOTESIN, 'Quote In'],
  [RFQStatus.QUOTED, 'Quoted'],
  [RFQStatus.QUOTE_PARKED, 'Short Term Parking'],
  [RFQStatus.INAPPROVAL, 'In Approval'],
  [RFQStatus.POISSUED, 'PO Issued'],
  [RFQStatus.REQUOTED, 'Requoted'],
  [RFQStatus.REOPENED, 'Reopened'],
  [RFQStatus.MISSED, 'Missed'],
  [RFQStatus.REJECTED, 'Rejected'],
  [RFQStatus.OPEN, 'Open'],
  [RFQStatus.EXPIRED, 'Long Term Parking'],
]);

export const RFQStatusOptions: ValueLabelOption[] = Array.from(
  RFQStatusMap,
  ([key, value]) => ({ value: key, label: value })
);

export const BuyerRFQStatusMap = new Map<string, string>([
  [RFQStatus.DRAFT, 'Draft'],
  [RFQStatus.POISSUED, 'PO Issued'],
  ['CLOSED', 'Closed'],
  ['AWAITING QUOTE', 'Awaiting Quote'],
  ['REVIEW QUOTE', 'Review Quote'],
]);

export const generateRFQOption = (rfq: RFQ): ValueLabelOption => {
  return {
    value: rfq._id,
    label: `${rfq._id} - ${rfq.buyerCompany?.companyName} - ${rfq.name}`,
  };
};

export const generateRFQOptions = (rfqs: RFQ[]): ValueLabelOption[] => {
  return rfqs.map(rfq => generateRFQOption(rfq));
};

export const formatQuoteSupplierCostTotal = (rfq: RFQ): string => {
  const currencyFormatter = new Intl.NumberFormat('default', {
    style: 'currency',
    currency: 'USD',
    maximumFractionDigits: 0,
  });
  if (rfq.quoteTotalMin && rfq.quoteTotalMax) {
    if (rfq.quoteTotalMin !== rfq.quoteTotalMax) {
      // Subtract min from min and max from max, because otherwise we get negative values sometimes and that's bad.
      return `${currencyFormatter.format(
        rfq.quoteTotalMin - rfq.quoteSupplierCostsTotalMin
      )} - ${currencyFormatter.format(
        rfq.quoteTotalMax - rfq.quoteSupplierCostsTotalMax
      )}`;
    } else {
      // quote min/max the same, only display values if there is a range in supplier costs
      if (rfq.quoteSupplierCostsTotalMax !== rfq.quoteSupplierCostsTotalMax) {
        return `${currencyFormatter.format(
          rfq.quoteTotalMin - rfq.quoteSupplierCostsTotalMin
        )} - ${currencyFormatter.format(
          rfq.quoteTotalMax - rfq.quoteSupplierCostsTotalMax
        )}`;
      }
      return `${currencyFormatter.format(
        rfq.quoteTotalMin - rfq.quoteSupplierCostsTotalMax
      )}`;
    }
  }
  return '--';
};

export const formatQuoteTotal = (rfq: RFQ): string => {
  const currencyFormatter = new Intl.NumberFormat('default', {
    style: 'currency',
    currency: 'USD',
    maximumFractionDigits: 0,
  });
  if (rfq.quoteTotalMin && rfq.quoteTotalMax) {
    if (rfq.quoteTotalMin !== rfq.quoteTotalMax) {
      return `${currencyFormatter.format(
        rfq.quoteTotalMin
      )} - ${currencyFormatter.format(rfq.quoteTotalMax)}`;
    } else {
      return currencyFormatter.format(rfq.quoteTotalMin);
    }
  }
  return '--';
};

export const getPartMaterials = (
  part: PartDAPP
): { material: string; detail: string }[] => {
  return part.materials.map((material: string, index: number) => {
    return {
      material,
      detail: part?.materialDetails?.[index]
        ? part.materialDetails[index]
        : null,
    };
  });
};

export const getPartFinishes = (
  part: PartDAPP
): { finish: string; detail: string }[] => {
  return part.finishes.map((finish: string, index: number) => {
    return {
      finish,
      detail: part?.finishDetails?.[index] ? part.finishDetails[index] : null,
    };
  });
};

export const enum RFQActionMenuOptions {
  CREATE_ACTION_ITEM = 'Create Action Item',
  DUPLICATE_RFQ = 'Duplicate RFQ',
}

export interface RFQLineItemGroup extends RFQLineItemGroupInput {
  _id: string
  rfqId: string
  matchedSupplierIds: string[]

  createdBy: UserIdAndName
  createdAt: Date
  updatedBy: UserIdAndName
  updatedAt: Date
  deletedBy: UserIdAndName
  deletedAt: Date
}

export interface RFQLineItemGroupDetail extends RFQLineItemGroup {
  supplierQuotes: SupplierQuote[]
}

export interface RFQLineItemGroupInput {
  name: string
  rfqLineItemIds: string[]
  directSupplierIds: string[]
  supplierCriteria?: SupplierCriteria
  emailSubject: string
}

export interface RFQGroupLineItemType {
  _id?: string;
  exclusionCriteria?: SupplierSearchCriteria;
  supplierCriteria?: SupplierSearchCriteria;
  rfqLineItemIds: Array<string>;
  directSupplierIds: Array<string>;
  matchedSupplierIds: Array<string>;
  name: string;
  rfqId: string;
  emailSubject: string;
}