import { call, put, takeLatest, getContext } from 'redux-saga/effects'
import { Gateway } from '../../components/gateway'
import {
    SignupAction,
    SIGNUP_SUCESSFUL,
    SIGNUP_FAILED,
    SIGNUP_ACTION_TYPE,
    ACTIVATE_USER_ACTION_TYPE,
    ACTIVATE_USER_SUCESSFUL,
    ACTIVATE_USER_FAILED,
    ActivationAction,
    RESEND_VALIDATION_ACTION_TYPE,
    RESEND_VALIDATION_SUCESSFUL,
    RESEND_VALIDATION_FAILED,
    SIGNUP_EMAIL_VALIDATION,
    RESEND_VERIFICATION_EMAIL_ACTION_TYPE,
} from './Actions'
import { PUSH, SHOW_FEEDBACK } from '@syncfab/machine'
import { reset, stopSubmit, startSubmit, startAsyncValidation, stopAsyncValidation } from 'redux-form'
import { SignupFormName } from './components/SignupPersonalInformation'
import { UserProdileEditorFormName } from '../users/editor/components/UserProfileEditorForm'
import { USER_EMAIL_VALIDATION } from '../../components/user/Actions'

function* signup({ data }: SignupAction) {
    try {
        yield put(startSubmit(SignupFormName))
        const gateway: Gateway = yield getContext('gateway')
        const { data: user } = yield call(gateway.getCompanyAPI().signup(data))

        yield put(SIGNUP_SUCESSFUL(user))
        yield put(PUSH('/confirmation'))
        yield put(reset(SignupFormName))
        yield put(stopSubmit(SignupFormName))
    } catch ({ response: { status, data } }) {
        yield put(SIGNUP_FAILED(data))
        yield put(stopSubmit(SignupFormName))
    }
}

function* activate(token: ActivationAction) {
    try {
        const gateway: Gateway = yield getContext('gateway')
        yield call(gateway.getUserAPI().activate(token))

        yield put(ACTIVATE_USER_SUCESSFUL())
    } catch ({ response: { status, data } }) {
        yield put(ACTIVATE_USER_FAILED(data))
    }
}

function* emailExists({ data: { email } }: { data: { email: string } }) {
    try {
        const gateway: Gateway = yield getContext('gateway')
        yield put(startAsyncValidation(SignupFormName))
        yield call(gateway.getUserAPI().emailExists(email))
        yield put(stopAsyncValidation(SignupFormName, {}))
        yield put(stopAsyncValidation(UserProdileEditorFormName, {}))
    } catch ({ response: { status, data } }) {
        yield put(stopAsyncValidation(SignupFormName, { email: 'Already in use' } as any))
        yield put(stopAsyncValidation(UserProdileEditorFormName, { email: 'Already in use' } as any))
    }
}

function* resendValidation({ data: { userId } }: { data: { userId: string } }) {
    try {
        const gateway: Gateway = yield getContext('gateway')
        yield call(gateway.getCompanyAPI().resendValidationEmail(userId))
        yield put(SHOW_FEEDBACK('validation-sent'))
        yield put(RESEND_VALIDATION_SUCESSFUL())
    } catch ({ response: { status, data } }) {
        yield put(RESEND_VALIDATION_FAILED(data))
        yield put(SHOW_FEEDBACK(data))
    }
}
function* resendVerificationEmail({ data: { email } }: { data: { email: string } }) {
    try {
        const gateway: Gateway = yield getContext('gateway')
        yield call(gateway.getCompanyAPI().resendVerificationEmail(email))
        yield put(SHOW_FEEDBACK('Verification Link Send Successfully.'))
        yield put(PUSH('/'))
    } catch ({ response: { status, data } }) {
        yield put(SHOW_FEEDBACK(data))
    }
}

function* signupSaga() {
    // Typings are not good enough. String should be valid but the types
    // do not show it
    yield takeLatest(SIGNUP_ACTION_TYPE as any, signup)
    yield takeLatest(RESEND_VALIDATION_ACTION_TYPE as any, resendValidation)
    yield takeLatest(ACTIVATE_USER_ACTION_TYPE as any, activate)
    yield takeLatest(SIGNUP_EMAIL_VALIDATION.types.TRIGGER as any, emailExists)
    yield takeLatest(USER_EMAIL_VALIDATION.types.TRIGGER as any, emailExists)
    yield takeLatest(RESEND_VERIFICATION_EMAIL_ACTION_TYPE as any, resendVerificationEmail)
}

export default signupSaga
