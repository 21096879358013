import React from 'react'
import { Grid } from '@material-ui/core'
import { withNamespaces, WithNamespaces } from 'react-i18next'
import { Field } from 'redux-form/immutable'
import { FormTextField, UserRole, FormAutocompleteV2 } from '@syncfab/machine'
import { WithStyles, withStyles } from '@material-ui/core/styles'

interface Props extends WithStyles, WithNamespaces {
    isEditing?: boolean
    isLoggedUserProfile?: boolean
}
const styles = () => ({
    gridItem: {
        paddingTop: '0px !important',
        paddingBottom: '0px !important',
    },
    formInput: {
        '& .MuiFormControl-marginNormal': {
            margin: 0,
        },
    },
})
const profile = ({ t, classes, isEditing, isLoggedUserProfile }: Props) => {
    const rolesArr = [
        {
            label: t('personal-form-admin'),
            value: UserRole.ADMIN,
        },

        {
            label: t('personal-form-member'),
            value: UserRole.MEMBER,
        },
    ]
    return (
        <Grid container spacing={2}>
            <Grid item md={6} xs={12} className={classes.gridItem}>
                <Field name="firstName" component={FormTextField} type="text" label={`${t('user-first-name')}*`} fullWidth className={classes.formInput} />
            </Grid>
            <Grid item md={6} xs={12} className={classes.gridItem}>
                <Field name="lastName" component={FormTextField} type="text" label={`${t('user-last-name')}*`} fullWidth className={classes.formInput} />
            </Grid>

            <>
                {!isLoggedUserProfile && (
                    <Grid item md={12} xs={12} className={classes.gridItem}>
                        <Field name="email" component={FormTextField} type="email" label={`${t('user-email')}*`} fullWidth disabled={isEditing} />
                    </Grid>
                )}
            </>

            <Grid item md={4} xs={8} className={classes.gridItem}>
                <Field name="phone" component={FormTextField} type="text" label={`${t('user-phone-number')}*`} fullWidth className={classes.formInput} />
            </Grid>
            <Grid item md={2} xs={4} className={classes.gridItem}>
                <Field name="phoneExtension" component={FormTextField} type="text" label={t('user-extension')} fullWidth className={classes.formInput} />
            </Grid>
            <Grid item md={6} xs={12} className={classes.gridItem}>
                <Field name="mobile" component={FormTextField} type="tel" inputProps={{ maxLength: 10 }} label={t('user-mobile-phone')} fullWidth className={classes.formInput} />
            </Grid>
            <Grid item md={6} xs={12} className={classes.gridItem}>
                <Field name="title" component={FormTextField} type="text" label={`${t('user-title')}*`} fullWidth className={classes.formInput} />
            </Grid>
            <Grid item md={6} xs={12} className={classes.gridItem}>
                <Field
                    name="roles"
                    component={FormAutocompleteV2}
                    options={rolesArr}
                    label={t('personal-form-roles')}
                    fullWidth
                    format={value => {
                        if (!value) {
                            return []
                        }
                        if (!!value.toJS) {
                            return value.toJS()
                        }
                        return value
                    }}
                    className={classes.formInput}
                    disabled
                ></Field>{' '}
                {/* <Field
                    name="roles"
                    component={FieldSelect}
                    multiple
                    label={t('personal-form-roles')}
                    fullWidth
                    disabled
                    format={value => {
                        // TODO (JR): Move to select
                        if (!value) {
                            return [UserRole.ADMIN]
                        }
                        if (!!value.toJS) {
                            return Array.isArray(value.toJS()) ? value.toJS() : [value.toJS()]
                        }
                        return value
                    }}
                    className={classes.formInput}
                >
                    <MenuItem value="ADMIN">{t('personal-form-admin')}</MenuItem>
                    <MenuItem value="MEMBER">{t('personal-form-member')}</MenuItem>
                </Field> */}
            </Grid>
        </Grid>
    )
}

export const ProfileSettings = withNamespaces()(withStyles(styles)(profile))
