import React from 'react';
import { Alert, AlertTitle } from "@material-ui/lab"
import { SvgIcon, withStyles } from "@material-ui/core"
const styles = () => ({
    '@global': {
        '.MuiAlert-standardInfo': {
            backgroundColor: '#368A8E',
        },
        '.MuiAlertTitle-root': {
            fontSize: 26,
        },
        '.MuiAlert-message': {
            color: 'white',
            fontSize: 14,
        }
    },
});

const Al = () => {
    return (
        <Alert 
        icon={
            <SvgIcon
                style={{ fontSize: 120 }}
            >
                <svg version="1.1" id="Layer_1"
                xmlns="http://www.w3.org/2000/svg"
                xmlnsXlink="http://www.w3.org/1999/xlink"
                x="0px" y="0px" viewBox="0 0 246 247"
                xmlSpace="preserve"> <style type="text/css"> {
                '.st0{fill:#FFFFFF;}' }</style> <g
                id="Layer_2_00000047056850335129246420000004597658170863705755_">
                </g> <g
                id="Layer_1_00000057144158233977485960000014141365075427201719_">
                <g> <path className="st0"
                d="M81.6,207.9c-1.8,0-3.4-0.1-5,0c-5.2,0.2-8.7-2.3-11.2-6.6c-3.2-5.7-2.6-13.4,1.4-18.6
                c2.3-2.9,5.4-4.5,9.2-4.5c6.5,0,13,0,19.5,0c1.1,0,1.4-0.3,1.3-1.4c0-12.1,0-24.2,0-36.4c0-4.2,2-6.3,6.2-6.3
                c12.2,0,24.4,0.1,36.6,0c4.3,0,6.5,2.6,6.5,6.5c-0.1,12,0,23.9,0,35.9c0,1.6,0,1.6,1.7,1.6c1.9,0,3.9-0.1,5.8,0
                c1.1,0,1.4-0.3,1.4-1.4c0-7,0-14,0-21c0-5.2,0-10.4,0-15.5c0-2.3,0.6-4.3,2.8-5.4c0.8-0.4,1.8-0.7,2.8-0.7c12.8,0,25.5,0,38.3,0
                c3.3,0,5.4,2.2,5.4,5.5c0,12.1,0,24.3,0,36.4c0,0.6,0,1.3,0,2.1c0.6,0,1.2,0,1.7,0c9.4,0,18.8,0,28.2,0c2.1,0,2.5,0.4,2.5,2.5
                c0,8.1,0,16.3,0,24.4c0,2.6-0.2,2.8-2.8,2.8c-3.7,0-7.5,0-11.2,0c-1,0-1.4,0.2-1.3,1.3c0.1,1.8,0,3.6,0,5.4c0,1.5-0.5,2-1.9,2
                c-1.7,0-3.4,0-5.1,0c-0.8,0-1.2,0.2-1.2,1.1c0,5.4,0,10.9,0,16.3c0,0.4,0.4,0.9,0.8,1.1c2.5,1.2,4.4,4.2,4.2,6.9
                c-0.1,1.2-0.8,1.9-2.1,1.9c-2.4,0-4.9,0-7.3,0c-40.4,0-80.8,0-121.2,0c-2.5,0-3.1-0.6-2.7-3.1c0.3-2.3,1.4-4.2,3.5-5.4
                c0.4-0.2,0.8-0.8,0.8-1.2c0.1-5.5,0-11,0-16.6c0-0.8-0.3-1.1-1.1-1.1c-1.5,0-2.9,0-4.4,0c-1.6,0-2.1-0.5-2.1-2.1
                C81.6,212.3,81.6,210.2,81.6,207.9z
                M233.5,181.3c-0.7,0-1.3,0-1.9,0c-30.7,0-61.4,0-92.1,0c-21,0-42,0-63,0
                c-2.6,0-4.9,0.8-6.6,2.8c-3.2,3.6-3.9,7.9-2.8,12.5c1.3,5.4,4.8,8.1,10.3,8.1c20.4,0,40.8,0,61.3,0c31,0,62.1,0,93.1,0
                c0.6,0,1.2-0.1,1.9-0.1C233.5,196.8,233.5,189.2,233.5,181.3z
                M201.1,178.1c0-0.4,0.1-0.6,0.1-0.8c0-12.5,0-25,0-37.5
                c0-1.6-0.9-2.5-2.5-2.5c-3,0-6.1,0-9.1,0c-0.5,0-1.1,0-1.7,0c0,2.3,0,4.4,0,6.5c0,1.8-0.5,2.3-2.3,2.3c-3.7,0-7.3,0-11,0
                c-2.4,0-2.7-0.3-2.7-2.7c0-2,0-4,0-6.1c-3.7,0-7.1,0-10.6,0c-2.4,0-3.1,0.7-3.1,3.1c0,12.1,0,24.2,0,36.3c0,1.3,0.3,1.6,1.6,1.6
                c13.3,0,26.6,0,39.9,0C200.2,178.2,200.6,178.2,201.1,178.1z
                M100.1,178.2c0.7,0,1.1,0,1.6,0c13.2,0,26.4,0,39.5,0
                c1.7,0,1.7,0,1.7-1.7c0-12.2,0-24.4,0-36.6c0-1.7-0.8-2.5-2.4-2.5c-3.2,0-6.4,0-9.6,0c-1,0-1.2,0.4-1.2,1.3c0.1,1.9,0,3.7,0,5.6
                c0,1.4-0.6,2-2,2c-4,0-8,0-12,0c-1.5,0-2-0.6-2-2.1c0-1.9,0-3.7,0-5.6c0-0.9-0.3-1.2-1.2-1.2c-3.1,0-6.2,0-9.3,0
                c-2.5,0-3.1,0.6-3.1,3.1c0,12.2,0,24.3,0,36.5C100.1,177.3,100.1,177.7,100.1,178.2z
                M173.1,216.6c-0.1,0.3-0.2,0.3-0.2,0.4
                c0,5.7,0,11.5,0,17.2c0,0.3,0.4,0.8,0.7,0.9c2.1,0.9,3.3,2.6,3.9,4.8c0.2,0.8,0.6,1,1.3,1c8.5,0,17.1,0,25.6,0
                c0.8,0,1.1-0.3,1.4-1.1c0.5-1.9,1.6-3.4,3.3-4.4c0.4-0.2,0.7-0.9,0.7-1.4c0-5.3,0-10.6,0-15.9c0-0.4,0-0.9,0-1.5c-2,0-3.8,0-5.7,0
                c-1.5,0-2-0.6-2-2c0-1.8-0.1-3.5,0-5.3c0-1-0.2-1.4-1.3-1.4c-6.1,0.1-12.3,0-18.4,0c-0.5,0-0.9,0-1.5,0.1c0,2.2,0,4.3,0,6.4
                c0,1.8-0.4,2.3-2.3,2.3C176.8,216.6,175,216.6,173.1,216.6z
                M137.7,240.8c0.2,0,0.4,0.1,0.5,0.1c8.8,0,17.5,0,26.3,0
                c0.8,0,1-0.3,1.2-1c0.5-1.9,1.6-3.4,3.3-4.5c0.4-0.2,0.7-0.8,0.7-1.3c0-5.5,0-10.9,0-16.4c0-1-0.4-1.2-1.3-1.2c-1.3,0.1-2.7,0-4,0
                c-1.8,0-2.3-0.5-2.3-2.3c0-2.1,0-4.2,0-6.3c-1.7,0-3.2,0-4.7,0c-5.1,0-10.1,0-15.2-0.1c-0.9,0-1.4,0.2-1.3,1.3
                c0.1,1.8,0,3.7,0,5.6c0,1.1-0.6,1.7-1.7,1.8c-0.5,0-1.1,0-1.6,0c-1.6,0-3.2,0-5,0c0,0.7,0,1.2,0,1.7c0,4.6,0,9.3,0,13.9
                c0,1.6-0.1,3,1.9,3.5c0.1,0,0.3,0.2,0.4,0.3C136.5,237.2,137.2,238.9,137.7,240.8z
                M100.7,208c0,2.2,0,4.2,0,6.2
                c0,1.9-0.5,2.4-2.4,2.4c-1.6,0-3.2,0-4.8,0c-0.8,0-1.1,0.2-1.1,1.1c0,5.4,0,10.9,0.1,16.3c0,0.4,0.4,1,0.8,1.2
                c2.1,0.9,3.3,2.5,3.9,4.6c0.2,0.8,0.6,1.1,1.5,1.1c8.5,0,16.9,0,25.4,0c0.9,0,1.2-0.3,1.5-1.1c0.5-1.9,1.6-3.4,3.4-4.4
                c0.3-0.2,0.6-0.8,0.6-1.2c0-5.5,0-11,0-16.5c0-0.8-0.3-1.1-1.1-1.1c-1.4,0-2.8,0-4.2,0c-2,0-2.4-0.4-2.4-2.4c0-2.1,0-4.1,0-6.2
                C114.7,208,107.8,208,100.7,208z
                M165.3,207.9c0,1.8,0,3.5,0,5.4c4,0,8,0,11.9,0c0.2,0,0.7-0.4,0.7-0.6c0.1-1.6,0-3.2,0-4.8
                C173.7,207.9,169.6,207.9,165.3,207.9z
                M137.8,208c-4.4,0-8.5,0-12.8,0c0,1.6,0,3.2,0,4.7c0,0.2,0.5,0.6,0.8,0.6
                c3.7,0,7.4,0,11.1,0c0.3,0,0.8-0.5,0.8-0.8C137.8,211.1,137.8,209.6,137.8,208z
                M205.5,213.4c4,0,8,0,12,0c0.2,0,0.6-0.4,0.6-0.6
                c0.1-1.6,0-3.2,0-4.8c-4.3,0-8.5,0-12.6,0C205.5,209.8,205.5,211.5,205.5,213.4z
                M97.4,213.4c0-1.9,0-3.6,0-5.4
                c-4.2,0-8.4,0-12.6,0c0,1.6,0,3.1,0,4.6c0,0.3,0.5,0.8,0.8,0.8C89.5,213.4,93.4,213.4,97.4,213.4z
                M175.1,137.4c0,1.9,0,3.7,0,5.5
                c3.2,0,6.4,0,9.6,0c0-1.9,0-3.7,0-5.5C181.4,137.4,178.3,137.4,175.1,137.4z
                M116.9,143c3,0,5.9,0,8.8,0c0.2,0,0.7-0.5,0.7-0.8
                c0.1-1.3,0.1-2.7,0-4c0-0.3-0.4-0.8-0.7-0.8c-2.9-0.1-5.9,0-8.9,0C116.9,139.3,116.9,141.1,116.9,143z
                M174.5,240.6
                c-0.6-1.8-1.7-2.8-3-2.7s-2.3,1.2-2.6,2.7C170.7,240.6,172.5,240.6,174.5,240.6z
                M88.3,240.5c1.9,0,3.8,0,5.7,0
                c-0.6-1.7-1.6-2.6-2.8-2.6C89.9,237.9,88.9,238.8,88.3,240.5z
                M128.5,240.5c1.9,0,3.8,0,5.7,0c-0.6-1.7-1.6-2.6-2.8-2.6
                S129.1,238.8,128.5,240.5z
                M214.7,240.6c-0.6-1.8-1.8-2.8-3-2.7s-2.4,1.3-2.5,2.7C211,240.6,212.8,240.6,214.7,240.6z"/>
                <path className="st0"
                d="M64.2,39.2c-3.8,3.3-7.4,6.6-11.1,9.9c-4.3,3.9-8.7,7.7-13,11.6c-0.4,0.4-0.8,0.9-0.8,1.4
                c-0.2,2.9-1,5.7-2.3,8.3c-0.2,0.5-0.3,1.2-0.1,1.7c3.8,8.3,7.7,16.6,11.5,24.9c1,2.2,2.2,3.8,4.3,5.2c6.1,4,8.8,9.9,9,17.2
                c0,0.9,0.1,1.9,0,2.8c-0.3,3,0.4,5.7,1.8,8.3c1.2,2.2,2.2,4.5,3.3,6.8c0.3,0.7,0.8,1,1.5,1c3.2,0,6.5,0,9.7,0
                c1.6,0,2.1,0.5,2.1,2.1c0,4.2,0,8.3,0,12.5c0,1.7-0.5,2.1-2.2,2.1c-11.9,0-23.8-0.1-35.8-0.1c-12.4,0-24.8,0-37.3,0
                c-2.1,0-2.5-0.4-2.5-2.5c0-4,0-8.1,0-12.1c0-1.6,0.5-2.1,2.1-2.1c3.2,0,6.5,0,9.7,0c0.8,0,1.2-0.2,1.5-1c1.5-3.3,3.2-6.6,4.7-9.9
                c0.3-0.6,0.4-1.4,0.5-2c0.2-3.4,0.1-6.9,0.5-10.3c0.3-3.2,1.8-5.9,3.8-8.4c0.6-0.7,0.6-1.2,0.2-2c-4-8.6-8-17.1-12.1-25.7
                c-0.4-0.8-1.1-1.5-1.9-2c-6.2-4-9.5-11.6-8-18.7c1.6-7.8,7.4-13.3,15-14.4c0.5-0.1,0.9-0.2,1.4-0.2c2.1,0.3,3.5-0.6,5-2
                c8.8-7.9,17.6-15.8,26.5-23.6c1.3-1.1,2.6-2.3,3.9-3.4c4.4-3.8,10.7-3.9,15.2-0.1c3.2,2.8,5.2,6.2,4.9,10.7c0,0.4,0.2,1,0.5,1.4
                c6.5,8.4,13.1,16.8,19.7,25.2c0.3,0.4,0.9,0.7,1.5,0.8c2.9,0.4,5.4,1.5,7.5,3.6c0.4,0.4,1,0.6,1.6,0.5c3.1,0,6.1-0.1,9.2-0.3
                c1,0,1.7,0.3,2.2,1.1c3.8,5,7.6,10.1,11.5,15.1c0.7,0.9,0.9,1.7,0.5,2.8c-1.6,4.3-3,8.7-4.6,13c-0.7,2-1.2,2.2-3.2,1.5
                c-1.4-0.5-2.9-1-4.3-1.5s-1.6-1.3-1.1-2.7c1.1-3.2,2.2-6.4,3.3-9.6c0.1-0.3,0-0.9-0.3-1.2c-2.1-2.8-4.2-5.5-6.2-8.3
                c-0.6-0.8-1.2-1.1-2.2-1c-2.6,0.2-2.7,0.1-3.3,2.5c-1.1,4.5-6.5,9.5-12.3,8.9c-1.5-0.1-2,0.4-2.5,1.7c-0.6,1.6-0.4,2.7,0.6,3.9
                c2,2.4,3.8,4.9,5.7,7.4c0.3,0.4,1,0.8,1.5,0.7c3.1-0.1,6.2-0.4,9.3-0.6c2-0.2,2.6,0.2,2.8,2.3c0.1,1.6,0.3,3.1,0.3,4.7
                c0.1,1.5-0.4,2-1.9,2.1c-4.2,0.3-8.3,0.4-12.5,0.9c-2.5,0.3-3.9-0.5-5.3-2.5c-3.3-4.7-6.9-9.2-10.4-13.7c-0.6-0.8-0.8-1.6-0.5-2.6
                c0.9-2.8,1.8-5.7,2.6-8.6c0.2-0.6,0.1-1.4-0.2-2c-1-2-1.4-4-1.5-6.2c0-0.5-0.2-1.2-0.5-1.6c-6-7.8-12-15.5-18-23.3
                C64.6,39.5,64.4,39.4,64.2,39.2z
                M77,141.5c-23.9,0-47.6,0-71.3,0c0,3.5,0,6.9,0,10.3c0.4,0,0.7,0.1,1,0.1
                c11.6,0,23.1,0.1,34.7,0.1c10.6,0,21.1,0,31.7,0c1,0,2,0.1,3,0c0.3,0,0.9-0.4,0.9-0.7C77.1,148,77,144.8,77,141.5z
                M38.8,57.5
                c0.4-0.3,0.7-0.5,1-0.8c9.8-8.7,19.6-17.4,29.4-26.2c1.3-1.1,2.2-2.5,2.6-4.2c0.9-3.6-0.4-6.4-2.9-8.9c-3.4-3.4-8.3-3.6-11.9-0.3
                c-0.8,0.8-1.7,1.5-2.5,2.3C47.1,26,39.7,32.5,32.3,39.1c-1.9,1.7-3.7,3.4-5.8,5.2C32.9,46.5,36.9,50.8,38.8,57.5z
                M21.2,76.5
                c8.2,0,14.9-6.7,14.9-14.8c0.1-8.2-6.8-15-15-15c-8,0-14.8,6.7-14.8,14.8C6.2,69.7,12.9,76.5,21.2,76.5z
                M58.2,125.4
                c0-3.3,0.4-6.4-0.1-9.4c-1.2-8.1-8.3-14.1-16.5-14.3c-7.9-0.2-15.3,5.5-16.9,13.4c-0.6,2.9-0.3,5.9-0.6,8.9
                c-0.1,1.1,0.3,1.4,1.4,1.4c10.4,0,20.7,0,31.1,0C57.1,125.4,57.7,125.4,58.2,125.4z
                M17,79.2c3.8,8.2,7.5,16.1,11.3,24.1
                c5.3-4.1,11-5.5,17.5-4.2c-3.9-8.5-7.7-16.7-11.6-25C29.4,78.8,23.8,80.5,17,79.2z
                M63.7,138.2c-1.5-3.2-2.9-6.2-4.4-9.2
                c-0.2-0.3-0.8-0.5-1.2-0.5c-11.2,0-22.3,0-33.5,0c-0.8,0-1.2,0.3-1.5,1c-1.2,2.6-2.4,5.1-3.6,7.6c-0.2,0.3-0.2,0.7-0.4,1.1
                C34,138.2,48.7,138.2,63.7,138.2z
                M92.2,52.8C86.1,45,80.1,37.3,74.6,30.3C72,32.7,69.5,35,67,37.4c5.6,7.2,11.4,14.7,17.3,22.2
                C87.2,55.3,87.9,54.7,92.2,52.8z
                M86.5,65c0.3,5.2,4.5,9.1,9.4,8.8c4.9-0.3,8.8-4.2,8.7-9.4c-0.2-5.3-4.5-9.1-9.9-8.8
                C90.1,55.9,86.2,60.3,86.5,65z
                M106.7,59.5c0.3,0.8,0.5,1.2,0.5,1.7c0.1,1.1,0.7,1.4,1.7,1.3c1.2-0.1,2.4-0.3,3.5-0.2
                c0.6,0,1.3,0.4,1.7,0.8c2.8,3.5,5.4,7.1,8.2,10.6c0.6,0.8,0.8,1.6,0.4,2.6c-0.6,1.6-1.1,3.2-1.7,4.8c-0.5,1.5-1.1,3.1-1.6,4.6
                c0.9,0.3,1.6,0.6,2.4,0.8c0.1-0.2,0.2-0.3,0.3-0.4c1.3-3.7,2.6-7.4,3.8-11.1c0.1-0.3,0-0.9-0.2-1.2c-3.4-4.5-6.8-9.1-10.3-13.6
                c-0.3-0.3-0.7-0.7-1.1-0.7C111.8,59.5,109.4,59.5,106.7,59.5z
                M87.8,74.3c-0.7,2.2-1.3,4.4-2.1,6.5c-0.3,0.8-0.1,1.4,0.4,2.1
                c3.4,4.4,6.7,8.8,10.1,13.2c0.3,0.4,0.9,0.6,1.3,0.6c1.4,0,2.9-0.1,4.3-0.2c2.5-0.2,5-0.4,7.5-0.6c0-0.9,0-1.7,0-2.7
                c-3.3,0.2-6.4,0.4-9.5,0.7c-1.2,0.1-2.1-0.1-2.8-1.1c-2.6-3.5-5.3-7-7.9-10.4c-0.6-0.8-0.7-1.5-0.3-2.3c0.5-1.4,1-2.8,1.5-4.3
                C89.4,75.3,88.7,74.9,87.8,74.3z"/> <path
                className="st0"
                d="M232.7,75c1.5,2.4,1.5,2.4,4.3,1.9c0.3-0.1,0.6-0.1,0.9-0.1c1.9-0.4,3.2,0.5,3.6,2.4c0.4,2.3,0.8,4.5,1.2,6.8
                c0.5,2.7-0.5,4.1-3.2,4.7c-0.3,0.1-0.6,0.1-0.9,0.2c-1.8,0.3-2.4,1-2.3,2.8c0,0.3,0.3,0.6,0.5,0.7c0.9,0.7,1.9,1.3,2.8,2
                c1.2,0.9,1.5,2.2,0.7,3.5c-1.6,2.5-3.3,5-5,7.4c-0.8,1.1-2.2,1.3-3.4,0.6c-1-0.6-1.9-1.2-2.8-1.9c-0.5-0.4-1-0.6-1.4,0
                c-0.5,0.6-2,0.4-1.5,1.9c0.3,1,0.4,2,0.6,3c0.3,1.8-0.5,3-2.3,3.4c-2.7,0.5-5.5,1-8.2,1.5c-1.5,0.3-2.7-0.5-3.1-2.1
                c-0.2-1-0.4-2.1-0.6-3.1c-0.1-1.2-1.1-1.1-1.9-1.3c-0.7-0.2-1.2-0.1-1.6,0.5c-0.5,0.9-1.1,1.7-1.7,2.5c-1,1.3-2.4,1.7-3.8,0.8
                c-2.4-1.6-4.7-3.2-7.1-4.9c-1.3-1-1.6-2.4-0.7-3.8c0.5-0.9,1.1-1.8,1.8-2.6c0.3-0.5,0.6-0.9,0.1-1.4c-0.6-0.6-0.7-1.9-2-1.6
                c-1,0.2-2.1,0.4-3.1,0.6c-1.5,0.2-2.8-0.6-3.1-2.1c-0.6-2.8-1.1-5.7-1.6-8.6c-0.3-1.6,0.6-2.8,2.2-3.2c1-0.2,2.1-0.4,3.1-0.6
                c0.9-0.1,1-0.8,1.1-1.5s0.6-1.4-0.4-2c-0.9-0.5-1.8-1.2-2.6-1.8c-1.3-0.9-1.6-2.4-0.8-3.7c1.6-2.5,3.3-4.9,5-7.2
                c1-1.3,2.4-1.4,3.8-0.5c0.6,0.4,1.2,0.8,1.7,1.2c1,0.9,1.8,0.9,2.8,0.1c0.7-0.6,0.9-1.1,0.8-1.9c-0.2-0.9-0.4-1.9-0.5-2.8
                c-0.2-1.5,0.5-2.7,2-3c2.9-0.6,5.8-1.1,8.7-1.6c1.4-0.2,2.6,0.6,2.9,2c0.2,0.9,0.4,1.9,0.5,2.8s0.5,1.4,1.5,1.6
                c1.1,0.2,1.8,0.2,2.3-0.9c0.3-0.7,0.9-1.3,1.3-2c1-1.5,2.4-1.9,3.9-0.9c2.4,1.6,4.7,3.3,7,4.9c1.3,0.9,1.5,2.3,0.6,3.7
                c-0.3,0.6-0.8,1.1-1.1,1.7C233.5,73.8,233.1,74.4,232.7,75z
                M207.4,64.7c0.2,1.9,0.7,3.6,0.5,5.1c-0.3,1.9-2.4,2.3-3.8,3.2
                c-2.8,1.9-4.2-1.3-6.4-2c-1.4,2-2.7,4-4.2,6.1c0.6,0.4,1.1,0.8,1.6,1.2c0.5,0.4,1,0.7,1.6,1.1c1.1,0.8,1.5,1.8,1.1,3.1
                c-0.2,0.8-0.4,1.7-0.6,2.6c-0.3,1.7-0.8,2.3-2.4,2.6c-1.2,0.3-2.4,0.5-3.7,0.8c0.5,2.6,0.9,5,1.4,7.5c1.3-0.2,2.5-0.4,3.6-0.7
                c1.4-0.3,2.4,0.2,3.1,1.4c0.5,0.8,1,1.6,1.6,2.5c0.9,1.3,0.9,2.1,0,3.5c-0.7,1-1.3,2-2,3c2.1,1.5,4.2,2.9,6.2,4.3
                c0.8-1.1,1.5-2.1,2.1-3.1c0.8-1.1,1.8-1.5,3.1-1.1c0.9,0.2,1.7,0.4,2.6,0.6c1.7,0.3,2.3,0.8,2.7,2.6c0.3,1.2,0.5,2.4,0.7,3.6
                c2.5-0.5,4.9-0.9,7.3-1.4c-0.2-1.4-0.4-2.6-0.6-3.8c-0.2-1.3,0.2-2.3,1.4-3c0.9-0.5,1.7-1.1,2.5-1.6c1.1-0.7,2.1-0.7,3.2,0
                c1,0.7,2.1,1.4,3.2,2.2c1.4-2.1,2.8-4.2,4.3-6.3c-1.1-0.8-2.1-1.5-3.1-2.1c-1.2-0.8-1.5-1.9-1.2-3.2c0.2-1,0.5-2,0.7-3.1
                s0.9-1.8,1.9-2s2.1-0.4,3.1-0.6c0.7-0.1,0.9-0.4,0.8-1.1c-0.4-1.9-0.7-3.8-1.1-5.7c-0.1-0.8-0.6-0.9-1.3-0.8
                c-0.9,0.2-1.8,0.4-2.7,0.5c-1.3,0.3-2.3-0.3-3-1.4c-0.5-0.8-1-1.6-1.5-2.3c-0.9-1.3-0.9-2.3,0-3.6c0.6-1,1.3-1.9,2-3
                c-2.1-1.5-4.2-2.9-6.2-4.3c-0.8,1.1-1.4,2.1-2.1,3c-0.9,1.3-1.8,1.6-3.3,1.2c-0.8-0.2-1.6-0.4-2.5-0.5c-1.7-0.3-2.4-1-2.7-2.7
                c-0.2-1.1-0.4-2.2-0.7-3.5C212.2,63.9,209.8,64.3,207.4,64.7z"/>
                <path className="st0"
                d="M197.4,34.2c0,14.3-10.4,26.6-24.1,28.8c-10,1.6-18.8-0.8-26.2-7.7c-5.1-4.7-8-10.6-8.8-17.6
                C136.5,22.1,148,8.2,163.5,6c16.2-2.3,31.5,8.9,33.7,25.1C197.3,32.3,197.3,33.5,197.4,34.2z
                M141.3,34.8c-0.1,3,0.5,6.1,1.7,9.1
                c6.2,15.4,25.6,21.4,39.5,12.1c11.3-7.6,14.9-21.7,8.5-33.5c-4.8-8.8-15.2-14.9-27.2-13.4C151.2,10.8,141.4,21.7,141.3,34.8z"/>
                <path className="st0"
                d="M176,117.1c-3.5,0-7,0.3-10.5-0.1c-7-0.7-12.4-4.2-15.7-10.6c-1.1-2.2-1.5-4.6-1.5-7.1c0-4.3,0-8.5,0-12.8
                c0-1-0.3-1.4-1.4-1.4c-1.4,0.1-2.9,0.1-4.3,0c-0.7-0.1-1.6-0.4-1.9-0.9c-0.3-0.4,0-1.4,0.3-1.9c2.4-3.8,4.9-7.6,7.3-11.4
                c0.9-1.4,2.2-1.4,3.1,0c2.5,3.8,4.9,7.5,7.3,11.3c1,1.6,0.4,2.8-1.5,2.9c-1.5,0-3,0-4.5,0c-0.8,0-1.2,0.2-1.2,1.1
                c0,4.5-0.1,9.1,0.1,13.6c0.2,6.3,4.4,11.4,10.9,13.2c1.7,0.5,3.5,0.8,5.2,0.8c6.1,0.1,12.3,0,18.4,0c0.5,0,1.1-0.1,1.5,0.2
                c0.5,0.4,1,1.1,0.9,1.6c0,0.5-0.7,1.2-1.2,1.3c-0.8,0.2-1.7,0.1-2.5,0.1c-2.9,0-5.9,0-8.8,0C176,117.2,176,117.2,176,117.1z
                M154.9,82c-1.7-2.6-3.2-5-4.9-7.5c-1.7,2.6-3.2,5-4.9,7.5C148.5,82,151.5,82,154.9,82z"/>
                <path className="st0"
                d="M234.1,37.4c1.9,0,3.6-0.1,5.4,0c0.8,0,1.7,0.3,2.2,0.8c0.7,0.7,0,1.6-0.4,2.3c-2.4,3.6-4.7,7.3-7.1,10.9
                c-1,1.6-2.2,1.6-3.2,0c-2.5-3.8-4.9-7.5-7.3-11.4c-0.3-0.5-0.4-1.3-0.2-1.7c0.3-0.5,1-0.8,1.6-0.8c1.9-0.1,3.9,0,6,0
                c0-1.7,0-3.3,0-4.9c0-3.2-0.1-6.3,0-9.5c0.3-6.9-4.2-12.1-9.8-14.1c-2-0.7-4.2-1.1-6.4-1.2c-6.1-0.2-12.2-0.1-18.3-0.1
                c-0.3,0-0.7,0-1,0c-1-0.1-1.6-0.6-1.5-1.6c0.1-0.9,0.5-1.5,1.6-1.5c7,0,14-0.3,21,0.1c8.5,0.4,15.4,6.2,17.1,13.7
                c0.3,1.1,0.4,2.2,0.4,3.4c0,4.7,0,9.3,0,14C234.1,36.2,234.1,36.7,234.1,37.4z
                M232.6,48.1c1.7-2.5,3.2-4.9,4.8-7.5
                c-3.3,0-6.4,0-9.7,0C229.4,43.1,230.9,45.5,232.6,48.1z"/>
                <path className="st0"
                d="M128.9,193.3c0-4.6,3.7-8.3,8.3-8.3s8.4,3.7,8.4,8.3s-3.8,8.4-8.4,8.3C132.6,201.6,128.9,198,128.9,193.3z
                M137.1,198.5c2.9,0,5.2-2.1,5.3-5s-2.2-5.3-5.1-5.4c-2.8-0.1-5.3,2.4-5.3,5.2S134.3,198.4,137.1,198.5z"/>
                <path className="st0"
                d="M218.6,185c4.6,0,8.3,3.7,8.3,8.2c0,4.7-3.8,8.5-8.4,8.4c-4.7-0.1-8.3-3.7-8.3-8.5
                C210.3,188.6,214,185,218.6,185z
                M218.6,198.5c2.9,0,5.2-2.2,5.2-5c0-2.9-2.3-5.3-5.1-5.3s-5.2,2.3-5.2,5.1
                C213.4,196.1,215.7,198.5,218.6,198.5z"/> <path
                className="st0"
                d="M110.1,185c4.7,0,8.5,3.7,8.4,8.4c0,4.6-3.7,8.2-8.4,8.3c-4.4,0.1-8.2-3.8-8.2-8.4
                C101.9,188.7,105.5,185,110.1,185z
                M115.3,193.4c0-2.9-2.3-5.3-5.2-5.3c-2.8,0-5.2,2.4-5.2,5.1c0,2.9,2.3,5.2,5.2,5.2
                C113.1,198.5,115.3,196.3,115.3,193.4z"/> <path
                className="st0"
                d="M199.8,193.5c-0.1,4.7-3.9,8.3-8.5,8.2c-4.7-0.1-8.2-3.9-8.1-8.6c0.1-4.7,3.9-8.2,8.7-8.1
                C196.4,185.2,200,189,199.8,193.5z
                M196.7,193.4c0-2.9-2.3-5.3-5.2-5.3c-2.8,0-5.2,2.3-5.2,5c0,3.1,2.2,5.3,5.2,5.3
                C194.4,198.5,196.7,196.2,196.7,193.4z"/> <path
                className="st0"
                d="M164.4,185c4.6,0,8.4,3.8,8.3,8.4c-0.1,4.5-3.8,8.2-8.3,8.3c-4.6,0.1-8.4-3.7-8.4-8.4
                C156,188.7,159.7,185,164.4,185z
                M164.2,198.5c2.9,0.1,5.3-2.2,5.4-5.1c0-2.8-2.3-5.2-5.1-5.3s-5.3,2.3-5.4,5.1
                S161.4,198.4,164.2,198.5z"/> <path
                className="st0"
                d="M91.4,193.3c0,4.7-3.7,8.4-8.3,8.4c-4.6,0-8.3-3.7-8.3-8.3c0-4.7,3.6-8.4,8.2-8.4
                C87.8,185,91.4,188.6,91.4,193.3z
                M83.1,198.5c3,0,5.2-2.2,5.2-5.2c0-2.8-2.3-5.2-5.1-5.2c-2.8,0-5.2,2.3-5.2,5.1
                C77.9,196.2,80.1,198.5,83.1,198.5z"/> <path
                className="st0"
                d="M180,172.2c-0.4,0-0.8,0-1.2-0.1c-0.8-0.2-1.4-0.9-1.2-1.6c0.2-0.6,0.9-1.1,1.5-1.4c0.4-0.2,1.1,0,1.6,0
                c1,0,1.6,0.4,1.7,1.4c0,1.1-0.6,1.6-1.6,1.7C180.5,172.2,180.3,172.2,180,172.2L180,172.2z"/>
                <path className="st0"
                d="M171.8,172.2c-0.4-0.1-1,0-1.3-0.3c-0.5-0.4-1-1.1-1-1.6s0.7-1,1.3-1.3c0.5-0.2,1.2-0.1,1.7-0.1
                c1,0,1.7,0.4,1.7,1.5s-0.7,1.5-1.7,1.6C172.3,172.2,172,172.2,171.8,172.2L171.8,172.2z"/>
                <path className="st0"
                d="M188.2,169c0.2,0,0.4,0,0.6,0c1,0,1.7,0.4,1.8,1.5c0.1,0.9-0.7,1.6-1.8,1.7c-0.5,0-1,0-1.5,0
                c-0.9-0.1-1.6-0.6-1.5-1.6c0-1,0.7-1.5,1.7-1.5C187.7,169,187.9,169,188.2,169z"/>
                <path className="st0"
                d="M121.8,169c0.4,0,0.8-0.1,1.1,0c0.5,0.3,1.1,0.8,1.2,1.2c0.1,0.5-0.4,1.5-0.8,1.6c-1,0.2-2.1,0.3-3,0
                c-0.4-0.1-0.9-1-0.9-1.5c0.1-0.5,0.8-1,1.3-1.3C121,168.9,121.4,169.1,121.8,169C121.8,169.1,121.8,169,121.8,169z"/>
                <path className="st0"
                d="M130,169c0.3,0,0.7-0.1,1,0c0.4,0.2,1,0.5,1.2,0.9c0.2,0.5,0,1.2-0.3,1.6c-0.6,0.9-3.4,0.9-4,0
                c-0.3-0.4-0.4-1.1-0.3-1.5s0.7-0.8,1.1-1C129.1,168.9,129.6,169.1,130,169C130,169.1,130,169,130,169z"/>
                <path className="st0"
                d="M113.8,169c0.3,0,0.5,0,0.8,0c1,0,1.5,0.5,1.6,1.5c0,0.9-0.5,1.5-1.4,1.6c-0.7,0.1-1.3,0.1-2,0
                c-0.9-0.1-1.4-0.7-1.4-1.6c0-1,0.6-1.4,1.5-1.5C113.1,169,113.4,169,113.8,169L113.8,169z"/>
                <path className="st0"
                d="M56.8,23.7c0.1-3.1,2.6-5.5,5.8-5.5c3,0.1,5.5,2.7,5.4,5.7c-0.1,3.1-2.6,5.5-5.6,5.5
                C59.2,29.3,56.8,26.8,56.8,23.7z
                M64.8,23.8c0-1.3-1-2.5-2.3-2.5S60,22.5,60,23.8s1.2,2.5,2.4,2.4C63.7,26.2,64.8,25.2,64.8,23.8z
                "/> <path className="st0"
                d="M26.7,61.6c0,3.1-2.4,5.5-5.5,5.6c-3.2,0-5.6-2.4-5.6-5.6c0-3.1,2.4-5.6,5.5-5.6
                C24.2,55.9,26.7,58.4,26.7,61.6z
                M23.6,61.6c0-1.4-1-2.4-2.3-2.4c-1.4,0-2.5,1-2.5,2.4c0,1.5,1,2.4,2.4,2.4
                C22.5,64,23.5,62.9,23.6,61.6z"/> <path
                className="st0"
                d="M41.3,119.2c-3.1,0-5.4-2.4-5.5-5.6c0-3.1,2.4-5.6,5.4-5.6c3.1,0,5.6,2.6,5.6,5.7
                C46.8,116.7,44.4,119.2,41.3,119.2z
                M41.3,111.1c-1.2,0-2.3,1.2-2.3,2.5c0,1.4,1.1,2.5,2.3,2.4c1.2,0,2.4-1.2,2.4-2.5
                C43.7,112.3,42.5,111.1,41.3,111.1z"/> <path
                className="st0"
                d="M90,64.8c0-3.2,2.3-5.6,5.5-5.6c3.1,0,5.6,2.5,5.6,5.6s-2.6,5.6-5.6,5.6C92.4,70.3,90,67.8,90,64.8z
                M93.1,64.6c0,1.3,1,2.4,2.3,2.5c1.2,0.1,2.4-1,2.5-2.3c0.1-1.4-1-2.5-2.4-2.6C94.3,62.2,93.1,63.3,93.1,64.6z"/>
                <path className="st0"
                d="M215.9,99.8c-6.2,0.3-11.8-4.8-12.2-11c-0.4-6.7,4.5-12.2,10.9-12.6c6.6-0.4,12.3,4.8,12.5,11.6
                C227.3,94.3,222.4,99.5,215.9,99.8z
                M215.3,96.6c4.9,0,8.7-3.7,8.7-8.5s-3.8-8.8-8.5-8.8c-4.8,0-8.7,3.9-8.7,8.7
                C206.8,92.6,210.7,96.6,215.3,96.6z"/> <path
                className="st0"
                d="M169.6,22.3c0,2.9,0,5.7,0,8.6c0,0.7,0.1,1.1,0.8,1.5c1.9,1.1,2.7,3.2,2.3,5.2c-0.4,1.9-2,3.5-3.9,3.8
                c-2.1,0.4-4.2-0.4-5.2-2.2c-0.5-0.8-1-1.1-1.9-1c-3.3,0.1-6.7,0-10,0c-0.4,0-0.8,0-1.1-0.1c-0.8-0.2-1.4-0.6-1.4-1.5
                s0.6-1.5,1.5-1.5c1.3,0,2.6,0,3.9,0c2.4,0,4.9,0,7.3,0c0.5,0,1-0.4,1.4-0.8c0.8-0.6,1.4-1.5,2.2-2c0.7-0.4,0.9-0.9,0.9-1.6
                c0-5.4,0-10.9,0-16.3c0-0.5,0-1,0-1.5c0.1-0.9,0.6-1.5,1.5-1.5c1,0,1.5,0.6,1.6,1.5c0.1,0.7,0.1,1.3,0.1,2
                C169.6,17.3,169.6,19.8,169.6,22.3L169.6,22.3z
                M169.6,36.7c0-1-0.9-1.8-1.9-1.8s-1.8,0.7-1.8,1.7s0.8,1.8,1.8,1.9
                C168.7,38.4,169.6,37.6,169.6,36.7z"/> <path
                className="st0"
                d="M45.8,206.3c-1.1-5.3-6.7-7.9-11.3-5.8c-1.1,0.5-1.6,0.3-2.3-0.6c-2.9-4-5.9-7.9-8.7-12
                c-4.8-7.2-8.6-15-11.5-23.1c-0.7-2-1.4-4-2-6c-0.9-0.9-2-0.7-2.1,0.8c2,6.7,4.7,13.1,7.9,19.3c2.3,4.4,4.7,8.9,7.5,13.1
                c2.3,3.3,4.8,6.5,7.5,9.6l0,0c0.5,0.5,0.6,1.3,0.1,1.8c-1.8,2.6-1.9,6.1-0.1,8.8c1.6,2.8,4.8,4.4,8,3.8c2.5-0.3,4.8-1.8,6.2-4.2
                C46,210.2,46.2,208.2,45.8,206.3z
                M37.8,213.9c-0.1,0-0.1,0-0.2,0c-2.7-0.1-5.2-2-5.7-4.7c-0.9-4,2.3-7.5,6.1-7.4
                c3.3,0.1,6,2.9,5.9,6.2C43.6,211.3,41,213.8,37.8,213.9z"/>
                <path className="st0"
                d="M9.6,82.9c-3.6,10-5.9,20.6-6.7,31.5c-0.5,5.8-0.5,11.5-0.1,17.3C2.9,132.4,3,133,4,133c0,0,1,0,1.1-0.9
                c-0.3-3-0.4-5.1-0.4-8.2v-0.1c0.3-4.4,0.4-8.9,0.8-13.3c0.9-8.4,2.7-16.6,5.4-24.5c0.1-0.4,0.3-0.8,0.4-1.2
                c0.2-0.5,0.1-1.1-0.3-1.5C10.6,82.8,9.8,82.4,9.6,82.9z"/>
                <path className="st0"
                d="M147.9,6.7c0.4-0.7-0.1-1.6-0.8-1.7c-16.5-3.3-33.6-3.1-50.2,0.7c-6.5,1.5-12.8,3.5-19,6
                c-0.6,0.3-0.9,1-0.6,1.6l0,0c0.3,0.6,0.9,0.8,1.5,0.6c10.5-4.2,21.6-7,33.5-8.2c9.3-0.9,18.7-0.7,28,0.7c2.1,0.3,4.2,0.7,6.3,1.1
                C147.1,7.5,147.6,7.2,147.9,6.7C147.8,6.8,147.9,6.7,147.9,6.7z"/>
                <path className="st0"
                d="M81.6,236.6c0.2-0.6-0.1-1.2-0.7-1.4c-4.4-1.7-8.7-3.6-12.9-5.8c-2.7-1.4-5.3-3-8-4.6
                c-0.7-0.4-1.3-0.5-1.8,0.2s-0.2,1.3,0.5,1.8c0.3,0.2,0.6,0.4,1,0.6c5.8,3.6,12,6.6,18.3,9.2c0.7,0.3,1.4,0.5,2.1,0.8
                C80.7,237.7,81.4,237.3,81.6,236.6L81.6,236.6z"/>
                <path className="st0"
                d="M241.5,112L241.5,112c-0.3,0.2-0.5,0.6-0.5,1c0.4,3.7,0.6,7.4,0.6,11.1v0.1c-0.3,4.4-0.4,8.9-0.8,13.3
                c-1.3,12.2-4.6,24-9.5,35.1c-0.2,0.5,0,1.2,0.5,1.4c0.1,0,0.1,0,0.2,0.1c0.5,0.2,1.2,0,1.4-0.5c5.5-12.4,9-25.8,10-39.8
                c0.5-7,0.5-14-0.2-21C243.1,111.9,242.2,111.5,241.5,112z"/>
                </g> </g> </svg>
            </SvgIcon>
            }
            severity="info" 
        >
        <AlertTitle>Your verification information was received and is in review.</AlertTitle>
        A SyncFab team member is reviewing your verification information. This
        can take a few days for our review to complete. Once completed you will
        receive a email notification with next steps.
    </Alert>
)
}

export default withStyles(styles)(Al);