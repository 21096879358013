import * as React from 'react';
import { connect } from 'react-redux';
import { withNamespaces, WithNamespaces } from 'react-i18next';
import { Dispatch } from 'redux';
import { HIDE_FEEDBACK } from './Actions';
import { Feedback } from '../ui/Feedback/Feedback';

interface FeedBackProps extends WithNamespaces {
  show: boolean;
  message: string;
  close: () => void;
  messageClass?: string;
}

class BaseFeedback extends React.Component<FeedBackProps> {
  constructor(props) {
    super(props);
    this.handleClose = this.handleClose.bind(this);
  }

  handleClose = (_, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    this.props.close();
  };

  render() {
    const { t, message, show, messageClass } = this.props;
    return (
      <Feedback
        message={t(message)}
        open={show}
        handleClose={this.handleClose}
        messageClass={messageClass}
      />
    );
  }
}

const mapStateToProps = ({ feedback: { show, message } }) => {
  return {
    show,
    message,
  };
};

const mapDispatchToProps = (dispatch: Dispatch) => {
  return {
    close: () => dispatch(HIDE_FEEDBACK()),
  };
};

export const SimpleFeedback = connect(
  mapStateToProps,
  mapDispatchToProps
)(withNamespaces()(BaseFeedback));
