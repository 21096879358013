import { Chip, Theme, useTheme } from "@material-ui/core";
import React, { FC } from "react";
import { SupplierQuoteStatus } from "../SupplierQuoteDetail";

export const getSupplierQuoteStatusBackgroundColor = (status: SupplierQuoteStatus, theme: Theme): string => {

    if (status === SupplierQuoteStatus.QUOTED) {
        return theme.palette.success.main
    } else if (status === SupplierQuoteStatus.NO_QUOTE) {
        return theme.palette.grey[500]
    } else { /* NO RESPONSE */
        return theme.palette.grey[600]
    }
}

interface SupplierQuoteStatusChipProps {
    status: SupplierQuoteStatus
}

export const SupplierQuoteStatusChip: FC<SupplierQuoteStatusChipProps> = ((props) => {

    const { status } = props
    const theme = useTheme()

    return (
        <Chip
            style={{ width: '100%', color: '#fff', backgroundColor: getSupplierQuoteStatusBackgroundColor(status, theme)}}
            size="small"
            label={status}
        />
    )
})