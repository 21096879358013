import { createTheme } from '@material-ui/core'
import { typography } from './Typography'
import { palette, colors as mmsColors } from './ColorPalette'

const whiteBackground = {
    backgroundColor: palette.common!.white,
}
const whiteFont = {
    color: palette.common!.black,
}

const grayBackground = {
    backgroundColor: '#f3f3f3',
}
const mmsTheme = createTheme({
    typography,
    palette,
    overrides: {
        MuiSnackbarContent: {
            root: {
                backgroundColor: (palette.primary as any).dark,
            },
        },
        MuiTableRow: {
            root: {
                height: '36px',
            },
        },
        MuiTableCell: {
            root: {
                padding: '0 24px 0 24px',
                borderBottom: 'none',
                ...whiteFont,
            },
        },
        MuiTable: {
            root: {
                ...whiteBackground,
            },
        },
        MuiDialogTitle: {
            root: {
                ...whiteFont,
            },
        },
        MuiDialogContent: {
            root: {
                ...whiteFont,
                fontFamily: `'Titillium Web', 'sans-serif'`,
            },
        },
        MuiDivider: {
            light: {
                color: palette.grey!.A100,
                backgroundColor: palette.grey!.A100,
            },
        },
        MuiListItemIcon: {
            root: {
                marginRight: 0,
            },
        },
        MuiTypography: {
            body1: {
                ...whiteFont,
            },
            body2: {
                ...whiteFont,
            },
            caption: {
                ...whiteFont,
            },
        },
        MuiButton: {
            root: {
                borderRadius: 20,
            },
            containedSizeSmall: {
                fontSize: '14px',
                height: '40px',
                minWidth: '120px'
            },
            outlinedSizeSmall: {
                fontSize:'14px',
                height: '40px',
                minWidth: '120px'
            },
        },
        MuiFilledInput: {
            input: {
                ...whiteBackground,
                borderTopLeftRadius: 3,
                borderTopRightRadius: 3,
                cursor: 'pointer',
                '&:focus': {
                    ...whiteBackground,
                },
            },
            inputMultiline: {
                minHeight: 190,
            },
            multiline: {
                ...whiteBackground,
                borderTopLeftRadius: 3,
                borderTopRightRadius: 3,
                minHeight: 190,
                cursor: 'pointer',
                '&:hover': {
                    ...whiteBackground,
                },
                '&:focus': {
                    ...whiteBackground,
                },
            },
            root: {
                backgroundColor: `${palette.common!.white} !important`,
                borderTopLeftRadius: 3,
                borderTopRightRadius: 3,
                cursor: 'pointer',
                '&:hover': {
                    ...whiteBackground,
                },
                '&:focus': {
                    ...whiteBackground,
                },
            },
        },
        MuiSelect: {
            filled: {
                ...whiteBackground,
                border: '1px solid #989898',
                borderRadius: '5px',
            },
            selectMenu: {
                ...grayBackground,
            },
            root: {
                ...whiteBackground,
                borderTopLeftRadius: 3,
                borderTopRightRadius: 3,
            },
        },
        MuiMenu: {
            paper: {
                ...whiteBackground,
            },
        },
        MuiMenuItem: {
            root: {
                ...whiteBackground,
                '&:hover': {
                    ...grayBackground,
                },
                '&$selected': {
                    backgroundColor: palette.grey!.A100,
                },
            },
        },
        MuiFormControl: {
            marginNormal: {
                marginBottom: 4,
                marginTop: 8,
            },
        },
        MuiFormControlLabel: {
            label: {
                ...whiteFont,
            },
        },
        MuiCheckbox: {
            root: {
                ...whiteFont,
            },
        },
        MuiListItem: {
            root: {
                ...whiteFont,
                textDecoration: 'none',
            },
            gutters: {
                paddingLeft: 32,
                paddingRight: 32,
            },
        },
        MuiListItemText: {
            root: {
                ...whiteFont,
                textDecoration: 'none',
            },
        },
        MuiInput: {
            root: {
                minHeight: 56,
                ...whiteBackground,
            },
        },
        MuiInputAdornment: {
            positionEnd: {
                ...whiteBackground,
            },
            filled: {
                ...whiteBackground,
            },
            root: {
                ...whiteBackground,
            },
        },
        MuiTab: {
            root: {
                color: palette.grey!.A100,
                '&$selected': {
                    color: (palette.primary as any).light,
                },
            },
            textColorPrimary: {
                color: palette.grey!.A700,
            },
        },
        MuiTabs: {
            root: {
                ...whiteFont,
            },
        },
        MuiDialogContentText: {
            root: {
                ...whiteFont,
            },
        },
    },
})

export { mmsTheme, mmsColors }
