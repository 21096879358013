export enum ActivityType {
    CREATION = 'CREATION',
    DELETION = 'DELETION',
    MODIFICATION = 'MODIFICATION',
    CREATE_REVISION = 'CREATE_REVISION',
    TOKENIZATION = 'TOKENIZATION',
    ADD_LINE_ITEM = 'ADD_LINE_ITEM',
    MODIFY_LINE_ITEM = 'MODIFY_LINE_ITEM',
    DELETE_LINE_ITEM = 'DELETE_LINE_ITEM',
    ARCHIVE_LINE_ITEM = 'ARCHIVE_LINE_ITEM',
    RESTORE_LINE_ITEM = 'RESTORE_LINE_ITEM',
    SEND_LINE_ITEM_GROUP = 'SEND_LINE_ITEM_GROUP',
    CHANGE_STATUS = 'CHANGE_STATUS',
    RECEIVE_QUOTE_FROM_SUPPLIER = 'RECEIVE_QUOTE_FROM_SUPPLIER',
    RECEIVE_NOQUOTE_FROM_SUPPLIER = 'RECEIVE_NOQUOTE_FROM_SUPPLIER',
    SEND_QUOTE_TO_BUYER = 'SEND_QUOTE_TO_BUYER',
    CREATE_PURCHASE_ORDER = 'CREATE_PURCHASE_ORDER',
    OPERATOR_UPDATE_NOTIFICATION_SENT = 'OPERATOR_UPDATE_NOTIFICATION_SENT',
    SUPPLIER_REMOVE_DOCUMENT = 'SUPPLIER_REMOVE_DOCUMENT',
    CHANGE_REQUEST = 'CHANGE_REQUEST',
    DECLINE_QUOTE = 'DECLINE_QUOTE',
    ACCEPT_QUOTE = 'ACCEPT_QUOTE',
    CONFIRM_SUPPLIER_PURCHASE_ORDER = 'CONFIRM_SUPPLIER_PURCHASE_ORDER'
}

export interface UserIdAndName {
    userId: string
    userName: string
}

export interface CompanyIdAndName {
    companyId: string
    companyName: string
}

export interface Activity {
    activityType: ActivityType
    activityBy: UserIdAndName
    activityAt: Date
    activityDetails?: string
}

export interface Comment {
    commentId: string
    comment: string
    poLineItemId?: string
    createdBy?: UserIdAndName
    createdAt?: Date | null
    updatedBy?: UserIdAndName
    updatedAt?: Date
    deletedBy?: UserIdAndName
    deletedAt?: Date
}

export enum SortDirection {
    ASC = 1,
    DESC = -1,
}

export interface ListMeta {
    page?: number
    size?: number
    total?: number
    sortField?: string
    sortDirection?: SortDirection
}

export interface ListParameters extends ListMeta {
    state?: string,
    searchTerm?: string
    query?: string,
    filter?: string,
    companyId? : string
}

export interface QuoteListParameters extends ListParameters {
    deadlineFilter?: string[]
}

export interface ListSuppliersParameters extends ListMeta {

}

export type ListResult<T> = {
    data: T[]
    total: number
}
