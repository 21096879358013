import { LOGOUT_ACTION_TYPE } from '@syncfab/machine'
import { GET_BLOCKCHAIN_ORDER } from './Actions'
import { BlockchainOrder } from './Blockchain'

export type BlockchainReducerType = {
    orders?: BlockchainOrder
}

const initialState: BlockchainReducerType = {}

export const BlockchainReducer = (state = initialState, action: any) => {
    switch (action.type) {
        case GET_BLOCKCHAIN_ORDER.types.TRIGGER:
            return {
                ...state,
                orders: null,
            }
        case GET_BLOCKCHAIN_ORDER.types.SUCCESSFULL:
            return {
                ...state,
                orders: action.data,
            }
        case LOGOUT_ACTION_TYPE:
            return {
                ...state,
                ...initialState,
            }
        default:
            return state
    }
}
