import React, { useState } from 'react'
import { withNamespaces, WithNamespaces } from 'react-i18next'
import { Dialog, DialogTitle, DialogContent, Grid, Typography, Card, Box, IconButton } from '@material-ui/core'
import FilePondComponent from '../../../../components/document/DocumentComponent'
import { withStyles, WithStyles } from '@material-ui/core/styles'
import { InsertDriveFile } from '@material-ui/icons'
import CloseIcon from '@material-ui/icons/Close'
import { Button, DocumentType } from '@syncfab/machine'

const styles = theme => ({
    card: {
        width: '100%',
        margin: 0,
        padding: theme.spacing(2),
        position: 'relative' as any,
        border: `1px solid rgba(255, 255, 255, 0.23)`,
        borderWidth: 2,
    },
    fileName: {
        color: theme.palette.common.white,
    },
    icon: {
        width: 25,
        height: 25,
        color: theme.palette.common.white,
        marginTop: theme.spacing(1),
        cursor: 'pointer',
    },
    documentContainer: {
        display: 'flex',
    },
    documentDetails: {
        flexGrow: 1,
        marginLeft: theme.spacing(2),
    },
    fileSize: {
        color: '#D4D5D6',
    },
})

interface DialogProps extends WithStyles, WithNamespaces {
    open: boolean
    close: () => void
    onSubmit: (documents: any[], lineItemKey: number) => void
    documents: any[]
    lineItemKey: number
}

const bytesForHuman = (bytes, decimals = 2) => {
    let units = ['B', 'KB', 'MB', 'GB', 'TB', 'PB']

    let i = 0

    for (i; bytes > 1024; i++) {
        bytes /= 1024
    }

    return parseFloat(bytes.toFixed(decimals)) + ' ' + units[i]
}

const dialog = ({ onSubmit, t, open, close, classes, documents, lineItemKey }: DialogProps) => {

    const [partDocuments, setPartDocuments] = useState(documents)

    const removePartDocument = documentId => {
        setPartDocuments(partDocuments.filter(document => document._id !== documentId))
    }

    const addPartDocument = document => {
        setPartDocuments([...partDocuments, document])
    }

    return (
        <Dialog open={open} aria-labelledby="quote-editor-documents-edit" onClose={close} fullWidth>
            <DialogTitle id="quote-editor-documents-edit">{`${t('quote-editor-documents-edit')}`}</DialogTitle>
            <DialogContent>
                <Grid container direction="row" spacing={2}>
                    <Grid item xs={12} sm={12} md={12}>
                        <FilePondComponent
                            className={classes.file}
                            type={DocumentType.PART}
                            acceptedFileTypes={['application/pdf', 'allowed/extension', '.step', '.stp', '.sldprt', '.stl', '.ipt', '.3dxml', '.prt', '.sat']}
                            allowMultiple={true}
                            maxFiles={20}
                            maxFileSize="150MB"
                            maxTotalFileSize="3000MB"
                            onLoad={docObj => {
                                addPartDocument(docObj)
                            }}
                            allFilesProcessed={() => {}}
                            clearAfterAllFilesProcessed={true}
                        />
                        <Typography gutterBottom variant="caption" paragraph align="center" style={{ color: '#DBDBDB', marginBottom: 8, fontSize: 11 }}>
                            {`${t('part-editor-footer-title')}`}
                        </Typography>
                    </Grid>
                    {partDocuments.map((document: any, index: number) => (
                        <Grid item xs={12} md={12} style={{ paddingTop: 0 }} key={index}>
                            <Card className={classes.card}>
                                <Box className={classes.documentContainer} alignItems="center">
                                    <Box>
                                        <IconButton style={{ borderRadius: '50%', padding: '8px', backgroundColor: '#1C2D3E', color: 'white', marginLeft: '8px' }}>
                                            <InsertDriveFile />
                                        </IconButton>
                                    </Box>
                                    <Box className={classes.documentDetails}>
                                        <Typography color="textPrimary" variant="body2" className={classes.fileName}>
                                            { document.name ?? ''}
                                        </Typography>
                                        <Typography variant="caption" className={classes.fileSize}>
                                            { document.size ? bytesForHuman(document.size) : ''}
                                        </Typography>
                                    </Box>
                                    <Box
                                        onClick={() => {
                                            removePartDocument(document._id)
                                        }}
                                    >
                                        <CloseIcon className={classes.icon} />
                                    </Box>
                                </Box>
                            </Card>
                        </Grid>
                        ))}
                    <Grid container item xs={12} justifyContent="flex-end" style={{ marginBottom: 15 }}>
                        <Button type="button" color="primary" variant="text" className={classes.action} onClick={close}>
                            {t('form-action-cancel')}
                        </Button>
                        <Button type="submit" color="primary" className={classes.action} style={{ marginLeft: 5 }} onClick={() => onSubmit(partDocuments, lineItemKey)}>
                            {t('form-action-save')}
                        </Button>
                    </Grid>
                </Grid>
            </DialogContent>
        </Dialog>
    )
}

export const EditPartDocumentDialog = withStyles(styles)(withNamespaces()(dialog))
