import { createStyles, makeStyles, Theme } from "@material-ui/core";

export const useStandardStyles = makeStyles((theme: Theme) =>
    createStyles({
        centeredAlignedContainer: {
            display: 'flex',
            'flex-direction': 'column',
            alignItems: 'center',
            padding: theme.spacing(6),
            paddingTop: theme.spacing(7),
            backgroundColor: '#1C2D3E',
        },
        header: {
            marginTop: theme.spacing(1),
            fontSize: '14px',
            display: 'flex',
            alignItems: 'center',
        },
        paper: {
            width: '100%',
            marginTop: theme.spacing(3),
            backgroundColor: '#364F68',
        },
        formBackground: {
            width: '100%',
            marginTop: theme.spacing(3),
            padding: '24px',
            paddingTop: theme.spacing(2),
            paddingBottom: theme.spacing(2),
            backgroundColor: '#364F68',
        },
        actionButton: {
            minWidth: 120,
            marginLeft: '20px'
        },
        label: {
            fontWeight: theme.typography.fontWeightMedium as any,
            fontSize: '14px'
        },
        value: {
            fontSize: '14px'
        },
        listControlContainer: {
            margin: theme.spacing(1),
            paddingTop: '18px',
            paddingBottom: '10px',
        },
    })
)