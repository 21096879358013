import React from 'react'
import { Grid, Typography, Card, withStyles, WithStyles, Menu, MenuItem, IconButton, Divider } from '@material-ui/core'
import MoreVert from '@material-ui/icons/MoreVert'
import { RFQ, RFQStatus, Button, colors, MFGIcon } from '@syncfab/machine'
import cn from 'classnames'
import { withNamespaces, WithNamespaces } from 'react-i18next'
import { Link } from 'react-router-dom'

const styles = theme => ({
    card: {
        width: '100%',
        margin: `${theme.spacing(3)}px 12px`,
        padding: theme.spacing(3),
        backgroundColor: theme.palette.common.white,
    },
    cardHideAciton: {
        width: '100%',
        margin: `${theme.spacing(3)}px 0`,
        padding: theme.spacing(3),
        backgroundColor: theme.palette.common.white,
    },
    idLabel: {
        color: theme.palette.primary.light,
        'font-weight': theme.typography.fontWeightMedium,
    },
    label: {
        color: theme.palette.common.black,
    },
    value: {
        color: theme.palette.common.black,
        fontWeight: theme.typography.fontWeightMedium,
        'text-overflow': 'ellipsis',
        'white-space': 'nowrap',
        overflow: 'hidden',
    },
    accepted: {
        color: theme.palette.primary.light,
    },
    expired: {
        color: theme.palette.error.dark,
    },
    decline: {
        color: theme.palette.error.dark,
    },
    quoted: {
        color: theme.palette.primary.light,
        fontWeight: theme.typography.fontWeightMedium,
    },
    button: {
        minWidth: 120,
    },
    divider: {
        margin: `${theme.spacing(2)}px 0`,
    },
    link: {
        'text-decoration': 'none',
        color: theme.palette.common.black,
    },
    viewButton: {
        'text-align': 'end',
    },
    total: {
        color: colors.success.primary,
    },
    expirationDate: {
        color: theme.palette.error.main,
    },
    mfgIcon: {
        background: '#FFBA00',
        borderRadius: '50%',
        padding: '4px',
        width: '32px',
        height: '32px',
        marginRight: '10px',
    },
})

interface QuoteListItemProps extends WithStyles, WithNamespaces {
    rfq: RFQ
    deleteRFQ: (id: string) => void
    editRFQ: (id: string) => void
    addCard?: boolean
    hideAction?: boolean
    hideId?: boolean
    isSupplier?: boolean
}

class ListItem extends React.Component<QuoteListItemProps, { anchorEl: HTMLElement | null }> {
    constructor(props) {
        super(props)
        this.state = {
            anchorEl: null,
        }
        this.deleteRFQ = this.deleteRFQ.bind(this)
        this.editRFQ = this.editRFQ.bind(this)
    }

    handleMenuOpen = event => {
        this.setState({ anchorEl: event.currentTarget })
    }

    handleMenuClose = () => {
        this.setState({ anchorEl: null })
    }

    deleteRFQ() {
        this.props.deleteRFQ(this.props.rfq._id)
        this.handleMenuClose()
    }

    editRFQ() {
        this.props.editRFQ(this.props.rfq._id)
        this.handleMenuClose()
    }

    render() {
        const { anchorEl } = this.state
        const isMenuOpen = Boolean(anchorEl)
        const { rfq, classes, t, hideAction, hideId, isSupplier } = this.props
        // const canbeEditOrDeleted = rfq.status === RFQStatus.PENDING || rfq.status === RFQStatus.QUOTED
        const canbeEditOrDeleted = rfq.status === RFQStatus.OPEN || rfq.status === RFQStatus.QUOTED
        const isQuoted = rfq.status === RFQStatus.QUOTED
        const statusClassNames = cn({
            [classes.value]: true,
            [classes.accepted]: rfq.status === RFQStatus.POISSUED,
            [classes.expired]: rfq.status === RFQStatus.EXPIRED,
            [classes.decline]: rfq.status === RFQStatus.MISSED,
            [classes.quoted]: rfq.status === RFQStatus.QUOTED,
        })

        const currencyFormatter = Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' })

        return (
            <Card className={!!hideAction ? classes.cardHideAciton : classes.card}>
                <Grid spacing={3} container direction="row">
                    <Grid item xs={4}>
                        {!hideId && (
                            <Typography gutterBottom className={classes.idLabel} variant="h5">
                                {rfq._id}
                            </Typography>
                        )}
                        {!!hideId && (
                            <Typography gutterBottom className={classes.label} variant="h5">
                                {`${t('quote-list-item-rfq-po-name')}`}
                            </Typography>
                        )}
                        <Typography className={classes.value} variant="h3">
                            {rfq.name}
                        </Typography>
                    </Grid>
                    <Grid item xs={2}>
                        <Typography gutterBottom className={classes.label} variant="h5">
                            {`${t('quote-list-item-rfq-status')}`}
                        </Typography>
                        <Typography className={statusClassNames} variant="h3">
                            {t(`quote-list-item-status-${rfq.status.toLowerCase()}`) as string}
                        </Typography>
                    </Grid>
                    <Grid item xs={2}>
                        <Typography gutterBottom className={classes.label} variant="h5">
                            {`${t('quote-list-item-submitted-date')}`}
                        </Typography>
                        <Typography className={classes.value} variant="h3">
                            {new Date(rfq.createdAt).toLocaleDateString()}
                        </Typography>
                    </Grid>
                    <Grid item xs={2}>
                        <Typography gutterBottom className={classes.label} variant="h5">
                            {`${t('quote-list-item-quote-deadline')}`}
                        </Typography>
                        <Typography className={classes.value} variant="h3">
                            {rfq.quoteDeadline ? new Date(rfq.quoteDeadline).toLocaleDateString() : '--'}
                        </Typography>
                    </Grid>
                    {!hideAction && (
                        <Grid item xs={1}>
                            {!isSupplier && (
                                <IconButton color="inherit" onClick={this.handleMenuOpen}>
                                    <MoreVert />
                                </IconButton>
                            )}
                        </Grid>
                    )}
                    {rfq && rfq.rfqReward && rfq.rfqReward > 0 && !isSupplier && (
                        <Grid item xs={3}>
                            <Typography gutterBottom className={classes.label} variant="h5">
                                {`${t('MFG Reward')}`}
                            </Typography>
                            <Grid container direction="row" alignItems="center" alignContent="center">
                                <Grid item>
                                    <div className={classes.mfgIcon}>
                                        <MFGIcon />
                                    </div>
                                </Grid>
                                <Grid item>
                                    <Typography gutterBottom className={classes.label} variant="h3" style={{ margin: 0 }}>
                                        {rfq.rfqReward} MFG Added
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                    )}
                </Grid>
                {!hideAction && (
                    <React.Fragment>
                        <Divider light className={classes.divider} />
                        {!!isQuoted && (
                            <Grid item spacing={3} container direction="row" alignItems="center">
                                {!isSupplier && (
                                    <React.Fragment>
                                        <Grid item xs={4}>
                                            <Typography gutterBottom className={classes.label} variant="h5">
                                                {`${t('quote-details-initial-quote')}`}
                                            </Typography>
                                            <Typography className={classes.total} variant="h3">
                                                {currencyFormatter.format(rfq.quote.totalMin)} -{currencyFormatter.format(rfq.quote.totalMax)}
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={3}>
                                            <Typography gutterBottom className={classes.label} variant="h5">
                                                {`${t('quote-details-quote-expires')}`}
                                            </Typography>
                                            <Typography className={classes.expirationDate} variant="h3">
                                                {new Date(rfq.quote.expiresAt).toLocaleDateString()}
                                            </Typography>
                                        </Grid>
                                    </React.Fragment>
                                )}
                                <Grid item xs={2} />
                                <Grid item xs={3} className={classes.viewButton}>
                                    <Link to={`/quotes/${rfq._id}`} className={classes.link}>
                                        <Button color={isQuoted && !isSupplier ? 'secondary' : 'primary'} className={classes.button} variant={isQuoted ? 'contained' : 'outlined'}>
                                            {t(`quote-list-item-${rfq.status.toLowerCase()}`)}
                                        </Button>
                                    </Link>
                                </Grid>
                            </Grid>
                        )}
                        {!isQuoted && (
                            <Grid spacing={0} container alignItems="center">
                                <Grid item xs={6} spacing={0} container justify="flex-start">
                                    {/* {rfq && rfq.rfqrewards && rfq.rfqrewards.length > 0 && ((isSupplier && rfq.status == 'PENDING') || !isSupplier) && ( */}
                                    {rfq && rfq.rfqrewards && rfq.rfqrewards.length > 0 && ((isSupplier && rfq.status == 'OPEN') || !isSupplier) && (
                                        <div>
                                            <Typography gutterBottom className={classes.label} variant="h5">
                                                {`${t('MFG Reward')}`}
                                            </Typography>
                                            <Grid container direction="row" alignItems="center" alignContent="center">
                                                <Grid item>
                                                    <div className={classes.mfgIcon}>
                                                        <MFGIcon />
                                                    </div>
                                                </Grid>
                                                <Grid item>
                                                    <Typography gutterBottom className={classes.label} variant="h3" style={{ margin: 0 }}>
                                                        {isSupplier ? rfq.rfqrewards[0].rfqReward : `${rfq.rfqrewards[0].rfqReward} MFG Added`}
                                                    </Typography>
                                                </Grid>
                                            </Grid>
                                        </div>
                                    )}
                                </Grid>

                                <Grid item xs={6} spacing={0} container justify="flex-end">
                                    {RFQStatus.POISSUED === rfq.status ? (
                                        <Link to={`/orders`} className={classes.link}>
                                            <Button color={isQuoted ? 'secondary' : 'primary'} className={classes.button} variant={isQuoted ? 'contained' : 'outlined'}>
                                                {t(`quote-list-item-${rfq.status.toLowerCase()}`)}
                                            </Button>
                                        </Link>
                                    ) : (
                                        <Link to={`/quotes/${rfq._id}`} className={classes.link}>
                                            <Button color={isQuoted ? 'secondary' : 'primary'} className={classes.button} variant={isQuoted ? 'contained' : 'outlined'}>
                                                {t(`quote-list-item-${rfq.status.toLowerCase()}`)}
                                            </Button>
                                        </Link>
                                    )}
                                </Grid>
                            </Grid>
                        )}
                    </React.Fragment>
                )}
                {(!!canbeEditOrDeleted || !hideAction) && (
                    <Menu
                        anchorEl={anchorEl}
                        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
                        open={isMenuOpen}
                        onClose={this.handleMenuClose}
                    >
                        {!!canbeEditOrDeleted && <MenuItem onClick={this.editRFQ}>{t('syncfab-edit')}</MenuItem>}
                        {!hideAction && (
                            <MenuItem>
                                <Link to={`/quotes/${rfq._id}`} className={classes.link}>
                                    {`${t(`syncfab-view`)}`}
                                </Link>
                            </MenuItem>
                        )}
                        {/* {!!canbeEditOrDeleted && <MenuItem onClick={this.deleteRFQ}>{t('syncfab-delete')}</MenuItem>} */}
                    </Menu>
                )}
            </Card>
        )
    }
}

export const QuoteListItem = withStyles(styles)(withNamespaces()(ListItem))
