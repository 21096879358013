import axios, { AxiosPromise } from 'axios'
import { Supplier, Environment, EnvironmentVariable, AuthorizationService } from '@syncfab/machine'
import { UpdateForgottenPasswordPayload } from '../supplier/Actions'

export class SupplierAPI {
    constructor(private environment: Environment, private authorizationService: AuthorizationService) {}

    public save(supplier: Partial<Supplier>): () => AxiosPromise {
        const url = this.environment.get(EnvironmentVariable.API_LAYER_URL)
        return () => axios.post(`${url}/signup/`, { ...supplier, loginRole: 'SUPPLIER' })
    }

    public login(email: string, password: string): () => AxiosPromise {
        const url = this.environment.get(EnvironmentVariable.API_LAYER_URL)
        return () => axios.post(`${url}/auth/supplierlogin`, { email, password })
    }

    public resendValidationEmail(id: string): () => AxiosPromise {
        const url = this.environment.get(EnvironmentVariable.API_LAYER_URL)
        return () => axios.post(`${url}/signup/${id}/resendVerification`)
    }

    public resendVerificationEmail(email: string): () => AxiosPromise {
        const url = this.environment.get(EnvironmentVariable.API_LAYER_URL)
        //console.log(`${url}/signup/supplier/resendVerification/${email}`)
        return () => axios.post(`${url}/signup/${encodeURIComponent(email)}/resendVerificationEmail`)
    }

    public validate(token: string): () => AxiosPromise {
        const url = this.environment.get(EnvironmentVariable.API_LAYER_URL)
        return () => axios.post(`${url}/signup/verify?token=${token}`)
    }

    public forgotPassword(email: string): () => AxiosPromise {
        const url = this.environment.get(EnvironmentVariable.API_LAYER_URL)
        return () => axios.post(`${url}/auth/forgotPassword`, { email })
    }

    public updateForgottenPassword(payload: UpdateForgottenPasswordPayload): () => AxiosPromise {
        const url = this.environment.get(EnvironmentVariable.API_LAYER_URL)
        const authorization = `Bearer ${this.authorizationService.getAuthToken()}`
        return () =>
            axios.post(
                `${url}/auth/updateForgottenPassword`,
                { ...payload },
                {
                    headers: {
                        authorization,
                    },
                }
            )
    }

    public getSuppliers = (): (() => AxiosPromise) => {
        const url = this.environment.get(EnvironmentVariable.API_LAYER_URL)
        const authorization = `Bearer ${this.authorizationService.getAuthToken()}`
        return () => {
            return axios.get(`${url}/suppliers/list/getSupplierList`, {
                headers: {
                    authorization,
                },
            })
        }
    }

    public getSupplier = (supplierId): (() => AxiosPromise) => {
        const url = this.environment.get(EnvironmentVariable.API_LAYER_URL)
        const authorization = `Bearer ${this.authorizationService.getAuthToken()}`
        return () => {
            return axios.get(`${url}/suppliers/${supplierId}`, {
                headers: {
                    authorization,
                },
            })
        }
    }

    public getSupplierTags = (): (() => AxiosPromise) => {
        const url = this.environment.get(EnvironmentVariable.API_LAYER_URL)
        const authorization = `Bearer ${this.authorizationService.getAuthToken()}`
        return () => {
            return axios.get(`${url}/tags/list`, {
                headers: {
                    authorization,
                },
            })
        }
    }
}
