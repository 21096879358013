import React from 'react'
import { Typography } from '@material-ui/core'
import { dappTheme } from '../../../themes/dapp'

const errorStyles: any = {
    textAlign: 'left',
    color: dappTheme.palette.error.light,
}

const descriptionStyles: any = {
    textAlign: 'left',
    color: dappTheme.palette.common.white,
}

export const Error = ({ error, trigger }: { error: string | undefined; trigger: boolean }) => {
    if (!trigger) {
        return null
    }

    return (
        <Typography gutterBottom variant="caption" style={errorStyles}>
            {error}
        </Typography>
    )
}

export const FormFieldFooter = ({ touched, error, description }: { touched: boolean; error: string; description?: string }) => {
    const showErrors = !!touched && !!error
    return (
        <React.Fragment>
            <Typography variant="caption" style={{ ...descriptionStyles, display: !showErrors ? 'block' : 'none' }}>
                {description}
            </Typography>
            <Typography variant="caption" style={{ ...errorStyles, visibility: showErrors ? 'visible' : 'hidden' }}>
                {error || '.'}
            </Typography>
        </React.Fragment>
    )
}
