import React, { FC } from "react";
import { Controller, UseControllerProps, useFormContext } from "react-hook-form";
import { NumberFormatField, NumberFormatFieldProps } from "@syncfab/machine";

export interface RHFNumberFormatFieldProps extends NumberFormatFieldProps {
    rules?: UseControllerProps['rules']
    RHFNumberFormatFieldOnChangeHandler?: Function
}

export const RHFNumberFormatField: FC<RHFNumberFormatFieldProps> =
    ({ name, label, rules, required = false, disabled = false, isTableCellInput = false, isReadOnly = false,
         thousandSeparator = true, decimalScale = 2, prefix = '', suffix = '',
         RHFNumberFormatFieldOnChangeHandler = null, onBlur = () => {} }: RHFNumberFormatFieldProps) => {

        const methods = useFormContext()

        return (
            <Controller
                name={name}
                control={methods.control}
                rules={rules}
                render={({
                     field: {onChange, value, ref: fieldRef},
                     fieldState: {error}
                }) => {
                    return (
                        <NumberFormatField
                            thousandSeparator={thousandSeparator}
                            prefix={prefix}
                            suffix={suffix}
                            decimalScale={decimalScale}
                            value={value}
                            name={name}
                            label={label}
                            required={required}
                            onChangeHandler={(value) => {
                                onChange(value)
                                if (RHFNumberFormatFieldOnChangeHandler) {
                                    RHFNumberFormatFieldOnChangeHandler(value)
                                }
                            }}
                            error={error}
                            disabled={disabled}
                            inputRef={fieldRef}
                            isTableCellInput={isTableCellInput}
                            isReadOnly={isReadOnly}
                            onBlur={onBlur}
                        />
                    )
                }}
            />
        )
}