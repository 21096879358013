import React from 'react'
import { WithNamespaces, withNamespaces } from 'react-i18next'
import { connect } from 'react-redux'
import { PUSH, Loader, Button } from '@syncfab/machine'
import { Dispatch } from 'redux'
import { CenteredAlignedContainer } from '../../../layout/Container'
import { State } from '../../../application/Store'
import { RouteComponentProps } from 'react-router'
import Helmet from 'react-helmet'
import { WithStyles, Paper, Divider, withStyles, TablePagination, Grid, Typography, Card, InputAdornment, MenuItem, Select, InputLabel, Checkbox, ListItemText, FormControl } from '@material-ui/core'
import EnhancedTableHead from '../../parts/components/EnhancedTableHead'
import TextField from '@material-ui/core/TextField'
import SearchOutlined from '@material-ui/icons/SearchOutlined'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableRow from '@material-ui/core/TableRow'
import Tooltip from '@material-ui/core/Tooltip'
import IconButton from '@material-ui/core/IconButton'
import { GET_SUPPLIERS } from '../../../components/supplier/Actions'
import RefreshIcon from '@material-ui/icons/Refresh'
//import ClearIcon from '@material-ui/icons/Clear'
import Drawer from '@material-ui/core/Drawer'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import FilledInput from '@material-ui/core/FilledInput'
import GetAppIcon from '@material-ui/icons/GetApp'
import ShareIcon from '@material-ui/icons/Share'
import { CSVLink } from 'react-csv'
import { Languages } from '../../../data/languages'
import { SHOW_FEEDBACK } from '@syncfab/machine'
import { getSupplierTag } from '../../../components/supplier/Selector'
import { Countries } from '../../../data/countries'

const styles = theme => ({
    detailcard: {
        width: '-webkit-fill-available',
        height: 'fit-content',
        margin: `${theme.spacing(3)}px 12px`,
        backgroundColor: theme.palette.common.white,
        //color: theme.palette.common.black,
        marginTop: '100px',
        marginLeft: '24px',
        marginRight: '24px',
    },
    noTokenID: {
        marginLeft: theme.spacing(3),
        'font-weight': 'bold',
    },
    marginTextRight: {
        marginRight: theme.spacing(2),
    },
    refresh: {
        color: '#40B4BF',
    },
    refreshIcon: {
        marginLeft: theme.spacing(1),
    },
    clearIcon: {
        marginLeft: theme.spacing(1),
    },
    error: {
        backgroundColor: theme.palette.error.light,
    },
    root: {
        color: theme.palette.text.secondary,
        background: '#ffffff',
        padding: theme.spacing(4),
        marginTop: theme.spacing(4),
        marginBottom: theme.spacing(4),
        minWidth: 300,
    },
    table: {
        minWidth: 650,
    },
    tableBody: {
        fontSize: 14,
    },
    spacer: {
        flex: '1 1 100%',
    },
    actions: {
        color: theme.palette.text.secondary,
        marginTop: '7px',
        marginRight: '10px',
    },
    title: {
        flex: '0 0 auto',
    },
    checkbox: {
        color: '#363636',
    },
    paper: {
        width: '90%',
        minWidth: 300,
        padding: theme.spacing(1),
        marginTop: theme.spacing(4),
        marginBottom: theme.spacing(4),
    },

    button: {
        margin: theme.spacing(1),
    },
    card: {
        //width: '100%',
        margin: `${theme.spacing(3)}px 12px`,
        // padding: theme.spacing.unit * 3,
        backgroundColor: theme.palette.common.white,
        // overflowX: 'auto',
    },
    header: {
        padding: `0px ${theme.spacing(1)}px`,
    },
    link: {
        // color: '#40B4BF',
        'text-decoration': 'none',
        cursor: 'pointer',
    },
    testLink: {
        cursor: 'pointer',
    },
    textField: {
        marginLeft: theme.spacing(3),
        marginRight: theme.spacing(1),
        width: 400,
    },
    row: {
        padding: '20px',
    },
    goBackButton: {
        margin: 0,
        padding: 0,
        'text-transform': 'none',
        color: theme.palette.primary.light,
        fontWeight: theme.typography.fontWeightRegular,
        'font-size': '12',
    },
    arrow: {
        color: theme.palette.common.white,
        marginRight: theme.spacing(1),
    },
    icon: {
        fontSize: 20,
        color: theme.palette.common.white,
        marginRight: theme.spacing(2),
    },
    iconVariant: {
        opacity: 0.9,
        marginRight: theme.spacing * 1,
    },
    message: {
        display: 'flex',
        alignItems: 'center',
    },
    btnclass: {
        color: theme.palette.common.white,
        marginLeft: theme.spacing(3),
        fontSize: 10,
        borderColor: theme.palette.common.white,
    },
    listItem: {
        display: 'flex',
        'flex-direction': 'column',
        alignItems: 'flex-start',
    },
    btnWrapper: {
        display: 'flex',
        alignItems: 'baseline',
        justifyContent: 'flex-end',
    },
    formControl: {
        'border-radius': '5px',
    },
    rootSelect: {
        color: '#000',
        padding: '9px 0 !important',
    },

    tableWrapper: {
        'overflow-x': 'auto',
    },
    clearBtn: {
        color: '#40B4BF',
        background: 'transparent',
        'box-shadow': 'none',
        minWidth: '100px',
        '&:hover': {
            background: 'transparent',
            'box-shadow': 'none',
        },
        '&:focus': {
            background: 'transparent',
            'box-shadow': 'none',
        },
    },
    downloadWrapper: {
        //'position': 'relative',
        width: '140px',
        display: 'flex',
        'justify-content': 'flex-start',
    },
    csvBtn: {
        background: 'transparent',
        width: '100%',
        height: '100%',
        left: 0,
        top: 0,
        padding: '10px 0 0 35px',
        color: '#40B4BF',
        'text-decoration': 'none',
    },
})

// const productPreferences = [
//     { label: 'Angles & Channels', value: 'Angles & Channels' },
//     { label: 'Assemblies', value: 'Assemblies' },
//     { label: 'Automation & Electronics', value: 'Automation & Electronics' },
//     { label: 'Bars & Rods', value: 'Bars & Rods' },
//     { label: 'Complex Machining', value: 'Complex Machining' },
//     { label: 'COTS', value: 'COTS' },
//     { label: 'Distributor', value: 'Distributor' },
//     { label: 'Electrical & Power Generation', value: 'Electrical & Power Generation' },
//     { label: 'Full Production', value: 'Full Production' },
//     { label: 'Hardware', value: 'Hardware' },
//     { label: 'Heavy Machining', value: 'Heavy Machining' },
//     { label: 'Instruments & Controls', value: 'Instruments & Controls' },
//     { label: 'Large Format Parts', value: 'Large Format Parts' },
//     { label: 'Limited Production', value: 'Limited Production' },
//     { label: 'Machinery & Tools', value: 'Machinery & Tools' },
//     { label: 'Molds & Dies', value: 'Molds & Dies' },
//     { label: 'Plant & Facility Equipment', value: 'Plant & Facility Equipment' },
//     { label: 'Plates', value: 'Plates' },
//     { label: 'Process Equipment', value: 'Process Equipment' },
//     { label: 'Prototyping', value: 'Prototyping' },
//     { label: 'Pumps, Valves & Accessories', value: 'Pumps, Valves & Accessories' },
//     { label: 'Raw Materials', value: 'Raw Materials' },
//     { label: 'Scrap & Secondary', value: 'Scrap & Secondary' },
//     { label: 'Shapes', value: 'Shapes' },
//     { label: 'Sheets', value: 'Sheets' },
//     { label: 'Small Precision Parts', value: 'Small Precision Parts' },
//     { label: 'Wire', value: 'Wire' },
//     { label: 'Tubing & Piping', value: 'Tubing & Piping' },
//     { label: 'None', value: 'None' },
//     { label: 'Other', value: 'Other' },
// ]

interface SupplierTagType {
    label: string
    value: string
}

interface SupplierProps extends WithNamespaces, WithStyles, RouteComponentProps {
    isLoading: boolean
    history: any
    suppliers: any
    showSupplierTab?: boolean
    getSuppliers: () => void
    viewSupplier: (id: string) => void
    showCopyMessage: (msg) => void
    supplierTags: {
        manufacturing: SupplierTagType[]
        finishing: SupplierTagType[]
        productPreferences: SupplierTagType[]
        materialPreferences: SupplierTagType[]
    }
}

interface SupplierState {
    //selected: any[]
    columns: any[]
    order: string
    orderBy: string
    filteredData: any[]
    page: number
    rowsPerPage: number
    anchorEl: any
    searchTerm: string
    selectedCountry: string
    selectedState: string
    filtersApplied: boolean
    selectedCapabilities: any[]
    right: false
    labelWidth: any
    selectedFinishingCapabilities: any[]
    selectedProductPref: any[]
    selectedMaterialPref: any[]
    selectedDocumentation: any[]
    selectedStateCantonLanguages: any[]
    selectedCompanyLanguages: any[]
    states: any[]
    csvData: any[]
}
class SupplierListView extends React.Component<SupplierProps, SupplierState> {
    InputLabelRef: any
    constructor(props: SupplierProps) {
        super(props)
        this.state = {
            //selected: [],
            columns: [
                { id: 0, label: 'Manufacturing Capabilities' },
                { id: 1, label: 'Finish Capabilities' },
                { id: 2, label: 'Certifications' },
                { id: 3, label: 'Company Name' },
                { id: 4, label: 'Address' },
                { id: 5, label: 'City' },
                { id: 6, label: 'State/Canton' },
                { id: 7, label: 'Country' },
                { id: 8, label: 'State/Canton Languages' },
                { id: 9, label: 'Company Languages' },
                // { id: 3, label: 'Contact Name' },
                // { id: 4, label: 'Email' },
            ],
            order: 'asc',
            orderBy: 'companyName',
            filteredData: this.props.suppliers ? this.props.suppliers : [],
            page: 0,
            rowsPerPage: 10,
            anchorEl: null,
            searchTerm: '',
            selectedCountry: 'Switzerland',
            filtersApplied: false,
            selectedCapabilities: [],
            right: false,
            labelWidth: 0,
            selectedState: '',
            selectedFinishingCapabilities: [],
            selectedProductPref: [],
            selectedMaterialPref: [],
            selectedDocumentation: [],
            selectedStateCantonLanguages: [],
            selectedCompanyLanguages: [],
            states: [],
            csvData: [],
        }
        this.props.getSuppliers()
        this.refresh = this.refresh.bind(this)
    }

    onlyUnique = (value, index, self) => {
        return self.indexOf(value) === index
    }
    refresh() {
        this.props.getSuppliers()
        this.setState({
            selectedCapabilities: [],
            filteredData: [],
            selectedCountry: 'Switzerland',
            selectedState: '',
            selectedFinishingCapabilities: [],
            selectedProductPref: [],
            selectedMaterialPref: [],
            selectedDocumentation: [],
            states: [],
            searchTerm: '',
        })
        this.props.history.push('/suppliers')
    }

    clearFilters = () => {
        this.setState({
            filteredData: this.props.suppliers,
            page: 0,
            selectedCapabilities: [],
            searchTerm: '',
            selectedCountry: 'Switzerland',
            selectedState: '',
            selectedFinishingCapabilities: [],
            selectedProductPref: [],
            selectedMaterialPref: [],
            selectedDocumentation: [],
            states: [],
        })
        this.props.history.push('/suppliers')
    }

    handleRequestSort = (_event, property) => {
        const fieldnames = ['manufacturingProcesses', 'finishes', 'certifications', 'companyName', 'language']
        const orderBy = fieldnames[property]

        let order = 'desc'

        if (this.state.orderBy === orderBy && this.state.order === 'desc') {
            order = 'asc'
        }

        this.setState({ order, orderBy })
    }
    handleSearch = event => {
        this.setState({ searchTerm: event })
        let filtered = []
        if (event.length > 0) {
            let search = event.toLowerCase()
            this.props.suppliers.forEach(supplier => {
                if (
                    supplier.firstName.toLowerCase().includes(search) ||
                    supplier.lastName.toLowerCase().includes(search) ||
                    supplier.companyName.toLowerCase().includes(search) ||
                    supplier.email.toLowerCase().includes(search) ||
                    (supplier.location &&
                        (supplier.location.line.toLowerCase().includes(search) ||
                            supplier.location.city.toLowerCase().includes(search) ||
                            supplier.location.state.toLowerCase().includes(search) ||
                            supplier.location.country.toLowerCase().includes(search)))
                ) {
                    filtered.push(supplier)
                }
                if (supplier.manufacturingProcesses && supplier.manufacturingProcesses.length > 0) {
                    let find = supplier.manufacturingProcesses.map(item => item.toLowerCase()).find(x => x.includes(search.replace(/\s/g, '_')))
                    if (find) filtered.push(supplier)
                }
                if (supplier.finishes && supplier.finishes.length > 0) {
                    let find = supplier.finishes.map(item => item.toLowerCase()).find(x => x.includes(search.replace(/\s/g, '_')))
                    if (find) filtered.push(supplier)
                }
                if (supplier.certifications && supplier.certifications.length > 0) {
                    let find = supplier.certifications.map(item => item.toLowerCase()).find(x => x.includes(search.replace(/\s/g, '_')))
                    if (find) filtered.push(supplier)
                }
                if (supplier.preferredLanguage || supplier.language) {
                    let findLang = Languages.map(lang => ({ code: lang.code, name: lang.name.toLowerCase() })).find(x => x.name.includes(search))
                    if (findLang) {
                        let findPreferredLang = supplier.preferredLanguage && supplier.preferredLanguage.find(x => x.includes(findLang.code))
                        if (findPreferredLang) filtered.push(supplier)

                        let findCompLang = supplier.language && supplier.language.find(x => x.includes(findLang.code))
                        if (findCompLang) filtered.push(supplier)
                    }
                }
            })
            filtered = filtered.filter(this.onlyUnique)
            this.setState({ filteredData: filtered, page: 0 }, this.applyFilters)
        } else {
            this.setState({ filteredData: this.props.suppliers, page: 0 }, this.applyFilters)
        }

        if (!this.props.location.search && event.length == 0) {
            this.setState({ filteredData: this.props.suppliers, page: 0 })
        }
    }

    handleSelection = event => {
        let value = event.target.value
        this.setState({ selectedCapabilities: value })

        // let filtered = []

        // status.forEach(element => {
        //     let singleFiltered = this.props.suppliers.filter(it => it.manufacturingProcesses.includes(element))
        //     singleFiltered.forEach(singleElement => filtered.push(singleElement))
        // })
        // filtered = status.length > 0 ? filtered : this.props.suppliers

        // filtered = filtered.filter(this.onlyUnique)
        // filtered = this.state.searchTerm
        //     ? filtered.filter(
        //           it =>
        //               new RegExp(this.state.searchTerm, 'i').test(it.firstName) ||
        //               new RegExp(this.state.searchTerm, 'i').test(it.lastName) ||
        //               new RegExp(this.state.searchTerm, 'i').test(it.companyName) ||
        //               new RegExp(this.state.searchTerm, 'i').test(it.email) ||
        //               new RegExp(this.state.searchTerm, 'i').test(it.location.country)
        //       )
        //     : filtered

        // filtered = filtered.filter(this.onlyUnique)
        //this.setState({ filteredData: filtered, page: 0, anchorEl: null })

        //let country = this.state.selectedCountry ? `&country=${this.state.selectedCountry}` : ''
    }
    getUrlParams(manufacturingCapabilities, finishingCapabilities, productPref, materialPref, documentation, companyLanguages, stateCantonLanguages) {
        let _manufacturingCapabilities = manufacturingCapabilities && manufacturingCapabilities.length > 0 ? `&manufacturingCapabilities=${manufacturingCapabilities}` : ''
        let _finishingCapabilities = finishingCapabilities && finishingCapabilities.length > 0 ? `&finishingCapabilities=${finishingCapabilities}` : ''
        let _productPref = productPref && productPref.length > 0 ? `&productPreferences=${productPref}` : ''
        let _materialPref = materialPref && materialPref.length > 0 ? `&materialPreferences=${materialPref}` : ''
        let _documentation = documentation && documentation.length > 0 ? `&documentation=${documentation}` : ''
        let _country = this.state.selectedCountry ? `&country=${this.state.selectedCountry}` : 'Switzerland'
        let _state = this.state.selectedState ? `&state=${this.state.selectedState}` : ''
        let _coLangs = companyLanguages && companyLanguages.length > 0 ? `&coLangs=${companyLanguages}` : ''
        let _scLangs = stateCantonLanguages && stateCantonLanguages.length > 0 ? `&scLangs=${stateCantonLanguages}` : ''
        return `?${_country}${_state}${_manufacturingCapabilities}${_finishingCapabilities}${_productPref}${_materialPref}${_documentation}${_coLangs}${_scLangs}`
    }
    handleFinishingCapabilities = event => {
        let value = event.target.value
        this.setState({ selectedFinishingCapabilities: value })
    }
    handleProductPref = event => {
        let value = event.target.value
        this.setState({ selectedProductPref: value })
    }
    handleMaterialPref = event => {
        let value = event.target.value
        this.setState({ selectedMaterialPref: value })
    }
    handleDocumentation = event => {
        let value = event.target.value
        this.setState({ selectedDocumentation: value })
    }

    handleStateCantonLanguages = event => {
        let value = event.target.value
        this.setState({ selectedStateCantonLanguages: value })
    }

    handleCompanyLanguages = event => {
        let value = event.target.value
        this.setState({ selectedCompanyLanguages: value })
    }

    desc = (a, b, orderBy) => {
        if (b[orderBy] < a[orderBy]) {
            return -1
        }
        if (b[orderBy] > a[orderBy]) {
            return 1
        }
        return 0
    }
    tableSort = (array, cmp) => {
        const stabilizedThis = array.map((el, index) => [el, index])
        stabilizedThis.sort((a, b) => {
            const order = cmp(a[0], b[0])
            if (order !== 0) return order
            return a[1] - b[1]
        })
        return stabilizedThis.map(el => el[0])
    }
    getSorting = (order, orderBy) => {
        return order === 'desc' ? (a, b) => this.desc(a, b, orderBy) : (a, b) => -this.desc(a, b, orderBy)
    }
    handleChangePage = (event, page) => {
        console.log(event)
        this.setState({ page })
    }
    handleChangeRowsPerPage = event => {
        this.setState({ rowsPerPage: event.target.value, page: 0 })
    }
    handleMenu = event => {
        this.setState({ anchorEl: event.currentTarget })
    }
    handleClose = () => {
        this.setState({ anchorEl: null })
    }
    componentDidUpdate(oldProps) {
        if (this.props.suppliers !== oldProps.suppliers) {
            this.setState({ filteredData: this.props.suppliers })
            this.getParams()
        }
        try {
            if (Countries && (!this.state.states || this.state.states.length == 0)) {
                const queryParams = new URLSearchParams(window.location.search)
                const country = queryParams.get('country') || this.state.selectedCountry
                if (country) {
                    const selectedCountry = Countries.find(x => x.name == country)
                    if (selectedCountry && selectedCountry.states) this.setState({ states: selectedCountry.states })
                }
            }
            if (this.state.csvData.length == 0) this.buildCSV()
        } catch (error) {
            console.log(error)
        }
        try {
            if (this.props.suppliers && this.state.filteredData.length === 0 && !this.state.filtersApplied && !this.state.searchTerm) {
                this.setState({ filteredData: this.props.suppliers })
            }
        } catch (error) {
            console.log(error)
        }
    }
    addFilters = () => {
        this.props.history.push({
            pathname: '/suppliers',
            search: this.getUrlParams(
                this.state.selectedCapabilities.length > 0 ? this.state.selectedCapabilities.map(item => item.replace(' & ', ' _ ')).join() : '',
                this.state.selectedFinishingCapabilities.length > 0 ? this.state.selectedFinishingCapabilities.map(item => item.replace(' & ', ' _ ')).join() : '',
                this.state.selectedProductPref.length > 0 ? this.state.selectedProductPref.map(item => item.replace(' & ', ' _ ')).join() : '',
                this.state.selectedMaterialPref.length > 0 ? this.state.selectedMaterialPref.map(item => item.replace(' & ', ' _ ')).join() : '',
                this.state.selectedDocumentation.join(),
                this.state.selectedCompanyLanguages.length > 0 ? this.state.selectedCompanyLanguages.map(item => item.replace(' & ', ' _ ')).join() : '',
                this.state.selectedStateCantonLanguages.length > 0 ? this.state.selectedStateCantonLanguages.map(item => item.replace(' & ', ' _ ')).join() : ''
            ),
        })
        this.getParams()
    }
    getParams() {
        const queryParams = new URLSearchParams(window.location.search)
        const capabilities = queryParams.get('manufacturingCapabilities') && queryParams.get('manufacturingCapabilities').replace(' _ ', ' & ')
        const finishingCapabilities = queryParams.get('finishingCapabilities') && queryParams.get('finishingCapabilities').replace(' _ ', ' & ')
        const productPreferences = queryParams.get('productPreferences') && queryParams.get('productPreferences').replace(' _ ', ' & ')
        const materialPreferences = queryParams.get('materialPreferences') && queryParams.get('materialPreferences').replace(' _ ', ' & ')
        const documentation = queryParams.get('documentation')
        const country = queryParams.get('country')
        const state = queryParams.get('state')
        const stateCantonLangs = queryParams.get('scLangs') && queryParams.get('scLangs').replace(' _ ', ' & ')
        const companyLangs = queryParams.get('coLangs') && queryParams.get('coLangs').replace(' _ ', ' & ')

        this.setState(
            {
                selectedCapabilities: capabilities ? capabilities.split(',') : [],
                selectedFinishingCapabilities: finishingCapabilities ? finishingCapabilities.split(',') : [],
                selectedProductPref: productPreferences ? productPreferences.split(',') : [],
                selectedMaterialPref: materialPreferences ? materialPreferences.split(',') : [],
                selectedDocumentation: documentation ? documentation.split(',') : [],
                selectedCountry: country ? country : 'Switzerland',
                selectedState: state ? state : '',
                selectedCompanyLanguages: companyLangs ? companyLangs.split(',') : [],
                selectedStateCantonLanguages: stateCantonLangs ? stateCantonLangs.split(',') : [],
            },
            () => {
                if (country || state || capabilities || finishingCapabilities || productPreferences || materialPreferences || documentation || stateCantonLangs || companyLangs)
                    this.handleSearch(this.state.searchTerm)
            }
        )
    }
    applyFilters = () => {
        this.setState({ filtersApplied: true })
        let filtered = this.state.searchTerm ? this.state.filteredData : this.props.suppliers

        const country = this.state.selectedCountry
        const state = this.state.selectedState
        const selectedDocumentation = this.state.selectedDocumentation

        if (country) {
            filtered = filtered.filter(supplier => {
                return supplier.location && supplier.location.country ? supplier.location.country === country : null
            })
        }
        if (state) {
            filtered = filtered.filter(supplier => {
                return supplier.location && supplier.location.state ? supplier.location.state === state : null
            })
        }

        const filters = [
            'selectedCapabilities',
            'selectedFinishingCapabilities',
            'selectedProductPref',
            'selectedMaterialPref',
            'selectedDocumentation',
            'selectedStateCantonLanguages',
            'selectedCompanyLanguages',
        ]

        filters.forEach(item => {
            if (this.state[item] && this.state[item].length > 0) {
                this.state[item]
                    .map(item => item.replace(' _ ', ' & '))
                    .forEach(x => {
                        let filteredValue = filtered.filter(supplier => {
                            if (item == 'selectedCapabilities') return supplier.manufacturingProcesses && supplier.manufacturingProcesses.includes(x)
                            if (item == 'selectedFinishingCapabilities') return supplier.finishes && supplier.finishes.includes(x)
                            if (item == 'selectedProductPref') return supplier.productPreference && supplier.productPreference.includes(x)
                            if (item == 'selectedMaterialPref') return supplier.materials && supplier.materials.includes(x)
                            if (item == 'selectedStateCantonLanguages') return supplier.preferredLanguage && supplier.preferredLanguage.includes(x)
                            if (item == 'selectedCompanyLanguages') return supplier.language && supplier.language.includes(x)
                        })

                        filtered = filteredValue
                    })
            }
        })

        filtered = filtered.filter(this.onlyUnique)

        if (selectedDocumentation.length && selectedDocumentation[0] === 'Yes') {
            filtered = filtered.filter(supplier => {
                return supplier.capacityDocument.length > 0
            })
        }
        if (selectedDocumentation.length && selectedDocumentation[0] === 'No') {
            filtered = filtered.filter(supplier => {
                return supplier.capacityDocument.length === 0
            })
        }

        this.setState({ filteredData: filtered, right: false }, this.buildCSV)
    }
    onChangesCountry = (country: string) => {
        this.setState({ selectedCountry: country }, this.addFilters)
        if (country) {
            const selectedCountry = Countries.find(x => x.name == country)
            if (selectedCountry && selectedCountry.states) this.setState({ states: selectedCountry.states })
        }
    }
    onChangesState = (state: string) => {
        this.setState({ selectedState: state }, this.addFilters)
    }
    toggleDrawer = open => () => {
        this.setState({ right: open })
    }

    componentDidMount() {
        this.setState({
            labelWidth: '100%',
        })
    }
    copy = () => {
        const el = document.createElement('input')
        el.value = window.location.href
        document.body.appendChild(el)
        el.select()
        document.execCommand('copy')
        document.body.removeChild(el)
        this.props.showCopyMessage(`Copied to Clipboard`)
    }
    buildCSV() {
        if (this.state.filteredData && this.state.filteredData.length > 0) {
            let csvData = []
            csvData.push([
                'Manufacturing Capabilities',
                'Finish Capabilities',
                'Certifications',
                'Company Name',
                'Address',
                'City',
                'State/Canton',
                'Country',
                'State/Canton Languages',
                'Company Languages',
            ])
            this.state.filteredData.forEach(supplier => {
                csvData.push([
                    supplier.manufacturingProcesses ? supplier.manufacturingProcesses.join(',') : '',
                    supplier.finishes ? supplier.finishes.join(',') : '',
                    supplier.certifications ? supplier.certifications.join(',') : '',
                    supplier.companyName,
                    supplier.location && supplier.location.line ? supplier.location.line : '',
                    supplier.location && supplier.location.city ? supplier.location.city : '',
                    supplier.location && supplier.location.state ? supplier.location.state : '',
                    supplier.location && supplier.location.country ? supplier.location.country : '',
                    '',
                    supplier.language,
                ])
            })
            this.setState({ csvData: csvData })
        }
    }

    render() {
        const { classes, isLoading, t, showSupplierTab, viewSupplier, suppliers, supplierTags } = this.props
        const {
            filteredData,
            columns,
            order,
            orderBy,
            page,
            rowsPerPage,
            searchTerm,
            selectedCountry,
            selectedDocumentation,
            selectedState,
            selectedFinishingCapabilities,
            selectedProductPref,
            selectedCapabilities,
            selectedMaterialPref,
            selectedStateCantonLanguages,
            selectedCompanyLanguages,
            states,
        } = this.state
        const emptyRows = rowsPerPage - Math.min(rowsPerPage, filteredData.length - page * rowsPerPage)
        if (isLoading) {
            return <Loader />
        }

        if (!showSupplierTab) {
            return (
                <Grid container>
                    <Card className={classes.detailcard} style={{ marginLeft: '24px', marginRight: '24px', textAlign: 'center' }}>
                        <Grid className={classes.card}>
                            <Typography color="textPrimary" variant="h6">
                                No record found
                            </Typography>
                        </Grid>
                    </Card>
                </Grid>
            )
        }

        const sideList = (
            <div style={{ width: '300px' }}>
                <List>
                    <ListItem style={{ borderBottom: '1px solid #45678a' }} className={classes.listItem}>
                        <Typography style={{ color: '#fff' }} variant="subtitle1">
                            FILTERS
                        </Typography>
                    </ListItem>
                    <ListItem className={classes.listItem}>
                        <FormControl fullWidth variant="filled" className={classes.formControl}>
                            <InputLabel htmlFor="select-multiple-checkbox">Manufacturing Capabilities</InputLabel>
                            <Select
                                multiple
                                value={selectedCapabilities}
                                onChange={e => this.handleSelection(e)}
                                input={<FilledInput name="selectedCapabilities" id="select-multiple-checkbox" />}
                                renderValue={(selected: any) => {
                                    let combined = selected.map(ele => {
                                        return t(
                                            `${ele.charAt(0)}${ele
                                                .slice(1)
                                                .toLowerCase()
                                                .replace(/_/g, ' ')}`
                                        );
                                    })

                                    return combined.join(', ')
                                }}
                            >
                                {/* <MenuItem value="">Capabilities</MenuItem> */}
                                {supplierTags.manufacturing.map(status => (
                                    <MenuItem key={status.value} value={status.value}>
                                        <Checkbox
                                            color="primary"
                                            inputProps={{
                                                'aria-label': 'primary checkbox',
                                            }}
                                            classes={{
                                                root: classes.checkbox,
                                            }}
                                            checked={this.state.selectedCapabilities.includes(status.value)}
                                        />
                                        <ListItemText primary={status && status.label ? status.label : ''} />
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </ListItem>
                    <ListItem className={classes.listItem}>
                        <FormControl fullWidth variant="filled" className={classes.formControl}>
                            <InputLabel htmlFor="select-multiple-checkbox">Finishing Capabilities</InputLabel>
                            <Select
                                multiple
                                value={selectedFinishingCapabilities}
                                onChange={e => this.handleFinishingCapabilities(e)}
                                input={<FilledInput name="selectedFinishingCapabilities" id="select-multiple-checkbox" />}
                                renderValue={(selected: any) => {
                                    let combined = selected.map(ele => {
                                        return t(
                                            `${ele.charAt(0)}${ele
                                                .slice(1)
                                                .toLowerCase()
                                                .replace(/_/g, ' ')}`
                                        );
                                    })

                                    return combined.join(', ')
                                }}
                            >
                                {/* <MenuItem value="">Capabilities</MenuItem> */}
                                {supplierTags.finishing.map(status => (
                                    <MenuItem key={status.value} value={status.value}>
                                        <Checkbox
                                            color="primary"
                                            inputProps={{
                                                'aria-label': 'primary checkbox',
                                            }}
                                            classes={{
                                                root: classes.checkbox,
                                            }}
                                            checked={selectedFinishingCapabilities.includes(status.value)}
                                        />
                                        <ListItemText
                                            primary={
                                                status && status.value
                                                    ? t(
                                                          `${status.value.charAt(0)}${status.value
                                                              .slice(1)
                                                              .toLowerCase()
                                                              .replace(/_/g, ' ')}`
                                                      ) as string
                                                    : ''
                                            }
                                        />
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </ListItem>
                    <ListItem className={classes.listItem}>
                        <FormControl fullWidth variant="filled" className={classes.formControl}>
                            <InputLabel htmlFor="select-multiple-checkbox">Product Preferences</InputLabel>
                            <Select
                                multiple
                                value={selectedProductPref}
                                onChange={e => this.handleProductPref(e)}
                                input={<FilledInput name="selectedProductPref" id="select-multiple-checkbox" />}
                                renderValue={(selected: any) => {
                                    let combined = selected.map(ele => {
                                        return t(
                                            `${ele.charAt(0)}${ele
                                                .slice(1)
                                                .toLowerCase()
                                                .replace(/_/g, ' ')}`
                                        );
                                    })

                                    return combined.join(', ')
                                }}
                            >
                                {/* <MenuItem value="">Capabilities</MenuItem> */}
                                {supplierTags.productPreferences.map((status, i) => (
                                    <MenuItem key={i} value={status.value}>
                                        <Checkbox
                                            color="primary"
                                            inputProps={{
                                                'aria-label': 'primary checkbox',
                                            }}
                                            classes={{
                                                root: classes.checkbox,
                                            }}
                                            checked={selectedProductPref.includes(status.value)}
                                        />
                                        <ListItemText primary={status && status.label ? t(`${status.label}`) as string : ''} />
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </ListItem>
                    <ListItem className={classes.listItem}>
                        <FormControl fullWidth variant="filled" className={classes.formControl}>
                            <InputLabel htmlFor="select-multiple-checkbox">Material Preferences</InputLabel>
                            <Select
                                multiple
                                value={selectedMaterialPref}
                                onChange={e => this.handleMaterialPref(e)}
                                input={<FilledInput name="selectedMaterialPref" id="select-multiple-checkbox" />}
                                renderValue={(selected: any) => {
                                    let combined = selected.map(ele => {
                                        return t(
                                            `${ele.charAt(0)}${ele
                                                .slice(1)
                                                .toLowerCase()
                                                .replace(/_/g, ' ')}`
                                        );
                                    })

                                    return combined.join(', ')
                                }}
                            >
                                {/* <MenuItem value="">Capabilities</MenuItem> */}
                                {supplierTags.materialPreferences.map(status => (
                                    <MenuItem key={status.value} value={status.value}>
                                        <Checkbox
                                            color="primary"
                                            inputProps={{
                                                'aria-label': 'primary checkbox',
                                            }}
                                            classes={{
                                                root: classes.checkbox,
                                            }}
                                            checked={this.state.selectedMaterialPref.includes(status.value)}
                                        />
                                        <ListItemText
                                            primary={t(
                                                `${status.value.charAt(0)}${status.value
                                                    .slice(1)
                                                    .toLowerCase()
                                                    .replace(/_/g, ' ')}`
                                            ) as string}
                                        />
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </ListItem>
                    <ListItem className={classes.listItem}>
                        <FormControl fullWidth variant="filled" className={classes.formControl}>
                            <InputLabel htmlFor="select-multiple-checkbox">Documentation</InputLabel>
                            <Select
                                multiple
                                value={selectedDocumentation}
                                onChange={e => this.handleDocumentation(e)}
                                input={<FilledInput name="selectedDocumentation" id="select-multiple-checkbox" />}
                                renderValue={(selected: any) => {
                                    let combined = selected.map(ele => {
                                        return t(`${ele}`)
                                    })

                                    return combined.join(', ')
                                }}
                            >
                                {/* <MenuItem value="">Capabilities</MenuItem> */}
                                <MenuItem value="Yes">Yes</MenuItem>
                                <MenuItem value="No">No</MenuItem>
                            </Select>
                        </FormControl>
                    </ListItem>
                    <ListItem className={classes.listItem}>
                        <FormControl fullWidth variant="filled" className={classes.formControl}>
                            <InputLabel htmlFor="select-multiple-checkbox">State/Canton Languages</InputLabel>
                            <Select
                                multiple
                                value={selectedStateCantonLanguages}
                                onChange={e => this.handleStateCantonLanguages(e)}
                                input={<FilledInput name="selectedStateCantonLanguages" id="select-multiple-checkbox" />}
                                renderValue={(selected: any) => {
                                    let combined = selected.map(ele => {
                                        return Languages.filter(lg => lg.code === ele)[0].name
                                    })

                                    return combined.join(', ')
                                }}
                            >
                                {Languages.map((status: any, i) => (
                                    <MenuItem key={i} value={status.code}>
                                        <Checkbox
                                            color="primary"
                                            inputProps={{
                                                'aria-label': 'primary checkbox',
                                            }}
                                            classes={{
                                                root: classes.checkbox,
                                            }}
                                            checked={selectedStateCantonLanguages.includes(status.code)}
                                        />
                                        <ListItemText primary={`${t(status.name)}`} />
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </ListItem>
                    <ListItem className={classes.listItem}>
                        <FormControl fullWidth variant="filled" className={classes.formControl}>
                            <InputLabel htmlFor="select-multiple-checkbox">Company Languages</InputLabel>
                            <Select
                                multiple
                                value={selectedCompanyLanguages}
                                onChange={e => this.handleCompanyLanguages(e)}
                                input={<FilledInput name="selectedCompanyLanguages" id="select-multiple-checkbox" />}
                                renderValue={(selected: any) => {
                                    let combined = selected.map(ele => {
                                        return Languages.filter(lg => lg.code === ele)[0].name
                                    })

                                    return combined.join(', ')
                                }}
                            >
                                {Languages.map((status: any, i) => (
                                    <MenuItem key={i} value={status.code}>
                                        <Checkbox
                                            color="primary"
                                            inputProps={{
                                                'aria-label': 'primary checkbox',
                                            }}
                                            classes={{
                                                root: classes.checkbox,
                                            }}
                                            checked={selectedCompanyLanguages.includes(status.code)}
                                        />
                                        <ListItemText primary={`${t(status.name)}`} />
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </ListItem>
                    <ListItem>
                        <Button onClick={this.addFilters} color="primary">
                            Apply Filter
                        </Button>
                    </ListItem>
                </List>
            </div>
        )

        return (
            <CenteredAlignedContainer>
                <Helmet>
                    <title>{'Suppliers'}</title>
                </Helmet>
                <Paper className={classes.paper} elevation={1}>
                    <Grid container justify="space-between" alignItems="center" className={classes.header}>
                        <Grid item>
                            <Typography variant="h2" style={{ margin: '15px 0 24px', letterSpacing: '0.5px', fontWeight: 'normal' }}>
                                Suppliers
                            </Typography>
                        </Grid>
                        <Grid item style={{ display: 'flex' }}>
                            <Button className={classes.downloadWrapper} style={{ position: 'relative' }} color="primary" variant="outlined">
                                <GetAppIcon />{' '}
                                <CSVLink
                                    style={{ position: 'absolute' }}
                                    className={classes.csvBtn}
                                    data={this.state.csvData}
                                    filename={`suppliers_${new Date().toLocaleDateString()}_${new Date().toLocaleTimeString()}.csv`}
                                >
                                    DOWNLOAD
                                </CSVLink>
                            </Button>
                            <Button onClick={this.copy} style={{ marginLeft: '15px' }} color="primary" variant="outlined">
                                <ShareIcon /> <Typography style={{ marginLeft: '5px', color: '#40B4BF' }}> {` SHARE`}</Typography>
                            </Button>
                        </Grid>
                    </Grid>
                    <Divider />

                    <Card className={classes.card}>
                        <Grid spacing={3} container direction="row" alignItems="center" justify="flex-start" className={classes.row}>
                            <Grid item md={4}>
                                <TextField
                                    style={{ width: '100%' }}
                                    id="filled-adornment-password"
                                    variant="filled"
                                    label="Search..."
                                    onChange={e => this.handleSearch(e.target.value)}
                                    value={searchTerm}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="start">
                                                <SearchOutlined />
                                            </InputAdornment>
                                        ),
                                    }}
                                />
                            </Grid>
                            <Grid item md={8} className={classes.btnWrapper} style={{ justifyContent: 'space-between' }}>
                                <FormControl style={{ borderBottom: '1px solid #9c9999', marginRight: '10px' }} fullWidth>
                                    <Select
                                        value={selectedCountry}
                                        onChange={(e: any) => {
                                            this.onChangesCountry(e.target.value)
                                        }}
                                        displayEmpty
                                        name="selectedCountry"
                                        className={classes.selectEmpty}
                                        variant="outlined"
                                        classes={{ root: classes.rootSelect }}
                                    >
                                        <MenuItem value="">Country</MenuItem>

                                        <MenuItem value={`Switzerland`} key={`Switzerland`}>
                                            {`Switzerland`}
                                        </MenuItem>
                                    </Select>
                                </FormControl>
                                <FormControl style={{ borderBottom: '1px solid #9c9999' }} fullWidth>
                                    <Select
                                        value={selectedState}
                                        onChange={(e: any) => {
                                            this.onChangesState(e.target.value)
                                        }}
                                        displayEmpty
                                        name="selectedState"
                                        className={classes.selectEmpty}
                                        variant="outlined"
                                        classes={{ root: classes.rootSelect }}
                                    >
                                        <MenuItem value="">State</MenuItem>
                                        {states &&
                                            states.map((item: any) => {
                                                return (
                                                    <MenuItem value={item.state_code} key={item.name}>
                                                        {item.name}
                                                    </MenuItem>
                                                )
                                            })}
                                    </Select>
                                </FormControl>
                                <div className={classes.actions}>
                                    <Tooltip title={`${t('refresh')}`}>
                                        <IconButton aria-label={t('refresh')} onClick={this.refresh}>
                                            <RefreshIcon />
                                        </IconButton>
                                    </Tooltip>
                                </div>

                                <React.Fragment>
                                    <Button style={{ minWidth: '130px' }} onClick={this.toggleDrawer(true)} color="primary">
                                        More Filters
                                    </Button>
                                    <Drawer anchor="right" open={this.state.right} onClose={this.toggleDrawer(false)}>
                                        <div>{sideList}</div>
                                    </Drawer>
                                    {this.state.filteredData.length !== this.props.suppliers.length && (
                                        // <Tooltip title={t('clear')}>
                                        //     <IconButton aria-label={t('clear')} onClick={this.clearFilters}>
                                        //         <ClearIcon />
                                        //     </IconButton>
                                        // </Tooltip>
                                        <Button className={classes.clearBtn} onClick={this.clearFilters}>
                                            CLEAR ALL
                                        </Button>
                                    )}
                                </React.Fragment>
                            </Grid>
                        </Grid>
                        <div className={classes.tableWrapper}>
                            <Table className={classes.table}>
                                <EnhancedTableHead
                                    columns={columns}
                                    order={order}
                                    orderBy={orderBy}
                                    //onSelectAllClick={this.handleSelectAllClick}
                                    onRequestSort={this.handleRequestSort}
                                    count={suppliers.length}
                                    page={'suppliers'}
                                />
                                <TableBody className={classes.tableBody}>
                                    {this.tableSort(filteredData, this.getSorting(order, orderBy))
                                        .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                        .map((row, index) => {
                                            //const isItemSelected = this.isSelected(row)
                                            const labelId = `enhanced-table-checkbox-${index}`

                                            return (
                                                <TableRow
                                                    hover
                                                    onClick={() => viewSupplier(row._id)}
                                                    role="checkbox"
                                                    //aria-checked={isItemSelected}
                                                    tabIndex={-1}
                                                    key={row._id}
                                                    //selected={isItemSelected}
                                                >
                                                    <TableCell style={{ minWidth: '270px' }} align="left" className={classes.tableBody}>
                                                        {row.manufacturingProcesses ? row.manufacturingProcesses.join(', ').replace(/_/g, ' ') : '-'}
                                                    </TableCell>
                                                    <TableCell style={{ minWidth: '270px' }} align="left" className={classes.tableBody}>
                                                        {row.finishes ? row.finishes.join(', ').replace(/_/g, ' ') : '-'}
                                                    </TableCell>
                                                    <TableCell align="left" className={classes.tableBody}>
                                                        {row.certifications ? row.certifications.join(', ') : '-'}
                                                    </TableCell>
                                                    <TableCell component="th" id={labelId} scope="row" align="left">
                                                        <a onClick={() => viewSupplier(row._id)} className={classes.link}>
                                                            {row.companyName}
                                                        </a>
                                                    </TableCell>
                                                    <TableCell align="left" className={classes.tableBody}>
                                                        {row.location && row.location.line ? row.location.line : '-'}
                                                    </TableCell>
                                                    <TableCell align="left" className={classes.tableBody}>
                                                        {row.location && row.location.city ? row.location.city : '-'}
                                                    </TableCell>
                                                    <TableCell align="left" className={classes.tableBody}>
                                                        {row.location && row.location.state ? row.location.state : '-'}
                                                    </TableCell>
                                                    <TableCell align="left" className={classes.tableBody}>
                                                        {row.location && row.location.country ? row.location.country : '-'}
                                                    </TableCell>
                                                    <TableCell align="left" className={classes.tableBody}>
                                                        {row.preferredLanguage
                                                            ? row.preferredLanguage
                                                                  .map(lng => {
                                                                      return Languages.filter(lg => lg.code === lng)[0].name
                                                                  })
                                                                  .join(',')
                                                            : '-'}
                                                    </TableCell>
                                                    <TableCell align="left" className={classes.tableBody}>
                                                        {row.language
                                                            ? row.language
                                                                  .map(lng => {
                                                                      return Languages.filter(lg => lg.code === lng)[0].name
                                                                  })
                                                                  .join(',')
                                                            : '-'}
                                                    </TableCell>
                                                    {/* <TableCell align="left" className={classes.tableBody}>
                                                        {row.firstName} {row.lastName}
                                                    </TableCell>
                                                    <TableCell align="left" className={classes.tableBody}>
                                                        {row.email}
                                                    </TableCell>
                                                    <TableCell align="left" className={classes.tableBody}>
                                                        {row.location.country}
                                                    </TableCell> */}
                                                </TableRow>
                                            );
                                        })}
                                    {emptyRows > 0 && (
                                        <TableRow style={{ height: 49 * emptyRows }}>
                                            <TableCell colSpan={6} />
                                        </TableRow>
                                    )}
                                </TableBody>
                            </Table>
                        </div>
                        <TablePagination
                            rowsPerPageOptions={[5, 10, 25]}
                            component="div"
                            count={filteredData.length}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            backIconButtonProps={{
                                'aria-label': 'Previous Page',
                            }}
                            nextIconButtonProps={{
                                'aria-label': 'Next Page',
                            }}
                            onPageChange={this.handleChangePage}
                            onRowsPerPageChange={this.handleChangeRowsPerPage}
                        />
                    </Card>
                </Paper>
            </CenteredAlignedContainer>
        );
    }
}

const SupplierListWithTranslations = withStyles(styles)(withNamespaces()(SupplierListView))

const mapStateToProps = (state: State) => {
    let suppliers =
        state && state.supplier && state.supplier.suppliers
            ? state.supplier.suppliers.filter(record => {
                  return record && record.location && record.location.country ? record.location.country === 'Switzerland' : null
              })
            : null

    const getManufacturingTags = getSupplierTag('MANUFACTURING')
    const getFinishingTags = getSupplierTag('FINISHING')
    const getProductPreferences = getSupplierTag('PRODUCT_PREFERENCES')
    const getMaterialPreferences = getSupplierTag('MATERIAL_PREFERENCES')

    return {
        isLoading: state.supplier.isLoading,
        suppliers: suppliers,
        showSupplierTab: state.supplier.showSupplierTab,
        supplierTags: {
            manufacturing: getManufacturingTags(state),
            finishing: getFinishingTags(state),
            productPreferences: getProductPreferences(state),
            materialPreferences: getMaterialPreferences(state),
        },
    }
}

const mapDispatchToProps = (dispatch: Dispatch) => {
    return {
        showCopyMessage: msg => dispatch(SHOW_FEEDBACK(msg)),
        push: (url: string) => dispatch(PUSH(url)),
        getSuppliers: () => dispatch(GET_SUPPLIERS.actions.TRIGGER()),
        viewSupplier: (id: string) => dispatch(PUSH(`/suppliers/${id}`)),
    }
}

export const Suppliers = connect(mapStateToProps, mapDispatchToProps)(SupplierListWithTranslations)
