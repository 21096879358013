import React from 'react'
import { Helmet } from 'react-helmet'
import { Map } from 'immutable'
import { withNamespaces, WithNamespaces } from 'react-i18next'
import { Dispatch } from 'redux'
import { WithStyles } from '@material-ui/core/styles'
import { connect } from 'react-redux'
import Typography from '@material-ui/core/Typography'
import { SIGNUP, SignupData } from './Actions'
import { State } from '../../application/Store'
import { SignupPersonalInformationForm } from './components/SignupPersonalInformation'
import { SignupCompanyInformationForm } from './components/SignupCompanyInformation'
import { SignupFinalDetailsForm } from './components/SignupFinalDetails'
import { Card, withStyles } from '@material-ui/core'
// import { CenteredContainer } from '../../layout/Container'
import { Stepper } from '../../layout/stepper/Stepper'
import { CompanySize, Industry } from '@syncfab/machine'

const styles = theme => ({
    stepper: {
        width: 500,
        margin: 'auto',
        padding: theme.spacing(2),
    },
    title: {
        color: theme.palette.common.white,
    },
    error: {
        color: theme.palette.error.main,
    },
    signinText: {
        color: theme.palette.common.white,
        textAlign: 'center' as any,
        marginTop: theme.spacing(1),
    },
    subheading: {
        marginTop: theme.spacing(2),
    },
})

interface SignupProps extends WithNamespaces, WithStyles {
    signup: (data: any) => void
    signupError?: string | undefined
}

class SignupView extends React.Component<SignupProps> {
    private steps: string[] = ['signup-step-personal', 'signup-step-company', 'signup-step-final']
    state: { activeStep: number } = {
        activeStep: 0,
    }

    constructor(props: SignupProps) {
        super(props)
        this.onSubmit = this.onSubmit.bind(this)
        this.next = this.next.bind(this)
        this.previous = this.previous.bind(this)
    }

    next = () => {
        const { activeStep } = this.state
        if (activeStep > this.steps.length) {
            return
        }
        this.setState({
            activeStep: activeStep + 1,
        })
    }

    previous = () => {
        const { activeStep } = this.state
        if (activeStep === 0) {
            return
        }
        this.setState({
            activeStep: activeStep - 1,
        })
    }

    public render() {
        const { activeStep } = this.state
        const { classes, t, signupError } = this.props
        const industryOpts = Object?.keys(Industry)?.map(industry => {
            return { value: industry, label: `${t(`company-industry-${industry.toLowerCase()}`)}` }
        })
        const companyOpts = Object?.keys(CompanySize)?.map(industry => {
            return { value: industry, label: `${t(`company-size-${industry.toLowerCase()}`)}` }
        })
        return (
            <div style={{ marginTop: '80px' }}>
                <Helmet>
                    <title>{`${t('signup-title')}`}</title>
                </Helmet>
                <Card className={classes.stepper} elevation={1}>
                    <Typography component="h3" variant="h3" className={classes.title}>
                        {`${t('buyer-sign-up-title')}`}
                    </Typography>
                    <Typography component="h4" variant="h4" className={classes.subheading}>
                        {`${t(this.steps[activeStep])}`}
                    </Typography>
                    {!!signupError && <Typography className={classes.error}>{`${t(signupError)}`}</Typography>}
                    <Stepper activeStep={activeStep} steps={this.steps} />
                    <section>
                        {activeStep === 0 && <SignupPersonalInformationForm onSubmit={this.next} />}
                        {activeStep === 1 && <SignupCompanyInformationForm onPrevious={this.previous} onSubmit={this.next} industryOpts={industryOpts} companyOpts={companyOpts} />}
                        {activeStep === 2 && <SignupFinalDetailsForm onPrevious={this.previous} onSubmit={this.onSubmit as any} />}
                    </section>
                </Card>
            </div>
        )
    }

    private onSubmit(data: Map<string, string>) {
        const values = data.toObject() as any
        delete values.agreement
        const { line, line2, city, zipCode, country, state } = values?.location?.toJS() as any
        const user = {
            email: values?.email ? values.email : '',
            firstName: values?.firstName ? values.firstName : '',
            lastName: values?.lastName ? values.lastName : '',
            name: values?.name ? values.name : '',
            password: values?.password ? values.password : '',
            phone: values?.phone ? values.phone : '',
            title: values?.title ? values.title : '',
            location: {
                line: line ? line : '',
                line2: line2 ? line2 : '',
                city: city ? city : '',
                zipCode: zipCode ? zipCode : '',
                country: country ? country?.value : '',
                state: state ? state?.value : '',
            },
            heardAboutUs: values?.heardAboutUs ? values.heardAboutUs.value : '',
            industry: values?.industry ? values.industry.value : '',
            size: values?.size ? values.size.value : '',
        }
        this.props.signup(user)
    }
}

const mapStateToProps = ({ company: { signupError } }: State) => {
    return {
        signupError,
    }
}

const mapDispatchToProps = (dispatch: Dispatch) => {
    return {
        signup: (data: SignupData) => dispatch(SIGNUP(data)),
    }
}

const SignupWithTranslations = withNamespaces()(withStyles(styles)(SignupView))

export const Signup = connect(mapStateToProps, mapDispatchToProps)(SignupWithTranslations)
