import React from 'react'
import { Field, reduxForm } from 'redux-form/immutable'
import { InjectedFormProps } from 'redux-form'
import { Map } from 'immutable'
import { WithStyles, withStyles } from '@material-ui/core/styles'
import { withNamespaces, WithNamespaces } from 'react-i18next'
import { FormTextField, jumpToFirstError } from '@syncfab/machine'
import { Grid } from '@material-ui/core'

export const SendMFGTokensFormName: string = 'SendMFGTokensForm'

const styles = theme => ({
    form: {
        display: 'flex',
        'flex-direction': 'column',
        maxWidth: 600,
        minWidth: 300,
        margin: 'auto',
    },
    action: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
    },
    signupLink: {
        margin: 0,
        padding: 0,
        'text-decoration': 'none',
        color: theme.palette.primary.light,
        marginBottom: theme.spacing(1),
    },
})

type SendMFGTokensFields = {
    recipientWalletAddress?: string
    MFGAmount?: string
}

const validate = (data: Map<string, string>): {} => {
    const errors: SendMFGTokensFields = {}
    // TODO (JR): Unify
    if (!data.get('recipientWalletAddress')) {
        errors.recipientWalletAddress = 'Required'
    }

    if (!data.get('MFGAmount')) {
        errors.MFGAmount = 'Required'
    }

    if (data.get('MFGAmount')) {
        if (Number(data.get('MFGAmount')) <= 0) errors.MFGAmount = 'Insert Valid Amount'
    }

    return errors
}

const form = (props: InjectedFormProps<{}, {}> & WithStyles & WithNamespaces) => {
    const { handleSubmit } = props
    return (
        <form onSubmit={handleSubmit as any}>
            <Grid container spacing={2}>
                <Grid container item xs={12} direction="row">
                    <Grid item xs={12}>
                        <Field name="recipientWalletAddress" component={FormTextField} type="text" label="Recipient's Wallet Address" fullWidth />
                    </Grid>
                    <Grid item xs={12}>
                        <Field name="MFGAmount" component={FormTextField} type="number" label="MFG Amount" fullWidth />
                    </Grid>
                </Grid>
            </Grid>
        </form>
    )
}

const translatedForm = withNamespaces()(withStyles(styles)(form))
export const SendMFGTokensForm = reduxForm<{}, {}, any>({
    form: SendMFGTokensFormName,
    validate,
    onSubmitFail: jumpToFirstError,
})(translatedForm)
