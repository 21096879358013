import _ from 'lodash-es'
import { Step, StepLabel, Stepper, Button } from '@material-ui/core'
import React from 'react'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import { FormProvider } from 'react-hook-form'
import { object } from 'yup';
import { useStyles } from './common'
import {
  SupplierDappSaveAndExitSchema,
  useGetLoggedUserQuery,
} from '@syncfab/machine'
import {
  createSupplierVerifyForm,
  Supplier,
  SupplierVerificationObject,
  useSavePartialSupplierVerifyMutation,
  useSupplierVerifyMutation,
  transformFormDataToSubmitFormat,
} from '@syncfab/machine'
import { GET_LOGGED_USER } from '../../../components/user/Actions'
import { useAppDispatch } from '../../../application/hooks'
import {
  SupplierStepDescriptions,
  SupplierFormSteps,
  getRoutesFromActiveStep,
  getKeysBasedOnStep,
} from './constants'
import { useHistory } from 'react-router'

interface VerifySupplierFormProps {
  supplier: Supplier
  children: React.ReactNode
  activeStep: number
}
const SupplierFormShell: React.FC<VerifySupplierFormProps> = ({
  activeStep,
  supplier,
  children,
}) => {
  const history = useHistory()
  const classes = useStyles()
  const dispatch = useAppDispatch()
  const subStepKeys = getKeysBasedOnStep(activeStep)
  const activeStepRoutes = getRoutesFromActiveStep(activeStep)
  const schema = _.pick(SupplierVerificationObject, subStepKeys)
  const { methods } = createSupplierVerifyForm(supplier, schema)
  const { formState: { isValid }} = methods;
  const { data: loggedUser } = useGetLoggedUserQuery();
  const [submitAPI] =
    activeStep === 4
      ? useSupplierVerifyMutation()
      : useSavePartialSupplierVerifyMutation()
  const [saveAndExitAPI] = useSavePartialSupplierVerifyMutation()

  React.useEffect(() => {
    if(!!supplier) methods.reset(supplier);
  }, [supplier])

  const onSaveAndExit = async () => {
    const data = methods.getValues();
    const saveExitSchema = _.pick(SupplierDappSaveAndExitSchema, subStepKeys);
    try {
      await object(saveExitSchema).isValid(data);
      const submitData = transformFormDataToSubmitFormat(
        _.pick(data, subStepKeys)
      )
      const res = await saveAndExitAPI(submitData)
      if ('data' in res) {
        dispatch(
          GET_LOGGED_USER.actions.SUCCESSFULL({
            ...loggedUser,
            supplier: res.data,
          })
        )
        history.push('/quotes');
      }
    }
    catch (err) {
      console.error(err);
      dispatch(GET_LOGGED_USER.actions.FAILED(err))
    }
  }
  const onSubmit = async data => {
    try {
      const submitData = transformFormDataToSubmitFormat(
        _.pick(data, subStepKeys)
      )
      const res = await submitAPI(submitData)
      if ('data' in res) {
        dispatch(
          GET_LOGGED_USER.actions.SUCCESSFULL({
            ...loggedUser,
            supplier: res.data,
          })
        )
        history.push(activeStepRoutes.next);
      }
    } catch (error) {
      console.error(error)
    }
  }

  return (
    <FormProvider {...methods}>
      <Grid
        container
        justifyContent="space-between"
        style={{ marginTop: 32, marginBottom: 16 }}
      >
        <Grid item xs={12}>
          <Typography gutterBottom variant="h3">
            Get Verified
          </Typography>
        </Grid>
      </Grid>
      <Grid
        container
        justifyContent="space-between"
        style={{ marginTop: 32, marginBottom: 16 }}
      >
        <Grid item xs={12}>
          {/* Use activeStep - 1 since activeStep is 0 indexed, whereas it is less confusing to display 1 indexed steps*/}
          <Stepper
            className={classes.stepper}
            activeStep={activeStep - 1}
            alternativeLabel
          >
            {SupplierFormSteps.map(label => {
              return (
                <Step key={label}>
                  <StepLabel className={classes.stepperLabel}>
                    {label}
                  </StepLabel>
                </Step>
              )
            })}
          </Stepper>
        </Grid>
      </Grid>
      <Grid container>
        <Grid item>
          <Typography variant="h5">
            {SupplierStepDescriptions[activeStep]}
          </Typography>
        </Grid>
      </Grid>
      {children}
      <Grid
        container
        style={{ textAlign: 'right', marginTop: 32, marginBottom: 16 }}
      >
        <Grid
          item
          xs={12}
          style={{ display: 'flex', justifyContent: 'flex-end' }}
        >
          {activeStep !== 4 && (
            <Button
              variant="outlined"
              type="button"
              color="primary"
              onClick={onSaveAndExit}
            >
              Save & Exit
            </Button>
          )}
          <Button
            onClick={() => {
              history.push(activeStepRoutes.previous)
            }}
            variant="outlined"
            color="primary"
            type="button"
            style={{ marginLeft: 10 }}
          >
            Back
          </Button>
          <Button
            onClick={async () => {
              await methods.handleSubmit(onSubmit)()
            }}
            variant="contained"
            color="primary"
            type="button"
            style={{ marginLeft: 10 }}
            disabled={!isValid}
          >
            {activeStep === 4 ? 'Verify' : 'Continue'}
          </Button>
        </Grid>
      </Grid>
    </FormProvider>
  )
}

export default SupplierFormShell
