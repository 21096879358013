import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { SortDirection, QuoteListParameters } from '@syncfab/machine'

const initialState: QuoteListParameters = {
    page: 0,
    size: 10,
    sortField: '_id',
    sortDirection: SortDirection.DESC,
    query: '',
    filter: '',
    deadlineFilter: [] as string[],
}

const QuoteListParametersSlice = createSlice({
    name: 'quoteListParameters',
    initialState,
    reducers: {
        updateQuoteListParameters: (state, action: PayloadAction<QuoteListParameters>) => {
            return { ...state, ...action.payload }
        },
    },
})

export const { updateQuoteListParameters } = QuoteListParametersSlice.actions

export default QuoteListParametersSlice.reducer
