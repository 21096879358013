import { UserIdAndName, Activity, Comment } from '../BaseTypes';
import { Address } from '../company';
import { Document } from '../document/Document';
import {
  PurchaseOrderLineItem,
  PurchaseOrderLineItemStatus,
} from './PurchaseOrderLineItem';
import { User } from '../user';
import { ValueLabelOption } from '../ui';
import { Theme } from '@material-ui/core';
import { ListQuery } from '../list';

export enum PurchaseOrderStatus {
  DRAFT = 'Draft',
  INPROGRESS = 'In Progress',
  INVOICING = 'Invoicing',
  ONHOLD = 'On Hold',
  CANCELED = 'Canceled',
  COMPLETED = 'Completed',
}

export const PurchaseOrderStatusMap = new Map<PurchaseOrderStatus, string>([
  [PurchaseOrderStatus.DRAFT, 'Draft'],
  [PurchaseOrderStatus.INPROGRESS, 'In Progress'],
  [PurchaseOrderStatus.ONHOLD, 'On Hold'],
  [PurchaseOrderStatus.CANCELED, 'Canceled'],
  [PurchaseOrderStatus.COMPLETED, 'Completed'],
]);

export const PurchaseOrderStatusToLineItemStatusesMap = new Map<
  PurchaseOrderStatus,
  Array<PurchaseOrderLineItemStatus>
>([
  [PurchaseOrderStatus.DRAFT, [PurchaseOrderLineItemStatus.DRAFT]],
  [
    PurchaseOrderStatus.INPROGRESS,
    [
      PurchaseOrderLineItemStatus.NOTISSUED,
      PurchaseOrderLineItemStatus.MATERIALORDERED,
      PurchaseOrderLineItemStatus.INPRODUCTION,
      PurchaseOrderLineItemStatus.OSP,
      PurchaseOrderLineItemStatus.INSPECTION,
      PurchaseOrderLineItemStatus.IN_FAI,
      PurchaseOrderLineItemStatus.QA_REVIEW,
      PurchaseOrderLineItemStatus.SHIPPED,
      PurchaseOrderLineItemStatus.RECEIVED,
      PurchaseOrderLineItemStatus.REVISED,
    ],
  ],
  [
    PurchaseOrderStatus.INVOICING,
    [
      PurchaseOrderLineItemStatus.READYTOINVOICE,
      PurchaseOrderLineItemStatus.INVOICESENT,
      PurchaseOrderLineItemStatus.PAYMENTDUE,
      PurchaseOrderLineItemStatus.PAYMENTRECEIVED,
    ],
  ],
  [
    PurchaseOrderStatus.ONHOLD,
    [
      PurchaseOrderLineItemStatus.ONHOLD,
      PurchaseOrderLineItemStatus.RECEIPTISSUE,
      PurchaseOrderLineItemStatus.STOCK,
    ],
  ],
  [PurchaseOrderStatus.CANCELED, [PurchaseOrderLineItemStatus.CANCELED]],
  [PurchaseOrderStatus.COMPLETED, [PurchaseOrderLineItemStatus.PAID]],
]);

export const PurhcaseOrderLineItemStatusToPurchaseOrderMap = new Map<
  PurchaseOrderLineItemStatus,
  PurchaseOrderStatus
>(
  [...PurchaseOrderStatusToLineItemStatusesMap.entries()].flatMap(p =>
    p[1].map(s => [s, p[0]])
  )
);

export const getPOStatusBackgroundColor = (
  status: PurchaseOrderStatus,
  theme: Theme
): string => {
  if (status === PurchaseOrderStatus.DRAFT) {
    return theme.palette.secondary.main; //yellow
  } else if (
    [PurchaseOrderStatus.ONHOLD, PurchaseOrderStatus.CANCELED].includes(status)
  ) {
    return theme.palette.error.main; //red
  } else if ([PurchaseOrderStatus.COMPLETED].includes(status)) {
    return theme.palette.success.main; // green
  } else if ([PurchaseOrderStatus.INPROGRESS].includes(status)) {
    return theme.palette.grey[500];
  } else {
    return theme.palette.grey[600];
  }
};

export interface PurchaseOrderInput {
  rfqId: string;
  quoteId: string;
  status?: string;
  revision: string;
  receivedDate?: Date;
  initialPromiseDate?: Date;
  currentPromiseDate?: Date;
  completionDate?: Date;
  certifications?: string[];
  inspection?: string;
  packaging?: string;
  additionalRequirements?: string;
  paymentTerms?: string;
  notes?: string;
  customerSuppliedMaterial?: boolean;
  domesticProductionRequired?: boolean;
  shippingMethod?: string;
  buyerRFQReference?: string;
  buyerPOReference: string;
  buyerCompany: {
    companyId: string;
    companyName?: string;
    billingAddressId: string;
    shippingAddressId: string;
  };
  buyerContact: {
    userId: string;
    userName?: string;
  };
  assignedTo: {
    userId: string;
    userName?: string;
  };
  salesperson: {
    userId: string;
    userName?: string;
  };
  buyerPODocumentIds: string[];
  additionalDocumentIds: string[];
}

export interface PurchaseOrder extends PurchaseOrderInput {
  _id: string;
  buyerCompany: {
    companyId: string;
    companyName: string;
    billingAddressId: string;
    billingAddress?: Address;
    shippingAddressId: string;
    shippingAddress?: Address;
  };
  buyerContact: UserIdAndName;
  assignedTo: UserIdAndName;
  salesperson: UserIdAndName;
  activity: Activity[];
  comments: Comment[];
  poTotal?: number;
  createdAt: Date;
  createdBy: UserIdAndName;
  updatedAt?: Date;
  updatedBy?: UserIdAndName;
  deletedAt?: Date;
  deletedBy?: UserIdAndName;
  totalLines?: number;
  completedLines?: number;
  supplierTotal?: number;
  nextDeliveryDate?: Date;
  totalMarkup?: number;
}

export interface PurchaseOrderDetails extends PurchaseOrder {
  buyerPODocuments: Document[];
  additionalDocuments: Document[];
  lineItems: PurchaseOrderLineItem[];
}

export type PurchaseOrderWithOptions = {
  po: PurchaseOrderDetails;
  buyerContacts: User[];
  buyerAddresses: Address[];
};

export const generatePurchaseOrderOptions = (
  purchaseOrders: PurchaseOrder[]
): ValueLabelOption[] => {
  return purchaseOrders.map(purchaseOrder =>
    generatePurchaseOrderOption(purchaseOrder._id)
  );
};

export interface PurchaseOrderListQuery extends ListQuery {
  newDockDateRange?: string;
}

export const generatePurchaseOrderOption = (poId: string): ValueLabelOption => {
  return {
    value: poId,
    label: `${poId}`,
  };
};
