import React from 'react'
import { WithNamespaces, withNamespaces } from 'react-i18next'
import { State } from '../../../../application/Store'
import { Dispatch } from 'redux'
import { connect } from 'react-redux'
import { PartTokenizeEdiorForm } from './PartTokenizationForm'
import { withStyles, WithStyles, Typography, Divider, Paper, Dialog, DialogContent, Grid, DialogActions, Button } from '@material-ui/core'
import { Map } from 'immutable'
import { GET_COST, GET_WALLET_ADDRESS, GET_BALANCE } from '../../../../components/transaction/Actions'
import { TOKENIZE_PARTS, TOKENIZE_FAILED_PARTS, RESET_DATA, SET_PARTS_FOR_TOKENIZATION } from '../../../../components/part/Actions'
import CachedIcon from '@material-ui/icons/Cached'
import { Redirect } from 'react-router'
import { PUSH } from '@syncfab/machine'
import ErrorIcon from '@material-ui/icons/Error'

const styles = theme => ({
    paper: {
        width: '90%',
        minWidth: 300,
        padding: theme.spacing(3),
        marginTop: theme.spacing(3),
        marginBottom: theme.spacing(3),
    },
    paper2: {
        width: '80%',
        minWidth: 300,
        padding: theme.spacing(3),
        marginTop: theme.spacing(3),
        marginBottom: theme.spacing(3),
    },
    gridPadding: {
        padding: `${theme.spacing(2)}px ${theme.spacing(20)}px`,
    },
    nftBalanceContainer: {
        border: `1px solid ${theme.palette.secondary.main}`,
        borderRadius: 15,
        margin: `${theme.spacing(2)}px ${theme.spacing(1)}px`,
        padding: theme.spacing(2),
    },
    mfgIcon: {
        width: 75,
        height: 75,
    },
    nftBalance: {
        color: theme.palette.secondary.main,
        fontSize: 34,
        marginLeft: theme.spacing(1),
    },
    margin: {
        margin: theme.spacing(1),
    },
    paragraph: {
        color: '#D6665D',
    },
    warningButton: {
        color: '#ef5350',
        margin: theme.spacing(1),
    },
    errorButton: {
        color: '#ef5350',
        margin: theme.spacing(1),
        fontSize: 48,
    },
    processingButton: {
        color: '#40BF8A',
        margin: theme.spacing(2),
        fontSize: 48,
    },
    root: {
        backgroundColor: theme.palette.common.white,
        marginTop: theme.spacing(2),
        padding: theme.spacing(2),
        color: theme.palette.grey['700'],
    },

    horizontalheader: {
        backgroundColor: theme.palette.grey['300'],
        width: '35%',
        fontWeight: 900,
        // padding:`${theme.spacing.unit * 2}px 0`
    },
    link: {
        color: '#40B4BF',
        textDecoration: 'none',
    },

    tablePagination: {},
    tablePaginationCaption: {
        color: 'white',
    },
    tablePaginationSelectIcon: {
        color: 'white',
    },

    tablePaginationSelect: {
        color: 'black',
    },
    tablePaginationActions: {
        color: 'white',
    },
})

interface PartTokenizeProps extends WithStyles, WithNamespaces {
    push: (url: string) => void
    //history?: any
    getBalance: () => void
    getWalletAddress: () => void

    getCost: () => void
    tokenizeParts: (data: any) => void
    tokenizeFailedParts: (data: any) => void
    setPartsForTokenization: (parts: any[]) => void

    nftBalance?: any
    transactionFeeForPartTokenization?: any
    selectedParts?: any[]
    walletAddress?: string
    isLoading: boolean
    successTokenizePart: boolean
    resetErrorMsg: () => void
}

interface PartTokenizeState {
    sendToNextPage: boolean
    WarningDialog: boolean
}

class PartTokenize extends React.Component<PartTokenizeProps, PartTokenizeState> {
    constructor(props) {
        super(props)

        this.onSubmit = this.onSubmit.bind(this)
        this.props.getWalletAddress()
        this.props.getBalance()
        this.props.getCost()
        this.state = {
            sendToNextPage: false,
            WarningDialog: false,
        }
        if (!this.props.selectedParts || this.props.selectedParts.length == 0) {
            this.state = {
                sendToNextPage: true,
                WarningDialog: false,
            }
        }
        this.handleClose = this.handleClose.bind(this)
        this.closeWarningDialog = this.closeWarningDialog.bind(this)
        this.sendForTokenization = this.sendForTokenization.bind(this)
    }

    sendForTokenization = deletepartID => {
        var filtered = this.props.selectedParts?.filter(part => part?._id != deletepartID)

        this.props.setPartsForTokenization(filtered)
        if (filtered.length == 0) {
            this.props.push('/parts')
        }
    }

    onSubmit(values: Map<string, string>) {
        if (!this.props.nftBalance.MFG_Balance) {
            this.setState({ WarningDialog: true })
            return
        }
        const vals = values.toJS()
        var parts = []
        var failedParts = []
        this.props.selectedParts.forEach(prt => {
            var key1 = vals[`${prt._id}_title_1`] != undefined ? vals[`${prt._id}_title_1`] : '-'
            var key2 = vals[`${prt._id}_title_2`] != undefined ? vals[`${prt._id}_title_2`] : '-'
            var val1 = vals[`${prt._id}_desc_1`] != undefined ? vals[`${prt._id}_desc_1`] : '-'
            var val2 = vals[`${prt._id}_desc_2`] != undefined ? vals[`${prt._id}_desc_2`] : '-'

            var customAttrib1 = `${key1}:${val1}`
            var customAttrib2 = `${key2}:${val2}`

            if (prt.status == 'FAILED') {
                failedParts.push({
                    SyncFabPartID: prt._id,
                    CompanyPartNumber: prt.number,
                    SyncFabCompanyID: prt.companyId,
                    CustomAttributes1: customAttrib1,
                    CustomAttributes2: customAttrib2,
                    PartDescription: prt.name,
                })
            }
            if (prt.status == 'NOT_TOKENIZE' || !prt.status) {
                parts.push({
                    SyncFabPartID: prt._id,
                    CompanyPartNumber: prt.number,
                    SyncFabCompanyID: prt.companyId,
                    CustomAttributes1: customAttrib1,
                    CustomAttributes2: customAttrib2,
                    PartDescription: prt.name,
                })
            }
        })

        var cost = 0
        this.props.selectedParts.forEach(prt => {
            if (prt.status === 'NOT_TOKENIZE' || !prt.status) {
                cost += this.props.transactionFeeForPartTokenization.nftCreationCost
                cost += this.props.transactionFeeForPartTokenization.nftCreationFee
                cost += this.props.transactionFeeForPartTokenization.mfgprtUnitCost
            }
        })

        if (parts.length > 0) {
            if (cost > Number(this.props.nftBalance.MFG_Balance)) {
                this.setState({ WarningDialog: true })
                return
            } else {
                var data = { parts: parts, totalCost: cost.toString(), companyWalletAddress: this.props.walletAddress }
                this.props.tokenizeParts(data)
            }
        }

        if (failedParts.length > 0) {
            var data = { parts: failedParts, totalCost: '0', companyWalletAddress: this.props.walletAddress }
            this.props.tokenizeFailedParts(data)
        }
    }
    handleClose() {
        this.props.resetErrorMsg()
        this.props.push('/parts')
    }

    closeWarningDialog() {
        this.setState({ WarningDialog: false })
    }

    render() {
        const { classes, selectedParts, nftBalance, transactionFeeForPartTokenization, isLoading, successTokenizePart } = this.props
        const { sendToNextPage, WarningDialog } = this.state
        console.log(selectedParts)

        return (
            <Paper className={classes.paper} elevation={1}>
                <Typography gutterBottom variant="h2">
                    {'Tokenize Your Part(s)'}
                </Typography>
                <Divider className={classes.divider} style={{ marginTop: '16px' }} />
                <PartTokenizeEdiorForm
                    onSubmit={this.onSubmit as any}
                    deletePart={this.sendForTokenization as any}
                    selectedParts={selectedParts}
                    nftBalance={nftBalance}
                    transactionFeeForPartTokenization={transactionFeeForPartTokenization}
                />

                <Dialog aria-labelledby="simple-dialog-title" open={WarningDialog} onClose={this.closeWarningDialog}>
                    <DialogContent>
                        <Grid container justify="center">
                            <Grid item container justify="center" xs={12}>
                                {/* <Fab className={classes.errorButton}> */}
                                <ErrorIcon className={classes.errorButton} />
                                {/* </Fab> */}
                            </Grid>

                            <Typography gutterBottom variant="h3">
                                Insufficient MFG Funds
                            </Typography>
                            <Typography align="center">
                                Your MFG Wallet nftBalance does not have enough MFG to complete your request. You can purchase MFG from SyncFab or send MFG to your wallet in the wallet page
                            </Typography>
                        </Grid>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={this.closeWarningDialog} color="primary" autoFocus>
                            CLOSE
                        </Button>
                    </DialogActions>
                </Dialog>

                <Dialog aria-labelledby="simple-dialog-title" open={isLoading}>
                    <DialogContent>
                        <Grid container justify="center">
                            <Grid item container justify="center" xs={12}>
                                {/* <Fab className={classes.processingButton}> */}
                                <CachedIcon className={classes.processingButton} />
                                {/* </Fab> */}
                            </Grid>

                            <Typography gutterBottom variant="h3">
                                Please Wait...
                            </Typography>
                        </Grid>
                    </DialogContent>
                </Dialog>

                <Dialog aria-labelledby="simple-dialog-title" open={successTokenizePart} onClose={this.handleClose}>
                    <DialogContent>
                        <Grid container justify="center">
                            <Grid item container justify="center" xs={12}>
                                <CachedIcon className={classes.processingButton} />
                            </Grid>

                            <Typography gutterBottom variant="h3">
                                Your MFG MFGPRT(s) is being minted
                            </Typography>
                            <p>The transaction may take several minutes to complete. You can refresh the page to check the status and you will receive an email when complete.</p>
                        </Grid>
                    </DialogContent>
                    <DialogActions>
                        <Grid container justify="center">
                            <Button onClick={this.handleClose} color="primary" autoFocus>
                                OKAY
                            </Button>
                        </Grid>
                    </DialogActions>
                </Dialog>

                {sendToNextPage && <Redirect to="/parts" />}
            </Paper>
        )
    }
}

const EditorWithTranslations = withStyles(styles)(withNamespaces()(PartTokenize))

const mapStateToProps = (state: State) => {
    return {
        nftBalance: state.transaction.balance,
        selectedParts: state.part.selectedPartsForTokenization,
        transactionFeeForPartTokenization: state.transaction.transactionFeeForPartTokenization,
        isLoading: state.part.isLoading,
        successTokenizePart: state.part.successTokenizePart,
        walletAddress: state.transaction.walletAddress,
    }
}

const mapDispatchToProps = (dispatch: Dispatch) => {
    return {
        getBalance: () => dispatch(GET_BALANCE.actions.TRIGGER()),
        getCost: () => dispatch(GET_COST.actions.TRIGGER()),
        tokenizeParts: (data: any) => dispatch(TOKENIZE_PARTS.actions.TRIGGER({ data })),
        tokenizeFailedParts: (data: any) => dispatch(TOKENIZE_FAILED_PARTS.actions.TRIGGER({ data })),
        push: (url: string) => dispatch(PUSH(url)),
        getWalletAddress: () => dispatch(GET_WALLET_ADDRESS.actions.TRIGGER()),
        resetErrorMsg: () => dispatch(RESET_DATA.actions.TRIGGER()),
        setPartsForTokenization: (parts: any[]) => dispatch(SET_PARTS_FOR_TOKENIZATION.actions.TRIGGER(parts)),
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(EditorWithTranslations)
