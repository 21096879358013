import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import TextField from '@material-ui/core/TextField'
import { Checkbox, FormControl, InputAdornment, Paper } from '@material-ui/core'
import Autocomplete from '@material-ui/lab/Autocomplete'
import { Lock } from '@material-ui/icons'
import { FormFieldFooter } from '../Error'
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank'
import CheckBoxIcon from '@material-ui/icons/CheckBox'

const styles = _ => ({
    // root: {
    //     'flex-grow': 1,
    //     height: 250,
    // },
})

export interface AutoselectOption {
    value: string
    label: string
}

export const findSelectedOptions = (options: AutoselectOption[], selectedValues: string[]): AutoselectOption[] => {
    // options is an array of options for an autoselect element
    // selectedValues are pre-selected values for the element (plain strings here)
    // need to return references to the autoselect options for the selected values

    const selectedOptions: AutoselectOption[] = []
    selectedValues?.forEach(value => {
        const foundOption = options?.find(item => item.value === value)
        if (!!foundOption) {
            selectedOptions.push(foundOption)
        }
    })
    //    console.log(selectedOptions)
    return selectedOptions
}

export const findSelectedSingleOption = (options: AutoselectOption[], selectedValue: string): AutoselectOption => {
    // options is an array of options for an autoselect element
    // selectedValue is a string value representing the single option that should be selected
    // need to return references to the autoselect option corresponding to the selected values
    return options?.find(item => item.value === selectedValue)
}

export const normalizeSingleValue = (value: any): string => {
    return value ? value?.value : ''
}

export const normalizeValues = (values: any = []): string => {
    return values && values?.length > 0 ? values?.map(val => val?.value) : []
}

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />
const checkedIcon = <CheckBoxIcon fontSize="small" />

const AutocompleteV2 = ({
    input,
    label,
    meta: { touched, error },
    placeholder,
    isMulti,
    fullWidth,
    className,
    disabled,
    options,
    description,
    onInputChange,
    checkboxExist,
    labelKey,
    valueKey,
    prefix,
    t,
    setRef = () => {},
    InputProps,
    disabledOptions = [],
    disableClearable = false,
    size = 'medium',
    hideFieldFooter = false,
}: any) => {
    return (
        <FormControl error={!!touched && !!error} fullWidth={fullWidth} className={className} disabled={disabled}>
            <Autocomplete
                ref={setRef}
                options={generateOptions(options, valueKey, labelKey, prefix, t)}
                disabled={disabled}
                getOptionLabel={option => (option.label ? option.label : typeof option == 'string' ? option : '')}
                PaperComponent={({ children }) => <Paper style={{ background: '#fff' }}>{children}</Paper>}
                getOptionSelected={(option, value) => {
                    if (option?.value?._id) {
                        return option?.value?._id === value?.value?._id
                    } else if (value?.value) {
                        return option.value === value.value
                    } else {
                        return option.value === value
                    }
                }}
                size={size}
                renderInput={(params: any) => {
                    let inputProps = params.InputProps
                    if (params.disabled && !InputProps) {
                        inputProps = {
                            ...params.InputProps,
                            endAdornment: (
                                <InputAdornment position="end" style={{ backgroundColor: 'inherit' }}>
                                    <Lock />
                                </InputAdornment>
                            ),
                        }
                    }
                    if (!params.disabled && InputProps) {
                        inputProps = {
                            ...params.InputProps,
                            ...InputProps,
                        }
                    }
                    return (
                        <TextField inputRef={setRef} {...params} label={label} placeholder={placeholder} value={input.value} variant="outlined" error={!!touched && !!error} InputProps={inputProps} />
                    )
                }}
                renderOption={(option, { selected }) => (
                    <React.Fragment>
                        {checkboxExist && (
                            <Checkbox
                                color="primary"
                                inputProps={{
                                    'aria-label': 'primary checkbox',
                                }}
                                icon={icon}
                                checkedIcon={checkedIcon}
                                style={{ marginRight: 8 }}
                                checked={selected}
                            />
                        )}
                        {option.label}
                    </React.Fragment>
                )}
                onChange={(_event, newValue) => {
                    if (isMulti) {
                        input.onChange(newValue.map(v => v))
                    } else {
                        input.onChange(newValue)
                    }
                }}
                onInputChange={onInputChange}
                value={input.value}
                disableCloseOnSelect={isMulti}
                multiple={isMulti}
                disableClearable={disableClearable}
                fullWidth
                getOptionDisabled={(option: any) => {
                    if (disabledOptions?.some(disableOption => disableOption === option?.value)) {
                        return true
                    }
                    return false
                }}
            />
            {!hideFieldFooter && <FormFieldFooter touched={touched} error={error} description={description} />}
        </FormControl>
    )
}

const generateOptions = (options: any, valueKey: string, labelKey: string, prefix?: string, t?: (name: string) => string, isDirectValues: boolean = false) => {
    if (options && options?.length > 0 && options[0]?.value && options[0]?.label) {
        return options
    } else {
        return options?.length > 0
            ? options.map(status => {
                  const label = isDirectValues ? status : status[labelKey]
                  return {
                      value: isDirectValues ? status : status[valueKey],
                      label:
                          !prefix || !t
                              ? `${`${label?.charAt(0)}${label
                                    .slice(1)
                                    .toLowerCase()
                                    .replace(/_/g, ' ')}`}`
                              : t(`${prefix}-${label.toLocaleLowerCase()}`),
                  }
              })
            : []
    }
}

export const FormAutocompleteV2 = withStyles(styles)(AutocompleteV2)
