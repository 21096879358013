import { createSelector } from 'reselect'
import { State } from '../../application/Store'

const getParts = (state: State) => {
    return state.part.parts
}

export const getPartById = () => {
    return createSelector(
        [getParts],
        parts => {
            return parts.reduce((acc, part) => {
                acc[part._id] = part
                return acc
            }, {})
        }
    )
}
