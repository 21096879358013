import React from 'react'
import { WithNamespaces, withNamespaces } from 'react-i18next'
import { connect } from 'react-redux'
import { WithStyles, withStyles, Paper, Typography, Grid, Card, Table, TableBody, TableRow, TableCell } from '@material-ui/core'
import { Dispatch } from 'redux'
import { CenteredAlignedContainer } from '../../layout/Container'
import { State } from '../../application/Store'
import { RouteComponentProps } from 'react-router'
import ExpandLessIcon from '@material-ui/icons/ExpandLess'
//import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
//import RemoveIcon from '@material-ui/icons/Remove'
import { GET_CAPABILITIES, GET_LAT_LONG, GET_SUPPLIER_ANALYTICS } from '../../components/dashboard/Actions'
import { MapContainer } from './MapContainer'
import { Button } from '@syncfab/machine'
import LockIcon from '@material-ui/icons/LockOutlined'
import ArrowDownIcon from '@material-ui/icons/ArrowDropDown'

const styles = theme => ({
    link: {
        textDecoration: 'none',
        color: theme.palette.common.black,
    },
    button: {
        borderRadius: 10,
        padding: `3px ${theme.spacing(1)}px`,
        background: 'transparent',
        border: '1px solid #b5b5b5',
        marginRight: `${theme.spacing(3)}px`,
        '&:hover': {
            background: 'transparent',
        },
    },
    mfgIcon: {
        background: 'transparent',
        borderRadius: '50%',
        padding: '3px',
        width: '32px',
        height: '32px',
        marginRight: `${theme.spacing(1)}px`,
    },
    paper: {
        width: '90%',
        minWidth: 300,
        padding: theme.spacing(3),
        marginTop: theme.spacing(3),
        marginBottom: theme.spacing(3),
    },
    mainPaper: {
        width: '90%',
        padding: 0,
        minWidth: '300px',
        marginTop: '24px',
        marginBottom: '24px',
        background: 'transparent',
        boxShadow: 'none',
    },
    icon: {
        background: '#dbdbdb',
        borderRadius: '50%',
        width: '30px',
        height: '30px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    root: {
        width: '100%',
        marginTop: theme.spacing(3),

        borderRadius: '5px',
        overflow: 'hidden',
    },
    cardWrapper: {},
    card: {
        padding: '20px',
        borderRight: '1px solid #736e6e',
        borderRadius: 0,
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        height: '100%',
    },
    capabilities: {
        padding: '20px',
        margin: '24px 0',
    },
    barWrapper: {
        background: '#eee',
        width: '100%',
        height: '40px',
        borderRadius: '3px',
    },
    bar: {
        background: '#4ebb8e',
        height: '100%',
        display: 'flex',
        borderRadius: '3px 0 0 3px',
        alignItems: 'center',
        justifyContent: 'center',
    },
    table: {
        color: '#fff',
        marginTop: '50px',
    },
    tableCell: {
        border: '0px',
        color: '#fff',
        padding: ' 10px 0 !important',
        letterSpacing: '0.5px',
        marginTop: '20px !important',
    },
    map: {
        width: '100%',
        maxWidth: '100%',
        borderRadius: '8px',
    },
    '@media all and (max-width:916px)': {
        gridItem: {
            width: '100%',
        },
    },
    '@media all and (max-width:959px)': {
        card: {
            border: '1px solid #736e6e !important',
        },
    },
})

interface DashboardProps extends WithNamespaces, WithStyles, RouteComponentProps {
    isLoading: boolean
    user: any
    capabilities: any
    analyticsData: any
    getCapabilities: () => void
    getSupplierAnalytics: () => void
    getLatLong: (addressString: string[]) => void
    addressesWithLatLong: any[]
    history: any
}

interface DashboardState {
    fetchedLocations: boolean
}

class DashboardView extends React.Component<DashboardProps, DashboardState> {
    constructor(props: DashboardProps) {
        super(props)
        this.state = { fetchedLocations: false }
        this.props.getCapabilities()
        this.props.getSupplierAnalytics()
    }

    componentDidUpdate() {
        if (!this.state.fetchedLocations) {
            if (this.props.analyticsData && this.props.analyticsData.locations) {
                this.setState({ fetchedLocations: true })
                let addressStrings = []
                this.props.analyticsData.locations.map(address => {
                    let addressString = ``

                    addressString += address.location.line ? `${address.location.line} ` : ''
                    addressString += address.location.city ? `${address.location.city} ` : ''
                    addressString += address.location.state ? `${address.location.state} ` : ''
                    addressString += address.location.country ? `${address.location.country} ` : ''
                    addressString += address.location.zipCode ? `${address.location.zipCode} ` : ''

                    let addr = { address: addressString }
                    addr['coordinates'] = address.geoLocation && address.geoLocation.coordinates ? address.geoLocation.coordinates : []

                    addressStrings.push(addr)
                })
                this.props.getLatLong(addressStrings)
            }
        }
    }

    render() {
        const { classes, user, capabilities, analyticsData } = this.props
        console.log('capabilities', capabilities, analyticsData)

        return (
            <CenteredAlignedContainer>
                <Paper className={classes.mainPaper} elevation={1}>
                    <Grid container>
                        <Grid item xs={6} spacing={0} container>
                            <Typography gutterBottom variant="h3" style={{ letterSpacing: '0.5px' }}>
                                Hello {user && user.firstName ? user.firstName : ''}
                            </Typography>
                        </Grid>
                        <Grid item xs={6} spacing={0} container justify="flex-end">
                            {/* <FormControl className={classes.formControl}>
                                <InputLabel htmlFor="demo-controlled-open-select">Country</InputLabel>
                                <Select
                                    value={`Switzerland`}
                                    inputProps={{
                                        name: 'age',
                                        id: 'demo-controlled-open-select',
                                    }}
                                >
                                    <MenuItem value={`Switzerland`}>Switzerland</MenuItem>
                                </Select>
                            </FormControl> */}
                            <Button aria-owns={open ? 'menu-appbar' : undefined} aria-haspopup="true" className={classes.button}>
                                <div className={classes.mfgIcon}>
                                    <LockIcon fontSize="small" style={{ color: '#b5b5b5', marginTop: '2px' }} />
                                </div>
                                <Typography style={{ color: '#b5b5b5' }}>{'Switzerland'}</Typography>

                                <ArrowDownIcon style={{ color: '#b5b5b5' }} />
                            </Button>
                        </Grid>
                    </Grid>

                    <Paper className={classes.root} style={{ backgroundColor: 'transparent' }}>
                        <Grid container>
                            <Grid className={classes.cardWrapper} item xs={6} sm={6} md={3}>
                                <Card className={classes.card}>
                                    <div>
                                        <Typography style={{ color: '#fff', letterSpacing: '0.5px', fontWeight: 'lighter' }} variant="subtitle2">
                                            Total Swiss Suppliers
                                        </Typography>
                                        <Typography variant="h2" style={{ margin: '8px 0', letterSpacing: '0.5px', fontWeight: 'normal' }}>
                                            {analyticsData && analyticsData.data && analyticsData.data.totalSupplier ? analyticsData.data.totalSupplier : '0'}
                                        </Typography>
                                        <Typography variant="caption">
                                            vs. {analyticsData && analyticsData.data && analyticsData.data.lastWeekTotalSupplier ? analyticsData.data.lastWeekTotalSupplier : '0'} last week
                                        </Typography>
                                    </div>
                                    <div style={{ background: '#387172' }} className={this.props.classes.icon}>
                                        <ExpandLessIcon style={{ color: '#4ebb8e' }} />
                                    </div>
                                </Card>
                            </Grid>
                            <Grid className={classes.cardWrapper} item xs={6} sm={6} md={3}>
                                <Card className={classes.card}>
                                    <div>
                                        <Typography style={{ color: '#fff', letterSpacing: '0.5px', fontWeight: 'lighter' }} variant="subtitle2">
                                            ITAR Certified Suppliers
                                        </Typography>
                                        <Typography variant="h2" style={{ margin: '8px 0', letterSpacing: '0.5px', fontWeight: 'normal' }}>
                                            {analyticsData && analyticsData.data && analyticsData.data.ITARCertifiedSupplierCount ? analyticsData.data.ITARCertifiedSupplierCount : '0'}
                                        </Typography>
                                        <Typography variant="caption">
                                            vs.{' '}
                                            {analyticsData && analyticsData.data && analyticsData.data.lastWeekITARSupplier
                                                ? analyticsData && analyticsData.data && analyticsData.data.lastWeekITARSupplier
                                                : '0'}{' '}
                                            last week
                                        </Typography>
                                    </div>
                                    <div style={{ background: '#387172' }} className={this.props.classes.icon}>
                                        <ExpandLessIcon style={{ color: '#4ebb8e' }} />
                                    </div>
                                </Card>
                            </Grid>
                            <Grid className={classes.cardWrapper} item xs={6} sm={6} md={3}>
                                <Card className={classes.card}>
                                    <div>
                                        <Typography style={{ color: '#fff', letterSpacing: '0.5px', fontWeight: 'lighter' }} variant="subtitle2">
                                            Average Quote Turnaround Time
                                        </Typography>
                                        <Typography variant="h2" style={{ margin: '8px 0', letterSpacing: '0.5px', fontWeight: 'normal' }}>
                                            Data Unavailable
                                        </Typography>
                                        <Typography variant="caption">Premium Feature</Typography>
                                    </div>
                                    {/* <div style={{ background: '#7a4c54' }} className={this.props.classes.icon}>
                                        <ExpandMoreIcon style={{ color: '#f13424' }} />
                                    </div> */}
                                </Card>
                            </Grid>
                            <Grid className={classes.cardWrapper} item xs={6} sm={6} md={3}>
                                <Card className={classes.card} style={{ borderRight: '0px' }}>
                                    <div>
                                        <Typography style={{ color: '#fff', letterSpacing: '0.5px', fontWeight: 'lighter' }} variant="subtitle2">
                                            Average Quote Percentage
                                        </Typography>
                                        <Typography variant="h2" style={{ margin: '8px 0', letterSpacing: '0.5px', fontWeight: 'normal' }}>
                                            Data Unavailable
                                        </Typography>
                                        <Typography variant="caption">Premium Feature</Typography>
                                    </div>
                                    {/* <div className={this.props.classes.icon}>
                                        <RemoveIcon style={{ color: '#968989' }} />
                                    </div> */}
                                </Card>
                            </Grid>
                        </Grid>
                    </Paper>
                    <Grid container spacing={3}>
                        <Grid className={classes.gridItem} item md={5}>
                            <Card className={classes.capabilities}>
                                <Typography variant="h3" style={{ marginBottom: '20px', letterSpacing: '0.5px', fontWeight: 'normal' }}>
                                    Top Capabilities
                                </Typography>
                                {capabilities && capabilities.capabilities && capabilities.capabilities.length > 0 && (
                                    <Table className={classes.table}>
                                        <TableBody className={classes.tableBody}>
                                            <TableRow>
                                                <TableCell className={classes.tableCell}>
                                                    <Typography style={{ color: '#fff' }} variant="caption">
                                                        Category
                                                    </Typography>
                                                </TableCell>
                                                <TableCell className={classes.tableCell}>Supplier#</TableCell>
                                                <TableCell className={classes.tableCell}>Percentage</TableCell>
                                            </TableRow>
                                            {capabilities.capabilities.map((item, index) => (
                                                <TableRow key={index}>
                                                    <TableCell className={classes.tableCell}>
                                                        <Typography style={{ color: '#fff', letterSpacing: '0.5px', fontWeight: 'normal' }} variant="subtitle2">
                                                            {item._id.replace(/_/g, ' ')}
                                                        </Typography>
                                                    </TableCell>
                                                    <TableCell className={classes.tableCell} style={{ width: '20%' }}>
                                                        <Typography style={{ color: '#fff', letterSpacing: '0.5px', fontWeight: 'normal' }} variant="subtitle2">
                                                            {item.count}
                                                        </Typography>
                                                    </TableCell>
                                                    <TableCell className={classes.tableCell} style={{ paddingRight: 0, width: '50%' }}>
                                                        <div className={classes.barWrapper}>
                                                            <span style={{ width: `${item.percentage}`, letterSpacing: '0.5px', fontWeight: 'normal' }} className={classes.bar}>
                                                                {item.percentage}
                                                            </span>
                                                        </div>
                                                    </TableCell>
                                                </TableRow>
                                            ))}
                                        </TableBody>
                                    </Table>
                                )}
                            </Card>
                        </Grid>
                        <Grid className={classes.gridItem} item md={7}>
                            <Card className={classes.capabilities}>
                                <Typography variant="h3" style={{ marginBottom: '20px', letterSpacing: '0.5px', fontWeight: 'normal' }}>
                                    {'Supplier Locations'}
                                </Typography>
                                {this.props.addressesWithLatLong && this.props.addressesWithLatLong.length > 0 && (
                                    <MapContainer addressesWithLatLong={this.props.addressesWithLatLong} locations={analyticsData.locations} />
                                )}
                            </Card>
                        </Grid>
                    </Grid>
                </Paper>
            </CenteredAlignedContainer>
        );
    }
}

const DashboardWithTranslations = withStyles(styles)(withNamespaces()(DashboardView))

const mapStateToProps = (state: State) => {
    return {
        user: state.user.loggedUser,
        capabilities: state.dashboard.capabilities,
        analyticsData: state.dashboard.supplierAnalytics,
        addressesWithLatLong: state.dashboard.latLong,
    }
}

const mapDispatchToProps = (dispatch: Dispatch) => {
    return {
        getCapabilities: () => dispatch(GET_CAPABILITIES.actions.TRIGGER()),
        getSupplierAnalytics: () => dispatch(GET_SUPPLIER_ANALYTICS.actions.TRIGGER()),
        getLatLong: (addressString: string[]) => dispatch(GET_LAT_LONG.actions.TRIGGER(addressString)),
    }
}

export const Dashboard = connect(
    mapStateToProps,
    mapDispatchToProps
)(DashboardWithTranslations)
