import { User } from '../user/User'

export const LOGIN_SUCESSFUL_ACTION_TYPE: string = 'LOGIN_SUCESSFUL'
export const LOGIN_FAILED_ACTION_TYPE: string = 'LOGIN_FAILED'
export const LOGIN_ACTION_TYPE: string = 'LOGIN'
export const SUPPLIER_LOGIN_ACTION_TYPE: string = 'SUPPLIERLOGIN'
export const LOGOUT_ACTION_TYPE: string = 'LOGOUT'
export const CLEAN_AUTH_TOKEN_ACTION_TYPE: string = 'CLEAN_AUTH_TOKEN_ACTION'

export type LoginData = {
    email: string
    password: string
    previousUrl?: string
}

export type LoginAction = {
    type: string
    data: LoginData
}

export const CLEAN_AUTH_TOKEN_ACTION = (): { type: string } => {
    return {
        type: CLEAN_AUTH_TOKEN_ACTION_TYPE,
    }
}

export const LOGIN = (loginData: LoginData): LoginAction => {
    return {
        type: LOGIN_ACTION_TYPE,
        data: {
            ...loginData,
        },
    }
}

export const LOGIN_SUCESSFUL = (token: string, user: User) => {
    return {
        token,
        user,
        type: LOGIN_SUCESSFUL_ACTION_TYPE,
    }
}

export const LOGIN_FAILED = error => {
    return {
        type: LOGIN_FAILED_ACTION_TYPE,
        error,
    }
}

export const LOGOUT = () => {
    return {
        type: LOGOUT_ACTION_TYPE,
        token: null,
    }
}

export const SUPPLIER_LOGIN = (loginData: LoginData): LoginAction => {
    return {
        type: SUPPLIER_LOGIN_ACTION_TYPE,
        data: {
            ...loginData,
        },
    }
}
