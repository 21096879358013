"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

exports.__esModule = true;
exports["default"] = void 0;

var _extends2 = _interopRequireDefault(require("@babel/runtime/helpers/extends"));

var _isPromise = _interopRequireDefault(require("is-promise"));

var _SubmissionError = require("./SubmissionError");

var mergeErrors = function mergeErrors(_ref) {
  var asyncErrors = _ref.asyncErrors,
      syncErrors = _ref.syncErrors;
  return asyncErrors && typeof asyncErrors.merge === 'function' ? asyncErrors.merge(syncErrors).toJS() : (0, _extends2["default"])({}, asyncErrors, {}, syncErrors);
};

var executeSubmit = function executeSubmit(submit, fields, props) {
  var dispatch = props.dispatch,
      submitAsSideEffect = props.submitAsSideEffect,
      onSubmitFail = props.onSubmitFail,
      onSubmitSuccess = props.onSubmitSuccess,
      startSubmit = props.startSubmit,
      stopSubmit = props.stopSubmit,
      setSubmitFailed = props.setSubmitFailed,
      setSubmitSucceeded = props.setSubmitSucceeded,
      values = props.values;
  var result;

  try {
    result = submit(values, dispatch, props);
  } catch (submitError) {
    var error = (0, _SubmissionError.isSubmissionError)(submitError) ? submitError.errors : undefined;
    stopSubmit(error);
    setSubmitFailed.apply(void 0, fields);

    if (onSubmitFail) {
      onSubmitFail(error, dispatch, submitError, props);
    }

    if (error || onSubmitFail) {
      // if you've provided an onSubmitFail callback, don't re-throw the error
      return error;
    } else {
      throw submitError;
    }
  }

  if (submitAsSideEffect) {
    if (result) {
      dispatch(result);
    }
  } else {
    if ((0, _isPromise["default"])(result)) {
      startSubmit();
      return result.then(function (submitResult) {
        stopSubmit();
        setSubmitSucceeded();

        if (onSubmitSuccess) {
          onSubmitSuccess(submitResult, dispatch, props);
        }

        return submitResult;
      }, function (submitError) {
        var error = (0, _SubmissionError.isSubmissionError)(submitError) ? submitError.errors : undefined;
        stopSubmit(error);
        setSubmitFailed.apply(void 0, fields);

        if (onSubmitFail) {
          onSubmitFail(error, dispatch, submitError, props);
        }

        if (error || onSubmitFail) {
          // if you've provided an onSubmitFail callback, don't re-throw the error
          return error;
        } else {
          throw submitError;
        }
      });
    } else {
      setSubmitSucceeded();

      if (onSubmitSuccess) {
        onSubmitSuccess(result, dispatch, props);
      }
    }
  }

  return result;
};

var handleSubmit = function handleSubmit(submit, props, valid, asyncValidate, fields) {
  var dispatch = props.dispatch,
      onSubmitFail = props.onSubmitFail,
      setSubmitFailed = props.setSubmitFailed,
      syncErrors = props.syncErrors,
      asyncErrors = props.asyncErrors,
      touch = props.touch,
      persistentSubmitErrors = props.persistentSubmitErrors;
  touch.apply(void 0, fields);

  if (valid || persistentSubmitErrors) {
    var asyncValidateResult = asyncValidate && asyncValidate();

    if (asyncValidateResult) {
      return asyncValidateResult.then(function (asyncErrors) {
        if (asyncErrors) {
          throw asyncErrors;
        }

        return executeSubmit(submit, fields, props);
      })["catch"](function (asyncErrors) {
        setSubmitFailed.apply(void 0, fields);

        if (onSubmitFail) {
          onSubmitFail(asyncErrors, dispatch, null, props);
        }

        return Promise.reject(asyncErrors);
      });
    } else {
      return executeSubmit(submit, fields, props);
    }
  } else {
    setSubmitFailed.apply(void 0, fields);
    var errors = mergeErrors({
      asyncErrors: asyncErrors,
      syncErrors: syncErrors
    });

    if (onSubmitFail) {
      onSubmitFail(errors, dispatch, null, props);
    }

    return errors;
  }
};

var _default = handleSubmit;
exports["default"] = _default;