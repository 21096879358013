import React from 'react'
import { withNamespaces, WithNamespaces } from 'react-i18next'
import { withStyles, WithStyles, MenuItem, FormControl, TextField, Paper, Typography, Toolbar, Button } from '@material-ui/core'
import ListSearchForm from './ListSearchForm'
import { ListMeta } from './Meta'
import { Autocomplete } from '@material-ui/lab'
import RefreshIcon from '@material-ui/icons/Refresh'

const styles = theme => ({
    root: {
        paddingRight: theme.spacing(1),
        paddingLeft: '17px',
        paddingTop: '18px',
    },
    title: {
        flex: '0 0 auto',
    },
    formControl: {
        margin: theme.spacing(1),
        minWidth: 120,
    },
    header: {
        paddingLeft: theme.spacing(2),
        paddingTop: theme.spacing(3),
    },
    refresh: {
        width: '100%',
        textAlign: 'end' as any,
        color: '#40B4BF',
    },
    refreshIcon: {
        marginLeft: theme.spacing(1),
    },
    text: {
        color: theme.palette.primary.main,
    },
})

interface ListToolbarState {
    selectedUserFilter: string
    anchorEl: any
    isSupplierDropdownOpen: boolean
    isCountryDropdownOpen: boolean
    selectedSortFilter: string
    selectedStatusFilter?: string
    showRefreshButton?: boolean
}

export interface ListToolbarProps extends WithNamespaces, WithStyles {
    onSearch: (query: any) => void
    onFilter?: (filter: string) => void
    hideRenderMenu?: boolean
    hideFilter?: boolean
    hideSearch?: boolean
    renderMenu?: (handleSelection: (filter: string) => void) => JSX.Element | JSX.Element[]
    refreshSupplierList?: () => void
    meta?: ListMeta
    showSortFilter?: boolean
    onSortFilter?: (filter: string) => void
    showStatusFilter?: boolean
    statusArray?: any[]
    onResetFilter?: () => void
    showUserFilter?: boolean
    userStatusList?: any[]
    onClickUserStatusFilter?: (filter: string) => void
    tableTitle?: string
    showRefreshButton?: boolean
    onRefresh?: () => void
}

class TableToolBar extends React.Component<ListToolbarProps, ListToolbarState> {
    constructor(props: ListToolbarProps) {
        super(props)
        this.state = {
            anchorEl: null,
            isSupplierDropdownOpen: false,
            isCountryDropdownOpen: false,
            selectedUserFilter: '',
            selectedSortFilter: '',
            selectedStatusFilter: '',
        }
    }

    handleMenu = event => {
        this.setState({ anchorEl: event.currentTarget })
    }

    handleClose = () => {
        this.setState({ anchorEl: null })
    }

    handleSelection = (filter: string) => {
        this.props.onFilter(filter)
        this.handleClose()
    }

    getRfqStatusLabel = status => {
        return this.props?.statusArray?.find(rfqStatus => rfqStatus?.value === status)?.label
    }

    getUserStatusLabel = status => {
        return this.props?.userStatusList?.find(userStatus => userStatus?.value === status)?.label
    }
    render() {
        const {
            classes,
            onSearch,
            hideSearch,
            meta,
            showSortFilter,
            onSortFilter,
            statusArray,
            showStatusFilter,
            onResetFilter,
            showUserFilter,
            userStatusList,
            onClickUserStatusFilter,
            tableTitle,
            showRefreshButton,
            onRefresh,
        } = this.props

        const { selectedSortFilter, selectedStatusFilter, selectedUserFilter } = this.state

        return (
            <React.Fragment>
                {tableTitle && (
                    <Typography variant="h3" className={classes.header}>
                        {tableTitle}
                    </Typography>
                )}

                <Toolbar className={classes.root} style={{ paddingTop: tableTitle ? 0 : 18 }}>
                    {!hideSearch && (
                        <div className={classes.title}>
                            <ListSearchForm initialValues={{ query: meta?.query ? meta?.query : '' }} onSubmit={onSearch} />
                        </div>
                    )}
                    {showStatusFilter && (
                        <FormControl variant="outlined" className={classes.formControl} style={{ marginTop: 0 }}>
                            <Autocomplete
                                id="combo-box-demo"
                                options={statusArray}
                                getOptionLabel={option => (option.label ? option.label : typeof option == 'string' ? option : '')}
                                PaperComponent={({ children }) => <Paper style={{ background: '#fff' }}>{children}</Paper>}
                                getOptionSelected={(option, value: any) => {
                                    if (value?.value) {
                                        return option.value === value.value
                                    } else {
                                        return option.value === value
                                    }
                                }}
                                value={selectedStatusFilter || (meta && meta.filter ? this.getRfqStatusLabel(meta.filter) : '') || ''}
                                style={{ width: 180 }}
                                onChange={(_event, newValue: any) => {
                                    this.setState({ selectedStatusFilter: newValue?.label })
                                    if (newValue?.value) {
                                        this.props.onFilter(newValue?.value)
                                    }
                                }}
                                onInputChange={(_, newInputValue, reason) => {
                                    if (reason === 'clear') {
                                        this.setState({ selectedStatusFilter: '' })
                                        onResetFilter()
                                        return
                                    } else {
                                        this.setState({ selectedStatusFilter: newInputValue })
                                    }
                                }}
                                renderInput={params => <TextField {...params} label="Status" variant="outlined" />}
                            />
                        </FormControl>
                    )}
                    {showUserFilter && (
                        <FormControl variant="outlined" className={classes.formControl} style={{ marginTop: 0 }}>
                            <Autocomplete
                                id="combo-box-demo"
                                options={userStatusList}
                                getOptionLabel={option => (option.label ? option.label : typeof option == 'string' ? option : '')}
                                PaperComponent={({ children }) => <Paper style={{ background: '#fff' }}>{children}</Paper>}
                                getOptionSelected={(option, value: any) => {
                                    if (value?.value) {
                                        return option.value === value.value
                                    } else {
                                        return option.value === value
                                    }
                                }}
                                value={selectedUserFilter || (meta && meta?.userState ? meta?.userState : '') || ''}
                                style={{ width: 180 }}
                                onChange={(_event, newValue: any) => {
                                    this.setState({ selectedUserFilter: newValue?.label })
                                    if (newValue?.value) {
                                        onClickUserStatusFilter(newValue?.value)
                                    }
                                }}
                                onInputChange={(_, newInputValue, reason) => {
                                    if (reason === 'clear') {
                                        this.setState({ selectedUserFilter: '' })
                                        onResetFilter()
                                        return
                                    } else {
                                        this.setState({ selectedUserFilter: newInputValue })
                                    }
                                }}
                                renderInput={params => <TextField {...params} label="Status" variant="outlined" />}
                            />
                        </FormControl>
                    )}
                    {showSortFilter && (
                        <FormControl variant="outlined" className={classes.formControl}>
                            <TextField
                                select
                                label="Sort By"
                                variant="outlined"
                                style={{ width: '163px', marginBottom: '6px' }}
                                value={
                                    selectedSortFilter ||
                                    (meta && meta.sortField === 'createdAt' && meta.sortDirection === -1
                                        ? 'NEWEST'
                                        : meta && meta.sortField === 'createdAt' && meta.sortDirection === 1
                                        ? 'OLDEST'
                                        : '') ||
                                    ''
                                }
                                onChange={event => {
                                    this.setState({ selectedSortFilter: event.target.value as string })
                                    onSortFilter(event.target.value as string)
                                }}
                            >
                                <MenuItem value={'NEWEST'} key={'NEWEST'}>
                                    Newest
                                </MenuItem>
                                <MenuItem value={'OLDEST'} key={'OLDEST'}>
                                    Oldest
                                </MenuItem>
                            </TextField>
                        </FormControl>
                    )}
                    {showRefreshButton && (
                        <div className={`${classes.refresh}`}>
                            <Button variant="text" onClick={onRefresh}>
                                <RefreshIcon className={`${classes.text} ${classes.refreshIcon}`} />
                                <Typography className={`${classes.refreshIcon} ${classes.text}`}>{'Refresh'}</Typography>
                            </Button>
                        </div>
                    )}
                </Toolbar>
            </React.Fragment>
        )
    }
}

export const ListToolbar = withNamespaces()(withStyles(styles)(TableToolBar))
