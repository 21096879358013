import React from 'react'
import SvgIcon from '@material-ui/core/SvgIcon'

export const LocationIcon = props => {
    return (
        <SvgIcon {...props} viewBox="0 0 16 20" x="0px" y="0px" baseProfile="tiny" xmlSpace="preserve">
            <path
                id="location_on_FILL1_wght400_GRAD0_opsz24"
                d="M12,12a2.006,2.006,0,0,0,2-2,2,2,0,0,0-3.412-1.413,1.991,1.991,0,0,0,0,2.825A1.923,1.923,0,0,0,12,12Zm0,9.625a1.132,1.132,0,0,1-.4-.075,1.2,1.2,0,0,1-.35-.2A30.86,30.86,0,0,1,5.8,15.363,9.591,9.591,0,0,1,4,10.2,7.733,7.733,0,0,1,6.413,4.225a8.127,8.127,0,0,1,11.174,0A7.733,7.733,0,0,1,20,10.2a9.591,9.591,0,0,1-1.8,5.163,30.86,30.86,0,0,1-5.45,5.987,1.2,1.2,0,0,1-.35.2,1.132,1.132,0,0,1-.4.075Z"
                transform="translate(-4 -2)"
                fill="#fff"
            />
        </SvgIcon>
    )
}
