import { Document } from "../document";
import { RFQ, RFQLineItem, RFQStatus } from "./rfq/RFQ";

export enum SupplierQuoteStatus {
    QUOTED = 'Quoted',
    NO_QUOTE = 'No Quote',
    NO_RESPONSE = 'No Response'
}


export interface SupplierQuoteDetail {
    _id: string
    rfqId: string
    document: Document
    supplierId: String
    supplierName: String
    rfq: RFQ
    rfqLineItem?: RFQLineItem
    tem
    rfqSentDate: Date
    quoteUploadedDate: Date
    supplierQuoteStatus: SupplierQuoteStatus
    supplierQuoteResponse: string
    numberLines: number
    rfqStatus: RFQStatus
}

export interface VerificationList {
    quoteMeetsCriteria: boolean
    readNMeetRequirement: boolean
}

export interface SupplierQuoteUpload {
    document: Document
    verification?: VerificationList
}

export const SupplierQuoteStatusOptions =
[
    { value: SupplierQuoteStatus.QUOTED, label: 'Quoted' },
    { value: SupplierQuoteStatus.NO_QUOTE, label: 'No Quote' },
    { value: SupplierQuoteStatus.NO_RESPONSE, label: 'No Response' }
]