import { Order, LOGOUT_ACTION_TYPE } from '@syncfab/machine'
import { GET_ORDERS, GET_ORDER, ADD_DOCUMENT_ORDER, REMOVE_DOCUMENT_ORDER, GET_PART_BY_TRACKID } from './Actions'

export type OrderReducerType = {
    orders?: Order[] | null
    order?: Order
    partDetails?: any
}

const initialState: OrderReducerType = {
    orders: null,
    order: null,
    partDetails: null,
}

export const OrderReducer = (state = initialState, action: any) => {
    switch (action.type) {
        case GET_ORDERS.types.TRIGGER:
            return {
                ...state,
                orders: null,
                order: null,
            }
        case GET_ORDERS.types.SUCCESSFULL:
            return {
                ...state,
                orders: action.data,
                order: null,
            }
        case GET_ORDER.types.TRIGGER:
            return {
                ...state,
                orders: null,
                order: null,
            }
        case GET_ORDER.types.SUCCESSFULL:
            return {
                ...state,
                order: action.data,
                orders: null,
            }
        case ADD_DOCUMENT_ORDER.types.SUCCESSFULL:
            return {
                ...state,
                order: {
                    ...state.order,
                    documents: [...state.order.documents, action.data.document],
                },
            }
        case REMOVE_DOCUMENT_ORDER.types.SUCCESSFULL:
            return {
                ...state,
                order: {
                    ...state.order,
                    documents: state.order.documents.filter(document => document._id !== action.data.documentId),
                },
            }

        case GET_PART_BY_TRACKID.types.TRIGGER:
            return {
                ...state,
                partDetails: null,
            }
        case GET_PART_BY_TRACKID.types.SUCCESSFULL:
            return {
                ...state,
                partDetails: action.data,
            }

        case LOGOUT_ACTION_TYPE:
            return {
                ...state,
                ...initialState,
            }

        default:
            return state
    }
}
