import {
  Badge,
  Divider,
  Grid,
  Menu,
  Theme,
  Typography,
} from '@material-ui/core';
import React, { FC } from 'react';
import NotificationsIcon from '@material-ui/icons/Notifications';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import { Link, RouteComponentProps, withRouter } from 'react-router-dom';
import { ActionMenu } from '../../ui';
import {
  useAcknowledgeNotificationMutation,
  useGetNotificationsQuery,
} from '../../../application';
import { getNotificationContent } from '../Notification';
import { DateFormatter } from '../../util';

const useStyles = makeStyles((_theme: Theme) =>
  createStyles({
    menuPaper: {
      padding: '16px',
      width: '600px',
    },
    link: {
      color: '#40B4BF',
      'text-decoration': 'none',
      cursor: 'pointer',
      fontWeight: 'bold' as any,
    },
  })
);

export const NotificationsRaw: FC<RouteComponentProps> = ({ history }) => {
  const classes = useStyles();

  const {
    data: notificationsData,
    isLoading: isNotificationsDataLoading,
    refetch: refetchNotifications,
  } = useGetNotificationsQuery(null, { pollingInterval: 30 * 1000 });

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const [acknowledgeNotification] = useAcknowledgeNotificationMutation();

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleNotificationClick = async notificationId => {
    await acknowledgeNotification(notificationId).unwrap();
    refetchNotifications();
    handleClose();
  };

  const NotificationEntry = ({
    description,
    icon,
    linkText,
    linkDestination,
    date,
    notificationId,
  }) => {
    return (
      <Grid container direction="row">
        <Grid item xs={2}>
          {icon}
        </Grid>
        <Grid item xs={9} container direction="column">
          <Grid container>
            <Grid item xs={4}>
              <Link
                className={classes.link}
                to={`/${linkDestination}`}
                onClick={() => {
                  handleClose();
                }}
              >
                {linkText}
              </Link>
              <Typography variant="body2">
                {DateFormatter.format(date)}
              </Typography>
            </Grid>
            <Grid item xs={8}>
              <div>{description}</div>
            </Grid>
          </Grid>
        </Grid>
        <Grid xs={1}>
          <ActionMenu
            itemKey={notificationId}
            handleMenuOptionClick={async (option, key) => {
              if (option === 'View') {
                history.push(`/${linkDestination}`);
                await handleNotificationClick(key);
              }
            }}
            menuOptions={['View']}
          />
        </Grid>
      </Grid>
    );
  };

  return (
    <>
      {isNotificationsDataLoading && (
        <NotificationsIcon style={{ marginRight: '40px' }} />
      )}
      {!!notificationsData && (
        <>
          <Badge
            anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
            badgeContent={notificationsData.length}
            color="error"
            style={{ marginRight: '40px' }}
            onClick={handleClick}
            overlap="rectangular"
          >
            <NotificationsIcon />
          </Badge>
          <Menu
            id="menu-notifications"
            anchorEl={anchorEl}
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            open={!!anchorEl}
            onClose={handleClose}
            classes={{ paper: classes.menuPaper }}
            variant="menu"
          >
            <Typography variant="h6">Action Item Notifications</Typography>
            {notificationsData.map(notification => (
              <div key={notification._id}>
                <Divider
                  style={{
                    backgroundColor: '#566E8',
                    marginTop: '8px',
                    marginBottom: '8px',
                  }}
                />
                <NotificationEntry {...getNotificationContent(notification)} />
              </div>
            ))}
          </Menu>
        </>
      )}
    </>
  );
};

export const Notifications = withRouter(NotificationsRaw)
