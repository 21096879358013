import React from 'react'
import SvgIcon from '@material-ui/core/SvgIcon'

export const ProductionStartIcon = props => {
    return (
        <SvgIcon {...props} viewBox="0 0 40 40" x="0px" y="0px" baseProfile="tiny" xmlSpace="preserve">
            <path
                fill="#FFFFFF"
                d="M23.3,9.9l-2.7-0.5c-0.1-0.6-0.4-1.1-0.7-1.7l1.5-2.1c0.1-0.3,0.1-0.7-0.1-0.9l-1.9-2c-0.2-0.2-0.6-0.3-0.9-0.1
				l-2.2,1.5c-0.5-0.3-1.1-0.5-1.7-0.7l-0.5-2.6c-0.1-0.4-0.4-0.6-0.7-0.6h-2.8c-0.3,0-0.6,0.2-0.7,0.6L9.4,3.4
				C8.8,3.6,8.3,3.8,7.7,4.1L5.6,2.6C5.3,2.4,4.9,2.5,4.7,2.7l-2,2C2.5,4.9,2.4,5.3,2.6,5.6l1.5,2.1C3.8,8.3,3.6,8.8,3.4,9.4L0.8,9.9
				c-0.4,0.1-0.6,0.4-0.6,0.7v2.8c0,0.3,0.2,0.6,0.6,0.7l2.6,0.5c0.2,0.6,0.4,1.2,0.7,1.7l-1.5,2.2c-0.2,0.3-0.1,0.7,0.1,0.9l2,1.9
				c0.2,0.3,0.6,0.3,0.9,0.1L7.7,20c0.6,0.2,1.1,0.5,1.7,0.7l0.5,2.7c0.1,0.3,0.4,0.5,0.7,0.5h2.8c0.3,0,0.6-0.2,0.7-0.5l0.5-2.7
				c0.6-0.1,1.2-0.4,1.7-0.7l2.2,1.5c0.3,0.1,0.7,0.1,0.9-0.1l1.9-1.9c0.3-0.2,0.3-0.6,0.1-0.9L20,16.3c0.2-0.5,0.5-1.1,0.7-1.7
				l2.7-0.5c0.3-0.1,0.5-0.4,0.5-0.7v-2.8C23.8,10.3,23.6,10,23.3,9.9z M12,6.7c3,0,5.3,2.4,5.3,5.3S15,17.3,12,17.3S6.7,15,6.7,12
				S9,6.7,12,6.7z"
            />
        </SvgIcon>
    )
}
