import { ACTIVATE_USER_FAILED_ACTION_TYPE, ACTIVATE_USER_SUCESSFUL_ACTION_TYPE, SIGNUP_SUCESSFUL_ACTION_TYPE } from '../../view/signup/Actions'
import { LOGIN_SUCESSFUL_ACTION_TYPE, User, LOGOUT_ACTION_TYPE } from '@syncfab/machine'
import { GET_LOGGED_USER, USER_PASSWORD, USER_UPDATE, USER_EMAIL_UPDATE, USER_EMAIL_VALIDATE, FORGOT_PASSWORD, GET_USER, LIST_USERS, SAVE_PREVIOUSURL, RESET_USER } from './Actions'
import { ListMeta, SortDirection } from '../../layout/list/Meta'

export type UserReducerType = {
    activationError?: string
    active: boolean
    loggedUser?: User
    user?: User
    users?: User[]
    updated?: boolean
    updateUserError?: string
    updatePasswordError?: string
    updateEmailError?: string
    updateEmailValidationError?: string
    forgotPasswordEmail?: string
    previousUrl?: string
    meta: ListMeta
    total: number
}

const initialState: UserReducerType = {
    active: false,
    meta: {
        page: 0,
        size: 5,
        sortField: '_id',
        sortDirection: SortDirection.DESC,
        loginRole: 'OPERATOR',
    },
    total: 0,
}

export const UserReducer = (state = initialState, action: any) => {
    switch (action.type) {
        case SIGNUP_SUCESSFUL_ACTION_TYPE:
            return {
                ...state,
                user: action.user,
            }
        case ACTIVATE_USER_FAILED_ACTION_TYPE:
            return {
                ...state,
                activationError: action.error,
                active: false,
            }
        case ACTIVATE_USER_SUCESSFUL_ACTION_TYPE:
            return {
                ...state,
                activationError: null,
                active: true,
            }
        case USER_PASSWORD.types.FAILED:
            return {
                ...state,
                updatePasswordError: action.error,
            }
        case USER_EMAIL_UPDATE.types.FAILED:
            return {
                ...state,
                updateEmailError: action.error,
            }
        case USER_EMAIL_UPDATE.types.FAILED:
            return {
                ...state,
                updateEmailError: null,
            }
        case USER_UPDATE.types.FAILED:
            return {
                ...state,
                updateUserError: action.error,
            }
        case USER_UPDATE.types.SUCCESSFULL:
            return {
                ...state,
                updateUserError: null,
            }
        case USER_PASSWORD.types.SUCCESSFULL:
            return {
                ...state,
                updatePasswordError: null,
            }
        case GET_LOGGED_USER.types.SUCCESSFULL:
            return {
                ...state,
                loggedUser: action.data,
            }
        case LOGIN_SUCESSFUL_ACTION_TYPE:
            return {
                ...state,
                loggedUser: action.user,
            }

        case USER_EMAIL_VALIDATE.types.SUCCESSFULL:
            return {
                ...state,
                updated: true,
            }
        case USER_EMAIL_VALIDATE.types.FAILED:
            return {
                ...state,
                updateEmailValidationError: action.error,
            }
        case FORGOT_PASSWORD.types.SUCCESSFULL:
            return {
                ...state,
                forgotPasswordEmail: action.data.email,
            }
        case GET_USER.types.TRIGGER:
            return {
                ...state,
                // users: null,
                user: null,
            }
        case GET_USER.types.SUCCESSFULL:
            return {
                ...state,
                user: action.data,
            }
        case LIST_USERS.types.TRIGGER:
            return {
                ...state,
                meta: action.data.meta,
                user: null,
                users: null,
                total: 0,
                isLoading: true,
            }
        case LIST_USERS.types.SUCCESSFULL:
            return {
                ...state,
                users: action.data.data,
                meta: { ...state.meta, total: action.data.total },
                user: null,
                isLoading: false,
            }
        case LOGOUT_ACTION_TYPE:
            return {
                ...state,
                activationError: null,
                active: false,
                loggedUser: null,
                user: null,
                users: null,
                updated: false,
                updateUserError: null,
                updatePasswordError: null,
                updateEmailError: null,
                updateEmailValidationError: null,
                forgotPasswordEmail: null,
            }
        case SAVE_PREVIOUSURL.types.TRIGGER:
            return {
                ...state,
                previousUrl: action.data.data,
            }

        case SAVE_PREVIOUSURL.types.SUCCESSFULL:
            return {
                ...state,
                previousUrl: action.data.data,
            }
        case RESET_USER.types.TRIGGER:
            return {
                ...state,
                user: null,
            }
        default:
            return state
    }
}
