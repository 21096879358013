import { createStore, applyMiddleware, compose, combineReducers } from 'redux'
import { reducer as form } from 'redux-form/immutable'
import thunk from 'redux-thunk'
import createSagaMiddleware, { SagaMiddleware } from 'redux-saga'
import { FormStateMap } from 'redux-form'
import {
    handleError,
    I18NReducerType,
    I18NReducer,
    PersistanceService,
    AuthReducerType,
    AuthReducer,
    RouterReducerType,
    RouterReducer,
    FeedbackReducerType,
    FeedbackReducer,
    api,
} from '@syncfab/machine'
import createGateway, { Gateway } from '../components/gateway'
import signupSaga from '../view/signup/Sagas'
import { UserReducerType, UserReducer } from '../components/user/Reducer'
import userSaga from '../components/user/Sagas'
import documentSaga from '../components/document/Sagas'
import { CompanyReducerType, CompanyReducer } from '../components/company/Reducer'
import { LayoutReducerType, LayoutReducer } from '../layout/Reducer'
import companySaga from '../components/company/Sagas'
import { DocumentReducerType, DocumentReducer } from '../components/document/Reducer'
import { RFQReducerType, RFQReducer } from '../components/quote/rfq/Reducer'
import quoteSaga from '../components/quote/rfq/Sagas'
import orderSaga from '../components/order/Sagas'
import { OrderReducerType, OrderReducer } from '../components/order/Reducer'
import { PartReducerType, PartReducer } from '../components/part/Reducer'
import partSaga from '../components/part/Sagas'
import { BlockchainReducerType, BlockchainReducer } from '../components/blockchain/Reducer'
import blockchainSaga from '../components/blockchain/Sagas'
import analyticsSaga from '../utils/Analytics/Sagas'
import supplierSaga from '../components/supplier/Sagas'
import { SupplierReducerType, SupplierReducer } from '../components/supplier/Reducer'
import transactionSaga from '../components/transaction/Saga'
import { TransactionReducer, TransactionReducerType } from '../components/transaction/Reducer'
import { DashboardReducerType, DashboardReducer } from '../components/dashboard/Reducer'
import dashboardSaga from '../components/dashboard/Sagas'
import { environment } from './environment'
import { authorizationService, persistanceService, i18nService, routerService, analyticsService } from './services'
import { QuoteListParameters } from '..'
import QuoteListParametersSlice from './QuoteListParametersSlice'
import { ExternalPurchaseOrderQuery } from "@syncfab/machine/src/components/list/ExternalPurchaseOrderQuery";
import ExternalPurchaseOrderListParametersSlice from "./ExternalPurchaseOrderListParametersSlice";

export type State = {
    i18n: I18NReducerType
    auth: AuthReducerType
    user: UserReducerType
    router: RouterReducerType
    form: FormStateMap
    company: CompanyReducerType
    layout: LayoutReducerType
    feedback: FeedbackReducerType
    document: DocumentReducerType
    rfq: RFQReducerType
    quoteListParameters: QuoteListParameters
    externalPurchaseOrderListParameters: ExternalPurchaseOrderQuery
    part: PartReducerType
    order: OrderReducerType
    blockchain: BlockchainReducerType
    supplier: SupplierReducerType
    transaction: TransactionReducerType
    dashboard: DashboardReducerType
}

const gateway: Gateway = createGateway(environment, authorizationService, analyticsService)

const configureSagas = (sagaMiddleware: SagaMiddleware<any>) => {
    // Sagas
    sagaMiddleware.run(signupSaga)
    sagaMiddleware.run(userSaga)
    sagaMiddleware.run(companySaga)
    sagaMiddleware.run(documentSaga)
    sagaMiddleware.run(quoteSaga)
    sagaMiddleware.run(orderSaga)
    sagaMiddleware.run(partSaga)
    sagaMiddleware.run(blockchainSaga)
    sagaMiddleware.run(analyticsSaga)
    sagaMiddleware.run(supplierSaga)
    sagaMiddleware.run(transactionSaga)
    sagaMiddleware.run(dashboardSaga)
}

const getInitialState = (persistanceService: PersistanceService): Partial<State> => {
    return persistanceService.getSavedState() || {}
}

const sagaMiddleware = createSagaMiddleware({
    context: {
        gateway,
        handleError,
    },
})
const middlewares = [sagaMiddleware, api.middleware, thunk]
const windowIfDefined = typeof window === 'undefined' ? {} : (window as any)
const composeEnhancers = windowIfDefined.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose

// apply the middleware
const middleware = applyMiddleware(...middlewares)

const rootStore = combineReducers({
    form,
    i18n: I18NReducer,
    auth: AuthReducer,
    user: UserReducer,
    router: RouterReducer,
    company: CompanyReducer,
    layout: LayoutReducer,
    feedback: FeedbackReducer,
    document: DocumentReducer,
    rfq: RFQReducer,
    part: PartReducer,
    order: OrderReducer,
    blockchain: BlockchainReducer,
    supplier: SupplierReducer,
    transaction: TransactionReducer,
    dashboard: DashboardReducer,
    quoteListParameters: QuoteListParametersSlice,
    externalPurchaseOrderListParameters: ExternalPurchaseOrderListParametersSlice,
    [api.reducerPath]: api.reducer,
})

// create the store
const store = createStore(rootStore, getInitialState(persistanceService), composeEnhancers(middleware))
configureSagas(sagaMiddleware)

i18nService.load()
i18nService.subscribe(store)
routerService.subscribe(store)
authorizationService.subscribe(store)
routerService.getHistory().listen((location, action) => {
    if (action === 'PUSH') {
        analyticsService.pageChange(location)
    }
})

export { store }
export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch