import axios, { AxiosPromise } from 'axios'
import { PartDAPP, AuthorizationService, Environment, EnvironmentVariable } from '@syncfab/machine'
import { ListMeta } from '../../layout/list/Meta'

export class PartAPI {
    constructor(private environment: Environment, private authorizationService: AuthorizationService) {}

    public findParts = (companyId: string): (() => AxiosPromise) => {
        const url = this.environment.get(EnvironmentVariable.API_LAYER_URL)
        const authorization = `Bearer ${this.authorizationService.getAuthToken()}`
        return () => {
            return axios.get(`${url}/parts/v2`, {
                params: {
                    companyId: companyId,
                },
                headers: {
                    authorization,
                },
            })
        }
    }

    public list = (meta: ListMeta): (() => AxiosPromise) => {
        const url = this.environment.get(EnvironmentVariable.API_LAYER_URL)
        const authorization = `Bearer ${this.authorizationService.getAuthToken()}`
        return () => {
            return axios.get(`${url}/parts/v2`, {
                params: meta,
                headers: {
                    authorization,
                },
            })
        }
    }
    public findByIds = (ids: string[]): (() => AxiosPromise) => {
        const url = this.environment.get(EnvironmentVariable.API_LAYER_URL)
        const authorization = `Bearer ${this.authorizationService.getAuthToken()}`
        return () => {
            return axios.get(`${url}/parts/ids`, {
                params: {
                    ids: ids.join(','),
                },
                headers: {
                    authorization,
                },
            })
        }
    }

    public findPart = (companyId: string, partid: string): (() => AxiosPromise) => {
        const url = this.environment.get(EnvironmentVariable.API_LAYER_URL)
        const authorization = `Bearer ${this.authorizationService.getAuthToken()}`
        return () => {
            return axios.get(`${url}/parts/v2/${partid}`, {
                params: {
                    companyId: companyId,
                },
                headers: {
                    authorization,
                },
            })
        }
    }
    public save = (part: Partial<PartDAPP>): (() => AxiosPromise) => {
        const url = this.environment.get(EnvironmentVariable.API_LAYER_URL)
        const authorization = `Bearer ${this.authorizationService.getAuthToken()}`
        return () => {
            return axios.post(`${url}/parts/v2`, part, {
                headers: {
                    authorization,
                },
            })
        }
    }

    public update = (partId: string, part: Partial<PartDAPP>): (() => AxiosPromise) => {
        const url = this.environment.get(EnvironmentVariable.API_LAYER_URL)
        const authorization = `Bearer ${this.authorizationService.getAuthToken()}`
        return () => {
            return axios.patch(`${url}/parts/v2/${partId}`, part, {
                headers: {
                    authorization,
                },
            })
        }
    }

    public tokenizepart = (data: any): (() => AxiosPromise) => {
        const url = this.environment.get(EnvironmentVariable.API_LAYER_URL)
        const authorization = `Bearer ${this.authorizationService.getAuthToken()}`
        return () => {
            return axios.post(`${url}/tokenization/part`, data.data.data, {
                headers: {
                    authorization,
                },
            })
        }
    }

    public deleteParts = (ids: string[]): (() => AxiosPromise) => {
        const url = this.environment.get(EnvironmentVariable.API_LAYER_URL)
        const authorization = `Bearer ${this.authorizationService.getAuthToken()}`
        return () => {
            return axios.delete(`${url}/parts/deleteparts`, {
                params: {
                    ids: ids.join(','),
                },
                headers: {
                    authorization,
                },
            })
        }
    }

    public tokenizefailedpart = (data: any): (() => AxiosPromise) => {
        const url = this.environment.get(EnvironmentVariable.API_LAYER_URL)
        const authorization = `Bearer ${this.authorizationService.getAuthToken()}`
        return () => {
            return axios.post(`${url}/tokenization/failedPart`, data.data.data, {
                headers: {
                    authorization,
                },
            })
        }
    }

    public editPart = (id: string, request?: string): (() => AxiosPromise) => {
        const url = this.environment.get(EnvironmentVariable.API_LAYER_URL)
        const authorization = `Bearer ${this.authorizationService.getAuthToken()}`
        return () => {
            return axios.put(
                `${url}/parts/edit/${id}`,
                { request },
                {
                    headers: {
                        authorization,
                    },
                }
            )
        }
    }

    public getpartHistory = (partId: string): (() => AxiosPromise) => {
        const url = this.environment.get(EnvironmentVariable.API_LAYER_URL)
        const authorization = `Bearer ${this.authorizationService.getAuthToken()}`
        return () => {
            return axios.get(`${url}/parts/getPartHistory`, {
                params: {
                    partId: partId,
                },
                headers: {
                    authorization,
                },
            })
        }
    }
    public getEthernetLink = (): (() => AxiosPromise) => {
        const url = this.environment.get(EnvironmentVariable.API_LAYER_URL)
        const authorization = `Bearer ${this.authorizationService.getAuthToken()}`
        return () => {
            return axios.get(`${url}/parts/viewNFTonEtherscan`, {
                headers: {
                    authorization,
                },
            })
        }
    }
}
