import React from 'react'
import SvgIcon from '@material-ui/core/SvgIcon'

export const UserContactIcon = props => {
    return (
        <SvgIcon {...props} viewBox="0 0 16 20" x="0px" y="0px" baseProfile="tiny" xmlSpace="preserve">
            <path
                fill={props.fill || '#FFFFFF'}
                d="M12,14a2,2,0,0,0,1.413-3.413,2,2,0,1,0-2.825,2.825A1.923,1.923,0,0,0,12,14ZM8,18h8v-.575a2,2,0,0,0-1.225-1.85,7.567,7.567,0,0,0-1.337-.425,6.968,6.968,0,0,0-2.876,0,7.567,7.567,0,0,0-1.337.425A2,2,0,0,0,8,17.425Zm10,4H6a2.006,2.006,0,0,1-2-2V4a1.927,1.927,0,0,1,.588-1.413A1.925,1.925,0,0,1,6,2h7.175a1.978,1.978,0,0,1,.763.15,1.945,1.945,0,0,1,.637.425l4.85,4.85a1.945,1.945,0,0,1,.425.637,1.978,1.978,0,0,1,.15.763V20a2,2,0,0,1-2,2Z"
                transform="translate(-4 -2)"
            />
        </SvgIcon>
    )
}
