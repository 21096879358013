import {
    ActivityType,
    DateFormatter,
    EntityType,
    HeaderNavLink,
    Loader,
    PDFViewerInPage,
    SupplierPurchaseOrderDetail,
    useGetSupplierPurchaseOrderQuery,
    useStandardStyles
} from "@syncfab/machine";
import Helmet from "react-helmet";
import { Box, Button, Dialog, DialogContent, Divider, Grid, Paper, Typography, useTheme } from "@material-ui/core";
import React, { FC, useState } from "react";
import { useParams } from "react-router";
import { useAppSelector } from "@syncfab/mms/src/application/hooks";
import InfoIcon from "@material-ui/icons/Info";
import TodayIcon from "@material-ui/icons/Today";
import { ChangeRequest } from "../quotes/details/components/ChangeRequest";
import { ConfirmSupplierPOForm } from "./components/ConfirmSupplierPOForm";
import { useLocation } from "react-router-dom";

export const ConfirmSupplierPurchaseOrder: FC = (() => {

    const standardClasses = useStandardStyles()
    const theme = useTheme()

    const { supplierPurchaseOrderId } = useParams<{ supplierPurchaseOrderId?: string }>()
    const location = useLocation()
    const authToken = useAppSelector(state => state.auth.authToken)
    const docURL = `/purchaseOrders/supplier/${supplierPurchaseOrderId}/generateSupplierPurchaseOrderDocument`

    const [isChangeRequestDialogOpen, setIsChangeRequestDialogOpen] = useState<boolean>(false)
    const [isConfirmPODialogOpen, setIsConfirmPODialogOpen] = useState<boolean>(false)

    const {
        data: supplierPOData,
        isLoading: isSupplierPODataLoading,
        // refetch,
        isError: isSupplierPODataError,
        error: supplierPODataError
    } = useGetSupplierPurchaseOrderQuery(supplierPurchaseOrderId)

    //const dispatch = useAppDispatch()
    const cancelChangeRequestDialog = () => {
        setIsChangeRequestDialogOpen(false)
    }

    const completeChangeRequest = () => {
        setIsChangeRequestDialogOpen(false)
    }

    const cancelConfirmPODialog = () => {
        setIsConfirmPODialogOpen(false)
    }

    const completeConfirmPODialog = () => {
        setIsConfirmPODialogOpen(false)
    }

    const isConfirmed = (supplierPO: SupplierPurchaseOrderDetail): boolean => {
        const issueDate = new Date(supplierPO.issueDate)

        const confirmationActivityDates =
            supplierPO.activity.filter(activity => activity.activityType === ActivityType.CONFIRM_SUPPLIER_PURCHASE_ORDER)
                .map(activity => new Date(activity.activityAt))

        if (confirmationActivityDates.length === 0) {
            return false
        }
        // find most recent confirmation activity
        const mostRecentConfirmationDate = confirmationActivityDates.reduce((accum: Date, activityDate) => {
            return activityDate.getTime() > accum.getTime() ? activityDate : accum
        }, new Date('1900-01-01'))

        return mostRecentConfirmationDate.getTime() > issueDate.getTime()
    }

    if (isSupplierPODataLoading) {
        return <Loader/>
    }

    if (isSupplierPODataError) {
        // @ts-ignore
        const errorStatusCode = supplierPODataError.status
        return (
            <div className={standardClasses.centeredAlignedContainer}>
                {errorStatusCode === 401 ?
                    (
                        <Typography style={{ fontSize: 32, fontWeight: 'bold' }}>
                            You are not authorized to view this Purchase Order.
                        </Typography>
                    ) : (
                        <Typography style={{ fontSize: 32, fontWeight: 'bold' }}>
                            An error has occurred.
                        </Typography>
                    )
                }
            </div>
        )
    }

    if (!!supplierPOData) {
        return (
            <div className={standardClasses.centeredAlignedContainer}>
                <Helmet>
                    <title>Purchase Orders</title>
                </Helmet>
                <HeaderNavLink
                    linkTo={location.search?.includes('returnToPO') ?
                        `/supplierPurchaseOrders/${supplierPurchaseOrderId}` : '/supplierPurchaseOrders'
                    }
                    linkText={location.search?.includes('returnToPO') ? 'BACK TO PO' : 'BACK TO PO LIST'} />
                <Grid container className={standardClasses.header}>
                    <Typography style={{ fontSize: 32, fontWeight: 'bold' }}>Review Purchase Order</Typography>
                </Grid>
                <Grid container justifyContent='flex-start' spacing={2}>
                    <Grid item xs={12} md={8}>
                        <PDFViewerInPage docURL={docURL} authToken={authToken} />
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <Paper className={standardClasses.paper} style={{ marginTop: '16px' }}>
                            <Grid container direction='column' style={{ padding: '16px' }}>
                                <Typography style={{ fontSize: 20, fontWeight: 'bold' }}>PO Number</Typography>
                                <Typography style={{ fontSize: 14 }}>{supplierPOData.number}</Typography>
                                <Box style={{ paddingTop: '16px', display: 'flex' }}>
                                    <InfoIcon fontSize='medium' style={{ color: '#A0AEC0' }}/>
                                    <Typography style={{ color: '#A0AEC0', fontWeight: 'bold', marginLeft: '8px' }}>
                                        Status: <span style={{ color: '#FFBA00' }}>{supplierPOData.po.status}</span>
                                    </Typography>
                                </Box>
                                <Box style={{ paddingTop: '16px', display: 'flex' }}>
                                    <TodayIcon fontSize='medium' style={{ color: '#A0AEC0' }}/>
                                    <Typography style={{ color: '#A0AEC0', fontWeight: 'bold', marginLeft: '8px' }}>
                                        PO Issued:&nbsp;
                                        <span style={{ color: '#F3F3F3' }}>
                                            {!!supplierPOData.issueDate ? DateFormatter.format(
                                                new Date(supplierPOData.issueDate)) : '--'}
                                        </span>
                                    </Typography>
                                </Box>
                            </Grid>
                            <Divider style={{ width: '100%', color: "#FFFFFF40" }}/>
                            <Grid container direction='column' style={{ padding: '16px' }}>
                                { !isConfirmed(supplierPOData) &&
                                    <Typography style={{ fontSize: 20, fontWeight: 'bold', marginBottom: '16px' }}>
                                        Confirm Purchase Order
                                    </Typography>
                                }
                                <Grid container direction='column' alignItems='center'>
                                    { !isConfirmed(supplierPOData) &&
                                        <Button
                                            variant='contained'
                                            style={{
                                                backgroundColor: theme.palette.primary.main,
                                                color: '#FFFFFF',
                                                width: '240px'
                                            }}
                                            onClick={() => {
                                                setIsConfirmPODialogOpen(true)
                                            }}
                                        >
                                            CONFIRM PURCHASE ORDER
                                        </Button>
                                    }
                                    <Button
                                        variant='outlined'
                                        style={{
                                            borderColor: theme.palette.primary.main,
                                            color: theme.palette.primary.main,
                                            width: '180px',
                                            marginTop: '16px'
                                        }}
                                        onClick={() => {
                                            setIsChangeRequestDialogOpen(true)
                                        }}
                                    >
                                        REQUEST CHANGE
                                    </Button>
                                </Grid>
                            </Grid>
                        </Paper>
                    </Grid>
                </Grid>
                <Dialog open={isConfirmPODialogOpen} onClose={cancelConfirmPODialog} fullWidth>
                    <DialogContent>
                        <ConfirmSupplierPOForm cancel={cancelConfirmPODialog} complete={completeConfirmPODialog} supplierPOId={supplierPOData._id} />
                    </DialogContent>
                </Dialog>
                <Dialog open={isChangeRequestDialogOpen} onClose={cancelChangeRequestDialog} fullWidth>
                    <DialogContent>
                        <ChangeRequest
                            cancel={cancelChangeRequestDialog}
                            complete={completeChangeRequest}
                            entityType={EntityType.PURCHASEORDER}
                            entityId={supplierPOData.po._id}
                        />
                    </DialogContent>
                </Dialog>
            </div>
        )}
    return null
})