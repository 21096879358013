export enum SortDirection {
    ASC = 1,
    DESC = -1,
}

export type ListMeta = {
    page: number
    size: number
    filter?: string
    query?: string
    sortField?: string
    sortDirection?: SortDirection
    total?: number
    search?: string
    userState?: string
    loginRole?: string
    companyId?: string
}
