import React from 'react'
import SvgIcon from '@material-ui/core/SvgIcon'

export const InvoiceIcon = props => {
    return (
        <SvgIcon {...props} viewBox="0 0 24 24" x="0px" y="0px" baseProfile="tiny" xmlSpace="preserve">
            <path fill="#FFFFFF" d="M20.42,5.45h3.41l-5.96-5.29v3.22C17.87,4.53,18.99,5.45,20.42,5.45L20.42,5.45z" />
            <path
                fill="#FFFFFF"
                d="M10.9,3.26c-0.23,0-0.41,0.05-0.52,0.16c-0.12,0.1-0.17,0.21-0.17,0.36c0,0.16,0.06,0.26,0.12,0.36
				c0.12,0.1,0.29,0.16,0.52,0.26L10.9,3.26z"
            />
            <path
                fill="#FFFFFF"
                d="M12.12,5.85c0-0.16-0.06-0.26-0.17-0.36c-0.12-0.1-0.29-0.16-0.58-0.26v1.14c0.23,0,0.41-0.1,0.52-0.16
				C12.07,6.11,12.12,6.01,12.12,5.85L12.12,5.85z"
            />
            <path
                fill="#FFFFFF"
                d="M20.42,6.02c-1.77,0-3.15-1.19-3.15-2.65V0H7.99C7.04,0,6.31,0.69,6.31,1.5v17.19c0,0.84,0.78,1.5,1.68,1.5
				h14.32c0.95,0,1.68-0.69,1.68-1.5V6.02L20.42,6.02z M9.73,4.66C9.5,4.45,9.38,4.19,9.38,3.83c0-0.36,0.12-0.67,0.41-0.88
				c0.29-0.26,0.64-0.36,1.11-0.36V2.22h0.47v0.31c0.52,0.05,1.05,0.21,1.52,0.52l-0.47,0.57c-0.29-0.21-0.64-0.31-1.05-0.36v1.19
				c0.52,0.1,0.93,0.26,1.23,0.47C12.82,5.13,13,5.39,13,5.75s-0.17,0.67-0.47,0.93c-0.29,0.26-0.7,0.36-1.17,0.36v0.47H10.9V7.05
				c-0.64-0.05-1.23-0.31-1.75-0.67L9.68,5.8c0.41,0.31,0.82,0.52,1.23,0.57V5.13C10.38,5.02,9.97,4.87,9.73,4.66L9.73,4.66z
				M21.63,18.19H8.81c-0.17,0-0.34-0.11-0.34-0.31c0-0.15,0.13-0.31,0.34-0.31h12.81c0.17,0,0.34,0.11,0.34,0.31
				C21.93,18.03,21.8,18.19,21.63,18.19z M21.63,16.04H8.81c-0.17,0-0.34-0.11-0.34-0.31c0-0.15,0.13-0.31,0.34-0.31h12.81
				c0.17,0,0.34,0.11,0.34,0.31C21.93,15.89,21.8,16.04,21.63,16.04z M21.62,9.02c0.17,0,0.34,0.11,0.34,0.31l0,4.34h-0.04
				c0,0.15-0.13,0.31-0.34,0.31H8.68c-0.17,0-0.34-0.11-0.34-0.31V9.32c0-0.15,0.13-0.31,0.34-0.31"
            />
            <path fill="#FFFFFF" d="M8.99,9.63h12.29v3.76H8.99L8.99,9.63z" />
            <path
                fill="#FFFFFF"
                d="M18.3,20.76v1.84c0,0.84-0.78,1.5-1.68,1.5l-14.93,0C0.73,24.1,0,23.41,0,22.6V5.41
				c0-0.84,0.78-1.5,1.68-1.5h3.94v14.77c0,1.15,1.04,2.07,2.33,2.07L18.3,20.76z"
            />
        </SvgIcon>
    )
}
