import React from 'react'
import SvgIcon from '@material-ui/core/SvgIcon'

export const TaskIcon = props => {
    return (
        <SvgIcon {...props} viewBox="0 0 24 24" x="0px" y="0px" baseProfile="tiny" xmlSpace="preserve">
            <path
                id="task_FILL1_wght400_GRAD0_opsz20"
                d="M5.5,18A1.5,1.5,0,0,1,4,16.5V3.5A1.5,1.5,0,0,1,5.5,2h5.875a1.639,1.639,0,0,1,.573.1,1.233,1.233,0,0,1,.49.334l3.124,3.124a1.233,1.233,0,0,1,.334.49,1.639,1.639,0,0,1,.1.573V16.5A1.5,1.5,0,0,1,14.5,18ZM11,6.25a.748.748,0,0,0,.75.75H14.5L11,3.5Zm-1.854,6.5L8.021,11.6q-.063-.083-.4.052t-.615.24a1.08,1.08,0,0,1-.385.094q-.1-.011.271-.386a.831.831,0,0,0,0,1.146l1.729,1.729a.679.679,0,0,0,.24.156.771.771,0,0,0,.562,0,.691.691,0,0,0,.24-.156l3.521-3.521a.8.8,0,0,0-1.126-1.125Z"
                transform="translate(-4 -2)"
                fill={!!props.fill ? props.fill : "#dbdbdb"}
            />
        </SvgIcon>
    )
}
