import React from 'react'
import { WithNamespaces, withNamespaces } from 'react-i18next'
import { State } from '../../../../application/Store'
import { Dispatch } from 'redux'
import { connect } from 'react-redux'
import { withStyles, WithStyles } from '@material-ui/core'
import { Address, RFQ, SHOW_FEEDBACK, findSelectedSingleOption } from '@syncfab/machine'
import { GET_RFQ, RESET_DATA, RFQ_CREATE, RFQ_DRAFT, RFQ_SAVE, UPDATE_RFQ_FORM } from '../../../../components/quote/rfq/Actions'
import { ADDRESS_GET, ADDRESS_CREATE_INLINE } from '../../../../components/company/Actions'
import { change, submit } from 'redux-form'
import { AddressFormName } from '../../../../components/company/address/AddressForm'
import { GET_PARTS_FOR_DAPP } from '../../../../components/part/Actions'
import { getSupplierTag } from '../../../../components/supplier/Selector'
import { CenteredAlignedContainerV2 } from '../../../../layout/Container'
import EditorHeader from '../../../../layout/editor/EditorHeader'
import { RFQQuoteEditorForm, RFQQuoteEditorFormName } from './RFQQuoteEditorForm'
import { withRouter, RouteComponentProps } from 'react-router-dom'

const styles = theme => ({
    paper: {
        width: '100%',
        // minWidth: 300,
        padding: theme.spacing(4),
        marginTop: theme.spacing(4),
        marginBottom: theme.spacing(4),
    },
    divider: {
        marginTop: theme.spacing(3),
        marginBottom: theme.spacing(3),
    },
    card: {
        width: '100%',
        margin: `0 0 ${theme.spacing(3)}px`,
        padding: `${theme.spacing(2)}px ${theme.spacing(2)}px`,
        backgroundColor: '#40B4BF',
        color: theme.palette.common.blue,
    },
    link: {
        textDecoration: 'none',
        color: theme.palette.common.black,
    },
    button: {
        backgroundColor: theme.palette.common.white,
        '&:hover': {
            background: theme.palette.common.white,
        },
    },
})

interface RFQQuoteEditorProps extends WithStyles, WithNamespaces, RouteComponentProps<{ id: string }> {
    id?: string
    parts: any[]
    getAddresses: () => void
    addresses?: Address[]
    createRFQ: (rfqId: string, rfq: Partial<RFQ>) => void
    submitAddressForm: () => void
    createAddress: (address, field) => void
    supplierTags: {
        manufacturing: { label: string; value: string }[]
        certifications: { label: string; value: string }[]
    }
    updateField: (field: string, data: boolean) => void
    getParts: () => void
    rfq: any
    onError: (error: string) => void
    saveToDraft: (rfqId: string, rfq?: any) => void
    saveRFQ: (rfq: any) => void
    resetRFQForm: () => void
    updateRFQFormData: (field: any) => void
    getRFQ: (id: string) => void
}

interface RFQQuoteEditorState {
    draftParts: any[]
    supportingDocuments: any[]
}

class RFQQuoteEditor extends React.Component<RFQQuoteEditorProps, RFQQuoteEditorState> {
    constructor(props) {
        super(props)
        const rfqId = this.props?.match?.params?.id

        this.onSubmit = this.onSubmit.bind(this)
        this.handleDraftPartsChange = this.handleDraftPartsChange.bind(this)
        this.handleSupportingDocumentsChange = this.handleSupportingDocumentsChange.bind(this)

        this.state = {
            draftParts: [],
            supportingDocuments: []
        }

        if (rfqId) {
            this.props.getRFQ(rfqId)
        }
        this.props.getAddresses()
        this.props.getParts()
    }

    componentWillUnmount() {
        this.props.resetRFQForm()
    }

    componentDidMount() {
        if (this.props.rfq && this.props.rfq.draftParts?.length > 0) {
            this.setState({ draftParts: this.props.rfq.draftParts})
        }
        if (this.props.rfq && this.props.rfq.documents?.length > 0) {
            this.setState({ supportingDocuments: this.props.rfq.documents })
        }
    }

    componentDidUpdate(prevProps: Readonly<RFQQuoteEditorProps>) {
        if (this.props.rfq && !prevProps.rfq && this.props.rfq.draftParts?.length > 0) {
            this.setState({ draftParts: this.props.rfq.draftParts})
        }
        if (this.props.rfq && !prevProps.rfq && this.props.rfq.documents?.length > 0) {
            this.setState({ supportingDocuments: this.props.rfq.documents })
        }
    }

    handleDraftPartsChange(draftParts: any[]) {
        this.setState( () => ({
            draftParts
        }))
    }

    handleSupportingDocumentsChange(documents: any[]) {
        this.setState(() => ({
            supportingDocuments: documents
        }))
    }

    onSubmit(values: any) {

        const formData = values.toJS()

        // perform validation on draftParts data
        // user must create at least one line item
        if (this.state.draftParts.length < 1) {
            this.props.onError("Please create at least one line item.")
            return
        }

        // each line item must have a part number, revision, at least one document and at least one quantity
        let allLineItemsValid = true

        this.state.draftParts.forEach(part => {
            if (!part.partNumber) {
                this.props.onError("All line items must contain a part number.")
                allLineItemsValid = false
            }
            if (!part.revision) {
                this.props.onError("All line items must contain a revision.")
                allLineItemsValid = false
            }
            if (!part.documents || part.documents?.length < 1) {
                this.props.onError("All line items must contain at least one document.")
                allLineItemsValid = false
            }
            if (!part.quantity || part.quantity?.length < 1) {
                this.props.onError("All line items must contain at least one quantity value.")
                allLineItemsValid = false
            }
        })

        if (!allLineItemsValid) {
            return
        }

        const draftPartsToSubmit = this.state.draftParts.map(part => {
            const partCopy = {
                ...part,
                documentIds: part.documents?.map(doc => doc._id)
            }
            delete partCopy.documents
            return partCopy
        })

        const saveAsDraft: boolean = formData.isDraft
        // const shipAddressSameAsBilling: boolean = formData.sameAsBilling
        const billingAddressId: string = formData.billingAddressId?.value
        const shippingAddressId: string =  formData.shippingAddressId?.value
        // const shippingAddressId: string = shipAddressSameAsBilling ? billingAddressId : formData.shippingAddressId?.value
        const leadTime: string = formData.leadTime
        const quoteDeadline: string = formData.quoteDeadline

        delete formData.isDraft
        delete formData.sameAsBilling
        delete formData.shippingAddressId
        delete formData.billingAddressId
        delete formData.leadTime
        delete formData.quoteDeadline

        const buyerRFQInput = {
            ...formData,
            draftParts: draftPartsToSubmit,
            certifications: formData.certifications?.map(c => c.value ?? c ) ?? [],
            documentIds: this.state.supportingDocuments?.map(doc => doc._id) ?? []
        }

        // if shipping or billing addresses were provided, create a nested buyerCompany object and populate the
        // addresses in it
        if (!!shippingAddressId || !!billingAddressId) {
            const buyerCompany: any = {}
            if (!!billingAddressId) {
                buyerCompany.billingAddressId = billingAddressId
            }
            if (!!shippingAddressId) {
                buyerCompany.shippingAddressId = shippingAddressId
            }
            buyerRFQInput.buyerCompany = buyerCompany
        }
        if (quoteDeadline) {
            buyerRFQInput.quoteDeadline = new Date(quoteDeadline)
        }
        if (leadTime) {
            buyerRFQInput.leadTime = new Date(leadTime)
        }
        if (saveAsDraft) {
            if (this.props.rfq?._id) {
                // this is an update
                this.props.saveToDraft(this.props.rfq._id, buyerRFQInput)
            } else {
                // this creates a new RFQ
                this.props.saveRFQ(buyerRFQInput)
            }
        } else {
            // this submits the buyer RFQ
            this.props.createRFQ(this.props.rfq._id, buyerRFQInput)
        }
    }

    render() {
        const {
            t,
            id,
            addresses,
            submitAddressForm,
            createAddress,
            supplierTags,
            updateField,
            rfq,
            parts,
            updateRFQFormData,
        } = this.props

        const { draftParts } = this.state
        const addressesOpts = addresses?.map(address => {
            return { value: address._id, label: `${address.location.line}, ${address.location.zipCode}` }
        })

        return (
            <CenteredAlignedContainerV2>
                <EditorHeader
                    id={id}
                    noIdText="Create RFQ"
                    message={t('side-menu-quote')}
                    to={'/quotes'}
                    draftParts={draftParts}
                />
                <RFQQuoteEditorForm
                    onSubmit={this.onSubmit as any}
                    initialValues={{
                        ...rfq,
                        billingAddressId: findSelectedSingleOption(addressesOpts, (rfq?.buyerCompany?.billingAddressId) || ''),
                        shippingAddressId: findSelectedSingleOption(addressesOpts, (rfq?.buyerCompany?.shippingAddressId) || ''),
                        sameAsBilling: false,
                        domesticProductionRequired: rfq?._id ? rfq.domesticProductionRequired : true,
                        isDraft: true,
                        leadTime: rfq?.leadTime ? new Date(rfq?.leadTime).toLocaleString() : '',
                        quoteDeadline: rfq?.quoteDeadline ? new Date(rfq?.quoteDeadline).toLocaleString() : '',
                    }}
                    addresses={addressesOpts}
                    submitAddressForm={submitAddressForm}
                    createAddress={createAddress}
                    supplierTags={supplierTags}
                    updateField={updateField}
                    draftParts={draftParts}
                    onDraftPartsChange={this.handleDraftPartsChange}
                    supportingDocuments={this.state.supportingDocuments}
                    onSupportingDocumentsChange={this.handleSupportingDocumentsChange}
                    rfq={rfq}
                    parts={parts}
                    updateRFQFormData={updateRFQFormData}
                />
            </CenteredAlignedContainerV2>
        )
    }
}

const EditorWithTranslations = withStyles(styles)(withRouter(withNamespaces()(RFQQuoteEditor)))

const mapStateToProps = (state: State) => {
    const getManufacturingTags = getSupplierTag('MANUFACTURING')
    const getCertifications = getSupplierTag('CERTIFICATIONS')
    return {
        rfq: state.rfq?.rfq,
        parts: state.part.parts,
        addresses: state.company.addresses,
        balance: state.transaction.balance,
        supplierTags: {
            manufacturing: getManufacturingTags(state),
            certifications: getCertifications(state),
        },
    }
}

const mapDispatchToProps = (dispatch: Dispatch) => {
    return {
        getAddresses: () => dispatch(ADDRESS_GET.actions.TRIGGER()),
        createRFQ: (rfqId: string, rfq: any) => dispatch(RFQ_CREATE.actions.TRIGGER({ rfqId, rfq })),
        submitAddressForm: () => dispatch(submit(AddressFormName)),
        createAddress: (address, field) => dispatch(ADDRESS_CREATE_INLINE.actions.TRIGGER({ address, formName: RFQQuoteEditorFormName, field })),
        updateField: (field, data) => dispatch(change(RFQQuoteEditorFormName, field, data)),
        getParts: () => dispatch(GET_PARTS_FOR_DAPP.actions.TRIGGER()),
        onError: error => dispatch(SHOW_FEEDBACK(error)),
        saveToDraft: (rfqId: string, rfq?: any) => dispatch(RFQ_DRAFT.actions.TRIGGER({ rfqId, rfq })),
        saveRFQ: (rfq: any) => dispatch(RFQ_SAVE.actions.TRIGGER({ rfq })),
        resetRFQForm: () => dispatch(RESET_DATA.actions.TRIGGER()),
        getRFQ: (id: string) => dispatch(GET_RFQ.actions.TRIGGER({ id })),
        updateRFQFormData: (field: any) => dispatch(UPDATE_RFQ_FORM.actions.TRIGGER({ field })),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(EditorWithTranslations)
