import { createStyles, makeStyles, Theme } from "@material-ui/core";

// TODO combine standard styles from MMS and DAPP using palette options for colors

export const useStandardStyles = makeStyles((theme: Theme) =>
    createStyles({
        centeredAlignedContainer: {
            display: 'flex',
            'flex-direction': 'column',
            alignItems: 'center',
            padding: theme.spacing(6),
            paddingTop: theme.spacing(7),
            backgroundColor: theme.palette.background.default,
        },
        header: {
            marginTop: theme.spacing(1),
            fontSize: '14px',
            display: 'flex',
            alignItems: 'center',
        },
        paper: {
            width: '100%',
            marginTop: theme.spacing(3),
            backgroundColor: theme.palette.background.paper,
        },
        formBackground: {
            width: '100%',
            marginTop: theme.spacing(3),
            padding: '24px',
            paddingTop: theme.spacing(2),
            paddingBottom: theme.spacing(2),
            backgroundColor: theme.palette.background.paper,
        },
        actionButton: {
            minWidth: 120,
            marginLeft: '20px'
        },
        label: {
            fontWeight: theme.typography.fontWeightMedium as any,
            fontSize: '14px'
        },
        value: {
            fontSize: '14px'
        },
        listControlContainer: {
            margin: theme.spacing(1),
            paddingTop: '18px',
            paddingBottom: '10px',
        },
        innerTableBorderRow: {
            borderTop: '1px solid #dbdbdb',
            paddingLeft: '16px'
        },
        compactRow: {
            paddingBottom: '10px',
            paddingTop: '10px',
            borderBottom: '1px solid #DBDBDB',
        },
        innerTableDataRow: {
            height: 'auto !important',
            border: `1px solid ${theme.palette.customComponent.innerTableBorderColor}`,
            '&:nth-of-type(odd)': {
                backgroundColor: theme.palette.customComponent.innerTableBackgroundColor,
            },
            // hide last border
            '&:last-child td, &:last-child th': {
                border: 0,
            },
            '& td': {
                padding: '8px',
                borderBottom: 'none',
                fontSize: 14
            }
        },
        tableLeftCell: {
            padding: '0px',
            width: 120,
            border: 'none'
        },
        tableRightCell: {
            padding: '0px 0px 0px 8px',
            border: 'none'
        },
        noData: {
            margin: theme.spacing(2),
            textAlign: 'center',
        },
        link: {
            color: '#40B4BF',
            'text-decoration': 'none',
            cursor: 'pointer',
            fontWeight: 'bold' as any,
        },
        quillEditor: {
            '& .ql-container.ql-snow': { border: 'none' },
            '& .ql-editor': { padding: 0 },
          },
    })
)