import React from 'react'
import { connect } from 'react-redux'
import { Paper, withStyles, WithStyles, Typography, Grid } from '@material-ui/core'
import { withNamespaces, WithNamespaces } from 'react-i18next'
import { Dispatch } from 'redux'
import { State } from '../../../application/Store'
import { Map } from 'immutable'
import { GET_LOGGED_COMPANY, COMPANY_UPDATE, ADDRESS_GET, ADDRESS_CREATE, ADDRESS_UPDATE, ADDRESS_DELETE } from '../../../components/company/Actions'
import { AddressCard, Address, AddAddressCard, Company, Error, SimpleDialog, CompanySize, Industry, findSelectedSingleOption, normalizeSingleValue } from '@syncfab/machine'
import { CompanySettingsForm } from './CompanySettingsForm'
import { AddressDialog } from '../../../components/company/address/AddressDialog'
import { submit } from 'redux-form'
import { AddressFormName } from '../../../components/company/address/AddressForm'

const styles = theme => ({
    paper: {
        width: '100%',
        minWidth: 300,
        padding: theme.spacing(3),
        marginTop: theme.spacing(3),
    },
})

interface CompanySettingsProps extends WithNamespaces, WithStyles {
    getLoggedCompany: () => void
    updateCompany: (delta: Partial<Company>) => void
    company?: Company
    addresses?: Address[]
    updateCompanyError?: string
    submitAddressForm: () => void
    getAddresses: () => void
    createAddress: (address) => void
    updateAddress: (address) => void
    deleteAddress: (id) => void
}

interface CompanySettingsState {
    deleteDialogOpen: boolean
    addressDialogOpen: boolean
    requestAddressId?: string
    addressToEdit?: Address
}

class CompanySettings extends React.Component<CompanySettingsProps, CompanySettingsState> {
    constructor(props) {
        super(props)
        this.onSubmitUpdateCompany = this.onSubmitUpdateCompany.bind(this)
        this.toggleAddressDialog = this.toggleAddressDialog.bind(this)
        this.toggleDeleteDialog = this.toggleDeleteDialog.bind(this)
        this.onSaveUpdateAddress = this.onSaveUpdateAddress.bind(this)
        this.onDeleteAddress = this.onDeleteAddress.bind(this)
        this.deleteAddress = this.deleteAddress.bind(this)
        this.editAddress = this.editAddress.bind(this)
        this.state = {
            addressDialogOpen: false,
            deleteDialogOpen: false,
        }
        props.getLoggedCompany()
        props.getAddresses()
    }

    toggleAddressDialog() {
        this.setState(state => ({
            addressDialogOpen: !state.addressDialogOpen,
            addressToEdit: undefined,
        }))
    }

    toggleDeleteDialog() {
        this.setState(state => ({
            deleteDialogOpen: !state.deleteDialogOpen,
        }))
    }

    editAddress(address: Address) {
        this.toggleAddressDialog()
        this.setState({
            addressToEdit: address,
        })
    }

    onSaveUpdateAddress(data: Map<string, any>) {
        const address: Address = (data.toJS() as unknown) as Address
        let addressData = address as any
        addressData = {
            ...addressData,
            location: {
                ...addressData.location,
                country: addressData?.location?.country ? addressData?.location?.country?.value : '',
                state: addressData?.location?.state ? addressData?.location?.state?.value : '',
            },
        }
        if (!address._id) {
            this.props.createAddress(addressData)
        } else {
            this.props.updateAddress(addressData)
        }
        this.toggleAddressDialog()
        this.setState({
            addressToEdit: undefined,
        })
    }

    deleteAddress(id: string) {
        this.setState({
            requestAddressId: id,
        })
        this.toggleDeleteDialog()
    }

    onDeleteAddress() {
        this.props.deleteAddress(this.state.requestAddressId)
        this.setState({
            requestAddressId: undefined,
        })
        this.toggleDeleteDialog()
    }

    onSubmitUpdateCompany(values: Map<string, string>) {
        const delta = values.toJS()
        if (delta?.size) {
            delta.size = normalizeSingleValue(delta?.size)
        }
        if (delta?.industry) {
            delta.industry = normalizeSingleValue(delta?.industry)
        }
        this.props.updateCompany(delta)
    }

    render() {
        const { classes, t, company, updateCompanyError, submitAddressForm, addresses } = this.props
        const { addressDialogOpen, deleteDialogOpen, addressToEdit } = this.state
        const companyOpts = Object?.keys(CompanySize)?.map(industry => {
            return { value: industry, label: `${t(`company-size-${industry.toLowerCase()}`)}` }
        })
        const industryOpts = Object?.keys(Industry)?.map(industry => {
            return { value: industry, label: `${t(`company-industry-${industry.toLowerCase()}`)}` }
        })

        return (
            <React.Fragment>
                <Paper className={classes.paper} elevation={1}>
                    <Typography variant="h3" gutterBottom style={{ marginTop: 8 }}>
                        {`${t('company-settings-details')}`}
                    </Typography>
                    <CompanySettingsForm
                        onSubmit={this.onSubmitUpdateCompany as any}
                        initialValues={{
                            ...company,
                            size: findSelectedSingleOption(companyOpts, (company && company?.size) || ''),
                            industry: findSelectedSingleOption(industryOpts, company?.industry as any),
                        }}
                        companyOpts={companyOpts}
                        industryOpts={industryOpts}
                    />
                    <Error trigger={!!updateCompanyError} error={t(updateCompanyError || '')} />
                </Paper>
                <Paper className={classes.paper} elevation={1}>
                    <Typography variant="h3" gutterBottom>
                        {`${t('company-settings-addresses')}`}
                    </Typography>
                    <Grid container direction="row" spacing={3}>
                        {!!addresses &&
                            addresses.map((address, index) => (
                                <Grid item xs={12} sm={12} md={6} key={index}>
                                    <AddressCard key={address._id} address={address} onDelete={this.deleteAddress} onEdit={this.editAddress} showActions={true} />
                                </Grid>
                            ))}
                    </Grid>
                    <AddAddressCard openDialog={this.toggleAddressDialog} />
                </Paper>
                <AddressDialog onSubmit={this.onSaveUpdateAddress as any} onSaveUpdate={submitAddressForm} close={this.toggleAddressDialog} open={addressDialogOpen} address={addressToEdit} />
                <SimpleDialog
                    title={t('company-settings-delete-address-dialog-title')}
                    content={t('company-settings-delete-address-dialog-content')}
                    primaryActionText={t('company-settings-delete-address-dialog-delete')}
                    onPrimaryAction={this.onDeleteAddress}
                    secondaryActionText={t('company-settings-delete-address-dialog-cancel')}
                    onSecondaryAction={this.toggleDeleteDialog}
                    open={deleteDialogOpen}
                />
            </React.Fragment>
        )
    }
}

const mapStateToProps = ({ company: { company, updateCompanyError, addresses } }: State) => {
    return {
        company,
        updateCompanyError,
        addresses,
    }
}

const mapDispatchToProps = (dispatch: Dispatch) => {
    return {
        updateCompany: (delta: Partial<Company>) => dispatch(COMPANY_UPDATE.actions.TRIGGER(delta)),
        getLoggedCompany: () => dispatch(GET_LOGGED_COMPANY.actions.TRIGGER()),
        getAddresses: () => dispatch(ADDRESS_GET.actions.TRIGGER()),
        submitAddressForm: () => dispatch(submit(AddressFormName)),
        createAddress: address => dispatch(ADDRESS_CREATE.actions.TRIGGER(address)),
        updateAddress: address => dispatch(ADDRESS_UPDATE.actions.TRIGGER(address)),
        deleteAddress: id => dispatch(ADDRESS_DELETE.actions.TRIGGER(id)),
    }
}

const CompanySettingsWithTranslations = withStyles(styles)(withNamespaces()(CompanySettings))

export default connect(mapStateToProps, mapDispatchToProps)(CompanySettingsWithTranslations)
