import { UserAPI } from './UserAPI'
import { CompanyAPI } from './CompanyAPI'
import { DocumentAPI } from './DocumentAPI'
import { RFQAPI } from './RFQAPI'
import { RFQV2API } from './RFQV2API'
import { OrderAPI } from './OrderAPI'
import { PartAPI } from './PartAPI'
import { BlockchainAPI } from './BlockchainAPI'
import { AnalyticsService } from '../../utils/Analytics/AnalyticsService'
import { SupplierAPI } from './SupplierAPI'
import { TransactionAPI } from './TransactionAPI'
import { DashboardAPI } from './DashboardAPI'

export class Gateway {
    constructor(
        private analyticsService: AnalyticsService,
        private userAPI: UserAPI,
        private companyAPI: CompanyAPI,
        private documentAPI: DocumentAPI,
        private rfqAPI: RFQAPI,
        private rfqV2API: RFQV2API,
        private orderAPI: OrderAPI,
        private partAPI: PartAPI,
        private blockchainAPI: BlockchainAPI,
        private supplierAPI: SupplierAPI,
        private transactionAPI: TransactionAPI,
        private dashboardAPI: DashboardAPI,
    ) {}

    public getAnalyticsService() {
        return this.analyticsService
    }

    public getDocumentAPI() {
        return this.documentAPI
    }

    public getUserAPI() {
        return this.userAPI
    }

    public getCompanyAPI() {
        return this.companyAPI
    }

    public getRFQAPI() {
        return this.rfqAPI
    }

    public getRFQV2API() {
        return this.rfqV2API
    }

    public getOrderAPI() {
        return this.orderAPI
    }

    public getPartAPI() {
        return this.partAPI
    }

    public getBlockchainAPI() {
        return this.blockchainAPI
    }

    public getSupplierAPI() {
        return this.supplierAPI
    }

    public getTransactionAPI() {
        return this.transactionAPI
    }

    public getDashboardAPI() {
        return this.dashboardAPI
    }
}
