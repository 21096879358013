import React, { FC } from "react";
import { useListSupplierPurchaseOrdersQuery } from "@syncfab/machine";
import { PurchaseOrderList } from "./PurchaseOrderList";

export const SupplierPurchaseOrderList: FC = () => {

    // use list parameters stored in redux state so that the search criteria carry over even if the user navigates

    const supplierPurchaseOrderColumns = [
        { id: 'number', label: 'PO ID' },
        { id: 'po.status', label: 'PO Status' },
        { id: 'nextDeliveryDate', label: 'Next Ship Date' },
        { id: 'po.initialPromiseDate', label: 'Final Ship Date' },
        { id: 'lineProgress', label: 'Progress', fixed: true },
        { id: 'supplierPOTotal', label: 'PO Total' },
        { id: 'actionItem', label: 'Action Item', fixed: true }
    ]

    return (
        <PurchaseOrderList
            listColumns={supplierPurchaseOrderColumns}
            retrieveData={useListSupplierPurchaseOrdersQuery}
        />
    )
}