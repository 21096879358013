import { createSelector } from 'reselect'
import { State } from '../../application/Store'

const getTransactions = (state: State) => {
    return state.transaction.transactions
}

const getTransactionTotal = (state: State) => {
    return state.transaction.transactionTotal
}

export const getHasNextSelector = () => {
    return createSelector(
        [getTransactions, getTransactionTotal],
        (transactions, transactionTotal) => {
            if (!transactions || (!!transactions && !transactions.length)) {
                return false
            }

            return transactions.length < transactionTotal
        }
    )
}
