import React from 'react'
import { Helmet } from 'react-helmet'
import { withNamespaces, WithNamespaces } from 'react-i18next'
import { WithStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import { State } from '../../../application/Store'
import { Dispatch } from 'redux'
import { connect } from 'react-redux'
import { Paper, withStyles } from '@material-ui/core'
import { CenteredContainer } from '../../../layout/Container'
import { GET_SUPPLIER_DATA_RFQ, GET_SUPPLIER_DOCUMENTS_RFQ } from '../../../components/quote/rfq/Actions'
import { Part, RFQ, Supplier } from '@syncfab/machine'
import { QuoteSupplierTable } from './components/QuoteSupplierTable'
import { getPartById } from '../../../components/part/Selector'
import { SimpleQuoteHeader } from './components/SimpleQuoteHeader'

const styles = theme => ({
    paper: {
        padding: theme.spacing(6),
        display: 'flex',
        'flex-direction': 'column',
        'justify-content': 'center',
        alignItems: 'center',
        width: 800,
    },
    centerText: {
        marginTop: theme.spacing(2),
        'text-align': 'center',
    },
})

interface SupplierQuoteProps extends WithNamespaces, WithStyles {
    getData: (token: string) => void
    getDocuments: (token: string) => void
    rfq: RFQ
    parts: { [key: string]: Part }
    supplier: Partial<Supplier>
}

class SupplierQuoteView extends React.Component<SupplierQuoteProps, { token: string }> {
    constructor(props: SupplierQuoteProps) {
        super(props)
        const token = new URLSearchParams(window.location.search.slice(1)).get('token') || ''
        this.state = {
            token,
        }
        this.getDocuments = this.getDocuments.bind(this)
        this.props.getData(token)
    }

    private getDocuments() {
        this.props.getDocuments(this.state.token)
    }

    public render() {
        const { classes, t, rfq, parts, supplier } = this.props

        if (!rfq) {
            return null
        }

        return (
            <CenteredContainer>
                <Helmet>
                    <title>{`${t('quotes-suppliers-title')}`}</title>
                </Helmet>
                <Paper className={classes.paper} elevation={1}>
                    {!!supplier && (
                        <Typography className={classes.text} variant="h3">
                            {`${t('syncfab-saludation')} ${supplier.companyName}`}
                        </Typography>
                    )}
                    <SimpleQuoteHeader rfq={rfq} />
                    <QuoteSupplierTable rfq={rfq} parts={parts} getDocuments={this.getDocuments} />
                    <Typography className={classes.centerText}>{`${t('quotes-suppliers-reply-email')}`}</Typography>
                </Paper>
            </CenteredContainer>
        )
    }
}

const mapStateToProps = (state: State) => {
    const partSelector = getPartById()
    return {
        rfq: state.rfq.rfq,
        parts: partSelector(state),
        supplier: state.supplier.supplier,
    }
}

const mapDispatchToProps = (dispatch: Dispatch) => {
    return {
        getData: (token: string) => dispatch(GET_SUPPLIER_DATA_RFQ.actions.TRIGGER({ token })),
        getDocuments: (token: string) => dispatch(GET_SUPPLIER_DOCUMENTS_RFQ.actions.TRIGGER({ token })),
    }
}

export const QuoteSuppliers = connect(
    mapStateToProps,
    mapDispatchToProps
)(withNamespaces()(withStyles(styles)(SupplierQuoteView)))
