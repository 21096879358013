import React from 'react'
import { Grid, Typography, withStyles, WithStyles } from '@material-ui/core'
import { Alert, AlertTitle } from '@material-ui/lab'

import { colors } from '../../../themes/dapp'
const styles = theme => ({
    root: {
        width: '100%',
        marginTop: theme.spacing(3),
        backgroundColor: colors.warning.light,
        paddingTop: '5px',
        paddingBottom: 0,
        '& .MuiAlert-icon': {
            color: '#F44336',
        },
    },
    container: {
        paddingLeft: '6px',
        paddingRight: '6px',
    },
    message: {
        color: '#611A15',
        fontSize: '12px',
    },
})

export interface InformationV2Properties extends WithStyles {
    message: string
    title: string
}

export const InformationV2 = withStyles(styles)(({ classes, title, message }: InformationV2Properties) => {
    return (
        <Grid container className={classes.container}>
            <Alert severity="error" className={classes.root}>
                <AlertTitle style={{ fontSize: '14px', color: '#611A15', marginBottom: 0 }}>{title}</AlertTitle>
                <Typography gutterBottom variant="caption" className={classes.message}>
                    {message}
                </Typography>
            </Alert>
        </Grid>
    )
})
