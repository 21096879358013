import React from 'react'
import { withStyles, WithStyles } from '@material-ui/core/styles'
import cn from 'classnames'
import TableCell from '@material-ui/core/TableCell'
import TableRow from '@material-ui/core/TableRow'
import { Box, Chip, Collapse, IconButton, Table, TableBody, TableHead, Typography } from '@material-ui/core'
import { withNamespaces, WithNamespaces } from 'react-i18next'
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown'
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp'
import { CurrencyFormatterDecimal } from '@syncfab/machine'

export interface RowProps extends WithStyles, WithNamespaces {
    edit: (id: string, status: string) => void
    rfq?: any
    statusArr?: any[]
}
interface RowState {
    open: boolean
    selectedStatus: string
}

const styles = theme => ({
    compactRow: {
        paddingBottom: '15px',
        paddingTop: '15px',
        borderBottom: '1px solid #65788B',
        color: '#fff',
    },
    captionStyle: {
        marginTop: theme.spacing(3),
    },
    link: {
        color: '#40B4BF !important',
        'text-decoration': 'none',
        cursor: 'pointer',
        fontWeight: 'bold' as any,
    },
    draft: {
        backgroundColor: theme.palette.primary.main // light blue
    },
    issued: {
        backgroundColor: theme.palette.success.main, // green
    },
    pending: {
        backgroundColor: theme.palette.grey[600],
    },
    quoted: {
        backgroundColor: theme.palette.secondary.main,
    },
    closed: {
        backgroundColor: theme.palette.grey[900], // grey
    },
    icon: {
        '& path': {
            fill: theme.palette.common.black,
        },
    },
})

const TableHeaderCell = withStyles(_ => ({
    root: {
        color: 'white',
        borderBottom: '1px solid #65788B',
    },
}))(TableCell)

class Row extends React.Component<RowProps, RowState> {
    constructor(props: RowProps) {
        super(props)
        this.state = {
            open: false,
            selectedStatus: this?.props?.rfq?.status ? this.getRfqStatusLabel(this?.props?.rfq?.status) : '',
        }
    }

    getRfqStatusLabel = status => {
        return this.props.statusArr.find(rfqStatus => rfqStatus?.value === status)?.label?.toUpperCase()
    }
    render() {
        const { rfq, edit, classes } = this.props
        const { open } = this.state

        const classNames = cn({
            [classes.draft]: rfq?.status === 'DRAFT',
            [classes.pending]: rfq?.status === 'AWAITING QUOTE',
            [classes.quoted]:  rfq?.status === 'REVIEW QUOTE',
            [classes.closed]: rfq?.status === 'CLOSED',
            [classes.issued]: rfq?.status === 'POISSUED',
        })

        return (
            <>
                <TableRow hover tabIndex={-1} key={rfq?._id}>
                    <TableCell className={classes.compactRow}>
                        <IconButton aria-label="expand row" style={{ color: '#fff' }} size="small" onClick={() => this.setState({ open: !open })}>
                            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                        </IconButton>
                    </TableCell>
                    <TableCell className={`${classes.compactRow} ${classes.link}`} component="th" scope="row" onClick={() => edit(rfq._id, rfq.status)}>
                        {rfq._id}
                    </TableCell>
                    <TableCell className={classes.compactRow}>
                        <Chip
                            className={`${classNames !== '' ? classNames : classes.closed}`}
                            size="small"
                            style={{ width: '100%', color: '#fff', fontWeight: 'bold' }}
                            label={this.getRfqStatusLabel(rfq?.status) || rfq?.status}
                        />
                    </TableCell>
                    <TableCell className={classes.compactRow}>{rfq?.buyerRFQReference ? rfq?.buyerRFQReference : '-'}</TableCell>
                    <TableCell className={classes.compactRow}>{rfq.buyerContact ? rfq?.buyerContact?.userName : ''}</TableCell>
                    <TableCell className={classes.compactRow}>{new Date(rfq?.createdAt).toLocaleDateString()}</TableCell>
                    <TableCell className={classes.compactRow}>{rfq?.quoteDeadline ? new Date(rfq.quoteDeadline).toLocaleDateString() : ''}</TableCell>
                    <TableCell className={classes.compactRow}>{rfq?.numLineItems ? rfq?.numLineItems : 0}</TableCell>
                    <TableCell className={classes.compactRow}>{rfq?.quoteTotalMin && rfq?.quoteTotalMax ? `${CurrencyFormatterDecimal.format(rfq?.quoteTotalMin)} - ${CurrencyFormatterDecimal.format(rfq?.quoteTotalMax)}` : '-'}</TableCell>
                </TableRow>
                {open && (
                    <TableRow>
                        <TableCell style={{ paddingTop: 0, borderBottom: '1px solid #65788B' }} colSpan={12}>
                            <Collapse in={open} timeout="auto" unmountOnExit>
                                <Box margin={1}>
                                    <Typography variant="h6" gutterBottom component="div" className={classes.captionStyle}>
                                        RFQ Details
                                    </Typography>
                                    <Table style={{ backgroundColor: '#364F68' }}>
                                        <TableHead>
                                            <TableRow style={{ color: '#fff' }}>
                                                <TableHeaderCell align="left">Item</TableHeaderCell>
                                                <TableHeaderCell align="left">Part ID</TableHeaderCell>
                                                <TableHeaderCell align="left">Part Number</TableHeaderCell>
                                                <TableHeaderCell align="left">Rev</TableHeaderCell>
                                                <TableHeaderCell align="left">Description</TableHeaderCell>
                                                <TableHeaderCell align="left">NSN</TableHeaderCell>
                                                <TableHeaderCell align="left">QTY1</TableHeaderCell>
                                                <TableHeaderCell align="left">QTY2</TableHeaderCell>
                                                <TableHeaderCell align="left">QTY3</TableHeaderCell>
                                                <TableHeaderCell align="left">QTY4</TableHeaderCell>
                                                <TableHeaderCell align="left">QTY5</TableHeaderCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {rfq?.lineItems && rfq?.lineItems?.length > 0 ? (
                                                rfq?.lineItems?.map((lineItem, index) => (
                                                    <TableRow key={index}>
                                                        <TableCell className={classes.compactRow} align="left">
                                                            {index + 1}
                                                        </TableCell>
                                                        <TableCell className={classes.compactRow} align="left">
                                                            {lineItem?.part ? lineItem?.part?._id : ''}
                                                        </TableCell>
                                                        <TableCell className={classes.compactRow} align="left">
                                                            {lineItem?.part ? lineItem?.part?.number : ''}
                                                        </TableCell>
                                                        <TableCell className={classes.compactRow} align="left">
                                                            {lineItem?.part ? lineItem?.part?.revision : ''}
                                                        </TableCell>
                                                        <TableCell className={classes.compactRow} align="left">
                                                            {lineItem?.part ? lineItem?.part?.name : ''}
                                                        </TableCell>
                                                        <TableCell className={classes.compactRow} align="left">
                                                            {lineItem?.part ? lineItem?.part?.nationalStockNumber : ''}
                                                        </TableCell>
                                                        <TableCell className={classes.compactRow}>{lineItem?.quantity?.[0] ? lineItem?.quantity[0] : '-'}</TableCell>
                                                        <TableCell className={classes.compactRow}>{lineItem?.quantity?.[1] ? lineItem?.quantity[1] : '-'}</TableCell>
                                                        <TableCell className={classes.compactRow}>{lineItem?.quantity?.[2] ? lineItem?.quantity[2] : '-'}</TableCell>
                                                        <TableCell className={classes.compactRow}>{lineItem?.quantity?.[3] ? lineItem?.quantity[3] : '-'}</TableCell>
                                                        <TableCell className={classes.compactRow}>{lineItem?.quantity?.[4] ? lineItem?.quantity[4] : '-'}</TableCell>
                                                    </TableRow>
                                                ))
                                            ) : (
                                                <TableRow>
                                                    <TableCell className={classes.compactRow} align="left" colSpan={12} style={{ textAlign: 'center' }}>
                                                        No Line Items Available
                                                    </TableCell>
                                                </TableRow>
                                            )}
                                        </TableBody>
                                    </Table>
                                </Box>
                            </Collapse>
                        </TableCell>
                    </TableRow>
                )}
            </>
        )
    }
}
export const RFQQuoteRow = withNamespaces()(withStyles(styles)(Row))
