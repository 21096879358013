import React, { FC } from 'react';
import {
  Controller,
  UseControllerProps,
  useFormContext,
} from 'react-hook-form';
import ReactQuill from 'react-quill-new';
import 'react-quill-new/dist/quill.snow.css';
import { Theme, Typography } from '@material-ui/core';
import { makeStyles, createStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    editor: {
      '& .ql-editor': {
        minHeight: 100,
        borderRadius: '0.5em',
        '&.ql-blank::before': {
          fontStyle: 'normal',
          color: theme.palette.customComponent.textColor,
        },
      },
      '& .ql-container': {
        borderRadius: '0.5em',
        backgroundColor: theme.palette.customComponent.fieldBackgroundColor,
        color: theme.palette.customComponent.textColor,
        borderColor: theme.palette.customComponent.fieldBorderColor,
      },
      '& .ql-snow.ql-toolbar': {
        borderTopLeftRadius: '0.5em',
        borderTopRightRadius: '0.5em',
      },
      '& .ql-snow.ql-toolbar button:hover': {
        stroke: theme.palette.primary.main,
      },
      '& .ql-snow.ql-toolbar button:hover .ql-stroke': {
        stroke: theme.palette.primary.main,
      },
      '& .ql-snow.ql-toolbar button.ql-active': {
        color: theme.palette.primary.main,
      },
      '& .ql-snow.ql-toolbar button:focus': {
        color: theme.palette.primary.main,
      },
      '& .ql-snow.ql-toolbar button.ql-active .ql-stroke': {
        stroke: theme.palette.primary.main,
      },
      '& .ql-editor.ql-blank::before': {
        fontSize: '14px',
      },
    },
    errorStyles: {
      textAlign: 'left' as any,
      color: theme.palette.error.light,
    },
  })
);

const modules = {
  toolbar: false,
};

const formats = ['bold', 'italic', 'underline'];

export interface RHFTextEditorProps {
  name: string;
  rules?: UseControllerProps['rules'];
  readOnly?: boolean;
  placeholder?: string;
}

export const RHFTextEditor: FC<RHFTextEditorProps> = ({
  name,
  rules,
  placeholder,
  readOnly = false,
}: RHFTextEditorProps) => {
  const classes = useStyles();
  const methods = useFormContext();

  return (
    <Controller
      name={name}
      control={methods.control}
      rules={rules}
      render={({ field: { onChange, value }, fieldState: { error } }) => (
        <>
          <ReactQuill
            className={classes.editor}
            onChange={onChange}
            value={value}
            readOnly={readOnly}
            modules={modules}
            formats={formats}
            placeholder={placeholder}
          />
          {error && (
            <Typography
              gutterBottom
              variant="caption"
              className={classes.errorStyles}
            >
              {error ? error.message : null}
            </Typography>
          )}
        </>
      )}
    />
  );
};
