import React from 'react'
import { Grid, Typography, Card, withStyles, WithStyles } from '@material-ui/core'
import { RFQ } from '@syncfab/machine'
import { withNamespaces, WithNamespaces } from 'react-i18next'

const styles = theme => ({
    card: {
        width: '100%',
        margin: `${theme.spacing(3)}px 12px`,
        padding: theme.spacing(3),
        backgroundColor: theme.palette.common.white,
    },
    label: {
        color: theme.palette.grey['700'],
    },
    value: {
        color: theme.palette.common.black,
        fontWeight: theme.typography.fontWeightMedium,
        'text-overflow': 'ellipsis',
        'white-space': 'nowrap',
        overflow: 'hidden',
    },
})

interface QuoteHeaderProps extends WithStyles, WithNamespaces {
    rfq: RFQ
}

class Header extends React.Component<QuoteHeaderProps> {
    constructor(props) {
        super(props)
    }

    render() {
        const { rfq, classes, t } = this.props
        return (
            <Card className={classes.card}>
                <Grid spacing={3} container justify="space-between" direction="row">
                    <Grid item xs={3}>
                        <Typography gutterBottom className={classes.label} variant="caption">
                            {`${t('quote-list-item-rfq-id')}`}
                        </Typography>
                        <Typography gutterBottom className={classes.value} variant="h4">
                            {rfq._id}
                        </Typography>
                    </Grid>
                    <Grid item xs={3}>
                        <Typography gutterBottom className={classes.label} variant="caption">
                            {`${t('quotes-lead-time')}`}
                        </Typography>
                        <Typography gutterBottom className={classes.value} variant="h4">
                            {rfq.leadTime}
                        </Typography>
                    </Grid>
                    <Grid item xs={3}>
                        <Typography gutterBottom className={classes.label} variant="caption">
                            {`${t('quote-list-item-submitted-date')}`}
                        </Typography>
                        <Typography className={classes.value} variant="h4">
                            {new Date(rfq.createdAt).toLocaleDateString()}
                        </Typography>
                    </Grid>
                </Grid>
            </Card>
        )
    }
}

export const SimpleQuoteHeader = withStyles(styles)(withNamespaces()(Header))
