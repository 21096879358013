import React from 'react'
import { Field, reduxForm } from 'redux-form/immutable'
import { InjectedFormProps } from 'redux-form'
import { Map } from 'immutable'
import { WithStyles, withStyles, Typography, Grid } from '@material-ui/core'
import { withNamespaces, WithNamespaces } from 'react-i18next'
import { Button, renderCheckbox, jumpToFirstError, MarketingChannel, FormAutocompleteV2 } from '@syncfab/machine'
import { SupplierSignupFormName } from './SupplierSignupPersonalInformationForm'

const styles = theme => ({
    action: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
    },
    goBackText: {
        display: 'flex',
        'justify-content': 'center',
        'align-items': 'baseline',
    },
    goBackButton: {
        margin: 0,
        padding: 0,
        'text-transform': 'none',
        color: theme.palette.primary.light,
        'font-weight': theme.typography.fontWeightRegular,
        'font-size': 12,
    },
    links: {
        'text-transform': 'none',
        color: theme.palette.primary.light,
    },
    gridItem: {
        paddingTop: '0px !important',
        paddingBottom: '0px !important',
    },
    formInput: {
        '& .MuiFormControl-marginNormal': {
            margin: 0,
        }},
    checkbox: {
        '& .MuiCheckbox-root': {
            color: '#fff !important',
        },
    },
})

type SupplierSignupFinalDetailsFields = {
    heardAboutUs?: string
}

const validate = (data: Map<string, string>): {} => {
    const errors: SupplierSignupFinalDetailsFields = {}
    ;['agreement', 'heardAboutUs'].forEach(field => {
        if (!data.get(field)) {
            errors[field] = 'Required'
        }
    })

    return errors
}

const AgreementLabel = ({ t, classes }) => {
    return (
        <Typography variant="caption">
            {t('supplier-signup-agreement-prefix')}
            <a target="_blank" href="https://syncfab.com/general-terms-and-conditions" className={classes.links}>
                {t('supplier-signup-agreement-terms-and-conditions')}
            </a>
            {', '}
            <a target="_blank" href="https://syncfab.com/supplier-terms-and-conditions" className={classes.links}>
                {t('supplier-signup-agreement-supplier-terms')}
            </a>
            {` ${t('supplier-signup-agreement-connector')} `}
            <a target="_blank" href="https://syncfab.com/privacy-policy" className={classes.links}>
                {t('supplier-signup-agreement-privacy-policy')}
            </a>
        </Typography>
    )
}

interface BaseFormProperties {
    onPrevious?: () => void
}

interface FormProperties extends BaseFormProperties, InjectedFormProps<{}, {}>, WithStyles, WithNamespaces {}

const form = (props: FormProperties) => {
    const { handleSubmit, submitting, t, onPrevious, classes } = props
    const marketingChannelOpts = Object?.keys(MarketingChannel)?.map(channel => {
        return { value: channel, label: `${t(`company-heard-about-us-${channel.toLowerCase()}`)}` }
    })
    return (
        <form onSubmit={handleSubmit}>
            <Grid container direction="row">
                <Grid item xs={12} md={12} className={classes.gridItem}>
                    <Field
                        name="heardAboutUs"
                        component={FormAutocompleteV2}
                        options={marketingChannelOpts}
                        format={value => {
                            if (!value) {
                                return []
                            }
                            if (!!value.toJS) {
                                return value.toJS()
                            }
                            return value
                        }}
                        label={t('signup-form-heard-about-us')}
                        fullWidth
                        className={classes.formInput}
                    ></Field>
                </Grid>
                <Grid item xs={12} md={12} className={classes.gridItem}>
                    <Field name="agreement" component={renderCheckbox} label={<AgreementLabel t={t} classes={classes} />} value="agree" className={classes.formInput} />
                </Grid>
                <Grid item xs={12} md={12} className={classes.gridItem}>
                    <Button type="submit" color="primary" disabled={submitting} className={props.classes.action} fullWidth>
                        {t('signup-form-complete')}
                    </Button>
                    <div className={classes.goBackText}>
                        <Typography variant="caption" className={classes.loginText}>
                            {`${t('signup-back')}`}
                        </Typography>
                        <Button type="button" variant="text" color="primary" disabled={submitting} className={classes.goBackButton} onClick={onPrevious}>
                            {t('signup-back-link')}
                        </Button>
                    </div>
                </Grid>
            </Grid>
        </form>
    )
}

const translatedForm = withNamespaces()(withStyles(styles)(form))
export const SupplierSignupFinalDetailsForm = reduxForm<{}, BaseFormProperties>({
    form: SupplierSignupFormName,
    validate,
    destroyOnUnmount: false,
    forceUnregisterOnUnmount: true,
    onSubmitFail: jumpToFirstError,
})(translatedForm)
