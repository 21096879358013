import {
    DateFormatter,
    Document,
    DocumentCardList,
    DocumentManager,
    DocumentMenuOption,
    DocumentType,
    FileExtensions,
    HeaderNavLink,
    Loader,
    MIMEFileType,
    SHOW_FEEDBACK,
    useAddSupplierQuoteDocumentMutation,
    useGetRFQQuery,
    useRemoveSupplierQuoteDocumentMutation
} from "@syncfab/machine";
import Helmet from "react-helmet";
import {
    Box,
    Button, createStyles,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Divider,
    Grid,
    makeStyles,
    Paper,
    Theme,
    Typography
} from "@material-ui/core";
import React, { FC, useEffect, useState } from "react";
import { useParams } from "react-router";
import { useStandardStyles } from "../../styles/standardStyles";
import { RFQPORequirements } from "./components/RFQPORequirements";
import { SupplierRFQLineItems } from "./components/SupplierRFQLineItems";
import InfoIcon from "@material-ui/icons/Info";
import TodayIcon from "@material-ui/icons/Today";
import { useAppDispatch } from "../../../application/hooks";
import VerifiedUserIcon from "@material-ui/icons/VerifiedUser";
import Checkbox from "@material-ui/core/Checkbox";
import { NoQuote } from "./components/NoQuote";
import WatchLaterIcon from "@material-ui/icons/WatchLater";
import EventAvailableIcon from "@material-ui/icons/EventAvailable";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        checkbox: {
            color: theme.palette.primary.main
        },
    })
)

export const ViewSupplierRFQ: FC = (() => {

    const standardClasses = useStandardStyles()
    const classes = useStyles()

    const { rfqId } = useParams<{ rfqId?: string }>()

    const {
        data: rfqData,
        isLoading: isRFQDataLoading,
        refetch,
        isError: isRFQDataError,
        error: rfqDataError
    } = useGetRFQQuery(rfqId)

    const [quoteDocuments, setQuoteDocuments] = useState<Document[]>([])
    const [_areQuoteDocumentsDirty, setAreQuoteDocumentsDirty] = useState<boolean>(false)

    const [isUploadQuoteDialogOpen, setIsUploadQuoteDialogOpen] = useState<boolean>(false)
    const [isNoQuoteDialogOpen, setIsNoQuoteDialogOpen] = useState<boolean>(false)

    const [hasSupplierVerifiedRequirements, setHasSupplierVerifiedRequirements] = useState<boolean>(false)
    const [requirementVerification, setRequirementVerification] = useState<boolean>(false)

    const dispatch = useAppDispatch()
    const [addSupplierQuoteDocument] = useAddSupplierQuoteDocumentMutation()
    const [removeSupplierQuoteDocument] = useRemoveSupplierQuoteDocumentMutation()

    useEffect(() => {
        if (!!rfqData && !!rfqData.supplierQuote?.document) {
            setQuoteDocuments([rfqData.supplierQuote.document])
        }
    },[rfqData])

    const handleAddDocument = async (document: Document) => {
        try {
            await addSupplierQuoteDocument({ rfqId, supplierQuoteUpload: { document }}).unwrap()
            refetch()
            dispatch(SHOW_FEEDBACK('Quote document uploaded.'))
        } catch(error) {
            dispatch(SHOW_FEEDBACK(error.data))
        }
    }

    const handleRemoveDocument = async (_docIds: string[], docId: string) => {
        try {
            await removeSupplierQuoteDocument({ docId, rfqId }).unwrap()
            refetch()
            dispatch(SHOW_FEEDBACK('Quote document removed.'))
        } catch(error) {
            dispatch(SHOW_FEEDBACK(error.data))
        }
    }
    const cancelUploadQuoteDialog = () => {
        setIsUploadQuoteDialogOpen(false)
    }

    const verifyRequirements = () => {
        setHasSupplierVerifiedRequirements(true)
        setIsUploadQuoteDialogOpen(false)
    }

    const cancelNoQuoteDialog = () => {
        setIsNoQuoteDialogOpen(false)
    }

    const completeNoQuote = () => {
        setIsNoQuoteDialogOpen(false)
        refetch()
    }

    if (isRFQDataLoading) {
        return <Loader/>
    }

    if (isRFQDataError) {
        // @ts-ignore
        const errorStatusCode = rfqDataError.status
        return (
            <div className={standardClasses.centeredAlignedContainer}>
                {errorStatusCode === 401 ?
                    (
                        <Typography style={{ fontSize: 32, fontWeight: 'bold' }}>
                            You are not authorized to view this RFQ.
                        </Typography>
                    ) : (
                        <Typography style={{ fontSize: 32, fontWeight: 'bold' }}>
                            An error has occurred.
                        </Typography>
                    )
                }
            </div>
        )
    }

    if (!!rfqData) {
        return (
            <div className={standardClasses.centeredAlignedContainer}>
                <Helmet>
                    <title>RFQs</title>
                </Helmet>
                <HeaderNavLink linkTo="/quotes" linkText="RFQ INBOX"/>
                <Grid container className={standardClasses.header} spacing={2}>
                    <Grid item xs={12} md={8}>
                        <Typography
                            style={{ marginBottom: '8px', fontSize: 32, fontWeight: 'bold' }}>{rfqData._id}</Typography>
                    </Grid>
                </Grid>
                <Grid container spacing={2}>
                    <Grid item xs={8}>
                        <SupplierRFQLineItems rfq={rfqData} />
                        <RFQPORequirements rfqOrPO={rfqData} entityType='RFQ'/>
                        {rfqData.documents?.length > 0 && (
                            <Paper className={standardClasses.paper}>
                                <Grid item xs={12} style={{ padding: '16px' }}>
                                    <Typography gutterBottom style={{ fontSize: 20, fontWeight: 'bold' }}>
                                        Supporting Files
                                    </Typography>
                                </Grid>
                                <Divider style={{ width: '100%', color: "#FFFFFF40" }}/>
                                <Grid item xs={12} style={{ padding: '24px' }}>
                                    <DocumentCardList
                                        documents={rfqData.documents}
                                        setAreDocumentsDirty={() => {}}
                                        setDocuments={() => {}}
                                        availableMenuOptions={[DocumentMenuOption.VIEW, DocumentMenuOption.DOWNLOAD]}
                                        showType={false}
                                        showVisibility={false}
                                        entity='RFQ'
                                        entityId={rfqId}
                                    />
                                </Grid>
                            </Paper>
                        )}
                    </Grid>
                    <Grid item xs={4}>
                        <Paper className={standardClasses.paper}>
                            <Grid container direction='column' style={{ padding: '16px' }}>
                                <Typography style={{ fontSize: 20, fontWeight: 'bold' }}>RFQ Name</Typography>
                                <Typography style={{ fontSize: 14 }}>{rfqData.name}</Typography>
                                <Typography style={{ color: '#F3F3F3' }}>{rfqData.buyerRFQReference}</Typography>
                                <Box style={{ paddingTop: '16px', display: 'flex' }}>
                                    <InfoIcon fontSize='medium' style={{ color: '#A0AEC0' }}/>
                                    <Typography style={{ color: '#A0AEC0', fontWeight: 'bold', marginLeft: '8px' }}>
                                        Status: <span style={{ color: '#FFBA00' }}>{rfqData.status}</span>
                                    </Typography>
                                </Box>
                                <Box style={{ paddingTop: '16px', display: 'flex' }}>
                                    <TodayIcon fontSize='medium' style={{ color: '#A0AEC0' }}/>
                                    <Typography style={{ color: '#A0AEC0', fontWeight: 'bold', marginLeft: '8px' }}>
                                        RFQ Created:&nbsp;
                                        <span style={{ color: '#F3F3F3' }}>
                                            {rfqData.createdAt ? DateFormatter.format(
                                                new Date(rfqData.createdAt)) : '--'}
                                        </span>
                                    </Typography>
                                </Box>
                                <Box style={{ paddingTop: '16px', display: 'flex' }}>
                                    <WatchLaterIcon fontSize='medium' style={{ color: '#A0AEC0' }}/>
                                    <Typography style={{ color: '#A0AEC0', fontWeight: 'bold', marginLeft: '8px' }}>
                                        Quote Due Date:&nbsp;
                                        <span style={{ color: '#F3F3F3' }}>
                                            {rfqData.quoteDeadline ? DateFormatter.format(
                                                new Date(rfqData.quoteDeadline)) : '--'}
                                        </span>
                                    </Typography>
                                </Box>
                                <Box style={{ paddingTop: '16px', display: 'flex' }}>
                                    <EventAvailableIcon fontSize='medium' style={{ color: '#A0AEC0' }}/>
                                    <Typography style={{ color: '#A0AEC0', fontWeight: 'bold', marginLeft: '8px' }}>
                                        Target Lead Time:&nbsp;
                                        <span style={{ color: '#F3F3F3' }}>
                                            {rfqData.leadTime ? DateFormatter.format(
                                                new Date(rfqData.leadTime)) : '--'}
                                        </span>
                                    </Typography>
                                </Box>
                            </Grid>
                            <Divider style={{ width: '100%', color: "#FFFFFF40" }}/>
                            <Grid container direction='column' style={{ padding: '16px' }}>
                                <Typography style={{ fontSize: 20, fontWeight: 'bold', marginBottom: '16px' }}>Upload Your Quote</Typography>
                                { !hasSupplierVerifiedRequirements &&
                                    <>
                                        { rfqData?.supplierQuote?.noQuote &&
                                            <>
                                                <Typography style={{ fontSize: 14, marginBottom: '16px' }}>
                                                    A 'No Quote' has been submitted for this RFQ.  If you would like to submit a quote instead, please press the 'Upload Quote' button below.
                                                </Typography>
                                                <Box style={{ display: 'flex', justifyContent: 'center' }}>
                                                    <Button
                                                        style={{ justifyContent: 'center', backgroundColor: '#FFBA00', color: '#FFFFFF', width: '120px' }}
                                                        onClick={() => {
                                                            setIsUploadQuoteDialogOpen(true)
                                                        }}
                                                    >
                                                        UPLOAD QUOTE
                                                    </Button>
                                                </Box>
                                            </>
                                        }
                                        { !rfqData.supplierQuote?.noQuote &&
                                            <Grid container alignItems='center' direction='column' style={{ padding: '16px' }}>
                                                <Button
                                                    style={{ backgroundColor: '#FFBA00', color: '#FFFFFF', width: '180px' }}
                                                    onClick={() => {
                                                        setIsUploadQuoteDialogOpen(true)
                                                    }}
                                                >
                                                    UPLOAD QUOTE
                                                </Button>
                                                <Typography
                                                    style={{ fontSize: 16, color: '#40B4BF', marginTop: '16px' }}
                                                    onClick={() => {
                                                        setIsNoQuoteDialogOpen(true)
                                                    }}
                                                >
                                                    No Quote
                                                </Typography>
                                            </Grid>
                                        }
                                    </>
                                }
                                {

                                }
                                { hasSupplierVerifiedRequirements &&
                                    <>
                                        { rfqData.supplierQuote?.quoteUploaded &&
                                            <Typography style={{ fontSize: 14, marginBottom: '16px' }}>
                                                To modify your quote, remove the document below and upload a new quote document.
                                            </Typography>
                                        }
                                        { !rfqData.supplierQuote.quoteUploaded &&
                                            <Typography style={{ fontSize: 14, marginBottom: '16px' }}>
                                                Drag and drop your PDF quote below or browse your computer for the file.
                                            </Typography>
                                        }
                                        <DocumentManager
                                            label=''
                                            documents={quoteDocuments}
                                            setDocuments={setQuoteDocuments}
                                            setAreDocumentsDirty={setAreQuoteDocumentsDirty}
                                            documentLimit={1}
                                            docType={DocumentType.SUPPORT_FILE}
                                            referenceId={''}
                                            maxFiles={1}
                                            multiple={false}
                                            maxFileSizeMB={20}
                                            acceptedFileTypes={[MIMEFileType.PDF]}
                                            acceptedFileExtensions={[FileExtensions.PDF]}
                                            fileAreaCaption='Accepting .pdf - Max File Size: 20MB'
                                            documentMenuOptions={[DocumentMenuOption.REMOVE]}
                                            showVisibility={false}
                                            showType={false}
                                            showRevision={false}
                                            handleRemoveDocument={handleRemoveDocument}
                                            handleAddDocument={handleAddDocument}
                                        />
                                    </>
                                }
                            </Grid>
                        </Paper>
                    </Grid>
                </Grid>
                <Dialog open={isNoQuoteDialogOpen} onClose={cancelNoQuoteDialog} fullWidth>
                    <DialogContent>
                        <NoQuote cancel={cancelNoQuoteDialog} complete={completeNoQuote} rfqId={rfqData._id} />
                    </DialogContent>
                </Dialog>
                <Dialog open={isUploadQuoteDialogOpen} onClose={cancelUploadQuoteDialog} >
                    <DialogTitle style={{ paddingBottom: '16px' }}>
                        <Box style={{ display: 'flex' }}>
                            <VerifiedUserIcon style={{ fontSize: 50, color: '#40BF8A'}} />
                            <Typography style={{ fontSize: '20px', margin: '10px 0px 0px 16px' }}>Please Verify Your Quote</Typography>
                        </Box>
                    </DialogTitle>
                    <DialogContent>
                        <Typography>
                            By checking the box and clicking 'Accept' below, you agree that you meet all certification requirements, your quote meets the RFQ requirements, and you agree to SyncFab's &nbsp;
                            <a href="https://syncfab.com/supplier-terms-and-conditions"
                               style={{ textDecoration: 'none', color: '#40B4BF', cursor: 'pointer' }} target="_blank">
                                terms and conditions
                            </a>
                            .
                        </Typography>
                        <Box style={{ display: 'flex', marginTop: '16px' }}>
                            <Checkbox
                                classes={{ root: classes.checkbox }}
                                color='primary'
                                checked={requirementVerification}
                                onChange={() => setRequirementVerification((prevState) => !prevState)}
                            />
                            <Typography>
                                My quote meets the listed notes, lead time, certification, material and finish requirements.
                            </Typography>
                        </Box>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={cancelUploadQuoteDialog}
                                color="primary"
                                autoFocus>
                            CANCEL
                        </Button>
                        <Button onClick={verifyRequirements}
                                disabled={!requirementVerification}
                                type="submit"
                                color="primary">
                            ACCEPT
                        </Button>
                    </DialogActions>
                </Dialog>
            </div>
        )
    }
    return null
})