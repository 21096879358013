import React from 'react'
import { WithNamespaces, withNamespaces } from 'react-i18next'
import { WithStyles, withStyles, Typography, Grid, Divider, ListItemText, InputAdornment } from '@material-ui/core'
import { Field, reduxForm } from 'redux-form/immutable'
import { FormTextField, Button } from '@syncfab/machine'
import { InjectedFormProps } from 'redux-form'
import CameraAltIcon from '@material-ui/icons/CameraAlt'
import SearchOutlined from '@material-ui/icons/SearchOutlined'
import { RouteComponentProps, withRouter } from 'react-router'

const styles = theme => ({
    divider: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(3),
    },
    link: {
        'text-decoration': 'none',
    },
})

export const PartRecordFormName: string = 'PartRecordFormName'

interface FormProperties {}

interface FormProps extends InjectedFormProps<{}, {}>, WithStyles, WithNamespaces, FormProperties, RouteComponentProps {
    history: any
}

interface FormState {}

class Form extends React.Component<FormProps, FormState> {
    constructor(props: FormProps) {
        super(props)
        this.state = {}
        this.onSubmit = this.onSubmit.bind(this)
    }
    trackableID
    onSubmit(e) {
        e.preventDefault()
        var trackableID = this.trackableID
        if (trackableID.value) {
            this.props.history.push(`/track/${trackableID.value}`)
        }
    }

    render() {
        const { classes } = this.props
        const {} = this.state

        return (
            <form onSubmit={this.onSubmit as any}>
                <Grid container>
                    <Typography gutterBottom variant="h3" style={{ marginBottom: '16px' }}>
                        Search with Trackable ID Number
                    </Typography>
                </Grid>
                <Grid container>
                    <Field
                        style={{ margin: '0px', padding: '0px' }}
                        name="number"
                        component={FormTextField}
                        type="text"
                        label={'Search with Trackable ID Number'}
                        fullWidth
                        ref={c => (this.trackableID = c)}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="start" onClick={this.onSubmit}>
                                    <SearchOutlined />
                                </InputAdornment>
                            ),
                        }}
                    />
                </Grid>
                <Divider className={classes.divider} />
                <Grid container>
                    <Typography style={{ marginBottom: '20px' }} gutterBottom variant="h3">
                        Search with Part QR Code
                    </Typography>
                </Grid>
                <Grid container>
                    <a target="_blank" href={`https://qrcodescan.in`} className={classes.link}>
                        <Button color="secondary">
                            <ListItemText style={{ marginRight: '20px' }}>
                                <Typography variant="h5">Scan</Typography>
                            </ListItemText>
                            <CameraAltIcon />
                        </Button>
                    </a>
                </Grid>
            </form>
        )
    }
}

const translatedForm = withRouter(withStyles(styles)(withNamespaces()(Form)))

export const LocatePartForm = reduxForm<{}, FormProperties>({
    form: PartRecordFormName,
})(translatedForm as any) // ReduxForm types are not good
