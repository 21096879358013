import React, { memo } from 'react'
import { Grid, Typography, withStyles, WithStyles } from '@material-ui/core'
import { Link } from 'react-router-dom'
import ArrowBack from '@material-ui/icons/ArrowBack'
import { connect } from 'react-redux'
import { Dispatch } from 'redux'
import { WithNamespaces, withNamespaces } from 'react-i18next'
import { State } from '../../application/Store'
import { Button } from '@syncfab/machine'
import { RFQQuoteEditorFormName } from '../../view/quotes/editor/components/RFQQuoteEditorForm'
import { change, isSubmitting, submit } from 'redux-form'

const styles = theme => ({
    header: {
        marginTop: theme.spacing(3),
        fontSize: '14px',
    },
    headerSlash: {
        marginRight: theme.spacing(1),
        marginLeft: theme.spacing(1),
    },
    arrow: {
        color: theme.palette.primary.main,
        marginRight: theme.spacing(1),
        marginTop: theme.spacing(0.5),
        fontSize: '14px',
    },
    detailsHeader: {
        color: theme.palette.primary.main,
        fontSize: '14px',
        letterSpacing: '1.25px',
    },
    headerChip: {
        '& > *': {
            margin: theme.spacing(0.5),
        },
    },
    headerContainer: {
        display: 'flex',
        alignItems: 'center',
    },
    link: {
        display: 'flex',
        textDecoration: 'none',
    },
    saveBtn: {
        width: 100,
        minHeight: '36px',
        marginLeft: 10,
        borderWidth: 2,
    },
    finalizeBtn: {
        width: 130,
        minHeight: '36px',
        height: 'auto',
        marginBottom: theme.spacing(2),
        marginLeft: 10,
        borderWidth: 2,
    },
    poActionBtn: {
        minWidth: 90,
        minHeight: '36px',
        height: 'auto',
        marginBottom: theme.spacing(2),
        marginLeft: 10,
        borderWidth: 2,
    },
    label: {
        fontSize: '24px',
    },
})

interface EditorHeaderProps extends WithStyles, WithNamespaces {
    id?: string
    noIdText?: string
    // chips?: string[]
    message: string
    to?: string
    updateField?: (field: string, data: boolean) => void
    submitRFQForm?: () => void
    rfq: any
    submitting: boolean
    draftParts?:any[]
}

const header = ({ classes, id, noIdText, message, to, t, submitting,draftParts, ...props }: EditorHeaderProps) => {
    return (
        <>
            <Grid container spacing={0} alignItems="center" className={classes.header}>
                <div className={classes.headerContainer}>
                    <Link to={to} className={classes.link}>
                        <ArrowBack className={classes.arrow} />
                        <Typography gutterBottom variant="h6" className={classes.detailsHeader}>
                            {message}
                        </Typography>
                    </Link>
                </div>
            </Grid>
            <Grid container spacing={0} alignItems="center" className={classes.header} direction="row">
                <Grid item xs={6}>
                    <Typography variant="h5" className={classes.label}>
                        {id ?? noIdText}
                    </Typography>
                </Grid>
                <Grid item xs={6}>
                    <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                        <Button
                            className={classes.saveBtn}
                            type="button"
                            color="primary"
                            size="small"
                            variant="outlined"
                            onFocus={() => {
                                props.updateField('isDraft', true)
                            }}
                            onClick={() => {
                                props.submitRFQForm()
                            }}
                        >
                            {`${t('rfq-draft-save')}`}
                        </Button>
                        <Button
                            className={classes.saveBtn}
                            type="button"
                            color="primary"
                            size="small"
                            onFocus={() => {
                                props.updateField('isDraft', false)
                            }}
                            onClick={() => {
                                props.submitRFQForm()
                            }}
                            disabled={draftParts?.length === 0 || !!submitting}
                        >
                            {`${t('side-menu-request')}`}
                        </Button>
                    </div>
                </Grid>
            </Grid>
        </>
    )
}

const mapStateToProps = (state: State) => {
    return {
        rfq: state.rfq,
        submitting: isSubmitting(RFQQuoteEditorFormName)(state),
    }
}
const mapDispatchToProps = (dispatch: Dispatch) => ({
    updateField: (field, data) => dispatch(change(RFQQuoteEditorFormName, field, data)),
    submitRFQForm: () => dispatch(submit(RFQQuoteEditorFormName)),
})

const EditorHeaderComponent = withStyles(styles)(withNamespaces()(header))
const EditorHeader = connect(mapStateToProps, mapDispatchToProps)(EditorHeaderComponent)

export default memo(EditorHeader)
