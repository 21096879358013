import React, { Dispatch, SetStateAction } from 'react';
import { Grid, Typography } from '@material-ui/core';
import {
  Document,
  DocumentCardList,
  DocumentMenuOption,
  DocumentType,
  FileExtensions,
  MIMEFileType,
} from '../document';
import { FilePondWrapper } from './FilePondWrapper';
import { ValueLabelOption } from '../ui';
import { EntityType } from '../actionItems';

interface DocumentManagerProps {
  documents: Document[];
  setDocuments: (fct: (documents: Document[]) => Document[]) => void;
  setAreDocumentsDirty?: Dispatch<SetStateAction<boolean>>;
  entity?: { entityId: string; entityType: EntityType };
  label?: string;
  multiple?: boolean;
  maxFiles?: number;
  maxFileSizeMB?: number;
  documentLimit?: number;
  docType: DocumentType;
  referenceId: string;
  acceptedFileTypes?: (MIMEFileType | string)[];
  acceptedFileExtensions?: FileExtensions[];
  fileAreaCaption?: string;
  documentMenuOptions?: DocumentMenuOption[];
  documentTypeOptions?: ValueLabelOption[];
  clearAllFilesAfterProcessing?: boolean;
  showRevision?: boolean;
  showType?: boolean;
  showVisibility?: boolean;
  handleRemoveDocument?: (docIds: string[], docId: string) => void;
  handleAddDocument?: (doc: Document) => void;
  handleAllFilesProcessed?: () => void;
}

/**
 *
 * @param { Array<Document> } documents The array of documents that the document manager operates on to display.
 * @param { Dispatch<React.SetStateAction<Array<Document>>> } setDocuments The callback function of the form of the second return value from React.useState
 * @param { Dispatch<React.SetStateAction<boolean>> } [setAreDocumentsDirty] The callback function of the form of the second return value from React.useState for setting whether all documents are uploaded to the remote storage location.
 * @param { string } [label] The label that will appear in the typography component for this manage instance.
 * @param { boolean } [multiple] True indicates that multiple documents can be uploaded to this component vice just replacing the uploaded document.
 * @param { number } [maxFiles] The maximum number of files that the uploader will manage in the event multiple is true.
 * @param { number } [maxFileSizeMB] The maximum file size for each individual file specified in MB.
 * @param { number } [documentLimit] Number of documents that uploader can handle. Specify 0 to have no limit.
 * @param { DocumentType } docType Indicates which of the types each file upload will default to. Should be one of the options provided for DocumentTypeOptions parameter.
 * @param { string } referenceId Reference ID passed to file pond that helps segregate documents in the storage solution (S3 for now)
 * @param { Array<MIMEFileType> } [acceptedFileTypes] Allowable MIME types for a given file upload. If this check fails, file will be validated by allowable extensions.
 * @param { Array<FileExtensions> } [acceptedFileExtensions] Allowable file extensions. This parameter is checked if MIME validation fails.
 * @param { string } [fileAreaCaption] Allowable file extensions. This parameter is checked if MIME validation fails.
 * @param { Array<DocumentMenuOption> } [documentMenuOptions] Array of options that are provided in the expansion in the upper right corner of the Document manager interface
 * @param { Array<DocumentType> } [documentTypeOptions] Array of options that are provided if showType is true. 
 * @returns { ReactElement }
 */

export const DocumentManager = ({
  documents,
  setDocuments,
  setAreDocumentsDirty = null,
  label = '',
  multiple = true,
  maxFiles = 20,
  maxFileSizeMB = 50,
  documentLimit = 0,
  docType,
  referenceId,
  acceptedFileTypes = [
    MIMEFileType.PDF,
    MIMEFileType.EXTENSION,
    MIMEFileType.DOCX,
    MIMEFileType.XLSX,
  ],
  acceptedFileExtensions = [
    FileExtensions.DOCX,
    FileExtensions.PDF,
    FileExtensions.XLSX,
  ],
  fileAreaCaption = 'Accepted file types: PDF | XLSX | DOCX files - Max Single Document Size: 50MB',
  documentMenuOptions = [
    DocumentMenuOption.VIEW,
    DocumentMenuOption.DOWNLOAD,
    DocumentMenuOption.REMOVE,
  ],
  documentTypeOptions = [
    { value: DocumentType.MODEL_2D, label: '2D Model' },
    { value: DocumentType.MODEL_3D, label: '3D Model' },
    { value: DocumentType.SUPPORT_FILE, label: 'Support File' },
  ],
  clearAllFilesAfterProcessing = true,
  showRevision = false,
  showType = true,
  showVisibility = true,
  handleRemoveDocument = () => {},
  handleAddDocument = () => {},
  handleAllFilesProcessed = () => {},
  entity,
}: DocumentManagerProps) => {
  return (
    <>
      {!!label && (
        <Grid item xs={12} sm={12} md={12}>
          <Typography gutterBottom variant="h6">
            {label}
          </Typography>
        </Grid>
      )}
      <Grid item xs={12} sm={12} md={12}>
        {(documentLimit === 0 || documents.length < documentLimit) && (
          <>
            <FilePondWrapper
              type={docType}
              acceptedFileTypes={acceptedFileTypes}
              acceptedFileExtensions={acceptedFileExtensions}
              allowMultiple={multiple}
              maxFiles={maxFiles}
              maxFileSize={`${maxFileSizeMB}MB`}
              maxTotalFileSize={`${maxFiles * maxFileSizeMB}MB`}
              referenceId={referenceId}
              onLoad={(document: Document) => {
                setDocuments(documentList => [...documentList, document]);
                if (!!setAreDocumentsDirty) {
                  setAreDocumentsDirty(true);
                }
                handleAddDocument(document);
              }}
              clearAfterAllFilesProcessed={clearAllFilesAfterProcessing}
              allFilesProcessed={handleAllFilesProcessed}
            />
            <Typography
              gutterBottom
              variant="caption"
              paragraph
              align="center"
              style={{ marginTop: '5px' }}
            >
              {fileAreaCaption}
            </Typography>
          </>
        )}
        <DocumentCardList
          documents={documents}
          setDocuments={setDocuments}
          availableMenuOptions={documentMenuOptions}
          typeOptions={documentTypeOptions}
          setAreDocumentsDirty={setAreDocumentsDirty}
          showRevision={showRevision}
          showType={showType}
          showVisibility={showVisibility}
          handleRemoveDocument={(documentIds: string[], docId: string) => {
            handleRemoveDocument(documentIds, docId);
          }}
          entity={entity?.entityType}
          entityId={entity?.entityId}
        />
      </Grid>
    </>
  );
};
