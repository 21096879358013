import React from 'react'
import { Helmet } from 'react-helmet'
import { Map } from 'immutable'
import { withNamespaces, WithNamespaces } from 'react-i18next'
import { Dispatch } from 'redux'
import { WithStyles } from '@material-ui/core/styles'
import { connect } from 'react-redux'
import Typography from '@material-ui/core/Typography'
import { LoginForm } from './components/LoginForm'
import { LoginData, LOGIN, BuyerIcon } from '@syncfab/machine'
import { State } from '../../application/Store'
import { Link, withRouter, RouteComponentProps } from 'react-router-dom'
import { Avatar, Box, Card, Grid, withStyles } from '@material-ui/core'
import { CenteredContainer } from '../../layout/Container'

const styles = theme => ({
    container: {
        padding: theme.spacing(2),
    },
    card: {
        width: '100%',
        margin: 'auto',
        padding: theme.spacing(2),
    },
    title: {
        color: theme.palette.common.white,
        fontSize: theme.spacing(3),
        marginLeft: 12,
    },
    error: {
        color: theme.palette.error.main,
    },
    signupText: {
        color: theme.palette.common.white,
        'text-align': 'center',
        marginTop: theme.spacing(2),
    },
    signupLink: {
        margin: 0,
        padding: 0,
        'text-decoration': 'none',
        color: theme.palette.primary.light,
    },
    header: {
        display: 'flex',
    },
    small: {
        width: theme.spacing(4),
        height: theme.spacing(4),
        backgroundColor: theme.palette.background.default,
    },
    icon: {
        fontSize: theme.spacing(2),
    },
})

interface LoginProps extends WithNamespaces, WithStyles, RouteComponentProps {
    login: (data: any) => void
    authError?: string | null
    previousUrl?: string
}

class LoginView extends React.Component<LoginProps> {
    constructor(props: LoginProps) {
        super(props)
        this.onSubmit = this.onSubmit.bind(this)
    }
    resendLink = (e: any) => {
        e.stopPropagation()
        this.props.history.push('/reverification')
    }

    public render() {
        const { classes, t, authError } = this.props
        return (
            <CenteredContainer>
                <Helmet>
                    <title>{`${t('login-header')}`}</title>
                </Helmet>
                <Grid container justifyContent="center" className={classes.container}>
                    <Grid item xs={12} sm={6} md={4}>
                        <Card className={classes.card}>
                            <Box className={classes.header}>
                                <Avatar className={classes.small}>
                                    <BuyerIcon className={classes.icon} />
                                </Avatar>
                                <Typography variant="h2" gutterBottom className={classes.title}>
                                    {`${t('buyer-login-title')}`}
                                </Typography>
                            </Box>
                            {!!authError && (
                                <Typography variant="caption" className={classes.error} style={{ display: 'inline' }}>
                                    {`${t(authError)} `}
                                </Typography>
                            )}
                            {!!authError && authError === 'authentication-login-not-activated' && (
                                <Typography variant="caption" className={classes.error}>
                                    <Link to="/reverification" className={classes.signupLink}>
                                        {` ${t('authentication-resend-email')}`}
                                    </Link>
                                </Typography>
                            )}
                            {!!authError && authError === 'authentication-unknown-email' && (
                                <Typography variant="caption" className={classes.error}>
                                    {'Please double check email or'}
                                    <Link to="/signup" className={classes.signupLink}>
                                        {` ${t('authentication-signup')}`}
                                    </Link>
                                </Typography>
                            )}
                            {!!authError && authError === 'authentication-account-deactivated' && (
                                <Typography variant="caption" className={classes.error}>
                                    <Link onClick={() => window.location.href="mailto:support@syncfab.com"} to="#" className={classes.signupLink}>
                                        {` ${t('authentication-contact-us')}`}
                                    </Link>
                                </Typography>
                            )}

                            <LoginForm onSubmit={this.onSubmit as any} />
                            <Typography variant="caption" className={classes.signupText}>
                                <Link to="/forgot" className={classes.signupLink}>
                                    {`${t('login-forgot-password')}  `}
                                </Link>
                                {/* {shouldShowResendBtn && (
                            <Button type="submit" color="secondary" onClick={this.resendLink}>
                                Resend Link
                            </Button>
                        )} */}
                            </Typography>
                        </Card>
                    </Grid>
                </Grid>
            </CenteredContainer>
        )
    }

    private onSubmit(values: Map<string, string>) {
        this.props.login({ ...values.toObject(), previousUrl: this.props.previousUrl })
    }
}

const mapStateToProps = ({ auth: { authError }, user: { previousUrl } }: State) => {
    return {
        authError,
        previousUrl,
    }
}

const mapDispatchToProps = (dispatch: Dispatch) => {
    return {
        login: (data: LoginData) => dispatch(LOGIN(data)),
    }
}

const loginWithTranslations = withRouter(withNamespaces()(withStyles(styles)(LoginView)))

export const Login = connect(mapStateToProps, mapDispatchToProps)(loginWithTranslations)
