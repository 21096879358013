import React from 'react'
import { Field, reduxForm } from 'redux-form/immutable'
import { InjectedFormProps } from 'redux-form'
import { Map } from 'immutable'
import { WithStyles, withStyles } from '@material-ui/core/styles'
import { withNamespaces, WithNamespaces } from 'react-i18next'
import { FormTextField, Button, jumpToFirstError, FormAutocompleteV2 } from '@syncfab/machine'
import { Grid } from '@material-ui/core'

export const CompanySettingsFormName: string = 'companySettingsForm'

const styles = theme => ({
    form: {
        // display: 'flex',
        // 'flex-direction': 'column',
        // maxWidth: 1000,
        // minWidth: 300,
        // width: '100%',
        marginTop: theme.spacing(4),
        margin: 'auto',
        [theme.breakpoints.down('md')]: {
            maxWidth: 600,
        },
    },
    action: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
        marginLeft: 'auto',
    },
    gridItem: {
        paddingTop: '0px !important',
        paddingBottom: '0px !important',
    },
    formInput: {
        '& .MuiFormControl-marginNormal': {
            margin: 0,
        },
    },
})

type CompanySettingsFields = {
    name?: string
    size?: number
    industry?: string
}

const validate = (data: Map<string, string>): {} => {
    const errors: CompanySettingsFields = {}
    ;['name', 'size', 'industry'].forEach(field => {
        if (!data.get(field)) {
            errors[field] = 'Required'
        }
    })

    return errors
}
interface Properties {
    companyOpts?: any[]
    industryOpts?: any[]
}

const form = (props: Properties & InjectedFormProps<{}, {}> & WithStyles & WithNamespaces) => {
    const { handleSubmit, submitting, t, classes, companyOpts, industryOpts } = props

    return (
        <form onSubmit={handleSubmit} className={classes.form}>
            <Grid container spacing={3}>
                <Grid item md={12} xs={12} className={classes.gridItem}>
                    <Field name="name" component={FormTextField} type="text" label={t('company-name')} fullWidth className={classes.formInput} />
                </Grid>
                <Grid item md={6} xs={12} className={classes.gridItem}>
                    <Field
                        name="size"
                        component={FormAutocompleteV2}
                        options={companyOpts}
                        format={value => {
                            if (!value) {
                                return []
                            }
                            if (!!value.toJS) {
                                return value.toJS()
                            }
                            return value
                        }}
                        label={t('company-size')}
                        fullWidth
                    ></Field>
                </Grid>
                <Grid item md={6} xs={12} className={classes.gridItem}>
                    <Field
                        name="industry"
                        component={FormAutocompleteV2}
                        options={industryOpts}
                        format={value => {
                            if (!value) {
                                return []
                            }
                            if (!!value.toJS) {
                                return value.toJS()
                            }
                            return value
                        }}
                        label={t('company-industry')}
                        fullWidth
                        className={classes.formInput}
                    ></Field>
                </Grid>
                <Grid item md={12} xs={12} style={{ textAlign: 'end' }}>
                    <Button type="submit" color="primary" disabled={submitting} className={classes.action}>
                        {t('form-action-save')}
                    </Button>
                </Grid>
            </Grid>
        </form>
    )
}

const translatedForm = withNamespaces()(withStyles(styles)(form))
export const CompanySettingsForm = reduxForm<{}, Properties>({
    form: CompanySettingsFormName,
    validate,
    onSubmitFail: jumpToFirstError,
    enableReinitialize: true,
})(translatedForm)
