import React from 'react'
import { reduxForm } from 'redux-form/immutable'
import { InjectedFormProps } from 'redux-form'
import { Map } from 'immutable'
import { WithStyles, withStyles } from '@material-ui/core/styles'
import { withNamespaces, WithNamespaces } from 'react-i18next'
import { Button, jumpToFirstError } from '@syncfab/machine'
import { ProfileSettings } from '../../../components/user/components/ProfileSettings'
import { isNumber } from '../../../utils/Regex'
import { Grid } from '@material-ui/core'

export const SettingsPersonalFormName: string = 'settingsPersonalForm'

const styles = theme => ({
    form: {
        // display: 'flex',
        // 'flex-direction': 'column',
        marginTop: theme.spacing(1),
        margin: 'auto',
        [theme.breakpoints.down('md')]: {
            maxWidth: 600,
        },
    },
    action: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
        marginLeft: 'auto',
    },
})

type PersonalSettingsFields = {
    firstName?: string
    lastName?: string
    phone?: string
    title?: string
}

const validate = (data: Map<string, string>): {} => {
    const errors: PersonalSettingsFields = {}
    ;['firstName', 'lastName', 'phone', 'title'].forEach(field => {
        if (!data.get(field)) {
            errors[field] = 'Required'
        }
    })
    let mobileNumber = data.get('mobile')

    if (mobileNumber && mobileNumber.length > 0 && !isNumber(data.get('mobile'))) {
        errors['mobile'] = 'Enter Valid Mobile number.'
    }

    return errors
}

const form = (props: InjectedFormProps<{}, {}> & WithStyles & WithNamespaces) => {
    const { handleSubmit, submitting, t, classes } = props
    return (
        <form onSubmit={handleSubmit} className={classes.form}>
            <Grid container>
                <Grid item xs={12} md={12}>
                    <ProfileSettings isLoggedUserProfile={true} />
                </Grid>
                <Grid item xs={12} md={12} style={{ textAlign: 'end' }}>
                    <Button type="submit" color="primary" disabled={submitting} className={classes.action}>
                        {t('form-action-save')}
                    </Button>
                </Grid>
            </Grid>
        </form>
    )
}

const translatedForm = withNamespaces()(withStyles(styles)(form))
export const SettingsPersonalForm = reduxForm<{}, PersonalSettingsFields>({
    form: SettingsPersonalFormName,
    validate,
    onSubmitFail: jumpToFirstError,
})(translatedForm)
