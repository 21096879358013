import React, { useState } from 'react'
import { Card, withStyles, CardContent, Typography, Grid, IconButton, Menu, MenuItem, Avatar } from '@material-ui/core'
import { withNamespaces } from 'react-i18next'
import { Button, ValueLabelOption } from '../../ui'
import MoreVertIcon from '@material-ui/icons/MoreVert'
import RoomIcon from '@material-ui/icons/Room'

export interface AddressCreateInlinePayload {
    address: Partial<Address>
    formName: string
    field: string
}

export interface Address {
    _id: string
    name: string
    firstName: string
    lastName: string
    companyId: string
    location: Location
    phoneNumber: string
    createdAt: Date
    updateAt?: Date
    deleteAd?: Date
}

export interface Location {
    line: string
    line2: string
    city: string
    state: string
    country: string
    zipCode: string
}

export const createAddressOption = (address: Address): ValueLabelOption => {
    if (!address) {
        return null
    } else if (!!address.name) {
        return { value: address._id, label: `${address.name} - ${address.location.line}, ${address.location.zipCode}` }
    } else {
        return { value: address._id, label: `${address.location.line}, ${address.location.city}, ${address.location.state} ${address.location.zipCode}` }
    }
}

const styles = theme => ({
    // card: {
    //     backgroundColor: theme.palette.common.white,
    //     width: '49%',
    //     height: 204,
    //     marginTop: theme.spacing.unit * 2,
    //     [theme.breakpoints.down('md')]: {
    //         width: '100%',
    //     },
    // },
    card: {
        margin: 0,
        backgroundColor: theme.palette.background.paper,
        position: 'relative' as any,
        height: '100%',
        display: 'flex' as any,
        flexDirection: 'column' as any,
        justifyContent: 'space-between' as any,
        border: '2px solid #566E83',
    },
    fullWidthCard: {
        backgroundColor: theme.palette.background.paper,
        width: '100%',
        height: 186,
        marginTop: theme.spacing.unit * 2,
        [theme.breakpoints.down('md')]: {
            width: '100%',
        },
    },
    addCard: {
        display: 'flex',
        'justify-content': 'center',
        'align-items': 'center',
        'flex-direction': 'column',
    },
    actions: {
        'justify-content': 'flex-end',
        '& button': {
            color: theme.palette.primary.dark,
        },
    },
    divider: {
        height: 20,
        color: theme.palette.grey.A100,
        borderRight: '1px solid',
    },
    content: {
        color: theme.palette.common.white,
        fontSize: '14px' as any,
        fontWeight: theme.typography.fontWeightMedium,
    },
    cardAction: {
        position: 'absolute' as any,
        top: 0,
        right: 0,
    },
    small: {
        width: theme.spacing(4),
        height: theme.spacing(4),
        backgroundColor: theme.palette.background.default,
    },
    saveBtn: {
        width: 130,
        minHeight: '36px',
    },
})

const card = ({ classes, address, onDelete, onEdit, fullWidth, title }: any) => {
    const ITEM_HEIGHT = 48
    const options = ['Edit', 'Delete']
    const [anchorEl, setAnchorEl] = useState(null)
    const handleClose = () => {
        setAnchorEl(null)
    }
    const open = Boolean(anchorEl)

    const handleClick = event => {
        setAnchorEl(event.currentTarget)
    }
    const handleDocumentMenuOption = (option, address) => {
        if (option.toLowerCase() === 'edit') {
            onEdit(address)
        }
        if (option.toLowerCase() === 'delete') {
            onDelete(address._id)
        }
    }
    return (
        <Card className={!!fullWidth ? classes.fullWidthCard : classes.card} variant="outlined">
            <CardContent>
                <Grid container spacing={2} direction="row">
                    <Grid item xs={1} sm={2} md={1}>
                        <Avatar className={classes.small}>
                            <RoomIcon style={{ color: '#fff' }} />
                        </Avatar>
                    </Grid>
                    <Grid item xs={10} sm={8} md={9}>
                        {!!title && (
                            <Typography className={classes.content} variant="h3" gutterBottom>
                                {title}
                            </Typography>
                        )}
                        <Typography className={classes.content} component="p">
                            {address.location.line}
                        </Typography>
                        {address.location.line2 && (
                            <Typography className={classes.content} component="p">
                                {address.location.line2}
                            </Typography>
                        )}
                        <Typography className={classes.content} component="p">
                            {address.location.city}, {address.location.state} {address.location.zipCode}
                        </Typography>
                        <Typography className={classes.content} component="p">
                            {address.location.country}
                        </Typography>
                    </Grid>
                    <Grid item xs={1} sm={2} md={1} className={classes.cardAction}>
                        <IconButton aria-label="More" aria-owns={open ? 'long-menu' : undefined} aria-haspopup="true" style={{ color: '#fff' }} onClick={e => handleClick(e)}>
                            <MoreVertIcon />
                        </IconButton>
                        <Menu
                            id="long-menu"
                            anchorEl={anchorEl}
                            open={open}
                            onClose={handleClose}
                            PaperProps={{
                                style: {
                                    maxHeight: ITEM_HEIGHT * 4.5,
                                    width: 100,
                                },
                            }}
                        >
                            {options.map((option, index) => (
                                <MenuItem
                                    key={index}
                                    onClick={() => {
                                        handleClose()
                                        handleDocumentMenuOption(option, address)
                                    }}
                                >
                                    {option}
                                </MenuItem>
                            ))}
                        </Menu>
                    </Grid>
                </Grid>
            </CardContent>
        </Card>
    )
}

export const AddressCard = withStyles(styles)(withNamespaces()(card))

const addAddress = ({ openDialog, classes, t }: any) => {
    return (
        <Grid container style={{ marginTop: 24 }}>
            <Grid item xs={12} style={{ textAlign: 'end' }}>
                <Button color="primary" className={classes.saveBtn} aria-label="Add" onClick={openDialog}>
                    {t('address-dialog-title')}
                </Button>
            </Grid>
        </Grid>
    )
}

export const AddAddressCard = withStyles(styles)(withNamespaces()(addAddress))
