import React from 'react'
import { Helmet } from 'react-helmet'
import { Map } from 'immutable'
import { withNamespaces, WithNamespaces } from 'react-i18next'
import { Dispatch } from 'redux'
import { WithStyles } from '@material-ui/core/styles'
import { connect } from 'react-redux'
import Typography from '@material-ui/core/Typography'
import { State } from '../../../application/Store'
import { Card, withStyles } from '@material-ui/core'
// import { CenteredContainer } from '../../../layout/Container'
import { Stepper } from '../../../layout/stepper/Stepper'
import { SupplierSignupPersonalInformationForm } from './components/SupplierSignupPersonalInformationForm'
import { SupplierSignupCompanyInformationForm } from './components/SupplierSignupCompanyInformationForm'
import { SupplierSignupFinalDetailsForm } from './components/SupplierSignupFinalDetails'
import { SUPPLIER_CREATE } from '../../../components/supplier/Actions'
import { Supplier } from '@syncfab/machine'

const styles = theme => ({
    stepper: {
        width: 500,
        margin: 'auto',
        padding: theme.spacing(2),
    },
    title: {
        color: theme.palette.common.white,
    },
    error: {
        color: theme.palette.error.main,
    },
    signinText: {
        color: theme.palette.common.white,
        'text-align': 'center',
        marginTop: theme.spacing(1),
    },
    supplierSignupText: {
        'text-align': 'left',
        marginTop: theme.spacing(1),
    },
    subheading: {
        marginTop: theme.spacing(2),
    },
})

interface SupplierSignupProps extends WithNamespaces, WithStyles {
    signup: (data: Partial<Supplier>) => void
    signupError?: string
}

class SupplierSignupView extends React.Component<SupplierSignupProps> {
    private steps: string[] = ['supplier-signup-step-personal', 'supplier-signup-step-company', 'supplier-signup-step-final']
    state: { activeStep: number } = {
        activeStep: 0,
    }

    constructor(props: SupplierSignupProps) {
        super(props)
        this.onSubmit = this.onSubmit.bind(this)
        this.next = this.next.bind(this)
        this.previous = this.previous.bind(this)
    }

    next = () => {
        const { activeStep } = this.state
        if (activeStep > this.steps.length) {
            return
        }
        this.setState({
            activeStep: activeStep + 1,
        })
    }

    previous = () => {
        const { activeStep } = this.state
        if (activeStep === 0) {
            return
        }
        this.setState({
            activeStep: activeStep - 1,
        })
    }

    public render() {
        const { activeStep } = this.state
        const { classes, t, signupError } = this.props
        return (
            <div style={{ marginTop: '80px' }}>
                <Helmet>
                    <title>{`${t('supplier-signup-title')}`}</title>
                </Helmet>
                <Card className={classes.stepper} elevation={1}>
                    <Typography component="h3" variant="h3" className={classes.title}>
                        {`${t('supplier-sign-up-header')}`}
                    </Typography>
                    {activeStep === 0 && (
                        <Typography variant="caption" className={classes.supplierSignupText}>
                            {`${t('supplier-sign-up-text')}`}
                        </Typography>
                    )}
                    <Typography component="h4" variant="h4" className={classes.subheading}>
                        {`${t(this.steps[activeStep])}`}
                    </Typography>
                    {!!signupError && <Typography className={classes.error}>{`${t(signupError)}`}</Typography>}
                    <Stepper activeStep={activeStep} steps={this.steps} />
                    <section>
                        {activeStep === 0 && <SupplierSignupPersonalInformationForm onSubmit={this.next} />}
                        {activeStep === 1 && <SupplierSignupCompanyInformationForm onPrevious={this.previous} onSubmit={this.next} />}
                        {activeStep === 2 && <SupplierSignupFinalDetailsForm onPrevious={this.previous} onSubmit={this.onSubmit as any} />}
                    </section>
                </Card>
            </div>
        )
    }

    private onSubmit(data: Map<string, string>) {
        const values = data.toJS() as any
        delete values['agreement']

        const { line, line2, city, zipCode, country, state } = values?.location
        const user = {
            email: values?.email ? values.email : '',
            firstName: values?.firstName ? values.firstName : '',
            lastName: values?.lastName ? values.lastName : '',
            password: values?.password ? values.password : '',
            phone: values?.phone ? values.phone : '',
            title: values?.title ? values.title : '',
            companyName: values.companyName ?? '',
            location: {
                line: line ? line : '',
                line2: line2 ? line2 : '',
                city: city ? city : '',
                zipCode: zipCode ? zipCode : '',
                country: country ? country?.value : '',
                state: state ? state?.value : '',
            },
            heardAboutUs: values?.heardAboutUs ? values.heardAboutUs.value : '',
        }
        this.props.signup(user)
    }
}

const mapStateToProps = ({ supplier: { signupError } }: State) => {
    return {
        signupError,
    }
}

const mapDispatchToProps = (dispatch: Dispatch) => {
    return {
        signup: (data: Partial<Supplier>) => dispatch(SUPPLIER_CREATE.actions.TRIGGER(data)),
    }
}

const SupplierSignupWithTranslations = withNamespaces()(withStyles(styles)(SupplierSignupView))

export const SupplierSignup = connect(mapStateToProps, mapDispatchToProps)(SupplierSignupWithTranslations)
