import React from 'react'
import SvgIcon from '@material-ui/core/SvgIcon'

export const SupplierLoginIcon = props => {
    return (
        <SvgIcon {...props} viewBox="0 0 512 512" x="0px" y="0px" baseProfile="tiny" xmlSpace="preserve">
            <g fill={props.fill || '#46b4bf'}>
                <title>manufacturer-Corporate-factory-industry-Business</title>
                <path d="M496,424a24.039,24.039,0,0,0-16-22.624V192a8,8,0,0,0-8-8H456V80a32.036,32.036,0,0,0-32-32H216a32.036,32.036,0,0,0-32,32V233.376a23.982,23.982,0,0,0-7.132,41.235l-54.136,47.368A8,8,0,0,0,120,328v56a7.994,7.994,0,0,0,.845,3.578l8,16A8,8,0,0,0,136,408h24a8,8,0,0,0,8-8h16V384H168V368h16V352H168v-4.687L195.314,320h41.372L264,347.313V352H248v16h16v16H248v16h16a8,8,0,0,0,8,8h24a8,8,0,0,0,7.155-4.422l8-16A7.994,7.994,0,0,0,312,384V328a8,8,0,0,0-2.732-6.021l-54.136-47.368A23.982,23.982,0,0,0,248,233.376V112H392v72H376a8,8,0,0,0-8,8v64h16V200h9.013a32,32,0,0,0,61.974,0H464V400H408V320h16a8,8,0,0,0,8-8V280a8,8,0,0,0-8-8H288v16H416v16H320v16h72V480H144V440a8,8,0,0,0-8-8H88a8,8,0,0,0-8,8v40H56V320h56V304H32V288H144V272H24a8,8,0,0,0-8,8v32a8,8,0,0,0,8,8H40V488a8,8,0,0,0,8,8H472a8,8,0,0,0,8-8V446.624A24.039,24.039,0,0,0,496,424ZM440,80a16,16,0,1,1-16-16A16.019,16.019,0,0,1,440,80ZM216,64a16,16,0,1,1-16,16A16.019,16.019,0,0,1,216,64ZM200,232V107.7a31.885,31.885,0,0,0,32,0V232Zm-8,16h48a8,8,0,0,1,0,16H192a8,8,0,0,1,0-16Zm-37.657,90.343A8,8,0,0,0,152,344v48H140.944L136,382.111V331.63l48-42v19.056ZM232,304H200V280h32Zm64,78.111L291.056,392H280V344a8,8,0,0,0-2.343-5.657L248,308.686V289.63l48,42ZM248,80A31.793,31.793,0,0,0,243.7,64h152.61a31.885,31.885,0,0,0,0,32H248Zm176,32a31.8,31.8,0,0,0,16-4.305v56.61a31.885,31.885,0,0,0-32,0V112Zm0,96a16,16,0,1,1,16-16A16.019,16.019,0,0,1,424,208ZM96,448h32v32H96Zm312,32V448h56v32Zm64-48H408V416h64a8,8,0,0,1,0,16Z" />
                <rect x="240" y="16" width="152" height="16" />
                <rect x="408" y="16" width="16" height="16" />
                <rect x="160" y="432" width="16" height="16" />
                <rect x="200" y="432" width="16" height="16" />
                <rect x="240" y="432" width="16" height="16" />
                <rect x="280" y="432" width="16" height="16" />
                <rect x="320" y="432" width="16" height="16" />
                <rect x="360" y="432" width="16" height="16" />
            </g>
        </SvgIcon>
    )
}
