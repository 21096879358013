import React, { useState } from "react";
import { Box, Grid, Paper, Typography, useTheme } from "@material-ui/core";
import {
    AutoCompleteValueLabel,
    List,
    ListMeta, ListResult,
    ListSearchTermInput, Loader,
    NotificationCategory, PurchaseOrderDetails,
    PurchaseOrderStatus,
    PurchaseOrderStatusMap,
    SupplierPurchaseOrderDetail,
    useNotifications,
    ValueLabelOption
} from "@syncfab/machine";
import { useStandardStyles } from "../styles/standardStyles";
import { useAppDispatch, useAppSelector } from "../../application/hooks";
import Helmet from "react-helmet";
import { SupplierPurchaseOrderRow } from "./components/SupplierPurchaseOrderRow";
import { AlertBox } from "@syncfab/machine/src/components/notifications/components/AlertBox";
import { updateExternalPurchaseOrderListParameters } from "../../application/ExternalPurchaseOrderListParametersSlice";
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';
import { ExternalPurchaseOrderQuery } from "@syncfab/machine/src/components/list/ExternalPurchaseOrderQuery";
import { BuyerPurchaseOrderRow } from "./components/BuyerPurchaseOrderRow";

interface PurchaseOrderListProps {
    listColumns: { id: string, label: string, fixed?: boolean, icon?: JSX.Element, action?: () => void }[]
    retrieveData: (listParams: ExternalPurchaseOrderQuery) => { data?: ListResult<SupplierPurchaseOrderDetail | PurchaseOrderDetails>, isLoading: boolean }
}

const isSupplierPurchaseOrderDetail = (b: SupplierPurchaseOrderDetail | PurchaseOrderDetails): b is SupplierPurchaseOrderDetail => {
    return (b as SupplierPurchaseOrderDetail).selectedSupplier !== undefined
}

export const PurchaseOrderList = ({ listColumns, retrieveData }: PurchaseOrderListProps) => {

    const standardClasses = useStandardStyles()
    const theme = useTheme()

    const listParams = useAppSelector((state) => state.externalPurchaseOrderListParameters)

    const { data: poData, isLoading: poDataIsLoading } =
        retrieveData(listParams)

    const statusOptions: ValueLabelOption[] =
        Array.from(PurchaseOrderStatusMap, ([value, label]) => ({ value, label }))
            .filter(option => option.value !== PurchaseOrderStatus.DRAFT)

    const dispatch = useAppDispatch()

    const [statusFilter, setStatusFilter] = useState<ValueLabelOption>(
        listParams.poStatus ? statusOptions.find(option => option.value === listParams.poStatus) : null)

    const [allOpen, setAllOpen] = useState<boolean>(false)

    const handleExpandAll = () => {
        setAllOpen(!allOpen)
    }

    const poListColumns = [
        { id: 'expand', label: 'Expand All', fixed: true, icon: <KeyboardArrowRightIcon />, action: handleExpandAll },
        ...listColumns
    ]

    const handleSearch = (searchText) => {
        dispatch(updateExternalPurchaseOrderListParameters({ ...listParams, searchTerm: searchText, page: 0 }))
    }

    const resetSearch = () => {
        dispatch(updateExternalPurchaseOrderListParameters({ ...listParams, searchTerm: '', page: 0 }))
    }

    const handleStatusFilter = (value: ValueLabelOption) => {
        setStatusFilter(value)
        dispatch(updateExternalPurchaseOrderListParameters({ ...listParams, poStatus: value?.value ?? '', page: 0 }))
    }

    const handleListMetaChange = (meta: ListMeta) => {
        dispatch(updateExternalPurchaseOrderListParameters({ ...listParams, ...meta }))
    }

    const [notificationData, _isNotificationDataLoading, handleNotificationClick] = useNotifications()

    return (
        <div className={standardClasses.centeredAlignedContainer}>
            <Helmet>
                <title>Purchase Orders</title>
            </Helmet>
            <Grid container justifyContent='flex-start'>
                <Typography gutterBottom style={{ marginTop: '32px', fontSize: 32, fontWeight: 'bold' }}>
                    Purchase Orders
                </Typography>
            </Grid>
            <Grid container justifyContent='flex-start'>
                <Grid item xs={12} md={12}>
                { notificationData?.filter(notification =>
                    [NotificationCategory.SUPPLIER_PURCHASE_ORDER_ISSUED, NotificationCategory.INVOICE_SENT]
                        .includes(notification.notificationCategory))
                    .map(notification =>
                        <AlertBox notification={notification} handleNotificationClick={handleNotificationClick} />
                    )
                }
                </Grid>
            </Grid>
            <Paper className={standardClasses.paper}>
                <Grid container direction="row" justifyContent="flex-start" alignItems="center" spacing={2}
                      className={standardClasses.listControlContainer}>
                    <Grid item xs={12} md={3}>
                        <ListSearchTermInput
                            initialValue={listParams.searchTerm ?? ''}
                            handleSearch={handleSearch}
                            resetSearch={resetSearch}
                        />
                    </Grid>
                    <Grid item xs={12} md={3}>
                        <AutoCompleteValueLabel
                            options={statusOptions}
                            name='POStatusFilter'
                            label='Status'
                            value={statusFilter}
                            onChangeHandler={(newValue: ValueLabelOption) => {
                                handleStatusFilter(newValue)
                            }}
                        />
                    </Grid>
                </Grid>
                {!!poData && poData?.data.length > 0 &&
                    <List<SupplierPurchaseOrderDetail | PurchaseOrderDetails>
                        columns={poListColumns}
                        freezeFirstColumn
                        data={poData}
                        meta={listParams}
                        onMetaChange={handleListMetaChange}
                        renderRow={(row: SupplierPurchaseOrderDetail | PurchaseOrderDetails) => {
                            if (isSupplierPurchaseOrderDetail(row)) {
                                return <SupplierPurchaseOrderRow supplierPO={row} allOpen={allOpen} key={row._id} />
                            } else {
                                return <BuyerPurchaseOrderRow po={row} allOpen={allOpen} key={row._id} />
                            }
                        }}
                    />
                }
                {!!poData && poData.data.length === 0 &&
                    <Box style={{ margin: theme.spacing(2), textAlign: 'center' }}>
                        <Typography gutterBottom style={{ fontSize: 18, fontWeight: 'bold' }}>
                            No Data Found
                        </Typography>
                    </Box>
                }
                {poDataIsLoading && <Loader />}
            </Paper>
        </div>
    )
}