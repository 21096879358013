import React from 'react'
import { TableRow, TableCell, Checkbox, Toolbar, Typography, Grid, Button, WithStyles, withStyles } from '@material-ui/core'
import { Loader, User, UserRole } from '@syncfab/machine'
import { UserListBaseProps } from '..'
import { List as BaseList } from '../../../../layout/list/List'
import { ListMeta } from '../../../../layout/list/Meta'
import { WithNamespaces, withNamespaces } from 'react-i18next'

const styles = theme => ({
    container: {
        width: '100%',
        minWidth: 300,
        paddingTop: theme.spacing(2),
        marginTop: theme.spacing(1),
    },
    toolbar: {
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.common.white,
        marginBottom: '-25px',
        borderRadius: '5px 5px 0 0',
    },
    actions: {
        borderColor: theme.palette.common.white,
        color: theme.palette.common.white,
        margin: theme.spacing(1),
    },
    tableRow: {
        '& .MuiTableCell-root': {
            borderBottom: `1px solid rgba(224, 224, 224, 0.23)`,
        },
    },
    compactRow: {
        paddingBottom: '5px',
        paddingTop: '5px',
    },
    checkbox: {
        '& .MuiCheckbox-root': {
            color: theme.palette.common.white,
        },
        '& .Mui-checked': {
            color: theme.palette.primary.main,
        },
    },
})
interface UserListProps extends WithStyles, WithNamespaces, UserListBaseProps {
    users: User[]
    meta: ListMeta
    getUserList: (meta: ListMeta) => void
}
interface UserState {
    selectedUser: any[]
}
class Users extends React.Component<UserListProps, UserState> {
    private ROWS = [
        { id: 'Select', label: '', fixed: true },
        { id: 'name', label: 'Name' },
        { id: 'email', label: 'Email' },
        { id: 'role', label: 'Role' },
        { id: 'Status', label: 'Status' },
        { id: 'createdDate', label: 'Created Date' },
        { id: 'lastLoginDate', label: 'Last Login Date' },
    ]
    newSelected = []
    rolesArr = [
        {
            label: UserRole.ADMIN,
            value: UserRole.ADMIN,
        },

        {
            label: UserRole.MEMBER,
            value: UserRole.MEMBER,
        },
    ]
    constructor(props) {
        super(props)
        this.state = {
            selectedUser: [],
        }
    }
    isSelected(user) {
        let x = this.state?.selectedUser?.find(item => user?._id == item?._id)
        return x ? true : false
    }
    handleClick = user => {
        const { selectedUser } = this.state
        let selectedIndex = -1
        if (selectedUser && selectedUser?.length > 0) {
            selectedUser?.forEach((item, i) => {
                if (user?._id === item?._id) {
                    selectedIndex = i
                }
            })
        } else {
            selectedIndex = -1
        }

        this.newSelected = []
        if (selectedIndex === -1) {
            this.newSelected = this.newSelected.concat(selectedUser, user)
        } else if (selectedIndex === 0) {
            this.newSelected = this.newSelected.concat(selectedUser.slice(1))
        } else if (selectedIndex === selectedUser.length - 1) {
            this.newSelected = this.newSelected.concat(selectedUser.slice(0, -1))
        } else if (selectedIndex > 0) {
            this.newSelected = this.newSelected.concat(selectedUser.slice(0, selectedIndex), selectedUser.slice(selectedIndex + 1))
        }
        this.setState({ selectedUser: this.newSelected })
    }
    getRoleNames = user => {
        if (!user || !user?.roles[0] || !user?.roles?.length) {
            return null
        }

        let records = []
        user?.roles?.forEach(role => {
            const filtered = this.rolesArr?.filter(v => {
                return v?.value?.toLowerCase() === role?.toLowerCase()
            })

            filtered && filtered?.length && records.push(filtered[0]?.label)
        })

        return records.join(', ')
    }
    private userStatusList = [
        { value: 'ACTIVE', label: 'ACTIVE' },
        { value: 'DEACTIVATED', label: 'DEACTIVATED' },
        { value: 'INVITED', label: 'INVITED' },
    ]
    render() {
        const { classes, users, deleteUser, editUser, meta, getUserList } = this.props
        const { selectedUser } = this.state
        if (!users) {
            return <Loader />
        }
        return (
            <div className={classes.container}>
                {selectedUser && selectedUser?.length === 1 ? (
                    <Toolbar color="primary" className={classes.toolbar}>
                        <Grid container justifyContent="space-between" alignItems="center" className={classes.header}>
                            <React.Fragment>
                                <Grid item>
                                    <Typography color="inherit" variant="subtitle1">
                                        {selectedUser.length} Selected
                                    </Typography>
                                </Grid>
                                <Grid item>
                                    <Button
                                        size="small"
                                        className={classes.actions}
                                        onClick={() => {
                                            deleteUser(selectedUser[0]?._id)
                                            this.setState({ selectedUser: [] })
                                        }}
                                    >
                                        REMOVE
                                    </Button>

                                    <Button
                                        variant="outlined"
                                        size="small"
                                        className={classes.actions}
                                        onClick={() => {
                                            editUser(selectedUser[0]?._id)
                                            this.setState({ selectedUser: [] })
                                        }}
                                    >
                                        EDIT
                                    </Button>
                                </Grid>
                            </React.Fragment>
                        </Grid>
                    </Toolbar>
                ) : (
                    ''
                )}
                <BaseList
                    elements={users}
                    rows={this.ROWS}
                    onChange={meta => {
                        delete meta.loginRole
                        getUserList({ ...meta, total: null })
                    }}
                    userStatusList={this.userStatusList}
                    meta={meta}
                    // showUserFilter={true}
                    tableTitle={'Users List'}
                    hideSortFilter={true}
                    renderBody={elements => {
                        return (
                            <React.Fragment>
                                {elements?.length > 0 &&
                                    elements?.map(user => {
                                        const isItemSelected = this.isSelected(user)
                                        return (
                                            <TableRow
                                                className={classes.tableRow}
                                                hover
                                                onClick={() => this.handleClick(user)}
                                                role="checkbox"
                                                aria-checked={isItemSelected}
                                                tabIndex={-1}
                                                key={user?._id}
                                            >
                                                <TableCell className={classes.checkbox}>
                                                    <Checkbox
                                                        color="primary"
                                                        checked={isItemSelected}
                                                        inputProps={{
                                                            'aria-label': 'primary checkbox',
                                                        }}
                                                        classes={{
                                                            root: classes.checkbox,
                                                        }}
                                                        className={classes.compactRow}
                                                    />
                                                </TableCell>
                                                <TableCell className={classes.compactRow} component="th" scope="row">
                                                    {user?.firstName} {user?.lastName}
                                                </TableCell>
                                                <TableCell className={classes.compactRow} component="th" scope="row">
                                                    {user?.email}
                                                </TableCell>
                                                <TableCell className={classes.compactRow} component="th" scope="row">
                                                    {this.getRoleNames(user)}
                                                </TableCell>
                                                <TableCell className={classes.compactRow} component="th" scope="row">
                                                    {user?.userState}
                                                </TableCell>
                                                <TableCell className={classes.compactRow} component="th" scope="row">
                                                    {user?.createdAt && new Date(user?.createdAt)?.toLocaleDateString()}
                                                    {user?.createdAt && new Date(user?.createdAt)?.toLocaleTimeString()}
                                                </TableCell>
                                                <TableCell className={classes.compactRow} component="th" scope="row">
                                                    {user?.lastActiveAt && new Date(user?.lastActiveAt)?.toLocaleDateString()}{' '}
                                                    {user?.lastActiveAt && new Date(user?.lastActiveAt)?.toLocaleTimeString()}
                                                </TableCell>
                                            </TableRow>
                                        )
                                    })}
                                {elements?.length === 0 && (
                                    <TableRow>
                                        <TableCell style={{ textAlign: 'center', border: 'none' }}>No User Found</TableCell>
                                    </TableRow>
                                )}
                            </React.Fragment>
                        )
                    }}
                />
            </div>
        )
    }
}

export const UserList = withStyles(styles)(withNamespaces()(Users))
