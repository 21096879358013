import { call, takeLatest, getContext, put, select } from 'redux-saga/effects'
import { Gateway } from '../gateway'
import { GET_LOGGED_COMPANY, COMPANY_UPDATE, ADDRESS_UPDATE, ADDRESS_CREATE, ADDRESS_DELETE, ADDRESS_GET, ADDRESS_CREATE_INLINE, UPDATE_COMPANY, DELETE_DOCUMENT } from './Actions'
import { stopSubmit, startSubmit, change } from 'redux-form'
import { CompanySettingsFormName } from '../../view/settings/components/CompanySettingsForm'
import { AddressCreateInlinePayload, Address, Company, SHOW_FEEDBACK } from '@syncfab/machine'
import { AddressFormName } from './address/AddressForm'
import { getCompany } from './Selectors'

function* getLoggedCompany() {
    try {
        const gateway: Gateway = yield getContext('gateway')
        const { data } = yield call(gateway.getCompanyAPI().getLoggedCompany())
        yield put(GET_LOGGED_COMPANY.actions.SUCCESSFULL(data))
    } catch ({ response: { status, data } }) {
        const handleError = yield getContext('handleError')
        yield call(handleError(status))
        yield put(GET_LOGGED_COMPANY.actions.FAILED(data))
    }
}

function* update({ data }: { data: Partial<Company> }) {
    try {
        yield put(startSubmit(CompanySettingsFormName))
        const gateway: Gateway = yield getContext('gateway')
        yield call(gateway.getCompanyAPI().update(data))

        yield put(COMPANY_UPDATE.actions.SUCCESSFULL())
        yield put(SHOW_FEEDBACK('feedback-user-updated'))
        yield put(stopSubmit(CompanySettingsFormName))
    } catch ({ response: { status, data } }) {
        const handleError = yield getContext('handleError')
        yield call(handleError(status))
        yield put(COMPANY_UPDATE.actions.FAILED(data))
        yield put(stopSubmit(CompanySettingsFormName))
    }
}

function* getAddresses() {
    try {
        const gateway: Gateway = yield getContext('gateway')
        const {
            data: { data },
        } = yield call(gateway.getCompanyAPI().getAddresses())
        yield put(ADDRESS_GET.actions.SUCCESSFULL(data))
    } catch ({ response: { status, data } }) {
        const handleError = yield getContext('handleError')
        yield call(handleError(status))
        yield put(ADDRESS_GET.actions.FAILED(data))
    }
}

function* updateAddress({ data: address }: { data: Address }) {
    try {
        yield put(startSubmit(AddressFormName))
        const gateway: Gateway = yield getContext('gateway')
        const {
            data: { data },
        } = yield call(gateway.getCompanyAPI().updateAddress(address))

        yield put(ADDRESS_UPDATE.actions.SUCCESSFULL(data))
        yield put(ADDRESS_GET.actions.TRIGGER())
        yield put(SHOW_FEEDBACK('feedback-address-updated'))
        yield put(stopSubmit(AddressFormName))
    } catch ({ response: { status, data } }) {
        const handleError = yield getContext('handleError')
        yield call(handleError(status))
        yield put(ADDRESS_UPDATE.actions.FAILED(data))
        yield put(stopSubmit(AddressFormName))
    }
}

function* creationAddress({ data: address }: { data: Partial<Address> }) {
    try {
        yield put(startSubmit(AddressFormName))
        const gateway: Gateway = yield getContext('gateway')
        const { data } = yield call(gateway.getCompanyAPI().createAddress(address))

        yield put(ADDRESS_CREATE.actions.SUCCESSFULL(data))
        yield put(SHOW_FEEDBACK('feedback-address-created'))
        yield put(stopSubmit(AddressFormName))
    } catch ({ response: { status, data } }) {
        const handleError = yield getContext('handleError')
        yield call(handleError(status))
        yield put(ADDRESS_CREATE.actions.FAILED(data))
        yield put(stopSubmit(AddressFormName))
    }
}

function* creationAddressInline({ data: { address, formName, field } }: { data: AddressCreateInlinePayload }) {
    try {
        yield put(startSubmit(formName))
        const gateway: Gateway = yield getContext('gateway')
        const { data } = yield call(gateway.getCompanyAPI().createAddress(address))

        yield put(ADDRESS_CREATE_INLINE.actions.SUCCESSFULL(data))
        yield put(SHOW_FEEDBACK('feedback-address-created'))
        // Have to use an object vice just string to match autocomplete.
        yield put(change(formName, field, {value: data._id, label: `${address.location.line}, ${address.location.zipCode}`}))
        yield put(stopSubmit(formName))
    } catch ({ response: { status, data } }) {
        const handleError = yield getContext('handleError')
        yield call(handleError(status))
        yield put(ADDRESS_CREATE_INLINE.actions.FAILED(data))
        yield put(stopSubmit(formName))
    }
}

function* deleteAddress({ data: id }: { data: string }) {
    try {
        const gateway: Gateway = yield getContext('gateway')
        yield call(gateway.getCompanyAPI().deleteAddress(id))

        yield put(ADDRESS_DELETE.actions.SUCCESSFULL())
        yield put(ADDRESS_GET.actions.TRIGGER())
        yield put(SHOW_FEEDBACK('feedback-address-deleted'))
    } catch ({ response: { status, data } }) {
        const handleError = yield getContext('handleError')
        yield call(handleError(status))
        yield put(ADDRESS_DELETE.actions.FAILED(data))
    }
}

function* updateCompany({ data: { companyId, companyInput } }: { data: { companyId: string; companyInput: Partial<Company> } }) {
    try {
        const gateway: Gateway = yield getContext('gateway')
        yield call(gateway.getCompanyAPI().updateCompany(companyId, companyInput))
        yield put(SHOW_FEEDBACK('document-uploaded'))
        yield call(getLoggedCompany)
    } catch ({ response: { status, data } }) {
        const handleError = yield getContext('handleError')
        yield call(handleError(status))
        yield put(SHOW_FEEDBACK(data))
    }
}

function* deleteDocument({ data: { documentId } }: { data: { documentId: string } }) {
    try {
        const gateway: Gateway = yield getContext('gateway')
        const company = yield select(getCompany)
        const documents = company?.documentIds?.filter(docId => docId !== documentId)
        yield call(gateway.getCompanyAPI().updateCompany(company?._id, { documentIds: documents }))
        yield put(SHOW_FEEDBACK('document-deleted'))
        yield call(getLoggedCompany)
        yield put(DELETE_DOCUMENT.actions.SUCCESSFULL())
    } catch ({ response: { status, data } }) {
        const handleError = yield getContext('handleError')
        yield call(handleError(status))
        yield put(DELETE_DOCUMENT.actions.FAILED(data))
        yield put(SHOW_FEEDBACK('feedback-document-deletion-failed'))
    }
}

function* companySaga() {
    // Typings are not good enough. String should be valid but the types
    // do not show it
    yield takeLatest(GET_LOGGED_COMPANY.types.TRIGGER as any, getLoggedCompany)
    yield takeLatest(COMPANY_UPDATE.types.TRIGGER as any, update)
    yield takeLatest(ADDRESS_CREATE.types.TRIGGER as any, creationAddress)
    yield takeLatest(ADDRESS_UPDATE.types.TRIGGER as any, updateAddress)
    yield takeLatest(ADDRESS_DELETE.types.TRIGGER as any, deleteAddress)
    yield takeLatest(ADDRESS_GET.types.TRIGGER as any, getAddresses)
    yield takeLatest(ADDRESS_CREATE_INLINE.types.TRIGGER as any, creationAddressInline)
    yield takeLatest(UPDATE_COMPANY.types.TRIGGER as any, updateCompany)
    yield takeLatest(DELETE_DOCUMENT.types.TRIGGER as any, deleteDocument)
}

export default companySaga
