import {
    Button,
    createStyles,
    FormControlLabel,
    Grid,
    makeStyles,
    Theme,
    Typography
} from "@material-ui/core";
import React, { FC, useState } from "react";
import { SHOW_FEEDBACK, useConfirmSupplierPurchaseOrderMutation } from "@syncfab/machine";
import { useAppDispatch } from "../../../application/hooks";
import Checkbox from "@material-ui/core/Checkbox";


const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        checkbox: {
            color: theme.palette.primary.main
        },
    })
)
interface ConfirmSupplierPOProps {
    supplierPOId: string
    cancel: () => void
    complete: () => void
}

export const ConfirmSupplierPOForm: FC<ConfirmSupplierPOProps> = ({ cancel, complete, supplierPOId }) => {

    const classes = useStyles()
    const dispatch = useAppDispatch()

    const [acknowledgment, setAcknowledgment] = useState<boolean>(false)
    const [confirmSupplierPurchaseOrder] = useConfirmSupplierPurchaseOrderMutation()

    const confirmSupplierPO = async () => {
        try {
            await confirmSupplierPurchaseOrder(supplierPOId).unwrap()
            complete()
            dispatch(SHOW_FEEDBACK('Purchase order confirmed.'))
        } catch(error) {
            dispatch(SHOW_FEEDBACK(error.data))
        }
    }

    const handleConfirmSupplierPurchaseOrder = async () => {
        await confirmSupplierPO()
        complete()
    }

    return (
        <div style={{ fontSize: 14 }}>
            <Typography style={{ fontSize: 22, fontWeight: 600, paddingTop: '8px', paddingBottom: '16px' }}>
                Acknowledgment Agreement
            </Typography>
            By clicking 'Confirm PO', you confirm that:
            <ol>
                <li>You have reviewed the order details.</li>
                <li>You agree to fulfill this order as specified in the PO requirements.</li>
                <li>You agree that you hold all the certifications specified in the PO requirements.</li>
                <li>You commit to delivering on or before the desired delivery date.</li>
                <li>You will communicate any changes or delays to SyncFab promptly.</li>
            </ol>
            <FormControlLabel
                control={
                    <Checkbox
                        checked={acknowledgment}
                        onChange={() => {setAcknowledgment(!acknowledgment)}}
                        name="acknowledgment"
                        color='primary'
                        classes={{ root: classes.checkbox }}
                    />
                }
                label='I agree to the terms and conditions of this PO.'
            />
            <Grid container justifyContent='flex-end' style={{ paddingTop: '16px', paddingBottom:'16px' }}>
                <Button variant="text" style={{ color: "#40B4BF" }} onClick={cancel}>
                    CANCEL
                </Button>
                <Button
                    type='submit'
                    variant="contained"
                    style={{ backgroundColor: '#40B4BF', color: '#FFFFFF', marginLeft: '16px' }}
                    onClick={handleConfirmSupplierPurchaseOrder}
                    disabled={!acknowledgment}
                >
                    Confirm PO
                </Button>
            </Grid>
        </div>
    )
}