import React from 'react'
import SvgIcon from '@material-ui/core/SvgIcon'

export const DocumentIcon = props => {
    return (
        <SvgIcon {...props} viewBox="0 0 48 64" x="0px" y="0px" baseProfile="tiny" xmlSpace="preserve" style={{ width: '80px' }}>
            <path
                fill="#FFFFFF"
                d="M32.43,2.3v9.26c0,1.26,1.15,2.29,2.57,2.29h10.41c2.29,0,3.44-2.47,1.82-3.91L36.83,0.68
				C35.21-0.77,32.43,0.26,32.43,2.3z"
            />
            <path
                fill="#FFFFFF"
                d="M46.05,19.2H31.78c-2.51,0-4.54-1.88-4.54-4.2V1.8c0-0.99-0.87-1.8-1.95-1.8H1.95C0.87,0,0,0.81,0,1.8v56.4
				C0,59.19,0.87,60,1.95,60h44.11c1.07,0,1.95-0.81,1.95-1.8V21C48,20.01,47.13,19.2,46.05,19.2z M7.42,20.77L7.42,20.77l0.12,0
				c0.08,0,0.16,0,0.24,0h15.57c0.69-0.01,1.36,0.23,1.86,0.66c0.49,0.44,0.77,1.03,0.77,1.65s-0.28,1.21-0.77,1.65
				c-0.5,0.43-1.17,0.67-1.86,0.66H7.78c-1.43,0.09-2.68-0.87-2.78-2.15C4.91,21.96,5.99,20.86,7.42,20.77z M42.08,52.41
				c-0.5,0.43-1.17,0.67-1.86,0.66H7.78c-1.43,0.09-2.68-0.87-2.78-2.15c-0.1-1.28,0.98-2.38,2.41-2.47h0.12c0.08,0,0.16,0,0.24,0
				h32.43c0.69-0.01,1.36,0.23,1.86,0.66c0.49,0.44,0.77,1.03,0.77,1.65C42.85,51.39,42.57,51.98,42.08,52.41z M42.08,43.18
				c-0.5,0.43-1.17,0.67-1.86,0.66H7.78c-1.43,0.09-2.68-0.87-2.78-2.15c-0.1-1.28,0.98-2.38,2.41-2.47v0h0.12c0.08,0,0.16,0,0.24,0
				h32.43c0.69-0.01,1.36,0.23,1.86,0.66c0.49,0.44,0.77,1.03,0.77,1.65C42.85,42.16,42.57,42.75,42.08,43.18z M42.08,33.95
				c-0.5,0.43-1.17,0.67-1.86,0.66H7.78c-1.43,0.09-2.68-0.87-2.78-2.15C4.91,31.2,5.99,30.09,7.42,30h0.12c0.08,0,0.16,0,0.24,0h32.43
				c0.69-0.01,1.36,0.23,1.86,0.66c0.49,0.44,0.77,1.03,0.77,1.65C42.85,32.93,42.57,33.52,42.08,33.95z"
            />
        </SvgIcon>
    )
}
