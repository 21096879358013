import React, { useEffect, useState } from 'react'
import { withNamespaces, WithNamespaces } from 'react-i18next'
import { Dialog, DialogTitle, DialogContent, DialogActions } from '@material-ui/core'
import { Address, Button } from '@syncfab/machine'
import { AddressFormV2 } from './AddressFormV2'
import { Countries } from '../../../data/countries'

interface DialogProps extends WithNamespaces {
    open: boolean
    onSaveUpdate: (data: Partial<Address>) => void
    close: () => void
    address?: Address
    onSubmit: () => void
}

const dialog = ({ onSubmit, open, close, onSaveUpdate, t, address }: DialogProps) => {
    const [states, setStates] = useState([])
    const [selectedState, setSelectedState] = useState(null)
    useEffect(() => {
        try {
            if (address?.location?.country) {
            const country = Countries.find(x => x?.name == address?.location?.country)
            setSelectedState(country?.states?.find(x => x?.state_code == address?.location?.state))
            if (country && country.states) setStates(country?.states as any)
            } else{
                const country = Countries.find(x => x?.name == 'United States')
                setSelectedState(country?.states?.find(x => x?.state_code == address?.location?.state))
            if (country && country?.states) setStates(country?.states as any)
            }
        } catch (error) {
            console.log(error)
        }
    }, [address])
    const findSelectedStateOption = (options: any[], selectedValue: string): any => {
        const data = options?.find(item => item?.state_code === selectedValue)
        if (data) {
            data.label = data?.name
            data.value = data?.state_code
        }
        return data
    }
    const findSelectedCountryOption = (options: any[], selectedValue: string): any => {
        const data = options?.find(item => item?.name === selectedValue)
        if (data) {
            data.label = data?.name
            data.value = data?.name
        }
        return data
    }
    const onModalClose = () => {
        setStates([])
        close()
    }
    return (
        <Dialog open={open} aria-labelledby="address-dialog-title" onClose={onModalClose} fullWidth>
            <DialogTitle id="address-dialog-title">{`${t('address-dialog-title')}`}</DialogTitle>
            <DialogContent>
                <AddressFormV2
                    onSubmit={onSubmit}
                    initialValues={{
                        ...address,
                        location: {
                            ...address?.location,
                            country: findSelectedCountryOption(Countries, address?.location?.country || 'United States'),
                            state: findSelectedStateOption(states, selectedState?.state_code || ''),
                        },
                    }}
                    stateOptions={states}
                />
                <DialogActions>
                    <Button onClick={close} color="primary" variant="text">
                        {t('address-dialog-cancel')}
                    </Button>
                    <Button onClick={onSaveUpdate} color="primary">
                        {!!address ? t('form-action-update') : t('form-action-save')}
                    </Button>
                </DialogActions>
            </DialogContent>
        </Dialog>
    )
}

export const AddressDialog = withNamespaces()(dialog)
