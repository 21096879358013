import React from 'react'
import SvgIcon from '@material-ui/core/SvgIcon'

export const EmptyList = props => {
    return (
        <SvgIcon {...props} viewBox="0 0 95.9 98" x="0px" y="0px" baseProfile="tiny" xmlSpace="preserve" style={{ width: 150, height: 150, marginTop: 32, marginBottom: 32 }}>
            <path
                fill="#e6e6e6"
                d="M79.9,78.7H64a1.4,1.4,0,0,0-1.3,1l-.8,3.2a1.3,1.3,0,0,1-1.3,1H39.3a1.4,1.4,0,0,1-1.3-1l-.8-3.2a1.3,1.3,0,0,0-1.3-1H20.1A1.3,1.3,0,0,0,18.8,80V94.3A5.1,5.1,0,0,0,24,99.5H76a5.3,5.3,0,0,0,5.3-5.2V80a1.3,1.3,0,0,0-.4-.9,1.3,1.3,0,0,0-1-.4Z"
                transform="translate(-2.1 -1.5)"
            />
            <path
                fill="#e6e6e6"
                d="M71,48.5a1.3,1.3,0,0,0-1.3-1.1H30.3A1.4,1.4,0,0,0,29,48.5L19,72a1.9,1.9,0,0,0,.3,1,1.4,1.4,0,0,0,1,.5H39.2a1.2,1.2,0,0,1,1.3,1l.9,3.2a1,1,0,0,0,.8,1H57.8a1.3,1.3,0,0,0,1-1l.8-3.3h0a1.2,1.2,0,0,1,1.3-.9H79.7a1.2,1.2,0,0,0,1-.5,1,1,0,0,0,.3-1Z"
                transform="translate(-2.1 -1.5)"
            />
            <path fill="#e6e6e6" d="M81.3,33.7a1.3,1.3,0,0,0-1.2,1.4,1.3,1.3,0,0,0,1.3,1.3h.1l1.9-.2a1.4,1.4,0,0,0,1.2-1.5,1.3,1.3,0,0,0-1.5-1.2l-1.8.2Z" transform="translate(-2.1 -1.5)" />
            <path fill="#e6e6e6" d="M85.6,18.8a1.3,1.3,0,0,0,1.3-1.3,1.3,1.3,0,0,0-1.2-1.4l-1.9-.2a1.4,1.4,0,0,0-1.4,1.3,1.5,1.5,0,0,0,1.3,1.5h1.9Z" transform="translate(-2.1 -1.5)" />
            <path fill="#e6e6e6" d="M74.1,34.1a1.3,1.3,0,0,0-1.3,1.4,1.5,1.5,0,0,0,1.4,1.3h1.9a1.3,1.3,0,0,0,1.3-1.4A1.5,1.5,0,0,0,76,34Z" transform="translate(-2.1 -1.5)" />
            <path fill="#e6e6e6" d="M67,34.2a1.4,1.4,0,0,0-1.4,1.4A1.3,1.3,0,0,0,67,36.9h1.8a1.3,1.3,0,0,0,1.3-1.3,1.4,1.4,0,0,0-1.3-1.4Z" transform="translate(-2.1 -1.5)" />
            <path fill="#e6e6e6" d="M59.7,36.9h1.8a1.4,1.4,0,0,0,.1-2.7H59.8a1.4,1.4,0,0,0-.1,2.7Z" transform="translate(-2.1 -1.5)" />
            <path
                fill="#e6e6e6"
                d="M95.3,32.8a1.1,1.1,0,0,0,.8-.3,4.7,4.7,0,0,0,1.5-1.7,1.3,1.3,0,0,0-.5-1.9,1.4,1.4,0,0,0-1.8.6l-.9.9a1.4,1.4,0,0,0-.2,1.9,1.6,1.6,0,0,0,1.1.5Z"
                transform="translate(-2.1 -1.5)"
            />
            <path
                fill="#e6e6e6"
                d="M95.3,25.1a1.5,1.5,0,0,0,1.3,1.1h.3A1.3,1.3,0,0,0,98,24.5l-.6-1.9a1.4,1.4,0,0,0-1.8-.8,1.4,1.4,0,0,0-.7,1.8,8.3,8.3,0,0,1,.4,1.5Z"
                transform="translate(-2.1 -1.5)"
            />
            <path fill="#e6e6e6" d="M88.6,35.4h.3l1.8-.4a1.4,1.4,0,0,0,1-1.6,1.4,1.4,0,0,0-1.7-1l-1.7.4a1.3,1.3,0,0,0-1,1.6,1.4,1.4,0,0,0,1.3,1.1Z" transform="translate(-2.1 -1.5)" />
            <path fill="#e6e6e6" d="M90.4,19.7l1.5.7h.6a1.2,1.2,0,0,0,1.2-.7,1.3,1.3,0,0,0-.5-1.8l-1.9-.8a1.3,1.3,0,0,0-.9,2.5Z" transform="translate(-2.1 -1.5)" />
            <path fill="#e6e6e6" d="M52.5,36.8h1.7a1.3,1.3,0,0,0,1.4-1.3,1.3,1.3,0,0,0-1.3-1.4H52.5a1.4,1.4,0,0,0-1.4,1.3A1.5,1.5,0,0,0,52.5,36.8Z" transform="translate(-2.1 -1.5)" />
            <path
                fill="#e6e6e6"
                d="M5.6,53.8a1.3,1.3,0,0,0,.1,1.9,8.3,8.3,0,0,0,1.6,1.2,1.1,1.1,0,0,0,.7.2,1.4,1.4,0,0,0,1.2-.6,1.3,1.3,0,0,0-.5-1.9l-1.2-.9a1.3,1.3,0,0,0-1.9.1Z"
                transform="translate(-2.1 -1.5)"
            />
            <path fill="#e6e6e6" d="M9.7,39.5l.7-.2,1.5-.8a1.5,1.5,0,0,0,.6-1.8,1.4,1.4,0,0,0-1.8-.7L9,36.9a1.4,1.4,0,0,0-.5,1.9,1.5,1.5,0,0,0,1.2.7Z" transform="translate(-2.1 -1.5)" />
            <path fill="#e6e6e6" d="M4,44.4h.7a1.3,1.3,0,0,0,1.2-.7l.8-1.3a1.3,1.3,0,1,0-2.1-1.6L3.5,42.5A1.4,1.4,0,0,0,4,44.4Z" transform="translate(-2.1 -1.5)" />
            <path fill="#e6e6e6" d="M45.2,36.6h1.9a1.3,1.3,0,0,0,1.3-1.3,1.3,1.3,0,0,0-1.3-1.4H45.4A1.3,1.3,0,0,0,44,35.2a1.3,1.3,0,0,0,1.2,1.4Z" transform="translate(-2.1 -1.5)" />
            <path
                fill="#e6e6e6"
                d="M3.7,51.5h.4A1.3,1.3,0,0,0,5,49.8a7.7,7.7,0,0,1-.2-1.5A1.4,1.4,0,0,0,3.4,47a1.4,1.4,0,0,0-1.3,1.5,6.4,6.4,0,0,0,.3,2,1.4,1.4,0,0,0,1.3,1Z"
                transform="translate(-2.1 -1.5)"
            />
            <path fill="#e6e6e6" d="M11.8,57.3A1.3,1.3,0,0,0,13,58.8l1.9.2h0a1.4,1.4,0,0,0,.1-2.7l-1.7-.2a1.4,1.4,0,0,0-1.5,1.2Z" transform="translate(-2.1 -1.5)" />
            <path fill="#e6e6e6" d="M23.6,36.1h1.8a1.3,1.3,0,0,0,1.3-1.4,1.3,1.3,0,0,0-1.3-1.3H23.6a1.3,1.3,0,0,0-1.4,1.3A1.4,1.4,0,0,0,23.6,36.1Z" transform="translate(-2.1 -1.5)" />
            <path fill="#e6e6e6" d="M30.8,36.2h1.8a1.4,1.4,0,0,0,.1-2.7H30.8a1.3,1.3,0,0,0-1.3,1.3A1.3,1.3,0,0,0,30.8,36.2Z" transform="translate(-2.1 -1.5)" />
            <path fill="#e6e6e6" d="M38,36.4h1.9a1.3,1.3,0,0,0,1.3-1.3,1.3,1.3,0,0,0-1.3-1.4H38A1.3,1.3,0,0,0,36.7,35,1.5,1.5,0,0,0,38,36.4Z" transform="translate(-2.1 -1.5)" />
            <path fill="#e6e6e6" d="M16.4,36.9h.3l1.7-.3a1.4,1.4,0,0,0-.4-2.7l-1.9.4a1.3,1.3,0,0,0-1,1.6,1.4,1.4,0,0,0,1.3,1.1Z" transform="translate(-2.1 -1.5)" />
            <path
                fill="#e6e6e6"
                d="M65.2,26.2h.1c0,.1.1.1.2.2a5.4,5.4,0,0,0,3.2,1.1,3.5,3.5,0,0,0,2.9-1.3l2.7-3.3a4,4,0,0,0,.5-4h.6a4,4,0,0,0,3-1.5.1.1,0,0,0,.1-.1v-.2h.1a4,4,0,0,0,.9-3.5h.9a3.5,3.5,0,0,0,2.9-1.3l2.8-3.3c1.3-1.7.9-4.3-1.1-6.1a.1.1,0,0,0-.1-.1l-.3-.2a5.1,5.1,0,0,0-3.2-1.2,3.6,3.6,0,0,0-2.9,1.3L75.8,6.1l-.2.3h0a4,4,0,0,0-.3,3.4,4.2,4.2,0,0,0-3.7,1.5h-.2a3.8,3.8,0,0,0-.8,3.8h-1a3.8,3.8,0,0,0-2.9,1.3L64,19.9c-1.4,1.7-.9,4.4,1,6.2Zm11.9-19,2.7-3.3a2.1,2.1,0,0,1,1.7-.8,3.5,3.5,0,0,1,2.2.8c.1.1.1.2.2.2h.2c1.3,1.2,1.6,2.9.8,3.9l-2.7,3.3a2.4,2.4,0,0,1-1.7.7,4,4,0,0,1-2.2-.8l-.2-.2h-.2c-1.2-1.2-1.6-2.9-.8-3.8ZM65.2,21,68,17.7a1.9,1.9,0,0,1,1.7-.8,4,4,0,0,1,2.2.8c0,.1.1.1.2.2a.1.1,0,0,0,.1.1c1.3,1.2,1.7,2.9.9,3.9l-2.8,3.3a2,2,0,0,1-1.6.7,4,4,0,0,1-2.3-.8c0-.1-.1-.1-.1-.2h-.2c-1.3-1.2-1.7-2.8-.9-3.8Z"
                transform="translate(-2.1 -1.5)"
            />
        </SvgIcon>
    )
}
