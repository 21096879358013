import React, { useState } from 'react'
import { Grid } from '@material-ui/core'
import _ from 'lodash-es'
import { QuoteListItem } from './QuoteListItem'
import { withRouter, RouteComponentProps } from 'react-router-dom'
import { WithNamespaces, withNamespaces } from 'react-i18next'
import SearchOutlined from '@material-ui/icons/SearchOutlined'
import { withStyles, WithStyles } from '@material-ui/core'
import { Loader, User, Button, SupplierState, Supplier } from '@syncfab/machine'
import Drawer from '@material-ui/core/Drawer'
import { RFQQuoteListItem } from './RFQQuoteListItem'
import { Typography, InputAdornment, IconButton, TablePagination } from '@material-ui/core'
import { AutoCompleteValueLabel } from '@syncfab/machine'
import CloseIcon from '@material-ui/icons/Close'
import TextField from '@material-ui/core/TextField'
import FilterDrawer from './FilterDrawer'
import Chip from '@material-ui/core/Chip';
import { useListRFQsQuery } from '@syncfab/machine'
import { SortDirection } from '@syncfab/machine'
import { updateQuoteListParameters } from '../../../../application/QuoteListParametersSlice'
import './QuoteList.css'
import { useAppDispatch, useAppSelector } from '../../../../application/hooks';
import VerifyAlert from './VerifyAlert'
import WaitingAlert from './WaitingAlert'
import VerificationCompleteAlert from './VerificationCompleteAlert'
import { NonStepKeys, StepFourKeys } from '../../../supplier/verifySupplier/constants'

const statusArr = [
    { value: 'OPEN', label: 'Open' },
    { value: 'QUOTED', label: 'Quoted' },
    { value: 'NO_QUOTE', label: 'No Quote' },
];

const createdArr = [
    { value: `${SortDirection.DESC}`, label: 'Newest'},
    { value: `${SortDirection.ASC}`, label: 'Oldest'}
]
const deadlineArr = [
    { value: 'THIS_WEEK', label: 'This Week'},
    { value: 'NEXT_WEEK', label: 'Next Week'},
    { value: 'NEXT30', label: 'Next 30 Days'},
    { value: 'NEXT60', label: 'Next 60 Days'}
]

const getSelectedValue = (stringVal, selections) => {
    return selections.find(s => s.value === stringVal) ?? null;
}
interface SupplierFilterState {
    manufacturingProcesses: any[],
    finishes: any[],
    affiliateOrganizations: any[],
    productPreference: any[],
    materials: any[],
    certifications: any[],
    leadTime: any[],
    lotSize: any[],
}
const styles = theme => ({
    detailcard: {
        width: '-webkit-fill-available',
        height: 'fit-content',
        margin: `${theme.spacing(3)}px 12px`,
        backgroundColor: theme.palette.common.white,
        marginTop: '100px',
        marginLeft: '24px',
        marginRight: '24px',
    },
    noTokenID: {
        marginLeft: theme.spacing(3),
        'font-weight': 'bold',
    },
    marginTextRight: {
        marginRight: theme.spacing(2),
    },
    refresh: {
        color: '#40B4BF',
    },
    refreshIcon: {
        marginLeft: theme.spacing(1),
    },
    clearIcon: {
        marginLeft: theme.spacing(1),
    },
    error: {
        backgroundColor: theme.palette.error.light,
    },
    filterListContainer: {
        borderRadius: '4px',
        padding: 0,
    },
    root: {
        color: theme.palette.text.secondary,
        background: '#ffffff',
        padding: theme.spacing(4),
        marginTop: theme.spacing(4),
        marginBottom: theme.spacing(4),
        minWidth: 300,
    },
    table: {
        minWidth: 650,
        backgroundColor: theme.palette.background.paper,
    },
    tableBody: {
        fontSize: 14,
    },
    spacer: {
        flex: '1 1 100%',
    },
    actions: {
        color: theme.palette.text.secondary,
        marginTop: '7px',
        marginRight: '10px',
    },
    title: {
        flex: '0 0 auto',
    },
    checkbox: {
        color: '#363636',
    },
    button: {
        margin: theme.spacing(1),
    },
    paper: {
        width: '100%',
        margin: `0px ${theme.spacing(2)}`,
        backgroundColor: theme.palette.background.paper,
    },
    header: {
        padding: `0px ${theme.spacing(1)}px`,
    },
    testLink: {
        cursor: 'pointer',
    },
    textField: {
        marginLeft: theme.spacing(3),
        marginRight: theme.spacing(1),
        width: 400,
    },
    row: {
        alignItems: 'center',
        padding: `${theme.spacing(2)}px`,
    },
    goBackButton: {
        margin: 0,
        padding: 0,
        'text-transform': 'none',
        color: theme.palette.primary.light,
        fontWeight: theme.typography.fontWeightRegular,
        'font-size': '12',
    },
    arrow: {
        color: theme.palette.common.white,
        marginRight: theme.spacing(1),
    },
    icon: {
        fontSize: 20,
        color: theme.palette.common.white,
        marginRight: theme.spacing(2),
    },
    iconVariant: {
        opacity: 0.9,
        marginRight: theme.spacing * 1,
    },
    message: {
        display: 'flex',
        alignItems: 'center',
    },
    btnclass: {
        color: theme.palette.common.white,
        marginLeft: theme.spacing(3),
        fontSize: 10,
        borderColor: theme.palette.common.white,
    },
    listItem: {
        display: 'flex',
        'flex-direction': 'column',
        alignItems: 'flex-start',
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
    },
    btnWrapper: {
        display: 'flex',
        alignItems: 'baseline',
        justifyContent: 'flex-start',
        [theme.breakpoints.down('md')]: {
            justifyContent: 'flex-start',
        },
    },
    formControl: {
        'border-radius': '5px',
    },
    rootSelect: {
        color: '#000',
        // padding: '9px 0 !important',
    },
    tableWrapper: {
        'overflow-x': 'auto',
    },
    clearBtn: {
        fontSize: 14,
        color: '#40B4BF',
        borderColor: '#40B4BF',
        background: 'transparent',
        'box-shadow': 'none',
        minWidth: '100px',
        marginRight: 10,
        '&:hover': {
            background: 'transparent',
            'box-shadow': 'none',
        },
        '&:focus': {
            background: 'transparent',
            'box-shadow': 'none',
        },
    },
    downloadWrapper: {
        //'position': 'relative',
        width: '140px',
        display: 'flex',
        'justify-content': 'flex-start',
    },
    csvBtn: {
        background: 'transparent',
        width: '100%',
        height: '100%',
        left: 0,
        top: 0,
        padding: '10px 0 0 35px',
        color: '#40B4BF',
        'text-decoration': 'none',
    },
    new: {
        backgroundColor: theme.palette.secondary.main,
    },
    active: {
        backgroundColor: theme.palette.success.main,
    },
    deactived: {
        backgroundColor: theme.palette.grey[600],
    },
    closeIcon: {
        padding: 0,
    },
    searchInput: {
        '& :-webkit-autofill': {
            '-webkit-text-fill-color': '#000',
        },
    },
    compactRow: {
        paddingBottom: '10px',
        paddingTop: '10px',
        borderBottom: '1px solid #DBDBDB',
    },
    captionStyle: {
        marginTop: theme.spacing(3),
    },
    link: {
        color: '#40B4BF',
        'text-decoration': 'none',
        cursor: 'pointer',
        fontWeight: 'bold' as any,
    },
    chip: {
        color: '#fff',
        marginLeft: theme.spacing(1),
    },
    chipContainer: {
        display: 'flex',
        justifyContent: 'left',
        flexWrap: 'wrap' as 'wrap',
    },
    headTitle: {
        fontSize: theme.spacing(4),
    },
    subTitle: {
        color: '#A0AEC0',
        fontWeight: theme.typography.fontWeightMedium,
    },
});

interface QuoteListProps extends RouteComponentProps, WithNamespaces, WithStyles {
    deleteRFQ: (id: string) => void
    editRFQ: (id: string) => void
    loggedUser?: User
}

const initialFilterState = {
    manufacturingProcesses: [],
    finishes: [],
    productPreference: [],
    materials: [],
    certifications: [],
    leadTime: [],
    lotSize: [],
}

const displayForChips = {
    manufacturingProcesses: 'Manufacturing Processes',
    finishes: 'Finishing Capabilities',
    affiliateOrganizations: 'Affiliate Organizations',
    productPreference: 'Product Preferences',
    materials: 'Material Preferences',
    certifications: 'Certifications',
    leadTime: 'Lead Time',
    lotSize: 'Lot Size'
}

// Determine if we've saved any info in the verify endpoint.
const supplierFieldsPreviouslyStarted = (supplier: Supplier) => {
    if(supplier?.teams?.length > 0) return true;
    // If any step, one, two or three keys are filled out, then we have a previously started thing.
    // Also, remove returns the elements that are removed by 2nd arg, *pitfall* and modifies array in place, we want returned values as this is an rvalue.
    if(
        _.values(
            _.pick(supplier, 
                _.remove(
                    [...StepFourKeys], 
                    e => !NonStepKeys.includes(e)
                )
            )
        )
            .some(
                e => e?.length > 0 ||
                e !== null ||
                e !== undefined
            )
    )
        return true;
    return false;
};
const QuoteList :React.FC<QuoteListProps> = function ({ deleteRFQ, editRFQ, loggedUser, classes, history}) {
    const [drawerExpanded, setDrawerExpanded] = useState(false);
    // const [instantFilters, setInstantFilters] = useState(initialInstantFilterState);
    const [filterState, setFilterState] = useState(initialFilterState)
    const [appliedFilters, setAppliedFilters] = useState({})
    // Assign to variable so we can do type checks on this with keyof typeof later on.
    const instantFilters = useAppSelector(state => state.quoteListParameters);
    const {
        sortField,
        sortDirection,
        page,
        size,
        query,
        filter,
        deadlineFilter,
    } = instantFilters
    const dispatch = useAppDispatch();

    const { data, isLoading } = useListRFQsQuery({
        page,
        size,
        query,
        state: filter ?? null,
        sortField,
        sortDirection,
        deadlineFilter,
        ...appliedFilters
    })

    const toggleDrawer = () => {
        setDrawerExpanded(d => !d)
    }

    /**
     *
     * @param field string for key in state object that should be updated.
     * @param mutationFunction function that takes in value returned from change handler and tranforms it to what is stored in state
     * @returns lambda value: any => void that can be used in onchange function to update captured field
     */
    const updateInstantFilterStateFunction = (field: keyof typeof instantFilters, mutationFunction = v => v) =>
        value => {
            dispatch(updateQuoteListParameters({
                [field]: mutationFunction(value),
            }));
        };
    const updateInstantFilterState = (field: keyof typeof instantFilters, value: any) => {
        dispatch(updateQuoteListParameters({
            [field]: value,
        }))
    }
        /**
     *
     * @param field string for key in state object that should be updated.
     * @param mutationFunction function that takes in value returned from change handler and tranforms it to what is stored in state
     * @returns lambda value: any => void that can be used in onchange function to update captured field
     */
    const updateFilterStateFunction = (field: keyof SupplierFilterState, mutationFunction = v => v) =>
        value => {
            setFilterState(filterState => {
                return {
                    ...filterState,
                    [field]: mutationFunction(value)
                };
            });
        };
    const alignFilters = () => {
        const populatedFilters = {};
        for (const key in filterState) {
            if(Array.isArray(filterState[key])) {
                populatedFilters[key] = filterState[key].map(v => v.value);
            } else if(filterState[key] !== null) {
                populatedFilters[key] = filterState[key].value;
            }
        }

        // Do a deep comparison on filters to see if they've actually changed
        // If this re-fetches too often, check to see if arrays of values are changing sort order.
        if(!_.isEqual(appliedFilters, populatedFilters)) {
            setAppliedFilters(populatedFilters);
        }
    };

    const removeFieldFromFilterState = (field: keyof SupplierFilterState, comparisonFunction: any) => () => {
        const currVal = filterState[field];
        console.log(filterState, field);
        let updatedVal;
        if(Array.isArray(currVal)) {
            updatedVal = _.remove(currVal, comparisonFunction)
        } else {
            updatedVal = null;
        }
        const newFilterState = {
            ...filterState,
            [field]: updatedVal,
        }
        setFilterState(newFilterState);
        const populatedFilters = {};
        for (const key in newFilterState) {
            if(Array.isArray(newFilterState[key])) {
                populatedFilters[key] = newFilterState[key].map(v => v.value);
            } else if(filterState[key] !== null) {
                populatedFilters[key] = newFilterState[key].value;
            }
        }

        // Do a deep comparison on filters to see if they've actually changed
        // If this re-fetches too often, check to see if arrays of values are changing sort order.
        if(!_.isEqual(appliedFilters, populatedFilters)) {
            setAppliedFilters(populatedFilters);
        }
    }


    const clearFilters = () => {
        dispatch(updateQuoteListParameters({
            page: 0,
            size: 10,
            sortDirection: SortDirection.DESC,
            sortField: '_id',
            deadlineFilter: [],
            state: '',
            filter: '',
        }))
        setFilterState({
            ...filterState,
            ...initialFilterState,
        });
        setAppliedFilters({});
        history.push('/quotes')
    };

    const generateChips = () => {
        const chipArray = [];
        for (const key in appliedFilters) {
            if(Array.isArray(appliedFilters[key])) {
                appliedFilters[key].forEach(fS => {
                    chipArray.push(
                        <Chip
                            className={classes.chip}
                            size="medium"
                            onDelete={removeFieldFromFilterState(
                                key as keyof SupplierFilterState,
                                e => e.value !== fS
                            )}
                            variant="outlined"
                            label={`${displayForChips[key]}: ${fS}`}
                        />
                    )
                })
            } else if(appliedFilters[key] !== null) {
                chipArray.push(
                    <Chip
                        className={classes.chip}
                        size="medium"
                        onDelete={removeFieldFromFilterState(
                            key as keyof SupplierFilterState,
                            e => e.value !== appliedFilters[key]
                        )}
                        variant="outlined"
                        label={`${displayForChips[key]}: ${filterState[key]}`}
                    />
                )
            }
        }
        return chipArray
    }
    const chips = generateChips();
    return (
        <>
            <Drawer anchor="right" open={drawerExpanded} onClose={toggleDrawer}>
                <FilterDrawer
                    toggleDrawer={toggleDrawer}
                    addFilter={alignFilters}
                    updateFilterStateFunction={updateFilterStateFunction}
                    filterState={filterState}
                />
            </Drawer>
            <Grid container justifyContent="space-between" style={{ marginTop: 32, marginBottom: 16 }}>
                <Grid item xs={12}>
                    <Typography gutterBottom variant="h4" className={classes.headTitle}>
                        RFQ Inbox
                    </Typography>
                    <Typography gutterBottom variant="h4" className={classes.subTitle}>
                        {`Hello, ${loggedUser?.firstName ?? ''} ${loggedUser?.lastName ?? ''}`}
                    </Typography>
                </Grid>
            </Grid>
            {
                !!loggedUser?.supplier && (
                    [SupplierState.ACTIVE_NEED_INFO, SupplierState.INFO_SUBMITTED].findIndex(e => e === loggedUser?.supplier?.state) >= 0 ||
                    (   loggedUser.supplier.state === SupplierState.ACTIVE
                        && loggedUser?.supplier?.hasOwnProperty('supplierInformedOfSupplierStateUpdate')
                        && !loggedUser.supplier.supplierInformedOfSupplierStateUpdate
                    )
                ) && (
                    <Grid container justifyContent="space-between" style={{ marginTop: 32, marginBottom: 16 }}>
                        <Grid item xs={12}>
                            { loggedUser.supplier.state === SupplierState.ACTIVE_NEED_INFO && <VerifyAlert previouslyStarted={supplierFieldsPreviouslyStarted(loggedUser.supplier)} /> }
                            { loggedUser.supplier.state === SupplierState.INFO_SUBMITTED && <WaitingAlert /> }
                            {
                                // Check for supplierInformedOfSupplierStateUpdate property and have it be false to display Verification Complete Alert
                                loggedUser.supplier.state === SupplierState.ACTIVE
                                && loggedUser?.supplier?.hasOwnProperty('supplierInformedOfSupplierStateUpdate')
                                && !loggedUser.supplier.supplierInformedOfSupplierStateUpdate
                                && <VerificationCompleteAlert />
                            }
                        </Grid>
                    </Grid>
                )
            }
            <Grid spacing={2} container direction="row" className={`${classes.row} ${classes.paper}`}>
                <Grid item sm={12} md={12}>
                    <TextField
                        fullWidth
                        className={classes.searchInput}
                        id="filled-adornment-password"
                        variant="outlined"
                        label="Search"
                        onChange={e => {
                            updateInstantFilterState('query', e.target.value);
                            window.scrollTo({left: 0, behavior: 'smooth'})
                        }}
                        value={query}
                        InputProps={{
                            endAdornment: (
                                <>
                                    <InputAdornment position="end">
                                        <SearchOutlined />
                                    </InputAdornment>
                                    {!!query && (
                                        <IconButton
                                            type="submit"
                                            style={{ padding: 5, visibility: query ? 'visible' : 'hidden' }}
                                            onClick={() => {
                                                updateInstantFilterState('query', '');
                                                window.scrollTo({left: 0, behavior: 'smooth'})
                                            }}>
                                            <CloseIcon />
                                        </IconButton>
                                    )}
                                </>
                            ),
                        }}
                    />
                </Grid>
            </Grid>
            {chips.length > 0 &&
                (
                    <Grid container direction="row" className={`${classes.row} ${classes.paper}`}>
                        <Grid item sm={10}>
                            <div className={classes.chipContainer}>
                                {chips}
                            </div>
                        </Grid>
                        <Grid item sm={2}>
                            <Button variant="text" className={classes.clearBtn} onClick={clearFilters}>
                                Clear All Filters
                            </Button>
                        </Grid>
                    </Grid>
                )
            }
            <Grid spacing={2} container direction="row" className={`${classes.row} ${classes.paper}`}>
                <Grid item sm={6} md={2}>
                    <AutoCompleteValueLabel
                        name="combo-box-demo"
                        onChangeHandler={updateInstantFilterStateFunction('filter', v => v.value)}
                        value={getSelectedValue(filter, statusArr)}
                        options={statusArr}
                        placeholder="Status:"
                        label="Status"
                        disableClearable={false}
                    />
                </Grid>
                <Grid item sm={6} md={2}>
                    <AutoCompleteValueLabel
                        name="combo-box-demo"
                        onChangeHandler={updateInstantFilterStateFunction('sortDirection', v => v.value)}
                        value={getSelectedValue(sortDirection, createdArr)}
                        options={createdArr}
                        placeholder="Created"
                        label="Created"
                        disableClearable
                    />
                </Grid>
                <Grid item sm={6} md={2}>
                    <AutoCompleteValueLabel
                        name="combo-box-demo"
                        onChangeHandler={updateInstantFilterStateFunction('deadlineFilter', v => v.map(x => x.value))}
                        value={deadlineFilter.map(dF => getSelectedValue(dF, deadlineArr))}
                        options={deadlineArr}
                        placeholder="Deadline"
                        label="Deadline"
                        disableClearable={false}
                        renderCheckboxes
                        multiple
                    />
                </Grid>

                <Grid item sm={12} md={2} className={classes.btnWrapper}>
                    <Button variant="outlined" className={classes.clearBtn} onClick={toggleDrawer}>
                        More Filters
                    </Button>
                </Grid>
            </Grid>

            {!isLoading ?
                (<Grid container spacing={3} style={{ marginTop: loggedUser && loggedUser.loginRole === 'SUPPLIER' && '24px' }}>
                    {loggedUser && loggedUser.loginRole === 'SUPPLIER'
                        ? data?.data.map(rfq => <RFQQuoteListItem rfq={rfq} key={rfq._id} />)
                        : data?.data.map(rfq => <QuoteListItem rfq={rfq} key={rfq._id} deleteRFQ={deleteRFQ} editRFQ={editRFQ} isSupplier={loggedUser && loggedUser.loginRole === 'SUPPLIER'} />)}
                </Grid>)
                : <Loader />}
            <TablePagination
                style={{width: '100%', color: '#FFFFFF'}}
                rowsPerPageOptions={[5, 10, 25, { label: 'All', value: -1 }]}
                component="div"
                count={data?.total ?? 0}
                rowsPerPage={size}
                labelDisplayedRows={({from, to, count}) => {
                    if(size === -1) {
                        return `All 1 entries`
                    }
                    return `${from}-${to} of ${count}`
                }}
                page={page}
                backIconButtonProps={{
                    'aria-label': 'Previous Page',
                }}
                nextIconButtonProps={{
                    'aria-label': 'Next Page',
                }}
                onPageChange={(_, value) => {
                    updateInstantFilterState('page', value)
                }}
                onRowsPerPageChange={e => {
                    updateInstantFilterState('size', e.target.value);
                    updateInstantFilterState('page', 0);
                }}
            />
        </>
    )
}

export default withRouter(withStyles(styles)(withNamespaces()(QuoteList)));
