import React from 'react'
import { Route } from 'react-router-dom'
import { connect } from 'react-redux'
import { Dispatch } from 'redux'
import { CLEAN_AUTH_TOKEN_ACTION } from '../auth/Actions'

const forcedPublicRouteHoc = ({ component: Component, cleanAuthToken, authToken, ...rest }) => {
    if (!!authToken) {
        cleanAuthToken()
    }
    return <Route {...rest} render={props => <Component {...props} />} />
}

const mapStateToProps = ({ auth: { authToken } }) => {
    return {
        authToken,
    }
}

const mapDispatchToProps = (dispatch: Dispatch) => {
    return {
        cleanAuthToken: () => dispatch(CLEAN_AUTH_TOKEN_ACTION()),
    }
}

export const ForcedPublicRoute = connect(
    mapStateToProps,
    mapDispatchToProps
)(forcedPublicRouteHoc)
