import React from 'react'
import { WithNamespaces, withNamespaces } from 'react-i18next'
import { connect } from 'react-redux'
import Helmet from 'react-helmet'
import { CenteredAlignedContainer } from '../../../layout/Container'
import { withRouter, RouteComponentProps } from 'react-router-dom'
import PurchaseMFG from './components/PurchaseMFG'

interface PurchaseMFGProps extends WithNamespaces, RouteComponentProps<{}> {}

interface PurchaseMFGState {}

class PurchaseMFGComponent extends React.Component<PurchaseMFGProps, PurchaseMFGState> {
    constructor(props) {
        super(props)
    }

    render() {
        return (
            <CenteredAlignedContainer>
                <Helmet>
                    <title>{'Purchase MFG'}</title>
                </Helmet>
                <PurchaseMFG />
            </CenteredAlignedContainer>
        )
    }
}

const PurchaseMFGWithTranslations = withRouter(withNamespaces()(PurchaseMFGComponent))

export const PurchaseMFGView = connect()(PurchaseMFGWithTranslations)
