import { createTheme } from '@material-ui/core'
import { typography } from './Typography'
import { palette, colors } from './ColorPalette'

const whiteBackground = {
    backgroundColor: palette.common!.white,
}
const whiteFont = {
    color: palette.common!.white,
}

const blackFont = {
    color: palette.common!.black,
}

const dappTheme = createTheme({
    typography,
    palette,
    overrides: {
        MuiSnackbarContent: {
            root: {
                backgroundColor: palette.common!.black,
            },
        },
        MuiDialogTitle: {
            root: {
                ...whiteFont,
            },
        },
        MuiDialogContent: {
            root: {
                ...whiteFont,
                fontFamily: `'Titillium Web', 'sans-serif'`,
            },
        },
        MuiIconButton: {
            root: {
                color: palette.common.white
            }
        },
        MuiListItemIcon: {
            root: {
                marginRight: 0,
            },
        },
        MuiTypography: {
            body1: {
                ...whiteFont,
            },
            body2: {
                ...whiteFont,
            },
            caption: {
                ...whiteFont,
            },
        },
        MuiButton: {
            root: {
                borderRadius: 20,
                '&.Mui-disabled': {
                    backgroundColor: `${palette.grey[300]} !important`,
                    color: `${palette.grey[500]}`,
                    fontWeight: 'bold',
                },
            },
        },
        MuiFilledInput: {
            input: {
                ...whiteBackground,
                borderTopLeftRadius: 3,
                borderTopRightRadius: 3,
                cursor: 'pointer',
                '&:focus': {
                    // ...whiteBackground,
                },
                '&::placeholder': {
                    color: palette.common.black,
                },
            },
            inputMultiline: {
                minHeight: 190,
            },
            multiline: {
                ...whiteBackground,
                borderTopLeftRadius: 3,
                borderTopRightRadius: 3,
                minHeight: 190,
                cursor: 'pointer',
                '&:hover': {
                    // ...whiteBackground,
                },
                '&:focus': {
                    // ...whiteBackground,
                },
            },
            root: {
                backgroundColor: `${palette.common!.white} !important`,
                borderTopLeftRadius: 3,
                borderTopRightRadius: 3,
                cursor: 'pointer',
                '&:hover': {
                    // ...whiteBackground,
                },
                '&:focus': {
                    // ...whiteBackground,
                },
            },
        },
        MuiOutlinedInput: {
            input: {
                // ...whiteBackground,
                borderTopLeftRadius: 3,
                borderTopRightRadius: 3,
                cursor: 'pointer',
                borderColor: '#FFF',
                '&:focus': {
                    // ...whiteBackground,
                },
                '&:-webkit-autofill': {
                    '-webkit-box-shadow': '0 0 0 100px #364F68 inset',
                    '-webkit-text-fill-color': palette.common.white,
                    'border-radius': 0,
                },
                '&::placeholder': {
                    color: palette.common.white,
                },
                '&:hover': {
                    borderColor: palette.common.white,
                    // ...whiteBackground,
                },
            },
            inputMultiline: {
                // minHeight: 190,
            },
            multiline: {
                // ...whiteBackground,
                borderTopLeftRadius: 3,
                borderTopRightRadius: 3,
                // minHeight: 190,
                cursor: 'pointer',
                '&:hover': {
                    // ...whiteBackground,
                },
                '&:focus': {
                    // ...whiteBackground,
                },
            },
            root: {
                // backgroundColor: `${palette.common!.white} !important`,
                color: '#fff',
                cursor: 'pointer',
                '&:hover': {
                    // ...whiteBackground,
                },
                // '&:hover fieldset': {
                //     borderColor: palette.common.white,
                // },
                '&:focus': {
                    // ...whiteBackground,
                },
                // '& fieldset': {
                //     borderColor: palette.common.white,
                //     opacity: 0.2,
                // },
                '& fieldset': {
                    borderColor: palette.common.white,
                    borderWidth: 2,
                    opacity: 0.2,
                },
                '&:hover fieldset': {
                    borderColor: palette.common.white,
                },
                '&.Mui-error fieldset': {
                    opacity: 1,
                },
                '&.Mui-focused fieldset': {
                    borderWidth: 1,
                    opacity: 1,
                },
                '&:hover:not($disabled):not($focused):not($error) $notchedOutline': {
                    borderColor: palette.common.white,
                },
                '&$disabled': {
                    color: '#6B7E94',
                    borderColor: palette.common.white,
                },
                '&.MuiOutlinedInput-root.Mui-disabled .MuiOutlinedInput-notchedOutline': {
                    borderColor: palette.common.white,
                },
            },
        },
        MuiInputLabel: {
            root: {
                '&$disabled': {
                    color: '#6B7E94',
                },
            },
        },
        MuiSelect: {
            filled: {
                // ...whiteBackground,
            },
            selectMenu: {
                // ...whiteBackground,
            },
            root: {
                // ...whiteBackground,
                borderTopLeftRadius: 3,
                borderTopRightRadius: 3,
            },
        },
        MuiMenu: {
            paper: {
                ...whiteBackground,
            },
        },
        MuiMenuItem: {
            root: {
                // ...whiteBackground,
                ...blackFont,
                '&:hover': {
                    // ...whiteBackground,
                },
                '&$selected': {
                    backgroundColor: palette.grey!.A100,
                },
            },
        },
        MuiFormControl: {
            marginNormal: {
                marginBottom: 4,
                marginTop: 8,
            },
        },
        MuiFormControlLabel: {
            label: {
                ...whiteFont,
            },
        },
        MuiCheckbox: {
            root: {
                // ...whiteFont,
            },
        },
        MuiListItem: {
            root: {
                // ...whiteFont,
                ...blackFont,
                textDecoration: 'none',
            },
            gutters: {
                paddingLeft: 32,
                paddingRight: 32,
            },
        },
        MuiListItemText: {
            root: {
                // ...whiteFont,
                ...blackFont,
                textDecoration: 'none',
            },
        },
        MuiInputAdornment: {
            positionEnd: {
                // ...whiteBackground,
            },
            filled: {
                // ...whiteBackground,
            },
            root: {
                // ...whiteBackground,
            },
        },
        MuiTab: {
            root: {
                color: palette.grey!.A100,
                '&$selected': {
                    color: (palette.primary as any).light,
                },
            },
            textColorPrimary: {
                color: palette.grey!.A100,
            },
        },
        MuiTabs: {
            root: {
                // ...whiteFont,
                ...blackFont,
            },
        },
        MuiDialogContentText: {
            root: {
                // ...whiteFont,
                ...blackFont,
            },
        },
        MuiPickersDay: {
            day: {
                ...whiteFont
            }
        },
        MuiPickersCalendarHeader: {
            dayLabel: {
                ...whiteFont
            }
        },
        // MuiPickersCalendarHeader: {
        //     switchHeader: {
        //         ['& > div > p']: {
        //             color: palette.common.black,
        //         },
        //     },
        //     iconButton: {
        //         backgroundColor: palette.common.white,
        //     },
        // },
        // MuiPickersDay: {
        //     daySelected: {
        //         backgroundColor: (palette.primary as any).main,
        //         '&:hover': {
        //             backgroundColor: (palette.primary as any).main,
        //         },
        //     },
        //     current: {
        //         color: '#6A148E',
        //     },
        // },
        // MuiPickersModal: {
        //     dialogRootWider: {
        //         backgroundColor: palette.common.white,
        //     },
        // },
        // MuiAutocomplete: {
        //     popupIndicator: {
        //         color: '#fff',
        //     },
        //     clearIndicator: {
        //         color: '#fff',
        //     },
        // },
        MuiTableCell: {
            root: {
                color: '#fff !important',
                padding: '0 24px 0 24px',
                fontSize: 14
            },
        },
        MuiTableRow: {
            root: {
                height: '36px',
            },
        },
        MuiTableSortLabel: {
            root: {
                '&$active': {
                    color: '#fff',
                    '&& $icon': {
                        opacity: 1,
                        color: '#fff',
                    },
                },
            },
        },
        MuiTablePagination: {
            root: {
                color: '#ffffff'
            }
        }
    },
})

export { dappTheme, colors }
