export const Countries = [
    {
        name: 'Afghanistan',
        iso3: 'AFG',
        iso2: 'AF',
        states: [
            {
                name: 'Badakhshan',
                state_code: 'BDS',
            },
            {
                name: 'Badghis',
                state_code: 'BDG',
            },
            {
                name: 'Baghlan',
                state_code: 'BGL',
            },
            {
                name: 'Balkh',
                state_code: 'BAL',
            },
            {
                name: 'Bamyan',
                state_code: 'BAM',
            },
            {
                name: 'Daykundi',
                state_code: 'DAY',
            },
            {
                name: 'Farah',
                state_code: 'FRA',
            },
            {
                name: 'Faryab',
                state_code: 'FYB',
            },
            {
                name: 'Ghazni',
                state_code: 'GHA',
            },
            {
                name: 'Ghōr',
                state_code: 'GHO',
            },
            {
                name: 'Helmand',
                state_code: 'HEL',
            },
            {
                name: 'Herat',
                state_code: 'HER',
            },
            {
                name: 'Jowzjan',
                state_code: 'JOW',
            },
            {
                name: 'Kabul',
                state_code: 'KAB',
            },
            {
                name: 'Kandahar',
                state_code: 'KAN',
            },
            {
                name: 'Kapisa',
                state_code: 'KAP',
            },
            {
                name: 'Khost',
                state_code: 'KHO',
            },
            {
                name: 'Kunar',
                state_code: 'KNR',
            },
            {
                name: 'Kunduz Province',
                state_code: 'KDZ',
            },
            {
                name: 'Laghman',
                state_code: 'LAG',
            },
            {
                name: 'Logar',
                state_code: 'LOG',
            },
            {
                name: 'Nangarhar',
                state_code: 'NAN',
            },
            {
                name: 'Nimruz',
                state_code: 'NIM',
            },
            {
                name: 'Nuristan',
                state_code: 'NUR',
            },
            {
                name: 'Paktia',
                state_code: 'PIA',
            },
            {
                name: 'Paktika',
                state_code: 'PKA',
            },
            {
                name: 'Panjshir',
                state_code: 'PAN',
            },
            {
                name: 'Parwan',
                state_code: 'PAR',
            },
            {
                name: 'Samangan',
                state_code: 'SAM',
            },
            {
                name: 'Sar-e Pol',
                state_code: 'SAR',
            },
            {
                name: 'Takhar',
                state_code: 'TAK',
            },
            {
                name: 'Urozgan',
                state_code: 'URU',
            },
            {
                name: 'Zabul',
                state_code: 'ZAB',
            },
        ],
    },
    {
        name: 'Aland Islands',
        iso3: 'ALA',
        iso2: 'AX',
        states: [],
    },
    {
        name: 'Albania',
        iso3: 'ALB',
        iso2: 'AL',
        states: [
            {
                name: 'Berat County',
                state_code: '01',
            },
            {
                name: 'Berat District',
                state_code: 'BR',
            },
            {
                name: 'Bulqizë District',
                state_code: 'BU',
            },
            {
                name: 'Delvinë District',
                state_code: 'DL',
            },
            {
                name: 'Devoll District',
                state_code: 'DV',
            },
            {
                name: 'Dibër County',
                state_code: '09',
            },
            {
                name: 'Dibër District',
                state_code: 'DI',
            },
            {
                name: 'Durrës County',
                state_code: '02',
            },
            {
                name: 'Durrës District',
                state_code: 'DR',
            },
            {
                name: 'Elbasan County',
                state_code: '03',
            },
            {
                name: 'Fier County',
                state_code: '04',
            },
            {
                name: 'Fier District',
                state_code: 'FR',
            },
            {
                name: 'Gjirokastër County',
                state_code: '05',
            },
            {
                name: 'Gjirokastër District',
                state_code: 'GJ',
            },
            {
                name: 'Gramsh District',
                state_code: 'GR',
            },
            {
                name: 'Has District',
                state_code: 'HA',
            },
            {
                name: 'Kavajë District',
                state_code: 'KA',
            },
            {
                name: 'Kolonjë District',
                state_code: 'ER',
            },
            {
                name: 'Korçë County',
                state_code: '06',
            },
            {
                name: 'Korçë District',
                state_code: 'KO',
            },
            {
                name: 'Krujë District',
                state_code: 'KR',
            },
            {
                name: 'Kuçovë District',
                state_code: 'KC',
            },
            {
                name: 'Kukës County',
                state_code: '07',
            },
            {
                name: 'Kukës District',
                state_code: 'KU',
            },
            {
                name: 'Kurbin District',
                state_code: 'KB',
            },
            {
                name: 'Lezhë County',
                state_code: '08',
            },
            {
                name: 'Lezhë District',
                state_code: 'LE',
            },
            {
                name: 'Librazhd District',
                state_code: 'LB',
            },
            {
                name: 'Lushnjë District',
                state_code: 'LU',
            },
            {
                name: 'Malësi e Madhe District',
                state_code: 'MM',
            },
            {
                name: 'Mallakastër District',
                state_code: 'MK',
            },
            {
                name: 'Mat District',
                state_code: 'MT',
            },
            {
                name: 'Mirditë District',
                state_code: 'MR',
            },
            {
                name: 'Peqin District',
                state_code: 'PQ',
            },
            {
                name: 'Përmet District',
                state_code: 'PR',
            },
            {
                name: 'Pogradec District',
                state_code: 'PG',
            },
            {
                name: 'Pukë District',
                state_code: 'PU',
            },
            {
                name: 'Sarandë District',
                state_code: 'SR',
            },
            {
                name: 'Shkodër County',
                state_code: '10',
            },
            {
                name: 'Shkodër District',
                state_code: 'SH',
            },
            {
                name: 'Skrapar District',
                state_code: 'SK',
            },
            {
                name: 'Tepelenë District',
                state_code: 'TE',
            },
            {
                name: 'Tirana County',
                state_code: '11',
            },
            {
                name: 'Tirana District',
                state_code: 'TR',
            },
            {
                name: 'Tropojë District',
                state_code: 'TP',
            },
            {
                name: 'Vlorë County',
                state_code: '12',
            },
            {
                name: 'Vlorë District',
                state_code: 'VL',
            },
        ],
    },
    {
        name: 'Algeria',
        iso3: 'DZA',
        iso2: 'DZ',
        states: [
            {
                name: 'Adrar Province',
                state_code: '01',
            },
            {
                name: 'Aïn Defla Province',
                state_code: '44',
            },
            {
                name: 'Aïn Témouchent Province',
                state_code: '46',
            },
            {
                name: 'Algiers Province',
                state_code: '16',
            },
            {
                name: 'Annaba Province',
                state_code: '23',
            },
            {
                name: 'Batna Province',
                state_code: '05',
            },
            {
                name: 'Béchar Province',
                state_code: '08',
            },
            {
                name: 'Béjaïa Province',
                state_code: '06',
            },
            {
                name: 'Biskra',
                state_code: '07',
            },
            {
                name: 'Blida Province',
                state_code: '09',
            },
            {
                name: 'Bordj Bou Arréridj Province',
                state_code: '34',
            },
            {
                name: 'Bouïra Province',
                state_code: '10',
            },
            {
                name: 'Boumerdès Province',
                state_code: '35',
            },
            {
                name: 'Chlef Province',
                state_code: '02',
            },
            {
                name: 'Constantine Province',
                state_code: '25',
            },
            {
                name: 'Djelfa Province',
                state_code: '17',
            },
            {
                name: 'El Bayadh Province',
                state_code: '32',
            },
            {
                name: 'El Oued Province',
                state_code: '39',
            },
            {
                name: 'El Tarf Province',
                state_code: '36',
            },
            {
                name: 'Ghardaïa Province',
                state_code: '47',
            },
            {
                name: 'Guelma Province',
                state_code: '24',
            },
            {
                name: 'Illizi Province',
                state_code: '33',
            },
            {
                name: 'Jijel Province',
                state_code: '18',
            },
            {
                name: 'Khenchela Province',
                state_code: '40',
            },
            {
                name: 'Laghouat Province',
                state_code: '03',
            },
            {
                name: "M'Sila Province",
                state_code: '28',
            },
            {
                name: 'Mascara Province',
                state_code: '29',
            },
            {
                name: 'Médéa Province',
                state_code: '26',
            },
            {
                name: 'Mila Province',
                state_code: '43',
            },
            {
                name: 'Mostaganem Province',
                state_code: '27',
            },
            {
                name: 'Naama Province',
                state_code: '45',
            },
            {
                name: 'Oran Province',
                state_code: '31',
            },
            {
                name: 'Ouargla Province',
                state_code: '30',
            },
            {
                name: 'Oum El Bouaghi Province',
                state_code: '04',
            },
            {
                name: 'Relizane Province',
                state_code: '48',
            },
            {
                name: 'Saïda Province',
                state_code: '20',
            },
            {
                name: 'Sétif Province',
                state_code: '19',
            },
            {
                name: 'Skikda Province',
                state_code: '21',
            },
            {
                name: 'Souk Ahras Province',
                state_code: '41',
            },
            {
                name: 'Tamanghasset Province',
                state_code: '11',
            },
            {
                name: 'Tébessa Province',
                state_code: '12',
            },
            {
                name: 'Tiaret Province',
                state_code: '14',
            },
            {
                name: 'Tindouf Province',
                state_code: '37',
            },
            {
                name: 'Tipasa Province',
                state_code: '42',
            },
            {
                name: 'Tissemsilt Province',
                state_code: '38',
            },
            {
                name: 'Tizi Ouzou Province',
                state_code: '15',
            },
            {
                name: 'Tlemcen Province',
                state_code: '13',
            },
        ],
    },
    {
        name: 'American Samoa',
        iso3: 'ASM',
        iso2: 'AS',
        states: [],
    },
    {
        name: 'Andorra',
        iso3: 'AND',
        iso2: 'AD',
        states: [
            {
                name: 'Andorra la Vella',
                state_code: '07',
            },
            {
                name: 'Canillo',
                state_code: '02',
            },
            {
                name: 'Encamp',
                state_code: '03',
            },
            {
                name: 'Escaldes-Engordany',
                state_code: '08',
            },
            {
                name: 'La Massana',
                state_code: '04',
            },
            {
                name: 'Ordino',
                state_code: '05',
            },
            {
                name: 'Sant Julià de Lòria',
                state_code: '06',
            },
        ],
    },
    {
        name: 'Angola',
        iso3: 'AGO',
        iso2: 'AO',
        states: [
            {
                name: 'Bengo Province',
                state_code: 'BGO',
            },
            {
                name: 'Benguela Province',
                state_code: 'BGU',
            },
            {
                name: 'Bié Province',
                state_code: 'BIE',
            },
            {
                name: 'Cabinda Province',
                state_code: 'CAB',
            },
            {
                name: 'Cuando Cubango Province',
                state_code: 'CCU',
            },
            {
                name: 'Cuanza Norte Province',
                state_code: 'CNO',
            },
            {
                name: 'Cuanza Sul',
                state_code: 'CUS',
            },
            {
                name: 'Cunene Province',
                state_code: 'CNN',
            },
            {
                name: 'Huambo Province',
                state_code: 'HUA',
            },
            {
                name: 'Huíla Province',
                state_code: 'HUI',
            },
            {
                name: 'Luanda Province',
                state_code: 'LUA',
            },
            {
                name: 'Lunda Norte Province',
                state_code: 'LNO',
            },
            {
                name: 'Lunda Sul Province',
                state_code: 'LSU',
            },
            {
                name: 'Malanje Province',
                state_code: 'MAL',
            },
            {
                name: 'Moxico Province',
                state_code: 'MOX',
            },
            {
                name: 'Uíge Province',
                state_code: 'UIG',
            },
            {
                name: 'Zaire Province',
                state_code: 'ZAI',
            },
        ],
    },
    {
        name: 'Anguilla',
        iso3: 'AIA',
        iso2: 'AI',
        states: [],
    },
    {
        name: 'Antarctica',
        iso3: 'ATA',
        iso2: 'AQ',
        states: [],
    },
    {
        name: 'Antigua And Barbuda',
        iso3: 'ATG',
        iso2: 'AG',
        states: [
            {
                name: 'Barbuda',
                state_code: '10',
            },
            {
                name: 'Redonda',
                state_code: '11',
            },
            {
                name: 'Saint George Parish',
                state_code: '03',
            },
            {
                name: 'Saint John Parish',
                state_code: '04',
            },
            {
                name: 'Saint Mary Parish',
                state_code: '05',
            },
            {
                name: 'Saint Paul Parish',
                state_code: '06',
            },
            {
                name: 'Saint Peter Parish',
                state_code: '07',
            },
            {
                name: 'Saint Philip Parish',
                state_code: '08',
            },
        ],
    },
    {
        name: 'Argentina',
        iso3: 'ARG',
        iso2: 'AR',
        states: [
            {
                name: 'Autonomous City Of Buenos Aires',
                state_code: 'C',
            },
            {
                name: 'Buenos Aires Province',
                state_code: 'B',
            },
            {
                name: 'Catamarca Province',
                state_code: 'K',
            },
            {
                name: 'Chaco Province',
                state_code: 'H',
            },
            {
                name: 'Chubut Province',
                state_code: 'U',
            },
            {
                name: 'Córdoba Province',
                state_code: 'X',
            },
            {
                name: 'Corrientes',
                state_code: 'W',
            },
            {
                name: 'Entre Ríos Province',
                state_code: 'E',
            },
            {
                name: 'Formosa Province',
                state_code: 'P',
            },
            {
                name: 'Jujuy Province',
                state_code: 'Y',
            },
            {
                name: 'La Pampa',
                state_code: 'L',
            },
            {
                name: 'La Rioja Province',
                state_code: 'F',
            },
            {
                name: 'Mendoza',
                state_code: 'M',
            },
            {
                name: 'Misiones Province',
                state_code: 'N',
            },
            {
                name: 'Neuquén Province',
                state_code: 'Q',
            },
            {
                name: 'Río Negro Province',
                state_code: 'R',
            },
            {
                name: 'Salta Province',
                state_code: 'A',
            },
            {
                name: 'San Juan Province',
                state_code: 'J',
            },
            {
                name: 'San Luis Province',
                state_code: 'D',
            },
            {
                name: 'Santa Cruz Province',
                state_code: 'Z',
            },
            {
                name: 'Santa Fe Province',
                state_code: 'S',
            },
            {
                name: 'Santiago del Estero Province',
                state_code: 'G',
            },
            {
                name: 'Tierra del Fuego Province',
                state_code: 'V',
            },
            {
                name: 'Tucumán Province',
                state_code: 'T',
            },
        ],
    },
    {
        name: 'Armenia',
        iso3: 'ARM',
        iso2: 'AM',
        states: [
            {
                name: 'Aragatsotn Region',
                state_code: 'AG',
            },
            {
                name: 'Ararat Province',
                state_code: 'AR',
            },
            {
                name: 'Armavir Region',
                state_code: 'AV',
            },
            {
                name: 'Gegharkunik Province',
                state_code: 'GR',
            },
            {
                name: 'Kotayk Region',
                state_code: 'KT',
            },
            {
                name: 'Lori Region',
                state_code: 'LO',
            },
            {
                name: 'Shirak Region',
                state_code: 'SH',
            },
            {
                name: 'Syunik Province',
                state_code: 'SU',
            },
            {
                name: 'Tavush Region',
                state_code: 'TV',
            },
            {
                name: 'Vayots Dzor Region',
                state_code: 'VD',
            },
            {
                name: 'Yerevan',
                state_code: 'ER',
            },
        ],
    },
    {
        name: 'Aruba',
        iso3: 'ABW',
        iso2: 'AW',
        states: [],
    },
    {
        name: 'Australia',
        iso3: 'AUS',
        iso2: 'AU',
        states: [
            {
                name: 'Australian Capital Territory',
                state_code: 'ACT',
            },
            {
                name: 'New South Wales',
                state_code: 'NSW',
            },
            {
                name: 'Northern Territory',
                state_code: 'NT',
            },
            {
                name: 'Queensland',
                state_code: 'QLD',
            },
            {
                name: 'South Australia',
                state_code: 'SA',
            },
            {
                name: 'Tasmania',
                state_code: 'TAS',
            },
            {
                name: 'Victoria',
                state_code: 'VIC',
            },
            {
                name: 'Western Australia',
                state_code: 'WA',
            },
        ],
    },
    {
        name: 'Austria',
        iso3: 'AUT',
        iso2: 'AT',
        states: [
            {
                name: 'Burgenland',
                state_code: '1',
            },
            {
                name: 'Carinthia',
                state_code: '2',
            },
            {
                name: 'Lower Austria',
                state_code: '3',
            },
            {
                name: 'Salzburg',
                state_code: '5',
            },
            {
                name: 'Styria',
                state_code: '6',
            },
            {
                name: 'Tyrol',
                state_code: '7',
            },
            {
                name: 'Upper Austria',
                state_code: '4',
            },
            {
                name: 'Vienna',
                state_code: '9',
            },
            {
                name: 'Vorarlberg',
                state_code: '8',
            },
        ],
    },
    {
        name: 'Azerbaijan',
        iso3: 'AZE',
        iso2: 'AZ',
        states: [
            {
                name: 'Absheron District',
                state_code: 'ABS',
            },
            {
                name: 'Agdam District',
                state_code: 'AGM',
            },
            {
                name: 'Agdash District',
                state_code: 'AGS',
            },
            {
                name: 'Aghjabadi District',
                state_code: 'AGC',
            },
            {
                name: 'Agstafa District',
                state_code: 'AGA',
            },
            {
                name: 'Agsu District',
                state_code: 'AGU',
            },
            {
                name: 'Astara District',
                state_code: 'AST',
            },
            {
                name: 'Babek District',
                state_code: 'BAB',
            },
            {
                name: 'Baku',
                state_code: 'BA',
            },
            {
                name: 'Balakan District',
                state_code: 'BAL',
            },
            {
                name: 'Barda District',
                state_code: 'BAR',
            },
            {
                name: 'Beylagan District',
                state_code: 'BEY',
            },
            {
                name: 'Bilasuvar District',
                state_code: 'BIL',
            },
            {
                name: 'Dashkasan District',
                state_code: 'DAS',
            },
            {
                name: 'Fizuli District',
                state_code: 'FUZ',
            },
            {
                name: 'Ganja',
                state_code: 'GA',
            },
            {
                name: 'Gədəbəy',
                state_code: 'GAD',
            },
            {
                name: 'Gobustan District',
                state_code: 'QOB',
            },
            {
                name: 'Goranboy District',
                state_code: 'GOR',
            },
            {
                name: 'Goychay',
                state_code: 'GOY',
            },
            {
                name: 'Goygol District',
                state_code: 'GYG',
            },
            {
                name: 'Hajigabul District',
                state_code: 'HAC',
            },
            {
                name: 'Imishli District',
                state_code: 'IMI',
            },
            {
                name: 'Ismailli District',
                state_code: 'ISM',
            },
            {
                name: 'Jabrayil District',
                state_code: 'CAB',
            },
            {
                name: 'Jalilabad District',
                state_code: 'CAL',
            },
            {
                name: 'Julfa District',
                state_code: 'CUL',
            },
            {
                name: 'Kalbajar District',
                state_code: 'KAL',
            },
            {
                name: 'Kangarli District',
                state_code: 'KAN',
            },
            {
                name: 'Khachmaz District',
                state_code: 'XAC',
            },
            {
                name: 'Khizi District',
                state_code: 'XIZ',
            },
            {
                name: 'Khojali District',
                state_code: 'XCI',
            },
            {
                name: 'Kurdamir District',
                state_code: 'KUR',
            },
            {
                name: 'Lachin District',
                state_code: 'LAC',
            },
            {
                name: 'Lankaran',
                state_code: 'LAN',
            },
            {
                name: 'Lankaran District',
                state_code: 'LA',
            },
            {
                name: 'Lerik District',
                state_code: 'LER',
            },
            {
                name: 'Martuni',
                state_code: 'XVD',
            },
            {
                name: 'Masally District',
                state_code: 'MAS',
            },
            {
                name: 'Mingachevir',
                state_code: 'MI',
            },
            {
                name: 'Nakhchivan Autonomous Republic',
                state_code: 'NX',
            },
            {
                name: 'Neftchala District',
                state_code: 'NEF',
            },
            {
                name: 'Oghuz District',
                state_code: 'OGU',
            },
            {
                name: 'Ordubad District',
                state_code: 'ORD',
            },
            {
                name: 'Qabala District',
                state_code: 'QAB',
            },
            {
                name: 'Qakh District',
                state_code: 'QAX',
            },
            {
                name: 'Qazakh District',
                state_code: 'QAZ',
            },
            {
                name: 'Quba District',
                state_code: 'QBA',
            },
            {
                name: 'Qubadli District',
                state_code: 'QBI',
            },
            {
                name: 'Qusar District',
                state_code: 'QUS',
            },
            {
                name: 'Saatly District',
                state_code: 'SAT',
            },
            {
                name: 'Sabirabad District',
                state_code: 'SAB',
            },
            {
                name: 'Sadarak District',
                state_code: 'SAD',
            },
            {
                name: 'Salyan District',
                state_code: 'SAL',
            },
            {
                name: 'Samukh District',
                state_code: 'SMX',
            },
            {
                name: 'Shabran District',
                state_code: 'SBN',
            },
            {
                name: 'Shahbuz District',
                state_code: 'SAH',
            },
            {
                name: 'Shaki',
                state_code: 'SA',
            },
            {
                name: 'Shaki District',
                state_code: 'SAK',
            },
            {
                name: 'Shamakhi District',
                state_code: 'SMI',
            },
            {
                name: 'Shamkir District',
                state_code: 'SKR',
            },
            {
                name: 'Sharur District',
                state_code: 'SAR',
            },
            {
                name: 'Shirvan',
                state_code: 'SR',
            },
            {
                name: 'Shusha District',
                state_code: 'SUS',
            },
            {
                name: 'Siazan District',
                state_code: 'SIY',
            },
            {
                name: 'Sumqayit',
                state_code: 'SM',
            },
            {
                name: 'Tartar District',
                state_code: 'TAR',
            },
            {
                name: 'Tovuz District',
                state_code: 'TOV',
            },
            {
                name: 'Ujar District',
                state_code: 'UCA',
            },
            {
                name: 'Yardymli District',
                state_code: 'YAR',
            },
            {
                name: 'Yevlakh',
                state_code: 'YE',
            },
            {
                name: 'Yevlakh District',
                state_code: 'YEV',
            },
            {
                name: 'Zangilan District',
                state_code: 'ZAN',
            },
            {
                name: 'Zaqatala District',
                state_code: 'ZAQ',
            },
            {
                name: 'Zardab District',
                state_code: 'ZAR',
            },
        ],
    },
    {
        name: 'Bahamas The',
        iso3: 'BHS',
        iso2: 'BS',
        states: [
            {
                name: 'Acklins',
                state_code: 'AK',
            },
            {
                name: 'Acklins and Crooked Islands',
                state_code: 'AC',
            },
            {
                name: 'Berry Islands',
                state_code: 'BY',
            },
            {
                name: 'Bimini',
                state_code: 'BI',
            },
            {
                name: 'Black Point',
                state_code: 'BP',
            },
            {
                name: 'Cat Island',
                state_code: 'CI',
            },
            {
                name: 'Central Abaco',
                state_code: 'CO',
            },
            {
                name: 'Central Andros',
                state_code: 'CS',
            },
            {
                name: 'Central Eleuthera',
                state_code: 'CE',
            },
            {
                name: 'Crooked Island',
                state_code: 'CK',
            },
            {
                name: 'East Grand Bahama',
                state_code: 'EG',
            },
            {
                name: 'Exuma',
                state_code: 'EX',
            },
            {
                name: 'Freeport',
                state_code: 'FP',
            },
            {
                name: 'Fresh Creek',
                state_code: 'FC',
            },
            {
                name: "Governor's Harbour",
                state_code: 'GH',
            },
            {
                name: 'Grand Cay',
                state_code: 'GC',
            },
            {
                name: 'Green Turtle Cay',
                state_code: 'GT',
            },
            {
                name: 'Harbour Island',
                state_code: 'HI',
            },
            {
                name: 'High Rock',
                state_code: 'HR',
            },
            {
                name: 'Hope Town',
                state_code: 'HT',
            },
            {
                name: 'Inagua',
                state_code: 'IN',
            },
            {
                name: 'Kemps Bay',
                state_code: 'KB',
            },
            {
                name: 'Long Island',
                state_code: 'LI',
            },
            {
                name: 'Mangrove Cay',
                state_code: 'MC',
            },
            {
                name: 'Marsh Harbour',
                state_code: 'MH',
            },
            {
                name: 'Mayaguana District',
                state_code: 'MG',
            },
            {
                name: 'Nichollstown and Berry Islands',
                state_code: 'NB',
            },
            {
                name: 'North Abaco',
                state_code: 'NO',
            },
            {
                name: 'North Andros',
                state_code: 'NS',
            },
            {
                name: 'North Eleuthera',
                state_code: 'NE',
            },
            {
                name: 'Ragged Island',
                state_code: 'RI',
            },
            {
                name: 'Rock Sound',
                state_code: 'RS',
            },
            {
                name: 'Rum Cay District',
                state_code: 'RC',
            },
            {
                name: 'San Salvador and Rum Cay',
                state_code: 'SR',
            },
            {
                name: 'San Salvador Island',
                state_code: 'SS',
            },
            {
                name: 'Sandy Point',
                state_code: 'SP',
            },
            {
                name: 'South Abaco',
                state_code: 'SO',
            },
            {
                name: 'South Andros',
                state_code: 'SA',
            },
            {
                name: 'South Eleuthera',
                state_code: 'SE',
            },
            {
                name: 'Spanish Wells',
                state_code: 'SW',
            },
            {
                name: 'West Grand Bahama',
                state_code: 'WG',
            },
        ],
    },
    {
        name: 'Bahrain',
        iso3: 'BHR',
        iso2: 'BH',
        states: [
            {
                name: 'Capital Governorate',
                state_code: '13',
            },
            {
                name: 'Central Governorate',
                state_code: '16',
            },
            {
                name: 'Muharraq Governorate',
                state_code: '15',
            },
            {
                name: 'Northern Governorate',
                state_code: '17',
            },
            {
                name: 'Southern Governorate',
                state_code: '14',
            },
        ],
    },
    {
        name: 'Bangladesh',
        iso3: 'BGD',
        iso2: 'BD',
        states: [
            {
                name: 'Bagerhat District',
                state_code: '05',
            },
            {
                name: 'Bahadia',
                state_code: '33',
            },
            {
                name: 'Bandarban District',
                state_code: '01',
            },
            {
                name: 'Barguna District',
                state_code: '02',
            },
            {
                name: 'Barisal District',
                state_code: '06',
            },
            {
                name: 'Barisal Division',
                state_code: 'A',
            },
            {
                name: 'Bhola District',
                state_code: '07',
            },
            {
                name: 'Bogra District',
                state_code: '03',
            },
            {
                name: 'Brahmanbaria District',
                state_code: '04',
            },
            {
                name: 'Chandpur District',
                state_code: '09',
            },
            {
                name: 'Chapai Nawabganj District',
                state_code: '45',
            },
            {
                name: 'Chittagong District',
                state_code: '10',
            },
            {
                name: 'Chittagong Division',
                state_code: 'B',
            },
            {
                name: 'Chuadanga District',
                state_code: '12',
            },
            {
                name: 'Comilla District',
                state_code: '08',
            },
            {
                name: "Cox's Bazar District",
                state_code: '11',
            },
            {
                name: 'Dhaka District',
                state_code: '13',
            },
            {
                name: 'Dhaka Division',
                state_code: 'C',
            },
            {
                name: 'Dinajpur District',
                state_code: '14',
            },
            {
                name: 'Faridpur District',
                state_code: '15',
            },
            {
                name: 'Feni District',
                state_code: '16',
            },
            {
                name: 'Gaibandha District',
                state_code: '19',
            },
            {
                name: 'Gazipur District',
                state_code: '18',
            },
            {
                name: 'Gopalganj District',
                state_code: '17',
            },
            {
                name: 'Habiganj District',
                state_code: '20',
            },
            {
                name: 'Jamalpur District',
                state_code: '21',
            },
            {
                name: 'Jessore District',
                state_code: '22',
            },
            {
                name: 'Jhalokati District',
                state_code: '25',
            },
            {
                name: 'Jhenaidah District',
                state_code: '23',
            },
            {
                name: 'Joypurhat District',
                state_code: '24',
            },
            {
                name: 'Khagrachari District',
                state_code: '29',
            },
            {
                name: 'Khulna District',
                state_code: '27',
            },
            {
                name: 'Khulna Division',
                state_code: 'D',
            },
            {
                name: 'Kishoreganj District',
                state_code: '26',
            },
            {
                name: 'Kurigram District',
                state_code: '28',
            },
            {
                name: 'Kushtia District',
                state_code: '30',
            },
            {
                name: 'Lakshmipur District',
                state_code: '31',
            },
            {
                name: 'Lalmonirhat District',
                state_code: '32',
            },
            {
                name: 'Madaripur District',
                state_code: '36',
            },
            {
                name: 'Meherpur District',
                state_code: '39',
            },
            {
                name: 'Moulvibazar District',
                state_code: '38',
            },
            {
                name: 'Munshiganj District',
                state_code: '35',
            },
            {
                name: 'Mymensingh District',
                state_code: '34',
            },
            {
                name: 'Mymensingh Division',
                state_code: 'H',
            },
            {
                name: 'Naogaon District',
                state_code: '48',
            },
            {
                name: 'Narail District',
                state_code: '43',
            },
            {
                name: 'Narayanganj District',
                state_code: '40',
            },
            {
                name: 'Natore District',
                state_code: '44',
            },
            {
                name: 'Netrokona District',
                state_code: '41',
            },
            {
                name: 'Nilphamari District',
                state_code: '46',
            },
            {
                name: 'Noakhali District',
                state_code: '47',
            },
            {
                name: 'Pabna District',
                state_code: '49',
            },
            {
                name: 'Panchagarh District',
                state_code: '52',
            },
            {
                name: 'Patuakhali District',
                state_code: '51',
            },
            {
                name: 'Pirojpur District',
                state_code: '50',
            },
            {
                name: 'Rajbari District',
                state_code: '53',
            },
            {
                name: 'Rajshahi District',
                state_code: '54',
            },
            {
                name: 'Rajshahi Division',
                state_code: 'E',
            },
            {
                name: 'Rangamati Hill District',
                state_code: '56',
            },
            {
                name: 'Rangpur District',
                state_code: '55',
            },
            {
                name: 'Rangpur Division',
                state_code: 'F',
            },
            {
                name: 'Satkhira District',
                state_code: '58',
            },
            {
                name: 'Shariatpur District',
                state_code: '62',
            },
            {
                name: 'Sherpur District',
                state_code: '57',
            },
            {
                name: 'Sirajganj District',
                state_code: '59',
            },
            {
                name: 'Sunamganj District',
                state_code: '61',
            },
            {
                name: 'Sylhet District',
                state_code: '60',
            },
            {
                name: 'Sylhet Division',
                state_code: 'G',
            },
            {
                name: 'Tangail District',
                state_code: '63',
            },
            {
                name: 'Thakurgaon District',
                state_code: '64',
            },
        ],
    },
    {
        name: 'Barbados',
        iso3: 'BRB',
        iso2: 'BB',
        states: [
            {
                name: 'Christ Church',
                state_code: '01',
            },
            {
                name: 'Saint Andrew',
                state_code: '02',
            },
            {
                name: 'Saint George',
                state_code: '03',
            },
            {
                name: 'Saint James',
                state_code: '04',
            },
            {
                name: 'Saint John',
                state_code: '05',
            },
            {
                name: 'Saint Joseph',
                state_code: '06',
            },
            {
                name: 'Saint Lucy',
                state_code: '07',
            },
            {
                name: 'Saint Michael',
                state_code: '08',
            },
            {
                name: 'Saint Peter',
                state_code: '09',
            },
            {
                name: 'Saint Philip',
                state_code: '10',
            },
            {
                name: 'Saint Thomas',
                state_code: '11',
            },
        ],
    },
    {
        name: 'Belarus',
        iso3: 'BLR',
        iso2: 'BY',
        states: [
            {
                name: 'Brest Region',
                state_code: 'BR',
            },
            {
                name: 'Gomel Region',
                state_code: 'HO',
            },
            {
                name: 'Grodno Region',
                state_code: 'HR',
            },
            {
                name: 'Minsk',
                state_code: 'HM',
            },
            {
                name: 'Minsk Region',
                state_code: 'MI',
            },
            {
                name: 'Mogilev Region',
                state_code: 'MA',
            },
            {
                name: 'Vitebsk Region',
                state_code: 'VI',
            },
        ],
    },
    {
        name: 'Belgium',
        iso3: 'BEL',
        iso2: 'BE',
        states: [
            {
                name: 'Antwerp',
                state_code: 'VAN',
            },
            {
                name: 'Brussels-Capital Region',
                state_code: 'BRU',
            },
            {
                name: 'East Flanders',
                state_code: 'VOV',
            },
            {
                name: 'Flanders',
                state_code: 'VLG',
            },
            {
                name: 'Flemish Brabant',
                state_code: 'VBR',
            },
            {
                name: 'Hainaut',
                state_code: 'WHT',
            },
            {
                name: 'Liège',
                state_code: 'WLG',
            },
            {
                name: 'Limburg',
                state_code: 'VLI',
            },
            {
                name: 'Luxembourg',
                state_code: 'WLX',
            },
            {
                name: 'Namur',
                state_code: 'WNA',
            },
            {
                name: 'Wallonia',
                state_code: 'WAL',
            },
            {
                name: 'Walloon Brabant',
                state_code: 'WBR',
            },
            {
                name: 'West Flanders',
                state_code: 'VWV',
            },
        ],
    },
    {
        name: 'Belize',
        iso3: 'BLZ',
        iso2: 'BZ',
        states: [
            {
                name: 'Belize District',
                state_code: 'BZ',
            },
            {
                name: 'Cayo District',
                state_code: 'CY',
            },
            {
                name: 'Corozal District',
                state_code: 'CZL',
            },
            {
                name: 'Orange Walk District',
                state_code: 'OW',
            },
            {
                name: 'Stann Creek District',
                state_code: 'SC',
            },
            {
                name: 'Toledo District',
                state_code: 'TOL',
            },
        ],
    },
    {
        name: 'Benin',
        iso3: 'BEN',
        iso2: 'BJ',
        states: [
            {
                name: 'Alibori Department',
                state_code: 'AL',
            },
            {
                name: 'Atakora Department',
                state_code: 'AK',
            },
            {
                name: 'Atlantique Department',
                state_code: 'AQ',
            },
            {
                name: 'Borgou Department',
                state_code: 'BO',
            },
            {
                name: 'Collines Department',
                state_code: 'CO',
            },
            {
                name: 'Donga Department',
                state_code: 'DO',
            },
            {
                name: 'Kouffo Department',
                state_code: 'KO',
            },
            {
                name: 'Littoral Department',
                state_code: 'LI',
            },
            {
                name: 'Mono Department',
                state_code: 'MO',
            },
            {
                name: 'Ouémé Department',
                state_code: 'OU',
            },
            {
                name: 'Plateau Department',
                state_code: 'PL',
            },
            {
                name: 'Zou Department',
                state_code: 'ZO',
            },
        ],
    },
    {
        name: 'Bermuda',
        iso3: 'BMU',
        iso2: 'BM',
        states: [
            {
                name: 'Devonshire Parish',
                state_code: 'DEV',
            },
            {
                name: 'Hamilton Municipality',
                state_code: 'HAM',
            },
            {
                name: 'Hamilton Parish',
                state_code: 'HA',
            },
            {
                name: 'Paget Parish',
                state_code: 'PAG',
            },
            {
                name: 'Pembroke Parish',
                state_code: 'PEM',
            },
            {
                name: "Saint George's Municipality",
                state_code: 'SG',
            },
            {
                name: "Saint George's Parish",
                state_code: 'SGE',
            },
            {
                name: 'Sandys Parish',
                state_code: 'SAN',
            },
            {
                name: "Smith's Parish,",
                state_code: 'SMI',
            },
            {
                name: 'Southampton Parish',
                state_code: 'SOU',
            },
            {
                name: 'Warwick Parish',
                state_code: 'WAR',
            },
        ],
    },
    {
        name: 'Bhutan',
        iso3: 'BTN',
        iso2: 'BT',
        states: [
            {
                name: 'Bumthang District',
                state_code: '33',
            },
            {
                name: 'Chukha District',
                state_code: '12',
            },
            {
                name: 'Dagana District',
                state_code: '22',
            },
            {
                name: 'Gasa District',
                state_code: 'GA',
            },
            {
                name: 'Haa District',
                state_code: '13',
            },
            {
                name: 'Lhuntse District',
                state_code: '44',
            },
            {
                name: 'Mongar District',
                state_code: '42',
            },
            {
                name: 'Paro District',
                state_code: '11',
            },
            {
                name: 'Pemagatshel District',
                state_code: '43',
            },
            {
                name: 'Punakha District',
                state_code: '23',
            },
            {
                name: 'Samdrup Jongkhar District',
                state_code: '45',
            },
            {
                name: 'Samtse District',
                state_code: '14',
            },
            {
                name: 'Sarpang District',
                state_code: '31',
            },
            {
                name: 'Thimphu District',
                state_code: '15',
            },
            {
                name: 'Trashigang District',
                state_code: '41',
            },
            {
                name: 'Trongsa District',
                state_code: '32',
            },
            {
                name: 'Tsirang District',
                state_code: '21',
            },
            {
                name: 'Wangdue Phodrang District',
                state_code: '24',
            },
            {
                name: 'Zhemgang District',
                state_code: '34',
            },
        ],
    },
    {
        name: 'Bolivia',
        iso3: 'BOL',
        iso2: 'BO',
        states: [
            {
                name: 'Beni Department',
                state_code: 'B',
            },
            {
                name: 'Chuquisaca Department',
                state_code: 'H',
            },
            {
                name: 'Cochabamba Department',
                state_code: 'C',
            },
            {
                name: 'La Paz Department',
                state_code: 'L',
            },
            {
                name: 'Oruro Department',
                state_code: 'O',
            },
            {
                name: 'Pando Department',
                state_code: 'N',
            },
            {
                name: 'Potosí Department',
                state_code: 'P',
            },
            {
                name: 'Santa Cruz Department',
                state_code: 'S',
            },
            {
                name: 'Tarija Department',
                state_code: 'T',
            },
        ],
    },
    {
        name: 'Bosnia and Herzegovina',
        iso3: 'BIH',
        iso2: 'BA',
        states: [
            {
                name: 'Bosnian Podrinje Canton',
                state_code: '05',
            },
            {
                name: 'Brčko District',
                state_code: 'BRC',
            },
            {
                name: 'Canton 10',
                state_code: '10',
            },
            {
                name: 'Central Bosnia Canton',
                state_code: '06',
            },
            {
                name: 'Federation of Bosnia and Herzegovina',
                state_code: 'BIH',
            },
            {
                name: 'Herzegovina-Neretva Canton',
                state_code: '07',
            },
            {
                name: 'Posavina Canton',
                state_code: '02',
            },
            {
                name: 'Republika Srpska',
                state_code: 'SRP',
            },
            {
                name: 'Sarajevo Canton',
                state_code: '09',
            },
            {
                name: 'Tuzla Canton',
                state_code: '03',
            },
            {
                name: 'Una-Sana Canton',
                state_code: '01',
            },
            {
                name: 'West Herzegovina Canton',
                state_code: '08',
            },
            {
                name: 'Zenica-Doboj Canton',
                state_code: '04',
            },
        ],
    },
    {
        name: 'Botswana',
        iso3: 'BWA',
        iso2: 'BW',
        states: [
            {
                name: 'Central District',
                state_code: 'CE',
            },
            {
                name: 'Ghanzi District',
                state_code: 'GH',
            },
            {
                name: 'Kgalagadi District',
                state_code: 'KG',
            },
            {
                name: 'Kgatleng District',
                state_code: 'KL',
            },
            {
                name: 'Kweneng District',
                state_code: 'KW',
            },
            {
                name: 'Ngamiland',
                state_code: 'NG',
            },
            {
                name: 'North-East District',
                state_code: 'NE',
            },
            {
                name: 'North-West District',
                state_code: 'NW',
            },
            {
                name: 'South-East District',
                state_code: 'SE',
            },
            {
                name: 'Southern District',
                state_code: 'SO',
            },
        ],
    },
    {
        name: 'Bouvet Island',
        iso3: 'BVT',
        iso2: 'BV',
        states: [],
    },
    {
        name: 'Brazil',
        iso3: 'BRA',
        iso2: 'BR',
        states: [
            {
                name: 'Acre',
                state_code: 'AC',
            },
            {
                name: 'Alagoas',
                state_code: 'AL',
            },
            {
                name: 'Amapá',
                state_code: 'AP',
            },
            {
                name: 'Amazonas',
                state_code: 'AM',
            },
            {
                name: 'Bahia',
                state_code: 'BA',
            },
            {
                name: 'Ceará',
                state_code: 'CE',
            },
            {
                name: 'Espírito Santo',
                state_code: 'ES',
            },
            {
                name: 'Federal District',
                state_code: 'DF',
            },
            {
                name: 'Goiás',
                state_code: 'GO',
            },
            {
                name: 'Maranhão',
                state_code: 'MA',
            },
            {
                name: 'Mato Grosso',
                state_code: 'MT',
            },
            {
                name: 'Mato Grosso do Sul',
                state_code: 'MS',
            },
            {
                name: 'Minas Gerais',
                state_code: 'MG',
            },
            {
                name: 'Pará',
                state_code: 'PA',
            },
            {
                name: 'Paraíba',
                state_code: 'PB',
            },
            {
                name: 'Paraná',
                state_code: 'PR',
            },
            {
                name: 'Pernambuco',
                state_code: 'PE',
            },
            {
                name: 'Piauí',
                state_code: 'PI',
            },
            {
                name: 'Rio de Janeiro',
                state_code: 'RJ',
            },
            {
                name: 'Rio Grande do Norte',
                state_code: 'RN',
            },
            {
                name: 'Rio Grande do Sul',
                state_code: 'RS',
            },
            {
                name: 'Rondônia',
                state_code: 'RO',
            },
            {
                name: 'Roraima',
                state_code: 'RR',
            },
            {
                name: 'Santa Catarina',
                state_code: 'SC',
            },
            {
                name: 'São Paulo',
                state_code: 'SP',
            },
            {
                name: 'Sergipe',
                state_code: 'SE',
            },
            {
                name: 'Tocantins',
                state_code: 'TO',
            },
        ],
    },
    {
        name: 'British Indian Ocean Territory',
        iso3: 'IOT',
        iso2: 'IO',
        states: [],
    },
    {
        name: 'Brunei',
        iso3: 'BRN',
        iso2: 'BN',
        states: [
            {
                name: 'Belait District',
                state_code: 'BE',
            },
            {
                name: 'Brunei-Muara District',
                state_code: 'BM',
            },
            {
                name: 'Temburong District',
                state_code: 'TE',
            },
            {
                name: 'Tutong District',
                state_code: 'TU',
            },
        ],
    },
    {
        name: 'Bulgaria',
        iso3: 'BGR',
        iso2: 'BG',
        states: [
            {
                name: 'Blagoevgrad Province',
                state_code: '01',
            },
            {
                name: 'Burgas Province',
                state_code: '02',
            },
            {
                name: 'Dobrich Province',
                state_code: '08',
            },
            {
                name: 'Gabrovo Province',
                state_code: '07',
            },
            {
                name: 'Haskovo Province',
                state_code: '26',
            },
            {
                name: 'Kardzhali Province',
                state_code: '09',
            },
            {
                name: 'Kyustendil Province',
                state_code: '10',
            },
            {
                name: 'Lovech Province',
                state_code: '11',
            },
            {
                name: 'Montana Province',
                state_code: '12',
            },
            {
                name: 'Pazardzhik Province',
                state_code: '13',
            },
            {
                name: 'Pernik Province',
                state_code: '14',
            },
            {
                name: 'Pleven Province',
                state_code: '15',
            },
            {
                name: 'Plovdiv Province',
                state_code: '16',
            },
            {
                name: 'Razgrad Province',
                state_code: '17',
            },
            {
                name: 'Ruse Province',
                state_code: '18',
            },
            {
                name: 'Silistra Province',
                state_code: '19',
            },
            {
                name: 'Sliven Province',
                state_code: '20',
            },
            {
                name: 'Smolyan Province',
                state_code: '21',
            },
            {
                name: 'Sofia City Province',
                state_code: '22',
            },
            {
                name: 'Sofia Province',
                state_code: '23',
            },
            {
                name: 'Stara Zagora Province',
                state_code: '24',
            },
            {
                name: 'Targovishte Province',
                state_code: '25',
            },
            {
                name: 'Varna Province',
                state_code: '03',
            },
            {
                name: 'Veliko Tarnovo Province',
                state_code: '04',
            },
            {
                name: 'Vidin Province',
                state_code: '05',
            },
            {
                name: 'Vratsa Province',
                state_code: '06',
            },
            {
                name: 'Yambol Province',
                state_code: '28',
            },
        ],
    },
    {
        name: 'Burkina Faso',
        iso3: 'BFA',
        iso2: 'BF',
        states: [
            {
                name: 'Balé Province',
                state_code: 'BAL',
            },
            {
                name: 'Bam Province',
                state_code: 'BAM',
            },
            {
                name: 'Banwa Province',
                state_code: 'BAN',
            },
            {
                name: 'Bazèga Province',
                state_code: 'BAZ',
            },
            {
                name: 'Boucle du Mouhoun Region',
                state_code: '01',
            },
            {
                name: 'Bougouriba Province',
                state_code: 'BGR',
            },
            {
                name: 'Boulgou',
                state_code: 'BLG',
            },
            {
                name: 'Cascades Region',
                state_code: '02',
            },
            {
                name: 'Centre',
                state_code: '03',
            },
            {
                name: 'Centre-Est Region',
                state_code: '04',
            },
            {
                name: 'Centre-Nord Region',
                state_code: '05',
            },
            {
                name: 'Centre-Ouest Region',
                state_code: '06',
            },
            {
                name: 'Centre-Sud Region',
                state_code: '07',
            },
            {
                name: 'Comoé Province',
                state_code: 'COM',
            },
            {
                name: 'Est Region',
                state_code: '08',
            },
            {
                name: 'Ganzourgou Province',
                state_code: 'GAN',
            },
            {
                name: 'Gnagna Province',
                state_code: 'GNA',
            },
            {
                name: 'Gourma Province',
                state_code: 'GOU',
            },
            {
                name: 'Hauts-Bassins Region',
                state_code: '09',
            },
            {
                name: 'Houet Province',
                state_code: 'HOU',
            },
            {
                name: 'Ioba Province',
                state_code: 'IOB',
            },
            {
                name: 'Kadiogo Province',
                state_code: 'KAD',
            },
            {
                name: 'Kénédougou Province',
                state_code: 'KEN',
            },
            {
                name: 'Komondjari Province',
                state_code: 'KMD',
            },
            {
                name: 'Kompienga Province',
                state_code: 'KMP',
            },
            {
                name: 'Kossi Province',
                state_code: 'KOS',
            },
            {
                name: 'Koulpélogo Province',
                state_code: 'KOP',
            },
            {
                name: 'Kouritenga Province',
                state_code: 'KOT',
            },
            {
                name: 'Kourwéogo Province',
                state_code: 'KOW',
            },
            {
                name: 'Léraba Province',
                state_code: 'LER',
            },
            {
                name: 'Loroum Province',
                state_code: 'LOR',
            },
            {
                name: 'Mouhoun',
                state_code: 'MOU',
            },
            {
                name: 'Nahouri Province',
                state_code: 'NAO',
            },
            {
                name: 'Namentenga Province',
                state_code: 'NAM',
            },
            {
                name: 'Nayala Province',
                state_code: 'NAY',
            },
            {
                name: 'Nord Region, Burkina Faso',
                state_code: '10',
            },
            {
                name: 'Noumbiel Province',
                state_code: 'NOU',
            },
            {
                name: 'Oubritenga Province',
                state_code: 'OUB',
            },
            {
                name: 'Oudalan Province',
                state_code: 'OUD',
            },
            {
                name: 'Passoré Province',
                state_code: 'PAS',
            },
            {
                name: 'Plateau-Central Region',
                state_code: '11',
            },
            {
                name: 'Poni Province',
                state_code: 'PON',
            },
            {
                name: 'Sahel Region',
                state_code: '12',
            },
            {
                name: 'Sanguié Province',
                state_code: 'SNG',
            },
            {
                name: 'Sanmatenga Province',
                state_code: 'SMT',
            },
            {
                name: 'Séno Province',
                state_code: 'SEN',
            },
            {
                name: 'Sissili Province',
                state_code: 'SIS',
            },
            {
                name: 'Soum Province',
                state_code: 'SOM',
            },
            {
                name: 'Sourou Province',
                state_code: 'SOR',
            },
            {
                name: 'Sud-Ouest Region',
                state_code: '13',
            },
            {
                name: 'Tapoa Province',
                state_code: 'TAP',
            },
            {
                name: 'Tuy Province',
                state_code: 'TUI',
            },
            {
                name: 'Yagha Province',
                state_code: 'YAG',
            },
            {
                name: 'Yatenga Province',
                state_code: 'YAT',
            },
            {
                name: 'Ziro Province',
                state_code: 'ZIR',
            },
            {
                name: 'Zondoma Province',
                state_code: 'ZON',
            },
            {
                name: 'Zoundwéogo Province',
                state_code: 'ZOU',
            },
        ],
    },
    {
        name: 'Burundi',
        iso3: 'BDI',
        iso2: 'BI',
        states: [
            {
                name: 'Bubanza Province',
                state_code: 'BB',
            },
            {
                name: 'Bujumbura Mairie Province',
                state_code: 'BM',
            },
            {
                name: 'Bujumbura Rural Province',
                state_code: 'BL',
            },
            {
                name: 'Bururi Province',
                state_code: 'BR',
            },
            {
                name: 'Cankuzo Province',
                state_code: 'CA',
            },
            {
                name: 'Cibitoke Province',
                state_code: 'CI',
            },
            {
                name: 'Gitega Province',
                state_code: 'GI',
            },
            {
                name: 'Karuzi Province',
                state_code: 'KR',
            },
            {
                name: 'Kayanza Province',
                state_code: 'KY',
            },
            {
                name: 'Kirundo Province',
                state_code: 'KI',
            },
            {
                name: 'Makamba Province',
                state_code: 'MA',
            },
            {
                name: 'Muramvya Province',
                state_code: 'MU',
            },
            {
                name: 'Muyinga Province',
                state_code: 'MY',
            },
            {
                name: 'Mwaro Province',
                state_code: 'MW',
            },
            {
                name: 'Ngozi Province',
                state_code: 'NG',
            },
            {
                name: 'Rumonge Province',
                state_code: 'RM',
            },
            {
                name: 'Rutana Province',
                state_code: 'RT',
            },
            {
                name: 'Ruyigi Province',
                state_code: 'RY',
            },
        ],
    },
    {
        name: 'Cambodia',
        iso3: 'KHM',
        iso2: 'KH',
        states: [
            {
                name: 'Banteay Meanchey Province',
                state_code: '1',
            },
            {
                name: 'Battambang Province',
                state_code: '2',
            },
            {
                name: 'Kampong Cham Province',
                state_code: '3',
            },
            {
                name: 'Kampong Chhnang Province',
                state_code: '4',
            },
            {
                name: 'Kampong Speu Province',
                state_code: '5',
            },
            {
                name: 'Kampot Province',
                state_code: '7',
            },
            {
                name: 'Kandal Province',
                state_code: '8',
            },
            {
                name: 'Kep Province',
                state_code: '23',
            },
            {
                name: 'Koh Kong Province',
                state_code: '9',
            },
            {
                name: 'Kratié Province',
                state_code: '10',
            },
            {
                name: 'Mondulkiri Province',
                state_code: '11',
            },
            {
                name: 'Oddar Meanchey Province',
                state_code: '22',
            },
            {
                name: 'Pailin Province',
                state_code: '24',
            },
            {
                name: 'Phnom Penh',
                state_code: '12',
            },
            {
                name: 'Preah Vihear Province',
                state_code: '13',
            },
            {
                name: 'Prey Veng Province',
                state_code: '14',
            },
            {
                name: 'Pursat Province',
                state_code: '15',
            },
            {
                name: 'Ratanakiri Province',
                state_code: '16',
            },
            {
                name: 'Siem Reap Province',
                state_code: '17',
            },
            {
                name: 'Sihanoukville Province',
                state_code: '18',
            },
            {
                name: 'Stung Treng Province',
                state_code: '19',
            },
            {
                name: 'Svay Rieng Province',
                state_code: '20',
            },
            {
                name: 'Takéo Province',
                state_code: '21',
            },
        ],
    },
    {
        name: 'Cameroon',
        iso3: 'CMR',
        iso2: 'CM',
        states: [
            {
                name: 'Adamawa',
                state_code: 'AD',
            },
            {
                name: 'Centre',
                state_code: 'CE',
            },
            {
                name: 'East',
                state_code: 'ES',
            },
            {
                name: 'Far North',
                state_code: 'EN',
            },
            {
                name: 'Littoral',
                state_code: 'LT',
            },
            {
                name: 'North',
                state_code: 'NO',
            },
            {
                name: 'Northwest',
                state_code: 'NW',
            },
            {
                name: 'South',
                state_code: 'SU',
            },
            {
                name: 'Southwest',
                state_code: 'SW',
            },
            {
                name: 'West',
                state_code: 'OU',
            },
        ],
    },
    {
        name: 'Canada',
        iso3: 'CAN',
        iso2: 'CA',
        states: [
            {
                name: 'Alberta',
                state_code: 'AB',
            },
            {
                name: 'British Columbia',
                state_code: 'BC',
            },
            {
                name: 'Manitoba',
                state_code: 'MB',
            },
            {
                name: 'New Brunswick',
                state_code: 'NB',
            },
            {
                name: 'Newfoundland and Labrador',
                state_code: 'NL',
            },
            {
                name: 'Northwest Territories',
                state_code: 'NT',
            },
            {
                name: 'Nova Scotia',
                state_code: 'NS',
            },
            {
                name: 'Nunavut',
                state_code: 'NU',
            },
            {
                name: 'Ontario',
                state_code: 'ON',
            },
            {
                name: 'Prince Edward Island',
                state_code: 'PE',
            },
            {
                name: 'Quebec',
                state_code: 'QC',
            },
            {
                name: 'Saskatchewan',
                state_code: 'SK',
            },
            {
                name: 'Yukon',
                state_code: 'YT',
            },
        ],
    },
    {
        name: 'Cape Verde',
        iso3: 'CPV',
        iso2: 'CV',
        states: [
            {
                name: 'Barlavento Islands',
                state_code: 'B',
            },
            {
                name: 'Boa Vista',
                state_code: 'BV',
            },
            {
                name: 'Brava',
                state_code: 'BR',
            },
            {
                name: 'Maio Municipality',
                state_code: 'MA',
            },
            {
                name: 'Mosteiros',
                state_code: 'MO',
            },
            {
                name: 'Paul',
                state_code: 'PA',
            },
            {
                name: 'Porto Novo',
                state_code: 'PN',
            },
            {
                name: 'Praia',
                state_code: 'PR',
            },
            {
                name: 'Ribeira Brava Municipality',
                state_code: 'RB',
            },
            {
                name: 'Ribeira Grande',
                state_code: 'RG',
            },
            {
                name: 'Ribeira Grande de Santiago',
                state_code: 'RS',
            },
            {
                name: 'Sal',
                state_code: 'SL',
            },
            {
                name: 'Santa Catarina',
                state_code: 'CA',
            },
            {
                name: 'Santa Catarina do Fogo',
                state_code: 'CF',
            },
            {
                name: 'Santa Cruz',
                state_code: 'CR',
            },
            {
                name: 'São Domingos',
                state_code: 'SD',
            },
            {
                name: 'São Filipe',
                state_code: 'SF',
            },
            {
                name: 'São Lourenço dos Órgãos',
                state_code: 'SO',
            },
            {
                name: 'São Miguel',
                state_code: 'SM',
            },
            {
                name: 'São Vicente',
                state_code: 'SV',
            },
            {
                name: 'Sotavento Islands',
                state_code: 'S',
            },
            {
                name: 'Tarrafal',
                state_code: 'TA',
            },
            {
                name: 'Tarrafal de São Nicolau',
                state_code: 'TS',
            },
        ],
    },
    {
        name: 'Cayman Islands',
        iso3: 'CYM',
        iso2: 'KY',
        states: [],
    },
    {
        name: 'Central African Republic',
        iso3: 'CAF',
        iso2: 'CF',
        states: [
            {
                name: 'Bamingui-Bangoran Prefecture',
                state_code: 'BB',
            },
            {
                name: 'Bangui',
                state_code: 'BGF',
            },
            {
                name: 'Basse-Kotto Prefecture',
                state_code: 'BK',
            },
            {
                name: 'Haut-Mbomou Prefecture',
                state_code: 'HM',
            },
            {
                name: 'Haute-Kotto Prefecture',
                state_code: 'HK',
            },
            {
                name: 'Kémo Prefecture',
                state_code: 'KG',
            },
            {
                name: 'Lobaye Prefecture',
                state_code: 'LB',
            },
            {
                name: 'Mambéré-Kadéï',
                state_code: 'HS',
            },
            {
                name: 'Mbomou Prefecture',
                state_code: 'MB',
            },
            {
                name: 'Nana-Grébizi Economic Prefecture',
                state_code: 'KB',
            },
            {
                name: 'Nana-Mambéré Prefecture',
                state_code: 'NM',
            },
            {
                name: "Ombella-M'Poko Prefecture",
                state_code: 'MP',
            },
            {
                name: 'Ouaka Prefecture',
                state_code: 'UK',
            },
            {
                name: 'Ouham Prefecture',
                state_code: 'AC',
            },
            {
                name: 'Ouham-Pendé Prefecture',
                state_code: 'OP',
            },
            {
                name: 'Sangha-Mbaéré',
                state_code: 'SE',
            },
            {
                name: 'Vakaga Prefecture',
                state_code: 'VK',
            },
        ],
    },
    {
        name: 'Chad',
        iso3: 'TCD',
        iso2: 'TD',
        states: [
            {
                name: 'Bahr el Gazel',
                state_code: 'BG',
            },
            {
                name: 'Batha Region',
                state_code: 'BA',
            },
            {
                name: 'Borkou',
                state_code: 'BO',
            },
            {
                name: 'Ennedi Region',
                state_code: 'EN',
            },
            {
                name: 'Ennedi-Est',
                state_code: 'EE',
            },
            {
                name: 'Ennedi-Ouest',
                state_code: 'EO',
            },
            {
                name: 'Guéra Region',
                state_code: 'GR',
            },
            {
                name: 'Hadjer-Lamis',
                state_code: 'HL',
            },
            {
                name: 'Kanem Region',
                state_code: 'KA',
            },
            {
                name: 'Lac Region',
                state_code: 'LC',
            },
            {
                name: 'Logone Occidental Region',
                state_code: 'LO',
            },
            {
                name: 'Logone Oriental Region',
                state_code: 'LR',
            },
            {
                name: 'Mandoul Region',
                state_code: 'MA',
            },
            {
                name: 'Mayo-Kebbi Est Region',
                state_code: 'ME',
            },
            {
                name: 'Mayo-Kebbi Ouest Region',
                state_code: 'MO',
            },
            {
                name: 'Moyen-Chari Region',
                state_code: 'MC',
            },
            {
                name: "N'Djamena",
                state_code: 'ND',
            },
            {
                name: 'Ouaddaï Region',
                state_code: 'OD',
            },
            {
                name: 'Salamat Region',
                state_code: 'SA',
            },
            {
                name: 'Sila Region',
                state_code: 'SI',
            },
            {
                name: 'Tandjilé Region',
                state_code: 'TA',
            },
            {
                name: 'Tibesti Region',
                state_code: 'TI',
            },
            {
                name: 'Wadi Fira Region',
                state_code: 'WF',
            },
        ],
    },
    {
        name: 'Chile',
        iso3: 'CHL',
        iso2: 'CL',
        states: [
            {
                name: 'Antofagasta Region',
                state_code: 'AN',
            },
            {
                name: 'Araucanía Region',
                state_code: 'AR',
            },
            {
                name: 'Arica y Parinacota Region',
                state_code: 'AP',
            },
            {
                name: 'Atacama Region',
                state_code: 'AT',
            },
            {
                name: 'Aysén Region',
                state_code: 'AI',
            },
            {
                name: 'Bío Bío Region',
                state_code: 'BI',
            },
            {
                name: 'Coquimbo Region',
                state_code: 'CO',
            },
            {
                name: 'Los Lagos Region',
                state_code: 'LL',
            },
            {
                name: 'Los Ríos Region',
                state_code: 'LR',
            },
            {
                name: 'Magellan and the Chilean Antarctic Region',
                state_code: 'MA',
            },
            {
                name: 'Maule Region',
                state_code: 'ML',
            },
            {
                name: 'Ñuble Region',
                state_code: 'NB',
            },
            {
                name: "O'Higgins",
                state_code: 'LI',
            },
            {
                name: 'Santiago Metropolitan Region',
                state_code: 'RM',
            },
            {
                name: 'Tarapacá Region',
                state_code: 'TA',
            },
            {
                name: 'Valparaíso',
                state_code: 'VS',
            },
        ],
    },
    {
        name: 'China',
        iso3: 'CHN',
        iso2: 'CN',
        states: [
            {
                name: 'Anhui',
                state_code: 'AH',
            },
            {
                name: 'Beijing',
                state_code: 'BJ',
            },
            {
                name: 'Chongqing',
                state_code: 'CQ',
            },
            {
                name: 'Fujian',
                state_code: 'FJ',
            },
            {
                name: 'Gansu',
                state_code: 'GS',
            },
            {
                name: 'Guangdong',
                state_code: 'GD',
            },
            {
                name: 'Guangxi Zhuang Autonomous Region',
                state_code: 'GX',
            },
            {
                name: 'Guizhou',
                state_code: 'GZ',
            },
            {
                name: 'Hainan',
                state_code: 'HI',
            },
            {
                name: 'Hebei',
                state_code: 'HE',
            },
            {
                name: 'Heilongjiang',
                state_code: 'HL',
            },
            {
                name: 'Henan',
                state_code: 'HA',
            },
            {
                name: 'Hong Kong',
                state_code: 'HK',
            },
            {
                name: 'Hubei',
                state_code: 'HB',
            },
            {
                name: 'Hunan',
                state_code: 'HN',
            },
            {
                name: 'Inner Mongolia',
                state_code: 'NM',
            },
            {
                name: 'Jiangsu',
                state_code: 'JS',
            },
            {
                name: 'Jiangxi',
                state_code: 'JX',
            },
            {
                name: 'Jilin',
                state_code: 'JL',
            },
            {
                name: 'Keelung',
                state_code: 'TW-KEE',
            },
            {
                name: 'Liaoning',
                state_code: 'LN',
            },
            {
                name: 'Macau',
                state_code: 'MO',
            },
            {
                name: 'Ningxia Hui Autonomous Region',
                state_code: 'NX',
            },
            {
                name: 'Qinghai',
                state_code: 'QH',
            },
            {
                name: 'Shaanxi',
                state_code: 'SN',
            },
            {
                name: 'Shandong',
                state_code: 'SD',
            },
            {
                name: 'Shanghai',
                state_code: 'SH',
            },
            {
                name: 'Shanxi',
                state_code: 'SX',
            },
            {
                name: 'Sichuan',
                state_code: 'SC',
            },
            {
                name: "Taiwan Province, People's Republic of China",
                state_code: 'TW',
            },
            {
                name: 'Tibet Autonomous Region',
                state_code: 'XZ',
            },
            {
                name: 'Xinjiang',
                state_code: 'XJ',
            },
            {
                name: 'Yunnan',
                state_code: 'YN',
            },
            {
                name: 'Zhejiang',
                state_code: 'ZJ',
            },
        ],
    },
    {
        name: 'Christmas Island',
        iso3: 'CXR',
        iso2: 'CX',
        states: [],
    },
    {
        name: 'Cocos (Keeling) Islands',
        iso3: 'CCK',
        iso2: 'CC',
        states: [],
    },
    {
        name: 'Colombia',
        iso3: 'COL',
        iso2: 'CO',
        states: [
            {
                name: 'Amazonas Department',
                state_code: 'AMA',
            },
            {
                name: 'Antioquia Department',
                state_code: 'ANT',
            },
            {
                name: 'Arauca Department',
                state_code: 'ARA',
            },
            {
                name: 'Archipelago of Saint Andréws, Providence and Saint Catalina',
                state_code: 'SAP',
            },
            {
                name: 'Atlántico Department',
                state_code: 'ATL',
            },
            {
                name: 'Bolívar Department',
                state_code: 'BOL',
            },
            {
                name: 'Boyacá Department',
                state_code: 'BOY',
            },
            {
                name: 'Caldas Department',
                state_code: 'CAL',
            },
            {
                name: 'Caquetá Department',
                state_code: 'CAQ',
            },
            {
                name: 'Casanare Department',
                state_code: 'CAS',
            },
            {
                name: 'Cauca Department',
                state_code: 'CAU',
            },
            {
                name: 'Cesar Department',
                state_code: 'CES',
            },
            {
                name: 'Chocó Department',
                state_code: 'CHO',
            },
            {
                name: 'Córdoba Department',
                state_code: 'COR',
            },
            {
                name: 'Cundinamarca Department',
                state_code: 'CUN',
            },
            {
                name: 'Guainía Department',
                state_code: 'GUA',
            },
            {
                name: 'Guaviare Department',
                state_code: 'GUV',
            },
            {
                name: 'Huila Department',
                state_code: 'HUI',
            },
            {
                name: 'La Guajira Department',
                state_code: 'LAG',
            },
            {
                name: 'Magdalena Department',
                state_code: 'MAG',
            },
            {
                name: 'Meta',
                state_code: 'MET',
            },
            {
                name: 'Nariño Department',
                state_code: 'NAR',
            },
            {
                name: 'Norte de Santander Department',
                state_code: 'NSA',
            },
            {
                name: 'Putumayo Department',
                state_code: 'PUT',
            },
            {
                name: 'Quindío Department',
                state_code: 'QUI',
            },
            {
                name: 'Risaralda Department',
                state_code: 'RIS',
            },
            {
                name: 'Santander Department',
                state_code: 'SAN',
            },
            {
                name: 'Sucre Department',
                state_code: 'SUC',
            },
            {
                name: 'Tolima Department',
                state_code: 'TOL',
            },
            {
                name: 'Valle del Cauca Department',
                state_code: 'VAC',
            },
            {
                name: 'Vaupés Department',
                state_code: 'VAU',
            },
            {
                name: 'Vichada Department',
                state_code: 'VID',
            },
        ],
    },
    {
        name: 'Comoros',
        iso3: 'COM',
        iso2: 'KM',
        states: [
            {
                name: 'Anjouan',
                state_code: 'A',
            },
            {
                name: 'Grande Comore',
                state_code: 'G',
            },
            {
                name: 'Mohéli',
                state_code: 'M',
            },
        ],
    },
    {
        name: 'Congo',
        iso3: 'COG',
        iso2: 'CG',
        states: [
            {
                name: 'Bouenza Department',
                state_code: '11',
            },
            {
                name: 'Brazzaville',
                state_code: 'BZV',
            },
            {
                name: 'Cuvette Department',
                state_code: '8',
            },
            {
                name: 'Cuvette-Ouest Department',
                state_code: '15',
            },
            {
                name: 'Kouilou Department',
                state_code: '5',
            },
            {
                name: 'Lékoumou Department',
                state_code: '2',
            },
            {
                name: 'Likouala Department',
                state_code: '7',
            },
            {
                name: 'Niari Department',
                state_code: '9',
            },
            {
                name: 'Plateaux Department',
                state_code: '14',
            },
            {
                name: 'Pointe-Noire',
                state_code: '16',
            },
            {
                name: 'Pool Department',
                state_code: '12',
            },
            {
                name: 'Sangha Department',
                state_code: '13',
            },
        ],
    },
    {
        name: 'Congo',
        iso3: 'COD',
        iso2: 'CD',
        states: [
            {
                name: 'Bandundu Province',
                state_code: 'BN',
            },
            {
                name: 'Bas-Congo province',
                state_code: 'BC',
            },
            {
                name: 'Bas-Uele',
                state_code: 'BU',
            },
            {
                name: 'Équateur',
                state_code: 'EQ',
            },
            {
                name: 'Haut-Katanga Province',
                state_code: 'HK',
            },
            {
                name: 'Haut-Lomami District',
                state_code: 'HL',
            },
            {
                name: 'Haut-Uele',
                state_code: 'HU',
            },
            {
                name: 'Ituri Interim Administration',
                state_code: 'IT',
            },
            {
                name: 'Kasaï District',
                state_code: 'KS',
            },
            {
                name: 'Kasaï-Occidental',
                state_code: 'KW',
            },
            {
                name: 'Kasaï-Oriental',
                state_code: 'KE',
            },
            {
                name: 'Katanga Province',
                state_code: 'KA',
            },
            {
                name: 'Kinshasa',
                state_code: 'KN',
            },
            {
                name: 'Kwango District',
                state_code: 'KG',
            },
            {
                name: 'Kwilu District',
                state_code: 'KL',
            },
            {
                name: 'Lomami Province',
                state_code: 'LO',
            },
            {
                name: 'Mai-Ndombe Province',
                state_code: 'MN',
            },
            {
                name: 'Maniema',
                state_code: 'MA',
            },
            {
                name: 'Mongala District',
                state_code: 'MO',
            },
            {
                name: 'Nord-Ubangi District',
                state_code: 'NU',
            },
            {
                name: 'North Kivu',
                state_code: 'NK',
            },
            {
                name: 'Orientale Province',
                state_code: 'OR',
            },
            {
                name: 'Sankuru District',
                state_code: 'SA',
            },
            {
                name: 'South Kivu',
                state_code: 'SK',
            },
            {
                name: 'Sud-Ubangi',
                state_code: 'SU',
            },
            {
                name: 'Tanganyika Province',
                state_code: 'TA',
            },
            {
                name: 'Tshopo District',
                state_code: 'TO',
            },
            {
                name: 'Tshuapa District',
                state_code: 'TU',
            },
        ],
    },
    {
        name: 'Cook Islands',
        iso3: 'COK',
        iso2: 'CK',
        states: [],
    },
    {
        name: 'Costa Rica',
        iso3: 'CRI',
        iso2: 'CR',
        states: [
            {
                name: 'Alajuela Province',
                state_code: 'A',
            },
            {
                name: 'Guanacaste Province',
                state_code: 'G',
            },
            {
                name: 'Heredia Province',
                state_code: 'H',
            },
            {
                name: 'Limón Province',
                state_code: 'L',
            },
            {
                name: 'Provincia de Cartago',
                state_code: 'C',
            },
            {
                name: 'Puntarenas Province',
                state_code: 'P',
            },
            {
                name: 'San José Province',
                state_code: 'SJ',
            },
        ],
    },
    {
        name: 'Ivory Coast',
        iso3: 'CIV',
        iso2: 'CI',
        states: [
            {
                name: 'Abidjan',
                state_code: 'AB',
            },
            {
                name: 'Agnéby',
                state_code: '16',
            },
            {
                name: 'Bafing Region',
                state_code: '17',
            },
            {
                name: 'Bas-Sassandra District',
                state_code: 'BS',
            },
            {
                name: 'Bas-Sassandra Region',
                state_code: '09',
            },
            {
                name: 'Comoé District',
                state_code: 'CM',
            },
            {
                name: 'Denguélé District',
                state_code: 'DN',
            },
            {
                name: 'Denguélé Region',
                state_code: '10',
            },
            {
                name: 'Dix-Huit Montagnes',
                state_code: '06',
            },
            {
                name: 'Fromager',
                state_code: '18',
            },
            {
                name: 'Gôh-Djiboua District',
                state_code: 'GD',
            },
            {
                name: 'Haut-Sassandra',
                state_code: '02',
            },
            {
                name: 'Lacs District',
                state_code: 'LC',
            },
            {
                name: 'Lacs Region',
                state_code: '07',
            },
            {
                name: 'Lagunes District',
                state_code: 'LG',
            },
            {
                name: 'Lagunes region',
                state_code: '01',
            },
            {
                name: 'Marahoué Region',
                state_code: '12',
            },
            {
                name: 'Montagnes District',
                state_code: 'MG',
            },
            {
                name: 'Moyen-Cavally',
                state_code: '19',
            },
            {
                name: 'Moyen-Comoé',
                state_code: '05',
            },
            {
                name: "N'zi-Comoé",
                state_code: '11',
            },
            {
                name: 'Sassandra-Marahoué District',
                state_code: 'SM',
            },
            {
                name: 'Savanes Region',
                state_code: '03',
            },
            {
                name: 'Sud-Bandama',
                state_code: '15',
            },
            {
                name: 'Sud-Comoé',
                state_code: '13',
            },
            {
                name: 'Vallée du Bandama District',
                state_code: 'VB',
            },
            {
                name: 'Vallée du Bandama Region',
                state_code: '04',
            },
            {
                name: 'Woroba District',
                state_code: 'WR',
            },
            {
                name: 'Worodougou',
                state_code: '14',
            },
            {
                name: 'Yamoussoukro',
                state_code: 'YM',
            },
            {
                name: 'Zanzan Region',
                state_code: 'ZZ',
            },
        ],
    },
    {
        name: 'Croatia (Hrvatska)',
        iso3: 'HRV',
        iso2: 'HR',
        states: [
            {
                name: 'Bjelovar-Bilogora County',
                state_code: '07',
            },
            {
                name: 'Brod-Posavina County',
                state_code: '12',
            },
            {
                name: 'Dubrovnik-Neretva County',
                state_code: '19',
            },
            {
                name: 'Istria County',
                state_code: '18',
            },
            {
                name: 'Koprivnica-Križevci County',
                state_code: '06',
            },
            {
                name: 'Krapina-Zagorje County',
                state_code: '02',
            },
            {
                name: 'Lika-Senj County',
                state_code: '09',
            },
            {
                name: 'Međimurje County',
                state_code: '20',
            },
            {
                name: 'Osijek-Baranja County',
                state_code: '14',
            },
            {
                name: 'Požega-Slavonia County',
                state_code: '11',
            },
            {
                name: 'Primorje-Gorski Kotar County',
                state_code: '08',
            },
            {
                name: 'Šibenik-Knin County',
                state_code: '15',
            },
            {
                name: 'Sisak-Moslavina County',
                state_code: '03',
            },
            {
                name: 'Split-Dalmatia County',
                state_code: '17',
            },
            {
                name: 'Varaždin County',
                state_code: '05',
            },
            {
                name: 'Virovitica-Podravina County',
                state_code: '10',
            },
            {
                name: 'Vukovar-Syrmia County',
                state_code: '16',
            },
            {
                name: 'Zadar County',
                state_code: '13',
            },
            {
                name: 'Zagreb',
                state_code: '21',
            },
            {
                name: 'Zagreb County',
                state_code: '01',
            },
        ],
    },
    {
        name: 'Cuba',
        iso3: 'CUB',
        iso2: 'CU',
        states: [
            {
                name: 'Artemisa Province',
                state_code: '15',
            },
            {
                name: 'Camagüey Province',
                state_code: '09',
            },
            {
                name: 'Ciego de Ávila Province',
                state_code: '08',
            },
            {
                name: 'Cienfuegos Province',
                state_code: '06',
            },
            {
                name: 'Granma Province',
                state_code: '12',
            },
            {
                name: 'Guantánamo Province',
                state_code: '14',
            },
            {
                name: 'Havana Province',
                state_code: '03',
            },
            {
                name: 'Holguín Province',
                state_code: '11',
            },
            {
                name: 'Isla de la Juventud',
                state_code: '99',
            },
            {
                name: 'Las Tunas Province',
                state_code: '10',
            },
            {
                name: 'Matanzas Province',
                state_code: '04',
            },
            {
                name: 'Mayabeque Province',
                state_code: '16',
            },
            {
                name: 'Pinar del Río Province',
                state_code: '01',
            },
            {
                name: 'Sancti Spíritus Province',
                state_code: '07',
            },
            {
                name: 'Santiago de Cuba Province',
                state_code: '13',
            },
            {
                name: 'Villa Clara Province',
                state_code: '05',
            },
        ],
    },
    {
        name: 'Cyprus',
        iso3: 'CYP',
        iso2: 'CY',
        states: [
            {
                name: 'Famagusta District',
                state_code: '04',
            },
            {
                name: 'Kyrenia District',
                state_code: '06',
            },
            {
                name: 'Larnaca District',
                state_code: '03',
            },
            {
                name: 'Limassol District',
                state_code: '02',
            },
            {
                name: 'Nicosia District',
                state_code: '01',
            },
            {
                name: 'Paphos District',
                state_code: '05',
            },
        ],
    },
    {
        name: 'Czech Republic',
        iso3: 'CZE',
        iso2: 'CZ',
        states: [
            {
                name: 'Benešov District',
                state_code: '201',
            },
            {
                name: 'Beroun District',
                state_code: '202',
            },
            {
                name: 'Blansko District',
                state_code: '641',
            },
            {
                name: 'Břeclav District',
                state_code: '644',
            },
            {
                name: 'Brno-City District',
                state_code: '642',
            },
            {
                name: 'Brno-Country District',
                state_code: '643',
            },
            {
                name: 'Bruntál District',
                state_code: '801',
            },
            {
                name: 'Central Bohemian Region',
                state_code: '20',
            },
            {
                name: 'Česká Lípa District',
                state_code: '511',
            },
            {
                name: 'České Budějovice District',
                state_code: '311',
            },
            {
                name: 'Český Krumlov District',
                state_code: '312',
            },
            {
                name: 'Cheb District',
                state_code: '411',
            },
            {
                name: 'Chomutov District',
                state_code: '422',
            },
            {
                name: 'Chrudim District',
                state_code: '531',
            },
            {
                name: 'Děčín District',
                state_code: '421',
            },
            {
                name: 'Domažlice District',
                state_code: '321',
            },
            {
                name: 'Frýdek-Místek District',
                state_code: '802',
            },
            {
                name: 'Havlíčkův Brod District',
                state_code: '631',
            },
            {
                name: 'Hodonín District',
                state_code: '645',
            },
            {
                name: 'Horní Počernice',
                state_code: '120',
            },
            {
                name: 'Hradec Králové District',
                state_code: '521',
            },
            {
                name: 'Hradec Králové Region',
                state_code: '52',
            },
            {
                name: 'Jablonec nad Nisou District',
                state_code: '512',
            },
            {
                name: 'Jeseník District',
                state_code: '711',
            },
            {
                name: 'Jičín District',
                state_code: '522',
            },
            {
                name: 'Jihlava District',
                state_code: '632',
            },
            {
                name: 'Jindřichův Hradec District',
                state_code: '313',
            },
            {
                name: 'Karlovy Vary District',
                state_code: '412',
            },
            {
                name: 'Karlovy Vary Region',
                state_code: '41',
            },
            {
                name: 'Karviná District',
                state_code: '803',
            },
            {
                name: 'Kladno District',
                state_code: '203',
            },
            {
                name: 'Klatovy District',
                state_code: '322',
            },
            {
                name: 'Kolín District',
                state_code: '204',
            },
            {
                name: 'Kroměříž District',
                state_code: '721',
            },
            {
                name: 'Liberec District',
                state_code: '513',
            },
            {
                name: 'Liberec Region',
                state_code: '51',
            },
            {
                name: 'Litoměřice District',
                state_code: '423',
            },
            {
                name: 'Louny District',
                state_code: '424',
            },
            {
                name: 'Mělník District',
                state_code: '206',
            },
            {
                name: 'Mladá Boleslav District',
                state_code: '207',
            },
            {
                name: 'Moravian-Silesian Region',
                state_code: '80',
            },
            {
                name: 'Most District',
                state_code: '425',
            },
            {
                name: 'Náchod District',
                state_code: '523',
            },
            {
                name: 'Nový Jičín District',
                state_code: '804',
            },
            {
                name: 'Nymburk District',
                state_code: '208',
            },
            {
                name: 'Olomouc District',
                state_code: '712',
            },
            {
                name: 'Olomouc Region',
                state_code: '71',
            },
            {
                name: 'Opava District',
                state_code: '805',
            },
            {
                name: 'Ostrava-City District',
                state_code: '806',
            },
            {
                name: 'Pardubice District',
                state_code: '532',
            },
            {
                name: 'Pardubice Region',
                state_code: '53',
            },
            {
                name: 'Pelhřimov District',
                state_code: '633',
            },
            {
                name: 'Písek District',
                state_code: '314',
            },
            {
                name: 'Plzeň Region',
                state_code: '32',
            },
            {
                name: 'Plzeň-City District',
                state_code: '323',
            },
            {
                name: 'Plzeň-North District',
                state_code: '325',
            },
            {
                name: 'Plzeň-South District',
                state_code: '324',
            },
            {
                name: 'Prachatice District',
                state_code: '315',
            },
            {
                name: 'Prague',
                state_code: '10',
            },
            {
                name: 'Prague 1',
                state_code: '101',
            },
            {
                name: 'Prague 10',
                state_code: '110',
            },
            {
                name: 'Prague 11',
                state_code: '111',
            },
            {
                name: 'Prague 12',
                state_code: '112',
            },
            {
                name: 'Prague 13',
                state_code: '113',
            },
            {
                name: 'Prague 14',
                state_code: '114',
            },
            {
                name: 'Prague 15',
                state_code: '115',
            },
            {
                name: 'Prague 16',
                state_code: '116',
            },
            {
                name: 'Prague 2',
                state_code: '102',
            },
            {
                name: 'Prague 21',
                state_code: '121',
            },
            {
                name: 'Prague 3',
                state_code: '103',
            },
            {
                name: 'Prague 4',
                state_code: '104',
            },
            {
                name: 'Prague 5',
                state_code: '105',
            },
            {
                name: 'Prague 6',
                state_code: '106',
            },
            {
                name: 'Prague 7',
                state_code: '107',
            },
            {
                name: 'Prague 8',
                state_code: '108',
            },
            {
                name: 'Prague 9',
                state_code: '109',
            },
            {
                name: 'Prague-East District',
                state_code: '209',
            },
            {
                name: 'Prague-West District',
                state_code: '20A',
            },
            {
                name: 'Přerov District',
                state_code: '714',
            },
            {
                name: 'Příbram District',
                state_code: '20B',
            },
            {
                name: 'Prostějov District',
                state_code: '713',
            },
            {
                name: 'Rakovník District',
                state_code: '20C',
            },
            {
                name: 'Rokycany District',
                state_code: '326',
            },
            {
                name: 'Rychnov nad Kněžnou District',
                state_code: '524',
            },
            {
                name: 'Semily District',
                state_code: '514',
            },
            {
                name: 'Sokolov District',
                state_code: '413',
            },
            {
                name: 'South Bohemian Region',
                state_code: '31',
            },
            {
                name: 'South Moravian Region',
                state_code: '64',
            },
            {
                name: 'Strakonice District',
                state_code: '316',
            },
            {
                name: 'Šumperk District',
                state_code: '715',
            },
            {
                name: 'Svitavy District',
                state_code: '533',
            },
            {
                name: 'Tábor District',
                state_code: '317',
            },
            {
                name: 'Tachov District',
                state_code: '327',
            },
            {
                name: 'Teplice District',
                state_code: '426',
            },
            {
                name: 'Třebíč District',
                state_code: '634',
            },
            {
                name: 'Trutnov District',
                state_code: '525',
            },
            {
                name: 'Uherské Hradiště District',
                state_code: '722',
            },
            {
                name: 'Ústí nad Labem District',
                state_code: '427',
            },
            {
                name: 'Ústí nad Labem Region',
                state_code: '42',
            },
            {
                name: 'Ústí nad Orlicí District',
                state_code: '534',
            },
            {
                name: 'Vsetín District',
                state_code: '723',
            },
            {
                name: 'Vyškov District',
                state_code: '646',
            },
            {
                name: 'Vysočina Region',
                state_code: '63',
            },
            {
                name: 'Žďár nad Sázavou District',
                state_code: '635',
            },
            {
                name: 'Zlín District',
                state_code: '724',
            },
            {
                name: 'Zlín Region',
                state_code: '72',
            },
            {
                name: 'Znojmo District',
                state_code: '647',
            },
        ],
    },
    {
        name: 'Denmark',
        iso3: 'DNK',
        iso2: 'DK',
        states: [
            {
                name: 'Capital Region of Denmark',
                state_code: '84',
            },
            {
                name: 'Central Denmark Region',
                state_code: '82',
            },
            {
                name: 'North Denmark Region',
                state_code: '81',
            },
            {
                name: 'Region of Southern Denmark',
                state_code: '83',
            },
            {
                name: 'Region Zealand',
                state_code: '85',
            },
        ],
    },
    {
        name: 'Djibouti',
        iso3: 'DJI',
        iso2: 'DJ',
        states: [
            {
                name: 'Ali Sabieh Region',
                state_code: 'AS',
            },
            {
                name: 'Arta Region',
                state_code: 'AR',
            },
            {
                name: 'Dikhil Region',
                state_code: 'DI',
            },
            {
                name: 'Djibouti',
                state_code: 'DJ',
            },
            {
                name: 'Obock Region',
                state_code: 'OB',
            },
            {
                name: 'Tadjourah Region',
                state_code: 'TA',
            },
        ],
    },
    {
        name: 'Dominica',
        iso3: 'DMA',
        iso2: 'DM',
        states: [
            {
                name: 'Saint Andrew Parish',
                state_code: '02',
            },
            {
                name: 'Saint David Parish',
                state_code: '03',
            },
            {
                name: 'Saint George Parish',
                state_code: '04',
            },
            {
                name: 'Saint John Parish',
                state_code: '05',
            },
            {
                name: 'Saint Joseph Parish',
                state_code: '06',
            },
            {
                name: 'Saint Luke Parish',
                state_code: '07',
            },
            {
                name: 'Saint Mark Parish',
                state_code: '08',
            },
            {
                name: 'Saint Patrick Parish',
                state_code: '09',
            },
            {
                name: 'Saint Paul Parish',
                state_code: '10',
            },
            {
                name: 'Saint Peter Parish',
                state_code: '11',
            },
        ],
    },
    {
        name: 'Dominican Republic',
        iso3: 'DOM',
        iso2: 'DO',
        states: [
            {
                name: 'Azua Province',
                state_code: '02',
            },
            {
                name: 'Baoruco Province',
                state_code: '03',
            },
            {
                name: 'Barahona Province',
                state_code: '04',
            },
            {
                name: 'Dajabón Province',
                state_code: '05',
            },
            {
                name: 'Distrito Nacional',
                state_code: '01',
            },
            {
                name: 'Duarte Province',
                state_code: '06',
            },
            {
                name: 'El Seibo Province',
                state_code: '08',
            },
            {
                name: 'Espaillat Province',
                state_code: '09',
            },
            {
                name: 'Hato Mayor Province',
                state_code: '30',
            },
            {
                name: 'Hermanas Mirabal Province',
                state_code: '19',
            },
            {
                name: 'Independencia',
                state_code: '10',
            },
            {
                name: 'La Altagracia Province',
                state_code: '11',
            },
            {
                name: 'La Romana Province',
                state_code: '12',
            },
            {
                name: 'La Vega Province',
                state_code: '13',
            },
            {
                name: 'María Trinidad Sánchez Province',
                state_code: '14',
            },
            {
                name: 'Monseñor Nouel Province',
                state_code: '28',
            },
            {
                name: 'Monte Cristi Province',
                state_code: '15',
            },
            {
                name: 'Monte Plata Province',
                state_code: '29',
            },
            {
                name: 'Pedernales Province',
                state_code: '16',
            },
            {
                name: 'Peravia Province',
                state_code: '17',
            },
            {
                name: 'Puerto Plata Province',
                state_code: '18',
            },
            {
                name: 'Samaná Province',
                state_code: '20',
            },
            {
                name: 'San Cristóbal Province',
                state_code: '21',
            },
            {
                name: 'San José de Ocoa Province',
                state_code: '31',
            },
            {
                name: 'San Juan Province',
                state_code: '22',
            },
            {
                name: 'San Pedro de Macorís',
                state_code: '23',
            },
            {
                name: 'Sánchez Ramírez Province',
                state_code: '24',
            },
            {
                name: 'Santiago Province',
                state_code: '25',
            },
            {
                name: 'Santiago Rodríguez Province',
                state_code: '26',
            },
            {
                name: 'Santo Domingo Province',
                state_code: '32',
            },
            {
                name: 'Valverde Province',
                state_code: '27',
            },
        ],
    },
    {
        name: 'Timor-Leste',
        iso3: 'TLS',
        iso2: 'TL',
        states: [
            {
                name: 'Aileu municipality',
                state_code: 'AL',
            },
            {
                name: 'Ainaro Municipality',
                state_code: 'AN',
            },
            {
                name: 'Baucau Municipality',
                state_code: 'BA',
            },
            {
                name: 'Bobonaro Municipality',
                state_code: 'BO',
            },
            {
                name: 'Cova Lima Municipality',
                state_code: 'CO',
            },
            {
                name: 'Dili municipality',
                state_code: 'DI',
            },
            {
                name: 'Ermera District',
                state_code: 'ER',
            },
            {
                name: 'Lautém Municipality',
                state_code: 'LA',
            },
            {
                name: 'Liquiçá Municipality',
                state_code: 'LI',
            },
            {
                name: 'Manatuto District',
                state_code: 'MT',
            },
            {
                name: 'Manufahi Municipality',
                state_code: 'MF',
            },
            {
                name: 'Viqueque Municipality',
                state_code: 'VI',
            },
        ],
    },
    {
        name: 'Ecuador',
        iso3: 'ECU',
        iso2: 'EC',
        states: [
            {
                name: 'Azuay Province',
                state_code: 'A',
            },
            {
                name: 'Bolívar Province',
                state_code: 'B',
            },
            {
                name: 'Cañar Province',
                state_code: 'F',
            },
            {
                name: 'Carchi Province',
                state_code: 'C',
            },
            {
                name: 'Chimborazo Province',
                state_code: 'H',
            },
            {
                name: 'Cotopaxi Province',
                state_code: 'X',
            },
            {
                name: 'El Oro Province',
                state_code: 'O',
            },
            {
                name: 'Esmeraldas',
                state_code: 'E',
            },
            {
                name: 'Galápagos Province',
                state_code: 'W',
            },
            {
                name: 'Guayas Province',
                state_code: 'G',
            },
            {
                name: 'Imbabura Province',
                state_code: 'I',
            },
            {
                name: 'Los Ríos Province',
                state_code: 'R',
            },
            {
                name: 'Manabí Province',
                state_code: 'M',
            },
            {
                name: 'Morona-Santiago Province',
                state_code: 'S',
            },
            {
                name: 'Napo Province',
                state_code: 'N',
            },
            {
                name: 'Orellana Province',
                state_code: 'D',
            },
            {
                name: 'Pastaza Province',
                state_code: 'Y',
            },
            {
                name: 'Pichincha Province',
                state_code: 'P',
            },
            {
                name: 'Santa Elena Province',
                state_code: 'SE',
            },
            {
                name: 'Santo Domingo de los Tsáchilas Province',
                state_code: 'SD',
            },
            {
                name: 'Sucumbíos Province',
                state_code: 'U',
            },
            {
                name: 'Tungurahua Province',
                state_code: 'T',
            },
            {
                name: 'Zamora-Chinchipe Province',
                state_code: 'Z',
            },
        ],
    },
    {
        name: 'Egypt',
        iso3: 'EGY',
        iso2: 'EG',
        states: [
            {
                name: 'Alexandria Governorate',
                state_code: 'ALX',
            },
            {
                name: 'Aswan Governorate',
                state_code: 'ASN',
            },
            {
                name: 'Asyut Governorate',
                state_code: 'AST',
            },
            {
                name: 'Beheira Governorate',
                state_code: 'BH',
            },
            {
                name: 'Beni Suef Governorate',
                state_code: 'BNS',
            },
            {
                name: 'Cairo Governorate',
                state_code: 'C',
            },
            {
                name: 'Dakahlia Governorate',
                state_code: 'DK',
            },
            {
                name: 'Damietta Governorate',
                state_code: 'DT',
            },
            {
                name: 'Faiyum Governorate',
                state_code: 'FYM',
            },
            {
                name: 'Gharbia Governorate',
                state_code: 'GH',
            },
            {
                name: 'Giza Governorate',
                state_code: 'GZ',
            },
            {
                name: 'Ismailia Governorate',
                state_code: 'IS',
            },
            {
                name: 'Kafr el-Sheikh Governorate',
                state_code: 'KFS',
            },
            {
                name: 'Luxor Governorate',
                state_code: 'LX',
            },
            {
                name: 'Matrouh Governorate',
                state_code: 'MT',
            },
            {
                name: 'Minya Governorate',
                state_code: 'MN',
            },
            {
                name: 'Monufia Governorate',
                state_code: 'MNF',
            },
            {
                name: 'New Valley Governorate',
                state_code: 'WAD',
            },
            {
                name: 'North Sinai Governorate',
                state_code: 'SIN',
            },
            {
                name: 'Port Said Governorate',
                state_code: 'PTS',
            },
            {
                name: 'Qalyubia Governorate',
                state_code: 'KB',
            },
            {
                name: 'Qena Governorate',
                state_code: 'KN',
            },
            {
                name: 'Red Sea Governorate',
                state_code: 'BA',
            },
            {
                name: 'Sohag Governorate',
                state_code: 'SHG',
            },
            {
                name: 'South Sinai Governorate',
                state_code: 'JS',
            },
            {
                name: 'Suez Governorate',
                state_code: 'SUZ',
            },
        ],
    },
    {
        name: 'El Salvador',
        iso3: 'SLV',
        iso2: 'SV',
        states: [
            {
                name: 'Ahuachapán Department',
                state_code: 'AH',
            },
            {
                name: 'Cabañas Department',
                state_code: 'CA',
            },
            {
                name: 'Chalatenango Department',
                state_code: 'CH',
            },
            {
                name: 'Cuscatlán Department',
                state_code: 'CU',
            },
            {
                name: 'La Libertad Department',
                state_code: 'LI',
            },
            {
                name: 'La Paz Department',
                state_code: 'PA',
            },
            {
                name: 'La Unión Department',
                state_code: 'UN',
            },
            {
                name: 'Morazán Department',
                state_code: 'MO',
            },
            {
                name: 'San Miguel Department',
                state_code: 'SM',
            },
            {
                name: 'San Salvador Department',
                state_code: 'SS',
            },
            {
                name: 'San Vicente Department',
                state_code: 'SV',
            },
            {
                name: 'Santa Ana Department',
                state_code: 'SA',
            },
            {
                name: 'Sonsonate Department',
                state_code: 'SO',
            },
            {
                name: 'Usulután Department',
                state_code: 'US',
            },
        ],
    },
    {
        name: 'Equatorial Guinea',
        iso3: 'GNQ',
        iso2: 'GQ',
        states: [
            {
                name: 'Annobón Province',
                state_code: 'AN',
            },
            {
                name: 'Bioko Norte Province',
                state_code: 'BN',
            },
            {
                name: 'Bioko Sur Province',
                state_code: 'BS',
            },
            {
                name: 'Centro Sur Province',
                state_code: 'CS',
            },
            {
                name: 'Insular Region',
                state_code: 'I',
            },
            {
                name: 'Kié-Ntem Province',
                state_code: 'KN',
            },
            {
                name: 'Litoral Province',
                state_code: 'LI',
            },
            {
                name: 'Río Muni',
                state_code: 'C',
            },
            {
                name: 'Wele-Nzas Province',
                state_code: 'WN',
            },
        ],
    },
    {
        name: 'Eritrea',
        iso3: 'ERI',
        iso2: 'ER',
        states: [
            {
                name: 'Anseba Region',
                state_code: 'AN',
            },
            {
                name: 'Debub Region',
                state_code: 'DU',
            },
            {
                name: 'Gash-Barka Region',
                state_code: 'GB',
            },
            {
                name: 'Maekel Region',
                state_code: 'MA',
            },
            {
                name: 'Northern Red Sea Region',
                state_code: 'SK',
            },
            {
                name: 'Southern Red Sea Region',
                state_code: 'DK',
            },
        ],
    },
    {
        name: 'Estonia',
        iso3: 'EST',
        iso2: 'EE',
        states: [
            {
                name: 'Harju County',
                state_code: '37',
            },
            {
                name: 'Hiiu County',
                state_code: '39',
            },
            {
                name: 'Ida-Viru County',
                state_code: '44',
            },
            {
                name: 'Järva County',
                state_code: '51',
            },
            {
                name: 'Jõgeva County',
                state_code: '49',
            },
            {
                name: 'Lääne County',
                state_code: '57',
            },
            {
                name: 'Lääne-Viru County',
                state_code: '59',
            },
            {
                name: 'Pärnu County',
                state_code: '67',
            },
            {
                name: 'Põlva County',
                state_code: '65',
            },
            {
                name: 'Rapla County',
                state_code: '70',
            },
            {
                name: 'Saare County',
                state_code: '74',
            },
            {
                name: 'Tartu County',
                state_code: '78',
            },
            {
                name: 'Valga County',
                state_code: '82',
            },
            {
                name: 'Viljandi County',
                state_code: '84',
            },
            {
                name: 'Võru County',
                state_code: '86',
            },
        ],
    },
    {
        name: 'Ethiopia',
        iso3: 'ETH',
        iso2: 'ET',
        states: [
            {
                name: 'Addis Ababa',
                state_code: 'AA',
            },
            {
                name: 'Afar Region',
                state_code: 'AF',
            },
            {
                name: 'Amhara Region',
                state_code: 'AM',
            },
            {
                name: 'Benishangul-Gumuz Region',
                state_code: 'BE',
            },
            {
                name: 'Dire Dawa',
                state_code: 'DD',
            },
            {
                name: 'Gambela Region',
                state_code: 'GA',
            },
            {
                name: 'Harari Region',
                state_code: 'HA',
            },
            {
                name: 'Oromia Region',
                state_code: 'OR',
            },
            {
                name: 'Somali Region',
                state_code: 'SO',
            },
            {
                name: "Southern Nations, Nationalities, and Peoples' Region",
                state_code: 'SN',
            },
            {
                name: 'Tigray Region',
                state_code: 'TI',
            },
        ],
    },
    {
        name: 'Falkland Islands',
        iso3: 'FLK',
        iso2: 'FK',
        states: [],
    },
    {
        name: 'Faroe Islands',
        iso3: 'FRO',
        iso2: 'FO',
        states: [],
    },
    {
        name: 'Fiji Islands',
        iso3: 'FJI',
        iso2: 'FJ',
        states: [
            {
                name: 'Ba',
                state_code: '01',
            },
            {
                name: 'Bua',
                state_code: '02',
            },
            {
                name: 'Cakaudrove',
                state_code: '03',
            },
            {
                name: 'Central Division',
                state_code: 'C',
            },
            {
                name: 'Eastern Division',
                state_code: 'E',
            },
            {
                name: 'Kadavu',
                state_code: '04',
            },
            {
                name: 'Lau',
                state_code: '05',
            },
            {
                name: 'Lomaiviti',
                state_code: '06',
            },
            {
                name: 'Macuata',
                state_code: '07',
            },
            {
                name: 'Nadroga-Navosa',
                state_code: '08',
            },
            {
                name: 'Naitasiri',
                state_code: '09',
            },
            {
                name: 'Namosi',
                state_code: '10',
            },
            {
                name: 'Northern Division',
                state_code: 'N',
            },
            {
                name: 'Ra',
                state_code: '11',
            },
            {
                name: 'Rewa',
                state_code: '12',
            },
            {
                name: 'Rotuma',
                state_code: 'R',
            },
            {
                name: 'Serua',
                state_code: '13',
            },
            {
                name: 'Tailevu',
                state_code: '14',
            },
            {
                name: 'Western Division',
                state_code: 'W',
            },
        ],
    },
    {
        name: 'Finland',
        iso3: 'FIN',
        iso2: 'FI',
        states: [
            {
                name: 'Åland Islands',
                state_code: '01',
            },
            {
                name: 'Central Finland',
                state_code: '08',
            },
            {
                name: 'Central Ostrobothnia',
                state_code: '07',
            },
            {
                name: 'Eastern Finland Province',
                state_code: 'IS',
            },
            {
                name: 'Finland Proper',
                state_code: '19',
            },
            {
                name: 'Kainuu',
                state_code: '05',
            },
            {
                name: 'Kymenlaakso',
                state_code: '09',
            },
            {
                name: 'Lapland',
                state_code: 'LL',
            },
            {
                name: 'North Karelia',
                state_code: '13',
            },
            {
                name: 'Northern Ostrobothnia',
                state_code: '14',
            },
            {
                name: 'Northern Savonia',
                state_code: '15',
            },
            {
                name: 'Ostrobothnia',
                state_code: '12',
            },
            {
                name: 'Oulu Province',
                state_code: 'OL',
            },
            {
                name: 'Päijänne Tavastia',
                state_code: '16',
            },
            {
                name: 'Pirkanmaa',
                state_code: '11',
            },
            {
                name: 'Satakunta',
                state_code: '17',
            },
            {
                name: 'South Karelia',
                state_code: '02',
            },
            {
                name: 'Southern Ostrobothnia',
                state_code: '03',
            },
            {
                name: 'Southern Savonia',
                state_code: '04',
            },
            {
                name: 'Tavastia Proper',
                state_code: '06',
            },
            {
                name: 'Uusimaa',
                state_code: '18',
            },
        ],
    },
    {
        name: 'France',
        iso3: 'FRA',
        iso2: 'FR',
        states: [
            {
                name: 'Alo',
                state_code: 'WF-AL',
            },
            {
                name: 'Alsace',
                state_code: 'A',
            },
            {
                name: 'Aquitaine',
                state_code: 'B',
            },
            {
                name: 'Auvergne',
                state_code: 'C',
            },
            {
                name: 'Auvergne-Rhône-Alpes',
                state_code: 'ARA',
            },
            {
                name: 'Bourgogne-Franche-Comté',
                state_code: 'BFC',
            },
            {
                name: 'Brittany',
                state_code: 'BRE',
            },
            {
                name: 'Burgundy',
                state_code: 'D',
            },
            {
                name: 'Centre-Val de Loire',
                state_code: 'CVL',
            },
            {
                name: 'Champagne-Ardenne',
                state_code: 'G',
            },
            {
                name: 'Corsica',
                state_code: 'COR',
            },
            {
                name: 'Franche-Comté',
                state_code: 'I',
            },
            {
                name: 'French Guiana',
                state_code: 'GF',
            },
            {
                name: 'French Polynesia',
                state_code: 'PF',
            },
            {
                name: 'Grand Est',
                state_code: 'GES',
            },
            {
                name: 'Guadeloupe',
                state_code: 'GP',
            },
            {
                name: 'Hauts-de-France',
                state_code: 'HDF',
            },
            {
                name: 'Île-de-France',
                state_code: 'IDF',
            },
            {
                name: 'Languedoc-Roussillon',
                state_code: 'K',
            },
            {
                name: 'Limousin',
                state_code: 'L',
            },
            {
                name: 'Lorraine',
                state_code: 'M',
            },
            {
                name: 'Lower Normandy',
                state_code: 'P',
            },
            {
                name: 'Martinique',
                state_code: 'MQ',
            },
            {
                name: 'Mayotte',
                state_code: 'YT',
            },
            {
                name: 'Nord-Pas-de-Calais',
                state_code: 'O',
            },
            {
                name: 'Normandy',
                state_code: 'NOR',
            },
            {
                name: 'Nouvelle-Aquitaine',
                state_code: 'NAQ',
            },
            {
                name: 'Occitania',
                state_code: 'OCC',
            },
            {
                name: 'Paris',
                state_code: '75',
            },
            {
                name: 'Pays de la Loire',
                state_code: 'PDL',
            },
            {
                name: 'Picardy',
                state_code: 'S',
            },
            {
                name: 'Poitou-Charentes',
                state_code: 'T',
            },
            {
                name: "Provence-Alpes-Côte d'Azur",
                state_code: 'PAC',
            },
            {
                name: 'Réunion',
                state_code: 'RE',
            },
            {
                name: 'Rhône-Alpes',
                state_code: 'V',
            },
            {
                name: 'Saint Barthélemy',
                state_code: 'BL',
            },
            {
                name: 'Saint Martin',
                state_code: 'MF',
            },
            {
                name: 'Saint Pierre and Miquelon',
                state_code: 'PM',
            },
            {
                name: 'Sigave',
                state_code: 'WF-SG',
            },
            {
                name: 'Upper Normandy',
                state_code: 'Q',
            },
            {
                name: 'Uvea',
                state_code: 'WF-UV',
            },
            {
                name: 'Wallis and Futuna',
                state_code: 'WF',
            },
        ],
    },
    {
        name: 'French Polynesia',
        iso3: 'PYF',
        iso2: 'PF',
        states: [],
    },
    {
        name: 'French Southern Territories',
        iso3: 'ATF',
        iso2: 'TF',
        states: [],
    },
    {
        name: 'Gabon',
        iso3: 'GAB',
        iso2: 'GA',
        states: [
            {
                name: 'Estuaire Province',
                state_code: '1',
            },
            {
                name: 'Haut-Ogooué Province',
                state_code: '2',
            },
            {
                name: 'Moyen-Ogooué Province',
                state_code: '3',
            },
            {
                name: 'Ngounié Province',
                state_code: '4',
            },
            {
                name: 'Nyanga Province',
                state_code: '5',
            },
            {
                name: 'Ogooué-Ivindo Province',
                state_code: '6',
            },
            {
                name: 'Ogooué-Lolo Province',
                state_code: '7',
            },
            {
                name: 'Ogooué-Maritime Province',
                state_code: '8',
            },
            {
                name: 'Woleu-Ntem Province',
                state_code: '9',
            },
        ],
    },
    {
        name: 'Gambia The',
        iso3: 'GMB',
        iso2: 'GM',
        states: [
            {
                name: 'Banjul',
                state_code: 'B',
            },
            {
                name: 'Central River Division',
                state_code: 'M',
            },
            {
                name: 'Lower River Division',
                state_code: 'L',
            },
            {
                name: 'North Bank Division',
                state_code: 'N',
            },
            {
                name: 'Upper River Division',
                state_code: 'U',
            },
            {
                name: 'West Coast Division',
                state_code: 'W',
            },
        ],
    },
    {
        name: 'Georgia',
        iso3: 'GEO',
        iso2: 'GE',
        states: [
            {
                name: 'Adjara',
                state_code: 'AJ',
            },
            {
                name: 'Autonomous Republic of Abkhazia',
                state_code: 'AB',
            },
            {
                name: 'Guria',
                state_code: 'GU',
            },
            {
                name: 'Imereti',
                state_code: 'IM',
            },
            {
                name: 'Kakheti',
                state_code: 'KA',
            },
            {
                name: 'Khelvachauri Municipality',
                state_code: '29',
            },
            {
                name: 'Kvemo Kartli',
                state_code: 'KK',
            },
            {
                name: 'Mtskheta-Mtianeti',
                state_code: 'MM',
            },
            {
                name: 'Racha-Lechkhumi and Kvemo Svaneti',
                state_code: 'RL',
            },
            {
                name: 'Samegrelo-Zemo Svaneti',
                state_code: 'SZ',
            },
            {
                name: 'Samtskhe-Javakheti',
                state_code: 'SJ',
            },
            {
                name: 'Senaki Municipality',
                state_code: '50',
            },
            {
                name: 'Shida Kartli',
                state_code: 'SK',
            },
            {
                name: 'Tbilisi',
                state_code: 'TB',
            },
        ],
    },
    {
        name: 'Germany',
        iso3: 'DEU',
        iso2: 'DE',
        states: [
            {
                name: 'Baden-Württemberg',
                state_code: 'BW',
            },
            {
                name: 'Bavaria',
                state_code: 'BY',
            },
            {
                name: 'Berlin',
                state_code: 'BE',
            },
            {
                name: 'Brandenburg',
                state_code: 'BB',
            },
            {
                name: 'Bremen',
                state_code: 'HB',
            },
            {
                name: 'Hamburg',
                state_code: 'HH',
            },
            {
                name: 'Hesse',
                state_code: 'HE',
            },
            {
                name: 'Lower Saxony',
                state_code: 'NI',
            },
            {
                name: 'Mecklenburg-Vorpommern',
                state_code: 'MV',
            },
            {
                name: 'North Rhine-Westphalia',
                state_code: 'NW',
            },
            {
                name: 'Rhineland-Palatinate',
                state_code: 'RP',
            },
            {
                name: 'Saarland',
                state_code: 'SL',
            },
            {
                name: 'Saxony',
                state_code: 'SN',
            },
            {
                name: 'Saxony-Anhalt',
                state_code: 'ST',
            },
            {
                name: 'Schleswig-Holstein',
                state_code: 'SH',
            },
            {
                name: 'Thuringia',
                state_code: 'TH',
            },
        ],
    },
    {
        name: 'Ghana',
        iso3: 'GHA',
        iso2: 'GH',
        states: [
            {
                name: 'Ashanti Region',
                state_code: 'AH',
            },
            {
                name: 'Brong-Ahafo Region',
                state_code: 'BA',
            },
            {
                name: 'Central Region',
                state_code: 'CP',
            },
            {
                name: 'Eastern Region',
                state_code: 'EP',
            },
            {
                name: 'Greater Accra Region',
                state_code: 'AA',
            },
            {
                name: 'Northern Region',
                state_code: 'NP',
            },
            {
                name: 'Upper East Region',
                state_code: 'UE',
            },
            {
                name: 'Upper West Region',
                state_code: 'UW',
            },
            {
                name: 'Volta Region',
                state_code: 'TV',
            },
            {
                name: 'Western Region',
                state_code: 'WP',
            },
        ],
    },
    {
        name: 'Gibraltar',
        iso3: 'GIB',
        iso2: 'GI',
        states: [],
    },
    {
        name: 'Greece',
        iso3: 'GRC',
        iso2: 'GR',
        states: [
            {
                name: 'Achaea Regional Unit',
                state_code: '13',
            },
            {
                name: 'Aetolia-Acarnania Regional Unit',
                state_code: '01',
            },
            {
                name: 'Arcadia Prefecture',
                state_code: '12',
            },
            {
                name: 'Argolis Regional Unit',
                state_code: '11',
            },
            {
                name: 'Attica Region',
                state_code: 'I',
            },
            {
                name: 'Boeotia Regional Unit',
                state_code: '03',
            },
            {
                name: 'Central Greece Region',
                state_code: 'H',
            },
            {
                name: 'Central Macedonia',
                state_code: 'B',
            },
            {
                name: 'Chania Regional Unit',
                state_code: '94',
            },
            {
                name: 'Corfu Prefecture',
                state_code: '22',
            },
            {
                name: 'Corinthia Regional Unit',
                state_code: '15',
            },
            {
                name: 'Crete Region',
                state_code: 'M',
            },
            {
                name: 'Drama Regional Unit',
                state_code: '52',
            },
            {
                name: 'East Attica Regional Unit',
                state_code: 'A2',
            },
            {
                name: 'East Macedonia and Thrace',
                state_code: 'A',
            },
            {
                name: 'Epirus Region',
                state_code: 'D',
            },
            {
                name: 'Euboea',
                state_code: '04',
            },
            {
                name: 'Grevena Prefecture',
                state_code: '51',
            },
            {
                name: 'Imathia Regional Unit',
                state_code: '53',
            },
            {
                name: 'Ioannina Regional Unit',
                state_code: '33',
            },
            {
                name: 'Ionian Islands Region',
                state_code: 'F',
            },
            {
                name: 'Karditsa Regional Unit',
                state_code: '41',
            },
            {
                name: 'Kastoria Regional Unit',
                state_code: '56',
            },
            {
                name: 'Kefalonia Prefecture',
                state_code: '23',
            },
            {
                name: 'Kilkis Regional Unit',
                state_code: '57',
            },
            {
                name: 'Kozani Prefecture',
                state_code: '58',
            },
            {
                name: 'Laconia',
                state_code: '16',
            },
            {
                name: 'Larissa Prefecture',
                state_code: '42',
            },
            {
                name: 'Lefkada Regional Unit',
                state_code: '24',
            },
            {
                name: 'Pella Regional Unit',
                state_code: '59',
            },
            {
                name: 'Peloponnese Region',
                state_code: 'J',
            },
            {
                name: 'Phthiotis Prefecture',
                state_code: '06',
            },
            {
                name: 'Preveza Prefecture',
                state_code: '34',
            },
            {
                name: 'Serres Prefecture',
                state_code: '62',
            },
            {
                name: 'South Aegean',
                state_code: 'L',
            },
            {
                name: 'Thessaloniki Regional Unit',
                state_code: '54',
            },
            {
                name: 'West Greece Region',
                state_code: 'G',
            },
            {
                name: 'West Macedonia Region',
                state_code: 'C',
            },
        ],
    },
    {
        name: 'Greenland',
        iso3: 'GRL',
        iso2: 'GL',
        states: [],
    },
    {
        name: 'Grenada',
        iso3: 'GRD',
        iso2: 'GD',
        states: [
            {
                name: 'Carriacou and Petite Martinique',
                state_code: '10',
            },
            {
                name: 'Saint Andrew Parish',
                state_code: '01',
            },
            {
                name: 'Saint David Parish',
                state_code: '02',
            },
            {
                name: 'Saint George Parish',
                state_code: '03',
            },
            {
                name: 'Saint John Parish',
                state_code: '04',
            },
            {
                name: 'Saint Mark Parish',
                state_code: '05',
            },
            {
                name: 'Saint Patrick Parish',
                state_code: '06',
            },
        ],
    },
    {
        name: 'Guadeloupe',
        iso3: 'GLP',
        iso2: 'GP',
        states: [],
    },
    {
        name: 'Guam',
        iso3: 'GUM',
        iso2: 'GU',
        states: [],
    },
    {
        name: 'Guatemala',
        iso3: 'GTM',
        iso2: 'GT',
        states: [
            {
                name: 'Alta Verapaz Department',
                state_code: 'AV',
            },
            {
                name: 'Baja Verapaz Department',
                state_code: 'BV',
            },
            {
                name: 'Chimaltenango Department',
                state_code: 'CM',
            },
            {
                name: 'Chiquimula Department',
                state_code: 'CQ',
            },
            {
                name: 'El Progreso Department',
                state_code: 'PR',
            },
            {
                name: 'Escuintla Department',
                state_code: 'ES',
            },
            {
                name: 'Guatemala Department',
                state_code: 'GU',
            },
            {
                name: 'Huehuetenango Department',
                state_code: 'HU',
            },
            {
                name: 'Izabal Department',
                state_code: 'IZ',
            },
            {
                name: 'Jalapa Department',
                state_code: 'JA',
            },
            {
                name: 'Jutiapa Department',
                state_code: 'JU',
            },
            {
                name: 'Petén Department',
                state_code: 'PE',
            },
            {
                name: 'Quetzaltenango Department',
                state_code: 'QZ',
            },
            {
                name: 'Quiché Department',
                state_code: 'QC',
            },
            {
                name: 'Retalhuleu Department',
                state_code: 'RE',
            },
            {
                name: 'Sacatepéquez Department',
                state_code: 'SA',
            },
            {
                name: 'San Marcos Department',
                state_code: 'SM',
            },
            {
                name: 'Santa Rosa Department',
                state_code: 'SR',
            },
            {
                name: 'Sololá Department',
                state_code: 'SO',
            },
            {
                name: 'Suchitepéquez Department',
                state_code: 'SU',
            },
            {
                name: 'Totonicapán Department',
                state_code: 'TO',
            },
        ],
    },
    {
        name: 'Guernsey',
        iso3: 'GGY',
        iso2: 'GG',
        states: [],
    },
    {
        name: 'Guinea',
        iso3: 'GIN',
        iso2: 'GN',
        states: [
            {
                name: 'Beyla Prefecture',
                state_code: 'BE',
            },
            {
                name: 'Boffa Prefecture',
                state_code: 'BF',
            },
            {
                name: 'Boké Prefecture',
                state_code: 'BK',
            },
            {
                name: 'Boké Region',
                state_code: 'B',
            },
            {
                name: 'Conakry',
                state_code: 'C',
            },
            {
                name: 'Coyah Prefecture',
                state_code: 'CO',
            },
            {
                name: 'Dabola Prefecture',
                state_code: 'DB',
            },
            {
                name: 'Dalaba Prefecture',
                state_code: 'DL',
            },
            {
                name: 'Dinguiraye Prefecture',
                state_code: 'DI',
            },
            {
                name: 'Dubréka Prefecture',
                state_code: 'DU',
            },
            {
                name: 'Faranah Prefecture',
                state_code: 'FA',
            },
            {
                name: 'Forécariah Prefecture',
                state_code: 'FO',
            },
            {
                name: 'Fria Prefecture',
                state_code: 'FR',
            },
            {
                name: 'Gaoual Prefecture',
                state_code: 'GA',
            },
            {
                name: 'Guéckédou Prefecture',
                state_code: 'GU',
            },
            {
                name: 'Kankan Prefecture',
                state_code: 'KA',
            },
            {
                name: 'Kankan Region',
                state_code: 'K',
            },
            {
                name: 'Kérouané Prefecture',
                state_code: 'KE',
            },
            {
                name: 'Kindia Prefecture',
                state_code: 'KD',
            },
            {
                name: 'Kindia Region',
                state_code: 'D',
            },
            {
                name: 'Kissidougou Prefecture',
                state_code: 'KS',
            },
            {
                name: 'Koubia Prefecture',
                state_code: 'KB',
            },
            {
                name: 'Koundara Prefecture',
                state_code: 'KN',
            },
            {
                name: 'Kouroussa Prefecture',
                state_code: 'KO',
            },
            {
                name: 'Labé Prefecture',
                state_code: 'LA',
            },
            {
                name: 'Labé Region',
                state_code: 'L',
            },
            {
                name: 'Lélouma Prefecture',
                state_code: 'LE',
            },
            {
                name: 'Lola Prefecture',
                state_code: 'LO',
            },
            {
                name: 'Macenta Prefecture',
                state_code: 'MC',
            },
            {
                name: 'Mali Prefecture',
                state_code: 'ML',
            },
            {
                name: 'Mamou Prefecture',
                state_code: 'MM',
            },
            {
                name: 'Mamou Region',
                state_code: 'M',
            },
            {
                name: 'Mandiana Prefecture',
                state_code: 'MD',
            },
            {
                name: 'Nzérékoré Prefecture',
                state_code: 'NZ',
            },
            {
                name: 'Nzérékoré Region',
                state_code: 'N',
            },
            {
                name: 'Pita Prefecture',
                state_code: 'PI',
            },
            {
                name: 'Siguiri Prefecture',
                state_code: 'SI',
            },
            {
                name: 'Télimélé Prefecture',
                state_code: 'TE',
            },
            {
                name: 'Tougué Prefecture',
                state_code: 'TO',
            },
            {
                name: 'Yomou Prefecture',
                state_code: 'YO',
            },
        ],
    },
    {
        name: 'Guinea-Bissau',
        iso3: 'GNB',
        iso2: 'GW',
        states: [
            {
                name: 'Bafatá',
                state_code: 'BA',
            },
            {
                name: 'Biombo Region',
                state_code: 'BM',
            },
            {
                name: 'Bolama Region',
                state_code: 'BL',
            },
            {
                name: 'Cacheu Region',
                state_code: 'CA',
            },
            {
                name: 'Gabú Region',
                state_code: 'GA',
            },
            {
                name: 'Leste Province',
                state_code: 'L',
            },
            {
                name: 'Norte Province',
                state_code: 'N',
            },
            {
                name: 'Oio Region',
                state_code: 'OI',
            },
            {
                name: 'Quinara Region',
                state_code: 'QU',
            },
            {
                name: 'Sul Province',
                state_code: 'S',
            },
            {
                name: 'Tombali Region',
                state_code: 'TO',
            },
        ],
    },
    {
        name: 'Guyana',
        iso3: 'GUY',
        iso2: 'GY',
        states: [
            {
                name: 'Barima-Waini',
                state_code: 'BA',
            },
            {
                name: 'Cuyuni-Mazaruni',
                state_code: 'CU',
            },
            {
                name: 'Demerara-Mahaica',
                state_code: 'DE',
            },
            {
                name: 'East Berbice-Corentyne',
                state_code: 'EB',
            },
            {
                name: 'Essequibo Islands-West Demerara',
                state_code: 'ES',
            },
            {
                name: 'Mahaica-Berbice',
                state_code: 'MA',
            },
            {
                name: 'Pomeroon-Supenaam',
                state_code: 'PM',
            },
            {
                name: 'Potaro-Siparuni',
                state_code: 'PT',
            },
            {
                name: 'Upper Demerara-Berbice',
                state_code: 'UD',
            },
            {
                name: 'Upper Takutu-Upper Essequibo',
                state_code: 'UT',
            },
        ],
    },
    {
        name: 'Haiti',
        iso3: 'HTI',
        iso2: 'HT',
        states: [
            {
                name: 'Artibonite',
                state_code: 'AR',
            },
            {
                name: 'Centre',
                state_code: 'CE',
            },
            {
                name: "Grand'Anse",
                state_code: 'GA',
            },
            {
                name: 'Nippes',
                state_code: 'NI',
            },
            {
                name: 'Nord',
                state_code: 'ND',
            },
            {
                name: 'Nord-Est',
                state_code: 'NE',
            },
            {
                name: 'Nord-Ouest',
                state_code: 'NO',
            },
            {
                name: 'Ouest',
                state_code: 'OU',
            },
            {
                name: 'Sud',
                state_code: 'SD',
            },
            {
                name: 'Sud-Est',
                state_code: 'SE',
            },
        ],
    },
    {
        name: 'Heard Island and McDonald Islands',
        iso3: 'HMD',
        iso2: 'HM',
        states: [],
    },
    {
        name: 'Honduras',
        iso3: 'HND',
        iso2: 'HN',
        states: [
            {
                name: 'Atlántida Department',
                state_code: 'AT',
            },
            {
                name: 'Bay Islands Department',
                state_code: 'IB',
            },
            {
                name: 'Choluteca Department',
                state_code: 'CH',
            },
            {
                name: 'Colón Department',
                state_code: 'CL',
            },
            {
                name: 'Comayagua Department',
                state_code: 'CM',
            },
            {
                name: 'Copán Department',
                state_code: 'CP',
            },
            {
                name: 'Cortés Department',
                state_code: 'CR',
            },
            {
                name: 'El Paraíso Department',
                state_code: 'EP',
            },
            {
                name: 'Francisco Morazán Department',
                state_code: 'FM',
            },
            {
                name: 'Gracias a Dios Department',
                state_code: 'GD',
            },
            {
                name: 'Intibucá Department',
                state_code: 'IN',
            },
            {
                name: 'La Paz Department',
                state_code: 'LP',
            },
            {
                name: 'Lempira Department',
                state_code: 'LE',
            },
            {
                name: 'Ocotepeque Department',
                state_code: 'OC',
            },
            {
                name: 'Olancho Department',
                state_code: 'OL',
            },
            {
                name: 'Santa Bárbara Department',
                state_code: 'SB',
            },
            {
                name: 'Valle Department',
                state_code: 'VA',
            },
            {
                name: 'Yoro Department',
                state_code: 'YO',
            },
        ],
    },
    {
        name: 'Hong Kong S.A.R.',
        iso3: 'HKG',
        iso2: 'HK',
        states: [],
    },
    {
        name: 'Hungary',
        iso3: 'HUN',
        iso2: 'HU',
        states: [
            {
                name: 'Bács-Kiskun County',
                state_code: 'BK',
            },
            {
                name: 'Baranya County',
                state_code: 'BA',
            },
            {
                name: 'Békés County',
                state_code: 'BE',
            },
            {
                name: 'Békéscsaba',
                state_code: 'BC',
            },
            {
                name: 'Borsod-Abaúj-Zemplén County',
                state_code: 'BZ',
            },
            {
                name: 'Budapest',
                state_code: 'BU',
            },
            {
                name: 'Csongrád County',
                state_code: 'CS',
            },
            {
                name: 'Debrecen',
                state_code: 'DE',
            },
            {
                name: 'Dunaújváros',
                state_code: 'DU',
            },
            {
                name: 'Eger',
                state_code: 'EG',
            },
            {
                name: 'Érd',
                state_code: 'ER',
            },
            {
                name: 'Fejér County',
                state_code: 'FE',
            },
            {
                name: 'Győr',
                state_code: 'GY',
            },
            {
                name: 'Győr-Moson-Sopron County',
                state_code: 'GS',
            },
            {
                name: 'Hajdú-Bihar County',
                state_code: 'HB',
            },
            {
                name: 'Heves County',
                state_code: 'HE',
            },
            {
                name: 'Hódmezővásárhely',
                state_code: 'HV',
            },
            {
                name: 'Jász-Nagykun-Szolnok County',
                state_code: 'JN',
            },
            {
                name: 'Kaposvár',
                state_code: 'KV',
            },
            {
                name: 'Kecskemét',
                state_code: 'KM',
            },
            {
                name: 'Miskolc',
                state_code: 'MI',
            },
            {
                name: 'Nagykanizsa',
                state_code: 'NK',
            },
            {
                name: 'Nógrád County',
                state_code: 'NO',
            },
            {
                name: 'Nyíregyháza',
                state_code: 'NY',
            },
            {
                name: 'Pécs',
                state_code: 'PS',
            },
            {
                name: 'Pest County',
                state_code: 'PE',
            },
            {
                name: 'Salgótarján',
                state_code: 'ST',
            },
            {
                name: 'Somogy County',
                state_code: 'SO',
            },
            {
                name: 'Sopron',
                state_code: 'SN',
            },
            {
                name: 'Szabolcs-Szatmár-Bereg County',
                state_code: 'SZ',
            },
            {
                name: 'Szeged',
                state_code: 'SD',
            },
            {
                name: 'Székesfehérvár',
                state_code: 'SF',
            },
            {
                name: 'Szekszárd',
                state_code: 'SS',
            },
            {
                name: 'Szolnok',
                state_code: 'SK',
            },
            {
                name: 'Szombathely',
                state_code: 'SH',
            },
            {
                name: 'Tatabánya',
                state_code: 'TB',
            },
            {
                name: 'Tolna County',
                state_code: 'TO',
            },
            {
                name: 'Vas County',
                state_code: 'VA',
            },
            {
                name: 'Veszprém',
                state_code: 'VM',
            },
            {
                name: 'Veszprém County',
                state_code: 'VE',
            },
            {
                name: 'Zala County',
                state_code: 'ZA',
            },
            {
                name: 'Zalaegerszeg',
                state_code: 'ZE',
            },
        ],
    },
    {
        name: 'Iceland',
        iso3: 'ISL',
        iso2: 'IS',
        states: [
            {
                name: 'Capital Region',
                state_code: '1',
            },
            {
                name: 'Eastern Region',
                state_code: '7',
            },
            {
                name: 'Northeastern Region',
                state_code: '6',
            },
            {
                name: 'Northwestern Region',
                state_code: '5',
            },
            {
                name: 'Southern Peninsula Region',
                state_code: '2',
            },
            {
                name: 'Southern Region',
                state_code: '8',
            },
            {
                name: 'Western Region',
                state_code: '3',
            },
            {
                name: 'Westfjords',
                state_code: '4',
            },
        ],
    },
    {
        name: 'India',
        iso3: 'IND',
        iso2: 'IN',
        states: [
            {
                name: 'Andaman and Nicobar Islands',
                state_code: 'AN',
            },
            {
                name: 'Andhra Pradesh',
                state_code: 'AP',
            },
            {
                name: 'Arunachal Pradesh',
                state_code: 'AR',
            },
            {
                name: 'Assam',
                state_code: 'AS',
            },
            {
                name: 'Bihar',
                state_code: 'BR',
            },
            {
                name: 'Chandigarh',
                state_code: 'CH',
            },
            {
                name: 'Chhattisgarh',
                state_code: 'CT',
            },
            {
                name: 'Dadra and Nagar Haveli',
                state_code: 'DN',
            },
            {
                name: 'Daman and Diu',
                state_code: 'DD',
            },
            {
                name: 'Delhi',
                state_code: 'DL',
            },
            {
                name: 'Goa',
                state_code: 'GA',
            },
            {
                name: 'Gujarat',
                state_code: 'GJ',
            },
            {
                name: 'Haryana',
                state_code: 'HR',
            },
            {
                name: 'Himachal Pradesh',
                state_code: 'HP',
            },
            {
                name: 'Jammu and Kashmir',
                state_code: 'JK',
            },
            {
                name: 'Jharkhand',
                state_code: 'JH',
            },
            {
                name: 'Karnataka',
                state_code: 'KA',
            },
            {
                name: 'Kerala',
                state_code: 'KL',
            },
            {
                name: 'Ladakh',
                state_code: 'LA',
            },
            {
                name: 'Lakshadweep',
                state_code: 'LD',
            },
            {
                name: 'Madhya Pradesh',
                state_code: 'MP',
            },
            {
                name: 'Maharashtra',
                state_code: 'MH',
            },
            {
                name: 'Manipur',
                state_code: 'MN',
            },
            {
                name: 'Meghalaya',
                state_code: 'ML',
            },
            {
                name: 'Mizoram',
                state_code: 'MZ',
            },
            {
                name: 'Nagaland',
                state_code: 'NL',
            },
            {
                name: 'Odisha',
                state_code: 'OR',
            },
            {
                name: 'Puducherry',
                state_code: 'PY',
            },
            {
                name: 'Punjab',
                state_code: 'PB',
            },
            {
                name: 'Rajasthan',
                state_code: 'RJ',
            },
            {
                name: 'Sikkim',
                state_code: 'SK',
            },
            {
                name: 'Tamil Nadu',
                state_code: 'TN',
            },
            {
                name: 'Telangana',
                state_code: 'TG',
            },
            {
                name: 'Tripura',
                state_code: 'TR',
            },
            {
                name: 'Uttar Pradesh',
                state_code: 'UP',
            },
            {
                name: 'Uttarakhand',
                state_code: 'UT',
            },
            {
                name: 'West Bengal',
                state_code: 'WB',
            },
        ],
    },
    {
        name: 'Indonesia',
        iso3: 'IDN',
        iso2: 'ID',
        states: [
            {
                name: 'Aceh',
                state_code: 'AC',
            },
            {
                name: 'Bali',
                state_code: 'BA',
            },
            {
                name: 'Bangka Belitung Islands',
                state_code: 'BB',
            },
            {
                name: 'Banten',
                state_code: 'BT',
            },
            {
                name: 'Bengkulu',
                state_code: 'BE',
            },
            {
                name: 'Central Java',
                state_code: 'JT',
            },
            {
                name: 'Central Kalimantan',
                state_code: 'KT',
            },
            {
                name: 'Central Sulawesi',
                state_code: 'ST',
            },
            {
                name: 'East Java',
                state_code: 'JI',
            },
            {
                name: 'East Kalimantan',
                state_code: 'KI',
            },
            {
                name: 'East Nusa Tenggara',
                state_code: 'NT',
            },
            {
                name: 'Gorontalo',
                state_code: 'GO',
            },
            {
                name: 'Jakarta',
                state_code: 'JK',
            },
            {
                name: 'Jambi',
                state_code: 'JA',
            },
            {
                name: 'Kalimantan',
                state_code: 'KA',
            },
            {
                name: 'Lampung',
                state_code: 'LA',
            },
            {
                name: 'Lesser Sunda Islands',
                state_code: 'NU',
            },
            {
                name: 'Maluku',
                state_code: 'MA',
            },
            {
                name: 'Maluku Islands',
                state_code: 'ML',
            },
            {
                name: 'North Kalimantan',
                state_code: 'KU',
            },
            {
                name: 'North Maluku',
                state_code: 'MU',
            },
            {
                name: 'North Sulawesi',
                state_code: 'SA',
            },
            {
                name: 'North Sumatra',
                state_code: 'SU',
            },
            {
                name: 'Papua',
                state_code: 'PA',
            },
            {
                name: 'Riau',
                state_code: 'RI',
            },
            {
                name: 'Riau Islands',
                state_code: 'KR',
            },
            {
                name: 'South Kalimantan',
                state_code: 'KS',
            },
            {
                name: 'South Sulawesi',
                state_code: 'SN',
            },
            {
                name: 'South Sumatra',
                state_code: 'SS',
            },
            {
                name: 'Southeast Sulawesi',
                state_code: 'SG',
            },
            {
                name: 'Special Region of Yogyakarta',
                state_code: 'YO',
            },
            {
                name: 'Sulawesi',
                state_code: 'SL',
            },
            {
                name: 'Sumatra',
                state_code: 'SM',
            },
            {
                name: 'West Java',
                state_code: 'JB',
            },
            {
                name: 'West Nusa Tenggara',
                state_code: 'NB',
            },
            {
                name: 'West Papua',
                state_code: 'PB',
            },
            {
                name: 'West Sulawesi',
                state_code: 'SR',
            },
            {
                name: 'West Sumatra',
                state_code: 'SB',
            },
        ],
    },
    {
        name: 'Iran',
        iso3: 'IRN',
        iso2: 'IR',
        states: [
            {
                name: 'Alborz Province',
                state_code: '32',
            },
            {
                name: 'Ardabil Province',
                state_code: '03',
            },
            {
                name: 'Bushehr Province',
                state_code: '06',
            },
            {
                name: 'Chaharmahal and Bakhtiari Province',
                state_code: '08',
            },
            {
                name: 'East Azerbaijan Province',
                state_code: '01',
            },
            {
                name: 'Fars Province',
                state_code: '14',
            },
            {
                name: 'Gilan Province',
                state_code: '19',
            },
            {
                name: 'Golestan Province',
                state_code: '27',
            },
            {
                name: 'Hormozgan Province',
                state_code: '23',
            },
            {
                name: 'Ilam Province',
                state_code: '05',
            },
            {
                name: 'Isfahan Province',
                state_code: '04',
            },
            {
                name: 'Kerman Province',
                state_code: '15',
            },
            {
                name: 'Kermanshah Province',
                state_code: '17',
            },
            {
                name: 'Khuzestan Province',
                state_code: '10',
            },
            {
                name: 'Kohgiluyeh and Boyer-Ahmad Province',
                state_code: '18',
            },
            {
                name: 'Kurdistan Province',
                state_code: '16',
            },
            {
                name: 'Lorestan Province',
                state_code: '20',
            },
            {
                name: 'Markazi Province',
                state_code: '22',
            },
            {
                name: 'Mazandaran Province',
                state_code: '21',
            },
            {
                name: 'North Khorasan Province',
                state_code: '31',
            },
            {
                name: 'Qazvin Province',
                state_code: '28',
            },
            {
                name: 'Qom Province',
                state_code: '26',
            },
            {
                name: 'Razavi Khorasan Province',
                state_code: '30',
            },
            {
                name: 'Semnan Province',
                state_code: '12',
            },
            {
                name: 'Sistan and Baluchestan',
                state_code: '13',
            },
            {
                name: 'South Khorasan Province',
                state_code: '29',
            },
            {
                name: 'Tehran Province',
                state_code: '07',
            },
            {
                name: 'West Azarbaijan Province',
                state_code: '02',
            },
            {
                name: 'Yazd Province',
                state_code: '25',
            },
            {
                name: 'Zanjan Province',
                state_code: '11',
            },
        ],
    },
    {
        name: 'Iraq',
        iso3: 'IRQ',
        iso2: 'IQ',
        states: [
            {
                name: 'Al Anbar Governorate',
                state_code: 'AN',
            },
            {
                name: 'Al Muthanna Governorate',
                state_code: 'MU',
            },
            {
                name: 'Al-Qādisiyyah Governorate',
                state_code: 'QA',
            },
            {
                name: 'Babylon Governorate',
                state_code: 'BB',
            },
            {
                name: 'Baghdad Governorate',
                state_code: 'BG',
            },
            {
                name: 'Basra Governorate',
                state_code: 'BA',
            },
            {
                name: 'Dhi Qar Governorate',
                state_code: 'DQ',
            },
            {
                name: 'Diyala Governorate',
                state_code: 'DI',
            },
            {
                name: 'Dohuk Governorate',
                state_code: 'DA',
            },
            {
                name: 'Erbil Governorate',
                state_code: 'AR',
            },
            {
                name: 'Karbala Governorate',
                state_code: 'KA',
            },
            {
                name: 'Kirkuk Governorate',
                state_code: 'KI',
            },
            {
                name: 'Maysan Governorate',
                state_code: 'MA',
            },
            {
                name: 'Najaf Governorate',
                state_code: 'NA',
            },
            {
                name: 'Nineveh Governorate',
                state_code: 'NI',
            },
            {
                name: 'Saladin Governorate',
                state_code: 'SD',
            },
            {
                name: 'Sulaymaniyah Governorate',
                state_code: 'SU',
            },
            {
                name: 'Wasit Governorate',
                state_code: 'WA',
            },
        ],
    },
    {
        name: 'Ireland',
        iso3: 'IRL',
        iso2: 'IE',
        states: [
            {
                name: 'Connacht',
                state_code: 'C',
            },
            {
                name: 'County Carlow',
                state_code: 'CW',
            },
            {
                name: 'County Cavan',
                state_code: 'CN',
            },
            {
                name: 'County Clare',
                state_code: 'CE',
            },
            {
                name: 'County Cork',
                state_code: 'CO',
            },
            {
                name: 'County Donegal',
                state_code: 'DL',
            },
            {
                name: 'County Dublin',
                state_code: 'D',
            },
            {
                name: 'County Galway',
                state_code: 'G',
            },
            {
                name: 'County Kerry',
                state_code: 'KY',
            },
            {
                name: 'County Kildare',
                state_code: 'KE',
            },
            {
                name: 'County Kilkenny',
                state_code: 'KK',
            },
            {
                name: 'County Laois',
                state_code: 'LS',
            },
            {
                name: 'County Limerick',
                state_code: 'LK',
            },
            {
                name: 'County Longford',
                state_code: 'LD',
            },
            {
                name: 'County Louth',
                state_code: 'LH',
            },
            {
                name: 'County Mayo',
                state_code: 'MO',
            },
            {
                name: 'County Meath',
                state_code: 'MH',
            },
            {
                name: 'County Monaghan',
                state_code: 'MN',
            },
            {
                name: 'County Offaly',
                state_code: 'OY',
            },
            {
                name: 'County Roscommon',
                state_code: 'RN',
            },
            {
                name: 'County Sligo',
                state_code: 'SO',
            },
            {
                name: 'County Tipperary',
                state_code: 'TA',
            },
            {
                name: 'County Waterford',
                state_code: 'WD',
            },
            {
                name: 'County Westmeath',
                state_code: 'WH',
            },
            {
                name: 'County Wexford',
                state_code: 'WX',
            },
            {
                name: 'County Wicklow',
                state_code: 'WW',
            },
            {
                name: 'Leinster',
                state_code: 'L',
            },
            {
                name: 'Munster',
                state_code: 'M',
            },
            {
                name: 'Ulster',
                state_code: 'U',
            },
        ],
    },
    {
        name: 'Israel',
        iso3: 'ISR',
        iso2: 'IL',
        states: [
            {
                name: 'Central District',
                state_code: 'M',
            },
            {
                name: 'Haifa District',
                state_code: 'HA',
            },
            {
                name: 'Jerusalem District',
                state_code: 'JM',
            },
            {
                name: 'Northern District',
                state_code: 'Z',
            },
            {
                name: 'Southern District',
                state_code: 'D',
            },
            {
                name: 'Tel Aviv District',
                state_code: 'TA',
            },
        ],
    },
    {
        name: 'Italy',
        iso3: 'ITA',
        iso2: 'IT',
        states: [
            {
                name: 'Abruzzo',
                state_code: '65',
            },
            {
                name: 'Aosta Valley',
                state_code: '23',
            },
            {
                name: 'Apulia',
                state_code: '75',
            },
            {
                name: 'Basilicata',
                state_code: '77',
            },
            {
                name: 'Benevento Province',
                state_code: 'BN',
            },
            {
                name: 'Calabria',
                state_code: '78',
            },
            {
                name: 'Campania',
                state_code: '72',
            },
            {
                name: 'Emilia-Romagna',
                state_code: '45',
            },
            {
                name: 'Friuli–Venezia Giulia',
                state_code: '36',
            },
            {
                name: 'Lazio',
                state_code: '62',
            },
            {
                name: 'Libero consorzio comunale di Agrigento',
                state_code: 'AG',
            },
            {
                name: 'Libero consorzio comunale di Caltanissetta',
                state_code: 'CL',
            },
            {
                name: 'Libero consorzio comunale di Enna',
                state_code: 'EN',
            },
            {
                name: 'Libero consorzio comunale di Ragusa',
                state_code: 'RG',
            },
            {
                name: 'Libero consorzio comunale di Siracusa',
                state_code: 'SR',
            },
            {
                name: 'Libero consorzio comunale di Trapani',
                state_code: 'TP',
            },
            {
                name: 'Liguria',
                state_code: '42',
            },
            {
                name: 'Lombardy',
                state_code: '25',
            },
            {
                name: 'Marche',
                state_code: '57',
            },
            {
                name: 'Metropolitan City of Bari',
                state_code: 'BA',
            },
            {
                name: 'Metropolitan City of Bologna',
                state_code: 'BO',
            },
            {
                name: 'Metropolitan City of Cagliari',
                state_code: 'CA',
            },
            {
                name: 'Metropolitan City of Catania',
                state_code: 'CT',
            },
            {
                name: 'Metropolitan City of Florence',
                state_code: 'FI',
            },
            {
                name: 'Metropolitan City of Genoa',
                state_code: 'GE',
            },
            {
                name: 'Metropolitan City of Messina',
                state_code: 'ME',
            },
            {
                name: 'Metropolitan City of Milan',
                state_code: 'MI',
            },
            {
                name: 'Metropolitan City of Naples',
                state_code: 'NA',
            },
            {
                name: 'Metropolitan City of Palermo',
                state_code: 'PA',
            },
            {
                name: 'Metropolitan City of Reggio Calabria',
                state_code: 'RC',
            },
            {
                name: 'Metropolitan City of Rome',
                state_code: 'RM',
            },
            {
                name: 'Metropolitan City of Turin',
                state_code: 'TO',
            },
            {
                name: 'Metropolitan City of Venice',
                state_code: 'VE',
            },
            {
                name: 'Molise',
                state_code: '67',
            },
            {
                name: 'Pesaro and Urbino Province',
                state_code: 'PU',
            },
            {
                name: 'Piedmont',
                state_code: '21',
            },
            {
                name: 'Province of Alessandria',
                state_code: 'AL',
            },
            {
                name: 'Province of Ancona',
                state_code: 'AN',
            },
            {
                name: 'Province of Ascoli Piceno',
                state_code: 'AP',
            },
            {
                name: 'Province of Asti',
                state_code: 'AT',
            },
            {
                name: 'Province of Avellino',
                state_code: 'AV',
            },
            {
                name: 'Province of Barletta-Andria-Trani',
                state_code: 'BT',
            },
            {
                name: 'Province of Belluno',
                state_code: 'BL',
            },
            {
                name: 'Province of Bergamo',
                state_code: 'BG',
            },
            {
                name: 'Province of Biella',
                state_code: 'BI',
            },
            {
                name: 'Province of Brescia',
                state_code: 'BS',
            },
            {
                name: 'Province of Brindisi',
                state_code: 'BR',
            },
            {
                name: 'Province of Campobasso',
                state_code: 'CB',
            },
            {
                name: 'Province of Carbonia-Iglesias',
                state_code: 'CI',
            },
            {
                name: 'Province of Caserta',
                state_code: 'CE',
            },
            {
                name: 'Province of Catanzaro',
                state_code: 'CZ',
            },
            {
                name: 'Province of Chieti',
                state_code: 'CH',
            },
            {
                name: 'Province of Como',
                state_code: 'CO',
            },
            {
                name: 'Province of Cosenza',
                state_code: 'CS',
            },
            {
                name: 'Province of Cremona',
                state_code: 'CR',
            },
            {
                name: 'Province of Crotone',
                state_code: 'KR',
            },
            {
                name: 'Province of Cuneo',
                state_code: 'CN',
            },
            {
                name: 'Province of Fermo',
                state_code: 'FM',
            },
            {
                name: 'Province of Ferrara',
                state_code: 'FE',
            },
            {
                name: 'Province of Foggia',
                state_code: 'FG',
            },
            {
                name: 'Province of Forlì-Cesena',
                state_code: 'FC',
            },
            {
                name: 'Province of Frosinone',
                state_code: 'FR',
            },
            {
                name: 'Province of Gorizia',
                state_code: 'GO',
            },
            {
                name: 'Province of Grosseto',
                state_code: 'GR',
            },
            {
                name: 'Province of Imperia',
                state_code: 'IM',
            },
            {
                name: 'Province of Isernia',
                state_code: 'IS',
            },
            {
                name: "Province of L'Aquila",
                state_code: 'AQ',
            },
            {
                name: 'Province of La Spezia',
                state_code: 'SP',
            },
            {
                name: 'Province of Latina',
                state_code: 'LT',
            },
            {
                name: 'Province of Lecce',
                state_code: 'LE',
            },
            {
                name: 'Province of Lecco',
                state_code: 'LC',
            },
            {
                name: 'Province of Livorno',
                state_code: 'LI',
            },
            {
                name: 'Province of Lodi',
                state_code: 'LO',
            },
            {
                name: 'Province of Lucca',
                state_code: 'LU',
            },
            {
                name: 'Province of Macerata',
                state_code: 'MC',
            },
            {
                name: 'Province of Mantua',
                state_code: 'MN',
            },
            {
                name: 'Province of Massa and Carrara',
                state_code: 'MS',
            },
            {
                name: 'Province of Matera',
                state_code: 'MT',
            },
            {
                name: 'Province of Medio Campidano',
                state_code: 'VS',
            },
            {
                name: 'Province of Modena',
                state_code: 'MO',
            },
            {
                name: 'Province of Monza and Brianza',
                state_code: 'MB',
            },
            {
                name: 'Province of Novara',
                state_code: 'NO',
            },
            {
                name: 'Province of Nuoro',
                state_code: 'NU',
            },
            {
                name: 'Province of Ogliastra',
                state_code: 'OG',
            },
            {
                name: 'Province of Olbia-Tempio',
                state_code: 'OT',
            },
            {
                name: 'Province of Oristano',
                state_code: 'OR',
            },
            {
                name: 'Province of Padua',
                state_code: 'PD',
            },
            {
                name: 'Province of Parma',
                state_code: 'PR',
            },
            {
                name: 'Province of Pavia',
                state_code: 'PV',
            },
            {
                name: 'Province of Perugia',
                state_code: 'PG',
            },
            {
                name: 'Province of Pescara',
                state_code: 'PE',
            },
            {
                name: 'Province of Piacenza',
                state_code: 'PC',
            },
            {
                name: 'Province of Pisa',
                state_code: 'PI',
            },
            {
                name: 'Province of Pistoia',
                state_code: 'PT',
            },
            {
                name: 'Province of Pordenone',
                state_code: 'PN',
            },
            {
                name: 'Province of Potenza',
                state_code: 'PZ',
            },
            {
                name: 'Province of Prato',
                state_code: 'PO',
            },
            {
                name: 'Province of Ravenna',
                state_code: 'RA',
            },
            {
                name: 'Province of Reggio Emilia',
                state_code: 'RE',
            },
            {
                name: 'Province of Rieti',
                state_code: 'RI',
            },
            {
                name: 'Province of Rimini',
                state_code: 'RN',
            },
            {
                name: 'Province of Rovigo',
                state_code: 'RO',
            },
            {
                name: 'Province of Salerno',
                state_code: 'SA',
            },
            {
                name: 'Province of Sassari',
                state_code: 'SS',
            },
            {
                name: 'Province of Savona',
                state_code: 'SV',
            },
            {
                name: 'Province of Siena',
                state_code: 'SI',
            },
            {
                name: 'Province of Sondrio',
                state_code: 'SO',
            },
            {
                name: 'Province of Taranto',
                state_code: 'TA',
            },
            {
                name: 'Province of Teramo',
                state_code: 'TE',
            },
            {
                name: 'Province of Terni',
                state_code: 'TR',
            },
            {
                name: 'Province of Treviso',
                state_code: 'TV',
            },
            {
                name: 'Province of Trieste',
                state_code: 'TS',
            },
            {
                name: 'Province of Udine',
                state_code: 'UD',
            },
            {
                name: 'Province of Varese',
                state_code: 'VA',
            },
            {
                name: 'Province of Verbano-Cusio-Ossola',
                state_code: 'VB',
            },
            {
                name: 'Province of Vercelli',
                state_code: 'VC',
            },
            {
                name: 'Province of Verona',
                state_code: 'VR',
            },
            {
                name: 'Province of Vibo Valentia',
                state_code: 'VV',
            },
            {
                name: 'Province of Vicenza',
                state_code: 'VI',
            },
            {
                name: 'Province of Viterbo',
                state_code: 'VT',
            },
            {
                name: 'Sardinia',
                state_code: '88',
            },
            {
                name: 'Sicily',
                state_code: '82',
            },
            {
                name: 'South Tyrol',
                state_code: 'BZ',
            },
            {
                name: 'Trentino',
                state_code: 'TN',
            },
            {
                name: 'Trentino-South Tyrol',
                state_code: '32',
            },
            {
                name: 'Tuscany',
                state_code: '52',
            },
            {
                name: 'Umbria',
                state_code: '55',
            },
            {
                name: 'Veneto',
                state_code: '34',
            },
        ],
    },
    {
        name: 'Jamaica',
        iso3: 'JAM',
        iso2: 'JM',
        states: [
            {
                name: 'Clarendon Parish',
                state_code: '13',
            },
            {
                name: 'Hanover Parish',
                state_code: '09',
            },
            {
                name: 'Kingston Parish',
                state_code: '01',
            },
            {
                name: 'Manchester Parish',
                state_code: '12',
            },
            {
                name: 'Portland Parish',
                state_code: '04',
            },
            {
                name: 'Saint Andrew',
                state_code: '02',
            },
            {
                name: 'Saint Ann Parish',
                state_code: '06',
            },
            {
                name: 'Saint Catherine Parish',
                state_code: '14',
            },
            {
                name: 'Saint Elizabeth Parish',
                state_code: '11',
            },
            {
                name: 'Saint James Parish',
                state_code: '08',
            },
            {
                name: 'Saint Mary Parish',
                state_code: '05',
            },
            {
                name: 'Saint Thomas Parish',
                state_code: '03',
            },
            {
                name: 'Trelawny Parish',
                state_code: '07',
            },
            {
                name: 'Westmoreland Parish',
                state_code: '10',
            },
        ],
    },
    {
        name: 'Japan',
        iso3: 'JPN',
        iso2: 'JP',
        states: [
            {
                name: 'Aichi Prefecture',
                state_code: '23',
            },
            {
                name: 'Akita Prefecture',
                state_code: '05',
            },
            {
                name: 'Aomori Prefecture',
                state_code: '02',
            },
            {
                name: 'Chiba Prefecture',
                state_code: '12',
            },
            {
                name: 'Ehime Prefecture',
                state_code: '38',
            },
            {
                name: 'Fukui Prefecture',
                state_code: '18',
            },
            {
                name: 'Fukuoka Prefecture',
                state_code: '40',
            },
            {
                name: 'Fukushima Prefecture',
                state_code: '07',
            },
            {
                name: 'Gifu Prefecture',
                state_code: '21',
            },
            {
                name: 'Gunma Prefecture',
                state_code: '10',
            },
            {
                name: 'Hiroshima Prefecture',
                state_code: '34',
            },
            {
                name: 'Hokkaidō Prefecture',
                state_code: '01',
            },
            {
                name: 'Hyōgo Prefecture',
                state_code: '28',
            },
            {
                name: 'Ibaraki Prefecture',
                state_code: '08',
            },
            {
                name: 'Ishikawa Prefecture',
                state_code: '17',
            },
            {
                name: 'Iwate Prefecture',
                state_code: '03',
            },
            {
                name: 'Kagawa Prefecture',
                state_code: '37',
            },
            {
                name: 'Kagoshima Prefecture',
                state_code: '46',
            },
            {
                name: 'Kanagawa Prefecture',
                state_code: '14',
            },
            {
                name: 'Kumamoto Prefecture',
                state_code: '43',
            },
            {
                name: 'Kyōto Prefecture',
                state_code: '26',
            },
            {
                name: 'Mie Prefecture',
                state_code: '24',
            },
            {
                name: 'Miyagi Prefecture',
                state_code: '04',
            },
            {
                name: 'Miyazaki Prefecture',
                state_code: '45',
            },
            {
                name: 'Nagano Prefecture',
                state_code: '20',
            },
            {
                name: 'Nagasaki Prefecture',
                state_code: '42',
            },
            {
                name: 'Nara Prefecture',
                state_code: '29',
            },
            {
                name: 'Niigata Prefecture',
                state_code: '15',
            },
            {
                name: 'Ōita Prefecture',
                state_code: '44',
            },
            {
                name: 'Okayama Prefecture',
                state_code: '33',
            },
            {
                name: 'Okinawa Prefecture',
                state_code: '47',
            },
            {
                name: 'Ōsaka Prefecture',
                state_code: '27',
            },
            {
                name: 'Saga Prefecture',
                state_code: '41',
            },
            {
                name: 'Saitama Prefecture',
                state_code: '11',
            },
            {
                name: 'Shiga Prefecture',
                state_code: '25',
            },
            {
                name: 'Shimane Prefecture',
                state_code: '32',
            },
            {
                name: 'Shizuoka Prefecture',
                state_code: '22',
            },
            {
                name: 'Tochigi Prefecture',
                state_code: '09',
            },
            {
                name: 'Tokushima Prefecture',
                state_code: '36',
            },
            {
                name: 'Tokyo',
                state_code: '13',
            },
            {
                name: 'Tottori Prefecture',
                state_code: '31',
            },
            {
                name: 'Toyama Prefecture',
                state_code: '16',
            },
            {
                name: 'Wakayama Prefecture',
                state_code: '30',
            },
            {
                name: 'Yamagata Prefecture',
                state_code: '06',
            },
            {
                name: 'Yamaguchi Prefecture',
                state_code: '35',
            },
            {
                name: 'Yamanashi Prefecture',
                state_code: '19',
            },
        ],
    },
    {
        name: 'Jersey',
        iso3: 'JEY',
        iso2: 'JE',
        states: [],
    },
    {
        name: 'Jordan',
        iso3: 'JOR',
        iso2: 'JO',
        states: [
            {
                name: 'Ajloun Governorate',
                state_code: 'AJ',
            },
            {
                name: 'Amman Governorate',
                state_code: 'AM',
            },
            {
                name: 'Aqaba Governorate',
                state_code: 'AQ',
            },
            {
                name: 'Balqa Governorate',
                state_code: 'BA',
            },
            {
                name: 'Irbid Governorate',
                state_code: 'IR',
            },
            {
                name: 'Jerash Governorate',
                state_code: 'JA',
            },
            {
                name: 'Karak Governorate',
                state_code: 'KA',
            },
            {
                name: "Ma'an Governorate",
                state_code: 'MN',
            },
            {
                name: 'Madaba Governorate',
                state_code: 'MD',
            },
            {
                name: 'Mafraq Governorate',
                state_code: 'MA',
            },
            {
                name: 'Tafilah Governorate',
                state_code: 'AT',
            },
            {
                name: 'Zarqa Governorate',
                state_code: 'AZ',
            },
        ],
    },
    {
        name: 'Kazakhstan',
        iso3: 'KAZ',
        iso2: 'KZ',
        states: [
            {
                name: 'Akmola Region',
                state_code: 'AKM',
            },
            {
                name: 'Aktobe Region',
                state_code: 'AKT',
            },
            {
                name: 'Almaty',
                state_code: 'ALA',
            },
            {
                name: 'Almaty Region',
                state_code: 'ALM',
            },
            {
                name: 'Atyrau Region',
                state_code: 'ATY',
            },
            {
                name: 'Baikonur',
                state_code: 'BAY',
            },
            {
                name: 'East Kazakhstan Region',
                state_code: 'VOS',
            },
            {
                name: 'Jambyl Region',
                state_code: 'ZHA',
            },
            {
                name: 'Karaganda Region',
                state_code: 'KAR',
            },
            {
                name: 'Kostanay Region',
                state_code: 'KUS',
            },
            {
                name: 'Kyzylorda Region',
                state_code: 'KZY',
            },
            {
                name: 'Mangystau Region',
                state_code: 'MAN',
            },
            {
                name: 'North Kazakhstan Region',
                state_code: 'SEV',
            },
            {
                name: 'Nur-Sultan',
                state_code: 'AST',
            },
            {
                name: 'Pavlodar Region',
                state_code: 'PAV',
            },
            {
                name: 'Turkestan Region',
                state_code: 'YUZ',
            },
            {
                name: 'West Kazakhstan Province',
                state_code: 'ZAP',
            },
        ],
    },
    {
        name: 'Kenya',
        iso3: 'KEN',
        iso2: 'KE',
        states: [
            {
                name: 'Baringo County',
                state_code: '01',
            },
            {
                name: 'Bomet County',
                state_code: '02',
            },
            {
                name: 'Bungoma County',
                state_code: '03',
            },
            {
                name: 'Busia County',
                state_code: '04',
            },
            {
                name: 'Central Province',
                state_code: '200',
            },
            {
                name: 'Coast Province',
                state_code: '300',
            },
            {
                name: 'Eastern Province',
                state_code: '400',
            },
            {
                name: 'Elgeyo-Marakwet County',
                state_code: '05',
            },
            {
                name: 'Embu County',
                state_code: '06',
            },
            {
                name: 'Garissa County',
                state_code: '07',
            },
            {
                name: 'Homa Bay County',
                state_code: '08',
            },
            {
                name: 'Isiolo County',
                state_code: '09',
            },
            {
                name: 'Kajiado County',
                state_code: '10',
            },
            {
                name: 'Kakamega County',
                state_code: '11',
            },
            {
                name: 'Kericho County',
                state_code: '12',
            },
            {
                name: 'Kiambu County',
                state_code: '13',
            },
            {
                name: 'Kilifi County',
                state_code: '14',
            },
            {
                name: 'Kirinyaga County',
                state_code: '15',
            },
            {
                name: 'Kisii County',
                state_code: '16',
            },
            {
                name: 'Kisumu County',
                state_code: '17',
            },
            {
                name: 'Kitui County',
                state_code: '18',
            },
            {
                name: 'Kwale County',
                state_code: '19',
            },
            {
                name: 'Laikipia County',
                state_code: '20',
            },
            {
                name: 'Lamu County',
                state_code: '21',
            },
            {
                name: 'Machakos County',
                state_code: '22',
            },
            {
                name: 'Makueni County',
                state_code: '23',
            },
            {
                name: 'Mandera County',
                state_code: '24',
            },
            {
                name: 'Marsabit County',
                state_code: '25',
            },
            {
                name: 'Meru County',
                state_code: '26',
            },
            {
                name: 'Migori County',
                state_code: '27',
            },
            {
                name: 'Mombasa County',
                state_code: '28',
            },
            {
                name: 'Muranga County',
                state_code: '29',
            },
            {
                name: 'Nairobi',
                state_code: '110',
            },
            {
                name: 'Nakuru District',
                state_code: '31',
            },
            {
                name: 'Nandi District',
                state_code: '32',
            },
            {
                name: 'Narok County',
                state_code: '33',
            },
            {
                name: 'North Eastern Province',
                state_code: '500',
            },
            {
                name: 'Nyamira District',
                state_code: '34',
            },
            {
                name: 'Nyandarua County',
                state_code: '35',
            },
            {
                name: 'Nyanza Province',
                state_code: '600',
            },
            {
                name: 'Nyeri County',
                state_code: '36',
            },
            {
                name: 'Rift Valley Province',
                state_code: '700',
            },
            {
                name: 'Samburu County',
                state_code: '37',
            },
            {
                name: 'Siaya County',
                state_code: '38',
            },
            {
                name: 'Taita–Taveta County',
                state_code: '39',
            },
            {
                name: 'Tana River County',
                state_code: '40',
            },
            {
                name: 'Tharaka Nithi County',
                state_code: '41',
            },
            {
                name: 'Trans-Nzoia District',
                state_code: '42',
            },
            {
                name: 'Turkana County',
                state_code: '43',
            },
            {
                name: 'Uasin Gishu District',
                state_code: '44',
            },
            {
                name: 'Vihiga District',
                state_code: '45',
            },
            {
                name: 'Wajir County',
                state_code: '46',
            },
            {
                name: 'West Pokot County',
                state_code: '47',
            },
            {
                name: 'Western Province',
                state_code: '800',
            },
        ],
    },
    {
        name: 'Kiribati',
        iso3: 'KIR',
        iso2: 'KI',
        states: [
            {
                name: 'Gilbert Islands',
                state_code: 'G',
            },
            {
                name: 'Line Islands',
                state_code: 'L',
            },
            {
                name: 'Phoenix Islands',
                state_code: 'P',
            },
        ],
    },
    {
        name: 'North Korea',
        iso3: 'PRK',
        iso2: 'KP',
        states: [
            {
                name: 'Chagang Province',
                state_code: '04',
            },
            {
                name: 'Kangwon Province',
                state_code: '07',
            },
            {
                name: 'North Hamgyong Province',
                state_code: '09',
            },
            {
                name: 'North Hwanghae Province',
                state_code: '06',
            },
            {
                name: 'North Pyongan Province',
                state_code: '03',
            },
            {
                name: 'Pyongyang',
                state_code: '01',
            },
            {
                name: 'Rason',
                state_code: '13',
            },
            {
                name: 'Ryanggang Province',
                state_code: '10',
            },
            {
                name: 'South Hamgyong Province',
                state_code: '08',
            },
            {
                name: 'South Hwanghae Province',
                state_code: '05',
            },
            {
                name: 'South Pyongan Province',
                state_code: '02',
            },
        ],
    },
    {
        name: 'South Korea',
        iso3: 'KOR',
        iso2: 'KR',
        states: [
            {
                name: 'Busan',
                state_code: '26',
            },
            {
                name: 'Daegu',
                state_code: '27',
            },
            {
                name: 'Daejeon',
                state_code: '30',
            },
            {
                name: 'Gangwon Province',
                state_code: '42',
            },
            {
                name: 'Gwangju',
                state_code: '29',
            },
            {
                name: 'Gyeonggi Province',
                state_code: '41',
            },
            {
                name: 'Incheon',
                state_code: '28',
            },
            {
                name: 'Jeju',
                state_code: '49',
            },
            {
                name: 'North Chungcheong Province',
                state_code: '43',
            },
            {
                name: 'North Gyeongsang Province',
                state_code: '47',
            },
            {
                name: 'North Jeolla Province',
                state_code: '45',
            },
            {
                name: 'Sejong City',
                state_code: '50',
            },
            {
                name: 'Seoul',
                state_code: '11',
            },
            {
                name: 'South Chungcheong Province',
                state_code: '44',
            },
            {
                name: 'South Gyeongsang Province',
                state_code: '48',
            },
            {
                name: 'South Jeolla Province',
                state_code: '46',
            },
            {
                name: 'Ulsan',
                state_code: '31',
            },
        ],
    },
    {
        name: 'Kosovo',
        iso3: 'XKX',
        iso2: 'XK',
        states: [
            {
                name: 'Đakovica District (Gjakove)',
                state_code: null,
            },
            {
                name: 'Gjilan District',
                state_code: null,
            },
            {
                name: 'Kosovska Mitrovica District',
                state_code: null,
            },
            {
                name: 'Peć District',
                state_code: null,
            },
            {
                name: 'Pristina (Priştine)',
                state_code: null,
            },
            {
                name: 'Prizren District',
                state_code: null,
            },
            {
                name: 'Uroševac District (Ferizaj)',
                state_code: null,
            },
        ],
    },
    {
        name: 'Kuwait',
        iso3: 'KWT',
        iso2: 'KW',
        states: [
            {
                name: 'Al Ahmadi Governorate',
                state_code: 'AH',
            },
            {
                name: 'Al Farwaniyah Governorate',
                state_code: 'FA',
            },
            {
                name: 'Al Jahra Governorate',
                state_code: 'JA',
            },
            {
                name: 'Capital Governorate',
                state_code: 'KU',
            },
            {
                name: 'Hawalli Governorate',
                state_code: 'HA',
            },
            {
                name: 'Mubarak Al-Kabeer Governorate',
                state_code: 'MU',
            },
        ],
    },
    {
        name: 'Kyrgyzstan',
        iso3: 'KGZ',
        iso2: 'KG',
        states: [
            {
                name: 'Batken Region',
                state_code: 'B',
            },
            {
                name: 'Bishkek',
                state_code: 'GB',
            },
            {
                name: 'Chuy Region',
                state_code: 'C',
            },
            {
                name: 'Issyk-Kul Region',
                state_code: 'Y',
            },
            {
                name: 'Jalal-Abad Region',
                state_code: 'J',
            },
            {
                name: 'Naryn Region',
                state_code: 'N',
            },
            {
                name: 'Osh',
                state_code: 'GO',
            },
            {
                name: 'Osh Region',
                state_code: 'O',
            },
            {
                name: 'Talas Region',
                state_code: 'T',
            },
        ],
    },
    {
        name: 'Laos',
        iso3: 'LAO',
        iso2: 'LA',
        states: [
            {
                name: 'Attapeu Province',
                state_code: 'AT',
            },
            {
                name: 'Bokeo Province',
                state_code: 'BK',
            },
            {
                name: 'Bolikhamsai Province',
                state_code: 'BL',
            },
            {
                name: 'Champasak Province',
                state_code: 'CH',
            },
            {
                name: 'Houaphanh Province',
                state_code: 'HO',
            },
            {
                name: 'Khammouane Province',
                state_code: 'KH',
            },
            {
                name: 'Luang Namtha Province',
                state_code: 'LM',
            },
            {
                name: 'Luang Prabang Province',
                state_code: 'LP',
            },
            {
                name: 'Oudomxay Province',
                state_code: 'OU',
            },
            {
                name: 'Phongsaly Province',
                state_code: 'PH',
            },
            {
                name: 'Sainyabuli Province',
                state_code: 'XA',
            },
            {
                name: 'Salavan Province',
                state_code: 'SL',
            },
            {
                name: 'Savannakhet Province',
                state_code: 'SV',
            },
            {
                name: 'Sekong Province',
                state_code: 'XE',
            },
            {
                name: 'Vientiane Prefecture',
                state_code: 'VT',
            },
            {
                name: 'Vientiane Province',
                state_code: 'VI',
            },
            {
                name: 'Xaisomboun',
                state_code: 'XN',
            },
            {
                name: 'Xaisomboun Province',
                state_code: 'XS',
            },
            {
                name: 'Xiangkhouang Province',
                state_code: 'XI',
            },
        ],
    },
    {
        name: 'Latvia',
        iso3: 'LVA',
        iso2: 'LV',
        states: [
            {
                name: 'Aglona Municipality',
                state_code: '001',
            },
            {
                name: 'Aizkraukle Municipality',
                state_code: '002',
            },
            {
                name: 'Aizpute Municipality',
                state_code: '003',
            },
            {
                name: 'Aknīste Municipality',
                state_code: '004',
            },
            {
                name: 'Aloja Municipality',
                state_code: '005',
            },
            {
                name: 'Alsunga Municipality',
                state_code: '006',
            },
            {
                name: 'Alūksne Municipality',
                state_code: '007',
            },
            {
                name: 'Amata Municipality',
                state_code: '008',
            },
            {
                name: 'Ape Municipality',
                state_code: '009',
            },
            {
                name: 'Auce Municipality',
                state_code: '010',
            },
            {
                name: 'Babīte Municipality',
                state_code: '012',
            },
            {
                name: 'Baldone Municipality',
                state_code: '013',
            },
            {
                name: 'Baltinava Municipality',
                state_code: '014',
            },
            {
                name: 'Balvi Municipality',
                state_code: '015',
            },
            {
                name: 'Bauska Municipality',
                state_code: '016',
            },
            {
                name: 'Beverīna Municipality',
                state_code: '017',
            },
            {
                name: 'Brocēni Municipality',
                state_code: '018',
            },
            {
                name: 'Burtnieki Municipality',
                state_code: '019',
            },
            {
                name: 'Carnikava Municipality',
                state_code: '020',
            },
            {
                name: 'Cēsis Municipality',
                state_code: '022',
            },
            {
                name: 'Cesvaine Municipality',
                state_code: '021',
            },
            {
                name: 'Cibla Municipality',
                state_code: '023',
            },
            {
                name: 'Dagda Municipality',
                state_code: '024',
            },
            {
                name: 'Daugavpils',
                state_code: 'DGV',
            },
            {
                name: 'Daugavpils Municipality',
                state_code: '025',
            },
            {
                name: 'Dobele Municipality',
                state_code: '026',
            },
            {
                name: 'Dundaga Municipality',
                state_code: '027',
            },
            {
                name: 'Durbe Municipality',
                state_code: '028',
            },
            {
                name: 'Engure Municipality',
                state_code: '029',
            },
            {
                name: 'Ērgļi Municipality',
                state_code: '030',
            },
            {
                name: 'Garkalne Municipality',
                state_code: '031',
            },
            {
                name: 'Grobiņa Municipality',
                state_code: '032',
            },
            {
                name: 'Gulbene Municipality',
                state_code: '033',
            },
            {
                name: 'Iecava Municipality',
                state_code: '034',
            },
            {
                name: 'Ikšķile Municipality',
                state_code: '035',
            },
            {
                name: 'Ilūkste Municipality',
                state_code: '036',
            },
            {
                name: 'Inčukalns Municipality',
                state_code: '037',
            },
            {
                name: 'Jaunjelgava Municipality',
                state_code: '038',
            },
            {
                name: 'Jaunpiebalga Municipality',
                state_code: '039',
            },
            {
                name: 'Jaunpils Municipality',
                state_code: '040',
            },
            {
                name: 'Jēkabpils',
                state_code: 'JKB',
            },
            {
                name: 'Jēkabpils Municipality',
                state_code: '042',
            },
            {
                name: 'Jelgava',
                state_code: 'JEL',
            },
            {
                name: 'Jelgava Municipality',
                state_code: '041',
            },
            {
                name: 'Jūrmala',
                state_code: 'JUR',
            },
            {
                name: 'Kandava Municipality',
                state_code: '043',
            },
            {
                name: 'Kārsava Municipality',
                state_code: '044',
            },
            {
                name: 'Ķegums Municipality',
                state_code: '051',
            },
            {
                name: 'Ķekava Municipality',
                state_code: '052',
            },
            {
                name: 'Kocēni Municipality',
                state_code: '045',
            },
            {
                name: 'Koknese Municipality',
                state_code: '046',
            },
            {
                name: 'Krāslava Municipality',
                state_code: '047',
            },
            {
                name: 'Krimulda Municipality',
                state_code: '048',
            },
            {
                name: 'Krustpils Municipality',
                state_code: '049',
            },
            {
                name: 'Kuldīga Municipality',
                state_code: '050',
            },
            {
                name: 'Lielvārde Municipality',
                state_code: '053',
            },
            {
                name: 'Liepāja',
                state_code: 'LPX',
            },
            {
                name: 'Līgatne Municipality',
                state_code: '055',
            },
            {
                name: 'Limbaži Municipality',
                state_code: '054',
            },
            {
                name: 'Līvāni Municipality',
                state_code: '056',
            },
            {
                name: 'Lubāna Municipality',
                state_code: '057',
            },
            {
                name: 'Ludza Municipality',
                state_code: '058',
            },
            {
                name: 'Madona Municipality',
                state_code: '059',
            },
            {
                name: 'Mālpils Municipality',
                state_code: '061',
            },
            {
                name: 'Mārupe Municipality',
                state_code: '062',
            },
            {
                name: 'Mazsalaca Municipality',
                state_code: '060',
            },
            {
                name: 'Mērsrags Municipality',
                state_code: '063',
            },
            {
                name: 'Naukšēni Municipality',
                state_code: '064',
            },
            {
                name: 'Nereta Municipality',
                state_code: '065',
            },
            {
                name: 'Nīca Municipality',
                state_code: '066',
            },
            {
                name: 'Ogre Municipality',
                state_code: '067',
            },
            {
                name: 'Olaine Municipality',
                state_code: '068',
            },
            {
                name: 'Ozolnieki Municipality',
                state_code: '069',
            },
            {
                name: 'Pārgauja Municipality',
                state_code: '070',
            },
            {
                name: 'Pāvilosta Municipality',
                state_code: '071',
            },
            {
                name: 'Pļaviņas Municipality',
                state_code: '072',
            },
            {
                name: 'Preiļi Municipality',
                state_code: '073',
            },
            {
                name: 'Priekule Municipality',
                state_code: '074',
            },
            {
                name: 'Priekuļi Municipality',
                state_code: '075',
            },
            {
                name: 'Rauna Municipality',
                state_code: '076',
            },
            {
                name: 'Rēzekne',
                state_code: 'REZ',
            },
            {
                name: 'Rēzekne Municipality',
                state_code: '077',
            },
            {
                name: 'Riebiņi Municipality',
                state_code: '078',
            },
            {
                name: 'Riga',
                state_code: 'RIX',
            },
            {
                name: 'Roja Municipality',
                state_code: '079',
            },
            {
                name: 'Ropaži Municipality',
                state_code: '080',
            },
            {
                name: 'Rucava Municipality',
                state_code: '081',
            },
            {
                name: 'Rugāji Municipality',
                state_code: '082',
            },
            {
                name: 'Rūjiena Municipality',
                state_code: '084',
            },
            {
                name: 'Rundāle Municipality',
                state_code: '083',
            },
            {
                name: 'Sala Municipality',
                state_code: '085',
            },
            {
                name: 'Salacgrīva Municipality',
                state_code: '086',
            },
            {
                name: 'Salaspils Municipality',
                state_code: '087',
            },
            {
                name: 'Saldus Municipality',
                state_code: '088',
            },
            {
                name: 'Saulkrasti Municipality',
                state_code: '089',
            },
            {
                name: 'Sēja Municipality',
                state_code: '090',
            },
            {
                name: 'Sigulda Municipality',
                state_code: '091',
            },
            {
                name: 'Skrīveri Municipality',
                state_code: '092',
            },
            {
                name: 'Skrunda Municipality',
                state_code: '093',
            },
            {
                name: 'Smiltene Municipality',
                state_code: '094',
            },
            {
                name: 'Stopiņi Municipality',
                state_code: '095',
            },
            {
                name: 'Strenči Municipality',
                state_code: '096',
            },
            {
                name: 'Talsi Municipality',
                state_code: '097',
            },
            {
                name: 'Tērvete Municipality',
                state_code: '098',
            },
            {
                name: 'Tukums Municipality',
                state_code: '099',
            },
            {
                name: 'Vaiņode Municipality',
                state_code: '100',
            },
            {
                name: 'Valka Municipality',
                state_code: '101',
            },
            {
                name: 'Valmiera',
                state_code: 'VMR',
            },
            {
                name: 'Varakļāni Municipality',
                state_code: '102',
            },
            {
                name: 'Vārkava Municipality',
                state_code: '103',
            },
            {
                name: 'Vecpiebalga Municipality',
                state_code: '104',
            },
            {
                name: 'Vecumnieki Municipality',
                state_code: '105',
            },
            {
                name: 'Ventspils',
                state_code: 'VEN',
            },
            {
                name: 'Ventspils Municipality',
                state_code: '106',
            },
            {
                name: 'Viesīte Municipality',
                state_code: '107',
            },
            {
                name: 'Viļaka Municipality',
                state_code: '108',
            },
            {
                name: 'Viļāni Municipality',
                state_code: '109',
            },
            {
                name: 'Zilupe Municipality',
                state_code: '110',
            },
        ],
    },
    {
        name: 'Lebanon',
        iso3: 'LBN',
        iso2: 'LB',
        states: [
            {
                name: 'Akkar Governorate',
                state_code: 'AK',
            },
            {
                name: 'Baalbek-Hermel Governorate',
                state_code: 'BH',
            },
            {
                name: 'Beirut Governorate',
                state_code: 'BA',
            },
            {
                name: 'Beqaa Governorate',
                state_code: 'BI',
            },
            {
                name: 'Mount Lebanon Governorate',
                state_code: 'JL',
            },
            {
                name: 'Nabatieh Governorate',
                state_code: 'NA',
            },
            {
                name: 'North Governorate',
                state_code: 'AS',
            },
            {
                name: 'South Governorate',
                state_code: 'JA',
            },
        ],
    },
    {
        name: 'Lesotho',
        iso3: 'LSO',
        iso2: 'LS',
        states: [
            {
                name: 'Berea District',
                state_code: 'D',
            },
            {
                name: 'Butha-Buthe District',
                state_code: 'B',
            },
            {
                name: 'Leribe District',
                state_code: 'C',
            },
            {
                name: 'Mafeteng District',
                state_code: 'E',
            },
            {
                name: 'Maseru District',
                state_code: 'A',
            },
            {
                name: "Mohale's Hoek District",
                state_code: 'F',
            },
            {
                name: 'Mokhotlong District',
                state_code: 'J',
            },
            {
                name: "Qacha's Nek District",
                state_code: 'H',
            },
            {
                name: 'Quthing District',
                state_code: 'G',
            },
            {
                name: 'Thaba-Tseka District',
                state_code: 'K',
            },
        ],
    },
    {
        name: 'Liberia',
        iso3: 'LBR',
        iso2: 'LR',
        states: [
            {
                name: 'Bomi County',
                state_code: 'BM',
            },
            {
                name: 'Bong County',
                state_code: 'BG',
            },
            {
                name: 'Gbarpolu County',
                state_code: 'GP',
            },
            {
                name: 'Grand Bassa County',
                state_code: 'GB',
            },
            {
                name: 'Grand Cape Mount County',
                state_code: 'CM',
            },
            {
                name: 'Grand Gedeh County',
                state_code: 'GG',
            },
            {
                name: 'Grand Kru County',
                state_code: 'GK',
            },
            {
                name: 'Lofa County',
                state_code: 'LO',
            },
            {
                name: 'Margibi County',
                state_code: 'MG',
            },
            {
                name: 'Maryland County',
                state_code: 'MY',
            },
            {
                name: 'Montserrado County',
                state_code: 'MO',
            },
            {
                name: 'Nimba',
                state_code: 'NI',
            },
            {
                name: 'River Cess County',
                state_code: 'RI',
            },
            {
                name: 'River Gee County',
                state_code: 'RG',
            },
            {
                name: 'Sinoe County',
                state_code: 'SI',
            },
        ],
    },
    {
        name: 'Libya',
        iso3: 'LBY',
        iso2: 'LY',
        states: [
            {
                name: 'Al Wahat District',
                state_code: 'WA',
            },
            {
                name: 'Benghazi',
                state_code: 'BA',
            },
            {
                name: 'Derna District',
                state_code: 'DR',
            },
            {
                name: 'Ghat District',
                state_code: 'GT',
            },
            {
                name: 'Jabal al Akhdar',
                state_code: 'JA',
            },
            {
                name: 'Jabal al Gharbi District',
                state_code: 'JG',
            },
            {
                name: 'Jafara',
                state_code: 'JI',
            },
            {
                name: 'Jufra',
                state_code: 'JU',
            },
            {
                name: 'Kufra District',
                state_code: 'KF',
            },
            {
                name: 'Marj District',
                state_code: 'MJ',
            },
            {
                name: 'Misrata District',
                state_code: 'MI',
            },
            {
                name: 'Murqub',
                state_code: 'MB',
            },
            {
                name: 'Murzuq District',
                state_code: 'MQ',
            },
            {
                name: 'Nalut District',
                state_code: 'NL',
            },
            {
                name: 'Nuqat al Khams',
                state_code: 'NQ',
            },
            {
                name: 'Sabha District',
                state_code: 'SB',
            },
            {
                name: 'Sirte District',
                state_code: 'SR',
            },
            {
                name: 'Tripoli District',
                state_code: 'TB',
            },
            {
                name: 'Wadi al Hayaa District',
                state_code: 'WD',
            },
            {
                name: 'Wadi al Shatii District',
                state_code: 'WS',
            },
            {
                name: 'Zawiya District',
                state_code: 'ZA',
            },
        ],
    },
    {
        name: 'Liechtenstein',
        iso3: 'LIE',
        iso2: 'LI',
        states: [
            {
                name: 'Balzers',
                state_code: '01',
            },
            {
                name: 'Eschen',
                state_code: '02',
            },
            {
                name: 'Gamprin',
                state_code: '03',
            },
            {
                name: 'Mauren',
                state_code: '04',
            },
            {
                name: 'Planken',
                state_code: '05',
            },
            {
                name: 'Ruggell',
                state_code: '06',
            },
            {
                name: 'Schaan',
                state_code: '07',
            },
            {
                name: 'Schellenberg',
                state_code: '08',
            },
            {
                name: 'Triesen',
                state_code: '09',
            },
            {
                name: 'Triesenberg',
                state_code: '10',
            },
            {
                name: 'Vaduz',
                state_code: '11',
            },
        ],
    },
    {
        name: 'Lithuania',
        iso3: 'LTU',
        iso2: 'LT',
        states: [
            {
                name: 'Akmenė District Municipality',
                state_code: '01',
            },
            {
                name: 'Alytus City Municipality',
                state_code: '02',
            },
            {
                name: 'Alytus County',
                state_code: 'AL',
            },
            {
                name: 'Alytus District Municipality',
                state_code: '03',
            },
            {
                name: 'Birštonas Municipality',
                state_code: '05',
            },
            {
                name: 'Biržai District Municipality',
                state_code: '06',
            },
            {
                name: 'Druskininkai municipality',
                state_code: '07',
            },
            {
                name: 'Elektrėnai municipality',
                state_code: '08',
            },
            {
                name: 'Ignalina District Municipality',
                state_code: '09',
            },
            {
                name: 'Jonava District Municipality',
                state_code: '10',
            },
            {
                name: 'Joniškis District Municipality',
                state_code: '11',
            },
            {
                name: 'Jurbarkas District Municipality',
                state_code: '12',
            },
            {
                name: 'Kaišiadorys District Municipality',
                state_code: '13',
            },
            {
                name: 'Kalvarija municipality',
                state_code: '14',
            },
            {
                name: 'Kaunas City Municipality',
                state_code: '15',
            },
            {
                name: 'Kaunas County',
                state_code: 'KU',
            },
            {
                name: 'Kaunas District Municipality',
                state_code: '16',
            },
            {
                name: 'Kazlų Rūda municipality',
                state_code: '17',
            },
            {
                name: 'Kėdainiai District Municipality',
                state_code: '18',
            },
            {
                name: 'Kelmė District Municipality',
                state_code: '19',
            },
            {
                name: 'Klaipeda City Municipality',
                state_code: '20',
            },
            {
                name: 'Klaipėda County',
                state_code: 'KL',
            },
            {
                name: 'Klaipėda District Municipality',
                state_code: '21',
            },
            {
                name: 'Kretinga District Municipality',
                state_code: '22',
            },
            {
                name: 'Kupiškis District Municipality',
                state_code: '23',
            },
            {
                name: 'Lazdijai District Municipality',
                state_code: '24',
            },
            {
                name: 'Marijampolė County',
                state_code: 'MR',
            },
            {
                name: 'Marijampolė Municipality',
                state_code: '25',
            },
            {
                name: 'Mažeikiai District Municipality',
                state_code: '26',
            },
            {
                name: 'Molėtai District Municipality',
                state_code: '27',
            },
            {
                name: 'Neringa Municipality',
                state_code: '28',
            },
            {
                name: 'Pagėgiai municipality',
                state_code: '29',
            },
            {
                name: 'Pakruojis District Municipality',
                state_code: '30',
            },
            {
                name: 'Palanga City Municipality',
                state_code: '31',
            },
            {
                name: 'Panevėžys City Municipality',
                state_code: '32',
            },
            {
                name: 'Panevėžys County',
                state_code: 'PN',
            },
            {
                name: 'Panevėžys District Municipality',
                state_code: '33',
            },
            {
                name: 'Pasvalys District Municipality',
                state_code: '34',
            },
            {
                name: 'Plungė District Municipality',
                state_code: '35',
            },
            {
                name: 'Prienai District Municipality',
                state_code: '36',
            },
            {
                name: 'Radviliškis District Municipality',
                state_code: '37',
            },
            {
                name: 'Raseiniai District Municipality',
                state_code: '38',
            },
            {
                name: 'Rietavas municipality',
                state_code: '39',
            },
            {
                name: 'Rokiškis District Municipality',
                state_code: '40',
            },
            {
                name: 'Šakiai District Municipality',
                state_code: '41',
            },
            {
                name: 'Šalčininkai District Municipality',
                state_code: '42',
            },
            {
                name: 'Šiauliai City Municipality',
                state_code: '43',
            },
            {
                name: 'Šiauliai County',
                state_code: 'SA',
            },
            {
                name: 'Šiauliai District Municipality',
                state_code: '44',
            },
            {
                name: 'Šilalė District Municipality',
                state_code: '45',
            },
            {
                name: 'Šilutė District Municipality',
                state_code: '46',
            },
            {
                name: 'Širvintos District Municipality',
                state_code: '47',
            },
            {
                name: 'Skuodas District Municipality',
                state_code: '48',
            },
            {
                name: 'Švenčionys District Municipality',
                state_code: '49',
            },
            {
                name: 'Tauragė County',
                state_code: 'TA',
            },
            {
                name: 'Tauragė District Municipality',
                state_code: '50',
            },
            {
                name: 'Telšiai County',
                state_code: 'TE',
            },
            {
                name: 'Telšiai District Municipality',
                state_code: '51',
            },
            {
                name: 'Trakai District Municipality',
                state_code: '52',
            },
            {
                name: 'Ukmergė District Municipality',
                state_code: '53',
            },
            {
                name: 'Utena County',
                state_code: 'UT',
            },
            {
                name: 'Utena District Municipality',
                state_code: '54',
            },
            {
                name: 'Varėna District Municipality',
                state_code: '55',
            },
            {
                name: 'Vilkaviškis District Municipality',
                state_code: '56',
            },
            {
                name: 'Vilnius City Municipality',
                state_code: '57',
            },
            {
                name: 'Vilnius County',
                state_code: 'VL',
            },
            {
                name: 'Vilnius District Municipality',
                state_code: '58',
            },
            {
                name: 'Visaginas Municipality',
                state_code: '59',
            },
            {
                name: 'Zarasai District Municipality',
                state_code: '60',
            },
        ],
    },
    {
        name: 'Luxembourg',
        iso3: 'LUX',
        iso2: 'LU',
        states: [
            {
                name: 'Canton of Capellen',
                state_code: 'CA',
            },
            {
                name: 'Canton of Clervaux',
                state_code: 'CL',
            },
            {
                name: 'Canton of Diekirch',
                state_code: 'DI',
            },
            {
                name: 'Canton of Echternach',
                state_code: 'EC',
            },
            {
                name: 'Canton of Esch-sur-Alzette',
                state_code: 'ES',
            },
            {
                name: 'Canton of Grevenmacher',
                state_code: 'GR',
            },
            {
                name: 'Canton of Luxembourg',
                state_code: 'LU',
            },
            {
                name: 'Canton of Mersch',
                state_code: 'ME',
            },
            {
                name: 'Canton of Redange',
                state_code: 'RD',
            },
            {
                name: 'Canton of Remich',
                state_code: 'RM',
            },
            {
                name: 'Canton of Vianden',
                state_code: 'VD',
            },
            {
                name: 'Canton of Wiltz',
                state_code: 'WI',
            },
            {
                name: 'Diekirch District',
                state_code: 'D',
            },
            {
                name: 'Grevenmacher District',
                state_code: 'G',
            },
            {
                name: 'Luxembourg District',
                state_code: 'L',
            },
        ],
    },
    {
        name: 'Macau S.A.R.',
        iso3: 'MAC',
        iso2: 'MO',
        states: [],
    },
    {
        name: 'Macedonia',
        iso3: 'MKD',
        iso2: 'MK',
        states: [
            {
                name: 'Aerodrom Municipality',
                state_code: '01',
            },
            {
                name: 'Aračinovo Municipality',
                state_code: '02',
            },
            {
                name: 'Berovo Municipality',
                state_code: '03',
            },
            {
                name: 'Bitola Municipality',
                state_code: '04',
            },
            {
                name: 'Bogdanci Municipality',
                state_code: '05',
            },
            {
                name: 'Bogovinje Municipality',
                state_code: '06',
            },
            {
                name: 'Bosilovo Municipality',
                state_code: '07',
            },
            {
                name: 'Brvenica Municipality',
                state_code: '08',
            },
            {
                name: 'Butel Municipality',
                state_code: '09',
            },
            {
                name: 'Čair Municipality',
                state_code: '79',
            },
            {
                name: 'Čaška Municipality',
                state_code: '80',
            },
            {
                name: 'Centar Municipality',
                state_code: '77',
            },
            {
                name: 'Centar Župa Municipality',
                state_code: '78',
            },
            {
                name: 'Češinovo-Obleševo Municipality',
                state_code: '81',
            },
            {
                name: 'Čučer-Sandevo Municipality',
                state_code: '82',
            },
            {
                name: 'Debarca Municipality',
                state_code: '22',
            },
            {
                name: 'Delčevo Municipality',
                state_code: '23',
            },
            {
                name: 'Demir Hisar Municipality',
                state_code: '25',
            },
            {
                name: 'Demir Kapija Municipality',
                state_code: '24',
            },
            {
                name: 'Dojran Municipality',
                state_code: '26',
            },
            {
                name: 'Dolneni Municipality',
                state_code: '27',
            },
            {
                name: 'Drugovo Municipality',
                state_code: '28',
            },
            {
                name: 'Gazi Baba Municipality',
                state_code: '17',
            },
            {
                name: 'Gevgelija Municipality',
                state_code: '18',
            },
            {
                name: 'Gjorče Petrov Municipality',
                state_code: '29',
            },
            {
                name: 'Gostivar Municipality',
                state_code: '19',
            },
            {
                name: 'Gradsko Municipality',
                state_code: '20',
            },
            {
                name: 'Greater Skopje',
                state_code: '85',
            },
            {
                name: 'Ilinden Municipality',
                state_code: '34',
            },
            {
                name: 'Jegunovce Municipality',
                state_code: '35',
            },
            {
                name: 'Karbinci',
                state_code: '37',
            },
            {
                name: 'Karpoš Municipality',
                state_code: '38',
            },
            {
                name: 'Kavadarci Municipality',
                state_code: '36',
            },
            {
                name: 'Kičevo Municipality',
                state_code: '40',
            },
            {
                name: 'Kisela Voda Municipality',
                state_code: '39',
            },
            {
                name: 'Kočani Municipality',
                state_code: '42',
            },
            {
                name: 'Konče Municipality',
                state_code: '41',
            },
            {
                name: 'Kratovo Municipality',
                state_code: '43',
            },
            {
                name: 'Kriva Palanka Municipality',
                state_code: '44',
            },
            {
                name: 'Krivogaštani Municipality',
                state_code: '45',
            },
            {
                name: 'Kruševo Municipality',
                state_code: '46',
            },
            {
                name: 'Kumanovo Municipality',
                state_code: '47',
            },
            {
                name: 'Lipkovo Municipality',
                state_code: '48',
            },
            {
                name: 'Lozovo Municipality',
                state_code: '49',
            },
            {
                name: 'Makedonska Kamenica Municipality',
                state_code: '51',
            },
            {
                name: 'Makedonski Brod Municipality',
                state_code: '52',
            },
            {
                name: 'Mavrovo and Rostuša Municipality',
                state_code: '50',
            },
            {
                name: 'Mogila Municipality',
                state_code: '53',
            },
            {
                name: 'Negotino Municipality',
                state_code: '54',
            },
            {
                name: 'Novaci Municipality',
                state_code: '55',
            },
            {
                name: 'Novo Selo Municipality',
                state_code: '56',
            },
            {
                name: 'Ohrid Municipality',
                state_code: '58',
            },
            {
                name: 'Oslomej Municipality',
                state_code: '57',
            },
            {
                name: 'Pehčevo Municipality',
                state_code: '60',
            },
            {
                name: 'Petrovec Municipality',
                state_code: '59',
            },
            {
                name: 'Plasnica Municipality',
                state_code: '61',
            },
            {
                name: 'Prilep Municipality',
                state_code: '62',
            },
            {
                name: 'Probištip Municipality',
                state_code: '63',
            },
            {
                name: 'Radoviš Municipality',
                state_code: '64',
            },
            {
                name: 'Rankovce Municipality',
                state_code: '65',
            },
            {
                name: 'Resen Municipality',
                state_code: '66',
            },
            {
                name: 'Rosoman Municipality',
                state_code: '67',
            },
            {
                name: 'Saraj Municipality',
                state_code: '68',
            },
            {
                name: 'Sopište Municipality',
                state_code: '70',
            },
            {
                name: 'Staro Nagoričane Municipality',
                state_code: '71',
            },
            {
                name: 'Štip Municipality',
                state_code: '83',
            },
            {
                name: 'Struga Municipality',
                state_code: '72',
            },
            {
                name: 'Strumica Municipality',
                state_code: '73',
            },
            {
                name: 'Studeničani Municipality',
                state_code: '74',
            },
            {
                name: 'Šuto Orizari Municipality',
                state_code: '84',
            },
            {
                name: 'Sveti Nikole Municipality',
                state_code: '69',
            },
            {
                name: 'Tearce Municipality',
                state_code: '75',
            },
            {
                name: 'Tetovo Municipality',
                state_code: '76',
            },
            {
                name: 'Valandovo Municipality',
                state_code: '10',
            },
            {
                name: 'Vasilevo Municipality',
                state_code: '11',
            },
            {
                name: 'Veles Municipality',
                state_code: '13',
            },
            {
                name: 'Vevčani Municipality',
                state_code: '12',
            },
            {
                name: 'Vinica Municipality',
                state_code: '14',
            },
            {
                name: 'Vraneštica Municipality',
                state_code: '15',
            },
            {
                name: 'Vrapčište Municipality',
                state_code: '16',
            },
            {
                name: 'Zajas Municipality',
                state_code: '31',
            },
            {
                name: 'Zelenikovo Municipality',
                state_code: '32',
            },
            {
                name: 'Želino Municipality',
                state_code: '30',
            },
            {
                name: 'Zrnovci Municipality',
                state_code: '33',
            },
        ],
    },
    {
        name: 'Madagascar',
        iso3: 'MDG',
        iso2: 'MG',
        states: [
            {
                name: 'Antananarivo Province',
                state_code: 'T',
            },
            {
                name: 'Antsiranana Province',
                state_code: 'D',
            },
            {
                name: 'Fianarantsoa Province',
                state_code: 'F',
            },
            {
                name: 'Mahajanga Province',
                state_code: 'M',
            },
            {
                name: 'Toamasina Province',
                state_code: 'A',
            },
            {
                name: 'Toliara Province',
                state_code: 'U',
            },
        ],
    },
    {
        name: 'Malawi',
        iso3: 'MWI',
        iso2: 'MW',
        states: [
            {
                name: 'Balaka District',
                state_code: 'BA',
            },
            {
                name: 'Blantyre District',
                state_code: 'BL',
            },
            {
                name: 'Central Region',
                state_code: 'C',
            },
            {
                name: 'Chikwawa District',
                state_code: 'CK',
            },
            {
                name: 'Chiradzulu District',
                state_code: 'CR',
            },
            {
                name: 'Chitipa district',
                state_code: 'CT',
            },
            {
                name: 'Dedza District',
                state_code: 'DE',
            },
            {
                name: 'Dowa District',
                state_code: 'DO',
            },
            {
                name: 'Karonga District',
                state_code: 'KR',
            },
            {
                name: 'Kasungu District',
                state_code: 'KS',
            },
            {
                name: 'Likoma District',
                state_code: 'LK',
            },
            {
                name: 'Lilongwe District',
                state_code: 'LI',
            },
            {
                name: 'Machinga District',
                state_code: 'MH',
            },
            {
                name: 'Mangochi District',
                state_code: 'MG',
            },
            {
                name: 'Mchinji District',
                state_code: 'MC',
            },
            {
                name: 'Mulanje District',
                state_code: 'MU',
            },
            {
                name: 'Mwanza District',
                state_code: 'MW',
            },
            {
                name: 'Mzimba District',
                state_code: 'MZ',
            },
            {
                name: 'Nkhata Bay District',
                state_code: 'NB',
            },
            {
                name: 'Nkhotakota District',
                state_code: 'NK',
            },
            {
                name: 'Northern Region',
                state_code: 'N',
            },
            {
                name: 'Nsanje District',
                state_code: 'NS',
            },
            {
                name: 'Ntcheu District',
                state_code: 'NU',
            },
            {
                name: 'Ntchisi District',
                state_code: 'NI',
            },
            {
                name: 'Phalombe District',
                state_code: 'PH',
            },
            {
                name: 'Rumphi District',
                state_code: 'RU',
            },
            {
                name: 'Salima District',
                state_code: 'SA',
            },
            {
                name: 'Southern Region',
                state_code: 'S',
            },
            {
                name: 'Thyolo District',
                state_code: 'TH',
            },
            {
                name: 'Zomba District',
                state_code: 'ZO',
            },
        ],
    },
    {
        name: 'Malaysia',
        iso3: 'MYS',
        iso2: 'MY',
        states: [
            {
                name: 'Johor',
                state_code: '01',
            },
            {
                name: 'Kedah',
                state_code: '02',
            },
            {
                name: 'Kelantan',
                state_code: '03',
            },
            {
                name: 'Kuala Lumpur',
                state_code: '14',
            },
            {
                name: 'Labuan',
                state_code: '15',
            },
            {
                name: 'Melaka',
                state_code: '04',
            },
            {
                name: 'Negeri Sembilan',
                state_code: '05',
            },
            {
                name: 'Pahang',
                state_code: '06',
            },
            {
                name: 'Penang',
                state_code: '07',
            },
            {
                name: 'Perak',
                state_code: '08',
            },
            {
                name: 'Perlis',
                state_code: '09',
            },
            {
                name: 'Putrajaya',
                state_code: '16',
            },
            {
                name: 'Sabah',
                state_code: '12',
            },
            {
                name: 'Sarawak',
                state_code: '13',
            },
            {
                name: 'Selangor',
                state_code: '10',
            },
            {
                name: 'Terengganu',
                state_code: '11',
            },
        ],
    },
    {
        name: 'Maldives',
        iso3: 'MDV',
        iso2: 'MV',
        states: [
            {
                name: 'Addu Atoll',
                state_code: '01',
            },
            {
                name: 'Alif Alif Atoll',
                state_code: '02',
            },
            {
                name: 'Alif Dhaal Atoll',
                state_code: '00',
            },
            {
                name: 'Central Province',
                state_code: 'CE',
            },
            {
                name: 'Dhaalu Atoll',
                state_code: '17',
            },
            {
                name: 'Faafu Atoll',
                state_code: '14',
            },
            {
                name: 'Gaafu Alif Atoll',
                state_code: '27',
            },
            {
                name: 'Gaafu Dhaalu Atoll',
                state_code: '28',
            },
            {
                name: 'Gnaviyani Atoll',
                state_code: '29',
            },
            {
                name: 'Haa Alif Atoll',
                state_code: '07',
            },
            {
                name: 'Haa Dhaalu Atoll',
                state_code: '23',
            },
            {
                name: 'Kaafu Atoll',
                state_code: '26',
            },
            {
                name: 'Laamu Atoll',
                state_code: '05',
            },
            {
                name: 'Lhaviyani Atoll',
                state_code: '03',
            },
            {
                name: 'Malé',
                state_code: 'MLE',
            },
            {
                name: 'Meemu Atoll',
                state_code: '12',
            },
            {
                name: 'Noonu Atoll',
                state_code: '25',
            },
            {
                name: 'North Central Province',
                state_code: 'NC',
            },
            {
                name: 'North Province',
                state_code: 'NO',
            },
            {
                name: 'Raa Atoll',
                state_code: '13',
            },
            {
                name: 'Shaviyani Atoll',
                state_code: '24',
            },
            {
                name: 'South Central Province',
                state_code: 'SC',
            },
            {
                name: 'South Province',
                state_code: 'SU',
            },
            {
                name: 'Thaa Atoll',
                state_code: '08',
            },
            {
                name: 'Upper South Province',
                state_code: 'US',
            },
            {
                name: 'Vaavu Atoll',
                state_code: '04',
            },
        ],
    },
    {
        name: 'Mali',
        iso3: 'MLI',
        iso2: 'ML',
        states: [
            {
                name: 'Bamako',
                state_code: 'BKO',
            },
            {
                name: 'Gao Region',
                state_code: '7',
            },
            {
                name: 'Kayes Region',
                state_code: '1',
            },
            {
                name: 'Kidal Region',
                state_code: '8',
            },
            {
                name: 'Koulikoro Region',
                state_code: '2',
            },
            {
                name: 'Ménaka Region',
                state_code: '9',
            },
            {
                name: 'Mopti Region',
                state_code: '5',
            },
            {
                name: 'Ségou Region',
                state_code: '4',
            },
            {
                name: 'Sikasso Region',
                state_code: '3',
            },
            {
                name: 'Taoudénit Region',
                state_code: '10',
            },
            {
                name: 'Tombouctou Region',
                state_code: '6',
            },
        ],
    },
    {
        name: 'Malta',
        iso3: 'MLT',
        iso2: 'MT',
        states: [
            {
                name: 'Attard',
                state_code: '01',
            },
            {
                name: 'Balzan',
                state_code: '02',
            },
            {
                name: 'Birgu',
                state_code: '03',
            },
            {
                name: 'Birkirkara',
                state_code: '04',
            },
            {
                name: 'Birżebbuġa',
                state_code: '05',
            },
            {
                name: 'Cospicua',
                state_code: '06',
            },
            {
                name: 'Dingli',
                state_code: '07',
            },
            {
                name: 'Fgura',
                state_code: '08',
            },
            {
                name: 'Floriana',
                state_code: '09',
            },
            {
                name: 'Fontana',
                state_code: '10',
            },
            {
                name: 'Għajnsielem',
                state_code: '13',
            },
            {
                name: 'Għarb',
                state_code: '14',
            },
            {
                name: 'Għargħur',
                state_code: '15',
            },
            {
                name: 'Għasri',
                state_code: '16',
            },
            {
                name: 'Għaxaq',
                state_code: '17',
            },
            {
                name: 'Gudja',
                state_code: '11',
            },
            {
                name: 'Gżira',
                state_code: '12',
            },
            {
                name: 'Ħamrun',
                state_code: '18',
            },
            {
                name: 'Iklin',
                state_code: '19',
            },
            {
                name: 'Kalkara',
                state_code: '21',
            },
            {
                name: 'Kerċem',
                state_code: '22',
            },
            {
                name: 'Kirkop',
                state_code: '23',
            },
            {
                name: 'Lija',
                state_code: '24',
            },
            {
                name: 'Luqa',
                state_code: '25',
            },
            {
                name: 'Marsa',
                state_code: '26',
            },
            {
                name: 'Marsaskala',
                state_code: '27',
            },
            {
                name: 'Marsaxlokk',
                state_code: '28',
            },
            {
                name: 'Mdina',
                state_code: '29',
            },
            {
                name: 'Mellieħa',
                state_code: '30',
            },
            {
                name: 'Mġarr',
                state_code: '31',
            },
            {
                name: 'Mosta',
                state_code: '32',
            },
            {
                name: 'Mqabba',
                state_code: '33',
            },
            {
                name: 'Msida',
                state_code: '34',
            },
            {
                name: 'Mtarfa',
                state_code: '35',
            },
            {
                name: 'Munxar',
                state_code: '36',
            },
            {
                name: 'Nadur',
                state_code: '37',
            },
            {
                name: 'Naxxar',
                state_code: '38',
            },
            {
                name: 'Paola',
                state_code: '39',
            },
            {
                name: 'Pembroke',
                state_code: '40',
            },
            {
                name: 'Pietà',
                state_code: '41',
            },
            {
                name: 'Qala',
                state_code: '42',
            },
            {
                name: 'Qormi',
                state_code: '43',
            },
            {
                name: 'Qrendi',
                state_code: '44',
            },
            {
                name: 'Rabat',
                state_code: '46',
            },
            {
                name: 'Saint Lawrence',
                state_code: '50',
            },
            {
                name: 'San Ġwann',
                state_code: '49',
            },
            {
                name: 'Sannat',
                state_code: '52',
            },
            {
                name: 'Santa Luċija',
                state_code: '53',
            },
            {
                name: 'Santa Venera',
                state_code: '54',
            },
            {
                name: 'Senglea',
                state_code: '20',
            },
            {
                name: 'Siġġiewi',
                state_code: '55',
            },
            {
                name: 'Sliema',
                state_code: '56',
            },
            {
                name: "St. Julian's",
                state_code: '48',
            },
            {
                name: "St. Paul's Bay",
                state_code: '51',
            },
            {
                name: 'Swieqi',
                state_code: '57',
            },
            {
                name: "Ta' Xbiex",
                state_code: '58',
            },
            {
                name: 'Tarxien',
                state_code: '59',
            },
            {
                name: 'Valletta',
                state_code: '60',
            },
            {
                name: 'Victoria',
                state_code: '45',
            },
            {
                name: 'Xagħra',
                state_code: '61',
            },
            {
                name: 'Xewkija',
                state_code: '62',
            },
            {
                name: 'Xgħajra',
                state_code: '63',
            },
            {
                name: 'Żabbar',
                state_code: '64',
            },
            {
                name: 'Żebbuġ Gozo',
                state_code: '65',
            },
            {
                name: 'Żebbuġ Malta',
                state_code: '66',
            },
            {
                name: 'Żejtun',
                state_code: '67',
            },
            {
                name: 'Żurrieq',
                state_code: '68',
            },
        ],
    },
    {
        name: 'Man (Isle of)',
        iso3: 'IMN',
        iso2: 'IM',
        states: [],
    },
    {
        name: 'Marshall Islands',
        iso3: 'MHL',
        iso2: 'MH',
        states: [
            {
                name: 'Ralik Chain',
                state_code: 'L',
            },
            {
                name: 'Ratak Chain',
                state_code: 'T',
            },
        ],
    },
    {
        name: 'Martinique',
        iso3: 'MTQ',
        iso2: 'MQ',
        states: [],
    },
    {
        name: 'Mauritania',
        iso3: 'MRT',
        iso2: 'MR',
        states: [
            {
                name: 'Adrar Region',
                state_code: '07',
            },
            {
                name: 'Assaba Region',
                state_code: '03',
            },
            {
                name: 'Brakna Region',
                state_code: '05',
            },
            {
                name: 'Dakhlet Nouadhibou',
                state_code: '08',
            },
            {
                name: 'Gorgol Region',
                state_code: '04',
            },
            {
                name: 'Guidimaka Region',
                state_code: '10',
            },
            {
                name: 'Hodh Ech Chargui Region',
                state_code: '01',
            },
            {
                name: 'Hodh El Gharbi Region',
                state_code: '02',
            },
            {
                name: 'Inchiri Region',
                state_code: '12',
            },
            {
                name: 'Nouakchott-Nord Region',
                state_code: '14',
            },
            {
                name: 'Nouakchott-Ouest Region',
                state_code: '13',
            },
            {
                name: 'Nouakchott-Sud Region',
                state_code: '15',
            },
            {
                name: 'Tagant Region',
                state_code: '09',
            },
            {
                name: 'Tiris Zemmour Region',
                state_code: '11',
            },
            {
                name: 'Trarza Region',
                state_code: '06',
            },
        ],
    },
    {
        name: 'Mauritius',
        iso3: 'MUS',
        iso2: 'MU',
        states: [
            {
                name: 'Agaléga',
                state_code: 'AG',
            },
            {
                name: 'Beau Bassin-Rose Hill',
                state_code: 'BR',
            },
            {
                name: 'Cargados Carajos',
                state_code: 'CC',
            },
            {
                name: 'Curepipe',
                state_code: 'CU',
            },
            {
                name: 'Flacq District',
                state_code: 'FL',
            },
            {
                name: 'Grand Port District',
                state_code: 'GP',
            },
            {
                name: 'Moka District',
                state_code: 'MO',
            },
            {
                name: 'Pamplemousses District',
                state_code: 'PA',
            },
            {
                name: 'Plaines Wilhems District',
                state_code: 'PW',
            },
            {
                name: 'Port Louis',
                state_code: 'PU',
            },
            {
                name: 'Port Louis District',
                state_code: 'PL',
            },
            {
                name: 'Quatre Bornes',
                state_code: 'QB',
            },
            {
                name: 'Rivière du Rempart District',
                state_code: 'RR',
            },
            {
                name: 'Rivière Noire District',
                state_code: 'BL',
            },
            {
                name: 'Rodrigues',
                state_code: 'RO',
            },
            {
                name: 'Savanne District',
                state_code: 'SA',
            },
            {
                name: 'Vacoas-Phoenix',
                state_code: 'VP',
            },
        ],
    },
    {
        name: 'Mayotte',
        iso3: 'MYT',
        iso2: 'YT',
        states: [],
    },
    {
        name: 'Mexico',
        iso3: 'MEX',
        iso2: 'MX',
        states: [
            {
                name: 'Aguascalientes',
                state_code: 'AGU',
            },
            {
                name: 'Baja California',
                state_code: 'BCN',
            },
            {
                name: 'Baja California Sur',
                state_code: 'BCS',
            },
            {
                name: 'Campeche',
                state_code: 'CAM',
            },
            {
                name: 'Chiapas',
                state_code: 'CHP',
            },
            {
                name: 'Chihuahua',
                state_code: 'CHH',
            },
            {
                name: 'Coahuila',
                state_code: 'COA',
            },
            {
                name: 'Colima',
                state_code: 'COL',
            },
            {
                name: 'Durango',
                state_code: 'DUR',
            },
            {
                name: 'Guanajuato',
                state_code: 'GUA',
            },
            {
                name: 'Guerrero',
                state_code: 'GRO',
            },
            {
                name: 'Hidalgo',
                state_code: 'HID',
            },
            {
                name: 'Jalisco',
                state_code: 'JAL',
            },
            {
                name: 'México',
                state_code: 'MEX',
            },
            {
                name: 'Mexico City',
                state_code: 'CMX',
            },
            {
                name: 'Michoacán',
                state_code: 'MIC',
            },
            {
                name: 'Morelos',
                state_code: 'MOR',
            },
            {
                name: 'Nayarit',
                state_code: 'NAY',
            },
            {
                name: 'Nuevo León',
                state_code: 'NLE',
            },
            {
                name: 'Oaxaca',
                state_code: 'OAX',
            },
            {
                name: 'Puebla',
                state_code: 'PUE',
            },
            {
                name: 'Querétaro',
                state_code: 'QUE',
            },
            {
                name: 'Quintana Roo',
                state_code: 'ROO',
            },
            {
                name: 'San Luis Potosí',
                state_code: 'SLP',
            },
            {
                name: 'Sinaloa',
                state_code: 'SIN',
            },
            {
                name: 'Sonora',
                state_code: 'SON',
            },
            {
                name: 'Tabasco',
                state_code: 'TAB',
            },
            {
                name: 'Tamaulipas',
                state_code: 'TAM',
            },
            {
                name: 'Tlaxcala',
                state_code: 'TLA',
            },
            {
                name: 'Veracruz',
                state_code: 'VER',
            },
            {
                name: 'Yucatán',
                state_code: 'YUC',
            },
            {
                name: 'Zacatecas',
                state_code: 'ZAC',
            },
        ],
    },
    {
        name: 'Micronesia',
        iso3: 'FSM',
        iso2: 'FM',
        states: [
            {
                name: 'Chuuk State',
                state_code: 'TRK',
            },
            {
                name: 'Kosrae State',
                state_code: 'KSA',
            },
            {
                name: 'Pohnpei State',
                state_code: 'PNI',
            },
            {
                name: 'Yap State',
                state_code: 'YAP',
            },
        ],
    },
    {
        name: 'Moldova',
        iso3: 'MDA',
        iso2: 'MD',
        states: [
            {
                name: 'Anenii Noi District',
                state_code: 'AN',
            },
            {
                name: 'Bălți Municipality',
                state_code: 'BA',
            },
            {
                name: 'Basarabeasca District',
                state_code: 'BS',
            },
            {
                name: 'Bender Municipality',
                state_code: 'BD',
            },
            {
                name: 'Briceni District',
                state_code: 'BR',
            },
            {
                name: 'Cahul District',
                state_code: 'CA',
            },
            {
                name: 'Călărași District',
                state_code: 'CL',
            },
            {
                name: 'Cantemir District',
                state_code: 'CT',
            },
            {
                name: 'Căușeni District',
                state_code: 'CS',
            },
            {
                name: 'Chișinău Municipality',
                state_code: 'CU',
            },
            {
                name: 'Cimișlia District',
                state_code: 'CM',
            },
            {
                name: 'Criuleni District',
                state_code: 'CR',
            },
            {
                name: 'Dondușeni District',
                state_code: 'DO',
            },
            {
                name: 'Drochia District',
                state_code: 'DR',
            },
            {
                name: 'Dubăsari District',
                state_code: 'DU',
            },
            {
                name: 'Edineț District',
                state_code: 'ED',
            },
            {
                name: 'Fălești District',
                state_code: 'FA',
            },
            {
                name: 'Florești District',
                state_code: 'FL',
            },
            {
                name: 'Gagauzia',
                state_code: 'GA',
            },
            {
                name: 'Glodeni District',
                state_code: 'GL',
            },
            {
                name: 'Hîncești District',
                state_code: 'HI',
            },
            {
                name: 'Ialoveni District',
                state_code: 'IA',
            },
            {
                name: 'Nisporeni District',
                state_code: 'NI',
            },
            {
                name: 'Ocnița District',
                state_code: 'OC',
            },
            {
                name: 'Orhei District',
                state_code: 'OR',
            },
            {
                name: 'Rezina District',
                state_code: 'RE',
            },
            {
                name: 'Rîșcani District',
                state_code: 'RI',
            },
            {
                name: 'Sîngerei District',
                state_code: 'SI',
            },
            {
                name: 'Șoldănești District',
                state_code: 'SD',
            },
            {
                name: 'Soroca District',
                state_code: 'SO',
            },
            {
                name: 'Ștefan Vodă District',
                state_code: 'SV',
            },
            {
                name: 'Strășeni District',
                state_code: 'ST',
            },
            {
                name: 'Taraclia District',
                state_code: 'TA',
            },
            {
                name: 'Telenești District',
                state_code: 'TE',
            },
            {
                name: 'Transnistria autonomous territorial unit',
                state_code: 'SN',
            },
            {
                name: 'Ungheni District',
                state_code: 'UN',
            },
        ],
    },
    {
        name: 'Monaco',
        iso3: 'MCO',
        iso2: 'MC',
        states: [],
    },
    {
        name: 'Mongolia',
        iso3: 'MNG',
        iso2: 'MN',
        states: [
            {
                name: 'Arkhangai Province',
                state_code: '073',
            },
            {
                name: 'Bayan-Ölgii Province',
                state_code: '071',
            },
            {
                name: 'Bayankhongor Province',
                state_code: '069',
            },
            {
                name: 'Bulgan Province',
                state_code: '067',
            },
            {
                name: 'Darkhan-Uul Province',
                state_code: '037',
            },
            {
                name: 'Dornod Province',
                state_code: '061',
            },
            {
                name: 'Dornogovi Province',
                state_code: '063',
            },
            {
                name: 'Dundgovi Province',
                state_code: '059',
            },
            {
                name: 'Govi-Altai Province',
                state_code: '065',
            },
            {
                name: 'Govisümber Province',
                state_code: '064',
            },
            {
                name: 'Khentii Province',
                state_code: '039',
            },
            {
                name: 'Khovd Province',
                state_code: '043',
            },
            {
                name: 'Khövsgöl Province',
                state_code: '041',
            },
            {
                name: 'Ömnögovi Province',
                state_code: '053',
            },
            {
                name: 'Orkhon Province',
                state_code: '035',
            },
            {
                name: 'Övörkhangai Province',
                state_code: '055',
            },
            {
                name: 'Selenge Province',
                state_code: '049',
            },
            {
                name: 'Sükhbaatar Province',
                state_code: '051',
            },
            {
                name: 'Töv Province',
                state_code: '047',
            },
            {
                name: 'Uvs Province',
                state_code: '046',
            },
            {
                name: 'Zavkhan Province',
                state_code: '057',
            },
        ],
    },
    {
        name: 'Montenegro',
        iso3: 'MNE',
        iso2: 'ME',
        states: [
            {
                name: 'Andrijevica Municipality',
                state_code: '01',
            },
            {
                name: 'Bar Municipality',
                state_code: '02',
            },
            {
                name: 'Berane Municipality',
                state_code: '03',
            },
            {
                name: 'Bijelo Polje Municipality',
                state_code: '04',
            },
            {
                name: 'Budva Municipality',
                state_code: '05',
            },
            {
                name: 'Danilovgrad Municipality',
                state_code: '07',
            },
            {
                name: 'Gusinje Municipality',
                state_code: '22',
            },
            {
                name: 'Kolašin Municipality',
                state_code: '09',
            },
            {
                name: 'Kotor Municipality',
                state_code: '10',
            },
            {
                name: 'Mojkovac Municipality',
                state_code: '11',
            },
            {
                name: 'Nikšić Municipality',
                state_code: '12',
            },
            {
                name: 'Old Royal Capital Cetinje',
                state_code: '06',
            },
            {
                name: 'Petnjica Municipality',
                state_code: '23',
            },
            {
                name: 'Plav Municipality',
                state_code: '13',
            },
            {
                name: 'Pljevlja Municipality',
                state_code: '14',
            },
            {
                name: 'Plužine Municipality',
                state_code: '15',
            },
            {
                name: 'Podgorica Municipality',
                state_code: '16',
            },
            {
                name: 'Rožaje Municipality',
                state_code: '17',
            },
            {
                name: 'Šavnik Municipality',
                state_code: '18',
            },
            {
                name: 'Tivat Municipality',
                state_code: '19',
            },
            {
                name: 'Ulcinj Municipality',
                state_code: '20',
            },
            {
                name: 'Žabljak Municipality',
                state_code: '21',
            },
        ],
    },
    {
        name: 'Montserrat',
        iso3: 'MSR',
        iso2: 'MS',
        states: [],
    },
    {
        name: 'Morocco',
        iso3: 'MAR',
        iso2: 'MA',
        states: [
            {
                name: 'Al Haouz Province',
                state_code: 'HAO',
            },
            {
                name: 'Al Hoceïma Province',
                state_code: 'HOC',
            },
            {
                name: 'Aousserd Province',
                state_code: 'AOU',
            },
            {
                name: 'Assa-Zag Province',
                state_code: 'ASZ',
            },
            {
                name: 'Azilal Province',
                state_code: 'AZI',
            },
            {
                name: 'Ben Slimane Province',
                state_code: 'BES',
            },
            {
                name: 'Béni Mellal-Khénifra',
                state_code: '05',
            },
            {
                name: 'Béni-Mellal Province',
                state_code: 'BEM',
            },
            {
                name: 'Berkane Province',
                state_code: 'BER',
            },
            {
                name: 'Boujdour Province',
                state_code: 'BOD',
            },
            {
                name: 'Boulemane Province',
                state_code: 'BOM',
            },
            {
                name: 'Casablanca-Settat',
                state_code: '06',
            },
            {
                name: 'Chefchaouen Province',
                state_code: 'CHE',
            },
            {
                name: 'Chichaoua Province',
                state_code: 'CHI',
            },
            {
                name: 'Dakhla-Oued Ed-Dahab',
                state_code: '12',
            },
            {
                name: 'Drâa-Tafilalet',
                state_code: '08',
            },
            {
                name: 'El Hajeb Province',
                state_code: 'HAJ',
            },
            {
                name: 'El Jadida Province',
                state_code: 'JDI',
            },
            {
                name: 'Errachidia Province',
                state_code: 'ERR',
            },
            {
                name: 'Es Semara Province',
                state_code: 'ESM',
            },
            {
                name: 'Essaouira Province',
                state_code: 'ESI',
            },
            {
                name: 'Fahs Anjra Province',
                state_code: 'FAH',
            },
            {
                name: 'Fès-Meknès',
                state_code: '03',
            },
            {
                name: 'Figuig Province',
                state_code: 'FIG',
            },
            {
                name: 'Guelmim Province',
                state_code: 'GUE',
            },
            {
                name: 'Guelmim-Oued Noun',
                state_code: '10',
            },
            {
                name: 'Ifrane Province',
                state_code: 'IFR',
            },
            {
                name: 'Inezgane-Aït Melloul Prefecture',
                state_code: 'INE',
            },
            {
                name: 'Jerada Province',
                state_code: 'JRA',
            },
            {
                name: 'Kelaat Sraghna Province',
                state_code: 'KES',
            },
            {
                name: 'Kénitra Province',
                state_code: 'KEN',
            },
            {
                name: 'Khémisset Province',
                state_code: 'KHE',
            },
            {
                name: 'Khénifra Province',
                state_code: 'KHN',
            },
            {
                name: 'Khouribga Province',
                state_code: 'KHO',
            },
            {
                name: 'Laâyoune Province',
                state_code: 'LAA',
            },
            {
                name: 'Laâyoune-Sakia El Hamra',
                state_code: '11',
            },
            {
                name: 'Larache Province',
                state_code: 'LAR',
            },
            {
                name: 'Marrakesh-Safi',
                state_code: '07',
            },
            {
                name: 'Mediouna Province',
                state_code: 'MED',
            },
            {
                name: 'Moulay Yacoub Province',
                state_code: 'MOU',
            },
            {
                name: 'Nador Province',
                state_code: 'NAD',
            },
            {
                name: 'Nouaceur Province',
                state_code: 'NOU',
            },
            {
                name: 'Oriental',
                state_code: '02',
            },
            {
                name: 'Ouarzazate Province',
                state_code: 'OUA',
            },
            {
                name: 'Oued Ed-Dahab Province',
                state_code: 'OUD',
            },
            {
                name: 'Safi Province',
                state_code: 'SAF',
            },
            {
                name: 'Sefrou Province',
                state_code: 'SEF',
            },
            {
                name: 'Settat Province',
                state_code: 'SET',
            },
            {
                name: 'Shtouka Ait Baha Province',
                state_code: 'CHT',
            },
            {
                name: 'Sidi Kacem Province',
                state_code: 'SIK',
            },
            {
                name: 'Sidi Youssef Ben Ali',
                state_code: 'SYB',
            },
            {
                name: 'Souss-Massa',
                state_code: '09',
            },
            {
                name: 'Tan-Tan Province',
                state_code: 'TNT',
            },
            {
                name: 'Tanger-Tétouan-Al Hoceïma',
                state_code: '01',
            },
            {
                name: 'Taounate Province',
                state_code: 'TAO',
            },
            {
                name: 'Taourirt Province',
                state_code: 'TAI',
            },
            {
                name: 'Taroudant Province',
                state_code: 'TAR',
            },
            {
                name: 'Tata Province',
                state_code: 'TAT',
            },
            {
                name: 'Taza Province',
                state_code: 'TAZ',
            },
            {
                name: 'Tétouan Province',
                state_code: 'TET',
            },
            {
                name: 'Tiznit Province',
                state_code: 'TIZ',
            },
            {
                name: 'Zagora Province',
                state_code: 'ZAG',
            },
        ],
    },
    {
        name: 'Mozambique',
        iso3: 'MOZ',
        iso2: 'MZ',
        states: [
            {
                name: 'Cabo Delgado Province',
                state_code: 'P',
            },
            {
                name: 'Gaza Province',
                state_code: 'G',
            },
            {
                name: 'Inhambane Province',
                state_code: 'I',
            },
            {
                name: 'Manica Province',
                state_code: 'B',
            },
            {
                name: 'Maputo',
                state_code: 'MPM',
            },
            {
                name: 'Maputo Province',
                state_code: 'L',
            },
            {
                name: 'Nampula Province',
                state_code: 'N',
            },
            {
                name: 'Niassa Province',
                state_code: 'A',
            },
            {
                name: 'Sofala Province',
                state_code: 'S',
            },
            {
                name: 'Tete Province',
                state_code: 'T',
            },
            {
                name: 'Zambezia Province',
                state_code: 'Q',
            },
        ],
    },
    {
        name: 'Myanmar',
        iso3: 'MMR',
        iso2: 'MM',
        states: [
            {
                name: 'Ayeyarwady Region',
                state_code: '07',
            },
            {
                name: 'Bago',
                state_code: '02',
            },
            {
                name: 'Chin State',
                state_code: '14',
            },
            {
                name: 'Kachin State',
                state_code: '11',
            },
            {
                name: 'Kayah State',
                state_code: '12',
            },
            {
                name: 'Kayin State',
                state_code: '13',
            },
            {
                name: 'Magway Region',
                state_code: '03',
            },
            {
                name: 'Mandalay Region',
                state_code: '04',
            },
            {
                name: 'Mon State',
                state_code: '15',
            },
            {
                name: 'Naypyidaw Union Territory',
                state_code: '18',
            },
            {
                name: 'Rakhine State',
                state_code: '16',
            },
            {
                name: 'Sagaing Region',
                state_code: '01',
            },
            {
                name: 'Shan State',
                state_code: '17',
            },
            {
                name: 'Tanintharyi Region',
                state_code: '05',
            },
            {
                name: 'Yangon Region',
                state_code: '06',
            },
        ],
    },
    {
        name: 'Namibia',
        iso3: 'NAM',
        iso2: 'NA',
        states: [
            {
                name: 'Erongo Region',
                state_code: 'ER',
            },
            {
                name: 'Hardap Region',
                state_code: 'HA',
            },
            {
                name: 'Karas Region',
                state_code: 'KA',
            },
            {
                name: 'Kavango East Region',
                state_code: 'KE',
            },
            {
                name: 'Kavango West Region',
                state_code: 'KW',
            },
            {
                name: 'Khomas Region',
                state_code: 'KH',
            },
            {
                name: 'Kunene Region',
                state_code: 'KU',
            },
            {
                name: 'Ohangwena Region',
                state_code: 'OW',
            },
            {
                name: 'Omaheke Region',
                state_code: 'OH',
            },
            {
                name: 'Omusati Region',
                state_code: 'OS',
            },
            {
                name: 'Oshana Region',
                state_code: 'ON',
            },
            {
                name: 'Oshikoto Region',
                state_code: 'OT',
            },
            {
                name: 'Otjozondjupa Region',
                state_code: 'OD',
            },
            {
                name: 'Zambezi Region',
                state_code: 'CA',
            },
        ],
    },
    {
        name: 'Nauru',
        iso3: 'NRU',
        iso2: 'NR',
        states: [
            {
                name: 'Aiwo District',
                state_code: '01',
            },
            {
                name: 'Anabar District',
                state_code: '02',
            },
            {
                name: 'Anetan District',
                state_code: '03',
            },
            {
                name: 'Anibare District',
                state_code: '04',
            },
            {
                name: 'Baiti District',
                state_code: '05',
            },
            {
                name: 'Boe District',
                state_code: '06',
            },
            {
                name: 'Buada District',
                state_code: '07',
            },
            {
                name: 'Denigomodu District',
                state_code: '08',
            },
            {
                name: 'Ewa District',
                state_code: '09',
            },
            {
                name: 'Ijuw District',
                state_code: '10',
            },
            {
                name: 'Meneng District',
                state_code: '11',
            },
            {
                name: 'Nibok District',
                state_code: '12',
            },
            {
                name: 'Uaboe District',
                state_code: '13',
            },
            {
                name: 'Yaren District',
                state_code: '14',
            },
        ],
    },
    {
        name: 'Nepal',
        iso3: 'NPL',
        iso2: 'NP',
        states: [
            {
                name: 'Bagmati Zone',
                state_code: 'BA',
            },
            {
                name: 'Bheri Zone',
                state_code: 'BH',
            },
            {
                name: 'Central Region',
                state_code: '1',
            },
            {
                name: 'Dhaulagiri Zone',
                state_code: 'DH',
            },
            {
                name: 'Eastern Development Region',
                state_code: '4',
            },
            {
                name: 'Far-Western Development Region',
                state_code: '5',
            },
            {
                name: 'Gandaki Zone',
                state_code: 'GA',
            },
            {
                name: 'Janakpur Zone',
                state_code: 'JA',
            },
            {
                name: 'Karnali Zone',
                state_code: 'KA',
            },
            {
                name: 'Kosi Zone',
                state_code: 'KO',
            },
            {
                name: 'Lumbini Zone',
                state_code: 'LU',
            },
            {
                name: 'Mahakali Zone',
                state_code: 'MA',
            },
            {
                name: 'Mechi Zone',
                state_code: 'ME',
            },
            {
                name: 'Mid-Western Region',
                state_code: '2',
            },
            {
                name: 'Narayani Zone',
                state_code: 'NA',
            },
            {
                name: 'Rapti Zone',
                state_code: 'RA',
            },
            {
                name: 'Sagarmatha Zone',
                state_code: 'SA',
            },
            {
                name: 'Seti Zone',
                state_code: 'SE',
            },
            {
                name: 'Western Region',
                state_code: '3',
            },
        ],
    },
    {
        name: 'Netherlands Antilles',
        iso3: 'ANT',
        iso2: 'AN',
        states: [
            {
                name: 'Bonaire',
                state_code: 'BQ1',
            },
            {
                name: 'Saba',
                state_code: 'BQ2',
            },
            {
                name: 'Sint Eustatius',
                state_code: 'BQ3',
            },
        ],
    },
    {
        name: 'Netherlands',
        iso3: 'NLD',
        iso2: 'NL',
        states: [
            {
                name: 'Drenthe',
                state_code: 'DR',
            },
            {
                name: 'Flevoland',
                state_code: 'FL',
            },
            {
                name: 'Friesland',
                state_code: 'FR',
            },
            {
                name: 'Gelderland',
                state_code: 'GE',
            },
            {
                name: 'Groningen',
                state_code: 'GR',
            },
            {
                name: 'Limburg',
                state_code: 'LI',
            },
            {
                name: 'North Brabant',
                state_code: 'NB',
            },
            {
                name: 'North Holland',
                state_code: 'NH',
            },
            {
                name: 'Overijssel',
                state_code: 'OV',
            },
            {
                name: 'South Holland',
                state_code: 'ZH',
            },
            {
                name: 'Utrecht',
                state_code: 'UT',
            },
            {
                name: 'Zeeland',
                state_code: 'ZE',
            },
        ],
    },
    {
        name: 'New Caledonia',
        iso3: 'NCL',
        iso2: 'NC',
        states: [],
    },
    {
        name: 'New Zealand',
        iso3: 'NZL',
        iso2: 'NZ',
        states: [
            {
                name: 'Auckland Region',
                state_code: 'AUK',
            },
            {
                name: 'Bay of Plenty Region',
                state_code: 'BOP',
            },
            {
                name: 'Canterbury Region',
                state_code: 'CAN',
            },
            {
                name: 'Chatham Islands',
                state_code: 'CIT',
            },
            {
                name: 'Gisborne District',
                state_code: 'GIS',
            },
            {
                name: "Hawke's Bay Region",
                state_code: 'HKB',
            },
            {
                name: 'Manawatu-Wanganui Region',
                state_code: 'MWT',
            },
            {
                name: 'Marlborough Region',
                state_code: 'MBH',
            },
            {
                name: 'Nelson Region',
                state_code: 'NSN',
            },
            {
                name: 'Northland Region',
                state_code: 'NTL',
            },
            {
                name: 'Otago Region',
                state_code: 'OTA',
            },
            {
                name: 'Southland Region',
                state_code: 'STL',
            },
            {
                name: 'Taranaki Region',
                state_code: 'TKI',
            },
            {
                name: 'Tasman District',
                state_code: 'TAS',
            },
            {
                name: 'Waikato Region',
                state_code: 'WKO',
            },
            {
                name: 'Wellington Region',
                state_code: 'WGN',
            },
            {
                name: 'West Coast Region',
                state_code: 'WTC',
            },
        ],
    },
    {
        name: 'Nicaragua',
        iso3: 'NIC',
        iso2: 'NI',
        states: [
            {
                name: 'Boaco Department',
                state_code: 'BO',
            },
            {
                name: 'Carazo Department',
                state_code: 'CA',
            },
            {
                name: 'Chinandega Department',
                state_code: 'CI',
            },
            {
                name: 'Chontales Department',
                state_code: 'CO',
            },
            {
                name: 'Estelí Department',
                state_code: 'ES',
            },
            {
                name: 'Granada Department',
                state_code: 'GR',
            },
            {
                name: 'Jinotega Department',
                state_code: 'JI',
            },
            {
                name: 'León Department',
                state_code: 'LE',
            },
            {
                name: 'Madriz Department',
                state_code: 'MD',
            },
            {
                name: 'Managua Department',
                state_code: 'MN',
            },
            {
                name: 'Masaya Department',
                state_code: 'MS',
            },
            {
                name: 'Matagalpa Department',
                state_code: 'MT',
            },
            {
                name: 'North Caribbean Coast Autonomous Region',
                state_code: 'AN',
            },
            {
                name: 'Río San Juan Department',
                state_code: 'SJ',
            },
            {
                name: 'Rivas Department',
                state_code: 'RI',
            },
            {
                name: 'South Caribbean Coast Autonomous Region',
                state_code: 'AS',
            },
        ],
    },
    {
        name: 'Niger',
        iso3: 'NER',
        iso2: 'NE',
        states: [
            {
                name: 'Agadez Region',
                state_code: '1',
            },
            {
                name: 'Diffa Region',
                state_code: '2',
            },
            {
                name: 'Dosso Region',
                state_code: '3',
            },
            {
                name: 'Maradi Region',
                state_code: '4',
            },
            {
                name: 'Tahoua Region',
                state_code: '5',
            },
            {
                name: 'Tillabéri Region',
                state_code: '6',
            },
            {
                name: 'Zinder Region',
                state_code: '7',
            },
        ],
    },
    {
        name: 'Nigeria',
        iso3: 'NGA',
        iso2: 'NG',
        states: [
            {
                name: 'Abia State',
                state_code: 'AB',
            },
            {
                name: 'Adamawa State',
                state_code: 'AD',
            },
            {
                name: 'Akwa Ibom State',
                state_code: 'AK',
            },
            {
                name: 'Anambra State',
                state_code: 'AN',
            },
            {
                name: 'Bauchi State',
                state_code: 'BA',
            },
            {
                name: 'Bayelsa State',
                state_code: 'BY',
            },
            {
                name: 'Benue State',
                state_code: 'BE',
            },
            {
                name: 'Borno State',
                state_code: 'BO',
            },
            {
                name: 'Cross River State',
                state_code: 'CR',
            },
            {
                name: 'Delta State',
                state_code: 'DE',
            },
            {
                name: 'Ebonyi State',
                state_code: 'EB',
            },
            {
                name: 'Edo State',
                state_code: 'ED',
            },
            {
                name: 'Ekiti State',
                state_code: 'EK',
            },
            {
                name: 'Enugu State',
                state_code: 'EN',
            },
            {
                name: 'Federal Capital Territory',
                state_code: 'FC',
            },
            {
                name: 'Gombe State',
                state_code: 'GO',
            },
            {
                name: 'Imo State',
                state_code: 'IM',
            },
            {
                name: 'Jigawa State',
                state_code: 'JI',
            },
            {
                name: 'Kaduna State',
                state_code: 'KD',
            },
            {
                name: 'Kano State',
                state_code: 'KN',
            },
            {
                name: 'Katsina State',
                state_code: 'KT',
            },
            {
                name: 'Kebbi State',
                state_code: 'KE',
            },
            {
                name: 'Kogi State',
                state_code: 'KO',
            },
            {
                name: 'Kwara State',
                state_code: 'KW',
            },
            {
                name: 'Lagos',
                state_code: 'LA',
            },
            {
                name: 'Nasarawa State',
                state_code: 'NA',
            },
            {
                name: 'Niger State',
                state_code: 'NI',
            },
            {
                name: 'Ogun State',
                state_code: 'OG',
            },
            {
                name: 'Ondo State',
                state_code: 'ON',
            },
            {
                name: 'Osun State',
                state_code: 'OS',
            },
            {
                name: 'Oyo State',
                state_code: 'OY',
            },
            {
                name: 'Plateau State',
                state_code: 'PL',
            },
            {
                name: 'Sokoto State',
                state_code: 'SO',
            },
            {
                name: 'Taraba State',
                state_code: 'TA',
            },
            {
                name: 'Yobe State',
                state_code: 'YO',
            },
            {
                name: 'Zamfara State',
                state_code: 'ZA',
            },
        ],
    },
    {
        name: 'Niue',
        iso3: 'NIU',
        iso2: 'NU',
        states: [],
    },
    {
        name: 'Norfolk Island',
        iso3: 'NFK',
        iso2: 'NF',
        states: [],
    },
    {
        name: 'Northern Mariana Islands',
        iso3: 'MNP',
        iso2: 'MP',
        states: [],
    },
    {
        name: 'Norway',
        iso3: 'NOR',
        iso2: 'NO',
        states: [
            {
                name: 'Akershus',
                state_code: '02',
            },
            {
                name: 'Buskerud',
                state_code: '06',
            },
            {
                name: 'Finnmark',
                state_code: '20',
            },
            {
                name: 'Hedmark',
                state_code: '04',
            },
            {
                name: 'Hordaland',
                state_code: '12',
            },
            {
                name: 'Jan Mayen',
                state_code: '22',
            },
            {
                name: 'Møre og Romsdal',
                state_code: '15',
            },
            {
                name: 'Nord-Trøndelag',
                state_code: '17',
            },
            {
                name: 'Nordland',
                state_code: '18',
            },
            {
                name: 'Oppland',
                state_code: '05',
            },
            {
                name: 'Oslo',
                state_code: '03',
            },
            {
                name: 'Østfold',
                state_code: '01',
            },
            {
                name: 'Rogaland',
                state_code: '11',
            },
            {
                name: 'Sogn og Fjordane',
                state_code: '14',
            },
            {
                name: 'Sør-Trøndelag',
                state_code: '16',
            },
            {
                name: 'Svalbard',
                state_code: '21',
            },
            {
                name: 'Telemark',
                state_code: '08',
            },
            {
                name: 'Troms',
                state_code: '19',
            },
            {
                name: 'Trøndelag',
                state_code: '50',
            },
            {
                name: 'Vest-Agder',
                state_code: '10',
            },
            {
                name: 'Vestfold',
                state_code: '07',
            },
        ],
    },
    {
        name: 'Oman',
        iso3: 'OMN',
        iso2: 'OM',
        states: [
            {
                name: 'Ad Dakhiliyah Governorate',
                state_code: 'DA',
            },
            {
                name: 'Ad Dhahirah Governorate',
                state_code: 'ZA',
            },
            {
                name: 'Al Batinah North Governorate',
                state_code: 'BS',
            },
            {
                name: 'Al Batinah Region',
                state_code: 'BA',
            },
            {
                name: 'Al Batinah South Governorate',
                state_code: 'BJ',
            },
            {
                name: 'Al Buraimi Governorate',
                state_code: 'BU',
            },
            {
                name: 'Al Wusta Governorate',
                state_code: 'WU',
            },
            {
                name: 'Ash Sharqiyah North Governorate',
                state_code: 'SS',
            },
            {
                name: 'Ash Sharqiyah Region',
                state_code: 'SH',
            },
            {
                name: 'Ash Sharqiyah South Governorate',
                state_code: 'SJ',
            },
            {
                name: 'Dhofar Governorate',
                state_code: 'ZU',
            },
            {
                name: 'Musandam Governorate',
                state_code: 'MU',
            },
            {
                name: 'Muscat Governorate',
                state_code: 'MA',
            },
        ],
    },
    {
        name: 'Pakistan',
        iso3: 'PAK',
        iso2: 'PK',
        states: [
            {
                name: 'Azad Kashmir',
                state_code: 'JK',
            },
            {
                name: 'Balochistan',
                state_code: 'BA',
            },
            {
                name: 'Federally Administered Tribal Areas',
                state_code: 'TA',
            },
            {
                name: 'Gilgit-Baltistan',
                state_code: 'GB',
            },
            {
                name: 'Islamabad Capital Territory',
                state_code: 'IS',
            },
            {
                name: 'Khyber Pakhtunkhwa',
                state_code: 'KP',
            },
            {
                name: 'Punjab',
                state_code: 'PB',
            },
            {
                name: 'Sindh',
                state_code: 'SD',
            },
        ],
    },
    {
        name: 'Palau',
        iso3: 'PLW',
        iso2: 'PW',
        states: [
            {
                name: 'Aimeliik',
                state_code: '002',
            },
            {
                name: 'Airai',
                state_code: '004',
            },
            {
                name: 'Angaur',
                state_code: '010',
            },
            {
                name: 'Hatohobei',
                state_code: '050',
            },
            {
                name: 'Kayangel',
                state_code: '100',
            },
            {
                name: 'Koror',
                state_code: '150',
            },
            {
                name: 'Melekeok',
                state_code: '212',
            },
            {
                name: 'Ngaraard',
                state_code: '214',
            },
            {
                name: 'Ngarchelong',
                state_code: '218',
            },
            {
                name: 'Ngardmau',
                state_code: '222',
            },
            {
                name: 'Ngatpang',
                state_code: '224',
            },
            {
                name: 'Ngchesar',
                state_code: '226',
            },
            {
                name: 'Ngeremlengui',
                state_code: '227',
            },
            {
                name: 'Ngiwal',
                state_code: '228',
            },
            {
                name: 'Peleliu',
                state_code: '350',
            },
            {
                name: 'Sonsorol',
                state_code: '370',
            },
        ],
    },
    {
        name: 'Palestinian Territory Occupied',
        iso3: 'PSE',
        iso2: 'PS',
        states: [],
    },
    {
        name: 'Panama',
        iso3: 'PAN',
        iso2: 'PA',
        states: [
            {
                name: 'Bocas del Toro Province',
                state_code: '1',
            },
            {
                name: 'Chiriquí Province',
                state_code: '4',
            },
            {
                name: 'Coclé Province',
                state_code: '2',
            },
            {
                name: 'Colón Province',
                state_code: '3',
            },
            {
                name: 'Darién Province',
                state_code: '5',
            },
            {
                name: 'Emberá-Wounaan Comarca',
                state_code: 'EM',
            },
            {
                name: 'Guna Yala',
                state_code: 'KY',
            },
            {
                name: 'Herrera Province',
                state_code: '6',
            },
            {
                name: 'Los Santos Province',
                state_code: '7',
            },
            {
                name: 'Ngöbe-Buglé Comarca',
                state_code: 'NB',
            },
            {
                name: 'Panamá Oeste Province',
                state_code: '10',
            },
            {
                name: 'Panamá Province',
                state_code: '8',
            },
            {
                name: 'Veraguas Province',
                state_code: '9',
            },
        ],
    },
    {
        name: 'Papua new Guinea',
        iso3: 'PNG',
        iso2: 'PG',
        states: [
            {
                name: 'Bougainville',
                state_code: 'NSB',
            },
            {
                name: 'Central Province',
                state_code: 'CPM',
            },
            {
                name: 'Chimbu Province',
                state_code: 'CPK',
            },
            {
                name: 'East New Britain',
                state_code: 'EBR',
            },
            {
                name: 'Eastern Highlands Province',
                state_code: 'EHG',
            },
            {
                name: 'Enga Province',
                state_code: 'EPW',
            },
            {
                name: 'Gulf',
                state_code: 'GPK',
            },
            {
                name: 'Hela',
                state_code: 'HLA',
            },
            {
                name: 'Jiwaka Province',
                state_code: 'JWK',
            },
            {
                name: 'Madang Province',
                state_code: 'MPM',
            },
            {
                name: 'Manus Province',
                state_code: 'MRL',
            },
            {
                name: 'Milne Bay Province',
                state_code: 'MBA',
            },
            {
                name: 'Morobe Province',
                state_code: 'MPL',
            },
            {
                name: 'New Ireland Province',
                state_code: 'NIK',
            },
            {
                name: 'Oro Province',
                state_code: 'NPP',
            },
            {
                name: 'Port Moresby',
                state_code: 'NCD',
            },
            {
                name: 'Sandaun Province',
                state_code: 'SAN',
            },
            {
                name: 'Southern Highlands Province',
                state_code: 'SHM',
            },
            {
                name: 'West New Britain Province',
                state_code: 'WBK',
            },
            {
                name: 'Western Highlands Province',
                state_code: 'WHM',
            },
            {
                name: 'Western Province',
                state_code: 'WPD',
            },
        ],
    },
    {
        name: 'Paraguay',
        iso3: 'PRY',
        iso2: 'PY',
        states: [
            {
                name: 'Alto Paraguay Department',
                state_code: '16',
            },
            {
                name: 'Alto Paraná Department',
                state_code: '10',
            },
            {
                name: 'Amambay Department',
                state_code: '13',
            },
            {
                name: 'Boquerón Department',
                state_code: '19',
            },
            {
                name: 'Caaguazú',
                state_code: '5',
            },
            {
                name: 'Caazapá',
                state_code: '6',
            },
            {
                name: 'Canindeyú',
                state_code: '14',
            },
            {
                name: 'Central Department',
                state_code: '11',
            },
            {
                name: 'Concepción Department',
                state_code: '1',
            },
            {
                name: 'Cordillera Department',
                state_code: '3',
            },
            {
                name: 'Guairá Department',
                state_code: '4',
            },
            {
                name: 'Itapúa',
                state_code: '7',
            },
            {
                name: 'Misiones Department',
                state_code: '8',
            },
            {
                name: 'Ñeembucú Department',
                state_code: '12',
            },
            {
                name: 'Paraguarí Department',
                state_code: '9',
            },
            {
                name: 'Presidente Hayes Department',
                state_code: '15',
            },
            {
                name: 'San Pedro Department',
                state_code: '2',
            },
        ],
    },
    {
        name: 'Peru',
        iso3: 'PER',
        iso2: 'PE',
        states: [
            {
                name: 'Amazonas',
                state_code: 'AMA',
            },
            {
                name: 'Áncash',
                state_code: 'ANC',
            },
            {
                name: 'Apurímac',
                state_code: 'APU',
            },
            {
                name: 'Arequipa',
                state_code: 'ARE',
            },
            {
                name: 'Ayacucho',
                state_code: 'AYA',
            },
            {
                name: 'Cajamarca',
                state_code: 'CAJ',
            },
            {
                name: 'Callao',
                state_code: 'CAL',
            },
            {
                name: 'Cusco',
                state_code: 'CUS',
            },
            {
                name: 'Huancavelica',
                state_code: 'HUV',
            },
            {
                name: 'Huanuco',
                state_code: 'HUC',
            },
            {
                name: 'Ica',
                state_code: 'ICA',
            },
            {
                name: 'Junín',
                state_code: 'JUN',
            },
            {
                name: 'La Libertad',
                state_code: 'LAL',
            },
            {
                name: 'Lambayeque',
                state_code: 'LAM',
            },
            {
                name: 'Lima',
                state_code: 'LIM',
            },
            {
                name: 'Madre de Dios',
                state_code: 'MDD',
            },
            {
                name: 'Moquegua',
                state_code: 'MOQ',
            },
            {
                name: 'Pasco',
                state_code: 'PAS',
            },
            {
                name: 'Piura',
                state_code: 'PIU',
            },
            {
                name: 'Puno',
                state_code: 'PUN',
            },
            {
                name: 'San Martín',
                state_code: 'SAM',
            },
            {
                name: 'Tacna',
                state_code: 'TAC',
            },
            {
                name: 'Tumbes',
                state_code: 'TUM',
            },
            {
                name: 'Ucayali',
                state_code: 'UCA',
            },
        ],
    },
    {
        name: 'Philippines',
        iso3: 'PHL',
        iso2: 'PH',
        states: [
            {
                name: 'Abra',
                state_code: 'ABR',
            },
            {
                name: 'Agusan del Norte',
                state_code: 'AGN',
            },
            {
                name: 'Agusan del Sur',
                state_code: 'AGS',
            },
            {
                name: 'Aklan',
                state_code: 'AKL',
            },
            {
                name: 'Albay',
                state_code: 'ALB',
            },
            {
                name: 'Antique',
                state_code: 'ANT',
            },
            {
                name: 'Apayao',
                state_code: 'APA',
            },
            {
                name: 'Aurora',
                state_code: 'AUR',
            },
            {
                name: 'Autonomous Region in Muslim Mindanao',
                state_code: '14',
            },
            {
                name: 'Basilan',
                state_code: 'BAS',
            },
            {
                name: 'Bataan',
                state_code: 'BAN',
            },
            {
                name: 'Batanes',
                state_code: 'BTN',
            },
            {
                name: 'Batangas',
                state_code: 'BTG',
            },
            {
                name: 'Benguet',
                state_code: 'BEN',
            },
            {
                name: 'Bicol Region',
                state_code: '05',
            },
            {
                name: 'Biliran',
                state_code: 'BIL',
            },
            {
                name: 'Bohol',
                state_code: 'BOH',
            },
            {
                name: 'Bukidnon',
                state_code: 'BUK',
            },
            {
                name: 'Bulacan',
                state_code: 'BUL',
            },
            {
                name: 'Cagayan',
                state_code: 'CAG',
            },
            {
                name: 'Cagayan Valley',
                state_code: '02',
            },
            {
                name: 'Calabarzon',
                state_code: '40',
            },
            {
                name: 'Camarines Norte',
                state_code: 'CAN',
            },
            {
                name: 'Camarines Sur',
                state_code: 'CAS',
            },
            {
                name: 'Camiguin',
                state_code: 'CAM',
            },
            {
                name: 'Capiz',
                state_code: 'CAP',
            },
            {
                name: 'Caraga',
                state_code: '13',
            },
            {
                name: 'Catanduanes',
                state_code: 'CAT',
            },
            {
                name: 'Cavite',
                state_code: 'CAV',
            },
            {
                name: 'Cebu',
                state_code: 'CEB',
            },
            {
                name: 'Central Luzon',
                state_code: '03',
            },
            {
                name: 'Central Visayas',
                state_code: '07',
            },
            {
                name: 'Compostela Valley',
                state_code: 'COM',
            },
            {
                name: 'Cordillera Administrative Region',
                state_code: '15',
            },
            {
                name: 'Cotabato',
                state_code: 'NCO',
            },
            {
                name: 'Davao del Norte',
                state_code: 'DAV',
            },
            {
                name: 'Davao del Sur',
                state_code: 'DAS',
            },
            {
                name: 'Davao Occidental',
                state_code: 'DVO',
            },
            {
                name: 'Davao Oriental',
                state_code: 'DAO',
            },
            {
                name: 'Davao Region',
                state_code: '11',
            },
            {
                name: 'Dinagat Islands',
                state_code: 'DIN',
            },
            {
                name: 'Eastern Samar',
                state_code: 'EAS',
            },
            {
                name: 'Eastern Visayas',
                state_code: '08',
            },
            {
                name: 'Guimaras',
                state_code: 'GUI',
            },
            {
                name: 'Ifugao',
                state_code: 'IFU',
            },
            {
                name: 'Ilocos Norte',
                state_code: 'ILN',
            },
            {
                name: 'Ilocos Region',
                state_code: '01',
            },
            {
                name: 'Ilocos Sur',
                state_code: 'ILS',
            },
            {
                name: 'Iloilo',
                state_code: 'ILI',
            },
            {
                name: 'Isabela',
                state_code: 'ISA',
            },
            {
                name: 'Kalinga',
                state_code: 'KAL',
            },
            {
                name: 'La Union',
                state_code: 'LUN',
            },
            {
                name: 'Laguna',
                state_code: 'LAG',
            },
            {
                name: 'Lanao del Norte',
                state_code: 'LAN',
            },
            {
                name: 'Lanao del Sur',
                state_code: 'LAS',
            },
            {
                name: 'Leyte',
                state_code: 'LEY',
            },
            {
                name: 'Maguindanao',
                state_code: 'MAG',
            },
            {
                name: 'Marinduque',
                state_code: 'MAD',
            },
            {
                name: 'Masbate',
                state_code: 'MAS',
            },
            {
                name: 'Metro Manila',
                state_code: '00',
            },
            {
                name: 'Mimaropa',
                state_code: '41',
            },
            {
                name: 'Misamis Occidental',
                state_code: 'MSC',
            },
            {
                name: 'Misamis Oriental',
                state_code: 'MSR',
            },
            {
                name: 'Mountain Province',
                state_code: 'MOU',
            },
            {
                name: 'Negros Occidental',
                state_code: 'NEC',
            },
            {
                name: 'Negros Oriental',
                state_code: 'NER',
            },
            {
                name: 'Northern Mindanao',
                state_code: '10',
            },
            {
                name: 'Northern Samar',
                state_code: 'NSA',
            },
            {
                name: 'Nueva Ecija',
                state_code: 'NUE',
            },
            {
                name: 'Nueva Vizcaya',
                state_code: 'NUV',
            },
            {
                name: 'Occidental Mindoro',
                state_code: 'MDC',
            },
            {
                name: 'Oriental Mindoro',
                state_code: 'MDR',
            },
            {
                name: 'Palawan',
                state_code: 'PLW',
            },
            {
                name: 'Pampanga',
                state_code: 'PAM',
            },
            {
                name: 'Pangasinan',
                state_code: 'PAN',
            },
            {
                name: 'Quezon',
                state_code: 'QUE',
            },
            {
                name: 'Quirino',
                state_code: 'QUI',
            },
            {
                name: 'Rizal',
                state_code: 'RIZ',
            },
            {
                name: 'Romblon',
                state_code: 'ROM',
            },
            {
                name: 'Sarangani',
                state_code: 'SAR',
            },
            {
                name: 'Siquijor',
                state_code: 'SIG',
            },
            {
                name: 'Soccsksargen',
                state_code: '12',
            },
            {
                name: 'Sorsogon',
                state_code: 'SOR',
            },
            {
                name: 'South Cotabato',
                state_code: 'SCO',
            },
            {
                name: 'Southern Leyte',
                state_code: 'SLE',
            },
            {
                name: 'Sultan Kudarat',
                state_code: 'SUK',
            },
            {
                name: 'Sulu',
                state_code: 'SLU',
            },
            {
                name: 'Surigao del Norte',
                state_code: 'SUN',
            },
            {
                name: 'Surigao del Sur',
                state_code: 'SUR',
            },
            {
                name: 'Tarlac',
                state_code: 'TAR',
            },
            {
                name: 'Tawi-Tawi',
                state_code: 'TAW',
            },
            {
                name: 'Western Visayas',
                state_code: '06',
            },
            {
                name: 'Zambales',
                state_code: 'ZMB',
            },
            {
                name: 'Zamboanga del Norte',
                state_code: 'ZAN',
            },
            {
                name: 'Zamboanga del Sur',
                state_code: 'ZAS',
            },
            {
                name: 'Zamboanga Peninsula',
                state_code: '09',
            },
            {
                name: 'Zamboanga Sibugay',
                state_code: 'ZSI',
            },
        ],
    },
    {
        name: 'Pitcairn Island',
        iso3: 'PCN',
        iso2: 'PN',
        states: [],
    },
    {
        name: 'Poland',
        iso3: 'POL',
        iso2: 'PL',
        states: [
            {
                name: 'Greater Poland Voivodeship',
                state_code: 'WP',
            },
            {
                name: 'Kielce',
                state_code: 'KI',
            },
            {
                name: 'Kuyavian-Pomeranian Voivodeship',
                state_code: 'KP',
            },
            {
                name: 'Lesser Poland Voivodeship',
                state_code: 'MA',
            },
            {
                name: 'Lower Silesian Voivodeship',
                state_code: 'DS',
            },
            {
                name: 'Lublin Voivodeship',
                state_code: 'LU',
            },
            {
                name: 'Lubusz Voivodeship',
                state_code: 'LB',
            },
            {
                name: 'Łódź Voivodeship',
                state_code: 'LD',
            },
            {
                name: 'Masovian Voivodeship',
                state_code: 'MZ',
            },
            {
                name: 'Opole Voivodeship',
                state_code: 'OP',
            },
            {
                name: 'Podkarpackie Voivodeship',
                state_code: 'PK',
            },
            {
                name: 'Podlaskie Voivodeship',
                state_code: 'PD',
            },
            {
                name: 'Pomeranian Voivodeship',
                state_code: 'PM',
            },
            {
                name: 'Silesian Voivodeship',
                state_code: 'SL',
            },
            {
                name: 'Świętokrzyskie Voivodeship',
                state_code: 'SK',
            },
            {
                name: 'Warmian-Masurian Voivodeship',
                state_code: 'WN',
            },
            {
                name: 'West Pomeranian Voivodeship',
                state_code: 'ZP',
            },
        ],
    },
    {
        name: 'Portugal',
        iso3: 'PRT',
        iso2: 'PT',
        states: [
            {
                name: 'Aveiro District',
                state_code: '01',
            },
            {
                name: 'Azores',
                state_code: '20',
            },
            {
                name: 'Beja District',
                state_code: '02',
            },
            {
                name: 'Braga District',
                state_code: '03',
            },
            {
                name: 'Bragança District',
                state_code: '04',
            },
            {
                name: 'Castelo Branco District',
                state_code: '05',
            },
            {
                name: 'Coimbra District',
                state_code: '06',
            },
            {
                name: 'Évora District',
                state_code: '07',
            },
            {
                name: 'Faro District',
                state_code: '08',
            },
            {
                name: 'Guarda District',
                state_code: '09',
            },
            {
                name: 'Leiria District',
                state_code: '10',
            },
            {
                name: 'Lisbon District',
                state_code: '11',
            },
            {
                name: 'Madeira',
                state_code: '30',
            },
            {
                name: 'Portalegre District',
                state_code: '12',
            },
            {
                name: 'Porto District',
                state_code: '13',
            },
            {
                name: 'Santarém District',
                state_code: '14',
            },
            {
                name: 'Setúbal District',
                state_code: '15',
            },
            {
                name: 'Viana do Castelo District',
                state_code: '16',
            },
            {
                name: 'Vila Real District',
                state_code: '17',
            },
            {
                name: 'Viseu District',
                state_code: '18',
            },
        ],
    },
    {
        name: 'Puerto Rico',
        iso3: 'PRI',
        iso2: 'PR',
        states: [],
    },
    {
        name: 'Qatar',
        iso3: 'QAT',
        iso2: 'QA',
        states: [
            {
                name: 'Al Daayen',
                state_code: 'ZA',
            },
            {
                name: 'Al Khor',
                state_code: 'KH',
            },
            {
                name: 'Al Rayyan Municipality',
                state_code: 'RA',
            },
            {
                name: 'Al Wakrah',
                state_code: 'WA',
            },
            {
                name: 'Al-Shahaniya',
                state_code: 'SH',
            },
            {
                name: 'Doha',
                state_code: 'DA',
            },
            {
                name: 'Madinat ash Shamal',
                state_code: 'MS',
            },
            {
                name: 'Umm Salal Municipality',
                state_code: 'US',
            },
        ],
    },
    {
        name: 'Réunion',
        iso3: 'REU',
        iso2: 'RE',
        states: [],
    },
    {
        name: 'Romania',
        iso3: 'ROU',
        iso2: 'RO',
        states: [
            {
                name: 'Alba',
                state_code: 'AB',
            },
            {
                name: 'Arad County',
                state_code: 'AR',
            },
            {
                name: 'Arges',
                state_code: 'AG',
            },
            {
                name: 'Bacău County',
                state_code: 'BC',
            },
            {
                name: 'Bihor County',
                state_code: 'BH',
            },
            {
                name: 'Bistrița-Năsăud County',
                state_code: 'BN',
            },
            {
                name: 'Botoșani County',
                state_code: 'BT',
            },
            {
                name: 'Braila',
                state_code: 'BR',
            },
            {
                name: 'Brașov County',
                state_code: 'BV',
            },
            {
                name: 'Bucharest',
                state_code: 'B',
            },
            {
                name: 'Buzău County',
                state_code: 'BZ',
            },
            {
                name: 'Călărași County',
                state_code: 'CL',
            },
            {
                name: 'Caraș-Severin County',
                state_code: 'CS',
            },
            {
                name: 'Cluj County',
                state_code: 'CJ',
            },
            {
                name: 'Constanța County',
                state_code: 'CT',
            },
            {
                name: 'Covasna County',
                state_code: 'CV',
            },
            {
                name: 'Dâmbovița County',
                state_code: 'DB',
            },
            {
                name: 'Dolj County',
                state_code: 'DJ',
            },
            {
                name: 'Galați County',
                state_code: 'GL',
            },
            {
                name: 'Giurgiu County',
                state_code: 'GR',
            },
            {
                name: 'Gorj County',
                state_code: 'GJ',
            },
            {
                name: 'Harghita County',
                state_code: 'HR',
            },
            {
                name: 'Hunedoara County',
                state_code: 'HD',
            },
            {
                name: 'Ialomița County',
                state_code: 'IL',
            },
            {
                name: 'Iași County',
                state_code: 'IS',
            },
            {
                name: 'Ilfov County',
                state_code: 'IF',
            },
            {
                name: 'Maramureș County',
                state_code: 'MM',
            },
            {
                name: 'Mehedinți County',
                state_code: 'MH',
            },
            {
                name: 'Neamț County',
                state_code: 'NT',
            },
            {
                name: 'Olt County',
                state_code: 'OT',
            },
            {
                name: 'Prahova County',
                state_code: 'PH',
            },
            {
                name: 'Sălaj County',
                state_code: 'SJ',
            },
            {
                name: 'Satu Mare County',
                state_code: 'SM',
            },
            {
                name: 'Sibiu County',
                state_code: 'SB',
            },
            {
                name: 'Suceava County',
                state_code: 'SV',
            },
            {
                name: 'Teleorman County',
                state_code: 'TR',
            },
            {
                name: 'Timiș County',
                state_code: 'TM',
            },
            {
                name: 'Tulcea County',
                state_code: 'TL',
            },
            {
                name: 'Vâlcea County',
                state_code: 'VL',
            },
            {
                name: 'Vaslui County',
                state_code: 'VS',
            },
            {
                name: 'Vrancea County',
                state_code: 'VN',
            },
        ],
    },
    {
        name: 'Russia',
        iso3: 'RUS',
        iso2: 'RU',
        states: [
            {
                name: 'Altai Krai',
                state_code: 'ALT',
            },
            {
                name: 'Altai Republic',
                state_code: 'AL',
            },
            {
                name: 'Amur Oblast',
                state_code: 'AMU',
            },
            {
                name: 'Arkhangelsk',
                state_code: 'ARK',
            },
            {
                name: 'Astrakhan Oblast',
                state_code: 'AST',
            },
            {
                name: 'Belgorod Oblast',
                state_code: 'BEL',
            },
            {
                name: 'Bryansk Oblast',
                state_code: 'BRY',
            },
            {
                name: 'Chechen Republic',
                state_code: 'CE',
            },
            {
                name: 'Chelyabinsk Oblast',
                state_code: 'CHE',
            },
            {
                name: 'Chukotka Autonomous Okrug',
                state_code: 'CHU',
            },
            {
                name: 'Chuvash Republic',
                state_code: 'CU',
            },
            {
                name: 'Irkutsk',
                state_code: 'IRK',
            },
            {
                name: 'Ivanovo Oblast',
                state_code: 'IVA',
            },
            {
                name: 'Jewish Autonomous Oblast',
                state_code: 'YEV',
            },
            {
                name: 'Kabardino-Balkar Republic',
                state_code: 'KB',
            },
            {
                name: 'Kaliningrad',
                state_code: 'KGD',
            },
            {
                name: 'Kaluga Oblast',
                state_code: 'KLU',
            },
            {
                name: 'Kamchatka Krai',
                state_code: 'KAM',
            },
            {
                name: 'Karachay-Cherkess Republic',
                state_code: 'KC',
            },
            {
                name: 'Kemerovo Oblast',
                state_code: 'KEM',
            },
            {
                name: 'Khabarovsk Krai',
                state_code: 'KHA',
            },
            {
                name: 'Khanty-Mansi Autonomous Okrug',
                state_code: 'KHM',
            },
            {
                name: 'Kirov Oblast',
                state_code: 'KIR',
            },
            {
                name: 'Komi Republic',
                state_code: 'KO',
            },
            {
                name: 'Kostroma Oblast',
                state_code: 'KOS',
            },
            {
                name: 'Krasnodar Krai',
                state_code: 'KDA',
            },
            {
                name: 'Krasnoyarsk Krai',
                state_code: 'KYA',
            },
            {
                name: 'Kurgan Oblast',
                state_code: 'KGN',
            },
            {
                name: 'Kursk Oblast',
                state_code: 'KRS',
            },
            {
                name: 'Leningrad Oblast',
                state_code: 'LEN',
            },
            {
                name: 'Lipetsk Oblast',
                state_code: 'LIP',
            },
            {
                name: 'Magadan Oblast',
                state_code: 'MAG',
            },
            {
                name: 'Mari El Republic',
                state_code: 'ME',
            },
            {
                name: 'Moscow',
                state_code: 'MOW',
            },
            {
                name: 'Moscow Oblast',
                state_code: 'MOS',
            },
            {
                name: 'Murmansk Oblast',
                state_code: 'MUR',
            },
            {
                name: 'Nenets Autonomous Okrug',
                state_code: 'NEN',
            },
            {
                name: 'Nizhny Novgorod Oblast',
                state_code: 'NIZ',
            },
            {
                name: 'Novgorod Oblast',
                state_code: 'NGR',
            },
            {
                name: 'Novosibirsk',
                state_code: 'NVS',
            },
            {
                name: 'Omsk Oblast',
                state_code: 'OMS',
            },
            {
                name: 'Orenburg Oblast',
                state_code: 'ORE',
            },
            {
                name: 'Oryol Oblast',
                state_code: 'ORL',
            },
            {
                name: 'Penza Oblast',
                state_code: 'PNZ',
            },
            {
                name: 'Perm Krai',
                state_code: 'PER',
            },
            {
                name: 'Primorsky Krai',
                state_code: 'PRI',
            },
            {
                name: 'Pskov Oblast',
                state_code: 'PSK',
            },
            {
                name: 'Republic of Adygea',
                state_code: 'AD',
            },
            {
                name: 'Republic of Bashkortostan',
                state_code: 'BA',
            },
            {
                name: 'Republic of Buryatia',
                state_code: 'BU',
            },
            {
                name: 'Republic of Dagestan',
                state_code: 'DA',
            },
            {
                name: 'Republic of Ingushetia',
                state_code: 'IN',
            },
            {
                name: 'Republic of Kalmykia',
                state_code: 'KL',
            },
            {
                name: 'Republic of Karelia',
                state_code: 'KR',
            },
            {
                name: 'Republic of Khakassia',
                state_code: 'KK',
            },
            {
                name: 'Republic of Mordovia',
                state_code: 'MO',
            },
            {
                name: 'Republic of North Ossetia-Alania',
                state_code: 'SE',
            },
            {
                name: 'Republic of Tatarstan',
                state_code: 'TA',
            },
            {
                name: 'Rostov Oblast',
                state_code: 'ROS',
            },
            {
                name: 'Ryazan Oblast',
                state_code: 'RYA',
            },
            {
                name: 'Saint Petersburg',
                state_code: 'SPE',
            },
            {
                name: 'Sakha Republic',
                state_code: 'SA',
            },
            {
                name: 'Sakhalin',
                state_code: 'SAK',
            },
            {
                name: 'Samara Oblast',
                state_code: 'SAM',
            },
            {
                name: 'Saratov Oblast',
                state_code: 'SAR',
            },
            {
                name: 'Sevastopol',
                state_code: 'UA-40',
            },
            {
                name: 'Smolensk Oblast',
                state_code: 'SMO',
            },
            {
                name: 'Stavropol Krai',
                state_code: 'STA',
            },
            {
                name: 'Sverdlovsk',
                state_code: 'SVE',
            },
            {
                name: 'Tambov Oblast',
                state_code: 'TAM',
            },
            {
                name: 'Tomsk Oblast',
                state_code: 'TOM',
            },
            {
                name: 'Tula Oblast',
                state_code: 'TUL',
            },
            {
                name: 'Tuva Republic',
                state_code: 'TY',
            },
            {
                name: 'Tver Oblast',
                state_code: 'TVE',
            },
            {
                name: 'Tyumen Oblast',
                state_code: 'TYU',
            },
            {
                name: 'Udmurt Republic',
                state_code: 'UD',
            },
            {
                name: 'Ulyanovsk Oblast',
                state_code: 'ULY',
            },
            {
                name: 'Vladimir Oblast',
                state_code: 'VLA',
            },
            {
                name: 'Vologda Oblast',
                state_code: 'VLG',
            },
            {
                name: 'Voronezh Oblast',
                state_code: 'VOR',
            },
            {
                name: 'Yamalo-Nenets Autonomous Okrug',
                state_code: 'YAN',
            },
            {
                name: 'Yaroslavl Oblast',
                state_code: 'YAR',
            },
            {
                name: 'Zabaykalsky Krai',
                state_code: 'ZAB',
            },
        ],
    },
    {
        name: 'Rwanda',
        iso3: 'RWA',
        iso2: 'RW',
        states: [
            {
                name: 'Eastern Province',
                state_code: '02',
            },
            {
                name: 'Kigali district',
                state_code: '01',
            },
            {
                name: 'Northern Province',
                state_code: '03',
            },
            {
                name: 'Southern Province',
                state_code: '05',
            },
            {
                name: 'Western Province',
                state_code: '04',
            },
        ],
    },
    {
        name: 'Saint Helena',
        iso3: 'SHN',
        iso2: 'SH',
        states: [],
    },
    {
        name: 'Saint Kitts And Nevis',
        iso3: 'KNA',
        iso2: 'KN',
        states: [
            {
                name: 'Christ Church Nichola Town Parish',
                state_code: '01',
            },
            {
                name: 'Nevis',
                state_code: 'N',
            },
            {
                name: 'Saint Anne Sandy Point Parish',
                state_code: '02',
            },
            {
                name: 'Saint George Gingerland Parish',
                state_code: '04',
            },
            {
                name: 'Saint James Windward Parish',
                state_code: '05',
            },
            {
                name: 'Saint John Capisterre Parish',
                state_code: '06',
            },
            {
                name: 'Saint John Figtree Parish',
                state_code: '07',
            },
            {
                name: 'Saint Kitts',
                state_code: 'K',
            },
            {
                name: 'Saint Mary Cayon Parish',
                state_code: '08',
            },
            {
                name: 'Saint Paul Capisterre Parish',
                state_code: '09',
            },
            {
                name: 'Saint Paul Charlestown Parish',
                state_code: '10',
            },
            {
                name: 'Saint Peter Basseterre Parish',
                state_code: '11',
            },
            {
                name: 'Saint Thomas Lowland Parish',
                state_code: '12',
            },
            {
                name: 'Saint Thomas Middle Island Parish',
                state_code: '13',
            },
            {
                name: 'Trinity Palmetto Point Parish',
                state_code: '15',
            },
        ],
    },
    {
        name: 'Saint Lucia',
        iso3: 'LCA',
        iso2: 'LC',
        states: [
            {
                name: 'Anse la Raye Quarter',
                state_code: '01',
            },
            {
                name: 'Canaries',
                state_code: '12',
            },
            {
                name: 'Castries Quarter',
                state_code: '02',
            },
            {
                name: 'Choiseul Quarter',
                state_code: '03',
            },
            {
                name: 'Dauphin Quarter',
                state_code: '04',
            },
            {
                name: 'Dennery Quarter',
                state_code: '05',
            },
            {
                name: 'Gros Islet Quarter',
                state_code: '06',
            },
            {
                name: 'Laborie Quarter',
                state_code: '07',
            },
            {
                name: 'Micoud Quarter',
                state_code: '08',
            },
            {
                name: 'Praslin Quarter',
                state_code: '09',
            },
            {
                name: 'Soufrière Quarter',
                state_code: '10',
            },
            {
                name: 'Vieux Fort Quarter',
                state_code: '11',
            },
        ],
    },
    {
        name: 'Saint Pierre and Miquelon',
        iso3: 'SPM',
        iso2: 'PM',
        states: [],
    },
    {
        name: 'Saint Vincent And The Grenadines',
        iso3: 'VCT',
        iso2: 'VC',
        states: [
            {
                name: 'Charlotte Parish',
                state_code: '01',
            },
            {
                name: 'Grenadines Parish',
                state_code: '06',
            },
            {
                name: 'Saint Andrew Parish',
                state_code: '02',
            },
            {
                name: 'Saint David Parish',
                state_code: '03',
            },
            {
                name: 'Saint George Parish',
                state_code: '04',
            },
            {
                name: 'Saint Patrick Parish',
                state_code: '05',
            },
        ],
    },
    {
        name: 'Saint-Barthelemy',
        iso3: 'BLM',
        iso2: 'BL',
        states: [],
    },
    {
        name: 'Saint-Martin (French part)',
        iso3: 'MAF',
        iso2: 'MF',
        states: [],
    },
    {
        name: 'Samoa',
        iso3: 'WSM',
        iso2: 'WS',
        states: [
            {
                name: "A'ana",
                state_code: 'AA',
            },
            {
                name: 'Aiga-i-le-Tai',
                state_code: 'AL',
            },
            {
                name: 'Atua',
                state_code: 'AT',
            },
            {
                name: "Fa'asaleleaga",
                state_code: 'FA',
            },
            {
                name: "Gaga'emauga",
                state_code: 'GE',
            },
            {
                name: "Gaga'ifomauga",
                state_code: 'GI',
            },
            {
                name: 'Palauli',
                state_code: 'PA',
            },
            {
                name: "Satupa'itea",
                state_code: 'SA',
            },
            {
                name: 'Tuamasaga',
                state_code: 'TU',
            },
            {
                name: "Va'a-o-Fonoti",
                state_code: 'VF',
            },
            {
                name: 'Vaisigano',
                state_code: 'VS',
            },
        ],
    },
    {
        name: 'San Marino',
        iso3: 'SMR',
        iso2: 'SM',
        states: [
            {
                name: 'Acquaviva',
                state_code: '01',
            },
            {
                name: 'Borgo Maggiore',
                state_code: '06',
            },
            {
                name: 'Chiesanuova',
                state_code: '02',
            },
            {
                name: 'Domagnano',
                state_code: '03',
            },
            {
                name: 'Faetano',
                state_code: '04',
            },
            {
                name: 'Fiorentino',
                state_code: '05',
            },
            {
                name: 'Montegiardino',
                state_code: '08',
            },
            {
                name: 'San Marino',
                state_code: '07',
            },
            {
                name: 'Serravalle',
                state_code: '09',
            },
        ],
    },
    {
        name: 'Sao Tome and Principe',
        iso3: 'STP',
        iso2: 'ST',
        states: [
            {
                name: 'Príncipe Province',
                state_code: 'P',
            },
            {
                name: 'São Tomé Province',
                state_code: 'S',
            },
        ],
    },
    {
        name: 'Saudi Arabia',
        iso3: 'SAU',
        iso2: 'SA',
        states: [
            {
                name: "'Asir Region",
                state_code: '14',
            },
            {
                name: 'Al Bahah Region',
                state_code: '11',
            },
            {
                name: 'Al Jawf Region',
                state_code: '12',
            },
            {
                name: 'Al Madinah Region',
                state_code: '03',
            },
            {
                name: 'Al-Qassim Region',
                state_code: '05',
            },
            {
                name: 'Eastern Province',
                state_code: '04',
            },
            {
                name: "Ha'il Region",
                state_code: '06',
            },
            {
                name: 'Jizan Region',
                state_code: '09',
            },
            {
                name: 'Makkah Region',
                state_code: '02',
            },
            {
                name: 'Najran Region',
                state_code: '10',
            },
            {
                name: 'Northern Borders Region',
                state_code: '08',
            },
            {
                name: 'Riyadh Region',
                state_code: '01',
            },
            {
                name: 'Tabuk Region',
                state_code: '07',
            },
        ],
    },
    {
        name: 'Senegal',
        iso3: 'SEN',
        iso2: 'SN',
        states: [
            {
                name: 'Dakar',
                state_code: 'DK',
            },
            {
                name: 'Diourbel Region',
                state_code: 'DB',
            },
            {
                name: 'Fatick',
                state_code: 'FK',
            },
            {
                name: 'Kaffrine',
                state_code: 'KA',
            },
            {
                name: 'Kaolack',
                state_code: 'KL',
            },
            {
                name: 'Kédougou',
                state_code: 'KE',
            },
            {
                name: 'Kolda',
                state_code: 'KD',
            },
            {
                name: 'Louga',
                state_code: 'LG',
            },
            {
                name: 'Matam',
                state_code: 'MT',
            },
            {
                name: 'Saint-Louis',
                state_code: 'SL',
            },
            {
                name: 'Sédhiou',
                state_code: 'SE',
            },
            {
                name: 'Tambacounda Region',
                state_code: 'TC',
            },
            {
                name: 'Thiès Region',
                state_code: 'TH',
            },
            {
                name: 'Ziguinchor',
                state_code: 'ZG',
            },
        ],
    },
    {
        name: 'Serbia',
        iso3: 'SRB',
        iso2: 'RS',
        states: [
            {
                name: 'Belgrade',
                state_code: '00',
            },
            {
                name: 'Bor District',
                state_code: '14',
            },
            {
                name: 'Braničevo District',
                state_code: '11',
            },
            {
                name: 'Central Banat District',
                state_code: '02',
            },
            {
                name: 'Jablanica District',
                state_code: '23',
            },
            {
                name: 'Kolubara District',
                state_code: '09',
            },
            {
                name: 'Mačva District',
                state_code: '08',
            },
            {
                name: 'Moravica District',
                state_code: '17',
            },
            {
                name: 'Nišava District',
                state_code: '20',
            },
            {
                name: 'North Bačka District',
                state_code: '01',
            },
            {
                name: 'North Banat District',
                state_code: '03',
            },
            {
                name: 'Pčinja District',
                state_code: '24',
            },
            {
                name: 'Pirot District',
                state_code: '22',
            },
            {
                name: 'Podunavlje District',
                state_code: '10',
            },
            {
                name: 'Pomoravlje District',
                state_code: '13',
            },
            {
                name: 'Rasina District',
                state_code: '19',
            },
            {
                name: 'Raška District',
                state_code: '18',
            },
            {
                name: 'South Bačka District',
                state_code: '06',
            },
            {
                name: 'South Banat District',
                state_code: '04',
            },
            {
                name: 'Srem District',
                state_code: '07',
            },
            {
                name: 'Šumadija District',
                state_code: '12',
            },
            {
                name: 'Toplica District',
                state_code: '21',
            },
            {
                name: 'Vojvodina',
                state_code: 'VO',
            },
            {
                name: 'West Bačka District',
                state_code: '05',
            },
            {
                name: 'Zaječar District',
                state_code: '15',
            },
            {
                name: 'Zlatibor District',
                state_code: '16',
            },
        ],
    },
    {
        name: 'Seychelles',
        iso3: 'SYC',
        iso2: 'SC',
        states: [
            {
                name: 'Anse Boileau',
                state_code: '02',
            },
            {
                name: 'Anse Royale',
                state_code: '05',
            },
            {
                name: 'Anse-aux-Pins',
                state_code: '01',
            },
            {
                name: 'Au Cap',
                state_code: '04',
            },
            {
                name: 'Baie Lazare',
                state_code: '06',
            },
            {
                name: 'Baie Sainte Anne',
                state_code: '07',
            },
            {
                name: 'Beau Vallon',
                state_code: '08',
            },
            {
                name: 'Bel Air',
                state_code: '09',
            },
            {
                name: 'Bel Ombre',
                state_code: '10',
            },
            {
                name: 'Cascade',
                state_code: '11',
            },
            {
                name: 'Glacis',
                state_code: '12',
            },
            {
                name: "Grand'Anse Mahé",
                state_code: '13',
            },
            {
                name: "Grand'Anse Praslin",
                state_code: '14',
            },
            {
                name: 'La Digue',
                state_code: '15',
            },
            {
                name: 'La Rivière Anglaise',
                state_code: '16',
            },
            {
                name: 'Les Mamelles',
                state_code: '24',
            },
            {
                name: 'Mont Buxton',
                state_code: '17',
            },
            {
                name: 'Mont Fleuri',
                state_code: '18',
            },
            {
                name: 'Plaisance',
                state_code: '19',
            },
            {
                name: 'Pointe La Rue',
                state_code: '20',
            },
            {
                name: 'Port Glaud',
                state_code: '21',
            },
            {
                name: 'Roche Caiman',
                state_code: '25',
            },
            {
                name: 'Saint Louis',
                state_code: '22',
            },
            {
                name: 'Takamaka',
                state_code: '23',
            },
        ],
    },
    {
        name: 'Sierra Leone',
        iso3: 'SLE',
        iso2: 'SL',
        states: [
            {
                name: 'Eastern Province',
                state_code: 'E',
            },
            {
                name: 'Northern Province',
                state_code: 'N',
            },
            {
                name: 'Southern Province',
                state_code: 'S',
            },
            {
                name: 'Western Area',
                state_code: 'W',
            },
        ],
    },
    {
        name: 'Singapore',
        iso3: 'SGP',
        iso2: 'SG',
        states: [
            {
                name: 'Central Singapore Community Development Council',
                state_code: '01',
            },
            {
                name: 'North East Community Development Council',
                state_code: '02',
            },
            {
                name: 'North West Community Development Council',
                state_code: '03',
            },
            {
                name: 'South East Community Development Council',
                state_code: '04',
            },
            {
                name: 'South West Community Development Council',
                state_code: '05',
            },
        ],
    },
    {
        name: 'Slovakia',
        iso3: 'SVK',
        iso2: 'SK',
        states: [
            {
                name: 'Banská Bystrica Region',
                state_code: 'BC',
            },
            {
                name: 'Bratislava Region',
                state_code: 'BL',
            },
            {
                name: 'Košice Region',
                state_code: 'KI',
            },
            {
                name: 'Nitra Region',
                state_code: 'NI',
            },
            {
                name: 'Prešov Region',
                state_code: 'PV',
            },
            {
                name: 'Trenčín Region',
                state_code: 'TC',
            },
            {
                name: 'Trnava Region',
                state_code: 'TA',
            },
            {
                name: 'Žilina Region',
                state_code: 'ZI',
            },
        ],
    },
    {
        name: 'Slovenia',
        iso3: 'SVN',
        iso2: 'SI',
        states: [
            {
                name: 'Ajdovščina Municipality',
                state_code: '001',
            },
            {
                name: 'Ankaran Municipality',
                state_code: '213',
            },
            {
                name: 'Beltinci Municipality',
                state_code: '002',
            },
            {
                name: 'Benedikt Municipality',
                state_code: '148',
            },
            {
                name: 'Bistrica ob Sotli Municipality',
                state_code: '149',
            },
            {
                name: 'Bled Municipality',
                state_code: '003',
            },
            {
                name: 'Bloke Municipality',
                state_code: '150',
            },
            {
                name: 'Bohinj Municipality',
                state_code: '004',
            },
            {
                name: 'Borovnica Municipality',
                state_code: '005',
            },
            {
                name: 'Bovec Municipality',
                state_code: '006',
            },
            {
                name: 'Braslovče Municipality',
                state_code: '151',
            },
            {
                name: 'Brda Municipality',
                state_code: '007',
            },
            {
                name: 'Brežice Municipality',
                state_code: '009',
            },
            {
                name: 'Brezovica Municipality',
                state_code: '008',
            },
            {
                name: 'Cankova Municipality',
                state_code: '152',
            },
            {
                name: 'Cerklje na Gorenjskem Municipality',
                state_code: '012',
            },
            {
                name: 'Cerknica Municipality',
                state_code: '013',
            },
            {
                name: 'Cerkno Municipality',
                state_code: '014',
            },
            {
                name: 'Cerkvenjak Municipality',
                state_code: '153',
            },
            {
                name: 'City Municipality of Celje',
                state_code: '011',
            },
            {
                name: 'City Municipality of Novo Mesto',
                state_code: '085',
            },
            {
                name: 'Črenšovci Municipality',
                state_code: '015',
            },
            {
                name: 'Črna na Koroškem Municipality',
                state_code: '016',
            },
            {
                name: 'Črnomelj Municipality',
                state_code: '017',
            },
            {
                name: 'Destrnik Municipality',
                state_code: '018',
            },
            {
                name: 'Divača Municipality',
                state_code: '019',
            },
            {
                name: 'Dobje Municipality',
                state_code: '154',
            },
            {
                name: 'Dobrepolje Municipality',
                state_code: '020',
            },
            {
                name: 'Dobrna Municipality',
                state_code: '155',
            },
            {
                name: 'Dobrova–Polhov Gradec Municipality',
                state_code: '021',
            },
            {
                name: 'Dobrovnik Municipality',
                state_code: '156',
            },
            {
                name: 'Dol pri Ljubljani Municipality',
                state_code: '022',
            },
            {
                name: 'Dolenjske Toplice Municipality',
                state_code: '157',
            },
            {
                name: 'Domžale Municipality',
                state_code: '023',
            },
            {
                name: 'Dornava Municipality',
                state_code: '024',
            },
            {
                name: 'Dravograd Municipality',
                state_code: '025',
            },
            {
                name: 'Duplek Municipality',
                state_code: '026',
            },
            {
                name: 'Gorenja Vas–Poljane Municipality',
                state_code: '027',
            },
            {
                name: 'Gorišnica Municipality',
                state_code: '028',
            },
            {
                name: 'Gorje Municipality',
                state_code: '207',
            },
            {
                name: 'Gornja Radgona Municipality',
                state_code: '029',
            },
            {
                name: 'Gornji Grad Municipality',
                state_code: '030',
            },
            {
                name: 'Gornji Petrovci Municipality',
                state_code: '031',
            },
            {
                name: 'Grad Municipality',
                state_code: '158',
            },
            {
                name: 'Grosuplje Municipality',
                state_code: '032',
            },
            {
                name: 'Hajdina Municipality',
                state_code: '159',
            },
            {
                name: 'Hoče–Slivnica Municipality',
                state_code: '160',
            },
            {
                name: 'Hodoš Municipality',
                state_code: '161',
            },
            {
                name: 'Horjul Municipality',
                state_code: '162',
            },
            {
                name: 'Hrastnik Municipality',
                state_code: '034',
            },
            {
                name: 'Hrpelje–Kozina Municipality',
                state_code: '035',
            },
            {
                name: 'Idrija Municipality',
                state_code: '036',
            },
            {
                name: 'Ig Municipality',
                state_code: '037',
            },
            {
                name: 'Ivančna Gorica Municipality',
                state_code: '039',
            },
            {
                name: 'Izola Municipality',
                state_code: '040',
            },
            {
                name: 'Jesenice Municipality',
                state_code: '041',
            },
            {
                name: 'Jezersko Municipality',
                state_code: '163',
            },
            {
                name: 'Juršinci Municipality',
                state_code: '042',
            },
            {
                name: 'Kamnik Municipality',
                state_code: '043',
            },
            {
                name: 'Kanal ob Soči Municipality',
                state_code: '044',
            },
            {
                name: 'Kidričevo Municipality',
                state_code: '045',
            },
            {
                name: 'Kobarid Municipality',
                state_code: '046',
            },
            {
                name: 'Kobilje Municipality',
                state_code: '047',
            },
            {
                name: 'Kočevje Municipality',
                state_code: '048',
            },
            {
                name: 'Komen Municipality',
                state_code: '049',
            },
            {
                name: 'Komenda Municipality',
                state_code: '164',
            },
            {
                name: 'Koper City Municipality',
                state_code: '050',
            },
            {
                name: 'Kostanjevica na Krki Municipality',
                state_code: '197',
            },
            {
                name: 'Kostel Municipality',
                state_code: '165',
            },
            {
                name: 'Kozje Municipality',
                state_code: '051',
            },
            {
                name: 'Kranj City Municipality',
                state_code: '052',
            },
            {
                name: 'Kranjska Gora Municipality',
                state_code: '053',
            },
            {
                name: 'Križevci Municipality',
                state_code: '166',
            },
            {
                name: 'Kungota',
                state_code: '055',
            },
            {
                name: 'Kuzma Municipality',
                state_code: '056',
            },
            {
                name: 'Laško Municipality',
                state_code: '057',
            },
            {
                name: 'Lenart Municipality',
                state_code: '058',
            },
            {
                name: 'Lendava Municipality',
                state_code: '059',
            },
            {
                name: 'Litija Municipality',
                state_code: '060',
            },
            {
                name: 'Ljubljana City Municipality',
                state_code: '061',
            },
            {
                name: 'Ljubno Municipality',
                state_code: '062',
            },
            {
                name: 'Ljutomer Municipality',
                state_code: '063',
            },
            {
                name: 'Log–Dragomer Municipality',
                state_code: '208',
            },
            {
                name: 'Logatec Municipality',
                state_code: '064',
            },
            {
                name: 'Loška Dolina Municipality',
                state_code: '065',
            },
            {
                name: 'Loški Potok Municipality',
                state_code: '066',
            },
            {
                name: 'Lovrenc na Pohorju Municipality',
                state_code: '167',
            },
            {
                name: 'Luče Municipality',
                state_code: '067',
            },
            {
                name: 'Lukovica Municipality',
                state_code: '068',
            },
            {
                name: 'Majšperk Municipality',
                state_code: '069',
            },
            {
                name: 'Makole Municipality',
                state_code: '198',
            },
            {
                name: 'Maribor City Municipality',
                state_code: '070',
            },
            {
                name: 'Markovci Municipality',
                state_code: '168',
            },
            {
                name: 'Medvode Municipality',
                state_code: '071',
            },
            {
                name: 'Mengeš Municipality',
                state_code: '072',
            },
            {
                name: 'Metlika Municipality',
                state_code: '073',
            },
            {
                name: 'Mežica Municipality',
                state_code: '074',
            },
            {
                name: 'Miklavž na Dravskem Polju Municipality',
                state_code: '169',
            },
            {
                name: 'Miren–Kostanjevica Municipality',
                state_code: '075',
            },
            {
                name: 'Mirna Municipality',
                state_code: '212',
            },
            {
                name: 'Mirna Peč Municipality',
                state_code: '170',
            },
            {
                name: 'Mislinja Municipality',
                state_code: '076',
            },
            {
                name: 'Mokronog–Trebelno Municipality',
                state_code: '199',
            },
            {
                name: 'Moravče Municipality',
                state_code: '077',
            },
            {
                name: 'Moravske Toplice Municipality',
                state_code: '078',
            },
            {
                name: 'Mozirje Municipality',
                state_code: '079',
            },
            {
                name: 'Municipality of Apače',
                state_code: '195',
            },
            {
                name: 'Municipality of Cirkulane',
                state_code: '196',
            },
            {
                name: 'Municipality of Ilirska Bistrica',
                state_code: '038',
            },
            {
                name: 'Municipality of Krško',
                state_code: '054',
            },
            {
                name: 'Municipality of Škofljica',
                state_code: '123',
            },
            {
                name: 'Murska Sobota City Municipality',
                state_code: '080',
            },
            {
                name: 'Muta Municipality',
                state_code: '081',
            },
            {
                name: 'Naklo Municipality',
                state_code: '082',
            },
            {
                name: 'Nazarje Municipality',
                state_code: '083',
            },
            {
                name: 'Nova Gorica City Municipality',
                state_code: '084',
            },
            {
                name: 'Odranci Municipality',
                state_code: '086',
            },
            {
                name: 'Oplotnica',
                state_code: '171',
            },
            {
                name: 'Ormož Municipality',
                state_code: '087',
            },
            {
                name: 'Osilnica Municipality',
                state_code: '088',
            },
            {
                name: 'Pesnica Municipality',
                state_code: '089',
            },
            {
                name: 'Piran Municipality',
                state_code: '090',
            },
            {
                name: 'Pivka Municipality',
                state_code: '091',
            },
            {
                name: 'Podčetrtek Municipality',
                state_code: '092',
            },
            {
                name: 'Podlehnik Municipality',
                state_code: '172',
            },
            {
                name: 'Podvelka Municipality',
                state_code: '093',
            },
            {
                name: 'Poljčane Municipality',
                state_code: '200',
            },
            {
                name: 'Polzela Municipality',
                state_code: '173',
            },
            {
                name: 'Postojna Municipality',
                state_code: '094',
            },
            {
                name: 'Prebold Municipality',
                state_code: '174',
            },
            {
                name: 'Preddvor Municipality',
                state_code: '095',
            },
            {
                name: 'Prevalje Municipality',
                state_code: '175',
            },
            {
                name: 'Ptuj City Municipality',
                state_code: '096',
            },
            {
                name: 'Puconci Municipality',
                state_code: '097',
            },
            {
                name: 'Rače–Fram Municipality',
                state_code: '098',
            },
            {
                name: 'Radeče Municipality',
                state_code: '099',
            },
            {
                name: 'Radenci Municipality',
                state_code: '100',
            },
            {
                name: 'Radlje ob Dravi Municipality',
                state_code: '101',
            },
            {
                name: 'Radovljica Municipality',
                state_code: '102',
            },
            {
                name: 'Ravne na Koroškem Municipality',
                state_code: '103',
            },
            {
                name: 'Razkrižje Municipality',
                state_code: '176',
            },
            {
                name: 'Rečica ob Savinji Municipality',
                state_code: '209',
            },
            {
                name: 'Renče–Vogrsko Municipality',
                state_code: '201',
            },
            {
                name: 'Ribnica Municipality',
                state_code: '104',
            },
            {
                name: 'Ribnica na Pohorju Municipality',
                state_code: '177',
            },
            {
                name: 'Rogaška Slatina Municipality',
                state_code: '106',
            },
            {
                name: 'Rogašovci Municipality',
                state_code: '105',
            },
            {
                name: 'Rogatec Municipality',
                state_code: '107',
            },
            {
                name: 'Ruše Municipality',
                state_code: '108',
            },
            {
                name: 'Šalovci Municipality',
                state_code: '033',
            },
            {
                name: 'Selnica ob Dravi Municipality',
                state_code: '178',
            },
            {
                name: 'Semič Municipality',
                state_code: '109',
            },
            {
                name: 'Šempeter–Vrtojba Municipality',
                state_code: '183',
            },
            {
                name: 'Šenčur Municipality',
                state_code: '117',
            },
            {
                name: 'Šentilj Municipality',
                state_code: '118',
            },
            {
                name: 'Šentjernej Municipality',
                state_code: '119',
            },
            {
                name: 'Šentjur Municipality',
                state_code: '120',
            },
            {
                name: 'Šentrupert Municipality',
                state_code: '211',
            },
            {
                name: 'Sevnica Municipality',
                state_code: '110',
            },
            {
                name: 'Sežana Municipality',
                state_code: '111',
            },
            {
                name: 'Škocjan Municipality',
                state_code: '121',
            },
            {
                name: 'Škofja Loka Municipality',
                state_code: '122',
            },
            {
                name: 'Slovenj Gradec City Municipality',
                state_code: '112',
            },
            {
                name: 'Slovenska Bistrica Municipality',
                state_code: '113',
            },
            {
                name: 'Slovenske Konjice Municipality',
                state_code: '114',
            },
            {
                name: 'Šmarje pri Jelšah Municipality',
                state_code: '124',
            },
            {
                name: 'Šmarješke Toplice Municipality',
                state_code: '206',
            },
            {
                name: 'Šmartno ob Paki Municipality',
                state_code: '125',
            },
            {
                name: 'Šmartno pri Litiji Municipality',
                state_code: '194',
            },
            {
                name: 'Sodražica Municipality',
                state_code: '179',
            },
            {
                name: 'Solčava Municipality',
                state_code: '180',
            },
            {
                name: 'Šoštanj Municipality',
                state_code: '126',
            },
            {
                name: 'Središče ob Dravi',
                state_code: '202',
            },
            {
                name: 'Starše Municipality',
                state_code: '115',
            },
            {
                name: 'Štore Municipality',
                state_code: '127',
            },
            {
                name: 'Straža Municipality',
                state_code: '203',
            },
            {
                name: 'Sveta Ana Municipality',
                state_code: '181',
            },
            {
                name: 'Sveta Trojica v Slovenskih Goricah Municipality',
                state_code: '204',
            },
            {
                name: 'Sveti Andraž v Slovenskih Goricah Municipality',
                state_code: '182',
            },
            {
                name: 'Sveti Jurij ob Ščavnici Municipality',
                state_code: '116',
            },
            {
                name: 'Sveti Jurij v Slovenskih Goricah Municipality',
                state_code: '210',
            },
            {
                name: 'Sveti Tomaž Municipality',
                state_code: '205',
            },
            {
                name: 'Tabor Municipality',
                state_code: '184',
            },
            {
                name: 'Tišina Municipality',
                state_code: '010',
            },
            {
                name: 'Tolmin Municipality',
                state_code: '128',
            },
            {
                name: 'Trbovlje Municipality',
                state_code: '129',
            },
            {
                name: 'Trebnje Municipality',
                state_code: '130',
            },
            {
                name: 'Trnovska Vas Municipality',
                state_code: '185',
            },
            {
                name: 'Tržič Municipality',
                state_code: '131',
            },
            {
                name: 'Trzin Municipality',
                state_code: '186',
            },
            {
                name: 'Turnišče Municipality',
                state_code: '132',
            },
            {
                name: 'Velika Polana Municipality',
                state_code: '187',
            },
            {
                name: 'Velike Lašče Municipality',
                state_code: '134',
            },
            {
                name: 'Veržej Municipality',
                state_code: '188',
            },
            {
                name: 'Videm Municipality',
                state_code: '135',
            },
            {
                name: 'Vipava Municipality',
                state_code: '136',
            },
            {
                name: 'Vitanje Municipality',
                state_code: '137',
            },
            {
                name: 'Vodice Municipality',
                state_code: '138',
            },
            {
                name: 'Vojnik Municipality',
                state_code: '139',
            },
            {
                name: 'Vransko Municipality',
                state_code: '189',
            },
            {
                name: 'Vrhnika Municipality',
                state_code: '140',
            },
            {
                name: 'Vuzenica Municipality',
                state_code: '141',
            },
            {
                name: 'Zagorje ob Savi Municipality',
                state_code: '142',
            },
            {
                name: 'Žalec Municipality',
                state_code: '190',
            },
            {
                name: 'Zavrč Municipality',
                state_code: '143',
            },
            {
                name: 'Železniki Municipality',
                state_code: '146',
            },
            {
                name: 'Žetale Municipality',
                state_code: '191',
            },
            {
                name: 'Žiri Municipality',
                state_code: '147',
            },
            {
                name: 'Žirovnica Municipality',
                state_code: '192',
            },
            {
                name: 'Zreče Municipality',
                state_code: '144',
            },
            {
                name: 'Žužemberk Municipality',
                state_code: '193',
            },
        ],
    },
    {
        name: 'Solomon Islands',
        iso3: 'SLB',
        iso2: 'SB',
        states: [
            {
                name: 'Central Province',
                state_code: 'CE',
            },
            {
                name: 'Choiseul Province',
                state_code: 'CH',
            },
            {
                name: 'Guadalcanal Province',
                state_code: 'GU',
            },
            {
                name: 'Honiara',
                state_code: 'CT',
            },
            {
                name: 'Isabel Province',
                state_code: 'IS',
            },
            {
                name: 'Makira-Ulawa Province',
                state_code: 'MK',
            },
            {
                name: 'Malaita Province',
                state_code: 'ML',
            },
            {
                name: 'Rennell and Bellona Province',
                state_code: 'RB',
            },
            {
                name: 'Temotu Province',
                state_code: 'TE',
            },
            {
                name: 'Western Province',
                state_code: 'WE',
            },
        ],
    },
    {
        name: 'Somalia',
        iso3: 'SOM',
        iso2: 'SO',
        states: [
            {
                name: 'Awdal Region',
                state_code: 'AW',
            },
            {
                name: 'Bakool',
                state_code: 'BK',
            },
            {
                name: 'Banaadir',
                state_code: 'BN',
            },
            {
                name: 'Bari',
                state_code: 'BR',
            },
            {
                name: 'Bay',
                state_code: 'BY',
            },
            {
                name: 'Galguduud',
                state_code: 'GA',
            },
            {
                name: 'Gedo',
                state_code: 'GE',
            },
            {
                name: 'Hiran',
                state_code: 'HI',
            },
            {
                name: 'Lower Juba',
                state_code: 'JH',
            },
            {
                name: 'Lower Shebelle',
                state_code: 'SH',
            },
            {
                name: 'Middle Juba',
                state_code: 'JD',
            },
            {
                name: 'Middle Shebelle',
                state_code: 'SD',
            },
            {
                name: 'Mudug',
                state_code: 'MU',
            },
            {
                name: 'Nugal',
                state_code: 'NU',
            },
            {
                name: 'Sanaag Region',
                state_code: 'SA',
            },
            {
                name: 'Togdheer Region',
                state_code: 'TO',
            },
        ],
    },
    {
        name: 'South Africa',
        iso3: 'ZAF',
        iso2: 'ZA',
        states: [
            {
                name: 'Eastern Cape',
                state_code: 'EC',
            },
            {
                name: 'Free State',
                state_code: 'FS',
            },
            {
                name: 'Gauteng',
                state_code: 'GP',
            },
            {
                name: 'KwaZulu-Natal',
                state_code: 'KZN',
            },
            {
                name: 'Limpopo',
                state_code: 'LP',
            },
            {
                name: 'Mpumalanga',
                state_code: 'MP',
            },
            {
                name: 'North West',
                state_code: 'NW',
            },
            {
                name: 'Northern Cape',
                state_code: 'NC',
            },
            {
                name: 'Western Cape',
                state_code: 'WC',
            },
        ],
    },
    {
        name: 'South Georgia and the South Sandwich Islands',
        iso3: 'SGS',
        iso2: 'GS',
        states: [],
    },
    {
        name: 'South Sudan',
        iso3: 'SSD',
        iso2: 'SS',
        states: [
            {
                name: 'Central Equatoria',
                state_code: 'EC',
            },
            {
                name: 'Eastern Equatoria',
                state_code: 'EE',
            },
            {
                name: 'Jonglei State',
                state_code: 'JG',
            },
            {
                name: 'Lakes',
                state_code: 'LK',
            },
            {
                name: 'Northern Bahr el Ghazal',
                state_code: 'BN',
            },
            {
                name: 'Unity',
                state_code: 'UY',
            },
            {
                name: 'Upper Nile',
                state_code: 'NU',
            },
            {
                name: 'Warrap',
                state_code: 'WR',
            },
            {
                name: 'Western Bahr el Ghazal',
                state_code: 'BW',
            },
            {
                name: 'Western Equatoria',
                state_code: 'EW',
            },
        ],
    },
    {
        name: 'Spain',
        iso3: 'ESP',
        iso2: 'ES',
        states: [
            {
                name: 'A Coruña Province',
                state_code: 'C',
            },
            {
                name: 'Albacete Province',
                state_code: 'AB',
            },
            {
                name: 'Alicante Province',
                state_code: 'A',
            },
            {
                name: 'Almería Province',
                state_code: 'AL',
            },
            {
                name: 'Andalusia',
                state_code: 'AN',
            },
            {
                name: 'Araba Álava',
                state_code: 'VI',
            },
            {
                name: 'Aragon',
                state_code: 'AR',
            },
            {
                name: 'Badajoz Province',
                state_code: 'BA',
            },
            {
                name: 'Balearic Islands',
                state_code: 'PM',
            },
            {
                name: 'Barcelona Province',
                state_code: 'B',
            },
            {
                name: 'Basque Country',
                state_code: 'PV',
            },
            {
                name: 'Biscay',
                state_code: 'BI',
            },
            {
                name: 'Burgos Province',
                state_code: 'BU',
            },
            {
                name: 'Cáceres Province',
                state_code: 'CC',
            },
            {
                name: 'Cádiz Province',
                state_code: 'CA',
            },
            {
                name: 'Canary Islands',
                state_code: 'CN',
            },
            {
                name: 'Cantabria',
                state_code: 'S',
            },
            {
                name: 'Castellón Province',
                state_code: 'CS',
            },
            {
                name: 'Castile and León',
                state_code: 'CL',
            },
            {
                name: 'Castile-La Mancha',
                state_code: 'CM',
            },
            {
                name: 'Catalonia',
                state_code: 'CT',
            },
            {
                name: 'Ceuta',
                state_code: 'CE',
            },
            {
                name: 'Ciudad Real Province',
                state_code: 'CR',
            },
            {
                name: 'Community of Madrid',
                state_code: 'MD',
            },
            {
                name: 'Córdoba Province',
                state_code: 'CO',
            },
            {
                name: 'Cuenca Province',
                state_code: 'CU',
            },
            {
                name: 'Extremadura',
                state_code: 'EX',
            },
            {
                name: 'Galicia',
                state_code: 'GA',
            },
            {
                name: 'Gipuzkoa',
                state_code: 'SS',
            },
            {
                name: 'Girona Province',
                state_code: 'GI',
            },
            {
                name: 'Granada Province',
                state_code: 'GR',
            },
            {
                name: 'Guadalajara Province',
                state_code: 'GU',
            },
            {
                name: 'Huelva Province',
                state_code: 'H',
            },
            {
                name: 'Huesca Province',
                state_code: 'HU',
            },
            {
                name: 'Jaén Province',
                state_code: 'J',
            },
            {
                name: 'La Rioja',
                state_code: 'RI',
            },
            {
                name: 'Las Palmas Province',
                state_code: 'GC',
            },
            {
                name: 'León Province',
                state_code: 'LE',
            },
            {
                name: 'Lleida Province',
                state_code: 'L',
            },
            {
                name: 'Lugo Province',
                state_code: 'LU',
            },
            {
                name: 'Madrid Province',
                state_code: 'M',
            },
            {
                name: 'Málaga Province',
                state_code: 'MA',
            },
            {
                name: 'Melilla',
                state_code: 'ML',
            },
            {
                name: 'Murcia Province',
                state_code: 'MU',
            },
            {
                name: 'Navarre',
                state_code: 'NC',
            },
            {
                name: 'Ourense Province',
                state_code: 'OR',
            },
            {
                name: 'Palencia Province',
                state_code: 'P',
            },
            {
                name: 'Pontevedra Province',
                state_code: 'PO',
            },
            {
                name: 'Province of Asturias',
                state_code: 'O',
            },
            {
                name: 'Province of Ávila',
                state_code: 'AV',
            },
            {
                name: 'Region of Murcia',
                state_code: 'MC',
            },
            {
                name: 'Salamanca Province',
                state_code: 'SA',
            },
            {
                name: 'Santa Cruz de Tenerife Province',
                state_code: 'TF',
            },
            {
                name: 'Segovia Province',
                state_code: 'SG',
            },
            {
                name: 'Seville Province',
                state_code: 'SE',
            },
            {
                name: 'Soria Province',
                state_code: 'SO',
            },
            {
                name: 'Tarragona Province',
                state_code: 'T',
            },
            {
                name: 'Teruel Province',
                state_code: 'TE',
            },
            {
                name: 'Toledo Province',
                state_code: 'TO',
            },
            {
                name: 'Valencia Province',
                state_code: 'V',
            },
            {
                name: 'Valencian Community',
                state_code: 'VC',
            },
            {
                name: 'Valladolid Province',
                state_code: 'VA',
            },
            {
                name: 'Zamora Province',
                state_code: 'ZA',
            },
            {
                name: 'Zaragoza Province',
                state_code: 'Z',
            },
        ],
    },
    {
        name: 'Sri Lanka',
        iso3: 'LKA',
        iso2: 'LK',
        states: [
            {
                name: 'Ampara District',
                state_code: '52',
            },
            {
                name: 'Anuradhapura District',
                state_code: '71',
            },
            {
                name: 'Badulla District',
                state_code: '81',
            },
            {
                name: 'Batticaloa District',
                state_code: '51',
            },
            {
                name: 'Central Province',
                state_code: '2',
            },
            {
                name: 'Colombo District',
                state_code: '11',
            },
            {
                name: 'Eastern Province',
                state_code: '5',
            },
            {
                name: 'Galle District',
                state_code: '31',
            },
            {
                name: 'Gampaha District',
                state_code: '12',
            },
            {
                name: 'Hambantota District',
                state_code: '33',
            },
            {
                name: 'Jaffna District',
                state_code: '41',
            },
            {
                name: 'Kalutara District',
                state_code: '13',
            },
            {
                name: 'Kandy District',
                state_code: '21',
            },
            {
                name: 'Kegalle District',
                state_code: '92',
            },
            {
                name: 'Kilinochchi District',
                state_code: '42',
            },
            {
                name: 'Mannar District',
                state_code: '43',
            },
            {
                name: 'Matale District',
                state_code: '22',
            },
            {
                name: 'Matara District',
                state_code: '32',
            },
            {
                name: 'Monaragala District',
                state_code: '82',
            },
            {
                name: 'Mullaitivu District',
                state_code: '45',
            },
            {
                name: 'North Central Province',
                state_code: '7',
            },
            {
                name: 'North Western Province',
                state_code: '6',
            },
            {
                name: 'Northern Province',
                state_code: '4',
            },
            {
                name: 'Nuwara Eliya District',
                state_code: '23',
            },
            {
                name: 'Polonnaruwa District',
                state_code: '72',
            },
            {
                name: 'Puttalam District',
                state_code: '62',
            },
            {
                name: 'Ratnapura district',
                state_code: '91',
            },
            {
                name: 'Sabaragamuwa Province',
                state_code: '9',
            },
            {
                name: 'Southern Province',
                state_code: '3',
            },
            {
                name: 'Trincomalee District',
                state_code: '53',
            },
            {
                name: 'Uva Province',
                state_code: '8',
            },
            {
                name: 'Vavuniya District',
                state_code: '44',
            },
            {
                name: 'Western Province',
                state_code: '1',
            },
        ],
    },
    {
        name: 'Sudan',
        iso3: 'SDN',
        iso2: 'SD',
        states: [
            {
                name: 'Al Jazirah',
                state_code: 'GZ',
            },
            {
                name: 'Al Qadarif',
                state_code: 'GD',
            },
            {
                name: 'Blue Nile',
                state_code: 'NB',
            },
            {
                name: 'Central Darfur',
                state_code: 'DC',
            },
            {
                name: 'East Darfur',
                state_code: 'DE',
            },
            {
                name: 'Kassala',
                state_code: 'KA',
            },
            {
                name: 'Khartoum',
                state_code: 'KH',
            },
            {
                name: 'North Darfur',
                state_code: 'DN',
            },
            {
                name: 'North Kordofan',
                state_code: 'KN',
            },
            {
                name: 'Northern',
                state_code: 'NO',
            },
            {
                name: 'Red Sea',
                state_code: 'RS',
            },
            {
                name: 'River Nile',
                state_code: 'NR',
            },
            {
                name: 'Sennar',
                state_code: 'SI',
            },
            {
                name: 'South Darfur',
                state_code: 'DS',
            },
            {
                name: 'South Kordofan',
                state_code: 'KS',
            },
            {
                name: 'West Darfur',
                state_code: 'DW',
            },
            {
                name: 'West Kordofan',
                state_code: 'GK',
            },
            {
                name: 'White Nile',
                state_code: 'NW',
            },
        ],
    },
    {
        name: 'Suriname',
        iso3: 'SUR',
        iso2: 'SR',
        states: [
            {
                name: 'Brokopondo District',
                state_code: 'BR',
            },
            {
                name: 'Commewijne District',
                state_code: 'CM',
            },
            {
                name: 'Coronie District',
                state_code: 'CR',
            },
            {
                name: 'Marowijne District',
                state_code: 'MA',
            },
            {
                name: 'Nickerie District',
                state_code: 'NI',
            },
            {
                name: 'Para District',
                state_code: 'PR',
            },
            {
                name: 'Paramaribo District',
                state_code: 'PM',
            },
            {
                name: 'Saramacca District',
                state_code: 'SA',
            },
            {
                name: 'Sipaliwini District',
                state_code: 'SI',
            },
            {
                name: 'Wanica District',
                state_code: 'WA',
            },
        ],
    },
    {
        name: 'Svalbard And Jan Mayen Islands',
        iso3: 'SJM',
        iso2: 'SJ',
        states: [],
    },
    {
        name: 'Swaziland',
        iso3: 'SWZ',
        iso2: 'SZ',
        states: [
            {
                name: 'Hhohho District',
                state_code: 'HH',
            },
            {
                name: 'Lubombo District',
                state_code: 'LU',
            },
            {
                name: 'Manzini District',
                state_code: 'MA',
            },
            {
                name: 'Shiselweni District',
                state_code: 'SH',
            },
        ],
    },
    {
        name: 'Sweden',
        iso3: 'SWE',
        iso2: 'SE',
        states: [
            {
                name: 'Blekinge',
                state_code: 'K',
            },
            {
                name: 'Dalarna County',
                state_code: 'W',
            },
            {
                name: 'Gävleborg County',
                state_code: 'X',
            },
            {
                name: 'Gotland County',
                state_code: 'I',
            },
            {
                name: 'Halland County',
                state_code: 'N',
            },
            {
                name: 'Jönköping County',
                state_code: 'F',
            },
            {
                name: 'Kalmar County',
                state_code: 'H',
            },
            {
                name: 'Kronoberg County',
                state_code: 'G',
            },
            {
                name: 'Norrbotten County',
                state_code: 'BD',
            },
            {
                name: 'Örebro County',
                state_code: 'T',
            },
            {
                name: 'Östergötland County',
                state_code: 'E',
            },
            {
                name: 'Skåne County',
                state_code: 'M',
            },
            {
                name: 'Södermanland County',
                state_code: 'D',
            },
            {
                name: 'Stockholm County',
                state_code: 'AB',
            },
            {
                name: 'Uppsala County',
                state_code: 'C',
            },
            {
                name: 'Värmland County',
                state_code: 'S',
            },
            {
                name: 'Västerbotten County',
                state_code: 'AC',
            },
            {
                name: 'Västernorrland County',
                state_code: 'Y',
            },
            {
                name: 'Västmanland County',
                state_code: 'U',
            },
            {
                name: 'Västra Götaland County',
                state_code: 'O',
            },
        ],
    },
    {
        name: 'Switzerland',
        iso3: 'CHE',
        iso2: 'CH',
        states: [
            {
                name: 'Aargau',
                state_code: 'AG',
            },
            {
                name: 'Appenzell Ausserrhoden',
                state_code: 'AR',
            },
            {
                name: 'Appenzell Innerrhoden',
                state_code: 'AI',
            },
            {
                name: 'Basel-Landschaft',
                state_code: 'BL',
            },
            {
                name: 'canton of Bern',
                state_code: 'BE',
            },
            {
                name: 'Canton of Fribourg',
                state_code: 'FR',
            },
            {
                name: 'Canton of Geneva',
                state_code: 'GE',
            },
            {
                name: 'Canton of Jura',
                state_code: 'JU',
            },
            {
                name: 'Canton of Lucerne',
                state_code: 'LU',
            },
            {
                name: 'Canton of Neuchâtel',
                state_code: 'NE',
            },
            {
                name: 'Canton of Schaffhausen',
                state_code: 'SH',
            },
            {
                name: 'Canton of Solothurn',
                state_code: 'SO',
            },
            {
                name: 'Canton of St. Gallen',
                state_code: 'SG',
            },
            {
                name: 'Canton of Valais',
                state_code: 'VS',
            },
            {
                name: 'Canton of Vaud',
                state_code: 'VD',
            },
            {
                name: 'Canton of Zug',
                state_code: 'ZG',
            },
            {
                name: 'canton of Zürich',
                state_code: 'ZH',
            },
            {
                name: 'Glarus',
                state_code: 'GL',
            },
            {
                name: 'Graubünden',
                state_code: 'GR',
            },
            {
                name: 'Nidwalden',
                state_code: 'NW',
            },
            {
                name: 'Obwalden',
                state_code: 'OW',
            },
            {
                name: 'Schwyz',
                state_code: 'SZ',
            },
            {
                name: 'Thurgau',
                state_code: 'TG',
            },
            {
                name: 'Ticino',
                state_code: 'TI',
            },
            {
                name: 'Uri',
                state_code: 'UR',
            },
        ],
    },
    {
        name: 'Syria',
        iso3: 'SYR',
        iso2: 'SY',
        states: [
            {
                name: 'Al-Hasakah Governorate',
                state_code: 'HA',
            },
            {
                name: 'Al-Raqqah Governorate',
                state_code: 'RA',
            },
            {
                name: 'Aleppo Governorate',
                state_code: 'HL',
            },
            {
                name: 'As-Suwayda Governorate',
                state_code: 'SU',
            },
            {
                name: 'Damascus Governorate',
                state_code: 'DI',
            },
            {
                name: 'Daraa Governorate',
                state_code: 'DR',
            },
            {
                name: 'Deir ez-Zor Governorate',
                state_code: 'DY',
            },
            {
                name: 'Hama Governorate',
                state_code: 'HM',
            },
            {
                name: 'Homs Governorate',
                state_code: 'HI',
            },
            {
                name: 'Idlib Governorate',
                state_code: 'ID',
            },
            {
                name: 'Latakia Governorate',
                state_code: 'LA',
            },
            {
                name: 'Quneitra Governorate',
                state_code: 'QU',
            },
            {
                name: 'Rif Dimashq Governorate',
                state_code: 'RD',
            },
            {
                name: 'Tartus Governorate',
                state_code: 'TA',
            },
        ],
    },
    {
        name: 'Taiwan',
        iso3: 'TWN',
        iso2: 'TW',
        states: [
            {
                name: 'Changhua County',
                state_code: 'CHA',
            },
            {
                name: 'Chiayi City',
                state_code: 'CYQ',
            },
            {
                name: 'Chiayi County',
                state_code: 'CYI',
            },
            {
                name: 'Hsinchu',
                state_code: 'HSZ',
            },
            {
                name: 'Hsinchu County',
                state_code: 'HSQ',
            },
            {
                name: 'Hualien County',
                state_code: 'HUA',
            },
            {
                name: 'Kaohsiung',
                state_code: 'KHH',
            },
            {
                name: 'Kaohsiung County',
                state_code: 'KHQ',
            },
            {
                name: 'Kinmen',
                state_code: 'KIN',
            },
            {
                name: 'Lienchiang County',
                state_code: 'LIE',
            },
            {
                name: 'Miaoli County',
                state_code: 'MIA',
            },
            {
                name: 'Nantou County',
                state_code: 'NAN',
            },
            {
                name: 'Penghu County',
                state_code: 'PEN',
            },
            {
                name: 'Pingtung County',
                state_code: 'PIF',
            },
            {
                name: 'Taichung',
                state_code: 'TXG',
            },
            {
                name: 'Taichung County',
                state_code: 'TXQ',
            },
            {
                name: 'Tainan',
                state_code: 'TNN',
            },
            {
                name: 'Tainan County',
                state_code: 'TNQ',
            },
            {
                name: 'Taipei',
                state_code: 'TPE',
            },
            {
                name: 'Taitung County',
                state_code: 'TTT',
            },
            {
                name: 'Taoyuan City',
                state_code: 'TAO',
            },
            {
                name: 'Yilan County',
                state_code: 'ILA',
            },
            {
                name: 'Yunlin County',
                state_code: 'YUN',
            },
        ],
    },
    {
        name: 'Tajikistan',
        iso3: 'TJK',
        iso2: 'TJ',
        states: [
            {
                name: 'districts of Republican Subordination',
                state_code: 'RA',
            },
            {
                name: 'Gorno-Badakhshan Autonomous Province',
                state_code: 'GB',
            },
            {
                name: 'Khatlon Province',
                state_code: 'KT',
            },
            {
                name: 'Sughd Province',
                state_code: 'SU',
            },
        ],
    },
    {
        name: 'Tanzania',
        iso3: 'TZA',
        iso2: 'TZ',
        states: [
            {
                name: 'Arusha Region',
                state_code: '01',
            },
            {
                name: 'Dar es Salaam Region',
                state_code: '02',
            },
            {
                name: 'Dodoma Region',
                state_code: '03',
            },
            {
                name: 'Geita Region',
                state_code: '27',
            },
            {
                name: 'Iringa Region',
                state_code: '04',
            },
            {
                name: 'Kagera Region',
                state_code: '05',
            },
            {
                name: 'Katavi Region',
                state_code: '28',
            },
            {
                name: 'Kigoma Region',
                state_code: '08',
            },
            {
                name: 'Kilimanjaro Region',
                state_code: '09',
            },
            {
                name: 'Lindi Region',
                state_code: '12',
            },
            {
                name: 'Manyara Region',
                state_code: '26',
            },
            {
                name: 'Mara Region',
                state_code: '13',
            },
            {
                name: 'Morogoro Region',
                state_code: '16',
            },
            {
                name: 'Mtwara Region',
                state_code: '17',
            },
            {
                name: 'Mwanza Region',
                state_code: '18',
            },
            {
                name: 'Njombe Region',
                state_code: '29',
            },
            {
                name: 'North Pemba Region',
                state_code: '06',
            },
            {
                name: 'Pwani Region',
                state_code: '19',
            },
            {
                name: 'Rukwa Region',
                state_code: '20',
            },
            {
                name: 'Ruvuma Region',
                state_code: '21',
            },
            {
                name: 'Shinyanga Region',
                state_code: '22',
            },
            {
                name: 'Simiyu Region',
                state_code: '30',
            },
            {
                name: 'Singida Region',
                state_code: '23',
            },
            {
                name: 'South Pemba Region',
                state_code: '10',
            },
            {
                name: 'Tabora Region',
                state_code: '24',
            },
            {
                name: 'Tanga Region',
                state_code: '25',
            },
            {
                name: 'Zanzibar Central South Region',
                state_code: '11',
            },
            {
                name: 'Zanzibar North Region',
                state_code: '07',
            },
            {
                name: 'Zanzibar Urban West Region',
                state_code: '15',
            },
        ],
    },
    {
        name: 'Thailand',
        iso3: 'THA',
        iso2: 'TH',
        states: [
            {
                name: 'Amnat Charoen',
                state_code: '37',
            },
            {
                name: 'Ang Thong',
                state_code: '15',
            },
            {
                name: 'Bangkok',
                state_code: '10',
            },
            {
                name: 'Bueng Kan',
                state_code: '38',
            },
            {
                name: 'Buri Ram',
                state_code: '31',
            },
            {
                name: 'Chachoengsao',
                state_code: '24',
            },
            {
                name: 'Chai Nat',
                state_code: '18',
            },
            {
                name: 'Chanthaburi',
                state_code: '22',
            },
            {
                name: 'Chiang Mai',
                state_code: '50',
            },
            {
                name: 'Chiang Rai',
                state_code: '57',
            },
            {
                name: 'Chon Buri',
                state_code: '20',
            },
            {
                name: 'Chumphon',
                state_code: '86',
            },
            {
                name: 'Kalasin',
                state_code: '46',
            },
            {
                name: 'Kamphaeng Phet',
                state_code: '62',
            },
            {
                name: 'Kanchanaburi',
                state_code: '71',
            },
            {
                name: 'Khon Kaen',
                state_code: '40',
            },
            {
                name: 'Krabi',
                state_code: '81',
            },
            {
                name: 'Lampang',
                state_code: '52',
            },
            {
                name: 'Lamphun',
                state_code: '51',
            },
            {
                name: 'Loei',
                state_code: '42',
            },
            {
                name: 'Lopburi',
                state_code: '16',
            },
            {
                name: 'Mae Hong Son',
                state_code: '58',
            },
            {
                name: 'Maha Sarakham',
                state_code: '44',
            },
            {
                name: 'Mukdahan',
                state_code: '49',
            },
            {
                name: 'Nakhon Nayok',
                state_code: '26',
            },
            {
                name: 'Nakhon Pathom',
                state_code: '73',
            },
            {
                name: 'Nakhon Phanom',
                state_code: '48',
            },
            {
                name: 'Nakhon Ratchasima',
                state_code: '30',
            },
            {
                name: 'Nakhon Sawan',
                state_code: '60',
            },
            {
                name: 'Nakhon Si Thammarat',
                state_code: '80',
            },
            {
                name: 'Nan',
                state_code: '55',
            },
            {
                name: 'Narathiwat',
                state_code: '96',
            },
            {
                name: 'Nong Bua Lam Phu',
                state_code: '39',
            },
            {
                name: 'Nong Khai',
                state_code: '43',
            },
            {
                name: 'Nonthaburi',
                state_code: '12',
            },
            {
                name: 'Pathum Thani',
                state_code: '13',
            },
            {
                name: 'Pattani',
                state_code: '94',
            },
            {
                name: 'Pattaya',
                state_code: 'S',
            },
            {
                name: 'Phang Nga',
                state_code: '82',
            },
            {
                name: 'Phatthalung',
                state_code: '93',
            },
            {
                name: 'Phayao',
                state_code: '56',
            },
            {
                name: 'Phetchabun',
                state_code: '67',
            },
            {
                name: 'Phetchaburi',
                state_code: '76',
            },
            {
                name: 'Phichit',
                state_code: '66',
            },
            {
                name: 'Phitsanulok',
                state_code: '65',
            },
            {
                name: 'Phra Nakhon Si Ayutthaya',
                state_code: '14',
            },
            {
                name: 'Phrae',
                state_code: '54',
            },
            {
                name: 'Phuket',
                state_code: '83',
            },
            {
                name: 'Prachin Buri',
                state_code: '25',
            },
            {
                name: 'Prachuap Khiri Khan',
                state_code: '77',
            },
            {
                name: 'Ranong',
                state_code: '85',
            },
            {
                name: 'Ratchaburi',
                state_code: '70',
            },
            {
                name: 'Rayong',
                state_code: '21',
            },
            {
                name: 'Roi Et',
                state_code: '45',
            },
            {
                name: 'Sa Kaeo',
                state_code: '27',
            },
            {
                name: 'Sakon Nakhon',
                state_code: '47',
            },
            {
                name: 'Samut Prakan',
                state_code: '11',
            },
            {
                name: 'Samut Sakhon',
                state_code: '74',
            },
            {
                name: 'Samut Songkhram',
                state_code: '75',
            },
            {
                name: 'Saraburi',
                state_code: '19',
            },
            {
                name: 'Satun',
                state_code: '91',
            },
            {
                name: 'Si Sa Ket',
                state_code: '33',
            },
            {
                name: 'Sing Buri',
                state_code: '17',
            },
            {
                name: 'Songkhla',
                state_code: '90',
            },
            {
                name: 'Sukhothai',
                state_code: '64',
            },
            {
                name: 'Suphanburi',
                state_code: '72',
            },
            {
                name: 'Surat Thani',
                state_code: '84',
            },
            {
                name: 'Surin',
                state_code: '32',
            },
            {
                name: 'Tak',
                state_code: '63',
            },
            {
                name: 'Trang',
                state_code: '92',
            },
            {
                name: 'Trat',
                state_code: '23',
            },
            {
                name: 'Ubon Ratchathani',
                state_code: '34',
            },
            {
                name: 'Udon Thani',
                state_code: '41',
            },
            {
                name: 'Uthai Thani',
                state_code: '61',
            },
            {
                name: 'Uttaradit',
                state_code: '53',
            },
            {
                name: 'Yala',
                state_code: '95',
            },
            {
                name: 'Yasothon',
                state_code: '35',
            },
        ],
    },
    {
        name: 'Togo',
        iso3: 'TGO',
        iso2: 'TG',
        states: [
            {
                name: 'Centrale Region',
                state_code: 'C',
            },
            {
                name: 'Kara Region',
                state_code: 'K',
            },
            {
                name: 'Maritime',
                state_code: 'M',
            },
            {
                name: 'Plateaux Region',
                state_code: 'P',
            },
            {
                name: 'Savanes Region',
                state_code: 'S',
            },
        ],
    },
    {
        name: 'Tokelau',
        iso3: 'TKL',
        iso2: 'TK',
        states: [],
    },
    {
        name: 'Tonga',
        iso3: 'TON',
        iso2: 'TO',
        states: [
            {
                name: 'Haʻapai',
                state_code: '02',
            },
            {
                name: 'ʻEua',
                state_code: '01',
            },
            {
                name: 'Niuas',
                state_code: '03',
            },
            {
                name: 'Tongatapu',
                state_code: '04',
            },
            {
                name: 'Vavaʻu',
                state_code: '05',
            },
        ],
    },
    {
        name: 'Trinidad And Tobago',
        iso3: 'TTO',
        iso2: 'TT',
        states: [
            {
                name: 'Arima',
                state_code: 'ARI',
            },
            {
                name: 'Chaguanas',
                state_code: 'CHA',
            },
            {
                name: 'Couva-Tabaquite-Talparo Regional Corporation',
                state_code: 'CTT',
            },
            {
                name: 'Diego Martin Regional Corporation',
                state_code: 'DMN',
            },
            {
                name: 'Eastern Tobago',
                state_code: 'ETO',
            },
            {
                name: 'Penal-Debe Regional Corporation',
                state_code: 'PED',
            },
            {
                name: 'Point Fortin',
                state_code: 'PTF',
            },
            {
                name: 'Port of Spain',
                state_code: 'POS',
            },
            {
                name: 'Princes Town Regional Corporation',
                state_code: 'PRT',
            },
            {
                name: 'Rio Claro-Mayaro Regional Corporation',
                state_code: 'MRC',
            },
            {
                name: 'San Fernando',
                state_code: 'SFO',
            },
            {
                name: 'San Juan-Laventille Regional Corporation',
                state_code: 'SJL',
            },
            {
                name: 'Sangre Grande Regional Corporation',
                state_code: 'SGE',
            },
            {
                name: 'Siparia Regional Corporation',
                state_code: 'SIP',
            },
            {
                name: 'Tunapuna-Piarco Regional Corporation',
                state_code: 'TUP',
            },
            {
                name: 'Western Tobago',
                state_code: 'WTO',
            },
        ],
    },
    {
        name: 'Tunisia',
        iso3: 'TUN',
        iso2: 'TN',
        states: [
            {
                name: 'Ariana Governorate',
                state_code: '12',
            },
            {
                name: 'Ben Arous Governorate',
                state_code: '13',
            },
            {
                name: 'Bizerte Governorate',
                state_code: '23',
            },
            {
                name: 'Gabès Governorate',
                state_code: '81',
            },
            {
                name: 'Gafsa Governorate',
                state_code: '71',
            },
            {
                name: 'Jendouba Governorate',
                state_code: '32',
            },
            {
                name: 'Kairouan Governorate',
                state_code: '41',
            },
            {
                name: 'Kasserine Governorate',
                state_code: '42',
            },
            {
                name: 'Kassrine',
                state_code: '31',
            },
            {
                name: 'Kebili Governorate',
                state_code: '73',
            },
            {
                name: 'Kef Governorate',
                state_code: '33',
            },
            {
                name: 'Mahdia Governorate',
                state_code: '53',
            },
            {
                name: 'Manouba Governorate',
                state_code: '14',
            },
            {
                name: 'Medenine Governorate',
                state_code: '82',
            },
            {
                name: 'Monastir Governorate',
                state_code: '52',
            },
            {
                name: 'Sfax Governorate',
                state_code: '61',
            },
            {
                name: 'Sidi Bouzid Governorate',
                state_code: '43',
            },
            {
                name: 'Siliana Governorate',
                state_code: '34',
            },
            {
                name: 'Sousse Governorate',
                state_code: '51',
            },
            {
                name: 'Tataouine Governorate',
                state_code: '83',
            },
            {
                name: 'Tozeur Governorate',
                state_code: '72',
            },
            {
                name: 'Tunis Governorate',
                state_code: '11',
            },
            {
                name: 'Zaghouan Governorate',
                state_code: '22',
            },
        ],
    },
    {
        name: 'Turkey',
        iso3: 'TUR',
        iso2: 'TR',
        states: [
            {
                name: 'Adana Province',
                state_code: '01',
            },
            {
                name: 'Adıyaman Province',
                state_code: '02',
            },
            {
                name: 'Afyonkarahisar Province',
                state_code: '03',
            },
            {
                name: 'Ağrı Province',
                state_code: '04',
            },
            {
                name: 'Aksaray Province',
                state_code: '68',
            },
            {
                name: 'Amasya Province',
                state_code: '05',
            },
            {
                name: 'Ankara Province',
                state_code: '06',
            },
            {
                name: 'Antalya Province',
                state_code: '07',
            },
            {
                name: 'Ardahan Province',
                state_code: '75',
            },
            {
                name: 'Artvin Province',
                state_code: '08',
            },
            {
                name: 'Aydın Province',
                state_code: '09',
            },
            {
                name: 'Balıkesir Province',
                state_code: '10',
            },
            {
                name: 'Bartın Province',
                state_code: '74',
            },
            {
                name: 'Batman Province',
                state_code: '72',
            },
            {
                name: 'Bayburt Province',
                state_code: '69',
            },
            {
                name: 'Bilecik Province',
                state_code: '11',
            },
            {
                name: 'Bingöl Province',
                state_code: '12',
            },
            {
                name: 'Bitlis Province',
                state_code: '13',
            },
            {
                name: 'Bolu Province',
                state_code: '14',
            },
            {
                name: 'Burdur Province',
                state_code: '15',
            },
            {
                name: 'Bursa Province',
                state_code: '16',
            },
            {
                name: 'Çanakkale Province',
                state_code: '17',
            },
            {
                name: 'Çankırı Province',
                state_code: '18',
            },
            {
                name: 'Çorum Province',
                state_code: '19',
            },
            {
                name: 'Denizli Province',
                state_code: '20',
            },
            {
                name: 'Diyarbakır Province',
                state_code: '21',
            },
            {
                name: 'Düzce Province',
                state_code: '81',
            },
            {
                name: 'Edirne Province',
                state_code: '22',
            },
            {
                name: 'Elazığ Province',
                state_code: '23',
            },
            {
                name: 'Erzincan Province',
                state_code: '24',
            },
            {
                name: 'Erzurum Province',
                state_code: '25',
            },
            {
                name: 'Eskişehir Province',
                state_code: '26',
            },
            {
                name: 'Gaziantep Province',
                state_code: '27',
            },
            {
                name: 'Giresun Province',
                state_code: '28',
            },
            {
                name: 'Gümüşhane Province',
                state_code: '29',
            },
            {
                name: 'Hakkâri Province',
                state_code: '30',
            },
            {
                name: 'Hatay Province',
                state_code: '31',
            },
            {
                name: 'Iğdır Province',
                state_code: '76',
            },
            {
                name: 'Isparta Province',
                state_code: '32',
            },
            {
                name: 'Istanbul Province',
                state_code: '34',
            },
            {
                name: 'İzmir Province',
                state_code: '35',
            },
            {
                name: 'Kahramanmaraş Province',
                state_code: '46',
            },
            {
                name: 'Karabük Province',
                state_code: '78',
            },
            {
                name: 'Karaman Province',
                state_code: '70',
            },
            {
                name: 'Kars Province',
                state_code: '36',
            },
            {
                name: 'Kastamonu Province',
                state_code: '37',
            },
            {
                name: 'Kayseri Province',
                state_code: '38',
            },
            {
                name: 'Kilis Province',
                state_code: '79',
            },
            {
                name: 'Kırıkkale Province',
                state_code: '71',
            },
            {
                name: 'Kırklareli Province',
                state_code: '39',
            },
            {
                name: 'Kırşehir Province',
                state_code: '40',
            },
            {
                name: 'Kocaeli Province',
                state_code: '41',
            },
            {
                name: 'Konya Province',
                state_code: '42',
            },
            {
                name: 'Kütahya Province',
                state_code: '43',
            },
            {
                name: 'Malatya Province',
                state_code: '44',
            },
            {
                name: 'Manisa Province',
                state_code: '45',
            },
            {
                name: 'Mardin Province',
                state_code: '47',
            },
            {
                name: 'Mersin Province',
                state_code: '33',
            },
            {
                name: 'Muğla Province',
                state_code: '48',
            },
            {
                name: 'Muş Province',
                state_code: '49',
            },
            {
                name: 'Nevşehir Province',
                state_code: '50',
            },
            {
                name: 'Niğde Province',
                state_code: '51',
            },
            {
                name: 'Ordu Province',
                state_code: '52',
            },
            {
                name: 'Osmaniye Province',
                state_code: '80',
            },
            {
                name: 'Rize Province',
                state_code: '53',
            },
            {
                name: 'Sakarya Province',
                state_code: '54',
            },
            {
                name: 'Samsun Province',
                state_code: '55',
            },
            {
                name: 'Şanlıurfa Province',
                state_code: '63',
            },
            {
                name: 'Siirt Province',
                state_code: '56',
            },
            {
                name: 'Sinop Province',
                state_code: '57',
            },
            {
                name: 'Sivas Province',
                state_code: '58',
            },
            {
                name: 'Şırnak Province',
                state_code: '73',
            },
            {
                name: 'Tekirdağ Province',
                state_code: '59',
            },
            {
                name: 'Tokat Province',
                state_code: '60',
            },
            {
                name: 'Trabzon Province',
                state_code: '61',
            },
            {
                name: 'Tunceli Province',
                state_code: '62',
            },
            {
                name: 'Uşak Province',
                state_code: '64',
            },
            {
                name: 'Van Province',
                state_code: '65',
            },
            {
                name: 'Yalova Province',
                state_code: '77',
            },
            {
                name: 'Yozgat Province',
                state_code: '66',
            },
            {
                name: 'Zonguldak Province',
                state_code: '67',
            },
        ],
    },
    {
        name: 'Turkmenistan',
        iso3: 'TKM',
        iso2: 'TM',
        states: [
            {
                name: 'Ahal Region',
                state_code: 'A',
            },
            {
                name: 'Ashgabat',
                state_code: 'S',
            },
            {
                name: 'Balkan Region',
                state_code: 'B',
            },
            {
                name: 'Daşoguz Region',
                state_code: 'D',
            },
            {
                name: 'Lebap Region',
                state_code: 'L',
            },
            {
                name: 'Mary Region',
                state_code: 'M',
            },
        ],
    },
    {
        name: 'Turks And Caicos Islands',
        iso3: 'TCA',
        iso2: 'TC',
        states: [],
    },
    {
        name: 'Tuvalu',
        iso3: 'TUV',
        iso2: 'TV',
        states: [
            {
                name: 'Funafuti',
                state_code: 'FUN',
            },
            {
                name: 'Nanumanga',
                state_code: 'NMG',
            },
            {
                name: 'Nanumea',
                state_code: 'NMA',
            },
            {
                name: 'Niutao Island Council',
                state_code: 'NIT',
            },
            {
                name: 'Nui',
                state_code: 'NUI',
            },
            {
                name: 'Nukufetau',
                state_code: 'NKF',
            },
            {
                name: 'Nukulaelae',
                state_code: 'NKL',
            },
            {
                name: 'Vaitupu',
                state_code: 'VAI',
            },
        ],
    },
    {
        name: 'Uganda',
        iso3: 'UGA',
        iso2: 'UG',
        states: [
            {
                name: 'Abim District',
                state_code: '314',
            },
            {
                name: 'Adjumani District',
                state_code: '301',
            },
            {
                name: 'Agago District',
                state_code: '322',
            },
            {
                name: 'Alebtong District',
                state_code: '323',
            },
            {
                name: 'Amolatar District',
                state_code: '315',
            },
            {
                name: 'Amudat District',
                state_code: '324',
            },
            {
                name: 'Amuria District',
                state_code: '216',
            },
            {
                name: 'Amuru District',
                state_code: '316',
            },
            {
                name: 'Apac District',
                state_code: '302',
            },
            {
                name: 'Arua District',
                state_code: '303',
            },
            {
                name: 'Budaka District',
                state_code: '217',
            },
            {
                name: 'Bududa District',
                state_code: '218',
            },
            {
                name: 'Bugiri District',
                state_code: '201',
            },
            {
                name: 'Buhweju District',
                state_code: '420',
            },
            {
                name: 'Buikwe District',
                state_code: '117',
            },
            {
                name: 'Bukedea District',
                state_code: '219',
            },
            {
                name: 'Bukomansimbi District',
                state_code: '118',
            },
            {
                name: 'Bukwo District',
                state_code: '220',
            },
            {
                name: 'Bulambuli District',
                state_code: '225',
            },
            {
                name: 'Buliisa District',
                state_code: '416',
            },
            {
                name: 'Bundibugyo District',
                state_code: '401',
            },
            {
                name: 'Bunyangabu District',
                state_code: '430',
            },
            {
                name: 'Bushenyi District',
                state_code: '402',
            },
            {
                name: 'Busia District',
                state_code: '202',
            },
            {
                name: 'Butaleja District',
                state_code: '221',
            },
            {
                name: 'Butambala District',
                state_code: '119',
            },
            {
                name: 'Butebo District',
                state_code: '233',
            },
            {
                name: 'Buvuma District',
                state_code: '120',
            },
            {
                name: 'Buyende District',
                state_code: '226',
            },
            {
                name: 'Central Region',
                state_code: 'C',
            },
            {
                name: 'Dokolo District',
                state_code: '317',
            },
            {
                name: 'Eastern Region',
                state_code: 'E',
            },
            {
                name: 'Gomba District',
                state_code: '121',
            },
            {
                name: 'Gulu District',
                state_code: '304',
            },
            {
                name: 'Ibanda District',
                state_code: '417',
            },
            {
                name: 'Iganga District',
                state_code: '203',
            },
            {
                name: 'Isingiro District',
                state_code: '418',
            },
            {
                name: 'Jinja District',
                state_code: '204',
            },
            {
                name: 'Kaabong District',
                state_code: '318',
            },
            {
                name: 'Kabale District',
                state_code: '404',
            },
            {
                name: 'Kabarole District',
                state_code: '405',
            },
            {
                name: 'Kaberamaido District',
                state_code: '213',
            },
            {
                name: 'Kagadi District',
                state_code: '427',
            },
            {
                name: 'Kakumiro District',
                state_code: '428',
            },
            {
                name: 'Kalangala District',
                state_code: '101',
            },
            {
                name: 'Kaliro District',
                state_code: '222',
            },
            {
                name: 'Kalungu District',
                state_code: '122',
            },
            {
                name: 'Kampala District',
                state_code: '102',
            },
            {
                name: 'Kamuli District',
                state_code: '205',
            },
            {
                name: 'Kamwenge District',
                state_code: '413',
            },
            {
                name: 'Kanungu District',
                state_code: '414',
            },
            {
                name: 'Kapchorwa District',
                state_code: '206',
            },
            {
                name: 'Kasese District',
                state_code: '406',
            },
            {
                name: 'Katakwi District',
                state_code: '207',
            },
            {
                name: 'Kayunga District',
                state_code: '112',
            },
            {
                name: 'Kibaale District',
                state_code: '407',
            },
            {
                name: 'Kiboga District',
                state_code: '103',
            },
            {
                name: 'Kibuku District',
                state_code: '227',
            },
            {
                name: 'Kiruhura District',
                state_code: '419',
            },
            {
                name: 'Kiryandongo District',
                state_code: '421',
            },
            {
                name: 'Kisoro District',
                state_code: '408',
            },
            {
                name: 'Kitgum District',
                state_code: '305',
            },
            {
                name: 'Koboko District',
                state_code: '319',
            },
            {
                name: 'Kole District',
                state_code: '325',
            },
            {
                name: 'Kotido District',
                state_code: '306',
            },
            {
                name: 'Kumi District',
                state_code: '208',
            },
            {
                name: 'Kween District',
                state_code: '228',
            },
            {
                name: 'Kyankwanzi District',
                state_code: '123',
            },
            {
                name: 'Kyegegwa District',
                state_code: '422',
            },
            {
                name: 'Kyenjojo District',
                state_code: '415',
            },
            {
                name: 'Kyotera District',
                state_code: '125',
            },
            {
                name: 'Lamwo District',
                state_code: '326',
            },
            {
                name: 'Lira District',
                state_code: '307',
            },
            {
                name: 'Luuka District',
                state_code: '229',
            },
            {
                name: 'Luwero District',
                state_code: '104',
            },
            {
                name: 'Lwengo District',
                state_code: '124',
            },
            {
                name: 'Lyantonde District',
                state_code: '114',
            },
            {
                name: 'Manafwa District',
                state_code: '223',
            },
            {
                name: 'Maracha District',
                state_code: '320',
            },
            {
                name: 'Masaka District',
                state_code: '105',
            },
            {
                name: 'Masindi District',
                state_code: '409',
            },
            {
                name: 'Mayuge District',
                state_code: '214',
            },
            {
                name: 'Mbale District',
                state_code: '209',
            },
            {
                name: 'Mbarara District',
                state_code: '410',
            },
            {
                name: 'Mitooma District',
                state_code: '423',
            },
            {
                name: 'Mityana District',
                state_code: '115',
            },
            {
                name: 'Moroto District',
                state_code: '308',
            },
            {
                name: 'Moyo District',
                state_code: '309',
            },
            {
                name: 'Mpigi District',
                state_code: '106',
            },
            {
                name: 'Mubende District',
                state_code: '107',
            },
            {
                name: 'Mukono District',
                state_code: '108',
            },
            {
                name: 'Nakapiripirit District',
                state_code: '311',
            },
            {
                name: 'Nakaseke District',
                state_code: '116',
            },
            {
                name: 'Nakasongola District',
                state_code: '109',
            },
            {
                name: 'Namayingo District',
                state_code: '230',
            },
            {
                name: 'Namisindwa District',
                state_code: '234',
            },
            {
                name: 'Namutumba District',
                state_code: '224',
            },
            {
                name: 'Napak District',
                state_code: '327',
            },
            {
                name: 'Nebbi District',
                state_code: '310',
            },
            {
                name: 'Ngora District',
                state_code: '231',
            },
            {
                name: 'Northern Region',
                state_code: 'N',
            },
            {
                name: 'Ntoroko District',
                state_code: '424',
            },
            {
                name: 'Ntungamo District',
                state_code: '411',
            },
            {
                name: 'Nwoya District',
                state_code: '328',
            },
            {
                name: 'Omoro District',
                state_code: '331',
            },
            {
                name: 'Otuke District',
                state_code: '329',
            },
            {
                name: 'Oyam District',
                state_code: '321',
            },
            {
                name: 'Pader District',
                state_code: '312',
            },
            {
                name: 'Pakwach District',
                state_code: '332',
            },
            {
                name: 'Pallisa District',
                state_code: '210',
            },
            {
                name: 'Rakai District',
                state_code: '110',
            },
            {
                name: 'Rubanda District',
                state_code: '429',
            },
            {
                name: 'Rubirizi District',
                state_code: '425',
            },
            {
                name: 'Rukiga District',
                state_code: '431',
            },
            {
                name: 'Rukungiri District',
                state_code: '412',
            },
            {
                name: 'Sembabule District',
                state_code: '111',
            },
            {
                name: 'Serere District',
                state_code: '232',
            },
            {
                name: 'Sheema District',
                state_code: '426',
            },
            {
                name: 'Sironko District',
                state_code: '215',
            },
            {
                name: 'Soroti District',
                state_code: '211',
            },
            {
                name: 'Tororo District',
                state_code: '212',
            },
            {
                name: 'Wakiso District',
                state_code: '113',
            },
            {
                name: 'Western Region',
                state_code: 'W',
            },
            {
                name: 'Yumbe District',
                state_code: '313',
            },
            {
                name: 'Zombo District',
                state_code: '330',
            },
        ],
    },
    {
        name: 'Ukraine',
        iso3: 'UKR',
        iso2: 'UA',
        states: [
            {
                name: 'Autonomous Republic of Crimea',
                state_code: '43',
            },
            {
                name: 'Cherkasy Oblast',
                state_code: '71',
            },
            {
                name: 'Chernihiv Oblast',
                state_code: '74',
            },
            {
                name: 'Chernivtsi Oblast',
                state_code: '77',
            },
            {
                name: 'Dnipropetrovsk Oblast',
                state_code: '12',
            },
            {
                name: 'Donetsk Oblast',
                state_code: '14',
            },
            {
                name: 'Ivano-Frankivsk Oblast',
                state_code: '26',
            },
            {
                name: 'Kharkiv Oblast',
                state_code: '63',
            },
            {
                name: 'Kherson Oblast',
                state_code: '65',
            },
            {
                name: 'Khmelnytsky Oblast',
                state_code: '68',
            },
            {
                name: 'Kiev',
                state_code: '30',
            },
            {
                name: 'Kirovohrad Oblast',
                state_code: '35',
            },
            {
                name: 'Kyiv Oblast',
                state_code: '32',
            },
            {
                name: 'Luhansk Oblast',
                state_code: '09',
            },
            {
                name: 'Lviv Oblast',
                state_code: '46',
            },
            {
                name: 'Mykolaiv Oblast',
                state_code: '48',
            },
            {
                name: 'Odessa Oblast',
                state_code: '51',
            },
            {
                name: 'Rivne Oblast',
                state_code: '56',
            },
            {
                name: 'Sumy Oblast',
                state_code: '59',
            },
            {
                name: 'Ternopil Oblast',
                state_code: '61',
            },
            {
                name: 'Vinnytsia Oblast',
                state_code: '05',
            },
            {
                name: 'Volyn Oblast',
                state_code: '07',
            },
            {
                name: 'Zakarpattia Oblast',
                state_code: '21',
            },
            {
                name: 'Zaporizhzhya Oblast',
                state_code: '23',
            },
            {
                name: 'Zhytomyr Oblast',
                state_code: '18',
            },
        ],
    },
    {
        name: 'United Arab Emirates',
        iso3: 'ARE',
        iso2: 'AE',
        states: [
            {
                name: 'Abu Dhabi Emirate',
                state_code: 'AZ',
            },
            {
                name: 'Ajman Emirate',
                state_code: 'AJ',
            },
            {
                name: 'Dubai',
                state_code: 'DU',
            },
            {
                name: 'Fujairah',
                state_code: 'FU',
            },
            {
                name: 'Ras al-Khaimah',
                state_code: 'RK',
            },
            {
                name: 'Sharjah Emirate',
                state_code: 'SH',
            },
            {
                name: 'Umm al-Quwain',
                state_code: 'UQ',
            },
        ],
    },
    {
        name: 'United Kingdom',
        iso3: 'GBR',
        iso2: 'GB',
        states: [
            {
                name: 'Aberdeen',
                state_code: 'ABE',
            },
            {
                name: 'Aberdeenshire',
                state_code: 'ABD',
            },
            {
                name: 'Angus',
                state_code: 'ANS',
            },
            {
                name: 'Antrim',
                state_code: 'ANT',
            },
            {
                name: 'Antrim and Newtownabbey',
                state_code: 'ANN',
            },
            {
                name: 'Ards',
                state_code: 'ARD',
            },
            {
                name: 'Ards and North Down',
                state_code: 'AND',
            },
            {
                name: 'Argyll and Bute',
                state_code: 'AGB',
            },
            {
                name: 'Armagh City and District Council',
                state_code: 'ARM',
            },
            {
                name: 'Armagh, Banbridge and Craigavon',
                state_code: 'ABC',
            },
            {
                name: 'Ascension Island',
                state_code: 'SH-AC',
            },
            {
                name: 'Ballymena Borough',
                state_code: 'BLA',
            },
            {
                name: 'Ballymoney',
                state_code: 'BLY',
            },
            {
                name: 'Banbridge',
                state_code: 'BNB',
            },
            {
                name: 'Barnsley',
                state_code: 'BNS',
            },
            {
                name: 'Bath and North East Somerset',
                state_code: 'BAS',
            },
            {
                name: 'Bedford',
                state_code: 'BDF',
            },
            {
                name: 'Belfast district',
                state_code: 'BFS',
            },
            {
                name: 'Birmingham',
                state_code: 'BIR',
            },
            {
                name: 'Blackburn with Darwen',
                state_code: 'BBD',
            },
            {
                name: 'Blackpool',
                state_code: 'BPL',
            },
            {
                name: 'Blaenau Gwent County Borough',
                state_code: 'BGW',
            },
            {
                name: 'Bolton',
                state_code: 'BOL',
            },
            {
                name: 'Bournemouth',
                state_code: 'BMH',
            },
            {
                name: 'Bracknell Forest',
                state_code: 'BRC',
            },
            {
                name: 'Bradford',
                state_code: 'BRD',
            },
            {
                name: 'Bridgend County Borough',
                state_code: 'BGE',
            },
            {
                name: 'Brighton and Hove',
                state_code: 'BNH',
            },
            {
                name: 'Buckinghamshire',
                state_code: 'BKM',
            },
            {
                name: 'Bury',
                state_code: 'BUR',
            },
            {
                name: 'Caerphilly County Borough',
                state_code: 'CAY',
            },
            {
                name: 'Calderdale',
                state_code: 'CLD',
            },
            {
                name: 'Cambridgeshire',
                state_code: 'CAM',
            },
            {
                name: 'Carmarthenshire',
                state_code: 'CMN',
            },
            {
                name: 'Carrickfergus Borough Council',
                state_code: 'CKF',
            },
            {
                name: 'Castlereagh',
                state_code: 'CSR',
            },
            {
                name: 'Causeway Coast and Glens',
                state_code: 'CCG',
            },
            {
                name: 'Central Bedfordshire',
                state_code: 'CBF',
            },
            {
                name: 'Ceredigion',
                state_code: 'CGN',
            },
            {
                name: 'Cheshire East',
                state_code: 'CHE',
            },
            {
                name: 'Cheshire West and Chester',
                state_code: 'CHW',
            },
            {
                name: 'City and County of Cardiff',
                state_code: 'CRF',
            },
            {
                name: 'City and County of Swansea',
                state_code: 'SWA',
            },
            {
                name: 'City of Bristol',
                state_code: 'BST',
            },
            {
                name: 'City of Derby',
                state_code: 'DER',
            },
            {
                name: 'City of Kingston upon Hull',
                state_code: 'KHL',
            },
            {
                name: 'City of Leicester',
                state_code: 'LCE',
            },
            {
                name: 'City of London',
                state_code: 'LND',
            },
            {
                name: 'City of Nottingham',
                state_code: 'NGM',
            },
            {
                name: 'City of Peterborough',
                state_code: 'PTE',
            },
            {
                name: 'City of Plymouth',
                state_code: 'PLY',
            },
            {
                name: 'City of Portsmouth',
                state_code: 'POR',
            },
            {
                name: 'City of Southampton',
                state_code: 'STH',
            },
            {
                name: 'City of Stoke-on-Trent',
                state_code: 'STE',
            },
            {
                name: 'City of Sunderland',
                state_code: 'SND',
            },
            {
                name: 'City of Westminster',
                state_code: 'WSM',
            },
            {
                name: 'City of Wolverhampton',
                state_code: 'WLV',
            },
            {
                name: 'City of York',
                state_code: 'YOR',
            },
            {
                name: 'Clackmannanshire',
                state_code: 'CLK',
            },
            {
                name: 'Coleraine Borough Council',
                state_code: 'CLR',
            },
            {
                name: 'Conwy County Borough',
                state_code: 'CWY',
            },
            {
                name: 'Cookstown District Council',
                state_code: 'CKT',
            },
            {
                name: 'Cornwall',
                state_code: 'CON',
            },
            {
                name: 'County Durham',
                state_code: 'DUR',
            },
            {
                name: 'Coventry',
                state_code: 'COV',
            },
            {
                name: 'Craigavon Borough Council',
                state_code: 'CGV',
            },
            {
                name: 'Cumbria',
                state_code: 'CMA',
            },
            {
                name: 'Darlington',
                state_code: 'DAL',
            },
            {
                name: 'Denbighshire',
                state_code: 'DEN',
            },
            {
                name: 'Derbyshire',
                state_code: 'DBY',
            },
            {
                name: 'Derry City and Strabane',
                state_code: 'DRS',
            },
            {
                name: 'Derry City Council',
                state_code: 'DRY',
            },
            {
                name: 'Devon',
                state_code: 'DEV',
            },
            {
                name: 'Doncaster',
                state_code: 'DNC',
            },
            {
                name: 'Dorset',
                state_code: 'DOR',
            },
            {
                name: 'Down District Council',
                state_code: 'DOW',
            },
            {
                name: 'Dudley',
                state_code: 'DUD',
            },
            {
                name: 'Dumfries and Galloway',
                state_code: 'DGY',
            },
            {
                name: 'Dundee',
                state_code: 'DND',
            },
            {
                name: 'Dungannon and South Tyrone Borough Council',
                state_code: 'DGN',
            },
            {
                name: 'East Ayrshire',
                state_code: 'EAY',
            },
            {
                name: 'East Dunbartonshire',
                state_code: 'EDU',
            },
            {
                name: 'East Lothian',
                state_code: 'ELN',
            },
            {
                name: 'East Renfrewshire',
                state_code: 'ERW',
            },
            {
                name: 'East Riding of Yorkshire',
                state_code: 'ERY',
            },
            {
                name: 'East Sussex',
                state_code: 'ESX',
            },
            {
                name: 'Edinburgh',
                state_code: 'EDH',
            },
            {
                name: 'England',
                state_code: 'ENG',
            },
            {
                name: 'Essex',
                state_code: 'ESS',
            },
            {
                name: 'Falkirk',
                state_code: 'FAL',
            },
            {
                name: 'Fermanagh and Omagh',
                state_code: 'FMO',
            },
            {
                name: 'Fermanagh District Council',
                state_code: 'FER',
            },
            {
                name: 'Fife',
                state_code: 'FIF',
            },
            {
                name: 'Flintshire',
                state_code: 'FLN',
            },
            {
                name: 'Gateshead',
                state_code: 'GAT',
            },
            {
                name: 'Glasgow',
                state_code: 'GLG',
            },
            {
                name: 'Gloucestershire',
                state_code: 'GLS',
            },
            {
                name: 'Gwynedd',
                state_code: 'GWN',
            },
            {
                name: 'Halton',
                state_code: 'HAL',
            },
            {
                name: 'Hampshire',
                state_code: 'HAM',
            },
            {
                name: 'Hartlepool',
                state_code: 'HPL',
            },
            {
                name: 'Herefordshire',
                state_code: 'HEF',
            },
            {
                name: 'Hertfordshire',
                state_code: 'HRT',
            },
            {
                name: 'Highland',
                state_code: 'HLD',
            },
            {
                name: 'Inverclyde',
                state_code: 'IVC',
            },
            {
                name: 'Isle of Wight',
                state_code: 'IOW',
            },
            {
                name: 'Isles of Scilly',
                state_code: 'IOS',
            },
            {
                name: 'Kent',
                state_code: 'KEN',
            },
            {
                name: 'Kirklees',
                state_code: 'KIR',
            },
            {
                name: 'Knowsley',
                state_code: 'KWL',
            },
            {
                name: 'Lancashire',
                state_code: 'LAN',
            },
            {
                name: 'Larne Borough Council',
                state_code: 'LRN',
            },
            {
                name: 'Leeds',
                state_code: 'LDS',
            },
            {
                name: 'Leicestershire',
                state_code: 'LEC',
            },
            {
                name: 'Limavady Borough Council',
                state_code: 'LMV',
            },
            {
                name: 'Lincolnshire',
                state_code: 'LIN',
            },
            {
                name: 'Lisburn and Castlereagh',
                state_code: 'LBC',
            },
            {
                name: 'Lisburn City Council',
                state_code: 'LSB',
            },
            {
                name: 'Liverpool',
                state_code: 'LIV',
            },
            {
                name: 'London Borough of Barking and Dagenham',
                state_code: 'BDG',
            },
            {
                name: 'London Borough of Barnet',
                state_code: 'BNE',
            },
            {
                name: 'London Borough of Bexley',
                state_code: 'BEX',
            },
            {
                name: 'London Borough of Brent',
                state_code: 'BEN',
            },
            {
                name: 'London Borough of Bromley',
                state_code: 'BRY',
            },
            {
                name: 'London Borough of Camden',
                state_code: 'CMD',
            },
            {
                name: 'London Borough of Croydon',
                state_code: 'CRY',
            },
            {
                name: 'London Borough of Ealing',
                state_code: 'EAL',
            },
            {
                name: 'London Borough of Enfield',
                state_code: 'ENF',
            },
            {
                name: 'London Borough of Hackney',
                state_code: 'HCK',
            },
            {
                name: 'London Borough of Hammersmith and Fulham',
                state_code: 'HMF',
            },
            {
                name: 'London Borough of Haringey',
                state_code: 'HRY',
            },
            {
                name: 'London Borough of Harrow',
                state_code: 'HRW',
            },
            {
                name: 'London Borough of Havering',
                state_code: 'HAV',
            },
            {
                name: 'London Borough of Hillingdon',
                state_code: 'HIL',
            },
            {
                name: 'London Borough of Hounslow',
                state_code: 'HNS',
            },
            {
                name: 'London Borough of Islington',
                state_code: 'ISL',
            },
            {
                name: 'London Borough of Lambeth',
                state_code: 'LBH',
            },
            {
                name: 'London Borough of Lewisham',
                state_code: 'LEW',
            },
            {
                name: 'London Borough of Merton',
                state_code: 'MRT',
            },
            {
                name: 'London Borough of Newham',
                state_code: 'NWM',
            },
            {
                name: 'London Borough of Redbridge',
                state_code: 'RDB',
            },
            {
                name: 'London Borough of Richmond upon Thames',
                state_code: 'RIC',
            },
            {
                name: 'London Borough of Southwark',
                state_code: 'SWK',
            },
            {
                name: 'London Borough of Sutton',
                state_code: 'STN',
            },
            {
                name: 'London Borough of Tower Hamlets',
                state_code: 'TWH',
            },
            {
                name: 'London Borough of Waltham Forest',
                state_code: 'WFT',
            },
            {
                name: 'London Borough of Wandsworth',
                state_code: 'WND',
            },
            {
                name: 'Magherafelt District Council',
                state_code: 'MFT',
            },
            {
                name: 'Manchester',
                state_code: 'MAN',
            },
            {
                name: 'Medway',
                state_code: 'MDW',
            },
            {
                name: 'Merthyr Tydfil County Borough',
                state_code: 'MTY',
            },
            {
                name: 'Metropolitan Borough of Wigan',
                state_code: 'WGN',
            },
            {
                name: 'Mid and East Antrim',
                state_code: 'MEA',
            },
            {
                name: 'Mid Ulster',
                state_code: 'MUL',
            },
            {
                name: 'Middlesbrough',
                state_code: 'MDB',
            },
            {
                name: 'Midlothian',
                state_code: 'MLN',
            },
            {
                name: 'Milton Keynes',
                state_code: 'MIK',
            },
            {
                name: 'Monmouthshire',
                state_code: 'MON',
            },
            {
                name: 'Moray',
                state_code: 'MRY',
            },
            {
                name: 'Moyle District Council',
                state_code: 'MYL',
            },
            {
                name: 'Neath Port Talbot County Borough',
                state_code: 'NTL',
            },
            {
                name: 'Newcastle upon Tyne',
                state_code: 'NET',
            },
            {
                name: 'Newport',
                state_code: 'NWP',
            },
            {
                name: 'Newry and Mourne District Council',
                state_code: 'NYM',
            },
            {
                name: 'Newry, Mourne and Down',
                state_code: 'NMD',
            },
            {
                name: 'Newtownabbey Borough Council',
                state_code: 'NTA',
            },
            {
                name: 'Norfolk',
                state_code: 'NFK',
            },
            {
                name: 'North Ayrshire',
                state_code: 'NAY',
            },
            {
                name: 'North Down Borough Council',
                state_code: 'NDN',
            },
            {
                name: 'North East Lincolnshire',
                state_code: 'NEL',
            },
            {
                name: 'North Lanarkshire',
                state_code: 'NLK',
            },
            {
                name: 'North Lincolnshire',
                state_code: 'NLN',
            },
            {
                name: 'North Somerset',
                state_code: 'NSM',
            },
            {
                name: 'North Tyneside',
                state_code: 'NTY',
            },
            {
                name: 'North Yorkshire',
                state_code: 'NYK',
            },
            {
                name: 'Northamptonshire',
                state_code: 'NTH',
            },
            {
                name: 'Northern Ireland',
                state_code: 'NIR',
            },
            {
                name: 'Northumberland',
                state_code: 'NBL',
            },
            {
                name: 'Nottinghamshire',
                state_code: 'NTT',
            },
            {
                name: 'Oldham',
                state_code: 'OLD',
            },
            {
                name: 'Omagh District Council',
                state_code: 'OMH',
            },
            {
                name: 'Orkney Islands',
                state_code: 'ORK',
            },
            {
                name: 'Outer Hebrides',
                state_code: 'ELS',
            },
            {
                name: 'Oxfordshire',
                state_code: 'OXF',
            },
            {
                name: 'Pembrokeshire',
                state_code: 'PEM',
            },
            {
                name: 'Perth and Kinross',
                state_code: 'PKN',
            },
            {
                name: 'Poole',
                state_code: 'POL',
            },
            {
                name: 'Powys',
                state_code: 'POW',
            },
            {
                name: 'Reading',
                state_code: 'RDG',
            },
            {
                name: 'Redcar and Cleveland',
                state_code: 'RCC',
            },
            {
                name: 'Renfrewshire',
                state_code: 'RFW',
            },
            {
                name: 'Rhondda Cynon Taf',
                state_code: 'RCT',
            },
            {
                name: 'Rochdale',
                state_code: 'RCH',
            },
            {
                name: 'Rotherham',
                state_code: 'ROT',
            },
            {
                name: 'Royal Borough of Greenwich',
                state_code: 'GRE',
            },
            {
                name: 'Royal Borough of Kensington and Chelsea',
                state_code: 'KEC',
            },
            {
                name: 'Royal Borough of Kingston upon Thames',
                state_code: 'KTT',
            },
            {
                name: 'Rutland',
                state_code: 'RUT',
            },
            {
                name: 'Saint Helena',
                state_code: 'SH-HL',
            },
            {
                name: 'Salford',
                state_code: 'SLF',
            },
            {
                name: 'Sandwell',
                state_code: 'SAW',
            },
            {
                name: 'Scotland',
                state_code: 'SCT',
            },
            {
                name: 'Scottish Borders',
                state_code: 'SCB',
            },
            {
                name: 'Sefton',
                state_code: 'SFT',
            },
            {
                name: 'Sheffield',
                state_code: 'SHF',
            },
            {
                name: 'Shetland Islands',
                state_code: 'ZET',
            },
            {
                name: 'Shropshire',
                state_code: 'SHR',
            },
            {
                name: 'Slough',
                state_code: 'SLG',
            },
            {
                name: 'Solihull',
                state_code: 'SOL',
            },
            {
                name: 'Somerset',
                state_code: 'SOM',
            },
            {
                name: 'South Ayrshire',
                state_code: 'SAY',
            },
            {
                name: 'South Gloucestershire',
                state_code: 'SGC',
            },
            {
                name: 'South Lanarkshire',
                state_code: 'SLK',
            },
            {
                name: 'South Tyneside',
                state_code: 'STY',
            },
            {
                name: 'Southend-on-Sea',
                state_code: 'SOS',
            },
            {
                name: 'St Helens',
                state_code: 'SHN',
            },
            {
                name: 'Staffordshire',
                state_code: 'STS',
            },
            {
                name: 'Stirling',
                state_code: 'STG',
            },
            {
                name: 'Stockport',
                state_code: 'SKP',
            },
            {
                name: 'Stockton-on-Tees',
                state_code: 'STT',
            },
            {
                name: 'Strabane District Council',
                state_code: 'STB',
            },
            {
                name: 'Suffolk',
                state_code: 'SFK',
            },
            {
                name: 'Surrey',
                state_code: 'SRY',
            },
            {
                name: 'Swindon',
                state_code: 'SWD',
            },
            {
                name: 'Tameside',
                state_code: 'TAM',
            },
            {
                name: 'Telford and Wrekin',
                state_code: 'TFW',
            },
            {
                name: 'Thurrock',
                state_code: 'THR',
            },
            {
                name: 'Torbay',
                state_code: 'TOB',
            },
            {
                name: 'Torfaen',
                state_code: 'TOF',
            },
            {
                name: 'Trafford',
                state_code: 'TRF',
            },
            {
                name: 'United Kingdom',
                state_code: 'UKM',
            },
            {
                name: 'Vale of Glamorgan',
                state_code: 'VGL',
            },
            {
                name: 'Wakefield',
                state_code: 'WKF',
            },
            {
                name: 'Wales',
                state_code: 'WLS',
            },
            {
                name: 'Walsall',
                state_code: 'WLL',
            },
            {
                name: 'Warrington',
                state_code: 'WRT',
            },
            {
                name: 'Warwickshire',
                state_code: 'WAR',
            },
            {
                name: 'West Berkshire',
                state_code: 'WBK',
            },
            {
                name: 'West Dunbartonshire',
                state_code: 'WDU',
            },
            {
                name: 'West Lothian',
                state_code: 'WLN',
            },
            {
                name: 'West Sussex',
                state_code: 'WSX',
            },
            {
                name: 'Wiltshire',
                state_code: 'WIL',
            },
            {
                name: 'Windsor and Maidenhead',
                state_code: 'WNM',
            },
            {
                name: 'Wirral',
                state_code: 'WRL',
            },
            {
                name: 'Wokingham',
                state_code: 'WOK',
            },
            {
                name: 'Worcestershire',
                state_code: 'WOR',
            },
            {
                name: 'Wrexham County Borough',
                state_code: 'WRX',
            },
        ],
    },
    {
        name: 'United States',
        iso3: 'USA',
        iso2: 'US',
        states: [
            {
                name: 'Alabama',
                state_code: 'AL',
            },
            {
                name: 'Alaska',
                state_code: 'AK',
            },
            {
                name: 'American Samoa',
                state_code: 'AS',
            },
            {
                name: 'Arizona',
                state_code: 'AZ',
            },
            {
                name: 'Arkansas',
                state_code: 'AR',
            },
            {
                name: 'Baker Island',
                state_code: 'UM-81',
            },
            {
                name: 'California',
                state_code: 'CA',
            },
            {
                name: 'Colorado',
                state_code: 'CO',
            },
            {
                name: 'Connecticut',
                state_code: 'CT',
            },
            {
                name: 'Delaware',
                state_code: 'DE',
            },
            {
                name: 'District of Columbia',
                state_code: 'DC',
            },
            {
                name: 'Florida',
                state_code: 'FL',
            },
            {
                name: 'Georgia',
                state_code: 'GA',
            },
            {
                name: 'Guam',
                state_code: 'GU',
            },
            {
                name: 'Hawaii',
                state_code: 'HI',
            },
            {
                name: 'Howland Island',
                state_code: 'UM-84',
            },
            {
                name: 'Idaho',
                state_code: 'ID',
            },
            {
                name: 'Illinois',
                state_code: 'IL',
            },
            {
                name: 'Indiana',
                state_code: 'IN',
            },
            {
                name: 'Iowa',
                state_code: 'IA',
            },
            {
                name: 'Jarvis Island',
                state_code: 'UM-86',
            },
            {
                name: 'Johnston Atoll',
                state_code: 'UM-67',
            },
            {
                name: 'Kansas',
                state_code: 'KS',
            },
            {
                name: 'Kentucky',
                state_code: 'KY',
            },
            {
                name: 'Kingman Reef',
                state_code: 'UM-89',
            },
            {
                name: 'Louisiana',
                state_code: 'LA',
            },
            {
                name: 'Maine',
                state_code: 'ME',
            },
            {
                name: 'Maryland',
                state_code: 'MD',
            },
            {
                name: 'Massachusetts',
                state_code: 'MA',
            },
            {
                name: 'Michigan',
                state_code: 'MI',
            },
            {
                name: 'Midway Atoll',
                state_code: 'UM-71',
            },
            {
                name: 'Minnesota',
                state_code: 'MN',
            },
            {
                name: 'Mississippi',
                state_code: 'MS',
            },
            {
                name: 'Missouri',
                state_code: 'MO',
            },
            {
                name: 'Montana',
                state_code: 'MT',
            },
            {
                name: 'Navassa Island',
                state_code: 'UM-76',
            },
            {
                name: 'Nebraska',
                state_code: 'NE',
            },
            {
                name: 'Nevada',
                state_code: 'NV',
            },
            {
                name: 'New Hampshire',
                state_code: 'NH',
            },
            {
                name: 'New Jersey',
                state_code: 'NJ',
            },
            {
                name: 'New Mexico',
                state_code: 'NM',
            },
            {
                name: 'New York',
                state_code: 'NY',
            },
            {
                name: 'North Carolina',
                state_code: 'NC',
            },
            {
                name: 'North Dakota',
                state_code: 'ND',
            },
            {
                name: 'Northern Mariana Islands',
                state_code: 'MP',
            },
            {
                name: 'Ohio',
                state_code: 'OH',
            },
            {
                name: 'Oklahoma',
                state_code: 'OK',
            },
            {
                name: 'Oregon',
                state_code: 'OR',
            },
            {
                name: 'Palmyra Atoll',
                state_code: 'UM-95',
            },
            {
                name: 'Pennsylvania',
                state_code: 'PA',
            },
            {
                name: 'Puerto Rico',
                state_code: 'PR',
            },
            {
                name: 'Rhode Island',
                state_code: 'RI',
            },
            {
                name: 'South Carolina',
                state_code: 'SC',
            },
            {
                name: 'South Dakota',
                state_code: 'SD',
            },
            {
                name: 'Tennessee',
                state_code: 'TN',
            },
            {
                name: 'Texas',
                state_code: 'TX',
            },
            {
                name: 'United States Minor Outlying Islands',
                state_code: 'UM',
            },
            {
                name: 'United States Virgin Islands',
                state_code: 'VI',
            },
            {
                name: 'Utah',
                state_code: 'UT',
            },
            {
                name: 'Vermont',
                state_code: 'VT',
            },
            {
                name: 'Virginia',
                state_code: 'VA',
            },
            {
                name: 'Wake Island',
                state_code: 'UM-79',
            },
            {
                name: 'Washington',
                state_code: 'WA',
            },
            {
                name: 'West Virginia',
                state_code: 'WV',
            },
            {
                name: 'Wisconsin',
                state_code: 'WI',
            },
            {
                name: 'Wyoming',
                state_code: 'WY',
            },
        ],
    },
    {
        name: 'United States Minor Outlying Islands',
        iso3: 'UMI',
        iso2: 'UM',
        states: [],
    },
    {
        name: 'Uruguay',
        iso3: 'URY',
        iso2: 'UY',
        states: [
            {
                name: 'Artigas Department',
                state_code: 'AR',
            },
            {
                name: 'Canelones Department',
                state_code: 'CA',
            },
            {
                name: 'Cerro Largo Department',
                state_code: 'CL',
            },
            {
                name: 'Colonia Department',
                state_code: 'CO',
            },
            {
                name: 'Durazno Department',
                state_code: 'DU',
            },
            {
                name: 'Flores Department',
                state_code: 'FS',
            },
            {
                name: 'Florida Department',
                state_code: 'FD',
            },
            {
                name: 'Lavalleja Department',
                state_code: 'LA',
            },
            {
                name: 'Maldonado Department',
                state_code: 'MA',
            },
            {
                name: 'Montevideo Department',
                state_code: 'MO',
            },
            {
                name: 'Paysandú Department',
                state_code: 'PA',
            },
            {
                name: 'Río Negro Department',
                state_code: 'RN',
            },
            {
                name: 'Rivera Department',
                state_code: 'RV',
            },
            {
                name: 'Rocha Department',
                state_code: 'RO',
            },
            {
                name: 'Salto Department',
                state_code: 'SA',
            },
            {
                name: 'San José Department',
                state_code: 'SJ',
            },
            {
                name: 'Soriano Department',
                state_code: 'SO',
            },
            {
                name: 'Tacuarembó Department',
                state_code: 'TA',
            },
            {
                name: 'Treinta y Tres Department',
                state_code: 'TT',
            },
        ],
    },
    {
        name: 'Uzbekistan',
        iso3: 'UZB',
        iso2: 'UZ',
        states: [
            {
                name: 'Andijan Region',
                state_code: 'AN',
            },
            {
                name: 'Bukhara Region',
                state_code: 'BU',
            },
            {
                name: 'Fergana Region',
                state_code: 'FA',
            },
            {
                name: 'Jizzakh Region',
                state_code: 'JI',
            },
            {
                name: 'Karakalpakstan',
                state_code: 'QR',
            },
            {
                name: 'Namangan Region',
                state_code: 'NG',
            },
            {
                name: 'Navoiy Region',
                state_code: 'NW',
            },
            {
                name: 'Qashqadaryo Region',
                state_code: 'QA',
            },
            {
                name: 'Samarqand Region',
                state_code: 'SA',
            },
            {
                name: 'Sirdaryo Region',
                state_code: 'SI',
            },
            {
                name: 'Surxondaryo Region',
                state_code: 'SU',
            },
            {
                name: 'Tashkent',
                state_code: 'TK',
            },
            {
                name: 'Tashkent Region',
                state_code: 'TO',
            },
            {
                name: 'Xorazm Region',
                state_code: 'XO',
            },
        ],
    },
    {
        name: 'Vanuatu',
        iso3: 'VUT',
        iso2: 'VU',
        states: [
            {
                name: 'Malampa',
                state_code: 'MAP',
            },
            {
                name: 'Penama',
                state_code: 'PAM',
            },
            {
                name: 'Sanma',
                state_code: 'SAM',
            },
            {
                name: 'Shefa',
                state_code: 'SEE',
            },
            {
                name: 'Tafea',
                state_code: 'TAE',
            },
            {
                name: 'Torba',
                state_code: 'TOB',
            },
        ],
    },
    {
        name: 'Vatican City State (Holy See)',
        iso3: 'VAT',
        iso2: 'VA',
        states: [],
    },
    {
        name: 'Venezuela',
        iso3: 'VEN',
        iso2: 'VE',
        states: [
            {
                name: 'Amazonas',
                state_code: 'Z',
            },
            {
                name: 'Anzoátegui',
                state_code: 'B',
            },
            {
                name: 'Apure',
                state_code: 'C',
            },
            {
                name: 'Aragua',
                state_code: 'D',
            },
            {
                name: 'Barinas',
                state_code: 'E',
            },
            {
                name: 'Bolívar',
                state_code: 'F',
            },
            {
                name: 'Capital District',
                state_code: 'A',
            },
            {
                name: 'Carabobo',
                state_code: 'G',
            },
            {
                name: 'Cojedes',
                state_code: 'H',
            },
            {
                name: 'Delta Amacuro',
                state_code: 'Y',
            },
            {
                name: 'Falcón',
                state_code: 'I',
            },
            {
                name: 'Federal Dependencies of Venezuela',
                state_code: 'W',
            },
            {
                name: 'Guárico',
                state_code: 'J',
            },
            {
                name: 'Lara',
                state_code: 'K',
            },
            {
                name: 'Mérida',
                state_code: 'L',
            },
            {
                name: 'Miranda',
                state_code: 'M',
            },
            {
                name: 'Monagas',
                state_code: 'N',
            },
            {
                name: 'Nueva Esparta',
                state_code: 'O',
            },
            {
                name: 'Portuguesa',
                state_code: 'P',
            },
            {
                name: 'Sucre',
                state_code: 'R',
            },
            {
                name: 'Táchira',
                state_code: 'S',
            },
            {
                name: 'Trujillo',
                state_code: 'T',
            },
            {
                name: 'Vargas',
                state_code: 'X',
            },
            {
                name: 'Yaracuy',
                state_code: 'U',
            },
            {
                name: 'Zulia',
                state_code: 'V',
            },
        ],
    },
    {
        name: 'Vietnam',
        iso3: 'VNM',
        iso2: 'VN',
        states: [
            {
                name: 'An Giang',
                state_code: '44',
            },
            {
                name: 'Bà Rịa-Vũng Tàu',
                state_code: '43',
            },
            {
                name: 'Bắc Giang',
                state_code: '54',
            },
            {
                name: 'Bắc Kạn',
                state_code: '53',
            },
            {
                name: 'Bạc Liêu',
                state_code: '55',
            },
            {
                name: 'Bắc Ninh',
                state_code: '56',
            },
            {
                name: 'Bến Tre',
                state_code: '50',
            },
            {
                name: 'Bình Dương',
                state_code: '57',
            },
            {
                name: 'Bình Định',
                state_code: '31',
            },
            {
                name: 'Bình Phước',
                state_code: '58',
            },
            {
                name: 'Bình Thuận',
                state_code: '40',
            },
            {
                name: 'Cà Mau',
                state_code: '59',
            },
            {
                name: 'Cao Bằng',
                state_code: '04',
            },
            {
                name: 'Da Nang',
                state_code: 'DN',
            },
            {
                name: 'Đắk Lắk',
                state_code: '33',
            },
            {
                name: 'Đắk Nông',
                state_code: '72',
            },
            {
                name: 'Điện Biên',
                state_code: '71',
            },
            {
                name: 'Đồng Nai',
                state_code: '39',
            },
            {
                name: 'Đồng Tháp',
                state_code: '45',
            },
            {
                name: 'Gia Lai',
                state_code: '30',
            },
            {
                name: 'Hà Giang',
                state_code: '03',
            },
            {
                name: 'Hà Nam',
                state_code: '63',
            },
            {
                name: 'Hà Tây',
                state_code: '15',
            },
            {
                name: 'Hà Tĩnh',
                state_code: '23',
            },
            {
                name: 'Hải Dương',
                state_code: '61',
            },
            {
                name: 'Haiphong',
                state_code: 'HP',
            },
            {
                name: 'Hanoi',
                state_code: 'HN',
            },
            {
                name: 'Hậu Giang',
                state_code: '73',
            },
            {
                name: 'Ho Chi Minh City',
                state_code: 'SG',
            },
            {
                name: 'Hòa Bình',
                state_code: '14',
            },
            {
                name: 'Hưng Yên',
                state_code: '66',
            },
            {
                name: 'Khánh Hòa',
                state_code: '34',
            },
            {
                name: 'Kiên Giang',
                state_code: '47',
            },
            {
                name: 'Kon Tum',
                state_code: '28',
            },
            {
                name: 'Lai Châu',
                state_code: '01',
            },
            {
                name: 'Lâm Đồng',
                state_code: '35',
            },
            {
                name: 'Lạng Sơn',
                state_code: '09',
            },
            {
                name: 'Lào Cai',
                state_code: '02',
            },
            {
                name: 'Long An',
                state_code: '41',
            },
            {
                name: 'Nam Định',
                state_code: '67',
            },
            {
                name: 'Nghệ An',
                state_code: '22',
            },
            {
                name: 'Ninh Bình',
                state_code: '18',
            },
            {
                name: 'Ninh Thuận',
                state_code: '36',
            },
            {
                name: 'Phú Thọ',
                state_code: '68',
            },
            {
                name: 'Phú Yên',
                state_code: '32',
            },
            {
                name: 'Quảng Bình',
                state_code: '24',
            },
            {
                name: 'Quảng Nam',
                state_code: '27',
            },
            {
                name: 'Quảng Ngãi',
                state_code: '29',
            },
            {
                name: 'Quảng Ninh',
                state_code: '13',
            },
            {
                name: 'Quảng Trị',
                state_code: '25',
            },
            {
                name: 'Sóc Trăng',
                state_code: '52',
            },
            {
                name: 'Sơn La',
                state_code: '05',
            },
            {
                name: 'Tây Ninh',
                state_code: '37',
            },
            {
                name: 'Thái Bình',
                state_code: '20',
            },
            {
                name: 'Thái Nguyên',
                state_code: '69',
            },
            {
                name: 'Thanh Hóa',
                state_code: '21',
            },
            {
                name: 'Thừa Thiên-Huế',
                state_code: '26',
            },
            {
                name: 'Tiền Giang',
                state_code: '46',
            },
            {
                name: 'Trà Vinh',
                state_code: '51',
            },
            {
                name: 'Tuyên Quang',
                state_code: '07',
            },
            {
                name: 'Vĩnh Long',
                state_code: '49',
            },
            {
                name: 'Vĩnh Phúc',
                state_code: '70',
            },
            {
                name: 'Yên Bái',
                state_code: '06',
            },
        ],
    },
    {
        name: 'Virgin Islands (British)',
        iso3: 'VGB',
        iso2: 'VG',
        states: [],
    },
    {
        name: 'Virgin Islands (US)',
        iso3: 'VIR',
        iso2: 'VI',
        states: [],
    },
    {
        name: 'Wallis And Futuna',
        iso3: 'WLF',
        iso2: 'WF',
        states: [],
    },
    {
        name: 'Western Sahara',
        iso3: 'ESH',
        iso2: 'EH',
        states: [],
    },
    {
        name: 'Yemen',
        iso3: 'YEM',
        iso2: 'YE',
        states: [
            {
                name: "'Adan Governorate",
                state_code: 'AD',
            },
            {
                name: "'Amran Governorate",
                state_code: 'AM',
            },
            {
                name: 'Abyan Governorate',
                state_code: 'AB',
            },
            {
                name: "Al Bayda' Governorate",
                state_code: 'BA',
            },
            {
                name: 'Al Hudaydah Governorate',
                state_code: 'HU',
            },
            {
                name: 'Al Jawf Governorate',
                state_code: 'JA',
            },
            {
                name: 'Al Mahrah Governorate',
                state_code: 'MR',
            },
            {
                name: 'Al Mahwit Governorate',
                state_code: 'MW',
            },
            {
                name: 'Dhamar Governorate',
                state_code: 'DH',
            },
            {
                name: 'Hadhramaut Governorate',
                state_code: 'HD',
            },
            {
                name: 'Hajjah Governorate',
                state_code: 'HJ',
            },
            {
                name: 'Ibb Governorate',
                state_code: 'IB',
            },
            {
                name: 'Lahij Governorate',
                state_code: 'LA',
            },
            {
                name: "Ma'rib Governorate",
                state_code: 'MA',
            },
            {
                name: 'Raymah Governorate',
                state_code: 'RA',
            },
            {
                name: 'Saada Governorate',
                state_code: 'SD',
            },
            {
                name: "Sana'a",
                state_code: 'SA',
            },
            {
                name: "Sana'a Governorate",
                state_code: 'SN',
            },
            {
                name: 'Shabwah Governorate',
                state_code: 'SH',
            },
            {
                name: 'Socotra Governorate',
                state_code: 'SU',
            },
            {
                name: "Ta'izz Governorate",
                state_code: 'TA',
            },
        ],
    },
    {
        name: 'Zambia',
        iso3: 'ZMB',
        iso2: 'ZM',
        states: [
            {
                name: 'Central Province',
                state_code: '02',
            },
            {
                name: 'Copperbelt Province',
                state_code: '08',
            },
            {
                name: 'Eastern Province',
                state_code: '03',
            },
            {
                name: 'Luapula Province',
                state_code: '04',
            },
            {
                name: 'Lusaka Province',
                state_code: '09',
            },
            {
                name: 'Muchinga Province',
                state_code: '10',
            },
            {
                name: 'Northern Province',
                state_code: '05',
            },
            {
                name: 'Northwestern Province',
                state_code: '06',
            },
            {
                name: 'Southern Province',
                state_code: '07',
            },
            {
                name: 'Western Province',
                state_code: '01',
            },
        ],
    },
    {
        name: 'Zimbabwe',
        iso3: 'ZWE',
        iso2: 'ZW',
        states: [
            {
                name: 'Bulawayo Province',
                state_code: 'BU',
            },
            {
                name: 'Harare Province',
                state_code: 'HA',
            },
            {
                name: 'Manicaland',
                state_code: 'MA',
            },
            {
                name: 'Mashonaland Central Province',
                state_code: 'MC',
            },
            {
                name: 'Mashonaland East Province',
                state_code: 'ME',
            },
            {
                name: 'Mashonaland West Province',
                state_code: 'MW',
            },
            {
                name: 'Masvingo Province',
                state_code: 'MV',
            },
            {
                name: 'Matabeleland North Province',
                state_code: 'MN',
            },
            {
                name: 'Matabeleland South Province',
                state_code: 'MS',
            },
            {
                name: 'Midlands Province',
                state_code: 'MI',
            },
        ],
    },
]
