import React, { useState } from 'react';
import { 
    Dialog, 
    DialogTitle, 
    DialogContent,
    Grid, 
    Card, 
    Typography, 
    Button, 
} from "@material-ui/core";
import { PersonAddOutlined as PersonAdd } from '@material-ui/icons';
import { 
    RHFTeamEditorForm,
    Supplier,
    UserState
} from "@syncfab/machine";
import { WithNamespaces, withNamespaces } from 'react-i18next';
import { useStyles } from './common';
import { useFormContext } from 'react-hook-form';
import { ErrorMessage } from '@hookform/error-message';
import { Alert } from '@material-ui/lab';
interface ContactListFormProps extends WithNamespaces {
    supplier: Supplier
}
const ContactListForm = ({ supplier, t }: ContactListFormProps) => {
    const classes = useStyles();
    const [dialogOpen, setDialogOpen] = useState(false);
    const [ addressToEdit, setAddressToEdit ] = useState(null);
    const { trigger } = useFormContext();
    React.useEffect(() => {
       trigger('teams');
    }, [])
    
    const createLabelValue = (key, label) => (
        <Typography gutterBottom variant="subtitle2">
            <b>{key}</b> <span className={classes.text}>{label}</span>
        </Typography>
    )
    return (
        <Grid container spacing={2} style={{ marginTop: 24 }} justifyContent="space-between">
            {supplier?.teams?.map((team, index) => (
                    <Grid item md={6} key={index}>
                        <Card className={classes.contactCard}>
                            <Typography gutterBottom variant="body1">
                                <b>
                                    {team.firstName} {team.lastName}
                                </b>
                            </Typography>

                            {team && team._id && team._id === supplier.primaryContact && (
                                <Typography gutterBottom variant="body1" color="primary" align="left">
                                    <b>{`PRIMARY`}</b>
                                </Typography>
                            )}
                            {createLabelValue('Title: ', team.title)}
                            {createLabelValue('Email: ', team.email)}
                            {createLabelValue('Work Phone: ', team.phone)}
                            {createLabelValue('Ext: ', team.phoneExtension)}
                            {createLabelValue('Cell Phone: ', team.mobile)}
                            {createLabelValue('Created: ', team.createdAt ? new Date(team.createdAt).toLocaleString() : '-')}
                            {createLabelValue('Last Active: ', team.lastActiveAt ? new Date(team.lastActiveAt).toLocaleString() : '-')}
                            <Grid container justifyContent="flex-end">
                                {index !== 0 && team.userState === UserState.INVITED && (
                                    <Button
                                        className={classes.action}
                                        variant="outlined"
                                        color="primary"
                                        size='small'
                                        disabled
                                    >
                                        User Invited
                                    </Button>
                                )}
                                    <Button 
                                    onClick={() => {
                                        setAddressToEdit(team)
                                        setDialogOpen(true);
                                    }} 
                                    className={classes.action}
                                    style={{ marginRight: '15px' }} 
                                    color="primary" 
                                    variant="text"
                                >
                                    {`${t('EDIT')}`}
                                </Button>
                            </Grid>
                        </Card>
                    </Grid>
                ))}
            <Grid item md={supplier?.teams?.length > 0 ? 6 : 12}>
                <Card className={classes.addCard}>
                    <PersonAdd />
                    <Button 
                        variant="contained" 
                        color="primary" 
                        aria-label="Add" 
                        style={{
                            marginTop: 10
                        }}
                        onClick={() => {
                            setAddressToEdit(undefined);
                            setDialogOpen(true);
                        }}>
                        Invite Team Member
                    </Button>
                </Card>
            </Grid>
            <ErrorMessage 
                name='teams'
                render={({ message }) => {
                    return (
                        <Alert 
                        className={classes.teamAlert}
                        severity='error'>{message}</Alert>
                    )
                }} 
            />
            <Dialog open={dialogOpen} aria-labelledby="Contact" onClose={() => setDialogOpen(false)} fullWidth>
                <DialogTitle id="Contact">{'Contact'}</DialogTitle>
                <DialogContent>
                    <RHFTeamEditorForm cancel={() => setDialogOpen(false)} initialValues={addressToEdit} supplier={supplier}/>
                </DialogContent>
            </Dialog>
        </Grid>
    )
}

export default withNamespaces()(ContactListForm);