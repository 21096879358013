import React from 'react'
import { Field, reduxForm, FormSection } from 'redux-form/immutable'
import { InjectedFormProps } from 'redux-form'
import { Map } from 'immutable'

import { WithStyles, withStyles } from '@material-ui/core/styles'
import { withNamespaces, WithNamespaces } from 'react-i18next'
import { FormTextField, Button, jumpToFirstError } from '@syncfab/machine'
import { Grid, Typography } from '@material-ui/core'
import { SupplierSignupFormName } from './SupplierSignupPersonalInformationForm'
import { LocationForm, locationValidation } from '../../../../components/company/address/AddressForm'
import { requiredTextField } from '../../../../utils/Validators'

const styles = theme => ({
    back: {
        marginTop: 16,
        marginBottom: 6,
    },
    action: {
        marginTop: 6,
        marginBottom: 16,
    },
    goBackText: {
        display: 'flex',
        'justify-content': 'center',
        'align-items': 'baseline',
    },
    goBackButton: {
        margin: 0,
        padding: 0,
        'text-transform': 'none',
        color: theme.palette.primary.light,
        'font-weight': theme.typography.fontWeightRegular,
        'font-size': 12,
    },
    gridItem: {
        paddingTop: '0px !important',
        paddingBottom: '0px !important',
    },
    formInput: {
        '& .MuiFormControl-marginNormal': {
            margin: 0,
        },
    },
})

type SupplierSignupCompanyInformationFields = {
    companyName?: string
    location?: Object
}
const MAX_LENGTH: number = 40

const validate = (data: Map<string, string>): {} => {
    const errors: SupplierSignupCompanyInformationFields = {}
    errors.companyName = requiredTextField(data.get('companyName'), MAX_LENGTH)
    errors.location = locationValidation(data)
    return errors
}

interface BaseFormProperties {
    onPrevious?: () => void
}

interface FormProperties extends BaseFormProperties, InjectedFormProps<{}, {}>, WithStyles, WithNamespaces {}

const form = (props: FormProperties) => {
    const { handleSubmit, submitting, t, onPrevious, classes } = props
    return (
        <form onSubmit={handleSubmit}>
            <Grid container direction="row" spacing={2}>
                <Grid item xs={12} md={12} className={classes.gridItem}>
                    <Field name="companyName" component={FormTextField} type="text" label={t('company-name')} fullWidth />
                </Grid>
                <Grid item xs={12} md={12}>
                    <FormSection name="location">
                        <LocationForm />
                    </FormSection>
                </Grid>
                <Grid item xs={12} md={12} className={classes.gridItem}>
                    <Button type="submit" color="primary" disabled={submitting} className={classes.action} fullWidth>
                        {t('signup-form-continue')}
                    </Button>
                    <div className={classes.goBackText}>
                        <Typography variant="caption" className={classes.loginText}>
                            {`${t('signup-back')}`}
                        </Typography>
                        <Button type="button" variant="text" color="primary" disabled={submitting} className={classes.goBackButton} onClick={onPrevious}>
                            {t('signup-back-link')}
                        </Button>
                    </div>
                </Grid>
            </Grid>
        </form>
    )
}

const translatedForm = withNamespaces()(withStyles(styles)(form))
export const SupplierSignupCompanyInformationForm = reduxForm<{}, BaseFormProperties>({
    form: SupplierSignupFormName,
    validate,
    destroyOnUnmount: false,
    forceUnregisterOnUnmount: true,
    onSubmitFail: jumpToFirstError,
})(translatedForm)
