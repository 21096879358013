import { connect } from 'react-redux'
import { State } from '../../application/Store'
import React from 'react'
import { Document, Page, pdfjs } from 'react-pdf'
import { Typography, Paper, WithStyles, withStyles, Dialog, AppBar, Toolbar, IconButton } from '@material-ui/core'
pdfjs.GlobalWorkerOptions.workerSrc = require('pdfjs-dist/build/pdf.worker.entry')
import CloseIcon from '@material-ui/icons/Close'

const styles = theme => ({
    paper: {
        width: '90%',
        minWidth: 300,
        padding: theme.spacing(1),
        marginTop: theme.spacing(6),
        marginBottom: theme.spacing(4),
        background: 'transparent',
        boxShadow: 'none',
        justifyContent: 'center',
    },
    container: {
        marginTop: theme.spacing(6),
    },
})
interface PDFViewerProps extends WithStyles {
    blobPDF: any
    open: boolean
    onClose: () => void
}

interface PDFViewerState {
    numPages: any
    pageNumber: Number
}

class PDFViewer extends React.Component<PDFViewerProps, PDFViewerState> {
    constructor(props) {
        super(props)
        this.state = { numPages: null, pageNumber: 1 }
    }

    onDocumentLoadSuccess = ({ numPages }) => {
        this.setState({ numPages })
    }

    render() {
        const { numPages } = this.state
        const { blobPDF, classes, open, onClose } = this.props
        return (
            <Dialog fullScreen open={open} onClose={() => onClose()}>
                <AppBar style={{ position: 'fixed' }}>
                    <Toolbar>
                        <IconButton edge="start" color="inherit" onClick={() => onClose()} aria-label="close">
                            <CloseIcon />
                        </IconButton>
                    </Toolbar>
                </AppBar>
                <div style={{ display: 'flex', justifyContent: 'center' }}>
                    <div className={classes.container}>
                        {blobPDF ? (
                            <div>
                                <Document
                                    file={blobPDF}
                                    onLoadSuccess={this.onDocumentLoadSuccess}
                                    onLoadError={console.error}
                                >
                                    {Array.from(new Array(numPages), (_, index) => (
                                        <Page key={`page_${index + 1}`} pageNumber={index + 1} scale={2} />
                                    ))}
                                </Document>
                            </div>
                        ) : (
                            <Paper className={classes.paper} elevation={1}>
                                <Typography color="primary" gutterBottom variant="h5" style={{ margin: '24px' }}>
                                    {`Please wait...`}
                                </Typography>
                            </Paper>
                        )}
                    </div>
                </div>
            </Dialog>
        )
    }
}
const PDFViewerWithStyles = withStyles(styles)(PDFViewer)

const mapStateToProps = (state: State) => {
    return {
        blobPDF: state.document.blobPDF,
    }
}

export default connect(mapStateToProps)(PDFViewerWithStyles)
