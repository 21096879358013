import React from 'react'
import SvgIcon from '@material-ui/core/SvgIcon'
import {makeStyles} from "@material-ui/core";

const useStyles = makeStyles(() => ({
    solidFill: {
        fill: "#ffc32e;"
    },
    gradient_b: {
        fill: "url(#b)"
    },
    gradient_c: {
        fill: "url(#c)"
    },
    gradient_d: {
        fill: "url(#d)"
    }
}))

export const MFGIcon = props => {
    const classes = useStyles({})
    return (
        <>
            <svg id="a" height="0" width="0">
                <linearGradient id="b" x1="19.9" y1="836.5" x2="10.6" y2="842.3" gradientTransform="translate(0 -829.2)" gradientUnits="userSpaceOnUse">
                    <stop offset="0" stopColor="#fdbe13"/>
                    <stop offset="1" stopColor="#7f754e"/>
                </linearGradient>
                <linearGradient id="c" x1="11.9" y1="850.7" x2="12.1" y2="842.8" gradientTransform="translate(0 -829.2)" gradientUnits="userSpaceOnUse">
                    <stop offset="0" stopColor="#fdbe13"/>
                    <stop offset="1" stopColor="#837950"/>
                </linearGradient>
                <linearGradient id="d" x1="3.8" y1="836.4" x2="11.9" y2="841" gradientTransform="translate(0 -829.2)" gradientUnits="userSpaceOnUse">
                    <stop offset="0" stopColor="#fdbe13"/>
                    <stop offset="1" stopColor="#83784f"/>
                </linearGradient>
            </svg>
            <SvgIcon {...props} viewBox="0 0 24 24" x="0px" y="0px" baseProfile="tiny" xmlSpace="preserve">
                <path d="M20.8,15c-4.4,0-8-3.6-8-8h0c0-1.7,.5-3.3,1.4-4.7h-4.5c2.5,3.6,1.7,8.6-1.9,11.2-1.3,1-3,1.5-4.6,1.5h-.9l2.3,4c1.8-4,6.5-5.8,10.6-4,1.8,.8,3.2,2.2,4,4l2.3-4h-.8Z" className={classes.solidFill} />
                <path d="M19.3,12.2c0-1.4,1-2.6,2.4-2.9l-2.3-4c-1.1,1.2-3,1.3-4.2,.2-.6-.6-1-1.4-1-2.2,0-.3,0-.7,.2-1h-.1c-2.5,3.6-1.7,8.6,1.9,11.2,1.4,.9,3,1.5,4.6,1.5h.5c-1.2-.5-2-1.6-2-2.8Z" className={classes.gradient_b} />
                <path d="M8.4,18.5c1.2,.6,1.8,2.1,1.4,3.4h4.6c-.5-1.6,.4-3.2,1.9-3.7,.8-.2,1.6-.2,2.3,.2,.3,.1,.5,.3,.7,.5-1.9-4-6.7-5.8-10.7-3.9-1.7,.8-3.1,2.2-3.9,3.9h0c.9-1,2.5-1.2,3.7-.5Z" className={classes.gradient_c} />
                <path d="M9.8,2.3h0c.5,1.5-.3,3.2-1.8,3.8-.3,.1-.6,.2-1,.2-.9,0-1.7-.3-2.3-1l-2.3,4.1c1.6,.2,2.7,1.7,2.4,3.3,0,0,0,0,0,0-.2,1.1-.9,2-2,2.3h.4c4.4,0,8-3.5,8.1-7.9,0-1.7-.5-3.4-1.5-4.8h0Z" className={classes.gradient_d} />
            </SvgIcon>
        </>
    )
}
