import { Order } from './Order'
import { PaymentTerms } from '../quote'
import DateFnsUtils from '@date-io/date-fns'

const dateFns = new DateFnsUtils()
const calculateMAterialDepositRequriedUpfront = (order: Order): Date => {
    return new Date(order.createdAt)
}

const calculateCOD = (order: Order): Date => {
    const deliveryATA = order.milestones.DELIVERY.actual
    return new Date(deliveryATA)
}

const calculateNET30 = (order: Order): Date => {
    const deliveryATA = order.milestones.DELIVERY.actual
    return dateFns.addDays(deliveryATA, 30)
}

const calculateNET45 = (order: Order): Date => {
    const deliveryATA = order.milestones.DELIVERY.actual
    return dateFns.addDays(deliveryATA, 45)
}

const calculateNET15 = (order: Order): Date => {
    const deliveryATA = order.milestones.DELIVERY.actual
    return dateFns.addDays(deliveryATA, 15)
}

const caculators: { [key in PaymentTerms]: (order: Order) => Date } = {
    [PaymentTerms.MATERIAL_DEPOSIT_REQUIRED_UPFRONT]: calculateMAterialDepositRequriedUpfront,
    [PaymentTerms.COD]: calculateCOD,
    [PaymentTerms.NET15]: calculateNET15,
    [PaymentTerms.NET30]: calculateNET30,
    [PaymentTerms.NET45]: calculateNET45,
}

export const orderDueDateCalculator = (order: Order): Date => {
    const deliveryATA = order.milestones.DELIVERY.actual
    const { paymentTerms } = order
    if (!deliveryATA && paymentTerms !== PaymentTerms.MATERIAL_DEPOSIT_REQUIRED_UPFRONT) {
        return null
    }

    return caculators[paymentTerms](order)
}
