import { Language } from '../i18n/I18NService'
import {Company} from "../company";
import { ListParameters } from "../BaseTypes";
import { ValueLabelOption } from "../ui";
import { Supplier } from '../supplier/Supplier';
export enum UserRole {
    ADMIN = 'ADMIN',
    MEMBER = 'MEMBER',
}

export enum NotificationType {
    RFQ = 'RFQ',
    QUOTES = 'QUOTES',
    PURCHASE_ORDERS = 'PURCHASE_ORDERS',
    NEWS_LETTER = 'NEWS_LETTER',
}

export const NOTIFICATION_TYPES = [NotificationType.RFQ, NotificationType.QUOTES, NotificationType.PURCHASE_ORDERS, NotificationType.NEWS_LETTER]

export type NotificationSetting = {
    type: NotificationType
    subscribed: boolean
}

export enum UserState {
    NEW = 'NEW',
    INVITED = 'INVITED',
    ACTIVE = 'ACTIVE',
    DEACTIVATED = 'DEACTIVATED',
    CONTACTONLY = 'CONTACTONLY',
}
export enum LoginRole {
    BUYER = 'BUYER',
    SUPPLIER = 'SUPPLIER',
    OPERATOR = 'OPERATOR',
}

export enum Department {
    SALES = 'SALES',
    PROCUREMENT = 'PROCUREMENT',
    PRODUCT = 'PRODUCT',
    MANAGEMENT = 'MANAGEMENT'
}

export interface User {
    _id: string
    companyId: string
    firstName: string
    lastName: string
    email: string
    phone: string
    mobile?: string
    roles: UserRole[]
    title: string
    notificationSettings: NotificationSetting[]
    isBillingContact?: boolean
    language: Language
    createdAt: Date
    updatedAt?: Date
    activatedAt?: Date
    supplier: Supplier
    supplierId?: string
    loginRole?: string
    company?: Company
    userState?: string
    phoneExtension?: string
    lastActiveAt?: string
    department?: Department
    teams?: any[]
}

export interface UserListParameters extends ListParameters {
    loginRole?: LoginRole
    userState?: string
}

export const createSingleUserOption = (userId: string, userName: string): ValueLabelOption => {
    return { value: userId, label: userName }
}

export const createUserOption = (user: User): ValueLabelOption => {
    return { value: user._id, label: `${user.firstName} ${user.lastName }` }
}

export const createUserOptionWithId = (user: User): ValueLabelOption => {
    return { value: user._id, label: `${user._id} - ${user.firstName} ${user.lastName }` }
}